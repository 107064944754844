import { fromJS } from 'immutable';
import { CATEGORY_BROWSING_STARTED, SET_CATEGORY_LIST } from 'client/actions/ui/categoriesDropdownActions';
import { SET_PREVIOUS_LOCATION } from '../../actions/ui/dropdownMenuActions';

const defaultState = fromJS({ isOpen: false, previousLocation: '/', categoriesList: [] });

export default (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORY_BROWSING_STARTED:
      return state.set('isOpen', false);

    case SET_PREVIOUS_LOCATION:
      return state.set('previousLocation', action.location);

    case SET_CATEGORY_LIST:
      return state.set('categoriesList', action.payload);
    default:
      return state;
  }
};
