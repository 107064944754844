import Tooltip from 'react-bootstrap/lib/Tooltip';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./braintreeTooltip.scss');
}

export const getToolTipContent = (lines) => {
  return (
    <Tooltip id="tooltip" className="braintreeTooltip">
      {
        lines.map(line => <div key={ line }>{ getLocalizedString(line) }</div>)
      }
    </Tooltip>
  );
};
