import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setGooglePayData } from 'client/actions/braintreeGatewayActions';
import { getGooglePaymentRequest } from 'client/components/elements/paymentMethod/buttons/utils/googlePayUtils';
import { getLocalizedString } from 'localization/localizer';
import { getCartTotalPriceWithQuotation } from 'client/components/elements/paymentMethod/buttons/utils/googlePayUtils';

if (process.browser) {
  require('./buttons.scss');
}

export const PayByGoogleButton = ({ paymentClient, googlePayInstance, totalPriceWithVat, onClick, setGooglePayData }) => {
  const googlePayButtonRef = useRef(null);
  const [isGooglePayClientError, setGooglePayClientError] = useState(null);

  useEffect(() => {
    const onGooglePaymentsButtonClicked = async () => {
      const paymentDataRequest = getGooglePaymentRequest(googlePayInstance, {
        currencyCode: 'GBP',
        totalPriceStatus: 'FINAL',
        totalPrice: totalPriceWithVat.toString()
      });
      try {
        const paymentData = await paymentClient.loadPaymentData(paymentDataRequest);
        const parsedPaymentData = await googlePayInstance.parseResponse(paymentData);
        if (parsedPaymentData.type !== 'AndroidPayCard') {
          throw new Error('paypal.via.googlepay.is.not.supported');
        }
        const result = {
          data: {
            paymentDetails: parsedPaymentData,
            billingAddress: paymentData.paymentMethodData.info.billingAddress
          }
        };
        setGooglePayData(result);
        onClick();
      } catch (err) {
        if (err.message !== 'User closed the Payment Request UI.') {
          // eslint-disable-next-line no-console
          console.log(err.message);
          setGooglePayClientError(true);
        }
      }
    };

    const googlePayButton = paymentClient.createButton({
      buttonColor: 'black',
      buttonType: 'pay',
      buttonSizeMode: 'fill',
      onClick: onGooglePaymentsButtonClicked
    });
    googlePayButtonRef.current.innerHTML = '';
    googlePayButtonRef.current.appendChild(googlePayButton);
  }, [totalPriceWithVat]);

  return (
    <>
      <div data-e2e="googlePayButton" ref={ googlePayButtonRef } style={{ height: '40px' }}/>
      {isGooglePayClientError ? <div className="ApiCallButton_error">{getLocalizedString('singlePageCheckout.place.order.internal.server.error')}</div> : null}
    </>
  );
};

function mapStateToProps (state, ownProps) {
  const { location } = ownProps;
  const cartTotal = state.getIn(['cart', 'cartPrice']).toJS();
  const query = location.query ? location.query : null;
  const quotationId = query && query.fromQuotationId;
  const quotationDetails = quotationId ? state.getIn(['quotations', 'details', quotationId]) : null;
  const totalPriceWithVat = getCartTotalPriceWithQuotation(cartTotal, quotationDetails);
  return {
    googlePayInstance: state.getIn(['braintree', 'googlePay', 'instance']),
    paymentClient: state.getIn(['braintree', 'googlePay', 'paymentClient']),
    cartTotal,
    totalPriceWithVat
  };
}

const mapDispatchToProps = {
  setGooglePayData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayByGoogleButton));
