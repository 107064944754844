'use strict';

import { fromJS } from 'immutable';
import {
  POST_ORDER_SUCCESS,
  POST_ORDER_FAILED,
  RESET_ORDER_REVIEW_STATE,
  TOGGLE_DELIVERY_OPTION_ACCORDION,
  PRODUCT_LINE_UPDATING_OPTION,
  TOGGLE_TERMS_OF_SALES
} from '../actions/orderReviewActions';

export const defaultState = fromJS({
  postOrderError: null,
  showTermsOfSales: true
});

const orderReviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_ORDER_REVIEW_STATE:
      return defaultState;
    case POST_ORDER_SUCCESS:
      return state.set('postOrderError', null);
    case POST_ORDER_FAILED:
      return state.set('postOrderError', action.payload.error);
    case TOGGLE_DELIVERY_OPTION_ACCORDION:
      return state.set('showDeliveryOptions', action.payload);
    case PRODUCT_LINE_UPDATING_OPTION:
      return state.set('isUpdatingDeliveryOption', action.payload);
    case TOGGLE_TERMS_OF_SALES:
      return state.set('showTermsOfSales', action.payload);
    default:
      return state;
  }
};

export default orderReviewReducer;
