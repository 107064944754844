import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { showLockingAndSavingQuoteDialog, hideLockingAndSavingQuoteDialog } from 'client/actions/ui/dialogActions';
import { PrimaryLink, SecondaryLink, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { fromJS } from 'immutable';
import {
  updateField,
  updateQuoteName
} from 'client/actions/globalQuotationActions';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';

if (process.browser) {
  require('./lockAndSaveQuoteDialog.scss');
}
import { MAX_QUOTE_NAME_LENGTH, MIN_QUOTE_NAME_LENGTH } from 'shared/constants/quotation';

function mapStateToProps (state) {
  return {
    globalQuotation: state.getIn(['globalQuotation', 'createdQuotation'], fromJS({})),
    quoteToSaveName: state.getIn(['globalQuotation', 'quoteToSaveName'], ''),
    quoteNameWasUpdated: state.getIn(['globalQuotation', 'quoteNameWasUpdated'], false),
    isVisibleSaveAndLockGlobalQuoteDialog: state.getIn(['ui', 'dialogs', 'saveAndLockGlobalQuote', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    showLockingAndSavingQuoteDialog: () => dispatch(showLockingAndSavingQuoteDialog()),
    hideLockingAndSavingQuoteDialog: () => dispatch(hideLockingAndSavingQuoteDialog()),
    updateField: (value, fieldName) => dispatch(updateField(value, fieldName)),
    updateQuoteName: (name, quoteRef) => dispatch(updateQuoteName(name, quoteRef))
  };
}

const isInRange = (value, min, max) => value >= min && value <= max;

const LockAndSaveGlobalQuote = (props) => {
  const {
    isVisibleSaveAndLockGlobalQuoteDialog,
    hideLockingAndSavingQuoteDialog,
    globalQuotation,
    updateField,
    quoteToSaveName,
    updateQuoteName,
    quoteNameWasUpdated
  } = props;
  const quoteRef = globalQuotation && globalQuotation.get('quoteRef');
  const name = globalQuotation && globalQuotation.get('name');
  const quoteNameField = 'Quote Name';
  return (
    <Modal className="LockAndSaveGlobalQuote" show={ isVisibleSaveAndLockGlobalQuoteDialog }
      onHide={ hideLockingAndSavingQuoteDialog }>
      <Modal.Header className="LockAndSaveGlobalQuote_header" closeButton/>
      <Modal.Body>
        <div className="LockAndSaveGlobalQuote_body">
          <h2>{ getLocalizedString('globalQuotation.lockAndSave') }</h2>
          <div className="LockAndSaveGlobalQuote_lockAndSavedText">
            Quotation <b data-e2e="quoteRef">{quoteRef}</b>{ getLocalizedString('globalQuotation.hasBeenLocked') }<br/>
          </div>
          { getLocalizedString('globalQuotation.lockedAndSavedMessage') }<b>{quoteRef}</b>
          <div className="LockAndSaveGlobalQuote_saveQuote">
            <div>
              { quoteNameField }
              <input type="text" disabled={ quoteNameWasUpdated } placeholder={ name } onChange={ (e) => updateField(e.target.value, 'quoteToSaveName') } data-e2e="quoteNameInput"/>
              {
                !quoteNameWasUpdated ? <PrimaryButton
                  text="Change name"
                  disabled={ !quoteToSaveName.length || !isInRange(quoteToSaveName.length, MIN_QUOTE_NAME_LENGTH, MAX_QUOTE_NAME_LENGTH) }
                  onClick={ () => updateQuoteName(quoteToSaveName, quoteRef) }/> : null
              }
            </div>
            {getErrorMessageFieldLength(quoteNameField, quoteToSaveName, MIN_QUOTE_NAME_LENGTH, MAX_QUOTE_NAME_LENGTH)}
          </div>
        </div>
      </Modal.Body>
      <div className="LockAndSaveGlobalQuote_actions">
        <PrimaryLink isExternalLink={ true } className="LockAndSaveGlobalQuote_actions-viewQuotes" text="View Quotations" to="/my-account/quotations" onClick={ hideLockingAndSavingQuoteDialog } datae2e='viewQuotationsLink' />
        <SecondaryLink className="LockAndSaveGlobalQuote_actions-continue" text="Continue Shopping" onClick={ hideLockingAndSavingQuoteDialog } datae2e='continueShoppingLink' />
      </div>
    </Modal>
  );
};

const getErrorMessageFieldLength = (field, fieldValue, minLength, maxLength) => {
  if (!fieldValue?.length || isInRange(fieldValue.length, minLength, maxLength)) {
    return null;
  }
  return (<small className="globalQuotationContainer_saveQuote-invalid">
    { fieldValue.length < minLength ? getLocalizedStringWithParam('error.validation.minLength', { field, minLength }) : null }
    { fieldValue.length > maxLength ? getLocalizedStringWithParam('error.validation.maxLength', { field, maxLength }) : null }
  </small>);
};

export default connect(mapStateToProps, mapDispatchToProps)(LockAndSaveGlobalQuote);
