import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeApplePay } from './payByApplePayActions';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { getLocalizedString } from 'localization/localizer';
import { PaymentAddress } from '../shared/PaymentAddress';
if (process.browser) {
  require('./payByApplePay.scss');
}

export const PayByApplePay = ({
  selector,
  initializeApplePay,
  billingAddress,
  reviewOrderButton,
  applePayPayload
}) => {
  useEffect(() => {
    if (applePayPayload) {
      initializeApplePay({
        selector
      });
    }
  }, [applePayPayload]);

  const isCheckout = selector === CHECKOUT_SELECTOR;
  return (
    window && window.ApplePaySession ? (
      <div className="PayByApplePay">
        { isCheckout && !applePayPayload ? reviewOrderButton : null}
        { billingAddress ? <PaymentAddress
          data-e2e="PayByApplyPay_payment_address"
          address={ billingAddress }
          title={ getLocalizedString('payment.label.billing.details') }
          button={ reviewOrderButton }/> : null }
      </div>) : null
  );
};

export function mapApplePayAddressToZoro (billingDetails) {
  return {
    firstName: billingDetails.billingAddress.givenName,
    lastName: billingDetails.billingAddress.familyName,
    addressLine1: billingDetails.billingAddress.addressLines[0],
    addressLine2: billingDetails.billingAddress.addressLines.length > 1 ? billingDetails.addressLines[1] : '',
    state: billingDetails.billingAddress.country,
    city: billingDetails.billingAddress.locality,
    postalCode: billingDetails.billingAddress.postalCode
  };
}

function mapStateToProps (state, ownProps) {
  const applePay = state.getIn(['braintree', 'applePay', 'data']);
  const billingAddress = applePay && mapApplePayAddressToZoro(applePay);
  return {
    billingAddress,
    applePayPayload: applePay
  };
}

const mapDispatchToProps = {
  initializeApplePay
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByApplePay);
