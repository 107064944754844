import Immutable from 'immutable';
import {
  RECIEVE_LOGIN_USER_SUCCESS,
  RECIEVE_LOGIN_USER_ERROR,
  REMOVE_LOGIN_ERROR
} from 'client/actions/userActions';
import { HIDE_LOGIN_DIALOG } from 'client/actions/ui/dialogActions';

export const defaultState = Immutable.fromJS({});

const loginFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECIEVE_LOGIN_USER_ERROR:
      return state.set('error', action.error);
    case RECIEVE_LOGIN_USER_SUCCESS:
    case HIDE_LOGIN_DIALOG:
    case REMOVE_LOGIN_ERROR:
      return state.set('error', undefined);
    default:
      return state;
  }
};

export default loginFormReducer;
