import { fromJS } from 'immutable';
import {
  LOAD_ORDER_SUCCESS,
  LOAD_MY_ORDERS_SUCCESS,
  LOAD_ORDERS_RAW_SUCCESS,
  LOAD_ACCOUNT_ORDERS_SUCCESS,
  REQUEST_MY_ORDERS,
  REQUEST_ACCOUNT_ORDERS,
  SHOW_HIDE_TRACKING_DETAILS,
  UPDATE_ORDER_SUMMARY
} from 'client/actions/ordersActions';
import { DEFAULT_PAGESIZE, DEFAULT_SORTBY, ALL_ORDERS, ALL_DAYS } from 'shared/constants/orderSummaryConstants';

const defaultState = fromJS({
  myOrders: [],
  accountOrders: [],
  orderSummary: { sortField: DEFAULT_SORTBY,
    pageSize: DEFAULT_PAGESIZE,
    pageNumber: 1,
    expanded: {},
    orderStatusType: ALL_ORDERS,
    dateRange: ALL_DAYS,
    searchInput: '' }
});

const order = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_ACCOUNT_ORDERS:
      return state.setIn(['accountOrders', 'isLoading'], true);
    case REQUEST_MY_ORDERS:
      return state.setIn(['myOrders', 'isLoading'], true);
    case LOAD_ORDER_SUCCESS:
      return state.setIn(['order', action.order._id], fromJS(action.order));
    case LOAD_MY_ORDERS_SUCCESS:
      return state.set('myOrders', fromJS(action.payload))
        .setIn(['myOrders', 'isLoading'], false);
    case LOAD_ACCOUNT_ORDERS_SUCCESS:
      return state.set('accountOrders', fromJS(action.payload))
        .setIn(['accountOrders', 'isLoading'], false);
    case LOAD_ORDERS_RAW_SUCCESS:
      return state.set('ordersRaw', fromJS(action.orders));
    case SHOW_HIDE_TRACKING_DETAILS:
      const { merlinOrderReference, trackingCode, orderType } = action.payload;
      const orderIndex = state
        .getIn([orderType, 'orders'])
        .findIndex(order => {
          return order.get('merlinOrderReference') === merlinOrderReference;
        });

      const dispatchedItemIndex = state
        .getIn([orderType, 'orders', orderIndex, 'dispatched'])
        .findIndex(dispatch => {
          return dispatch.get('trackingCode') === trackingCode;
        });

      const previousSelectedItem = state
        .getIn([orderType, 'selectedTrackingCode'], {});

      const { selectedOrderIndex, selectedTrackingCodeIndex } = previousSelectedItem;

      const showTrackingDetails = state.getIn([
        orderType,
        'orders',
        orderIndex,
        'dispatched',
        dispatchedItemIndex,
        'showTrackingDetails'
      ], false);

      return selectedTrackingCodeIndex === undefined
        ? state.setIn([ orderType, 'orders', orderIndex, 'dispatched', dispatchedItemIndex, 'showTrackingDetails' ], !showTrackingDetails)
          .setIn([orderType, 'selectedTrackingCode' ], { selectedOrderIndex: orderIndex, selectedTrackingCodeIndex: dispatchedItemIndex })

        : state
          .setIn([orderType, 'orders', selectedOrderIndex, 'dispatched', selectedTrackingCodeIndex, 'showTrackingDetails'], false)
          .setIn([orderType, 'orders', orderIndex, 'dispatched', dispatchedItemIndex, 'showTrackingDetails'], !showTrackingDetails)
          .setIn([orderType, 'selectedTrackingCode' ], { selectedOrderIndex: orderIndex, selectedTrackingCodeIndex: dispatchedItemIndex });
    case UPDATE_ORDER_SUMMARY:
      const stateObj = state.toJS();
      const { orderSummary } = stateObj;
      const updated = { ...orderSummary, ...action.payload };
      return fromJS({ ...stateObj, orderSummary: updated });
    default:
      return state;
  }
};

export default order;
