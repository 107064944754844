export const RECENTLY_VIEWED = 'Recently Viewed';
export const TITLE_LENGTH_LIMIT = 40;
export const UNBRANDED_BRANDNAME = '(Unbranded)';

export const CURRENCY_TYPE = {
  currency: 'GBP'
};

export const RECOMMENDATIONS_LOCATIONS = {
  cart_screen: 'CART_PAGE',
  basket_interstitial: 'BASKET_INTERSTITIAL_PAGE',
  add_to_quotation_interstitial: 'ADD_TO_QUOTATION_INTERSTITIAL_PAGE',
  subscriptions: 'Subscriptions'
};
export const BRAND_LENGTH_LIMIT = 25;
