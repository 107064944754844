import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { formatPrice } from 'client/utils/priceUtils';

export const getFreeDeliveryShortfall = (totalizedValues) => {
  const [ left, right ] = getLocalizedString('cart.cartTotalizer.fieldLabels.freeDeliveryShortfall').split('{amount}');
  const priceWithCurrency = formatPrice(totalizedValues.currency, totalizedValues.freeDeliveryShortfall);

  return totalizedValues.freeDeliveryShortfall > 0
    ? [ left, <strong>{`${priceWithCurrency.symbol}${priceWithCurrency.amount}`}</strong>, right ] // eslint-disable-line react/jsx-key
    : null;
};
