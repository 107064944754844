
import React, { Component } from 'react';
import PropTypes from 'prop-types';
if (process.browser) {
  require('./RadioButtonWithValidation.scss');
}
class RadioButtonWithValidation extends Component {
  render () {
    const { input, meta, options, validatorName } = this.props;
    const hasError = meta.touched && meta.error;
    const classNameAndValidatorName = (validatorName) ? `RadioButtonWithValidation ${validatorName}` : 'RadioButtonWithValidation';
    return (
      <div className={ classNameAndValidatorName }>
        {options.map(radioButton => <label key={ radioButton.value } className={ `RadioButtonWithValidation_radio-label ${radioButton.disabled ? 'RadioButtonWithValidation_radio-label_disabled' : ''}` } >
          <input type="radio" data-e2e={ radioButton.datae2e } disabled={ radioButton.disabled } className="RadioButtonWithValidation_radio-input" { ...input }
            value={ radioButton.value } checked={ radioButton.value === input.value }/>
          <span className={ `RadioButtonWithValidation_radio-button ${radioButton.disabled ? 'RadioButtonWithValidation_radio-button-disabled' : ''}` }></span>{radioButton.title}</label>)}
        {hasError && <div className="RadioButtonWithValidation_radio-error">{meta.error}</div>}
      </div>

    );
  }
}

RadioButtonWithValidation.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default RadioButtonWithValidation;
