import {
  STORE_FETCHED_GLOBAL_QUOTATIONS,
  CLEAR_ADDED_PRODUCT_TO_QUOTATION,
  REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_SUCCESS,
  STORE_GLOBAL_QUOTATION,
  GLOBAL_QUOTATION_QTY_CHANGE_SUCCESS,
  REMOVE_GLOBAL_QUOTATION,
  IS_QUOTE_REF_VALID,
  NO_QUOTE_FOUND_BY_REF,
  UPDATE_QUOTE_FIELD,
  LOADING_GLOBAL_QUOTE,
  FETCHING_QUOTE_BY_REF,
  QUOTE_NAME_UPDATED,
  LOCK_GLOBAL_QUOTATION,
  GLOBAL_QUOTE_PAID_ON_ACCOUNT,
  UPDATE_STOCKINFO_GLOBAL_QUOTATION_QTY_CHANGE,
  UPDATE_GLOBAL_QUOTATION_ON_QTY_CHANGE,
  LOCKING_GLOBAL_QUOTATION
} from 'client/actions/globalQuotationActions';
import { FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS } from 'client/actions/quotationsActions';
import { fromJS, List } from 'immutable';
import _find from 'lodash/find';
import _inRange from 'lodash/inRange';

const defaultState = fromJS({
  createdQuotation: {},
  isQuoteRefValid: true,
  addedProductToQuotation: {},
  quotesNotFound: {}
});

const globalQuotationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GLOBAL_QUOTE_PAID_ON_ACCOUNT:
      return state.set('isGlobalQuotePaidOnAccount', true);
    case LOCK_GLOBAL_QUOTATION:
      const existingLockedQuotes = state.get('lockedQuotes', List()).toJS();
      return state.set('lockedQuotes', fromJS([...existingLockedQuotes, action.quotationId]));
    case FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS:
      if (action.isQuoteGlobal) {
        const products = state.getIn(['createdQuotation', action.quotationId, 'products']);
        const productIndex = products ? products.findIndex((p) => p.get('sku') === action.sku) : -1;
        return productIndex === -1 ? state
          : state.setIn(['createdQuotation', action.quotationId, 'products', productIndex, 'stock'], fromJS(action.stockInfo));
      }
      return state;
    case QUOTE_NAME_UPDATED:
      return state.set('quoteNameWasUpdated', true);
    case LOADING_GLOBAL_QUOTE:
      return state.set('loadingGlobalQuote', action.payload);
    case UPDATE_QUOTE_FIELD:
      if (action.fieldName === 'quoteRefToRetrieve') {
        return state
          .set('isQuoteRefValid', true)
          .set('invalidQuoteRef', '')
          .set(action.fieldName, action.value.trim().toUpperCase());
      }
      return state
        .set('invalidQuoteRef', '')
        .set(action.fieldName, action.value.trim());
    case FETCHING_QUOTE_BY_REF:
      return state.set('isFetchinQuoteByRef', action.payload);
    case LOCKING_GLOBAL_QUOTATION:
      return state.set('isLockingGlobalQuotation', action.payload);
    case NO_QUOTE_FOUND_BY_REF:
      return state.set('quotesNotFound', fromJS({ [action.payload]: true }));
    case IS_QUOTE_REF_VALID:
      if (action.invalidQuoteRef) {
        return state
          .set('isQuoteRefValid', action.isValid)
          .set('invalidQuoteRef', action.invalidQuoteRef);
      }
      if (action.isValid) {
        return state
          .set('quoteRefToRetrieve', '')
          .set('isQuoteRefValid', action.isValid);
      }
      return state.set('isQuoteRefValid', action.isValid);
    case REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_SUCCESS:
      const currentQuotation = state.get('createdQuotation').toJS();
      const productIndexToRemove = currentQuotation.products.findIndex(p => p.sku === action.sku);
      currentQuotation.products.splice(productIndexToRemove, 1);
      return state.set('createdQuotation', fromJS(currentQuotation));
    case REMOVE_GLOBAL_QUOTATION:
      return state.set('createdQuotation', fromJS({}));
    case GLOBAL_QUOTATION_QTY_CHANGE_SUCCESS:
      const currentQuote = state.get('createdQuotation').toJS();
      const productIndex = currentQuote.products.findIndex(p => p.sku === action.sku);
      const getNewState = (newBreakPrice) => {
        return newBreakPrice ? state
          .setIn(['createdQuotation', 'products', productIndex, 'qty'], fromJS(action.qty))
          .setIn(['createdQuotation', 'products', productIndex, 'lockedPrice', 'packPriceWithVat'], fromJS(newBreakPrice.breakPriceValue))
          .setIn(['createdQuotation', 'products', productIndex, 'lockedPrice', 'packPrice'], fromJS(newBreakPrice.packPriceValue))
          .setIn(['createdQuotation', 'products', productIndex, 'lockedPrice', 'qtyDiscount'], newBreakPrice.breakDiscount)
          : state
            .setIn(['createdQuotation', 'products', productIndex, 'qty'], fromJS(action.qty));
      };
      const newBreakPrice = _find(action.breakPrices, (breakPrice) => {
        return _inRange(action.qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
      });

      return productIndex === -1
        ? state
        : getNewState(newBreakPrice);
    case STORE_GLOBAL_QUOTATION:
      if (action.globalQuotation.quoteRef) {
        return state
          .set('createdQuotation', action.globalQuotation ? fromJS(action.globalQuotation) : fromJS({}))
          .set('quotationQuoteRef', action.globalQuotation.quoteRef);
      }
      return state.set('createdQuotation', action.globalQuotation ? fromJS(action.globalQuotation) : fromJS({}));
    case STORE_FETCHED_GLOBAL_QUOTATIONS:
      return state.set('createdQuotation', fromJS(action.quotations[0]));
    case CLEAR_ADDED_PRODUCT_TO_QUOTATION:
      return state
        .set('createdQuotation', fromJS({}))
        .set('isQuoteRefValid', false)
        .set('addedProductToQuotation', fromJS({}))
        .set('quotesNotFound', fromJS({}));
    case UPDATE_STOCKINFO_GLOBAL_QUOTATION_QTY_CHANGE:
      const globalCurrentQuote = state.get('createdQuotation').toJS();
      const productIndx = globalCurrentQuote.products.findIndex(product => product.sku === action.sku);
      return state
        .setIn(['createdQuotation', 'products', productIndx, 'stock', 'stockMessage'], fromJS(action.stockInfo));
    case UPDATE_GLOBAL_QUOTATION_ON_QTY_CHANGE:
      return state
        .setIn(['createdQuotation', 'quotationPrice'], fromJS(action.quotationPrice))
        .setIn(['createdQuotation', 'orderLines'], fromJS(action.orderLines));
    default:
      return state;
  }
};

export default globalQuotationReducer;
