export const FAQHeaderLinks = [
  {
    url: '/info/zoro-account-faq',
    alt: 'Questions about your Zoro Account',
    iconName: 'account-details',
    pageName: 'Zoro Account FAQs'
  },
  {
    url: '/info/zoro-current-orders-faq',
    alt: 'Questions about currently open orders',
    iconName: 'current-order',
    pageName: 'Zoro Current Orders FAQ'
  },
  {
    url: '/info/zoro-delivery-returns-faq',
    alt: 'Questions about delivery and returns',
    iconName: 'order-return',
    pageName: 'Zoro Delivery & Returns FAQ'
  },
  {
    url: '/info/zoro-business-account-faqs',
    alt: 'Questions about Zoro Business Accounts',
    iconName: 'business-account',
    pageName: 'Zoro Business Account FAQs'
  },
  {
    url: '/info/zoro-payment-faq',
    alt: 'Questions about payment methods and options',
    iconName: 'payment',
    pageName: 'Zoro Payment FAQ'
  },
  {
    url: '/info/zoro-features-faqs',
    alt: 'Questions about Zoro Features',
    iconName: 'zoro-feature',
    pageName: 'Zoro Features FAQs'
  }
];
