/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, SubmissionError } from 'redux-form/immutable';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { fromJS } from 'immutable';
import {
  isRequiredIf,
  matches,
  minLength,
  passwordValidator
} from 'client/utils/validators';
import {
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  YES,
  USER_TYPE_BUSINESS,
  accountStatusMapper
} from '../../constants';
import { makeValidator } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import CheckBoxWithValidations from 'client/components/elements/formField/CheckboxWithValidations';
import PasswordStengthIndicator from 'client/components/elements/formField/PasswordStrengthIndicator';
import { validatePasswordChecklist } from 'client/actions/passwordChecklistAction';
import { PasswordChecklistAndSvg } from '../../individualAccountRegistration/PasswordChecklistAndSvg';
import { setView } from 'client/actions/loginAndRegistration';
import { registerCreditAccount } from 'client/actions/creditAccountActions';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';
import { COMPANY_TYPES_REQUIRING_REG_NO } from '../../../../../utils/companyDataUtils';

const privacyPolicyLink = (
  <a id="PrivacyPolicyAgreement.checkbox.PrivacyPolicyLink" href="/info/privacy-policy" target="_blank">
    { getLocalizedString('registration.label.privacyPolicy') }
  </a>
);

const termsAndConditionsLink = (
  <a id="TAndCAcceptance.checkbox.TAndCLink" href="/info/zoro-business-account-terms" target="_blank">
    { getLocalizedString('creditAccount.application.label.textTermsLink') }
  </a>
);

const termsofAccessLink = (
  <a id="TAndCAcceptance.checkbox.TAndCLink" href="/info/terms-of-access" target="_blank">
    Terms of Access
  </a>
);

const DOT = '.';
const validateFunc = (openCreditLine, csAgentName) => makeValidator({
  password: [
    // Password is not required when customer-agent session is active
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.password')
    ),
    passwordValidator({
      fieldName: getLocalizedString('registration.label.password')
    }),
    minLength({
      minLength: 8,
      fieldName: getLocalizedString('registration.label.password')
    })
  ],
  confirmPassword: [
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.confirmPassword')
    ),
    matches({
      otherField: 'password',
      fieldName: getLocalizedString('registration.label.confirmPassword'),
      otherFieldName: getLocalizedString('registration.label.password'),
      message: getLocalizedString('error.match.password')
    })
  ],
  businessTC: [
    isRequiredIf(
      () => openCreditLine === YES,
      'Please select to proceed'
    )
  ]
});

const FORM_NAME = 'businessAccountFinalStep';
const FinalStepForm = ({
  csAgentName,
  handleSubmit,
  dispatchSetView,
  primaryContactForm,
  creditLineApplicationForm,
  financeContactForm,
  additionalUsersList,
  dispatchRegisterCreditAccount,
  finalStepError,
  hideExitConfirmation
}) => {
  const [, setConfirmPasswordVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordChecklist, setPasswordChecklist] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false
  });

  const submit = (values) => {
    return validateForm(values, validateFunc(primaryContactForm?.openCreditLine, csAgentName))
      .then(() => {
        const userPayload = {
          userType: USER_TYPE_BUSINESS,
          title: primaryContactForm.title,
          firstName: primaryContactForm.firstName,
          lastName: primaryContactForm.lastName,
          password: values.get('password'),
          email: primaryContactForm.email,
          phoneNumber: primaryContactForm.phoneNumber,
          csAgentName: csAgentName ? csAgentName : '',
          marketingConsent: true,
          companyName: primaryContactForm?.companyName
        };

        dispatchRegisterCreditAccount({
          userPayload,
          primaryContactForm,
          financeContactForm,
          creditLineApplicationForm,
          additionalUsersList,
          csAgentName,
          currentPath: window?.location?.pathname || '/'
        });
        hideExitConfirmation();
      }).catch((errors) => {
        hideExitConfirmation();
        throw new SubmissionError(errors);
      });
  };

  const updatePasswordChecklist = (password) => {
    setPasswordVal(password);
    const checklist = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*()+.,£-]/.test(password)
    };

    setPasswordChecklist(checklist);
    if (
      checklist.length &&
      checklist.uppercase &&
      checklist.lowercase &&
      checklist.digit &&
      checklist.specialChar
    ) {
      setPasswordStrength('strong');
    } else if (checklist.lowercase && checklist.uppercase) {
      setPasswordStrength('average');
    } else {
      setPasswordStrength('weak');
    }
  };

  const {
    phoneNumber = '',
    email = '',
    title = '',
    firstName = '',
    jobTitle = '',
    lastName = '',
    companyName = '',
    openCreditLine,
    additionalUsers
  } = primaryContactForm;

  const {
    companyType,
    primaryIndustry,
    noOfStaff,
    vatRegNo,
    companyRegNo,
    primary_addressLine1,
    primary_city,
    primary_postalCode,
    financeContact,
    expectedMonthlySpend
  } = creditLineApplicationForm;

  const {
    sameAsPrimaryContact,
    sameAsRegisteredAddress,
    firstName: invoiceFirstName,
    lastName: invoiceLastName,
    email: invoiceEmail,
    phoneNumber: invoicePhoneNumber,
    primary_addressLine1: invoiceAddressLine1,
    primary_city: invoiceCity,
    primary_postalCode: invoicePostalCode,
    invoiceCompanyName
  } = financeContactForm;

  const firstNameInvoice = sameAsPrimaryContact ? firstName : invoiceFirstName;
  const lastNameInvoice = sameAsPrimaryContact ? lastName : invoiceLastName;
  const emailInvoice = sameAsPrimaryContact ? email : invoiceEmail;
  const phoneNumberInvoice = sameAsPrimaryContact ? phoneNumber : invoicePhoneNumber;
  const addressLine1Invoice = sameAsRegisteredAddress ? primary_addressLine1 : invoiceAddressLine1;
  const addressCityInvoice = sameAsRegisteredAddress ? primary_city : invoiceCity;
  const addressPostCodeInvoice = sameAsRegisteredAddress ? primary_postalCode : invoicePostalCode;
  const additionalUsersListFiltered = additionalUsersList.filter(el => !el.isFinanceUser);
  const showCompanyRegNo = companyRegNo && COMPANY_TYPES_REQUIRING_REG_NO.includes(companyType);
  const backView = additionalUsers === YES
    ? CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW
    : financeContact === YES && openCreditLine === YES
      ? CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW
      : openCreditLine === YES
        ? CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW : CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW;

  return (<div className="BusinessAccountRegistrationForm_finalStep">
    <div className="col-xs-12 col-md-6">
      <div className="EmailAndRegistration">
        <h1 className="EmailAndRegistration_finalStep">Final Step</h1>
        <form onSubmit={ handleSubmit(submit) }>
          { csAgentName
            ? <div className="EmailAndRegistration_getStartedTitle">{ getLocalizedString('login.csAgentNameLogin.text') }</div>
            : <React.Fragment>
              <div className="EmailAndRegistration_getStartedInput">
                <Field
                  id="password"
                  name="password"
                  placeholderResourceName="login.password.placeholder"
                  className={ `form-control password focus-shadow-none:focus ${passwordStrength === 'weak' ? 'border-weak weak-pass' : passwordStrength === 'average' ? 'border-average average-pass' : passwordStrength === 'strong' ? 'border-success success-pass' : ''}` }
                  labelClassName="control-label required"
                  type="password"
                  labelResourceName="login.createAPassword.placeholder"
                  component={ PasswordStengthIndicator }
                  formClassName='password'
                  datae2e="password"
                  updatePasswordChecklist={ updatePasswordChecklist }
                  passwordStrength={ passwordStrength }
                  validatePasswordChecklist={ validatePasswordChecklist }
                />
              </div>
              <div className="EmailAndRegistration_getStartedInput">
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholderResourceName="login.confirmPassword.placeholder"
                  className="form-control confirmPassword"
                  labelClassName="control-label required"
                  type="password"
                  labelResourceName="login.confirmPassword.placeholder"
                  component={ FieldWithValidations }
                  datae2e="confirm-password"
                  onChange= { (e) => setConfirmPasswordVal(e.target.value) }
                />
              </div>
              <h3 className="passContain">{ getLocalizedString('your.password.must.contain') }</h3>
              <div className="passwordChecklist passwordChecklistMobile">
                <div className="passwordChecklistContent_1 fontWeightNormal">
                  {
                    <PasswordChecklistAndSvg condition={ passwordChecklist.length } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.length') } />
                  }
                  {
                    <PasswordChecklistAndSvg condition={ passwordChecklist.lowercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.lowercase') } />
                  }
                  {
                    <PasswordChecklistAndSvg condition={ passwordChecklist.uppercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.uppercase') }/>
                  }
                </div>

                <div className="passwordChecklistContent_2">
                  {
                    <PasswordChecklistAndSvg condition={ passwordChecklist.digit } passwordVal={ passwordVal } name={ getLocalizedString('password.checklist.digit') }/>
                  }
                  {
                    <PasswordChecklistAndSvg condition={ passwordChecklist.specialChar } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.specialChar') } />
                  }
                </div>

              </div>
            </React.Fragment>
          }
          { openCreditLine === YES ? <Field type="checkbox"
            name="businessTC"
            component={ CheckBoxWithValidations }
            datae2e="businessTC"
            textAndLink={
              <div className="BusinessAccountRegistrationForm_termsAndConditionsLink">
              I accept the Business Credit Line Account {termsAndConditionsLink} <br/>
              </div>
            } /> : null }
          { finalStepError ? <div className="BusinessAccountRegistrationForm_finalStep-error">{finalStepError}</div> : null }
          <div className="row">
            <div className="col-xs-12 BusinessAccountRegistrationForm_finalStep-btns">
              <div className="BusinessAccountRegistrationForm_finalStep-back">
                <SecondaryButton
                  datae2e="back"
                  className="ForgotPasswordNewForm_backToLogin"
                  onClick={ () => {
                    hideExitConfirmation();
                    dispatchSetView(backView);
                  } }
                >
                Back
                </SecondaryButton>
              </div>
              <div className="ForgotPasswordNewForm_submitBtn BusinessAccountRegistrationForm_finalStep-createAccount">
                <PrimaryButton
                  className="btn"
                  type="submit"
                  onClick={ () => {
                    hideExitConfirmation();
                  } }
                  datae2e="continue">
                Create account
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="ContactUsForm_TacLabel BusinessAccountRegistrationForm_finalStep-tAndC">
            { getLocalizedString('contactus.thank.you.message') } { termsofAccessLink }
            { DOT } { getLocalizedString('register.tAndCpart2.text') } { privacyPolicyLink } { getLocalizedString('register.tAndCpart3.text') }<br />
          </div>
        </form>
      </div>
    </div>
    <div className="hidden-xs hidden-sm col-xs-12 col-md-6 BusinessAccountRegistrationForm_overview">
      <div className="BusinessAccountRegistrationForm_overview-rightBox">
        <div className="BusinessAccountRegistrationForm_overview-mainTitle">
          Overview
        </div>
      </div>
      <div className="BusinessAccountRegistrationForm_overview-rightBox">
        <div className="BusinessAccountRegistrationForm_overview-title">
          Primary Contact Details
          <div
            className="BusinessAccountRegistrationForm_overview-editLink"
            data-e2e="editContact"
            onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW) }>
              Edit
          </div>
        </div>
        <div>{title} {firstName} {lastName}</div>
        <div>{email}</div>
        <div>{phoneNumber}</div>
        <div>{companyName}</div>
        { jobTitle ? <div>{jobTitle}</div> : null }
      </div>
      { openCreditLine === YES ? <div className="BusinessAccountRegistrationForm_overview-rightBox">
        <div className="BusinessAccountRegistrationForm_overview-title">
          Credit Line Details
          <div
            className="BusinessAccountRegistrationForm_overview-editLink"
            data-e2e="editCompany"
            onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW) }>
              Edit
          </div>
        </div>
        <div data-e2e="companyDetails" className="BusinessAccountRegistrationForm_overview-rightBox-column col-xs-12 col-md-6">
          <div>Type: {companyType}</div>
          <div>Primary Industry: {primaryIndustry}</div>
          { showCompanyRegNo ? <div>Company Reg no: {companyRegNo}</div> : null }
          <div>Number of Staff: {noOfStaff}</div>
          { vatRegNo ? <div>VAT Reg No: {vatRegNo}</div> : null }
          <div>Expected Monthly Spend (£): {expectedMonthlySpend}</div>
        </div>
        <div data-e2e="registeredAddress" className="BusinessAccountRegistrationForm_overview-rightBox-column col-xs-12 col-md-6">
          <div>Registered Address:</div>
          <div>{primary_addressLine1}</div>
          <div>{primary_city}</div>
          <div>{primary_postalCode}</div>
        </div>
      </div> : null }
      { financeContact === YES ? <div className="BusinessAccountRegistrationForm_overview-rightBox">
        <div data-e2e="invoiceDetails" className="BusinessAccountRegistrationForm_overview-title">
          Invoice Details
          <div
            className="BusinessAccountRegistrationForm_overview-editLink"
            data-e2e="editInvoice"
            onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW) }>
              Edit
          </div>
        </div>
        <div className="BusinessAccountRegistrationForm_overview-rightBox-column col-xs-12 col-md-6">
          <div>{firstNameInvoice} {lastNameInvoice}</div>
          <div>{emailInvoice}</div>
          <div>{phoneNumberInvoice}</div>
        </div>
        <div className="BusinessAccountRegistrationForm_overview-rightBox-column col-xs-12 col-md-6">
          <div>Invoice Address:</div>
          <div>{invoiceCompanyName}</div>
          <div>{addressLine1Invoice}</div>
          <div>{addressCityInvoice}</div>
          <div>{addressPostCodeInvoice}</div>
        </div>
      </div> : null }
      {
        additionalUsers === YES && additionalUsersListFiltered?.length ? <div className="BusinessAccountRegistrationForm_overview-rightBox">
          <div className="BusinessAccountRegistrationForm_overview-title">
          Users Details
            <div
              className="BusinessAccountRegistrationForm_overview-editLink"
              data-e2e="editUsers"
              onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW) }>
              Edit
            </div>
          </div>
          <div data-e2e="additionalUsers" className="BusinessAccountRegistrationForm_overview-rightBox-column col-xs-12">
            {
              additionalUsersListFiltered?.length
                ? additionalUsersListFiltered.map((user) => (<div key={ user.email }>{user.email} | Role: {accountStatusMapper[user.role]}</div>))
                : <div>No additional users added</div>
            }
          </div>
        </div> : null
      }
    </div>
  </div>);
};

const mapStateToProps = (state) => {
  return {
    financeContactForm: (getFormValues('financeContactForm')(state) || fromJS({})).toJS(),
    creditLineApplicationForm: (getFormValues('creditLineApplicationForm')(state) || fromJS({})).toJS(),
    primaryContactForm: (getFormValues('primaryContactForm')(state) || fromJS({})).toJS(),
    passwordValidationChecklist: state.getIn(['passwordChecklist']),
    additionalUsersList: (state.getIn(['loginAndRegistration', 'additionalUsersList'], fromJS([])) || fromJS([])).toJS(),
    finalStepError: state.getIn(['loginAndRegistration', 'finalStepError'], ''),
    csAgentName: state.getIn(['customerService', 'csAgentName'])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validatePasswordChecklist: () => dispatch(validatePasswordChecklist()),
    dispatchSetView: (view) => dispatch(setView({ view })),
    dispatchRegisterCreditAccount: (body) => dispatch(registerCreditAccount(body)),
    hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog())
  };
};

const ReduxFinalStepForm = reduxForm({
  form: FORM_NAME,
  validate: validateFunc(),
  destroyOnUnmount: true
})(FinalStepForm);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFinalStepForm);
