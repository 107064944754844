import { createAction } from 'redux-actions';

export const LOADING_GLOBAL_QUOTE = 'LOADING_GLOBAL_QUOTE';
export const loadinGlobalQuote = createAction(LOADING_GLOBAL_QUOTE);

export const GLOBAL_QUOTE_PAID_ON_ACCOUNT = 'GLOBAL_QUOTE_PAID_ON_ACCOUNT';
export const isGlobalQuotePaidOnAccount = createAction(GLOBAL_QUOTE_PAID_ON_ACCOUNT);

export const ADD_TO_QUOTE = 'ADD_TO_QUOTE';
export const addToQuote = (product, redirectToGlobalQuotation) => ({
  type: ADD_TO_QUOTE,
  product,
  redirectToGlobalQuotation
});

export const SAVE_QUOTE_TO_MY_LIST = 'SAVE_QUOTE_TO_MY_LIST';
export const saveQuoteToMylist = (name, products) => ({
  type: SAVE_QUOTE_TO_MY_LIST,
  name,
  products
});

export const STORE_GLOBAL_QUOTATION = 'STORE_GLOBAL_QUOTATION';
export const storeGlobalQuotation = (globalQuotation) => ({
  type: STORE_GLOBAL_QUOTATION,
  globalQuotation
});

export const REMOVE_GLOBAL_QUOTATION = 'REMOVE_GLOBAL_QUOTATION';
export const removeGlobalQuotation = createAction(REMOVE_GLOBAL_QUOTATION);

export const STORE_FETCHED_GLOBAL_QUOTATIONS = 'STORE_FETCHED_GLOBAL_QUOTATIONS';
export const storeFetchedGlobalQuotations = (quotations) => ({
  type: STORE_FETCHED_GLOBAL_QUOTATIONS,
  quotations
});

export const GET_PRODUCT_BY_SKU = 'GET_PRODUCT_BY_SKU';
export const getProductBySku = (sku, quantity) => ({
  type: GET_PRODUCT_BY_SKU,
  sku,
  quantity
});

export const REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_SUCCESS = 'REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_SUCCESS';
export const removeProductFromGlobalQuotationSuccess = (quotationId, sku) => ({
  type: REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_SUCCESS,
  quotationId,
  sku
});

export const IS_QUOTE_REF_VALID = 'IS_QUOTE_REF_VALID';
export const isQuoteRefValid = (isValid, invalidQuoteRef) => ({
  type: IS_QUOTE_REF_VALID,
  isValid,
  invalidQuoteRef
});

export const RETRIEVE_GLOBAL_QUOTATION = 'RETRIEVE_GLOBAL_QUOTATION';
export const retrieveGlobalQuotation = (quoteRef) => ({
  type: RETRIEVE_GLOBAL_QUOTATION,
  quoteRef
});

export const REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_CONFIRM = 'REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_CONFIRM';
export const removeProductFromGlobalQuotationConfirm = (quotationId, product, delQuotation) => ({
  type: REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_CONFIRM,
  quotationId,
  product,
  delQuotation
});

export const GLOBAL_QUOTATION_QTY_CHANGE = 'GLOBAL_QUOTATION_QTY_CHANGE';
export const changeGlobalQuotationQty = (prevQty, quotationId, sku, qty, breakPrices) => ({
  type: GLOBAL_QUOTATION_QTY_CHANGE,
  quotationId,
  prevQty,
  qty,
  sku,
  breakPrices: breakPrices.toJS()
});

export const GLOBAL_QUOTATION_QTY_CHANGE_SUCCESS = 'GLOBAL_QUOTATION_QTY_CHANGE_SUCCESS';
export const updateGlobalQuotationProductQtySuccess = (quotationId, sku, qty, breakPrices) => ({
  type: GLOBAL_QUOTATION_QTY_CHANGE_SUCCESS,
  quotationId,
  sku,
  qty,
  breakPrices
});

export const LOCK_GLOBAL_QUOTATION = 'LOCK_GLOBAL_QUOTATION';
export const lockGlobalQuotation = (quotationId, isLockingQuote, isLoggedInOrhasValidLongSessionToken) => ({
  type: LOCK_GLOBAL_QUOTATION,
  quotationId,
  isLockingQuote,
  isLoggedInOrhasValidLongSessionToken
});

export const FETCH_GLOBAL_QUOTATION_BY_ID_OR_QUOTE_REF = 'FETCH_GLOBAL_QUOTATION_BY_ID_OR_QUOTE_REF';
export const fetchGlobalQuotationByIdOrQuoteRef = (quoteRefOrId, redirectToGlobalQuotation = true, eligibleForDeliveryOptions) => {
  return {
    type: FETCH_GLOBAL_QUOTATION_BY_ID_OR_QUOTE_REF,
    quoteRefOrId,
    redirectToGlobalQuotation,
    eligibleForDeliveryOptions
  };
};

export const FETCH_GLOBAL_QUOTE_BY_ID = 'FETCH_GLOBAL_QUOTE_BY_ID';
export const fetchGlobalQuotationById = createAction(FETCH_GLOBAL_QUOTE_BY_ID);

export const CLEAR_ADDED_PRODUCT_TO_QUOTATION = 'CLEAR_ADDED_PRODUCT_TO_QUOTATION';
export const clearAddedProductToQuotation = createAction(CLEAR_ADDED_PRODUCT_TO_QUOTATION);

export const UPDATE_QUOTE_FIELD = 'UPDATE_QUOTE_FIELD';
export const updateField = (value, fieldName) => ({
  type: UPDATE_QUOTE_FIELD,
  value,
  fieldName
});

export const NO_QUOTE_FOUND_BY_REF = 'NO_QUOTE_FOUND_BY_REF';
export const noQuoteFoundByRef = createAction(NO_QUOTE_FOUND_BY_REF);

export const FETCHING_QUOTE_BY_REF = 'FETCHING_QUOTE_BY_REF';
export const fetchinQuoteByRef = createAction(FETCHING_QUOTE_BY_REF);

export const LOCKING_GLOBAL_QUOTATION = 'LOCKING_GLOBAL_QUOTATION';
export const lockingGlobalQuotation = createAction(LOCKING_GLOBAL_QUOTATION);

export const FETCH_GLOBAL_QUOTATION = 'FETCH_GLOBAL_QUOTATION';
export const fetchGlobalQuotation = createAction(FETCH_GLOBAL_QUOTATION);

export const MERGE_GLOBAL_QUOTATION = 'MERGE_GLOBAL_QUOTATION';
export const mergeGlobalQuotes = (quotationId) => ({
  type: MERGE_GLOBAL_QUOTATION,
  quotationId
});

export const UPDATE_GLOBAL_QUOTE_NAME = 'UPDATE_GLOBAL_QUOTE_NAME';
export const updateQuoteName = (name, quoteRef) => ({
  type: UPDATE_GLOBAL_QUOTE_NAME,
  name,
  quoteRef
});

export const QUOTE_NAME_UPDATED = 'QUOTE_NAME_UPDATED';
export const quoteNameWasUpdated = createAction(QUOTE_NAME_UPDATED);

export const INIT_GLOBAL_QUOTATIONS_DETAILS = 'globalQuotationsActions.INIT_GLOBAL_QUOTATIONS_DETAILS';
export const initGlobalQuotationsDetails = (quoteRefOrId, redirectToGlobalQuotation, eligibleForDeliveryOptions) => (
  {
    type: INIT_GLOBAL_QUOTATIONS_DETAILS,
    quoteRefOrId,
    redirectToGlobalQuotation,
    eligibleForDeliveryOptions
  }
);

export const CHANGE_DELIVERY_OPTION_FROM_GLOBAL_QUOTATION = 'globalQuotationsActions.CHANGE_DELIVERY_OPTION_FROM_GLOBAL_QUOTATION';
export const changeDeliveryOptionFromGlobalQuotation = (payload, redirectToGlobalQuotation, quotationId) => ({
  type: CHANGE_DELIVERY_OPTION_FROM_GLOBAL_QUOTATION,
  payload,
  redirectToGlobalQuotation,
  quotationId
});

export const UPDATE_STOCKINFO_GLOBAL_QUOTATION_QTY_CHANGE = 'UPDATE_STOCKINFO_GLOBAL_QUOTATION_QTY_CHANGE';
export const updateGlobalQuotationstock = (quotationId, sku, stockInfo) => ({
  type: UPDATE_STOCKINFO_GLOBAL_QUOTATION_QTY_CHANGE,
  quotationId,
  sku,
  stockInfo
});

export const UPDATE_GLOBAL_QUOTATION_ON_QTY_CHANGE = 'UPDATE_GLOBAL_QUOTATION_ON_QTY_CHANGE';
export const updateGlobalQuotation = (quotationId, quotationPrice, orderLines) => ({
  type: UPDATE_GLOBAL_QUOTATION_ON_QTY_CHANGE,
  quotationId,
  quotationPrice,
  orderLines
});

export const ADD_QUOTE_LINE_TO_BASKET = 'ADD_QUOTE_LINE_TO_BASKET';
export const addQuoteLineToBasket = (quotationId) => ({
  type: ADD_QUOTE_LINE_TO_BASKET,
  quotationId
});
