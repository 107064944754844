import { createAction } from 'redux-actions';

export const POST_ORDER_SUCCESS = 'orderReviewActions.POST_ORDER_SUCCESS';
export const postOrderSuccess = createAction(POST_ORDER_SUCCESS);

export const POST_ORDER_FAILED = 'orderReviewActions.POST_ORDER_FAILED';
export const postOrderFailed = createAction(POST_ORDER_FAILED);

export const RESET_ORDER_REVIEW_STATE = 'orderReviewActions.RESET_ORDER_REVIEW_STATE';
export const resetOrderReviewState = createAction(RESET_ORDER_REVIEW_STATE);

// Delivery Options
export const INIT_ORDER_REVIEW = 'orderReviewActions.INIT_ORDER_REVIEW';
export const initOrderReview = createAction(INIT_ORDER_REVIEW);

export const INIT_LOAD_CART = 'orderReviewActions.INIT_LOAD_CART';
export const initLoadCart = createAction(INIT_LOAD_CART);

export const TOGGLE_DELIVERY_OPTION_ACCORDION = 'orderReviewActions.TOGGLE_DELIVERY_OPTION_ACCORDION';
export const toggleDeliveryOptionAccordion = createAction(TOGGLE_DELIVERY_OPTION_ACCORDION);

export const CHANGE_DELIVERY_OPTION_INIT = 'orderReviewActions.CHANGE_DELIVERY_OPTION_INIT';
export const changeOptionSelectionInit = createAction(CHANGE_DELIVERY_OPTION_INIT);

export const PRODUCT_LINE_UPDATING_OPTION = 'PRODUCT_LINE_UPDATING_OPTION';
export const updatingOptionLoader = createAction(PRODUCT_LINE_UPDATING_OPTION);

export const TOGGLE_TERMS_OF_SALES = 'TOGGLE_TERMS_OF_SALES';
export const toggleTermsOfSales = createAction(TOGGLE_TERMS_OF_SALES);
