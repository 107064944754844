import React from 'react';
import { CmsOfferItem as CmsOfferItems } from 'client/components/elements/cms/cmsOffersPanel/CmsOffersPanel';

export class CmsSkusCarousel extends React.Component {
  fetchSkusData () {
    const data = this.props.data;
    const skusRaw = data ? data.get('skus') : [];
    // dont ask api for more that 30 skus
    const skus = skusRaw.slice(0, 30);
    const skusString = skus.join(',');
    if (skus) {
      this.props.dispatchRequestSkusData(
        skusString,
        skus
      );
    }
  }

  componentDidMount () {
    this.fetchSkusData();
  }

  render () {
    const data = this.props.data;

    const skusRaw = data.get('skus');
    // dont ask api for more that 30 skus
    const skus = skusRaw.slice(0, 30);
    const skusString = skus.join(',');

    const title = data.get('title');
    const seeMoreUrl = data.get('seeMoreUrl');
    const skusData = this.props.skusData ? this.props.skusData.get(skusString) : null;

    return (
      <CmsOfferItems items={ skusData ? skusData.toJS() : null } title={ title } seeMoreUrl={ seeMoreUrl } />
    );
  }
}

export default CmsSkusCarousel;
