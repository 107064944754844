import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from 'client/utils/priceUtils';
import { fromJS } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { quotationCalculator } from 'client/utils/quotationUtils';
import BasketItemsDetails from 'client/components/elements/basketItemsDetails/BasketItemsDetails';
import { FREE_DELIVERY_LOYALTY_TIERS } from 'shared/constants/common';

if (process.browser) {
  require('./QuotationTotalizer.scss');
}

const TotalRow = ({ label, rawValue, value, className, e2e }) => (
  <Row className={ className }>
    <Col xs={ 8 }>
      { label }
    </Col>
    <Col xs={ 4 } className="text-right" data-e2e={ e2e }>
      <div key={ rawValue }>{ value }</div>
    </Col>
  </Row>
);

const QuotationTotalizer = ({ quotationDetails, includeVat, cta, loyaltyTier, isFarFlungDelivery }) => {
  const { currency } = quotationCalculator(quotationDetails);
  const isFreeShipping = FREE_DELIVERY_LOYALTY_TIERS.includes(loyaltyTier) && !isFarFlungDelivery;
  const { cartOrderLinesTotalWithoutVat, totalShippingPriceWithVat, cartGrandTotalVat,
    totalShippingPriceWithoutVat, cartOrderLinesTotalWithVat, additionalShippingTotalWithoutVat,
    totalSavingsWithVat, totalSavingsWithoutVat, hideTotalSavings, cartGrandTotalWithVat,
    cartGrandTotalWithoutVat } = quotationDetails.get('quotationPrice', fromJS({})).toJS();
  const totalGoodsRawValue = includeVat ? cartOrderLinesTotalWithVat : cartOrderLinesTotalWithoutVat;
  const totalGoodsWithCurrency = formatPrice(currency, totalGoodsRawValue);
  const deliveryExVat = isFreeShipping ? 0 : totalShippingPriceWithoutVat + additionalShippingTotalWithoutVat;
  const deliveryRawValue = isFreeShipping ? 0 : includeVat ? totalShippingPriceWithVat : deliveryExVat;
  const deliveryRawWithCurrency = formatPrice(currency, deliveryRawValue);

  const totalSavingsRawValue = includeVat ? totalSavingsWithVat : totalSavingsWithoutVat;
  const totalSavingsCurrency = totalSavingsRawValue ? formatPrice(currency, totalSavingsRawValue) : 0;
  const vatWithCurrency = formatPrice(currency, cartGrandTotalVat);
  const grandTotalRawValue = isFreeShipping && includeVat ? cartGrandTotalWithVat - totalShippingPriceWithVat : isFreeShipping && !includeVat ? cartGrandTotalWithoutVat - totalShippingPriceWithoutVat : includeVat ? cartGrandTotalWithVat : cartGrandTotalWithoutVat;
  const grandTotalWithCurreny = formatPrice(currency, grandTotalRawValue);
  const quotationProducts = quotationDetails.get('orderLines', fromJS([])).toJS();

  return (
    <div className="QuotationTotalizer">
      <div className="QuotationTotalizer_Header">
        <h3 className="QuotationTotalizer_Title">{ getLocalizedString('myAccount.quotations.label.quotationTotal') }</h3>
      </div>
      <BasketItemsDetails items={ quotationProducts } totalizer="quotation" includeVat={ includeVat } currency={ currency } />
      <div className="QuotationTotalizer_RowContainer">
        <TotalRow
          label={ getLocalizedString('cart.cartTotalizer.fieldLabels.totalGoods') }
          rawValue={ totalGoodsRawValue }
          value={ `${totalGoodsWithCurrency.symbol}${totalGoodsWithCurrency.amount}` }
          className="QuotationTotalizer_Row QuotationTotalizer_totalGood"
        />
        <TotalRow
          label={ getLocalizedString('cart.cartTotalizer.fieldLabels.delivery') }
          rawValue={ deliveryRawValue }
          value={ `${deliveryRawWithCurrency.symbol}${deliveryRawWithCurrency.amount}` }
          className="QuotationTotalizer_Row QuotationTotalizer_delivery"
          e2e="deliveryGross"
        />
      </div>
      {
        (parseFloat(totalSavingsCurrency.amount) > 0) && !hideTotalSavings &&
      (
        <div className="QuotationTotalizer_RowContainer">
          <Row className="QuotationTotalizer_DiscountContainer">
            <Col xs={ 8 }>
              { getLocalizedString('cart.cartTotalizer.fieldLabels.totalSavings') }
            </Col>
            <Col xs={ 4 } className="text-right" data-e2e="totalSaving">
              { `${totalSavingsCurrency.symbol}${totalSavingsCurrency.amount}` }
            </Col>
          </Row>
        </div>
      )
      }
      {
        !includeVat
          ? <TotalRow
            className="QuotationTotalizer_Row"
            e2e="vat"
            label={ getLocalizedString('cart.cartTotalizer.fieldLabels.vat') }
            value={ `${vatWithCurrency.symbol}${vatWithCurrency.amount}` }
          /> : null
      }
      <TotalRow
        className="QuotationTotalizer_Row-Strong QuotationTotalizer_Row-ExtraBottomMargin"
        e2e="grandTotalGross"
        label={ getLocalizedString('cart.cartTotalizer.fieldLabels.grandTotalGross') }
        value={ `${grandTotalWithCurreny.symbol}${grandTotalWithCurreny.amount}` }
      />
      {
        cta
          ? <Row>
            <Col xs={ 12 }>
              <div className="small-12 columns CartTotalizer_makePaymentButton">
                { cta }
              </div>
            </Col>
          </Row> : null
      }
    </div>

  );
};

QuotationTotalizer.propTypes = {
  quotationDetails: PropTypes.object
};

export default QuotationTotalizer;
