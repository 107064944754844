import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { CvvField } from './cvv';
import {
  getCvvHostedFieldConfig,
  getHostedFieldStylesConfig
} from 'client/utils/braintreeUtils';
import {
  createCvvHostedField,
  generateCvvNonce,
  setCvvVerificationCancel
} from 'client/actions/braintreeActions';

import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms/formButtons';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
if (process.browser) {
  require('./BraintreeCvvCheck.scss');
}
const fieldConfig = getCvvHostedFieldConfig();
const styleConfig = getHostedFieldStylesConfig();

class BraintreeCvvCheck extends React.PureComponent {
  componentDidMount () {
    this.props.onCreateCvvHostedField({ styleConfig, fieldConfig });
  }

  render () {
    const {
      cardCvv,
      onGenerateCvvNonce,
      onCancelCvvVerification
    } = this.props;

    const { isValid: isCvvValid, isEmpty: isCvvEmpty } = cardCvv;

    const errorMessage = (!isCvvValid && isCvvEmpty)
      ? getLocalizedString('payment.security.code.required.message')
      : (!isCvvValid && !isCvvEmpty) ? getLocalizedString('payment.security.code.Invalid.message') : '';

    return <div className="BraintreeCvvCheck">
      <form autoComplete="off">
        <div className="form-group row BraintreeCvvCheck_cvvWrapper">
          <div className="BraintreeCvvCheck_cvvWrapper-cvv">
            <CvvField isCvvValid={ isCvvValid } error={ errorMessage } cvvId='cc-auth-cvv' />
          </div>
        </div>
        <div className="row BraintreeCvvCheck_actions">
          <div className="col-md-2 BraintreeCvvCheck_actions-cancel" >
            <SecondaryButton
              text={ getLocalizedString('braintreeCvvCheckDialog.action.cancel') }
              onClick={ () => onCancelCvvVerification() }/>
          </div>
          <div className="col-md-2" >
            <PrimaryButton
              text={ getLocalizedString('braintreeCvvCheckDialog.action.confirm') }
              onClick={ () => onGenerateCvvNonce({ selector: CHECKOUT_SELECTOR }) }/>
          </div>
        </div>
      </form>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    cvvHostedFieldInstance: state.getIn(['braintreePayment', 'cvvHostedFieldInstance']),
    cardCvv: state.getIn(['braintreePayment', 'verifyCvv'], fromJS({})).toJS()
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateCvvHostedField: (payload) => dispatch(createCvvHostedField(payload)),
  onGenerateCvvNonce: (payload) => dispatch(generateCvvNonce(payload)),
  onCancelCvvVerification: () => dispatch(setCvvVerificationCancel())
});

export default connect(mapStateToProps, mapDispatchToProps)(BraintreeCvvCheck);
export { BraintreeCvvCheck };
