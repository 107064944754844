import {
  RECIEVE_REGISTER_USER_SUCCESS,
  RECIEVE_LOGIN_USER_SUCCESS,
  UPDATE_IS_LOGIN_STATE
} from 'client/actions/userActions';

const auth = (state, action) => {
  switch (action.type) {
    case RECIEVE_LOGIN_USER_SUCCESS:
    case RECIEVE_REGISTER_USER_SUCCESS:
      return state
        .set('jwtToken', action.tokens.token)
        .set('longSessionJwtToken', action.tokens.longSessionToken)
        .set('isLoggedIn', true)
        .set('hasValidLongSessionToken', true)
        .set('hasActiveTradeAccount', action.tokens.hasActiveTradeAccount)
        .set('tradeAccountIsValidForStatement', action.tokens.tradeAccountIsValidForStatement || false)
        .set('tradeAccountIsValid', action.tokens.tradeAccountIsValid || false);
    case 'UPDATE_JWT_TOKEN':
      return action.tokens.longSessionToken
        ? state
          .set('jwtToken', action.tokens.token)
          .set('longSessionJwtToken', action.tokens.longSessionToken)
          .set('hasActiveTradeAccount', action.tokens.hasActiveTradeAccount)
          .set('tradeAccountIsValidForStatement', action.tokens.tradeAccountIsValidForStatement || false)
          .set('tradeAccountIsValid', action.tokens.tradeAccountIsValid || false)
        : state
          .set('jwtToken', action.tokens.token);
    case 'UPDATE_LONG_SESSION_STATUS':
      return state
        .set('hasValidLongSessionToken', true);
    case UPDATE_IS_LOGIN_STATE:
      return state
        .set('isLoggedIn', false);
    default:
      return state;
  }
};

export default auth;
