import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./NonRefundableWarning.scss');
}
const PARISHABLE_AND_MTO = [1, 2];
const HYGIENE_AND_ELECTRONICS = [3, 4];

const NonRefundableWarning = ({ nonRefundableType }) => {
  return (
    <div className='nonRefundableWarning'>
      <SvgLoader xlinkHref="#info-icons" className='nonRefundableIcon'/>
      <span> {
        PARISHABLE_AND_MTO.includes(nonRefundableType)
          ? getLocalizedString('nonRefundableType.warning.message')
          : HYGIENE_AND_ELECTRONICS.includes(nonRefundableType)
            ? getLocalizedString('nonRefundableType.originalpacking.warning.message')
            : null
      }</span>
    </div>
  );
};

export default NonRefundableWarning;
