import { createAction } from 'redux-actions';

export const TERMINATE_CUSTOMER_SERVICE_AGENT_SESSION = 'cookieActions.TERMINATE_CUSTOMER_SERVICE_AGENT_SESSION';

export const terminateCustomerServiceAgentSession = () => ({
  type: TERMINATE_CUSTOMER_SERVICE_AGENT_SESSION
});

export const SEND_CJEVENT_TO_DATALAYER = 'cookieActions.SEND_CJEVENT_TO_DATALAYER';
export const sendCJEventToDataLayer = createAction(SEND_CJEVENT_TO_DATALAYER);

export const SET_COOKIE_CJEVENT = 'cookieActions.SET_COOKIE_CJEVENT';
export const setCookieCJEvent = createAction(SET_COOKIE_CJEVENT);

export const SEND_AWC_TO_DATALAYER = 'cookieActions.SEND_AWC_TO_DATALAYER';
export const sendAWCToDataLayer = createAction(SEND_AWC_TO_DATALAYER);

export const SET_COOKIE_AWC = 'cookieActions.SET_COOKIE_AWC';
export const setCookieAWC = createAction(SET_COOKIE_AWC);

export const UPDATE_ONETRUST_CONSENT = 'UPDATE_ONETRUST_CONSENT';
export const updateOneTrustConsent = createAction(UPDATE_ONETRUST_CONSENT);

export const GET_COOKIE_USER_PREFERENCES = 'cookieActions.GET_COOKIE_USER_PREFERENCES';
export const getCookieUserPreferences = createAction(GET_COOKIE_USER_PREFERENCES);

export const SET_COOKIE_USER_PREFERENCES = 'cookieActions.SET_COOKIE_USER_PREFERENCES';
export const setCookieUserPreferences = createAction(SET_COOKIE_USER_PREFERENCES);
