import { fromJS } from 'immutable';
import {
  UPDATE_ACCOUNT_USERS,
  RESET_FORM_FIELDS,
  IS_USER_REGISTERED,
  UPDATE_ACCOUNT_USER_FORM,
  CHECK_EMAIL_FIELD_VALIDITY,
  REFRESH_ACCOUNT_USERS_SUCCESS
} from 'client/actions/myAccountUsersActions';
import { CREDIT_APPLICATION_FORM_ADD_USERS, CREDIT_APPLICATION_FORM_REMOVE_MULTI_USER } from 'client/actions/creditAccountActions';
import {
  removeEmailDuplicates,
  getAccountUsersList,
  validateFields,
  checkEmailFieldValidity,
  areEmailsDuplicated
} from 'shared/utils/reducerUtils';

const accountUserForm = {
  email: { value: '', valid: true, tooManyEmailAddresses: false, duplicatedEmails: false },
  shortMessage: { value: '', valid: true },
  accountRole: { value: '', valid: true },
  monthlyLimit: { value: '', valid: true }
};
const defaultState = fromJS({
  accountUserList: [],
  accountUserForm,
  creditAccountApplicationUsersList: []
});

const myAccountUsers = (state = defaultState, action) => {
  switch (action.type) {
    case CREDIT_APPLICATION_FORM_REMOVE_MULTI_USER:
      const existingUsersList = state.get('creditAccountApplicationUsersList') && state.get('creditAccountApplicationUsersList').toJS();
      const filteredUsersList = existingUsersList.filter((user) => user.colleaguesEmail !== action.email);
      return state.set('creditAccountApplicationUsersList', fromJS(filteredUsersList));
    case CREDIT_APPLICATION_FORM_ADD_USERS:
      const { colleaguesEmail, shortMessage, accountRole, spendLimit } = action;
      const newUser = { colleaguesEmail, shortMessage, accountRole, spendLimit };
      const updatedUsersList = [...removeEmailDuplicates(state.get('creditAccountApplicationUsersList').toJS(), colleaguesEmail), newUser];
      return state.set('creditAccountApplicationUsersList', fromJS(updatedUsersList));
    case REFRESH_ACCOUNT_USERS_SUCCESS:
      return state.set(
        'accountUserList',
        fromJS(action.usersList)
      );
    case UPDATE_ACCOUNT_USERS:
      return state.set(
        'accountUserList',
        fromJS(getAccountUsersList(state.get('accountUserList'), action.currentUser, action.key, action.value))
      );
    case UPDATE_ACCOUNT_USER_FORM:
      return state.setIn(
        ['accountUserForm', action.field.toString()],
        fromJS({
          value: action.field === 'email' ? action.value.trim() : action.value,
          valid: validateFields(action.field, action.value)
        })
      );
    case IS_USER_REGISTERED:
      return state.setIn(
        ['accountUserForm', 'email', 'valid'],
        action.flag
      );
    case CHECK_EMAIL_FIELD_VALIDITY:
      const emailValues = state.getIn(['accountUserForm', 'email', 'value']);
      const emailList = emailValues.split(',');
      const MAX_EMAIL_ADDRESSES_ALLOWED = 50;

      return state
        .setIn(['accountUserForm', 'email', 'valid'], checkEmailFieldValidity(emailList))
        .setIn(['accountUserForm', 'email', 'tooManyEmails'], emailList.length > MAX_EMAIL_ADDRESSES_ALLOWED)
        .setIn(['accountUserForm', 'email', 'duplicatedEmails'], areEmailsDuplicated(emailList));
    case RESET_FORM_FIELDS:
      return state.set(
        'accountUserForm',
        fromJS({ ...accountUserForm })
      );
    default:
      return state;
  }
};

export default myAccountUsers;
