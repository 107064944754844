import { take, all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getClientToken, getPaymentMethods, saveCard } from 'shared/endpoints/braintreeEndpoint';
import {
  BRAINTREE_CLIENT_TOKEN_SUCCESS,
  braintreeClientTokenSuccess,
  GET_EXISTING_CARDS,
  INITIALIZE_HOSTED_FIELDS,
  SET_BRAINTREE_3D_INSTANCE, SET_BRAINTREE_3D_INSTANCE_SUCCESS, SET_BRAINTREE_CLIENT_TOKEN,
  SET_BRAINTREE_DATA_COLLECTOR_INSTANCE, SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS,
  SET_BRAINTREE_PAYPAL_INSTANCE, SET_BRAINTREE_APPLE_PAY_INSTANCE, setBraintree3dInstance,
  setBraintree3dInstanceSuccess, setBraintreeClientToken, setBraintreeDataCollectorInstance,
  setBraintreeDataCollectorInstanceSuccess,
  setBraintreeHostedFieldsInstanceSuccess,
  setBraintreePaypalInstanceSuccess,
  setExistingCards,
  setBraintreeApplePayInstanceSuccess,
  setBraintreeGooglePayInstanceSuccess,
  SET_BRAINTREE_GOOGLE_PAY_INSTANCE
} from '../actions/braintreeGatewayActions';
import Braintree from 'braintree-web';
import { getHostedFieldConfig, getHostedFieldStylesConfig } from '../utils/braintreeUtils';
import {
  braintreeSelectors,
  orderSelectors,
  paymentMethodSelectors
} from '../components/elements/paymentMethod/shared/selectors';
import { getThreeDSecureParameters } from './braintreeThreeDSecureSagas';
import { CARD_ENROLLED_IN_3DS_CODE } from 'shared/constants/braintree';
import { toastError } from 'client/actions/showNotificationsActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedString } from 'localization/localizer';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { setGoogleIsReadyToPay } from 'client/components/elements/paymentMethod/PayByGooglePay/payByGooglePayActions';
import { getGoogleIsReadyToPayRequest, getIsReadyToPay } from 'client/components/elements/paymentMethod/buttons/utils/googlePayUtils';

const googlePayConfigSelector = (state) => state.getIn(['config', 'googlePay']).toJS();

export function * braintreeClientToken () {
  try {
    const result = yield call(getClientToken);
    const parserResult = JSON.parse(result.text);
    yield put(braintreeClientTokenSuccess({ token: parserResult.clientToken }));
  } catch (err) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('braintree.get.clientToken.error')
      ),
      'top-right', 5000));
  }
}

export function * getExistingCardsFromBraintree ({ payload }) {
  try {
    const { paymentMethods = [] } = yield call(getPaymentMethods);
    yield put(setExistingCards({
      selector: payload.selector,
      paymentMethods
    }));
  } catch (err) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('braintree.get.existing.card.error')
      ),
      'top-right', 5000));
  }
}

export function * setBraintreePaypalInstance () {
  const clientInstance = yield call(getBraintreeClientInstance);
  const paypalInstance = yield Braintree.paypalCheckout.create({ client: clientInstance });
  yield put(setBraintreePaypalInstanceSuccess({ paypalInstance }));
}

export function * setBraintreeApplePayInstance () {
  const clientInstance = yield call(getBraintreeClientInstance);
  const applePayInstance = yield Braintree.applePay.create({ client: clientInstance });
  yield put(setBraintreeApplePayInstanceSuccess({ applePayInstance }));
}

export function * setBraintreeGooglePayInstance () {
  const googlePayConfig = yield select(googlePayConfigSelector);
  try {
    const clientInstance = yield call(getBraintreeClientInstance);
    const googlePayInstance = yield Braintree.googlePayment.create({
      client: clientInstance,
      googlePayVersion: 2,
      googleMerchantId: googlePayConfig.merchantId
    });
    const paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: googlePayConfig.environment
    });
    const paymentRequest = getGoogleIsReadyToPayRequest(googlePayInstance);
    const isReadyToPay = yield call(getIsReadyToPay, paymentsClient, paymentRequest);
    yield put(setGoogleIsReadyToPay(isReadyToPay));
    yield put(setBraintreeGooglePayInstanceSuccess({ googlePayInstance, paymentsClient }));
  } catch (error) {
    yield put(setGoogleIsReadyToPay(false));
  }
}

export function * getBraintreeInstance (selector, setInstance, INSTANCE_SUCCESS) {
  const threeDSecureInstance = yield select(selector);
  if (!threeDSecureInstance) {
    yield put(setInstance());
    yield take(INSTANCE_SUCCESS);
  }
  return threeDSecureInstance || (yield select(selector));
}

export function * getDeviceData () {
  const dataCollector = yield call(getBraintreeInstance,
    braintreeSelectors.instances.dataCollector,
    setBraintreeDataCollectorInstance,
    SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS);
  return dataCollector.deviceData;
}

export function * saveNewCard (selector) {
  const hostedFieldsInstance = yield select(braintreeSelectors.instances.hostedFields);
  const nameOnCard = (yield select(paymentMethodSelectors.payByNewCard.nameOnCard(selector))).trim();
  const { nonce } = yield hostedFieldsInstance.tokenize({ cardholderName: nameOnCard });
  const deviceData = yield call(getDeviceData);
  const billingAddressFormOpen = yield select(paymentMethodSelectors.payByNewCard.addressFormOpen(selector));
  const billingAddress = billingAddressFormOpen ? yield select(orderSelectors.billingAddressForm) : yield select(orderSelectors.shippingAddress);
  const saveCardPayload = {
    nonce,
    deviceData,
    billingAddress
  };
  return yield call(saveCard, saveCardPayload);
}

function * handleVerifyCardWith3dsError (err) {
  const {
    name: errorName,
    type: errorType,
    details: errorDetails
  } = err;

  const isCustomerInputRelatedError = (errorName === 'BraintreeError' && errorType === 'CUSTOMER') || err.message.startsWith('InputError');

  if (!isCustomerInputRelatedError) {
    return;
  }

  const errorMessage = errorDetails?.originalError?.details?.originalError?.error?.message || err.message; // This is braintree error structure and we can't do much about this.
  yield put(toastError(
    getToastMessage(errorMessage),
    'top-right',
    5000)
  );
}

export function * verifyCardWith3ds (nonce, details, billingAddress, quotationId) {
  try {
    const threeDSecureInstance = yield call(getBraintreeInstance,
      braintreeSelectors.instances.threeDSecure,
      setBraintree3dInstance,
      SET_BRAINTREE_3D_INSTANCE_SUCCESS);
    const threeDSecureParameters = yield call(getThreeDSecureParameters, nonce, details.bin, billingAddress, quotationId);
    yield put(updateDataLayer({
      event: 'checkout_modal',
      type: 'braintree_authentication'
    }));
    const braintree3DSecurePayload = yield threeDSecureInstance.verifyCard(threeDSecureParameters);
    const threeDSecureFailed = (threeDSecureInfo) =>
      threeDSecureInfo.enrolled === CARD_ENROLLED_IN_3DS_CODE &&
      threeDSecureInfo.liabilityShiftPossible &&
      !threeDSecureInfo.liabilityShifted;
    if (threeDSecureFailed(braintree3DSecurePayload.threeDSecureInfo)) {
      return;
    }
    return {
      nonceFromThreeDS: braintree3DSecurePayload.nonce,
      detailsFromThreeDS: braintree3DSecurePayload.details
    };
  } catch (err) {
    yield handleVerifyCardWith3dsError(err);
    throw err;
  }
}

export function * initializeHostedFields () {
  try {
    const clientInstance = yield call(getBraintreeClientInstance);
    const hostedFieldsInstance = yield Braintree.hostedFields.create({
      client: clientInstance,
      styles: getHostedFieldStylesConfig(),
      fields: getHostedFieldConfig() });
    yield put(setBraintreeHostedFieldsInstanceSuccess({ hostedFieldsInstance }));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }
}

export const clientTokenSelector = (state) => state.getIn(['braintree', 'clientToken']);

export function * getBraintreeClientInstance () {
  const clientToken = yield select(clientTokenSelector);
  if (!clientToken) {
    yield put(setBraintreeClientToken());
    yield take(BRAINTREE_CLIENT_TOKEN_SUCCESS);
  }
  const token = clientToken || (yield select(clientTokenSelector));
  return yield Braintree.client.create({ authorization: token });
}

export function * setBraintree3dSecureInstance () {
  const clientInstance = yield call(getBraintreeClientInstance);
  const threeDSecureInstance = yield Braintree.threeDSecure.create({ version: 2, client: clientInstance });
  yield put(setBraintree3dInstanceSuccess({ threeDSecureInstance }));
}

export function * setBraintreeDataCollector () {
  const clientInstance = yield call(getBraintreeClientInstance);
  const dataCollectorInstance = yield Braintree.dataCollector.create({ client: clientInstance, kount: true });
  yield put(setBraintreeDataCollectorInstanceSuccess({ dataCollectorInstance }));
}

function * watchGetExistingCards () {
  yield takeLatest(GET_EXISTING_CARDS, getExistingCardsFromBraintree);
}
function * watchSetBraintreePaypalInstance () {
  yield takeLatest(SET_BRAINTREE_PAYPAL_INSTANCE, setBraintreePaypalInstance);
}
function * watchSetBraintreeApplePayInstance () {
  yield takeLatest(SET_BRAINTREE_APPLE_PAY_INSTANCE, setBraintreeApplePayInstance);
}

function * watchSetBraintreeGooglePayInstance () {
  yield takeLatest(SET_BRAINTREE_GOOGLE_PAY_INSTANCE, setBraintreeGooglePayInstance);
}

function * watchSetBraintree3dInstance () {
  yield takeLatest(SET_BRAINTREE_3D_INSTANCE, setBraintree3dSecureInstance);
}
function * watchSetBraintreeDataCollectorInstance () {
  yield takeLatest(SET_BRAINTREE_DATA_COLLECTOR_INSTANCE, setBraintreeDataCollector);
}
function * watchInitializeHostedFields () {
  yield takeLatest(INITIALIZE_HOSTED_FIELDS, initializeHostedFields);
}
function * watchSetBraintreeClientToken () {
  yield takeLatest(SET_BRAINTREE_CLIENT_TOKEN, braintreeClientToken);
}

export function * watchAllBraintreeGatewaySagas () {
  yield all([
    fork(watchGetExistingCards),
    fork(watchSetBraintreePaypalInstance),
    fork(watchSetBraintreeApplePayInstance),
    fork(watchSetBraintreeGooglePayInstance),
    fork(watchInitializeHostedFields),
    fork(watchSetBraintree3dInstance),
    fork(watchSetBraintreeDataCollectorInstance),
    fork(watchSetBraintreeClientToken)
  ]);
}
