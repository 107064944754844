import { createAction } from 'redux-actions';

export const GET_EXISTING_CARDS = 'braintreeGateway.GET_EXISTING_CARDS';
export const getExistingCards = createAction(GET_EXISTING_CARDS);

export const SET_EXISTING_CARDS = 'braintreeGateway.SET_EXISTING_CARDS';
export const setExistingCards = createAction(SET_EXISTING_CARDS);

export const SET_BRAINTREE_CLIENT_TOKEN = 'braintreeGateway.SET_BRAINTREE_CLIENT_TOKEN';
export const setBraintreeClientToken = createAction(SET_BRAINTREE_CLIENT_TOKEN);

export const BRAINTREE_CLIENT_TOKEN_SUCCESS = 'braintreeGateway.BRAINTREE_CLIENT_TOKEN_SUCCESS';
export const braintreeClientTokenSuccess = createAction(BRAINTREE_CLIENT_TOKEN_SUCCESS);

export const SET_BRAINTREE_PAYPAL_INSTANCE = 'braintreeGateway.SET_BRAINTREE_PAYPAL_INSTANCE';
export const setBraintreePaypalInstance = createAction(SET_BRAINTREE_PAYPAL_INSTANCE);

export const SET_BRAINTREE_PAYPAL_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_PAYPAL_INSTANCE_SUCCESS';
export const setBraintreePaypalInstanceSuccess = createAction(SET_BRAINTREE_PAYPAL_INSTANCE_SUCCESS);

export const SET_BRAINTREE_HOSTED_FIELDS_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_HOSTED_FIELDS_INSTANCE_SUCCESS';
export const setBraintreeHostedFieldsInstanceSuccess = createAction(SET_BRAINTREE_HOSTED_FIELDS_INSTANCE_SUCCESS);

export const SET_BRAINTREE_3D_INSTANCE = 'braintreeGateway.SET_BRAINTREE_3D_INSTANCE';
export const setBraintree3dInstance = createAction(SET_BRAINTREE_3D_INSTANCE);

export const SET_BRAINTREE_3D_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_3D_INSTANCE_SUCCESS';
export const setBraintree3dInstanceSuccess = createAction(SET_BRAINTREE_3D_INSTANCE_SUCCESS);

export const SET_BRAINTREE_DATA_COLLECTOR_INSTANCE = 'braintreeGateway.SET_BRAINTREE_DATA_COLLECTOR_INSTANCE';
export const setBraintreeDataCollectorInstance = createAction(SET_BRAINTREE_DATA_COLLECTOR_INSTANCE);

export const SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS';
export const setBraintreeDataCollectorInstanceSuccess = createAction(SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS);

export const INITIALIZE_HOSTED_FIELDS = 'braintreeGateway.INITIALIZE_HOSTED_FIELDS';
export const initializeHostedFields = createAction(INITIALIZE_HOSTED_FIELDS);

export const SET_PAYPAL_DATA = 'braintreeGateway.SET_PAYPAL_DATA';
export const setPaypalData = createAction(SET_PAYPAL_DATA);

export const SET_APPLE_PAY_DATA = 'braintreeGateway.SET_APPLE_PAY_DATA';
export const setApplePayData = createAction(SET_APPLE_PAY_DATA);

export const SET_GOOGLE_PAY_DATA = 'braintreeGateway.SET_GOOGLE_PAY_DATA';
export const setGooglePayData = createAction(SET_GOOGLE_PAY_DATA);

export const SET_BRAINTREE_APPLE_PAY_INSTANCE = 'braintreeGateway.SET_BRAINTREE_APPLE_PAY_INSTANCE';
export const setBraintreeApplePayInstance = createAction(SET_BRAINTREE_APPLE_PAY_INSTANCE);

export const SET_BRAINTREE_APPLE_PAY_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_APPLE_PAY_INSTANCE_SUCCESS';
export const setBraintreeApplePayInstanceSuccess = createAction(SET_BRAINTREE_APPLE_PAY_INSTANCE_SUCCESS);

export const SET_BRAINTREE_GOOGLE_PAY_INSTANCE = 'braintreeGateway.SET_BRAINTREE_GOOGLE_PAY_INSTANCE';
export const setBraintreeGooglePayInstance = createAction(SET_BRAINTREE_GOOGLE_PAY_INSTANCE);

export const SET_BRAINTREE_GOOGLE_PAY_INSTANCE_SUCCESS = 'braintreeGateway.SET_BRAINTREE_GOOGLE_PAY_INSTANCE_SUCCESS';
export const setBraintreeGooglePayInstanceSuccess = createAction(SET_BRAINTREE_GOOGLE_PAY_INSTANCE_SUCCESS);
