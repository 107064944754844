import { createAction } from 'redux-actions';
// --------------------------------------------------
//

export const REQUEST_LOAD_CART = 'cartActions.REQUEST_LOAD_CART';
export const RECIEVE_LOAD_CART_SUCCESS = 'cartActions.RECIEVE_LOAD_CART_SUCCESS';
export const RECIEVE_LOAD_CART_ERROR = 'cartActions.RECIEVE_LOAD_CART_ERROR';

export const loadCart = createAction(REQUEST_LOAD_CART);
export const recieveLoadCartSuccess = createAction(RECIEVE_LOAD_CART_SUCCESS);
export const recieveLoadCartError = createAction(RECIEVE_LOAD_CART_ERROR);

// --------------------------------------------------
//

export const REQUEST_LOAD_CART_QUANTITY = 'cartActions.REQUEST_LOAD_CART_QUANTITY';
export const RECEIVE_LOAD_CART_QUANTITY_SUCCESS = 'cartActions.RECEIVE_LOAD_CART_QUANTITY_SUCCESS';
export const RECEIVE_LOAD_CART_QUANTITY_ERROR = 'cartActions.RECEIVE_LOAD_CART_QUANTITY_ERROR';

export const loadCartQuantity = createAction(REQUEST_LOAD_CART_QUANTITY);
export const receiveLoadCartQuantitySuccess = createAction(RECEIVE_LOAD_CART_QUANTITY_SUCCESS);
export const receiveLoadCartQuantityError = createAction(RECEIVE_LOAD_CART_QUANTITY_ERROR);

// --------------------------------------------------
//

export const REQUEST_SET_ORDER_LINE_QUANTITY = 'cartActions.REQUEST_SET_ORDER_LINE_QUANTITY';
export const RECIEVE_SET_ORDER_LINE_QUANTITY_SUCCESS = 'cartActions.RECIEVE_SET_ORDER_LINE_QUANTITY_SUCCESS';
export const RECIEVE_SET_ORDER_LINE_QUANTITY_ERROR = 'cartActions.RECIEVE_SET_ORDER_LINE_QUANTITY_ERROR';

export const setOrderLineQuantity = createAction(REQUEST_SET_ORDER_LINE_QUANTITY);
export const recieveSetOrderLineQuantitySuccess = createAction(RECIEVE_SET_ORDER_LINE_QUANTITY_SUCCESS);
export const recieveSetOrderLineQuantityError = createAction(RECIEVE_SET_ORDER_LINE_QUANTITY_ERROR);

export const REQUEST_SET_BUNDLE_QUANTITY = 'cartActions.REQUEST_SET_BUNDLE_QUANTITY';
export const RECEIVE_SET_BUNDLE_QUANTITY_SUCCESS = 'cartActions.RECEIVE_SET_BUNDLE_QUANTITY_SUCCESS';
export const RECEIVE_SET_BUNDLE_QUANTITY_ERROR = 'cartActions.RECEIVE_SET_BUNDLE_QUANTITY_ERROR';

export const setBundleQuantity = createAction(REQUEST_SET_BUNDLE_QUANTITY);
export const receiveSetBundleQuantitySuccess = createAction(RECEIVE_SET_BUNDLE_QUANTITY_SUCCESS);
export const receiveSetBundleQuantityError = createAction(RECEIVE_SET_BUNDLE_QUANTITY_ERROR);

// --------------------------------------------------
//

export const REQUEST_REMOVE_ORDER_LINE = 'cartActions.REQUEST_REMOVE_ORDER_LINE';
export const REQUEST_REMOVE_ORDER_LINE_CONFIRM = 'cartActions.REQUEST_REMOVE_ORDER_LINE_CONFIRM';
export const RECIEVE_REMOVE_ORDER_LINE_SUCCESS = 'cartActions.RECIEVE_REMOVE_ORDER_LINE_SUCCESS';
export const RECIEVE_REMOVE_ORDER_LINE_ERROR = 'cartActions.RECIEVE_REMOVE_ORDER_LINE_ERROR';
export const REMOVE_ORDER_LINE_FROM_BASKET_DROPDOWN = 'cartActions.REMOVE_ORDER_LINE_FROM_BASKET_DROPDOWN';

export const removeOrderLine = (payload, eligibleForDeliveryOptions, isBasketDropDown = false) => ({
  type: REQUEST_REMOVE_ORDER_LINE,
  payload,
  eligibleForDeliveryOptions,
  isBasketDropDown
});
export const requestRemoveOrderLineConfirm = createAction(REQUEST_REMOVE_ORDER_LINE_CONFIRM);
export const recieveRemoveOrderLineSuccess = createAction(RECIEVE_REMOVE_ORDER_LINE_SUCCESS);
export const recieveRemoveOrderLineError = createAction(RECIEVE_REMOVE_ORDER_LINE_ERROR);
export const removeOrderLineFromBasketDropdown = createAction(REMOVE_ORDER_LINE_FROM_BASKET_DROPDOWN);

export const REQUEST_REMOVE_BUNDLE_ORDER_LINE = 'cartActions.REQUEST_REMOVE_BUNDLE_ORDER_LINE';
export const REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM = 'cartActions.REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM';
export const RECEIVE_REMOVE_BUNDLE_ORDER_LINE_SUCCESS = 'cartActions.RECEIVE_REMOVE_BUNDLE_ORDER_LINE_SUCCESS';
export const RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR = 'cartActions.RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR';

export const removeBundleOrderLine = createAction(REQUEST_REMOVE_BUNDLE_ORDER_LINE);
export const requestRemoveBundleOrderLineConfirm = createAction(REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM);
export const receiveRemoveBundleOrderLineSuccess = createAction(RECEIVE_REMOVE_BUNDLE_ORDER_LINE_SUCCESS);
export const receiveRemoveBundleOrderLineError = createAction(RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR);

// --------------------------------------------------
//

export const REQUEST_ADD_TO_CART = 'cartActions.REQUEST_ADD_TO_CART';
export const RECIEVE_ADD_TO_CART_SUCCESS = 'cartActions.RECIEVE_ADD_TO_CART_SUCCESS';
export const RECIEVE_ADD_TO_CART_ERROR = 'cartActions.RECIEVE_ADD_TO_CART_ERROR';

export const addToCart = createAction(REQUEST_ADD_TO_CART);
export const recieveAddToCartSuccess = createAction(RECIEVE_ADD_TO_CART_SUCCESS);
export const recieveAddToCartError = createAction(RECIEVE_ADD_TO_CART_ERROR);

export const QTY_ADDED_TO_CART = 'cartActions.QTY_ADDED_TO_CART';
export const qtyAddedToCart = createAction(QTY_ADDED_TO_CART);
