import { takeLatest, take, put, call, all, fork, select } from 'redux-saga/effects';
import { delay } from 'shared/utils/sagaUtils';
import { fromJS, List } from 'immutable';
import { loadCart } from 'client/actions/cartActions';
import {
  FETCH_REPEATORDERS,
  FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS,
  fetchRepeatOrdersSuccess,
  DELETE_REPEAT_ORDER_CONFIRM,
  REQUEST_REMOVE_REPEAT_ORDER_CONFIRM,
  REQUEST_REMOVE_PRODUCT_REPEAT_ORDER_CONFIRM,
  deleteRepeatOrderSuccess,
  PAUSE_PLAY_REPEAT_ORDER_CONFIRM,
  pausePlayRepeatOrderSuccess,
  ADD_PRODUCT_TO_REPEAT_ORDER,
  REQUEST_PAUSE_PLAY_REPEAT_ORDER_CONFIRM,
  CREATE_SUBSCRIPTION,
  requestConfirmSubscription,
  createSubscriptionFailed,
  SAVE_NEW_DELIVERY_ADDRESS,
  setNewAddressAsSelected,
  FETCH_REPEATORDER_BY_ID,
  updateRepeatOrderDetails,
  REMOVE_PRODUCT,
  ADD_PRODUCT,
  editRepeatOrderSuccess,
  UPDATE_REPEAT_ORDER,
  loadUpdateRepeatOrder,
  SET_DEFAULT_DELIVERY_ADDRESS,
  fetchRepeatOrdersWithoutPriceDetails as fetchRepeatOrdersWithoutPriceDetailsAction,
  SHOW_CREATE_NEW_ADDRESS_FORM,
  CHANGE_DELIVERY_ADDRESS,
  SET_SUBSCRIPTION_NAME,
  REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS,
  requestAllNotExpiredSavedCardsSuccess,
  resetNewRepeatOrderDetails,
  fetchRepeatOrders as fetchRepeatOrdersAction,
  addProductToRepeatOrderSuccess,
  addProductToRepeatOrderFailed,
  resetAddProductToRepeatOrder,
  initialize as initializeAction,
  updateQuantity,
  CREATE_SUBSCRIPTION_SUCCESS,
  paymentMethodUpdated,
  INITIALIZE,
  setInitializeState,
  setInitialState,
  SET_PRODUCT_AND_QUANTITY, resetDisplay, STOP_SUBSCRIPTION, FETCH_REPEATORDERS_SUCCESS, resetBannerStatuses,
  fetchRepeatOrderById as fetchRepeatOrderByIdAction,
  fetchRepeatOrderProductSuccess
} from 'client/actions/repeatOrdersActions';
import {
  showCustomAlertDialog,
  showRemoveRepeatOrderDialog,
  hideRemoveRepeatOrderDialog,
  showPausePlayRepeatOrderDialog,
  hidePausePlayRepeatOrderDialog,
  showRemoveProductRepeatOrderDialog,
  hideRemoveProductRepeatOrderDialog,
  hideEditRepeatOrderDialog, HIDE_LOGIN_DIALOG
} from 'client/actions/ui/dialogActions';
import {
  getBraintreeSavedCards
} from 'client/utils/braintreeUtils';
import { saveAddress, getUserAddresses, GET_USER_ADDRESSES_SUCCESS } from 'client/actions/addressActions';
import { goToUrl } from 'client/actions/routeActions';
import { scrollToRepeatOrderSection } from 'client/actions/productVariantActions';
import {
  fetchRepeatOrders as fetchRepeatOrdersEndpoint,
  fetchRepeatOrdersWithOutPriceDetails as fetchRepeatOrdersWithOutPriceDetailsEndpoint,
  deleteRepeatOrder as deleteRepeatOrderEndPoint,
  pausePlayRepeatOrder as pausePlayRepeatOrderEndPoint,
  addProductToRepeatOrder as addProductToRepeatOrderEndPoint,
  fetchRepeatOrderById as fetchRepeatOrderByIdEndpoint,
  removeProduct as removeProductEndpoint,
  addProduct as addProductEndpoint,
  editRepeatOrder as editRepeatOrderEndPoint,
  updatePayment
} from 'shared/endpoints/repeatOrdersEndpoint';
import { addProductLineItems, updatingQuantity } from 'client/actions/productLineActions';
import { toProductLineItems } from 'client/utils/productLineUtils';

import { showLoginDialog } from 'client/actions/ui/dialogActions';
import { resetSelectedIndex } from 'client/actions/ui/selectedIndexActions';
import { change, reset } from 'redux-form';
import {
  getAllSavedPaymentMethods
} from 'shared/endpoints/braintreeEndpoint';
import {
  PAUSED,
  RUNNING,
  ACCOUNT,
  EXISTING_SUBSCRIPTION_SELECTOR, CARD as SUBSCRIPTION_CARD, PROCESSING, COMPLETED, STOP, ADD_TO_SUBSCRIPTION_FORM_NAME
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { subscribeByNewCard } from 'client/components/elements/paymentMethod/PayByNewCard/payByNewCardActions';
import { paymentMethodSelectors } from 'client/components/elements/paymentMethod/shared/selectors';
import {
  DEFAULT_PAYMENT_METHOD,
  PAY_BY_ACCOUNT,
  PAY_BY_EXISTING_CARD,
  PAY_BY_NEW_CARD
} from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import {
  getStockInfo,
  getProductById
} from 'shared/endpoints/productVariantEndpoint';
import {
  SET_SELECTED_CARD,
  subscribeByExistingCard
} from 'client/components/elements/paymentMethod/PayByExistingCard/payByExistingCardActions';
import { subscribeByAccount } from 'client/components/elements/paymentMethod/PayByAccount/payByAccountActions';
import { CHANGE_PAYMENT_METHOD } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { scrollToTop } from 'client/actions/scrollActions';
import { toastError, toastSuccess } from 'client/actions/showNotificationsActions';
import { sortOrdersByEarliestNextDeliveryDate } from 'client/reducers/repeatOrdersReducers';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedString } from 'localization/localizer';
import { updateDefaultPayment } from 'shared/endpoints/userEndpoint';
import { getAccountDetails } from 'client/actions/accountActions';
import { getPaymentMethod } from 'client/components/elements/paymentMethod/shared/paymentHelpers';
import { getDeliveryDateNewSubscription } from 'shared/utils/subscriptionUtils';

const getUserAuthenticationsDetails = state => state.get('auth');
const getPublicHolidays = (state) => state.get('publicHolidays', fromJS([])).toJS();
const getRepeatOrders = (state) => {
  return state.getIn(['repeatOrders', 'all']).toJS();
};

const getRepeatOrder = (repeatOrders, id) => repeatOrders.find(repeatOrder => repeatOrder._id === id);

const productExistsInRepeatOrder = (products, sku) => products.find(product => product.sku === sku);

const getQuantityToUpdate = (newQtyToSubs, existingProduct) => parseInt(existingProduct.quantity) + parseInt(newQtyToSubs);

const productToAddToSubscription = (existingRepeatOrder, payload) => {
  if (productExistsInRepeatOrder(existingRepeatOrder.products, payload.sku)) {
    return {
      ...payload,
      subscriptionProductQuantity: getQuantityToUpdate(
        payload.subscriptionProductQuantity,
        productExistsInRepeatOrder(existingRepeatOrder.products, payload.sku)
      )
    };
  }
  return payload;
};

export const getAllNotExpiredSavedCards = (paymentMethods) => {
  const cardPaymentMethods = getBraintreeSavedCards(paymentMethods);
  return cardPaymentMethods.filter(p => !p.isCardExpired);
};

export function * requestAllNotExpiredSavedCards () {
  const paymentMethods = yield call(getAllSavedPaymentMethods);
  const allSavedCards = getAllNotExpiredSavedCards(paymentMethods);
  yield put(requestAllNotExpiredSavedCardsSuccess({ allSavedCards }));
}

export function * fetchRepeatOrders () {
  const repeatOrders = yield call(fetchRepeatOrdersEndpoint);
  yield put(fetchRepeatOrdersSuccess(repeatOrders));
}

export function * fetchRepeatOrdersWithoutPriceDetails () {
  const auth = yield select(getUserAuthenticationsDetails);

  if (!auth.get('isLoggedIn')) {
    yield put(showLoginDialog(undefined, fetchRepeatOrdersWithoutPriceDetailsAction()));
  } else {
    yield put(getUserAddresses({ addressesDisplayed: 'ALL', addressesSkip: 0 }));
    const repeatOrders = yield call(fetchRepeatOrdersWithOutPriceDetailsEndpoint);
    yield put(fetchRepeatOrdersSuccess(repeatOrders));
  }
}

export function * fetchRepeatOrderById ({ id }) {
  try {
    const repeatOrder = yield call(fetchRepeatOrderByIdEndpoint, id);
    yield put(updateRepeatOrderDetails(repeatOrder.body));
    yield put(addProductLineItems(toProductLineItems(repeatOrder.body.orderPrice, 'cart')));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog(`There was an ERROR when retrieving the repeat order with id: ${id}`));
  }
}

export function * deleteRepeatOrderConfirm ({ repeatOrderId, page }) {
  yield put(showRemoveRepeatOrderDialog(repeatOrderId));
  const payload = yield take(REQUEST_REMOVE_REPEAT_ORDER_CONFIRM);
  if (!payload) {
    return;
  }
  yield deleteRepeatOrder({ repeatOrderId, page });
}

export function * deleteRepeatOrder ({ repeatOrderId, page }) {
  try {
    const response = yield call(deleteRepeatOrderEndPoint, repeatOrderId);
    if (page === 'detail_page') {
      yield put(goToUrl('/my-account/repeat-orders'));
    } else if (page === 'list_page') {
      yield put(deleteRepeatOrderSuccess(response.body));
    }
    yield put(hideRemoveRepeatOrderDialog());
    yield put(showCustomAlertDialog('The repeat order has been deleted.'));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR deleting the repeat order.'));
  }
}

export function * updateRepeatOrder ({ repeatOrderId }) {
  try {
    const subscriptionRegularitySelect = (state, repeatOrderId) => state
      .getIn(['repeatOrders', 'details', repeatOrderId, 'regularity'], '');
    const subscriptionFormSelect = (state) => state
      .getIn(['form', 'updateSubscriptionForm', 'values'], fromJS({}))
      .toJS();
    const currentRegularity = yield select(subscriptionRegularitySelect, repeatOrderId);
    const { regularity, startDate, endDate } = yield select(subscriptionFormSelect);

    const isNewRegularity = currentRegularity !== regularity.toUpperCase();
    const repeatOrderDetailsRequest = {
      ...(isNewRegularity ? { regularity } : {}),
      ...(startDate ? { startDate } : {}),
      ...(endDate !== undefined ? { endDate } : {}),
      repeatOrderId
    };
    yield put(loadUpdateRepeatOrder(repeatOrderId, true));
    const response = yield call(editRepeatOrderEndPoint, repeatOrderDetailsRequest);
    yield put(updateRepeatOrderDetails(response.body));
    yield put(loadUpdateRepeatOrder(repeatOrderId, false));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }
}

export function * pausePlayRepeatOrderConfirm ({ repeatOrder, page }) {
  const newState = repeatOrder.get('state') === RUNNING ? PAUSED : RUNNING;

  const publicHolidays = yield select(getPublicHolidays);
  const playOrderNextDeliveryDate = getDeliveryDateNewSubscription(publicHolidays);
  yield put(showPausePlayRepeatOrderDialog(repeatOrder, newState, playOrderNextDeliveryDate));

  const payload = yield take(REQUEST_PAUSE_PLAY_REPEAT_ORDER_CONFIRM);
  if (!payload) {
    return;
  }
  yield pausePlayRepeatOrder({ repeatOrder, newState, page });
}

export function * pausePlayRepeatOrder ({ repeatOrder, newState, page }) {
  try {
    const response = yield call(pausePlayRepeatOrderEndPoint, { _id: repeatOrder.get('_id'), state: newState });
    yield put(pausePlayRepeatOrderSuccess(fromJS(response.body), page));
    yield put(hidePausePlayRepeatOrderDialog());
    yield put(showCustomAlertDialog(`The repeat order  ${repeatOrder.get('orderReference')} has been set to ${response.body?.state}.`));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR setting state of the repeat order.'));
  }
}

export function * editRepeatOrder ({ repeatOrderDetails, newRegularity, repeatOrderId, page, endDate }) {
  try {
    const repeatOrderWithUpdatedRegularity = endDate ? {
      repeatOrderId: repeatOrderId,
      endDate: endDate
    } : {
      regularity: newRegularity,
      repeatOrderId: repeatOrderId
    };
    const response = yield call(editRepeatOrderEndPoint, repeatOrderWithUpdatedRegularity);
    yield put(editRepeatOrderSuccess(fromJS(response.body)));
    yield put(hideEditRepeatOrderDialog());
    yield put(showCustomAlertDialog(`Your subscription ${repeatOrderDetails.orderReference} has been updated.`));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR setting regularity of the subscription ${subscriptionDetails.orderReference}.'));
  }
}

export function * addProductToRepeatOrder ({ payload }) {
  try {
    yield put(resetBannerStatuses());
    const repeatOrders = yield select(getRepeatOrders);
    const existingRepeatOrder = getRepeatOrder(repeatOrders, payload.existingSubscriptionId);
    const productToAdd = productToAddToSubscription(existingRepeatOrder, payload);
    yield put(addProductToRepeatOrderSuccess({ reference: existingRepeatOrder.orderReference }));
    yield call(addProductToRepeatOrderEndPoint, productToAdd);
    yield put(resetAddProductToRepeatOrder());
    yield call(fetchRepeatOrders);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(addProductToRepeatOrderFailed());
    yield delay(2500);
    yield put(resetNewRepeatOrderDetails());
  }
}

export const newRepeatOrderSelect = (state) => state.getIn(['repeatOrders', 'newRepeatOrder'], fromJS({})).toJS();
export function * createRepeatOrder ({ payload }) {
  try {
    yield put(resetBannerStatuses());
    yield put(requestConfirmSubscription());
    const paymentType = yield select(paymentMethodSelectors.paymentMethod(payload.selector));
    switch (paymentType) {
      case PAY_BY_NEW_CARD:
        yield put(subscribeByNewCard(payload));
        break;
      case PAY_BY_EXISTING_CARD:
        yield put(subscribeByExistingCard(payload));
        break;
      case PAY_BY_ACCOUNT:
        yield put(subscribeByAccount());
        break;
      default:
        yield put(createSubscriptionFailed());
    }
    yield take(CREATE_SUBSCRIPTION_SUCCESS);
    yield put(resetDisplay());
    yield put(resetNewRepeatOrderDetails());
    yield fetchRepeatOrdersWithoutPriceDetails();
    const { sku } = payload;
    const { body: product } = yield call(getProductById, sku);
    const productStockInfo = yield call(getStockInfo, sku, parseInt(product?.stock?.minOrderQuantity || 1));
    const productInfo = { ...product, stock: productStockInfo };
    yield put(fetchRepeatOrderProductSuccess(productInfo, sku));
    yield put(reset('createNewSubscriptionForm'));
  } catch (error) {
    yield put(createSubscriptionFailed());
  }
}

export function * saveNewDeliveryAddress ({ payload }) {
  try {
    yield put(saveAddress(
      {
        ...payload,
        addressType: 'delivery',
        address: {
          ...payload.address,
          useAsBillingAddress: false
        },
        fromRepeatOrder: true,
        payOnAccount: null,
        fromQuotationId: null
      }
    ));
    yield take(GET_USER_ADDRESSES_SUCCESS);
    yield call(setDeliveryAddress, payload.address.addressName, payload.createNewSubscriptionNextStep);
  } catch (error) {
  }
}

export function * setDeliveryAddress (name, createNewSubscriptionNextStep) {
  const addressListSelector = (state) => state.getIn(['user', 'address', 'deliveryAddresses', 'data']).toJS();
  const addressList = yield select(addressListSelector);
  const address = addressList.find((address) => address.addressName === name);
  const addressId = address ? address.addressId : addressList[0].addressId;
  yield put(change('createNewSubscriptionForm', 'deliveryAddressId', addressId));
  yield put(setNewAddressAsSelected({ addressId }));
  createNewSubscriptionNextStep();
}

export function * changeDeliveryAddress ({ payload }) {
  yield put(change('createNewSubscriptionForm', 'deliveryAddressId', payload));
}

export function * removeProduct ({ sku, quantity, repeatOrderId, isLastItem }) {
  try {
    if (isLastItem) {
      yield deleteRepeatOrderConfirm({ repeatOrderId, page: 'detail_page' });
    } else {
      yield put(showRemoveProductRepeatOrderDialog(repeatOrderId));
      const payload = yield take(REQUEST_REMOVE_PRODUCT_REPEAT_ORDER_CONFIRM);
      if (!payload) {
        return;
      }
      yield call(removeProductEndpoint, { sku, quantity, repeatOrderId });
      const repeatOrder = yield call(fetchRepeatOrderByIdEndpoint, repeatOrderId);
      yield put(updateRepeatOrderDetails(repeatOrder.body));
      yield put(addProductLineItems(toProductLineItems(repeatOrder.body.orderPrice, 'cart')));
      yield put(hideRemoveProductRepeatOrderDialog(repeatOrderId));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

export function * addProduct ({ sku, quantity, name, repeatOrderId, isValidQuantity }) {
  if (isValidQuantity) {
    yield put(updatingQuantity(sku, true));
    const addedProduct = yield call(addProductEndpoint, { sku, quantity, name, repeatOrderId });
    const deliveryAddressSelector = (state) => state.getIn(['repeatOrders', 'details', addedProduct.body._id, 'deliveryAddress'], fromJS({})).toJS();
    addedProduct.body.deliveryAddress = yield select(deliveryAddressSelector);
    yield put(updateRepeatOrderDetails(addedProduct.body));
    yield put(addProductLineItems(toProductLineItems(addedProduct.body.orderPrice, 'cart')));
    yield put(updatingQuantity(sku, false));
  } else {
    yield put(updateQuantity(sku, quantity, repeatOrderId));
  }
}

export function * setScrollEventOnReturnFromAddressBook ({ payload }) {
  if (!payload) {
    yield put(scrollToRepeatOrderSection(true));
  }
}

const subscriptionIdSelector = (state) => state.getIn(['repeatOrders', 'details', 'id']);
export function * updateSubscriptionPaymentMethod ({ payload, type }) {
  const subscriptionId = yield select(subscriptionIdSelector);
  if (payload.initialPaymentMethod) {
    yield put(paymentMethodUpdated());
  } else if (payload.selector === EXISTING_SUBSCRIPTION_SELECTOR) {
    try {
      if (type === SET_SELECTED_CARD) {
        const currentCardTokenSelector = (state) => state.getIn(['repeatOrders', 'details', subscriptionId, 'cardToken']);
        const currentCardToken = yield select(currentCardTokenSelector);
        if (currentCardToken && currentCardToken !== payload.selectedCard.token) {
          const updatePaymentPayload = {
            paymentType: SUBSCRIPTION_CARD,
            cardToken: payload.selectedCard.token
          };
          yield call(updatePayment, updatePaymentPayload, subscriptionId);
          yield put(paymentMethodUpdated({ paymentMethod: SUBSCRIPTION_CARD, last4: payload.selectedCard.cardLastFourDigits }));
          yield put(fetchRepeatOrderByIdAction(subscriptionId));
          yield put(scrollToTop());
        }
      }
      if (payload.paymentMethod === PAY_BY_ACCOUNT) {
        const updatePaymentPayload = {
          paymentType: ACCOUNT
        };
        yield call(updatePayment, updatePaymentPayload, subscriptionId);
        yield put(paymentMethodUpdated({ paymentMethod: ACCOUNT }));
        yield put(fetchRepeatOrderByIdAction(subscriptionId));
        yield put(scrollToTop());
      }
      if (payload.paymentMethod === PAY_BY_EXISTING_CARD && !payload.cancelled) {
        yield take(SET_SELECTED_CARD);
        const selectedCard = yield select(paymentMethodSelectors.payByExistingCard.selectedCard(payload.selector));
        const updatePaymentPayload = {
          paymentType: SUBSCRIPTION_CARD,
          cardToken: selectedCard.token
        };
        yield call(updatePayment, updatePaymentPayload, subscriptionId);
        yield put(paymentMethodUpdated({ paymentMethod: SUBSCRIPTION_CARD, last4: selectedCard.cardLastFourDigits }));
        yield put(fetchRepeatOrderByIdAction(subscriptionId));
        yield put(scrollToTop());
      }
    } catch (err) {
      yield put(toastError(
        getToastMessage(
          getLocalizedString('repeatOrder.subscription.payment.error')
        ),
        'top-right', 5000));
    }
  } else if (payload.selector === DEFAULT_PAYMENT_METHOD) {
    try {
      const { paymentMethod } = payload;
      const defaultPaymentMethod = getPaymentMethod(paymentMethod);
      yield call(updateDefaultPayment, { defaultPaymentMethod });
      yield put(getAccountDetails());
      yield put(toastSuccess(
        getToastMessage(
          getLocalizedString(`defaultPaymentMethod.updateRequest.${defaultPaymentMethod.toLocaleLowerCase()}.text`)
        ),
        'top-right', 5000));
    } catch (error) {
      yield put(toastError(
        getToastMessage(
          getLocalizedString('defaultPaymentMethod.updateRequest.failed.text')
        ),
        'top-right', 5000));
    }
  }
}

function * initialize (action) {
  try {
    const auth = yield select(getUserAuthenticationsDetails);
    const { sku, qty } = action.payload;
    yield put(setInitializeState(PROCESSING));
    yield put(loadCart());
    if (!auth.get('isLoggedIn')) {
      yield put(showLoginDialog(undefined, initializeAction().type));
    } else {
      yield put(resetNewRepeatOrderDetails());
      yield put(getUserAddresses({ addressesDisplayed: 'ALL', addressesSkip: 0 }));
      const repeatOrders = yield call(fetchRepeatOrdersWithOutPriceDetailsEndpoint);
      const { body: product } = yield call(getProductById, sku);
      const productStockInfo = yield call(getStockInfo, sku, parseInt(qty));
      const productInfo = { ...product, stock: productStockInfo };
      yield put(fetchRepeatOrderProductSuccess(productInfo, sku));
      yield put(fetchRepeatOrdersSuccess(repeatOrders));
      const sortedOrders = sortOrdersByEarliestNextDeliveryDate(repeatOrders);
      const activeOrders = sortedOrders.filter(item => item.state !== STOP);
      const isNewSubscriptionSelector = (state) => state.getIn(['repeatOrders', 'isNewSubscription']);
      const isNewSubscription = yield select(isNewSubscriptionSelector);
      const initialState = isNewSubscription ? {
        showAddToSubscription: false,
        showNewSubscription: true
      } : {
        showAddToSubscription: activeOrders.length > 0,
        showNewSubscription: activeOrders.length === 0
      };
      initialState.currentDisplay = 1;
      yield put(setInitialState(initialState));
      yield put(setInitializeState(COMPLETED));
    }
  } catch {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('repeatOrder.subscription.initialize.error')
      ),
      'top-right', 5000));
    yield put(setInitializeState(COMPLETED));
  }
}

function * checkUserLoggedIn () {
  const auth = yield select(getUserAuthenticationsDetails);

  if (!auth.get('isLoggedIn')) {
    yield put(resetSelectedIndex());
  }
}

export function * updateCurrentSubscriptionQuantity ({ payload }) {
  if (payload.form === ADD_TO_SUBSCRIPTION_FORM_NAME) {
    yield put(change('addToMySubscription', 'subscriptionProductQuantity', payload.quantity));
  }
}

export function * stopSubscription ({ payload }) {
  try {
    yield put(fetchRepeatOrdersAction());
    yield take(FETCH_REPEATORDERS_SUCCESS);
    const repeatOrdersSelector = (state) => state.getIn(['repeatOrders', 'all'], List()).toJS();
    const repeatOrders = yield select(repeatOrdersSelector);
    const repeatOrdersToStop = payload.token
      ? repeatOrders.filter(order => order.cardToken === payload.token)
      : repeatOrders.filter(order => order.deliveryAddressId === payload.addressId);
    if (repeatOrdersToStop) {
      for (const order of repeatOrdersToStop) {
        yield call(deleteRepeatOrderEndPoint, order._id);
      }
    }
  } catch (err) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('repeatOrder.subscription.stopSubscription.error')
      ),
      'top-right', 5000));
  }
}

export function * watchChangeExistingCard () {
  yield takeLatest(SET_SELECTED_CARD, updateSubscriptionPaymentMethod);
}

export function * watchUpdateQuantity () {
  yield takeLatest(SET_PRODUCT_AND_QUANTITY, updateCurrentSubscriptionQuantity);
}

export function * watchInitialize () {
  yield takeLatest(INITIALIZE, initialize);
}

export function * watchCheckUserLoggedIn () {
  yield takeLatest(HIDE_LOGIN_DIALOG, checkUserLoggedIn);
}

export function * watchPaymentMethodUpdated () {
  yield takeLatest(CHANGE_PAYMENT_METHOD, updateSubscriptionPaymentMethod);
}

export function * watchFetchRepeatOrders () {
  yield takeLatest(FETCH_REPEATORDERS, fetchRepeatOrders);
}

export function * watchFetchRepeatOrdersWithoutPriceDetails () {
  yield takeLatest(FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS, fetchRepeatOrdersWithoutPriceDetails);
}

export function * watchDeleteRepeatOrder () {
  yield takeLatest(DELETE_REPEAT_ORDER_CONFIRM, deleteRepeatOrderConfirm);
}

export function * watchPausePlayRepeatOrder () {
  yield takeLatest(PAUSE_PLAY_REPEAT_ORDER_CONFIRM, pausePlayRepeatOrderConfirm);
}

export function * watchAddProductToRepeatOrder () {
  yield takeLatest(ADD_PRODUCT_TO_REPEAT_ORDER, addProductToRepeatOrder);
}

export function * watchCreateRepeatOrder () {
  yield takeLatest(CREATE_SUBSCRIPTION, createRepeatOrder);
}

export function * watchSaveNewDeliveryAddress () {
  yield takeLatest(SAVE_NEW_DELIVERY_ADDRESS, saveNewDeliveryAddress);
}

export function * watchFetchRepeatOrderById () {
  yield takeLatest(FETCH_REPEATORDER_BY_ID, fetchRepeatOrderById);
}

export function * watchRemoveProductFromRepeatOrder () {
  yield takeLatest(REMOVE_PRODUCT, removeProduct);
}

export function * watchAddProductRepeatOrder () {
  yield takeLatest(ADD_PRODUCT, addProduct);
}

export function * watchUpdateRepeatOrder () {
  yield takeLatest(UPDATE_REPEAT_ORDER, updateRepeatOrder);
}

export function * watchSetDefaultDeliveryAddress () {
  yield takeLatest(SET_DEFAULT_DELIVERY_ADDRESS, setDeliveryAddress);
}

export function * watchChangeDeliveryAddress () {
  yield takeLatest(CHANGE_DELIVERY_ADDRESS, changeDeliveryAddress);
}

export function * watchSetScrollEventOnReturnFromAddressBook () {
  yield takeLatest(SHOW_CREATE_NEW_ADDRESS_FORM, setScrollEventOnReturnFromAddressBook);
}

export function * setSubscriptionName (action) {
  yield put(change('createNewSubscriptionForm', 'orderReference', action.payload));
}

export function * watchSetDefaultSubscriptionName () {
  yield takeLatest(SET_SUBSCRIPTION_NAME, setSubscriptionName);
}

export function * watchRequestAllNotExpiredSavedCards () {
  yield takeLatest(REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS, requestAllNotExpiredSavedCards);
}

export function * watchStopSubscription () {
  yield takeLatest(STOP_SUBSCRIPTION, stopSubscription);
}

export function * watchAllRepeatOrdersActions () {
  yield all([
    fork(watchFetchRepeatOrders),
    fork(watchFetchRepeatOrdersWithoutPriceDetails),
    fork(watchDeleteRepeatOrder),
    fork(watchPausePlayRepeatOrder),
    fork(watchAddProductToRepeatOrder),
    fork(watchCreateRepeatOrder),
    fork(watchSaveNewDeliveryAddress),
    fork(watchFetchRepeatOrderById),
    fork(watchRemoveProductFromRepeatOrder),
    fork(watchAddProductRepeatOrder),
    fork(watchUpdateRepeatOrder),
    fork(watchSetDefaultDeliveryAddress),
    fork(watchSetScrollEventOnReturnFromAddressBook),
    fork(watchChangeDeliveryAddress),
    fork(watchSetDefaultSubscriptionName),
    fork(watchRequestAllNotExpiredSavedCards),
    fork(watchPaymentMethodUpdated),
    fork(watchInitialize),
    fork(watchCheckUserLoggedIn),
    fork(watchUpdateQuantity),
    fork(watchChangeExistingCard),
    fork(watchStopSubscription)
  ]);
}
