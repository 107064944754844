import React, { useState } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { SEND_PARTIAL_ORDER } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { FieldDatePicker } from 'client/components/elements/formField/FieldDatePicker';
import { getMinimumStopDate, getNumberOfScheduledOrders, getShiftedEndDate } from 'client/components/elements/scheduledOrders/utils';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { makeTextClickable } from 'client/utils/stockMessageUtil';
import { Price } from 'client/components/elements/productLine/Price';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { WEEKLY, FORTNIGHTLY, MONTHLY } from 'client/components/elements/scheduledOrders/constants';
import dayjs from 'dayjs';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons.js';
import TotalSaving from 'client/components/elements/TotalSaving/TotalSaving';

export const ScheduleOrdersForm = ({
  formValues,
  saveFormValues,
  earliestDeliveryDate,
  subscriptionPriceProps,
  isGoldOrAboveLoyaltyDiscount,
  isConfirmationScreenScheduledOrder,
  submitSubscription,
  isLoadingCreateSubscriptionButton
}) => {
  const isOrderLineShippingCost = subscriptionPriceProps?.linePriceCurrency?.amount < FREE_DELIVERY_THRESHOLD && !isGoldOrAboveLoyaltyDiscount;
  const startDate = new Date(formValues.startDate || earliestDeliveryDate);
  const endDate = formValues.endDate ? new Date(formValues.endDate) : null;
  const regularity = formValues.regularity;
  const numberOfOrders = getNumberOfScheduledOrders(endDate, startDate, regularity);
  const minStopDate = getMinimumStopDate(startDate, regularity);
  const isButtonLoading = isLoadingCreateSubscriptionButton || (isLoadingCreateSubscriptionButton === false && !formValues._id);

  const [isNumberOfOrdersFocused, setIsNumberOfOrdersFocused] = useState(false);

  const saveSettings = (startDate, endDate, regularity) => {
    const newStartDate = !dayjs(startDate).isValid() || dayjs(startDate).isBefore(earliestDeliveryDate) ? earliestDeliveryDate : startDate;
    return saveFormValues({
      startDate: dayjs(newStartDate).startOf('day').toDate(),
      endDate: dayjs(endDate).isValid() ? dayjs(endDate).startOf('day').toDate() : null,
      regularity: regularity ?? WEEKLY,
      outOfStockDeliveryPreference: SEND_PARTIAL_ORDER
    });
  };

  const startDateInputProps = {
    name: 'start-date',
    onChange: (date) => {
      saveSettings(date, null, regularity);
    }
  };

  const stopDateInputProps = {
    name: 'stop-date',
    onChange: (date) => {
      const newNoOfOrders = getNumberOfScheduledOrders(date, startDate, regularity);
      saveSettings(startDate, date, regularity, newNoOfOrders);
    }
  };

  const handleChangeNumberOfOrders = (ev) => {
    const inputValue = ev.target.value;

    if (!inputValue) {
      saveSettings(startDate, null, regularity);
      return;
    }

    const newNoOfOrders = inputValue === '20+' ? 20 : inputValue;
    const newStopDate = getShiftedEndDate(newNoOfOrders, startDate, regularity);
    saveSettings(startDate, newStopDate, regularity, newNoOfOrders);
  };

  const changeDeliveryFrequency = (ev) => {
    const newEndDate = getShiftedEndDate(numberOfOrders, startDate, ev.target?.value);
    saveSettings(startDate, newEndDate, ev.target?.value);
  };

  const handleCreateSubscription = () => {
    submitSubscription({
      startDate: dayjs(startDate).startOf('day').toDate(),
      endDate: dayjs(endDate).isValid() ? dayjs(endDate).startOf('day').toDate() : null,
      regularity: regularity ?? WEEKLY,
      outOfStockDeliveryPreference: SEND_PARTIAL_ORDER
    });
  };

  return <div className="scheduleOrderFormContainer">
    <form className="scheduleOrderForm row" data-e2e="scheduleOrderForm">
      <div className="col-xs-8 col-md-6" data-e2e="scheduleOrderForm-regularity">
        <label className="scheduleOrderForm_required">{getLocalizedString('scheduledOrders.settings.delivery.frequency')}</label>
        <select value={ regularity } onChange={ changeDeliveryFrequency }>
          <option value={ WEEKLY }>{getLocalizedString('scheduledOrders.settings.delivery.frequency.weekly')}</option>
          <option value={ FORTNIGHTLY }>{getLocalizedString('scheduledOrders.settings.delivery.frequency.fortnightly')}</option>
          <option value={ MONTHLY }>{getLocalizedString('scheduledOrders.settings.delivery.frequency.monthly')}</option>
        </select>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-7 col-md-4">
          <FieldDatePicker
            data-e2e="scheduleOrderForm-startDate"
            input={ startDateInputProps }
            initialDate={ startDate }
            updatedDate={ startDate }
            minDate={ earliestDeliveryDate }
            isClearable={ false }
            labelResourceName={ 'scheduledOrders.settings.start.date' }
            labelClassName="required"
            id="start-date"
            name="start-date"
            icon={ <SvgLoader className="inputCalendarIcon" xlinkHref="#date-picker-icon" /> }
            meta={{}} />
        </div>
        <div className={ `row col-xs-12 endSubscriptionGroup col-md-8` }>
          <div className="col-xs-5 noOfOrders" data-e2e="scheduleOrderForm-noOfOrders">
            <label htmlFor="no-of-orders">{getLocalizedString('scheduledOrders.no.of.orders')}</label>
            {
              numberOfOrders > 19 || isNumberOfOrdersFocused ? <input type="number" onChange={ handleChangeNumberOfOrders } value={ numberOfOrders || '' }
                onFocus={ () => setIsNumberOfOrdersFocused(true) }
                onBlur={ () => setIsNumberOfOrdersFocused(false) }
              />
                : <select
                  id="text-field-no-of-orders"
                  type="number"
                  value={ numberOfOrders || '' }
                  onChange={ handleChangeNumberOfOrders } >
                  {
                    ['', ...[...Array(20).keys() ].slice(1), '20+'].map(noOrders =>
                      <option key={ `orders-quantity-${noOrders}` } label={ noOrders } value={ noOrders } />)
                  }
                </select>
            }
          </div>
          <div className="col-xs-7">
            <FieldDatePicker
              data-e2e="scheduleOrderForm-endDate"
              input={ stopDateInputProps }
              initialDate={ endDate }
              updatedDate={ endDate }
              minDate={ minStopDate }
              isClearable={ true }
              placeholder="Until further notice"
              labelResourceName={ 'scheduledOrders.settings.stop.date' }
              id="stop-date"
              name="stop-date"
              icon={ <SvgLoader className="inputCalendarIcon" xlinkHref="#date-picker-icon" /> }
              meta={{}} />
          </div>
        </div>
      </div>
      <div className="col-xs-12 row">
        { isConfirmationScreenScheduledOrder
          ? <div className="col-xs-5 col-sm-4" data-e2e="scheduledOrders-createSubscription">
            {
              isButtonLoading
                ? <div className="UpdateSubscriptionDetailsForm_spinner" />
                : <PrimaryButton onClick={ handleCreateSubscription } className="createSubscriptionButton">{
                  getLocalizedString('scheduledOrders.create.scheduled.order')
                }</PrimaryButton>
            }
          </div> : null
        }
        { subscriptionPriceProps
          ? <div className="col-xs-6 col-sm-7" data-e2e="scheduleOrderForm-estimatedPrice">
            <span>{getLocalizedString('scheduledOrders.next.order.price')}</span>
            <Price { ...subscriptionPriceProps } />
            {!isGoldOrAboveLoyaltyDiscount ? <div className="ProductLine_totalSaving">
              <TotalSaving
                totalSaving={ subscriptionPriceProps.totalSaving }
                vat={ subscriptionPriceProps.vat }
                currency={ subscriptionPriceProps.currency }
                includeVat={ subscriptionPriceProps.isIncludingVat }
              />
            </div> : null }
          </div>
          : null
        }
      </div>
      {
        isOrderLineShippingCost ? <span className="gotoDeliveryInfo col-xs-12" data-e2e="scheduleOrderForm-gotoDeliveryInfo">
          <SvgLoader xlinkHref="#warning-circle"/>
          {makeTextClickable(getLocalizedStringWithParam('scheduledOrders.goto.delivery.info', { freeDeliveryThreshold: FREE_DELIVERY_THRESHOLD }), /delivery information/, '/info/delivery', '_blank')}
        </span> : null
      }
    </form>
  </div>;
};

export default ScheduleOrdersForm;
