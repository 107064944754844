import { takeLatest, call } from 'redux-saga/effects';

import { UPDATE_SESSION_CAM } from 'client/actions/sessionCamActions';
import { pushToSessionCam } from 'client/utils/sessionCamUtils';

export function * pushToSessionCamDataLayer (action) {
  if (process.browser) {
    try {
      yield call(pushToSessionCam, action.payload);
    } catch (e) {
      // console.log(e);
    }
  }
}

export function * watchSessionCamDataLayer () {
  yield takeLatest(UPDATE_SESSION_CAM, pushToSessionCamDataLayer);
}
