import { ApiRequest } from 'shared/utils/apiUtils';

export function getFullAddressWithPostcodeId (postcodeid) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/addresses/findAddress/${postcodeid}`
  });

  return req.execute()
    .then((res) => {
      return res.body;
    });
}

export function getPostcodeDelvZone (postcode) {
  const postalCode = postcode.trim();
  const code = encodeURIComponent(postalCode);
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/users/me/postalCode/${code}`
  });

  return req.execute()
    .then((res) => {
      return res.body;
    });
}
