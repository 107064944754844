import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import flowRight from 'lodash/flowRight';
import { showValidationMessageDialog } from 'client/actions/ui/dialogActions';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) { require('./ValidationMessageDialog.scss'); }

const ValidationMessageDialog = ({
  hideValidationDialog,
  validationDialog
}) => {
  const { isVisible, title, bodyText } = validationDialog;
  const onHideActionParams = { isVisible: !isVisible, title, bodyText };
  const onClickHandler = () => {
    hideValidationDialog(onHideActionParams);
  };
  return (
    <Modal className="ValidationMessageDialog_Modal" show={ isVisible } onHide={ onClickHandler }>
      <Modal.Header closeButton>
        <div className="ValidationMessageDialog_Modal__hgroup">
          <h4>
            { title }
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          { bodyText }
        </p>
        <div>
          <PrimaryButton
            text={ getLocalizedString('ui.dialog.okay') }
            onClick={ onClickHandler } />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    validationDialog: state.getIn(['ui', 'dialogs', 'validationDialog']).toJS()
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideValidationDialog: flowRight(dispatch, showValidationMessageDialog)
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationMessageDialog);
