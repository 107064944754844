import strings from './en-gb';
import React from 'react';

export function getLocalizedString (key) {
  const string = strings[key];
  return string || `*{${key}}*`;
}

export function formatLocalizedCurrency (value) {
  // TODO This will need to be smarter when moving to non prefixed and/or two decimal place currencies.
  return `${getLocalizedString('currencySymbol')}${!isNaN(value) ? value.toFixed(2) : 'NaN'}`;
}

export function getLocalizedStringWithParam (key, params) {
  return Object.keys(params)
    .reduce(
      (str, param) => str.replace(`{{${param}}}`, params[param]),
      getLocalizedString(key)
    );
}

export const getLocalizedHtmlWithParam = (key, params) => {
  const stringWithHTML = Object.keys(params)
    .reduce(
      (str, param) => str.replace(`{{${param}}}`, params[param]),
      getLocalizedString(key)
    );
  return (<span dangerouslySetInnerHTML={{ __html: stringWithHTML }}/>);
};

export const getLocalizedHtmlWithContactNumber = (key, contactNumberKey, isMobile) => {
  const contactNumber = getLocalizedString(contactNumberKey);
  const contactNumberHtml = isMobile
    ? `<a href='tel:${contactNumber}'> ${contactNumber} </a>`
    : contactNumber;
  return getLocalizedHtmlWithParam(key, { contactNumber: contactNumberHtml });
};
