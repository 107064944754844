import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { PasswordInput } from '@graingerglobalonline/curly-eureka';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./PasswordStrengthIndicator.scss');
}
const passwordIndicator = (props) => {
  const {
    input,
    className,
    label,
    labelResourceName,
    labelClassName,
    tooltip,
    id,
    formClassName,
    placeholderResourceName,
    updatePasswordChecklist,
    passwordStrength,
    validatePasswordChecklist,
    meta: { touched, error }
  } = props;
  return (
    <div className={ `PasswordStrengthContainer form-group ${formClassName || ''} ${(touched && error) ? 'has-error' : ''}` }>
      { id && <label htmlFor={ id } className={ labelClassName }>{ labelResourceName ? getLocalizedString(labelResourceName) : label }</label> }
      { tooltip && tooltip }
      <PasswordInput
        { ...input }
        className={ className }
        style={{ paddingRight: 60 }}
        placeholder={ placeholderResourceName ? getLocalizedString(placeholderResourceName) : null }
        onChange={ (event) => {
          updatePasswordChecklist(event.target.value);
          validatePasswordChecklist({ password: event.target.value });
          return input.onChange(event); } }
        type='password'
      />
      {
        passwordStrength === 'weak' && (
          <div className="my-password-input weak passwordStrength"> Weak <div className='my-password-input'> <SvgLoader xlinkHref="#red-alert"/></div> </div>
        )}
      {
        passwordStrength === 'average' && (
          <div className="my-password-input average passwordStrength">Average <div className='my-password-input'> <SvgLoader xlinkHref="#yellow-alert"/></div> </div>
        )}
      {
        passwordStrength === 'strong' && (
          <div className="my-password-input strong passwordStrength">Strong <div className='my-password-input'><SvgLoader xlinkHref="#green-success"/></div> </div>
        )}
    </div>
  );
};

passwordIndicator.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholderResourceName: PropTypes.string,
  datae2e: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelResourceName: PropTypes.string,
  tooltip: PropTypes.object,
  formClassName: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default passwordIndicator;
