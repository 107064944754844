import PropTypes from 'prop-types';
import React from 'react';
import IconDefs from './IconDefs';
import { Empty } from 'client/components/elements/empty/Empty';

const SvgLoader = ({ xlinkHref, ...other }) => {
  const iconName = xlinkHref.startsWith('#') ? xlinkHref.slice(1) : xlinkHref;

  if (!IconDefs[iconName]) {
    return (<Empty />);
  }

  return IconDefs[iconName](other);
};

SvgLoader.propTypes = {
  xlinkHref: PropTypes.string,
  viewBox: PropTypes.string
};

export default SvgLoader;
