import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import Row from 'react-bootstrap/lib/Row';
import { List } from 'immutable';
import { PENDING, ERROR } from 'shared/constants/loadStateType';
import { getLocalizedString } from 'localization/localizer';

import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import LoadError from './LoadError';
import CmsContentScreen from '../cmsContentScreen/CmsContentScreen';
import CartOrderLineList from 'client/components/elements/cartOrderLineList/CartOrderLineList';
import FamilyBottomRecommendations from 'client/components/elements/recommendations/composers/FamilyBottom';
import { RECOMMENDATIONS_LOCATIONS } from 'shared/constants/recommendation';
import { PrimaryLink, SecondaryButton } from 'client/components/controls/StyledForms/formButtons';
import { ASSETS_S3_LOCATION } from 'shared/constants/images';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./AddToBasketInterstitialScreen.scss');
}

const header = () => {
  const continueShopping = () => {
    browserHistory.goBack();
  };

  return (
    <div className="row Cart_interstitialScreen">
      <div className="Cart_interstitialScreen_leftBorder" />
      <div className="col-xs-12 col-sm-5">
        <span className="Cart_interstitialScreen_h1">{ getLocalizedString('cart.addToCart.hasBeenAddedToCart')}</span>
      </div>
      <div className="col-xs-12 col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-3">
        <SecondaryButton onClick= { continueShopping } className="Cart_interstitialScreen_continueShopping" text = { getLocalizedString('product.addToBasket.continueShopping') } />
      </div>
      <div className="col-xs-12 col-sm-3 col-md-2 pull-right goToCheckout">
        <PrimaryLink text={ getLocalizedString('cart.buttons.goToCheckout') } className="Cart_interstitialScreen_checkoutButton" to = "/cart" isExternalLink />
      </div>
    </div>
  );
};

const getContent = (
  loadState,
  setOrderLineQuantity,
  removeOrderLine,
  includeVat,
  toastSuccess,
  showFamilyRecommendationsOnCheckout,
  productCmsId,
  productVariantId,
  pageData,
  offers,
  skusData,
  dispatchRequestSkusData,
  addToCart,
  orderProductItem,
  qtyAddedToCart,
  setBundleQuantity,
  removeBundleOrderLine,
  orderBundleProductItem,
  primaryProductVariantId,
  isTradeAccount
) => {
  if (loadState === PENDING) {
    return <ScreenLoadPending />;
  }

  if (loadState === ERROR) {
    return <LoadError />;
  }
  const productSku = primaryProductVariantId || productVariantId;
  return (
    <React.Fragment>
      { header(orderProductItem.get(0), qtyAddedToCart, orderBundleProductItem.get(0)) }
      <Row>
        <div className="col-xs-12 col-md-12 Cart_interstitialScreen_cartOrderLine">
          { orderProductItem && orderProductItem.size
            ? <div>
              <div className="row">
                <div className="col-md-9">
                  <CartOrderLineList
                    orderLines={ orderBundleProductItem && orderBundleProductItem.size ? List() : orderProductItem }
                    setOrderLineQuantity={ setOrderLineQuantity }
                    removeOrderLine={ removeOrderLine }
                    isCartEditable={ true }
                    includeVat={ includeVat }
                    toastSuccess={ toastSuccess }
                    hideRemoveButton={ true }
                    bundles= { orderBundleProductItem }
                    setBundleQuantity= { setBundleQuantity }
                    removeBundleOrderLine= { removeBundleOrderLine }
                  />
                </div>
                { !isTradeAccount && <div className="col-md-3 Cart_interstitialScreen_businessAccountBannerContainer">
                  <a href='/info/zoro-business-account'>
                    <ZoroImage src={ `${ASSETS_S3_LOCATION}/mimg/info/zoro-business-account/20240514_Introduction_Business_Banner.png` }
                      alt="business_account_banner"
                      title="business_account_banner"
                      className="Cart_interstitialScreen_businessAccountBanner"/>
                  </a>
                </div>}
              </div>
              <div>
                {
                  showFamilyRecommendationsOnCheckout
                    ? <FamilyBottomRecommendations
                      addToCart={ addToCart }
                      location={ RECOMMENDATIONS_LOCATIONS.basket_interstitial }
                      isPriceWithVat={ includeVat }
                      disableModal />
                    : null

                }
              </div>
              <div>
                {productSku === productCmsId
                  ? <CmsContentScreen
                    pageData={ pageData }
                    offers={ offers }
                    skusData={ skusData }
                    dispatchRequestSkusData={ dispatchRequestSkusData }
                  /> : null }
              </div>
            </div> : <ScreenLoadPending /> }
        </div>
      </Row>
    </React.Fragment>
  );
};

const AddToBasketInterstitial = ({
  cart,
  setOrderLineQuantity,
  removeOrderLine,
  includeVat,
  showFamilyRecommendationsOnCheckout,
  addToCart,
  toastSuccess,
  productCmsId,
  productVariantId,
  pageData,
  offers,
  skusData,
  dispatchRequestSkusData,
  productItem,
  qtyAddedToCart,
  setBundleQuantity,
  removeBundleOrderLine,
  bundleProductItem,
  primaryProductVariantId,
  tradeAccount
}) => {
  const loadState = cart.getIn([ 'loadState', 'type' ]);
  const orderProductItem = productItem ? List([productItem]) : List();
  const orderBundleProductItem = bundleProductItem ? List([bundleProductItem]) : List();
  const isTradeAccount = tradeAccount ? !!tradeAccount.get('accountNo') : false;
  const content = getContent(
    loadState,
    setOrderLineQuantity,
    removeOrderLine,
    includeVat,
    toastSuccess,
    showFamilyRecommendationsOnCheckout,
    productCmsId,
    productVariantId,
    pageData,
    offers,
    skusData,
    dispatchRequestSkusData,
    addToCart,
    orderProductItem,
    qtyAddedToCart,
    setBundleQuantity,
    removeBundleOrderLine,
    orderBundleProductItem,
    primaryProductVariantId,
    isTradeAccount
  );

  return (
    <div className="Cart ProductLine_addtoCart">
      {content}
    </div>
  );
};

AddToBasketInterstitial.propTypes = {
  cart: PropTypes.object,
  orderLine: PropTypes.array,
  setOrderLineQuantity: PropTypes.func,
  removeOrderLine: PropTypes.func,
  isCartEditable: PropTypes.bool,
  onApplyDiscountCode: PropTypes.func,
  onRemoveDiscountCode: PropTypes.func,
  showAddToQuotationDialog: PropTypes.func,
  tradeAccount: PropTypes.object,
  toastSuccess: PropTypes.func,
  productCmsId: PropTypes.string,
  productVariantId: PropTypes.string,
  goToUrl: PropTypes.func,
  setBundleQuantity: PropTypes.func,
  removeBundleOrderLine: PropTypes.func
};

export default AddToBasketInterstitial;
