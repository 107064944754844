import Immutable from 'immutable';
import {
  ON_RESET_PASSWORD,
  ON_RESET_PASSWORD_SUCCESS,
  ON_RESET_PASSWORD_ERROR
} from 'client/actions/userActions';

export const defaultState = Immutable.fromJS({});

const resetPasswordFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ON_RESET_PASSWORD_ERROR:
      return state.set('error', action.error);
    case ON_RESET_PASSWORD_SUCCESS:
    case ON_RESET_PASSWORD:
      return state.set('error', undefined);
    default:
      return state;
  }
};

export default resetPasswordFormReducer;
