export function pushToSessionCam (data) {
  if (!data) {
    throw Error('No data was provided to pushToSessionCam function');
  }
  sessioncamConfiguration.customDataObjects.push(data); // eslint-disable-line
}

export function clearSessionCam () {
  sessioncamConfiguration.customDataObjects.length = 0; // eslint-disable-line
}
