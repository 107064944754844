import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { reduxForm, Field, formValueSelector, SubmissionError, getFormSyncErrors, isValid } from 'redux-form/immutable';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { makeValidator, defaultAddressFormValidator } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import PostcodeInput from 'client/components/elements/postcodeInput/postcodeInput';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { toggleEnterManually } from 'client/actions/ui/postCodeActions';
import { BILLING_ADDRESS_FORM_NAME, DELIVERY_ADDRESS_FORM_NAME, PERSONAL } from 'shared/constants/singlePageCheckout';

if (process.browser) {
  require('./AddressForm.scss');
}

const validate = makeValidator(defaultAddressFormValidator);
class AddressForm extends PureComponent {
  render () {
    const {
      isEditingAddress,
      showCancelButtonOnAddressForm,
      addressIdBeingEdited,
      handleSubmit,
      quickFindPostCodeValue,
      companyName,
      addressLine1,
      addressLine2,
      form: formName,
      city,
      stateValue,
      postalCode,
      expandPostCode,
      isPostCodeManuallyEntered,
      onAddressSubmit,
      handleBackButtonClick,
      showCta = true,
      isPayment,
      addressName
    } = this.props;

    const expandPostCodeInput = !!isPostCodeManuallyEntered;
    const submit = async (values, dispatch) => {
      try {
        await validateForm(values, validate);
        const addressName = values.get('addressName') || values.get('addressLine1');
        // The space before addressLine2 on the line below is important
        const secondAddressLine = values.get('addressLine2') ? ` ${values.get('addressLine2')}` : '';
        const addressNickName = values.get('addressLine1') + secondAddressLine;
        const addressId = values.get('addressId');
        const { ...rest } = values.toJS();
        onAddressSubmit({ ...rest, addressId, addressName, addressNickName, addressIdBeingEdited, isDefault: true });
      } catch (errors) {
        throw new SubmissionError(errors);
      }
    };

    const onSubmitClick = () => {
      expandPostCode();
      handleSubmit(submit)();
    };

    const onCancelClick = () => {
      handleBackButtonClick(addressIdBeingEdited);
    };

    const formCta = () => {
      return (
        <div className={ `AddressForm_formCta` }>
          { showCancelButtonOnAddressForm ? <SecondaryButton
            className={ `AddressForm_formCta_cancelButton` }
            onClick={ onCancelClick } text="Cancel"
            datae2e="cancel save address"
          /> : null }
          <PrimaryButton
            className={ `AddressForm_formCta_saveButton` }
            onClick={ onSubmitClick } text="Save"
            datae2e="submit"
          />
        </div>
      );
    };

    return (
      <div className="AddressForm">
        <div className="AddressForm_DeliverTo">{ formName === DELIVERY_ADDRESS_FORM_NAME ? getLocalizedString('singlePageCheckout.deliverTo') : null }</div>
        <div className="AddressForm_FormDetails form-group">
          <form>
            <div className="AddressForm_FormDetails_ContactName row">
              <div className={ `${isPayment ? 'col-md-6' : 'col-md-4'}` }>
                <Field
                  name="firstName"
                  id="firstName"
                  className="AddressForm_formGroup_input form-control"
                  labelClassName="AddressForm_formGroup_label control-label required"
                  type="text"
                  component={ FieldWithValidations }
                  labelResourceName="addressForm.label.firstName"
                  datae2e="firstName" />
              </div>
              <div className={ `${isPayment ? 'col-md-6' : 'col-md-4'}` }>
                <Field
                  name="lastName"
                  id="lastName"
                  className="AddressForm_formGroup_input form-control"
                  labelClassName="AddressForm_formGroup_label control-label required"
                  type="text"
                  component={ FieldWithValidations }
                  labelResourceName="addressForm.label.lastName"
                  datae2e="lastName" />
              </div>
            </div>
            <div className="AddressForm_FormDetails_PhoneNo row">
              <div className={ `${isPayment ? 'col-md-6' : 'col-md-4'}` }>
                <Field
                  name="addressPhoneNumber"
                  id="addressPhoneNumber"
                  className="PhoneNumberInput form-control"
                  labelClassName="AddressForm_formGroup_label control-label required"
                  type="text"
                  component={ FieldWithValidations }
                  labelResourceName="addressForm.label.telephoneNumber"
                  datae2e="telephoneNumber" />
              </div>
              <div className={ `${isPayment ? 'col-md-6' : 'col-md-4'}` }>
                <Field
                  name="addressType"
                  id="addressType"
                  className="AddressForm_formGroup_input form-control"
                  labelClassName="AddressForm_formGroup_label control-label required"
                  type="text"
                  disabled
                  component={ FieldWithValidations }
                  labelResourceName="addressForm.label.addressType"
                  datae2e="addressType" />
              </div>
            </div>
            <div className="AddressForm_FormDetails_DeliverAddressSection">{
              formName === DELIVERY_ADDRESS_FORM_NAME
                ? isEditingAddress ? getLocalizedString('singlePageCheckout.editDeliveryAddress') : getLocalizedString('singlePageCheckout.addressForm.label.deliveryAddress')
                : null }</div>
            <div className="AddressForm_FormDetails_PostCode row">
              <div className={ expandPostCodeInput || isEditingAddress ? `${formName === BILLING_ADDRESS_FORM_NAME ? 'col-md-12' : 'col-md-8'}` : 'col-md-4' }>
                <PostcodeInput
                  quickFindPostCodeValue={ quickFindPostCodeValue }
                  addressName={ addressName }
                  companyName={ companyName }
                  addressLine1={ addressLine1 }
                  form={ formName }
                  addressLine2={ addressLine2 }
                  city={ city }
                  state={ stateValue }
                  postCode={ postalCode }
                  selector={ formName }
                  isEditAddress = { isEditingAddress }
                  isManuallyEntered = { isEditingAddress }
                  isSinglePageCheckout = { true }
                  addressId = { addressIdBeingEdited }
                />
              </div>
            </div>
            { showCta ? formCta() : null }
          </form>
        </div>
      </div>

    );
  }
}

const formValueSelectors = (state, formName) => {
  const selector = formValueSelector(formName);
  const quickFindPostCodeValue = selector(state, 'quickFindPostCode');
  const companyName = selector(state, 'companyName');
  const addressLine1 = selector(state, 'addressLine1');
  const addressName = selector(state, 'addressName');
  const postalCode = selector(state, 'postalCode');
  const addressLine2 = selector(state, 'addressLine2');
  const stateValue = selector(state, 'state');
  const city = selector(state, 'city');
  const addressType = selector(state, 'addressType');

  return {
    quickFindPostCodeValue,
    companyName,
    addressLine1,
    addressName,
    postalCode,
    addressLine2,
    stateValue,
    city,
    addressType
  };
};

const checkformSyncErrors = (state, formName) => {
  const errors = getFormSyncErrors(formName)(state) || {};
  return Object
    .entries(errors)
    .filter((error) => error[1]);
};

const mapStateToProps = (state, ownProps) => {
  const { formName, address } = ownProps;
  return {
    initialValues: {
      ...address,
      addressPhoneNumber: address.phoneNumbers ? address.phoneNumbers.mobile : address.addressPhoneNumber,
      addressType: address.addressType || PERSONAL
    },
    formSyncErrors: checkformSyncErrors(state, formName),
    isFormValid: isValid(formName)(state),
    isPostCodeManuallyEntered: state.getIn(['ui', 'postCode', formName, 'isManuallyEntered']),
    ...formValueSelectors(state, formName)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    expandPostCode: () => dispatch(toggleEnterManually(formName, true))
  };
};

let ReduxFormAddress = reduxForm({
  validate,
  enableReinitialize: true
}
)(AddressForm);

ReduxFormAddress = connect(mapStateToProps, mapDispatchToProps)(ReduxFormAddress);
export { AddressForm };
export default ReduxFormAddress;
