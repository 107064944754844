import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import debounce from 'lodash/debounce';
import NewStockMessage from 'client/components/elements/newStockMessage/NewStockMessage';
import { Empty } from 'client/components/elements/empty/Empty';
import PromoLabel from 'client/components/elements/promoLabel/PromoLabel';
import { showAddToBasketDialog } from 'client/actions/ui/dialogActions';
import SvgLoader from 'client/components/svg/SvgLoader';
import { toastSuccess } from '../../../actions/showNotificationsActions';
import { newBreakPriceObject, isEqualObject } from 'client/utils/priceUtils';
import QuantityInput from '../quantityInput/quantityInput';
import { calculateZoroPromoRounding } from '../../../utils/discountUtils';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { qtyAddedToCart } from 'client/actions/cartActions';
import { fromJS } from 'immutable';
import AddToBasketButton from '../addToBasketButton/AddToBasketButton';
import QuantityErrorMessage from 'client/components/elements/quantityInput/quantityErrorMessage';
import { isValidQuantityWithMax, isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import { RECOMMENDATIONS_LOCATIONS } from 'shared/constants/recommendation';
import AddToQuotationButtonInterstitial from 'client/components/elements/addToQuotationButtonInterstitial/AddToQuotationButtonInterstitial';
import { IS_AVAILABLE_ON_LONG_LEADTIME, IS_OUT_OF_STOCK, IS_OBSOLETE_AND_HAS_STOCK, IS_UNAVAIABLE } from 'client/utils/stockMessageUtil';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import RequestPriceButton from '../requestPriceButton/RequestPriceButton';
import { goToUrl } from 'client/actions/routeActions';

if (process.browser) { require('./ProductAddToCart.scss'); }

const DEBOUNCE_DELAY = 300;
const TOATSER_DELAY = 5000;

const getAddToCartPriceHeader = (isQtyMultiplier, showHideDisplayPrice, qtyMultiplier, displayPrice, priceVatTagLabel, isPOA) => {
  return (<>
    {
      isQtyMultiplier
        ? <span className={ `PriceDisplay_qtyOf hidden-xs hidden-sm ${showHideDisplayPrice}` }>
          { getLocalizedStringWithParam('product.qtyOf', { qty: qtyMultiplier }) }
        </span>
        : null }
    { !isPOA ? <h3 className={ `PriceDisplay_Price PriceDisplay_Price${isQtyMultiplier ? '-qtyMultiplier' : ''} hidden-xs hidden-sm ${showHideDisplayPrice}` }>
      <strong>{ displayPrice }</strong>
      { priceVatTagLabel
        ? <span className="PriceDisplay_Price-priceVatSpan">
          { priceVatTagLabel }
        </span>
        : null
      }
    </h3> : null }
  </>
  );
};

export class ProductAddToCart extends Component {
  constructor (props) {
    super(props);
    const qtyMultiplier = props.product.getIn(['price', 'qtyMultiplier'], '1');
    const minOrderQuantity = props.product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
    this.state = {
      quantity: props.initialQuantity !== undefined ? props.initialQuantity : minOrderQuantity,
      disabled: false,
      inputStyle: 'success',
      previousBreakPriceObject: props.breakPrices ? props.breakPrices[0] : {}
    };

    this.addToCart = this.addToCart.bind(this);
    this.requestPrice = this.requestPrice.bind(this);
    this._debouncedSetQuantity = null;
  }

  componentWillReceiveProps (nextProps) { // eslint-disable-line react/no-deprecated
    if (nextProps.initialQuantity && this.state.quantity !== nextProps.initialQuantity) {
      this.setState({ quantity: nextProps.initialQuantity });
    }
  }

  componentWillUpdate (nextProps, nextState) { // eslint-disable-line react/no-deprecated
    if (this.state.quantity !== nextState.quantity) {
      if (this.props.updateVariantStockInfo) {
        if (nextState.quantity > 0) {
          this.props.updateVariantStockInfo({
            sku: this.props.sku,
            quantity: nextState.quantity,
            familyId: this.props.familyId
          });
        }
      }
    }
  }

  validateBreakQuantityAndUpdatePrice = (qty, breakPrices, appliedDiscounts) => {
    const { toastSuccess, updatePriceWithBreakPrice, sku, familyId, recommendationType } = this.props;
    const foundNewBreakPriceObject = newBreakPriceObject(qty, breakPrices);
    if (updatePriceWithBreakPrice) {
      updatePriceWithBreakPrice({ ...foundNewBreakPriceObject, sku, familyId, recommendationType, appliedDiscounts });
    }

    if (qty !== 0 &&
      foundNewBreakPriceObject &&
      foundNewBreakPriceObject.breakDiscount &&
      foundNewBreakPriceObject.breakDiscount !== 0 &&
      foundNewBreakPriceObject.breakDiscount !== undefined &&
      !isEqualObject(this.state.previousBreakPriceObject, foundNewBreakPriceObject)) {
      const discount = foundNewBreakPriceObject.breakDiscount;
      const discountToRounded = calculateZoroPromoRounding(discount);
      toastSuccess(
        getToastMessage(
          getLocalizedStringWithParam('cart.toast.message.discount.got', { discountToRounded })
        ),
        'top-right',
        TOATSER_DELAY
      );
    }

    if (!isEqualObject(this.state.previousBreakPriceObject, foundNewBreakPriceObject)) {
      this.setState({
        previousBreakPriceObject: foundNewBreakPriceObject
      });
    }
  }

  _cancelSetQuantity () {
    if (this._debouncedSetQuantity) {
      this._debouncedSetQuantity.cancel();
    }
  }

  _debouncedAction () {
    this._cancelSetQuantity();
    const [fn, ...rest] = arguments;
    this._debouncedSetQuantity = debounce(() => {
      fn.apply(this, rest);
    }, DEBOUNCE_DELAY);
    this._debouncedSetQuantity();
  }

  handleChange = (value) => {
    const qty = parseInt(value, 10);

    if (qty > 0) {
      this.setState(this.validationState(qty));
      this._debouncedAction(this.validateBreakQuantityAndUpdatePrice, qty, this.props.breakPrices, this.props.appliedDiscounts);
      if (this.props.onQtyChange) {
        this.props.onQtyChange(parseInt(qty, 10), this.state.previousBreakPriceObject);
      }
    } else if (qty === 0) { // always change from 0 to 1 so they cannot set 0 -> add to basket
      this.setState({ quantity: 1, disabled: false });
    } else {
      this._cancelSetQuantity();
      this.setState({ quantity: value ? qty : '', disabled: true });
    }
  }

  validationState (qty) {
    const quantity = parseInt(qty, 10);
    const inputStyle = (quantity > 0 && typeof quantity === 'number')
      ? 'success'
      : 'error';
    const disabled = inputStyle === 'error';
    return { inputStyle, disabled, quantity };
  }

  addToCart () {
    const {
      addToCart,
      qtyAddedToCart,
      sku,
      isRecommendations,
      basketDropDown
    } = this.props;

    const { inputStyle, quantity } = this.state;
    if (inputStyle === 'success') {
      const orderLinesToBeAdded = [
        {
          qty: quantity,
          sku
        }
      ];

      const payload = {
        isRecommendations: isRecommendations,
        orderLinesToBeAdded,
        location: RECOMMENDATIONS_LOCATIONS.basket_interstitial,
        basketDropDown
      };
      qtyAddedToCart(quantity);
      addToCart(payload);
    }
  }

  requestPrice () {
    const { sku, goToUrl } = this.props;
    const { quantity } = this.state;
    const url = `/contact-us?sku=${sku}&qty=${quantity}`;
    goToUrl(url);
  }

  stopPropagation (e) {
    e.stopPropagation();
  }

  submitHandler (event) {
    event.preventDefault();
  }

  _getQuantityInput () {
    const { qtyText, qtyChangeDisabled, product, displayQuantityDropdownAsModal, quantityDropdownDirection } = this.props;
    const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
    const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
    const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);

    return (
      <div className="has-success">
        { qtyText && <label
          htmlFor="ProductAddToCart_Quantity"
          className="control-label"
        >
          { qtyText }
        </label>
        }
        <QuantityInput
          disabled={ !!qtyChangeDisabled }
          productAddToCartHandler={ this.handleChange }
          quantity={ this.state.quantity }
          unavailableStatus={ true }
          productAddToCart = { true }
          qtyMultiplier= { qtyMultiplier }
          minOrderQuantity = { minOrderQuantity }
          maxOrderQuantity = { maxOrderQuantity }
          displayQuantityDropdownAsModal = { displayQuantityDropdownAsModal }
          quantityDropdownDirection = { quantityDropdownDirection }
        />
      </div>
    );
  }

  _getAddToCartButton (displayPrice = '', priceObj,
    showBasketIcon, buttonClassName, isDeliverable, isQuantityValid, isPOA) {
    if (this.props.isIconView) {
      return this._getAddToCartIconButton(displayPrice, priceObj, isDeliverable, isQuantityValid, isPOA);
    }
    return this._getAddToCartBoxButton(displayPrice, priceObj, showBasketIcon, buttonClassName, isDeliverable, isQuantityValid, isPOA);
  }

  _getAddToCartBoxButton (displayPrice, priceObj, showBasketIcon, buttonClassName, isDeliverable, isQuantityValid, isPOA) {
    const showHideDisplayPrice = displayPrice ? '' : 'hideDisplayPrice';
    const addToCartButtonDisplay = buttonClassName ? buttonClassName + '_add_to_cart' : '';
    const { product } = this.props;
    const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
    const isQtyMultiplier = qtyMultiplier > 1;
    return (
      <div className={ addToCartButtonDisplay }>
        { getAddToCartPriceHeader(isQtyMultiplier, showHideDisplayPrice, qtyMultiplier, displayPrice, isPOA) }
        {
          priceObj && priceObj.get('isInPromo') && !isPOA &&
          (<p className={ `ProductVariantPrice_OldPrice hidden-xs hidden-sm ${showHideDisplayPrice}` }>
            <PromoLabel
              oldPrice={ priceObj.get('packWasPriceWithVat') }
              finalDiscount={ priceObj.get('finalPromoDiscount') }

              currency={ priceObj.get('currency') }
              discount={ priceObj.get('promoDiscount') }
              label={ priceObj.get('promoLabel') }
              promoUrl={ priceObj.get('promoUrl') }
            />
          </p>)
        }
        { isPOA
          ? <RequestPriceButton
            datae2e={ 'request-price-button' }
            type="button"
            onClick={ this.requestPrice }
            buttonClassName={ buttonClassName }
          />
          : <AddToBasketButton
            datae2e={ 'add-to-basket' }
            type="button"
            active={ !(this.state.disabled || !isDeliverable || !isQuantityValid) }
            disabled={ this.state.disabled || !isDeliverable || !isQuantityValid }
            onClick={ this.addToCart }
            showBasketIcon={ showBasketIcon }
            buttonClassName={ buttonClassName }
          />
        }
      </div>
    );
  }

  _getAddToCartIconButton (displayPrice, priceObj, isDeliverable, isQuantityValid, isPOA) {
    const showHideDisplayPrice = displayPrice ? '' : 'hideDisplayPrice';

    const { qtyChangeDisabled, product, priceVatTagLabel, displayQuantityDropdownAsModal, quantityDropdownDirection } = this.props;

    const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
    const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
    const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);
    const isQtyMultiplier = qtyMultiplier > 1;
    const addToCartClassName = `add_to_cart ${!isQtyMultiplier ? 'productVarientTable' : ''}`;

    return (
      <div className={ addToCartClassName }>

        <div className="ProductAddToCart_productPriceFixedWidth">
          { getAddToCartPriceHeader(isQtyMultiplier, showHideDisplayPrice, qtyMultiplier, displayPrice, priceVatTagLabel, isPOA) }
          {
            priceObj && priceObj.get('isInPromo') === true && !isPOA &&
            (<p className={ `ProductVariantPrice_OldPrice hidden-xs hidden-sm ${showHideDisplayPrice}` }>
              <PromoLabel
                oldPrice={ priceObj.get('packWasPriceWithVat') }
                finalDiscount={ priceObj.get('finalPromoDiscount') }

                currency={ priceObj.get('currency') }
                discount={ priceObj.get('promoDiscount') }
                label={ priceObj.get('promoLabel') }
                promoUrl={ priceObj.get('promoUrl') }
              />
            </p>)
          }
        </div>

        <div>
          <QuantityInput
            disabled={ !!qtyChangeDisabled }
            productAddToCartHandler={ this.handleChange }
            quantity={ this.state.quantity }
            unavailableStatus={ true }
            productAddToCart = { true }
            qtyMultiplier = { qtyMultiplier }
            minOrderQuantity = { minOrderQuantity }
            maxOrderQuantity = { maxOrderQuantity }
            displayQuantityDropdownAsModal = { displayQuantityDropdownAsModal }
            quantityDropdownDirection = { quantityDropdownDirection }
          />
        </div>

        <div>
          <button
            data-e2e={ 'add-to-basket' }
            type="button"
            className={ 't-add-to-basket-btn' }
            disabled={
              this.state.disabled || !isDeliverable || !isQuantityValid
            }
            onClick={ this.addToCart }>
            <SvgLoader xlinkHref="#shopping-cart" className="cart-plus" title="Add To Cart" />
            { this.props.displayText && <span className='displayText'> Add To Basket </span> }
          </button>
        </div>
      </div>
    );
  }

  _getAddToCartForm (
    displayPrice,
    priceObj,
    hideAddToBasketButton,
    showBasketIcon,
    buttonClassName,
    showUnavailable,
    isDeliverable,
    isQuantityValid,
    productsToAddCallback,
    showQuotationButton,
    productVariant,
    status,
    isPOA
  ) {
    const stockStatusToShowCart = [IS_AVAILABLE_ON_LONG_LEADTIME, IS_OUT_OF_STOCK, IS_OBSOLETE_AND_HAS_STOCK, IS_UNAVAIABLE];
    return isDeliverable || stockStatusToShowCart.includes(status)
      ? (
        <form
          className="form-inline ProductAddToCart_Form"
          onClick={ this.stopPropagation }
          onSubmit = { this.submitHandler }
        >
          <div className="ProductAddToCart_Form-Content">
            { this.props.isIconView ? null : this._getQuantityInput() }
            { hideAddToBasketButton
              ? null
              : this._getAddToCartButton(
                displayPrice,
                priceObj,
                showBasketIcon,
                buttonClassName,
                isDeliverable,
                isQuantityValid,
                isPOA
              )
            }
          </div>
          { showQuotationButton && !isPOA
            ? <AddToQuotationButtonInterstitial
              isDeliverable={ isDeliverable }
              isQuantityValid={ isQuantityValid }
              productVariant={ productVariant }
              productsToAddCallback={ productsToAddCallback }
              redisplayDialogOnLogin
              buttonClassName={ buttonClassName }
            />
            : null
          }
        </form>
      )
      : showUnavailable ? <span>{getLocalizedString('product.addToBasket.unavailable')}</span> : <Empty />;
  }
  render () {
    const {
      className,
      hideStockMessage,
      displayPrice,
      priceObj,
      renderMicrodataAttr,
      product,
      hideAddToBasketButton,
      isPLA,
      metaUrl,
      showBasketIcon,
      buttonClassName,
      showUnavailable,
      sku,
      displayAvailabilityHeader,
      isRecommendations,
      productsToAddCallback,
      showQuotationButton,
      longQtyErrorMessage
    } = this.props;
    const productPackPriceWithVat = product.getIn(['price', 'packPriceWithVat']);
    const productCurrency = product.getIn(['price', 'currency']);
    const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
    const stock = product.get('stock');
    const stockMessageToJS = stock ? stock.get('stockMessage', fromJS({})).toJS() : undefined;
    const isDeliverable = (stockMessageToJS) && stockMessageToJS.isDeliverable;
    const status = stockMessageToJS && stockMessageToJS.status;
    const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
    const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);
    const fulfillmentType = product.getIn(['stock', 'fulfillmentType']);
    const isPOA = fulfillmentType === 5;
    const isQuantityValid = isValidQuantityWithMinMaxMultiplier(this.state.quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    const priceWithMinOrderQty = (productPackPriceWithVat * minOrderQuantity).toFixed(2);

    const isQtyMultiplier = qtyMultiplier > 1;
    const isMinOrderQuantity = minOrderQuantity > 1;
    const isMaxOrderQuantity = isValidQuantityWithMax(this.state.quantity, maxOrderQuantity);

    return (
      <div className={ isPLA ? `ProductAddToCart ${className} pla` : `ProductAddToCart ${className}` }>
        <div className='quantityErrMsgContainer'>
          { isQtyMultiplier || isMinOrderQuantity || !isMaxOrderQuantity
            ? <div className={ !showBasketIcon && this.props.isIconView ? `ProductAddToCart_errorMessage` : '' }>
              {
                <QuantityErrorMessage
                  quantity= { this.state.quantity }
                  qtyMultiplier = { qtyMultiplier }
                  shortMessage = { !longQtyErrorMessage }
                  minOrderQuantity = { minOrderQuantity }
                  maxOrderQuantity = { maxOrderQuantity }
                />
              }
            </div>
            : <Empty />
          }
        </div>
        {
          this._getAddToCartForm(
            displayPrice,
            priceObj,
            hideAddToBasketButton,
            showBasketIcon,
            buttonClassName,
            showUnavailable,
            isDeliverable,
            isQuantityValid,
            productsToAddCallback,
            showQuotationButton,
            product,
            status,
            isPOA
          )
        }

        {
          stock && !hideStockMessage && !isPOA
            ? <NewStockMessage
              renderMicrodataAttr={ renderMicrodataAttr }
              stockStatus = { stockMessageToJS }
              productCurrency={ productCurrency }
              metaUrl = { metaUrl }
              sku = { sku }
              displayAvailabilityHeader = { displayAvailabilityHeader }
              isRecommendations = { isRecommendations }
              priceWithMinOrderQty = { priceWithMinOrderQty }
            />
            : <Empty />
        }
      </div>
    );
  }
}

ProductAddToCart.defaultProps = {
  className: '',
  hideStockMessage: false
};

ProductAddToCart.propTypes = {
  sku: PropTypes.string,
  productName: PropTypes.string,
  img: PropTypes.string,
  addToCart: PropTypes.func,
  updateVariantStockInfo: PropTypes.func,
  buttonSize: PropTypes.string,
  qtyText: PropTypes.string,
  className: PropTypes.string,
  stock: PropTypes.object,
  hideStockMessage: PropTypes.bool,
  familyId: PropTypes.string,
  onQtyChange: PropTypes.func,
  hideAddToBasketButton: PropTypes.bool,
  isPLA: PropTypes.bool,
  qtyChangeDisabled: PropTypes.bool,
  breakPrices: PropTypes.array,
  updatePriceWithBreakPrice: PropTypes.func,
  toastSuccess: PropTypes.func,
  displayText: PropTypes.bool,
  metaUrl: PropTypes.string
};

// export default ProductAddToCart;
const mapDispatchToProps = (dispatch) => ({
  showAddToBasketDialog: flowRight(dispatch, showAddToBasketDialog),
  toastSuccess: flowRight(dispatch, toastSuccess),
  qtyAddedToCart: flowRight(dispatch, qtyAddedToCart),
  goToUrl: (url) => dispatch(goToUrl(url))
});

export default connect(null, mapDispatchToProps)(ProductAddToCart);
