import { createAction } from 'redux-actions';

export const CHANGE_PAYMENT_METHOD = 'paymentMethod.CHANGE_PAYMENT_METHOD';
export const changePaymentMethod = createAction(CHANGE_PAYMENT_METHOD);

export const INITIALIZE = 'paymentMethod.INITIALIZE';
export const initialize = createAction(INITIALIZE);

export const CLEAR_PAYMENT_METHOD = 'paymentMethod.CLEAR_PAYMENT_METHOD';
export const clearPaymentMethod = createAction(CLEAR_PAYMENT_METHOD);
