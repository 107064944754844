import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import { reduxForm, Field } from 'redux-form/immutable';
import { getLocalizedString } from 'localization/localizer';
import {
  hideTermsandConditionsDialog,
  showTermsandConditionsDialog
} from 'client/actions/ui/dialogActions';

import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { PrimaryButton } from '../../controls/StyledForms';

if (process.browser) {
  require('./DiscountCode.scss');
}

const onShowTermsandConditionsDialog = (showTermsandConditionsDialog) => (e) => {
  e.preventDefault();
  showTermsandConditionsDialog();
};

const tsAndCsTemplate = (tsAndCs) => {
  if (!tsAndCs || tsAndCs.length === 0) {
    return getLocalizedString('discount.coupon.noTermsAndConditions');
  }

  return (
    <ul className="ordered__list">
      { tsAndCs.map((tAndC, idx) => <li key={ idx } className="ordered__list__item">{ tAndC }</li>) }
    </ul>
  );
};

const ModalWindow = ({ isVisible, hideTermsandConditionsDialog, discountTermsAndConditions }) => (
  <Modal
    className="modal discountCodeModal"
    show={ isVisible }
    onHide={ hideTermsandConditionsDialog }>
    <Modal.Header
      className="modal__Header"
      closeButton>
      <h2 className="modal__title">Discount Voucher / Promotional Code Terms &amp; Conditions</h2>
    </Modal.Header>
    <Modal.Body>
      <div className="modal__content">
        { tsAndCsTemplate(discountTermsAndConditions) }
      </div>
    </Modal.Body>
  </Modal>
);

// ! hack to block browser extensions from submitting the form
const randomValue = `discountCode${Date.now().toString()}`;

export const DiscountCode = (props) => {
  const {
    handleSubmit,
    discountWarning,
    discountIsValid,
    discountCodeApplied = null,
    onApplyDiscountCode,
    onRemoveDiscountCode,
    showTermsandConditionsDialog,
    eligibleForDeliveryOptions,
    applyingDiscountCode,
    toggleApplyDiscountCode,
    saleDays,
    isSaleActive,
    addressId,
    discountNotAppliedProduct,
    ...others
  } = props;
  const submit = (values) => {
    // Check coupon code isn't empty
    if (values.get(randomValue)) {
      onApplyDiscountCode({
        discountCode: values.get(randomValue),
        form: 'discountCodeInput',
        eligibleForDeliveryOptions,
        addressId
      });
    }
  };

  let content = (
    <div>
      <Field
        name={ randomValue }
        id={ randomValue }
        formClassName="DiscountCode_FormGroup"
        className="DiscountCode_FormInput form-control"
        labelClassName="sr-only"
        type="text"
        disabled={ isSaleActive || applyingDiscountCode || !toggleApplyDiscountCode }
        component={ FieldWithValidations }
        label="Discount"
        datae2e="discountCode"
        onEnterSubmit={ handleSubmit(submit) }/>
      <PrimaryButton
        onClick={ handleSubmit(submit) }
        value="Apply"
        className="DiscountCode_Button"
        disabled={ isSaleActive || applyingDiscountCode || !toggleApplyDiscountCode }
        datae2e="couponInputSubmit"
      >
        { applyingDiscountCode ? <div className="DiscountCode_FormInput-applyingDiscountLoader"/> : getLocalizedString('discount.label.input') }
      </PrimaryButton>
    </div>
  );

  let label = (
    <div className="DiscountCode_Label">
    </div>
  );

  if (isSaleActive) {
    label = (
      <div className="DiscountCode_Label">{ getLocalizedString('discount.label.ongoingSale') }{ saleDays.get('saleName') || 'ongoing sale' }</div>
    );
  } else if (discountWarning) {
    label = (
      <div className="DiscountCode_Label DiscountCode_Warning">{ discountWarning }</div>
    );
  } else if (discountCodeApplied && discountIsValid) {
    content = (
      <div className="DiscountCode_Wrapper">
        <span className="DiscountCode_Code_Applied_Wrapper">
          <span className="DiscountCode_Code_Applied">{ discountCodeApplied }</span>
          &nbsp;{ getLocalizedString('discount.label.applied') }
        </span>

        <a
          className="DiscountCode_Remove"
          onClick={ (...args) => onRemoveDiscountCode({ ...args[0], eligibleForDeliveryOptions, addressId }) }>
          { getLocalizedString('discount.label.remove') }
        </a>
      </div>
    );

    label = (
      <div className="DiscountCode_Label">
        <a
          href="#"
          onClick={ onShowTermsandConditionsDialog(showTermsandConditionsDialog) }>View</a>
        &nbsp;{ getLocalizedString('discount.label.termsAndConditions') }
        {discountNotAppliedProduct ? <p className="DiscountCode_NotAppliedMsg">{ getLocalizedString('discount.not.applied.all.products.message') }</p> : null}
      </div>
    );
  }

  return (
    <div>
      <form
        data-e2e="DiscountCodeForm"
        className="DiscountCode_Form form-inline"
      >
        <div className="row">
          <div className="col-xs-12">
            <h5 className="DiscountCode_Title">{ getLocalizedString('discount.label.title') }</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            { content }
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            { label }
          </div>
        </div>
      </form>
      { ModalWindow(others) }
    </div>
  );
};

DiscountCode.propTypes = {
  handleSubmit: PropTypes.func,
  discountWarning: PropTypes.string,
  onApplyDiscountCode: PropTypes.func,
  onRemoveDiscountCode: PropTypes.func,
  discountCodeApplied: PropTypes.string,
  discountIsValid: PropTypes.bool,
  saleDays: PropTypes.object,
  isSaleActive: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isVisible: state.getIn(['ui', 'dialogs', 'discountTermsAndConditions', 'isVisible']),
  applyingDiscountCode: state.getIn(['cart', 'discount', 'applyingDiscountCodeRequest'], false),
  toggleApplyDiscountCode: state.getIn(['singlePageCheckout', 'toggleApplyDiscountCode'])
});

const mapDispatchToProps = (dispatch) => ({
  hideTermsandConditionsDialog: (e) => { dispatch(hideTermsandConditionsDialog()); },
  showTermsandConditionsDialog: (e) => { dispatch(showTermsandConditionsDialog()); }
});

let ReduxFormDiscountCode = connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountCode);

ReduxFormDiscountCode = reduxForm({
  form: 'discountCodeInput'
}
)(ReduxFormDiscountCode);

export default ReduxFormDiscountCode;
