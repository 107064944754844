import { ApiRequest } from 'shared/utils/apiUtils';

export const getStockInfo = function getStockInfo (sku, quantity) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/products/${sku}/stockinfo`,
    query: {
      quantity
    }
  });

  return req.executeAndGetBody();
};

export function getProductById (id) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/products/${id}`
  });
  return req.execute();
}

export function getProductBySkuWithOrderline (sku, quantity) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/product/${sku}`,
    query: {
      quantity
    }
  });
  return req.executeAndGetBody();
}
