import { takeLatest } from 'redux-saga/effects';
import { LOG_ACTION } from 'client/actions/logActions';

export function onLogAction (action) {
  try {
    // FIXME: we need to add the app dynamics logging here but temporarily added console log
    // console.log(action.payload.error); // eslint-disable-line no-console
    console.log(JSON.stringify(action.payload, null, 2)); // eslint-disable-line no-console
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }
}

export function * watchLogAction () {
  yield takeLatest(LOG_ACTION, onLogAction);
}
