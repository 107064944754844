import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveSale } from 'shared/utils/saleUtils';
import SvgLoader from 'client/components/svg/SvgLoader';
import HeaderMenu from './components/HeaderMenu';
import CategoryItem from './components/MenuItems/CategoryItem';
import UserItem from './components/MenuItems/UserItem';
import InfoRibbon from './components/MenuItems/InfoRibbon';
import DropdownCart from './components/MenuItems/DropdownCart';
import CartItem, { CartLink } from './components/MenuItems/CartItem';
import CategoriesList from './components/Categories/CategoriesList';
import WideRibbon from 'client/components/elements/wideRibbon/WideRibbon';
import { toggleDropdownMenu } from 'client/actions/ui/dropdownMenuActions';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';
import { isUserLoggedIn, hasValidLongSessionToken } from 'client/utils/routeUtils';
import { fetchGlobalQuotation, clearAddedProductToQuotation } from 'client/actions/globalQuotationActions';
import { fetchQuotations } from 'client/actions/quotationsActions';
import { List, fromJS } from 'immutable';
import SearchBarItem from './components/MenuItems/SearchBarItem';
import { toggleBasketConfirmationDropdown } from 'client/actions/ui/toggleElementActions';
import _isEqual from 'lodash/isEqual';
import { UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE } from 'shared/constants/abTesting';
import ConnectedUnleashWrapper from '../unleashABTest/ConnectedUnleashWrapper';
import MobileToggle from './MenuToggle';
import { CsAgentBanner } from './CsAgentBanner';
import { fetchCategories } from 'client/actions/ui/categoriesDropdownActions';
import HeaderInfoRibbon from './components/MenuItems/HeaderInfoRibbon';

if (process.browser) {
  require('./Header.scss');
}

/*
 Use Component because a "ref" is used in the parent component
 and "ref"s should not be used with stateless functions
 */

export class Header extends Component {
  componentDidMount () {
    this.props.fetchGlobalQuotation();
    this.props.fetchCategoriesAction();
    if (this.props.isUserLoggedIn) {
      this.props.fetchQuotations();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps (nextProps) {
    if (this.props.isLoggedInOrhasValidLongSessionToken !== nextProps.isLoggedInOrhasValidLongSessionToken && !this.props.quoteRef) {
      this.props.fetchGlobalQuotation();
    }
  }

  shouldComponentUpdate (nextProps) {
    if (!_isEqual(nextProps, this.props)) {
      return true;
    }
    return false;
  }

  render () {
    const { props } = this;
    const activeSale = getActiveSale(props.sale);
    const { searchFieldIsVisible, toggleDropdownMenu } = this.props;

    const nav = (
      <nav role="navigation" className={ `${props.searchFieldIsVisible ? 'search-is-visible' : ''}` }>
        <HeaderMenu>
          <CategoryItem { ...props } cssClass="hidden-md hidden-lg" />
          <CategoryItem { ...props } ref={ (node) => { this.anchorCI = node; } }
            cssClass={ `hidden-xs hidden-sm ${props.dropdownMenuVisible ? 'show' : 'hide'}Arrow` }
            hasMouseEvents
          />
          <SearchBarItem
            { ...props }
            searchFieldIsVisible={ searchFieldIsVisible }
            toggleDropdownMenu = { toggleDropdownMenu }
            cssClass="HeaderB_nav_item--search"/>
          <UserItem { ...props }
            cssClass={ `${props.myAccountListVisible ? 'show' : 'hide'}Arrow hidden-sm hidden-xs` }/>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div cssClass="hidden-sm hidden-xs">
            <ConnectedUnleashWrapper flag = { UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE }
              Dropdown = { <DropdownCart { ...props } /> }
              DefaultComponent = {
                <CartLink>
                  <CartItem { ...props } />
                </CartLink>
              }
            />
          </div>
        </HeaderMenu>
      </nav>
    );

    return (
      <div className="HeaderB HeaderBStickyMenu">
        <CsAgentBanner { ...props }/>
        <HeaderInfoRibbon/>
        <div className="HeaderB_bgStripe" />
        <div className="HeaderB_masthead HeaderB_Container_StickyMenu">
          <header role="banner" className="HeaderB_row">
            <div className="HeaderB_logo">
              <a className="HeaderB_logo--link" href="/" aria-label="Zoro">
                <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
              </a>
            </div>
            <div className="HeaderB_menu hidden-md hidden-lg">
              <UserItem { ...props }
                cssClass={ `${props.myAccountListVisible ? 'show' : 'hide'}Arrow` }/>
              <ConnectedUnleashWrapper flag = { UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE }
                Dropdown = { <DropdownCart { ...props } /> }
                DefaultComponent = {
                  <CartLink>
                    <CartItem { ...props } />
                  </CartLink>
                }
              />
              <MobileToggle />
            </div>
          </header>

          {nav}
          {
            props.dropdownMenuVisible
              ? <CategoriesList { ...props } anchorCI={ this.anchorCI } />
              : null
          }
        </div>

        <InfoRibbon/>
        { activeSale ? <WideRibbon sale={ activeSale } isMobile={ props.isMobile }/> : null }
      </div>
    );
  }
}

Header.propTypes = {
  sale: PropTypes.object
};

const mapStateToProps = (state) => {
  const dropdownMenuToShow = state.getIn(['showDropdownMenu', 'visibleDropdown'], VISIBLE_DROPDOWN.NONE);
  const isBasketConfirmationDropdown = state.getIn(['ui', 'toggleElement', 'showBasketConfirmationDropdown']);
  const unleashToggles = state.getIn(['unleash', 'toggles'], List()).toJS();
  return {
    lockedQuotes: state.getIn(['globalQuotation', 'lockedQuotes'], fromJS([])),
    quoteRefId: state.getIn(['globalQuotation', 'createdQuotation', '_id'], ''),
    quoteRef: state.getIn(['globalQuotation', 'createdQuotation', 'quoteRef']),
    numberOfQuotationProducts: state.getIn(['globalQuotation', 'createdQuotation', 'products'], fromJS([])),
    isMobile: state.getIn(['deviceInfo', 'isMobile']),
    isLoggedInOrhasValidLongSessionToken: (isUserLoggedIn(state) || hasValidLongSessionToken(state)),
    sale: state.get('sale'),
    myAccountListVisible: dropdownMenuToShow === VISIBLE_DROPDOWN.MY_ACCOUNT,
    dropdownMenuVisible: dropdownMenuToShow === VISIBLE_DROPDOWN.CATEGORIES,
    isBasketConfirmationDropdown,
    unleashToggles
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearQuote: () => dispatch(clearAddedProductToQuotation()),
  toggleMyAccount: () => dispatch(toggleDropdownMenu(VISIBLE_DROPDOWN.MY_ACCOUNT)),
  fetchGlobalQuotation: () => dispatch(fetchGlobalQuotation()),
  fetchQuotations: () => dispatch(fetchQuotations()),
  dispatchToggleBasketConfirmationDropdown: (flag) => dispatch(toggleBasketConfirmationDropdown(flag)),
  fetchCategoriesAction: () => dispatch(fetchCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
