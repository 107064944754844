import { topCategoriesNames } from 'shared/constants/categories';
import { urlifyText } from 'client/utils/urlUtils';

export function buildProductUrl (product, categoryNames = topCategoriesNames) {
  const terminalCategorySlug = urlifyText(product.getIn(['category', 'categoryName'])) || 'miscellaneous';
  const productSlug = urlifyText(product.get('name'));
  const sku = product.get('sku');
  const topCategoryId = product.getIn(['category', 'categoryId']).substring(0, 2);
  const topCategorySlug = urlifyText(categoryNames[topCategoryId]) || 'miscellaneous';

  return `/shop/${topCategorySlug}/${terminalCategorySlug}/${productSlug}/p/${sku}`;
}

export function buildFamilyUrl (product, categoryNames = topCategoriesNames) {
  const terminalCategorySlug = urlifyText(product.getIn(['category', 'categoryName'])) || 'miscellaneous';
  const familyName = urlifyText(product.getIn(['family', 'familyName']) || '');
  const familyId = product.getIn(['family', 'familyId']);
  const topCategoryId = product.getIn(['category', 'categoryId']).substring(0, 2);
  const topCategorySlug = urlifyText(categoryNames[topCategoryId]) || 'miscellaneous';

  return `/shop/${topCategorySlug}/${terminalCategorySlug}/${familyName}/f/${familyId}`;
}

export function buildCategoryUrl (category, categoryNames = topCategoriesNames) {
  const categoryId = category.get('categoryId');
  const topCategoryId = categoryId.substring(0, 2);
  const topCategorySlug = urlifyText(categoryNames[topCategoryId]);

  if (categoryId === topCategoryId) {
    return `/shop/${topCategorySlug}/${categoryId}`;
  }
  const categorySlug = urlifyText(category.getIn(['descriptions', 0, 'Title']));
  return `/shop/${topCategorySlug}/${categorySlug}/${categoryId}`;
}
