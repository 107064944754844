export const ON_API_BUTTON_LOAD = 'ON_API_BUTTON_LOAD';
export const ON_API_BUTTON_CLICK = 'ON_API_BUTTON_CLICK';
export const ON_API_BUTTON_SUCCESS = 'ON_API_BUTTON_SUCCESS';
export const ON_API_BUTTON_ERROR = 'ON_API_BUTTON_ERROR';
export const ON_API_BUTTON_UNLOAD = 'ON_API_BUTTON_UNLOAD';
export const SET_API_BUTTON_STATUS = 'RESET_API_BUTTON';

export function onApiButtonLoad (selector, triggerAction, successAction, errorAction, resetOnSuccess) {
  return {
    type: ON_API_BUTTON_LOAD,
    payload: {
      selector,
      triggerAction,
      successAction,
      errorAction,
      resetOnSuccess
    }
  };
}

export function setApiButtonStatus (selector, status) {
  return {
    type: SET_API_BUTTON_STATUS,
    payload: {
      selector,
      status
    }
  };
}

export function onApiButtonClick (selector) {
  return {
    type: ON_API_BUTTON_CLICK,
    payload: {
      selector
    }
  };
}

export function onApiButtonSuccess (selector) {
  return {
    type: ON_API_BUTTON_SUCCESS,
    payload: {
      selector
    }
  };
}

export function onApiButtonError (selector) {
  return {
    type: ON_API_BUTTON_ERROR,
    payload: {
      selector
    }
  };
}

export function onApiButtonUnLoad (selector) {
  return {
    type: ON_API_BUTTON_UNLOAD,
    payload: {
      selector
    }
  };
}
