export function isCardFormValid (fields) {
  return (fields.cvv && fields.cvv.isValid && !fields.cvv.isEmpty) &&
    (fields.cardholderName && fields.cardholderName.isValid && !fields.cardholderName.isEmpty) &&
    (fields.number && fields.number.isValid && !fields.number.isEmpty) &&
    (fields.expirationDate && fields.expirationDate.isValid && !fields.expirationDate.isEmpty);
}

export function checkInvalidField (fields) {
  if (!(fields.number && fields.number.isValid && !fields.number.isEmpty)) {
    return 'number';
  }
  if (!(fields.cardholderName && fields.cardholderName.isValid && !fields.cardholderName.isEmpty)) {
    return 'cardholderName';
  }
  if (!(fields.expirationDate && fields.expirationDate.isValid && !fields.expirationDate.isEmpty)) {
    return 'expirationDate';
  }
  if (!(fields.cvv && fields.cvv.isValid && !fields.cvv.isEmpty)) {
    return 'cvv';
  }
  return '';
}

export function formIsValid (address) {
  return !address || !Object.values(address).some(i => i !== undefined);
}
