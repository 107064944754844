import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { getToolTipContent } from '../braintreeTooltip';

if (process.browser) {
  require('./cvv.scss');
}

const cvvTooltip = () => {
  const tooltipLines = [
    'singlePageCheckout.cvv.info.line1',
    'singlePageCheckout.cvv.info.line2'
  ];
  return getToolTipContent(tooltipLines);
};

const overlayInnerStyle = (e) => {
  const toolTipInner = e.children[1];
  toolTipInner.style.fontWeight = 'normal';
  toolTipInner.style.opacity = '1';
  toolTipInner.style.fontFamily = 'Roboto, sans-serif';
};

const cvcInfoIcon = (
  <OverlayTrigger placement="right" onEntering={ overlayInnerStyle } overlay={ cvvTooltip() }>
    <i className="fa fa-info-circle CvvField_cvvInfoIcon" aria-hidden="true" />
  </OverlayTrigger>
);

export const CvvField = ({ isCvvValid, error, cvvId }) => {
  return (
    <div className="CvvField">
      <div className="CvvField_row">
        <label htmlFor={ cvvId } className="CvvField_formGroup_label CvvField_formGroup_label-required">
          {getLocalizedString('payment.label.security.code')}
        </label>
        <span className="CvvField_cvvInfo">{ cvcInfoIcon }</span>
      </div>
      <div className="CvvField_row">
        <div className={ `form-control ${isCvvValid ? '' : 'CvvField_isInvalid'}` } id={ cvvId } />
      </div>
      <div className="CvvField_errorMessage">{error}</div>
    </div>
  );
};
