import PropTypes from 'prop-types';
import React from 'react';
import ProductLine from '../productLine/ProductLine';
import { IMAGERESOLUTION, BACKUP_IMAGE } from 'shared/constants/images';
import ProductBundleLine from 'client/components/elements/productLine/ProductBundleLine';
import ScheduleOrdersCart from 'client/components/elements/scheduledOrders/ScheduleOrdersCart';
import { isCheckoutSubscriptionPage, isConfirmationsScreen } from 'client/components/elements/scheduledOrders/utils';

if (process.browser) { require('./CartOrderLineList.scss'); }

const getPrimaryProduct = (bundle) => {
  const products = bundle.get('products');
  const primaryProduct = products.find(product => product.get('isPrimary'));
  return primaryProduct.get('sku');
};

const CartOrderLineList = ({
  orderLines,
  setOrderLineQuantity,
  setBundleQuantity,
  removeOrderLine,
  removeBundleOrderLine,
  isCartEditable,
  includeVat,
  hideStockMessages,
  hideSavingsLabels,
  hideRemoveButton,
  eligibleProductCountForDeliveryOption,
  eligibleForDeliveryOptions,
  changeDeliveryOption,
  bundles,
  isSinglePageCheckout = false,
  discount
}) => {
  return (
    <>
      {isCheckoutSubscriptionPage() || isConfirmationsScreen() ? <ScheduleOrdersCart /> : null }
      <div className="row">
        <div className="CartOrderLineList col-md-12" >
          { orderLines.size > 0 ? orderLines.map((orderLine, idx) => (
            <div className="CartOrderLineList_Product" key={ orderLine.get('id') + '-' + idx }>
              <ProductLine
                sku={ orderLine.get('sku') }
                isAvailable={ orderLine.get('stockOverallAvailability', true) }
                isEditable={ isCartEditable }
                quantity={ orderLine.get('quantity') }
                onQuantityChange={ setOrderLineQuantity }
                onRemove={ removeOrderLine }
                isIncludingVat={ includeVat }
                hideStockMessages={ hideStockMessages }
                hideSavingsLabels={ hideSavingsLabels }
                hideRemoveButton={ hideRemoveButton }
                eligibleProductCountForDeliveryOption={ eligibleProductCountForDeliveryOption }
                eligibleForDeliveryOptions={ eligibleForDeliveryOptions }
                deliveryOptions={ orderLine.getIn(['deliveryOptionList', 'optionList'], []) }
                selectedDeliveryOption={ orderLine.getIn(['deliveryOptionList', 'selectedDeliveryOption']) }
                changeDeliveryOption={ changeDeliveryOption }
                nonRefundableType={ orderLine.get('nonRefundableType') }
                isSinglePageCheckout={ isSinglePageCheckout }
                isDiscountApplied={ orderLine.get('isDiscountApplied') }
                discountCode={ discount ? discount.get('code', null) : null }
                offerType={ discount ? discount.get('offerType', null) : null }
              />
            </div>
          )) : null }

          { bundles.size > 0 ? bundles.map((bundle, idx) => (
            <div className="CartOrderLineList_Product" key={ `${bundle.get('sku')} - ${idx}` }>
              <ProductBundleLine
                sku={ bundle.get('sku') }
                isAvailable={ bundle.get('stockOverallAvailability', true) }
                isEditable={ isCartEditable }
                quantity={ bundle.get('bundleQuantity') }
                onQuantityChange={ setBundleQuantity }
                onRemove={ removeBundleOrderLine }
                isIncludingVat={ includeVat }
                hideStockMessages={ hideStockMessages }
                hideSavingsLabels={ hideSavingsLabels }
                primarySku={ getPrimaryProduct(bundle) }
                hideRemoveButton={ hideRemoveButton }
              />
            </div>
          )) : null }
        </div>
      </div>
    </>

  ); };

CartOrderLineList.defaultProps = {
  getOrderLineImage: (orderLine) => orderLine.getIn(['image', 'mainPicture', 'shovelerImage', IMAGERESOLUTION], BACKUP_IMAGE)
};

CartOrderLineList.propTypes = {
  orderLines: PropTypes.any,
  setOrderLineQuantity: PropTypes.func,
  removeOrderLine: PropTypes.func,
  isCartEditable: PropTypes.bool,
  includeVat: PropTypes.bool,
  hideStockMessages: PropTypes.bool,
  getOrderLineImage: PropTypes.func,
  hideSavingsLabels: PropTypes.bool,
  toastSuccess: PropTypes.func,
  bundles: PropTypes.any
};

export default CartOrderLineList;
