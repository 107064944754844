import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { PrimaryLink } from 'client/components/controls/StyledForms';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./NotFoundScreen.scss');
}
export default class NotFound extends React.Component {
  render () {
    return (
      <div className="notFoundScreen">
        <div className="notFoundMessage">
          <span>{getLocalizedString('error.page.header')}</span>
          <span>{getLocalizedString('error.page.404.subHeader')}</span>
          <span>{getLocalizedString('error.page.404.message')}</span>
          <span>
            <PrimaryLink isExternalLink={ true } to="/">{ getLocalizedString('error.page.go.to.homepage') }</PrimaryLink>
          </span>
        </div>
        <SvgLoader xlinkHref="404-error" className="notFoundSvg" />
      </div>
    );
  }
}
