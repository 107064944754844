import combineReducers from 'shared/utils/reduxUtils';
import productVariantReducer from './productVariantReducer';
import productFamilyReducer from './productFamilyReducer';
import categoryFamiliesReducer from './categoryFamiliesReducer';
import cartReducer from './cartReducer';
import publicHolidaysReducer from './publicHolidaysReducer';
import userReducer from './userReducer';
import uiReducer from './uiReducer';
import authReducer from './authReducer';
import ordersReducer from './ordersReducer';
import searchReducer from './searchReducer';
import searchBarReducer from './searchBarReducer';
import categoryReducer from './categoryReducer';
import filterPanelReducer from './ui/filterPanelReducer';
import cacheReducer from './cacheReducer';
import loginReducer from './loginReducer';
import recommendationsReducer from './recommendationsReducer';
import { reducer as formReducer } from 'redux-form/immutable';
import togglerReducer from './togglerReducer';
import saleReducer from './saleReducer';
import dropdownMenuTogglerReducer from './dropdownMenuTogglerReducer';
import cmsReducer from './cmsReducer';
import quotationsReducer from './quotationsReducer';
import routeReducer from './routeReducer';
import unsubscribeReducer from './unsubscribeReducer';
import feefoReviewsReducer from './feefoReviewsReducer';
import myAccountUsers from './myAccountUsersReducer';
import industriesPageScreenReducers from './industriesPageScreenReducer';
import productLineReducer from './productLineReducer';
import productBundleLineReducer from './productBundleLineReducer';
import globalQuotationReducer from './globalQuotationReducer';
import businessAccountReducer from './ui/buisnessAccountReducer';
import repeatOrders from './repeatOrdersReducers';
import stockMessagesReducer from './stockMessageReducer';
import orderReviewReducer from './orderReviewReducer';
import braintreePaymentReducer from './braintreeReducer';
import braintreeReducer from './braintreeGatewayReducer';
import singlePageCheckoutReducer from './singlePageCheckoutReducer';
import mySavedCardsScreenReducer from './mySavedCardsScreenReducer';
import checkoutReducer from './ui/checkoutReducer';
import returnsOrReplacementReducer from './returnsOrReplacementReducer';
import unleashReducer from './unleashReducer';
import validatePasswordChecklist from './passwordValidationChecklistReducer';
import loginAndRegistrationReducer from './loginAndRegistrationReducer';
import scheduledOrdersReducer from 'client/reducers/scheduledOrdersReducer';

export default combineReducers({
  unleash: unleashReducer,
  braintree: braintreeReducer,
  productVariants: productVariantReducer,
  cart: cartReducer,
  publicHolidays: publicHolidaysReducer,
  user: userReducer,
  ui: uiReducer,
  form: formReducer,
  auth: authReducer,
  productFamilies: productFamilyReducer,
  orders: ordersReducer,
  search: searchReducer,
  searchBar: searchBarReducer,
  filterPanel: filterPanelReducer,
  categoryFamilies: categoryFamiliesReducer,
  apiCache: cacheReducer,
  login: loginReducer,
  recommendations: recommendationsReducer,
  category: categoryReducer,
  toggler: togglerReducer,
  routeInfo: routeReducer,
  sale: saleReducer,
  showDropdownMenu: dropdownMenuTogglerReducer,
  cms: cmsReducer,
  quotations: quotationsReducer,
  unsubscribeState: unsubscribeReducer,
  feefoReviews: feefoReviewsReducer,
  accountUsers: myAccountUsers,
  productLineItems: productLineReducer,
  productBundleLineItem: productBundleLineReducer,
  businessAccountOrderMessage: businessAccountReducer,
  repeatOrders: repeatOrders,
  industriesPage: industriesPageScreenReducers,
  stockMessages: stockMessagesReducer,
  globalQuotation: globalQuotationReducer,
  orderReview: orderReviewReducer,
  braintreePayment: braintreePaymentReducer,
  singlePageCheckout: singlePageCheckoutReducer,
  checkout: checkoutReducer,
  mySavedCardsScreen: mySavedCardsScreenReducer,
  returnsOrReplacements: returnsOrReplacementReducer,
  validatePasswordChecklist: validatePasswordChecklist,
  loginAndRegistration: loginAndRegistrationReducer,
  scheduledOrders: scheduledOrdersReducer
});
