import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import BraintreeCvvCheck from 'client/components/elements/braintree/BraintreeCvvCheck';
import { getCardImageUrl } from 'client/utils/braintreeUtils';
import { getLocalizedString } from 'localization/localizer';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';

if (process.browser) {
  require('./BraintreeCvvCheckDialog.scss');
}
class BraintreeCvvCheckDialog extends React.Component {
  componentDidUpdate (prevProps) {
    const { location, updateDataLayer, isVisible } = this.props;
    if (isVisible && !prevProps.isVisible && location.pathname.startsWith('/checkout')) {
      updateDataLayer({
        event: 'checkout_modal',
        type: 'Braintree_cvv'
      });
    }
  }

  render () {
    const {
      isVisible,
      savedCard
    } = this.props;
    return (
      <Modal className="BraintreeCvvCheckDialog"
        show={ isVisible }>
        <Modal.Header>
          <div className="modal-header_hgroup">
            <h2 className="modal-header_hgroup-title">{getLocalizedString('braintreeCvvCheckDialog.verify.label')}</h2>
            <h5 className="modal-header_hgroup-des">{ getLocalizedString('braintreeCvvCheckDialog.verify.description') }</h5>
          </div>
          <div className="modal-header_cardInfo">
            <img src={ getCardImageUrl[savedCard && savedCard.cardType] } alt={ savedCard && savedCard.cardType } />
            <span className="modal-header_cardInfo-endingFourDigits">{
              `${getLocalizedString('braintreeCvvCheckDialog.cardEnding')}${savedCard && savedCard.cardLastFourDigits} ${getLocalizedString('braintreeCvvCheckDialog.card.selected')}` }</span>
            <span className="modal-header_cardInfo-expires">{ `${getLocalizedString('braintreeCvvCheckDialog.card.expires')} ${savedCard && savedCard.cardExpirationDate}` }</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <BraintreeCvvCheck/>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const payByExistingCard = state.getIn(['ui', 'payByExistingCard']).toJS();
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'braintreeCvvCheck', 'isVisible']),
    savedCard: payByExistingCard[CHECKOUT_SELECTOR] ? payByExistingCard[CHECKOUT_SELECTOR].selectedCard : {}
  };
};

export default connect(mapStateToProps)(BraintreeCvvCheckDialog);
export { BraintreeCvvCheckDialog };
