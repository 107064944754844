import { takeLatest, all, fork } from 'redux-saga/effects';

import { ON_USER_ACCOUNT_DETAILS_RECEIVED } from 'client/actions/userActions';

export function * pushUserIdentifierToDynatrace ({ payload = {} }) {
  try {
    if (typeof dtrum !== 'undefined') {
      const { customerId } = payload;
      if (customerId) { // it's global object, it will be available at run time
        // eslint-disable-next-line no-undef
        yield dtrum.identifyUser(customerId);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in push user identity to dynatrace', error);
  }
}

export function * watchOnUserAccountDetailsReceived () {
  yield takeLatest(ON_USER_ACCOUNT_DETAILS_RECEIVED, pushUserIdentifierToDynatrace);
}

export function * watchAllDynatraceSagas () {
  yield all([
    fork(watchOnUserAccountDetailsReceived)
  ]);
}
