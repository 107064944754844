import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { getLocalizedString } from 'localization/localizer';
import { Field, reduxForm, getFormValues, destroy } from 'redux-form/immutable';
import { validateForm } from 'client/utils/formValidation';
import { fromJS } from 'immutable';
import FormSelectField from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import RadioButtonWithStyles from 'client/components/elements/formField/RadioButtonWithStyles';
import { setView, updateAdditionalUsersList } from 'client/actions/loginAndRegistration';
import { asyncValidate } from 'client/sagas/loginSagas';
import {
  WELCOME_ACCOUNT_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW,
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  YES,
  MAYBE_LATER,
  COMPANY_INFO_URL
} from '../../constants';
import { makeValidator, isCompanyRegNoIf, isRequired, maxLengthWithCustomError, isUKPhoneNumber, isValidEmailForNewUser } from 'client/utils/validators';
import { ToolTipInfo } from 'client/components/elements/LoginAndRegistrationForm/businessAccountRegistration';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';

const validate = makeValidator({
  firstName: [
    isRequired('First name'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 15 characters long',
      maxLength: 15
    })
  ],
  lastName: [
    isRequired('Last name'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 15 characters long',
      maxLength: 15
    })
  ],
  phoneNumber: [
    isRequired('Phone number'),
    isUKPhoneNumber(getLocalizedString('error.invalid.phoneNumber'))
  ],
  email: [
    isRequired('Email address'),
    isValidEmailForNewUser()
  ],
  companyName: [
    isRequired('Company name'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 50 characters long',
      maxLength: 50
    })
  ],
  jobTitle: [
    isRequired('Job title'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 50 characters long',
      maxLength: 50
    })
  ],
  companyRegNo: [
    isCompanyRegNoIf(
      (values) => values.get('companyRegNo')
    )
  ]
});

const titles = {
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  ms: 'Ms',
  dr: 'Dr'
};
const PRIMARY_CONTACT_FORM = 'primaryContactForm';
const PrimaryContactDetailsForm = ({
  dispatchSetView,
  handleSubmit,
  primaryContactDetailsForm,
  onDestroyForm,
  dispatchUpdateAdditionalUsersList,
  hideExitConfirmation,
  additionalUsersList
}) => {
  const nextView = primaryContactDetailsForm?.openCreditLine === YES
    ? CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW
    : primaryContactDetailsForm?.additionalUsers === YES
      ? CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW
      : CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW;

  useEffect(() => {
    if (primaryContactDetailsForm?.openCreditLine === MAYBE_LATER) {
      onDestroyForm('creditLineApplicationForm');
      dispatchUpdateAdditionalUsersList(fromJS(additionalUsersList.filter((user) => !user.isFinanceUser)));
    }
  }, [primaryContactDetailsForm?.openCreditLine]);

  useEffect(() => {
    if (primaryContactDetailsForm?.additionalUsers === MAYBE_LATER) {
      dispatchUpdateAdditionalUsersList(fromJS([]));
      onDestroyForm('additionalUsersForm');
    }
  }, [primaryContactDetailsForm?.additionalUsers]);

  const submitForm = (handleSubmit, submit) => {
    return handleSubmit(submit)();
  };

  const submit = (values) => {
    return validateForm(values, validate)
      .then(() => {
        hideExitConfirmation();
        dispatchSetView(nextView);
      })
      .catch(() => {
        hideExitConfirmation();
      });
  };

  return <div className="row BusinessAccountRegistrationForm_primaryContactDetails">
    <div className="col-md-12">
      <h3 className="BusinessAccountRegistrationForm_title">
        Primary Contact Details
      </h3>
    </div>
    <form>
      <div className="row col-xs-12">
        <div className="col-xs-3 col-md-2">
          <Field
            name="title"
            id="title"
            className="AboutYou_select form-control"
            labelClassName="AboutYou_labelInput control-label required"
            component={ FormSelectField }
            labelResourceName="registration.label.title"
            datae2e="title"
            value={ Object.values(titles)[0] }
          >
            {
              Object.values(titles).map((item, i) =>
                <option selected={ !i } key={ item } value={ item }>{ item }</option>
              )
            }
          </Field>
        </div>
        <div className="col-xs-9 col-md-4">
          <Field
            name="firstName"
            id="firstName"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label required AboutYou_labelInput"
            labelResourceName="registration.label.firstName"
            datae2e="firstName"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <Field
            name="lastName"
            id="lastName"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label required AboutYou_labelInput"
            labelResourceName="registration.label.lastName"
            datae2e="lastName"
          />
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          <Field
            name="email"
            id="email"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="email"
            labelClassName="control-label required AboutYou_labelInput"
            labelResourceName="registration.placeholder.emailAddress"
            datae2e="email"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <Field
            name="phoneNumber"
            id="phoneNumber"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label required AboutYou_labelInput"
            labelResourceName="registration.label.phoneNumber"
            datae2e="phoneNumber"
          />
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          <Field
            id="companyName"
            name="companyName"
            className="form-control"
            labelClassName="control-label required"
            type="text"
            fieldSubtitle={
              <div className="BusinessAccountRegistrationForm_primaryContactDetails-subTitle">
              Must match your registered company name
              </div>
            }
            component={ FieldWithValidations }
            labelResourceName="creditAccount.application.label.companyName"
            datae2e="companyName" />
        </div>
        <div className="col-xs-12 col-md-6">
          <Field
            id="jobTitle"
            name="jobTitle"
            className="form-control"
            labelClassName="control-label required"
            type="text"
            component={ FieldWithValidations }
            labelResourceName="registration.label.jobTitle"
            datae2e="jobTitle" />
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          <Field
            id="companyRegNo"
            name="companyRegNo"
            className="form-control"
            labelClassName="control-label"
            type="text"
            errorLink={{
              url: COMPANY_INFO_URL,
              label: primaryContactDetailsForm?.companyRegNo ? getLocalizedString('error.invalid.companyRegNo.click.here') : null
            }}
            component={ FieldWithValidations }
            labelResourceName="creditAccount.application.label.companyRegNo"
            datae2e="companyRegNo" />
        </div>
      </div>
      <div className="row col-xs-12 BusinessAccountRegistrationForm_openCreditLine">
        <div className="col-xs-12">
          <Field
            component={ RadioButtonWithStyles }
            name="openCreditLine"
            labelResourceName="creditAccount.application.openCreditLine"
            labelClassName="control-label"
            className="form-control"
            options={ [
              { value: YES, title: YES, datae2e: 'creditLineYes' },
              { value: MAYBE_LATER, title: MAYBE_LATER, datae2e: 'creditLineMaybeLater' }
            ] }
            datae2e="openCreditLine"
            tooltip={
              <ToolTipInfo
                title="Credit Line"
                text="Zoro will issue a statement at the beginning of each month for orders placed the previous month. Payment is due 30 days after the end of the month of invoice."
              />
            }
            validatorName="openCreditLine" />
        </div>
      </div>
      <div className="row col-xs-12 BusinessAccountRegistrationForm_additionalUsers">
        <div className="col-xs-12">
          <Field
            component={ RadioButtonWithStyles }
            name="additionalUsers"
            labelResourceName="creditAccount.application.additionalUsers"
            labelClassName="control-label"
            className="form-control"
            options={ [
              { value: YES, title: YES, datae2e: 'additionalUsersYes' },
              { value: MAYBE_LATER, title: MAYBE_LATER, datae2e: 'additionalUsersMaybeLater' }
            ] }
            data-e2e="additionalUsers"
            tooltip={
              <ToolTipInfo
                title="Business Account"
                text="Once you have invited your colleagues to join, they will be able to make quick and easy purchases under your Zoro Business Account. As an admin, you can add, remove or alter others privileges, set the spend limits of colleagues and view all business account invoices in one place."
              />
            }
            validatorName="additionalUsers" />
        </div>
      </div>
      <div className="row BusinessAccountRegistrationForm_addUserForm-btnsContainer">
        <div className="col-xs-12 BusinessAccountRegistrationForm_addUserForm-btns">
          <div className="col-xs-6 BusinessAccountRegistrationForm_backBtn ForgotPasswordNewForm_noLeftPadding">
            <SecondaryButton
              datae2e="back"
              onClick={ () => {
                dispatchSetView(WELCOME_ACCOUNT_VIEW);
                hideExitConfirmation();
              } }
            >
                Back
            </SecondaryButton>
          </div>
          <div className="col-xs-6 BusinessAccountRegistrationForm_continueBtn ForgotPasswordNewForm_noRightPadding">
            <PrimaryButton
              className="btn"
              onClick={ () => {
                hideExitConfirmation();
                return submitForm(handleSubmit, submit);
              } }
              datae2e="continue">
                Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  </div>;
};

const ReduxPrimaryContactDetailsForm = reduxForm({
  form: PRIMARY_CONTACT_FORM,
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
  destroyOnUnmount: false
})(PrimaryContactDetailsForm);

export const mapStateToProps = (state) => {
  const primaryContactDetailsForm = (getFormValues(PRIMARY_CONTACT_FORM)(state) || fromJS({})).toJS();
  const aboutYouForm = (getFormValues('aboutYouForm')(state) || fromJS({})).toJS();

  return {
    primaryContactDetailsForm,
    additionalUsersList: (state.getIn(['loginAndRegistration', 'additionalUsersList'], fromJS([])) || fromJS([])).toJS(),
    initialValues: {
      openCreditLine: MAYBE_LATER,
      additionalUsers: YES,
      title: 'Mr',
      email: aboutYouForm?.email || '',
      firstName: aboutYouForm?.firstName || '',
      lastName: aboutYouForm?.lastName || '',
      phoneNumber: aboutYouForm?.phoneNumber || ''
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetView: (view) => dispatch(setView({ view })),
  hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog()),
  dispatchUpdateAdditionalUsersList: (list) => dispatch(updateAdditionalUsersList(list)),
  onDestroyForm: (formName) => dispatch(destroy(formName))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxPrimaryContactDetailsForm);
