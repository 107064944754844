//
// THESE FILE IS FOR THE OLD FAMILY PAGE. DO NOT USE IT
//
// TODO: REMOVE THIS FILE AFTER NEW PRODUCT PAGE GOES LIVE (see isNewFamilyPageEnabled)

import { createAction } from 'redux-actions';

export const SORT_FEEFO_REVIEWS = 'SORT_FEEFO_REVIEWS';
export const sortFeefoReviews = (familyId, reviews, sortBy) => {
  return {
    type: SORT_FEEFO_REVIEWS,
    sortBy,
    familyId,
    reviews
  };
};

export const ON_SEARCH_KEYWORD_CHANGE = 'ON_SEARCH_KEYWORD_CHANGE';

export function onSearchKeywordChange (payload) {
  return {
    type: ON_SEARCH_KEYWORD_CHANGE,
    payload
  };
}

export const UPDATE_PRICE_WITH_BREAK_PRICE_ON_PRODUCT_FAMILY_TABLE = 'UPDATE_PRICE_WITH_BREAK_PRICE_ON_PRODUCT_FAMILY_TABLE';
export const updatePriceWithBreakPriceOnProductFamilyTable = createAction(UPDATE_PRICE_WITH_BREAK_PRICE_ON_PRODUCT_FAMILY_TABLE);

export const SCROLL_TO_PRODUCT_FAMILY_TABLE = 'SCROLL_TO_PRODUCT_FAMILY_TABLE';
export const scrollToProductFamilyTableSection = createAction(SCROLL_TO_PRODUCT_FAMILY_TABLE);
