import React from 'react';
import PayByNewCardButton from './PayByNewCardButton';
import { connect } from 'react-redux';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';

export const PaymentMethods = ({
  paymentMethodSelector,
  showPayOnAccountButton,
  setPaymentType
}) => {
  const isCheckout = paymentMethodSelector === CHECKOUT_SELECTOR;
  return (
    <div className={ `PaymentMethods ${!showPayOnAccountButton || !isCheckout ? 'PaymentMethods-small' : null}` } data-e2e="PaymentMethods" >
      <PayByNewCardButton paymentMethodSelector={ paymentMethodSelector } setPaymentType={ setPaymentType } />
    </div>
  );
};

function mapStateToProps (state) {
  return {
    showPayOnAccountButton: state.getIn(['cart', 'tradeAccountButton', 'showPayOnAccountButton'], false)
  };
}

export default connect(mapStateToProps)(PaymentMethods);
