import { fromJS, List, Map } from 'immutable';
import { isSubscribableProduct, parseConfirmedOrderIdFromUrl } from 'client/components/elements/scheduledOrders/utils';

export const productLineItemsSelector = (state) => state.get('productLineItems', fromJS({}));
export const confirmedOrderSelector = (state, orderId) => state.getIn(['orders', 'order', orderId], Map({}));

export const subscriptionUserInfoSelector = (state) => {
  const order = state.getIn(['orders', 'order', parseConfirmedOrderIdFromUrl()], Map({}));
  const paymentMeyhod = state.getIn(['scheduledOrders', 'paymentMethod'], fromJS({}));
  return {
    deliveryAddressId: order.getIn(['shippingDetails', 'shippingAddress', 'addressId']),
    userCode: order.get('userCode'),
    paymentType: paymentMeyhod.get('paymentType'),
    cardToken: paymentMeyhod.get('cardToken', null)
  };
};

export const confirmScreenSubscribableProductsSelector = (state, confirmedOrderLines) => state.get('productLineItems', fromJS({})).filter(
  (productLineItem) => {
    const orderLine = confirmedOrderLines.find(orderLine => orderLine.get('sku') === productLineItem.get('sku'));
    return !!orderLine && !orderLine.get('scheduledOrder') && isSubscribableProduct(productLineItem);
  }
);

export const getIsCartScheduledOrders = state => !!state.get('productLineItems', List()).find(item => item.getIn(['scheduledOrder', 'startDate']));
export const scheduledOrderCartFormStatusSelector = state => state.getIn(['scheduledOrders', 'cartLevelFormStatus']);
export const isCartScheduledOrdersSelector = (state) => !!state
  .get('productLineItems', List([]))
  .find(item => item.hasIn(['scheduledOrder', 'regularity']));

export const scheduledOrderFormValuesSelector = state => state.getIn(['scheduledOrders', 'forms'], fromJS({}));
