import { ApiRequest } from 'shared/utils/apiUtils';

export function tryVerifyEmail (accountNo, verificationToken) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/verifyEmail',
    body: { accountNo, verificationToken }
  });

  return req.execute()
    .then((r) => {
      return r.body;
    });
}
