import { fromJS, Map } from 'immutable';
import moment from 'moment';
import {
  FETCH_REPEATORDERS,
  FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS,
  FETCH_REPEATORDERS_SUCCESS,
  DELETE_REPEAT_ORDER_SUCCESS,
  PAUSE_PLAY_REPEAT_ORDER_SUCCESS,
  SHOW_CREATE_NEW_REPEAT_ORDER_FORM,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILED,
  RESET_SUBSCRIPTION_REVIEW_STATE,
  REQUEST_CONFIRM_SUBSCRIPTION,
  SHOW_CREATE_NEW_ADDRESS_FORM,
  SET_NEW_ADDRESS_AS_SELECTED,
  SET_PRODUCT_AND_QUANTITY,
  UPDATE_REPEAT_ORDER_DETAILS,
  EDIT_REPEAT_ORDER_SUCCESS,
  EDIT_REPEAT_ORDER_DETAILS,
  CANCEL_EDITING_REPEAT_ORDER_DETAILS,
  LOADING_UPDATE_REPEAT_ORDER,
  CHANGE_DELIVERY_ADDRESS,
  UPDATE_QUANTITY,
  REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS,
  REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS_SUCCESS,
  CHANGE_PAYMENT_METHOD_SELECTED,
  NEW_REPEAT_ORDER_DETAILS,
  RESET_NEW_REPEAT_ORDER_DETAILS,
  ADD_PRODUCT_TO_REPEAT_ORDER_SUCCESS,
  ADD_PRODUCT_TO_REPEAT_ORDER_FAILED,
  RESET_ADD_PRODUCT_TO_REPEAT_ORDER,
  RESET_BANNER_STATUSES,
  PAYMENT_METHOD_UPDATED,
  SET_SUBSCRIPTION_ID,
  SET_INITIALIZE_STATUS,
  SET_INITIAL_STATE,
  TOGGLE_ACCORDION,
  UPDATE_CURRENT_DISPLAY,
  RESET_DISPLAY,
  CREATE_NEW_SUBSCRIPTION,
  CLEAR_REPEAT_ORDER_DETAILS,
  FETCH_REPEAT_ORDER_PRODUCT_SUCCESS
} from 'client/actions/repeatOrdersActions';
import sortBy from 'lodash/sortBy';
import { STOP } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

const todayDate = moment().format('DD-MM-YYYY');
const subscriptionName = `Subscription-${todayDate}`;
const defaultState = fromJS({
  faqExpandingFlag: false,
  all: [],
  details: {},
  updatedRepeatOrderDetails: {},
  showNewRepeatOrderForm: false,
  showNewAddressForm: false,
  confirmSubscriptionButtonStatus: 'readyToSubmit',
  confirmAddProductsToSubscriptionStatus: 'readyToSubmit',
  setNewAddressAsSelected: false,
  ADD_TO_SUBSCRIPTION: {
    quantity: 0
  },
  NEW_SUBSCRIPTION: {
    quantity: 0
  },
  newRepeatOrder: {},
  newRepeatOrderCreatedName: subscriptionName,
  selectedPaymentMethod: ''
});

export const sortOrdersByEarliestNextDeliveryDate = (repeatOrders) => {
  return sortBy(repeatOrders, (repeatOrder) => {
    return repeatOrder.nextDeliveryInDays;
  });
};

const repeatOrders = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_BANNER_STATUSES:
      return state
        .set('confirmSubscriptionButtonStatus', 'readyToSubmit')
        .set('newRepeatOrderCreatedName', subscriptionName)
        .set('confirmAddProductsToSubscriptionStatus', 'readyToSubmit');
    case RESET_ADD_PRODUCT_TO_REPEAT_ORDER:
      return state.set('confirmAddProductsToSubscriptionStatus', 'bannerOnly');
    case ADD_PRODUCT_TO_REPEAT_ORDER_SUCCESS:
      return state
        .set('confirmAddProductsToSubscriptionStatus', 'submitted')
        .set('existingSubscriptionName', action.payload.reference);
    case ADD_PRODUCT_TO_REPEAT_ORDER_FAILED:
      return state.set('confirmAddProductsToSubscriptionStatus', 'failed');
    case RESET_NEW_REPEAT_ORDER_DETAILS:
      return state
        .set('newRepeatOrder', Map())
        .set('selectedPaymentMethod', '');
    case CHANGE_PAYMENT_METHOD_SELECTED:
      return state.set('selectedPaymentMethod', action.value);
    case REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS:
      return state.set('isLoading', true);
    case REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS_SUCCESS:
      const { allSavedCards } = action.payload;
      return state.set('isLoading', false)
        .set('savedCards', fromJS(allSavedCards));
    case NEW_REPEAT_ORDER_DETAILS:
      const withExistingDetails = {
        ...state.get('newRepeatOrder', Map()).toJS(),
        ...action.payload
      };
      return state.set('newRepeatOrder', fromJS(withExistingDetails));
    case LOADING_UPDATE_REPEAT_ORDER:
      return state.setIn(['updatedRepeatOrderDetails', action.repeatOrderId, 'isLoading'], action.isLoading);
    case EDIT_REPEAT_ORDER_DETAILS:
      return state.setIn(['details', action.repeatOrderId, 'editingRepeatOrder'], true);
    case CANCEL_EDITING_REPEAT_ORDER_DETAILS:
      return state.setIn(['details', action.repeatOrderId, 'editingRepeatOrder'], false);
    case UPDATE_REPEAT_ORDER_DETAILS:
      const repeatOrderId = action.repeatOrderDetails._id;
      return state.setIn(['details', repeatOrderId], fromJS(action.repeatOrderDetails));

    case FETCH_REPEATORDERS:
      return state.set('loadStateType', 'PENDING');

    case FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS:
      return state.set('loadStateType', 'PENDING');
    case FETCH_REPEAT_ORDER_PRODUCT_SUCCESS:
      const newRepeatOrder = state.get('newRepeatOrder', Map()).toJS();
      return state.set('newRepeatOrder', fromJS({
        ...newRepeatOrder,
        [action.sku]: fromJS(action.product)
      }));
    case FETCH_REPEATORDERS_SUCCESS:
      const sortedOrders = sortOrdersByEarliestNextDeliveryDate(action.repeatOrders);
      const activeOrders = sortedOrders.filter(item => item.state !== STOP);
      return activeOrders.length
        ? state.set('all', fromJS(sortedOrders || []))
          .set('loadStateType', 'SUCCESS')
        : state.set('all', fromJS(sortedOrders || []))
          .set('showNewRepeatOrderForm', true)
          .set('loadStateType', 'SUCCESS');

    case DELETE_REPEAT_ORDER_SUCCESS:
      return state.set('all', fromJS(action.repeatOrderDetails));

    case PAUSE_PLAY_REPEAT_ORDER_SUCCESS:
      if (action.page === 'detail_page') {
        return state
          .setIn(['details', action.repeatOrder.get('_id'), 'state'], action.repeatOrder.get('state'))
          .setIn(['details', action.repeatOrder.get('_id'), 'nextDeliveryDate'], action.repeatOrder.get('nextDeliveryDate'))
          .setIn(['details', action.repeatOrder.get('_id'), 'nextPickUpDate'], action.repeatOrder.get('nextPickUpDate'))
          .setIn(['details', action.repeatOrder.get('_id'), 'nextDeliveryInDays'], action.repeatOrder.get('nextDeliveryInDays'));
      }
      const selectedRepeatOrderIndex = state.get('all')
        ? state.get('all').findIndex((repeatOrder) => repeatOrder.get('_id') === action.repeatOrder.get('_id'))
        : -1;

      return state.setIn(['all', selectedRepeatOrderIndex, 'state'], action.repeatOrder.get('state'))
        .setIn(['all', selectedRepeatOrderIndex, 'nextDeliveryDate'], action.repeatOrder.get('nextDeliveryDate'))
        .setIn(['all', selectedRepeatOrderIndex, 'nextPickUpDate'], action.repeatOrder.get('nextPickUpDate'))
        .setIn(['all', selectedRepeatOrderIndex, 'nextDeliveryInDays'], action.repeatOrder.get('nextDeliveryInDays'));

    case EDIT_REPEAT_ORDER_SUCCESS:
      return state.setIn(['details', action.repeatOrder.get('_id'), 'regularity'], action.repeatOrder.get('regularity'));

    case SHOW_CREATE_NEW_REPEAT_ORDER_FORM:
      return state.set('showNewRepeatOrderForm', action.payload)
        .set('confirmSubscriptionButtonStatus', 'readyToSubmit');

    case SHOW_CREATE_NEW_ADDRESS_FORM:
      return state.set('showNewAddressForm', action.payload);

    case RESET_SUBSCRIPTION_REVIEW_STATE:
      return state.set('confirmSubscriptionButtonStatus', 'readyToSubmit');

    case REQUEST_CONFIRM_SUBSCRIPTION:
      return state.set('confirmSubscriptionButtonStatus', 'processing');

    case PAYMENT_METHOD_UPDATED:
      return state.setIn(['details', 'updatedPaymentMethod'], action.payload);

    case SET_SUBSCRIPTION_ID:
      return state.setIn(['details', 'id'], action.payload.id);

    case CREATE_SUBSCRIPTION_SUCCESS:
      return state.set('confirmSubscriptionButtonStatus', 'submitted')
        .set('showNewRepeatOrderForm', false)
        .set('newRepeatOrderCreatedName', action.payload.repeatOrderCreatedName);

    case CREATE_SUBSCRIPTION_FAILED:
      return state.set('confirmSubscriptionButtonStatus', 'failed');

    case SET_NEW_ADDRESS_AS_SELECTED:
      return state
        .set('setNewAddressAsSelected', true)
        .setIn(['newRepeatOrder', 'deliveryAddressId'], action.payload.addressId);

    case SET_PRODUCT_AND_QUANTITY:
      const newRepeatOrderDetails = state.get('newRepeatOrder', Map()).toJS();
      const quantitySelected = action.payload.quantity;
      const DEFAULT_QUANTITY = 1;
      return state.set('newRepeatOrder', fromJS({
        ...newRepeatOrderDetails,
        quantitySelected: !quantitySelected ? DEFAULT_QUANTITY : quantitySelected
      }));
    case CHANGE_DELIVERY_ADDRESS:
      return state.set('setNewAddressAsSelected', false);
    case SET_INITIALIZE_STATUS:
      return state.setIn(['form', 'initialize'], action.payload.status);
    case SET_INITIAL_STATE:
      return state.setIn(['form', 'state'], action.payload).set('isNewSubscription', undefined);
    case TOGGLE_ACCORDION:
      const currentAccordionState = state.getIn(['form', 'state']);
      return state.setIn(['form', 'state'], {
        showNewSubscription: !currentAccordionState.showNewSubscription,
        showAddToSubscription: !currentAccordionState.showAddToSubscription,
        currentDisplay: currentAccordionState.currentDisplay
      });
    case CREATE_NEW_SUBSCRIPTION:
      return state.set('isNewSubscription', true);
    case UPDATE_CURRENT_DISPLAY:
      const formState = state.getIn(['form', 'state']);
      return state.setIn(['form', 'state'], {
        ...formState,
        currentDisplay: formState.currentDisplay + action.payload.display
      });
    case RESET_DISPLAY:
      const subscriptions = state.get('all');
      return state.setIn(['form', 'state'], {
        showAddToSubscription: subscriptions.size > 0,
        showNewSubscription: subscriptions.size === 0,
        currentDisplay: 1
      });
    case UPDATE_QUANTITY:
      const orderLineIndex = state.getIn(['details', action.repeatOrderId, 'orderPrice', 'orderLines'])
        .findIndex((orderLine) => orderLine.get('sku') === action.sku);
      return state.setIn(['details', action.repeatOrderId, 'orderPrice', 'orderLines', orderLineIndex, 'quantity']
        , action.quantity);
    case CLEAR_REPEAT_ORDER_DETAILS:
      return state.set('details', fromJS({}));
    default:
      return state;
  }
};

export default repeatOrders;
