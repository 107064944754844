export const UNSUPPORTED_BROWSER_COOKIE_NAME = 'unsupported.browser.acknowledged';
export const COOKIE_CJEVENT_ID = 'cj_event_id';
export const COOKIE_CJEVENT_EXPIRY = 3600 * 24 * 1000 * 395; // 395 days
export const COOKIE_AWC_ID = 'awin_id';
export const COOKIE_AWC_EXPIRY = 3600 * 24 * 1000 * 395; // 395 days
export const JWT_COOKIE_NAME = 'jwtToken';
export const LONG_SESSION_JWT_COOKIE_NAME = 'jwtTokenLongSession';
export const TOKEN_REFRESH_THRESHOLD_SECONDS = 10 * 60;
export const COOKIE_USER_PREFERENCES = 'zoro_user_preferences';
export const USER_PREFERENCES_COOKIE_EXP_DAYS = 365;
export const PRICE_DISPLAY_TYPE_INC_VAT = 'IncVat';
export const PRICE_DISPLAY_TYPE_EXCL_VAT = 'ExclVat';
