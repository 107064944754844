import React from 'react';
import get from 'lodash/get';
import { fromJS } from 'immutable';
import { IMAGERESOLUTION, BACKUP_IMAGE } from 'shared/constants/images';
import ProductRecommendation from './ProductRecommendation';
import _isEqual from 'lodash/isEqual';

const thumbnailLocation = 'mediaContent.mainPicture.shovelerImage';

export class ProductItem extends React.Component {
  shouldComponentUpdate (nextProps) {
    return !_isEqual(this.props, nextProps);
  }

  render () {
    const {
      item: product,
      panelIndex,
      isPriceWithVat,
      isAnyProductInPromo,
      cmsFlag = false,
      query,
      addToCart,
      position,
      disableModal,
      isVerticalRecommendation,
      recommendationLocation
    } = this.props;
    const thumbnail = get(product, `${thumbnailLocation}[${IMAGERESOLUTION}]`, BACKUP_IMAGE);
    const {
      sku,
      name
    } = product;

    return (
      <div className={ `RecommendationTile ${isVerticalRecommendation ? 'verticalRecommendation' : ''}` }>
        <ProductRecommendation
          key={ sku }
          product={ fromJS(product) }
          name={ name }
          sku={ sku }
          addToCart={ addToCart }
          thumbnail={ thumbnail }
          position={ position }
          disableModal={ disableModal }
          panelIndex = { panelIndex }
          isPriceWithVat = { isPriceWithVat }
          isAnyProductInPromo = { isAnyProductInPromo }
          cmsFlag = { cmsFlag }
          query = { query }
          isVerticalRecommendation= { isVerticalRecommendation }
          recommendationLocation = { recommendationLocation }
        />
      </div>
    );
  }
}

export default ProductItem;
