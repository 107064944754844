import {
  buildSkuDataMap,
  removeDispatchedItemsFromOrder,
  processCancelled,
  removeCancelledFromPending,
  processDispatched,
  appendZoroSku,
  appendIsDeliverable,
  buildSkuMapFromDispatches,
  getValidDispatchList,
  getNonRefundableProducts
} from './processItemsUtil';

export default function processOrders (orders, productsMetadataArray, publicHolidays) {
  const processed = orders.map((rawOrder) => {
    const rawOrderWithCats = Object.assign({}, rawOrder);
    rawOrderWithCats.orderLines.map(appendZoroSku(productsMetadataArray));
    rawOrderWithCats.orderLines.map(appendIsDeliverable(productsMetadataArray));
    if (rawOrderWithCats.orderLinesForMerlin && rawOrderWithCats.orderLinesForMerlin.length) {
      rawOrderWithCats.orderLinesForMerlin.map(appendZoroSku(productsMetadataArray));
    }

    const orderLines = rawOrderWithCats.orderLinesForMerlin &&
    rawOrderWithCats.orderLinesForMerlin.length
      ? rawOrderWithCats.orderLinesForMerlin
      : rawOrderWithCats.orderLines;
    const skuDataMap = buildSkuDataMap(orderLines);
    const pendingAndCancelledItems = removeDispatchedItemsFromOrder(
      orderLines, rawOrderWithCats.dispatchDetails,
      skuDataMap);
    const cancelled = processCancelled(rawOrderWithCats.cancellationDetails, skuDataMap);
    const pending = removeCancelledFromPending(pendingAndCancelledItems, cancelled, skuDataMap, rawOrderWithCats.creationDate, publicHolidays);
    const dispatched = processDispatched(rawOrderWithCats.dispatchDetails, skuDataMap);

    const order = {
      cancelled,
      dispatched,
      pending,
      skuDataMap,
      merlinOrderReference: rawOrderWithCats.merlinOrderReference,
      orderStatus: rawOrderWithCats.orderStatus,
      orderPrice: rawOrderWithCats.orderPrice,
      currency: rawOrderWithCats.currency,
      dispatchStatus: rawOrderWithCats.dispatchStatus,
      creationDate: rawOrderWithCats.creationDate,
      deliveryBy: rawOrderWithCats.deliveryBy,
      raw: rawOrderWithCats,
      products: productsMetadataArray
    };

    return order;
  });

  return processed;
}

export function orderReturnMap (rawOrder, productsMetadataArray, publicHolidays, isCsAgent) {
  const order = Object.assign({}, rawOrder);
  const { orderLines, orderLinesForMerlin, currency, dispatchStatus, deliveryBy,
    dispatchDetails, cancellationDetails, orderPrice, creationDate,
    returnRequests, merlinOrderReference, orderStatus, isFOC } = order;
  const orderLinesWithZoroSkuAndDeliverable = orderLines.map(appendZoroSku(productsMetadataArray))
    .map(appendIsDeliverable(productsMetadataArray));

  const orderLinesForMerlinWithZoroSku = orderLinesForMerlin && orderLinesForMerlin.length
    ? orderLinesForMerlin.map(appendZoroSku(productsMetadataArray)) : [];

  const updatedOrderLines = orderLinesForMerlinWithZoroSku.length
    ? orderLinesForMerlinWithZoroSku : orderLinesWithZoroSkuAndDeliverable;
  const skuDataMap = buildSkuDataMap(updatedOrderLines);
  const pendingAndCancelledItems = removeDispatchedItemsFromOrder(
    updatedOrderLines, dispatchDetails,
    skuDataMap);
  const dispatchedProducts = processDispatched(dispatchDetails, skuDataMap);
  const cancelled = processCancelled(cancellationDetails, skuDataMap);
  const pending = removeCancelledFromPending(pendingAndCancelledItems, cancelled, skuDataMap, creationDate, publicHolidays);
  const groupedDispatchList = buildSkuMapFromDispatches(dispatchedProducts);

  return {
    dispatched: getValidDispatchList(groupedDispatchList, returnRequests, productsMetadataArray, isCsAgent, orderLines),
    nonRefundableProducts: isCsAgent ? [] : getNonRefundableProducts(groupedDispatchList, productsMetadataArray, orderLines),
    skuDataMap,
    cancelled,
    pending,
    merlinOrderReference,
    orderStatus,
    orderPrice,
    currency,
    dispatchStatus,
    creationDate,
    deliveryBy,
    isFOC,
    customerId: order.customerId
  };
}
