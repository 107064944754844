import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import { logoutUser } from 'client/actions/userActions';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';
import { toggleDropdownMenu } from 'client/actions/ui/dropdownMenuActions';
import { validateTradeAccount } from 'client/utils/myAccountUtils';
import { getLocalizedString } from 'localization/localizer';
import { getTokenDetails } from '../../../../shared/utils/jwtUtils';
import Avatar from './Avatar';
import { CREDIT, menus, menuLinks, ADMIN, FINANCE, ACCOUNT_STATEMENTS, ACCOUNT_USERS, menuIds, LinksMap, ALL_LINKS_USER, LIMITED_LINKS_USER } from 'shared/constants/account';

class MyAccountButton extends Component {
  onToggle = (isOpen) => {
    const { toggleMyAccount, closeMyAccount, myAccountListVisible } = this.props;
    if (isOpen) {
      toggleMyAccount();
    }
    else if (myAccountListVisible) {
      closeMyAccount();
    }
  }
  render () {
    const {
      logoutUser,
      loggedUser,
      accountDetails,
      longSessionJwtToken,
      jwtToken,
      tradeAccountIsValidForStatement: tradeAccountValidForStatementFromJWT
    } = this.props;
    const { firstName, lastName, tradeAccount: tradeAccountFromDetails } = accountDetails.toJS() || {};
    const { name = [] } = getTokenDetails(longSessionJwtToken || jwtToken) || {};
    const { accountUser, accountType } = tradeAccountFromDetails || {};
    const { accountRole } = accountUser || {};
    const userRole = (accountRole === FINANCE || accountRole === ADMIN) ? ALL_LINKS_USER : LIMITED_LINKS_USER;
    const { tradeAccountIsValid, tradeAccountIsValidForStatement } = validateTradeAccount(loggedUser.toJS());
    const showAccountStatementMenu = accountType === CREDIT && (tradeAccountIsValidForStatement || tradeAccountValidForStatementFromJWT);
    const showAccountUsersMenu = tradeAccountIsValid;
    const filteredMenus = menus[userRole].filter(menu => (!(menu === ACCOUNT_STATEMENTS && !showAccountStatementMenu) && !(menu === ACCOUNT_USERS && !showAccountUsersMenu)));
    const nameArray = name.split(' ');
    const fName = firstName || nameArray?.[0];
    const lName = lastName || nameArray?.[nameArray.length - 1];
    const isValidLength = fName?.length < 15;
    const validFirstName = !fName ? getLocalizedString('login.register.label')
      : isValidLength ? fName : fName?.slice(0, 12) + '...';
    return (
      <div className="HeaderMenu__button">
        <DropdownButton
          className="AccountDropdown"
          title={ <span className="AccountIcon" id="HeaderAccountIcon">
            <Avatar text={ fName?.[0] } />
            <label>{ validFirstName } { isValidLength ? lName?.[0] : ''}</label>
          </span> }
          id="HeaderMenu.goToMyAccountButton"
          aria-haspopup="true"
          onToggle={ this.onToggle }
        >
          <MenuItem href="/my-account/account-details">
            <a
              id="HeaderMenu.goToMyAccountDetailsButton"
              href="/my-account/account-details">
              <ListGroupItem className="MyAccountMenuItem">
                { getLocalizedString('myAccount.accountDetails.title')}
              </ListGroupItem>
            </a>
          </MenuItem>
          {filteredMenus.map((menu, key) => {
            if (LinksMap.includes(menu)) {
              return <MenuItem key={ key }>
                <Link
                  id={ menuIds[menu].headerId }
                  to={ menuLinks[menu] }>
                  <ListGroupItem className="MyAccountMenuItem">
                    {menu}
                  </ListGroupItem>
                </Link>
              </MenuItem>;
            }

            return <MenuItem href={ menuLinks[menu] } key={ key }>
              <a
                id={ menuIds[menu].headerId }
                href={ menuLinks[menu] }>
                <ListGroupItem className="MyAccountMenuItem">
                  {menu}
                </ListGroupItem>
              </a>
            </MenuItem>;
          })}
          <MenuItem
            id="HeaderMenu.logout"
            onSelect={ () => {
              logoutUser();
            } }>
            <ListGroupItem className="MyAccountMenuItem">
              { getLocalizedString('myAccount.logout') }
            </ListGroupItem>
          </MenuItem>
        </DropdownButton>
      </div>);
  }
}

MyAccountButton.propTypes = {
  myAccountListVisible: PropTypes.bool,
  tradeAccountIsValidForStatement: PropTypes.bool,
  tradeAccountIsValid: PropTypes.bool
};

const mapStateToProps = (state) => ({
  hasActiveTradeAccount: state.getIn(['auth', 'hasActiveTradeAccount']),
  tradeAccountIsValidForStatement: state.getIn(['auth', 'tradeAccountIsValidForStatement']),
  tradeAccountIsValid: state.getIn(['auth', 'tradeAccountIsValid']),
  accountDetails: state.getIn(['user', 'accountDetails']),
  longSessionJwtToken: state.getIn(['auth', 'longSessionJwtToken'], ''),
  jwtToken: state.getIn(['auth', 'jwtToken'], '')
});

const mapDisPatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  closeMyAccount: () => dispatch(toggleDropdownMenu(VISIBLE_DROPDOWN.NONE))
});

export default connect(mapStateToProps, mapDisPatchToProps)(MyAccountButton);
