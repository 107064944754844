import PropTypes from 'prop-types';
import React from 'react';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import {
  changeInQuoteLineQtyConfirm
} from 'client/actions/quotationsActions';

import { hideChangeInQuoteLineQtyDialog } from '../../../actions/ui/dialogActions';

if (process.browser) {
  require('./ChangeInQuoteLineQtyDialog.scss');
}

const mapStateToProps = (state) => {
  return {
    changeInQuoteLineQtyIsVisible: state.getIn(['ui', 'dialogs', 'quoteLineQtyChange', 'isVisible']),
    quotationId: state.getIn(['ui', 'dialogs', 'quoteLineQtyChange', 'quotationId']),
    qty: state.getIn(['ui', 'dialogs', 'quoteLineQtyChange', 'qty']),
    sku: state.getIn(['ui', 'dialogs', 'quoteLineQtyChange', 'sku']),
    breakPrices: state.getIn(['ui', 'dialogs', 'quoteLineQtyChange', 'breakPrices'])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideChangeInQuoteLineQtyDialog: flowRight(dispatch, hideChangeInQuoteLineQtyDialog),
    changeInQuoteLineQtyConfirm: flowRight(dispatch, changeInQuoteLineQtyConfirm)
  };
};

const ChangeInQuoteLineQtyDialog = (props) => {
  const {
    changeInQuoteLineQtyIsVisible,
    hideChangeInQuoteLineQtyDialog,
    changeInQuoteLineQtyConfirm,
    quotationId,
    qty,
    sku,
    breakPrices
  } = props;

  const applyChangeInQuoteLineQty = () => {
    hideChangeInQuoteLineQtyDialog();
    changeInQuoteLineQtyConfirm(quotationId, qty, sku, breakPrices);
  };

  return (
    <Modal className="ChangeInQuoteLineQtyDialog" show={ changeInQuoteLineQtyIsVisible }
      onHide={ hideChangeInQuoteLineQtyDialog }>
      <Modal.Header className="ChangeInQuoteLineQtyDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <div>You may loose the discount!
          </div>
        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            onClick={ hideChangeInQuoteLineQtyDialog }>
                        Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={ applyChangeInQuoteLineQty }>
                        Accept
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ChangeInQuoteLineQtyDialog.propTypes = {
  ChangeInQuoteLineQtyDialogIsVisible: PropTypes.bool,
  hideChangeInQuoteLineQtyDialog: PropTypes.func,
  requestRemoveQuotationConfirm: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeInQuoteLineQtyDialog);
