import React from 'react';
import { Link } from 'react-router';
import SvgLoader from 'client/components/svg/SvgLoader';
if (process.browser) {
  require('./formButtons.scss');
}

const Button = ({
  onClick,
  text, // is ignored if there are children
  datae2e,
  baseClassname = '',
  className = '',
  type = 'button',
  active = true,
  disabled = false,
  children,
  svgId,
  svgClassName,
  displaySecondarySvg,
  secondarySvgId,
  secondarySvgClassName,
  ...rest
}) =>
  <button
    { ...rest }
    type={ type }
    className= { `${baseClassname} ${className} ${disabled ? 'disabled' : ''} ${active ? '' : 'inActive'}` }
    data-e2e={ datae2e }
    onClick={ onClick }
    disabled={ disabled }
  >
    { svgId ? <SvgLoader xlinkHref={ `#${svgId}` } className={ svgClassName ? `${svgClassName}` : 'svg-icon' }/> : null}
    { children ? children : text }
    { displaySecondarySvg ? <SvgLoader xlinkHref={ `#${secondarySvgId}` } className={ secondarySvgClassName ? `${secondarySvgClassName}` : 'secondary-svg-icon' }/> : null}
  </button>;

export const PrimaryButton = (props) => <Button { ...props } baseClassname='button-primary' />;

export const SecondaryButton = (props) => <Button { ...props } baseClassname='button-secondary' />;

const LinkComp = ({
  to,
  text,
  datae2e,
  baseClassName = '',
  className = '',
  active = true,
  disabled = false,
  children,
  isExternalLink = false,
  ...rest
}) =>
  isExternalLink ? <a
    { ...rest }
    className= { `centerAlign ${baseClassName} ${className} ${disabled ? 'disabled' : ''} ${active ? '' : 'inActive'}` }
    data-e2e={ datae2e }
    href={ to }
    disabled={ disabled }
  >
    { children ? children : text }
  </a> : <Link
    { ...rest }
    className= { `centerAlign ${baseClassName} ${className} ${disabled ? 'disabled' : ''} ${active ? '' : 'inActive'}` }
    data-e2e={ datae2e }
    to={ to }
    disabled={ disabled }
  >
    { children ? children : text }
  </Link>;

export const PrimaryLink = (props) => <LinkComp { ...props } baseClassName='link-primary' />;
export const SecondaryLink = (props) => <LinkComp { ...props } baseClassName='link-secondary' />;
