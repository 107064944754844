export const UPDATE_JWT_TOKEN = 'UPDATE_JWT_TOKEN';

export function updateJWTToken (tokens) {
  return {
    type: UPDATE_JWT_TOKEN,
    tokens
  };
}

export const FETCH_ANONYMOUS_TOKEN = 'FETCH_ANONYMOUS_TOKEN';

export function fetchAnonymousToken () {
  return {
    type: FETCH_ANONYMOUS_TOKEN
  };
}

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';

export function validateToken (token) {
  return {
    type: VALIDATE_TOKEN,
    token
  };
}

export const MISSING_TOKEN = 'MISSING_TOKEN';

export function missingToken (token) {
  return {
    type: MISSING_TOKEN,
    token
  };
}

export const ALMOST_EXPIRED_TOKEN = 'ALMOST_EXPIRED_TOKEN';

export function almostExpiredToken (token) {
  return {
    type: ALMOST_EXPIRED_TOKEN,
    token
  };
}

export const INVALID_TOKEN = 'INVALID_TOKEN';

export function invalidToken (token) {
  return {
    type: INVALID_TOKEN,
    token
  };
}

export const EXPIRED_TOKEN = 'EXPIRED_TOKEN';

export function expiredToken (token) {
  return {
    type: EXPIRED_TOKEN,
    token
  };
}

export const VALID_TOKEN = 'VALID_TOKEN';

export function validToken (token) {
  return {
    type: VALID_TOKEN,
    token
  };
}
