import { fromJS } from 'immutable';
import { CHANGE_PAYMENT_METHOD, CLEAR_PAYMENT_METHOD } from './paymentMethodActions';

const defaultState = fromJS({});

const paymentMethod = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_PAYMENT_METHOD:
      return defaultState;
    case CHANGE_PAYMENT_METHOD:
      return state.setIn([action.payload.selector, 'method'], action.payload.paymentMethod);
    default:
      return state;
  }
};

export default paymentMethod;
