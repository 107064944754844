
import curry from 'lodash/curry';
import { createAction } from 'redux-actions';

export const SEARCH_TERM_CHANGED = 'searchBar.searchTermChanged';
export const SEARCH_TERM_CHANGE_FAILED = 'searchBar.searchTermChangeFailed';
export const SEARCH_TERM_CHANGE_SUCCEEDED = 'searchBar.searchTermChangeSucceeded';
export const SEARCH_TERM_SELECTED = 'searchBar.searchTermSelected';
export const SEARCH_TERM_CLEARED = 'searchBar.searchTermCleared';
export const GET_RECENT_SEARCHES = 'searchBar.recentSearches';

export const SEARCH_TERM_UPDATE_AQ_TERM = 'searchBar.searchTermUpdateAqTerm';
export const searchBarUpdateAqTerm = createAction(SEARCH_TERM_UPDATE_AQ_TERM);

export const SEARCH_TERM_UPDATE_AQ_TERM_SUCCESS = 'searchBar.searchTermUpdateAqTermSuccess';
export const searchBarUpdateAqTermSuccess = createAction(SEARCH_TERM_UPDATE_AQ_TERM_SUCCESS);

export const GET_RECENT_SEARCHES_SUCCESS = 'searchBar.recentSearchesSuccess';
export const getRecentSearchesSuccess = createAction(GET_RECENT_SEARCHES_SUCCESS);

export const clearSearchTerm = (termStoreId) => ({
  type: SEARCH_TERM_CLEARED,
  termStoreId
});

export const changeSearchTerm = (termStoreId, term) => ({
  type: SEARCH_TERM_CHANGED,
  termStoreId,
  term,
  hasErrors: false
});

export const changeSearchTermSucceeded = (term, suggestions) => ({
  type: SEARCH_TERM_CHANGE_SUCCEEDED,
  term,
  suggestions,
  hasErrors: false
});

export const changeSearchTermFailed = (term) => ({
  type: SEARCH_TERM_CHANGE_FAILED,
  term,
  hasErrors: true
});

export const searchTermSelected = ({ term, ...props }) => ({
  type: SEARCH_TERM_SELECTED,
  term,
  ...props
});

export const dispatchGetRecentSearches = (searchTerm = '') => {
  return {
    type: GET_RECENT_SEARCHES,
    searchTerm
  };
};

export const autoCompleteChangeHandler = (termStoreId) => (
  curry(function (dispatch, evt, value) {
    if (value.method === 'type') {
      dispatch(changeSearchTerm(termStoreId, value.newValue));
    }
    dispatch(dispatchGetRecentSearches(value?.newValue || ''));
  })
);

export const autoCompleteTermSelectedHandler = (termStoreId) => (
  curry(function autoCompleteTermSelectedHandler (dispatch, evt, value) {
    const term = value.suggestion ? value.suggestion.display : value.newValue;
    const event = value.params.evt ? value.params.evt : value.evt;

    dispatch(searchBarUpdateAqTerm());
    dispatch(clearSearchTerm(termStoreId));
    dispatch(searchTermSelected({ ...value, term, event }));
  })
);

export const FETCH_PRODUCTS_ON_SEARCH_TERM_HOVER = 'FETCH_PRODUCTS_ON_SEARCH_TERM_HOVER';
export const fetchAutoCompleteTopProductsOnHoverHandler = (text, params) => {
  return {
    type: FETCH_PRODUCTS_ON_SEARCH_TERM_HOVER,
    payload: {
      text,
      ...params
    }
  };
};

export const SET_PRODUCTS_ON_SEARCH_TERM_HOVER_SUCCESS = 'SET_PRODUCTS_ON_SEARCH_TERM_HOVER_SUCCESS';
export const changeProductOnSearchTermHoverSucceeded = (term, suggestions) => {
  return {
    type: SET_PRODUCTS_ON_SEARCH_TERM_HOVER_SUCCESS,
    term,
    suggestions,
    hasErrors: false
  };
};

export const changeProductOnSearchTermHoverFailed = (term) => ({
  type: SEARCH_TERM_CHANGE_FAILED,
  term,
  hasErrors: true
});
