
export const GET_STOCK_MESSAGES = 'stockMessageAction.GET_STOCK_MESSAGES';
export const getStockMessages = (props
) => {
  return {
    type: GET_STOCK_MESSAGES,
    payload: {
      ...props
    }
  };
};

export const GET_STOCK_MESSAGES_SUCCESS = 'stockMessageAction.GET_STOCK_MESSAGES_SUCCESS';
export const getStockMessagesSuccess = (payload) => {
  return {
    type: GET_STOCK_MESSAGES_SUCCESS,
    payload
  };
};
