import { ApiRequest } from 'shared/utils/apiUtils';

export function search (query) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/find',
    query
  });
  return req.executeAndGetBody();
}

export function getRecentSearches (keyword = '') {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/recent-searches?keyword=${keyword}`
  });
  return req.executeAndGetBody();
}

export function searchToGetCategories (query) {
  return search(query);
}

export function searchToGetFilters (query) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/search/filters',
    query
  });
  return req.executeAndGetBody();
}

export const getSearchSuggestions = function getSearchSuggestions (term, searchVersion) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/search/suggest',
    query: { keyword: term, searchVersion },
    allowCaching: true
  });
  return req.executeAndGetBody();
};

export const getProductsOnSearchTermHover = (payload, searchVersion) => {
  const query = {};
  query.keyword = payload.text;
  query.categoryId = payload.id;
  query.brandId = payload.brandId;
  query.searchVersion = searchVersion;

  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/search/suggest',
    query: query,
    allowCaching: true
  });
  return req.executeAndGetBody();
};
