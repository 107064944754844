
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./FieldInputStyles.scss');
}

class RadioButtonWithValidation extends Component {
  render () {
    const { input, meta, options, validatorName, labelResourceName, labelClassName = '', id } = this.props;
    const hasError = meta.touched && meta.error;
    const classNameAndValidatorName = (validatorName) ? `FieldRadioButton ${validatorName}` : 'FieldRadioButton';
    return (
      <div className={ classNameAndValidatorName } id={ id } data-e2e="RadioButtonWithValidation">
        { labelResourceName ? <div className={ `FieldRadioButton_label ${labelClassName}` }>{ getLocalizedString(labelResourceName) }</div> : null }
        {options.map(radioButton => <label key={ radioButton.value } className="FieldRadioButton_radio-label" >
          <input type="radio" data-e2e={ radioButton.datae2e } className="FieldRadioButton_radio-input" { ...input }
            value={ radioButton.value } checked={ radioButton.value === input.value }/>
          <span className={ `FieldRadioButton_radio-button` }></span>
          <span>{radioButton.title}</span>
        </label>)}
        {hasError ? <div className="FieldRadioButton_radio-error">{meta.error}</div> : null }
      </div>

    );
  }
}

RadioButtonWithValidation.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default RadioButtonWithValidation;
