import Immutable from 'immutable';
import {
  ON_SET_EMAIL_FOR_FORGOT_PASSWORD,
  ON_FORGOT_PASSWORD_SUCCESS,
  ON_FORGOT_PASSWORD_ERROR,
  ON_FORGOT_PASSWORD,
  FORGOT_PASSWORD_DEFAULT_STATE
} from 'client/actions/userActions';

export const defaultState = Immutable.fromJS({});

const forgotPasswordFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_DEFAULT_STATE:
      return state
        .set('error', undefined)
        .set('emailSent', false);
    case ON_FORGOT_PASSWORD_ERROR:
      return state
        .set('error', action.error)
        .set('emailSent', false);
    case ON_FORGOT_PASSWORD_SUCCESS:
    case ON_FORGOT_PASSWORD:
      return state
        .set('error', undefined)
        .set('emailSent', true);
    case ON_SET_EMAIL_FOR_FORGOT_PASSWORD:
      return state.set('email', action.email);
    default:
      return state;
  }
};

export default forgotPasswordFormReducer;
