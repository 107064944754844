import { connect } from 'react-redux';
import React from 'react';
import { ZoroUnleashWrapper } from '@graingerglobalonline/curly-eureka';
import { getJwtUnleashUserId } from 'shared/utils/jwtUtils';
import { getContext } from 'shared/utils/unleashUtils';
import { withRouter } from 'react-router';
import { prepareUnleashOverridePayload } from 'client/utils/unleashUtils';
import { fromJS } from 'immutable';

const ConnectedUnleashWrapper = (props) => {
  const { skipDataLayerEventPush, dataLayer, ...otherProps } = props;
  return (
    <React.Fragment>
      <ZoroUnleashWrapper { ...otherProps }
        dataLayer = { skipDataLayerEventPush ? null : dataLayer }
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const jwtToken = state.getIn(['auth', 'jwtToken']);
  const longSessionToken = state.getIn(['auth', 'longSessionJwtToken'], '');
  const isLoggedIn = state.getIn(['auth', 'isLoggedIn']);
  const isLoggedInOrhasValidLongSessionToken = isLoggedIn || state.getIn(['auth', 'hasValidLongSessionToken']);
  const { unleashUserId, zoroUserId } = getJwtUnleashUserId(jwtToken, longSessionToken, isLoggedInOrhasValidLongSessionToken);
  const deviceInfo = state.get('deviceInfo').toJS();
  const isB2B = !!state.getIn(['user', 'accountDetails', 'loyaltyDiscount', 'b2bAccount']);
  const context = getContext({ unleashUserId, zoroUserId, isB2B, isLoggedIn, ...deviceInfo });

  const unleashOverrideValues = state.getIn(['unleash', 'payload'], fromJS({})).toJS();
  const { unl_feature: unlFeature, unl_variant: unlVariant, unl_payload: unlPayload } = unleashOverrideValues;
  const overridenUnleashData = {
    unl_feature: unlFeature,
    unl_variant: unlVariant,
    unl_payload: unlPayload?.value || {}
  };
  const overrideVariant = prepareUnleashOverridePayload(overridenUnleashData);

  return {
    isEnabled: state.getIn(['config', 'isUnleashEnabled']),
    context,
    dataLayer: process.browser ? window.dataLayer : null,
    overrideVariant
  };
};

export default withRouter(connect(mapStateToProps)(
  ConnectedUnleashWrapper
));
