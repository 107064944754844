import { API_CACHE } from 'client/actions/cacheActions';
import { Map, Seq } from 'immutable';

const defaultState = Map();
const maxCachableKeys = 100;

export default (state = defaultState, { type, key, value }) => {
  switch (type) {
    case API_CACHE:
      return Seq(state)
        .flip()
        .take(maxCachableKeys - 1)
        .flip()
        .toMap()
        .set(key, value);
    default:
      return state;
  }
};
