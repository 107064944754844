import { takeLatest, call, put,
  fork, all, select, take, race } from 'redux-saga/effects';
import Braintree from 'braintree-web';
import { verifyCard } from 'shared/endpoints/braintreeEndpoint';
import { getSelectedSavedCardDetails } from 'client/utils/braintreeUtils';
import { CARD_CVV, CARD_VERIFY_CVV } from 'shared/constants/braintree';
import {
  SAVED_CARD_CVV_VERIFICATION_FAILED,
  SELECT_PAYMENT_OPTION
} from 'shared/constants/singlePageCheckout';

import {
  showBraintreeCvvCheckDialog,
  hideBraintreeCvvCheckDialog
} from 'client/actions/ui/dialogActions';

import {
  CREATE_CVV_HOSTED_FIELD,
  CVV_VERIFICATION_SUCCESS,
  CVV_VERIFICATION_FAILED,
  GENERATE_CVV_NONCE,
  CVV_VERIFICATION_CANCEL,
  setCvvVerificationSuccess,
  setCvvVerificationFailed,
  updateHostedFieldsStatus
} from 'client/actions/braintreeActions';
import { getBraintreeClientInstance, getDeviceData } from './braintreeGatewaySagas';
import { receiveBraintreeCvvHostedFieldInstance } from '../actions/braintreeActions';
import { paymentMethodSelectors } from '../components/elements/paymentMethod/shared/selectors';

const getCvvHostedFieldInstance = (state) => state.getIn(['braintreePayment', 'cvvHostedFieldInstance']);
const getSavedPaymentMethods = (state) => state.getIn(['braintree', 'cards']).toJS();
const getIsShippingAddressUpdated = (state) => state.getIn(['user', 'address', 'delivery', 'isUpdated'], false);
const getShippingAddressId = (state) => state.getIn(['user', 'address', 'delivery', 'addressId']);

const getCvvVerificationResult = {
  CVV_VERIFICATION_SUCCESS: { success: true },
  CVV_VERIFICATION_FAILED: { success: false, status: SAVED_CARD_CVV_VERIFICATION_FAILED },
  CVV_VERIFICATION_CANCEL: { success: false }
};

export function * createCvvHostedField ({ payload: { styleConfig, fieldConfig } }) {
  const clientInstance = yield call(getBraintreeClientInstance);
  const cvvHostedFieldInstance = yield Braintree.hostedFields.create({
    client: clientInstance,
    styles: styleConfig,
    fields: fieldConfig });
  yield put(receiveBraintreeCvvHostedFieldInstance(cvvHostedFieldInstance));
}

export function * setCvvHostedFieldStatus (formState, cvvHostedFieldName, storeStateFieldName) {
  yield put(updateHostedFieldsStatus({
    hostedFieldName: storeStateFieldName,
    hostedFieldStatus: {
      isEmpty: formState.fields[cvvHostedFieldName].isEmpty,
      isValid: formState.fields[cvvHostedFieldName].isValid
    }
  }));
}

export function * getCvvHostedFieldState () {
  const hostedInstance = yield select(getCvvHostedFieldInstance);
  const formState = yield hostedInstance.getState();
  if (formState && !formState.fields[CARD_CVV].isValid) {
    yield * setCvvHostedFieldStatus(formState, CARD_CVV, CARD_VERIFY_CVV);
    return { hostedCvvState: false, hostedInstance };
  }
  return { hostedCvvState: true, hostedInstance };
}

export function * generateCvvNonce ({ payload }) {
  try {
    const { hostedCvvState, hostedInstance } = yield * getCvvHostedFieldState();
    if (!hostedCvvState) {
      return;
    }
    yield put(hideBraintreeCvvCheckDialog());
    const { nonce } = yield hostedInstance.tokenize();
    const token = yield select(paymentMethodSelectors.payByExistingCard.token(payload.selector));
    const deviceData = yield call(getDeviceData);
    const isUpdated = yield select(getIsShippingAddressUpdated);
    const addressId = yield select(getShippingAddressId);
    yield call(verifyCard, { nonce, deviceData, braintreeToken: token, isUpdated, addressId });
    yield put(setCvvVerificationSuccess());
  } catch (error) {
    yield put(setCvvVerificationFailed());
  }
}

export function * verifyStoredCardByCvv (identifier, shippingAddressId) {
  const isShippingAddressUpdated = yield select(getIsShippingAddressUpdated);
  const storedCards = yield select(getSavedPaymentMethods);
  const {
    isNewShippingDetails,
    storedCard
  } = getSelectedSavedCardDetails(storedCards, identifier, shippingAddressId);
  if (!isNewShippingDetails && !isShippingAddressUpdated) {
    return { success: true };
  }
  if (!storedCard) {
    return { success: false, status: SELECT_PAYMENT_OPTION };
  }
  yield put(showBraintreeCvvCheckDialog());
  const { verified, failed, cancelled } = yield race({
    verified: take(CVV_VERIFICATION_SUCCESS),
    failed: take(CVV_VERIFICATION_FAILED),
    cancelled: take(CVV_VERIFICATION_CANCEL)
  });
  const cvvVerificationResult = verified || failed || cancelled;
  if (failed || cancelled) {
    yield put(hideBraintreeCvvCheckDialog());
  }
  return getCvvVerificationResult[cvvVerificationResult.type];
}

export function * watchCreateCVVHostedField () {
  yield takeLatest(CREATE_CVV_HOSTED_FIELD, createCvvHostedField);
}

export function * watchGenerateCvvNonce () {
  yield takeLatest(GENERATE_CVV_NONCE, generateCvvNonce);
}

export function * watchAllBraintreeCvvCheckSagas () {
  yield all([
    fork(watchCreateCVVHostedField),
    fork(watchGenerateCvvNonce)
  ]);
}
