import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { takeLatest, call, put, all, fork, take, select } from 'redux-saga/effects';
import {
  DELETE_PAYMENT_METHOD_CONFIRM,
  REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE,
  REQUEST_SAVED_CARDS,
  REQUEST_REMOVE_PAYMENT_METHOD_CONFIRM,
  requestSavedCardsSuccess,
  requestDefaultPaymentMethodChangeSuccess,
  deletePaymentMethodConfirmSuccess
} from 'client/actions/mySavedCardsScreenActions';
import {
  getAllSavedPaymentMethods,
  updateDefaultPaymentMethod,
  updateSavedForFuturePaymentMethod
} from 'shared/endpoints/braintreeEndpoint';
import {
  getBraintreeSavedCards
} from 'client/utils/braintreeUtils';
import {
  hideRemovePaymentMethodDialog,
  showRemovePaymentMethodDialog,
  showCustomAlertDialog
} from 'client/actions/ui/dialogActions';
import { toastSuccess, toastWarn } from 'client/actions/showNotificationsActions';
import { getLocalizedString } from 'localization/localizer';
import { stopSubscription } from 'client/actions/repeatOrdersActions';

const TOASTER_DELAY = 4000;
const DEFAULT_NUMBER_OF_ROWS = 10;

const numberOfRowsSelector = (state) => state.getIn(['mySavedCardsScreen', 'numberOfTableRows'], DEFAULT_NUMBER_OF_ROWS);
const pageNumberSelector = (state) => state.getIn(['mySavedCardsScreen', 'pageNumber']);

export const getAllSavedCards = (paymentMethods) => {
  const cardPaymentMethods = getBraintreeSavedCards(paymentMethods);
  return [...cardPaymentMethods.filter(p => !p.isCardExpired),
    ...cardPaymentMethods.filter(p => p.isCardExpired)];
};

export const getPaginatedSavedCards = (allSavedCards, numberOfRows, pageNumber) => {
  const begin = numberOfRows * (pageNumber - 1);
  const end = numberOfRows * pageNumber;
  return allSavedCards.slice(begin, end);
};

export const getSavedCards = (paymentMethods, numberOfRows, pageNumber) => {
  const allSavedCards = getAllSavedCards(paymentMethods);
  const totalNumberOfAllSavedCards = allSavedCards.length;
  const savedCards = getPaginatedSavedCards(allSavedCards, numberOfRows, pageNumber);
  return { savedCards, totalNumberOfAllSavedCards };
};

export function * requestSavedCards ({ payload }) {
  const { pageNumber } = payload || {};
  const paymentMethods = yield call(getAllSavedPaymentMethods);
  const numberOfRows = yield select(numberOfRowsSelector);
  const { savedCards, totalNumberOfAllSavedCards } = getSavedCards(paymentMethods, numberOfRows, pageNumber);
  yield put(requestSavedCardsSuccess({ savedCards, pageNumber, totalNumberOfAllSavedCards }));
}

export function * changeDefaultPaymentMethod ({ payload }) {
  const { token, checked } = payload;
  const paymentMethods = yield call(updateDefaultPaymentMethod, { braintreeToken: token, isDefault: checked });
  const numberOfRows = yield select(numberOfRowsSelector);
  const pageNumber = yield select(pageNumberSelector);
  const { savedCards, totalNumberOfAllSavedCards } = getSavedCards(paymentMethods, numberOfRows, pageNumber);
  if (checked) {
    yield put(toastSuccess(
      getToastMessage(
        getLocalizedString('myAccount.savedCards.defaultPaymentMethod.changed')
      ), 'top-right', TOASTER_DELAY));
  } else {
    yield put(toastWarn(
      getToastMessage(
        getLocalizedString('myAccount.savedCards.defaultPaymentMethod.noDefaultCard'),
        getLocalizedString('myAccount.savedCards.defaultPaymentMethod.setDefaultCard')
      ),
      'top-right', TOASTER_DELAY));
  }
  yield put(requestDefaultPaymentMethodChangeSuccess({ savedCards, pageNumber, totalNumberOfAllSavedCards }));
}

export function * deletePaymentMethodConfirm ({ payload }) {
  const { token, cardType, cardLastFourDigits } = payload;
  yield put(showRemovePaymentMethodDialog(cardType, cardLastFourDigits));
  const confirmed = yield take(REQUEST_REMOVE_PAYMENT_METHOD_CONFIRM);
  if (!confirmed) {
    return;
  }
  yield call(deletePaymentMethod, { token });
}

export function * deletePaymentMethod ({ token }) {
  try {
    const paymentMethods = yield call(updateSavedForFuturePaymentMethod, { braintreeToken: token, isSavedForFuturePayment: false });
    const numberOfRows = yield select(numberOfRowsSelector);
    const pageNumber = yield select(pageNumberSelector);
    const { savedCards, totalNumberOfAllSavedCards } = getSavedCards(paymentMethods, numberOfRows, pageNumber);
    yield put(deletePaymentMethodConfirmSuccess({ savedCards, pageNumber, totalNumberOfAllSavedCards }));
    yield put(stopSubscription({ token }));
    yield put(hideRemovePaymentMethodDialog());
    yield put(showCustomAlertDialog(getLocalizedString('myAccount.savedCards.defaultPaymentMethod.deleted.success')));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog(getLocalizedString('myAccount.savedCards.defaultPaymentMethod.deleted.error')));
  }
}

export function * watchRequestMySavedCards () {
  yield takeLatest(REQUEST_SAVED_CARDS, requestSavedCards);
}

export function * watchRequestMyDefaultSavedCardsChange () {
  yield takeLatest(REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE, changeDefaultPaymentMethod);
}

export function * watchDeletePaymentMethodConfirm () {
  yield takeLatest(DELETE_PAYMENT_METHOD_CONFIRM, deletePaymentMethodConfirm);
}

export function * watchAllMySavedCardsScreenSagas () {
  yield all([
    fork(watchRequestMySavedCards),
    fork(watchRequestMyDefaultSavedCardsChange),
    fork(watchDeletePaymentMethodConfirm)
  ]);
}
