import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { urlifyText } from 'client/utils/urlUtils';

const NewCategoriesItem = (props) => {
  const [delayHandler, setDelayHandler] = useState(null);
  const { category } = props;
  const categoryId = category.get('id');

  const handleMouseEnter = event => {
    setDelayHandler(setTimeout(() => {
      props.setCategoryId(categoryId);
    }, 500));
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
  };
  const onClickHandler = () => {
    const categoryId = category.get('id');
    const slug = urlifyText(category.get('title'));
    const url = `/shop/${slug}/${categoryId}`;
    props.goToUrl(url, true);
  };
  return (
    <a className="grouped-menu-item"
      onClick={ () => (props.isMobile ? props.setCategoryId(categoryId) : onClickHandler()) }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <SvgLoader xlinkHref={ `#cat-${categoryId}-icon` } aria-hidden="true" />
      { category.get('title') }
      {
        props.isMobile
          ? <SvgLoader xlinkHref={ `right-chevron-icon` } aria-hidden="true" id="menu-item-mobile"/>
          : null
      }
    </a>
  );
};

NewCategoriesItem.propTypes = {
  categories: PropTypes.object,
  categoryBrowsingStarted: PropTypes.func.isRequired
};

export default NewCategoriesItem;
