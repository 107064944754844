import { select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { getTotalizedValues } from 'client/utils/cartUtils';
import { quotationCalculator } from 'client/utils/quotationUtils';
import { COUNTRY_CODE_UK } from 'shared/constants/braintree';
import { splitStringIntoLines } from '../utils/stringUtils';

const getDeliveryAddress = (state) => state.getIn(['user', 'address', 'delivery'], fromJS({})).toJS();
const getCart = (state) => state.get('cart');
const getUserEmail = (state) => state.getIn(['user', 'accountDetails', 'email'], null);
const getTelephone = (state) => state.getIn(['user', 'accountDetails', 'phoneNumbers', 'mobile']);
const getQuotationDetails = (state) => state.getIn(['quotations', 'details'], fromJS({}));

const generateBraintreeAddressParameters = (address1, address2, maxNumberOfLines = 2) => {
  const MAX_CHARS_PER_LINE = 50;
  const addressLine1 = address1 || '';
  const addressLine2 = address2 || '';
  const areLinesWithin3DSlimits = addressLine1.length <= MAX_CHARS_PER_LINE && addressLine2.length <= MAX_CHARS_PER_LINE;

  if (areLinesWithin3DSlimits) {
    return {
      streetAddress: addressLine1,
      extendedAddress: addressLine2
    };
  }

  const concatenatedAddress = `${addressLine1} ${addressLine2}`;
  const addresslines = splitStringIntoLines(concatenatedAddress, MAX_CHARS_PER_LINE);

  return {
    streetAddress: addresslines[0] || '',
    extendedAddress: addresslines[1] || '',
    exceedesMaxNumberOfLines: addresslines.length > maxNumberOfLines
  };
};

export function mappingAddressFromState (address) {
  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    addressPhoneNumber
  } = address;

  const NUMBER_OF_LINES_FOR_SPLIT = 2;

  const { streetAddress,
    extendedAddress,
    exceedesMaxNumberOfLines
  } = generateBraintreeAddressParameters(addressLine1, addressLine2, NUMBER_OF_LINES_FOR_SPLIT);

  return {
    givenName: firstName,
    surname: lastName,
    phoneNumber: addressPhoneNumber,
    streetAddress,
    extendedAddress,
    locality: city,
    postalCode,
    countryCodeAlpha2: COUNTRY_CODE_UK,
    exceedesMaxNumberOfLines
  };
}

function * getMappedDeliveryAddress () {
  const deliveryAddress = yield select(getDeliveryAddress);
  return mappingAddressFromState(deliveryAddress);
}

export function * getAmount (fromQuotationId) {
  if (fromQuotationId) {
    const quotationDetails = yield select(getQuotationDetails);
    const quotation = quotationDetails.get(fromQuotationId);
    const { totalPriceWithVat } = quotationCalculator(quotation);
    return totalPriceWithVat;
  }
  const cart = yield select(getCart);
  const { grandTotalIncVat } = getTotalizedValues(cart);
  return grandTotalIncVat;
}

export function * getThreeDSecureParameters (nonce, bin, billingAddress, fromQuotationId) {
  const mappedBillingAddress = mappingAddressFromState(billingAddress);
  const deliveryAddress = yield * getMappedDeliveryAddress();
  const deliveryAddressExceedLimit = deliveryAddress.exceedesMaxNumberOfLines;
  const BillingAddressExceedLimit = mappedBillingAddress.exceedesMaxNumberOfLines;

  if (deliveryAddressExceedLimit || BillingAddressExceedLimit) {
    if (deliveryAddressExceedLimit && BillingAddressExceedLimit) {
      throw new Error('InputError Billing & Shipping Addresses are too long');
    } else if (deliveryAddressExceedLimit) {
      throw new Error('InputError Shipping Addresses is too long');
    } else if (BillingAddressExceedLimit) {
      throw new Error('InputError Billing Addresses is too long');
    }
  }

  const shippingPhone = yield select(getTelephone);
  const {
    givenName: shippingGivenName,
    surname: shippingSurname,
    phoneNumber: workPhoneNumber,
    streetAddress,
    extendedAddress,
    locality,
    postalCode,
    countryCodeAlpha2
  } = deliveryAddress;
  const amount = yield * getAmount(fromQuotationId);
  const email = yield select(getUserEmail);
  return {
    challengeRequested: true, // enforce 3DS as much as possible
    amount,
    nonce,
    bin,
    email,
    billingAddress: mappedBillingAddress,
    additionalInformation: {
      workPhoneNumber,
      shippingGivenName,
      shippingPhone,
      shippingSurname,
      shippingAddress: {
        streetAddress,
        extendedAddress,
        locality,
        postalCode,
        countryCodeAlpha2
      }
    },
    onLookupComplete: (data, next) => {
      next();
    }
  };
}
