import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  GET_INDUSTRIES_MAP,
  storeIndustriesMap
} from 'client/actions/industriesPageScreenActions';
import { getAllIndustries } from 'shared/endpoints/industryEndpoint';

const industriesMapSelector = (state) => state.getIn(['industriesPage']).toJS().industriesMap;

export function * onGetIndustriesMap () {
  const industries = yield select(industriesMapSelector);
  if (!industries.size) {
    const industriesData = yield call(getAllIndustries);
    const industriesDataMap = new Map(industriesData.map(industry => [parseInt(industry.industryId),
      { ...industry, name: industry.industryName }])
    );
    yield put(storeIndustriesMap(industriesDataMap));
  }
}

export function * watchGetIndustriesMap () {
  yield takeLatest(GET_INDUSTRIES_MAP, onGetIndustriesMap);
}
