import { fromJS } from 'immutable';
import { TOGGLE_DROPDOWN_MENU } from 'client/actions/ui/dropdownMenuActions';
import {
  UI_TOGGLE_ELEMENT,
  TOGGLE_MULTI_USER_ACCOUNT_FORM,
  HANDLE_BILLING_ADDRESS_TOGGLE,
  DELIVERY_OPTION_HOVER,
  TOGGLE_CANCEL_ITEM_DIALOG,
  CLOSE_CANCEL_ITEM_DIALOG,
  CLOSE_SUBMIT_CANCEL_ITEM_DIALOG,
  TOGGLE_SUBMIT_CANCELLATION_REQUEST,
  UPDATE_QTY_TO_CANCEL,
  TOGGLE_SHOW_MORE_ATTRIBUTES,
  TOGGLE_SHOW_MORE_ATTRIBUTES_BUTTON,
  CANCELLATION_REQUEST_FAILED,
  TOGGLE_BASKET_CONFIRMATION_DROPDOWN,
  TOGGLE_BASKET_CONFIRMATION_LOADER
} from 'client/actions/ui/toggleElementActions';
import { NAV_SEARCH_FIELD } from 'client/components/elements/NewHeader/components/MenuItems/SearchBarItem';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';
import { ADDRESS_FORM_DEFAULT_DELIVERY_TOGGLE, ON_NEW_ADDRESS_CLICK, SEND_TO_THIS_ADDRESS, EDIT_ADDRESS } from 'client/actions/addressActions';
import { FETCHING_RECOMMENDATIONS } from '../../actions/recommendationsActions';

const safeUpdate = (state, id) => state.get(id) ? state.set(id, false) : state.set(id, true);
const safeDelete = (state, id) => state.has(id) ? state.delete(id) : state;
const defaultState = fromJS({
  handleBillingAddressToggle: true,
  sendToThisAddressIndex: 0,
  addressFormDefaultDeliveryToggle: false,
  deliveryOptionHover: false,
  toggleMultiUserAccountForm: false,
  cancelledItemDialog: {},
  submittedCancelRequestDialog: {},
  qtyToRequestForCancellation: {},
  showMoreAttributes: false,
  showMoreAttributesButton: false,
  showBasketConfirmationDropdown: false,
  showBaksetConfirmationLoader: false,
  fetchingRecommendations: false
});
export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCHING_RECOMMENDATIONS:
      return state.set('fetchingRecommendations', action.payload);
    case UPDATE_QTY_TO_CANCEL:
      return state.set('qtyToRequestForCancellation', fromJS({ [action.sku]: action.qty }));
    case CLOSE_CANCEL_ITEM_DIALOG:
      return state.set('cancelledItemDialog', fromJS({}));
    case CLOSE_SUBMIT_CANCEL_ITEM_DIALOG:
      return state.set('submittedCancelRequestDialog', fromJS({}));
    case CANCELLATION_REQUEST_FAILED:
      return state
        .set('qtyToRequestForCancellation', fromJS({}))
        .set('submittedCancelRequestDialog', fromJS({}))
        .set('cancelledItemDialog', fromJS({}));
    case TOGGLE_SUBMIT_CANCELLATION_REQUEST:
      const cancelRequest = { [action.orderId]: action.sku };
      return state
        .set('qtyToRequestForCancellation', fromJS({}))
        .set('submittedCancelRequestDialog', fromJS(cancelRequest))
        .set('cancelledItemDialog', fromJS({}));
    case TOGGLE_CANCEL_ITEM_DIALOG:
      const newCancelItemDialog = { [action.orderId]: action.sku };
      if (state.get('cancelledItemDialog', fromJS({})).size) {
        return state
          .set('cancelledItemDialog', fromJS({}))
          .set('qtyToRequestForCancellation', fromJS({}));
      }
      return state
        .set('cancelledItemDialog', fromJS(newCancelItemDialog), fromJS({}))
        .set('submittedCancelRequestDialog', fromJS({}))
        .set('qtyToRequestForCancellation', fromJS({}));

    // Specific case to close NAV_SEARCH_FIELD
    case TOGGLE_DROPDOWN_MENU:
      return action.visibleDropdown === VISIBLE_DROPDOWN.NONE ? state : safeDelete(state, NAV_SEARCH_FIELD);

    // Generic Element toggle
    case UI_TOGGLE_ELEMENT:
      return action.payload.id ? safeUpdate(state, action.payload.id) : state;

    // handles toggle billing address
    case HANDLE_BILLING_ADDRESS_TOGGLE:
      return state.set('handleBillingAddressToggle', action.payload);
    case ADDRESS_FORM_DEFAULT_DELIVERY_TOGGLE:
      return state.set('addressFormDefaultDeliveryToggle', !action.payload.isDefault);
    case EDIT_ADDRESS:
      return state
        .set('addressFormDefaultDeliveryToggle', action.payload.isDefault);
    case ON_NEW_ADDRESS_CLICK:
      return state
        .set('addressFormDefaultDeliveryToggle', false);
    case SEND_TO_THIS_ADDRESS:
      return state.set('sendToThisAddressIndex', action.payload);
    case TOGGLE_BASKET_CONFIRMATION_LOADER:
      return state.set('showBaksetConfirmationLoader', action.toggleFlag);
    case TOGGLE_BASKET_CONFIRMATION_DROPDOWN:
      return state.set('showBasketConfirmationDropdown', action.toggleFlag);
    case DELIVERY_OPTION_HOVER:
      return state.set('deliveryOptionHover', action.payload);
    case TOGGLE_MULTI_USER_ACCOUNT_FORM:
      return state.set('toggleMultiUserAccountForm', !state.get('toggleMultiUserAccountForm'));
    case TOGGLE_SHOW_MORE_ATTRIBUTES:
      return state.set('showMoreAttributes', action.toggleFlag);
    case TOGGLE_SHOW_MORE_ATTRIBUTES_BUTTON:
      return state.set('showMoreAttributesButton', action.toggleFlag);
    default:
      return state;
  }
};
