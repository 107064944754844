'use strict';

import { fromJS } from 'immutable';
import {
  RESET_USER_QUESTION,
  REQUEST_USER_QUESTION,
  RESPONSE_USER_QUESTION_ERROR,
  RESPONSE_USER_QUESTION_SUCCESS,
  TOGGLE_USER_QUESTION
} from 'client/actions/userActions';

export const defaultState = fromJS({ reportRequestFail: null, requestSuccessStatus: false, toggleReportErrorOrAskQuestion: false });

const reportErrorOrQuestionFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_USER_QUESTION:
    case REQUEST_USER_QUESTION:
      return state
        .set('reportRequestFail', null)
        .set('requestSuccessStatus', false);
    case RESPONSE_USER_QUESTION_SUCCESS:
      return state
        .set('reportRequestFail', null)
        .set('requestSuccessStatus', true);
    case RESPONSE_USER_QUESTION_ERROR:
      return state
        .set('reportRequestFail', action.error)
        .set('requestSuccessStatus', false);
    case TOGGLE_USER_QUESTION:
      if (action.payload) {
        return state.set('toggleReportErrorOrAskQuestion', action.payload);
      }
      return state.set('toggleReportErrorOrAskQuestion', !state.get('toggleReportErrorOrAskQuestion'));
    default:
      return state;
  }
};

export default reportErrorOrQuestionFormReducer;
