import { createAction } from 'redux-actions';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';

export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export function getAccountDetails (onLoginSuccess = null) {
  return {
    type: GET_ACCOUNT_DETAILS,
    isLoginSuccess: onLoginSuccess
  };
}

export const RELOAD_ACCOUNT_DETAILS = 'RELOAD_ACCOUNT_DETAILS';
export const reloadAccountDetails = createAction(RELOAD_ACCOUNT_DETAILS);

export const LOAD_USER_PREFERENCE = 'LOAD_USER_PREFERENCE';
export const loadUserPreference = createAction(LOAD_USER_PREFERENCE);

export const RECEIVE_ACCOUNT_DETAILS = 'RECEIVE_ACCOUNT_DETAILS';

export function receiveAccountDetails (accountDetails, isLoginSuccess) {
  return {
    type: RECEIVE_ACCOUNT_DETAILS,
    payload: accountDetails,
    isLoginSuccess: isLoginSuccess
  };
}

export const RECEIVE_USER_PREFERENCE = 'RECEIVE_USER_PREFERENCE';
export function receiveUserPreference (isPriceWithVatLong) {
  return {
    type: RECEIVE_USER_PREFERENCE,
    isPriceWithVatLong: isPriceWithVatLong
  };
}
export const RECIEVE_CHANGE_ACCOUNT_DETAILS_SUCCESS = 'RECIEVE_CHANGE_ACCOUNT_DETAILS_SUCCESS';
export const receiveChangeAccountDetailsSuccess = createAction(RECIEVE_CHANGE_ACCOUNT_DETAILS_SUCCESS);

export const RECIEVE_CHANGE_ACCOUNT_DETAILS_ERROR = 'RECIEVE_CHANGE_ACCOUNT_DETAILS_ERROR';
export function receiveChangeAccountDetailsError (error) {
  return {
    type: RECIEVE_CHANGE_ACCOUNT_DETAILS_ERROR,
    error: buildErrorMessageFromError(error)
  };
}

export const UPDATE_MARKETING_PREFERENCES = 'UPDATE_MARKETING_PREFERENCES';
export const updateMarketingPreferences = createAction(UPDATE_MARKETING_PREFERENCES);

export const RECIEVE_UPDATE_MARKETING_PREFERENCES_SUCCESS = 'RECIEVE_UPDATE_MARKETING_PREFERENCES_SUCCESS';
export const receiveUpdateMarketingPreferencesSuccess = createAction(RECIEVE_UPDATE_MARKETING_PREFERENCES_SUCCESS);

export const RECIEVE_UPDATE_MARKETING_PREFERENCES_ERROR = 'RECIEVE_UPDATE_MARKETING_PREFERENCES_ERROR';
export const receiveUpdateMarketingPreferencesError = createAction(RECIEVE_UPDATE_MARKETING_PREFERENCES_ERROR);
