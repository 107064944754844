import { createAction } from 'redux-actions';

export const SET_LOAD_STATE_TYPE = 'checkoutAction.SET_LOAD_STATE_TYPE';
export const setCheckoutLoadState = createAction(SET_LOAD_STATE_TYPE);

export const RESET_CHECKOUT_STATE = 'checkoutAction.RESET_CHECKOUT_STATE';
export const resetCheckoutState = createAction(RESET_CHECKOUT_STATE);

export const SET_DELIVERY_PANEL_LOAD_STATE_TYPE = 'checkoutAction.SET_DELIVERY_PANEL_LOAD_STATE_TYPE';
export const setDeliveryPanelLoadState = createAction(SET_DELIVERY_PANEL_LOAD_STATE_TYPE);

export const GET_NECESSARY_DETAILS_CHECKOUT_PROCESS = 'checkoutAction.GET_NECESSARY_DETAILS_CHECKOUT_PROCESS';
export const getDetailsForCheckoutProcess = createAction(GET_NECESSARY_DETAILS_CHECKOUT_PROCESS);

export const SET_ACTIVE_PANEL_ID = 'checkoutAction.SET_ACTIVE_PANEL_ID';
export const setActivePanelId = createAction(SET_ACTIVE_PANEL_ID);

export const TOGGLE_ACCORDION_STATE = 'checkoutAction.TOGGLE_ACCORDION_STATE';
export const toggleAccordionState = createAction(TOGGLE_ACCORDION_STATE);

export const START_EDIT_DELIVERY_ADDRESS = 'checkoutAction.START_EDIT_DELIVERY_ADDRESS';
export const onEditDeliveryAddressClick = createAction(START_EDIT_DELIVERY_ADDRESS);

export const SHOW_DELIVERY_ADDRESS_FORM = 'checkoutAction.SHOW_DELIVERY_ADDRESS_FORM';
export const showDeliveryAddressForm = createAction(SHOW_DELIVERY_ADDRESS_FORM);

export const REQUEST_SHOW_ADDRESS_BOOK = 'checkoutAction.REQUEST_SHOW_ADDRESS_BOOK';
export const onDisplayAddressBookClick = createAction(REQUEST_SHOW_ADDRESS_BOOK);

export const SHOW_MY_ADDRESS_BOOK = 'checkoutAction.SHOW_MY_ADDRESS_BOOK';
export const showMyAddressBook = createAction(SHOW_MY_ADDRESS_BOOK);

export const REQUEST_CHANGE_DELIVERY_ADDRESS = 'checkoutAction.REQUEST_CHANGE_DELIVERY_ADDRESS';
export const onSelectNewAddress = createAction(REQUEST_CHANGE_DELIVERY_ADDRESS);

export const SET_DELIVERY_ID_BEING_EDITED = 'checkoutAction.SET_DELIVERY_ID_BEING_EDITED';
export const setDeliveryIdBeingEdited = createAction(SET_DELIVERY_ID_BEING_EDITED);

export const CONFIRM_DELIVERY_ADDRESS = 'checkoutAction.CONFIRM_DELIVERY_ADDRESS';
export const onConfirmDeliveryAddress = createAction(CONFIRM_DELIVERY_ADDRESS);

export const BACK_DELIVERY_PANEL = 'checkoutAction.BACK_DELIVERY_PANEL';
export const onBackToDeliveryPanel = createAction(BACK_DELIVERY_PANEL);

export const REQUEST_ADD_NEW_ADDRESS = 'checkoutAction.REQUEST_ADD_NEW_ADDRESS';
export const handleAddNewAddressClick = createAction(REQUEST_ADD_NEW_ADDRESS);

export const CONFIRM_DELIVERY_ADDRESS_FROM_ADDRESSLIST_DROPDOWN = 'checkoutAction.CONFIRM_DELIVERY_ADDRESS_FROM_ADDRESSLIST_DROPDOWN';
export const onChangeDeliveryAddressFromAddressListDropDown = createAction(CONFIRM_DELIVERY_ADDRESS_FROM_ADDRESSLIST_DROPDOWN);

export const CANCEL_EDIT_ADDRESS = 'checkoutAction.CANCEL_EDIT_ADDRESS';
export const cancelEditAddresss = createAction(CANCEL_EDIT_ADDRESS);

export const SAVE_ADDRESS = 'checkoutAction.SAVE_ADDRESS';
export const saveAddresss = createAction(SAVE_ADDRESS);

export const CHANGE_ADDRESSBOOK_VIEWTYPE = 'checkoutAction.CHANGE_ADDRESSBOOK_VIEWTYPE';
export const changeAddressBookViewType = createAction(CHANGE_ADDRESSBOOK_VIEWTYPE);

export const PROCEED_TO_NEXT_STAGE = 'checkoutAction.PROCEED_TO_NEXT_STAGE';
export const proceedToNextStage = createAction(PROCEED_TO_NEXT_STAGE);

export const REQUEST_CHANGE_BILLING_ADDRESS = 'checkoutAction.REQUEST_CHANGE_BILLING_ADDRESS';
export const requestChangeBillingAddress = createAction(REQUEST_CHANGE_BILLING_ADDRESS);

export const REQUEST_GO_TO_REVIEW_ORDER_PANEL = 'REQUEST_GO_TO_REVIEW_ORDER_PANEL';
export const onGoToReviewOrderPanel = createAction(REQUEST_GO_TO_REVIEW_ORDER_PANEL);

export const REQUEST_SKIP_PAYMENT_FLOW = 'REQUEST_SKIP_PAYMENT_FLOW';
export const skipPaymentFlow = createAction(REQUEST_SKIP_PAYMENT_FLOW);

export const SKIP_PAYMENT_FLOW_SUCCESS = 'SKIP_PAYMENT_FLOW_SUCCESS';
export const skipPaymentFlowSuccesss = createAction(SKIP_PAYMENT_FLOW_SUCCESS);

export const RESET_ALL_SCROLLS = 'checkoutAction.RESET_ALL_SCROLLS';
export const resetAllScrolls = createAction(RESET_ALL_SCROLLS);

export const REQUEST_SCROLL_TO_SECTION = 'checkoutAction.REQUEST_SCROLL_TO_SECTION';
export const requestScrollToSection = createAction(REQUEST_SCROLL_TO_SECTION);

export const SCROLL_TO_SECTION = 'checkoutAction.SCROLL_TO_SECTION';
export const scrollToSection = createAction(SCROLL_TO_SECTION);

export const PLACE_ORDER_FAILED = 'checkoutAction.PLACE_ORDER_FAILED';
export const placeOrderFailed = createAction(PLACE_ORDER_FAILED);

export const ADDRESS_SAVED_SUCCESS = 'checkoutAction.ADDRESS_SAVED_SUCCESS';
export const addressSavedSuccess = createAction(ADDRESS_SAVED_SUCCESS);

export const PLACE_ORDER_SUCCESS = 'checkoutAction.PLACE_ORDER_SUCCESS';
export const placeOrderSuccess = createAction(PLACE_ORDER_SUCCESS);

export const RESET_PLACE_ORDER_ERROR = 'checkoutAction.RESET_PLACE_ORDER_ERROR';
export const resetPlaceOrderError = createAction(RESET_PLACE_ORDER_ERROR);
