import { put, takeLatest, call } from 'redux-saga/effects';
import { resetPassword } from 'shared/endpoints/securityEndpoint';
import { gotoExternalUrl } from 'client/actions/routeActions';

import {
  ON_RESET_PASSWORD,
  onResetPasswordSuccess,
  onResetPasswordError,
  setFinalDestination
} from 'client/actions/userActions';

export function * onResetPassword (action) {
  try {
    yield call(resetPassword, action.resetPasswordToken, action.newPassword);
    yield put(onResetPasswordSuccess());
    yield put(setFinalDestination('/'));
    yield put(gotoExternalUrl('/login'));
  } catch (e) {
    yield put(onResetPasswordError(e));
  }
}

export function * watchResetPasswordScreen () {
  yield takeLatest(ON_RESET_PASSWORD, onResetPassword);
}
