import { getPages } from 'shared/constants/seo';

export const getMetaTagsByUrl = (pageUrl, metaTags, totalProductsCount, params) => {
  const updatedMetaTags = [...metaTags];
  const page = getPages(pageUrl, totalProductsCount, params);
  const pageMetaTags = page ? page.metaTags : [];

  if (!pageMetaTags.length) {
    return metaTags;
  }

  return updatedMetaTags.map((metaTag) => {
    const pageMetaTagName = pageMetaTags.find(el => el.name && metaTag.name && el.name === metaTag.name);
    const pageMetaTagProperty = pageMetaTags.find(el => el.property && metaTag.property && el.property === metaTag.property);
    if (pageMetaTagName) {
      metaTag.content = pageMetaTagName.content;
    }

    if (pageMetaTagProperty) {
      metaTag.content = pageMetaTagProperty.content;
    }

    return metaTag;
  });
};
