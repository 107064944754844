import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ListItems from '../elements/List';
import { clearRecommendations } from 'client/actions/recommendationsActions';
import uniqBy from 'lodash/uniqBy';
import ConnectedUnleashWrapper from '../../unleashABTest/ConnectedUnleashWrapper';
import { RECOMMENDATIONS_FEATURE_TOGGLE } from '../../../../../shared/constants/abTesting';
import { getParsedRecommendationPayload, getIsRecomLocationActive } from '../utils';
import { getToggle } from '../../../../utils/unleashUtils';

const defaultResults = { results: [], title: '' };

export const RecommendationsCarousel = ({ bottomBorder, addToCart, isMobile, disableModal = false, recs = defaultResults, panelIndex, isPriceWithVat, recommendationLocation }) => (
  <ListItems
    items={ recs.results }
    title={ recs.title }
    panelIndex={ panelIndex }
    addToCart={ addToCart }
    disableModal={ disableModal }
    bottomBorder={ bottomBorder }
    isMobile={ isMobile }
    isPriceWithVat={ isPriceWithVat }
    recommendationLocation={ recommendationLocation }
  />
);

class FamilyBottom extends Component {
  componentWillUnmount () {
    this.props.clearRecommendations();
  }

  render () {
    const { bottomBorder, addToCart, disableModal = false, allRecs = List(), isMobile, isPriceWithVat, location, unleashToggles } = this.props;
    const recommendationToggle = getToggle(unleashToggles, RECOMMENDATIONS_FEATURE_TOGGLE).value;
    const parsedPayload = getParsedRecommendationPayload(recommendationToggle);
    const isRecomLocActive = getIsRecomLocationActive(parsedPayload, location);
    const allRecsPlain = uniqBy(allRecs.toJS(), 'tableName');

    return (
      <>
        <div>
          {
          allRecsPlain?.map((recs, panelIndex) => (
            <RecommendationsCarousel
              key={ recs.recommendationType }
              type={ recs.recommendationType }
              panelIndex={ panelIndex }
              bottomBorder={ bottomBorder }
              addToCart={ addToCart }
              disableModal={ disableModal }
              recs={ recs }
              isMobile={ isMobile }
              isPriceWithVat={ isPriceWithVat }
              recommendationLocation={ location } />
          ))
          }
        </div>
        { isRecomLocActive ? <ConnectedUnleashWrapper flag={ RECOMMENDATIONS_FEATURE_TOGGLE } recommendationLocation={ location } /> : null }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allRecs: state.get('recommendations'),
    isMobile: state.getIn(['deviceInfo', 'isMobile']),
    unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS()
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearRecommendations: () => dispatch(clearRecommendations())
  };
};

const ConnectedFamilyBottom = connect(mapStateToProps, mapDispatchToProps)(FamilyBottom);

const FamilyBottomWrapper = (props) => {
  return <ConnectedFamilyBottom { ...props }/>;
};

export default FamilyBottomWrapper;
export { FamilyBottom };
