import { fromJS } from 'immutable';
import { RECEIVE_ACCOUNT_ORDER_MESSAGE_SUCCESS } from 'client/actions/creditAccountActions';

const defaultState = fromJS([]);

const businessAccount = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_ACCOUNT_ORDER_MESSAGE_SUCCESS:
      return fromJS(action.businessAccountOnHoldMessage);
    default:
      return state;
  }
};

export default businessAccount;
