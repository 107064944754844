import { createStore, applyMiddleware, compose } from 'redux';
import createLogger from 'redux-logger';
import Immutable from 'immutable';
import createSagaMiddleware, { END } from 'redux-saga';
import appReducer from './reducers/appReducer';
import sagas from './sagas';
import actionSubscriber from '../shared/middleware/ActionSubscriber';

function getStore (initialServerState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
    actionSubscriber.middleware
  ];
  const predicate = (getState, { type }) => {
    return type !== 'EFFECT_TRIGGERED' && type !== 'EFFECT_RESOLVED';
  };
  if (process.browser && process.env.DEBUG === 'true') {
    const logger = createLogger({
      collapsed: true,
      predicate,
      stateTransformer: (state) => {
        return state.toJS();
      }
    });
    middlewares.push(logger);
  }

  // The redux extension allows developers to inspect the current state of the app through redux devtools (https://github.com/reduxjs/redux-devtools)
  const reduxExtension = typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f;

  // Create the store creator
  const createStoreWithMiddleware = compose(applyMiddleware.apply(this, middlewares), reduxExtension)(createStore);

  // Convert raw initial state into an immutable object
  const initialState = Immutable.fromJS(
    process.browser ? window.__initialValues.initialState : initialServerState
  );

  // Create the store
  const store = createStoreWithMiddleware(appReducer, initialState);

  if (process.browser) {
    sagaMiddleware.run(sagas);
  } else {
    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);
  }

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('./reducers/appReducer', () => {
      const nextRootReducer = require('./reducers/appReducer');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default getStore;
