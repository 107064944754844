function flattenProducts (list = []) {
  if (!list) {
    return [];
  }
  return list.reduce(
    (a, b) => a.concat(Array.isArray(b.products)
      ? flattenProducts(b.products) : b), []
  );
}

function calculateItemTotalQuantity (item, skuDataMap) {
  const packQty = skuDataMap[item.sku].packQty;
  return item.qty / packQty;
}

function calculateTotalPrice (quantity, unitPrice) {
  const totalPrice = unitPrice * quantity;
  return parseFloat(Math.round(totalPrice * 100) / 100).toFixed(2);
}

export {
  flattenProducts,
  calculateItemTotalQuantity,
  calculateTotalPrice
};
