import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeGooglePay } from './payByGooglePayActions';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';

export const payByGooglePay = ({
  selector,
  initializeGooglePay,
  reviewOrderButton,
  googlePayData
}) => {
  useEffect(() => {
    if (googlePayData) {
      initializeGooglePay({
        selector
      });
    }
  }, [googlePayData]);
  const isCheckout = selector === CHECKOUT_SELECTOR;
  return (
    <div className='google-pay-button-wrapper'>
      { isCheckout && !googlePayData ? reviewOrderButton : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    googlePayData: state.getIn(['braintree', 'googlePay', 'data'])
  };
};

const mapDispatchToProps = {
  initializeGooglePay
};

export default connect(mapStateToProps, mapDispatchToProps)(payByGooglePay);
