import React from 'react';
import debounce from 'lodash/debounce';
import { isValidQuantity, isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import Select, { components } from 'react-select';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./quantityInput.scss');
}
const E_KEY = 69;
const MINUS_KEY = 189;
const PLUS_KEY = 187;
const DOT_KEY = 190;
const KEY_LIST = [E_KEY, MINUS_KEY, PLUS_KEY, DOT_KEY];

class QuantityInput extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      isQuantitySelector: true,
      isInitialValueOverTwenty: false,
      quantity: props.quantity,
      focus: false
    };
  }

  componentWillReceiveProps (newProps) { // eslint-disable-line react/no-deprecated
    const quantity = newProps.quantity;
    if (quantity !== this.state.quantity) {
      this.setState({ ...this.state, quantity });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount () {
    const qtyMultiplier = this.props.qtyMultiplier || 1;
    const MAX_SELECTOR_VALUE = 19 * qtyMultiplier;
    const isQuantityValid = isValidQuantity(this.props.quantity, qtyMultiplier);
    if (this.props.quantity > MAX_SELECTOR_VALUE || !isQuantityValid) {
      this.setState({
        ...this.state,
        isInitialValueOverTwenty: true
      });
    }
  }

  onQuantityChange = (value, debounceFlag = true) => {
    const { setQuantity, removeProductLine, product, eligibleForDeliveryOptions, addressId, isFromBasketInterstitial, minOrderQuantity } = this.props;
    const orderLine = product; // in sagas is looking for this key name when setting values
    const quantity = parseInt(value, 10);
    const previousBreakPriceObject = product.get('breakPriceObject', {});
    const sku = orderLine.get('sku');
    if (quantity > 0) {
      this.setState({ quantity });
      debounceFlag
        ? this.debouncedAction(setQuantity, { orderLine, quantity, id: sku, previousBreakPriceObject, eligibleForDeliveryOptions, addressId })
        : setQuantity({ orderLine, quantity, id: sku, previousBreakPriceObject, eligibleForDeliveryOptions, addressId });
    } else if (quantity === 0) {
      if (isFromBasketInterstitial) {
        this.setState({ quantity: minOrderQuantity });
        this.debouncedAction(setQuantity, { orderLine, quantity: minOrderQuantity, id: sku, previousBreakPriceObject, eligibleForDeliveryOptions, addressId });
      } else {
        this.setState({ quantity });
        this.debouncedAction(removeProductLine, orderLine, eligibleForDeliveryOptions, addressId);
      }
    } else {
      this.cancelSetQuantity();
      this.setState({ quantity: value ? quantity : '' });
    }
  }

  onSelectChange = (event) => {
    event.value === 'other'
      ? this.setState({ isQuantitySelector: false, quantity: '', focus: true })
      : this.props.productAddToCart
        ? this.props.productAddToCartHandler(parseInt(event.value, 10))
        : this.onQuantityChange(parseInt(event.value, 10), false);
  }

  debouncedAction () {
    const DEBOUNCE_DELAY = 250;
    this.cancelSetQuantity();
    const [fn, ...rest] = arguments;
    this.debouncedSetQuantity = debounce(() => {
      fn.apply(this, rest);
    }, DEBOUNCE_DELAY);
    this.debouncedSetQuantity();
  }

  cancelSetQuantity () {
    if (this.debouncedSetQuantity) {
      this.debouncedSetQuantity.cancel();
    }
  }

  preventNonNumericalInput = (e) => {
    e = e || window.event;
    // eslint-disable-next-line eqeqeq
    const charCode = (typeof e.which == 'undefined') ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  };

  onKeyDownHandler = (e) => {
    if (KEY_LIST.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator { ...props }>
          <SvgLoader
            className="dropDownIndicatorSvg"
            xlinkHref={ props.selectProps.menuIsOpen ? 'arrow-up' : 'arrow-down' }/>
        </components.DropdownIndicator>
      )
    );
  };

  render () {
    const {
      unavailableStatus,
      disabled,
      productAddToCart,
      productAddToCartHandler,
      qtyMultiplier = 1,
      minOrderQuantity = 1,
      maxOrderQuantity = null,
      displayOptionsCount = 20,
      displayQuantityDropdownAsModal,
      quantityDropdownDirection,
      closeMenuOnScroll
    } = this.props;
    const { quantity, isQuantitySelector, isInitialValueOverTwenty, focus } = this.state;
    const MAX_QUANTITY = displayOptionsCount * qtyMultiplier; // MAX 19 for quantity selector
    const quantityLessThanMax = quantity < MAX_QUANTITY;
    const onChangeHandler = productAddToCart ? productAddToCartHandler : this.onQuantityChange;
    const onBlurHandler = productAddToCart ? productAddToCartHandler : () => { };
    const isQuantityValid = isValidQuantityWithMinMaxMultiplier(this.state.quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    const menuPortalTarget = displayQuantityDropdownAsModal && document.body;
    const selectOptions = Array.from(
      { length: MAX_QUANTITY - minOrderQuantity },
      (_, i) => i + minOrderQuantity
    );
    const options = [...selectOptions]
      .filter(option => (option % qtyMultiplier === 0))
      .map(option => ({ value: option, label: option }));
    options.push({ value: 'other', label: `${MAX_QUANTITY}+` });
    return <React.Fragment>
      {
        isQuantitySelector && !isInitialValueOverTwenty && quantityLessThanMax ? <Select
          menuPortalTarget={ menuPortalTarget ? document.body : null }
          menuPlacement={ quantityDropdownDirection ? quantityDropdownDirection : 'auto' }
          closeMenuOnScroll={ closeMenuOnScroll ? false : (e) => e.target.classList && !e.target.classList.contains('react-select__menu-list') }
          placeholder={ quantity }
          options={ options }
          className={ `basic-single QuantityInput_selector ${(!unavailableStatus || !isQuantityValid) ? 'unavailable' : ''}` }
          classNamePrefix="react-select"
          value={ quantity }
          isDisabled={ disabled }
          components={{ DropdownIndicator: this.DropdownIndicator }}
          onChange={ this.onSelectChange } />
          : <input
            className={ `form-control QuantityInput${(!unavailableStatus || !isQuantityValid) ? '_unavailable' : ''}` }
            value={ quantity }
            type="number"
            min="1"
            disabled={ disabled }
            autoFocus={ focus }
            onBlur={ (e) => onBlurHandler(e.target.value) }
            onChange={ (e) => onChangeHandler(e.target.value) }
            onKeyDown={ (e) => this.onKeyDownHandler(e) }
            data-e2e="quotationProductLineQuantityInput"
          />
      }
    </React.Fragment>;
  }
}

export default QuantityInput;
