import React from 'react';
import SvgLoader from '../../svg/SvgLoader';

if (process.browser) {
  require('./AgeRestrictedUnableToDeliverBadge.scss');
}

export const AgeRestrictedUnableToDeliverBadge = (props) => {
  return <div className="AgeRestrictedUnableToDeliverBadge" { ...props }>
    <SvgLoader className="AgeRestrictedUnableToDeliverBadge_warningIcon" xlinkHref="warning"/>
    We are unable to deliver age restricted products to this delivery address.
  </div>;
};
