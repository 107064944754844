import { call, put, select } from 'redux-saga/effects';

import { clearSale } from 'client/actions/saleActions';
import { isSaleActive, timeLeftAsMomentDuration } from 'shared/utils/saleUtils';

const SALE_REMOVE_DELAY_MS = 3000;

const delay = (timeout) => (new Promise((resolve) => {
  setTimeout(resolve, timeout);
}));

export function * watchSaleExpiry () {
  const sale = yield select((state) => state.get('sale'));
  if (isSaleActive(sale)) {
    const timeLeftMs = timeLeftAsMomentDuration(sale).asMilliseconds();
    yield call(delay, timeLeftMs + SALE_REMOVE_DELAY_MS);
    yield put(clearSale());
  }
}
