import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hidePausePlayRepeatOrderDialog } from '../../../actions/ui/dialogActions';
import { requestPausePlayRepeatOrderConfirm } from '../../../actions/repeatOrdersActions';
import { Map } from 'immutable';
import moment from 'moment';
import { PAUSED, RUNNING } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./RemoveRepeatOrderDialog.scss');
}

function mapStateToProps (state) {
  return {
    pausePlayRepeatOrderDialogIsVisible: state.getIn(['ui', 'dialogs', 'pausePlayRepeatOrder', 'isVisible']),
    repeatOrder: state.getIn(['ui', 'dialogs', 'pausePlayRepeatOrder', 'repeatOrder'], Map({})),
    nextDeliveryDate: state.getIn(['ui', 'dialogs', 'pausePlayRepeatOrder', 'nextDeliveryDate'], null),
    newState: state.getIn(['ui', 'dialogs', 'pausePlayRepeatOrder', 'newState'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hidePausePlayRepeatOrderDialog: flowRight(dispatch, hidePausePlayRepeatOrderDialog),
    requestPausePlayRepeatOrderConfirm: flowRight(dispatch, requestPausePlayRepeatOrderConfirm)
  };
}

const PausePlayRepeatOrderDialog = (props) => {
  const {
    pausePlayRepeatOrderDialogIsVisible,
    repeatOrder,
    requestPausePlayRepeatOrderConfirm,
    hidePausePlayRepeatOrderDialog,
    newState,
    nextDeliveryDate
  } = props;

  const repeatOrderState = repeatOrder.get('state');

  return (
    <Modal className="RemoveRepeatOrdersDialog" show={ pausePlayRepeatOrderDialogIsVisible }
      onHide={ hidePausePlayRepeatOrderDialog }>
      <Modal.Header className="RemoveRepeatOrderDialog_header" closeButton/>
      <Modal.Body>
        <div className="product modal-body_content">
          <div>
            { getLocalizedString('subscriptions.dialog.confirmation.start') }
            {newState === PAUSED ? 'Pause' : 'Start Again'}
            { getLocalizedString('subscriptions.dialog.confirmation.end') }?
            <br />
            {
              newState === RUNNING ? <p> { getLocalizedString('subscriptions.dialog.next.delivery') }:{' '}
                { moment(nextDeliveryDate).format('MMMM Do, YYYY')} </p> : null
            }
          </div>
        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            onClick={ hidePausePlayRepeatOrderDialog }>
            { getLocalizedString('subscriptions.dialog.cancel') }
          </SecondaryButton>
          <PrimaryButton
            onClick={ requestPausePlayRepeatOrderConfirm }>
            { repeatOrderState === RUNNING
              ? getLocalizedString('subscriptions.dialog.pause')
              : getLocalizedString('subscriptions.dialog.start.again') }
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PausePlayRepeatOrderDialog.propTypes = {
  removeRepeatOrderDialogIsVisible: PropTypes.bool,
  requestPausePlayRepeatOrderConfirm: PropTypes.func,
  hideRemoveRepeatOrderDialog: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PausePlayRepeatOrderDialog);
