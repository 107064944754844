import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { CardItem, DropdownTitleCard } from './savedCardsMenu';
import { setDefaultCard, setSelectedCard } from './payByExistingCardActions';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { getLocalizedString } from 'localization/localizer';
import SvgLoader from 'client/components/svg/SvgLoader';
import { PAY_BY_EXISTING_CARD } from '../PaymentMethod/constants';
import { InfoDefaultCards } from '../shared/infoIcon';
import RadioSelector from '../shared/RadioSelector';

if (process.browser) {
  require('./savedCardList.scss');
}

export const SavedCardsList = ({ selector, paymentType, selectedCard, existingCards, setDefaultCard, setSelectedCard, changePaymentMethod, styles }) => {
  const isPayByExistingCard = paymentType === PAY_BY_EXISTING_CARD;

  return (
    <div className={ `SavedCardsListContainer ${styles}` } data-e2e="savedCardsListContainer">
      <RadioSelector
        checked={ isPayByExistingCard }
        onClick={ () => changePaymentMethod({
          selector: selector,
          paymentMethod: PAY_BY_EXISTING_CARD,
          cancelled: true
        }) }
        datae2e='chooseFromOneOfYourSavedCardsSelector'
        selectorText={ getLocalizedString('singlePageCheckout.panel.chooseFromOneOfYourSavedCards') }
      />
      <div className="SavedCards_listoptions">
        <DropdownButton
          disabled={ !isPayByExistingCard }
          className={ `PayByExistingCard_dropdown ${!isPayByExistingCard ? 'SavedCards_dropdownDisabled' : ''}` }
          styles={ `${!isPayByExistingCard ? 'backgroundColor: "#D9D9D9"' : ''}` }
          title={ <DropdownTitleCard card={ selectedCard } /> }
          data-e2e="PayByExistingCard_dropdown">
          <div className="PayByExistingCard_subheader">
            {getLocalizedString('payment.label.pay.with.existingcard')}
          </div>
          {existingCards.map((card) => <MenuItem key={ card.token } onSelect={ () => {
            setSelectedCard({
              selector,
              selectedCard: card
            });
          } }>
            <CardItem card={ card } />
          </MenuItem>
          )}
        </DropdownButton>

        <div className={ `PayByExistingCard_default_card-set ${!isPayByExistingCard ? 'SavedCards_defaul-card-disabled' : ''}` } data-e2e="PayByExistingCard_set_default_card">
          <SvgLoader
            className="PayByExistingCard_default_card_checkbox"
            xlinkHref={ selectedCard.isDefault ? '#checkbox-icon-checked' : '#checkbox-icon-unchecked' }
            onClick={ () => {
              setDefaultCard({ selector, isDefault: !selectedCard.isDefault });
            } }
          />
          <div className="PayByExistingCard_default_card_text">{getLocalizedString('payment.save.as.default')}</div>
          <div className="PayByExistingCard_info_icon">
            {InfoDefaultCards}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps (state, ownProps) {
  const braintree = state.getIn(['braintree'], {})?.toJS();
  return {
    existingCards: braintree?.cards || [],
    selectedCard: state.getIn(['ui', 'payByExistingCard', ownProps.selector, 'selectedCard'], Map()).toJS()
  };
}

const mapDispatchToProps = {
  setSelectedCard,
  setDefaultCard,
  changePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedCardsList);
