export const DEFAULT_SORTBY = '-orderDate';
export const ALL_ORDERS = 'allOrders';
export const MY_ORDERS = 'myOrders';
export const OPEN_ORDERS = 'openOrders';
export const CANCELLED_ORDERS = 'cancelledOrders';
export const DISPATCHED_ORDERS = 'dispatchedOrders';
export const DEFAULT_PAGESIZE = 10;
export const CHAR_LIMIT = 2;

// dateRange options
export const SEVEN_DAYS = 7;
export const THIRTY_DAYS = 30;
export const SIX_MONTHS = 180;
export const ONE_YEAR = 365;
export const ALL_DAYS = 'All';
export const DEBOUNCE_DELAY = 500;

// dateRange options array
export const dateRangeOptions = [
  { value: SEVEN_DAYS, label: 'Last 7 days' },
  { value: THIRTY_DAYS, label: 'Last 30 days' },
  { value: SIX_MONTHS, label: 'Last 180 days' },
  { value: ONE_YEAR, label: 'Last 360 days' },
  { value: ALL_DAYS, label: 'All' }
];

export const ORDER_CANCELLATION_REASON_FAILED_AUTHORIZATION = 'failed authorization';

const NI_REGEX = /BT\s*\d{1,2}\s*\d{1,2}[A-Z]{2}$/i;
const ROI_REGEX = /\b[A-Z0-9]{3}(?:[ \d])?[A-Z0-9]{4}\b/i;
const IOM_REGEX = /^IM[0-9]{1,2}[\s]*([\d][A-Za-z]{2})$/i; // Isle of Man
const IOW_REGEX = /^PO(3[0-9]|4[01])[\s]*([\d][A-Za-z]{2})$/i; // Isle Of Wight
export const NON_DELIVERABLE_TO_REGEXES = {
  NI: NI_REGEX,
  ROI: ROI_REGEX,
  IM: IOM_REGEX,
  PO: IOW_REGEX
};
