import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SvgLoader from 'client/components/svg/SvgLoader';
import { Empty } from 'client/components/elements/empty/Empty';
import FamilyItem from './FamilyItem';
import ProductItem from './ProductItem';
import { addToCart } from 'client/actions/cartActions';
import { getMessages, LONGER_LEAD_TIME_TEXT } from 'client/utils/stockMessageUtil';
import { RECENTLY_VIEWED } from 'shared/constants/recommendation';
import { getLocalizedString } from 'localization/localizer';
import { Carousel, ORIENTATION_HORIZONTAL } from 'client/components/screens/productVariantScreen/Carousel';
import _isEqual from 'lodash/isEqual';

if (process.browser) {
  require('./List.scss');
}

const prevArrow = <SvgLoader xlinkHref="#left-chevron-icon" />;
const nextArrow = <SvgLoader xlinkHref="#right-chevron-icon" />;

const MOBILE_BREAK_POINT = 540;

const LEFT_CONTAINER_PADDING = 8;
const COLUMN_GAP = 17;
const REC_TILE_WIDTH = 183;
const REC_TILE_HALF_WIDTH = REC_TILE_WIDTH / 2;

const InitialMobileScrollPx = LEFT_CONTAINER_PADDING + REC_TILE_HALF_WIDTH;
const MobileScrollPx = REC_TILE_WIDTH + COLUMN_GAP;

class List extends React.Component {
  constructor (props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carouselRef = React.createRef();
    this.isMobileScroll = typeof window !== 'undefined' ? props.isMobile && window.innerWidth <= MOBILE_BREAK_POINT : false;
  }

  shouldComponentUpdate (nextProps) {
    return !_isEqual(this.props.items, nextProps.items) || this.props.isPriceWithVat !== nextProps.isPriceWithVat;
  }

  static propTypes = {
    items: PropTypes.array,
    title: PropTypes.string
  };

  componentDidMount () {
    if (this.isMobileScroll) {
      this.next(InitialMobileScrollPx);
    }
  }

  next (scrollPX) {
    if (typeof (scrollPX) === 'number') {
      this.scroll(scrollPX);
    } else {
      const { scrollWidth, scrollLeft, offsetWidth } = this.carouselRef.current;
      const widthAfterScroll = scrollWidth - scrollLeft - offsetWidth;
      const offSet = this.props.isVerticalCarouselFeatureEnabled ? 0 : COLUMN_GAP;
      const scrollAmount = widthAfterScroll <= offsetWidth ? widthAfterScroll - offSet : offsetWidth + offSet;
      this.scroll(this.isMobileScroll ? MobileScrollPx : scrollAmount);
    }
  }
  previous () {
    const offSet = this.props.isVerticalCarouselFeatureEnabled ? 0 : COLUMN_GAP;
    const scrollAmount = -this.carouselRef.current.offsetWidth - offSet;
    this.scroll(this.isMobileScroll ? -MobileScrollPx : scrollAmount);
  }

  getFilteredItems (items, isMobile) {
    return items.filter(item => {
      const {
        familyId,
        stock = {}
      } = item;

      if (familyId) {
        return !!familyId;
      }
      const { stockMessage } = stock;
      const isRecommendation = true;
      const stockMessages = getMessages(stockMessage, isMobile, isRecommendation);
      const longLeadTimeProduct = stockMessages.every(message => message.text === LONGER_LEAD_TIME_TEXT);
      return !longLeadTimeProduct;
    });
  }
  scroll (scrollOffset) {
    if (this.carouselRef) {
      this.carouselRef.current.scrollLeft += scrollOffset;
    }
  }

  render () {
    const {
      items,
      isMobile,
      title,
      addToCart,
      disableModal,
      bottomBorder,
      seeMoreUrl,
      panelIndex,
      isPriceWithVat,
      cmsFlag = false,
      query,
      recommendationLocation
    } = this.props;

    if (!items || !items.length) {
      return <Empty />;
    }

    const itemsToShow = title !== RECENTLY_VIEWED
      ? this.getFilteredItems(items, isMobile)
      : items;

    const NavArrow = () => (
      <div className="pull-right Unselectable">
        <div
          className="RecommendedFamilies_Header_PrevButton"
          onClick={ this.previous }>
          { prevArrow }
        </div>
        <div
          className="RecommendedFamilies_Header_NextButton"
          onClick={ this.next }>
          { nextArrow }
        </div>
      </div>
    );

    return (
      <div className={ `RecommendedFamilies ${bottomBorder ? ' BottomBorder' : ''}` }>
        <div className="RecommendedFamilies_Header Cart_recommandation">
          <span className="ListTitle">{ title }</span>
          {
            isMobile
              ? <NavArrow/>
              : itemsToShow.length > 6
                ? <NavArrow/>
                : <Empty />
          }
        </div>
        <div className="RecommendedFamilies_List">
          <Carousel ref={ this.carouselRef } orientation={ ORIENTATION_HORIZONTAL } className="recommendation-tiles-carousel" hideScrollBar>
            {
              itemsToShow.map((item, position) => {
                if (item.familyId) {
                  return (<FamilyItem
                    key={ item.familyId }
                    item={ item }
                    position={ position }
                    isPriceWithVat = { isPriceWithVat }
                  />);
                }
                return (<ProductItem
                  key={ item.sku }
                  position={ position }
                  item={ item }
                  addToCart={ addToCart }
                  disableModal={ disableModal }
                  panelIndex = { panelIndex }
                  isPriceWithVat = { isPriceWithVat }
                  cmsFlag = { cmsFlag }
                  query = { query }
                  recommendationLocation = { recommendationLocation }
                />);
              }) }

          </Carousel>

        </div>
        {
          seeMoreUrl
            ? <h2><a href={ seeMoreUrl }>{getLocalizedString('recommendations.list.seeMoreLinkText')}</a></h2>
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    isVerticalCarouselFeatureEnabled: state.getIn(['config', 'verticalCarouselFeature'], false)
  });

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (orderLinesToBeAdded) => dispatch(addToCart(orderLinesToBeAdded))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

export { List };
