import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  fork
} from 'redux-saga/effects';
import { fromJS } from 'immutable';
import _groupBy from 'lodash/groupBy';
import { search } from 'shared/endpoints/searchEndpoint';
import { getProductFamilyProducts } from 'shared/endpoints/productFamilyEndpoint';
import {
  LOAD_CMS_PAGE_DATA,
  loadCmsPageDataSuccess,
  loadCmsPageDataFailure,
  REQUEST_CMS_OFFERS,
  requestCmsOffersSuccess,
  REQUEST_SKUS_DATA,
  successSkusData,
  REQUEST_CMS_STOCK_INFO,
  receiveCmsStockInfo,
  receiveCmsAppliedDiscountInfo
} from 'client/actions/cmsActions';
import { goToUrl } from 'client/actions/routeActions';
import {
  fetchVertical
} from '../../shared/endpoints/cmsEndpoints';
import { logAction } from 'client/actions/logActions';
import { getStockInfo } from 'shared/endpoints/productVariantEndpoint';
import { redirectUrl } from '../actions/searchActions';

// CMS PAGE DATA
function * loadCmsPageData ({ cmsParameters }) {
  try {
    const { body } = yield call(fetchVertical, cmsParameters);
    if (body === null) {
      if (['URL', 'INFO'].includes(cmsParameters.type)) {
        yield put(goToUrl('/page-not-found'));
      }
    } else {
      if (body.redirectUrl) {
        yield put(redirectUrl({ redirectUrl: body.redirectUrl, statusCode: 301 }));
      }
      yield put(loadCmsPageDataSuccess(
        cmsParameters,
        fromJS(body)
      ));
    }
  } catch (e) {
    yield put(loadCmsPageDataFailure());
    yield put(logAction({ message: 'Failure while fetching the vertical', e }));
  }
}

function * watchLoadCmsPageData () {
  yield takeLatest(LOAD_CMS_PAGE_DATA, loadCmsPageData);
}

// CMS OFFERS
function * doRequestCmsOffers ({ query }) {
  try {
    const searchResults = yield call(search, { ...query });
    if (searchResults) {
      const families = fromJS(searchResults.families || []);
      const productSkus = families.toSeq()
        .map((f) => f.get('products').take(1))
        .flatten()
        .toArray();

      const productsForAllFamilies = yield call(getProductFamilyProducts, productSkus);
      const prodsGroupedByFamilyId = fromJS(_groupBy(productsForAllFamilies, (prod) => prod.family.familyId));
      const familiesWithProductInfo = families.map((fam) => {
        const prodsForFam = prodsGroupedByFamilyId.get(fam.get('id') + '');
        return fam.set('productsInfo', prodsForFam);
      });
      yield put(successSkusData(null, productsForAllFamilies));
      yield put(requestCmsOffersSuccess(query.query, familiesWithProductInfo));
    }
  } catch (e) {
    yield put(logAction({ message: 'Error fetching offers:', e }));
  }
}

function * watchRequestCmsOffers () {
  // Use takeEvery instead of takeLatest, because there may be
  // multiple offer panels on one page, all triggering call for offers.
  yield takeEvery(REQUEST_CMS_OFFERS, doRequestCmsOffers);
}

// CMS SKUS DATA
function * requestSkusData ({ skusString, skus }) {
  try {
    const productsForAllFamilies = yield call(getProductFamilyProducts, skus);
    yield put(successSkusData(skusString, productsForAllFamilies));
  } catch (e) {
    yield put(logAction({ message: 'Error fetching skus:', e }));
  }
}

function * watchRequestSkusData () {
  // Use takeEvery instead of takeLatest, because there may be
  // multiple sku carousels on one page, all triggering call for skus.
  yield takeEvery(REQUEST_SKUS_DATA, requestSkusData);
}

export function * fetchProductStockInfo ({ payload, type }) {
  try {
    const productStockInfo = yield call(getStockInfo, payload.sku, payload.quantity);

    const panelIndex = payload.cmsData.panelIndex || 0;
    const cmsQuery = payload.cmsData.query;

    yield put(receiveCmsStockInfo({ panelIndex, cmsQuery, stockInfo: productStockInfo, familyId: payload.familyId }));
    yield put(receiveCmsAppliedDiscountInfo({ panelIndex, cmsQuery, productStockInfo, familyId: payload.familyId }));
  } catch (e) {
    yield put(logAction({ message: 'Failure within fetchProductStockInfo', e }));
  }
}

function * watchCmsStocksInfo () {
  yield takeEvery(REQUEST_CMS_STOCK_INFO, fetchProductStockInfo);
}

function * watchCmsSagas () {
  yield all([
    fork(watchLoadCmsPageData),
    fork(watchRequestCmsOffers),
    fork(watchRequestSkusData),
    fork(watchCmsStocksInfo)
  ]);
}

export {
  loadCmsPageData,
  doRequestCmsOffers,
  requestSkusData,
  watchCmsSagas
};
