import { fromJS, List } from 'immutable';
import {
  LOAD_CMS_PAGE_DATA_SUCCESS,
  LOAD_CMS_PAGE_DATA_FAILURE,
  REQUEST_CMS_OFFERS_SUCCESS,
  SUCCESS_SKUS_DATA,
  RECEIVE_CMS_STOCK_INFO,
  UPDATE_CMS_APPLIED_DISCOUNT_INFO
} from 'client/actions/cmsActions';

import {
  PENDING,
  SUCCESS,
  ERROR
} from 'shared/constants/loadStateType';

const defaultState = fromJS({
  pages: {
    loadState: PENDING
  },
  offers: {},
  skusData: {}
});

const cms = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_CMS_PAGE_DATA_SUCCESS:
      return state
        .setIn(['pages', action.cmsParameters.type], fromJS(action.pageData))
        .setIn(['pages', 'loadState'], SUCCESS);

    case LOAD_CMS_PAGE_DATA_FAILURE:
      return state.setIn(['pages', 'loadState'], ERROR);

    case REQUEST_CMS_OFFERS_SUCCESS:
      return state.setIn(['offers', action.query], fromJS(action.results));

    case SUCCESS_SKUS_DATA:
      return state.setIn(['skusData', action.skusString], fromJS(action.results));
    case RECEIVE_CMS_STOCK_INFO:
      const { cmsQuery, familyId, stockInfo } = action.payload;
      const offers = state.getIn(['offers', cmsQuery], List());
      const itemIndex = offers.toJS()
        .findIndex((item) => item.familyObject.familyId === familyId);
      if (itemIndex < 0) {
        return state;
      }
      if (offers.size) {
        return state.setIn(
          ['offers', cmsQuery, itemIndex, 'productsInfo', 0, 'stock'],
          fromJS(stockInfo)
        );
      }
      return state;
    case UPDATE_CMS_APPLIED_DISCOUNT_INFO:
      const query = action.payload.cmsQuery;
      const dataInfo = action.payload.productStockInfo;

      const offersCms = state.getIn(['offers', query], List());
      const offerIndex = offersCms.toJS()
        .findIndex((item) => item.familyObject.familyId === action.payload.familyId);
      if (offerIndex < 0) {
        return state;
      }
      const { packPrice, packPriceWithVat } = dataInfo.price;
      if (offersCms.size) {
        return state.setIn(
          ['offers', query, offerIndex, 'productsInfo', 0, 'price'],
          fromJS(dataInfo.price)
        ).setIn(['offers', query, offerIndex, 'packPriceWithoutVat'],
          fromJS(packPrice))
          .setIn(['offers', query, offerIndex, 'packPriceWithVat'],
            fromJS(packPriceWithVat))
          .setIn(['offers', query, offerIndex, 'minPrice'],
            fromJS(packPriceWithVat));
      }
      return state;
    default:
      return state;
  }
};

export default cms;
