import { ApiRequest } from 'shared/utils/apiUtils';

export const updateUnleashUserId = (unlFeature, unlVariant) => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/override-unleash-userid',
    body: {
      unlFeature,
      unlVariant
    }
  });

  return req.executeAndGetBody();
};
