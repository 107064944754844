import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getLocalizedString } from 'localization/localizer';
import { PasswordInput } from '@graingerglobalonline/curly-eureka';

if (process.browser) {
  require('./FieldInputStyles.scss');
}

const FieldWithValidationsNewStyles = (props) => {
  const {
    input,
    type,
    placeholderResourceName,
    className,
    label,
    labelResourceName,
    labelClassName,
    datae2e,
    tooltip,
    subTitle,
    fieldSubtitle,
    id,
    autoComplete,
    autoFocus,
    formClassName,
    disabled,
    min,
    optional,
    meta: { touched, error },
    maxLength,
    errorLink = null,
    onEnterSubmit
  } = props;

  const errorTag = useMemo(() => `${input.name ?? ''}Error`, [input]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && onEnterSubmit) {
      event.preventDefault();
      onEnterSubmit();
    }
  };

  const inputProps = {
    ...input,
    id,
    disabled,
    min,
    type,
    className,
    placeholder: placeholderResourceName ? getLocalizedString(placeholderResourceName) : null,
    autoComplete: autoComplete ? autoComplete : 'on',
    autoFocus,
    maxLength,
    'data-e2e': datae2e,
    onKeyDown: handleKeyDown
  };

  return (
    <div className={ `form-group FieldInputStyles ${fieldSubtitle ? 'FieldInputStyles_withFieldSubTitle' : ''} ${formClassName ? formClassName : ''} ${(touched && error) ? 'has-error' : ''}` }>
      { id ? <label htmlFor={ id } className={ labelClassName }>{ labelResourceName ? getLocalizedString(labelResourceName) : label }</label> : null }
      { subTitle ? <div className="subTitle">{subTitle}</div> : null }
      {optional ? <span className="optional">Optional</span> : null }
      { tooltip ? tooltip : null }

      {type === 'password' ? <PasswordInput { ...inputProps }/> : <input { ...inputProps }/>}
      { fieldSubtitle ? <div className="fieldSubtitle">{fieldSubtitle}</div> : null }
      {
        touched && error
          ? <div className="help-block">
            <div data-e2e={ errorTag } className={ errorTag }>
              { error }
              { errorLink?.url
                ? <a className="error-link" href={ errorLink.url } target='_blank' rel="noreferrer">
                  { errorLink.label }
                </a>
                : null
              }
            </div>
          </div> : null
      }
    </div>
  );
};

FieldWithValidationsNewStyles.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholderResourceName: PropTypes.string,
  datae2e: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelResourceName: PropTypes.string,
  tooltip: PropTypes.object,
  formClassName: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  onEnterSubmit: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default FieldWithValidationsNewStyles;
