import React from 'react';
if (process.browser) {
  require('./carousel.scss');
}

const CAROUSEL_HORIZONTAL = 'carousel-horizontal';
const CAROUSEL_VERTICAL = 'carousel-vertical';
export const ORIENTATION_HORIZONTAL = 'horizontal';
export const ORIENTATION_VERTICAL = 'vertical';

const ARRAY_MULTIPLIER = 5;
const randomNumber = () => Math.floor(100 + Math.random() * 100000);

const repeatJSXArray = (elements, multiplier) =>
  Array.from({ length: multiplier }, () =>
    elements.map((e) => {
      return { ...e, key: `${e.key}_${randomNumber()}` };
    })
  ).flat();

export const Carousel = React.forwardRef(
  ({ orientation, className, children, repeatElements, hideScrollBar }, ref) => {
    return (
      <div
        ref={ ref }
        className={ `${className} carousel ${
          orientation === ORIENTATION_HORIZONTAL
            ? CAROUSEL_HORIZONTAL
            : CAROUSEL_VERTICAL
        } ${hideScrollBar ? 'hide-scrollbar' : ''}` }
      >
        {repeatElements ? repeatJSXArray(children, ARRAY_MULTIPLIER) : children}
      </div>
    );
  }
);
