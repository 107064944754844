import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { getLocalizedString } from 'localization/localizer';
import { hideRemoveProductBundleDialog } from 'client/actions/ui/dialogActions';
import { requestRemoveBundleOrderLineConfirm } from 'client/actions/cartActions';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms/formButtons';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./RemoveProductBundleDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeProductDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeProductBundle', 'isVisible']),
    bundleLine: state.getIn(['ui', 'dialogs', 'removeProductBundle', 'bundleLine'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveProductBundleDialog: flowRight(dispatch, hideRemoveProductBundleDialog),
    requestRemoveBundleOrderLineConfirm: flowRight(dispatch, requestRemoveBundleOrderLineConfirm)
  };
}

const RemoveProductBundleDialog = ({ removeProductDialogIsVisible,
  requestRemoveBundleOrderLineConfirm,
  hideRemoveProductBundleDialog, bundleLine, location, updateDataLayer }) => {
  useEffect(() => {
    if (location.pathname.startsWith('/checkout') && removeProductDialogIsVisible) {
      updateDataLayer({
        event: 'checkout_modal',
        type: 'remove_product_bundle'
      });
    }
  }, [removeProductDialogIsVisible]);
  if (!bundleLine) {
    return null;
  }
  const removeOrderLine = () => {
    requestRemoveBundleOrderLineConfirm(bundleLine);
    hideRemoveProductBundleDialog();
  };
  const imageUrl = bundleLine.get('imageSrcLowResolution', '');
  const name = bundleLine.get('name');
  const sku = bundleLine.get('sku');
  return (
    <Modal className="RemoveProductBundleDialog" show={ removeProductDialogIsVisible }
      onHide={ hideRemoveProductBundleDialog }>
      <Modal.Header className="RemoveProductBundleDialog_header" closeButton>
        <h4 className="RemoveProductBundleDialog_title">
          { getLocalizedString('cart.dialog.header.message') }
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="RemoveProductBundleDialog_modalBody_content">
          <ZoroImage src={ imageUrl } className="RemoveProductBundleDialog_modalBody_img" />

          <div>
            <div className="RemoveProductBundleDialog_modalBody_title" >{ name }</div>
            <div>
              { getLocalizedString('cart.dialog.label.sku') } { sku }
            </div>
          </div>
        </div>

        <div className="row RemoveProductBundleDialog_modalBody_options">

          <PrimaryButton onClick={ removeOrderLine }
            text={ getLocalizedString('cart.dialog.remove.product.button') }
          />

          <SecondaryButton onClick={ hideRemoveProductBundleDialog }
            text={ getLocalizedString('cart.dialog.cancel.button') } />

        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveProductBundleDialog.propTypes = {
  removeProductDialogIsVisible: PropTypes.bool,
  hideRemoveProductBundleDialog: PropTypes.func,
  requestRemoveBundleOrderLineConfirm: PropTypes.func,
  bundleLine: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveProductBundleDialog);
