import { createAction } from 'redux-actions';

export const PAY_BY_ACCOUNT = 'payByAccount.PAY';
export const payByAccount = createAction(PAY_BY_ACCOUNT);

export const SUBSCRIBE_BY_ACCOUNT = 'payByAccount.SUBSCRIBE';
export const subscribeByAccount = createAction(SUBSCRIBE_BY_ACCOUNT);

export const INITIALIZE_PAY_ON_ACCOUNT = 'payByAccount.INITIALIZE';
export const initializePayOnAccount = createAction(INITIALIZE_PAY_ON_ACCOUNT);
