import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';

const RemoveFromBasketConfirmation = ({ toggleRemoveProduct, removeOrderLineFromBasketDropdown, sku }) => {
  return <>
    <div className='basketConfirmationDropdown_productLine-removeConfirmationBackdrop' />
    <div className='basketConfirmationDropdown_productLine-removeConfirmation'>
      <div className='basketConfirmationDropdown_productLine-removeConfirmationBody'>
        <span className='basketConfirmationDropdown_productLine-removeConfirmationText'>{ getLocalizedString('cart.basketConfirmation.removeProductConfirmation') }</span>
        <div className='basketConfirmationDropdown_productLine-removeConfirmationButtons'>
          <PrimaryButton className ='basketConfirmationDropdown_productLine-removeConfirmationButtonCancel' data-e2e="No-keep-item" onClick={ () => toggleRemoveProduct(sku) }>{ getLocalizedString('cart.basketConfirmation.removeProductConfirmationCancel') }</PrimaryButton>
          <SecondaryButton className ='basketConfirmationDropdown_productLine-removeConfirmationButtonRemove' data-e2e="Yes-remove-item" onClick={ () => removeOrderLineFromBasketDropdown({ sku }) }>{ getLocalizedString('cart.basketConfirmation.removeProductConfirmationConfirm') } </SecondaryButton>
        </div>
      </div>
    </div>
  </>;
};

export default RemoveFromBasketConfirmation;
