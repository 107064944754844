import React from 'react';
import { Body } from '@graingerglobalonline/curly-eureka';

export const RecommendationPrice = ({ symbol,
  amount,
  priceVatTagLabel,
  showHighlightedPrice,
  isInPromo
}) => {
  return (
    <div className="RecommendationTile_ProductAmount">
      <span className= { showHighlightedPrice && isInPromo ? 'ProductAmount_Highlighted' : null }>
        <strong>{`${symbol}${amount} `}</strong>
      </span>
      <Body variant="xSmall" >{priceVatTagLabel}</Body>
    </div>);
};
