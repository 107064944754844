import { takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { logAction } from 'client/actions/logActions';

import {
  REQUEST_SERVICE_REVIEWS,
  REQUEST_SERVICE_AVG_RATING,
  receiveLoadServiceReviewsSuccess,
  receiveLoadServiceRatingSuccess,
  receiveLoadServiceRatingError
} from 'client/actions/feefoReviewsActions';

import {
  getServiceReviews,
  getServiceAverageRating
} from 'shared/endpoints/feefoReviewEndpoint';

export function * fetchAvgServiceRating () {
  try {
    const { body } = yield call(getServiceAverageRating);
    yield put(receiveLoadServiceRatingSuccess(fromJS(body)));
  } catch (e) {
    yield put(logAction({ message: 'Failure within fetchAvgServiceRating' }));
    yield put(receiveLoadServiceRatingError(e));
  }
}

export function * watchRequestAverageServiceRating () {
  yield takeLatest(REQUEST_SERVICE_AVG_RATING, fetchAvgServiceRating);
}

export function * fetchServiceReviews (payload) {
  const {
    reviewDisplayed,
    reviewSkip
  } = payload;
  const { serviceReviews, totalReviews } = yield call(getServiceReviews, { reviewDisplayed, reviewSkip });
  yield put(receiveLoadServiceReviewsSuccess(serviceReviews, totalReviews));
}

export function * watchRequestServiceReviews () {
  yield takeLatest(REQUEST_SERVICE_REVIEWS, fetchServiceReviews);
}
