import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { reduxForm, Field } from 'redux-form/immutable';
import { makeValidator, isRequired, isEmail } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import SvgLoader from 'client/components/svg/SvgLoader';
import { PrimaryLink, PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { NEW_MODAL } from 'shared/constants/loginModalType';
import { hideLoginDialog } from 'client/actions/ui/dialogActions';
import {
  onForgotPassword,
  resetStateToDefaults
} from 'client/actions/userActions';
import { toggleLoginPage } from 'client/actions/ui/dialogActions';
import { setView } from 'client/actions/loginAndRegistration';
import {
  LOGIN_VIEW_IMAGE,
  LOGIN_VIEW
} from '../constants';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

const validate = makeValidator({
  email: [isRequired('Email'), isEmail()]
});

if (process.browser) {
  require('./ForgotPasswordForm.scss');
}

const FORGOT_PASSWORD_FORM_NAME = 'newForgotPassword';
const ForgotPasswordForm = (props) => {
  useEffect(() => {
    props.dispatchResetStateToDefaults();
  }, []);

  const {
    handleSubmit,
    onForgotPassword,
    forgotPasswordError,
    dispatchSetView,
    isEmailSent,
    hideLoginDialog
  } = props;

  const submit = (values) => validateForm(values, validate).then(() => onForgotPassword(values.get('email'), NEW_MODAL));

  const getInitialTemplate = () => (<div className="col-xs-10 col-xs-offset-1 ForgotPasswordNewRegistration">
    <h2 className="ForgotPasswordNewRegistration_resetPassword">
      { getLocalizedString('login.resetPassword.text') }
    </h2>
    <p>
      { getLocalizedString('login.resetPassword.description') }
    </p>
    <form onSubmit={ handleSubmit(submit) }>
      <div>
        <div>
          <Field
            name="email"
            type="email"
            component={ FieldWithValidations }
            className="form-control"
            labelClassName="control-label required"
            datae2e="forgot-password-email" />
        </div>
        {
          !forgotPasswordError
            ? null
            : <div className="row">
              { forgotPasswordError }
            </div>
        }
      </div>
      <div className="col-xs-12 ForgotPasswordNewRegistration_btnsContainer">
        <div className="ForgotPasswordNewRegistration_btnsContainer-back">
          <SecondaryButton
            className="ForgotPasswordNewRegistration_backToLogin"
            datae2e="back"
            onClick={ () => dispatchSetView(LOGIN_VIEW) }
          >
              Back
          </SecondaryButton>
        </div>
        <div className="ForgotPasswordNewRegistration_submitBtn ForgotPasswordNewRegistration_noRightPadding">
          <PrimaryButton
            type="submit"
            datae2e="forgot-password-submit">
            { getLocalizedString('forgotPassword.submit.label') }
          </PrimaryButton>
        </div>
      </div>
    </form>
  </div>);

  const getTemplateAfterSubmit = () => (<div className="col-xs-10 col-xs-offset-1 ForgotPasswordNewRegistrationSuccess">
    <h1 className="ForgotPasswordNewRegistrationSuccess_thankYou">Thank you</h1>
    <p className="ForgotPasswordNewRegistrationSuccess_message">
      { getLocalizedString('forgotPassword.thankYou.text') }
    </p>
    <div className="col-xs-12">
      <div className="col-xs-12 col-md-6">
        <PrimaryLink
          type="submit"
          datae2e="back-to-login"
          className="ForgotPasswordNewRegistrationSuccess_backToLogin"
          onClick={ () => dispatchSetView(LOGIN_VIEW) }
        >
            Back To Login
        </PrimaryLink>
      </div>
      <div className="col-xs-12 col-md-6">
        <PrimaryButton
          id="ForgotPassword.continueShopping"
          datae2e="continue-shopping"
          className="ForgotPasswordNewRegistrationSuccess_continueShopping"
          onClick={ () => hideLoginDialog() }
        >
            Continue shopping
        </PrimaryButton>
      </div>
    </div>
  </div>);

  return <div className="col-xs-12 NewLoginForm_container">
    <div className="hidden-xs hidden-sm col-xs-5 NewLoginForm_leftImageContainer">
      <ZoroImage
        className="NewLoginForm_leftImage"
        src={ LOGIN_VIEW_IMAGE } />
    </div>
    <form onSubmit={ handleSubmit(submit) } className="col-xs-12 col-md-7 NewLoginForm">
      <div className="NewLoginForm_logo">
        <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
      </div>
      { !isEmailSent ? getInitialTemplate() : getTemplateAfterSubmit() }
    </form>
  </div>;
};

const ReduxFormForgotPassword = reduxForm({
  form: FORGOT_PASSWORD_FORM_NAME,
  validate
})(ForgotPasswordForm);

export const mapStateToProps = (state) => {
  return {
    forgotPasswordError: state.getIn(['ui', 'forgotPasswordForm', 'error']),
    isEmailSent: state.getIn(['ui', 'forgotPasswordForm', 'emailSent'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideLoginDialog: () => dispatch(hideLoginDialog()),
  onForgotPassword: (email, loginType) => dispatch(onForgotPassword(email, loginType)),
  dispatchToggleLoginPage: () => dispatch(toggleLoginPage()),
  dispatchResetStateToDefaults: () => dispatch(resetStateToDefaults()),
  dispatchSetView: (view) => dispatch(setView({ view }))
});

export { ForgotPasswordForm };
export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormForgotPassword);
