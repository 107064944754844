import { getLocalizedString } from 'localization/localizer';
import {
  DELIVERY_ADDRESS_AND_PAYMENT_REQUIRED,
  BILLING_ADDRESS_AND_PAYMENT_REQUIRED,
  DELIVERY_ADDRESS_BILLING_ADDRESS_AND_PAYMENT_REQUIRED } from 'shared/constants/singlePageCheckout';

const mappedStatus = {
  DELIVERY_ADDRESS_AND_BILLING_ADDRESS_REQUIRED: getLocalizedString('singlePageCheckout.delivery.and.billing.address.required'),
  BILLING_ADDRESS_REQUIRED: getLocalizedString('singlePageCheckout.billingAddressRequired'),
  DELIVERY_ADDRESS_REQUIRED: getLocalizedString('singlePageCheckout.deliveryAddressRequired'),
  THREE_D_SECURE_FAILED: getLocalizedString('singlePageCheckout.place.order.error'),
  POST_ORDER_FAILED: getLocalizedString('singlePageCheckout.place.order.internal.server.error'),
  DELIVERY_ADDRESS_BILLING_ADDRESS_AND_PAYMENT_REQUIRED: getLocalizedString('singlePageCheckout.address.payment.required'),
  BILLING_ADDRESS_AND_PAYMENT_REQUIRED: getLocalizedString('singlePageCheckout.billing.address.payment.required'),
  DELIVERY_ADDRESS_AND_PAYMENT_REQUIRED: getLocalizedString('singlePageCheckout.delivery.address.payment.required'),
  DELIVERY_ADDRESS_FORM_NOT_SAVED: getLocalizedString('singlePageCheckout.error.delivery.not.saved'),
  BILLING_ADDRESS_FORM_NOT_SAVED: getLocalizedString('singlePageCheckout.error.billing.not.saved'),
  CONFIRM_BILLING_ADDRESS: getLocalizedString('singlePageCheckout.error.billing.confirm'),
  SAVED_CARD_CVV_VERIFICATION_FAILED: getLocalizedString('singlePageCheckout.place.order.error'),
  SAVED_PAYPAL_ACCOUNT_SHIPPING_DETAILS_CHANGED: getLocalizedString('singlePageCheckout.add.paypal.details'),
  SELECT_PAYMENT_OPTION: getLocalizedString('singlePageCheckout.select.payment.method.message')
};
const mappedStatusWithPaymentInvalid = {
  BILLING_ADDRESS_REQUIRED: BILLING_ADDRESS_AND_PAYMENT_REQUIRED,
  DELIVERY_ADDRESS_REQUIRED: DELIVERY_ADDRESS_AND_PAYMENT_REQUIRED,
  DELIVERY_ADDRESS_AND_BILLING_ADDRESS_REQUIRED: DELIVERY_ADDRESS_BILLING_ADDRESS_AND_PAYMENT_REQUIRED
};
const getPaymentInvalidErrorMessage = (message = '', status) => {
  return mappedStatus[status] || message;
};
export const getErrorMessage = (message, status, isPaymentInvalid) => {
  if (isPaymentInvalid) {
    const _status = mappedStatusWithPaymentInvalid[status] || status;
    return getPaymentInvalidErrorMessage(message, _status);
  }
  return mappedStatus[status];
};

export const removeEmptyStringFromAddress = (text) => {
  const regexp = /, ,/gi;
  const formattedText = text.replace(regexp, ',').trim();
  return formattedText.indexOf(',') === 0 ? formattedText.slice(1).trim() : formattedText;
};
