import { fromJS } from 'immutable';
import {
  ON_API_BUTTON_LOAD,
  ON_API_BUTTON_CLICK,
  ON_API_BUTTON_SUCCESS,
  ON_API_BUTTON_ERROR,
  ON_API_BUTTON_UNLOAD
} from 'client/actions/ui/apiCallButtonActions';
import { SET_API_BUTTON_STATUS } from '../../actions/ui/apiCallButtonActions';

const defaultState = fromJS({ });

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_API_BUTTON_STATUS:
      return state
        .set(action.payload.selector, action.payload.status);
    case ON_API_BUTTON_LOAD:
      return state
        .set(action.payload.selector, 'readyToSubmit');
    case ON_API_BUTTON_CLICK:
      return state
        .set(action.payload.selector, 'submitting');
    case ON_API_BUTTON_SUCCESS:
      return state
        .set(action.payload.selector, 'submitted');
    case ON_API_BUTTON_ERROR:
      return state
        .set(action.payload.selector, 'error');
    case ON_API_BUTTON_UNLOAD:
      return state
        .remove(action.payload.selector);
    default:
      return state;
  }
};
