export const RUNNING = 'RUNNING';
export const ACTIVE = 'ACTIVE';
export const PAUSED = 'PAUSED';
export const STOP = 'STOP';
export const EXPIRED = 'EXPIRED';
export const SEND_PARTIAL_ORDER = 'SEND_PARTIAL_ORDER';
export const STOP_DELIVERY = 'STOP_DELIVERY';

export const WEEKLY = 'Weekly';
export const FORTNIGHTLY = 'Fortnightly';
export const MONTHLY = 'Monthly';

export const ADD_TO_SUBSCRIPTION_FORM_NAME = 'ADD_TO_SUBSCRIPTION';
export const NEW_SUBSCRIPTION_FORM_NAME = 'NEW_SUBSCRIPTION';

export const CREATE_NEW_SUBSCRIPTION_STATUS_SUBMITTED = 'submitted';
export const CREATE_NEW_SUBSCRIPTION_STATUS_FAILED = 'failed';
export const CREATE_NEW_SUBSCRIPTION_STATUS_BANNERONLY = 'bannerOnly';
export const CREATE_NEW_SUBSCRIPTION_STATUS_PROCESSING = 'processing';

export const SHOW_ADD_TO_SUBSCRIPTION = 'showAddToSubscription';
export const SHOW_NEW_SUBSCRIPTION = 'showNewSubscription';

export const ACCOUNT = 'Account';
export const CARD = 'Card';
export const TRADE_ACCOUNT_DECLINED = 'DECLINED';

export const SAVED_CARDS_ROUTE = '/my-account/saved-cards';

export const SUBSCRIPTION_SELECTOR = 'SUBSCRIPTION_SELECTOR';
export const EXISTING_SUBSCRIPTION_SELECTOR = 'EXISTING_SUBSCRIPTION_SELECTOR';

export const PROCESSING = 'processing';
export const COMPLETED = 'completed';
