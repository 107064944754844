import { take, call, race, fork, put, takeLatest, select, all } from 'redux-saga/effects';
import { RECOMMENDATIONS_LOCATIONS } from '../../shared/constants/recommendation';
import {
  REQUEST_ADD_TO_CART,
  REQUEST_LOAD_CART,
  RECIEVE_LOAD_CART_SUCCESS,
  RECIEVE_LOAD_CART_ERROR,
  REQUEST_LOAD_CART_QUANTITY,
  REQUEST_REMOVE_ORDER_LINE,
  REQUEST_REMOVE_ORDER_LINE_CONFIRM,
  REQUEST_REMOVE_BUNDLE_ORDER_LINE,
  REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM,
  RECIEVE_REMOVE_ORDER_LINE_ERROR,
  RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR,
  REQUEST_SET_ORDER_LINE_QUANTITY,
  REQUEST_SET_BUNDLE_QUANTITY,
  REMOVE_ORDER_LINE_FROM_BASKET_DROPDOWN,
  loadCart,
  recieveLoadCartSuccess,
  recieveLoadCartError,
  receiveLoadCartQuantitySuccess,
  receiveLoadCartQuantityError,
  recieveAddToCartSuccess,
  recieveAddToCartError,
  qtyAddedToCart
} from 'client/actions/cartActions';
import {
  getCart,
  getCartQuantity,
  addProductToCart,
  setOrderLineQuantity,
  removeProductFromCart,
  setBundleQuantity,
  removeBundleProductFromCart
} from 'shared/endpoints/cartEndpoint';
import {
  showRemoveProductDialog,
  SET_REMOVE_PRODUCT_DIALOG_VISIBILITY,
  showDiscountCodeDialog,
  showRemoveProductBundleDialog,
  SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY,
  hideNonDeliverableOrderMessage
} from 'client/actions/ui/dialogActions';
import { sendBasketChangeToDataLayer, delay, sendBundleBasketChangeToDataLayer } from 'shared/utils/sagaUtils';
import {
  postOrderFailed
} from '../actions/orderReviewActions';
import { pushGeneralData } from 'client/utils/googleTagManagerUtils';
import { getRecommendationsForFamily } from 'shared/endpoints/recommendationEndpoint';
import { familyRecomendationsSuccess, clearRecommendations } from 'client/actions/recommendationsActions';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import _inRange from 'lodash/inRange';
import _flatten from 'lodash/flatten';
import { addProductLineItems, updatingQuantity } from 'client/actions/productLineActions';
import { addProductBundleLineItems, updatingBundleQuantity } from 'client/actions/productBundleLineActions';
import { toProductLineItems } from 'client/utils/productLineUtils';
import { newBreakPriceObject, isEqualObject } from 'client/utils/priceUtils';
import { toastSuccess, toastWarn, toastInfo } from 'client/actions/showNotificationsActions';
import { applyingDiscountCode } from 'client/actions/discountCodeActions';
import { List, fromJS } from 'immutable';
import { calculateZoroPromoRounding } from '../utils/discountUtils';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';
import { goToUrl } from 'client/actions/routeActions';
import { toggleBasketConfirmationDropdown, toggleBasketConfirmationLoader } from 'client/actions/ui/toggleElementActions';
import { SINGLE_PAGE_CHECKOUT } from 'shared/constants/singlePageCheckout';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { scrollToTop } from './scrollSagas';
import { reloadScheduledOrders } from 'client/actions/scheduledOrders';
import { RECOMMENDATIONS_SERVED_EVENT } from '../components/elements/recommendations/constants';
const TOASTER_DELAY = 5000;

const prepareToastMessage = (breakPrice, type) => {
  const discount = breakPrice.breakDiscount;
  const discountToRounded = calculateZoroPromoRounding(discount);
  return (type === 'error'
    ? getToastMessage(
      getLocalizedStringWithParam('cart.toast.message.discount.loss', { discountToRounded })
    )
    : getToastMessage(
      getLocalizedStringWithParam('cart.toast.message.discount.got', { discountToRounded })
    )
  );
};

const getSelectedBreakPriceObject = (qty, breakPrices) => {
  return _find(breakPrices, (breakPrice) => {
    return _inRange(qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
  });
};

export function * onRequestAddToCart ({ type, payload }) {
  const { isRecommendations, orderLinesToBeAdded, redirectUrl, basketDropDown } = payload;
  const body = {
    products: orderLinesToBeAdded
  };
  if (basketDropDown) { // A/B testing basket confirmation dropdown
    yield put(toggleBasketConfirmationDropdown(true));
    yield put(toggleBasketConfirmationLoader(true));
    scrollToTop();
  } else {
    const [{
      sku
    }] = orderLinesToBeAdded;
    const redirectPath = redirectUrl ? redirectUrl : `/added-to-basket/${sku}`;
    yield put(goToUrl(redirectPath)); // this to be ab tested
  }

  try {
    yield call(addProductToCart, body);
    yield put(recieveAddToCartSuccess());
    yield put(toggleBasketConfirmationLoader(false));
  } catch (err) {
    yield put(recieveAddToCartError(err));
  }
  const location = _get(payload, 'location', RECOMMENDATIONS_LOCATIONS.cart_screen);

  yield put(loadCart({ showMultimessageToastInfo: false, location, basketDropDown }));
  if (orderLinesToBeAdded.length) {
    const response = yield race({
      success: take(RECIEVE_LOAD_CART_SUCCESS),
      error: take(RECIEVE_LOAD_CART_ERROR),
      timeout: call(delay)
    });
    const { success } = response;
    if (success && success.payload) {
      const [{
        sku,
        qty,
        recommendationType,
        recommendationPosition,
        recommendationLocation
      }] = orderLinesToBeAdded;
      const { orderLines = [], currency } = success.payload;
      const addedItem = orderLines.find((orderLine) => orderLine.sku === sku);
      if (addedItem) {
        const eventName = isRecommendations ? 'RecommendationAddToCart' : 'addToCart';
        yield * sendBasketChangeToDataLayer(
          addedItem, currency, qty, eventName, recommendationType, recommendationPosition, recommendationLocation
        );
      } else {
        yield call(pushGeneralData, {
          'event': 'onAddToCartRaceCondition'
        });
      }
    }
  }
}

export function * watchRequestAddToCart () {
  yield takeLatest(REQUEST_ADD_TO_CART, onRequestAddToCart);
}

export function * onRequestRemoveOrderLine ({ payload, eligibleForDeliveryOptions, isBasketDropDown }) {
  if (!payload) {
    return;
  }
  yield put(showRemoveProductDialog(payload.toJS()));
  const { confirm } = yield race({
    confirm: take(REQUEST_REMOVE_ORDER_LINE_CONFIRM),
    close: take(SET_REMOVE_PRODUCT_DIALOG_VISIBILITY)
  });
  if (confirm) {
    if (isBasketDropDown) { // A/B testing basket confirmation dropdown
      yield put(toggleBasketConfirmationLoader(true));
    }
    yield call(removeProductFromCart, payload.get('sku'));
    const addressIdSelect = (state) => state.getIn(['user', 'address', 'delivery', 'addressId'], null);
    const addressId = yield select(addressIdSelect);
    const cartPayload = { eligibleForDeliveryOptions, showMultimessageToastInfo: false, basketDropDown: isBasketDropDown, addressId };
    yield put(loadCart(cartPayload));
  }
}

export function * removeProductFromBasketDropdown ({ payload }) {
  try {
    yield call(removeProductFromCart, payload.sku);
    yield put(loadCart());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
}

export function * onRequestRemoveOrderLineConfirm ({ payload }) {
  if (!payload) {
    return;
  }
  const { success } = yield race({
    success: take(RECIEVE_LOAD_CART_SUCCESS),
    error: take(RECIEVE_REMOVE_ORDER_LINE_ERROR),
    timeout: call(delay)
  });
  if (success && success.payload) {
    const { payload: { currency } } = success;
    yield * sendBasketChangeToDataLayer(payload.toJS(), currency,
      payload.get('quantity'), 'removeFromCart');
  }
}

export function * onRequestRemoveBundleOrderLine ({ payload }) {
  if (!payload) {
    return;
  }
  const { sku, name, imageSrcLowResolution,
    skuOfPrimaryProduct,
    orderLinePrice,
    category } = payload.toJS();
  yield put(showRemoveProductBundleDialog({ sku,
    name,
    imageSrcLowResolution,
    skuOfPrimaryProduct,
    orderLinePrice,
    category }));
  const { confirm } = yield race({
    confirm: take(REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM),
    close: take(SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY)
  });
  if (confirm) {
    const bundleFamilyId = payload.get('bundleFamilyId');
    const quantity = payload.get('quantity');
    const products = payload.get('products').toJS()
      .map(product => {
        return product.isPrimary
          ? { isPrimary: product.isPrimary, sku: product.sku }
          : { sku: product.sku };
      });

    const body = {
      bundle: {
        bundleFamilyId,
        bundleQuantity: quantity,
        products
      }
    };

    yield call(removeBundleProductFromCart, body);
    yield put(loadCart());
  }
}

export function * onRequestRemoveBundleOrderLineConfirm ({ payload }) {
  if (!payload) {
    return;
  }
  const { success } = yield race({
    success: take(RECIEVE_LOAD_CART_SUCCESS),
    error: take(RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR),
    timeout: call(delay)
  });
  if (success && success.payload) {
    const { payload: { currency } } = success;
    yield * sendBundleBasketChangeToDataLayer({ orderLine: payload.toJS(),
      currency,
      quantity: payload.get('quantity'),
      event: 'remove-bundle-from-cart' });
  }
}

export function * watchRequestRemoveOrderLine () {
  yield takeLatest(REQUEST_REMOVE_ORDER_LINE, onRequestRemoveOrderLine);
}

export function * watchRequestRemoveOrderLineConfirm () {
  yield takeLatest(REQUEST_REMOVE_ORDER_LINE_CONFIRM, onRequestRemoveOrderLineConfirm);
}

export function * watchRequestRemoveBundleOrderLine () {
  yield takeLatest(REQUEST_REMOVE_BUNDLE_ORDER_LINE, onRequestRemoveBundleOrderLine);
}

export function * watchRequestRemoveBundleOrderLineConfirm () {
  yield takeLatest(REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM, onRequestRemoveBundleOrderLineConfirm);
}

function * updateQtyAddedToCart (orderLines, id, quantity) {
  const currentOrderLine = orderLines.find(orderLine => orderLine.sku === id);
  const isDeliverable = currentOrderLine.stock.stockMessage.isDeliverable;
  if (isDeliverable) {
    yield put(qtyAddedToCart(quantity));
  }
}

export function * onRequestSetOrderLineQuantity ({ payload: { orderLine, quantity, product, eligibleForDeliveryOptions, addressId } }) {
  const id = orderLine.get('sku');
  const selectedDeliveryOption = orderLine.get('selectedDeliveryOption');
  yield put(updatingQuantity(id, true));

  if (!id || typeof id !== 'string') {
    throw new Error(`Tried to call setOrderLineQuantity with invalid id: ${id}`);
  }

  if (!quantity || typeof quantity !== 'number') {
    throw new Error(`Tried to call setOrderLineQuantity with invalid quantity: ${quantity}`);
  }

  const body = {
    products: [{
      sku: id,
      qty: quantity,
      selectedDeliveryOption
    }]
  };

  try {
    yield call(setOrderLineQuantity, body);
  } catch (err) {
    yield put(recieveAddToCartError(err));
  }
  const payload = { addressId, eligibleForDeliveryOptions, fetchRecommendations: false };
  yield put(loadCart(payload));

  if (quantity) {
    const { success } = yield race({
      success: take(RECIEVE_LOAD_CART_SUCCESS),
      error: take(RECIEVE_LOAD_CART_ERROR),
      timeout: call(delay)
    });
    if (success && success.payload) {
      const previousQuantity = orderLine.get('quantity');
      const previousBreakPriceObject = orderLine.get('breakPriceObject', {}).toJS();
      const breakPrices = orderLine.getIn(['price', 'breakPrices'], List()).toJS();

      const foundNewBreakPriceObject = newBreakPriceObject(quantity, breakPrices);
      yield * updateQtyAddedToCart(success.payload.orderLines, id, quantity);
      const { payload: { currency } } = success;
      if (previousQuantity > quantity) {
        yield * sendBasketChangeToDataLayer(orderLine.toJS(), currency, previousQuantity - quantity, 'ItemQtyDecrease', null, null, null, quantity);
      }

      if (previousQuantity < quantity) {
        yield * sendBasketChangeToDataLayer(orderLine.toJS(), currency, quantity - previousQuantity, 'ItemQtyIncrease', null, null, null, quantity);
      }

      if (foundNewBreakPriceObject.breakDiscount && foundNewBreakPriceObject.breakDiscount !== 0 && !isEqualObject(previousBreakPriceObject, foundNewBreakPriceObject)) {
        call(delay);
        yield put(toastSuccess(prepareToastMessage(foundNewBreakPriceObject, 'success'), 'top-right', TOASTER_DELAY));
      }

      if (quantity < previousQuantity && !_isEqual(getSelectedBreakPriceObject(previousQuantity, breakPrices), getSelectedBreakPriceObject(quantity, breakPrices))) {
        call(delay);
        yield put(
          toastWarn(
            prepareToastMessage(getSelectedBreakPriceObject(previousQuantity, breakPrices), 'error')
            , 'top-right',
            TOASTER_DELAY
          )
        );
      }
    }
    yield put(updatingQuantity(id, false));
  }
}

export function * onRequestSetBundleQuantity ({ payload: { orderLine, quantity } }) {
  const id = orderLine.get('sku');
  const skuOfPrimaryProduct = orderLine.get('skuOfPrimaryProduct');
  yield put(updatingBundleQuantity(id, true, skuOfPrimaryProduct));

  if (!quantity || typeof quantity !== 'number') {
    throw new Error(`Tried to call setBundleQuantity with invalid quantity: ${quantity}`);
  }

  const bundleFamilyId = orderLine.get('bundleFamilyId');
  const products = orderLine.get('products').toJS()
    .map(product => {
      return product.isPrimary
        ? { isPrimary: product.isPrimary, sku: product.sku }
        : { sku: product.sku };
    });

  const body = {
    bundle: {
      bundleFamilyId,
      bundleQuantity: quantity,
      products
    }
  };

  try {
    yield call(setBundleQuantity, body);
  } catch (err) {
    yield put(recieveAddToCartError(err));
  }
  yield put(loadCart());

  if (quantity) {
    const { success } = yield race({
      success: take(RECIEVE_LOAD_CART_SUCCESS),
      error: take(RECIEVE_LOAD_CART_ERROR),
      timeout: call(delay)
    });
    if (success && success.payload) {
      const previousQuantity = orderLine.get('quantity');
      yield put(qtyAddedToCart(quantity));

      const { payload: { currency } } = success;
      if (previousQuantity > quantity) {
        yield * sendBundleBasketChangeToDataLayer(orderLine.toJS(), currency, previousQuantity - quantity, 'remove-bundle-from-cart');
      }

      if (previousQuantity < quantity) {
        yield * sendBundleBasketChangeToDataLayer(orderLine.toJS(), currency, quantity - previousQuantity, 'add-bundle-to-cart');
      }
    }
    yield put(updatingBundleQuantity(id, false, skuOfPrimaryProduct));
  }
}

const getFamilyIds = (orderLines, bundles) => {
  const familyIdsArrayFromOrderLines = _map(orderLines, (orderline) => orderline.family.familyId);
  const familyIds = familyIdsArrayFromOrderLines.join();

  if (bundles) {
    const familyIdsArrayFromBundles = _flatten(bundles.map(bundle => {
      return bundle.productDetails.map(product => product.family.familyId);
    }));

    return _uniq([...familyIdsArrayFromOrderLines, ...familyIdsArrayFromBundles]).join();
  }

  return familyIds;
};

function * IsMultiMessgaeDeliveryInfo () {
  const productLineItemsSelector = (state) => state.get('productLineItems', {});
  const orderLinesFromJs = yield select(productLineItemsSelector);
  const orderLinesToJs = orderLinesFromJs && orderLinesFromJs.toJS();
  return Object.keys(orderLinesToJs).some((sku) => {
    const orderLine = orderLinesToJs[sku];
    const deliveryInfo = _get(orderLine, 'stock.stockMessage.deliveryInfo', []);
    return deliveryInfo.length > 1;
  });
}

export function * onLoadCartRequest ({ payload }) {
  const discountCodeSelector = (state) => state.getIn(['cart', 'discount', 'code'], null);
  const typeSelector = (state) => state.getIn(['cart', 'discount', 'type'], null);
  const orderLineSelector = (state) => state.getIn(['cart', 'orderLines']).toJS();
  const discountCode = yield select(discountCodeSelector);
  const orderLine = yield select(orderLineSelector) || [];
  const type = yield select(typeSelector);
  const { eligibleForDeliveryOptions, addressId: userSelectedAddressId } = payload || {};
  const isBasketDropDown = (payload && payload.basketDropDown);
  if (isBasketDropDown) { // A/B testing basket confirmation dropdown
    yield put(toggleBasketConfirmationLoader(true));
  }
  const hasNonDeliverableProduct = !!orderLine && orderLine.some((line) => !!line && !!line.nonDeliverableTo && line.nonDeliverableTo.length > 0);
  if (!hasNonDeliverableProduct) {
    yield put(hideNonDeliverableOrderMessage());
  }
  try {
    const addressIdSelector = (state) => state.getIn(['user', 'address', 'delivery' ]);
    const deliveryAddress = yield select(addressIdSelector);
    const addressId = userSelectedAddressId || deliveryAddress?.toJS().addressId;
    const cart = yield call(getCart, discountCode, type, eligibleForDeliveryOptions, addressId);
    const productLineItems = toProductLineItems(cart, 'cart');
    yield put(reloadScheduledOrders(fromJS(productLineItems)));
    yield put(recieveLoadCartSuccess(cart));
    yield put(addProductLineItems(productLineItems));
    yield put(addProductBundleLineItems(toProductLineItems(cart, 'bundle')));
    if (isBasketDropDown) { // A/B testing basket confirmation dropdown
      yield put(toggleBasketConfirmationLoader(false));
    }

    if (cart.discount.code && type === 'modal') {
      yield put(showDiscountCodeDialog());
    }

    if (cart.isDiscountHigherThanTTP) {
      yield put(toastInfo(
        getToastMessage(
          getLocalizedString('cart.message.discountHigherThanTTP')
        ), 'top-right', 0, false));
    }

    const location = _get(payload, 'location', RECOMMENDATIONS_LOCATIONS.cart_screen);
    const fetchRecommendations = _get(payload, 'fetchRecommendations', true);
    const familyIds = getFamilyIds(cart.orderLines, cart.bundles);

    if (familyIds && fetchRecommendations && location !== SINGLE_PAGE_CHECKOUT && !isBasketDropDown) {
      yield put(clearRecommendations());
      const queryParams = { location, familyIds };
      const recommendations = yield call(getRecommendationsForFamily, queryParams);
      const isValidRecsList = recommendations?.length > 0;
      yield put(familyRecomendationsSuccess(recommendations));
      if (isValidRecsList) {
        yield call(pushGeneralData, {
          event: RECOMMENDATIONS_SERVED_EVENT,
          recommendationLocation: location,
          recommendationsServed: recommendations?.map(rec => rec.tableName)
        });
      }
    }
    const dispatchType = _get(payload, 'showMultimessageToastInfo', true);
    const isMultimessage = (dispatchType) ? yield IsMultiMessgaeDeliveryInfo() : null;
    if (isMultimessage) {
      yield put(toastWarn(
        getToastMessage(
          getLocalizedString('stockMessage.toastInfo.heading'),
          getLocalizedString('stockMessage.toastInfo.message')
        ), 'top-right', 0, false));
    }
    yield put(applyingDiscountCode(false));
    yield put(updateDataLayer({ cart }));
  } catch (err) {
    if (isBasketDropDown) { // A/B testing basket confirmation dropdown
      yield put(toggleBasketConfirmationLoader(false));
    }
    yield put(postOrderFailed({ error: buildErrorMessageFromError(err) }));
    yield put(recieveLoadCartError(err));
  }
}

export function * onLoadCartQuantityRequest () {
  const cartQty = yield call(getCartQuantity);
  try {
    yield put(receiveLoadCartQuantitySuccess(cartQty));
  } catch (err) {
    yield put(receiveLoadCartQuantityError(err));
  }
}

export function * watchRequestSetOrderLineQuantity () {
  yield takeLatest(REQUEST_SET_ORDER_LINE_QUANTITY, onRequestSetOrderLineQuantity);
}

export function * watchRequestSetBundleQuantity () {
  yield takeLatest(REQUEST_SET_BUNDLE_QUANTITY, onRequestSetBundleQuantity);
}

export function * watchLoadCartRequest () {
  yield takeLatest(REQUEST_LOAD_CART, onLoadCartRequest);
}

export function * watchLoadCartQuantityRequest () {
  yield takeLatest(REQUEST_LOAD_CART_QUANTITY, onLoadCartQuantityRequest);
}

export function * watchRemoveProductFromBasketDropdown () {
  yield takeLatest(REMOVE_ORDER_LINE_FROM_BASKET_DROPDOWN, removeProductFromBasketDropdown);
}

export function * watchCartChanges () {
  yield all([
    fork(watchRequestRemoveOrderLine),
    fork(watchRequestRemoveOrderLineConfirm),
    fork(watchRequestAddToCart),
    fork(watchRequestSetOrderLineQuantity),
    fork(watchLoadCartRequest),
    fork(watchLoadCartQuantityRequest),
    fork(watchRequestSetBundleQuantity),
    fork(watchRequestRemoveBundleOrderLine),
    fork(watchRequestRemoveBundleOrderLineConfirm),
    fork(watchRemoveProductFromBasketDropdown)
  ]);
}
