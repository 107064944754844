import { fromJS } from 'immutable';

import {
  TOGGLE_ENTER_MANUALLY,
  REQUEST_ADDRESS_WITH_POSTCODE_SUCCESS,
  REQUEST_FULL_ADDRESS_SUCCESS,
  RECEIVE_ADDRESS_ERROR,
  REQUEST_POST_CODE_ZONE_SUCCESS
} from 'client/actions/ui/postCodeActions';
import {
  INITIALIZE_COMPONENT_STATE,
  REMOVE_COMPONENT_STATE
} from 'shared/utils/localState';

const defaultState = fromJS({});

const postCode = (state = defaultState, action) => {
  switch (action.type) {
    case INITIALIZE_COMPONENT_STATE:
      return state.set(action.selector, fromJS({
        isManuallyEntered: false,
        addressList: [],
        errorMessage: ''
      }));
    case REMOVE_COMPONENT_STATE:
      return state.remove(action.selector);
    case TOGGLE_ENTER_MANUALLY:
      return state.set(action.selector, fromJS({
        isManuallyEntered: action.shouldEnterManually,
        addressList: [],
        errorMessage: '',
        postCodeZone: state.toJS()[action.selector]?.postCodeZone
      }));
    case REQUEST_ADDRESS_WITH_POSTCODE_SUCCESS:
      return state.set(action.selector, fromJS({
        isManuallyEntered: false,
        addressList: action.addressList,
        errorMessage: '',
        postCodeZone: state.toJS()[action.selector]?.postCodeZone
      }));
    case REQUEST_FULL_ADDRESS_SUCCESS:
      return state.setIn([action.selector, 'initialValues'], action.address);
    case RECEIVE_ADDRESS_ERROR:
      return state.set(action.selector, fromJS({
        isManuallyEntered: false,
        addressList: [],
        errorMessage: action.error
      }));
    case REQUEST_POST_CODE_ZONE_SUCCESS:
      return state.set(action.selector, fromJS({
        isManuallyEntered: true,
        postCodeZone: action.postCodeZone
      }));
    default:
      return state;
  }
};

export default postCode;
