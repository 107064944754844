
import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { ADDRESSBOOK_GRID_VIEW, ADDRESSBOOK_LIST_VIEW, PERSONAL } from 'shared/constants/singlePageCheckout';
import { removeEmptyStringFromAddress } from 'client/utils/singlePageCheckoutUtils';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./AddressBlock.scss');
}

const getClass = (defaultDeliveryId, selectedAddressIdForDelivery, addressId) => {
  return selectedAddressIdForDelivery
    ? addressId === selectedAddressIdForDelivery ? 'active' : 'default'
    : defaultDeliveryId === addressId ? 'active' : 'default';
};

const gridViewLayout = (address, defaultDeliveryId, onSelectNewAddress, selectedAddressIdForDelivery, onEditDeliveryAddressClick, showEditAction) => {
  const className = getClass(defaultDeliveryId, selectedAddressIdForDelivery, address.get('addressId'));
  return (
    <div className={ `AddressBlock_gridView_${className} col-md-3 col-sm-4 col-xs-6` } onClick={ (e) => onSelectNewAddress(address.get('addressId')) }>
      <div className="AddressBlock_gridView_head">
        <SvgLoader xlinkHref={ `#radio-${className}` } className="AddressBlock_gridView_head_svg"/>
        <span className="AddressBlock_gridView_head_deliverTo">{ getLocalizedString('singlePageCheckout.deliverTo') }</span>
        { showEditAction
          ? <span className="AddressBlock_gridView_head_editAddress"
            onClick={ () => onEditDeliveryAddressClick(selectedAddressIdForDelivery ? selectedAddressIdForDelivery : address.get('addressId')) }>
            { getLocalizedString('singlePageCheckout.addessBook.edit') }
          </span>
          : null
        }
      </div>
      <div className="AddressBlock_gridView_body">
        {
          address.get('addressName')
            ? <div className="AddressBlock_gridView_body_addressRef AddressBlock_gridView_textOverflowEllipsis" title={ address.get('addressName') }>{ getLocalizedString('singlePageCheckout.addessBook.addressRef') }{ address.get('addressName') }</div>
            : <div className="AddressBlock_gridView_body_addressRef"/>
        }
        <div className="AddressBlock_gridView_body_userName">
          <span>{ address.get('firstName') } { address.get('lastName') }</span>
        </div>
        <div className="AddressBlock_gridView_body_companyName AddressBlock_gridView_textOverflowEllipsis" title={ address.get('companyName') }>{ address.get('companyName') }</div>
        <div className="AddressBlock_gridView_body_addressLineSection">
          <div className="AddressBlock_gridView_body_addressLineSection_addressLine AddressBlock_gridView_textOverflowEllipsis" title={ address.get('addressLine1') }>{ address.get('addressLine1') }</div>
          <div className="AddressBlock_gridView_body_addressLineSection_addressLine AddressBlock_gridView_textOverflowEllipsis" title={ address.get('addressLine2') }>{ address.get('addressLine2') }</div>
          <div className="AddressBlock_gridView_body_addressLineSection_city">{ address.get('city') }</div>
          <div className="AddressBlock_gridView_body_addressLineSection_postalCode">{ address.get('postalCode') }</div>
        </div>
        <div className="AddressBlock_gridView_body_phone">
          {
            address.get('addressPhoneNumber')
              ? <div>
                <div className="AddressBlock_gridView_body_phone_title">{getLocalizedString('singlePageCheckout.phoneNumber')} </div>
                <div className="AddressBlock_gridView_body_phone_value">{ address.get('addressPhoneNumber') }</div>
              </div>
              : null
          }
        </div>
        <div className="AddressBlock_gridView_body_addressType">
          <div className="AddressBlock_gridView_body_addressType_title">{getLocalizedString('singlePageCheckout.addressType')} </div>
          <div className="AddressBlock_gridView_body_addressType_value">{ address.get('addressType') || PERSONAL }</div>
        </div>
      </div>
    </div>
  );
};

const listViewLayout = (address, defaultDeliveryId, onSelectNewAddress, selectedAddressIdForDelivery, onEditDeliveryAddressClick, showEditAction) => {
  const className = getClass(defaultDeliveryId, selectedAddressIdForDelivery, address.get('addressId'));
  return (
    <div className={ `AddressBlock_listView_${className} row` } onClick={ (e) => onSelectNewAddress(address.get('addressId')) }>
      <div className="AddressBlock_listView_head col-md-3">
        <SvgLoader xlinkHref={ `#radio-${className}` } className="AddressBlock_listView_head_svg"/>
        <span className="AddressBlock_listView_head_deliverTo">{ getLocalizedString('singlePageCheckout.deliverTo') }</span>
      </div>
      <div className="AddressBlock_listView_body col-md-6">
        {
          address.get('addressName')
            ? <div className="AddressBlock_listView_body_addressRef" title={ address.get('addressName') }>{ getLocalizedString('singlePageCheckout.addessBook.addressRef') }{ address.get('addressName') }</div>
            : <div className="AddressBlock_listView_body_addressRef"/>
        }
        <div className="AddressBlock_listView_body_address">
          <div className="AddressBlock_listView_body_address_userName">
            <span>{ address.get('firstName') } { address.get('lastName') }</span>
          </div>
          <div className="AddressBlock_listView_body_address_addressLineSection">
            <span>{
              removeEmptyStringFromAddress(getLocalizedString('singlePageCheckout.addessBook.formattedAddress')
                .replace('{companyName}', address.get('companyName') ? address.get('companyName') : '')
                .replace('{addressLine1}', address.get('addressLine1'))
                .replace('{addressLine2}', address.get('addressLine2') ? address.get('addressLine2') : '')
                .replace('{city}', address.get('city'))
                .replace('{postalCode}', address.get('postalCode')))
            }</span>
          </div>
        </div>
        <div className="AddressBlock_listView_body_phone">
          {
            address.get('addressPhoneNumber')
              ? <div>
                <strong>{ getLocalizedString('singlePageCheckout.phoneNumber') }</strong>
                <span> { address.get('addressPhoneNumber') }</span>
              </div>
              : null
          }
        </div>
        <div className="AddressBlock_listView_body_phone">
          <strong>{ getLocalizedString('singlePageCheckout.addressType') }</strong>
          <span> { address.get('addressType') || PERSONAL }</span>
        </div>
      </div>
      {
        showEditAction ? <div className="AddressBlock_listView_head col-md-3">
          <span className="AddressBlock_listView_head_editAddress" onClick={ () => onEditDeliveryAddressClick(address.get('addressId')) }> { getLocalizedString('singlePageCheckout.addessBook.edit') }  </span>
        </div>
          : null
      }
    </div>

  );
};

export const AddressBlock = ({ address, addressBookViewType, defaultDeliveryId, onSelectNewAddress, selectedAddressIdForDelivery, onEditDeliveryAddressClick, showEditAction }) => {
  const addressBookLayoutMap = {
    [ADDRESSBOOK_GRID_VIEW]: gridViewLayout,
    [ADDRESSBOOK_LIST_VIEW]: listViewLayout
  };

  return addressBookLayoutMap[addressBookViewType](address, defaultDeliveryId, onSelectNewAddress, selectedAddressIdForDelivery, onEditDeliveryAddressClick, showEditAction);
};
