import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import flow from 'lodash/flow';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';
import { urlifyText } from 'client/utils/urlUtils';
import Col from 'react-bootstrap/lib/Col';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import LinesEllipsis from 'react-lines-ellipsis';
import { formatPrice } from 'client/utils/priceUtils';
import get from 'lodash/get';
import { MAX_SEARCH_INPUT } from 'shared/constants/common';

if (process.browser) {
  require('./AutoComplete.scss');
}
const suffix = (preposition) => (value) => (
  <span key={ value.split(' ').join('') }>
    { ` ${preposition} ` }
    <span className="react-autosuggest__suggestions-suffix">{value}</span>
  </span>
);

const brandSuffix = suffix(getLocalizedString('search.suggestion.brand.preposition'));
const categorySuffix = suffix(getLocalizedString('search.suggestion.category.preposition'));

const suffixText = ({ brandName, label }) => {
  if (brandName) {
    return brandSuffix(brandName);
  }

  if (label) {
    return categorySuffix(label);
  }

  return '';
};

const matchSearchTerm = (regex) => (textToSearch) => textToSearch.replace(regex, '<match>\$1<match>'); // eslint-disable-line no-useless-escape
const splitAtMatches = (searchedText) => searchedText.split('<match>');
const addHtml = (regex) => (matchedText) => (
  matchedText.map((subtext, index) => (
    subtext.match(regex)
      ? subtext
      : <em key={ `${subtext}${index}` } className="react-autosuggest__suggestion-highlight">{subtext}</em>
  ))
);

const escapeRegExp = (searchTerm = '') => {
  return searchTerm.replace(/[.*+?^${}()|[\]\\]/g, ' '); // "All of these should be replaced: \\ \^ \$ \* \+ \? \. \( \) \| \{ \} \[ \] "
};

const getPriceAndVat = (price, vat, currency, isPriceWithVat) => {
  const formatedPrice = formatPrice(currency, price);
  return (
    <React.Fragment>
      <span className="amount">{formatedPrice.symbol}{formatedPrice.amount}</span>
      { <span className="vat fontWeightNormal">{`${isPriceWithVat ? 'Inc Vat' : 'Exc Vat'} (${vat}%)`}</span>}
    </React.Fragment>
  );
};

const getProductPriceAndSaleDetails = (params, isPriceWithVat) => {
  const defaultCurrency = 'GBP';
  const isInPromo = get(params, 'packPromoInfo.isInPromo', false);
  const vat = get(params, 'price.vat', null);
  const currency = get(params, 'price.currency', defaultCurrency);
  // temporary fix to set default valve 0 for packWasPriceWithVat(for bloomreach)
  const packWasPrice = get(params, isPriceWithVat ? 'packPromoInfo.packWasPriceWithVat' : 'packPromoInfo.packWasPrice', 0);
  const price = get(params, isPriceWithVat ? 'packPriceWithVat' : 'packPrice', null);
  const promoDiscount = get(params, 'packPromoInfo.promoDiscount', null);

  if (isInPromo) {
    const formattedPrice = formatPrice(currency, packWasPrice);
    return (
      <div>
        { getPriceAndVat(price, vat, currency, isPriceWithVat) }
        <div className="row">
          <div className="promoLabel fontWeightNormal">
          Was { formattedPrice.symbol }{ formattedPrice.amount } {(promoDiscount) && <span>{ promoDiscount }% off</span>}</div>
        </div>
      </div>
    );
  }
  return (
    <div>
      { getPriceAndVat(price, vat, currency, isPriceWithVat) }
    </div>
  );
};

const displaySecondaryText = (params, isPriceWithVat) => {
  return (
    <div className="secondary-text">
      <div className="FontSmallGrey fontWeightNormal">
        Order Code: {params.productId}
      </div>
      { getProductPriceAndSaleDetails(params, isPriceWithVat) }
    </div>
  );
};

const getTrimmedText = (text) => {
  return (
    <LinesEllipsis
      text={ text }
      className="trucateTexts"
      maxLine={ 6 }
      ellipsis="..."
      trimRight
      basedOn="letters"
    />
  );
};

const getTopProduct = (params, isPriceWithVat) => {
  return (
    <Grid className="MarginBottom TopProductCard" data-e2e="topProduct">
      <Row>
        <Col xs={ 12 } md={ 4 }>
          <img
            src={ params.imageUrl }
            style={{ borderRadius: 0, width: 84, height: 84, paddingBottom: '5px' }}
          />
        </Col>
        <Col xs={ 12 } md={ 8 }>
          <Row className="fontWeightNormal  ">
            { getTrimmedText(params.name) }
          </Row>
          <Row>
            { displaySecondaryText(params, isPriceWithVat) }
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};

const handleMouseEnter = (text, params, fetchAutoCompleteTopProductsOnHover) => {
  return (evt) => {
    fetchAutoCompleteTopProductsOnHover(text, params);
  };
};

const renderSuggestion = (searchTerm, fetchAutoCompleteTopProductsOnHover, isPriceWithVat) => {
  const escapedTerm = searchTerm ? escapeRegExp(searchTerm) : '';
  const regex = new RegExp(`(${escapedTerm})`, 'gi');
  const highlightText = flow(matchSearchTerm(regex), splitAtMatches, addHtml(regex));

  return ({ text, params = {} }) => {
    if (params && params.productId) {
      return getTopProduct(params, isPriceWithVat);
    }
    return (
      <div onMouseEnter={ () => {
        if (searchTerm.length >= 3) {
          handleMouseEnter(text, params, fetchAutoCompleteTopProductsOnHover);
        }
      } }>
        <span>{ highlightText(text) }{ suffixText({ ...params }) }</span>
      </div>
    );
  };
};

const renderSectionTitle = (section) => <h4>{section.title}</h4>;
const getSectionSuggestions = (section) => section.results;
const onSuggestionsFetchRequested = () => '';

const onSuggestionsClearRequested = () => [];

const onSuggestionSelected = (autoCompleteSelect) => {
  return function (evt, { suggestion = {}, suggestionValue }) {
    evt.preventDefault();
    const path = suggestion.params && suggestion.params.label ? `/${urlifyText(suggestion.params.label)}/${suggestion.params.id}` : '';
    const params = Object.assign({}, { query: suggestionValue }, suggestion.params);
    autoCompleteSelect(null, { params, path, suggestion, evt: evt.type });
  };
};

const AutoComplete = (props) => {
  const {
    autoCompleteSearch,
    autoCompleteSelect,
    placeholder = getLocalizedString('search.input.placeholder'),
    searchSuggestions: suggestions,
    value,
    fetchAutoCompleteTopProductsOnHover,
    getRecentSearches,
    isPriceWithVat
  } = props;

  const searchTerm = value || '';
  const validSuggestions = suggestions.map((suggestion) => {
    const validatedTitle = suggestion.title || '';
    const validatedResults = suggestion.results || [];
    return { ...suggestion, title: validatedTitle, results: validatedResults };
  });
  const isMultiSection = true;
  const [selected, setSelected] = useState(null);
  const [shouldShow, setShouldShow] = useState(false);
  const term = selected !== null ? selected : searchTerm;
  const autoCompleteDropdownRef = useRef(null);
  const inputProps = {
    autoComplete: 'off',
    name: 'query',
    onChange: autoCompleteSearch,
    placeholder,
    type: 'search',
    value: term,
    maxLength: MAX_SEARCH_INPUT,
    'data-e2e': 'searchInput',
    onFocus: (e) => {
      e.preventDefault();
      setShouldShow(true);
      if (!e.target.value) {
        return getRecentSearches();
      }
    },
    onClick: () => {
      setShouldShow(true);
    },
    onKeyDown: e => handleKeyBoardEnter(e)
  };

  useEffect(() => {
    const hide = (e) => {
      const dropDownEl = autoCompleteDropdownRef.current.autowhatever.itemsContainer;
      if (dropDownEl && !dropDownEl.matches(':hover')) {
        setShouldShow(false);
      }
    };
    window.addEventListener('scroll', hide);
    return () => window.removeEventListener('scroll', hide);
  }, []);

  const getSuggestionValue = (suggestion) => {
    if (suggestion && suggestion.params && suggestion.params.productId) {
      return '';
    }
    handleMouseEnter(suggestion.text, suggestion.params, fetchAutoCompleteTopProductsOnHover)();
    return suggestion.text;
  };

  const onSuggestionHighlighted = ({ suggestion }) => {
    suggestion === null
      ? setSelected(null)
      : setSelected(suggestion.text);
  };

  // handle KeyBoard Enter
  const handleKeyBoardEnter = (evt) => {
    if (evt.key === 'Enter') {
      const params = { query: term };
      term.trim().length && autoCompleteSelect(null, { params });
    }
  };

  const handleOnClick = () => {
    if (!term.trim().length) {
      return;
    }
    const params = { query: term };
    autoCompleteSelect(null, { params });
  };
  return (
    <div className="AutoComplete">
      <form action="/shop" method="GET"
        autoComplete="on"
        onSubmit={ (evt) => {
          evt.preventDefault();
        } }
      >
        <label className="AutoComplete_SearchIcon">
          <SvgLoader
            xlinkHref="#search-icon"
            data-e2e="searchButton"
            onClick = { handleOnClick }
          />
        </label>
        <span className="sr-only">Search</span>
        <Autosuggest
          ref={ autoCompleteDropdownRef }
          multiSection={ isMultiSection }
          shouldRenderSuggestions={ () => shouldShow }
          renderSectionTitle={ renderSectionTitle }
          getSectionSuggestions={ getSectionSuggestions }
          onSuggestionSelected={ onSuggestionSelected(autoCompleteSelect, searchTerm) }
          suggestions={ validSuggestions }
          onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
          onSuggestionsClearRequested={ onSuggestionsClearRequested }
          getSuggestionValue={ getSuggestionValue }
          renderSuggestion={ renderSuggestion(value, fetchAutoCompleteTopProductsOnHover, isPriceWithVat) }
          inputProps={ inputProps }
          onSuggestionHighlighted = { onSuggestionHighlighted }
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isPriceWithVat: state.getIn(['user', 'isPriceWithVat'])
  };
};

AutoComplete.propTypes = {
  getRecentSearches: PropTypes.func,
  autoCompleteSearch: PropTypes.func,
  autoCompleteSelect: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string,
  searchSuggestions: PropTypes.object,
  value: PropTypes.string,
  fetchAutoCompleteTopProductsOnHover: PropTypes.func
};

export default connect(mapStateToProps, null)(AutoComplete);
