import {
  SET_VIEW,
  SET_USER_TYPE,
  ADD_ANOTHER_USER_TOGGLE,
  UPDATE_ADDITIONAL_USERS_LIST,
  SET_FINAL_STEP_ERROR,
  IS_LOGIN_LOADING
} from 'client/actions/loginAndRegistration';
import { fromJS } from 'immutable';
import {
  LOGIN_VIEW
} from 'client/components/elements/LoginAndRegistrationForm/constants';

const defaultObject = {
  view: LOGIN_VIEW,
  toggleAnotherUser: true,
  additionalUsersList: [],
  isLoading: false
};

const loginAndRegistrationReducer = (state = fromJS(defaultObject), { type, payload }) => {
  switch (type) {
    case SET_FINAL_STEP_ERROR:
      return state
        .set('finalStepError', payload);
    case UPDATE_ADDITIONAL_USERS_LIST:
      return state
        .set('additionalUsersList', payload.sort((a, b) => a?.isFinanceUser - b?.isFinanceUser));
    case ADD_ANOTHER_USER_TOGGLE:
      return state
        .set('toggleAnotherUser', payload);
    case SET_VIEW:
      return state
        .set('view', payload.view);
    case IS_LOGIN_LOADING:
      return state
        .set('isLoading', payload.isLoading);
    case SET_USER_TYPE:
      return state
        .set('userType', payload.userType);
    default:
      return state;
  }
};

export default loginAndRegistrationReducer;
