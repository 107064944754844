export const populateTitleOptionBox = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'];
export const populateReasonForContact = [
  'Order Update',
  'Invoice Request',
  'Quotation',
  'Purchase Order',
  'Price and Stock',
  'Trade Account Query',
  'Finance',
  'Product Question',
  'Account Statement',
  'Other'
];

export const isZoneTwoOrThree = (parcelForceZone) => {
  return parcelForceZone === 2 || parcelForceZone === 3;
};
