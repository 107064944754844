import { ApiRequest, FrontendApiRequest } from 'shared/utils/apiUtils';

export function registerUser (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users',
    body
  });

  return req.executeAndGetBody();
}

export function saveUserTelephone (telephoneNumber) {
  const body = { telephoneNumber };
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/saveUserTelephone',
    body
  });
  return req.executeAndGetBody();
}

export function inviteToAccount (newAccount) {
  const body = { ...newAccount };
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/inviteToAccount',
    body
  });

  return req.executeAndGetBody();
}

export function updateAccountUser (updatedUser) {
  const body = { ...updatedUser };
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/updateAccountUser',
    body
  });

  return req.executeAndGetBody();
}

export function accountUsers () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users/accountUsers'
  });

  return req.executeAndGetBody();
}

export function getAccountUser (email) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users/getAccountUser',
    query: {
      email
    }
  });

  return req.executeAndGetBody();
}

export function saveUserAddress (address) {
  if (address.companyName === null) {
    address.companyName = '';
  }
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users/me/addresses',
    body: { address }
  });

  return req.executeAndGetBody();
}

export function saveBillingAddress (address) {
  if (address.companyName === null) {
    address.companyName = '';
  }
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users/me/addresses/billing',
    body: { address }
  });

  return req.executeAndGetBody();
}
export function getUserAddressesAll (paginationData) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users/me/addresses',
    query: paginationData
  });

  return req.executeAndGetBody();
}

export function updateDefaultDeliveryAddress (addressId) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/me/addresses/updateDefaultDeliveryAddress',
    body: { addressId }
  });

  return req.executeAndGetBody();
}

export function updateUserAddress ({ addressId, ...rest }) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/users/me/addresses/${addressId}`,
    body: { address: rest }
  });
  return req.executeAndGetBody();
}

export function deleteAddressEndPoint (addressId) {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/users/me/addresses/${addressId}`
  });

  return req.executeAndGetBody();
}

export function loginUser (email, password) {
  const body = {
    loginData: {
      email: email,
      password: password,
      sub: 'testSub'
    }
  };
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/login',
    body
  });

  return req.executeAndGetBody();
}

export function forceUserLogout () {
  if (!process.browser) {
    throw new Error('Logout only works on browser');
  }

  const req = new FrontendApiRequest({
    method: 'DELETE',
    apiPath: 'api/jwtToken'
  });

  return req.execute().then(() => {
    window.location.href = '/';
  });
}

export function doesUserExist (email) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users',
    query: {
      email
    }
  });

  return req.executeAndGetBody();
}

export function userEnquiry (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users/enquiry',
    body
  });

  return req.executeAndGetBody();
}

export function updateVAT (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users/preference',
    body
  });

  return req.executeAndGetBody();
}

export function fetchUserPreference () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users/preference'
  });
  return req.executeAndGetBody();
}

export function updateDefaultPayment (body) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/paymentMethod/default',
    body
  });
  return req.executeAndGetBody();
}
