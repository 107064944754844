import { takeLatest, call, take, put, select } from 'redux-saga/effects';
import {
  FETCH_GLOBAL_QUOTATION,
  storeGlobalQuotation,
  GET_PRODUCT_BY_SKU,
  removeProductFromGlobalQuotationSuccess,
  REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_CONFIRM,
  GLOBAL_QUOTATION_QTY_CHANGE,
  updateGlobalQuotationProductQtySuccess,
  ADD_TO_QUOTE,
  clearAddedProductToQuotation,
  LOCK_GLOBAL_QUOTATION,
  isQuoteRefValid,
  RETRIEVE_GLOBAL_QUOTATION,
  noQuoteFoundByRef,
  MERGE_GLOBAL_QUOTATION,
  FETCH_GLOBAL_QUOTATION_BY_ID_OR_QUOTE_REF,
  loadinGlobalQuote,
  fetchinQuoteByRef,
  UPDATE_GLOBAL_QUOTE_NAME,
  quoteNameWasUpdated,
  fetchGlobalQuotationByIdOrQuoteRef as fetchGlobalQuotationByIdOrQuoteRefAction,
  INIT_GLOBAL_QUOTATIONS_DETAILS,
  CHANGE_DELIVERY_OPTION_FROM_GLOBAL_QUOTATION,
  changeDeliveryOptionFromGlobalQuotation,
  STORE_GLOBAL_QUOTATION,
  updateGlobalQuotation,
  fetchGlobalQuotation as fetchGlobalQuotationAction,
  SAVE_QUOTE_TO_MY_LIST,
  lockingGlobalQuotation
} from 'client/actions/globalQuotationActions';
import {
  fetchQuotations as fetchQuotationsEndpoint,
  addProductsToQuotation as addProductsToQuotationEndpoint,
  fetchGlobalQuotations,
  fetchQuotationDetails as fetchQuotationDetailsEndpoint,
  createQuotation as createQuotationEndpoint,
  createGlobalQuotation as createGlobalQuotationEndpoint,
  removeProductFromQuotation as removeProductFromQuotationEndpoint,
  updateQuotationProductQty as updateQuotationProductQtyEndpoint,
  lockQuotation as lockGlobalQuotationEndPoint,
  saveQuoteToYourList as saveQuoteToYourListEndpoint,
  deleteQuotation as deleteGlobalQuotationEndpoint,
  mergeGlobalQuotes as mergeGlobalQuotesEndpoint,
  fetchQuotationDetails as fetchGlobalQuotationDetailsEndpoint,
  updateGlobalQuoteName as updateGlobalQuoteNameEndpoint
} from 'shared/endpoints/quotationsEndpoint';
import {
  toggleDeliveryOptionAccordion,
  updatingOptionLoader
} from 'client/actions/orderReviewActions';
import { REQUEST_REMOVE_QUOTE_LINE_CONFIRM, addToQuotationError } from 'client/actions/quotationsActions';
import pick from 'lodash/pick';
import { addProductLineItems, updatingQuantity } from 'client/actions/productLineActions';
import { toProductLineItems } from 'client/utils/productLineUtils';
import { getProductBySkuWithOrderline } from 'shared/endpoints/productVariantEndpoint';
import { getSelectedBreakPriceObject, showBanner, prepareToastMessage } from './quotationsSagas';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { newBreakPriceObject } from 'client/utils/priceUtils';
import { toastWarn, toastSuccess } from 'client/actions/showNotificationsActions';
import {
  showCustomAlertDialog,
  showRemoveQuoteLineDialog,
  hideRemoveQuoteLineDialog,
  showLockingAndSavingQuoteDialog,
  hideSavingQuoteDialog
} from 'client/actions/ui/dialogActions';
import { familyRecomendationsSuccess, clearRecommendations } from 'client/actions/recommendationsActions';
import { getRecommendationsForFamily } from 'shared/endpoints/recommendationEndpoint';
import { goToUrl, gotoExternalUrl } from 'client/actions/routeActions';
import { fromJS } from 'immutable';
import { RECOMMENDATIONS_LOCATIONS } from '../../shared/constants/recommendation';
import { pushGeneralData } from '../utils/googleTagManagerUtils';
import { RECOMMENDATIONS_SERVED_EVENT } from '../components/elements/recommendations/constants';

const globalQuotationSelector = (state) => state.getIn(['globalQuotation', 'createdQuotation'], fromJS({}));
const existingQuotationsSelector = (state) => state.getIn(['quotations', 'all'], fromJS([]));
const isLoggedInOrhasValidLongSessionTokenSelector = (state) => state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken']);
const TOATSER_DELAY = 5000;

export function * mergeGlobalQuotes (action) {
  try {
    const updatedQuote = yield call(mergeGlobalQuotesEndpoint, action.quotationId);
    const globalQuote = yield call(fetchGlobalQuotationDetailsEndpoint, updatedQuote._id);
    yield put(storeGlobalQuotation(globalQuote));
    yield put(addProductLineItems(toProductLineItems(globalQuote, 'quotations')));
  } catch (e) {
    console.error('Error merging quotes', e); // eslint-disable-line no-console
  }
}

export function * addToQuoteOrCreateQuote (action) {
  try {
    const productForQuotation = pick(action.product, ['sku', 'qty']);
    const isUserLoggedIn = yield select(isLoggedInOrhasValidLongSessionTokenSelector);
    const globalQuote = yield select(globalQuotationSelector);
    const existingQuotations = yield select(existingQuotationsSelector);
    if (isUserLoggedIn && !action.redirectToGlobalQuotation) {
      if (!existingQuotations.size) {
        yield put(loadinGlobalQuote(true));
        !globalQuote.size
          ? yield createGlobalQuotation(productForQuotation)
          : yield onAddProductsToQuotation(productForQuotation, globalQuote.get('_id'));
        yield put(loadinGlobalQuote(false));
      }
      yield put(goToUrl(`/add-to-quotation/${productForQuotation.sku}?qty=${productForQuotation.qty}`));
      return;
    }

    yield put(loadinGlobalQuote(true));
    !globalQuote.size
      ? yield createGlobalQuotation(productForQuotation)
      : yield onAddProductsToQuotation(productForQuotation, globalQuote.get('_id'));
    yield put(loadinGlobalQuote(false));

    if (action.redirectToGlobalQuotation && existingQuotations.size) {
      yield put(goToUrl('/global-quotation'));
    } else {
      yield put(goToUrl(`/add-to-quotation/${productForQuotation.sku}?qty=${productForQuotation.qty}`));
    }
  } catch (e) {
    console.error('Error on adding or creating quote', e); // eslint-disable-line no-console
  }
}

export function * onAddProductsToQuotation (product, quoteId) {
  try {
    yield call(addProductsToQuotationEndpoint, quoteId, [product]);
    yield fetchGlobalQuotation();
  } catch (e) {
    console.error('Error adding products to quotation', e); // eslint-disable-line no-console
  }
}

export function * fetchGlobalQuotation () {
  try {
    const globalQuotation = yield call(fetchGlobalQuotations);
    if (globalQuotation && Object.keys(globalQuotation).length) {
      yield put(storeGlobalQuotation(globalQuotation));
      yield put(addProductLineItems(toProductLineItems(globalQuotation, 'quotations')));
    }
  } catch (e) {
    console.error('Error fetching global quotation', e); // eslint-disable-line no-console
  }
}

export function * createGlobalQuotation (product) {
  try {
    const createdQuote = yield call(createGlobalQuotationEndpoint, [product]);
    const createdGlobalQuotation = yield call(fetchQuotationDetailsEndpoint, createdQuote._id);
    yield put(storeGlobalQuotation(createdGlobalQuotation));
    yield put(addProductLineItems(toProductLineItems(createdGlobalQuotation, 'quotations')));
  } catch (e) {
    console.error('Error fetching global quotation', e); // eslint-disable-line no-console
  }
}

export function * fetchGlobalQuotationByIdOrQuoteRef (action) {
  try {
    const quoteRefRegex = new RegExp(/^ZQ-\d{6}-\w{3}$/i);
    const isQuoteRef = quoteRefRegex.test(action.quoteRefOrId);
    yield put(fetchinQuoteByRef(true));
    if (!isQuoteRef && action.redirectToGlobalQuotation) {
      yield put(goToUrl('/global-quotation'));
      yield put(fetchinQuoteByRef(false));
      return;
    }
    const quoteRefOrId = isQuoteRef ? action.quoteRefOrId.toUpperCase() : action.quoteRefOrId;
    const globalQuotation = yield call(fetchGlobalQuotationDetailsEndpoint, quoteRefOrId, action.eligibleForDeliveryOptions);
    if (globalQuotation) {
      yield put(addProductLineItems(toProductLineItems(globalQuotation, 'quotations')));
      yield put(storeGlobalQuotation(globalQuotation));
    }
    yield put(fetchinQuoteByRef(false));
  } catch (e) {
    yield put(noQuoteFoundByRef(action.quoteRefOrId));
    yield put(fetchinQuoteByRef(false));
    console.error('Error fetching global quotation', e); // eslint-disable-line no-console
  }
}

export function * getProductBySku (action) {
  try {
    const result = yield call(getProductBySkuWithOrderline, action.sku, action.quantity);
    yield put(addProductLineItems(toProductLineItems(result, 'quotationInterstitial')));
    const { family: { familyId } } = result;
    if (familyId) {
      const location = RECOMMENDATIONS_LOCATIONS.add_to_quotation_interstitial;
      yield put(clearRecommendations());
      const queryParams = { location, familyIds: familyId };
      const recommendations = yield call(getRecommendationsForFamily, queryParams);
      const isValidRecsList = recommendations?.length > 0;
      yield put(familyRecomendationsSuccess(recommendations));
      if (isValidRecsList) {
        yield call(pushGeneralData, {
          event: RECOMMENDATIONS_SERVED_EVENT,
          recommendationLocation: location,
          recommendationsServed: recommendations?.map(rec => rec.tableName)
        });
      }
    }
  } catch (e) {
    console.error('Error getting product by sku', e); // eslint-disable-line no-console
  }
}

export function * deleteQuotation ({ quotationId }, displayAlertMessage = true) {
  try {
    yield call(deleteGlobalQuotationEndpoint, quotationId);
    yield put(clearAddedProductToQuotation());
    if (displayAlertMessage) {
      yield put(showCustomAlertDialog('The quotation has been deleted.'));
    }
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR deleting the quotation.'));
  }
}

export function * removeProductFromGlobalQuotationConfirm ({ quotationId, product, delQuotation }) {
  yield put(showRemoveQuoteLineDialog(product, delQuotation));
  const payload = yield take(REQUEST_REMOVE_QUOTE_LINE_CONFIRM);
  if (!payload) {
    return;
  }
  if (delQuotation) {
    yield deleteQuotation({ quotationId });
    yield put(clearAddedProductToQuotation());
    yield put(hideRemoveQuoteLineDialog());
  } else {
    yield removeProductFromGlobalQuotation({ quotationId, sku: product.get('sku') });
  }
}

export function * removeProductFromGlobalQuotation ({ quotationId, sku }) {
  yield call(removeProductFromQuotationEndpoint, quotationId, sku);
  yield put(removeProductFromGlobalQuotationSuccess(quotationId, sku));
  yield put(hideRemoveQuoteLineDialog());
  yield put(updateDataLayer({
    event: 'remove_product_from_quotation',
    quotationId,
    sku
  }));
  yield put(fetchGlobalQuotationAction());
  yield put(showCustomAlertDialog('Product has been removed from global quotation.'));
}

export function * updateQuotationProductQty ({ quotationId, sku, qty, breakPrices }) {
  const result = yield call(updateQuotationProductQtyEndpoint, quotationId, sku, qty);
  yield put(updateGlobalQuotationProductQtySuccess(quotationId, sku, qty, breakPrices));
  yield put(addProductLineItems(toProductLineItems(result, 'quotations')));
  yield put(updatingQuantity(sku, false));
  if (result.quotationPrice) {
    yield put(updateGlobalQuotation(quotationId, result.quotationPrice, result.orderLines));
  }
}

export function * requestChangeInGlobalQuoteLineQty ({ quotationId, prevQty, qty, sku, breakPrices }) {
  yield put(updatingQuantity(sku, true));
  const foundNewBreakPriceObject = newBreakPriceObject(qty, breakPrices);
  if (showBanner(prevQty, qty, breakPrices) === 'error') {
    yield put(toastWarn(
      prepareToastMessage(getSelectedBreakPriceObject(prevQty, breakPrices), 'error'),
      'top-right',
      TOATSER_DELAY));
  } else if (showBanner(prevQty, qty, breakPrices) === 'success') {
    yield put(toastSuccess(
      prepareToastMessage(foundNewBreakPriceObject, 'success'),
      'top-right',
      TOATSER_DELAY));
  }
  yield updateQuotationProductQty({ quotationId, sku, qty, breakPrices });
}

export function * lockGlobalQuotation (action) {
  try {
    yield put(lockingGlobalQuotation(true));
    const lockedQuote = yield call(lockGlobalQuotationEndPoint, action.quotationId);
    const reLockedQuote = yield call(fetchGlobalQuotationDetailsEndpoint, lockedQuote.quoteRef);
    yield put(lockingGlobalQuotation(false));
    yield put(storeGlobalQuotation(reLockedQuote));
    if (action.isLockingQuote && action.isLoggedInOrhasValidLongSessionToken) {
      yield call(saveQuoteToYourListEndpoint, reLockedQuote._id);
      yield put(showLockingAndSavingQuoteDialog());
    }
    yield put(goToUrl(`/global-quotation/${lockedQuote.quoteRef}`));
  } catch (e) {
    console.error(`Error locking quotation ${action.quotationId}`, e); // eslint-disable-line no-console
  }
}

export function * retrieveGlobalQuotation (action) {
  try {
    const quoteRefRegex = new RegExp(/^ZQ-\d{6}-\w{3}$/i);
    const isUserLoggedIn = yield select(isLoggedInOrhasValidLongSessionTokenSelector);
    if (!quoteRefRegex.test(action.quoteRef)) {
      yield put(isQuoteRefValid(false));
      return;
    }
    yield call(fetchGlobalQuotationDetailsEndpoint, action.quoteRef);
    if (isUserLoggedIn) { // add this check to don't make unecessary call
      const quotations = yield call(fetchQuotationsEndpoint);
      const isRetrievingOwnQuote = quotations.filter(quote => quote.quoteRef === action.quoteRef);
      if (isRetrievingOwnQuote && Array.isArray(isRetrievingOwnQuote) && isRetrievingOwnQuote.length) {
        yield put(gotoExternalUrl(`/my-account/quotations/${isRetrievingOwnQuote[0]._id}`));
      } else {
        yield put(goToUrl(`/global-quotation/${action.quoteRef}`, false, true));
      }
    } else {
      yield put(goToUrl(`/global-quotation/${action.quoteRef}`, false, true));
    }
    yield put(isQuoteRefValid(true));
  } catch (e) {
    yield put(isQuoteRefValid(false, action.quoteRef));
    console.error('No quote ref found :', e); // eslint-disable-line no-console
  }
}

export function * updateGlobalQuoteName (action) {
  try {
    yield call(updateGlobalQuoteNameEndpoint, action.name, action.quoteRef);
    yield put(quoteNameWasUpdated(true));
  } catch (e) {
    console.error('Error updating quote name:', e); // eslint-disable-line no-console
  }
}

export function * saveQuoteToMyList (action) {
  try {
    const savedQuote = yield call(createQuotationEndpoint, action.name, action.products);
    yield call(lockGlobalQuotationEndPoint, savedQuote._id);
    yield put(hideSavingQuoteDialog());
    yield put(gotoExternalUrl('/my-account/quotations'));
  } catch (e) {
    console.error('Save quote to my list:', e); // eslint-disable-line no-console
  }
}

export function * requestInitGlobalQuotationDetails (action) {
  yield put(fetchGlobalQuotationByIdOrQuoteRefAction(action.quoteRefOrId, action.redirectToGlobalQuotation, action.eligibleForDeliveryOptions));
  yield take(STORE_GLOBAL_QUOTATION);
  const payload = { eligibleForDeliveryOptions: action.eligibleForDeliveryOptions };
  yield put(changeDeliveryOptionFromGlobalQuotation(payload, action.redirectToGlobalQuotation, action.quoteRefOrId));
}

export function * requestChangeDeliveryOptionFromGlobalQuotation ({ payload: { optionType, eligibleForDeliveryOptions }, redirectToGlobalQuotation, quotationId }) {
  yield put(updatingOptionLoader(true));

  const quotationProductSelector = (state) => state.getIn(['globalQuotation', 'createdQuotation', 'products'], []);
  const quotationProducts = (yield select(quotationProductSelector)) || [];
  const products = quotationProducts.reduce((products, orderLine) => {
    const optionList = orderLine.getIn(['deliveryOptionList', 'optionList'], fromJS([]));
    if (optionList.size) {
      products.push({
        sku: orderLine.get('sku'),
        qty: 0,
        selectedDeliveryOption: optionType || orderLine.getIn(['deliveryOptionList', 'selectedDeliveryOption'])
      });
    }
    return products;
  }, []);
  try {
    yield call(addProductsToQuotationEndpoint, quotationId, products);
  } catch (e) {
    yield put(updatingOptionLoader(false));
    yield put(addToQuotationError());
  }
  yield put(fetchGlobalQuotationByIdOrQuoteRefAction(quotationId, redirectToGlobalQuotation, eligibleForDeliveryOptions));
  yield take(STORE_GLOBAL_QUOTATION);
  yield put(toggleDeliveryOptionAccordion(''));
  yield put(updatingOptionLoader(false));
}

export function * watchFetchGlobalQuotation () {
  yield takeLatest(FETCH_GLOBAL_QUOTATION, fetchGlobalQuotation);
}

export function * watchFetchGlobalQuotationById () {
  yield takeLatest(FETCH_GLOBAL_QUOTATION_BY_ID_OR_QUOTE_REF, fetchGlobalQuotationByIdOrQuoteRef);
}

export function * watchGetProductBySku () {
  yield takeLatest(GET_PRODUCT_BY_SKU, getProductBySku);
}

export function * watchUpdatingQtyGlobalQuotation () {
  yield takeLatest(GLOBAL_QUOTATION_QTY_CHANGE, requestChangeInGlobalQuoteLineQty);
}

export function * watchRemoveProductFromGlobalQuotationConfirm () {
  yield takeLatest(REMOVE_PRODUCT_FROM_GLOBAL_QUOTATION_CONFIRM, removeProductFromGlobalQuotationConfirm);
}

export function * watchAddToQuote () {
  yield takeLatest(ADD_TO_QUOTE, addToQuoteOrCreateQuote);
}

export function * watchLockGlobalQuotation () {
  yield takeLatest(LOCK_GLOBAL_QUOTATION, lockGlobalQuotation);
}

export function * watchRetrieveGlobalQuotation () {
  yield takeLatest(RETRIEVE_GLOBAL_QUOTATION, retrieveGlobalQuotation);
}

export function * watchMergGlobalQuotes () {
  yield takeLatest(MERGE_GLOBAL_QUOTATION, mergeGlobalQuotes);
}

export function * watchUpdateGlobalQuoteName () {
  yield takeLatest(UPDATE_GLOBAL_QUOTE_NAME, updateGlobalQuoteName);
}

export function * watchInitGlobalQuotationsDetails () {
  yield takeLatest(INIT_GLOBAL_QUOTATIONS_DETAILS, requestInitGlobalQuotationDetails);
}

export function * watchChangeDeliveryOptionFromGlobalQuotation () {
  yield takeLatest(CHANGE_DELIVERY_OPTION_FROM_GLOBAL_QUOTATION, requestChangeDeliveryOptionFromGlobalQuotation);
}
export function * watchSaveQuoteToMyList () {
  yield takeLatest(SAVE_QUOTE_TO_MY_LIST, saveQuoteToMyList);
}
