import { fork, all, takeLatest } from 'redux-saga/effects';
import { watchFetchAddress, watchFetchFullAddress, watchPostCodeZone } from './postcodeFinderSagas';
import { watchRequestFamilyStockInfo } from './productFamilySagas';
import { watchRoutes } from './routeChangeSagas';
import {
  watchSaveAddress,
  watchUpdateAddress,
  watchSetDefaultAddress,
  watchChangeAddressFromAddressBook,
  watchDeleteAddress,
  watchVerifyAddresses,
  watchRequestGetUserAddresses,
  watchGetUserAddress
} from './addressSagas';
import { watchSearch } from './searchSagas';
import { watchFetchSuggestions, watchTermSelected, watchTermHovered, watchSearchBarUpdateAqTerm, watchRecentSearches } from './searchBarSagas';
import { ON_SCREEN_READY } from '../actions/routeActions';
import { watchLoadFamiliesByCategory, watchStoreFamiliesByCategory } from './categoryFamiliesSagas';
import { watchForgotPasswordScreen } from './forgotPasswordSagas';
import { watchResetPasswordScreen } from './resetPasswordSagas';
import {
  watchLoadAccountDetails,
  watchUpdateMarketingPreferences,
  watchReloadAccountDetails
} from './accountSagas';
import {
  watchRequestOrdersRaw,
  watchRequestMyOrders,
  watchRequestAccountOrders,
  watchFetchOrder,
  watchOrderConfirmationFetch,
  watchAddOrderToCart,
  watchSubmitCancellationRequest, watchPostOrder, watchGetReturnReplaceOrder
} from './ordersSagas';
import { watchToggleMyOrder } from './orderSummarySagas';
import { watchLoadCategory, watchLoadAllCategory } from './categorySagas';
import { watchLoginSuccess, watchRequestLogin, watchDoesUserExist, watchShowLoginDialog } from './loginSagas';
import { watchUserReport } from './reportErrorOrQuestionSagas';
import { watchUserRegistration, watchUserNewRegistration, watchUserAndAccountRegistration, watchSaveUserTelephone } from './userRegistrationSaga';
import { watchUserEnquiry } from './userContactUsSaga';
import { watchUpdateDataLayer } from './dataLayerSagas';
import { watchCartChanges } from './cartSagas';
import { watchLoadPublicHolidaysRequest } from './publicHolidaysSagas.js';
import { watchDiscountCodeChanges, watchCheckDiscountCodeDialog, watchApplyDiscountCodeToExistingCart } from './discountCodeSagas';
import { watchCookieActions } from './frontendApiSagas';
import { watchEmailLinkClicked } from './emailSagas';
import { watchPersonalInfoAccess } from './accessLogSagas';
import {
  watchRefreshAccountUserList,
  watchInviteToAccount,
  watchResendAccountInvitation,
  watchUpdateAccountUserDetails,
  watchRefreshUserData,
  watchTradeAccountUsersInvitations,
  watchupdateUserVAT
} from './myAccountUsersSagas';
import {
  watchRecommendedLinkClicked
} from './productVariantSagas';
import { watchRequestServiceReviews, watchRequestAverageServiceRating } from './feefoReviewsSagas';
import { watchLogAction } from './logActionSagas';
import { watchSaleExpiry } from './saleSagas';
import { watchSessionCamDataLayer } from './sessionCamSagas';
import { watchCmsSagas } from './cmsSagas';
import { watchAllQuotationsActions } from './quotationsSagas';
import { watchAllTokenSagas } from './tokenSaga';
import { watchTryUnsubscribe } from './unsubscribeSaga';
import { watchTryVerifyEmail } from './verifyEmailSaga';
import { watchCreditAccountRegistration, watchValdiateBusinessAccountOrder } from './creditAccountSagas';
import { watchToggleAllCategoryPage } from './headerSagas';
import { watchGetIndustriesMap } from './industriesPageScreenSagas';
import { watchApiCallButtonActions } from './apiCallButtonSagas';
import { watchNotificationSuccess, watchNotificationWarn, watchNotificationError, watchNotificationInfo } from './showNotificationsSagas';
import {
  watchUpdatingQtyGlobalQuotation,
  watchRemoveProductFromGlobalQuotationConfirm,
  watchFetchGlobalQuotationById,
  watchFetchGlobalQuotation,
  watchGetProductBySku,
  watchLockGlobalQuotation,
  watchRetrieveGlobalQuotation,
  watchMergGlobalQuotes,
  watchAddToQuote,
  watchUpdateGlobalQuoteName,
  watchInitGlobalQuotationsDetails,
  watchChangeDeliveryOptionFromGlobalQuotation,
  watchSaveQuoteToMyList
} from './globalQuotationSagas';
import { watchAllRepeatOrdersActions } from './repeatOrdersSaga';
import { watchAllStockActions } from './getStockMessageSaga';
import { watchOrderReviewChanges } from './orderReviewSagas';
import { watchAllBraintreeSagas } from './braintreeSagas';
import { watchAllBraintreeGatewaySagas } from './braintreeGatewaySagas';
import { watchScrollToTop } from './scrollSagas';
import { watchAllCheckoutSagas } from './checkoutSagas';
import { watchAllMySavedCardsScreenSagas } from './mySavedCardsSagas';
import { watchAllBraintreeCvvCheckSagas } from './braintreeCvvCheckSagas';
import { watchAllDynatraceSagas } from './dynatraceSagas';
import { watchAllPayByExistingCardSagas } from '../components/elements/paymentMethod/PayByExistingCard/payByExistingCardSaga';
import { watchAllPayByNewCardSagas } from '../components/elements/paymentMethod/PayByNewCard/payByNewCardSaga';
import { watchAllPayByPaypalSagas } from '../components/elements/paymentMethod/PayByPaypal/payByPaypalSaga';
import { watchAllPayByApplePaySagas } from '../components/elements/paymentMethod/PayByApplePay/payByApplePaySaga';
import { watchAllPayByGooglePaySagas } from '../components/elements/paymentMethod/PayByGooglePay/payByGooglePaySaga';
import { watchAllPaymentMethodSagas } from '../components/elements/paymentMethod/PaymentMethod/paymentMethodSaga';
import { watchAllRequestCreditLimitSagas } from '../components/elements/paymentMethod/RequestCreditLimit/requestCreditLimitSaga';
import { watchAllPayByAccountSagas } from '../components/elements/paymentMethod/PayByAccount/payByAccountSaga';
import { watchReturnReplacement } from './returnReplacementSagas';
import { watchCopyTextToClipboard } from './copyTextToClipboardSagas';
import { watchUnleashOverrideValuesSuccess } from './unleashSaga';
import { watchAllPayBySkippingPaymentMethod } from 'client/components/elements/paymentMethod/PayBySkippingMethod/sagas';
import { watchScheduledOrders } from 'client/sagas/scheduledOrders';

const callbacks = [];

export function onScreenReady (callback) {
  callbacks.push(callback);
}

function * watchScreenReady () {
  yield takeLatest(ON_SCREEN_READY, () => {
    callbacks.forEach((cb) => { cb(); });
  });
}

export default function * root () {
  // These are functions, in order to not call fork() unnecessarily
  const oldFamilySagaForks = () => all([
    fork(watchRequestFamilyStockInfo)
  ]);

  const familySagaForks = [
    oldFamilySagaForks()
  ];

  yield all([
    fork(watchFetchAddress),
    fork(watchPostCodeZone),
    fork(watchLoadPublicHolidaysRequest),
    fork(watchFetchFullAddress),
    fork(watchVerifyAddresses),
    fork(watchRequestGetUserAddresses),
    fork(watchRoutes),
    fork(watchSaveAddress),
    fork(watchUpdateAddress),
    fork(watchSetDefaultAddress),
    fork(watchChangeAddressFromAddressBook),
    fork(watchDeleteAddress),
    fork(watchSearch),
    fork(watchScreenReady),
    fork(watchAllTokenSagas),
    fork(watchFetchSuggestions),
    fork(watchSearchBarUpdateAqTerm),
    fork(watchRecentSearches),
    fork(watchTermSelected),
    fork(watchLoadFamiliesByCategory),
    fork(watchStoreFamiliesByCategory),
    fork(watchForgotPasswordScreen),
    fork(watchResetPasswordScreen),
    fork(watchPostOrder),
    fork(watchRequestOrdersRaw),
    fork(watchRequestMyOrders),
    fork(watchRequestAccountOrders),
    fork(watchFetchOrder),
    fork(watchGetReturnReplaceOrder),
    fork(watchOrderConfirmationFetch),
    fork(watchAddOrderToCart),
    fork(watchSubmitCancellationRequest),
    fork(watchLogAction),
    fork(watchRefreshAccountUserList),
    fork(watchTradeAccountUsersInvitations),
    fork(watchInviteToAccount),
    fork(watchResendAccountInvitation),
    fork(watchUpdateAccountUserDetails),
    fork(watchRefreshUserData),
    fork(watchLoadAccountDetails),
    fork(watchReloadAccountDetails),
    fork(watchUpdateMarketingPreferences),
    fork(watchLoginSuccess),
    fork(watchShowLoginDialog),
    fork(watchDoesUserExist),
    fork(watchRequestLogin),
    fork(watchPersonalInfoAccess),
    fork(watchUserReport),
    fork(watchUserRegistration),
    fork(watchUserNewRegistration),
    fork(watchUserAndAccountRegistration),
    fork(watchSaveUserTelephone),
    fork(watchUserEnquiry),
    fork(watchLoadCategory),
    fork(watchUpdateDataLayer),
    fork(watchCartChanges),
    fork(watchScheduledOrders),
    fork(watchDiscountCodeChanges),
    fork(watchCheckDiscountCodeDialog),
    fork(watchApplyDiscountCodeToExistingCart),
    fork(watchEmailLinkClicked),
    fork(watchRecommendedLinkClicked),
    fork(watchRequestServiceReviews),
    fork(watchRequestAverageServiceRating),
    fork(watchCookieActions),
    fork(watchSaleExpiry),
    fork(watchSessionCamDataLayer),
    fork(watchToggleMyOrder),
    fork(watchCmsSagas),
    fork(watchAllQuotationsActions),
    fork(watchTryUnsubscribe),
    fork(watchTryVerifyEmail),
    fork(watchCreditAccountRegistration),
    fork(watchToggleAllCategoryPage),
    fork(watchTermHovered),
    fork(watchScrollToTop),
    fork(watchNotificationSuccess),
    fork(watchNotificationError),
    fork(watchNotificationWarn),
    fork(watchNotificationInfo),
    fork(watchValdiateBusinessAccountOrder),
    fork(watchAllRepeatOrdersActions),
    fork(watchGetIndustriesMap),
    fork(watchApiCallButtonActions),
    fork(watchFetchGlobalQuotationById),
    fork(watchFetchGlobalQuotation),
    fork(watchRemoveProductFromGlobalQuotationConfirm),
    fork(watchGetProductBySku),
    fork(watchUpdatingQtyGlobalQuotation),
    fork(watchAddToQuote),
    fork(watchLockGlobalQuotation),
    fork(watchRetrieveGlobalQuotation),
    fork(watchMergGlobalQuotes),
    fork(watchAllStockActions),
    fork(watchOrderReviewChanges),
    fork(watchUpdateGlobalQuoteName),
    fork(watchInitGlobalQuotationsDetails),
    fork(watchChangeDeliveryOptionFromGlobalQuotation),
    fork(watchupdateUserVAT),
    fork(watchSaveQuoteToMyList),
    ...familySagaForks,
    fork(watchAllBraintreeSagas),
    fork(watchAllBraintreeGatewaySagas),
    fork(watchAllPayByExistingCardSagas),
    fork(watchAllCheckoutSagas),
    fork(watchAllMySavedCardsScreenSagas),
    fork(watchAllBraintreeCvvCheckSagas),
    fork(watchAllDynatraceSagas),
    fork(watchGetUserAddress),
    fork(watchAllPayByNewCardSagas),
    fork(watchAllPayByPaypalSagas),
    fork(watchAllPayByApplePaySagas),
    fork(watchAllPayByGooglePaySagas),
    fork(watchAllPaymentMethodSagas),
    fork(watchAllRequestCreditLimitSagas),
    fork(watchAllPayByAccountSagas),
    fork(watchAllPayBySkippingPaymentMethod),
    fork(watchReturnReplacement),
    fork(watchCopyTextToClipboard),
    fork(watchUnleashOverrideValuesSuccess),
    fork(watchLoadAllCategory)
  ]);
}
