import React from 'react';
import { getLocalizedString } from 'localization/localizer';
if (process.browser) {
  require('./address.scss');
}

const showPhoneNumber = (address) => {
  const phoneNumber = address.addressPhoneNumber
    ? address.addressPhoneNumber
    : address.number;
  if (!phoneNumber) {
    return null;
  }
  return (
    <div className="Address_deliveryAddress_phoneNumber">
      <div className="Address_deliveryAddress_phoneNumber_title">{getLocalizedString('singlePageCheckout.phoneNumber')} </div>
      <div className="Address_deliveryAddress_phoneNumber_value">{phoneNumber}</div>
    </div>
  );
};

export const Address = ({ address }) => {
  if (!address) {
    return <></>;
  }
  return (
    <div className="Address">
      <div className="Address_address row">
        <div className="col-md-6 Address_address_deliveryAddress">
          <div className="Address_deliveryAddress_name">{ address.firstName} { address.lastName }</div>
          <div className="Address_deliveryAddress_companyName">{address.companyName}</div>
          <div className="Address_deliveryAddress_addressLine1">{address.addressLine1}</div>
          <div className="Address_deliveryAddress_addressLine2">{address.addressLine2}</div>
          <div className="Address_deliveryAddress_state">{address.state}</div>
          <div className="Address_deliveryAddress_city">{address.city}</div>
          <div className="Address_deliveryAddress_postalCode">{address.postalCode}</div>
          { showPhoneNumber(address) }
        </div>
      </div>
    </div>
  );
};
