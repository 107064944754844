'use strict';

import { fromJS, Map, List } from 'immutable';
import {
  CREATE_RETURN_OR_REPLACEMENT,
  SELECT_UPDATE_ITEM_SELECTION,
  SET_VERIFY,
  RESET_SELECTION,
  INIT_RETURN_FILE_UPLOAD,
  INIT_DELETE_RETURN_FILE,
  ON_FILE_UPLOAD_ERROR,
  UPDATE_MY_RETURNS_STATE,
  FETCH_MY_RETURNS,
  ON_MY_RETURNS_SUCESS,
  ON_SUCCESS_CONFIRMED_RETURNS,
  SUBMIT_RETURN_FORM
} from '../actions/returnsOrReplacementActions';
import { DEFAULT_SORTBY, DEFAULT_PAGESIZE, RETURN_AND_REPLACEMENT, ALL_DAYS } from 'shared/constants/returnRefundConstants';

export const defaultState = fromJS({
  order: {},
  selectedReturnItems: [],
  isSummary: false,
  myReturnsState: {
    sortField: DEFAULT_SORTBY,
    pageSize: DEFAULT_PAGESIZE,
    pageNumber: 1,
    expanded: {},
    returnStatus: RETURN_AND_REPLACEMENT,
    dateRange: ALL_DAYS,
    searchInput: '',
    defaultSelectedOrder: null
  },
  myReturns: {},
  returnsConfirmationDetails: {},
  isLoading: true
});

const getValuesFromState = (state, payload) => {
  const existingSelectedItems = state.get('selectedReturnItems').toJS();
  const { sku, imageList, imageKey } = payload;
  const existingItem = existingSelectedItems.find((item) => item.sku === sku);
  const { imageObjectList = [] } = existingItem ? existingItem : {};
  const filteredList = existingSelectedItems.filter((item) => item.sku !== sku);
  return { imageObjectList, imageList, imageKey, existingItem, sku, filteredList };
};

const returnsOrReplacementReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_RETURN_OR_REPLACEMENT:
      return state.set('order', fromJS(action.order));
    case SELECT_UPDATE_ITEM_SELECTION: {
      const { existingItem, filteredList } = getValuesFromState(state, action.payload);
      const returnItem = existingItem ? { ...existingItem, ...action.payload } : action.payload;
      return state.set('selectedReturnItems', fromJS([...filteredList, returnItem]));
    }
    case SET_VERIFY:
      return state.set('isSummary', action.payload);
    case ON_SUCCESS_CONFIRMED_RETURNS:
      return state.set('returnsConfirmationDetails', fromJS(action.payload)).set('selectedReturnItems', List());
    case RESET_SELECTION:
      return state.set('selectedReturnItems', List())
        .set('isSummary', false)
        .set('order', Map());
    case INIT_DELETE_RETURN_FILE: {
      const { imageObjectList, imageKey, existingItem, filteredList } = getValuesFromState(state, action.payload);
      const filteredImageList = imageObjectList.filter((img) => img.Key !== imageKey);
      const updateItemObject = { ...existingItem, imageObjectList: filteredImageList };
      return state.set('selectedReturnItems', fromJS([...filteredList, updateItemObject]));
    }
    case INIT_RETURN_FILE_UPLOAD: {
      const { imageObjectList, imageList, existingItem, filteredList } = getValuesFromState(state, action.payload);
      const returnItem = existingItem ? { ...existingItem, imageObjectList: [...imageObjectList, ...imageList] } : { ...action.payload, imageObjectList: imageList };
      return state.set('selectedReturnItems', fromJS([...filteredList, returnItem]));
    }
    case ON_FILE_UPLOAD_ERROR: {
      const { imageObjectList, imageList, existingItem, filteredList } = getValuesFromState(state, action.payload);
      const filteredImageList = imageObjectList.filter((img) => !imageList.filter((newImg) => newImg.Key === img.Key).length);
      const returnItem = existingItem ? { ...existingItem, imageObjectList: filteredImageList } : action.payload;
      return state.set('selectedReturnItems', fromJS([...filteredList, returnItem]));
    }
    case UPDATE_MY_RETURNS_STATE: {
      const myReturnsState = state.get('myReturnsState').toJS();
      const updated = { ...myReturnsState, ...action.payload };
      return state.set('myReturnsState', fromJS(updated));
    }
    case FETCH_MY_RETURNS: {
      return state.setIn(['myReturns', 'isLoading'], true);
    }
    case ON_MY_RETURNS_SUCESS: {
      return state.set('myReturns', fromJS(action.payload)).set('isLoading', false);
    }
    case SUBMIT_RETURN_FORM: {
      return state.set('isSummary', true).set('selectedReturnItems', fromJS(action.payload));
    }
    default:
      return state;
  }
};

export default returnsOrReplacementReducer;
