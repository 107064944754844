/* eslint-disable camelcase */
import { hasTradeAccount } from 'shared/endpoints/accountEndpoint';
import { registerCreditAccount } from 'shared/endpoints/creditAccountEndpoint';
import { registerUser, inviteToAccount } from 'shared/endpoints/userEndpoint';
import {
  YES,
  STANDARD,
  CREDIT,
  CHARITY,
  INVITED,
  MONTHLY,
  USER,
  TRADE_ACCOUNT,
  MAYBE_LATER
} from '../constants';
import {
  COMPANY_TYPES_REQUIRING_REG_NO
} from 'client/utils/companyDataUtils';
import { validateCompanyRegNo } from 'client/utils/validators';

const trimSpaces = (text) => {
  return text ? text.replace(/\s/g, '') : text;
};

export const getMappedBusinessPayload = (
  primaryContactForm,
  financeContactForm,
  creditLineApplicationForm
) => {
  const {
    firstName,
    lastName,
    jobTitle = '',
    phoneNumber,
    email,
    companyName = '',
    openCreditLine = MAYBE_LATER,
    companyRegNo: primaryCompanyRegNo
  } = primaryContactForm;

  const {
    sameAsPrimaryContact,
    sameAsRegisteredAddress = true,
    firstName: invoiceFirstName,
    lastName: invoiceLastName,
    phoneNumber: invoicePhoneNumber,
    email: invoiceEmail,
    invoiceCompanyName,
    primary_addressLine1: invoiceAddressLine1,
    primary_city: invoiceCity,
    primary_postalCode: invoicePostalCode,
    primary_state: invoiceState,
    primary_addressLine2: invoiceAddressLine2
  } = financeContactForm;

  const {
    companyType,
    vatRegNo,
    companyRegNo,
    charityRegNo,
    vatRegistered,
    primaryIndustry,
    noOfStaff,
    businessPhoneNumber,
    businessWebsite,
    primary_addressLine1,
    primary_city,
    primary_postalCode,
    primary_state,
    primary_addressLine2,
    financeContact = MAYBE_LATER,
    expectedMonthlySpend
  } = creditLineApplicationForm;
  const isSameInvoiceDetails = financeContact === MAYBE_LATER || sameAsPrimaryContact;
  const trimCompanyRegNo = trimSpaces(companyRegNo);
  const isValidCompanyRegNo = validateCompanyRegNo(trimCompanyRegNo) && COMPANY_TYPES_REQUIRING_REG_NO.filter(el => el !== CHARITY).includes(companyType);

  return {
    accountType: openCreditLine === MAYBE_LATER ? STANDARD : CREDIT,
    companyDetails: {
      companyName: companyName?.trim(),
      phoneNumber: phoneNumber?.trim(),
      ...(primaryCompanyRegNo ? { companyRegNo: trimSpaces(primaryCompanyRegNo) } : {}),
      ...(openCreditLine === YES
        ? {
          ...(expectedMonthlySpend || expectedMonthlySpend === '0' ? { expectedMonthlySpend: Number(expectedMonthlySpend) } : {}),
          companyType: companyType,
          ...(vatRegistered ? { vatRegNo: trimSpaces(vatRegNo) } : {}),
          ...(companyType === CHARITY ? { charityRegNo: trimSpaces(charityRegNo) } : {}),
          primaryIndustry: primaryIndustry,
          primaryFunctionStaffCount: noOfStaff,
          mobileNumber: businessPhoneNumber?.trim(),
          website: businessWebsite?.trim(),
          ...(isValidCompanyRegNo ? { companyRegNo: trimCompanyRegNo } : {})
        } : {})
    },
    primaryContact: {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      jobTitle: jobTitle?.trim(),
      number: phoneNumber?.trim(),
      email: email?.trim(),
      companyName: companyName?.trim(),
      ...(openCreditLine === YES ? {
        addressLine1: primary_addressLine1?.trim(),
        addressLine2: primary_addressLine2?.trim(),
        city: primary_city?.trim(),
        state: primary_state?.trim(),
        postalCode: primary_postalCode?.trim()
      } : {})
    },
    invoiceContact: {
      firstName: (isSameInvoiceDetails ? firstName : invoiceFirstName)?.trim(),
      lastName: (isSameInvoiceDetails ? lastName : invoiceLastName)?.trim(),
      number: (isSameInvoiceDetails ? phoneNumber : invoicePhoneNumber)?.trim(),
      email: (isSameInvoiceDetails ? email : invoiceEmail)?.trim(),
      companyName: (sameAsRegisteredAddress ? companyName : invoiceCompanyName)?.trim(),
      ...(openCreditLine === YES ? {
        addressLine1: (sameAsRegisteredAddress ? primary_addressLine1 : invoiceAddressLine1)?.trim(),
        addressLine2: (sameAsRegisteredAddress ? primary_addressLine2 : invoiceAddressLine2)?.trim(),
        city: (sameAsRegisteredAddress ? primary_city : invoiceCity)?.trim(),
        state: (sameAsRegisteredAddress ? primary_state : invoiceState)?.trim(),
        postalCode: (sameAsRegisteredAddress ? primary_postalCode : invoicePostalCode)?.trim()
      } : {})
    }
  };
};

export const checkForExistingTradeAccounts = async (emails) => {
  const promises = await emails.map(async (value) => {
    const check = await hasTradeAccount(value);
    return {
      hasValidTradeAccount: check.hasValidTradeAccount,
      email: value
    };
  });

  const results = await Promise.all(promises);
  const validTradeAccount = results.filter((result) => result.hasValidTradeAccount);

  if (validTradeAccount.length >= 1) {
    const emails = validTradeAccount.map((tradeAccount) => {
      return tradeAccount.email;
    }).join();
    const message = `${emails} already a part of another trade account.`;
    throw new Error(message);
  }
};

export const createAccountForIndividuals = (data) => {
  const {
    userType,
    title,
    firstName,
    lastName,
    password,
    email,
    phoneNumber,
    csAgentName = '',
    marketingConsent,
    companyName = ''
  } = data;
  const payload = {
    userType,
    title,
    password,
    email,
    firstName,
    lastName,
    phoneNumber,
    csAgentName,
    companyName,
    marketingConsent: !!marketingConsent
  };
  return registerUser(payload);
};

export const createAccountForBusiness = ({
  primaryContactForm,
  financeContactForm,
  creditLineApplicationForm = {},
  csAgentName
}) => {
  const mappedData = getMappedBusinessPayload(
    primaryContactForm,
    financeContactForm,
    creditLineApplicationForm
  );

  return registerCreditAccount({
    ...mappedData,
    ...(csAgentName ? { csAgentName } : {})
  });
};

export const inviteUsersToTradeAccount = async (inviteMembers, customerId, primaryContactDetailsForm) => {
  const isOpenCreditLine = primaryContactDetailsForm?.openCreditLine === YES;

  for (const user of inviteMembers) {
    const { message, email, spendLimit, role } = user;
    const history = {
      actionDate: new Date(),
      action: INVITED,
      createdBy: customerId,
      actionFor: email
    };
    const newUser = {
      accountRole: role === STANDARD ? USER : role,
      limit: spendLimit === 'Account Limit' ? !isOpenCreditLine ? null : '' : spendLimit,
      shortMessage: message,
      limitType: spendLimit && spendLimit !== 'Account Limit' ? MONTHLY : TRADE_ACCOUNT,
      emailAddress: email,
      history
    };
    await inviteToAccount(newUser);
  }
};
