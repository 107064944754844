import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./SubscriptionDetails.scss');
}

export const SubscriptionBanner = ({ text, icon, subtext }) => {
  return <div className="SubscriptionBanner">
    <div className={ `SubscriptionBanner_leftEdge SubscriptionBanner_edgeColour-${icon}` }/>
    <div className={ `SubscriptionBanner_rightEdge SubscriptionBanner_edgeColour-${icon}` }/>
    <div className='SubscriptionBanner_title'>
      <div className="SubscriptionBanner_details">
        <SvgLoader xlinkHref={ icon } title="banner-icon" className={ `SubscriptionBanner_details-${icon}` } />
        <div className="SubscriptionBanner_details-text">{text}</div>
      </div>
    </div>
    { subtext ? <div className="SubscriptionBanner_subtext">{subtext}</div> : null }
  </div>;
};
