import Immutable from 'immutable';
import {
  SUCCESS,
  ERROR
} from 'shared/constants/loadStateType';
import omit from 'lodash/omit';
import { ACCOUNT_ROLES } from 'shared/constants/account';

const filtersToBeOmit = ['query', 'fetchProductIds', 'categoryId', 'familyId', 'price', 'skip', 'limit', 'sort', 'rating', 'industryId', 'brand'];

export function returnActionPayloadWithLoadStateTypeSuccess (defaultState) {
  return (state = defaultState, action) => {
    // If no action.payload is passed in pass back the initial state
    return Immutable
      .fromJS(action && action.payload ? action.payload : {})
      .setIn(['loadState', 'type'], SUCCESS);
  };
}

export function returnCurrentStateWithLoadStateTypeError (defaultState) {
  return (state = defaultState, action) => {
    // If no state is passed in return the initial state which, due to
    return state
      .setIn(['loadState', 'type'], ERROR);
  };
}

const filterBrand = filters => filters.find(attr => attr.name === 'Brand');
const filterRating = filters => filters.find(attr => attr.name === 'Rating');
const filterAttibutes = (filters, attrKey) => filters.find(attrFilter => attrFilter.name === attrKey);

const chooseBrand = (brand, filterList) => {
  const brandFilter = filterBrand(filterList);
  return brandFilter && brandFilter.attributeValues.length && brandFilter.attributeValues.find(attr => attr.value === brand);
};

const selectBrand = (brand, existingFilterTags, filters) => {
  if (filters && filters.length) {
    const selectedFilter = chooseBrand(brand, filters);
    if (selectedFilter) return selectedFilter;
  }
  if (existingFilterTags && existingFilterTags.length) {
    const selectedFilter = chooseBrand(brand, existingFilterTags);
    if (selectedFilter) return selectedFilter;
  }
  return { brand,
    value: brand ? brand : 'Unknown',
    numberOfMatchingProducts: 0,
    filterName: 'Brand',
    urlFilter: { brand },
    filterIndex: 0,
    attrIndex: 0
  };
};

const chooseRating = (ratingValue, filterList) => {
  const ratingFilter = filterRating(filterList);
  return ratingFilter && ratingFilter.attributeValues.length && ratingFilter.attributeValues.find(attr => attr.value === ratingValue);
};

const selectRating = (ratingValue, existingFilterTags, filters) => {
  if (filters && filters.length) {
    const selectedFilter = chooseRating(ratingValue, filters);
    if (selectedFilter) return selectedFilter;
  }
  if (existingFilterTags && existingFilterTags.length) {
    const selectedFilter = chooseRating(ratingValue, existingFilterTags);
    if (selectedFilter) return selectedFilter;
  }
  return {
    value: ratingValue,
    numberOfMatchingProducts: 0,
    filterName: 'Rating',
    urlFilter: { rating: ratingValue },
    filterIndex: 1,
    attrIndex: 0 };
};

const chooseAttribute = (attrValue, attrKey, filterList) => {
  const filteredAttibutes = filterAttibutes(filterList, attrKey);
  return filteredAttibutes && filteredAttibutes.attributeValues.length && filteredAttibutes.attributeValues.find(attr => attr.value === attrValue);
};

const selectAttribute = (attrValue, existingFilterTags, filters, attrKey) => {
  if (filters && filters.length) {
    const selectedFilter = chooseAttribute(attrValue, attrKey, filters);
    if (selectedFilter) return selectedFilter;
  }
  if (existingFilterTags && existingFilterTags.length) {
    const selectedFilter = chooseAttribute(attrValue, attrKey, existingFilterTags);
    if (selectedFilter) return selectedFilter;
  }
  const urlFilter = {};
  urlFilter[attrKey] = attrValue;
  return {
    value: attrValue,
    numberOfMatchingProducts: 0,
    filterName: attrKey ? attrKey : 'Unknown',
    attributeName: attrKey,
    urlFilter,
    filterIndex: 3,
    attrIndex: 0
  };
};

export const getFilterTags = (filters, existingFilterTags, appliedFilter) => {
  const filterTags = [];
  const isFilter = filters && filters.length;
  if (appliedFilter.brand && appliedFilter.brand.length) {
    const brandFilter = isFilter && filterBrand(filters)
      ? filterBrand(filters)
      : { name: 'Brand',
        isGlobalFilter: true,
        filterKey: 'brandName',
        valueKey: 'value' };
    const selectedBrands = appliedFilter.brand.map(brand => {
      return selectBrand(brand, existingFilterTags, filters);
    });
    brandFilter.attributeValues = selectedBrands;
    filterTags.push(brandFilter);
  }
  if (appliedFilter.rating && appliedFilter.rating.length) {
    const ratingFilter = isFilter && filterRating(filters)
      ? filterRating(filters)
      : { name: 'Rating',
        isGlobalFilter: true,
        filterKey: 'rating',
        valueKey: 'value' };
    const selectedRatings = appliedFilter.rating.map(ratingValue => {
      return selectRating(ratingValue, existingFilterTags, filters);
    });
    ratingFilter.attributeValues = selectedRatings;
    filterTags.push(ratingFilter);
  }
  const appliedFilterAttributes = omit(appliedFilter, filtersToBeOmit);
  const attributeKeys = Object.keys(appliedFilterAttributes);
  const attributeFilterList = attributeKeys.map(attrKey => {
    if (appliedFilter[attrKey] && appliedFilter[attrKey].length) {
      const attributeFilter = isFilter && filterAttibutes(filters, attrKey)
        ? filterAttibutes(filters, attrKey)
        : { name: 'UnknownAttr',
          keyIsAttrName: true,
          filterKey: attrKey,
          valueKey: 'value' };

      const selectedAttributes = appliedFilter[attrKey].map(attrValue => {
        return selectAttribute(attrValue, existingFilterTags, filters, attrKey);
      });
      attributeFilter.attributeValues = selectedAttributes;
      return attributeFilter;
    }
  });
  return filterTags.concat(attributeFilterList);
};

const maxMessageLength = 1000;
export const validateFields = (field, value, creditLimit) => {
  const validator = {
    'monthlyLimit': (value) => value > 0,
    'shortMessage': (value) => !(value.length > maxMessageLength),
    'accountRole': (value) => ACCOUNT_ROLES.includes(value)
  };
  return field !== 'email' ? validator[field](value, creditLimit) : true;
};

export const checkEmailFieldValidity = (emailList) => {
  const re = /^\S+@\S+$/;
  const areEmailsValid = emailList.every(email => re.test(email));
  if (!areEmailsValid) {
    return false;
  }

  return true;
};

export const getAccountUsersList = (usersList, currentUser, key, value) => {
  currentUser[key] = value;
  const newUsersList = usersList.toJS();
  const userIndex = newUsersList.findIndex((user) => user.email === currentUser.email);
  newUsersList[userIndex] = currentUser;
  return newUsersList;
};

export const removeEmailDuplicates = (arr, email) => {
  const duplicateEmailIndex = arr.findIndex((user) => user.colleaguesEmail === email);
  if (duplicateEmailIndex !== -1) {
    arr.splice(duplicateEmailIndex, 1);
  }
  return arr;
};

export const areEmailsDuplicated = (emailsList) => {
  const emailSet = new Set(emailsList.map(el => el.toLowerCase()));
  return emailSet.size !== emailsList.length;
};
