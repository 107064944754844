import React from 'react';
import SvgLoader from '../../svg/SvgLoader';

if (process.browser) {
  require('./AgeRestrictedRemoveMessage.scss');
}

/**
 * AgeRestrictedRemoveMessage component displays a warning message
 * regarding age restricted products in the order.
 *
 * @param {Object} props - The component props.
 * @param {'change-address'} props.message - The message type to display.
 */
export const AgeRestrictedRemoveMessage = ({ message }) => {
  return <div className="AgeRestrictedRemoveMessage">
    <SvgLoader className="AgeRestrictedRemoveMessage_warningIcon" xlinkHref="warning"/>
    {message === 'change-address' && 'Please remove age restricted products or select alternative delivery address.'}
    {!message && 'Your order contains age restricted products. Please remove them to place order.'}
  </div>;
};
