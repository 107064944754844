import { createAction } from 'redux-actions';

// export const SKIP_PAYMENT_FLOW = 'SKIP_PAYMENT_FLOW';
// export const skipPaymentFlow = createAction(SKIP_PAYMENT_FLOW);

export const SET_ACTIVE_PANEL_ID = 'SET_ACTIVE_PANEL_ID';
export const setActivePanelId = createAction(SET_ACTIVE_PANEL_ID);

export const SCROLL_TO_CHECKOUT_ACCORDION_SECTION = 'SCROLL_TO_CHECKOUT_ACCORDION_SECTION';
export const scrollToCheckoutSection = createAction(SCROLL_TO_CHECKOUT_ACCORDION_SECTION);

export const RESET_SCROLLS = 'RESET_SCROLLS';
export const resetScrolls = createAction(RESET_SCROLLS);

export const VIEW_ADDRESS_BOOK_TOGGLE = 'VIEW_ADDRESS_BOOK_TOGGLE';
export const viewAddressBookToggle = createAction(VIEW_ADDRESS_BOOK_TOGGLE);

export const DISPLAY_ADDRESS_TOGGLE = 'DISPLAY_ADDRESS_TOGGLE';
export const displayAddressToggle = createAction(DISPLAY_ADDRESS_TOGGLE);

export const NEW_DELIVERY_ADDRESS_TOGGLE = 'NEW_DELIVERY_ADDRESS_TOGGLE';
export const newDeliveryAddressToggle = createAction(NEW_DELIVERY_ADDRESS_TOGGLE);

export const CHANGE_BILLING_ADDRESS_TOGGLE = 'CHANGE_BILLING_ADDRESS_TOGGLE';
export const changeBillingAddressToggle = createAction(CHANGE_BILLING_ADDRESS_TOGGLE);

export const DELIVERY_ACCORDION_LOADING = 'DELIVERY_ACCORDION_LOADING';
export const deliveryAccordionLoading = createAction(DELIVERY_ACCORDION_LOADING);

export const SET_PAYMENT_ACCORDION_STATE = 'SET_PAYMENT_ACCORDION_STATE';
export const setPaymentAccordionState = createAction(SET_PAYMENT_ACCORDION_STATE);

export const SET_DELIVERY_ADDRESS_ACCORDION_STATE = 'SET_DELIVERY_ADDRESS_ACCORDION_STATE';
export const setDeliveryAddressAccordionState = createAction(SET_DELIVERY_ADDRESS_ACCORDION_STATE);

export const SET_BILLING_ADDRESS_ACCORDION_STATE = 'SET_BILLING_ADDRESS_ACCORDION_STATE';
export const setBillingAddressAccordionState = createAction(SET_BILLING_ADDRESS_ACCORDION_STATE);

export const TOGGLE_APPLY_DISCOUNT_CODE = 'cartActions.TOGGLE_APPLY_DISCOUNT_CODE';
export const toggleApplyDiscountCode = createAction(TOGGLE_APPLY_DISCOUNT_CODE);

export const TOGGLE_ACCORDION_STATE = 'TOGGLE_ACCORDION_STATE';
export const toggleAccordionState = createAction(TOGGLE_ACCORDION_STATE);

export const RESET_ALL_SINGLE_PAGE_CHECKOUT_STATUS = 'RESET_ALL_SINGLE_PAGE_CHECKOUT_STATUS';
export const resetAllSinglePageCheckoutStatus = createAction(RESET_ALL_SINGLE_PAGE_CHECKOUT_STATUS);

export const TOGGLE_PLACE_ORDER_BUTTON = 'TOGGLE_PLACE_ORDER_BUTTON';
export const togglePlaceOrderButton = createAction(TOGGLE_PLACE_ORDER_BUTTON);

export const DELIVERY_ADDRESS_CHANGED = 'DELIVERY_ADDRESS_UPDATED';
export const deliveryAddressChanged = createAction(DELIVERY_ADDRESS_CHANGED);
