import React from 'react';

import { getLocalizedString } from 'localization/localizer';
import CmsShoveler from './cmsShoveler';
import CmsBrandsTile from './cmsBrandsTile';

export default (props) =>
  <CmsShoveler
    TileComponent={ CmsBrandsTile }
    searchPlaceHolder={ getLocalizedString('shoveler.brands.searchPlaceholder') }
    heading={ getLocalizedString('shoveler.brands.heading') }
    ifEmptyCustomClassName="CmsShoveler_Brands_NoResults"
    { ...props }
  />;
