export const getViewIdBasedOnLoyaltyTier = (userTier = '0') => {
  const loyaltyTiers = {
    '0': 'no_tier',
    '1': 'silver',
    '2': 'gold',
    '3': 'platinum'
  };

  return loyaltyTiers[userTier];
};
