
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  RECEIVE_RECOMMENDATIONS_STOCK_INFO
} from 'client/actions/recommendationsActions';

import {
  RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS
} from 'client/actions/productVariantActions';

import {
  GET_STOCK_MESSAGES_SUCCESS
} from 'client/actions/getStockMessageActions';

import {
  RECEIVE_CAT_FAMILY_STOCK_INFO
} from 'client/actions/categoryFamiliesActions';

import {
  STORE_FAMILIES_BY_CATEGORY
} from 'client/actions/categoryFamiliesActions';

import {
  FAMILY_RECOMMENDATIONS_SUCCESS
} from 'client/actions/recommendationsActions';

import {
  SUCCESS_SKUS_DATA
} from 'client/actions/cmsActions';

import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _pick from 'lodash/pick';

export const defaultState = fromJS({});

const stockMessagesReducerMap = {
  [GET_STOCK_MESSAGES_SUCCESS]: (state = defaultState, action) => {
    return state.set(action.payload.sku, fromJS({ ...action.payload.productStockInfo.stockMessage }));
  },
  [RECEIVE_RECOMMENDATIONS_STOCK_INFO]: (state = defaultState, action) => {
    return state.set(action.payload.stockInfo.sku, fromJS({ ...action.payload.stockInfo.stockMessage }));
  },
  [RECEIVE_CAT_FAMILY_STOCK_INFO]: (state = defaultState, action) => {
    return state.set(action.payload.stockInfo.sku, fromJS({ ...action.payload.stockInfo.stockMessage }));
  },
  [RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS]: (state = defaultState, action) => {
    const payload = action.payload.toJS();
    return state.set(payload.sku, fromJS({ ...payload.stock.stockMessage }));
  },
  [STORE_FAMILIES_BY_CATEGORY]: (state = defaultState, action) => {
    const { families } = action;
    const productsInfoArray = _map(families.toJS(), family => _pick(family, ['productsInfo']));
    const filteredProductsInfo = productsInfoArray.filter(item => item.productsInfo && item.productsInfo[0] && item.productsInfo[0].sku);
    const data = _map(filteredProductsInfo, item => {
      return {
        sku: item.productsInfo[0].sku,
        stockMessage: item.productsInfo[0].stock.stockMessage
      };
    });

    const newState = data.reduce((state, current) => {
      return {
        ...state,
        [current.sku]: current.stockMessage
      };
    }, state.toJS());

    return fromJS(newState);
  },
  [FAMILY_RECOMMENDATIONS_SUCCESS]: (state = defaultState, action) => {
    const recommendations = action.payload;

    const recommendationsProducts = recommendations.map(recommendation => {
      return recommendation.results.filter(item => item.sku);
    });

    const flattenedRecommendationsProducts = _flatten(recommendationsProducts);

    const data = flattenedRecommendationsProducts.map(item => {
      return {
        sku: item.sku,
        stockMessage: item.stock.stockMessage
      };
    });

    const newState = data.reduce((state, current) => {
      return {
        ...state,
        [current.sku]: current.stockMessage
      };
    }, state.toJS());

    return fromJS(newState);
  },
  [SUCCESS_SKUS_DATA]: (state = defaultState, action) => {
    const data = action.results.map(item => {
      return {
        sku: item.sku,
        stockMessage: item.stock.stockMessage
      };
    });

    const newState = data.reduce((state, current) => {
      return {
        ...state,
        [current.sku]: current.stockMessage
      };
    }, state.toJS());

    return fromJS(newState);
  }
};

export default handleActions(stockMessagesReducerMap, defaultState);
