import { createAction } from 'redux-actions';

export const REQUEST_APPLY_DISCOUNT_CODE = 'cartActions.REQUEST_APPLY_DISCOUNT_CODE';
export const REQUEST_REMOVE_DISCOUNT_CODE = 'cartActions.REQUEST_REMOVE_DISCOUNT_CODE';

export const applyDiscountCode = createAction(REQUEST_APPLY_DISCOUNT_CODE);
export const removeDiscountCode = createAction(REQUEST_REMOVE_DISCOUNT_CODE);

export const APPLY_DISCOUNT_CODE_TO_EXISTING_CART = 'cartActions.APPLY_DISCOUNT_CODE_TO_EXISTING_CART';
export const applyDiscountCodeToExistingCart = (discountCode) => {
  return {
    type: APPLY_DISCOUNT_CODE_TO_EXISTING_CART,
    discountCode
  };
};

export const APPLYING_DISCOUNT_CODE = 'APPLYING_DISCOUNT_CODE';
export const applyingDiscountCode = (flag) => {
  return {
    type: APPLYING_DISCOUNT_CODE,
    flag
  };
};
