import React, { Fragment } from 'react';
import { PrimaryButton, SecondaryLink } from 'client/components/controls/StyledForms';

import { getLocalizedString } from 'localization/localizer';
import { AddressBlock } from 'client/components/screens/checkoutScreen/element/addressBlock/AddressBlock';
import {
  ADDRESSBOOK_GRID_VIEW,
  ADDRESSBOOK_LIST_VIEW
} from 'shared/constants/singlePageCheckout';

if (process.browser) {
  require('./MyAddressBook.scss');
}

const getCustomerAddresses = (addressList, selectedDeliveryAddressId) => {
  return [
    addressList.find(l => l.get('addressId') === selectedDeliveryAddressId),
    ...addressList.filter(l => l.get('addressId') !== selectedDeliveryAddressId)
  ];
};

export const MyAddressBook = ({
  addressList, addressBookViewType, defaultDeliveryId, selectedAddressIdForDelivery,
  onSelectNewAddress, onConfirmDeliveryAddress, onBackToDeliveryPanel, handleAddNewAddressClick,
  onEditDeliveryAddressClick, changeAddressBookViewType, isValidBAWithAdminRole, loggedInUserCustomerId
}) => {
  const customerAddresses = addressList ? getCustomerAddresses(addressList, defaultDeliveryId) : [];
  return (
    <Fragment>
      <div className="AddressBookScreen_header">
        <span className="AddressBookScreen_header_title">{getLocalizedString('singlePageCheckout.addessBook.yourAddressBook')}</span>
        <span className={ `AddressBookScreen_header_toggleAddressBookView_${addressBookViewType === ADDRESSBOOK_GRID_VIEW ? 'gridView' : 'listView'}` }
          onClick={ () => changeAddressBookViewType(
            addressBookViewType === ADDRESSBOOK_GRID_VIEW ? ADDRESSBOOK_LIST_VIEW : ADDRESSBOOK_GRID_VIEW
          ) }>
          {
            addressBookViewType === ADDRESSBOOK_GRID_VIEW
              ? getLocalizedString(`singlePageCheckout.addessBook.${ADDRESSBOOK_LIST_VIEW}`)
              : getLocalizedString(`singlePageCheckout.addessBook.${ADDRESSBOOK_GRID_VIEW}`)
          }
        </span>
      </div>
      <div className="AddressBookScreen_AddressBooksWrapper row">
        {
          customerAddresses.map((address, index) => {
            const addressId = address.get('addressId');
            const isLoggedInUserAddress = address.get('customerId') === loggedInUserCustomerId;
            const showEditAction = isLoggedInUserAddress || isValidBAWithAdminRole;
            return (
              <AddressBlock
                key={ addressId }
                address = { address }
                defaultDeliveryId = { defaultDeliveryId }
                selectedAddressIdForDelivery = { selectedAddressIdForDelivery }
                addressBookViewType = { addressBookViewType }
                onSelectNewAddress = { onSelectNewAddress }
                onEditDeliveryAddressClick = { onEditDeliveryAddressClick }
                showEditAction = { showEditAction }
                index = { index } />
            );
          })
        }
      </div>

      <div className="AddressBookScreen_ButtonsCta row">
        <SecondaryLink className="AddressBookScreen_ButtonsCta_backToDeliveryPanel col-xs-12" text={ getLocalizedString('singlePageCheckout.addessBook.goBackToDeliveryPanel') } onClick={ () => onBackToDeliveryPanel() } />
        <SecondaryLink className="AddressBookScreen_ButtonsCta_addNewAddress col-xs-12" text={ getLocalizedString('singlePageCheckout.addessBook.addNewAddress') } onClick={ () => handleAddNewAddressClick() } />
        <PrimaryButton className="AddressBookScreen_ButtonsCta_useThisAddress col-xs-12" onClick={ () => onConfirmDeliveryAddress(selectedAddressIdForDelivery) } text={ getLocalizedString('singlePageCheckout.addessBook.useThisAddress') } />
      </div>
    </Fragment>
  );
};
