import { List } from 'immutable';
import _get from 'lodash/get';
import moment from 'moment';
import { ORDER_REVIEW_ACCORDION_PANEL_ID, PAYMENT_ACCORDION_PANEL_ID, DELIVERY_ADDRESS_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';

export function getOrderShippingDate (order) {
  return order.get('deliveryBy') ? moment(order.get('deliveryBy')).format('YYYYMMDD') : '';
}

export function pushTransactionDetails (order) {
  if (order.get('orderStatus') !== 'payment_authorised' && order.get('orderStatus') !== 'credit_new') {
    return;
  }

  const event = {
    ecommerce: {
      purchase: {
        actionField: {
          id: order.get('merlinOrderReference'), // Transaction ID. Required for purchases and refunds.
          affiliation: 'Cromwell B2C',
          revenue: order.getIn(['orderPrice', 'orderGrandTotalWithVat']), // Total transaction value (incl. tax and shipping)
          tax: order.getIn(['orderPrice', 'orderGrandTotalVat']),
          shipping: order.getIn(['orderPrice', 'totalShippingPriceWithVat']),
          coupon: order.getIn(['discount', 'code'], '')
        },
        products: order.get('orderLines').map((orderLine) => ({ // List of productFieldObjects.
          name: orderLine.get('name', ''),
          id: orderLine.get('sku', ''),
          price: orderLine.getIn(['orderLinePrice', 'unitPriceWithVat']),
          brand: orderLine.getIn(['brand', 'brandName'], ''),
          category: orderLine.get('breadcrumb', ''),
          quantity: orderLine.get('quantity'),
          coupon: order.getIn(['discount', 'code'], '')
        })).toJS(),
        bundles: order.get('bundles', List()).map((bundle) => ({ // List of bundles.
          name: bundle.get('name', ''),
          message: bundle.get('message', ''),
          id: bundle.get('sku', ''),
          bundleFamilyId: bundle.get('bundleFamilyId', ''),
          price: bundle.getIn(['bundlePrice', 'bundlePriceWithVat']),
          quantity: bundle.get('bundleQuantity')
        })).toJS()
      }
    }
  };

  dataLayer.push(event); // eslint-disable-line

  dataLayer.push({  // eslint-disable-line
    event: 'checkout_success',
    checkout_success: 'true',
    shipping_date: getOrderShippingDate(order)
  });
}

export function pushGeneralData (details) {
  if (!details) {
    throw Error('googleTagManagerUtils pushGeneralData(details), details was undefined. ');
  }
  dataLayer.push(details); // eslint-disable-line
}

export function removeEcommereKeys () {
  dataLayer.forEach((item, key) => {
    if (item.ecommerce) {
      delete dataLayer[key];
    }
  });
}

export const pushViewCart = (cart) => {
  const currency = cart.currency || 'GBP';
  const dataLayerEvent = {
    event: 'view_cart',
    currency,
    value: cart.cartPrice.cartGrandTotalWithVat,
    items: cart.orderLines.map((orderLine, index) => {
      const discountAmount = Number(orderLine.orderLinePrice.beforeDiscountUnitPriceWithVat) - Number(orderLine.orderLinePrice.unitPriceWithVat);
      const categoryBreadcrumb = orderLine.categoryBreadcrumb.map((category) => category || '');
      const [
        itemCategory = '',
        itemCategory2 = '',
        itemCategory3 = '',
        itemCategory4 = '',
        itemCategory5 = ''
      ] = categoryBreadcrumb;

      return {
        item_id: orderLine.sku,
        item_name: orderLine.name,
        affiliation: 'Cromwell B2C',
        coupon: cart.discount ? cart.discount.code : '',
        discount: Math.round(discountAmount * 100) / 100,
        index: index,
        item_brand: orderLine.brand.brandName,
        price: orderLine.orderLinePrice.unitPriceWithVat,
        quantity: orderLine.quantity,
        currency,
        item_category: itemCategory,
        item_category2: itemCategory2,
        item_category3: itemCategory3,
        item_category4: itemCategory4,
        item_category5: itemCategory5
        // item_list_id: 'related_products',
        // item_list_name: 'Related Products',
        // item_variant: 'green',
        // location_id: 'L_12345'
      };
    })
  };

  dataLayer.push(dataLayerEvent);
};

export const getCheckoutAnalyticsEvent = (orderLines) => {
  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        products: orderLines.map(getMappedCheckoutProduct)
      }
    }
  };
};

const getMappedCheckoutProduct = (orderLine) => {
  return {
    name: _get(orderLine, 'name', ''),
    id: _get(orderLine, 'sku', ''),
    price: _get(orderLine, ['orderLinePrice', 'unitPriceWithVat'], ''),
    brand: _get(orderLine, ['brand', 'brandName'], ''),
    category: _get(orderLine, 'categoryBreadcrumb', []).join(' > '),
    quantity: _get(orderLine, 'quantity', '')
  };
};

export const getCheckoutOptionAnalyticsEvent = (panelId, paymentMethod) => {
  return {
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          option: getPanelInfo(panelId, paymentMethod)
        }
      }
    }
  };
};

const getPanelInfo = (panelId, paymentMethod = '') => {
  const panelNames = {
    [DELIVERY_ADDRESS_ACCORDION_PANEL_ID]: 'delivery details',
    [PAYMENT_ACCORDION_PANEL_ID]: 'payment method',
    [ORDER_REVIEW_ACCORDION_PANEL_ID]: `order review / ${paymentMethod}`
  };
  return panelNames[panelId] || '';
};
