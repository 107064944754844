import React from 'react';
import { FAQHeaderLinks } from './FAQHeaderLinks';
import SvgLoader from 'client/components/svg/SvgLoader';

const CmsFAQHeader = ({ pageUrl }) => (
  <>
    { FAQHeaderLinks.map((item) => {
      return (<a href={ item.url } key={ item.iconName } className={ pageUrl === item.pageName ? 'selected' : '' }>
        <SvgLoader xlinkHref={ item.iconName }/>
      </a>);
    })
    }
  </>
);
export default CmsFAQHeader;
