import React from 'react';
import { getLocalizedString } from 'localization/localizer';

export const RecommendationPriceWithMultiplier = ({
  isQtyMultiplier,
  symbol,
  productPrice,
  qtyMultiplier,
  location
}) =>
  (
    <div className={ `${(location && isQtyMultiplier) ? 'RecommendationTile_PriceWithMultiplier' : 'RecommendationTile_Vertical_PriceWithMultiplier'} ` }>
      {(location && isQtyMultiplier) ? `x ${qtyMultiplier}` : isQtyMultiplier ? `${symbol}${productPrice} ${getLocalizedString('cart.eachPackOf')}${qtyMultiplier}` : null }
    </div>
  );
