import { ApiRequest } from 'shared/utils/apiUtils';

export function fetchVertical (cmsQueryParams) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/verticals',
    query: cmsQueryParams
  });
  return req.execute();
}
