import { put, takeLatest, call } from 'redux-saga/effects';
import { change } from 'redux-form';
import { requestAddressSuccess,
  receiveAddressError,
  toggleEnterManually,
  onPostalCodeZoneSuccess,
  REQUEST_ADDRESS_WITH_POSTCODE,
  REQUEST_FULL_ADDRESS,
  REQUEST_POST_CODE_CHANGE
} from 'client/actions/ui/postCodeActions';
import compact from 'lodash/compact';
import { getFullAddressWithPostcodeId, getPostcodeDelvZone } from 'shared/endpoints/postcodeEndpoint';
import { splitAddressToTwoAddressLines } from 'client/utils/validators';
import { delay } from 'shared/utils/sagaUtils';

export function * getPostCodeZone (action) {
  const postCodeZone = yield call(getPostcodeDelvZone, action.postcode);
  yield put(onPostalCodeZoneSuccess(action.selector, postCodeZone));
}

export function * fetchAddress (action) {
  try {
    yield call(delay, 400);
    const address = yield call(getFullAddressWithPostcodeId, action.postcode);
    yield put(requestAddressSuccess(action.selector, address));
  } catch (e) {
    yield put(receiveAddressError(action.selector, e));
  }
}

export const distributeAddress = (line1, line2, line3) => {
  let addressLine1 = compact([ line1, line2]).join(', ');
  let addressLine2 = line3;
  if (addressLine1.length > 30) {
    addressLine1 = line1;
    addressLine2 = compact([ line2, line3]).join(', ');
  }
  return [
    addressLine1,
    addressLine2
  ];
};

export function * fetchFullAddress (action) {
  try {
    const { address, postcode, city, county = null, company = null } = action.postcodeid;
    const { addressLine1, addressLine2 } = splitAddressToTwoAddressLines(address);
    const fieldNames = {
      companyName: 'companyName',
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      state: 'state',
      city: 'city',
      postalCode: 'postalCode'
    };
    if (action.fieldNamePrefix) {
      fieldNames.companyName = action.fieldNamePrefix + fieldNames.companyName;
      fieldNames.addressLine1 = action.fieldNamePrefix + fieldNames.addressLine1;
      fieldNames.addressLine2 = action.fieldNamePrefix + fieldNames.addressLine2;
      fieldNames.state = action.fieldNamePrefix + fieldNames.state;
      fieldNames.city = action.fieldNamePrefix + fieldNames.city;
      fieldNames.postalCode = action.fieldNamePrefix + fieldNames.postalCode;
    }
    yield put(change(action.form, fieldNames.companyName, company));
    yield put(change(action.form, fieldNames.addressLine1, addressLine1));
    yield put(change(action.form, fieldNames.addressLine2, addressLine2));
    yield put(change(action.form, fieldNames.state, county));
    yield put(change(action.form, fieldNames.city, city));
    yield put(change(action.form, fieldNames.postalCode, postcode));
    yield put(toggleEnterManually(action.selector, true));
  } catch (e) {
    // do something on failure
  }
}

export function * watchFetchAddress () {
  yield takeLatest(REQUEST_ADDRESS_WITH_POSTCODE, fetchAddress);
}

export function * watchFetchFullAddress () {
  yield takeLatest(REQUEST_FULL_ADDRESS, fetchFullAddress);
}

export function * watchPostCodeZone () {
  yield takeLatest(REQUEST_POST_CODE_CHANGE, getPostCodeZone);
}
