import React from 'react';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import ErrorOutputScreen from 'client/components/screens/errorOutputScreen/ErrorOutputScreen';
import { pushGeneralData, removeEcommereKeys as removeDLEcommereKeys } from 'client/utils/googleTagManagerUtils';
import { getViewIdBasedOnLoyaltyTier } from 'client/utils/viewIdUtils';
import { validateToken } from 'client/actions/tokenActions';
import { ApiRequest } from 'shared/utils/apiUtils';
import { setCookieCJEvent, sendCJEventToDataLayer, updateOneTrustConsent, setCookieAWC, sendAWCToDataLayer, getCookieUserPreferences } from 'client/actions/ui/cookieActions';
import { getUnleashUserPreferences } from 'client/actions/ui/unleashActions';
import { loadUserPreference, reloadAccountDetails } from '../actions/accountActions';
import { getPerformanceCookiesConsentClient } from 'shared/utils/cookiesUtils';

let prevUrl = null;
function resetCategoryTree () {
  pushGeneralData({
    'event': 'category_tree',
    'category_tree': ''
  });
}

function render (routes, store, callback) {
  let firstLoad = true;
  // NOTE: using old style closures looks a bit weird but seems to be the only way to access
  // a reference to the router since fat arrow functions have the scope of the surrounding
  // block or instance.
  const onUpdate = function () {
    window.scrollTo(0, 0);

    const { hash = '' } = window.location;
    const currentUrl = this.state.location.pathname + this.state.location.search;
    /*
      This solution was sourced from here: https://github.com/rafrex/react-router-hash-link/tree/react-router-v2/3
      which is also the official solution on the react router site.
    */
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.

      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }

    if (firstLoad) {
      firstLoad = false;
    } else if (currentUrl !== prevUrl) {
      // ^ do not trigger event if it's a page refresh (it happens when page is A/B tested)
      try {
        pushGeneralData({ 'event': 'virtual_page_load' });
        const loyaltyDiscountTier = store.getState().getIn(['user', 'accountDetails', 'loyaltyDiscount', 'loyaltyTier'], '0');
        const viewId = getViewIdBasedOnLoyaltyTier(loyaltyDiscountTier);
        pushGeneralData({ 'event': 'loyalty_tier', view_id: viewId });

        const webSiteUrl = store.getState().getIn(['config', 'websiteUrl'], '');
        const prevCompleteUrl = `${webSiteUrl}${prevUrl ? prevUrl : ''}`;
        pushGeneralData({ 'event': 'referrer', prevCompleteUrl });

        resetCategoryTree();
        removeDLEcommereKeys();
      } catch (err) {
        console.error(err.message); // eslint-disable-line no-console
        return -1;
      }
    }

    const { query } = this.state.location;
    if (query.cjevent) {
      store.dispatch(setCookieCJEvent(query.cjevent));
    } else {
      store.dispatch(sendCJEventToDataLayer());
    }
    if (query.awc) {
      store.dispatch(setCookieAWC(query.awc));
    } else {
      store.dispatch(sendAWCToDataLayer());
    }
    store.dispatch(reloadAccountDetails());
    store.dispatch(loadUserPreference());
    store.dispatch(getCookieUserPreferences());
    store.dispatch(getUnleashUserPreferences());
    prevUrl = currentUrl;
    store.dispatch({
      type: 'UPDATE_ROUTE_PARAMS',
      params: { ...this.state.params, ...(query || {}) }
    });
  };

  window.OneTrust?.OnConsentChanged && window.OneTrust.OnConsentChanged(function () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = urlSearchParams ? Object.fromEntries(urlSearchParams.entries()) : {};
    store.dispatch(updateOneTrustConsent(params));
  });

  const output = (<Provider store={ store }>
    <Router
      history={ browserHistory }
      onUpdate={ onUpdate }
      routes={ routes }
    />
  </Provider>);
  callback(null, output);
}

const logoutListener = (event) => { // logout all tabs in one browser
  if (event.key === 'logout-event') {
    window.localStorage.removeItem('logout-event');
    window.removeEventListener('storage', logoutListener);
    window.location.href = '/';
  }
};

export function createClientApp (routes, store, callback) {
  const serverSideLoadError = store.getState().get('serverSideLoadError');

  window.addEventListener('storage', logoutListener, false);
  ApiRequest.apiHost = store.getState().getIn(['config', 'apiUrl']);

  // Needs to be a function since its going to be a function in the server.
  ApiRequest.token = () => store.getState().getIn(['auth', 'jwtToken']);
  ApiRequest.longSessionToken = () => store.getState().getIn(['auth', 'longSessionJwtToken'], null);
  const loyaltyDiscountTier = store.getState().getIn(['user', 'accountDetails', 'loyaltyDiscount', 'loyaltyTier'], '0');
  const viewId = getViewIdBasedOnLoyaltyTier(loyaltyDiscountTier);
  pushGeneralData({ 'event': 'loyalty_tier', view_id: viewId });
  const isOnBrowser = typeof window !== 'undefined';
  if (isOnBrowser) {
    if (typeof window.dtrum !== 'undefined' && window.dtrum) {
      if (getPerformanceCookiesConsentClient()) {
        window.dtrum.enable();
      } else {
        window.dtrum.disable();
      }
    }
  }
  if (serverSideLoadError) {
    if (serverSideLoadError.get('status') === 404 &&
      serverSideLoadError.getIn(['response', 'req', 'url']).includes('/users/me')) {
      window.location.href = '/login';
    }
    const output = (
      <ErrorOutputScreen
        title={ getLocalizedString('errorOutputScreen.serverLoadError.title') }
        subtitle={ getLocalizedString('errorOutputScreen.serverLoadError.subtitle') }
        body={ serverSideLoadError.get('message') }
      />
    );
    callback(null, output);
  } else {
    store.dispatch(validateToken());
    render(routes, store, callback);
  }
}
