export const TOGGLE_DROPDOWN_MENU = 'TOGGLE_DROPDOWN_MENU';
export const TOGGLE_ALL_CATEGORY_PAGE = 'TOGGLE_ALL_CATEGORY_PAGE';
export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';

export const toggleDropdownMenu = (visibleDropdown) => ({
  type: TOGGLE_DROPDOWN_MENU,
  visibleDropdown
});

export const toggleAllCategoryPage = (location) => ({
  type: TOGGLE_ALL_CATEGORY_PAGE,
  location
});

export const setPreviousLocation = (location) => ({
  type: SET_PREVIOUS_LOCATION,
  location
});
