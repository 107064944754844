import { fromJS } from 'immutable';
import {
  ORDER_DETAILS_TOGGLE
} from 'client/actions/ui/orderSummaryActions';
import {
  INITIALIZE_COMPONENT_STATE,
  REMOVE_COMPONENT_STATE
} from 'shared/utils/localState';

export const defaultState = fromJS({});

const filterPanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INITIALIZE_COMPONENT_STATE:
      return state.set(action.selector, fromJS({
        open: false
      }));
    case REMOVE_COMPONENT_STATE:
      return state.remove(action.selector);
    case ORDER_DETAILS_TOGGLE:
      return fromJS(Object.keys(state.toObject()).reduce((acc, value) => {
        acc[value] = {
          open: (value === action.payload.selector)
            ? !state.getIn([action.payload.selector, 'open'])
            : false
        };
        return acc;
      }, {}));
    default:
      return state;
  }
};

export default filterPanelReducer;
