import React from 'react';
import { connect } from 'react-redux';
import CreateAddressDetailsScreen from './CreateAddressDetailsScreen';
import { saveAddress, getUserAddresses, updateAddress, editAddress } from 'client/actions/addressActions';
import { isUserUpdateBillingDetails } from 'client/actions/userActions';
import flowRight from 'lodash/flowRight';
import { fromJS } from 'immutable';

function mapStateToProps (state, ownProps) {
  const query = ownProps.location && ownProps.location.query;
  return {
    accountDetails: state.getIn(['user', 'accountDetails']),
    addresses: state.getIn(['user', 'address', 'deliveryAddresses', 'data']),
    billingAddress: state.getIn(['user', 'address', 'billing'], fromJS({})),
    payOnAccount: query && query.onAccount === 'y',
    fromQuotationId: query && query.fromQuotationId,
    editAddress: state.getIn(['ui', 'editAddress']),
    calledFrom: ownProps.calledFrom ? ownProps.calledFrom : state.getIn(['user', 'address', 'calledFrom'])
  };
}

export function mapDispatchToProps (dispatch) {
  return {
    getUserAddresses: flowRight(dispatch, getUserAddresses),
    onIsUpdateBillingDetails: () => dispatch(isUserUpdateBillingDetails()),
    onEnterDeliveryAddress: (address, payOnAccount, fromQuotationId, redirectToUrl) => {
      dispatch(saveAddress({ address: address, addressType: 'delivery', payOnAccount, fromQuotationId, redirectToUrl }));
    },
    onEditDeliveryAddress: (address, payOnAccount, fromQuotationId, redirectToUrl) => {
      dispatch(updateAddress({ address: address, addressType: 'delivery', payOnAccount, fromQuotationId, redirectToUrl }));
    },
    onEnterBillingAddress: (address, payOnAccount, fromQuotationId, redirectToUrl) => {
      dispatch(saveAddress({ address: address, addressType: 'billing', fromQuotationId, redirectToUrl }));
    },
    editAddressAction: flowRight(dispatch, editAddress)
  };
}

class CreateAddressDetailsScreenInjectionWrapper extends React.Component {
  render () {
    return <CreateAddressDetailsScreen { ...this.props } />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CreateAddressDetailsScreenInjectionWrapper
);
