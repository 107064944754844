import React, { useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import flowRight from 'lodash/flowRight';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import { SecondaryButton, PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import { PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD, PENDING_UNVERIFIED_MESSAGE } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { CHECKOUT_SELECTOR, PAYMENT_ACCORDION_PANEL_ID, SINGLE_PAGE_PAYMENT } from 'shared/constants/singlePageCheckout';
import { hideBusinessAccountOnHoldDialog } from 'client/actions/ui/dialogActions';
import { payByAccount } from 'client/components/elements/paymentMethod/PayByAccount/payByAccountActions';
import { setApiButtonStatus } from 'client/actions/ui/apiCallButtonActions';
import { SUBMITTING } from 'shared/constants/apiButtonConstants';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { getVariant, prepareUnleashOverridePayload } from '../../../utils/unleashUtils';
import { UNLEASH_SHOW_ONHOLD_MODAL, SHOW } from 'shared/constants/abTesting';
import { getAccountStatusMessage } from '../../../utils/getAccountStatusMessage';

if (process.browser) {
  require('./BusinessAccountOnHoldDialog.scss');
}

const BusinessAccountOnHoldDialog = (props) => {
  const {
    isVisible,
    hideBusinessAccountOnHoldDialog,
    hasExistingCards,
    changePaymentMethod,
    location,
    updateDataLayer,
    payByAccount,
    setApiButtonStatus,
    setActivePanelId,
    showOnHoldModal,
    accountRole,
    accountAdminEmail,
    accountStatusMessageKey,
    tradeAccountStatus
  } = props;

  const accountStatusMessage = useMemo(() => {
    return getAccountStatusMessage(accountStatusMessageKey, hideBusinessAccountOnHoldDialog, accountRole, accountAdminEmail, tradeAccountStatus);
  }, [accountStatusMessageKey, hideBusinessAccountOnHoldDialog, accountRole, accountAdminEmail, tradeAccountStatus]);
  const isPendingOrUnverifiedAccount = PENDING_UNVERIFIED_MESSAGE.includes(accountStatusMessage?.heading);

  useEffect(() => {
    if (location.pathname.startsWith('/checkout') && isVisible && accountStatusMessage?.heading) {
      updateDataLayer({
        event: 'checkout_modal',
        type: (accountStatusMessage.heading).toLowerCase().split(' ').join('_')
      });
    }
  }, [isVisible]);

  return (
    <Modal className="BusinessAccountOnHoldDialog"
      show={ isVisible && ((isPendingOrUnverifiedAccount && showOnHoldModal) || !isPendingOrUnverifiedAccount) }>
      <Modal.Header>
        <h2 className="BusinessAccountOnHoldDialog_headerText">{ accountStatusMessage?.heading || '' }</h2>
      </Modal.Header>
      <Modal.Body>
        <div data-e2e="warning-alert" className="BusinessAccountOnHoldDialog_bodyText">
          <div className="BusinessAccountOnHoldDialog_bodyText-firstLine">{ accountStatusMessage?.content || ''}</div>
          <br />
          <div className="row">
            <SecondaryButton onClick={
              () => {
                changePaymentMethod({
                  selector: CHECKOUT_SELECTOR,
                  paymentMethod: hasExistingCards ? PAY_BY_EXISTING_CARD : PAY_BY_NEW_CARD
                });
                hideBusinessAccountOnHoldDialog();
                setActivePanelId({ panelId: PAYMENT_ACCORDION_PANEL_ID });
              }
            } text={ getLocalizedString('cart.buttons.payByCard') } className="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-2 BusinessAccountOnHoldDialog_payByCard" />
            <PrimaryLink onClick={ () => {
              changePaymentMethod({
                selector: CHECKOUT_SELECTOR,
                paymentMethod: PAY_BY_ACCOUNT
              });
              hideBusinessAccountOnHoldDialog();
              setApiButtonStatus(CHECKOUT_SELECTOR, SUBMITTING);
              setApiButtonStatus(SINGLE_PAGE_PAYMENT, SUBMITTING);
              payByAccount({ selector: CHECKOUT_SELECTOR });
            }
            } text={ getLocalizedString('cart.buttons.payOnAccount') } className="col-xs-10 col-xs-offset-1 col-md-4 col-sm-offset-0 BusinessAccountOnHoldDialog_payOnAccount" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const unleashToggles = state.getIn(['unleash', 'toggles'], List()).toJS();
  const { location } = ownProps;
  const query = location.query ? location.query : null;
  const overrideVariantFromQuery = prepareUnleashOverridePayload(query);
  const unleashOnHoldModal = getVariant(unleashToggles, UNLEASH_SHOW_ONHOLD_MODAL, '', overrideVariantFromQuery);
  const showOnHoldModal = unleashOnHoldModal === SHOW;
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'businessAccountOnHold', 'isVisible']),
    hasExistingCards: state.getIn(['braintree', 'cards'], List()).toJS().length !== 0,
    payload: state.getIn(['ui', 'dialogs', 'orderPayload', 'payload']),
    showOnHoldModal,
    accountStatusMessageKey: state.getIn(['cart', 'tradeAccountButton', 'accountStatusMessageKey'], ''),
    accountRole: state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'accountRole'], ''),
    accountAdminEmail: state.getIn(['user', 'accountDetails', 'tradeAccount', 'primaryContact', 'email'], ''),
    tradeAccountStatus: state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'status'], '')
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePaymentMethod: flowRight(dispatch, changePaymentMethod),
  hideBusinessAccountOnHoldDialog: flowRight(dispatch, hideBusinessAccountOnHoldDialog),
  payByAccount: flowRight(dispatch, payByAccount),
  setApiButtonStatus: flowRight(dispatch, setApiButtonStatus),
  setActivePanelId: flowRight(dispatch, setActivePanelId)
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessAccountOnHoldDialog);
export { BusinessAccountOnHoldDialog };
