import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { getLocalizedString } from 'localization/localizer';
import { formatPrice } from 'client/utils/priceUtils';
import BasketItemsDetails from 'client/components/elements/basketItemsDetails/BasketItemsDetails';

if (process.browser) {
  require('./CartTotalizer.scss');
}

const TotalRow = ({ label, rawValue, value, className, e2e }) => (
  <Row className={ className }>
    <Col xs={ 8 }>
      { label }
    </Col>
    <Col xs={ 4 } className="text-right" data-e2e={ e2e }>
      <div key={ rawValue }>{ value }</div>
    </Col>
  </Row>
);

TotalRow.propTypes = {
  className: PropTypes.string,
  e2e: PropTypes.string,
  label: PropTypes.string.isRequired,
  rawValue: PropTypes.number,
  value: PropTypes.string.isRequired
};

const CartTotalizer = ({ togglePlaceOrderButton, isSinglePageCheckout, openOrderReviewPanel, totalizedValues, orderLines, currency, isLoggedIn, includeVat, excludeVatToggle, cta, title, hideTotalSavings, makePaymentProps, payOnAccountTerms }) => {
  const {
    deliveryExVat, deliveryIncVat, discountWithVat, discountWithoutVat,
    discountCodeApplied, discountCampaignTitle, grandTotalExVat, grandTotalIncVat,
    totalGoodsIncVat, totalGoodsExVat, vat, totalSavingsWithVat, totalSavingsWithoutVat
  } = totalizedValues;
  const deliveryRawValue = includeVat ? deliveryIncVat : deliveryExVat;
  const totalGoodsRawValue = includeVat ? totalGoodsIncVat : totalGoodsExVat;
  const discountRawValue = includeVat ? discountWithVat : discountWithoutVat;
  const totalSavingsRawValue = includeVat ? totalSavingsWithVat : totalSavingsWithoutVat;
  const grandTotalRawValue = includeVat ? grandTotalIncVat : grandTotalExVat;
  const grandTotalExtraCss = cta ? 'CartTotalizer_Row-ExtraBottomMargin' : '';
  const totalGoodsWithCurrency = formatPrice(currency, totalGoodsRawValue);
  const deliveryRawWithCurrency = formatPrice(currency, deliveryRawValue);
  const discountWithCurrency = formatPrice(currency, discountRawValue);
  const totalSavingsCurrency = formatPrice(currency, totalSavingsRawValue);
  const vatWithCurrency = formatPrice(currency, vat);
  const grandTotalWithCurreny = formatPrice(currency, grandTotalRawValue);

  return (
    <div className="CartTotalizer">
      <div className="CartTotalizer_Header">
        <h3 className="CartTotalizer_Title">
          { title }
        </h3>
        { togglePlaceOrderButton && isSinglePageCheckout ? <Link onClick={ openOrderReviewPanel } className="CartTotalizer_edit">{ getLocalizedString('myAccount.edit') }</Link> : null }
      </div>
      <BasketItemsDetails items={ orderLines } includeVat={ includeVat } currency={ currency } />
      <div className="CartTotalizer_RowContainer">
        <TotalRow
          label={ getLocalizedString('cart.cartTotalizer.fieldLabels.totalGoods') }
          rawValue={ totalGoodsRawValue }
          value={ `${totalGoodsWithCurrency.symbol}${totalGoodsWithCurrency.amount}` }
          className="CartTotalizer_Row"
          e2e="goodsNet"
        />
        <TotalRow
          label={ getLocalizedString('cart.cartTotalizer.fieldLabels.delivery') }
          rawValue={ deliveryRawValue }
          value={ `${deliveryRawWithCurrency.symbol}${deliveryRawWithCurrency.amount}` }
          className="CartTotalizer_Row"
          e2e="deliveryGross"
        />
      </div>
      {
        discountCodeApplied && discountCampaignTitle && !hideTotalSavings && totalizedValues.discountWithVat > 0 && (
          <div className="CartTotalizer_RowContainerDiscount">
            <Row className="CartTotalizer_DiscountContainer">
              <Col xs={ 8 }>
                { discountCampaignTitle }
              </Col>
              <Col xs={ 4 } className="text-right" data-e2e="discount">
              - { `${discountWithCurrency.symbol}${discountWithCurrency.amount}` }
              </Col>
            </Row>
          </div>
        )
      }
      {
        (parseInt(totalSavingsCurrency.amount) > 0) && !hideTotalSavings &&
        (
          <div className="CartTotalizer_RowContainer">
            <Row className="CartTotalizer_DiscountContainer">
              <Col xs={ 8 }>
                { getLocalizedString('cart.cartTotalizer.fieldLabels.totalSavings') }
              </Col>
              <Col xs={ 4 } className="text-right" data-e2e="totalSaving">
                { `${totalSavingsCurrency.symbol}${totalSavingsCurrency.amount}` }
              </Col>
            </Row>
          </div>
        )
      }
      {
        !includeVat
          ? <TotalRow
            className="CartTotalizer_Row"
            e2e="vat"
            label={ getLocalizedString('cart.cartTotalizer.fieldLabels.vat') }
            value={ `${vatWithCurrency.symbol}${vatWithCurrency.amount}` }
          /> : null
      }
      <TotalRow
        className={ `CartTotalizer_Row-Strong ${grandTotalExtraCss}` }
        e2e="grandTotalGross"
        label={ getLocalizedString('cart.cartTotalizer.fieldLabels.grandTotalGross') }
        value={ `${grandTotalWithCurreny.symbol}${grandTotalWithCurreny.amount}` }
      />
      {
        cta
          ? <Row>
            <Col xs={ 12 }>
              <div className="small-12 columns CartTotalizer_makePaymentButton">
                { cta }
              </div>
            </Col>
          </Row> : null
      }
      {
        payOnAccountTerms
      }
    </div>
  );
};

CartTotalizer.defaultProps = {
  title: getLocalizedString('cart.cartTotalizer.title')
};

CartTotalizer.propTypes = {
  orderLines: PropTypes.object,
  cta: PropTypes.object,
  currency: PropTypes.string,
  excludeVatToggle: PropTypes.bool,
  includeVat: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  title: PropTypes.string,
  totalizedValues: PropTypes.object,
  hideTotalSavings: PropTypes.bool
};

export default CartTotalizer;
