import React from 'react';
import moment from 'moment';
import { AddressDisplay } from 'client/components/elements/subscriptionDetails/AddressDisplay';
import { formatPrice } from 'client/utils/priceUtils';
import PromoLabel from 'client/components/elements/promoLabel/PromoLabel';
import { getLocalizedString } from 'localization/localizer';
import { Map } from 'immutable';

if (process.browser) {
  require('./AddToMySubscriptionForm.scss');
}

const getQtyMultiplierPriceCalculated = (priceObj, qtyMultiplier, quantitySelected, isPriceWithVat) => {
  const price = priceObj.get(isPriceWithVat ? 'packPriceWithVat' : 'packWasPriceWithoutVat');
  const isQtyMultiplier = qtyMultiplier > 1;
  const priceForNonQtyMultiplier = Number(quantitySelected) * Number(price);
  const unitPriceWithMultiplier = isQtyMultiplier ? quantitySelected > 1 ? priceForNonQtyMultiplier : Number(qtyMultiplier * Number(price)) : priceForNonQtyMultiplier;
  return parseFloat(Math.round(unitPriceWithMultiplier * 100) / 100).toFixed(2);
};

const getPrices = (price = {}, quantity, isPriceWithVat) => {
  const priceObj = Map(price);
  const qtyMultiplier = priceObj.get('qtyMultiplier', 1);
  const priceCalculated = getQtyMultiplierPriceCalculated(priceObj, qtyMultiplier, quantity, isPriceWithVat);
  const currency = priceObj.get('currency', '£');
  const priceWithCurrency = formatPrice(currency, priceCalculated);
  const displayPrice = `${priceWithCurrency.symbol}${priceWithCurrency.amount}`;

  return (<>
    <div>
      <strong>{ displayPrice }</strong>
      {' '}
      <span className="AddToMySubscription_vatText">
        { getLocalizedString(isPriceWithVat ? 'incVat' : 'excVat') }
      </span>
    </div>
    <PromoLabel
      oldPrice={ priceObj.get('packWasPriceWithVat') }
      finalDiscount={ priceObj.get('finalPromoDiscount') }
      currency={ priceObj.get('currency') }
      discount={ priceObj.get('promoDiscount') }
      label={ priceObj.get('promoLabel') }
      promoUrl={ priceObj.get('promoUrl') }
    />
  </>);
};

const SubscriptionOverview = ({
  deliveryAddressId,
  currentDisplay,
  isPriceWithVat,
  newRepeatOrder,
  productPrice,
  addressList = []
}) => {
  const DISPLAY_THREE = 3;
  const DISPLAY_TWO = 2;
  const { regularity, endDate, startDate, orderReference, quantitySelected = 1, name: repeatProductOrderName } = newRepeatOrder;
  const newRepeatOrderAddress = addressList && deliveryAddressId ? addressList.find((address) => address.addressId === deliveryAddressId) : [];
  return (<>
    {
      [DISPLAY_TWO, DISPLAY_THREE].includes(currentDisplay) ? <div className="AddToMySubscription_createNewSubscriptionStepTwo">
        <div className="AddToMySubscription_subscriptionOverview">
          { getLocalizedString('createNewOrderForm.subscriptionOverview.text') }
        </div>
        <div className="row">
          <div className="col-md-6 AddToMySubscription_paddingLeftZero">
            <div className="AddToMySubscription_subscriptionReference">
              { orderReference }
            </div>
            <br/>
            <div className="AddToMySubscription_subscriptionProductName">
              { repeatProductOrderName }
            </div>
          </div>
          <div className="col-md-6 AddToMySubscription_paddingLeftZero">
            <div className="AddToMySubscription_subscriptionReference AddToMySubscription_subscriptionOverviewPricesColumn">
              { quantitySelected } { getLocalizedString('subscriptionForm.createNewSubscription.ItemAdded') }
            </div>
            <br/>
            <div className="AddToMySubscription_subscriptionProductName AddToMySubscription_subscriptionOverviewPricesColumn">
              { getPrices(productPrice, quantitySelected, isPriceWithVat) }
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 AddToMySubscription_paddingLeftZero">
            <div>
              <span className="AddToMySubscription_subscriptionDetailsLabels">
                { getLocalizedString('myAccount.repeatOrders.label.regularity') }
              </span>: <span className="AddToMySubscription_subscriptionDetailsValue">{ regularity }</span>
            </div>
            <div>
              { startDate
                ? <>
                  <span className="AddToMySubscription_subscriptionDetailsLabels">
                    { getLocalizedString('myAccount.repeatOrders.label.startDateText') }
                  </span>:
                  <span className="AddToMySubscription_subscriptionDetailsValue"> { moment(startDate).format('DD/MM/YYYY') }</span>
                </> : null
              }
            </div>
            <div>
              <span className="AddToMySubscription_subscriptionDetailsLabels">
                { getLocalizedString('myAccount.repeatOrders.label.endDate') }
              </span>:
              <span className="AddToMySubscription_subscriptionDetailsValue"> { endDate ? moment(endDate).format('DD/MM/YYYY') : getLocalizedString('subscriptionOverview.none') }</span>
            </div>
          </div>
        </div>
        { deliveryAddressId ? <div className="AddToMySubscription_createNewSubsAddress">
          <hr />
          <AddressDisplay
            address={ newRepeatOrderAddress }
            title={ getLocalizedString('singlePageCheckout.panel.deliveryDetailsPanelName') }/>
        </div> : null }
      </div> : null
    }
  </>);
};

export default SubscriptionOverview;
