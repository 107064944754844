import { fromJS } from 'immutable';
import {
  SET_NAME_ON_CARD, SET_SUBSCRIBE_BY_NEW_CARD_STATUS,
  TOGGLE_SAVE_CARD_FOR_FUTURE_ORDERS,
  TOGGLE_SHOW_ADDRESS_FORM,
  UPDATE_HOSTED_FIELDS_STATUS
} from './payByNewCardActions';

const defaultState = fromJS({});

const payByNewCard = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_ADDRESS_FORM:
      const showAddressForm = state.getIn([action.payload.selector, 'showAddressForm']);
      return state.setIn([action.payload.selector, 'showAddressForm'], !showAddressForm);
    case UPDATE_HOSTED_FIELDS_STATUS:
      return state.setIn(
        [action.payload.selector, 'hostedFields', action.payload.hostedFieldName],
        action.payload.hostedFieldStatus);
    case TOGGLE_SAVE_CARD_FOR_FUTURE_ORDERS:
      const saveCardForFutureOrders = state.getIn([action.payload.selector, 'saveCardForFutureOrders']);
      return state.setIn([action.payload.selector, 'saveCardForFutureOrders'], !saveCardForFutureOrders);
    case SET_NAME_ON_CARD:
      return state.setIn([action.payload.selector, 'nameOnCard'], action.payload.nameOnCard);
    case SET_SUBSCRIBE_BY_NEW_CARD_STATUS:
      return state.setIn([action.payload.selector, 'subscribe', 'status'], action.payload.status);
    default:
      return state;
  }
};

export default payByNewCard;
