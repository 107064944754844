import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { toggleEnterManually, requestFullAddress, requestAddressGen, requestAddressSuccess, onChangePostalCode } from 'client/actions/ui/postCodeActions';
import localState from 'shared/utils/localState';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { SecondaryButton } from '../../controls/StyledForms/formButtons';
import Autosuggest from 'react-autosuggest';
import SvgLoader from 'client/components/svg/SvgLoader';
import { isValidPostCode } from '../../../utils/validators';

if (process.browser) {
  require('./postcodeInput.scss');
}

export const displayFarFlungDelvMessage = () => {
  const text1 = `${getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text1')} ${getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text2')}`;
  const text3 = `${getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text3')}`;
  return (
    <div className="CheckoutPage_DeliveryDetailsPanel_zone2Zone3Delv" data-e2e="deliveryDetails-extraDeliveryCharge">
      <SvgLoader xlinkHref="yellow-alert"/>
      <span>
        {text1}
        <a href="/info/delivery" target="_blank">
          <span className='CheckoutPage_textColor'>{getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text7') }</span>
        </a>
        {text3}
      </span>
    </div>
  );
};

const ManualEntrySection = ({ fieldNamePrefix, disabled = false, isSinglePageCheckout = false, onChangePostCodeZone, postCodeZone, deilveryZone, postalCode }) => {
  let companyName = 'companyName';
  let companyNameId = 'companyName';
  let companyNameE2E = 'companyName';
  let addressLine1 = 'addressLine1';
  let addressName = 'addressName';
  let addressNameId = 'addressName';
  let addressNameE2E = 'addressName';
  let addressLine1Id = 'addressLine1';
  let addressLine1E2E = 'houseNo';
  let addressLine2 = 'addressLine2';
  let addressLine2Id = 'addressLine2';
  let addressLine2E2E = 'address';
  let city = 'city';
  let cityId = 'city';
  let cityE2E = 'city';
  let state = 'state';
  let stateId = 'state';
  let stateE2E = 'state';
  let postCode = 'postalCode';
  let postCodeId = 'postalCode';
  let postCodeE2E = 'postCode';

  if (fieldNamePrefix) {
    companyName = fieldNamePrefix + companyName;
    companyNameId = fieldNamePrefix + companyNameId;
    companyNameE2E = fieldNamePrefix + companyNameE2E;
    addressLine1 = fieldNamePrefix + addressLine1;
    addressName = fieldNamePrefix + addressName;
    addressNameId = fieldNamePrefix + addressNameId;
    addressLine1Id = fieldNamePrefix + addressLine1Id;
    addressLine1E2E = fieldNamePrefix + addressLine1E2E;
    addressLine2 = fieldNamePrefix + addressLine2;
    addressLine2Id = fieldNamePrefix + addressLine2Id;
    addressLine2E2E = fieldNamePrefix + addressLine2E2E;
    city = fieldNamePrefix + city;
    cityId = fieldNamePrefix + cityId;
    cityE2E = fieldNamePrefix + cityE2E;
    state = fieldNamePrefix + state;
    stateId = fieldNamePrefix + stateId;
    stateE2E = fieldNamePrefix + stateE2E;
    postCode = fieldNamePrefix + postCode;
    postCodeId = fieldNamePrefix + postCodeId;
    postCodeE2E = fieldNamePrefix + postCodeE2E;
    addressNameE2E = fieldNamePrefix + addressName;
  }

  const postalCodeChecker = isValidPostCode();
  const isValidPostCode1 = postalCodeChecker(postalCode);
  const [isZoneValid, setIsZoneValid] = useState(!!isValidPostCode1);

  const handlePostCodeChange = (e) => {
    const postCode = isValidPostCode();
    const val = e.target.value;
    const checker = postCode(val);
    setIsZoneValid(checker);
    if (!checker) {
      onChangePostCodeZone(val);
    }
  };

  return (
    <>
      <div>
        <Field
          name={ companyName }
          id={ companyNameId }
          className="AddressForm_formGroup_input form-control"
          disabled = { disabled }
          labelClassName="AddressForm_formGroup_label AddressForm_formGroup_label-optional"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.companyName"
          datae2e={ companyNameE2E } />

        <Field
          name={ addressLine1 }
          id={ addressLine1Id }
          className="AddressForm_formGroup_input form-control"
          disabled = { disabled }
          labelClassName="AddressForm_formGroup_label control-label required"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.addressLine1"
          datae2e={ addressLine1E2E } />

        <Field
          name={ addressLine2 }
          id={ addressLine2Id }
          className="AddressForm_formGroup_input form-control"
          disabled = { disabled }
          labelClassName="AddressForm_formGroup_label AddressForm_formGroup_label-optional"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.addressLine2"
          datae2e={ addressLine2E2E } />
        <div className="row">
          <div className="col-md-6">
            <Field
              name={ city }
              id={ cityId }
              className="AddressForm_formGroup_input form-control"
              disabled = { disabled }
              labelClassName="AddressForm_formGroup_label control-label required"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="postcodeInput.label.city"
              datae2e={ cityE2E } /></div>
          <div className="col-md-6">
            <Field
              name={ state }
              id={ stateId }
              className="AddressForm_formGroup_input form-control"
              disabled = { disabled }
              labelClassName="AddressForm_formGroup_label AddressForm_formGroup_label-optional"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="postcodeInput.label.state"
              datae2e={ stateE2E } /></div>
        </div>

        <div className="">
          <div className="col-md-6 AddressForm_paddingStyle">
            <Field
              name={ postCode }
              id={ postCodeId }
              className="AddressForm_formGroup_input form-control"
              disabled = { disabled }
              labelClassName="AddressForm_formGroup_label control-label required"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="postcodeInput.label.postcode"
              datae2e={ postCodeE2E }
              onChange={ handlePostCodeChange } />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              name={ addressName }
              id={ addressNameId }
              className="AddressForm_formGroup_input form-control"
              labelClassName="AddressForm_formGroup_label AddressForm_formGroup_label-optional"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="addressForm.label.addressName"
              datae2e={ addressNameE2E } />
          </div>
        </div>
      </div>
      {
        (!isZoneValid && postCodeZone && (postCodeZone === 2 || postCodeZone === 3)) ? displayFarFlungDelvMessage() : null
      }
    </>
  );
};

export const PostcodeInput = ({
  fieldNamePrefix,
  disabled,
  quickFindPostCodeValue,
  addressList = [],
  requestAddressForId,
  isManuallyEntered = false,
  isEditAddress,
  isPostCodeSubmitted,
  toggleShowManually,
  requestAddress,
  errorMessage,
  change,
  onPostcodeLinkClick = () => {},
  clearRequestedAddress,
  isBillingForm,
  isSinglePageCheckout = false,
  postCodeZone,
  onChangePostCodeZone,
  postalCode,
  deilveryZone,
  addressId
}) => {
  let quickFindPostCodeName = 'quickFindPostCode';
  let quickFindPostCodeE2E = 'quickFinder';
  if ((isEditAddress || isBillingForm) && !isManuallyEntered && !isPostCodeSubmitted) {
    enterManually();
  }

  if (fieldNamePrefix) {
    quickFindPostCodeName = fieldNamePrefix + quickFindPostCodeName;
    quickFindPostCodeE2E = fieldNamePrefix + quickFindPostCodeE2E;
  }

  function showAddressEntrySection () {
    if (isManuallyEntered) {
      return (
        <ManualEntrySection
          fieldNamePrefix={ fieldNamePrefix }
          disabled={ disabled }
          isSinglePageCheckout={ isSinglePageCheckout }
          postCodeZone={ postCodeZone }
          onChangePostCodeZone = { onChangePostCodeZone }
          deilveryZone= { deilveryZone }
          postalCode= { postalCode }
          addressId = { addressId }
          isEditAddress = { isEditAddress }
        />
      );
    }
    return null;
  }

  function onFindPostCode (e, { suggestion }) {
    const { company, address, postcode, city } = suggestion;
    const addressLine = address && suggestion.address.join(', ');
    const companyName = company ? `${company}, ` : '';
    const addressValue = `${companyName}${addressLine}, ${city}, ${postcode}`;

    change(quickFindPostCodeName, addressValue);
    toggleShowManually(false);
    requestAddressForId(suggestion);
  }

  function enterManually () {
    toggleShowManually(true);
  }

  function showEnterManuallyBlock () {
    if (!isManuallyEntered) {
      if (addressList && addressList.length) {
        return (
          <div>
            <span>Can&apos;t find address?</span>&nbsp;
            <SecondaryButton
              className="enterManuallyButton"
              disabled={ disabled }
              datae2e={ fieldNamePrefix + 'enterManually' }
              text={ getLocalizedString('postcodeInput.label.enterManually') }
              onClick={ enterManually } />
          </div>
        );
      }
      return (
        <div>
          <a className="AddressForm_manualLink"
            disabled={ disabled }
            data-e2e={ fieldNamePrefix + 'enterManually' }
            onClick={ enterManually }
          >{ getLocalizedString('postcodeInput.label.enterManually') }</a>
        </div>
      );
    }
  }

  const stripPostCode = (e) => {
    const emptyAddress = [];
    const value = e.target.value;
    e.preventDefault();
    const postcodeInput = value ? value.trimStart() : '';
    // removing space at start of string
    change(quickFindPostCodeName, postcodeInput);
    if (postcodeInput.length > 2) {
      requestAddress(postcodeInput);
    } else {
      clearRequestedAddress(emptyAddress);
    }
  };

  const inputProps = {
    name: quickFindPostCodeName,
    onChange: stripPostCode,
    value: quickFindPostCodeValue ? quickFindPostCodeValue : '',
    datae2e: quickFindPostCodeE2E,
    placeholder: getLocalizedString('postcodeInput.label.addressFinderMsg')
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => {
    const { company, address, postcode, city } = suggestion;
    const companyName = company ? `${company}, ` : '';
    const addressLine = address && suggestion.address.join(', ');
    return <div className="react-autosuggest__suggestions">
      <span>{`${companyName}${addressLine}, ${city}, `}<b>{postcode}</b></span>
    </div>;
  };

  const onSuggestionsFetchRequested = () => '';

  const onSuggestionsClearRequested = () => [];
  return (
    <div className="AddressForm">
      <div className="form-group AddressForm_quickFinder">
        <SvgLoader xlinkHref={ `#addressFinder-icon` } className="AddressForm_quickFinder_logo"/>
        <label className="control-label AddressForm_formGroup_label--textBold">
          { getLocalizedString('postcodeInput.label.addressFinder') }
        </label>
        <p className="AddressForm_formGroup_label--msgSize">
          { getLocalizedString('postcodeInput.label.addressFinderMsg') }
        </p>
        <div className="AddressForm_quickFinder_Content">
          <Autosuggest
            id="addressFinder"
            onSuggestionSelected={ onFindPostCode }
            suggestions={ addressList }
            onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
            onSuggestionsClearRequested={ onSuggestionsClearRequested }
            getSuggestionValue={ getSuggestionValue }
            renderSuggestion={ renderSuggestion }
            inputProps={ inputProps }
          />
        </div>
      </div>
      {showAddressEntrySection()}
      {showEnterManuallyBlock()}
      {errorMessage
        ? <div className="AddressForm_message AddressForm_message_notFoundWarning">
          { getLocalizedString('postcodeInput.error.unrecognisedAddressPart1') }{ getLocalizedString('postcodeInput.error.unrecognisedAddressPart2') }.
        </div> : null}
    </div>
  );
};

PostcodeInput.propTypes = {
  addressLine2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string,
  addressName: PropTypes.string,
  addressLine1: PropTypes.string.isRequired,
  postCode: PropTypes.string.isRequired,
  requestAddress: PropTypes.func.isRequired,
  requestAddressForId: PropTypes.func.isRequired,
  addressList: PropTypes.array,
  toggleShowManually: PropTypes.func.isRequired
};

function mapStateToProps (state, ownProps) {
  const componentState = state.getIn(['ui', 'postCode', ownProps.selector]);
  const userAddresses = state.getIn(['user', 'address', 'deliveryAddresses', 'data']);
  const addressIdSelectedForEdit = ownProps?.addressId;
  const userEditAddtessId = userAddresses?.toJS()?.find((address) => address.addressId === addressIdSelectedForEdit);
  const eddittedAddressParcelforceZone = userEditAddtessId?.parcelForceZone;
  const postalCode = state.getIn(['user', 'address', 'delivery', 'postalCode']);
  const compState = componentState ? componentState.toJS() : {};
  compState.postCodeZone = compState.postCodeZone ? compState.postCodeZone : (ownProps.isEditAddress && !!eddittedAddressParcelforceZone) ? eddittedAddressParcelforceZone : null;
  if (ownProps.isManuallyEntered && Object.keys(compState).length) {
    compState.isManuallyEntered = true;
  }
  compState.postalCode = postalCode;
  return compState;
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  change: (name, value) => dispatch(change(ownProps.form, name, value)),
  requestAddress: (postCode) => dispatch(requestAddressGen(ownProps.selector, postCode)),
  requestAddressForId: (postcodeId) => dispatch(requestFullAddress(ownProps.form, ownProps.selector, ownProps.fieldNamePrefix, postcodeId)),
  toggleShowManually: (shouldEnterManually) => dispatch(toggleEnterManually(ownProps.selector, shouldEnterManually)),
  clearRequestedAddress: (address) => dispatch(requestAddressSuccess(ownProps.selector, address)),
  onChangePostCodeZone: (postcode) => dispatch(onChangePostalCode(ownProps.selector, postcode))
});

export default connect(mapStateToProps, mapDispatchToProps)(localState(PostcodeInput));
