import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  autoCompleteChangeHandler,
  autoCompleteTermSelectedHandler,
  fetchAutoCompleteTopProductsOnHoverHandler,
  dispatchGetRecentSearches
} from 'client/actions/searchBarActions';
import AutoComplete from 'client/components/elements/autoComplete/AutoComplete';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import qs from 'qs';

const MAX_NO_OF_SUGGESTIONS_SHOWN = 5;
const NO_OF_CHARACTERS_FOR_SUGGESTIONS = 3;
class SearchBar extends Component {
  render () {
    const {
      searchTerm = '',
      searchSuggestions,
      autoCompleteSearch,
      autoCompleteSelect,
      fetchAutoCompleteTopProductsOnHover,
      query,
      hasSearchTermBeenSelected,
      getRecentSearches,
      recentSearches
    } = this.props;

    const recentSearchesMapped = recentSearches.map(text => ({ text })).slice(0, MAX_NO_OF_SUGGESTIONS_SHOWN);
    const suggestions = searchSuggestions.toArray().map((item, idx) => {
      if (searchTerm.length >= NO_OF_CHARACTERS_FOR_SUGGESTIONS && idx === 0) {
        return {
          ...item,
          results: uniqBy([...recentSearchesMapped, ...(item?.results || [])], 'text').slice(0, MAX_NO_OF_SUGGESTIONS_SHOWN)
        };
      }

      return item;
    });
    const isOnlyRecentSearches = searchTerm.length > NO_OF_CHARACTERS_FOR_SUGGESTIONS && !suggestions?.length;
    const shouldDisplayOnlyRecentSearches = searchTerm.length < NO_OF_CHARACTERS_FOR_SUGGESTIONS;
    const recentSearchesSuggestions = !isOnlyRecentSearches && !!recentSearches?.length ? [
      {
        column: 1,
        results: recentSearchesMapped,
        title: 'Suggestions',
        type: 'suggestions'
      }
    ] : [];
    const newSuggestions = shouldDisplayOnlyRecentSearches ? recentSearchesSuggestions : suggestions;
    const queryStringObject = (query) ? qs.parse(query, { ignoreQueryPrefix: true }) : null;
    const searchQuery = (queryStringObject) ? get(queryStringObject, 'query', '') : '';
    const autoCompleteProps = {
      autoCompleteSelect,
      autoCompleteSearch,
      getRecentSearches,
      searchSuggestions: newSuggestions,
      value: (hasSearchTermBeenSelected || hasSearchTermBeenSelected === undefined) ? searchQuery : searchTerm,
      fetchAutoCompleteTopProductsOnHover
    };

    return (
      <React.Fragment>
        <AutoComplete { ...autoCompleteProps } />
      </React.Fragment>
    );
  }
}

SearchBar.propTypes = {
  autoCompleteSearch: PropTypes.func,
  getRecentSearches: PropTypes.func,
  autoCompleteSelect: PropTypes.func,
  searchSuggestions: PropTypes.object,
  recentSearches: PropTypes.array,
  fetchAutoCompleteTopProductsOnHover: PropTypes.func,
  searchTerm: PropTypes.string,
  termType: PropTypes.string
};

const DEFAULT_TERM_TYPE = 'term';

const mapStateToProps = (state, myProps) => {
  const termType = get(myProps, 'termType', DEFAULT_TERM_TYPE);
  const query = (myProps && myProps.location) ? get(myProps.location, 'search', null) : null;
  return {
    searchTerm: state.getIn(['searchBar', termType]),
    recentSearches: state.getIn(['searchBar', 'recentSearches']),
    searchSuggestions: state.getIn(['searchBar', 'suggestions']),
    hasSearchTermBeenSelected: state.getIn(['searchBar', 'hasSearchTermBeenSelected']),
    query
  };
};

const mapDispatchToProps = (dispatch, myProps) => {
  const termType = get(myProps, 'termType', DEFAULT_TERM_TYPE);
  return {
    autoCompleteSearch: autoCompleteChangeHandler(termType)(dispatch),
    getRecentSearches: (searchTerm = '') => dispatch(dispatchGetRecentSearches(searchTerm)),
    autoCompleteSelect: autoCompleteTermSelectedHandler(termType)(dispatch),
    fetchAutoCompleteTopProductsOnHover: (text, params) => dispatch(fetchAutoCompleteTopProductsOnHoverHandler(text, params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SearchBar
);
