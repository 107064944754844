export const SAVE_SCHEDULED_ALL_ORDERLINES_SETTINGS = 'SAVE_SCHEDULED_ALL_ORDERLINES_SETTINGS';
export const REMOVE_CART_SCHEDULED_ORDERS_SETTINGS = 'REMOVE_CART_SCHEDULED_ORDERS_SETTINGS';
export const REMOVE_SCHEDULED_ORDER_SETTINGS = 'REMOVE_SCHEDULED_ORDER_SETTINGS';
export const SCHEDULE_SUBSCRIPTION_ALL_ORDER_LINES = 'SCHEDULE_SUBSCRIPTION_ALL_ORDER_LINES';
export const SET_CONFIRMATION_SCREEN_SUBSCRIPTION_PAYMENT = 'SET_CONFIRMATION_SCREEN_SUBSCRIPTION_PAYMENT';

export const SET_CART_LEVEL_FORM_STATUS = 'SET_CART_LEVEL_FORM_STATUS';
export const RELOAD_SCHEDULED_ORDERS = 'RELOAD_SCHEDULED_ORDERS';
export const SET_INVALID_SUBSCRIPTION_PAYMENT_METHOD = 'SET_INVALID_SUBSCRIPTION_PAYMENT_METHOD';

export const SAVE_SCHEDULED_ORDER_FORM_SETTINGS = 'SAVE_SCHEDULED_ORDER_FORM_SETTINGS';
export const SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS = 'SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS';
export const SCHEDULE_ORDERLINE_SUBSCRIPTION = 'SCHEDULE_ORDERLINE_SUBSCRIPTION';
export const SET_IS_LOADING_CREATE_SUBSCRIPTION_BUTTON = 'SET_IS_LOADING_CREATE_SUBSCRIPTION_BUTTON';
export const SET_PRODUCT_LINE_SCHEDULED_ORDER = 'SET_PRODUCT_LINE_SCHEDULED_ORDER';

export const saveScheduledAllOrderlinesSettings = () => ({ type: SAVE_SCHEDULED_ALL_ORDERLINES_SETTINGS });
export const removeCartScheduledOrdersSettings = () => ({ type: REMOVE_CART_SCHEDULED_ORDERS_SETTINGS });
export const removeScheduledOrderSettings = (formId, isPersistentState) => ({ type: REMOVE_SCHEDULED_ORDER_SETTINGS, payload: { formId, isPersistentState } });
export const scheduleSubscriptionsAllOrderLines = (settings) => ({ type: SCHEDULE_SUBSCRIPTION_ALL_ORDER_LINES, payload: { settings } });
export const scheduleOrderlineSubscription = (sku, settings) => ({ type: SCHEDULE_ORDERLINE_SUBSCRIPTION, payload: { sku, settings } });
export const setConfirmationScreenSubscriptionPayment = (paymentMethod) => ({ type: SET_CONFIRMATION_SCREEN_SUBSCRIPTION_PAYMENT, payload: { paymentMethod } });

export const setCartLevelFormStatus = (cartLevelFormStatus) => ({ type: SET_CART_LEVEL_FORM_STATUS, payload: { cartLevelFormStatus } });
export const reloadScheduledOrders = (productLineItems) => ({ type: RELOAD_SCHEDULED_ORDERS, payload: { productLineItems } });

export const setInvalidSubscriptionPaymentMethod = (paymentMethod) => ({ type: SET_INVALID_SUBSCRIPTION_PAYMENT_METHOD, payload: { paymentMethod } });
export const saveScheduledOrderFormSettings = (sku, settings) => ({ type: SAVE_SCHEDULED_ORDER_FORM_SETTINGS, payload: { sku, settings } });
export const saveScheduledOrderCartFormSettings = (settings) => ({ type: SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS, payload: { settings } });

export const setIsLoadingCreateSubscriptionButton = (formId, isLoading) => ({ type: SET_IS_LOADING_CREATE_SUBSCRIPTION_BUTTON, payload: { formId, isLoading } });
export const setProductLineScheduledOrder = (sku, scheduledOrder) => ({ type: SET_PRODUCT_LINE_SCHEDULED_ORDER, payload: { sku, scheduledOrder } });
