import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_PUBLIC_HOLIDAYS,
  requestPublicHolidaysSuccess
} from 'client/actions/publicHolidaysActions';

import { logAction } from 'client/actions/logActions';

import {
  getPublicHolidays
} from 'shared/endpoints/publicHolidaysEndpoint';

export function * onLoadPublicHolidaysRequest () {
  try {
    const publicHolidays = yield call(getPublicHolidays);
    yield put(requestPublicHolidaysSuccess(publicHolidays));
  } catch (e) {
    yield put(logAction({ message: 'Failure within onLoadPublicHolidaysRequest', e }));
  }
}

export function * watchLoadPublicHolidaysRequest () {
  yield takeLatest(REQUEST_PUBLIC_HOLIDAYS, onLoadPublicHolidaysRequest);
}
