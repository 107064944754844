import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  SubmissionError
} from 'redux-form/immutable';
import {
  makeValidator,
  maxLength,
  isRequired,
  minValue,
  minLength
} from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidations';
import ApiCallButton from '../apiCallButton/ApiCallButton';
import {
  ADD_PRODUCT_TO_QUOTATION,
  ADD_TO_QUOTATION_SUCCESS,
  ADD_TO_QUOTATION_ERROR,
  CREATE_QUOTATION,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_ERROR
} from 'client/actions/quotationsActions';
import { setApiButtonStatus } from 'client/actions/ui/apiCallButtonActions';
import {
  ADD_TO_NEW_QUOTATION,
  ADD_TO_NEW_QUOTATION_SELECTOR,
  ADD_TO_EXISTING_QUOTATION,
  MIN_QUOTE_NAME_LENGTH,
  MAX_QUOTE_NAME_LENGTH } from 'shared/constants/quotation';

if (process.browser) {
  require('./AddToQuotationForm.scss');
}

const AddToQuotationForm = (props) => {
  const {
    handleSubmit,
    createQuotation,
    addProductsToQuotation,
    products,
    quotations,
    disableAddToQuotation,
    disableNewAddToQuotation,
    dispatchResetAddToQuotationButton
  } = props;

  const submit = (submitType) => (values, dispatch) => {
    const validators = {};

    if (products.size <= 1) {
      validators.quotationProductQuantity = [
        isRequired(getLocalizedString('quotationForm.label.productQuantity')),
        minValue(1)
      ];
    }

    if (submitType === ADD_TO_NEW_QUOTATION) {
      validators.newQuotationName = [
        isRequired({
          field: getLocalizedString('quotationForm.label.newQuotationName'),
          message: 'Please provide new quotation name'
        }),
        minLength({
          minLength: MIN_QUOTE_NAME_LENGTH,
          fieldName: getLocalizedString('quotationForm.label.newQuotationName')
        }),
        maxLength({
          maxLength: MAX_QUOTE_NAME_LENGTH,
          fieldName: getLocalizedString('quotationForm.label.newQuotationName')
        })
      ];
    } else {
      validators.existingQuotationId = [
        isRequired({
          field: getLocalizedString('quotationForm.label.existingQuotation'),
          message: 'Please select new or existing quotation.'
        })
      ];
    }

    const validate = makeValidator(validators);

    return validateForm(values, validate)
      .then(() => {
        const { existingQuotationId, newQuotationName, quotationProductQuantity } = values.toJS();
        const productsWithQuantities = products.size <= 1
          ? products.map((p) => ({ sku: p.get('sku'), qty: quotationProductQuantity }))
          : products.map((p) => ({ sku: p.get('sku'), qty: p.get('qty') }));

        if (submitType === ADD_TO_NEW_QUOTATION) {
          createQuotation(newQuotationName, productsWithQuantities);
        } else {
          addProductsToQuotation(existingQuotationId, productsWithQuantities);
        }
      })
      .catch((errors) => {
        dispatchResetAddToQuotationButton(ADD_TO_NEW_QUOTATION_SELECTOR);
        throw new SubmissionError(errors);
      });
  };

  const quotationSelectionInput = quotations && quotations.size > 0
    ? (
      <div className="AddToQuotationForm_existingQuotationSelection">
        <div className="AddToQuotationForm_existingQuotationNameInput">
          <label htmlFor="existingQuotationId" className="AddToQuotationForm_formGroup_label QuotationForm_formGroup_label-required">
            { getLocalizedString('quotationForm.label.existingQuotation') }
          </label>
          <Field
            name="existingQuotationId"
            id="existingQuotationId"
            className="AddToQuotationForm_formGroup_select form-control"
            component="select"
            data-e2e="existingQuotationId"
            value=""
          >
            <option key={ 0 } value="" disabled> Select quotation </option>
            {
              quotations.map(
                (entry, i) => <option key={ entry.get('_id') } value={ entry.get('_id') }>{ entry.get('name') }</option>
              )
            }
          </Field>
        </div>
        <div className="AddToQuotationForm_existingQuotationSubmitButton">
          <ApiCallButton
            selector="addToExistingQuotation"
            triggerAction={ ADD_PRODUCT_TO_QUOTATION }
            onSuccessAction={ ADD_TO_QUOTATION_SUCCESS }
            onErrorAction={ ADD_TO_QUOTATION_ERROR }
            resetOnSuccess
            onClick={ handleSubmit(submit(ADD_TO_EXISTING_QUOTATION)) }
            datae2e="submit"
            buttonClass="AddToQuotationForm_existingQuotationSubmitButton_submitButton"
            text="Add to Quotation"
            textWhenComplete="processing..."
            disabled={ disableAddToQuotation }
          />
        </div>
      </div>
    )
    : null;

  const quantitySelection = products && products.size <= 1 ? (
    <div className="AddToQuotationForm_quantity">
      <Field
        id="quotationProductQuantity"
        name="quotationProductQuantity"
        className="AddToQuotationForm_formGroup_input form-control"
        labelClassName="AddToQuotationForm_formGroup_label QuotationForm_formGroup_label-required"
        type="number"
        min="1"
        component={ FieldWithValidations }
        labelResourceName="quotationForm.label.productQuantity"
        datae2e="quotationProductQuantity" />
    </div>)
    : null;

  return (
    <div className="AddToQuotationForm">
      <form onSubmit={ handleSubmit(submit) }>
        { quantitySelection }
        { quotationSelectionInput }
        <div className="AddToQuotationForm_newQuotation">
          <div className="AddToQuotationForm_newQuotationNameInput">
            <Field
              name="newQuotationName"
              id="newQuotationName"
              className="AddToQuotationForm_formGroup_input form-control"
              labelClassName="AddToQuotationForm_formGroup_label QuotationForm_formGroup_label-required"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="quotationForm.label.newQuotationName"
              datae2e="newQuotationName" />
          </div>
          <div className="AddToQuotationForm_newQuotationSubmitButton">
            <ApiCallButton
              selector={ ADD_TO_NEW_QUOTATION_SELECTOR }
              triggerAction={ CREATE_QUOTATION }
              onSuccessAction={ CREATE_QUOTATION_SUCCESS }
              onErrorAction={ CREATE_QUOTATION_ERROR }
              resetOnSuccess
              onClick={ handleSubmit(submit(ADD_TO_NEW_QUOTATION)) }
              datae2e="submit"
              buttonClass="AddToQuotationForm_newQuotationSubmitButton_submitButton"
              text="Create Quotation"
              textWhenComplete="processing..."
              disabled={ disableNewAddToQuotation }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

let reduxAddToQuotationForm = reduxForm({
  form: 'addToQuotation'
})(AddToQuotationForm);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      quotationProductQuantity: ownProps.products.getIn([0, 'qty']),
      existingQuotationId: '',
      newQuotationName: ''
    },
    disableAddToQuotation: state.getIn(['form', 'addToQuotationForm', 'values', 'existingQuotationId']) === '',
    disableNewAddToQuotation: state.getIn(['form', 'addToQuotationForm', 'values', 'newQuotationName']) === ''
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchResetAddToQuotationButton: (addToQuotationButtonSelector) => dispatch(setApiButtonStatus(addToQuotationButtonSelector, ''))
  };
};

reduxAddToQuotationForm = connect(mapStateToProps, mapDispatchToProps)(reduxAddToQuotationForm);

export { AddToQuotationForm };
export default reduxAddToQuotationForm;
