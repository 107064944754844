import React from 'react';
import { connect } from 'react-redux';
import { formatPrice, getFreeDeliveryPrice } from 'client/utils/priceUtils';
import { onToggleGlobalVat } from 'client/actions/userActions';
import flowRight from 'lodash/flowRight';
import { fromJS } from 'immutable';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { toastSuccess } from 'client/actions/showNotificationsActions';
import { clearAddedProductToQuotation } from 'client/actions/globalQuotationActions';
import { getTokenDetails } from 'shared/utils/jwtUtils';
import { FREE_DELIVERY_LOYALTY_TIERS } from 'shared/constants/common';

if (process.browser) {
  require('./InfoRibbon.scss');
}

export const HeaderInfoRibbon = (props) => {
  const { loyaltyTier } = props;
  const hasTPPFreeDelivery = FREE_DELIVERY_LOYALTY_TIERS.includes(loyaltyTier);
  const priceSymbol = formatPrice('GBP');
  const freeDeliveryPrice = getFreeDeliveryPrice(priceSymbol.symbol);
  return (
    <div className="infoRibbon hidden-sm">
      <div className="container-fluid headerInfoRibbonBackground">
        <div className="container infoRibbonContainer">
          <div className="row infoRibbonLinks">
            <div className="headerInfoRibbon">
              <div className="infoRibbonLinks_delivery">
                <a href="/info/delivery" className="infoRibbonLink">
                  {hasTPPFreeDelivery ? <span className="infoRibbonLinkBold">{ getLocalizedString('label.free.delivery.all.orders')}</span> : <><span className="infoRibbonLinkBold">{ getLocalizedString('header.label.freeNextDayDelivery') }</span> <span>{ getLocalizedStringWithParam('header.label.onOrders', { price: freeDeliveryPrice }) }</span></> }
                </a>
              </div>
              <div className="text-center infoRibbonBorderHeader infoRibbonLinks_account">
                <a href="/info/zoro-business-account" className="infoRibbonLink">
                  <span className="infoRibbonLinkBold">{ getLocalizedString('header.label.businessAccountsAvailable') }</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const jwtToken = state.getIn(['auth', 'longSessionJwtToken']) || state.getIn(['auth', 'jwtToken']);
  const jwtTokenDetails = jwtToken ? getTokenDetails(jwtToken) : null;
  const loyaltyTier = jwtTokenDetails ? jwtTokenDetails.loyaltyTier : null;

  return {
    isPriceWithVat: state.getIn(['user', 'isPriceWithVat']),
    lockedQuotes: state.getIn(['globalQuotation', 'lockedQuotes'], fromJS([])),
    quoteRefId: state.getIn(['globalQuotation', 'createdQuotation', '_id'], ''),
    quoteRef: state.getIn(['globalQuotation', 'createdQuotation', 'quoteRef']),
    numberOfQuotationProducts: state.getIn(['globalQuotation', 'createdQuotation', 'products'], fromJS([])),
    isVatPreferenceFromUnleash: state.getIn(['user', 'isVatPreferenceFromUnleash'], false),
    loyaltyTier
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearQuote: () => dispatch(clearAddedProductToQuotation()),
  changeGlobalVATState: flowRight(dispatch, onToggleGlobalVat),
  toastSuccess: flowRight(dispatch, toastSuccess)
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderInfoRibbon);
