import { put, take, takeLatest, select, call, all, fork } from 'redux-saga/effects';

import { getLocalizedString } from '../../localization/localizer';

import { POST_ORDER_FAILED } from 'client/actions/orderReviewActions';
import { toastError } from 'client/actions/showNotificationsActions';
import {
  loadCart,
  recieveAddToCartError
} from 'client/actions/cartActions';

import {
  CHANGE_DELIVERY_OPTION_INIT,
  INIT_LOAD_CART,
  INIT_ORDER_REVIEW,
  changeOptionSelectionInit,
  toggleDeliveryOptionAccordion,
  initLoadCart,
  updatingOptionLoader
} from 'client/actions/orderReviewActions';

import {
  initGlobalQuotationsDetails
} from 'client/actions/globalQuotationActions';

import { addProductLineItems } from 'client/actions/productLineActions';
import { setOrderLineQuantity } from 'shared/endpoints/cartEndpoint';
import { initQuotationDetails } from 'client/actions/quotationsActions';
import { verifyAddresses, verifyAddressCompleted } from 'client/actions/addressActions';
import { SINGLE_PAGE_CHECKOUT } from 'shared/constants/singlePageCheckout';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';

const TOASTER_DELAY = 5000;
const eligibleForDeliveryOptionSelector = (state) => state.getIn(['user', 'address', 'delivery', 'eligibleForDeliveryOptions'], null);

export function * onRequestUpdateDeliveryOptionsInCart ({ payload: { optionType, eligibleForDeliveryOptions } }) {
  yield put(updatingOptionLoader(true));

  const orderLinesSelector = (state) => state.getIn(['cart', 'orderLines']);
  const orderLines = (yield select(orderLinesSelector)) || [];

  const requestData = orderLines.reduce((products, orderLine) => {
    const optionList = orderLine.getIn(['deliveryOptionList', 'optionList'], []);
    if (optionList.size) {
      return [
        ...products,
        {
          sku: orderLine.get('sku'),
          qty: orderLine.get('quantity'),
          selectedDeliveryOption: optionType || orderLine.getIn(['deliveryOptionList', 'selectedDeliveryOption'])
        }
      ];
    }
    return products;
  }, []);

  const body = { products: requestData };

  try {
    yield call(setOrderLineQuantity, body);
  } catch (err) {
    yield put(recieveAddToCartError(err));
    yield put(updatingOptionLoader(false));
  }
  const payload = { eligibleForDeliveryOptions };
  yield put(loadCart(payload));
  yield take(addProductLineItems);
  yield put(updatingOptionLoader(false));
  yield put(toggleDeliveryOptionAccordion(''));
}

export function * watchDeliveryOptionChange () {
  yield takeLatest(CHANGE_DELIVERY_OPTION_INIT, onRequestUpdateDeliveryOptionsInCart);
}

export function * onInitLoadCart ({ eligibleForDeliveryOptions }) {
  yield put(loadCart({ eligibleForDeliveryOptions, location: SINGLE_PAGE_CHECKOUT }));
  if (eligibleForDeliveryOptions) {
    yield take(addProductLineItems);
    yield put(changeOptionSelectionInit(eligibleForDeliveryOptions));
  }
}

export function * watchInitLoadCart () {
  yield takeLatest(INIT_LOAD_CART, onInitLoadCart);
}

export function * onPostOrderFailed (action) {
  const postOrderError = action.payload.error;
  const fromQuotationId = action.payload.fromQuotationId;
  if (postOrderError === getLocalizedString('orderReview.deliveryOptions.invalidOption')) {
    yield put(toastError(
      getToastMessage(
        postOrderError
      ),
      'top-right', TOASTER_DELAY));

    const isGlobal = action.payload.isGlobalQuotation;
    const eligibleForDeliveryOptions = yield select(eligibleForDeliveryOptionSelector);
    fromQuotationId ? isGlobal
      ? yield put(initGlobalQuotationsDetails(fromQuotationId, false, eligibleForDeliveryOptions))
      : yield put(initQuotationDetails(fromQuotationId, eligibleForDeliveryOptions))
      : yield put(initLoadCart({ eligibleForDeliveryOptions, location: SINGLE_PAGE_CHECKOUT }));
    yield take(addProductLineItems);
    yield put(updatingOptionLoader(false));
    yield put(toggleDeliveryOptionAccordion(''));
  }
}

export function * watchPostOrderFailed () {
  yield takeLatest(POST_ORDER_FAILED, onPostOrderFailed);
}

export function * onInitOrderReview ({ payload }) {
  const {
    fromQuotationId,
    isGlobal,
    query,
    isSinglePageCheckout
  } = payload;

  const {
    deliveryAddressId,
    billingAddressId,
    onAccount: payOnAccount
  } = query;

  yield put(verifyAddresses({ deliveryAddressId, billingAddressId, payOnAccount, isSinglePageCheckout }));
  yield take(verifyAddressCompleted);
  const eligibleForDeliveryOptions = yield select(eligibleForDeliveryOptionSelector);
  const addressIdSelector = (state) => state.getIn(['user', 'address', 'delivery' ]);
  const deliveryAddress = yield select(addressIdSelector);
  const addressId = deliveryAddress?.toJS().addressId;
  if (!fromQuotationId || fromQuotationId === 'undefined') {
    yield put(initLoadCart({ eligibleForDeliveryOptions, location: SINGLE_PAGE_CHECKOUT }));
  } else {
    !isGlobal
      ? yield put(initQuotationDetails(fromQuotationId, eligibleForDeliveryOptions, addressId))
      : yield put(initGlobalQuotationsDetails(fromQuotationId, false, eligibleForDeliveryOptions));
  }
}

export function * watchInitOrderReview () {
  yield takeLatest(INIT_ORDER_REVIEW, onInitOrderReview);
}

export function * watchOrderReviewChanges () {
  yield all([
    fork(watchDeliveryOptionChange),
    fork(watchInitLoadCart),
    fork(watchPostOrderFailed),
    fork(watchInitOrderReview)
  ]);
}
