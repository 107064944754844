import React, { Component } from 'react';

if (process.browser) {
  require('./ProgressCircle.scss');
}

export default class ProgressCircle extends Component {
  render () {
    const {
      size = 'sm'
    } = this.props;

    return (
      <div className={ `ProgressCircle ProgressCircle-${size}` }>
      </div>
    );
  }
}
