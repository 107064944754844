import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SvgLoader from 'client/components/svg/SvgLoader';
import QuantityInput from '../quantityInput/quantityInput';
import flowRight from 'lodash/flowRight';
import TapButton from 'client/components/controls/tapButton/TapButton';
import { fromJS } from 'immutable';
import { SecondaryLink, SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import {
  setOrderLineQuantity,
  loadCart
} from 'client/actions/cartActions';
import { getOrderLineImage } from 'client/utils/productLineUtils';
import { goToUrl } from 'client/actions/routeActions';
import { removeOrderLineFromBasketDropdown } from 'client/actions/cartActions';
import {
  toggleBasketConfirmationDropdown,
  toggleBasketConfirmationLoader
} from 'client/actions/ui/toggleElementActions';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { formatPrice } from 'client/utils/priceUtils';
import { getTotalQuantity } from 'client/utils/cartUtils';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import NewStockMessage from 'client/components/elements/newStockMessage/NewStockMessage';
import SaleTag from '../promoLabel/SaleTag';
import { showLoginDialog } from 'client/actions/ui/dialogActions';
import QuantityErrorMessage from 'client/components/elements/quantityInput/quantityErrorMessage';
import { isValidQuantityWithMinMaxMultiplier } from '../../../utils/messageUtils';
import RemoveFromBasketConfirmation from './RemoveFromBasketConfirmation';
import { List } from 'immutable';

if (process.browser) {
  require('./BasketConfirmationDropdown.scss');
}

const NO_OF_ITEMS_FOR_NO_SCROLL = 3;
const closeBasketDropdownAndGoToUrl = (dispatchToggleBasketConfirmationDropdown, goToUrl, url, isLoggedIn, showLoginDialog) => {
  dispatchToggleBasketConfirmationDropdown(false);
  if (isLoggedIn) {
    return goToUrl(url);
  }
  showLoginDialog();
};

const getHeader = (cartLines, dispatchToggleBasketConfirmationDropdown) => {
  const cartLineQty = getTotalQuantity({ orderLines: cartLines.toJS(), bundles: [] });
  return (
    <div className="basketConfirmationDropdown_header">
      <div className="basketConfirmationDropdown_header-itemsText">
        <SvgLoader xlinkHref="cart-icon" className="basketConfirmationDropdown_header-cartIcon" />
        <span data-e2e="cart-product-count" className="basketConfirmationDropdown_header-noOfItems">{ getLocalizedStringWithParam('cart.basketConfirmation.itemsAddedToBasket', { qty: cartLineQty }) }</span>
      </div>
      <div>
        <SvgLoader xlinkHref="close-x" onClick={ () => dispatchToggleBasketConfirmationDropdown(false) } className="basketConfirmationDropdown_header-closeIcon" />
      </div>
    </div>
  );
};

const getActionButtons = ({ dispatchToggleBasketConfirmationDropdown, goToUrl, isLoggedIn, showLoginDialog }) => {
  return (<div className="basketConfirmationDropdown_actions">
    <SecondaryButton data-e2e="view-basket" onClick={ () => closeBasketDropdownAndGoToUrl(dispatchToggleBasketConfirmationDropdown, goToUrl, '/cart', true) } text={ getLocalizedString('cart.basketConfirmation.viewBasket') } className="basketConfirmationDropdown_actions-viewBasket"/>
    <PrimaryButton data-e2e="go-to-payment" onClick={ () => closeBasketDropdownAndGoToUrl(dispatchToggleBasketConfirmationDropdown, goToUrl, '/checkout/order', isLoggedIn, showLoginDialog) } text={ getLocalizedString('cart.basketConfirmation.goToCheckout') } className="basketConfirmationDropdown_actions-goToPayment"/>
  </div>);
};

const getCartLines = ({
  cartLines,
  setOrderLineQuantity,
  includeVat,
  skuRemoved = [],
  removeProductFromBasket,
  toggleRemoveProduct,
  removeOrderLineFromBasketDropdown,
  unleashToggles
}) => {
  return cartLines && cartLines.size ? cartLines.map((productLine) => {
    const sku = productLine.get('sku');
    const brandName = productLine.getIn(['brand', 'brandName'], 1);
    const name = productLine.get('name');
    const qty = productLine.get('quantity');
    const imageSrc = getOrderLineImage(productLine.toJS(), 'cart');
    const qtyMultiplier = productLine.getIn(['price', 'qtyMultiplier'], 1);
    const currency = productLine.getIn(['price', 'currency'], 'GBP');
    const cartLinePrice = productLine.get('orderLinePrice');
    const linePrice = cartLinePrice.get(includeVat ? 'orderLinePriceWithVat' : 'orderLinePriceWithoutVat');
    const linePriceCurrency = formatPrice(currency, linePrice);
    const productUrl = productLine.get('productUrl');
    const stockMessageToJS = productLine.getIn(['stock', 'stockMessage'], fromJS({})).toJS();
    const price = productLine.get('price');
    const minOrderQuantity = productLine.getIn(['stock', 'minOrderQuantity'], 1);
    const maxOrderQuantity = productLine.getIn(['stock', 'maxOrderQuantity'], 1);
    const isQuantityValid = isValidQuantityWithMinMaxMultiplier(qty, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    const isAppliedDiscounts = price && price.get('appliedDiscounts').size;
    const subTotalWithVat = (
      <div>
        <b>{ `${linePriceCurrency.symbol}${linePriceCurrency.amount} ` }</b>
        <span>{ getLocalizedString(includeVat ? 'incVat' : 'excVat') }</span>
      </div>
    );
    return (
      <div key={ sku } className="basketConfirmationDropdown_productLine-item">
        { skuRemoved.includes(sku)
          ? <RemoveFromBasketConfirmation
            toggleRemoveProduct={ toggleRemoveProduct }
            removeOrderLineFromBasketDropdown={ removeOrderLineFromBasketDropdown }
            sku={ sku }
          /> : null
        }
        <ZoroImage src={ imageSrc } alt={ name } title={ name } className="basketConfirmationDropdown_productLine-img"/>
        <div className='basketConfirmationDropdown_productLine-productInfo'>
          <div className="basketConfirmationDropdown_productLine-title">
            <a
              href={ productUrl }>
              { name }
            </a>
          </div>
          <div className='basketConfirmationDropdown_productLine-brand'>
              By <p className="basketConfirmationDropdown_productLine-brandName"> { brandName } </p>
          </div>
          <div className="basketConfirmationDropdown_productLine-price">
            { subTotalWithVat }
          </div>
          {isAppliedDiscounts ? <div className="basketConfirmationDropdown_productLine-promoLabel">
            { price.get('isFamilyPrice')
              ? <SaleTag label={ price.get('promoLabel') }
                promoUrl={ price.get('promoUrl') }
              />
              : null
            }
          </div> : null}
          <div className="basketConfirmationDropdown_productLine-stockMessage">
            <NewStockMessage
              stockStatus = { stockMessageToJS }
              sku = { sku }
            />
          </div>
          <div className="basketConfirmationDropdown_productLine-qty">
            <div>Qty</div>
            <QuantityInput
              disabled={ false }
              setQuantity={ setOrderLineQuantity }
              removeProductLine={ removeOrderLineFromBasketDropdown }
              product={ productLine }
              quantity={ qty }
              unavailableStatus={ isQuantityValid }
              eligibleForDeliveryOptions={ false }
              qtyMultiplier={ qtyMultiplier }
              displayQuantityDropdownAsModal = { false }
              closeMenuOnScroll ={ true }
            />
            <TapButton
              data-e2e="Remove-link"
              styles="basketConfirmationDropdown_productLine-remove"
              id={ `cart.orderLine.remove.${sku}` }
              onTap={ () => {
                removeProductFromBasket(sku);
              } }>
              { getLocalizedString('cart.orderLine.remove') }
            </TapButton>
            { !isQuantityValid
              ? <QuantityErrorMessage
                quantity= { qty }
                qtyMultiplier = { qtyMultiplier }
                shortMessage = { true }
                minOrderQuantity = { minOrderQuantity }
                maxOrderQuantity = { maxOrderQuantity }
              />
              : null
            }
          </div>
        </div>
      </div>);
  }).reverse() : null;
};

class BasketConfirmationDropdown extends PureComponent {
  constructor () {
    super();
    this.state = { isScrolled: false, left: 0, skuRemoved: [] };
    this.dialog = React.createRef();
  }

  componentDidMount () {
    const { dispatchLoadCart, dispatchToggleBasketConfirmationLoader, cartQty, cartLines } = this.props;
    if (!cartLines.size && cartQty) {
      dispatchLoadCart();
      dispatchToggleBasketConfirmationLoader(true);
    }

    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('scroll', this.scrollPosition, false);
    // eslint-disable-next-line react/no-find-dom-node

    const scrollPosition = window.scrollY;
    window.scrollTo({ top: scrollPosition - 1, left: 0, behaviour: 'smooth' });
  }

  componentWillUnmount () {
    this.props.dispatchToggleBasketConfirmationLoader(false);
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('scroll', this.scrollPosition, false);
  }

  scrollPosition = () => {
    const scrollPosition = window.scrollY;
    const HEADER_SCROLL_POSITION_OVERLAP = 60;

    if (scrollPosition > HEADER_SCROLL_POSITION_OVERLAP) {
      // eslint-disable-next-line react/no-find-dom-node
      const domNode = this.dialog;
      const rect = domNode.current.getBoundingClientRect();
      const left = rect.x;
      this.setState({
        isScrolled: true,
        left
      });
    }

    if (scrollPosition < HEADER_SCROLL_POSITION_OVERLAP) {
      this.setState({
        ...this.state,
        isScrolled: false
      });
    }
  }

  removeProductFromBasket = (sku) => {
    this.setState({
      ...this.state,
      skuRemoved: [...this.state.skuRemoved, sku]
    });
  }

  toggleRemoveProduct = (sku) => {
    this.setState({
      ...this.state,
      skuRemoved: this.state.skuRemoved.filter(el => el !== sku)
    });
  }

  handleOutsideClick = (e) => {
    const { dispatchToggleBasketConfirmationLoader, dispatchToggleBasketConfirmationDropdown } = this.props;
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = this.dialog;
    const isProductModal = e.path && e.path.find(e => e.className === 'modal-content'); // used for remove product modal
    const isCartHeaderButton = e.path && e.path.find(e => e.className && typeof e.className === 'string' && e.className.includes('new-cart-inner'));
    if (isCartHeaderButton) {
      return;
    }

    if ((!domNode || !domNode.current?.contains(e.target)) && !isProductModal) {
      dispatchToggleBasketConfirmationLoader(false);
      dispatchToggleBasketConfirmationDropdown(false);
    }
  }

  render () {
    const {
      cartPrice,
      cartLines,
      setOrderLineQuantity,
      dispatchToggleBasketConfirmationDropdown,
      cartQty,
      goToUrl,
      includeVat,
      isBasketConfirmationLoading,
      removeOrderLineFromBasketDropdown,
      currency,
      isLoggedIn,
      showLoginDialog,
      unleashToggles
    } = this.props;
    const { isScrolled, left } = this.state;

    const cartGrandTotalWithVat = cartPrice.get('cartOrderLinesTotalWithVat');
    const cartGrandTotalWithoutVat = cartPrice.get('cartOrderLinesTotalWithoutVat');
    const cartGrandTotal = includeVat ? cartGrandTotalWithVat : cartGrandTotalWithoutVat;
    const priceWithCurrency = formatPrice(currency, cartGrandTotal);

    if (isBasketConfirmationLoading) {
      return (
        <>
          <div className='basketConfirmationDropdown_backDrop' />
          <div style={ isScrolled ? { left } : {} } className={ `basketConfirmationDropdown basketConfirmationDropdown_stickyMainMenu ${isScrolled ? 'basketConfirmationDropdown_scrolled' : ''}` }>
            <div className='basketConfirmationDropdown_arrow' />
            <div className="basketConfirmationDropdown_loadingParent">
              {getLocalizedString('cart.basketConfirmation.pleaseWait')}
              <div className="basketConfirmationDropdown_loading" />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className='basketConfirmationDropdown_backDrop'/>
        <div style={ isScrolled ? { left } : {} } ref={ this.dialog } className={ `basketConfirmationDropdown basketConfirmationDropdown_stickyMainMenu ${isScrolled ? 'basketConfirmationDropdown_scrolled' : ''}` }>
          <div className='basketConfirmationDropdown_arrow' />
          {cartQty ? <>
            {cartLines.size ? <>
              {getHeader(cartLines, dispatchToggleBasketConfirmationDropdown)}
              <div className={ `basketConfirmationDropdown_productLine ${cartLines.size > NO_OF_ITEMS_FOR_NO_SCROLL ? 'basketConfirmationDropdown_productLine-withShadow' : ''}` }>
                {getCartLines({
                  cartLines,
                  setOrderLineQuantity,
                  includeVat,
                  skuRemoved: this.state.skuRemoved,
                  removeProductFromBasket: this.removeProductFromBasket,
                  toggleRemoveProduct: this.toggleRemoveProduct,
                  removeOrderLineFromBasketDropdown,
                  unleashToggles
                })}
              </div>
              <div className="basketConfirmationDropdown_basketTotal">
                <span className="basketConfirmationDropdown_basketTotal-basket">{getLocalizedString('cart.cartTotalizer.title')}:</span>
                <span className="basketConfirmationDropdown_basketTotal-basket"> {`${priceWithCurrency.symbol}${priceWithCurrency.amount}`}</span>
              </div>
              {getActionButtons({ dispatchToggleBasketConfirmationDropdown, goToUrl, isLoggedIn, showLoginDialog })}
            </> : null}
          </> : <div className="basketConfirmationDropdown_emptyBasket">
            <SvgLoader xlinkHref="close-x" onClick={ () => dispatchToggleBasketConfirmationDropdown(false) } className="basketConfirmationDropdown_header-emptyBasketcloseIcon" />
            <div className='basketConfirmationDropdown_emptyBasketContainer'>
              <p className='basketConfirmationDropdown_emptyBasketText'>{getLocalizedString('cart.basketConfirmation.basketEmpty')}</p>
              <SecondaryLink onClick={ () => dispatchToggleBasketConfirmationDropdown(false) } text={ getLocalizedString('cart.basketConfirmation.continueShopping') } className="basketConfirmationDropdown_actions-continueShopping" />
            </div>
          </div>}
        </div>
      </>);
  }
}

const mapStateToProps = (state) => ({
  cartPrice: state.getIn(['cart', 'cartPrice'], fromJS({})),
  currency: state.getIn(['cart', 'currency'], fromJS({})),
  cartLines: state.getIn(['cart', 'orderLines'], fromJS([])),
  cartQty: state.getIn(['cart', 'quantity'], 0),
  includeVat: state.getIn(['user', 'isPriceWithVat']),
  isBasketConfirmationLoading: state.getIn(['ui', 'toggleElement', 'showBaksetConfirmationLoader']),
  isLoggedIn: state.getIn(['auth', 'isLoggedIn']),
  unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS()

});

const mapDispatchToProps = (dispatch) => ({
  dispatchToggleBasketConfirmationDropdown: (flag) => dispatch(toggleBasketConfirmationDropdown(flag)),
  dispatchToggleBasketConfirmationLoader: (flag) => dispatch(toggleBasketConfirmationLoader(flag)),
  showLoginDialog: flowRight(dispatch, showLoginDialog),
  dispatchLoadCart: () => dispatch(loadCart({ basketDropDown: true })),
  setOrderLineQuantity: flowRight(dispatch, setOrderLineQuantity),
  goToUrl: (url) => dispatch(goToUrl(url)),
  removeOrderLineFromBasketDropdown: flowRight(dispatch, removeOrderLineFromBasketDropdown)
});

export { BasketConfirmationDropdown };
export default connect(mapStateToProps, mapDispatchToProps)(BasketConfirmationDropdown);
