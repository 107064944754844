import React from 'react';
import { fromJS } from 'immutable';
import CmsTextPanel from '../cmsTextPanel/CmsTextPanel';
import { CmsButton } from '../cmsButton/CmsButton';
import CmsTitlePanel from '../cmsTitlePanel/CmsTitlePanel';
import { nanoid } from 'nanoid';

const CmsMultiTextPanel = ({ data }) => {
  const BUTTON = 'BUTTON';
  const TITLE = 'TITLE';
  const textPanels = data.toJS();

  return (
    textPanels.map(textPanelData =>
      textPanelData.type === BUTTON
        ? <CmsButton
          key={ nanoid() }
          buttonCss={ textPanelData.buttonCss }
          href={ textPanelData.href }
          text={ textPanelData.text } />
        : textPanelData.type === TITLE
          ? <CmsTitlePanel
            key={ nanoid() }
            css={ textPanelData.css }
            text={ textPanelData.text } />
          : <CmsTextPanel
            key={ nanoid() }
            data={ fromJS(textPanelData) } />)
  );
};

export default CmsMultiTextPanel;
