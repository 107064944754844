// @Flow

import { put, takeLatest, call, select, take } from 'redux-saga/effects';

import {
  updateUserAddress,
  saveUserAddress,
  getUserAddressesAll,
  updateDefaultDeliveryAddress,
  saveBillingAddress,
  deleteAddressEndPoint
} from 'shared/endpoints/userEndpoint';

import { goToUrl } from 'client/actions/routeActions';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { toastSuccess, toastError } from 'client/actions/showNotificationsActions';

import {
  getUserAddressesSuccess,
  SAVE_ADDRESS,
  setAddress,
  GET_USER_ADDRESSES,
  VERIFY_ADDRESSES,
  getUserAddresses,
  SET_DEFAULT_ADDRESS,
  CHANGE_ADDRESS_FROM_ADDRESSBOOK,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  REQUEST_REMOVE_ADDRESS_CONFIRM,
  updateDefaultDeliveryId,
  verifyAddressCompleted,
  editAddress,
  GET_USER_ADDRESS
} from 'client/actions/addressActions';
import { logPersonalInfoAccess } from 'client/actions/accessLogActions';
import { getLocalizedString } from 'localization/localizer';
import {
  showRemoveAddressDialog,
  hideRemoveAddressDialog
} from 'client/actions/ui/dialogActions';
import { displayAddressForm, stopSubscription } from 'client/actions/repeatOrdersActions';
import {
  setActivePanelId,
  scrollToCheckoutSection,
  viewAddressBookToggle,
  displayAddressToggle,
  newDeliveryAddressToggle,
  deliveryAccordionLoading,
  setDeliveryAddressAccordionState,
  setBillingAddressAccordionState
} from 'client/actions/singlePageCheckoutActions';
import {
  deliveryAddressChanged
} from 'client/actions/singlePageCheckoutActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { loadCart } from '../actions/cartActions';

const TOASTER_DELAY = 4000;

export const CHECKOUT_DELIVERY_URL = '/checkout/order';
export const ADDRESS_BOOK_URL = '/my-account/address-book';
export const ADDRESS_BOOK_CHECKOUT_URL = '/checkout/address-book';

export function * getUserAddress ({ payload }) {
  const userAddresses = yield call(getUserAddressesAll, {
    addressesDisplayed: 'ALL',
    addressesSkip: 0
  });
  yield put(setAddress({
    addressType: payload.type,
    address: userAddresses.billingAddress
  }));
}

export function * requestUserAddresses ({ payload }) {
  const {
    addressesDisplayed,
    addressesSkip
  } = payload;
  const isLoggedInSelector = (state) => state.getIn(['auth', 'isLoggedIn']);
  const isUserLoggedIn = yield select(isLoggedInSelector);
  if (isUserLoggedIn) {
    const userAddresses = yield call(getUserAddressesAll, { addressesDisplayed, addressesSkip });
    yield put(getUserAddressesSuccess(userAddresses));
    yield * setDefaultAddresses(userAddresses);
    yield put(logPersonalInfoAccess());
  }
}

export function * verifyAddresses ({ payload }) {
  const {
    deliveryAddressId,
    billingAddressId,
    payOnAccount,
    isSinglePageCheckout
  } = payload;
  const currentDeliveryAddressSelector = (state) => state.getIn(['user', 'address', 'delivery', 'addressId'], null);
  const billingAddressSelector = (state) => state.getIn(['user', 'address', 'billing', 'addressId'], null);
  const tradeAccountAddressSelector = (state) => state.getIn(['user', 'accountDetails', 'tradeAccount', 'invoiceContact', '_id'], null);
  const currentDeliveryAddressId = yield select(currentDeliveryAddressSelector);
  const currentBillingAddressId = payOnAccount ? yield select(tradeAccountAddressSelector) : yield select(billingAddressSelector);
  if (!isSinglePageCheckout && (deliveryAddressId !== currentDeliveryAddressId || billingAddressId !== currentBillingAddressId)) {
    yield * setAddressesById(deliveryAddressId, billingAddressId, payOnAccount);
  }
  if (isSinglePageCheckout) {
    const requestUserAddressesPayload = {
      payload: {
        addressesDisplayed: 'ALL',
        addressesSkip: 0
      }
    };
    yield * requestUserAddresses(requestUserAddressesPayload);
    if (deliveryAddressId !== currentDeliveryAddressId) {
      yield put(deliveryAddressChanged());
    }
  }
  yield put(verifyAddressCompleted());
}

export function * setAddressesById (deliveryAddressId, billingAddressId, payOnAccount) {
  try {
    const userAddresses = yield call(getUserAddressesAll, { addressesDisplayed: 'ALL', addressesSkip: 0 });
    const deliveryAddressIndex = userAddresses.addresses.data.findIndex((address) => address.addressId === deliveryAddressId);
    const deliveryAddress = deliveryAddressIndex >= 0 ? userAddresses.addresses.data[deliveryAddressIndex] : null;
    const billingAddress = userAddresses.billingAddress && userAddresses.billingAddress.addressId === billingAddressId ? userAddresses.billingAddress : null;
    yield put(getUserAddressesSuccess(userAddresses));
    if (deliveryAddress === null || (!payOnAccount && !billingAddress)) {
      yield put(goToUrl('/cart'));
    } else {
      yield put(setAddress({
        addressType: 'delivery',
        address: deliveryAddress
      }));
      yield put(setAddress({
        addressType: 'billing',
        address: billingAddress
      }));
    }
  } catch (error) {
    throw Error('setAddressesById saga threw an error: ', error);
  }
}

export function * setDefaultAddresses (userAddresses) {
  const selectedDeliveryAddressSelector = (state) => state.getIn(['user', 'address', 'delivery'], null);
  const selectedDeliveryAddress = yield select(selectedDeliveryAddressSelector);
  const { addressId } = selectedDeliveryAddress ? selectedDeliveryAddress.toJS() : {};
  // if addressId is set, refetch address data from the store, because it could have changed
  const refetchedSelectedDeliveryAddress = userAddresses.addresses ? userAddresses.addresses.data.find((address) => address.addressId === addressId) : null;

  yield put(setAddress({
    addressType: 'delivery',
    address: refetchedSelectedDeliveryAddress || userAddresses.deliveryAddress
  }));

  yield put(setAddress({
    addressType: 'billing',
    address: userAddresses.billingAddress
  }));

  const viewAddressBookToggleSelector = (state) => state.getIn(['singlePageCheckout', 'viewAddressBook']);
  const isViewAddressBookToggle = yield select(viewAddressBookToggleSelector);
  if (!isViewAddressBookToggle && userAddresses.deliveryAddress) {
    yield put(displayAddressToggle(true));
  }
  yield put(deliveryAccordionLoading(false));
}

export function * updateAddress ({ payload }) {
  try {
    yield call(updateUserAddress, payload.address);
    if (payload.address.isDefault) {
      yield put(setAddress({
        addressType: 'delivery',
        address: null
      }));
    }
    yield put(toastSuccess(
      getToastMessage(
        getLocalizedString('myAccount.addressBook.addressUpdate.successMessage')
      ),
      'top-right', TOASTER_DELAY));
    yield put(editAddress({}));
    if (payload.redirectToUrl === 'singlePageCheckout') {
      yield put(newDeliveryAddressToggle(false));
      yield put(viewAddressBookToggle(true));
    } else {
      if (payload.redirectToUrl) {
        yield put(goToUrl(payload.redirectToUrl));
      }
    }
  } catch (error) {
    yield put(toastError(
      getToastMessage(
        error.message
      ),
      'top-right', TOASTER_DELAY));
  }
}

export function * saveAddress ({ payload }) {
  try {
    if (payload.addressType === 'billing') {
      yield call(saveBillingAddress, payload.address);
    } else {
      const newAddress = yield call(saveUserAddress, payload.address);
      yield put(loadCart({ addressId: newAddress.addressId }));
    }
    if (payload.addressType === 'delivery' && payload.address.isDefault) {
      yield put(setAddress({
        addressType: 'delivery',
        address: null
      }));
    }
    const url = payload.redirectToUrl;
    const shouldSetBillingAddress = url === SINGLE_PAGE_CHECKOUT_ROUTE && payload.isSkipUpdatePanelId && payload.addressType === 'billing';
    if (shouldSetBillingAddress) {
      yield put(setAddress({
        addressType: 'billing',
        address: payload.address
      }));
      return;
    }
    yield put(getUserAddresses({
      addressesDisplayed: 'ALL',
      addressesSkip: 0
    }));
    if (payload.fromRepeatOrder) {
      yield put(displayAddressForm());
    } else {
      if (url === SINGLE_PAGE_CHECKOUT_ROUTE) {
        const isSkip = payload.isSkipUpdatePanelId;
        if (!isSkip) {
          yield * handleRedirectForSinglePageCheckout(payload.addressType);
        }
      } else {
        if (payload.redirectToUrl) {
          yield put(goToUrl(payload.redirectToUrl));
        }
      }
    }
  } catch (error) {
    yield put(toastError(
      getToastMessage(
        error.message
      ),
      'top-right', TOASTER_DELAY));
  }
}

function * handleRedirectForSinglePageCheckout (addressType) {
  const activePanelIdSelector = (state) => state.getIn(['singlePageCheckout', 'activePanelId']);
  const currentPanelId = yield select(activePanelIdSelector);
  if (addressType === 'billing') {
    yield put(setBillingAddressAccordionState(true));
  }
  if (addressType === 'delivery') {
    yield put(setDeliveryAddressAccordionState(true));
  }
  yield put(newDeliveryAddressToggle(false));
  yield put(displayAddressToggle(true));
  yield put(scrollToCheckoutSection(true));
  if (currentPanelId !== '1') {
    const nextPanelId = parseInt(currentPanelId) + 1;
    yield put(setActivePanelId({ panelId: nextPanelId.toString() }));
  }
}

export function * onSetDefaultDeliveryAddress ({ payload }) {
  if (payload.addressId && !payload.isDefault) {
    yield call(updateDefaultDeliveryAddress, payload.addressId);
    yield put(toastSuccess(
      getToastMessage(
        getLocalizedString('myAccount.addressBook.deliveryAddressUpdate.successMessage')
      ),
      'top-right', TOASTER_DELAY));
    yield put(updateDefaultDeliveryId({
      addressId: payload.addressId
    }));
    yield put(setAddress({
      addressType: 'delivery',
      address: payload.address
    }));
  }
}

export function * onChangeAddressFromAddressBook ({ payload }) {
  if (payload.sendToThisAddressIndex !== null) {
    const addressListSelector = (state) => state.getIn(['user', 'address', 'deliveryAddresses', 'data']);
    const addressList = yield select(addressListSelector);
    const selectedAddress = addressList.get(payload.sendToThisAddressIndex);
    yield put(setAddress({
      address: selectedAddress,
      addressType: 'delivery'
    }));
    yield put(viewAddressBookToggle(false));
    yield put(displayAddressToggle(true));
    yield put(deliveryAddressChanged());
    yield put(goToUrl(payload.redirectUrl));
  }
}

export function * onDeleteAddress ({ payload }) {
  const { addressId, addressesDisplayed, addressesSkip } = payload;
  yield put(showRemoveAddressDialog(addressId));
  const dialogConfirmPayload = yield take(REQUEST_REMOVE_ADDRESS_CONFIRM);
  if (!dialogConfirmPayload) {
    return;
  }
  yield deleteAddress({ addressId, addressesDisplayed, addressesSkip });
}

export function * deleteAddress ({ addressId, addressesDisplayed, addressesSkip }) {
  yield call(deleteAddressEndPoint, addressId);
  yield put(hideRemoveAddressDialog());
  yield put(toastSuccess(
    getToastMessage(
      getLocalizedString('myAccount.addressBook.addressDelete.successMessage')
    ),
    'top-right', TOASTER_DELAY));
  yield put(stopSubscription({ addressId }));
  const currentDeliveryAddresssIdSelector = (state) => state.getIn(['user', 'address', 'delivery', 'addressId'], null);
  const currentDeliveryAddressId = yield select(currentDeliveryAddresssIdSelector);
  if (currentDeliveryAddressId === addressId) {
    yield put(setAddress({
      addressType: 'delivery',
      address: null
    }));
  }
  yield put(getUserAddresses({ addressesDisplayed, addressesSkip }));
}

export function * watchVerifyAddresses () {
  yield takeLatest(VERIFY_ADDRESSES, verifyAddresses);
}

export function * watchRequestGetUserAddresses () {
  yield takeLatest(GET_USER_ADDRESSES, requestUserAddresses);
}

export function * watchSaveAddress () {
  yield takeLatest(SAVE_ADDRESS, saveAddress);
}

export function * watchUpdateAddress () {
  yield takeLatest(UPDATE_ADDRESS, updateAddress);
}

export function * watchSetDefaultAddress () {
  yield takeLatest(SET_DEFAULT_ADDRESS, onSetDefaultDeliveryAddress);
}

export function * watchChangeAddressFromAddressBook () {
  yield takeLatest(CHANGE_ADDRESS_FROM_ADDRESSBOOK, onChangeAddressFromAddressBook);
}

export function * watchDeleteAddress () {
  yield takeLatest(DELETE_ADDRESS, onDeleteAddress);
}

export function * watchGetUserAddress () {
  yield takeLatest(GET_USER_ADDRESS, getUserAddress);
}
