import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setBraintreeApplePayInstance, setApplePayData } from 'client/actions/braintreeGatewayActions';
if (process.browser) {
  require('./buttons.scss');
}
import { updateDataLayer } from 'client/actions/dataLayerActions';

const PayByApplePayButton = ({
  braintreeEnvironment,
  setApplePayData,
  setBraintreeApplePayInstance,
  applePayInstance,
  deliveryAddress,
  onClick,
  cartTotal,
  updateDataLayer
}) => {
  useEffect(() => {
    setBraintreeApplePayInstance();
  }, []);

  const beginSession = () => {
    if (applePayInstance) {
      const paymentRequest = applePayInstance.createPaymentRequest({ total: {
        label: 'Zoro UK',
        amount: cartTotal.cartGrandTotalWithVat
      },
      requiredBillingContactFields: ['postalAddress']
      });

      if (window.ApplePaySession) {
        const hasActiveCard = window.ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
        hasActiveCard.then(function (canMakePayments) {
          if (!canMakePayments) {
            updateDataLayer({
              event: 'apple_pay_has_no_active_card'
            });
          }
        });
      }
      const session = new window.ApplePaySession(3, paymentRequest);
      session.onvalidatemerchant = function (event) {
        applePayInstance.performValidation({
          validationURL: event.validationURL,
          displayName: 'Zoro UK'
        }).then(function (merchantSession) {
          session.completeMerchantValidation(merchantSession);
        }).catch(function (validationErr) {
          // eslint-disable-next-line no-console
          console.log('apple.pay.payment.session.error', validationErr);
          session.abort();
        });
      };
      session.onpaymentauthorized = function (event) {
        applePayInstance.tokenize({
          token: event.payment.token
        }).then(function (data) {
          setApplePayData({ data: { ...data, billingAddress: event.payment.billingContact } });
          onClick();
          session.completePayment(session.STATUS_SUCCESS);
        }).catch(function (tokenizeErr) {
          // eslint-disable-next-line no-console
          console.log('apple.pay.payment.session.error', tokenizeErr);
          session.completePayment(session.STATUS_FAILURE);
        });
      };
      session.begin();
    }
  };

  return (
    <div>
      <button onClick={ () => beginSession() } className="apple-pay-button apple-pay-button-black" style={{
        width: '100%'
      }}/>
    </div>

  );
};

function mapStateToProps (state) {
  const addresses = state.getIn(['user', 'address']).toJS();
  return {
    deliveryAddress: addresses.delivery || {},
    braintreeEnvironment: state.getIn(['config', 'braintreeEnvironment']),
    applePayInstance: state.getIn(['braintree', 'applePay', 'instance']),
    cartTotal: state.getIn(['cart', 'cartPrice']).toJS()
  };
}

const mapDispatchToProps = {
  setApplePayData,
  setBraintreeApplePayInstance,
  updateDataLayer
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByApplePayButton);
