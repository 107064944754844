import React from 'react';
import { APPROVED, ONHOLD, UNVERIFIED } from 'shared/constants/account';
import { fromJS } from 'immutable';
import { getLocalizedString } from 'localization/localizer';

export const CsAgentBanner = ({
  user,
  csAgentName,
  terminateCustomerServiceAgentSession,
  csAgentDisplayName
}) => {
  const {
    tradeAccount,
    firstName,
    lastName
  } = user ? user.get('accountDetails', fromJS({}))?.toJS() : {};

  const { accountNo, status: accountStatus } = tradeAccount || {};
  return (
    <>
      {
        csAgentName
          ? <div className="Root_CustomerServiceSessionInfo" data-e2e="CustomerServiceSessionBar">
            <label>{getLocalizedString('csAgnet.banner.title')} {csAgentDisplayName ? `${csAgentDisplayName} (${csAgentName})` : csAgentName} -
              <a href="#" onClick={ () => { terminateCustomerServiceAgentSession(); } }> {getLocalizedString('myAccount.logout')}</a>
            </label>
            {
              accountNo && accountStatus ? <span>
                <span> | </span>
                <span>{getLocalizedString('myInvoice.accountNo')}: {firstName} {lastName} ({accountNo})</span>
                <span className={
                  `Root_CustomerServiceSessionInfo_badge${
                    accountStatus === APPROVED ? '_green' : [ONHOLD, UNVERIFIED ].includes(accountStatus) ? '_yellow' : '_red'}` }>
                  {accountStatus}
                </span>
              </span> : null
            }
          </div>
          : null
      }
    </>
  );
};
