export const APPROVED = 'APPROVED';
export const ACTIVE = 'ACTIVE';
export const INVITED = 'PENDING';
export const PAUSED = 'PAUSED';
export const DELETED = 'DELETED';
export const ONHOLD = 'ON-HOLD';
export const UNVERIFIED = 'UNVERIFIED';
export const PENDING = 'PENDING';
export const STANDARD = 'STANDARD';
export const USER = 'USER';
export const ADMIN = 'ADMIN';
export const FINANCE = 'FINANCE';
export const ACCOUNT_ROLES = ['ADMIN', 'USER', 'FINANCE'];
export const TRADE_ACCOUNT_INVITED_STATUSES = ['ACTIVE', 'PAUSED', 'DELETED'];
export const CREDIT = 'CREDIT';
export const MAX_CHAR_LIMIT = 15;
export const ALL_LINKS_USER = 'ALL_LINKS_USER';
export const LIMITED_LINKS_USER = 'LIMITED_LINKS_USER';

export const ORDERS = 'Orders';
export const INVOICES = 'Invoices';
export const ACCOUNT_STATEMENTS = 'Account Statements';
export const ACCOUNT_USERS = 'Account Users';
export const ADDRESS_BOOK = 'Address Book';
export const PAYMENT_DETAILS = 'Payment Details';
export const REFUND_AND_REPLACEMENT = 'Refund and Replacement';
export const QUOTATIONS = 'Quotations';
export const SCHEDULED_ORDERS = 'Scheduled Orders';
export const MARKETING_PREFERENCES = 'Marketing Preferences';

export const ORDERS_ICON = 'orders-icon';
export const INVOICES_ICON = 'invoice-icon';
export const STATEMENT_ICON = 'accountStatements-icon';
export const USERS_ICON = 'accountUsers-icon';
export const ADDRESS_BOOK_ICON = 'addressBook-icon';
export const SAVED_CARDS_ICON = 'card-icon';
export const MY_RETURNS_ICON = 'return-refund';
export const QUOTATIONS_ICON = 'quotations-icon';
export const REPEAT_ORDERS_ICON = 'subscriptions-icon';
export const EMAIL_PREFERENCES_ICON = 'marketingPrfs-icon';

export const menuLinks = {
  [ORDERS]: '/my-account/my-orders',
  [INVOICES]: '/my-account/my-invoices',
  [ACCOUNT_STATEMENTS]: '/my-account/account-statements',
  [ACCOUNT_USERS]: '/my-account/account-users',
  [ADDRESS_BOOK]: '/my-account/address-book',
  [PAYMENT_DETAILS]: '/my-account/saved-cards',
  [REFUND_AND_REPLACEMENT]: '/my-account/my-returns',
  [QUOTATIONS]: '/my-account/quotations',
  [SCHEDULED_ORDERS]: '/my-account/repeat-orders',
  [MARKETING_PREFERENCES]: '/my-account/email-preferences'
};

export const menuIcons = {
  [ORDERS]: ORDERS_ICON,
  [INVOICES]: INVOICES_ICON,
  [ACCOUNT_STATEMENTS]: STATEMENT_ICON,
  [ACCOUNT_USERS]: USERS_ICON,
  [ADDRESS_BOOK]: ADDRESS_BOOK_ICON,
  [PAYMENT_DETAILS]: SAVED_CARDS_ICON,
  [REFUND_AND_REPLACEMENT]: MY_RETURNS_ICON,
  [QUOTATIONS]: QUOTATIONS_ICON,
  [SCHEDULED_ORDERS]: REPEAT_ORDERS_ICON,
  [MARKETING_PREFERENCES]: EMAIL_PREFERENCES_ICON
};

export const menus = {
  ALL_LINKS_USER: [
    ORDERS,
    INVOICES,
    ACCOUNT_STATEMENTS,
    ACCOUNT_USERS,
    ADDRESS_BOOK,
    PAYMENT_DETAILS,
    REFUND_AND_REPLACEMENT,
    QUOTATIONS,
    SCHEDULED_ORDERS,
    MARKETING_PREFERENCES
  ],
  LIMITED_LINKS_USER: [
    ORDERS,
    INVOICES,
    ADDRESS_BOOK,
    PAYMENT_DETAILS,
    REFUND_AND_REPLACEMENT,
    QUOTATIONS,
    SCHEDULED_ORDERS,
    MARKETING_PREFERENCES
  ]
};

export const menuIds = {
  [ORDERS]: {
    headerId: 'HeaderMenu.goToMyOrdersButton',
    id: 'myAccount.menu.myOrders',
    class: 'MyAccount_menuLink'
  },
  [INVOICES]: {
    headerId: 'HeaderMenu.goToMyInvoices',
    id: 'myAccount.menu.myInvoices',
    class: 'MyAccount_menuLink'
  },
  [ACCOUNT_STATEMENTS]: {
    headerId: 'HeaderMenu.goToAccountStatements',
    id: 'myAccount.menu.accountStatements',
    class: 'MyAccount_menuLink',
    svgLoaderId: 'statementSvg'
  },
  [ACCOUNT_USERS]: {
    headerId: 'HeaderMenu.goToAccountUsers',
    id: 'myAccount.menu.accountUsers'
  },
  [ADDRESS_BOOK]: {
    headerId: 'HeaderMenu.goToAddressBook',
    id: 'myAccount.menu.addressBook',
    class: 'MyAccount_menuLink'
  },
  [PAYMENT_DETAILS]: {
    headerId: 'HeaderMenu.goToSavedCards',
    id: 'myAccount.menu.savedCards',
    class: 'MyAccount_menuLink'
  },
  [REFUND_AND_REPLACEMENT]: {
    headerId: 'HeaderMenu.goToMyReturns',
    id: 'myAccount.menu.returns',
    class: 'MyAccount_menuLink'
  },
  [QUOTATIONS]: {
    headerId: 'HeaderMenu.goToQuotations',
    id: 'myAccount.menu.quotations',
    class: 'MyAccount_menuLink'
  },
  [SCHEDULED_ORDERS]: {
    headerId: 'HeaderMenu.goToRepeatOrders',
    id: 'myAccount.menu.repeatOrders',
    class: 'MyAccount_menuLink'
  },
  [MARKETING_PREFERENCES]: {
    headerId: 'HeaderMenu.goToMyEmailPreferencesButton',
    id: 'myAccount.menu.emailPreferences',
    class: 'MyAccount_menuLink'
  }
};

export const LinksMap = [];
export const PathClassName = [PAYMENT_DETAILS, SCHEDULED_ORDERS];
