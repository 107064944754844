import React from 'react';

if (process.browser) {
  require('./CmsImage.scss');
}

export default class CmsImage extends React.Component {
  render () {
    const data = this.props.data;
    const imgSrc = data.get('imgSrc');
    const imgUrl = data.get('imgUrl');

    if (imgUrl) {
      return (
        <div className="CmsImage">
          <a href={ imgUrl }>
            <img
              className="CmsImage__img CmsImage__img--clickable"
              src={ imgSrc }
              style={{ 'width': '100%' }}/>
          </a>
        </div>
      );
    }
    return (
      <div className="CmsImage">
        <img
          className="CmsImage__img"
          src={ imgSrc }
          style={{ 'width': '100%' }}/>
      </div>
    );
  }
}
