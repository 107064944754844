export const DEFAULT_SHOW_ACCORDION = false;
export const AB_MBOX_RECOMMENDATIONS = 'recommendations';
export const DEFAULT_AB_PRICE_COLUMN_TO_LEFT = false;
export const DEFAULT_SHOW_FAMILY_RECOMMENDATIONS_ON_CHECKOUT = true;
export const DEFAULT_AB_SELECTED_HEADER = 'NEW';
export const UNLEASH_VIEW_TYPE_FEATURE_TOGGLE = 'SearchPage-ViewType';
export const UNLEASH_APPLE_PAY_FEATURE_TOGGLE = 'Payment-ApplePay';
export const UNLEASH_APPLE_PAY_SHOW = 'show';
export const UNLEASH_BEFORE_YOU_GO_RECOMMENDATIONS_FEATURE_TOGGLE = 'CheckoutPage-BeforeYouGo';
export const UNLEASH_BEFORE_YOU_GO_RECOMMENDATIONS_SHOW = 'show';
export const UNLEASH_BOOST_TYPE = 'SearchPage-BoostType';
export const UNLEASH_SEARCH_TEMPLATE_FEATURE_TOGGLE = 'search-templates';
export const UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE = 'Header-AddToBasket';
export const BASKET_DROPDOWN_VARIANT = 'Dropdown';
export const UNLEASH_APPLY_FOR_BUSINESS_ACCOUNT_CART = 'CartPage-ApplyForBusinessAccount';
export const UNLEASH_APPLY_FOR_BUSINESS_ACCOUNT_CHECKOUT = 'CheckoutPage-ApplyForBusinessAccount';
export const DEFAULT_INFO_DISCOUNT_EXPERIMENT = 'experiment_1';
export const UNLEASH_CMS_EXPERIMENTS = [
  {
    toggleName: 'InfoPage-DiscountCodes',
    url: 'discount-codes'
  }
];
export const SPECIAL_PRICE_HIGHLIGHT = 'Special-Price-highlight';
export const SHOW = 'show';
export const HIDE = 'hide';
export const UNLEASH_DISCOUNT_PROMOLABEL = 'Discount-PromoLabel';
export const UNLEASH_CARTPAGE_SHOW_DISCOUNT_INPUT = 'CartPage-ShowDiscountInput';
export const UNLEASH_SHOW_ONHOLD_MODAL = 'PendingOrUnverifiedModal';
export const RECOMMENDATIONS_FEATURE_TOGGLE = 'Recommendations';
export const BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE = 'Checkout_BeforeYouGo_ProductCount';
