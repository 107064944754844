import request from 'superagent';
import { ApiRequest } from 'shared/utils/apiUtils';

const BASE_PRODUCT_URL = 'https://api.feefo.com/api/10/reviews/product?merchant_identifier=zoro';
const BASE_SUMMARY_URL = 'https://api.feefo.com/api/10/reviews/summary/product?merchant_identifier=zoro';
const BASE_SUMMARY_SERVICE_URL = 'https://api.feefo.com/api/10/reviews/summary/service?merchant_identifier=zoro';
export const STORE_BADGE_URL = 'https://api.feefo.com/api/logo?merchantidentifier=zoro&template=Service-Stars-Yellow-Independent-225x57.png';

export function getProductReviews (familyId) {
  const endPoint = `${BASE_PRODUCT_URL}&parent_product_sku=${familyId}`;
  return request
    .get(endPoint);
}

export function getProductAverageRating (familyId) {
  const endPoint = `${BASE_SUMMARY_URL}&parent_product_sku=${familyId}`;
  return request
    .get(endPoint);
}

export function getServiceAverageRating () {
  const endPoint = BASE_SUMMARY_SERVICE_URL;
  return request
    .get(endPoint);
}
export function getServiceReviews (paginationData) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/feefoReviews',
    query: paginationData
  });
  return req.executeAndGetBody();
}
