export const TRY_UNSUBSCRIBE = 'TRY_UNSUBSCRIBE';
export const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE';

export function tryUnsubscribe (customerId, unsubscribeToken, emailType) {
  return {
    type: TRY_UNSUBSCRIBE,
    customerId,
    emailType,
    unsubscribeToken
  };
}

export function setUnsubscribeState (status) {
  return {
    type: SET_UNSUBSCRIBE,
    status
  };
}
