import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemovePaymentMethodDialog } from 'client/actions/ui/dialogActions.js';
import { requestRemovePaymentMethodConfirm } from 'client/actions/mySavedCardsScreenActions.js';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms/formButtons.js';
import { getLocalizedString } from 'localization/localizer';
if (process.browser) {
  require('./RemovePaymentMethodDialog.scss');
}

function mapStateToProps (state) {
  return {
    removePaymentMethodDialogIsVisible: state.getIn(['ui', 'dialogs', 'removePaymentMethod', 'isVisible']),
    cardType: state.getIn(['ui', 'dialogs', 'removePaymentMethod', 'cardType']),
    cardLastFourDigits: state.getIn(['ui', 'dialogs', 'removePaymentMethod', 'cardLastFourDigits'])

  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemovePaymentMethodDialog: flowRight(dispatch, hideRemovePaymentMethodDialog),
    requestRemovePaymentMethodConfirm: flowRight(dispatch, requestRemovePaymentMethodConfirm)
  };
}

const RemovePaymentMethodDialog = (props) => {
  const {
    removePaymentMethodDialogIsVisible,
    requestRemovePaymentMethodConfirm,
    hideRemovePaymentMethodDialog,
    cardType,
    cardLastFourDigits
  } = props;
  const cardLabel = `${cardType} ${getLocalizedString('paymentMethodDialog.confirmText.cardLabelText')} ${cardLastFourDigits}`;
  return (
    <Modal className="RemovePaymentMethodDialog" show={ removePaymentMethodDialogIsVisible }
      onHide={ hideRemovePaymentMethodDialog }>
      <Modal.Header className="RemovePaymentMethodDialog_header" closeButton/>
      <Modal.Body>
        <div className="product modal-body_content">
          <Fragment>
            { getLocalizedString('paymentMethodDialog.confirmText.deletePaymentMethodWarning') }
            <label><b>{cardLabel}</b></label>
            <br />
            { getLocalizedString('paymentMethodDialog.confirmText.paymentMethodWarningNote') }
          </Fragment>
        </div>
        <div className="modal-body_options">
          <SecondaryButton
            value={ getLocalizedString('cart.dialog.cancel.button') }
            text={ getLocalizedString('cart.dialog.cancel.button') }
            className="RemovePaymentMethodDialog_buttonMarginRight"
            onClick={ hideRemovePaymentMethodDialog } />
          <PrimaryButton
            text={ getLocalizedString('cart.orderLine.remove') }
            className="RemovePaymentMethodDialog_buttonMargin"
            onClick={ requestRemovePaymentMethodConfirm }
            value={ getLocalizedString('cart.orderLine.remove') }
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

RemovePaymentMethodDialog.propTypes = {
  removePaymentMethodDialogIsVisible: PropTypes.bool,
  hideRemovePaymentMethodDialog: PropTypes.func,
  requestRemovePaymentMethodConfirm: PropTypes.func,
  cardType: PropTypes.string,
  cardLastFourDigits: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(RemovePaymentMethodDialog);
