import { put, takeLatest, take, call, race, fork, all, select } from 'redux-saga/effects';
import { delay } from 'shared/utils/sagaUtils';
import {
  CREATE_QUOTATION,
  createQuotationSuccess,
  createQuotationError,
  FETCH_QUOTATIONS,
  fetchQuotationsSuccess,
  ADD_PRODUCT_TO_QUOTATION,
  addToQuotationSuccess,
  addToQuotationError,
  FETCH_QUOTATION_DETAILS,
  fetchQuotationDetailsSuccess,
  REMOVE_PRODUCT_FROM_QUOTATION_CONFIRM,
  removeProductFromQuotationSuccess,
  FETCH_QUOTATION_PRODUCT_STOCK_INFO,
  fetchQuotationProductStockInfoSuccess,
  ADD_QUOTATION_TO_CART,
  UPDATE_QUOTATION_PRODUCT_QTY,
  updateQuotationProductQtySuccess,
  SEND_QUOTATION_EMAIL,
  DELETE_QUOTATION_CONFIRM,
  LOCK_QUOTATION,
  REQUEST_REMOVE_QUOTE_LINE_CONFIRM,
  REQUEST_REMOVE_QUOTATION_CONFIRM,
  deleteQuotationSuccess,
  REQUEST_CHANGE_IN_QUOTE_LINE_QTY,
  CHANGE_DELIVERY_OPTION_FROM_QUOTATION,
  fetchQuotationDetails as fetchQuotationDetailsAction,
  INIT_QUOTATION_DETAILS,
  changeDeliveryOptionFromQuotation,
  updateQuotationOnChange,
  INIT_QUOTATIONS,
  quotationSuccess,
  initQuotations
} from 'client/actions/quotationsActions';
import {
  addToCart,
  RECIEVE_ADD_TO_CART_SUCCESS,
  RECIEVE_ADD_TO_CART_ERROR
} from 'client/actions/cartActions';

import {
  toggleDeliveryOptionAccordion,
  updatingOptionLoader
} from 'client/actions/orderReviewActions';

import { updateDataLayer } from 'client/actions/dataLayerActions';
import {
  showCustomAlertDialog,
  showRemoveQuoteLineDialog,
  hideRemoveQuoteLineDialog,
  showRemoveQuotationDialog,
  hideRemoveQuotationDialog
} from 'client/actions/ui/dialogActions';

import {
  updateGlobalQuotationstock,
  ADD_QUOTE_LINE_TO_BASKET
} from 'client/actions/globalQuotationActions';

import {
  createQuotation as createQuotationEndpoint,
  fetchQuotations as fetchQuotationsEndpoint,
  fetchQuotationDetails as fetchQuotationDetailsEndpoint,
  addProductsToQuotation as addProductsToQuotationEndpoint,
  removeProductFromQuotation as removeProductFromQuotationEndpoint,
  updateQuotationProductQty as updateQuotationProductQtyEndpoint,
  sendQuotationEmail as sendQuotationEmailEndpoint,
  deleteQuotation as deleteQuotationEndPoint,
  lockQuotation as lockQuotationEndPoint,
  loadQuotations as loadQuotationEndPoint,
  addQuoteToBasketEndpoint
} from '../../shared/endpoints/quotationsEndpoint';
import { getStockInfo } from 'shared/endpoints/productVariantEndpoint';
import { newBreakPriceObject, isEqualObject } from 'client/utils/priceUtils';
import { addProductLineItems, updatingQuantity } from 'client/actions/productLineActions';
import { toProductLineItems } from 'client/utils/productLineUtils';
import { goToUrl, gotoExternalUrl } from 'client/actions/routeActions';
import { calculateZoroPromoRounding } from '../utils/discountUtils';
import _find from 'lodash/find';
import _inRange from 'lodash/inRange';
import _isEqual from 'lodash/isEqual';
import { toastWarn, toastSuccess } from 'client/actions/showNotificationsActions';
import { fromJS } from 'immutable';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedStringWithParam } from 'localization/localizer';

const quotationStateAction = (state) => state.getIn(['quotations', 'quotationState'], fromJS({})).toJS();

export const prepareToastMessage = (breakPrice, type) => {
  const discount = breakPrice.breakDiscount;
  const discountToRounded = calculateZoroPromoRounding(discount);
  return (type === 'error'
    ? getToastMessage(
      getLocalizedStringWithParam('cart.toast.message.discount.loss', { discountToRounded })
    )
    : getToastMessage(
      getLocalizedStringWithParam('cart.toast.message.discount.got', { discountToRounded })
    )
  );
};

export function * createQuotation ({ quotationName, products }) {
  try {
    const createdQuote = yield call(createQuotationEndpoint, quotationName, products);
    yield call(lockQuotationEndPoint, createdQuote._id);
    yield put(createQuotationSuccess());
    yield put(updateDataLayer({
      event: 'add_products_to_new_quotation',
      quotationName,
      ecommerce: {
        add: {
          products: products.toJS()
            .map((p) => ({ id: p.sku }))
        }
      }
    }));
  } catch (e) {
    yield put(createQuotationError(e));
  }
}

export function * fetchQuotations () {
  const quotations = yield call(fetchQuotationsEndpoint);
  yield put(fetchQuotationsSuccess(quotations));
}

export function * fetchQuotationDetails ({ quotationId, eligibleForDeliveryOptions, addressId }) {
  const result = yield call(fetchQuotationDetailsEndpoint, quotationId, eligibleForDeliveryOptions, undefined, undefined, addressId);
  yield put(fetchQuotationDetailsSuccess(result));
  yield put(addProductLineItems(toProductLineItems(result, 'quotations')));
}

export function * addProductsToQuotation ({ quotationId, products }) {
  try {
    yield call(addProductsToQuotationEndpoint, quotationId, products);
    yield put(addToQuotationSuccess());
    yield put(updateDataLayer({
      event: 'add_products_to_existing_quotation',
      quotationId,
      ecommerce: {
        add: {
          products: products.toJS()
            .map((p) => ({ id: p.sku }))
        }
      }
    }));
  } catch (e) {
    yield put(addToQuotationError());
  }
}

export function * removeProductFromQuotationConfirm ({ quotationId, product, delQuotation }) {
  yield put(showRemoveQuoteLineDialog(product, delQuotation));
  const payload = yield take(REQUEST_REMOVE_QUOTE_LINE_CONFIRM);
  if (!payload) {
    return;
  }
  if (delQuotation) {
    yield deleteQuotation({ quotationId: quotationId, page: 'detail_page' });
    yield put(hideRemoveQuoteLineDialog());
  } else {
    yield removeProductFromQuotation({ quotationId: quotationId, sku: product.get('sku') });
    yield fetchQuotationDetails({ quotationId });
  }
}

export function * removeProductFromQuotation ({ quotationId, sku }) {
  yield call(removeProductFromQuotationEndpoint, quotationId, sku);
  yield put(removeProductFromQuotationSuccess(quotationId, sku));
  yield put(hideRemoveQuoteLineDialog());
  // yield fetchQuotationDetails({ quotationId });
  yield put(updateDataLayer({
    event: 'remove_product_from_quotation',
    quotationId,
    sku
  }));
  yield put(showCustomAlertDialog('Product has been removed from quotation.'));
}

export function * fetchQuotationProductStockInfo ({ quotationId, sku, qty, isQuoteGlobal }) {
  const stockInfo = yield call(getStockInfo, sku, qty);
  yield put(fetchQuotationProductStockInfoSuccess(quotationId, sku, stockInfo, isQuoteGlobal));
  if (isQuoteGlobal) {
    yield put(updateGlobalQuotationstock(quotationId, sku, stockInfo.stockMessage));
  }
}

export function * addQuotationToCart ({ quotationId, orderLines }) {
  yield put(addToCart({ orderLinesToBeAdded: orderLines }));
  const { success } = yield race({
    success: take(RECIEVE_ADD_TO_CART_SUCCESS),
    error: take(RECIEVE_ADD_TO_CART_ERROR),
    timeout: call(delay)
  });
  if (success) {
    yield put(showCustomAlertDialog('This quotation has been added to the basket.'));
    yield put(updateDataLayer({
      event: 'add_quotation_to_basket',
      quotationId,
      ecommerce: {
        add: {
          products: orderLines.toJS()
            .map((p) => ({ id: p.sku, quantity: p.qty }))
        }
      }
    }));
  } else {
    yield put(showCustomAlertDialog('There was an error adding the quotation to the basket.'));
  }
}

export function * updateQuotationProductQty ({ quotationId, sku, qty, breakPrices }) {
  const result = yield call(updateQuotationProductQtyEndpoint, quotationId, sku, qty);
  yield put(updateQuotationProductQtySuccess(quotationId, sku, qty, breakPrices));
  yield put(addProductLineItems(toProductLineItems(result, 'quotations')));
  yield put(updatingQuantity(sku, false));
  if (result.quotationPrice) {
    yield put(updateQuotationOnChange(quotationId, result.quotationPrice, result.orderLines));
  }
}

export function * sendQuotationEmail ({ quotationId }) {
  try {
    yield call(sendQuotationEmailEndpoint, quotationId);
    yield put(showCustomAlertDialog('The quotation has been sent to your email address.'));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR sending the quotation email.'));
  }
}

export function * deleteQuotationConfirm ({ quotationId, page }) {
  yield put(showRemoveQuotationDialog(quotationId));
  const payload = yield take(REQUEST_REMOVE_QUOTATION_CONFIRM);
  if (!payload) {
    return;
  }
  yield deleteQuotation({ quotationId, page });
}

export function * deleteQuotation ({ quotationId, page }) {
  try {
    yield call(deleteQuotationEndPoint, quotationId);
    if (page === 'detail_page') {
      yield put(gotoExternalUrl('/my-account/quotations'));
    } else if (page === 'list_page') {
      yield put(deleteQuotationSuccess(quotationId));
      const quotationState = yield select(quotationStateAction);
      yield put(initQuotations({ ...quotationState }));
    }
    yield put(hideRemoveQuotationDialog());
    yield put(showCustomAlertDialog('The quotation has been deleted.'));
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    yield put(showCustomAlertDialog('There was an ERROR deleting the quotation.'));
  }
}

export function * lockQuotation ({ quotationId, page }) {
  yield call(lockQuotationEndPoint, quotationId);
  if (page === 'detail_page') {
    yield fetchQuotationDetails({ quotationId });
  } else if (page === 'list_page') {
    const quotationState = yield select(quotationStateAction);
    yield put(initQuotations({ ...quotationState }));
  }
  yield put(showCustomAlertDialog('The quotation has been locked.'));
}

export const getSelectedBreakPriceObject = (qty, breakPrices) => {
  return _find(breakPrices, (breakPrice) => {
    return _inRange(qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
  });
};

export const showBanner = (prevQty, qty, breakPrices) => {
  const foundNewBreakPriceObject = newBreakPriceObject(qty, breakPrices);
  if (qty < prevQty && !_isEqual(getSelectedBreakPriceObject(prevQty, breakPrices), getSelectedBreakPriceObject(qty, breakPrices))) {
    return 'error';
  } else if (qty !== 0 &&
    foundNewBreakPriceObject &&
    foundNewBreakPriceObject.breakDiscount &&
    foundNewBreakPriceObject.breakDiscount !== 0 &&
    foundNewBreakPriceObject.breakDiscount !== undefined &&
    !isEqualObject(getSelectedBreakPriceObject(prevQty, breakPrices), foundNewBreakPriceObject)) {
    return 'success';
  }
  return '';
};

const TOATSER_DELAY = 5000;

export function * requestChangeInQuoteLineQty ({ quotationId, prevQty, qty, sku, breakPrices }) {
  yield put(updatingQuantity(sku, true));
  const foundNewBreakPriceObject = newBreakPriceObject(qty, breakPrices);
  if (showBanner(prevQty, qty, breakPrices) === 'error') {
    yield put(
      toastWarn(
        prepareToastMessage(getSelectedBreakPriceObject(prevQty, breakPrices), 'error'),
        'top-right',
        TOATSER_DELAY
      ));
  } else if (showBanner(prevQty, qty, breakPrices) === 'success') {
    yield put(
      toastSuccess(
        prepareToastMessage(foundNewBreakPriceObject, 'success'),
        'top-right',
        TOATSER_DELAY
      ));
  }
  yield updateQuotationProductQty({ quotationId, sku, qty, breakPrices });
}

export function * watchCreateQuotation () {
  yield takeLatest(CREATE_QUOTATION, createQuotation);
}

export function * watchFetchQuotations () {
  yield takeLatest(FETCH_QUOTATIONS, fetchQuotations);
}

export function * watchFetchQuotationDetails () {
  yield takeLatest(FETCH_QUOTATION_DETAILS, fetchQuotationDetails);
}

export function * watchAddProductToQuotation () {
  yield takeLatest(ADD_PRODUCT_TO_QUOTATION, addProductsToQuotation);
}

export function * watchRemoveProductFromQuotation () {
  yield takeLatest(REMOVE_PRODUCT_FROM_QUOTATION_CONFIRM, removeProductFromQuotationConfirm);
}

export function * watchRequestRemoveQuoteLine () {
  yield take(REQUEST_REMOVE_QUOTE_LINE_CONFIRM);
}

export function * watchFetchQuotationProductStockInfo () {
  yield takeLatest(FETCH_QUOTATION_PRODUCT_STOCK_INFO, fetchQuotationProductStockInfo);
}

export function * watchAddQuotationToCart () {
  yield takeLatest(ADD_QUOTATION_TO_CART, addQuotationToCart);
}

export function * watchUpdateQuotationProductQty () {
  yield takeLatest(UPDATE_QUOTATION_PRODUCT_QTY, updateQuotationProductQty);
}

export function * watchSendQuotationEmail () {
  yield takeLatest(SEND_QUOTATION_EMAIL, sendQuotationEmail);
}

export function * watchDeleteQuotation () {
  yield takeLatest(DELETE_QUOTATION_CONFIRM, deleteQuotationConfirm);
}

export function * watchLockQuotation () {
  yield takeLatest(LOCK_QUOTATION, lockQuotation);
}

export function * watchChangeInQuoteLineQty () {
  yield takeLatest(REQUEST_CHANGE_IN_QUOTE_LINE_QTY, requestChangeInQuoteLineQty);
}

export function * requestChangeDeliveryOptionFromQuotation ({ payload: { optionType, eligibleForDeliveryOptions }, quotationId }) {
  yield put(updatingOptionLoader(true));

  const quotationProductSelector = (state) => state.getIn(['quotations', 'details', quotationId, 'products'], []);
  const quotationProducts = (yield select(quotationProductSelector)) || [];
  const products = quotationProducts.reduce((products, orderLine) => {
    const optionList = orderLine.getIn(['deliveryOptionList', 'optionList'], fromJS([]));
    if (optionList.size) {
      products.push({
        sku: orderLine.get('sku'),
        qty: 0,
        selectedDeliveryOption: optionType || orderLine.getIn(['deliveryOptionList', 'selectedDeliveryOption'])
      });
    }
    return products;
  }, []);
  try {
    yield call(addProductsToQuotationEndpoint, quotationId, products);
  } catch (e) {
    yield put(updatingOptionLoader(false));
    yield put(addToQuotationError());
  }
  yield put(fetchQuotationDetailsAction(quotationId, eligibleForDeliveryOptions));
  yield take(fetchQuotationDetailsSuccess);
  yield put(toggleDeliveryOptionAccordion(''));
  yield put(updatingOptionLoader(false));
}

export function * watchChangeDeliveryOptionFromQuotation () {
  yield takeLatest(CHANGE_DELIVERY_OPTION_FROM_QUOTATION, requestChangeDeliveryOptionFromQuotation);
}

export function * requestInitQuotationDetails ({ quotationId, eligibleForDeliveryOptions, addressId }) {
  yield put(fetchQuotationDetailsAction(quotationId, eligibleForDeliveryOptions, addressId));
  if (eligibleForDeliveryOptions) {
    yield take(addProductLineItems);
    const payload = { eligibleForDeliveryOptions };
    yield put(changeDeliveryOptionFromQuotation(payload, quotationId));
  }
}
export function * watchInitQuotationDetails () {
  yield takeLatest(INIT_QUOTATION_DETAILS, requestInitQuotationDetails);
}

export function * requestQuotations ({ payload }) {
  const quotations = yield call(loadQuotationEndPoint, payload);
  yield put(quotationSuccess(quotations));
}

export function * watchInitQuotation () {
  yield takeLatest(INIT_QUOTATIONS, requestQuotations);
}

export function * addQuoteToBasket ({ quotationId }) {
  try {
    yield call(addQuoteToBasketEndpoint, quotationId);
    yield put(goToUrl('/cart'));
  } catch (e) {
    yield put(showCustomAlertDialog('There was an ERROR.'));
  }
}

export function * watchAddQuoteToBasket () {
  yield takeLatest(ADD_QUOTE_LINE_TO_BASKET, addQuoteToBasket);
}

export function * watchAllQuotationsActions () {
  yield all([
    fork(watchCreateQuotation),
    fork(watchFetchQuotations),
    fork(watchFetchQuotationDetails),
    fork(watchAddProductToQuotation),
    fork(watchRemoveProductFromQuotation),
    fork(watchRequestRemoveQuoteLine),
    fork(watchFetchQuotationProductStockInfo),
    fork(watchAddQuotationToCart),
    fork(watchUpdateQuotationProductQty),
    fork(watchSendQuotationEmail),
    fork(watchDeleteQuotation),
    fork(watchLockQuotation),
    fork(watchChangeInQuoteLineQty),
    fork(watchChangeDeliveryOptionFromQuotation),
    fork(watchInitQuotationDetails),
    fork(watchInitQuotation),
    fork(watchAddQuoteToBasket)
  ]);
}
