import { all, call, fork, put, takeLatest, select, take } from 'redux-saga/effects';
import { createNonce, updateDefaultPaymentMethod } from 'shared/endpoints/braintreeEndpoint';
import {
  INITIALIZE,
  PAY_BY_EXISTING_CARD,
  SET_DEFAULT_CARD,
  setSelectedCard,
  SUBSCRIBE_BY_EXISTING_CARD
} from './payByExistingCardActions';
import { getExistingCards, SET_EXISTING_CARDS } from 'client/actions/braintreeGatewayActions';
import { braintreeSelectors, orderSelectors, paymentMethodSelectors } from '../shared/selectors';
import { verifyStoredCardByCvv } from 'client/sagas/braintreeCvvCheckSagas';
import { getLocalizedString } from 'localization/localizer';
import { getDeviceData, verifyCardWith3ds } from 'client/sagas/braintreeGatewaySagas';
import { SAVED_CARD } from 'shared/constants/braintree';
import { CARD as SUBSCRIPTION_CARD } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { postOrderWithPaymentInformation } from 'shared/endpoints/ordersEndpoint';
import { createOrder, handleResponse, orderFailed } from '../shared/orderHelpers';
import { toastError } from 'client/actions/showNotificationsActions';
import { createSubscriptionFailed, createSubscriptionSuccess } from 'client/actions/repeatOrdersActions';
import { createSubscription } from 'client/utils/subscriptionUtils';
import { createRepeatOrder as createRepeatOrderEndPoint } from 'shared/endpoints/repeatOrdersEndpoint';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';

export const selectedCardSelector = (selector) => (state) => state.getIn(['ui', 'payByExistingCard', selector, 'selectedCard']).toJS();

export function * setDefaultCard ({ payload }) {
  const selectedCard = yield select(selectedCardSelector(payload.selector));
  try {
    yield call(updateDefaultPaymentMethod, { braintreeToken: selectedCard.token, isDefault: payload.isDefault });
    yield put(getExistingCards({ selector: payload.selector }));
  } catch (err) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('singlePageCheckout.error.setting.defaultCard')
      ),
      'top-right', 5000));
  }
}

export function * initialize ({ payload }) {
  yield put(getExistingCards({ selector: payload.selector }));
  yield take(SET_EXISTING_CARDS);
  yield call(setInitialSelectedCard, payload.selector, payload.subscriptionPayment);
}

export function * setInitialSelectedCard (selector, subscriptionPayment) {
  const cards = yield select(braintreeSelectors.cards);
  if (subscriptionPayment && subscriptionPayment.cardToken) {
    const subscriptionCard = cards.find(card => card.token === subscriptionPayment.cardToken);
    yield put(setSelectedCard({
      selector,
      selectedCard: subscriptionCard || cards[0]
    }));
  } else {
    const defaultCard = cards.filter(card => card.isDefault)[0];
    const selectedCard = defaultCard ? defaultCard : cards[0];
    yield put(setSelectedCard({
      selector,
      selectedCard
    }));
  }
}

export function * payByExistingCard ({ payload }) {
  try {
    const shippingAddress = yield select(orderSelectors.shippingAddress);
    const token = yield select(paymentMethodSelectors.payByExistingCard.token(payload.selector));
    const cvvResponse = yield call(verifyStoredCardByCvv, token, shippingAddress.addressId);
    if (!cvvResponse.success) {
      yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.error'));
      return;
    }
    const response = yield call(createNonce, { braintreeToken: token });
    const billingAddress = yield select(paymentMethodSelectors.payByExistingCard.billingAddress(payload.selector));
    const threeDResponse = yield call(verifyCardWith3ds, response.nonce, response.details, billingAddress, payload.quotationId);
    if (!threeDResponse) {
      yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.error'));
      return;
    }
    const paymentInformation = {
      identifier: token,
      paymentType: SAVED_CARD,
      isDefaultPaymentMethod: yield select(paymentMethodSelectors.payByExistingCard.isDefaultCard(payload.selector)),
      isPriceWithVat: yield select(orderSelectors.isPriceWithVat),
      deviceData: yield call(getDeviceData),
      braintreePayload: {
        nonce: threeDResponse.nonceFromThreeDS,
        details: threeDResponse.detailsFromThreeDS
      },
      shouldUpdateBillingAddress: true,
      csAgentName: yield select(orderSelectors.csAgent.name)
    };
    const order = yield call(createOrder, SAVED_CARD, billingAddress, paymentInformation);
    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrderWithPaymentInformation, order);
    yield call(handleResponse, postOrderResponse);
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

function * subscribeByExistingCard ({ payload }) {
  try {
    const token = yield select(paymentMethodSelectors.payByExistingCard.token(payload.selector));
    const subscription = yield call(createSubscription, SUBSCRIPTION_CARD, token);
    yield call(createRepeatOrderEndPoint, subscription);
    yield put(createSubscriptionSuccess({ repeatOrderCreatedName: subscription.orderReference }));
  } catch (error) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('mySubscriptions.account.create.error')
      ),
      'top-right', 5000));
    yield put(createSubscriptionFailed());
  }
}

function * watchSubscribeByExistingCard () {
  yield takeLatest(SUBSCRIBE_BY_EXISTING_CARD, subscribeByExistingCard);
}
function * watchInitialize () {
  yield takeLatest(INITIALIZE, initialize);
}
function * watchSetDefaultCard () {
  yield takeLatest(SET_DEFAULT_CARD, setDefaultCard);
}
function * watchPayByExistingCard () {
  yield takeLatest(PAY_BY_EXISTING_CARD, payByExistingCard);
}

export function * watchAllPayByExistingCardSagas () {
  yield all([
    fork(watchSetDefaultCard),
    fork(watchInitialize),
    fork(watchPayByExistingCard),
    fork(watchSubscribeByExistingCard)
  ]);
}
