import { put, select, takeLatest, call } from 'redux-saga/effects';
import { onScreenReady } from 'client/actions/routeActions';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { logAction } from 'client/actions/logActions';
import { getJwtCustomerId } from 'shared/utils/jwtUtils';
import { SHORT_SESSION, LONG_SESSION } from 'shared/constants/userSessionType';
import { getViewIdBasedOnLoyaltyTier } from 'client/utils/viewIdUtils';
import { changeGlobalVATStateAction, onUserAccountDetailsReceived } from 'client/actions/userActions';

import {
  fetchAccountDetails,
  updateMarketingPreferences
} from 'shared/endpoints/accountEndpoint';

import {
  isLoggedInSelector,
  hasValidLongSessionToken,
  longSessionTokenSelector,
  loyaltyDiscountTierSelector,
  accountDetailsSelector
} from './accountSagaSelectors';

import {
  GET_ACCOUNT_DETAILS,
  RELOAD_ACCOUNT_DETAILS,
  receiveAccountDetails,
  receiveChangeAccountDetailsError,
  UPDATE_MARKETING_PREFERENCES,
  receiveUpdateMarketingPreferencesSuccess,
  receiveUpdateMarketingPreferencesError,
  receiveUserPreference,
  LOAD_USER_PREFERENCE
} from 'client/actions/accountActions';

import { logPersonalInfoAccess } from 'client/actions/accessLogActions';
import { fetchUserPreference } from 'shared/endpoints/userEndpoint';

export function * reloadAccountDetails (action) {
  try {
    const isLoggedInState = yield select(isLoggedInSelector);
    if (isLoggedInState) {
      const accountState = action.isLoginSuccess ? yield select(accountDetailsSelector) : {};
      const accountDetails = accountState.customerId ? accountState : yield call(fetchAccountDetails);
      yield put(receiveAccountDetails(accountDetails, action.isLoginSuccess));
      yield put(updateDataLayer({
        event: 'crom_user_id',
        crom_user_id: accountDetails.customerId
      }));
      yield put(updateDataLayer(createUserSessionTypeEvent(accountDetails.customerId, SHORT_SESSION)));
    } else {
      const isLongSession = yield select(hasValidLongSessionToken);
      if (isLongSession) {
        const longSessionToken = yield select(longSessionTokenSelector);
        const customerId = getJwtCustomerId(longSessionToken);
        yield put(updateDataLayer({
          event: 'crom_user_id',
          crom_user_id: customerId
        }));
        yield put(updateDataLayer(createUserSessionTypeEvent(customerId, LONG_SESSION)));
        yield put(onUserAccountDetailsReceived({ customerId }));
      }
    }
  } catch (e) {
    yield put(logAction({ message: 'Failure within reloadAccountDetails', e }));
  }
}

function createUserSessionTypeEvent (customerId, sessionType) {
  return {
    event: 'user_session_type',
    session_type: sessionType,
    user_id: customerId
  };
}

export function * loadUserPreference () {
  try {
    const isLoggedInState = yield select(isLoggedInSelector);
    const isLongSession = yield select(hasValidLongSessionToken);
    if (!isLoggedInState && isLongSession) {
      const isPriceWithVatLong = yield call(fetchUserPreference);
      yield put(receiveUserPreference(isPriceWithVatLong));
      yield put(changeGlobalVATStateAction(isPriceWithVatLong));
    }
  } catch (e) {
    yield put(logAction({ message: 'Failure within loadUserPreference', e }));
  }
}

export function * watchReloadAccountDetails () {
  yield takeLatest(RELOAD_ACCOUNT_DETAILS, reloadAccountDetails);
  yield takeLatest(LOAD_USER_PREFERENCE, loadUserPreference);
}

export function * fetchAccountDetailsFromEndpoint (action) {
  try {
    yield reloadAccountDetails(action);
    yield put(logPersonalInfoAccess());
    yield put(onScreenReady());
    const loyaltyDiscountTier = yield select(loyaltyDiscountTierSelector);
    const viewId = getViewIdBasedOnLoyaltyTier(loyaltyDiscountTier);
    yield put(updateDataLayer({
      event: 'loyalty_tier',
      view_id: viewId
    }));
  } catch (e) {
    yield put(receiveChangeAccountDetailsError(e));
  }
}

export function * watchLoadAccountDetails () {
  yield takeLatest(GET_ACCOUNT_DETAILS, fetchAccountDetailsFromEndpoint);
}

export function * handleUpdateMarketingPreferences ({ payload }) {
  try {
    const accountDetails = yield call(updateMarketingPreferences, payload);
    yield put(receiveUpdateMarketingPreferencesSuccess(accountDetails));
  } catch (e) {
    yield put(receiveUpdateMarketingPreferencesError(e));
  }
}

export function * watchUpdateMarketingPreferences () {
  yield takeLatest(UPDATE_MARKETING_PREFERENCES, handleUpdateMarketingPreferences);
}
