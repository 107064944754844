import { call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY } from 'client/actions/ui/dialogActions';
import {
  TERMINATE_CUSTOMER_SERVICE_AGENT_SESSION,
  SET_COOKIE_CJEVENT,
  SEND_CJEVENT_TO_DATALAYER,
  SET_COOKIE_AWC,
  SEND_AWC_TO_DATALAYER,
  UPDATE_ONETRUST_CONSENT,
  SET_COOKIE_USER_PREFERENCES,
  GET_COOKIE_USER_PREFERENCES
} from 'client/actions/ui/cookieActions';
import {
  acceptBrowserRecommendation,
  setCookieCJEvent,
  getCJEventFromCookie,
  setCookieAWC,
  getAWCFromCookie,
  updateConsents,
  setCookieUserPreferences,
  getUserPreferencesFromCookie
} from 'shared/endpoints/cookieEndpoint';
import { forceUserLogout } from 'shared/utils/jwtUtils';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { changeGlobalVATStateAction, setIsVatPreferenceFromUnleash } from 'client/actions/userActions';
import { List } from 'immutable';
import { isPriceWithVatPreference, isValidGlobalIsPriceWithVat } from 'shared/utils/accountPreferences';
import { VAT_TOGGLE } from 'shared/constants/unleash';
import { GET_UNLEASH_USER_PREFERENCES } from 'client/actions/ui/unleashActions';
import { accountDetailsSelector } from 'client/sagas/accountSagaSelectors';

export function * handleSetUnsupportedBrowserDialogVisibility (action) {
  try {
    if (!action.payload) {
      yield call(acceptBrowserRecommendation);
    }
  } catch (e) {
    console.error('Error while calling the frontend API for accepting browser recommendations:', e); // eslint-disable-line no-console
  }
}

export function * watchSetUnsupportedBrowserDialogVisibility () {
  yield takeLatest(SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY, handleSetUnsupportedBrowserDialogVisibility);
}

export function * handleTerminateCustomerServiceAgentSession () {
  yield call(forceUserLogout);
}

export function * handleSetCookieCJEvent (action) {
  try {
    yield call(setCookieCJEvent, action.payload);
    yield put(updateDataLayer({
      event: 'cjevent',
      cj_event_id: action.payload
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling cjeventAPI', error);
  }
}

export function * handleSendCJEventToDataLayer () {
  try {
    const cjeventId = yield call(getCJEventFromCookie);
    if (cjeventId) {
      yield put(updateDataLayer({
        event: 'cjevent',
        cj_event_id: cjeventId
      }));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling cjeventAPI', error);
  }
}

export function * handleSetCookieAWC (action) {
  try {
    yield call(setCookieAWC, action.payload);
    yield put(updateDataLayer({
      event: 'awc',
      awin_id: action.payload
    }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling awcAPI', error);
  }
}

export function * handleSendAWCToDataLayer () {
  try {
    const awinId = yield call(getAWCFromCookie);
    if (awinId) {
      yield put(updateDataLayer({
        event: 'awc',
        awin_id: awinId
      }));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling awcAPI', error);
  }
}

export function * handleUpdateOneTrustConsent ({ payload }) {
  try {
    yield call(updateConsents, { query: payload });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating consents', error);
  }
}

const getParsedCookie = (cookie) => {
  try {
    if (cookie) {
      return JSON.parse(cookie);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export function * handleSetCookieUserPreferences (action) {
  try {
    yield call(setCookieUserPreferences, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling userPreferences API', error);
  }
}

const unleashToggleByNameSelector = (toggleName) => (state) => {
  const toggles = state.getIn(['unleash', 'toggles'], List()).toJS();
  return toggles?.find((toggle) => toggle.name === toggleName);
};

const isUserPriceWithVatSelector = (state) => state.getIn(['user', 'accountDetails', 'isPriceWithVat'], null);

export function * upateIsPriceWithVatFromUnleash () {
  const accountDetails = yield select(accountDetailsSelector);

  if (isValidGlobalIsPriceWithVat(accountDetails?.isPriceWithVat)) {
    return;
  }

  const userPreferencesCookie = yield call(getUserPreferencesFromCookie);
  const userCookies = getParsedCookie(userPreferencesCookie);

  if (userCookies?.priceDisplayType) {
    return;
  }

  const isB2b = !!accountDetails?.tradeAccount?.accountNo;

  if (!isB2b) {
    yield put(changeGlobalVATStateAction(true));
    return;
  }

  const vatUnleashToggle = yield select(unleashToggleByNameSelector(VAT_TOGGLE));
  yield put(setIsVatPreferenceFromUnleash(true));
  const unleashVatPreference = isPriceWithVatPreference(vatUnleashToggle?.variant?.payload.value);
  yield put(changeGlobalVATStateAction(unleashVatPreference));
}

export function * handlegetUserPreferencesFromCookie () {
  try {
    const isPriceWithVat = yield select(isUserPriceWithVatSelector);
    if (isValidGlobalIsPriceWithVat(isPriceWithVat)) {
      return;
    }

    const userPreferencesCookie = yield call(getUserPreferencesFromCookie);
    const userCookies = getParsedCookie(userPreferencesCookie);

    if (userCookies?.priceDisplayType) {
      yield put(changeGlobalVATStateAction(isPriceWithVatPreference(userCookies.priceDisplayType)));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error calling userPreferences API', error);
  }
}

export function * watchTerminateCustomerServiceAgentSession () {
  yield takeLatest(TERMINATE_CUSTOMER_SERVICE_AGENT_SESSION, handleTerminateCustomerServiceAgentSession);
}

export function * watchSetCookieCJEvent () {
  yield takeLatest(SET_COOKIE_CJEVENT, handleSetCookieCJEvent);
}

export function * watchSendCJEventToDataLayer () {
  yield takeLatest(SEND_CJEVENT_TO_DATALAYER, handleSendCJEventToDataLayer);
}

export function * watchSetCookieAWC () {
  yield takeLatest(SET_COOKIE_AWC, handleSetCookieAWC);
}

export function * watchSendAWCToDataLayer () {
  yield takeLatest(SEND_AWC_TO_DATALAYER, handleSendAWCToDataLayer);
}

export function * watchUpdateOneTrustConsent () {
  yield takeLatest(UPDATE_ONETRUST_CONSENT, handleUpdateOneTrustConsent);
}

export function * watchSetCookieUserPreferences () {
  yield takeLatest(SET_COOKIE_USER_PREFERENCES, handleSetCookieUserPreferences);
}

export function * watchGetCookieUserPreferences () {
  yield takeLatest(GET_COOKIE_USER_PREFERENCES, handlegetUserPreferencesFromCookie);
}

export function * watchGetUnleashUserPreferences () {
  yield takeLatest(GET_UNLEASH_USER_PREFERENCES, upateIsPriceWithVatFromUnleash);
}

export function * watchCookieActions () {
  yield * [
    fork(watchSetUnsupportedBrowserDialogVisibility),
    fork(watchTerminateCustomerServiceAgentSession),
    fork(watchSetCookieCJEvent),
    fork(watchSendCJEventToDataLayer),
    fork(watchSetCookieAWC),
    fork(watchSendAWCToDataLayer),
    fork(watchUpdateOneTrustConsent),
    fork(watchSetCookieUserPreferences),
    fork(watchGetUnleashUserPreferences),
    fork(watchGetCookieUserPreferences)
  ];
}
