import { List, fromJS } from 'immutable';
import {
  STORE_FAMILIES_BY_CATEGORY,
  LOAD_PRODUCTS_BY_FAMILIES,
  RECEIVE_CAT_FAMILY_STOCK_INFO,
  UPDATE_CAT_FAMILY_PRICE_WITH_BREAK_PRICE,
  SET_CATEGORY_FAMILY_LOADING
} from 'client/actions/categoryFamiliesActions';

const defaultState = fromJS({
  categoryId: 0,
  families: List([]),
  isCategoryFamilyLoading: false
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case STORE_FAMILIES_BY_CATEGORY:
      const { categoryId, families, totalCount } = action;
      return state
        .set('categoryId', categoryId)
        .set('families', families)
        .set('totalCount', totalCount)
        .set('isCategoryFamilyLoading', false);
    case LOAD_PRODUCTS_BY_FAMILIES:
      return state
        .set('categoryId', 0)
        .set('families', List([]))
        .set('isCategoryFamilyLoading', true);
    case SET_CATEGORY_FAMILY_LOADING:
      return state.set('isCategoryFamilyLoading', true);
    case RECEIVE_CAT_FAMILY_STOCK_INFO:
      const { stockInfo, familyId } = action.payload;
      const familyIndex = state.get('families').findIndex((family) => family.get('id').toString() === familyId.toString());
      if (familyIndex < 0) {
        console.warn(`Family not found in state: ${familyId}`); // eslint-disable-line no-console
        return state;
      }
      const variantIndex = state.getIn(['families', familyIndex, 'productsInfo']).findIndex((variant) => variant.get('sku') === stockInfo.sku);
      if (variantIndex < 0) {
        console.warn(`Product variant not found in state: ${stockInfo.sku}`); // eslint-disable-line no-console
        return state;
      }

      return state.setIn(['families', familyIndex, 'productsInfo', variantIndex, 'stock'], fromJS(stockInfo));
    case UPDATE_CAT_FAMILY_PRICE_WITH_BREAK_PRICE:
      const { sku, familyId: familyIdBr, breakPriceValue } = action.payload;
      if (!breakPriceValue) {
        return state;
      }
      const familyIndexFound = state.get('families').findIndex((family) => family.get('id').toString() === familyIdBr.toString());
      if (familyIndexFound < 0) {
        console.warn(`Family not found in state: ${familyIdBr}`); // eslint-disable-line no-console
        return state;
      }

      const variantIndexFound = state.getIn(['families', familyIndexFound, 'productsInfo']).findIndex((variant) => variant.get('sku') === sku);
      if (variantIndexFound < 0) {
        console.warn(`Product variant not found in state: ${stockInfo.sku}`); // eslint-disable-line no-console
        return state;
      }

      return state.setIn([
        'families',
        familyIndexFound,
        'productsInfo',
        variantIndexFound,
        'price',
        'packPriceWithVat'], breakPriceValue);
    default:
      return state;
  }
};

export default reducer;
