import { ApiRequest } from 'shared/utils/apiUtils';

export function fetchNewAnonymousToken (subject = 'subject') {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/token',
    body: {
      sub: subject
    },
    sendJwtToken: false,
    validateToken: false // to avoid infinite loop
  });

  return req.execute()
    .then((r) => {
      return r.body.token;
    });
}

export function renewToken (token, tokensToRenew = 'token') {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/renew_token',
    body: {
      token: token,
      tokensToRenew
    }
  });

  return req.execute()
    .then((r) => {
      return JSON.parse(r.text);
    });
}
