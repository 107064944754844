import { fromJS } from 'immutable';

import { handleActions } from 'redux-actions';
import { SUCCESS, ERROR } from 'shared/constants/loadStateType';

import {
  RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS,
  RECIEVE_LOAD_PRODUCT_VARIANT_ERROR,
  UPDATE_PRICE_WITH_BREAK_PRICE,
  SCROLL_TO_THE_TOP,
  SCROLL_TO_REPEAT_ORDER_SECTION,
  RESET_SCROLLS
} from 'client/actions/productVariantActions';

import { RESET_STORE } from 'client/actions/appActions';

export const defaultState = fromJS({
  scrollToTop: false,
  scrollToRepeatOrderSection: false
});

export const actionMap = {
  [RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS]: (state = defaultState, action) => {
    const sku = action.payload.get('sku');
    const valueForSku = action.payload.set('loadState', fromJS({
      type: SUCCESS,
      cacheTime: (new Date()).getTime()
    }));
    return state
      .set('productVariantId', sku)
      .set(sku, valueForSku);
  },
  [RECIEVE_LOAD_PRODUCT_VARIANT_ERROR]: (state = defaultState, action) => {
    const newState = state.set(action.payload.id, fromJS({
      loadState: {
        type: ERROR
      }
    }));
    return newState;
  },

  [RESET_STORE]: (state = defaultState, action) => {
    return defaultState;
  },

  [UPDATE_PRICE_WITH_BREAK_PRICE]: (state = defaultState, action) => {
    if (!action.payload.breakPriceValue) {
      return state;
    }
    const newState = state
      .setIn([action.payload.sku, 'price', 'packPriceWithVat'],
        fromJS(action.payload.breakPriceValue))
      .setIn([action.payload.sku, 'price', 'packPrice'],
        fromJS(action.payload.packPriceValue))
      .setIn([action.payload.sku, 'price', 'breakDiscount'],
        fromJS(action.payload.breakDiscount));
    return newState;
  },
  [SCROLL_TO_THE_TOP]: (state = defaultState, action) => {
    return state.set('scrollToTop', action.payload);
  },
  [SCROLL_TO_REPEAT_ORDER_SECTION]: (state = defaultState, action) => {
    return state.set('scrollToRepeatOrderSection', action.payload);
  },
  [RESET_SCROLLS]: (state = defaultState, action) => {
    return state.set('scrollToRepeatOrderSection', false)
      .set('scrollToTop', false);
  }

};

export default handleActions(actionMap, defaultState);
