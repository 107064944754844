import { fromJS } from 'immutable';

import {
  SAVE_ADDRESS,
  SET_ADDRESS,
  GET_USER_ADDRESSES,
  GET_USER_ADDRESSES_SUCCESS,
  UPDATE_DEFAULT_DELIVERY_ID,
  CLEAR_ADDRESS,
  SET_PO_REFERENCE,
  ON_NEW_ADDRESS_CLICK,
  EDIT_ADDRESS
} from 'client/actions/addressActions';
import {
  RECEIVE_ACCOUNT_DETAILS,
  RECEIVE_USER_PREFERENCE,
  RECIEVE_UPDATE_MARKETING_PREFERENCES_SUCCESS,
  RELOAD_ACCOUNT_DETAILS
} from 'client/actions/accountActions';
import { SET_USER_EXISTENCE, CHANGE_GLOBAL_VAT_STATE, IS_USER_UPDATED_BILLING_DETAILS, SET_IS_VAT_PREFERENCE_FROM_UNLEASH } from 'client/actions/userActions';
import { RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS } from 'client/actions/creditAccountActions';
import { isValidGlobalIsPriceWithVat } from '../../shared/utils/accountPreferences';

export const defaultState = fromJS({ emails: {}, accountDetails: { loyaltyDiscount: {} }, isPriceWithVat: true, isUserChangedBillingAddress: false, tradeAccountSubUserVerified: { error: null } });

const user = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_ADDRESS:
      return state;
    case CLEAR_ADDRESS:
      return state.deleteIn([ 'address', action.payload ]);
    case UPDATE_DEFAULT_DELIVERY_ID:
      return state.setIn(['address', 'defaultDeliveryAddressId'], action.payload.addressId);
    case GET_USER_ADDRESSES:
      return state.setIn(['address', 'deliveryAddresses', 'data'], null);
    case GET_USER_ADDRESSES_SUCCESS:
      return state
        .setIn([ 'address', 'deliveryAddresses', 'data' ], fromJS(action.payload.addresses.data))
        .setIn([ 'address', 'deliveryAddresses', 'totalCount' ], fromJS(action.payload.addresses.totalAddresses))
        .setIn([ 'address', 'defaultDeliveryAddressId' ], fromJS(action.payload.defaultDeliveryAddressId))
        .setIn([ 'address', 'delivery' ], fromJS(action.payload.deliveryAddress));
    case SET_ADDRESS:
      return state.setIn([ 'address', action.payload.addressType ], fromJS(action.payload.address));
    case ON_NEW_ADDRESS_CLICK:
      return state
        .setIn([ 'address', 'calledFrom' ], action.payload.calledFrom);
    case EDIT_ADDRESS:
      return state
        .setIn([ 'address', 'calledFrom' ], action.payload.calledFrom);
    case RECEIVE_ACCOUNT_DETAILS:
    case RELOAD_ACCOUNT_DETAILS:
      if (!action.payload) return state;
      const isGlobalPriceWithVat = isValidGlobalIsPriceWithVat(action.payload.isPriceWithVat) ? action.payload.isPriceWithVat : state.get('isPriceWithVat');
      return state.set('accountDetails', fromJS(action.payload)).set('isPriceWithVat', isGlobalPriceWithVat);
    case RECEIVE_USER_PREFERENCE:
      return isValidGlobalIsPriceWithVat(action.isPriceWithVatLong) ? state.setIn(['accountDetails', 'isPriceWithVat'], action.isPriceWithVatLong) : state;
    case RECIEVE_UPDATE_MARKETING_PREFERENCES_SUCCESS:
      return state
        .setIn(['accountDetails', 'marketingPreferences'], fromJS(action.payload))
        .setIn(['accountDetails', 'marketingPreferencesUpdated'], true);
    case RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS:
      return state.setIn([ 'accountsDetails', 'tradeAccount' ], {
        ...state.getIn([ 'accountsDetails', 'tradeAccount' ]),
        ...action.tradeAccount
      });
    case SET_PO_REFERENCE:
      return state.set('poReference', action.payload);
    case SET_USER_EXISTENCE:
      return state.setIn([ 'emails', action.email ], fromJS(action.exists));
    case CHANGE_GLOBAL_VAT_STATE:
      return isValidGlobalIsPriceWithVat(action.isPriceWithVat) ? state.set('isPriceWithVat', action.isPriceWithVat) : state;
    case IS_USER_UPDATED_BILLING_DETAILS:
      return state
        .set('isUserChangedBillingAddress', true)
        .set('initialBillingAddressId', state.getIn(['address', 'billing', 'addressId']));
    case SET_IS_VAT_PREFERENCE_FROM_UNLEASH:
      return state.set('isVatPreferenceFromUnleash', action.payload);
    default:
      return state;
  }
};

export default user;
