import { takeLatest } from 'redux-saga/effects';
import { SCROLL_TO_TOP } from 'client/actions/scrollActions';

export const scrollToTop = function scrollToTop () {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const watchScrollToTop = function * watchScrollToTop () {
  yield takeLatest(SCROLL_TO_TOP, scrollToTop);
};
