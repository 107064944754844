import React from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { Map, List } from 'immutable';
import {
  getCheapestPriceObject,
  getPriceInfo,
  formatPrice
} from 'client/utils/priceUtils';
import { Empty } from 'client/components/elements/empty/Empty';
import { recommendedLinkClicked } from 'client/actions/productVariantActions';
import {
  requestRecommendationsStockInfo,
  updateRecommendationsProductPriceWithBreakPrice
} from 'client/actions/recommendationsActions';
import { requestCmsStockInfo } from 'client/actions/cmsActions';
import { getLocalizedString } from 'localization/localizer';
import { getTruncatedTitle } from 'client/utils/recommendationUtils';
import { RecommendationTile } from './RecommendationTile';
import { VerticalRecommendationProductCard } from './VerticalRecommendationProductCard';
import { getVariant } from 'client/utils/unleashUtils';
import { SPECIAL_PRICE_HIGHLIGHT, SHOW, UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE, BASKET_DROPDOWN_VARIANT } from 'shared/constants/abTesting';
import numeral from 'numeral';
import { displayFinalPromoDiscount } from '../../promoLabel/PromoLabel';

const ProductRecommendation = (props) => {
  const {
    product,
    name = '',
    thumbnail,
    sku,
    addToCart,
    recommendedClick,
    position,
    disableModal,
    updateRecsStockInfo,
    updatePriceWithBreakPrice,
    isPriceWithVat = true,
    cmsFlag = false,
    updateCmsStockInfo,
    isVerticalRecommendation,
    unleashToggles,
    recommendationLocation
  } = props;

  const type = product.get('recommendationType');
  const isDeleted = product.get('isDeleted');
  const productUrl = product.get('productUrl');
  const boughtOn = product.get('boughtOn');
  const familyId = product.getIn(['family', 'familyId'], '');
  const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
  const brandName = product.getIn(['brand', 'brandName']);
  const feefoReviews = product.getIn(['feefoReviews'], Map()).toJS();
  const stock = product.getIn(['stock', 'stockMessage'], Map()).toJS();
  const fulfillmentType = product.getIn(['stock', 'fulfillmentType']);
  const isPOA = fulfillmentType === 5;
  const productPackPriceWithVat = product.getIn(['price', 'packPriceWithVat']);
  const showHighlightedPriceVariant = getVariant(unleashToggles, SPECIAL_PRICE_HIGHLIGHT, '');
  const basketDropDown = getVariant(unleashToggles, UNLEASH_ADD_TO_BASKET_FEATURE_TOGGLE, false) === BASKET_DROPDOWN_VARIANT;
  const showHighlightedPrice = showHighlightedPriceVariant === SHOW;
  const clickHandler = () =>
    recommendedClick({ family_sku: sku, position, type });
  const priceObj = getCheapestPriceObject([product]);

  if (priceObj.size === 0 || isDeleted) {
    return <Empty />;
  }

  const { priceWithVat, priceWithoutVat } = getPriceInfo(priceObj);

  const promoUrl = priceObj.get('promoUrl');
  const currency = priceObj.get('currency');
  const finalPromoDiscount = priceObj.get('finalPromoDiscount');
  const appliedDiscounts = priceObj.get('appliedDiscounts');
  const appliedBreakPriceObject = priceObj.get('appliedBreakPriceObject');
  const promoDiscount = priceObj.get('promoDiscount');
  const packWasPriceWithVat = priceObj.get('packWasPriceWithVat');
  const packWasPriceWithoutVat = priceObj.get('packWasPriceWithoutVat');
  const breakPrices = priceObj.getIn(['breakPrices'], List()).toJS();

  const isInPromo = priceObj && appliedDiscounts.size;
  const productPrice = isPriceWithVat ? priceWithVat : priceWithoutVat;
  const amountWithQtyMultiplier = (productPrice * qtyMultiplier).toFixed(2);
  const { symbol, amount } = formatPrice(currency, amountWithQtyMultiplier);
  const isQtyMultiplier = qtyMultiplier > 1;

  const breakDiscount =
    isInPromo &&
    !promoDiscount &&
    !finalPromoDiscount &&
    appliedBreakPriceObject
      ? appliedBreakPriceObject.get('breakDiscount')
      : 0;

  const priceVatTagLabel = isPriceWithVat
    ? getLocalizedString('incVat')
    : getLocalizedString('excVat');

  const truncatedName = getTruncatedTitle(name);

  const { averageRating, totalReviews } = feefoReviews;

  const discount = promoDiscount || breakDiscount * 100;
  const displayDiscount = displayFinalPromoDiscount(discount, finalPromoDiscount);
  const promoLabelProps = {
    isInPromo,
    isPriceWithVat,
    packWasPriceWithVat,
    packWasPriceWithoutVat,
    currency,
    promoDiscount,
    breakDiscount,
    appliedDiscounts,
    promoUrl,
    finalPromoDiscount,
    displayDiscount
  };
  const reviewProps = { averageRating, numberOfReviews: totalReviews };
  const deliveryDetailsProps = {
    stock,
    productPackPriceWithVat,
    currency,
    sku
  };
  const quantityOfProps = { isQtyMultiplier, qtyMultiplier };
  const priceWithMultiplierProps = { isQtyMultiplier, symbol, productPrice: numeral(productPrice).format('0,0.00'), qtyMultiplier };
  const priceProps = { symbol, amount, priceVatTagLabel, isPriceWithVat, showHighlightedPrice, isInPromo, packWasPriceWithVat, packWasPriceWithoutVat };
  const actionButtonProps = {
    sku,
    productName: name,
    img: thumbnail,
    addToCart,
    isRecommendations: true,
    recommendationType: type,
    recommendationPosition: position,
    recommendationLocation,
    disableModal,
    updateVariantStockInfo: cmsFlag ? updateCmsStockInfo : updateRecsStockInfo,
    familyId,
    product,
    breakPrices,
    appliedDiscounts,
    updatePriceWithBreakPrice: updatePriceWithBreakPrice,
    buttonClassName: 'RecommendationAddToBasket',
    hideStockMessage: true,
    quantityDropdownDirection: 'top',
    className: 'RecommendationTile_AddToCart',
    isPOA
  };

  const recommendationProps = {
    link: productUrl,
    clickHandler,
    thumbnail,
    actionButtonProps,
    brandName,
    name,
    promoLabelProps,
    reviewProps,
    deliveryDetailsProps,
    quantityOfProps,
    priceWithMultiplierProps,
    priceProps,
    boughtOn,
    basketDropDown
  };
  return (
    <>
      {isVerticalRecommendation ? (
        <VerticalRecommendationProductCard
          { ...recommendationProps }
          isFamilyProduct={ false }
        />
      ) : (
        <RecommendationTile
          truncatedName={ truncatedName }
          { ...recommendationProps }
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS()
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const cmsData = { panelIndex: ownProps.panelIndex, query: ownProps.query };
  return {
    recommendedClick: flowRight(dispatch, recommendedLinkClicked),
    updateRecsStockInfo: (args) =>
      dispatch(
        requestRecommendationsStockInfo({
          ...args,
          panelIndex: ownProps.panelIndex
        })
      ),
    updateCmsStockInfo: (args) =>
      dispatch(
        requestCmsStockInfo({
          ...args,
          cmsData: cmsData
        })
      ),
    updatePriceWithBreakPrice: flowRight(
      dispatch,
      updateRecommendationsProductPriceWithBreakPrice
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRecommendation);
export { ProductRecommendation };
