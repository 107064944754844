const CUSTOMER_SUPPORT_PHONE = '0800 3688 665';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';

export default {

  // store pages
  'store.links.text': 'View store page results for',

  // used for testing
  'test.simple': 'localized string',
  'test.params': 'sku: {{sku}}, family: {{family}}',
  'test.contactNumber.message': 'please contact: {{contactNumber}}',
  'test.contactNumber': '123456789',

  // loading page
  'loading.pleaseWait': 'Please Wait.. We are proceessing your request..',
  'loading.pageError': 'Page Error: Please try again or refresh the page.',

  // verifyEmail
  'verifyEmail.heading': 'Email Verification',
  'verifyEmail.success.beforeLink': 'You have successfully verified your email address. Please note that applications can take up to 2 business days to process and you will be notified once your account is active. If you need help or further information; please ',
  'verifyEmail.success.linkText': 'contact us',
  'verifyEmail.success.afterLink': ' with your application reference number: ',
  'verifyEmail.failed.beforeLink': 'We are unable to verify your email. Please note our verification link expires in 2 weeks and can be used only once. If you need help or further information; please ',
  'verifyEmail.failed.linkText': 'contact us',
  'verifyEmail.failed.afterLink': ' with your application reference number: ',

  // account user form validation messages
  'accountUserForm.updatedUser': "You successfully updated {{userName}}'s account.",
  'accountUserForm.spendLimitExceeded': 'Spend limit on this trade account is £{{limit}}.',
  'accountUserForm.invitationSent': 'An invitation has been sent to {{email}}',
  'accountUserForm.shortMessageInvalid': 'Max 1000 Characters',
  'accountUserForm.validEmailAddress': 'Please insert a valid email address.',
  'accountUserForm.tooManyEmailAddresses': 'Please enter less than 50 email addresses.',
  'accountUserForm.duplicatedEmails': 'Duplicated email addresses are not allowed.',

  // unsubscribe
  'unsubscribe.heading': 'Email Unsubscribe',
  'unsubscribe.success': 'You are no longer subscribed to our {{emailType}} emails. Please allow 24 hours for this to come into effect.',

  // Shared
  'currencySymbol': '£',
  'customerSupport.phone': `${CUSTOMER_SUPPORT_PHONE}`,
  'customerSupport.opening': 'Monday - Friday, 8:30am - 4:30pm',
  'customerSupport.email': 'support@zoro.co.uk',

  // Page html header and meta data
  'html.title': 'Zoro - Experts in Hand Tools, Power Tools and PPE',
  'html.siteName': 'Zoro Tools & MRO Supplier',
  'html.description': 'UK\'s leading Tools & MRO industry supplier with a number of business-friendly features that make B2B supply shopping quick and easy. Over {{totalProductsCount}} products.',
  'html.keywords': 'power tool, hand tool, hammer, power drill, screwdriver, wrench, abrasive, adhesive, personal protection, gloves, gardening, precision, welder, loctite',

  'html.family.title': ' | Zoro UK',
  'html.family.description': `, products available to order online. Next day delivery available on most items, free delivery on orders over £${FREE_DELIVERY_THRESHOLD}!`,
  'html.product.description': ' available at www.zoro.co.uk - UK\'s Leading Supplier of DIY Tools and Supplies',

  // ErrorOutputScreen
  'errorOutputScreen.serverLoadError.title': 'Sorry, there was a problem loading data.',
  'errorOutputScreen.serverLoadError.subtitle': 'Please try refreshing the page',

  // Header
  'header.label.customerSupport': ' Contact Us',
  'header.label.showPrices': 'Show Prices',
  'header.label.freeNextDayDelivery': 'Free Delivery',
  'label.free.delivery.all.orders': 'Free Delivery For All Orders',
  'header.label.businessAccountsAvailable': 'Open Your Zoro Business Accounts Today!',
  'header.label.applyHere': 'Apply Here',

  // Footer
  'footer.freeShipping.text': `Free shipping on all orders over £${FREE_DELIVERY_THRESHOLD}*`,
  'footer.customerSupport.text': 'Contact us with an enquiry',
  'footer.placeOrder.text': 'Call: {{contactNumber}} to order by phone ',
  'footer.returns.text': 'Looking to return an order?',

  // Cart
  'sku.copied': 'Order code {{sku}} has been copied',
  'quotation.reference.copied': 'Quotation reference {{quoteRef}} has been copied',
  'copy.sku': 'Copy {{sku}}',
  'cart.each': 'each',
  'cart.eachPackOf': 'each, pack of ',
  'cart.title': 'Your Basket',
  'cart.continueShopping': 'Continue Shopping?',
  'cart.callToAction': 'Basket',
  'cart.emptyCartMessage': 'Sorry, your cart is empty!',

  'cart.checkout.error': 'Items out of stock. Review basket.',
  'cart.checkout.quantity.error': 'Please review your basket.',
  'cart.buttons.loginToCheckout': 'Checkout',
  'cart.buttons.checkout': 'Proceed to Payment',
  'cart.buttons.goToCheckout': 'Go to Checkout',
  'cart.buttons.payByCard': 'Pay by Card/PayPal',
  'cart.buttons.payOnAccount': 'Pay on Account',
  'cart.buttons.createTradeAcocunt': 'Zoro Business Accounts. Learn More Here',
  'cart.buttons.quotationLabel': 'Create quotation from basket',
  'cart.buttons.quotationSubLabel': 'Lock these prices for 14 days',
  'cart.buttons.applyForABusinessAccount': 'Apply for a Business Account',
  'cart.message.discountHigherThanTTP': 'Tiered pricing discount is not applicable',
  'cart.toast.message.discount.loss': 'You have lost your quantity discount of {{discountToRounded}}%',
  'cart.toast.message.discount.got': 'You have got a quantity discount of {{discountToRounded}}%',
  'productVariant.buttons.quotationLabel': 'Add to Quotation',
  'productVariant.buttons.label.loginToCreateQuotation': 'Log in to create quotations',
  'productVariant.buttons.quotationSubLabel': 'Lock Price',
  'productVariant.buttons.subscriptionLabel': 'Create a New Scheduled Order',
  'productVariant.buttons.subscription.label.loginToSubscribe': 'Log in to Subscribe to Edit Scheduled Order Details',
  'productVariant.buttons.subscription.tooltip.text': 'Create Scheduled Orders of eligible products you need regularly. Create Scheduled Orders, make changes, pause or cancel at any time. To learn more about Scheduled Orders',
  'productVariant.tabs.subscriptionLabel': 'Scheduled Orders',
  'productVariant.tabs.subscriptionSubLabel': 'Set regular deliveries',
  'productVariant.tabs.specificatoinLabel': 'Specifications',
  'productVariant.tabs.specificatoinSubLabel': 'Product Specs',
  'productVariant.buttons.see.noOfProductVarients': 'See {{numberOfProductVariantsInFamily}} More Product Variants',
  'productVariant.industriesPanel.title': 'Browse by Industry:',

  'cart.customerSupport.label.phone': 'Customer Support: ',
  'cart.customerSupport.number': '{{contactNumber}}',

  'cart.cartTotalizer.title': 'Basket Total',
  'cart.cartTotalizer.title.orderConfirmation': 'My Order',
  'cart.cartTotalizer.fieldLabels.freeDeliveryShortfall': 'You are {amount} away from FREE Delivery',
  'cart.cartTotalizer.fieldLabels.totalGoods': 'Total Goods:',
  'cart.cartTotalizer.fieldLabels.delivery': 'Delivery:',
  'cart.cartTotalizer.fieldLabels.vat': 'VAT Grand Total:',
  'cart.cartTotalizer.fieldLabels.grandTotalGross': 'Grand Total:',
  'cart.cartTotalizer.fieldLabels.totalSavings': 'Total Savings:',

  'cart.cartOrderLine.skuLabel': 'Order Code: ',
  'cart.cartOrderLine.savingPercentage': 'Save {percentage}%',
  'cart.cartOrderLine.priceChange': 'Price Change',

  'cart.orderLine.remove': 'Remove',
  'cart.orderLine.adjust': 'Adjust',
  'cart.orderLine.continue': 'Continue',
  'cart.orderLine.product': 'Product',
  'cart.orderLine.quantity': 'Basket Qty',
  'cart.orderLine.unitIncVat': 'Unit:Inc VAT',
  'cart.orderLine.subTotalIncVat': 'Sub Total Inc VAT',
  'cart.orderLine.unitExcVat': 'Unit:Exc VAT',
  'cart.orderLine.subTotalExcVat': 'Sub Total Exc VAT',
  'cart.orderLine.fieldLabels.savings': 'Savings',
  'cart.dialog.remove.product.button': 'Remove this item',
  'cart.dialog.cancel.button': 'Cancel',
  'cart.dialog.header.message': 'Are you sure you want to remove this item?',
  'cart.dialog.label.sku': 'Order Code:',
  'cart.productBundleLine.label.sub.total': 'Sub-total: ',
  'cart.productBundleLine.label.price.change': 'Price Change',
  'cart.addToCart.hasBeenAddedToCart': 'Successfully added to your Basket',
  'cart.orderLine.discount.not.applied.message': '{{discountCode}} is not applicable for this product',

  // Basket confirmation dropdown
  'cart.basketConfirmation.itemsAddedToBasket': '{{qty}} Items In Basket',
  'cart.basketConfirmation.viewBasket': 'View Basket',
  'cart.basketConfirmation.goToPayment': 'Go to Payment',
  'cart.basketConfirmation.goToCheckout': 'Go to Checkout',
  'cart.basketConfirmation.proceedToCheckout': 'Proceed to Checkout',
  'cart.basketConfirmation.basketEmpty': 'Your basket is currently empty',
  'cart.basketConfirmation.pleaseWait': 'Please wait',
  'cart.basketConfirmation.caretDown': '▾',
  'cart.basketConfirmation.removeProductConfirmation': 'Are you sure you want to remove this item?',
  'cart.basketConfirmation.removeProductConfirmationCancel': 'No, keep item',
  'cart.basketConfirmation.removeProductConfirmationConfirm': 'Yes, remove item',
  'cart.basketConfirmation.continueShopping': 'Continue Shopping',

  // New Login Labels
  'login.email.placeholder': 'Email',
  'login.createAPassword.placeholder': 'Create Password',
  'login.password.placeholder': 'Password',
  'login.confirmPassword.placeholder': 'Confirm password',
  'login.forgotPassword.text': 'Forgot your password?',
  'login.resetPassword.text': 'Reset password',
  'login.resetPassword.description': "Please enter your email address and we'll help you to reset your password.",
  'login.loginButton.text': 'Log in',
  'login.signInToYourAccount.text': 'Sign in to your account',
  'login.getStarted.text': 'Get started!',
  'login.csAgentNameLogin.text': 'This is a customer service session. Password will be autogenerated.',
  'login.alreadyHaveAnAccount.text': 'Already have an account?',
  'login.signInButton.text': 'Sign in',
  'login.loginHere.text': 'Please Login Here',
  'login.createAccountButton.text': 'Create account',
  'register.continue.text': 'Continue',
  'register.almostThere.text': 'Almost there!',
  'register.createAccount.text': 'Create Account',
  'forgotPassword.thanks.text': 'Thank you!',
  'forgotPassword.backToLogin.text': 'Back To Login',
  'login.pleaseLogin.text': 'Please login',
  'register.tAndCpart1.text': "By creating an account you agree to Zoro's",
  'register.tAndCpart2.text': 'Please see our',
  'register.tAndCpart3.text': 'to learn about how we collect and use your information.',
  'forgotPassword.thankYou.text': 'We have sent you an email with steps to follow. The link in the email is valid for 24 hours only. If you have not recieved the email then please check your spam folder and ensure you have used the correct email address. Thank you.',
  'password.checklist.length': 'At least 8 characters',
  'password.checklist.lowercase': 'A lowercase letter',
  'password.checklist.uppercase': 'An uppercase letter',
  'password.checklist.digit': 'A number (0-9)',
  'password.checklist.specialChar': 'A special character ( ! @ # $ % ^ & * ( ) - + . , £ )',
  'your.password.must.contain': 'Your password must contain',
  // Registration
  'form.maxLength.error': '{{fieldName}} cannot be more than {{max}} characters long',
  'registration.heading': 'Login or Create Account',
  'registration.heading.create.new.account': 'New Customer',
  'registration.heading.contact.details': 'Contact Details',
  'registration.heading.terms.conditions': 'Terms & Conditions',
  'registration.heading.email.password': 'Email & Password',
  'registration.heading.returningCustomer': 'Returning Customer',
  'registration.heading.newCustomer': 'New Customer',
  'registration.heading.csAgent.message': 'This is a customer service session. Password will be autogenerated.',
  'registration.label.title': 'Title',
  'registration.label.firstName': 'First Name',
  'registration.label.email': 'Email',
  'registration.label.invoiceEmail': 'Invoice Email',
  'registration.label.lastName': 'Last Name',
  'registration.label.password': 'Password',
  'registration.label.newPassword': 'New Password',
  'registration.label.confirmNewPassword': 'Confirm New Password',
  'registration.label.companyName': 'Company Name',
  'registration.label.jobTitle': 'Job title',
  'registration.contactDetailsColumn.placeholder.companyName': 'Company Name (Optional)',
  'registration.label.phoneNumber': 'Phone Number',
  'registration.label.confirmPassword': 'Confirm Password',
  'registration.label.tAndC.acceptance': 'I accept the ',
  'registration.label.termsAndConditions': 'Terms of Access',
  'registration.label.agree': 'I agree to the ',
  'registration.label.privacyPolicy': 'Privacy Policy',
  'registration.label.marketingConsent': 'I would like to receive emails or texts from zoro with the latest discounts and info on our range of tools, industrial products and MRO supplies.',
  'registration.label.button.register': 'Create New Account',
  'registration.label.button.redirecting': 'Processing...',
  'registration.label.captcha': 'Captcha',
  'registration.button.create': 'Create Account',
  'registration.placeholder.emailAddress': 'Email Address',
  'registration.placeholder.emailAddresses': 'Email Address(es)',
  'registration.placeholder.setAMontlySpendLimit': 'Set A Monthly Spend Limit',
  'registration.placeholder.includeMessage': 'Include a message',
  'registration.placeholder.password': 'Password',
  'registration.button.login': 'Login',
  'registration.link.forgotPassword': 'Forgot Password?',
  'registration.duplicate.email.error': 'Email already exists',
  'addressForm.title.contactDetails': 'Contact Details',
  'addressForm.title.addressDetails': 'Address Details',
  'addressForm.dropdown.placeHolder': 'Select Existing Address:',
  'addressForm.title.orderReference': 'Add your Order Reference',
  'addressForm.title.orderReference.optional': 'Optional',
  'addressForm.title.phoneNumber': 'Primary contact number',
  'addressForm.placeholder.phoneNumber': 'UK landline or mobile',
  'addressForm.label.firstName': 'First Name',
  'addressForm.label.lastName': 'Last Name',
  'addressForm.label.telephoneNumber': 'Phone Number',
  'addressForm.label.addressType': 'Address Type',
  'addressForm.label.postcode': 'Post Code',
  'addressForm.label.addressName': 'Address Nickname',
  'addressForm.label.address': 'Address',
  'addressForm.checkBox.label.billingAddress': 'Use this as my Billing Address',
  'quotationForm.label.productQuantity': 'Quantity:',
  'basket.label.unitQty': 'Qty:',
  'quotationForm.label.existingQuotation': 'Existing Quotation:',
  'quotationForm.label.newQuotationName': 'New Quotation Name',
  'login.success.message': 'You are successfully logged in',
  'postcodeInput.label.companyName': 'Company Name',
  'postcodeInput.label.addressLine1': 'Address Line 1',
  'postcodeInput.label.addressLine2': 'Address Line 2',
  'postcodeInput.label.state': 'County',
  'postcodeInput.label.city': 'City/Town',
  'postcodeInput.label.postcode': 'Post Code',
  'postcodeInput.label.enterManually': 'Enter address manually',
  'postcodeInput.error.unrecognisedAddressPart1': 'Sorry your address has not been found. If your address is within the UK please try again or enter manually. Please note - we only deliver to ',
  'postcodeInput.error.unrecognisedAddressPart2': 'UK based addresses',
  'postCodeInput.error.nonDeliverablePostCode': 'Unfortunately we are unable to deliver to this post code. Sorry for the inconvenience',
  'postcodeInput.label.addressFinder': 'Address Finder',
  'postcodeInput.label.addressFinderMsg': 'Start typing a UK address or Postcode',
  'addressForm.label.AddDeliveryAddress': 'Add Delivery Address',
  'addressForm.label.AddBillingAddress': 'Add Billing Address',
  'addressForm.label.deliveryAddress': 'Delivery Address',
  'addressForm.label.billingAddress': 'Billing Address',
  'error.invalid.postCode': 'Please enter a valid UK postcode',
  'error.invalid.email': 'Please enter a valid email address',
  'error.invalid.number': 'Please enter a valid number greater than 0',
  'error.invalid.positive.number': 'Please enter a valid number',
  'error.invalid.phoneNumber': 'Please enter a valid phone number',
  'error.invalid.companyRegNo': 'Invalid Company Registration Number. Please ensure you enter an 8-digit number, or a 2-letter prefix followed by 6 digits, as per the UK Companies House format. You can search for your correct registration number',
  'error.invalid.companyRegNo.click.here': ' here.',
  'error.invalid.vatRegNo': 'Must be a valid UK or Ireland number, e.g. "GB123456789"',
  'error.required': '{field} is required',
  'error.required.creditAccountTerms': 'Please accept the Terms and Conditions',
  'error.match': '{field} does not match {matchField}',
  'error.match.password': 'Your two passwords do not match, please correct',
  'error.minLength': '{field} needs to be at least {minLength} characters long',
  'error.maxLength': '{field} cannot be more than {maxLength} characters long',
  'error.validation.minLength': '{{field}} needs to be at least {{minLength}} characters long',
  'error.validation.maxLength': '{{field}} cannot be more than {{maxLength}} characters long',
  'error.minValue': 'This value cannot be less than {minValue}',
  'error.maxValue': 'This value cannot be more than {maxValue}',
  'error.maxMonthlyLimit': 'This value cannot be more than setted monthly limit {maxValue}.',
  'error.invalid.orderReferenceNumber': 'Please enter a valid order number',
  'error.password.atleastOne': '{{field}} must have at least one {{type}}',
  'error.password.special.character': '{{field}} must contain at least 1 {{type}} from the list: ! @ # $ % ^ & * ( ) - + . , £',

  'order.address.sameAsBilling': 'Same as Delivery Address',
  'order.address.continueToReview': '+ Save and Continue',
  'order.address.continueBtn': 'Save and Continue',
  'order.address.saveAddressBtn': 'Save Address',
  'order.review.deliveryAddress': 'Delivery Address',
  'order.review.billingAddress': 'Billing Address',
  'order.review.delivery': 'Delivery',
  'order.review.billing': 'Billing',
  'order.review.back2billing': 'Back',
  'order.review.continue2payment': 'Continue to Payment',
  'order.review.payOnAccount': 'Pay on Account',
  'order.review.saveOrder': 'Save & Complete order',
  'order.review.redirecting': 'Processing ...',
  'order.review.csAgentWarning': 'Warning: Some unavailable products in basket, you may still place order',
  'order.review.productsUnavailable': 'Some products are unavailable. Please review your order!',
  'order.review.place.order.error': 'Items out of stock. Review your order!.',
  'order.review.quotation.expired': 'This quotation is expired. Please re-quote to order!',
  'order.review.quotation.productsUnavailable': 'Some products are unavailable. Please review your quotation!',
  'order.review.invalid.quantity': 'Please review your basket.',
  'order.yourReference.placeholder': 'Input Your Reference Number',

  'order.payment_authorised.header': 'Thank you for your order!',
  'order.order_under_review.header': 'Thank you for your order!',
  'order.payment_fraudulent.header': 'Thank you for your order!',
  'order.payment_rejected.header': 'Your order could not be processed',
  'order.payment_required.header': 'Payment required',
  'order.credit_new.header': 'Thank you for your order',
  'order.credit_payment_authorised.header': 'Thank you for your order',
  'order.credit_payment_over_limit.header': 'Thank you for your order',
  'order.credit_stopped.header': 'Thank you for your order',
  'order.credit_check_error.header': 'Thank you for your order',
  'order.test_credit_order.header': 'Thank you for your order',
  'order.test_order.header': 'Thank you for your order',
  'order.status.text': 'You can track and check the status of your order ',
  'discount.label.title': 'Discount code',
  'discount.label.input': 'Apply',
  'discount.label.ongoingSale': 'Unavailable during the ',
  'discount.label.code': 'Enter a valid code.',
  'discount.label.error.invalid': 'Sorry! This discount code is invalid.',
  'discount.label.termsAndConditions': 'Terms & Conditions',
  'discount.label.applied': 'code is applied',
  'discount.label.remove': 'Remove',
  'discount.coupon.noTermsAndConditions': 'No terms and conditions.',
  'discount.not.applied.all.products.message': 'Please note that the discount code is only valid for some items in your basket.',

  'forgotPassword.prompt': "Please enter your email address and we'll help you reset your password.",
  'forgotPassword.submit.label': 'Submit',
  'forgotPassword.emailSentConfirmation.header': 'Thank you. We have sent you an email with steps to follow',
  'forgotPassword.emailSentConfirmation.subText': 'Link in the email is valid for 24 hours only. If you have not recieved the email then please check your spam folder and ensure you have used the correct email address. Thank you.',

  'resetPassword.prompt': 'Please enter your new password.',
  'resetPassword.startFailureMessage': 'Sorry, your reset password link has expired. ',
  'resetPassword.clickableMidFailureMessage': 'Click here to reset password',
  'resetPassword.endFailureMessage': ' , and we willl send you a new reset password email.',
  'resetPassword.submit.label': 'Submit',

  // My Account
  'myAccount.header': 'My Account',
  'myAccount.greeting': 'Welcome',
  'myAccount.logout': 'Logout',
  'myAccount.edit': 'Edit',
  'myAccount.save': 'Save',
  'myAccount.change': 'Change',
  'myAccount.myOrders.title': 'Orders',
  'myAccount.myInvoices.title': 'Invoices',
  'myAccount.accountUsers.title': 'Account Users',
  'myAccount.addressBook.title': 'Address Book',
  'myAccount.addressBook.description': 'Select the address you wish to send this delivery to.',
  'myAccount.addressBook.viewAddressBook': 'My Address Book',
  'myAccount.addressBook.addDeliveryAddress': ' Add Delivery Address',
  'myAccount.addressBook.addressDelete.successMessage': 'Address deleted successfully',
  'myAccount.addressBook.addressUpdate.successMessage': 'Address updated successfully',
  'myAccount.addressBook.deliveryAddressUpdate.successMessage': 'Delivery Address Updated',
  'myAccount.addressBook.deliveryAddressUpdate.error': 'Error saving address',
  'myAccount.quotations.link': 'Quotations',
  'myAccount.quotations.title': 'Quotations',
  'myAccount.quotations.info': 'Store, edit, send and download your quotations. Quotations lock prices for 14 days.',
  'myAccount.quotations.label.name': 'Name',
  'myAccount.quotations.label.quoteRef': 'Quote Ref',
  'myAccount.accountUsers.primaryTitle': 'Business Account Users',
  'myAccount.accountUsers.secondaryTitle': 'Invite users to be part of your Business Account',
  'myAccount.accountUsers.userRoleADMIN': 'Authorised to spend on the company business account, add users, view account invoices and orders.',
  'myAccount.accountUsers.userRoleFINANCE': 'Authorised to view all account invoices and orders and purchase on account.',
  'myAccount.accountUsers.userRoleUSER': 'Authorised to spend on your business account and benefit from other features. Unable to add or manage user spending.',
  'myAccount.accountUsers.userRoleUSER.noCredit': 'Authorised to spend on your business account and benefit from other features. Unable to add or manage user spending.',
  'myAccount.accountUsers.noTradeAccounts': "You haven't added any users to your trade account.",
  'myAccount.accountUsers.monthlySpendPlaceholder': 'Monthly Spend',
  'myAccount.accountUsers.monthlySpendPlaceholderAdmin': 'Account Limit',
  'myAccount.accountUsers.monthlySpendLimitInvalid': 'Please enter a limit greater than 0.',
  'myAccount.quotations.label.quotationNo': 'Quotation ID',
  'myAccount.quotations.label.count': 'Items',
  'myAccount.quotations.label.cost': 'Total Cost',
  'myAccount.quotations.label.validuntil': 'Valid Until',
  'myAccount.quotations.label.action': 'Action',
  'myAccount.quotations.invalid.quantity': 'Incorrect quantity. Please review your quotation.',
  'myAccount.quotations.label.quotationTotal': 'Quotation Total',
  'myAccount.accountDetails.title': 'Account Details',
  'myAccount.changePassword.label.newPassword': 'New Password',
  'myAccount.changePassword.label.confirmNewPassword': 'Confirm New Password',
  'myAccount.emailPreferences.title': 'Marketing Preferences',
  'myAccount.emailPreferences.info': 'At Zoro.co.uk we take your privacy very seriously and only want to let you know about things you actually want to hear about, in the way you want to hear about them.',
  'myAccount.emailPreferences.unsubscribe.from.email': 'If you would still like to subscribe to some features but not others,',
  'myAccount.emailPreferences.info.two': ' So please use this setting centre to customise your alerts.',

  'myAccount.paymentDetails.title': 'Payment Details',
  'myAccount.paymentDetails.info': 'Manage payment methods and settings',
  'myAccount.paymentDetails.defaultPaymentMethod.title': 'Default Payment Method',
  'myAccount.paymentDetails.defaultPaymentMethod.info': 'Select your default payment method from the options below',
  'defaultPaymentMethod.updateRequest.account.text': 'Default payment method updated to Pay on Account',
  'defaultPaymentMethod.updateRequest.card.text': 'Default payment method updated to Card',
  'defaultPaymentMethod.updateRequest.paypal.text': 'Default payment method updated to Paypal',
  'defaultPaymentMethod.updateRequest.failed.text': 'Update default payment method request failed',
  'myAccount.paymentDetails.savedCards.title': 'Saved Cards',
  'myAccount.savedCards.title': 'Payment Details',
  'myAccount.savedCards.cardDetails.title': 'ends in',
  'myAccount.savedCards.noCardFound': 'No saved cards found',
  'myAccount.savedCards.yourCardsHeader': 'Your Cards',
  'myAccount.savedCards.defaultCardHeader': 'Default Card',
  'myAccount.savedCards.defaultHeader': 'Default',
  'myAccount.savedCards.expired': 'Expired',
  'myAccount.savedCards.expires': 'Expires',
  'myAccount.savedCards.cardInfo.name': 'Name on card',
  'myAccount.savedCards.cardInfo.billingAddress': 'Billing Address',
  'myAccount.savedCards.button.delete': 'Delete',
  'myAccount.savedCards.defaultPaymentMethod.changed': 'Default card updated succesfully',
  'myAccount.savedCards.defaultPaymentMethod.noDefaultCard': 'No default card selected',
  'myAccount.savedCards.defaultPaymentMethod.setDefaultCard': 'Set a Default Card',
  'myAccount.savedCards.defaultPaymentMethod.deleted.success': 'The saved card has been deleted succesfully',
  'myAccount.savedCards.defaultPaymentMethod.deleted.error': 'There was an ERROR deleting the saved card',

  'myAccount.myOrders.dispatchStatus.processing': 'Processing: Order Received',
  'myAccount.myOrders.dispatchStatus.processingPartiallyDispatched': 'Processing: Partially Dispatched',
  'myAccount.myOrders.dispatchStatus.processingPartiallyCancelled': 'Processing: Partially Cancelled',
  'myAccount.myOrders.dispatchStatus.completeDispatched': 'Complete: Dispatched',
  'myAccount.myOrders.dispatchStatus.completeCancelled': 'Complete: Cancelled',
  'myAccount.myOrders.dispatchStatus.completePartCancelled': 'Complete: Part Cancelled',
  'myAccount.myOrders.dispatchStatus.expectedDelivery': 'Exp Delivery By',
  'myAccount.myOrders.dispatchStatus.trackOrder': 'View tracking on Parcelforce',
  'myAccount.myOrders.dispatchStatus.original': 'Original ',
  'myAccount.myOrders.dispatchStatus.updated': 'Updated ',
  'myAccount.myOrders.updatedDeliveryDate': 'Delivery date updated',

  'myAccount.myOrders.label.poReference': 'Your reference',
  'myAccount.myOrders.label.title': 'Title',
  'myAccount.myOrders.label.orderCode': 'Order code',
  'myAccount.myOrders.label.unitPrice': 'Unit Price',
  'myAccount.myOrders.label.addToCart': 'Add to Basket',
  'myAccount.myOrders.label.quantity': 'Quantity',
  'myAccount.myOrders.label.totalPrice': 'Total Price',
  'myAccount.myOrders.label.status': 'Status',
  'myAccount.myOrders.label.cancellations': 'Cancellations',
  'myAccount.myOrders.label.cancelItem': 'Cancel item',
  'myAccount.myOrders.label.dontCancel': "Don't Cancel",
  'myAccount.myOrders.label.submitCancellation': 'Submit Cancellation',
  'myAccount.myOrders.label.deliveryInfo': 'Delivery Status',
  'myAccount.myOrders.label.dispatched': 'Dispatched',
  'myAccount.myOrders.label.liveTracking': 'Live Tracking',
  'myAccount.myOrders.label.cancelled': 'Cancelled',
  'myAccount.myOrders.label.processing': 'Processing',
  'myAccount.myOrders.label.statusUnavailable': 'Status Unavailable',
  'myAccount.myOrders.cancellation.requestTitle': 'Are you sure you want to submit a cancellation request?',
  'myAccount.myOrders.cancellation.requestMessage': 'Please note that some cancellation requests can not be guaranteed and this action can not be undone.',
  'myAccount.myOrders.cancellation.alreadyRequested': 'You have already sent a cancellation request for ({{qty}}) item(s).',
  'myAccount.myOrders.cancellation.cancellableQty': '{{qtyCancellable}} available to cancel as {{processingQty}} processing for dispatch',
  'myAccount.myOrders.cancellation.allQtyCancellable': '{{qtyCancellable}} available to cancel',
  'myAccount.myOrders.cancellation.itemsProcessing': 'Unable to cancel as {{qtyProcessing}} already processing for dispatch',
  'myAccount.myOrders.cancellation.thankYouMessage': 'Thank you, your cancellation request has been submitted and is pending for ({{qty}}) item(s).',
  'myAccount.myOrders.cancellation.qtyToCancel': 'Qty to cancel: ',
  'myAccount.myOrders.cancellation.thankYouEmail': 'An email confirming cancellation will be sent to you.',
  'myAccount.myOrders.cancellation.cancellationRequestFailed': 'There was an error while submitting a cancellation request for order {{orderId}}, product with sku {{sku}}',
  'myAccount.myOrders.cancellation.cancellationRequestDueToFailedAuthorization.one': 'Cancellation request initiated and is pending for {{qty}} item(s) as we were unable to take payment for this item. Please contact customer services team',
  'myAccount.myOrders.cancellation.cancellationRequestDueToFailedAuthorization.two': 'if you have any questions.',

  'myAccount.accountStatements.title': 'Account Statements',
  'myAccount.accountStatements.noStatementFound': 'No statements found',
  'myAccount.accountStatements.createdAtHeader': 'Created',
  'myAccount.accountStatements.statementNumberHeader': 'Statement Number',
  'myAccount.accountStatements.downloadHeader': 'Download',
  'myAccount.myOrders.allOrders': 'All Orders',
  'myAccount.myOrders.openOrders': 'Open Orders',
  'myAccount.myOrders.cancelledOrders': 'Cancelled Orders',
  'myAccount.myOrders.dispatchedOrders': 'Dispatched Orders',
  'myAccount.myOrders.clearAll': 'Clear Search',
  'myAccount.myOrders.ordersPlacedIn': 'orders placed in:',
  'myAccount.myOrders.noOrdersFound': 'No orders found',
  'myAccount.myOrders.searchInputPlaceholder': 'Search by order number, sku or product title',
  'myAccount.myOrders.clear': 'Clear',
  'myAccount.myOrders.orderPlacedIn': 'orders placed in:',
  'myAccount.myOrders.cancelOrderConfirmation': 'Check And Confirm',
  'myAccount.myOrders.pleaseCheckBox': 'Please check the box below to proceed',
  'myAccount.myOrders.checkBoxMessage': 'As a CS Agent, I confirm that I have checked this order cannot be fulfilled.',
  'myAccount.myOrders.cancelCanellation': 'Cancel Cancellation',
  'myAccount.myOrders.confirmCancellation': 'Confirm Cancellation',
  'myAccount.myOrders.cancellationValidationMsg': 'Please check the box in order to proceed if you can confirm the order cannot be fulfilled. If you need to check again, click Cancel Cancellation',
  'myAccount.myOrders.cancellation.success': 'Cancellation Request sent successfully',

  'myAccount.quotations.allQuotations': 'All Quotations',
  'myAccount.quotations.activeQuotates': 'Active Quotes',
  'myAccount.quotations.expiredQuotes': 'Expired Quotes',
  'myAccount.quotations.quotationPlaceIn': 'quotations over:',
  'myAccount.quotations.noQuotation': 'No Quotation found',
  'myAccount.quotations.reQuoteForPrices': 'Refresh for prices',
  'myAccount.quotations.priceChangeSave': 'Price Change! Save',
  'myAccount.quotations.expired': 'Expired',
  // create address
  'createAdddress.headerText.editAddress': 'Edit Address',
  'createAdddress.headerText.deliverAddress': 'Delivery Address',
  'createAdddress.headerText.billingAddress': 'Billing Address',

  // Repeat Orders -
  'myAccount.repeatOrders.title': 'Scheduled Orders',
  'myAccount.repeatOrders.info': 'Manage Scheduled Orders.',
  'myAccount.repeatOrders.stoped-order-info': 'Your old Scheduled Orders.',
  'myAccount.repeatOrders.label.reference': 'Reference',
  'myAccount.repeatOrders.label.itemsCount': 'Items',
  'myAccount.repeatOrders.label.totalCost': 'Total Cost',
  'myAccount.repeatOrders.label.regularity': 'Regularity',
  'myAccount.repeatOrders.label.endDate': 'End Date',
  'myAccount.repeatOrders.label.startDateText': 'Start Date',
  'myAccount.repeatOrders.label.nextScheduledDelivery': 'Next Delivery',
  'myAccount.repeatOrders.label.actions': 'Actions',
  'myAccount.repeatOrders.label.error.message': 'Unavailable. Please ',
  'myAccount.repeatOrders.label.error.contactus': 'contact us.',
  'repeatOrder.subscription.payment.error': 'Error updating payment method',
  'repeatOrder.subscription.initialize.error': 'Error getting Scheduled Orders',
  'repeatOrder.subscription.stopSubscription.error': 'Error stopping associated Scheduled Order',
  'subscriptionForm.label.existingSubscription': 'Add to existing Scheduled Order',
  'subscriptionForm.label.existingSubscription.select': 'Select an existing Scheduled Order',
  'subscriptionForm.createNewSubscription.ItemAdded': ' Item(s)',
  'subscriptionForm.title.existingSubscription': 'Add to an Existing Scheduled Order',
  'subscriptionForm.title.createNewSubscription': 'Create a New Scheduled Order',
  'subscriptionForm.label.aboutSubscription': 'Create Scheduled Orders of eligible products you need regularly. Create Scheduled Orders, edit them, pause them and cancel them at any time. To find out more about how Scheduled Orders work click here.',
  'subscriptionForm.label.productQuantity': 'Quantity',
  'createNewOrderForm.label.subscriptionName': 'Scheduled Order Name',
  'createNewOrderForm.titleHeader.text': 'Fill in your Scheduled Order Details',
  'createNewOrderForm.subscriptionOverview.text': 'Scheduled Order Overview',
  'createNewOrderForm.titleHeader.secondaryText': 'To find out more about how Scheduled Orders work ',
  'createNewOrderForm.titleHeader.clickHere': 'click here.',
  'createNewOrderForm.label.regularity': 'Please select the regularity of this Scheduled Order',
  'createNewOrderForm.label.outOfStockDeliveryPreference': 'Please select your preference in case your order is out of stock',
  'createNewOrderForm.label.startDate': 'Start Delivery date',
  'createNewOrderForm.label.endDate': 'End Delivery date',
  'createNewOrderForm.label.deliveryAddressId': 'Delivery Address',
  'createNewOrderForm.label.addNewDeliveryAddress': 'Add new delivery address',
  'createNewOrderForm.createdSuccesfully.text': '{{subscriptionName}} has been successfully created',
  'createNewOrderForm.createdSuccesfully.subTextOne': 'You created your first Scheduled Order.\n',
  'createNewOrderForm.createdSuccesfully.subTextTwo': 'You can view these details anytime in your account under ',
  'createNewOrderForm.orderSubscriptions': 'Scheduled Orders',
  'createNewOrderForm.addingToSubscription': 'Adding to Scheduled Order...',
  'createNewOrderForm.addProductsToSubscription.success': 'You successfully added your product to Scheduled Order {{subscriptionName}}',
  'createNewOrderForm.creationFailed.text': 'Creation of Scheduled Order {{subscriptionName}} failed',
  'createNewOrderForm.creationFailed.subTextOne': 'Please try again to create a Scheduled Order.',
  'addToSubscriptionForm.updateSubscription': 'Product has been added to {{orderReference}} ',
  'createNewSubscriptionForm.newSubscription': 'New Scheduled Order {{orderReference}} has been created',
  'createNewSubscriptionForm.selectDeliveryAddress': 'Select Delivery Address',
  'createNewSubscriptionForm.selectRegularity': 'Select Regularity',
  'addToMySubscriptionForm.selectSubscription': 'Select Scheduled Order',
  'addToMySubscriptionForm.addToSubscription': 'Add to Scheduled Order',
  'subscriptionOverview.none': 'None',
  'subscription.regularity.sendPartialOrder': 'Send Partial Order',
  'subscription.regularity.stopDelivery': 'Stop Delivery',
  'subscription.fieldError.subscriptionName': 'Please enter Scheduled Order name',
  'subscription.fieldError.outOfStockDeliveryPreference': 'Please select a delivery preference for when item is out of stock',
  'subscription.fieldError.regularity': 'Please select regularity',
  'subscription.fieldError.startDate': 'Please select the start date',
  'subscription.fieldError.deliveryAddressId': 'Please select delivery address',
  'subscription.fieldError.newOrExistingSubscription': 'Please select new or existing Scheduled Order',
  'createNewSubscriptionForm.startDate.noEndDate': 'Select End Date First',
  'createNewSubscriptionForm.startDate.optionalEndDate': '(Optional) Select End Date',
  'addToSubscriptionBtn.clickHere': ' click here.',
  'mySubscriptions.button.cancel': 'Cancel',
  'mySubscriptions.button.save': 'Save',
  'mySubscriptions.button.back': 'Back',
  'mySubscriptions.products.title': 'Products Included',
  'mySubscriptions.subtitle': 'The details of your Scheduled Order are below',
  'mySubscriptions.manage.title': 'Scheduled Order Details',
  'mySubscriptions.manage.status': 'Status',
  'mySubscriptions.manage.edit.title': 'Edit Scheduled Order Details',
  'mySubscriptions.manage.edit.regularity': 'Select the regularity of the Scheduled Order',
  'mySubscriptions.manage.edit.option.weekly': 'Weekly',
  'mySubscriptions.manage.edit.option.fortnightly': 'Fortnightly',
  'mySubscriptions.manage.edit.option.monthly': 'Monthly',
  'mySubscriptions.manage.delete.title': 'Delete Scheduled Order',
  'mySubscriptions.address.delivery.title': 'Delivery Details',
  'mySubscriptions.address.billing.title': 'Billing Details',
  'mySubscriptions.totals.totalGoods': 'Total Goods',
  'mySubscriptions.totals.totalVat': 'Total VAT',
  'mySubscriptions.totals.delivery': 'UK Delivery',
  'mySubscriptions.totals.free': 'FREE',
  'subscriptions.dialog.confirmation.start': 'Are you sure you want to ',
  'subscriptions.dialog.confirmation.end': ' this Scheduled Order',
  'subscriptions.dialog.next.delivery': 'The next scheduled delivery will be on',
  'subscriptions.dialog.warning': 'Please note: this action cannot be reversed',
  'subscriptions.dialog.pause': 'Pause',
  'subscriptions.dialog.play': 'Resume',
  'subscriptions.dialog.cancel': 'Cancel',
  'subscriptions.dialog.start.again': 'Start Again',
  'mySubscriptions.totals.total': 'Grand Total',
  'mySubscriptions.pay.account': 'Payment method updated to Pay on Account',
  'mySubscriptions.pay.card': 'Payment method updated to Credit/Debit Card ending in ',
  'product.not.available': 'Sorry! This product is no longer available',
  'mySubscriptions.account.create.error': 'Error creating Scheduled Order',
  'createNewScheduledOrder.button': 'Create Scheduled Order',

  // Paging
  'paging.next': 'Next ▶',
  'paging.previous': '◀ Previous',
  'product.family.table.header.priceQuantity': 'Price',
  'product.family.table.header.productName': 'Product Name',
  'family.paging.previous.text': 'Back',
  'family.paging.next.text': 'Next',
  'product.family.table.menu.filter': 'Filter',
  'product.family.table.menu.clear': 'Clear',
  'product.family.table.filter.clearAll': 'Clear All',
  'product.family.table.filter.clear': 'Clear',
  'product.family.table.search.placeholder': 'Search for Variants',

  // Global quotation
  'globalQuotation.lockQuoteToGetRefOne': 'Lock your quote to get your quotation reference.',
  'globalQuotation.lockQuoteToGetRefTwo': ' to create and mange your quotations.',
  'globalQuotation.lockForReference': 'Lock prices to get reference code',
  'globalQuotation.lockAndSave': 'Lock & Save',
  'globalQuotation.lockPricesFor14Days': 'Lock Prices for 14 days',
  'globalQuotation.noteThisRef': 'Note this ref to retrieve and buy this quote.',
  'globalQuotation.reQuote': 'Re-Quote',
  'globalQuotation.lockQuoteToPay': 'Lock quotation to be able to pay',
  'globalQuotation.totalExceedsLimit': 'Quotation grand total exceeds account limit.',
  'globalQuotation.retrieveQuoteText': 'Retrieve quotation. Enter code below',
  'globalQuotation.saveThisQuoteText': 'Save this quotation',
  'globalQuotation.downloadGlobalQuote': 'Download a PDF',
  'globalQuotation.yourQuote': 'Your Quotation',
  'globalQuotation.noQuoteFoundWithRef': 'No quotation with quote ref: {{quoteRefNo}} found.',
  'globalQuotation.tryDifferentCode': 'Please check your reference or try a different code. Thank you.',
  'globalQuotation.pleaseNote': 'Please note: ',
  'globalQuotation.itemsCan': 'Items and quantities can ',
  'globalQuotation.notChange': ' be changed while a quote is locked.',
  'globalQuotation.importantReminder': 'IMPORTANT! Please make a note of your quotation code: ',
  'globalQuotation.navigatingAway': 'By navigating away from this page you will only be able to access this quote by entering it into the quotation field on the right.',
  'globalQuotation.lockedAndSavedMessage': 'You can edit the quotation reference here, but please note in order to allow others to retrieve this quote, they will need to use code ',
  'globalQuotation.hasBeenLocked': ' has been locked and saved to you quotations. ',

  'globalQuotation.noProducts.howQuotationWorks': 'How our quotations works',
  'globalQuotation.noProducts.title': 'Your Quotation is currently empty',
  'globalQuotation.noProducts.subtitle': ' to create and manage quotations.',
  'globalQuotation.noProducts.descriptionTitle1': 'Quotations lock the very best price for that product for 14 days.',
  'globalQuotation.noProducts.descriptionTitle2': 'Quotations can later be retrieved, updated and turned into an order with a few clicks. However, the best thing about quotations is that they will lock the very best price for that product for 14 days.',
  'globalQuotation.noProducts.description.bold.1': 'Add product to Quote: ',
  'globalQuotation.noProducts.description.bold.2': 'Lock you Quote: ',
  'globalQuotation.noProducts.description.bold.3': 'Save the Quote: ',
  'globalQuotation.noProducts.description.bold.4': 'Get the best price for 14 days: ',
  'globalQuotation.noProducts.description.1': 'Click the Add to Quotation button on any product page.',
  'globalQuotation.noProducts.description.2': 'Once you have added all the products you want to a quotation, simply click "Lock quotation" and a quotation reference will be generated.',
  'globalQuotation.noProducts.description.3': 'Email or download your quotation as a pdf.',
  'globalQuotation.noProducts.description.4': 'If prices fall during your 14 day period this will be reflected in your quotation.You do not need to create a Zoro account to create, manage or retrieve a quote. However, the easiest way to manage your Quotes is through your Zoro account. More information about Business Quotation you can find',
  'globalQuotation.noProducts.description.5': 'When you are ready to buy simply enter your quotation reference into the box on the quotation page and you can purchase the items on that list.',
  'globalQuotation.noProducts.description.6': 'You will always get the best price. If prices fall during your 14 day period this will be reflected in your quotation.',
  'globalQuotation.noProducts.description.7': 'If you login/create an account with Zoro you will be able to more easily manage your quotations and hear about price reductions and offers on the products you are interested in.',
  'globalQuotation.selectQuotation': 'Select Quotation',
  'globalQuotation.addedToQuotation': 'Added to your Quotation',
  'globalQuotation.invalid.quantity': 'Please review your basket.',
  'globalQuotation.subscribeAlerts.beforeLinkMessage': 'You are not signed up to receive information about your quotations. To opt in please click ',
  'globalQuotation.subscribeAlerts.linkMessage': 'here',
  'globalQuotation.subscribeAlerts.afterLinkMessage': '.',
  'globalQuotation.successfullySubscribed.toastMessage': 'You are successfully subscribed to quotations emails.',

  // Confirmation Screen

  'confirmation.orderStatusText.payment_authorised': 'Your payment has been accepted and we started processing your order.',
  'confirmation.orderStatusText.order_under_review': 'We started processing your order.',
  'confirmation.orderStatusText.payment_fraudulent': 'We started processing your order.',
  'confirmation.orderStatusText.payment_rejected': 'Unfortunately your payment didn\'t go through. To complete your order please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.payment_required': 'Your order is awaiting payment. Please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.credit_new': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.credit_payment_authorised': 'Your payment has been accepted and we started processing your order.',
  'confirmation.orderStatusText.credit_payment_over_limit': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.credit_stopped': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.credit_check_error': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',
  'confirmation.orderRef.label': 'Your order reference is:',
  'confirmation.orderStatusText.test_credit_order': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',
  'confirmation.orderStatusText.test_order': 'Your order is being processed and you will receive email confirmation shortly. For questions please call us on {{contactNumber}}.',

  // UnsupportedBrowserDialog
  'unsupportedBrowser.title': 'Your Browser is out-of-date.',
  'unsupportedBrowser.mainText': 'It may not display all features of this and other sites.',
  'unsupportedBrowser.suggestionText': 'Please download the latest version of',
  'unsupportedBrowser.title.alt': 'We suggest upgrading to Chrome',
  'unsupportedBrowser.mainText.alt': 'to enjoy all the features of this site.',

  // Search Screen
  'search.noresults.header': 'Sorry, we couldn\'t find any results ',
  'search.noresults.tips.title': 'Search Tips:',
  'search.noresults.tips.tip1': 'Make sure all words are spelled correctly',
  'search.noresults.tips.tip2': 'Try different keywords',
  'search.noresults.tips.tip3': 'Try more general words',
  'search.noresults.tips.tip4': 'Try fewer keywords',
  'search.noresults.tips.tip5': 'Try different filters',
  'search.noresults.callus.title': 'Or call us on:',
  'search.noresults.emailus.title': 'Email customer support:',
  'search.noresults.searchAgain': 'Try adjusting the filters or try searching here:',
  'search.noresults.orcalluson': 'Or call us on:',
  'search.noresults.custsuppemail': 'Try searching again here:',
  'search.found.forQuery': 'Search results for {{keywordUsed}}',
  'search.found.zeroResults': 'Search for "{{originalKeyword}}" returned no results, matches for {{keywordUsed}}',
  'search.found.itemsFound': 'Items found',
  'search.allCategories': 'All Categories',
  'search.viewing.no.of.results.per.page': ', viewing {{noOfSearchResults}} results per page',
  'search.noresults.header.for': 'for',
  'search.option.no.of.results.display': '- Results Per Page',
  'search.option.view.more.information': 'View more information',

  // Search Bar
  'search.input.placeholder': 'Search Keyword, Item, SKU, MPN',
  'search.suggestion.brand.preposition': 'by',
  'search.suggestion.category.preposition': 'in',

  // Stock messaging
  'stockMessages.outOfStock': 'Out of Stock',
  'stockMessages.unavailable': 'Currently unavailable.',
  'stockMessages.unavailableAmount': 'Required amount not available.',
  'stockMessages.xAmountAvailable': '{{stockAmount}} Available',
  'stockMessages.discontinued': 'Discontinued',
  'stockMessages.onlyXInStock': `Only {stockValue} in stock. For larger orders call ${CUSTOMER_SUPPORT_PHONE}.`,
  'stockMessages.deliveryInXDays': '{deliveryTime} days',
  'stockMessages.deliveryTime': '{deliveryTime}',
  'stockMessages.transportRestrictions': 'Branch orderable only. Please contact your local branch.',

  // Product
  'product.cataloguePage.title': 'Catalogue Page',
  'product.incVat': 'Inc VAT ({{vat}}%)',
  'product.excVat': 'Exc VAT ({{vat}}%)',
  'product.addToBasket.continueShopping': 'Continue shopping',
  'product.addToBasket.goToBasket': 'Go to basket',
  'product.addToBasket.addToBasket': 'Add to Basket',
  'product.addToBasket.unavailable': 'Unavailable',
  'product.addToBasket.hasBeenAddedToBasket': 'Has been added to your basket',
  'product.filterPanel.clearSelection': 'Clear selection',
  'product.recommendedProducts.title': 'Special Offers',
  'product.qtyOf': 'Qty of {{qty}} for',
  'product.label.hashtags': 'Tags:',
  'product.description.readMore.less': 'See less description',
  'product.description.readMore.more': 'See full description',
  'product.header.brand.by': 'By',
  'product.productInformation.productFurtherInfo.title': 'Specifications for',
  'product.price.minimum.order.value': '{{priceWithMinOrderQty}} minimum order value',

  // Family
  'family.priceFrom': 'From',
  'family.priceOrLess': 'or less',
  'family.description': 'Description',
  'family.attributes': 'Feature Attributes',
  'family.showMoreAttributes': 'More attributes',
  'family.showLessAttributes': 'Show less',
  'family.refineSelection': 'Please refine your selection',
  'family.chooseVariant': 'Please choose a variant',
  'family.features': 'Features',
  'family.selectFeatures': 'Select Features',
  'family.specialOffers': 'Special Offers',
  'family.viewAllProductVariants': 'View all product variants',
  'family.viewNumberOfMatchingVariants': 'View {{numberOfMatchingProducts}} Variants',
  'family.variantFrom': 'Variants From',
  'family.unavailableProductVariants': 'No Product Variants found',
  'family.totalNumberOfVariants.label': 'Variants available',
  'family.totalNumberOfVariants.of.label': 'Showing {{numberOfVariantsByFilter}} of {{totalNumberOfVariants}} Variants',
  'family.productFamilyScreen.no.product.found': 'Sorry we couldn\'t find that product.',
  'family.productFamilyScreen.no.search.result.found': 'Your search returned no results',
  'family.productFamilyScreen.view.all.variants': 'View all variants.',

  // Credit Accounts
  'additionalUsers.emailAddresses.placeholder': 'Enter comma separated email addresses',
  'creditAccount.application.additionalUsers': 'Would you like to add additional users to your account?',
  'creditAccount.application.openCreditLine': 'Would you like to buy on credit (30 days end of month terms)?',
  'creditAccount.application.financeContact': 'Would you like to add a separate Invoice contact?',
  'creditAccount.application.preApplicationNumberText': 'Your ZORO Trade Account Application Reference Number is: ',
  'creditAccount.application.unverified.firstLine': 'Your ZORO Trade Account application already exists and is awaiting email verification. Once your email is verified; applications are usually processed within 2 business days.',
  'creditAccount.application.unverified.beforeLink': 'If you have not recieved an email in your primary contact email; please ',
  'creditAccount.application.unverified.linkText': 'contact us',
  'creditAccount.application.unverified.afterLink': ' with your application reference number: ',
  'creditAccount.application.verified.beforeLink': 'Your primary account email has been verified. Applications are usually processed within 2 business days. If you need help or further information; please ',
  'creditAccount.application.verified.linkText': 'contact us',
  'creditAccount.application.verified.afterLink': ' with your application reference number: ',
  'creditAccount.application.approved': 'Your account is active. You can place trade orders by opting for the "Pay by Account" button during checkout.',
  'creditAccount.application.declined': 'Sorry! We are unable to proceed based on the results of your earlier application.',
  'creditAccount.application.pageHeading': 'ZORO Business Account Application',
  'creditAccount.application.processTime': 'Please complete the form below to apply for a Zoro Business Account. Accounts operate on a net 30 days end of month basis and take up to 3 business days to approve. You will be notified by email.',
  'creditAccount.application.companyDetails': 'Company Details',
  'creditAccount.application.label.companyName': 'Company Name',
  'creditAccount.application.label.companyType': 'Company Type',
  'creditAccount.application.label.noOfStaff': 'No of Staff',
  'creditAccount.application.label.vatRegistered': 'My company is VAT registered',
  'creditAccount.application.label.companyWebsite': 'Business Website',
  'creditAccount.application.label.company.businessPhoneNumberType': 'Business Phone Number',
  'creditAccount.application.label.companyRegNo': 'Company Reg No',
  'creditAccount.application.label.charityRegNo': 'Charity Reg No',
  'creditAccount.application.label.vatRegNo': 'VAT Reg No',
  'additionalUsers.spendLimit.placeholder': 'Monthly spend limit',
  'creditAccount.application.label.expectedMonthlySpend': 'Expected Monthly Spend (£)',
  'creditAccount.application.label.industryDetails': 'Industry Details',
  'creditAccount.application.label.primaryIndustry': 'Primary Industry',
  'creditAccount.application.label.secondaryIndustry': 'Secondary Industry',
  'creditAccount.application.label.primaryFunction': 'Primary Function',
  'creditAccount.application.label.primaryFunctionStaffCount': 'No. of Primary Function Staff',
  'creditAccount.application.label.companyVatRegToggle': 'Is your company VAT registered?',
  'creditAccount.application.label.cncMachines': 'No. of CNC Machines',
  'creditAccount.application.primaryContactDetails.title': 'Primary Contact Details',
  'creditAccount.application.primaryContactDetails.firstName': 'Primary Contact First Name',
  'creditAccount.application.primaryContactDetails.lastName': 'Primary Contact Last Name',
  'creditAccount.application.primaryContactDetails.number': 'Phone Number',
  'creditAccount.application.primaryContactDetails.email': 'Email',
  'creditAccount.application.shortMessage.title': 'Add a short message',
  'creditAccount.application.colleaguesEmail.title': "Enter colleague's email address",
  'creditAccount.application.colleaguesEmail.email': 'Please provide a valid email address',
  'creditAccount.application.accountRole.title': 'Select Account Role',
  'creditAccount.application.accountRole.text': 'Account Role',
  'creditAccount.application.spendLimit.title': 'Set Monthly Spend Limit (Leave blank for no limit)',
  'creditAccount.application.expectedMonthlyLimit.title': 'Set Monthly Spend Limit (Leave blank to set expected monthly spend for account)',
  'creditAccount.application.primaryContactAddress.title': 'Registered Trade Address',
  'creditAccount.application.invoiceContactDetails.title': 'Invoice Details',
  'creditAccount.application.invoiceContactDetails.copyCheckboxLabel': 'Same as Primary Contact Details',
  'creditAccount.application.invoiceContactDetails.firstName': 'Invoice Contact First Name',
  'creditAccount.application.invoiceContactDetails.lastName': 'Invoice Contact Last Name',
  'creditAccount.application.invoiceContactDetails.number': 'Phone Number',
  'creditAccount.application.invoiceContactDetails.email': 'Email',
  'creditAccount.application.invoiceContactAddress.title': 'Invoice Address',
  'creditAccount.application.label.textBeforeTermsLink': 'I accept the trade ',
  'creditAccount.application.label.textTermsLink': 'Terms and Conditions',
  'creditAccount.application.creditAccountApllicationSubmit': 'Submit Application',
  'creditAccount.application.multiUser.title': 'Add users to your Business Account',
  'creditAccount.application.multiUser.moreUsersTitle': 'Would you like colleagues or administrators to use this business account?',
  'creditAccount.application.multiUser.canBeDoneLater': 'This can be done later.',
  'creditAccount.application.multiUser.duplicate.email.error': 'Email already added',
  'creditAccount.application.multiUser.applicant.duplicate.email.error': 'Sorry, main account holder cannot be added as a user. You will automatically be added as admin on application.',
  'BusinessAccount.onHold.pageHeading': 'Account on Hold',
  'BusinessAccount.onHold.firstLine': 'This order is on hold and may be delayed in processing while we review your balance and credit limit, usually 24-48 hours in addition to the advertised lead time.',
  'BusinessAccount.onHold.secondLine': 'If you need your items sooner, then please choose Pay by Card/PayPal.',
  'businessAccount.creditLineInfo': 'Zoro Business Accounts are free and make shopping on Zoro even easier by providing businesses with a credit-line and payment terms.',
  'businessAccount.applicationInfo': 'The application is quick and simple but you will need your company details.',
  'businessAccount.interestedInCreditAccounts': 'Interested in a Zoro Business Account?',
  'businessAccount.applied.header': 'Your application is in progress but you can start using your trade account now',
  'businessAccount.applied.text1': 'In order to verify your application, please click the verification link sent to your primary contact email.',
  'businessAccount.applied.text2': 'If you have any further questions; you can contact our Customer Service Team, by telephoning {{contactNumber}} or e-mailing us at support@zoro.co.uk Monday - Friday, 8:30am - 4:30pm.',
  'businessAccount.applied.text3': 'You can place orders on your unapproved account now however these will not be released until your application has been processed. This may add an additional 48 hours to any advertised delivery times. Alternatively you can pay for your current order using a credit card',
  'businessAccount.applied.continueCheckout': 'Continue with Checkout',
  'businessAccount.applied.continueCheckoutOnAccount': 'Continue Checkout on Account',
  'businessAccount.applied.continueCheckoutOnCard': 'Continue Checkout by Card/PayPal',
  'businessAccount.applied.continueShopping': 'Continue Shopping',
  'businessAccount.continueCheckout': 'No Thanks, Continue Checkout',
  'businessAccount.continueShopping': 'No Thanks, Continue Shopping',
  'businessApplications.invoiceDetails.firstName.error': 'First name is required',
  'businessApplications.invoiceDetails.firstName.error.max': 'First name must be less then 15 characters',
  'businessApplications.invoiceDetails.lastName.error': 'Last name is required',
  'businessApplications.invoiceDetails.lastName.error.max': 'Last name must be less then 15 characters',
  'businessApplications.invoiceDetails.email.error': 'Email is invalid',
  'businessApplications.invoiceDetails.telephone.error': 'Phone number is invalid',
  'businessApplications.invoiceDetails.jobTitle.error': 'Job title is required',
  'businessApplications.invoiceDetails.jobTitle.error.max': 'Job title must be less then 50 characters',
  'businessApplications.invoiceDetails.phoneNumber.error': 'Phone number is required',
  'businessApplications.companyDetails.companyName.error': 'Company name is required',
  'businessApplications.companyDetails.primaryIndustry.error': 'Primary industry is required',
  'businessApplications.companyDetails.companyType.error': 'Company type is required',
  'businessApplications.companyDetails.charityRegNo.error': 'Charity reg no is required',
  'businessApplications.companyDetails.companyVatRegNo.error': 'Company VAT reg no is required',
  'businessApplications.companyDetails.noOfStaff.error': 'No of staff is required',
  'businessApplications.companyDetails.noOfCncMachines.error': 'No of CNC machines is required',
  'businessApplications.companyDetails.businessPhoneNumber.error': 'Business phone number is required',
  'businessApplications.companyDetails.addressLine1.error': 'AddressLine1 is required',
  'businessApplications.companyDetails.city.error': 'City is required',
  'businessApplications.companyDetails.postCode.error': 'Post code is required',
  'businessApplications.addUser.hasTradeAccount.error': '{{email}} already a part of another trade account.',
  'businessApplications.email.isRequired': 'Email is required',
  'businessApplications.signIn.account': 'Sign in to apply for a business account',
  'businessApplications.signIn.description': 'You already have a Zoro account associated with your email address. Sign in to continue.',

  // Get Trade Account Banner
  'getTradeAccountBanner.heading': 'Open a Business Account',
  'getTradeAccountBanner.line2': 'Credit Terms - Multi User Accounts - Pay on Invoice',
  'getTradeAccountBanner.button': 'Apply Here',

  // Stay In Touch Banner
  'stayInTouchBanner.heading': 'Lets keep in touch!',
  'stayInTouchBanner.line1.preBold': 'Subscribe to our email newsletter and enjoy ',
  'stayInTouchBanner.line1.bold': '5% Off',
  'stayInTouchBanner.line1.postBold': ' your next order',
  'stayInTouchBanner.checkbox.label': 'Simply tick this box',
  'stayInTouchBanner.checkbox.smallMessage': 'Your email preferences can be managed via your My Accounts Section',
  'stayInTouchBanner.thankYou.title': 'Thank You!',
  'stayInTouchBanner.thankYou.smallMessage': 'An email with your 5% discount code will be sent to you within 24 hours',

  // FAQs
  // Quotations
  'quotations.title': 'Quotations FAQs',
  'quotations.intro': 'Click read more below to see frequently asked questions about how Zoro quotations work and how to get the most of them for your business.',
  'quotations.question1.title': 'What are quotations?',
  'quotations.question1.answer': 'Quotations allow you to create one or multiple collection of products that you may need for a specific job. Quotations can easily be emailed, printed and forwarded on for approval. Quotations can later get retrieved, updated and turned into an order with a few clicks.\n Quotations allow you to create one or multiple collection of products that you may need for a specific job. Quotations can easily be emailed, printed and forwarded on for approval. Quotations can later get retrieved, updated and turned into an order with a few clicks.',
  'quotations.question2.title': 'How do I create a quote?',
  'quotations.question2.answer': "To create a quote, you will need to be logged in.\n You can create a list directly from the product page and at checkout - Simply browse as normal, but rather than clicking ‘add to basket’ select ‘add to quotation’. To find your created quotes go to 'My Account' - 'Quotations'. Here you can edit, send and download them.",
  'quotations.question3.title': 'Can I remove/ add items to my quotation after it has been created?',
  'quotations.question3.answer': 'Yes, you can add items to your quotation directly from the product page and at checkout. By selecting ‘add to quotation’ the product/s will be added to your list which you can view through your account and your active quotations, and your total price will be updated. To remove items, go to ‘My Account’ and then ‘Quotations’. Here you will be given the opportunity to edit your quotation and remove items. Your total will be automatically updated.',
  'quotations.question4.title': 'Will editing the products in my quotation change the ‘locked in’ price?',
  'quotations.question4.answer': 'Yes – Adding or removing items from your list will be reflected in your quotation total. The prices of any previously added items will remain the same.',
  'quotations.question5.title': 'What happens if prices decrease within the 14-day period?',
  'quotations.question5.answer': 'If items in an active quotation (within the 14-day period) drop for any reason, for example a promotion or sale on the website which alters the price of the products in your list, then your quotation total will automatically reflect this. You will always benefit from the lowest price – even if it increases again once the promotion has ended.',
  'quotations.question6.title': 'What if prices increase during the 14-day period?',
  'quotations.question6.answer': 'Your quoted price will not change. Prices are automatically locked at the lowest amount and will only ever decrease if products become cheaper.',
  'quotations.orderLine.product': 'Product',
  'quotations.orderLine.quantity': 'Basket Qty',
  'quotations.orderLine.unitIncVat': 'Unit:Inc VAT',
  'quotations.orderLine.subTotalIncVat': 'Sub Total Inc VAT',
  'quotations.orderLine.subTotalExcVat': 'Sub Total Exc VAT',
  'quotations.orderLine.unitExcVat': 'Unit:Exc VAT',

  // Api Call Button
  'apiCallButton.buttonText': 'SUBMIT',
  'apiCallButton.processing': ' ',
  'apiCallButton.submitted': 'Redirecting...',
  'apiCallButton.error': 'Submit failed. Please try again.',
  'apiCallButton.buttonText.submit': 'Submit',

  // LOGIN
  'login.newcustomer': 'New Customer',
  'login.newcustomer.description': 'If you have not purchased from us before it is straightforward to create a new account',
  'login.returningcustomer': 'Returning Customer',
  'login.infohere': 'Learn More',
  'login.busineesaccount.headertext': 'To Apply for a Business account, you need to log into your Zoro Account. If you are a New Customer, then please create an account. ',
  'login.user.success.message': 'Welcome {{userName}}',

  // CONTACT US
  'contactus.description.customer.service.team': 'Our Customer Service Team are available to help with your enquiries.',
  'contactus.description.toll.free.number': 'Tel: {{contactNumber}}',
  'contactus.link.my.account': 'My Account',
  'contactus.myaccount.order.invoice': 'Remember you can check the status of your order at any time or download a VAT invoice from',
  'contactus.label.your.name': 'Your Name',
  'contactus.label.reason.for.contact': 'Reason for Contact',
  'contactus.label.your.email': 'Your Email',
  'contactus.label.your.message': 'Your Message',
  'contactus.thank.you.message': "By submitting your Business Application, you are creating an account with Zoro and agree to Zoro's",
  'contactus.support.message': 'Alternatively you can contact us on',
  'contactus.label.button.send.message': 'Send Message',
  'contactus.label.button.redirecting': 'Processing ...',
  'contactus.label.orderReferenceNumber': 'Order Number',
  'contactus.label.sku': 'Order Code/SKU',
  'contactus.label.tooltip.messaage.for.sku': 'This code starts with the letters \'ZT\' and can be found on the product page.',

  // Christmas related opening times
  'contact.description.xmas.heading': 'We are open on the following times between Christmas and New Year',
  'contact.description.xmas.note': 'Specific product delivery dates will be shown at the point of ordering.',
  'contact.description.xmas.openingtimes.christmaseve': 'Christmas Eve: 8am - 12.30pm',
  'contact.description.xmas.openingtimes.christmas': 'Christmas Day / Boxing Day - CLOSED',
  'contact.description.xmas.openingtimes.friday': 'Friday 27th Dec 9am - 5pm',
  'contact.description.xmas.openingtimes.monday': 'Monday 30th Dec 9am - 5pm',
  'contact.description.xmas.openingtimes.tuesday': 'Tuesday 31st Dec 9am - 3pm',
  'contact.description.xmas.openingtimes.wednesday': 'Wednesday 1st Jan - CLOSED',

  // Find more messages
  'findMore.clickHere': 'click here',
  'findMore.tieredPricing': 'Find out more about Tiered Pricing',
  'findMore.businessFeatures': 'Find out more about other Business Features',
  'findMore.quotations': 'Find out more about how Quotations work',

  //  Report Error Or Question Form
  'reportErrorOrQuestion.email.label': 'Your Email',
  'reportErrorOrQuestion.name.label': 'Your Name',
  'reportErrorOrQuestion.clickhere': 'Click here',
  'reportErrorOrQuestion.reasonForQuery': 'Reason For Contact',
  'reportErrorOrQuestion.query.label': 'Your Query',
  'reportErrorOrQuestion.radioButton.productQuery': 'Product Question',
  'reportErrorOrQuestion.radioButton.informationQuery': 'Content Error',
  'reportErrorOrQuestion.header.text': 'Ask a question or report an error',
  'reportErrorOrQuestion.header.description': 'If you have a technical question or have spotted an error ',
  'reportErrorOrQuestion.query.description': 'We aim to respond to technical queries as quickly as possible. Please provide as much information in relation to your question. Product Support is provided as guidance only.',
  'reportErrorOrQuestion.label.button.send.query': 'Send Query',
  'reportErrorOrQuestion.label.button.cancel': 'Cancel',
  'reportErrorOrQuestion.label.button.close': 'Close',
  'reportErrorOrQuestion.thanks.message': 'Thanks for your query!',
  'reportErrorOrQuestion.label.button.redirecting': 'Processing ...',

  // Delivery Options messages
  'orderReview.deliveryOptions.DeliveryOptionsTitle': 'Delivery Options',
  'orderReview.deliveryOptions.DeliveryOptionTitle': 'Delivery Option',
  'orderReview.deliveryOptions.showMore': 'See More Options',
  'orderReview.deliveryOptions.showLess': 'See Less Options',
  'orderReview.deliveryOptions.invalidOption': 'Selected delivery option is no longer valid. Please select another one.',

  'orderReview.payOnAccount.termsOfSalesBeforeLink': 'By clicking Pay on account you agree to our ',
  'orderReview.payOnAccount.termsOfSalesLink': ' Terms of Sale',
  'orderReview.payOnAccount.termsOfSalesAfterLink': '. For more information about how we process payments and use your payment details, please see our ',
  'orderReview.payOnAccount.privacyBeforeLink': ' and ',
  'orderReview.payOnAccount.privacyLink': 'privacy policy',
  'orderReview.payOnAccount.privacyAfterLink': '.',

  'checkoutBreadcrumbs.label.orderReview': '2/4. Order Review',
  'checkoutBreadcrumbs.label.optionsAvailable': 'Delivery Options Available.',
  'checkoutBreadcrumbs.label.seeProducts': 'See products.',
  'checkoutBreadcrumbs.label.optional': ' optional',

  // QuotationDialog
  'quotationDialog.label.viewQuotations': 'View Quotations',
  'quotationDialog.label.continueShopping': 'Continue shopping',
  'quotationDialog.confirmText.deleteQuotationWarning': 'Are you sure you want to delete this quotation?',
  'quotationDialog.confirmText.quotationWarningNote': 'Please note: this action cannot be reversed.',

  // QuoteLineDialog
  'quoteLineDialog.confirmText.deleteWarning': 'Are you sure you want to delete this item?',
  'quoteLineDialog.confirmText.additionalText': 'You will lose any additional discount you may have on it.',
  'quoteLineDialog.confirmText.productDelete': 'Quotation will be deleted as this is the only product in quotation!',

  // QuotationDetailsScreen
  'quotationDetailsScrn.label.orderAccount': 'Order on Account',
  'quotationDetailsScrn.label.orderPay': 'Proceed to Payment',
  'quotationDetailsScrn.button.find': 'Find',

  // stock messages
  'stockMessage.productScreen.header': 'Availability',
  'stockMessage.unavailable.message': 'Currently unavailable',
  'stockMessage.unavailable.text': 'Unavailable',
  'stockMessage.longerLeadTime.message': 'Available on a longer lead time. Please contact us for more information.',
  'stockMessage.longerLeadTime.message.recommendations': 'Not currently in stock',
  'stockMessage.longerLeadTime.text': 'Available on a longer lead time',
  'stockMessage.longerLeadTime.withQty.message': 'Available on a longer lead time. Please contact us for more information.',
  'stockMessage.ageRestricted.message': 'Unavailable.',
  'stockMessage.ageRestricted.text': 'Branch only order',
  'stockMessage.limited.stock.message': `Only {stockValue} in stock. For larger orders call ${CUSTOMER_SUPPORT_PHONE}.`,
  'stockMessage.limited.stock.text': 'Limited Stock',
  'stockMessage.out_of_stock.message': 'Out of Stock',
  'stockMessage.out_of_stock.text': 'Out of Stock',
  'stockMessage.tomorrow.message': 'Tomorrow',
  'stockMessage.instock.message': 'In Stock',
  'stockMessage.learnMore.message': 'Learn More',
  'stockMessage.available.message': 'Available',
  'stockMessage.getQtyBy.message': 'Get {qty} by',
  'stockMessage.getQtyWithin.message': 'Get {qty} within',
  'stockMessage.getItBy.message': 'Get it by',
  'stockMessage.getItOn.message': 'Get it on',
  'stockMessage.getItIn.message': 'Get it in',
  'stockMessage.getQtyOn.message': 'Get {qty} on',
  'stockMessage.getQtyIn.message': 'Get {qty} in',
  'stockMessage.getItWithin.message': 'Get it within',
  'stockMessage.toastInfo.heading': 'Review Delivery Times',
  'stockMessage.toastInfo.message': 'Some products are unavailable for immediate dispatch',
  'stockMessage.christmas.message': 'Delivery times may be longer than stated over Christmas and New Year.',
  'stockMessage.orderBy3pm': 'Order by 3pm,',
  'stockMessage.recommendations.inStock': 'In stock,',
  // shoveler
  'shoveler.industries.heading': 'Browse by Industry',
  'shoveler.industries.searchPlaceholder': 'Search our Industries',
  'shoveler.brands.heading': 'Browse by Brand',
  'shoveler.brands.searchPlaceholder': 'Search our Brands',
  'shoveler.categories.heading': 'Browse Top Categories',
  'shoveler.categories.searchPlaceholder': 'Search Categories',
  'shoveler.noResults': 'No results found for ',

  // AccountDetails
  'myAccount.accountDetails.accountInfo.name': 'Name',
  'myAccount.accountDetails.accountInfo.email': 'Email',
  'myAccount.accountDetails.already.exist.email': 'This email is already registered with another account, please use different email.',
  'myAccount.accountDetails.update.password.success': 'You successfully updated your password!',

  // Feefo Reviews

  'feefo.Serivce.Review.heading': 'Service Reviews',
  'feefo.Product.Review.heading': 'Product Reviews',
  'feefo.Serive.noResults': 'No Reviews Found',
  'feefo.review.title': 'Review',
  'feefo.reviews.title': 'Reviews',
  'feefo.reviews.total': '{{total}} Reviews',

  'quantity.promo.discount': 'Quantity Discount',
  'myAccount.accountDetails.accountInfo.defaultPrice': 'Default Price',
  'myAccount.accountDetails.accountInfo.defaultPrice.description': 'Set how you would always like to view prices',
  'myAccount.includeVat.title': 'Include VAT',
  'myAccount.excludeVat.title': 'Exclude VAT',
  'myAccount.includeVat.successMsg': 'All prices now set to Include VAT',
  'myAccount.excludeVat.successMsg': 'All prices now set to Exclude VAT',
  'header.label.onOrders': 'On Orders {{price}}* And Over',

  // Braintree Payment
  'payment.save.as.default': 'Save as default card',
  'payment.header': 'Pay with Card',
  'payment.label.button.redirecting': 'Processing Payment...',
  'payment.label.business.account': 'Zoro Business Account',
  'payment.label.button.next': 'Next',
  'payment.label.button.reviewOrderButton': 'Review Your Order',
  'payment.label.button.savingDetails': 'Saving Details...',
  'payment.label.button.errorSavingDetails': 'Error in Saving Details',
  'payment.label.button.back': 'Back',
  'payment.cardNumber.required.message': 'Card number is required',
  'payment.nameOnCard.required.message': 'Name on Card is required',
  'payment.nameOnCard.Invalid.message': 'Name on Card is not valid',
  'payment.cardNumber.Invalid.message': 'Card number is not valid',
  'payment.security.code.required.message': 'Security code required',
  'payment.security.code.Invalid.message': 'Security code is not valid',
  'payment.expiration.date.required.message': 'Expiration date required',
  'payment.expiration.date.Invalid.message': 'Expiration date is not valid',
  'payment.label.future.payments': 'Save this card for future orders',
  'payment.label.setAsDefaultCard': 'Set as default card',
  'payment.label.card.number': 'Card Number',
  'payment.label.expiration.date': 'Expiry Date',
  'payment.label.security.code': 'Security code',
  'payment.label.pay.with.card': 'Pay with Debit/Credit Card',
  'payment.label.pay.with.existingcard': 'Pay with an Existing Card',
  'payment.label.pay.with.myZoroAccount': 'My Zoro Account',
  'payment.label.add.new.card': 'New Credit/Debit Card',
  'payment.label.edit.card': 'Edit Card/Billing Details',
  'payment.label.pay.with.paypal': 'Pay with PayPal',
  'payment.label.add.new.paypal.account': 'Add New PayPal Account',
  'payment.label.paypal.account': 'PayPal Account',
  'payment.label.default.payment.method': 'Default Payment Method',
  'payment.label.default.name.on.card': 'Full Name on Card',
  'payment.label.payment.method.header': 'Payment Method',
  'payment.label.billing.address': 'Billing Address',
  'payment.label.billing.details': 'Billing Details',
  'payment.label.pleaseWait': 'Processing order, please wait',
  'payment.label.or': 'OR',
  'payment.label.paypal.paymentMethod.selected': 'PayPal payment method selected.',
  'payment.label.paypal.redirectNote': 'You will be redirected to sign in to your PayPal account. With a PayPal account, you\'re eligible for free return shipping, Purchase Protection, and more.',
  'payment.label.pay.with.paypal.or.edit.paypal': 'New PayPal Account/Edit PayPal Account',
  'payment.label.pay.progressbar.text': 'Processing PayPal',
  'payment.label.pay.with.applepay': 'Pay with Apple Pay',
  'payment.label.pay.with.googlepay': 'Pay with Google Pay',
  'payment.label.weAccept': 'Zoro UK accepts:',
  'payment.label.payPal': 'PayPal',
  'payment.label.changeDefaultCard': 'You can change your default card in',
  'payment.label.cardSelected': 'The card selected will become your default card on your account',
  'payment.label.payWithExistingCard': 'Pay with an Existing Card',
  'payment.label.newCreditDebitCard': 'New Credit/Debit Card',
  'payment.label.myZoroAccount': 'My Zoro Account',
  'payment.label.creditOrDebitCardText': 'Credit or Debit Card',
  'payment.label.sameAsDeliveryDetails': 'Same as my delivery details',
  'braintree.billingAgreementDescription': 'Zorouk',
  'braintree.hostedFieldsEmpty': 'All fields are empty! Please fill out the form.',
  'braintree.hostedFieldsInvalid': 'Some fields are invalid',
  'braintree.hostedFieldsTokenizationFailOnDuplicate': 'This payment method already exists in your vault.',
  'braintree.hostedFieldsTokenizationCvvFail': 'CVV did not pass verification',
  'braintree.hostedFieldsFailedTokenization': 'Tokenization failed server side. Is the card valid?',
  'braintree.hostedFieldsTokenizationNetworkError': 'Network error occurred when tokenizing.',
  'braintree.hostedFieldsTearDownError': 'Could not tear down Hosted Fields!',
  'braintree.get.clientToken.error': 'Error with braintree',
  'braintree.get.existing.card.error': 'Error retrieving existing cards',
  'requestCreditLimitIncrease.button.label': 'Request monthly spends limit increase',
  'requestCreditLimitIncrease.request.sent.message': 'You have reached your monthly account limit ({{accountLimit}}) this month, so cannot currently pay on account. You may request an increase ',
  'requestCreditLimitIncrease.request.sent.success.message': 'You have requested a monthly spends limit increase on {{requestLimitDate}} please contact your account admin ',
  'requestCreditLimitIncrease.request.sent.failed.error': 'Sorry, your request hasn’t been sent due to technical issue. Pease, try again',
  // addNewCardForm
  'addNewCardForm.label.ccNumber': 'Card Number',
  'addNewCardForm.label.ccHolderName': 'Full Name on Card',
  'addNewCardForm.label.ccExpiration': 'Expiry Date',
  'addNewCardForm.label.ccCVV': 'Security Code',
  'addNewCardForm.label.saveCardForFutureOrder': 'Save this card for future orders',
  'addNewCardForm.label.payOnAccount.companyName': '{companyName} Business Account',
  'addNewCardForm.label.payOnAccount.information': 'You may place order with your Zoro business account by clicking the Pay on Account button below.',

  // Bundles
  'select': 'Select',
  'total': 'Total:',
  'bundleprice': 'Bundle Price: ',
  'bundle.wasPrice': ' - Was {symbol}{amount}',

  // single page checkout
  'singlePageCheckout.next.label': 'Next',
  'singlePageCheckout.changeBillingAddress': 'Save',
  'singlePageCheckout.billingAddressNote': 'This address is linked to your default payment method. Please add new card if you need to change this address.',
  'singlePageCheckout.billingAddressRequired': 'Billing Address is required',
  'singlePageCheckout.deliveryAddressRequired': 'Delivery Address is required',
  'singlePageCheckout.payment.details.Required': 'Payment details are required',
  'singlePageCheckout.delivery.and.billing.address.required': 'Delivery and Billing Address are required',
  'singlePageCheckout.checkout.title': 'Hi {userFirstName}, checkout in {noOfSteps} simple steps.',
  'not.Allproduct.inStock': 'Not all product(s) were added due to being out of stock',
  'singlePageCheckout.checkout.panelName': '{panelId}. {panelName}',
  'singlePageCheckout.termsOfSale.beforeLink': 'By clicking Buy Now, you agree to our ',
  'singlePageCheckout.termsOfSale.applePay.beforeLink': 'By clicking Pay with Apple Pay, you agree to our ',
  'singlePageCheckout.termsOfSale.paypal.beforeLink': 'By clicking Pay with PayPal, you agree to our ',
  'singlePageCheckout.termsOfSale.googlePay.beforeLink': 'By clicking Pay with Google Pay, you agree to our ',
  'singlePageCheckout.termsOfSale.link': ' Terms of Sale',
  'singlePageCheckout.ampersand': ' & ',
  'singlePageCheckout.privacyPolicy.link': ' Privacy Policy',
  'singlePageCheckout.termsOfSale.afterLink': '.',
  'singlePageCheckout.deliveryOptionOrderReview': 'See Order Review',
  'singlePageCheckout.emptyBasket': 'Your basket is empty',
  'singlePageCheckout.csAgentMessageForFoc': ' (Disabled for Customer Service Agent during FOC)',
  'singlePageCheckout.cs.maskCallDialogTitle': 'Customer Service Agent:',
  'singlePageCheckout.cs.maskCallDialogBody': 'Please mask your call before receiving customer card details',
  'singlePageCheckout.cs.maskCallDialogAccept': 'Okay',
  'singlePageCheckout.cs.unmaskCallDialogBody': "Don't forget to unmask your call",
  'singlePageCheckout.skipPayment': 'Skip Payment: ',
  'singlePageCheckout.cardEnding': 'ending ...',
  'singlePageCheckout.card.selected': ' selected',
  'singlePageCheckout.paypalEmail': 'Email: ',
  'singlePageCheckout.buyNow.button.name': 'Buy Now',
  'singlePageCheckout.paymentMethod': 'Method: ',
  'singlePageCheckout.panel.addressLine': 'Address: ',
  'singlePageCheckout.place.order.error': 'Your order could not be processed. Please review payment details and try again.',
  'singlePageCheckout.panel.deliveryDetailsPanelName': 'Delivery Details',
  'singlePageCheckout.panel.paymentPanelName': 'Select Payment Method',
  'singlePageCheckout.panel.chooseFromOneOfYourSavedCards': 'Choose from one of your saved Cards',
  'singlePageCheckout.panel.addNewCreditOrDebitCard': 'Add new credit/debit card',
  'singlePageCheckout.panel.billingAddressPanelName': 'Billing Address',
  'singlePageCheckout.address.payment.required': 'Delivery, Billing and Payment details are required',
  'singlePageCheckout.billing.address.payment.required': 'Billing and Payment details are required',
  'singlePageCheckout.delivery.address.payment.required': 'Delivery and Payment details are required',
  'singlePageCheckout.panel.orderReviewPanelName': 'Order Review',
  'singlePageCheckout.select.payment.method.message': 'Please Select Payment Option',
  'singlePageCheckout.add.paypal.details': 'Please login into your PayPal Account, And Confirm your details',
  'singlePageCheckout.add.card.details': 'Please add your card details',
  'singlePageCheckout.cvv.info.line1': 'Card Verification Code, the three-digit number printed on the back of your card.',
  'singlePageCheckout.cvv.info.line2': 'For American Express it\'s the four-digit number printed on the front.',
  'singlePageCheckout.braintree.badge.line1': 'Zoro UK takes credit card data security very seriously.',
  'singlePageCheckout.braintree.badge.line2': 'For that reason, we use Braintree to securely handle credit card information.',
  'singlePageCheckout.braintree.badge.line3': 'Braintree is a Validated Level 1 PCI DSS Compliant Service Provider.',
  'singlePageCheckout.place.order.internal.server.error': 'Your order could not be processed, please try again.',
  'singlePageCheckout.delivery.details.required': 'Delivery Details are required',
  'singlePageCheckout.tooltip.future.payments': 'Braintree securely stores your information, including payment method so you can safely make future orders without entering your card details again.',
  'singlePageCheckout.tooltip.defaultCardInformation': 'Check this box to make this card your default payment method for all future Zoro purchases.',
  'singlePageCheckout.error.delivery.not.saved': 'Please save your new delivery address before placing order',
  'singlePageCheckout.error.billing.not.saved': 'Please save your new billing address before placing order',
  'singlePageCheckout.error.billing.confirm': 'Please confirm your billing address before placing order',
  'singlePageCheckout.error.new.card.empty': 'Please complete the new card form',
  'singlePageCheckout.card.declined.message': 'Sorry, your card was declined by your bank, please use a different card.',
  'singlePageCheckout.deliverTo': 'Deliver to:',
  'singlePageCheckout.phoneNumber': 'Phone Number:',
  'singlePageCheckout.addressType': 'Address Type:',
  'singlePageCheckout.proceedToPayment': 'Proceed to Payment',
  'singlePageCheckout.continue': 'Continue',
  'singlePageCheckout.chooseAnotherAddress': 'Change to another existing address',
  'singlePageCheckout.selectAnotherAddress': 'Select Existing Address',
  'singlePageCheckout.editDeliveryAddress': 'Edit Delivery Address',
  'singlePageCheckout.addessBook.yourAddressBook': 'Your Address Book',
  'singlePageCheckout.addessBook.listView': 'List View',
  'singlePageCheckout.addessBook.gridView': 'Grid View',
  'singlePageCheckout.addessBook.edit': 'Edit',
  'singlePageCheckout.addessBook.addressRef': 'Address Ref: ',
  'singlePageCheckout.addessBook.formattedAddress': '{companyName}, {addressLine1}, {addressLine2}, {city}, {postalCode}',
  'singlePageCheckout.addessBook.useThisAddress': 'Use this address',
  'singlePageCheckout.addessBook.addNewAddress': 'Add New Address',
  'singlePageCheckout.addessBook.goBackToDeliveryPanel': 'Go Back',
  'singlePageCheckout.error.setting.defaultCard': 'Error setting default card',
  'singlePageCheckout.error.saving.card': 'Error saving card',
  'singlePageCheckout.non.deliverable.product.text.1': 'This product cannot be delivered to your chosen delivery address.',
  'singlePageCheckout.non.deliverable.product.text.2': 'Please remove the product or choose another address to proceed.',
  'singlePageCheckout.non.deliverable.address.text.1': 'Some products in your basket cannot be delivered to this address.',
  'singlePageCheckout.non.deliverable.address.review.order': 'review your order for details.',
  'singlePageCheckout.non.deliverable.order': 'Unable to place order. Please',

  // checkout subscriptions
  'scheduledOrders.info.info.save.orderline': 'Create a scheduled order and save 5% on future deliveries',
  'scheduledOrders.info.info.save.orderline.mobile': 'Create a scheduled order and save 5%',
  'scheduledOrders.info.info.save.orderline.gold.platinum': 'Create a scheduled order and save time on future deliveries',
  'scheduledOrders.info.info.save.order': 'Create a single scheduled order for the items below  ',
  'scheduledOrders.settings.start.date': 'Get your 1st delivery on',
  'scheduledOrders.settings.stop.date': 'Stop after',
  'scheduledOrders.settings.delivery.frequency': 'Delivery Frequency',
  'scheduledOrders.settings.delivery.frequency.weekly': 'Weekly',
  'scheduledOrders.settings.delivery.frequency.fortnightly': 'Fortnightly',
  'scheduledOrders.settings.delivery.frequency.monthly': 'Monthly',
  'scheduledOrders.settings.out.of.stock.delivery.preference': 'Preference in case my order is out of stock',
  'scheduledOrders.no.of.orders': 'No of orders',
  'scheduledOrders.saved.settings': 'Scheduling to deliver {{orderQuantity}} every {{regularity}}, until {{endDate}}',
  'scheduledOrders.subscription.created': 'Scheduled to deliver {{orderQuantity}} every {{regularity}}, until {{endDate}}',
  'scheduledOrders.subscription.created.all.orderlines': 'Scheduled a single order for products below every {{regularity}}, until {{endDate}}',
  'scheduledOrders.out.of.stock.delivery.partial': 'Partial Order',
  'scheduledOrders.out.of.stock.delivery.stop': 'Stop Delivery',
  'scheduledOrders.save.schedule.settings': 'Save Settings',
  'scheduledOrders.edit.schedule': 'Edit scheduled order',
  'scheduledOrders.next.order.price': 'Estimated price for the next delivery',
  'scheduledOrders.goto.delivery.info': 'Delivery charges may apply. Please see our delivery information.',
  'scheduledOrders.goto.scheduledOrders.info.title': 'Scheduled orders',
  'scheduledOrders.goto.scheduledOrders.info.content': 'Create scheduled orders of eligible products you need regularly.\nMake changes, pause or cancel at any time.\n\nTo learn more click here.',
  'scheduledOrder.confirmation.goto.mysubscriptions': 'Review and amend your scheduled orders here.',
  'scheduledOrders.create.scheduled.order': 'Schedule order',
  'scheduledOrders.paymentModal.save.card.title': 'Save payment method for scheduled order',
  'scheduledOrders.paymentModal.save.card.message': 'To proceed with your scheduled order, save your card. \nAlternatively continue placing order without saving card and removing scheduling order.',
  'scheduledOrders.paymentModal.save.card.button': 'Save card and Place Order',
  'scheduledOrders.paymentModal.change.method.title': 'Change payment method',
  'scheduledOrders.paymentModal.change.method.message': 'To proceed with scheduling orders, please change your payment method',
  'scheduledOrders.paymentModal.change.method.button': 'Edit my payment method',
  'scheduledOrders.paymentModal.remove.subscriptions': 'Remove and Place Order',
  'scheduledOrders.toast.create.success': 'You successfully created scheduled orders',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text1': 'This address will incur an extra delivery charge.',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text2': ' Please see our ',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text3': 'page for further details.',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text4': 'Please ',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text5': 'click here',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text6': ' to choose another address.',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text7': ' delivery info',
  'singlePageCheckout.zone1.zone2.extra.delv.charge.text8': 'You can also see our delivery info page for further details.',
  // SinglePage AddressForm
  'singlePageCheckout.addressForm.label.firstName': 'First Name',
  'singlePageCheckout.addressForm.label.lastName': 'Last Name',
  'singlePageCheckout.addressForm.label.deliveryAddress': 'Delivery Address:',
  'singlePageCheckout.label.change.payment': 'Change Payment Method',
  // Quantity input error message
  'QuantityErrorMessage.input.multiples.message': 'This product must be ordered in multiples of {{qtyMultiplier}}',
  'QuantityErrorMessage.multiples.message.with.bound.limit': 'This product must be ordered in multiples of {{qtyMultiplier}}. Please change your quantity to {{boundMessage}}',
  'QuantityErrorMessage.input.short.message': 'Order in multiples of {{qtyMultiplier}}',
  'QuantityErrorMessage.min.multiples.error.message': 'Order must be for a minimum of {{minOrderQuantity}} and a multiple of {{qtyMultiplier}}. Please change your quantity to {{minOrderValue}}',
  'QuantityErrorMessage.max.multiples.error.message': 'Order must be for a maximum of {{maxOrderQuantity}} and a multiple of {{qtyMultiplier}}. Please change your quantity to {{maxOrderValue}}',
  'QuantityErrorMessage.min.error.message': 'Order must be for a minimum of {{minOrderQuantity}}. Please change your quantity to {{minOrderValue}}',
  'QuantityErrorMessage.max.error.message': 'Order must be for a maximum of {{maxOrderQuantity}}. Please change your quantity to {{maxOrderValue}}',
  'QuantityErrorMessage.min.multiple.valid.message': 'Order must be for a minimum of {{minOrderQuantity}} and a multiple of {{qtyMultiplier}}.',
  'QuantityErrorMessage.min.valid.message': 'Minimum order quantity: {{minOrderQuantity}}.',
  'searchPage.readMore': 'Read More',
  'searchPage.qtyMultiplier.forText': 'for',

  // Remove payment method dialog
  'paymentMethodDialog.confirmText.deletePaymentMethodWarning': 'Are you sure you want to delete card ',
  'paymentMethodDialog.confirmText.paymentMethodWarningNote': 'Please note: this action cannot be reversed.',
  'paymentMethodDialog.confirmText.cardLabelText': 'which ends in',
  'searchPage.priceOff': '% off',

  // Delivery information event codes and message
  // Stage 1 event code
  'ICRR': 'Dispatched',
  'ICRU': 'Dispatched',
  // Stage 2 event code
  'I155': 'Received by carrier',
  'ISCN': 'Received by carrier',
  'I159': 'Collection refused - parcel overweight',
  'I161': 'Collection attempted - not successful',
  'I169': 'Collection attempted - not successful',
  'I534': 'Returned to sender - parcel not collected',
  // Stage 3 event code
  'I101': 'In delivery depot',
  'I124': 'Held at delivery depot awaiting delivery',
  'I125': 'Held at depot at customers request',
  'I121': 'Held at depot parcel damaged',
  'I112': 'Depot can not locate Parcel',
  'I135': 'Parcel returned to sender',
  'I160': 'Parcels dimensions outside T\'s & C\'s',
  'I162': 'Parcels dimensions outside T\'s & C\'s',
  'ILDR': 'Parcel lost/damaged/returned to sender',
  'ILJ': 'Parcels dimensions outside T\'s & C\'s',
  'IPDA': 'Parcel returned to sender',
  'ISJ': 'Parcels dimensions outside T\'s & C\'s',
  'IWJ': 'Parcels dimensions outside T\'s & C\'s',
  'I148': 'Parcel arrived late at Delivery Office',
  'I533': 'Returned to sender - sender\'s request',
  // Stage 4 event code
  'I107': 'Forwarded to alternative destination',
  'I113': 'Parcel prepared for delivery',
  'I115': 'On vehicle for delivery',
  'I131': 'Forwarded to correct destination',
  'I137': 'Handed to delivery partner',
  'I156': 'Out for delivery',
  'IHWA': 'Change to delivery address',
  'IPAX': 'Change to delivery address',
  'I110': 'Redelivery arranged',
  'I111': 'Delivery arranged',
  'I114': 'Out for delivery',
  // Stage 5 event code
  'I119': 'Wrong address',
  'I120': 'Delivery attempt unsuccessful',
  'I122': 'Held at depot customer refused delivery',
  'I127': 'Awaiting collection from local parcelforce',
  'I128': 'Delivery attempt unsuccessful',
  'I139': 'Parcel collected',
  'I163': 'Delivery attempt unsuccessful',
  'I500': 'Customer collected from local post office',
  'I510': 'Awaiting collection from local post office',
  'I520': 'Customer collected from local post office',
  'I525': 'Customer collected from local post office',
  'ICON': 'Awaiting collection from local post office',
  'IHPD': 'Delivery attempt unsuccessful',
  'IPDF': 'Delivered',
  'IPDP': 'Not all items within consignment delivered',
  'IPMD': 'Delivered',
  'I136': 'Collected by customer',
  'I531': 'Returned to sender - addressee unknown',
  'I532': 'Returned to sender - refused by addressee',
  'I102': 'Delivered',
  'I108': 'Returned to sender - refused by addressee',

  // braintree cvv verify dialog
  'braintreeCvvCheckDialog.verify.label': 'Verify Your Card',
  'braintreeCvvCheckDialog.verify.description': 'Please verify your card by entering the security code.',
  'braintreeCvvCheckDialog.action.cancel': 'Cancel',
  'braintreeCvvCheckDialog.action.confirm': 'Confirm',
  'braintreeCvvCheckDialog.cardEnding': 'ending ...',
  'braintreeCvvCheckDialog.card.selected': ' selected',
  'braintreeCvvCheckDialog.card.expires': 'expires ',

  // Recommendations
  'recommendations.brandName.byText': 'By ',
  'recommendations.title.ellipsis': '...',
  'recommendations.list.seeMoreLinkText': 'See More',
  'recommendations.product.tile.discount.percentage': '-{{discount}}%',
  'recommendations.product.tile.previously.bought.label': 'Previously bought',

  'incVat': 'Inc VAT',
  'excVat': 'Exc VAT',
  'search.allBrands': 'All Brands',
  'search.browsebybrands': 'Browse by Brand Alphabetically',
  'quotation.moveItemsToBasket': 'Move items to Basket',

  'myAccount.returnAndReplacements': 'Refund & Replacement',
  'myAccount.myReturns.all': 'All',
  'myAccount.myReturns.refunds': 'Refunds',
  'myAccount.myReturns.replacements': 'Replacements',
  'myAccount.myReturns.message': 'To start select item(s) and option',
  'myAccount.returns.orderNumber': 'Order Number:',
  'myAccount.returns.REPLACERequest': 'Replacement request submitted',
  'myAccount.returns.REFUNDRequest': 'Refund request submitted',
  'myAccount.returns.REPLACE_AND_REFUNDRequest': 'Refund & Replacement request submitted',
  'myAccount.returns.REPLACE': 'Replacement',
  'myAccount.returns.REFUND': 'Refund',
  'myAccount.returns.REPLACE_AND_REFUND': 'Refund & Replacement',
  'myAccount.returns.submitted': 'Submitted',
  'myAccount.returns.returnOrReplacement': 'Refund or Replace',
  'myAccount.returns.REFUNDOrderNumber': 'Refund order number:',
  'myAccount.returns.REPLACEOrderNumber': 'Replacement order number:',
  'myAccount.returns.REPLACE_AND_REFUNDOrderNumber': 'Refund & Replacement order number:',
  'myAccount.returns.confirmationInfo': 'You have requested a {{requestType}} for the following product(s). A confirmation email will be sent to {{emailId}} shortly.',
  'myAccount.returns.whatHappendNext': 'What happens next?',
  'myAccount.returns.requestReviewMessage': 'Once your request has been approved and you have returned your items to the returns address provided, we will process your replacement order and you will receive a new order confirmation. Please refer to the order confirmation for a new delivery date.',
  'myAccount.returns.requestReviewMessageNotDelivered': 'Once your request has been approved and processed, you will receive a new order confirmation. Please refer to the order confirmation for a new delivery date.',
  'myAccount.returns.ineligibleProducts': 'Ineligible Products',
  'myAccount.returns.contactMessage': 'For any products that are not eligible for replacement, you will be contacted by our Customer Service Team for more details.',
  'myAccount.returns.backToReturn': 'Back to Refund & Replacement',
  'myAccount.returns.needHelp': 'Need help?',
  'myAccount.returns.customerSupportMessage': 'Our Customer Service Team are available to help.Monday - Friday 8:30am - 4:30pm',
  'myAccount.returns.supportTelNumber': `Tel: ${CUSTOMER_SUPPORT_PHONE}`,
  'myAccount.returns.email': 'Email:',
  'myAccount.returns.imageToolTip': `Up to 5 uploads in jpg or png format only. Max file size 5MB. If unable to add an image, please contact Customer Service at ${CUSTOMER_SUPPORT_PHONE}.`,
  'myAccount.returns.summaryTitle': 'Please review the selected product(s) you are requesting for {{requestType}} before submitting the request.',
  'myAccount.returns.unsureHeading': 'Unsure about something?',
  'myAccount.returns.attachImage': 'Attach Image',
  'myAccount.summary.reason': 'Reason',
  'myAccount.returns.warningMessage': 'Do not post your items back until you receive confirmation from us',
  'myAccount.returns.confirmation.next.step1': 'Our team will review your request',
  'myAccount.returns.confirmation.next.step2': 'We will send you the details on where and how to return',
  'myAccount.returns.confirmation.next.step3': 'Send back your item(s)',
  'myAccount.returns.confirmation.next.step4': 'Await your {{requestType}}',
  'myAccount.returns.refundMessage': 'Please allow 3-5 working days for refunds to appear in your account.',
  'myAccount.returns.nonRefundableProduct': 'Non-Refundable Products',
  'myAccount.returns.nonRefundableProductMessage': 'Once your request has been approved and you have returned your items to the returns address provided, we will process a refund or credit back to the original payment method. Please allow 3-5 working days for the refund to appear in your account.\nPlease refer to our returns policy for more information.',
  'myAccount.returns.nonRefundableProductMessageNotDelivered': 'Once your request has been approved, your refund will be credited back to the account used to place the order. Please allow 3-5 working days for refunds to appear in your account.',
  'myAccount.summary': 'Summary',
  'myAccount.summary.quantity': 'Quantity',
  'myAccount.summary.subTotal': 'Subtotal',
  'myAccount.summary.expectedRefund': 'Expected Refund*',
  'myAccount.summary.refundValue': 'Refund Value*',
  'myAccount.summary.wentWrongMessage': 'Something went wrong',
  'myAccount.summary.comments': 'Comments',
  'myAccount.summary.imageAdded': 'image Added',
  'myAccount.returns.image': 'Image:',
  'myAccount.returns.stillProcessing': 'Still Processing. Please check back soon.',
  'myAccount.returns.allReturnCreated': 'You have already placed a return request for all items in this order.',
  'myAccount.returns.more': 'There is more than {{noOfReturnRequests}} R&R request for this order',
  'myAccount.returns.pleaseLeaveComment': 'Please provide us with more details on your request',
  'myAccount.returns.pleaseSelectOption': 'Please select an option',
  'myAccount.returns.selectAnOption': 'Select an option',
  'myAccount.returns.validationMessageTitle': 'Attachment',
  'myAccount.returns.refundAmountSubject': 'Refund amount subject to',
  'myAccount.returns.fileSizeMessage': `Your file is larger than 5MB. The maximum you can upload is 5MB. If unable to add an image, please contact Customer Service at ${CUSTOMER_SUPPORT_PHONE}.`,
  'myAccount.returns.fileCountMessage': `You can upload up to 5 file in jpg or png format only. If unable to add an image, please contact Customer Service at ${CUSTOMER_SUPPORT_PHONE}.`,
  'myAccount.returns.noReturns': 'No {{returnStatus}} found. To make a refund or get a replacement, go to your',
  'myAccount.returns.placedIn': '{{totalCount}} placed in:',
  'myAccount.returns.refundAndReplacement': 'Refund & Replacement',
  'myAccount.returns.iLikeReplacement': 'I’d like a replacement',
  'myAccount.returns.iLikeRefund': 'I’d like a refund',
  'myAccount.returns.searchInputPlaceholder': 'Search by order number, sku or reference',
  'myAccount.returns.viewStatus': 'View status',
  'myAccount.returns.productOutsideReturnsPeriod': 'Product outside of returns period. Please email us at support@zoro.co.uk so we can help',
  'myAccount.returns.dropShipMessage': 'This item is non-returnable, please see our returns policy for more information. If the item arrives damaged or defective please call 0800 3688 665.',
  'myAccount.returns.dropShipAlert': 'This order is not eligible for refund/replacement from the website. To arrange return, please email us at',
  'myAccount.returns.pendingDropShipAlert': 'Some items are not eligible for refund/replacement from the website. To arrange return, please email us at',
  'myAccount.returns.productOutsideReturnsPeriodAlert': 'This order is outside of returns period. Please email us at',
  'myAccount.returns.commentRequired': 'Please add a comment',
  'myAccount.returns.imageRequired': 'Please attach an image',
  'myAccount.returns.qtyRequired': 'Please enter valid quantity',
  'myAccount.returns.wrongQty': 'Cannot be greater than order quantity',
  'myAccount.returns.optionRequired': 'Please select an option',
  'myAccount.returns.uploadMessage': 'Please wait while uploading',
  'myAccount.returns.ourReturnsPolicy': 'Our Returns Policy',
  'myAccount.returns.viewReturnRequest': 'View Return Request',
  'myAccount.returns.myReturns': 'You can track the progress of your return in the Refund and Replacement section of My Account.',
  'myAccount.returns.notApproved': 'Not Approved - Call for more information.',
  'myAccount.returns.formError': 'Please ensure you have completed all steps',
  'myAccount.returns.csAgentNonRefundableProduct': 'This item is non-returnable, please ensure you follow the guidelines.',
  'myAccount.returns.csAgentNonReturnableProduct': 'Supplier not accepting returns for this item.',
  'ui.dialog.okay': 'Okay',
  'ui.dialog.leave': 'Leave',
  'ui.dialog.backEditing': 'Back to editing',
  'ui.dialog.cancelReturnConfirmationDialog.title': 'Are you sure you want to leave?',
  'ui.dialog.cancelReturnConfirmationDialog.message': 'You are about to leave this refund and replacement request. Changes you made will not be saved.',
  'table.header.orderNumber': 'Order Number',
  'table.header.requestNumber': 'Request Number',
  'table.header.type': 'Type',
  'table.header.yourReference': 'Your Reference',
  'table.header.status': 'Status',
  'businessAccount.apply': 'Apply for Business Account',
  'return.request.confirmation': 'Returns timeframe exceeded',
  'return .request.message': 'The returns timeframe for this order has now passed. Would you still like to proceed?',
  'button.label.proceed': 'Proceed',

  // myinvoice
  'myInvoice.selectedTab.account': 'Account Invoice',
  'myInvoice.selectedTab.cash': 'Cash Invoice',
  'myInvoice.totalAccountBalance': 'Total Account Balance',
  'myInvoice.totalUnpaidOverdue': 'Total Unpaid Amount Over-Due',
  'myInvoice.paymentRefect': 'If you have already made the payment,the amount will be reflected in 48 hours.',
  'myInvoice.bank': 'Bank',
  'myInvoice.bank.value': 'Lloyds Bank Plc',
  'myInvoice.beneficiary': 'Beneficiary Name',
  'myInvoice.beneficiary.value': 'Zoro UK Limited',
  'myInvoice.address': 'Address',
  'myInvoice.address.value': 'PO Box 72, Bailey Drive, Gillingham Business Park, Kent, ME8 0LS',
  'myInvoice.sortCode': 'Sort Code',
  'myInvoice.sortCode.value': '30-80-12',
  'myInvoice.accountNo': 'Account',
  'myInvoice.accountNo.value': '22035960',
  'myInvoice.IBAN': 'IBAN Number',
  'myInvoice.IBAN.value': 'GB32LOYD30801222035960',
  'myInvoice.BIC': 'BIC',
  'myInvoice.BIC.value': 'LOYDGB21F09',
  'myInvoice.bankDetails': 'Zoro Bank Transfer Details',
  'myInvoice.DueBy': 'Due by',
  'myInvoice.invoiceNo': 'Invoice Number: ',
  'myInvoice.orderId': 'Order Id: ',
  'myInvoice.amount': 'Amount: ',
  'myInvoice.makePayment': 'Make Payment',
  'myInvoice.dueBy': 'Due by {{invoiceDueDate}}',
  'myInvoice.unpaid': 'Unpaid',
  'myInvoice.overDue': ' - OverDue',
  'myInvoice.payable': ' - Payable',
  'myInvoice.AccountInvoice': 'AccountInvoice',
  'myInvoice.searchInputPlaceholder': 'Search by invoice number, order ID or PO number',

  'return.request.upload.error': 'Error while uploading file',
  'return.request.fetch.error': 'Error while fetching returns',

  // unsupported IE browser
  'unsupportedBrowser.heading': 'We no longer support IE11 as Microsoft has discontinued supporting the browser.',
  'unsupportedBrowser.subHeading': 'Please upgrade your browser to Edge, Chrome or Firefox to enjoy all the features of this site',

  // business account status on-hold warning message
  'account.onhold.message.admin': 'Your account has been put on hold. Please complete your pending invoices.',
  'account.onhold.message.users': 'Your account has been put on hold. Please contact your account admin ({{primaryContact}}) for support.',
  'account.onhold.message': 'Account on hold',
  'account.onhold.full.message': 'Your account has been put on hold.',
  'account.onhold.subMessage': 'Please check the details ',
  'account.viewInvoices': 'View Invoices',
  'label.quotation': 'Quotation',
  'label.quickOrder': 'Quick Order ▾',
  'label.quickOrder.model': 'Enter item numbers and quantity below.',
  'label.sku': 'SKU',
  'label.qty': 'Qty',
  'login.register.label': 'Login / Register',
  'nonRefundableType.warning.message': 'Non-returnable',
  'nonRefundableType.originalpacking.warning.message': 'Only returnable if left sealed and in its original packaging',

  // Custom Error Pages
  'error.page.header': 'Oops!',
  'error.page.go.to.homepage': 'Go to homepage',
  'error.page.404.subHeader': 'Page not found',
  'error.page.404.message': `We're sorry, the page you requested could not be found`,

  // POA products
  'POA.product.request.price': 'Request Price',
  'POA.product.text.poa': 'POA',

  //
  'csAgnet.banner.title': 'Customer service session:',
  'label.view.all': 'View all',
  'label.shop.categories': 'Shop Categories',
  'label.categories': 'Categories',

  'account.onhold.message1': 'Your account is currently under review, which may slightly delay the processing of your order. Rest assured, we aim to have it processed within 2 working days.',
  'account.onhold.message2': 'If you require immediate processing, feel free to choose an alternative payment method.',
  'account.onhold.message3': 'Your Zoro credit Account is still under review, so that order will be processed within 2 working days.'
};
