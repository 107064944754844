import React from 'react';

if (process.browser) {
  require('./SaleTag.scss');
}

const SaleTag = ({ label, promoUrl = '' }) => {
  if (promoUrl && promoUrl !== '') {
    return (
      <span className="SaleTag" data-e2e={ label } >
        <a href={ promoUrl }>{ label }</a>
      </span>
    );
  }
  return (
    <span className="SaleTag" data-e2e={ label }>
      { label }
    </span>
  );
};

export default SaleTag;
