import { createAction } from 'redux-actions';
import { RUNNING } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

export const REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS = 'repeatOrdersActions.REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS';
export const requestAllNotExpiredSavedCards = createAction(REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS);

export const REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS_SUCCESS = 'repeatOrdersActions.REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS_SUCCESS';
export const requestAllNotExpiredSavedCardsSuccess = createAction(REQUEST_ALL_NOT_EXPIRED_SAVED_CARDS_SUCCESS);

export const CANCEL_EDITING_REPEAT_ORDER_DETAILS = 'repeatOrdersActions.CANCEL_EDITING_REPEAT_ORDER_DETAILS';
export const cancelEditingRepeatOrderDetails = (repeatOrderId) => ({
  type: CANCEL_EDITING_REPEAT_ORDER_DETAILS,
  repeatOrderId
});

export const LOADING_UPDATE_REPEAT_ORDER = 'repeatOrdersActions.LOADING_UPDATE_REPEAT_ORDER';
export const loadUpdateRepeatOrder = (repeatOrderId, isLoading) => ({
  type: LOADING_UPDATE_REPEAT_ORDER,
  repeatOrderId,
  isLoading
});

export const UPDATE_REPEAT_ORDER = 'repeatOrdersActions.UPDATE_REPEAT_ORDER';
export const updateRepeatOrder = (repeatOrderId) => ({
  type: UPDATE_REPEAT_ORDER,
  repeatOrderId
});

export const CHANGE_PAYMENT_METHOD_SELECTED = 'repeatOrdersActions.CHANGE_PAYMENT_METHOD_SELECTED';
export const changePaymentMethodSelected = (value) => ({
  type: CHANGE_PAYMENT_METHOD_SELECTED,
  value
});

export const EDIT_REPEAT_ORDER_DETAILS = 'repeatOrdersActions.EDIT_REPEAT_ORDER_DETAILS';
export const editRepeatOrderDetails = (repeatOrderId) => ({
  type: EDIT_REPEAT_ORDER_DETAILS,
  repeatOrderId
});

export const FETCH_REPEATORDERS = 'repeatOrdersActions.FETCH_REPEATORDERS';
export const FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS = 'repeatOrdersActions.FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS';

export const fetchRepeatOrders = createAction(FETCH_REPEATORDERS);
export const fetchRepeatOrdersWithoutPriceDetails = createAction(FETCH_REPEATORDERS_WITHOUT_PRICE_DETAILS);

export const ADD_PRODUCT_TO_REPEAT_ORDER_SUCCESS = 'repeatOrdersActions.ADD_PRODUCT_TO_REPEAT_ORDER_SUCCESS';
export const ADD_PRODUCT_TO_REPEAT_ORDER_FAILED = 'repeatOrdersActions.ADD_PRODUCT_TO_REPEAT_ORDER_FAILED';
export const RESET_ADD_PRODUCT_TO_REPEAT_ORDER = 'repeatOrdersActions.RESET_ADD_PRODUCT_TO_REPEAT_ORDER';
export const RESET_BANNER_STATUSES = 'repeatOrdersActions.RESET_BANNER_STATUSES';

export const addProductToRepeatOrderSuccess = createAction(ADD_PRODUCT_TO_REPEAT_ORDER_SUCCESS);
export const addProductToRepeatOrderFailed = createAction(ADD_PRODUCT_TO_REPEAT_ORDER_FAILED);
export const resetAddProductToRepeatOrder = createAction(RESET_ADD_PRODUCT_TO_REPEAT_ORDER);
export const resetBannerStatuses = createAction(RESET_BANNER_STATUSES);

export const FETCH_REPEATORDERS_SUCCESS = 'repeatOrdersActions.FETCH_REPEATORDERS_SUCCESS';

export const fetchRepeatOrdersSuccess = (repeatOrders) => ({
  type: FETCH_REPEATORDERS_SUCCESS,
  repeatOrders
});

export const FETCH_REPEAT_ORDER_PRODUCT_SUCCESS = 'repeatOrdersActions.FETCH_REPEAT_ORDER_PRODUCT_SUCCESS';
export const fetchRepeatOrderProductSuccess = (product, sku) => ({
  type: FETCH_REPEAT_ORDER_PRODUCT_SUCCESS,
  product,
  sku
});

export const REQUEST_REMOVE_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.REQUEST_REMOVE_REPEAT_ORDER_CONFIRM';
export const requestRemoveRepeatOrderConfirm = createAction(REQUEST_REMOVE_REPEAT_ORDER_CONFIRM);

export const DELETE_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.DELETE_REPEAT_ORDER_CONFIRM';

export const deleteRepeatOrderConfirm = (repeatOrderId, page) => ({
  type: DELETE_REPEAT_ORDER_CONFIRM,
  repeatOrderId,
  page
});

export const DELETE_REPEAT_ORDER_SUCCESS = 'repeatOrdersActions.DELETE_REPEAT_ORDER_SUCCESS';
export const deleteRepeatOrderSuccess = (repeatOrderDetails) => ({
  type: DELETE_REPEAT_ORDER_SUCCESS,
  repeatOrderDetails
});

export const REQUEST_EDIT_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.REQUEST_EDIT_REPEAT_ORDER_CONFIRM';
export const requestEditRepeatOrderConfirm = createAction(REQUEST_EDIT_REPEAT_ORDER_CONFIRM);

export const EDIT_REPEAT_ORDER_SUCCESS = 'repeatOrdersActions.EDIT_REPEAT_ORDER_SUCCESS';
export const editRepeatOrderSuccess = (repeatOrder) => ({
  type: EDIT_REPEAT_ORDER_SUCCESS,
  repeatOrder: repeatOrder
});

export const REQUEST_PAUSE_PLAY_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.REQUEST_PAUSE_PLAY_REPEAT_ORDER_CONFIRM';
export const requestPausePlayRepeatOrderConfirm = createAction(REQUEST_PAUSE_PLAY_REPEAT_ORDER_CONFIRM);

export const PAUSE_PLAY_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.PAUSE_PLAY_REPEAT_ORDER_CONFIRM';
export const pausePlayRepeatOrderConfirm = (repeatOrder, page) => ({
  type: PAUSE_PLAY_REPEAT_ORDER_CONFIRM,
  repeatOrder,
  page
});

export const PAUSE_PLAY_REPEAT_ORDER_SUCCESS = 'repeatOrdersActions.PAUSE_PLAY_REPEAT_ORDER_SUCCESS';
export const pausePlayRepeatOrderSuccess = (repeatOrder, page) => ({
  type: PAUSE_PLAY_REPEAT_ORDER_SUCCESS,
  repeatOrder: repeatOrder,
  page: page
});

export const ADD_PRODUCT_TO_REPEAT_ORDER = 'repeatOrdersActions.ADD_PRODUCT_TO_REPEAT_ORDER';
export const addProductsToRepeatOrder = (existingSubscriptionId, sku, subscriptionProductQuantity, orderReference, name) => ({
  type: ADD_PRODUCT_TO_REPEAT_ORDER,
  payload: {
    existingSubscriptionId, sku, subscriptionProductQuantity, orderReference, name
  }
});

export const SHOW_CREATE_NEW_REPEAT_ORDER_FORM = 'repeatOrdersActions.SHOW_CREATE_NEW_REPEAT_ORDER_FORM';
export const displayCreateRepeatOrderForm = createAction(SHOW_CREATE_NEW_REPEAT_ORDER_FORM);

export const SHOW_CREATE_NEW_ADDRESS_FORM = 'repeatOrdersActions.SHOW_CREATE_NEW_ADDRESS_FORM';
export const displayAddressForm = createAction(SHOW_CREATE_NEW_ADDRESS_FORM);

export const RESET_NEW_REPEAT_ORDER_DETAILS = 'repeatOrdersActions.RESET_NEW_REPEAT_ORDER_DETAILS';
export const resetNewRepeatOrderDetails = createAction(RESET_NEW_REPEAT_ORDER_DETAILS);

export const NEW_REPEAT_ORDER_DETAILS = 'repeatOrdersActions.NEW_REPEAT_ORDER_DETAILS';
export const newRepeatOrderDetails = (
  sku,
  subscriptionProductQuantity,
  orderReference,
  regularity,
  startDate,
  endDate,
  deliveryAddressId,
  outOfStockDeliveryPreference,
  name,
  price
) => {
  return {
    type: NEW_REPEAT_ORDER_DETAILS,
    payload: {
      sku,
      name,
      subscriptionProductQuantity,
      orderReference,
      regularity,
      startDate,
      endDate,
      deliveryAddressId,
      outOfStockDeliveryPreference,
      price,
      state: RUNNING
    }
  };
};

export const CREATE_SUBSCRIPTION = 'repeatOrdersActions.CREATE_SUBSCRIPTION';
export const createSubscription = createAction(CREATE_SUBSCRIPTION);

export const CREATE_SUBSCRIPTION_SUCCESS = 'repeatOrdersActions.POST_ORDER_SUCCESS';
export const createSubscriptionSuccess = createAction(CREATE_SUBSCRIPTION_SUCCESS);

export const CREATE_SUBSCRIPTION_FAILED = 'repeatOrdersActions.CREATE_SUBSCRIPTION_FAILED';
export const createSubscriptionFailed = createAction(CREATE_SUBSCRIPTION_FAILED);

export const RESET_SUBSCRIPTION_REVIEW_STATE = 'repeatOrdersActions.RESET_SUBSCRIPTION_REVIEW_STATE';
export const resetSubscriptionReviewState = createAction(RESET_SUBSCRIPTION_REVIEW_STATE);

export const REQUEST_CONFIRM_SUBSCRIPTION = 'repeatOrdersActions.REQUEST_CONFIRM_SUBSCRIPTION';
export const requestConfirmSubscription = createAction(REQUEST_CONFIRM_SUBSCRIPTION);

export const SAVE_NEW_DELIVERY_ADDRESS = 'repeatOrdersActions.SAVE_NEW_DELIVERY_ADDRESS';
export const saveNewDeliveryAddress = (address, createNewSubscriptionNextStep) => {
  return {
    type: SAVE_NEW_DELIVERY_ADDRESS,
    payload: {
      address,
      createNewSubscriptionNextStep
    }
  };
};

export const SET_NEW_ADDRESS_AS_SELECTED = 'repeatOrdersActions.SET_NEW_ADDRESS_AS_SELECTED';
export const setNewAddressAsSelected = createAction(SET_NEW_ADDRESS_AS_SELECTED);
export const SET_DEFAULT_DELIVERY_ADDRESS = 'repeatOrdersActions.SET_DEFAULT_DELIVERY_ADDRESS';
export const setDefaultDeliveryAddress = createAction(SET_DEFAULT_DELIVERY_ADDRESS);

export const CHANGE_DELIVERY_ADDRESS = 'repeatOrdersActions.CHANGE_DELIVERY_ADDRESS';
export const onDeliveryAddressChange = createAction(CHANGE_DELIVERY_ADDRESS);

export const SET_PRODUCT_AND_QUANTITY = 'repeatOrdersActions.SET_PRODUCT_AND_QUANTITY';
export const setProductAndQuantity = createAction(SET_PRODUCT_AND_QUANTITY);

export const FETCH_REPEATORDER_BY_ID = 'repeatOrdersActions.FETCH_REPEATORDER_BY_ID';
export const fetchRepeatOrderById = (id) => ({
  type: FETCH_REPEATORDER_BY_ID,
  id
});

export const UPDATE_REPEAT_ORDER_DETAILS = 'repeatOrdersActions.UPDATE_REPEAT_ORDER_DETAILS';
export const updateRepeatOrderDetails = (repeatOrderDetails) => ({
  type: UPDATE_REPEAT_ORDER_DETAILS,
  repeatOrderDetails
});

export const REMOVE_PRODUCT = 'repeatOrdersActions.REMOVE_PRODUCT';
export const removeProduct = (sku, quantity, repeatOrderId, isLastItem) => ({
  type: REMOVE_PRODUCT,
  sku,
  quantity,
  repeatOrderId,
  isLastItem
});

export const ADD_PRODUCT = 'repeatOrdersActions.ADD_PRODUCT';
export const addProduct = (sku, quantity, repeatOrderId, name, isValidQuantity) => ({
  type: ADD_PRODUCT,
  sku,
  quantity,
  name,
  repeatOrderId,
  isValidQuantity
});

export const UPDATE_QUANTITY = 'repeatOrdersActions.UPDATE_QUANTITY';
export const updateQuantity = (sku, quantity, repeatOrderId) => ({
  type: UPDATE_QUANTITY,
  sku,
  quantity,
  repeatOrderId
});

export const REQUEST_REMOVE_PRODUCT_REPEAT_ORDER_CONFIRM = 'repeatOrdersActions.REQUEST_REMOVE_PRODUCT_REPEAT_ORDER_CONFIRM';
export const requestRemoveProductRepeatOrderConfirm = createAction(REQUEST_REMOVE_PRODUCT_REPEAT_ORDER_CONFIRM);

export const SET_SUBSCRIPTION_NAME = 'repeatOrdersActions.SET_SUBSCRIPTION_NAME';
export const setSubscriptionName = createAction(SET_SUBSCRIPTION_NAME);

export const PAYMENT_METHOD_UPDATED = 'repeatOrdersActions.PAYMENT_METHOD_UPDATED';
export const paymentMethodUpdated = createAction(PAYMENT_METHOD_UPDATED);

export const SET_SUBSCRIPTION_ID = 'repeatOrdersActions.SET_SUBSCRIPTION_ID';
export const setSubscriptionId = createAction(SET_SUBSCRIPTION_ID);

export const SET_INITIAL_STATE = 'repeatOrdersActions.SET_INITIAL_STATE';
export const setInitialState = createAction(SET_INITIAL_STATE);

export const INITIALIZE = 'repeatOrdersActions.INITIALIZE';
export const initialize = createAction(INITIALIZE);

export const SET_INITIALIZE_STATUS = 'repeatOrdersActions.SET_INITIALIZE_STATE';
export const setInitializeState = createAction(SET_INITIALIZE_STATUS);

export const TOGGLE_ACCORDION = 'repeatOrdersActions.TOGGLE_ACCORDION';
export const toggleAccordion = createAction(TOGGLE_ACCORDION);

export const UPDATE_CURRENT_DISPLAY = 'repeatOrdersActions.UPDATE_CURRENT_DISPLAY';
export const updateCurrentDisplay = createAction(UPDATE_CURRENT_DISPLAY);

export const RESET_DISPLAY = 'repeatOrdersActions.RESET_DISPLAY';
export const resetDisplay = createAction(RESET_DISPLAY);

export const CREATE_NEW_SUBSCRIPTION = 'repeatOrdersActions.CREATE_NEW_SUBSCRIPTION';
export const createNewSubscription = createAction(CREATE_NEW_SUBSCRIPTION);

export const STOP_SUBSCRIPTION = 'repeatOrdersActions.STOP_SUBSCRIPTION';
export const stopSubscription = createAction(STOP_SUBSCRIPTION);

export const CLEAR_REPEAT_ORDER_DETAILS = 'repeatOrderActions.CLEAR_REPEAT_ORDER_DETAILS';
export const clearRepeatOrderDetails = createAction(CLEAR_REPEAT_ORDER_DETAILS);
