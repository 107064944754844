import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./CmsTilesPanel.scss');
}

const styles = {
  tileBox: {
    backgroundColor: '#ff6633',
    color: '#FFF'
  },
  tileTitle: {
    fontSize: '150%',
    height: '30px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  tileText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  },
  seeMore: {
    textAlign: 'right',
    color: '#FFF'
  }
};

const getTileStyle = (backgroundColor, color) => {
  return {
    backgroundColor: backgroundColor ? backgroundColor : styles.tileBox.backgroundColor,
    color: color ? color : styles.tileBox.color
  };
};

const getTitleAndTextStyles = (title) => {
  if (title && title.length > 24) {
    return {
      tileTitle: {
        fontSize: '150%',
        height: '60px',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical'
      },
      tileText: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical'
      }
    };
  }
  return styles;
};

export const IndividualTile = ({ tileDetails, css }) => {
  const title = tileDetails.get('title');
  const text = tileDetails.get('text');
  const imgSrc = tileDetails.get('imgSrc');
  const seeMoreUrl = tileDetails.get('seeMoreUrl');
  const tileBackgroundColor = css.getIn(['tileBox', 'backgroundColor']);
  const tileTextColor = css.getIn(['tileBox', 'color']);

  return (
    <a href={ seeMoreUrl } >
      <div className="col-xs-12 col-sm-3 tile">
        <div className="tile-box thumbnail"
          style={ getTileStyle(tileBackgroundColor, tileTextColor) }
        >
          <div className="text-wrapper">
            <div className="tile-title" style={ getTitleAndTextStyles(title).tileTitle }>
              <LinesEllipsis
                text={ title }
                maxLine={ getTitleAndTextStyles(title).tileTitle.WebkitLineClamp }
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            <div className="tile-text" style={ getTitleAndTextStyles(title).tileText }>
              <LinesEllipsis
                text={ text }
                maxLine= { getTitleAndTextStyles(title).tileText.WebkitLineClamp }
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          </div>
          <div className="img-wrapper">
            <ZoroImage src={ imgSrc } alt={ title } className="align-center img-responsive" />
          </div>
          <div className="caption tile-seeMore" style={ styles.seeMore }>
            { seeMoreUrl && <div style={ styles.seeMore }>See More &gt;</div>}
          </div>
        </div>
      </div>
    </a>
  );
};

export class CmsTilesPanel extends React.Component {
  render () {
    const tiles = this.props.data.getIn(['tiles']);
    const headingTitle = this.props.data.getIn(['heading', 'sectionTitle']);
    const headingDescription = this.props.data.getIn(['heading', 'sectionText']);
    const css = this.props.css;
    return (
      <div className="container-fluid" style={ styles.container }>
        { headingTitle && <div className="row heading-title"> { headingTitle } </div> }
        { headingDescription && <div className="row heading-description"> { headingDescription } </div> }
        <div className="row"> {
          tiles.map((item, index) => {
            return (<IndividualTile
              key={ index }
              tileDetails={ item }
              css={ css }
            />);
          })
        }
        </div>
      </div>
    );
  }
}

CmsTilesPanel.propTypes = {
  data: PropTypes.object,
  css: PropTypes.object
};

export default CmsTilesPanel;
