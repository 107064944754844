import { fromJS, List } from 'immutable';
import {
  SEARCH_TERM_CHANGED,
  SEARCH_TERM_CHANGE_SUCCEEDED,
  SEARCH_TERM_CLEARED,
  SET_PRODUCTS_ON_SEARCH_TERM_HOVER_SUCCESS,
  SEARCH_TERM_SELECTED,
  SEARCH_TERM_UPDATE_AQ_TERM_SUCCESS,
  GET_RECENT_SEARCHES_SUCCESS
} from 'client/actions/searchBarActions';

const defaultState = fromJS({
  term: '',
  suggestions: [],
  termOnNoResultsPage: '',
  aq: '',
  recentSearches: []
});

const searchBar = (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_TERM_SELECTED:
      if (action.params.flag) {
        action.event = 'submit';
      }
      return state.set('hasSearchTermBeenSelected', true)
        .set('selectedPriceFilters', {});
    case SEARCH_TERM_CHANGED:
      return state
        .set('hasSearchTermBeenSelected', false)
        .set(action.termStoreId, action.term);
    case SEARCH_TERM_CLEARED:
      return state.delete(action.termStoreId);
    case SEARCH_TERM_CHANGE_SUCCEEDED:
      return state.set('suggestions', List(action.suggestions));
    case SET_PRODUCTS_ON_SEARCH_TERM_HOVER_SUCCESS:
      // don't pupulate the state is search term is selected.
      if (state.get('hasSearchTermBeenSelected')) {
        return state;
      }
      const { productIndex, products } = action.suggestions;
      return state.setIn(['suggestions', productIndex], products);
    case SEARCH_TERM_UPDATE_AQ_TERM_SUCCESS:
      const aq = action && action.payload ? action.payload : '';
      return state.set('aq', aq);
    case GET_RECENT_SEARCHES_SUCCESS:
      return state.set('recentSearches', action?.payload || []);
    default:
      return state;
  }
};

export default searchBar;
