export const TRY_VERIFY_EMAIL = 'TRY_VERIFY_EMAIL';
export const SET_VERIFY_EMAIL = 'SET_VERIFY_EMAIL';

export function tryVerifyEmail (accountNo, verificationToken) {
  return {
    type: TRY_VERIFY_EMAIL,
    accountNo,
    verificationToken
  };
}

export function setVerifyEmailState (status) {
  return {
    type: SET_VERIFY_EMAIL,
    status
  };
}
