import { put, takeEvery, takeLatest, take, call } from 'redux-saga/effects';
import { loadCategoryByIdSuccess, LOAD_CATEGORY } from 'client/actions/categoryActions';
import { loadCategories, FETCH_CATEGORY_LIST } from 'client/actions/ui/categoriesDropdownActions';
import { getCategoryById, getAllCategories } from 'shared/endpoints/categoryEndpoint';

export function * getCategory ({ payload }) {
  const cat = yield call(getCategoryById, payload.getIn(['category', 'categoryId']));
  yield put(loadCategoryByIdSuccess(cat));
}

export function * categoryChannelHandler (channel) {
  while (true) {
    const action = yield take(channel);
    yield call(getCategory, action);
  }
}

export function * getAllCategoriesSaga () {
  const result = yield call(getAllCategories);
  yield put(loadCategories(result?.categories));
}

export function * watchLoadCategory () {
  yield takeEvery(LOAD_CATEGORY, getCategory);
}

export function * watchLoadAllCategory () {
  yield takeLatest(FETCH_CATEGORY_LIST, getAllCategoriesSaga);
}
