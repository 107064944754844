import { fromJS } from 'immutable';
import { TOOLTIP_HIDE, TOOLTIP_TOGGLE } from 'client/actions/ui/tooltipActions';

export const defaultState = fromJS({});

function safeUpdate (state, id) {
  return state.has(id) ? state.delete(id) : state.set(id, true);
}

const tooltipReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOOLTIP_TOGGLE:
      return safeUpdate(state, action.payload);
    case TOOLTIP_HIDE:
      return state.delete(action.payload);
    default:
      return state;
  }
};

export default tooltipReducer;
