import { put, takeLatest, call, all, select } from 'redux-saga/effects';
import { accountUsers, getAccountUser, inviteToAccount, updateAccountUser, updateVAT } from 'shared/endpoints/userEndpoint';
import { toastSuccess, toastWarn } from '../actions/showNotificationsActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedStringWithParam, getLocalizedString } from 'localization/localizer';
import {
  REFRESH_ACCOUNT_USERS,
  INVITE_TO_ACCOUNT,
  RESEND_ACCOUNT_INVITATION,
  UPDATE_ACCOUNT_USER_DETAILS,
  REFRESH_ACCOUNT_USER_DATA,
  refreshAccountUsersSuccess,
  refreshAccountUsers,
  resetFormFields,
  updateAccountUsers
} from '../actions/myAccountUsersActions';
import { changeGlobalVATStateAction,
  ON_TOGGLE_GLOBAL_VAT } from 'client/actions/userActions';
import { INVITE_USERS_TO_TRADE_ACCOUNT } from 'client/actions/creditAccountActions';
import {
  isLoggedInSelector
} from './accountSagaSelectors';
import { setCookieUserPreferences } from '../actions/ui/cookieActions';
import { PRICE_DISPLAY_TYPE_EXCL_VAT, PRICE_DISPLAY_TYPE_INC_VAT } from 'shared/constants/cookies';
import { VAT_PRICE } from 'shared/constants/showNotifications';
import { ADMIN } from 'shared/constants/account';

const usersList = (state) => state.getIn(['accountUsers', 'accountUserList']);
const userCustomerId = (state) => state.getIn(['user', 'accountDetails', 'tradeAccount', 'customerId']);
const accountUser = (state) => state.getIn(['accountUsers', 'accountUserForm'], {});
const validationMessages = {
  updatedUser: (userName) => getLocalizedStringWithParam('accountUserForm.updatedUser', { userName }),
  invitationSent: (email) => getLocalizedStringWithParam('accountUserForm.invitationSent', { email })
};

export function * changedHistoryFields (user) {
  const accountUsersList = yield select(usersList);
  const [{ limit, accountRole, status }] = accountUsersList.toJS().filter((el) => el.email === user.email);
  const historyChanges = {
    newStatus: user.status,
    newRole: user.accountRole,
    newLimit: user.limit
  };

  if (user.limit !== limit) {
    historyChanges.previousLimit = limit;
  }

  if (user.status !== status) {
    historyChanges.previousStatus = limit;
  }
  if (user.accountRole !== accountRole) {
    historyChanges.previousRole = accountRole;
  }

  return historyChanges;
}

export function * refreshAccountUserList () {
  const accountUserList = yield call(accountUsers);
  yield put(refreshAccountUsersSuccess(enhanceAccountUserListData(accountUserList)));
}

export function enhanceAccountUserListData (accountUserList, editModeFlag) {
  return accountUserList.map((user) => {
    user.tradeAccount.editMode = editModeFlag ? editModeFlag : false;

    if (!user.tradeAccount.userName) {
      user.tradeAccount.userName = `${user.firstName || ''} ${user.lastName || ''}`;
    }

    if (!user.tradeAccount.email) {
      user.tradeAccount.email = user.email;
    }

    if (user.tradeAccount.status === 'APPROVED') {
      user.tradeAccount.status = 'ACTIVE';
    }
    return user.tradeAccount;
  });
}

export function getUpdatedUsersList (fetchedUser, existingUsers, editModeFlag) {
  const [ enhancedFetchedUser ] = enhanceAccountUserListData([ fetchedUser ], editModeFlag);
  const updatedUsersList = existingUsers.toJS().map((user) => {
    return user.email === enhancedFetchedUser.email ? enhancedFetchedUser : user;
  });

  return updatedUsersList;
}

export function * creditApplicationInviteUsers ({ users }) {
  const usersCustomerId = yield select(userCustomerId);
  yield all(users.map((user) => {
    const history = {
      actionDate: new Date(),
      action: 'INVITED',
      createdBy: usersCustomerId,
      actionFor: user.colleaguesEmail
    };
    const newUser = {
      accountRole: user.accountRole,
      limit: !user.spendLimit ? '' : user.spendLimit,
      shortMessage: user.shortMessage && user.shortMessage.length ? user.shortMessage : undefined,
      limitType: user.spendLimit !== undefined ? 'MONTHLY' : 'TRADE_ACCOUNT',
      emailAddress: user.colleaguesEmail,
      history
    };
    return call(inviteToAccount, newUser);
  }));
}

export function * inviteUserToAccount ({ user }) {
  const accountUsersForm = yield select(accountUser);
  const usersCustomerId = yield select(userCustomerId);
  const form = accountUsersForm.toJS();
  const isAdmin = form.accountRole.value === ADMIN;
  const formIsValid = form.email.valid && form.shortMessage.valid && form.accountRole.valid && ((form.monthlyLimit.valid && !isAdmin) || isAdmin);

  if (formIsValid) {
    const history = {
      actionDate: new Date(),
      action: 'INVITED',
      createdBy: usersCustomerId,
      actionFor: user.email.value
    };

    const newTradeAccount = {
      accountRole: user.accountRole.value,
      limit: user.monthlyLimit.value === '' ? '' : user.monthlyLimit.value,
      shortMessage: user.shortMessage.value && user.shortMessage.value.length ? user.shortMessage.value : undefined,
      limitType: user.monthlyLimit.value !== '' ? 'MONTHLY' : 'TRADE_ACCOUNT',
      emailAddress: user.email.value,
      history
    };

    const response = yield call(inviteToAccount, newTradeAccount);
    if (response.error) {
      yield put(toastWarn(
        getToastMessage(
          response.message
        )
      ));
      return;
    }

    yield put(resetFormFields());
    yield put(refreshAccountUsers());
    yield put(toastSuccess(
      getToastMessage(
        validationMessages.invitationSent(user.email.value)
      )
    ));
  }
}

export function * resendAccountInvitation ({ user }) {
  const history = {
    actionDate: new Date(),
    action: 'RESEND INVITE',
    createdBy: userCustomerId,
    actionFor: user.email,
    ...changedHistoryFields(user)
  };
  const userReinvited = {
    accountRole: user.accountRole,
    limit: user.limit === '' ? 0 : user.limit,
    shortMessage: undefined,
    limitType: user.limit === '' ? 'MONTHLY' : 'TRADE_ACCOUNT',
    emailAddress: user.email,
    history
  };

  yield call(inviteToAccount, userReinvited);
  yield put(refreshAccountUsers());
  yield put(toastSuccess(
    getToastMessage(
      validationMessages.invitationSent(user.email)
    )
  ));
}

export function * updateAccountUserDetails ({ user }) {
  const existingUsers = yield select(usersList);
  const userToUpdate = {
    emailAddress: user.email,
    accountRole: user.accountRole
  };

  if (user.status && (user.status !== 'PENDING' || user.status !== 'INVITED')) {
    userToUpdate.status = user.status;
  }

  userToUpdate.limit = user.limit === '' ? '' : user.limit;
  userToUpdate.history = {
    actionDate: new Date(),
    action: 'UPDATE',
    createdBy: userCustomerId,
    actionFor: user.email,
    ...changedHistoryFields(user)
  };
  const res = yield call(updateAccountUser, userToUpdate);
  yield put(updateAccountUsers(user, 'editMode', !user.editMode));

  if (res.error) {
    yield put(toastWarn(
      getToastMessage(
        res.message
      )
    ));
    return;
  }
  const fetchedUser = yield call(getAccountUser, user.email);
  const updatedUsersList = getUpdatedUsersList(fetchedUser, existingUsers);
  yield put(refreshAccountUsersSuccess(updatedUsersList));
  yield put(toastSuccess(
    getToastMessage(
      validationMessages.invitationSent(user.userName)
    )
  ));
}

export function * refreshUserData ({ email }) {
  const existingUsers = yield select(usersList);
  const fetchedUser = yield call(getAccountUser, email);
  if (fetchedUser.error) {
    yield put(toastWarn(
      getToastMessage(
        fetchedUser.message
      )
    ));
    return;
  }
  const updatedUsersList = getUpdatedUsersList(fetchedUser, existingUsers, true);
  yield put(refreshAccountUsersSuccess(updatedUsersList));
}

export function * watchRefreshAccountUserList () {
  yield takeLatest(REFRESH_ACCOUNT_USERS, refreshAccountUserList);
}

export function * watchInviteToAccount () {
  yield takeLatest(INVITE_TO_ACCOUNT, inviteUserToAccount);
}

export function * watchResendAccountInvitation () {
  yield takeLatest(RESEND_ACCOUNT_INVITATION, resendAccountInvitation);
}

export function * watchUpdateAccountUserDetails () {
  yield takeLatest(UPDATE_ACCOUNT_USER_DETAILS, updateAccountUserDetails);
}

export function * watchRefreshUserData () {
  yield takeLatest(REFRESH_ACCOUNT_USER_DATA, refreshUserData);
}

export function * watchTradeAccountUsersInvitations () {
  yield takeLatest(INVITE_USERS_TO_TRADE_ACCOUNT, creditApplicationInviteUsers);
}

export function * displayVATToast (isPriceWithVat) {
  const toastId = `${VAT_PRICE}-${isPriceWithVat}`;
  const toastTitle = getLocalizedString(isPriceWithVat ? 'myAccount.includeVat.title' : 'myAccount.excludeVat.title');
  const toastMessage = getLocalizedString(isPriceWithVat ? 'myAccount.includeVat.successMsg' : 'myAccount.excludeVat.successMsg');

  yield put(toastSuccess(getToastMessage(toastTitle, toastMessage), 'top-right', null, toastId));
}

export function * updateUserVAT (payload) {
  try {
    const isLoggedInState = yield select(isLoggedInSelector);
    if (isLoggedInState) {
      yield call(updateVAT, { isPriceWithVat: !payload.isPriceWithVat });
    }
    yield put(changeGlobalVATStateAction(!payload.isPriceWithVat));
    const priceDisplayType = !payload.isPriceWithVat ? PRICE_DISPLAY_TYPE_INC_VAT : PRICE_DISPLAY_TYPE_EXCL_VAT;
    const zoroUserPreferences = {
      priceDisplayType
    };
    yield put(setCookieUserPreferences(zoroUserPreferences));
    yield call(displayVATToast, !payload.isPriceWithVat);
  } catch (e) {
    throw Error('error while updating user VAT', e);
  }
}

export function * watchupdateUserVAT () {
  yield takeLatest(ON_TOGGLE_GLOBAL_VAT, updateUserVAT);
}
