import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import QuickOrderForm from './QuickOrderForm';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';
import { toggleDropdownMenu } from 'client/actions/ui/dropdownMenuActions';
import { addToCart } from 'client/actions/cartActions';
import flowRight from 'lodash/flowRight';
import SvgLoader from '../../../../svg/SvgLoader';

if (process.browser) {
  require('./QuickOrderDialog.scss');
}

export class QuickOrderDialog extends Component {
  constructor (props) {
    super(props);
    this.anchorEl = null;
    this.setAnchorEl = (node) => {
      this.anchorEl = node;
    };
  }

  componentWillMount () { // eslint-disable-line react/no-deprecated
    process.browser && document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (event) => {
    if (!this.props.showQuickOrder || this.anchorEl.contains(event.target)) {
      return;
    }
    this.props.closeQuickOrder();
  };

  render () {
    const { showQuickOrder, toggleQuickOrder } = this.props;
    const handler = () => toggleQuickOrder();

    return (
      <div className="QuickOrderDialogue-dropdown" ref={ this.setAnchorEl.bind(this) } style={{ position: 'relative' }}>
        <SvgLoader xlinkHref="#quick-order-header-icon" className="infoRibbonLinks_quick_order_icon"/>
        <button
          type="button"
          className="btn nav-link nav-link-quickorder"
          data-e2e="quickOrderOpenButton"
          onClick = { handler }
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          Quick Order ▾
        </button>

        <Overlay
          show={ showQuickOrder }
          placement="right"
          className="QuickOrderDialogue-dropdown"
          container={ this }>
          <div
            className="QuickOrderDialogue QuickOrderDialogue-arrow"
          >
            <QuickOrderForm { ...this.props } />
          </div>
        </Overlay>
      </div>
    );
  }
}

QuickOrderDialog.propTypes = {
  btnClassName: PropTypes.string,
  quickOrderVisible: PropTypes.bool,
  isMobile: PropTypes.bool,
  children: PropTypes.element
};

const mapDispatchToProps = (dispatch) => ({
  toggleQuickOrder: () => dispatch(toggleDropdownMenu(VISIBLE_DROPDOWN.QUICK_ORDER)),
  closeQuickOrder: () => dispatch(toggleDropdownMenu(VISIBLE_DROPDOWN.NONE)),
  addToCart: flowRight(dispatch, addToCart)
});

const mapStateToProps = (state, ownProps) => ({
  showQuickOrder: state.getIn(['showDropdownMenu', 'visibleDropdown'], VISIBLE_DROPDOWN.NONE) === VISIBLE_DROPDOWN.QUICK_ORDER
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrderDialog);
