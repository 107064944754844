import { getLocalizedString } from 'localization/localizer';
import DatePicker from 'react-datepicker';
import React from 'react';
import { connect } from 'react-redux';
import { isWorkingDay } from 'zoro-stock-date-lib';
import { fromJS } from 'immutable';

export const FieldDatePickerPure = ({
  input,
  initialDate,
  updatedDate,
  minDate,
  maxDate,
  isClearable,
  placeholder,
  labelResourceName,
  className,
  labelClassName,
  disabled,
  id,
  name,
  meta,
  fieldClassName,
  datePickerPlacement,
  filterDate,
  icon
}) => {
  const { touched, error } = meta;

  return (
    <div className={ fieldClassName ? fieldClassName : '' }>
      { labelResourceName ? <label htmlFor={ id } className={ labelClassName }>
        { getLocalizedString(labelResourceName) }
      </label> : null }
      <DatePicker
        showIcon={ !!icon }
        selected={ updatedDate || initialDate || null }
        filterDate={ filterDate }
        className={ `${className} ${touched && error ? 'has-error-border' : ''}` }
        placeholderText={ placeholder }
        disabled={ disabled }
        minDate={ minDate }
        name={ name }
        maxDate={ maxDate }
        data-e2e={ id }
        popperPlacement={ datePickerPlacement ? datePickerPlacement : 'auto' }
        dateFormat="dd/MM/yyyy"
        onChange={ (value) => input.onChange(value) }
        isClearable={ isClearable }
        disabledKeyboardNavigation
        autoComplete="off"
        icon={ icon }
      />
      {
        touched && error
          ? <div className="help-block">
            <div data-e2e={ input.name + 'Error' }>{ error }</div>
          </div> : null
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const publicHolidays = state.get('publicHolidays', fromJS([])).toJS();
  return {
    filterDate: (date) => isWorkingDay(date, publicHolidays)
  };
};

export const FieldDatePicker = connect(mapStateToProps)(FieldDatePickerPure);
