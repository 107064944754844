import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SvgLoader from 'client/components/svg/SvgLoader';
import ProgressCircle from 'client/components/elements/progressCircle/ProgressCircle';
import { PrimaryButton } from '../../controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';

import {
  onApiButtonLoad,
  onApiButtonUnLoad,
  onApiButtonClick
} from 'client/actions/ui/apiCallButtonActions';

if (process.browser) {
  require('./ApiCallButton.scss');
}

/* ApiCallButton Usage
  Requirments: selector, onClick, triggerAction, onSuccessAction, onErrorAction
   - selector will be the unique identifier(you can have 2 synced button on top and buttom of same page by providing the same selector)
   - Button click must call an triggerAction when the function onClick is called
  onClick should trigger the triggerAction which is watched by a saga which will get the all the data it needs from the store
   - in case for redux-form submit please use the selector 'getFormValues('formName')(state)' and dispatch select() saga-effect. i.e. onClick is called without any params.
   - onSuccess(that ususally redirects page), onError actions should be passed that will be dispatched by your saga(button state will be managed by hooking these actions)

  Optional params:
    resetOnSuccess: resets the button on success; defaults to false
    text: shows on the button; defaults to 'SUBMIT'
    textWhenProcessing: shows inside progress bar; defaults to 'processing...'
    textWhenComplete: shows below done-icon on success; defaults to 'redirecting...'
    textWhenError: shows bellow button in red on error, defaults to 'Submit failed. Please try again.'
    disabled: disables the button
    buttonClass: this is an override class that can be used to change the button color or other props
    datae2e: data-e2e value passed to the button
*/

export class ApiCallButton extends Component {
  static propTypes = {
    selector: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    triggerAction: PropTypes.string.isRequired,
    onSuccessAction: PropTypes.string.isRequired,
    onErrorAction: PropTypes.string.isRequired,
    resetOnSuccess: PropTypes.bool,
    text: PropTypes.string,
    textWhenProcessing: PropTypes.string,
    textWhenComplete: PropTypes.string,
    textWhenError: PropTypes.string,
    disabled: PropTypes.bool,
    buttonClass: PropTypes.string,
    datae2e: PropTypes.string
  }

  componentDidMount () {
    const {
      onComponentLoad,
      selector,
      triggerAction,
      onSuccessAction,
      onErrorAction,
      resetOnSuccess = false
    } = this.props;
    onComponentLoad(
      selector,
      triggerAction,
      onSuccessAction,
      onErrorAction,
      resetOnSuccess
    );
  }

  componentWillUnMount () { // eslint-disable-line react/no-deprecated
    const { onComponentUnload, selector } = this.props;
    onComponentUnload(selector);
  }

  onClickHandler = (onClick, selector) => {
    this.props.onApiButtonClick(selector);
    onClick();
    // _debounce(this.props.onClick, 200)();
  }

  getButton = (onClick, selector, text, disabled, buttonClass, datae2e) => {
    return <PrimaryButton
      datae2e={ datae2e }
      onClick={ () => this.onClickHandler(onClick, selector) }
      disabled={ disabled }
      className={ `${buttonClass}${disabled ? ' disabled' : ''}` }
      text={ text }
    />;
  }

  getComponentBasedOnStatus = (
    onClick,
    selector,
    status,
    text,
    textWhenComplete,
    textWhenError,
    textWhenProcessing,
    disabled,
    buttonClass,
    datae2e
  ) => {
    switch (status) {
      case 'submitting':
        return <React.Fragment>
          <ProgressCircle/>
          <span className="ApiCallButton_progressBar_text"> { textWhenProcessing } </span>
        </React.Fragment>;
      case 'submitted':
        return <React.Fragment>
          <SvgLoader
            xlinkHref="done-icon"
            title="Done Icon"
            className="ApiCallButton_doneIcon"
            width={ 50 }
            height={ 50 }
          />
          <span className="ApiCallButton_doneText"> { textWhenComplete } </span>
        </React.Fragment>;
      case 'error':
        return <React.Fragment>
          { this.getButton(onClick, selector, text, disabled, buttonClass, datae2e) }
          <div className="ApiCallButton_error">{ textWhenError }</div>
        </React.Fragment>;
      // default : 'readyToSubmit'
      default:
        return this.getButton(onClick, selector, text, disabled, buttonClass, datae2e);
    }
  }

  render () {
    const {
      onClick,
      selector,
      status,
      text = getLocalizedString('apiCallButton.buttonText'),
      textWhenProcessing = getLocalizedString('apiCallButton.processing'),
      textWhenComplete = getLocalizedString('apiCallButton.submitted'),
      textWhenError = getLocalizedString('apiCallButton.error'),
      disabled,
      buttonClass,
      datae2e
    } = this.props;
    return <div className="ApiCallButton">
      { this.getComponentBasedOnStatus(
        onClick,
        selector,
        status,
        text,
        textWhenComplete,
        textWhenError,
        textWhenProcessing,
        disabled,
        buttonClass,
        datae2e
      ) }
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    status: state.getIn([ 'ui', 'apiCallButton', ownProps.selector ], '')
  };
};

const mapDispatchToProps = (dispatch) => ({
  onComponentLoad: (...args) => dispatch(onApiButtonLoad(...args)),
  onApiButtonClick: (...args) => dispatch(onApiButtonClick(...args)),
  onComponentUnload: (...args) => dispatch(onApiButtonUnLoad(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiCallButton);
