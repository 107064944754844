import React from 'react';

const COMPANY_TYPE_LLP = 'LLP';
const COMPANY_TYPE_PLC_LTD_CO = 'Plc/Ltd Co';
const COMPANY_TYPE_INDUSTRIAL_PROVIDENT = 'Industrial Provident';

export const COMPANY_TYPES_REQUIRING_REG_NO = [
  'Charity',
  COMPANY_TYPE_LLP,
  COMPANY_TYPE_PLC_LTD_CO,
  COMPANY_TYPE_INDUSTRIAL_PROVIDENT
];

// NEW FIELDS SHOULD BE ADDED ALPHABETICALLY
export const populateCompanyTypes = [
  'Charity',
  'Charity by Guarantee',
  'Govt Dept',
  COMPANY_TYPE_INDUSTRIAL_PROVIDENT,
  'Joint Venture',
  COMPANY_TYPE_LLP,
  'Local Auth',
  'Partnership',
  COMPANY_TYPE_PLC_LTD_CO,
  'Sole Trader',
  'Trust'
].map((item, idx) =>
  <option key={ idx } value={ item }>{ item }</option>
);

export const populatePrimaryIndustry = [
  'Agriculture, Forestry & Fishing',
  'Mining & Quarrying',
  'Manufacturing',
  'Electricity, Gas, Steam & Air Conditioning Supply',
  'Water Supply, Sewerage & Waste Activities',
  'Construction',
  'Wholesale & Retail Trade, Repair Of Motor Vehicles',
  'Transportation & Storage',
  'Accommodation & Food Service Activities',
  'Information & Communication',
  'Financial & Insurance Activities',
  'Real Estate Activities',
  'Professional, Scientific & Technical Activities',
  'Administrative & Support Service Activities',
  'Public Administration & Defence',
  'Education',
  'Human Health & Social Work Activities',
  'Arts, Entertainment & Recreation',
  'Other Service Activities',
  'Activities of Households as Employers',
  'Activities of Extraterritorial Organisations'
].map((item, idx) =>
  <option key={ idx } value={ item }>{ item }</option>
);

// For Secondary Industry on select of Primary Industry
const industryMap = new Map();
industryMap.set('Aerospace, Defence & Marine', [
  '',
  'Aircraft',
  'Engines',
  'Other',
  'Repairers',
  'Ship Builders',
  'Subcontractors',
  'Submarines',
  'Systems',
  'Weapons'
]);
industryMap.set('Automotive', [
  '',
  'Armoured Vehicles',
  'Caravans',
  'Cars',
  'Coaches & Buses',
  'Components',
  'Construction',
  'Fork Lift Trucks',
  'Lorries',
  'Motorcycles',
  'Other',
  'Subcontractors'
]);
industryMap.set('Rail & Transport', [
  '',
  'Airports',
  'Bicycles',
  'Coach Operators',
  'Garages',
  'Logistics Providers',
  'Manufacturers Rolling Stock',
  'Other',
  'Ports',
  'Signalling & Communication',
  'Stations',
  'Track',
  'Train Operators'
]);
industryMap.set('General Manufacturing, Materials & Machines', [
  '',
  'Agricultural',
  'Appliances',
  'Furniture',
  'General Engineering',
  'Machine Shops',
  'Other',
  'Precision Engineering',
  'Sheet Metal Work',
  'Structural Fabricators',
  'Textiles'
]);
industryMap.set('Oil & Gas', [
  '',
  'Fracking',
  'Offshore',
  'Other',
  'Pipelines',
  'Refineries',
  'Subcontractors',
  'Transportation'
]);
industryMap.set('Power, Utilities, Recycling & Renewable', [
  '',
  'Bio Fuels',
  'Communication',
  'Electricity',
  'Environmental',
  'Gas',
  'Heat Source',
  'Other',
  'Solar',
  'Waste Management',
  'Water',
  'Wave',
  'Wind'
]);
industryMap.set('Construction, Aggregates & Mining', [
  '',
  'Ground Works',
  'Industrial & Commercial Buildings',
  'Mining',
  'Modular Buildings',
  'Other',
  'Quarrying',
  'Trades'
]);
industryMap.set('Chemicals, Pharma & Sciences', [
  '',
  'Chemicals',
  'Laboratories',
  'Other',
  'Paints',
  'Pharmaceutical',
  'R&D'
]);
industryMap.set('Food & Beverage', [
  '',
  'Agriculture',
  'Beverages',
  'Bottle Manufacturing',
  'Breweries',
  'Can Manufacturing',
  'Food Processors Manufactures',
  'Food Retailers',
  'Other'
]);
industryMap.set('Printing & Packaging', [
  '',
  'Other',
  'Packaging',
  'Paper Manufacturers',
  'Paper Products',
  'Printing',
  'Publishing'
]);
industryMap.set('Government, Education Health & Leisure', [
  '',
  'Armed Forces',
  'Emergency Services',
  'General Healthcare',
  'Hospitals',
  'Leisure Activities',
  'Local Authorities',
  'Nurseries',
  'Other',
  'Prisons',
  'Schools & Universities',
  'Vetinary'
]);
industryMap.set('Facilities Management', [
  '',
  'Cleaning Contractors',
  'Forestry & Estates',
  'Maintenance Services',
  'Other'
]);
industryMap.set('Trade & Resellers', [
  '',
  'Integrators',
  'Local Distributors',
  'National Distributors',
  'Plant Hire',
  'Regional Distributors',
  'Resellers'
]);
export const populateSecondaryIndustry = (primaryIndustry) => {
  const secondaryIndustryArray = industryMap.get(primaryIndustry);
  if (secondaryIndustryArray && secondaryIndustryArray.length) {
    return secondaryIndustryArray.map((item, idx) =>
      <option key={ idx } value={ item }>{ item }</option>
    );
  }
  return <option/>;
};

export const populatePrimaryFunction = [
  'Assembly',
  'Bonding',
  'Cleaning',
  'Coating & Painting',
  'Demolition',
  'Disposal',
  'Distribution',
  'Erection',
  'Fabrication',
  'Generation',
  'Installation & Maintenance',
  'Machining',
  'Manufacturing',
  'Moulding',
  'Processing',
  'R&D',
  'Services',
  'Storage'
].map((item, idx) =>
  <option key={ idx } value={ item }>{ item }</option>
);
