export const PAY_ON_ACCOUNT = 'payOnAccount';
export const PAYPAL = 'payPal';
export const APPLE_PAY = 'applePay';
export const GOOGLE_PAY = 'googlePay';
export const ACCOUNT = 'Account';
export const CARD = 'card';
export const SAVED_CARD = 'saveCard';
export const PAYPAL_FLOW = 'vault';
export const COUNTRY_CODE_UK = 'GB';
export const GATEWAY_REJECTED = 'gateway_rejected';
export const PROCESSOR_DECLINED = 'processor_declined';
export const TRANSACTION_AUTHORIZED = 'authorized';
export const DEFAULT_FAILED_STATUS = 'failed';
export const CARD_NUMBER_FIELD_NAME = 'number';
export const CARD_CVV = 'cvv';
export const CARD_EXPIRATION_DATE = 'expirationDate';
export const BRAINTREE_BADGE_URL = 'https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png';
export const MAESTRO_CARD_TYPE = 'Maestro';
export const MASTER_CARD_TYPE = 'MasterCard';
export const AMERICAN_EXPRESS_CARD_TYPE = 'American Express';
export const DISCOVER_CARD_TYPE = 'Discover';
export const PAYPAL_CARD_TYPE = 'PayPal';
export const APPLE_PAY_CARD_TYPE = 'ApplePay';
export const GOOGLE_PAY_CARD_TYPE = 'GooglePay';
export const VISA_CARD_TYPE = 'Visa';
export const CARD_ENROLLED_IN_3DS_CODE = 'Y';
export const INSUFFICIENT_FUNDS_CODE = '2001';
export const CARD_VERIFY_CVV = 'verifyCvv';
export const cards = [VISA_CARD_TYPE, MASTER_CARD_TYPE, MAESTRO_CARD_TYPE, AMERICAN_EXPRESS_CARD_TYPE, DISCOVER_CARD_TYPE, PAYPAL_CARD_TYPE, APPLE_PAY_CARD_TYPE, GOOGLE_PAY_CARD_TYPE];
