import React from 'react';
import { Route } from 'react-router';
import RootInjectionWrapper from './components/screens/root/RootInjectionWrapper';
import NotFoundScreen from './components/screens/notFoundScreen/NotFoundScreen';
import IEBrowserScreen from './components/screens/unsupportedIEBrowserScreen/IEBrowserScreen';
import AddToBasketInterstitialInjectionWrapper from './components/screens/addToBasketInterstitialScreen/AddToBasketInterstitialInjectionWrapper';
import CreateAddressDetailsScreenInjectionWrapper from './components/screens/deliveryAndBillingDetailsScreen/CreateAddressDetailsScreenInjectionWrapper';
import { redirectIfNotLoggedIn } from './utils/routeUtils';
import MyAccountScreenInjectionWrapper from './components/screens/myAccountScreen/MyAccountScreenInjectionWrapper';
import QuotationDetailsScreenInjectionWrapper from './components/screens/quotationDetailsScreen/QuotationDetailsScreenInjectionWrapper';
import GlobalQuotationScreen from './components/screens/globalQuotation/globalQuotationScreen';
import AddToQuotationInterstitial from './components/screens/addToQuotationInterstitial/addToQuotationInterstitial';
import CheckoutScreenInjectionWrapper from 'client/components/screens/checkoutScreen/CheckoutScreenInjectionWrapper';
import SubscriptionsWrapper from './components/screens/subscriptionsWrapper';

export const ALL_CATEGORIES_PATH_NAME = 'shop/all-categories';
const QUOTATIONS_ID_TYPE = 'quotationsId';
const MY_ACCOUNT_TYPE = 'myAccount';
const BILLING_TYPE = 'billing';
const DELIVERY_TYPE = 'delivery';
const DELIVERY_DETAILS = 'DELIVERY_DETAILS';
export const ALL_BRANDS_PATH_NAME = 'shop/all-brands';

export const getRoutes = (store) => {
  return (
    <Route>
      <Route path="/" component={ RootInjectionWrapper }>
        <Route path="subscriptions" component={ SubscriptionsWrapper } />
        <Route path="added-to-basket/:productVariantId" component={ AddToBasketInterstitialInjectionWrapper } screenName="CartInterstitialScreen"/>
        <Route path="added-to-basket/:productVariantId/:primaryProductVariantId" component={ AddToBasketInterstitialInjectionWrapper } screenName="CartInterstitialScreen"/>
        <Route path="checkout">
          <Route path="order" onEnter={ redirectIfNotLoggedIn(store, DELIVERY_DETAILS) } component={ CheckoutScreenInjectionWrapper }/>
          <Route path="order/:fromQuotationId" onEnter={ redirectIfNotLoggedIn(store, DELIVERY_DETAILS) } component={ CheckoutScreenInjectionWrapper }/>
        </Route>
        <Route path="delivery-address" onEnter={ redirectIfNotLoggedIn(store, DELIVERY_TYPE) } component={ CreateAddressDetailsScreenInjectionWrapper } type="delivery" />
        <Route path="billing-address" onEnter={ redirectIfNotLoggedIn(store, BILLING_TYPE) } component={ CreateAddressDetailsScreenInjectionWrapper } type="billing" />
        <Route path="my-account" onEnter={ redirectIfNotLoggedIn(store, MY_ACCOUNT_TYPE) } component={ MyAccountScreenInjectionWrapper }>
          <Route path="quotations/:quotationId" onEnter={ redirectIfNotLoggedIn(store, QUOTATIONS_ID_TYPE) } component={ QuotationDetailsScreenInjectionWrapper } />
        </Route>
        <Route path="global-quotation(/:quoteRef)" component={ GlobalQuotationScreen } />
        <Route path="add-to-quotation/:sku" component={ AddToQuotationInterstitial } />
        <Route path="unsupported-browser" component={ IEBrowserScreen }/>
        <Route path="/page-not-found" status={ 404 } component={ NotFoundScreen }/>
        <Route path="*" status={ 404 } component={ NotFoundScreen }/>
      </Route>
    </Route>
  );
};
