import React, { Fragment, useEffect } from 'react';
import { getLocalizedString } from 'localization/localizer';
import ProgressCircle from '../../progressCircle/ProgressCircle';
import PayByApplePayButton from '../buttons/PayByApplePayButton';
import { connect } from 'react-redux';
import { formIsValid } from '../PayByNewCard/utils';
import { DELIVERY_ADDRESS_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';
import { setActivePanelId } from 'client/actions/checkoutActions';

const ApplePayButtonWrapper = ({ selector, onClick, applePayData, isDeliveryAddressAvailable, setActivePanelId }) => {
  useEffect(() => {
    if (isDeliveryAddressAvailable) {
      setActivePanelId({ panelId: DELIVERY_ADDRESS_ACCORDION_PANEL_ID });
    }
  }, [isDeliveryAddressAvailable]);
  return (<Fragment>
    { !applePayData
      ? ((!isDeliveryAddressAvailable) ? <PayByApplePayButton
        paymentMethodSelector= { selector }
        onClick= { onClick }
      /> : <div className="ApiCallButton_error">{getLocalizedString('singlePageCheckout.error.delivery.not.saved')}</div>)
      : <Fragment>
        <ProgressCircle/>
        <p className="text-center">{ getLocalizedString('payment.label.pleaseWait') }</p>
        <div className="CheckoutPage_pageDisabled"/>
      </Fragment>
    }
  </Fragment>);
};

const mapStateToProps = (state) => {
  const deliveryAddressFormErrors = state.getIn(['form', 'deliveryAddressForm', 'syncErrors']);
  const deliveryAddressFormOpen = state.getIn(['checkout', 'deliveryPanel', 'showDeliveryAddressForm']);
  return {
    isDeliveryAddressAvailable: !formIsValid(deliveryAddressFormErrors) || deliveryAddressFormOpen,
    applePayData: state.getIn(['braintree', 'applePay', 'data'])
  };
};

const mapDispatchToProps = {
  setActivePanelId
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayButtonWrapper);
