import { put, select, takeLatest, call } from 'redux-saga/effects';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import processOrders, { orderReturnMap } from 'client/sagas/processOrders/index';
import { pushTransactionDetails } from 'client/utils/googleTagManagerUtils.js';
import { getProductFamilyProducts } from 'shared/endpoints/productFamilyEndpoint';
import flatten from 'lodash/flatten';
import { addProductLineItems } from 'client/actions/productLineActions';
import { toProductLineItems } from 'client/utils/productLineUtils';
import { onRequestAddToCart } from 'client/sagas/cartSagas';
import { addProductBundleLineItems } from 'client/actions/productBundleLineActions';
import { SAVED_CARD } from 'shared/constants/braintree';
import {
  loadOrdersRaw,
  loadOrder,
  cancelRequest
} from 'shared/endpoints/ordersEndpoint';
import {
  REQUEST_MY_ORDERS,
  REQUEST_ACCOUNT_ORDERS,
  REQUEST_ORDERS_RAW,
  GET_ORDER_CONFIRMATION_SCREEN_ORDER,
  loadMyOrdersSuccess,
  loadOrdersRawSuccess,
  REQUEST_FETCH_ORDER,
  loadOrderSuccess,
  loadAccountOrderSuccess,
  loadOrderError,
  ADD_ORDER_TO_CART,
  SUBMIT_CANCELLATION_REQUEST, POST_ORDER, GET_RETURN_REPLACE_ORDER
} from 'client/actions/ordersActions';
import { List, fromJS } from 'immutable';
import { getSavedCardByToken } from 'client/utils/braintreeUtils';
import {
  toggleSubmitCancelItemDialog,
  cancellationRequestFailed
} from 'client/actions/ui/toggleElementActions';
import { toastSuccess, toastWarn } from 'client/actions/showNotificationsActions';
import { setActivePanelId } from '../actions/checkoutActions';
import {
  accountSelectors,
  orderSelectors,
  paymentMethodSelectors,
  validationSelectors
} from '../components/elements/paymentMethod/shared/selectors';
import { DELIVERY_ADDRESS_ACCORDION_PANEL_ID, ORDER_REVIEW_ACCORDION_PANEL_ID, SINGLE_PAGE_PAYMENT } from '../../shared/constants/singlePageCheckout';
import { setApiButtonStatus } from 'client/actions/ui/apiCallButtonActions';
import { READY_TO_SUBMIT, SUBMITTING } from 'shared/constants/apiButtonConstants';
import {
  PAY_BY_ACCOUNT,
  PAY_BY_EXISTING_CARD,
  PAY_BY_NEW_CARD, PAY_BY_PAYPAL, PAY_BY_APPLE_PAY, PAY_BY_GOOGLE_PAY
} from '../components/elements/paymentMethod/PaymentMethod/constants';
import { formIsValid } from '../components/elements/paymentMethod/PayByNewCard/utils';
import { orderFailed } from '../components/elements/paymentMethod/shared/orderHelpers';
import { payByAccount } from '../components/elements/paymentMethod/PayByAccount/payByAccountActions';
import { payByExistingCard } from '../components/elements/paymentMethod/PayByExistingCard/payByExistingCardActions';
import { payByNewCard } from '../components/elements/paymentMethod/PayByNewCard/payByNewCardActions';
import { payByPaypal } from '../components/elements/paymentMethod/PayByPaypal/payByPaypalActions';
import { payByApplePay } from '../components/elements/paymentMethod/PayByApplePay/payByApplePayActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { showBusinessAccountOnHoldDialog, showNonDeliverableOrderMessage } from 'client/actions/ui/dialogActions';
import { createReturnOrReplacement } from '../actions/returnsOrReplacementActions';
import { MY_ORDERS } from '../../shared/constants/orderSummaryConstants';
import { goToUrl } from 'client/actions/routeActions';
import { payBySkippingMethod } from 'client/components/elements/paymentMethod/PayBySkippingMethod/actions';
import { scheduledOrderFormValuesSelector } from 'client/selectors/scheduledOrdersSelectors';
import { reloadScheduledOrders, saveScheduledAllOrderlinesSettings, setInvalidSubscriptionPaymentMethod } from 'client/actions/scheduledOrders';
import { payByGooglePay } from 'client/components/elements/paymentMethod/PayByGooglePay/payByGooglePayActions';
import { PENDING, UNVERIFIED } from '../../shared/constants/account';

const csAgentNameSelector = (state) => state.getIn(['customerService', 'csAgentName']);
const csAgentDisplayNameSelector = (state) => state.getIn(['customerService', 'csAgentDisplayName']);
const orderSelector = (orderId) => (state) => state.getIn(['orders', 'order', orderId]);
const getSavedPaymentMethods = (state) => state.getIn(['braintreePayment', 'savedPaymentMethods'], List()).toJS();
const isMobileSelector = (state) => state.getIn(['deviceInfo', 'isMobile']);
export const showDeliveryAddressFormSelector = (state) => state.getIn(['checkout', 'deliveryPanel', 'showDeliveryAddressForm']);

export function * onRequestOrdersRaw () {
  const orders = yield call(loadOrdersRaw);
  yield put(loadOrdersRawSuccess(orders));
}

const getOrderLine = (orders) => {
  return orders.map((order) => {
    return Array.isArray(order.orderLinesForMerlin) &&
      order.orderLinesForMerlin.length
      ? order.orderLinesForMerlin
      : order.orderLines;
  }
  );
};

export function * onRequestMyOrders (payload) {
  const loadOrders = yield call(loadOrdersRaw, payload);
  const { orders = [], totalNumberOfOrders, invoicesOnly } = loadOrders;
  const skusArray = flatten(getOrderLine(orders)).map((orderLine) => orderLine.sku);
  const products = yield call(getProductFamilyProducts, skusArray);
  const publicHolidaysSelector = (state) => state.get('publicHolidays');
  const publicHolidays = yield select(publicHolidaysSelector);
  const processedOrders = !publicHolidays ? [] : processOrders(orders, products, publicHolidays.toJS());

  if (payload.type === 'REQUEST_ACCOUNT_ORDERS') {
    yield put(loadAccountOrderSuccess({ totalNumberOfOrders, orders: processedOrders, invoicesOnly }));
  }

  if (payload.type === 'REQUEST_MY_ORDERS') {
    yield put(loadMyOrdersSuccess({ totalNumberOfOrders, orders: processedOrders, invoicesOnly }));
  }
}

export function * checkIfUpdateBillingAddress (paymentInformation) {
  const savedPaymentMethods = yield select(getSavedPaymentMethods);
  const selectedSavedCard = (savedPaymentMethods && paymentInformation)
    ? getSavedCardByToken(savedPaymentMethods, paymentInformation.identifier)
    : null;
  const shouldUpdateBillingAddress = !!(paymentInformation && paymentInformation.paymentType === SAVED_CARD &&
    selectedSavedCard && !selectedSavedCard.isDefault);
  return shouldUpdateBillingAddress;
}

export function * onRequestFetchOrder (action, confirmationScreen = false) {
  const { orderId } = action;
  try {
    const order = yield call(loadOrder, orderId);
    const { breadcrumbs } = order;
    order.orderLines.map((orderLine) => {
      const { categoryId } = orderLine.category;
      const breadcrumb = breadcrumbs.find((element) => element[0].startsWith(categoryId));
      orderLine.breadcrumb = breadcrumb && breadcrumb[0].split('|')[1];
    });
    yield put(loadOrderSuccess(order));
    yield put(addProductLineItems(toProductLineItems(order, order.fromQuotationId ? (confirmationScreen ? 'cart' : 'quotations') : 'cart')));
    yield put(addProductBundleLineItems(toProductLineItems(order, 'bundle')));
  } catch (e) {
    yield loadOrderError(e);
  }
}

export function * onGetReturnReplaceOrder (action, confirmationScreen = false) {
  const { orderNumber, accountRequest } = action;
  try {
    const payload = {
      type: REQUEST_MY_ORDERS,
      limit: 1,
      pageNumber: 1,
      sortField: '-orderDate',
      invoicesOnly: 'false',
      orderStatusType: MY_ORDERS,
      searchInput: orderNumber,
      ...(accountRequest ? {
        ordersType: 'accountOrders',
        selectedCustomerIds: 'all'
      } : {})
    };
    const returnOrders = yield call(loadOrdersRaw, payload);

    const { orders = [] } = returnOrders;
    if (!orders.length) {
      yield put(goToUrl('/my-account/my-returns', true));
    }
    const skusArray = flatten(getOrderLine(orders)).map((orderLine) => orderLine.sku);
    const products = yield call(getProductFamilyProducts, skusArray);
    const publicHolidaysSelector = (state) => state.get('publicHolidays');
    const publicHolidays = yield select(publicHolidaysSelector);
    const [processedOrder] = !publicHolidays ? [] : processOrders(orders, products, publicHolidays.toJS());
    const csAgentName = yield select(csAgentNameSelector);
    const ordersWithReturn = orderReturnMap(processedOrder.raw, processedOrder.products, {}, !!csAgentName);
    yield put(createReturnOrReplacement(ordersWithReturn));
  } catch (error) {
    yield put(goToUrl('/my-account/my-returns'));
  }
}

export function * onRequestOrderConfirmationOrder (action) {
  const confirmationScreen = true;
  yield onRequestFetchOrder(action, confirmationScreen);
  const { orderId } = action;
  const order = yield select(orderSelector(orderId));

  if (process.browser) {
    pushTransactionDetails(order);
  }

  if (order) {
    yield put(reloadScheduledOrders(fromJS(toProductLineItems(order.toJS(), 'cart'))));
  }
}

export function * onRequestAddOrderToCart (action) {
  const orderLines = action.order.raw.orderLinesForMerlin && action.order.raw.orderLinesForMerlin.length
    ? action.order.raw.orderLinesForMerlin
    : action.order.raw.orderLines;
  const orderLinesToBeAdded = orderLines.map(orderLine => {
    return {
      sku: orderLine.zoroSku || action.order.sku,
      qty: orderLine.quantity || 0
    };
  });
  yield call(onRequestAddToCart, { payload: { isRecommendations: false, orderLinesToBeAdded, redirectUrl: '/cart' } });
}

export function * onSubmitCancellationRequest (action) {
  try {
    const { qty, sku, orderId, myOrdersState } = action;
    const csAgentName = yield select(csAgentNameSelector);
    const csAgentDisplayName = yield select(csAgentDisplayNameSelector);

    const requestDetails = {
      sku,
      qty,
      csAgentDisplayName,
      csAgentName
    };
    yield put(toggleSubmitCancelItemDialog(sku, orderId));
    yield call(cancelRequest, orderId, requestDetails);
    yield put(toastSuccess(
      getToastMessage(
        getLocalizedString('myAccount.myOrders.cancellation.success')
      ),
      'top-right'));
    const ordersPayload = { ...myOrdersState, type: 'REQUEST_MY_ORDERS' };
    yield onRequestMyOrders(ordersPayload);
  } catch {
    const { sku = '', orderId = '' } = action;
    const message = getLocalizedStringWithParam('myAccount.myOrders.cancellation.cancellationRequestFailed', { orderId, sku });
    yield put(cancellationRequestFailed());
    yield put(toastWarn(
      getToastMessage(
        message
      ),
      'top-right'));
  }
}

export function * requestPostOrder ({ payload }) {
  const paymentMethod = yield select(paymentMethodSelectors.paymentMethod(payload.selector));
  const scheduledOrdersFormsValues = yield select(scheduledOrderFormValuesSelector);
  const isPaymentFlowSkipped = yield select(orderSelectors.isPaymentFlowSkipped);
  const isSavedSettings = !!scheduledOrdersFormsValues.remove('cart').size && scheduledOrdersFormsValues.remove('cart').find(settings => settings.get('regularity'));

  if (isSavedSettings && !isPaymentFlowSkipped) {
    const isSavingCardForFutureOrders = yield select(paymentMethodSelectors.payByNewCard.saveCardForFutureOrders(payload.selector));

    const isValidPaymentMethod = [PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD].includes(paymentMethod) ||
            (paymentMethod === PAY_BY_NEW_CARD && isSavingCardForFutureOrders);

    if (!isValidPaymentMethod) {
      yield put(setInvalidSubscriptionPaymentMethod(paymentMethod));
      return;
    }
    yield put(saveScheduledAllOrderlinesSettings());
  }

  const isBusinessAccountOrderOnHold = yield select(accountSelectors.isBusinessAccountOrderOnHold);
  try {
    const isMobile = yield select(isMobileSelector);
    if (!isMobile) {
      yield put(setActivePanelId({ panelId: -1 }));
    }
    const deliveryAddressFormErrors = yield select(validationSelectors.form.deliveryAddress);
    const deliveryAddressFormOpen = yield select(showDeliveryAddressFormSelector);
    if (!formIsValid(deliveryAddressFormErrors) || deliveryAddressFormOpen) {
      yield put(setActivePanelId({ panelId: DELIVERY_ADDRESS_ACCORDION_PANEL_ID }));
      yield call(orderFailed, getLocalizedString('singlePageCheckout.error.delivery.not.saved'));
      return;
    }
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }

  if (payload.validNonDeliverableStatus) {
    yield put(setApiButtonStatus(payload.selector, READY_TO_SUBMIT));
    yield put(setApiButtonStatus(SINGLE_PAGE_PAYMENT, READY_TO_SUBMIT));
    yield put(showNonDeliverableOrderMessage());
    yield put(setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID }));
    return;
  }

  if ((isBusinessAccountOrderOnHold && paymentMethod === PAY_BY_ACCOUNT) && !isPaymentFlowSkipped) {
    const accountStatus = yield select(orderSelectors.accountStatus);
    yield put(setApiButtonStatus(payload.selector, READY_TO_SUBMIT));
    yield put(setApiButtonStatus(SINGLE_PAGE_PAYMENT, READY_TO_SUBMIT));
    yield put(showBusinessAccountOnHoldDialog());
    if (payload.showOnHoldModal || ![PENDING, UNVERIFIED].includes(accountStatus)) {
      return;
    }
  }

  yield put(setApiButtonStatus(payload.selector, SUBMITTING));

  if (isPaymentFlowSkipped) {
    yield put(payBySkippingMethod(payload));
    return;
  }

  if (paymentMethod === PAY_BY_ACCOUNT) {
    yield put(payByAccount(payload));
    return;
  }
  if (paymentMethod === PAY_BY_EXISTING_CARD) {
    yield put(payByExistingCard(payload));
    return;
  }
  if (paymentMethod === PAY_BY_NEW_CARD) {
    yield put(payByNewCard(payload));
    return;
  }
  if (paymentMethod === PAY_BY_PAYPAL) {
    yield put(payByPaypal(payload));
  }
  if (paymentMethod === PAY_BY_APPLE_PAY) {
    yield put(payByApplePay(payload));
  }
  if (paymentMethod === PAY_BY_GOOGLE_PAY) {
    yield put(payByGooglePay(payload));
  }
}

export function * watchPostOrder () {
  yield takeLatest(POST_ORDER, requestPostOrder);
}
export function * watchRequestOrdersRaw () {
  yield takeLatest(REQUEST_ORDERS_RAW, onRequestOrdersRaw);
}
export function * watchRequestMyOrders () {
  yield takeLatest(REQUEST_MY_ORDERS, onRequestMyOrders);
}
export function * watchRequestAccountOrders () {
  yield takeLatest(REQUEST_ACCOUNT_ORDERS, onRequestMyOrders);
}
export function * watchFetchOrder () {
  yield takeLatest(REQUEST_FETCH_ORDER, onRequestFetchOrder);
}
export function * watchGetReturnReplaceOrder () {
  yield takeLatest(GET_RETURN_REPLACE_ORDER, onGetReturnReplaceOrder);
}
export function * watchOrderConfirmationFetch () {
  yield takeLatest(GET_ORDER_CONFIRMATION_SCREEN_ORDER, onRequestOrderConfirmationOrder);
}
export function * watchAddOrderToCart () {
  yield takeLatest(ADD_ORDER_TO_CART, onRequestAddOrderToCart);
}
export function * watchSubmitCancellationRequest () {
  yield takeLatest(SUBMIT_CANCELLATION_REQUEST, onSubmitCancellationRequest);
}
