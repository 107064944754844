import { createAction } from 'redux-actions';

export const SET_SELECTED_CARD = 'payByExistingCard.SET_SELECTED_CARD';
export const setSelectedCard = createAction(SET_SELECTED_CARD);

export const SET_DEFAULT_CARD = 'payByExistingCard.SET_DEFAULT_CARD';
export const setDefaultCard = createAction(SET_DEFAULT_CARD);

export const INITIALIZE = 'payByExistingCard.INITIALIZE';
export const initialize = createAction(INITIALIZE);

export const PAY_BY_EXISTING_CARD = 'payByExistingCard.PAY';
export const payByExistingCard = createAction(PAY_BY_EXISTING_CARD);

export const SUBSCRIBE_BY_EXISTING_CARD = 'payByExistingCard.SUBSCRIBE';
export const subscribeByExistingCard = createAction(SUBSCRIBE_BY_EXISTING_CARD);
