import PropTypes from 'prop-types';
import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';

export const CATEGORY_ITEM_MENU = 'categoryItemMenu';
export const CATEGORY_WHITE_CROSS_SVG = 'whiteCrossSvg';

const CategoryItem = ({
  toggleDropdownMenu,
  hasMouseEvents,
  dropdownMenuVisible,
  isMobile
}) => {
  let content;
  const isMobileDropdownMenuVisible = dropdownMenuVisible && isMobile;

  const handler = () => dropdownMenuVisible ? toggleDropdownMenu(VISIBLE_DROPDOWN.NONE) : toggleDropdownMenu(VISIBLE_DROPDOWN.CATEGORIES);

  let dataE2e;
  if (hasMouseEvents) {
    dataE2e = 'categoryListDropdownMenuButtonDesktop';
    content = <><SvgLoader xlinkHref="#hamburger-icon"/><span>  Categories ▾</span></>;
  } else {
    dataE2e = 'categoryListDropdownMenuButtonMobile';
    content = isMobileDropdownMenuVisible ? <SvgLoader id={ CATEGORY_WHITE_CROSS_SVG } xlinkHref="#white-cross" fill="white"/> : <SvgLoader xlinkHref="#hamburger-icon"/>;
  }

  return (
    <button
      id={ CATEGORY_ITEM_MENU }
      type="button"
      data-e2e={ dataE2e }
      style={ isMobileDropdownMenuVisible ? { backgroundColor: '#D24600', outline: 'none', boxShadow: 'none', padding: '0.5rem' } : { outline: 'none', boxShadow: 'none' } }
      aria-label="Categories Menu"
      className="btn nav-link nav-link-categories"
      onClick={ handler }
    >
      { content }
    </button>
  );
};

CategoryItem.propTypes = {
  toggleDropdownMenu: PropTypes.func.isRequired
};

export default CategoryItem;
