import React from 'react';
import { Link } from 'react-router';

if (process.browser) {
  require('./CmsPromos.scss');
}

export const Promo = ({ promoDetails }) => {
  const title = promoDetails.get('title');
  const buttonText = promoDetails.get('buttonText');
  const imgSrc = promoDetails.get('imgSrc');
  const shopNowUrl = promoDetails.get('shopNowUrl');

  return (
    <div>
      {
        title && imgSrc && shopNowUrl && <div className="col-md-4 HomeScreen_promoAd">
          <div className="HomeScreen_promoAdPanel">
            <Link
              id="HomeScreen_promoAdPanel.stokoderm"
              to={ shopNowUrl }>
              <img
                src={ imgSrc }
                alt=""
                title=""
                className="HomeScreen_promoAd_Image" />
            </Link>
            <div className="HomeScreen_promoAdInfoWrap">
              <span className="HomeScreen_promoAdTitle">{ title }</span>
              <Link
                id="HomeScreen_promoAdPanel.stokoderm.shopNow"
                to={ shopNowUrl }
                className="pull-right ShopNowButton">{ buttonText }</Link>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export const CmsPromos = ({ data, css }) => {
  const promos = data.get('promos');
  return (
    <div className="container-fluid cms-promos" style={{}}>
      <div className="row">{
        promos.map((promo, index) => {
          return (<Promo
            key={ index }
            promoDetails={ promo }
            css={ css }
          />);
        })
      }
      </div>
    </div>
  );
};

export default CmsPromos;
