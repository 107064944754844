import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import AddressForm from 'client/components/elements/addressForm/AddressForm';
import { getLocalizedString } from 'localization/localizer';
import { PrimaryFormTitle } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./DeliveryAndBillingDetailsScreen.scss');
}

const getPrePopulatedAddressFields = (isEditAddress, addresses, accountDetails, editAddress) => {
  if (isEditAddress) {
    const editAddressIndex = editAddress.get('editAddressIndex');
    const isDefault = editAddress.get('isDefault');
    const address = addresses && addresses.get(editAddressIndex);
    const addressDetails = address && {
      firstName: address.get('firstName', ''),
      lastName: address.get('lastName', ''),
      addressPhoneNumber: address.get('addressPhoneNumber', ''),
      companyName: address.get('companyName', ''),
      addressLine1: address.get('addressLine1', ''),
      addressLine2: address.get('addressLine2', ''),
      city: address.get('city', ''),
      state: address.get('state', ''),
      postalCode: address.get('postalCode', ''),
      addressId: address.get('addressId', ''),
      addressName: address.get('addressName', ''),
      isDefault
    };
    return addressDetails;
  }
  return {
    firstName: accountDetails.get('firstName', ''),
    lastName: accountDetails.get('lastName', ''),
    addressPhoneNumber: accountDetails.getIn(['phoneNumbers', 'mobile'])
  };
};

const getPrePopulatedBillingAddressFields = (billingAddress) => {
  return billingAddress ? {
    firstName: billingAddress.get('firstName', ''),
    lastName: billingAddress.get('lastName', ''),
    addressPhoneNumber: billingAddress.get('addressPhoneNumber', ''),
    companyName: billingAddress.get('companyName', ''),
    addressLine1: billingAddress.get('addressLine1', ''),
    addressLine2: billingAddress.get('addressLine2', ''),
    city: billingAddress.get('city', ''),
    state: billingAddress.get('state', ''),
    postalCode: billingAddress.get('postalCode', '')
  } : null;
};

const CreateAddressDetailsScreen = ({ accountDetails, onEditDeliveryAddress,
  onEnterDeliveryAddress,
  onEnterBillingAddress, addresses, route,
  payOnAccount,
  fromQuotationId, editAddress, calledFrom,
  onIsUpdateBillingDetails,
  isDisableSaveButton,
  isDisableBackButton,
  displayAddressToggle,
  newDeliveryAddressToggle,
  viewAddressBookToggle,
  scrollToCheckoutSection,
  editAddressAction,
  onChangeBillingAddressToggle,
  submitButtonText,
  isFirstAddress,
  isInitialDeliveryForm,
  billingAddress
}) => {
  const editAddressIndex = editAddress.get('editAddressIndex');
  const isPostCodeSubmitted = editAddress.get('isPostCodeSubmitted');
  const isEditAddress = editAddressIndex || editAddressIndex === 0 ? true : false;
  const isDeliveryForm = route.type === 'delivery';
  const isBillingForm = route.type === 'billing';
  const formKey = isDeliveryForm ? 'deliveryDetailsAddressForm' : 'billingDetailsAddressForm';
  const prePopulatedAddressFields = isBillingForm && billingAddress ? getPrePopulatedBillingAddressFields(billingAddress) : getPrePopulatedAddressFields(isEditAddress, addresses, accountDetails, editAddress);
  const headerText = isEditAddress
    ? getLocalizedString('createAdddress.headerText.editAddress')
    : isDeliveryForm
      ? getLocalizedString('createAdddress.headerText.deliverAddress')
      : getLocalizedString('createAdddress.headerText.billingAddress');
  const submitHandler = isEditAddress
    ? onEditDeliveryAddress
    : isDeliveryForm
      ? onEnterDeliveryAddress
      : onEnterBillingAddress;

  const submitButtonLabel = (submitButtonText) ? submitButtonText : getLocalizedString('order.address.saveAddressBtn');
  return (
    <Fragment>
      <div className="col-xs-12">
        <div className="col-md-6 col-md-offset-3 col-xs-12">
          <PrimaryFormTitle titleText={ headerText } iconName={ isDeliveryForm ? 'home-icon' : 'card-icon' } />
        </div>
        <div className="col-md-6 col-md-offset-3 col-xs-12">
          <AddressForm
            form={ formKey }
            formName={ formKey }
            onAddressSubmit={ submitHandler }
            submitButtonText={ submitButtonLabel }
            address={ prePopulatedAddressFields }
            isInitialAddressForm={ isDeliveryForm || undefined }
            payOnAccount={ payOnAccount }
            fromQuotationId={ fromQuotationId }
            calledFrom={ calledFrom }
            editAddress={ editAddress }
            isEditAddress={ isEditAddress }
            isPostCodeSubmitted={ isPostCodeSubmitted }
            onIsUpdateBillingDetails = { onIsUpdateBillingDetails }
            isDisableSaveButton = { isDisableSaveButton }
            isDisableBackButton = { isDisableBackButton }
            displayAddressToggle={ displayAddressToggle }
            newDeliveryAddressToggle={ newDeliveryAddressToggle }
            viewAddressBookToggle={ viewAddressBookToggle }
            scrollToCheckoutSection={ scrollToCheckoutSection }
            editAddressAction={ editAddressAction }
            onChangeBillingAddressToggle = { onChangeBillingAddressToggle }
            isFirstAddress={ isFirstAddress }
            isInitialDeliveryForm={ isInitialDeliveryForm }
            onBillingAddressSubmit={ onEnterBillingAddress }
          />
        </div>
      </div>
    </Fragment>
  );
};

CreateAddressDetailsScreen.propTypes = {
  accountDetails: PropTypes.object,
  onEnterDeliveryAddress: PropTypes.func,
  onEnterBillingAddress: PropTypes.func,
  payOnAccount: PropTypes.bool,
  fromQuotationId: PropTypes.string
};

export default CreateAddressDetailsScreen;
