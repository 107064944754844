import { VALIDATE_PASSWORD_CHECKLIST } from '../actions/passwordChecklistAction';
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

export const defaultState = fromJS({
  passwordChecklist: {
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false
  }
});

export const validatePasswordChecklist = {
  [VALIDATE_PASSWORD_CHECKLIST]: (state = defaultState, password) =>
    state
      .setIn(['passwordChecklist', 'length'], password.length >= 8)
      .setIn(['passwordChecklist', 'uppercase'], /[A-Z]/.test(password))
      .setIn(['passwordChecklist', 'lowercase'], /[a-z]/.test(password))
      .setIn(['passwordChecklist', 'digit'], /[0-9]/.test(password))
      .setIn(['passwordChecklist', 'specialChar'], /[!@#$%^&*()+.,£-]/.test(password))
};
export default handleActions(validatePasswordChecklist, defaultState);
