import { fromJS } from 'immutable';

const defaultState = fromJS({
  location: {},
  params: {}
});

export default (state = defaultState, { type, params }) =>
  (type === 'UPDATE_ROUTE_PARAMS')
    ? state.set('params', params)
    : state;
