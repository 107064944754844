import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemoveAddressDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveAddressConfirm } from '../../../actions/addressActions';

if (process.browser) {
  require('./RemoveAddressDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeAddressDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeAddress', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveAddressDialog: flowRight(dispatch, hideRemoveAddressDialog),
    requestRemoveAddressConfirm: flowRight(dispatch, requestRemoveAddressConfirm)
  };
}

const RemoveAddressDialog = (props) => {
  const { removeAddressDialogIsVisible,
    requestRemoveAddressConfirm,
    hideRemoveAddressDialog } = props;

  return (
    <Modal className="RemoveAddressDialog" show={ removeAddressDialogIsVisible }
      onHide={ hideRemoveAddressDialog }>
      <Modal.Header className="RemoveAddressDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <div>
            Are you sure you want to delete this address?<br/>
            Please note: This action cannot be reversed.
          </div>
        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            bsSize="large"
            onClick={ hideRemoveAddressDialog }>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            bsSize="large"
            onClick={ requestRemoveAddressConfirm }>
            Delete
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveAddressDialog.propTypes = {
  removeAddressDialogIsVisible: PropTypes.bool,
  hideRemoveAddressDialog: PropTypes.func,
  requestRemoveAddressConfirm: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAddressDialog);
