import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { LOAD_SERVICE_AVG_RATING, LOAD_SERVICE_REVIEWS_SUCCESS, REQUEST_SERVICE_REVIEWS } from 'client/actions/feefoReviewsActions';

export const defaultState = fromJS({});
export const actionMap = {
  [REQUEST_SERVICE_REVIEWS]: (state = defaultState, action) => {
    const serviceRating = state.getIn(['serviceReviews', 'serviceRating']);
    return state.set('serviceReviews', fromJS({
      'loadStateType': 'PENDING',
      'serviceRating': serviceRating
    }));
  },
  [LOAD_SERVICE_AVG_RATING]: (state = defaultState, action) => {
    return state.setIn(['serviceReviews', 'serviceRating'],
      fromJS({
        'avgRating': action.serviceRating.getIn(['rating', 'rating']),
        'maxRating': action.serviceRating.getIn(['rating', 'max'])
      })
    );
  },
  [LOAD_SERVICE_REVIEWS_SUCCESS]: (state = defaultState, action) => {
    const serviceRating = state.getIn(['serviceReviews', 'serviceRating']);
    return state.set('serviceReviews',
      fromJS({
        'reviews': action.serviceReviews,
        'totalReviews': action.totalReviews,
        'serviceRating': serviceRating
      })
    );
  }
};
export default handleActions(actionMap, defaultState);
