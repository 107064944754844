import React from 'react';
import { Iterable } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import CmsShoveler from './cmsShoveler';
import CmsIndustriesTile from './cmsIndustriesTile';

const getIndustriesData = (data) => {
  const industriesArray = [];
  if (data && Iterable.isIterable(data) && data.size) {
    data.toJS().forEach(i => {
      industriesArray.push({ ...i, to: i.industryUrl });
    });
  }
  return industriesArray;
};

export default (props) =>
  <CmsShoveler
    TileComponent={ CmsIndustriesTile }
    searchPlaceHolder={ getLocalizedString('shoveler.industries.searchPlaceholder') }
    heading={ getLocalizedString('shoveler.industries.heading') }
    ifEmptyCustomClassName="CmsShoveler_Industries_NoResults"
    { ...props }
    data={ getIndustriesData(props.data) }
  />;
