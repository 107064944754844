import React, { Component } from 'react';
import { Link } from 'react-router';
import _truncate from 'lodash/truncate';

import SvgLoader from '../../../svg/SvgLoader';

export default class CmsIndustriesTile extends Component {
  render = () => (
    <Link to={ this.props.item.to } >
      <div className="CmsShoveler_Industries_Tile">
        <div className="CmsShoveler_Industries_Tile_svg">
          <SvgLoader xlinkHref={ this.props.item.iconId }/>
        </div>
        <p className="CmsShoveler_Industries_Tile_title">
          { _truncate(this.props.item.name, { 'length': 60, 'separator': /,? +/ }) }
        </p>
      </div>
    </Link>
  );
}
