import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { getLocalizedString } from 'localization/localizer';
import { hideRemoveProductDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveOrderLineConfirm } from '../../../actions/cartActions';
import { PrimaryButton, SecondaryButton } from '../../controls/StyledForms/formButtons';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./RemoveProductDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeProductDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeProduct', 'isVisible']),
    orderLine: state.getIn(['ui', 'dialogs', 'removeProduct', 'orderLine'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveProductDialog: flowRight(dispatch, hideRemoveProductDialog),
    requestRemoveOrderLineConfirm: flowRight(dispatch, requestRemoveOrderLineConfirm)
  };
}

const RemoveProductDialog = (props) => {
  const { removeProductDialogIsVisible,
    requestRemoveOrderLineConfirm,
    hideRemoveProductDialog, orderLine, location, updateDataLayer } = props;
  useEffect(() => {
    if (location.pathname.startsWith('/checkout') && removeProductDialogIsVisible) {
      updateDataLayer({
        event: 'checkout_modal',
        type: 'remove_product'
      });
    }
  }, [removeProductDialogIsVisible]);
  if (!orderLine) {
    return null;
  }
  const removeOrderLine = () => {
    requestRemoveOrderLineConfirm(orderLine);
    hideRemoveProductDialog();
  };
  const imageUrl = orderLine.getIn(['image', 'mainPicture', 'productThumbnail', 'lowResolution']) || orderLine.get('imageSrcLowResolution', '');
  const name = orderLine.get('name');
  const sku = orderLine.get('sku');
  return (
    <Modal className="RemoveProductDialog" show={ removeProductDialogIsVisible }
      onHide={ hideRemoveProductDialog }>
      <Modal.Header className="RemoveProductDialog_header" closeButton>
        <h4 className="RemoveProductDialog_title">
          { getLocalizedString('cart.dialog.header.message') }
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <ZoroImage src={ imageUrl }/>
          <div>
            <div>{ name }</div>
            <div>
              { getLocalizedString('cart.dialog.label.sku') } { sku }
            </div>
          </div>
        </div>

        <div className="row modal-body_options modal-body_inline">

          <PrimaryButton onClick={ removeOrderLine }
            text={ getLocalizedString('cart.dialog.remove.product.button') }
          />

          <SecondaryButton onClick={ hideRemoveProductDialog }
            text={ getLocalizedString('cart.dialog.cancel.button') } />

        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveProductDialog.propTypes = {
  removeProductDialogIsVisible: PropTypes.bool,
  hideRemoveProductDialog: PropTypes.func,
  requestRemoveOrderLineConfirm: PropTypes.func,
  orderLine: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveProductDialog);
