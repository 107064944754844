import { put, call, takeLatest, fork, all, select, takeEvery } from 'redux-saga/effects';
import { INIT_RETURN_REPLACEMENT,
  onReturnReplacementError,
  INIT_RETURN_FILE_UPLOAD,
  updateItemSelection,
  onFileUploadError,
  FETCH_CONFIRMED_RETURNS,
  onConfirmReturnsSuccess,
  RESET_SELECTION,
  resetReturnRequestDetails } from 'client/actions/returnsOrReplacementActions.js';
import { processReturnReplacementRequest,
  uploadReturnReplacementImages,
  fetchReturnsRequestDetails,
  getImageBlob } from 'shared/endpoints/returnReplacementEndpoint';
import { RETURN_FORM } from 'shared/constants/returnRefundConstants';
import { transfromReturnPayload, processConfirmationDetails } from 'client/utils/returnRefundUtils';
import { toastError } from 'client/actions/showNotificationsActions';
import { goToUrl } from 'client/actions/routeActions';
import { destroy, change } from 'redux-form/immutable';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedString } from '../../localization/localizer';

const selectedReturnItemsAction = (state) => state.getIn(['returnsOrReplacements', 'selectedReturnItems']).toJS();
const TOASTER_DELAY = 4000;
const MY_ACCOUNT_RETURN_URL = '/my-account/refund-replacement';

export function * onInitReturnRequest ({ payload }) {
  try {
    const { merlinOrderReference, selectedReturnItems } = payload;
    const eligibleReturnReplacementItems = selectedReturnItems.filter((selected) => selected.toggle);
    const body = transfromReturnPayload(eligibleReturnReplacementItems);
    const returnResponse = yield call(processReturnReplacementRequest, merlinOrderReference, body);
    const nextUrl = `${MY_ACCOUNT_RETURN_URL}/confirmation/${returnResponse.returnRequestId}`;
    yield put(resetReturnRequestDetails());
    yield put(goToUrl(nextUrl));
  } catch (error) {
    yield put(onReturnReplacementError(error.message));
  }
}

const getUpdatedImageList = (existingImageList, newImageList) => {
  return existingImageList.map((image) => {
    const newImage = newImageList.find((imgObject) => imgObject.Key === image.Key);
    const updatedImage = newImage ? { ...newImage, fileName: image.fileName } : image;
    return updatedImage;
  });
};

function * onInitFileUpload ({ payload, fieldName }) {
  try {
    const data = [];
    const { imageList, merlinOrderReference, sku } = payload;
    for (const img of imageList) {
      const { blobUrl, Key: fileName } = img;
      const blob = yield call(getImageBlob, blobUrl);
      window.URL.revokeObjectURL(blobUrl);
      data.push({ key: 'returnRequestImages', fileBlob: blob, fileName });
    }
    const result = yield all(data.map((imageObj) => {
      return call(uploadReturnReplacementImages, merlinOrderReference, [imageObj]);
    }));
    const imageObjectList = [].concat(...result);
    const selectedReturnItems = yield select(selectedReturnItemsAction);
    const existingItem = selectedReturnItems.find((item) => item.sku === sku);
    const allImageList = existingItem ? existingItem.imageObjectList : [];
    const updatedImageObjectList = getUpdatedImageList(allImageList, imageObjectList);
    yield put(updateItemSelection({ sku, imageObjectList: updatedImageObjectList }));
    yield put(change(RETURN_FORM, fieldName, updatedImageObjectList));
  } catch (error) {
    yield put(onFileUploadError(payload));
    yield put(toastError(getToastMessage(getLocalizedString('return.request.upload.error')), 'top-right', TOASTER_DELAY));
  }
}

export function * fetchConfirmedReturnsDetails ({ reqId }) {
  try {
    const order = yield call(fetchReturnsRequestDetails, reqId);
    const details = processConfirmationDetails(order);
    yield put(onConfirmReturnsSuccess(details));
  } catch (error) {
    yield put(toastError(getToastMessage(getLocalizedString('return.request.fetch.error')), 'top-right', TOASTER_DELAY));
  }
}

function * resetReturnRequestForm () {
  yield put(destroy(RETURN_FORM));
}

export function * watchInitReturnReplacement () {
  yield takeLatest(INIT_RETURN_REPLACEMENT, onInitReturnRequest);
}

export function * watchInitFileUpload () {
  yield takeEvery(INIT_RETURN_FILE_UPLOAD, onInitFileUpload);
}

export function * watchFetchReturnsRequestDetails () {
  yield takeLatest(FETCH_CONFIRMED_RETURNS, fetchConfirmedReturnsDetails);
}

function * watchResetReturnRequestForm () {
  yield takeLatest(RESET_SELECTION, resetReturnRequestForm);
}

export function * watchReturnReplacement () {
  yield all([
    fork(watchInitReturnReplacement),
    fork(watchInitFileUpload),
    fork(watchFetchReturnsRequestDetails),
    fork(watchResetReturnRequestForm)
  ]);
}
