export const FREE_DELIVERY_THRESHOLD = 25;
export const MY_ORDERS_TYPE = 'myOrders';
export const ZORO_META_TITLE = 'Zoro UK Business Supplies';
export const ZORO_META_SITE_NAME = 'Zoro UK - Business Supplies';

const MY_ACCOUNT_BASE_PATH = '/my-account';
export const myAccountPageUrlMap = {
  accountDetails: `${MY_ACCOUNT_BASE_PATH}/account-details`,
  accountStatements: `${MY_ACCOUNT_BASE_PATH}/account-statements`,
  accountUsers: `${MY_ACCOUNT_BASE_PATH}/account-users`,
  addressBook: `${MY_ACCOUNT_BASE_PATH}/address-book`,
  emailPreferences: `${MY_ACCOUNT_BASE_PATH}/email-preferences`,
  myInvoices: `${MY_ACCOUNT_BASE_PATH}/my-invoices`,
  myOrders: `${MY_ACCOUNT_BASE_PATH}/my-orders`,
  myReturns: `${MY_ACCOUNT_BASE_PATH}/my-returns`,
  savedCards: `${MY_ACCOUNT_BASE_PATH}/saved-cards`,
  quotations: `${MY_ACCOUNT_BASE_PATH}/quotations`,
  repeatOrders: `${MY_ACCOUNT_BASE_PATH}/repeat-orders`
};

export const MY_ACCOUNT_PAGES = Object.keys(myAccountPageUrlMap);
export const MY_ACCOUNT_PAGE_URLS = Object.values(myAccountPageUrlMap);

export const MY_ORDERS_URL = '/my-account/my-orders';
export const FREE_DELIVERY_LOYALTY_TIERS = [1, 2, 3];
export const CART_URL = '/cart';
export const ALL_PRODUCTS_NOT_DELIVERABLE = 'allProductsNotDeliverable';
export const MAX_SEARCH_INPUT = 100;
