import { LOAD_CATEGORY_SUCCESS } from 'client/actions/categoryActions';
import { fromJS } from 'immutable';

const categoryReducer = function categoryReducer (state, action) {
  switch (action.type) {
    case LOAD_CATEGORY_SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
};

export default categoryReducer;
