import React from 'react';
import { connect } from 'react-redux';
import { getCardImageUrl } from 'client/utils/braintreeUtils';
import { displayAddress } from './AccordionContent';
import { fromJS, List } from 'immutable';
import {
  PAY_BY_ACCOUNT,
  PAY_BY_APPLE_PAY,
  PAY_BY_EXISTING_CARD, PAY_BY_GOOGLE_PAY, PAY_BY_NEW_CARD,
  PAY_BY_PAYPAL
} from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import SvgLoader from 'client/components/svg/SvgLoader';
import { mapPaypalAddressToZoro } from 'client/components/elements/paymentMethod/PayByPaypal/payByPaypal';
import { getLocalizedString } from 'localization/localizer';
import { getVariant, prepareUnleashOverridePayload } from 'client/utils/unleashUtils';
import { SHOW, UNLEASH_APPLE_PAY_FEATURE_TOGGLE, UNLEASH_APPLE_PAY_SHOW, UNLEASH_SHOW_ONHOLD_MODAL } from 'shared/constants/abTesting';
import { withRouter } from 'react-router';
import { mapGooglePayAddressToZoro } from 'client/components/elements/paymentMethod/shared/PaymentAddress';
import ConnectedUnleashWrapper from '../../../elements/unleashABTest/ConnectedUnleashWrapper';
import { BOTTOM_BOX_MESSAGE } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { getAccountStatusMessage } from '../../../../utils/getAccountStatusMessage';

export const PaymentAccordionHeader = ({ paymentMethod, payByExistingCard, payByPaypal, payByAccount, showPayOnAccountButton, payByApplePay, payByGooglePay, showApplePay, showGooglePay, showOnHoldBanner, unleashOnHoldModal, accountStatusMessage }) => {
  if (paymentMethod === PAY_BY_EXISTING_CARD) {
    const selectedCard = payByExistingCard.selectedCard;
    return (
      <>
        <img src={ getCardImageUrl[selectedCard.cardType] } className="PayByExistingCard_card_icon" alt="card"/>
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ selectedCard.cardType } - { selectedCard.cardLastFourDigits }</span>
        { displayAddress({ deliveryAddress: fromJS(selectedCard.billingAddress) }) }
      </>
    );
  }
  if (paymentMethod === PAY_BY_PAYPAL) {
    return (
      <>
        <SvgLoader xlinkHref={ 'paypal-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardLogo" />
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ payByPaypal.payPalEmail }</span>
        { displayAddress({ deliveryAddress: fromJS(payByPaypal.billingAddress) }) }
      </>
    );
  }
  if (paymentMethod === PAY_BY_APPLE_PAY && showApplePay) {
    return (<>
      <SvgLoader xlinkHref={ 'pay-by-apple-pay-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardLogo"/>
      <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails"> { displayAddress({ deliveryAddress: fromJS(payByApplePay.billingAddress) }) }</span>
    </>);
  }
  if (paymentMethod === PAY_BY_GOOGLE_PAY && showGooglePay) {
    return (<>
      <SvgLoader xlinkHref={ 'pay-by-google-pay-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_googlePayLogo"/>
      <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails"> { displayAddress({ deliveryAddress: fromJS(payByGooglePay.billingAddress) }) }</span>
    </>);
  }
  if (paymentMethod === PAY_BY_ACCOUNT) {
    return <>
      <SvgLoader xlinkHref="pay-on-account-icon" className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_accountLogo"/>
      <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ getLocalizedString('cart.buttons.payOnAccount') }</span>
      { displayAddress({ deliveryAddress: payByAccount.billingAddress, showOnHoldBanner, accountStatusMessage }) }
    </>;
  }
  if (paymentMethod === PAY_BY_NEW_CARD) {
    return <div className="row col-xs-12">
      <div className="col-xs-12 col-md-4">
        <SvgLoader xlinkHref="#card-icon" className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_newCardLogo"/>
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails_new">{ getLocalizedString('payment.label.add.new.card') }</span>
      </div>
      <div className="col-xs-12 col-md-4">
        <SvgLoader xlinkHref={ 'paypal-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardLogo" />
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ getLocalizedString('payment.label.pay.with.paypal') }</span>
      </div>
      { window.ApplePaySession && showApplePay ? (
        <div className="col-xs-12 col-md-4">
          <SvgLoader xlinkHref={ 'pay-by-apple-pay-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardLogo"/>
          <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ getLocalizedString('payment.label.pay.with.applepay') }</span>
        </div>
      ) : null }
      { showGooglePay ? (
        <div className="col-xs-12 col-md-4">
          <SvgLoader xlinkHref={ 'pay-by-google-pay-icon' } className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardLogo"/>
          <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ getLocalizedString('payment.label.pay.with.googlepay') }</span>
        </div>
      ) : null }
      { showPayOnAccountButton ? <div className="col-xs-12 col-md-4">
        <SvgLoader xlinkHref="pay-on-account-icon" className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_accountLogo"/>
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_cardDetails">{ getLocalizedString('cart.buttons.payOnAccount') }</span>
      </div> : null }
      { unleashOnHoldModal && <ConnectedUnleashWrapper flag = { UNLEASH_SHOW_ONHOLD_MODAL } /> }
    </div>;
  }

  return null;
};

function mapStateToProps (state, ownProps) {
  const { location } = ownProps;
  const query = location.query ? location.query : null;
  const overrideVariantFromQuery = prepareUnleashOverridePayload(query);
  const details = state.getIn(['ui', 'payByPaypal', ownProps.selector, 'details']);
  const email = details && details.email;
  const billingAddress = details && mapPaypalAddressToZoro(details);
  const payByExistingCard = state.getIn(['ui', 'payByExistingCard']).toJS();
  const selectedCard = payByExistingCard[ownProps.selector] === undefined ? {} : payByExistingCard[ownProps.selector].selectedCard;
  const unleashToggles = state.getIn(['unleash', 'toggles'], List()).toJS();

  const unleashOverrideValues = state.getIn(['unleash', 'payload'], fromJS({})).toJS();
  const { unl_feature: unlFeature, unl_variant: unlVariant, unl_payload: unlPayload } = unleashOverrideValues;
  const overridenUnleashData = {
    unl_feature: unlFeature,
    unl_variant: unlVariant,
    unl_payload: unlPayload?.value || {}
  };
  const overrideVariant = prepareUnleashOverridePayload(overridenUnleashData);

  const toggleApplePayVariant = getVariant(unleashToggles, UNLEASH_APPLE_PAY_FEATURE_TOGGLE, '', overrideVariant);
  const isApplePayShowVariant = toggleApplePayVariant === UNLEASH_APPLE_PAY_SHOW;
  const isGooglePayReady = state.getIn(['ui', 'payByGooglePay', 'isReadyToPay'], false);
  const googlePayPaymentDetails = state.getIn(['ui', 'payByGooglePay', ownProps.selector, 'details']);
  const googlePayBillingAddress = googlePayPaymentDetails && mapGooglePayAddressToZoro(googlePayPaymentDetails);
  const unleashOnHoldModal = getVariant(unleashToggles, UNLEASH_SHOW_ONHOLD_MODAL, '', overrideVariantFromQuery);
  const messagePosition = state.getIn(['cart', 'tradeAccountButton', 'messagePosition'], '');
  const showOnHoldBanner = unleashOnHoldModal !== SHOW && messagePosition === BOTTOM_BOX_MESSAGE;
  const accountRole = state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'accountRole'], '');
  const accountAdminEmail = state.getIn(['user', 'accountDetails', 'tradeAccount', 'primaryContact', 'email'], '');
  const accountStatusMessageKey = state.getIn(['cart', 'tradeAccountButton', 'accountStatusMessageKey'], '');
  const tradeAccountStatus = state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'status'], '');

  return {
    paymentMethod: state.getIn(['ui', 'paymentMethod', ownProps.selector, 'method']),
    payByExistingCard: {
      selectedCard
    },
    payByPaypal: {
      email,
      billingAddress
    },
    payByApplePay: {
      billingAddress
    },
    payByAccount: {
      billingAddress: state.getIn(['user', 'accountDetails', 'tradeAccount', 'invoiceContact'])
    },
    payByGooglePay: {
      billingAddress: googlePayBillingAddress
    },
    showPayOnAccountButton: state.getIn(['cart', 'tradeAccountButton', 'showPayOnAccountButton'], false),
    showApplePay: isApplePayShowVariant,
    showGooglePay: isGooglePayReady,
    showOnHoldBanner,
    unleashOnHoldModal,
    accountStatusMessage: getAccountStatusMessage(accountStatusMessageKey, () => {}, accountRole, accountAdminEmail, tradeAccountStatus)
  };
}

export default withRouter(connect(mapStateToProps)(PaymentAccordionHeader));
