import { createAction } from 'redux-actions';

export const INITIALIZE_GOOGLE_PAY = 'payByGooglePay.INITIALIZE_GOOGLE_PAY';
export const initializeGooglePay = createAction(INITIALIZE_GOOGLE_PAY);

export const SET_DETAILS = 'payByGooglePay.SET_DETAILS';
export const setDetails = createAction(SET_DETAILS);

export const SET_DATA = 'payByGooglePay.SET_DATA';
export const setData = createAction(SET_DATA);

export const PAY_BY_GOOGLE_PAY = 'payByGooglePay.PAY';
export const payByGooglePay = createAction(PAY_BY_GOOGLE_PAY);

export const SET_IS_READY_TO_PAY = 'payByGooglePay.isReadyToPay';
export const setGoogleIsReadyToPay = createAction(SET_IS_READY_TO_PAY);
