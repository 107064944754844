import React from 'react';
import { Field, change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { toggleEnterManually, requestFullAddress, requestAddressGen, requestAddressSuccess } from 'client/actions/ui/postCodeActions';
import localState from 'shared/utils/localState';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { SecondaryButton } from 'client/components/controls/StyledForms/formButtons';
import Autosuggest from 'react-autosuggest';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./postcodeInput.scss');
}

const ManualEntrySection = ({ fieldNamePrefix, datae2ePrefix, disabled = false }) => {
  let addressLine1 = 'addressLine1';
  let addressLine1Id = 'addressLine1';
  let addressLine1E2E = 'addressLine1';

  let addressLine2 = 'addressLine2';
  let addressLine2Id = 'addressLine2';
  let addressLine2E2E = 'addressLine2';

  let city = 'city';
  let cityId = 'city';
  let cityE2E = 'city';

  let state = 'state';
  let stateId = 'state';
  let stateE2E = 'state';

  let postCode = 'postalCode';
  let postCodeId = 'postalCode';
  let postCodeE2E = 'postCode';

  if (fieldNamePrefix) {
    addressLine1 = fieldNamePrefix + addressLine1;
    addressLine1Id = fieldNamePrefix + addressLine1Id;
    addressLine1E2E = datae2ePrefix + addressLine1E2E;
    addressLine2 = fieldNamePrefix + addressLine2;
    addressLine2Id = fieldNamePrefix + addressLine2Id;
    addressLine2E2E = datae2ePrefix + addressLine2E2E;
    city = fieldNamePrefix + city;
    cityId = fieldNamePrefix + cityId;
    cityE2E = datae2ePrefix + cityE2E;
    state = fieldNamePrefix + state;
    stateId = fieldNamePrefix + stateId;
    stateE2E = datae2ePrefix + stateE2E;
    postCode = fieldNamePrefix + postCode;
    postCodeId = fieldNamePrefix + postCodeId;
    postCodeE2E = datae2ePrefix + postCodeE2E;
  }

  return (<div className="row">
    <div className="row col-xs-12">
      <div className="col-xs-12 col-md-6">
        <Field
          name={ addressLine1 }
          id={ addressLine1Id }
          className="AddressFormPostCode_formGroup_input form-control"
          disabled={ disabled }
          labelClassName="AddressFormPostCode_formGroup_label control-label required"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.addressLine1"
          datae2e={ addressLine1E2E }
        />
      </div>
      <div className="col-xs-12 col-md-6">
        <Field
          name={ addressLine2 }
          id={ addressLine2Id }
          className="AddressFormPostCode_formGroup_input form-control"
          disabled={ disabled }
          labelClassName="AddressFormPostCode_formGroup_label AddressFormPostCode_formGroup_label-optional"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.addressLine2"
          datae2e={ addressLine2E2E }
        />
      </div>
    </div>
    <div className="row col-xs-12">
      <div className="col-xs-12 col-md-3">
        <Field
          name={ city }
          id={ cityId }
          className="AddressFormPostCode_formGroup_input form-control"
          disabled={ disabled }
          labelClassName="AddressFormPostCode_formGroup_label control-label required"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.city"
          datae2e={ cityE2E } />
      </div>
      <div className="col-xs-12 col-md-3">
        <Field
          name={ state }
          id={ stateId }
          className="AddressFormPostCode_formGroup_input form-control"
          disabled={ disabled }
          labelClassName="AddressFormPostCode_formGroup_label AddressFormPostCode_formGroup_label-optional"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.state"
          datae2e={ stateE2E } />
      </div>
      <div className="col-xs-12 col-md-3">
        <Field
          name={ postCode }
          id={ postCodeId }
          className="AddressFormPostCode_formGroup_input form-control"
          disabled={ disabled }
          labelClassName="AddressFormPostCode_formGroup_label control-label required"
          type="text"
          component={ FieldWithValidations }
          labelResourceName="postcodeInput.label.postcode"
          datae2e={ postCodeE2E } />
      </div>
    </div>
  </div>
  );
};

export const PostcodeInput = ({
  fieldNamePrefix,
  disabled = false,
  quickFindPostCodeValue,
  addressList = [],
  requestAddressForId,
  isManuallyEntered = false,
  toggleShowManually,
  requestAddress,
  errorMessage,
  change,
  clearRequestedAddress,
  isSinglePageCheckout = false,
  datae2ePrefix
}) => {
  let quickFindPostCodeName = 'quickFindPostCode';
  let quickFindPostCodeE2E = 'quickFinder';

  if (fieldNamePrefix) {
    quickFindPostCodeName = fieldNamePrefix + quickFindPostCodeName;
    quickFindPostCodeE2E = datae2ePrefix + quickFindPostCodeE2E;
  }

  function showAddressEntrySection () {
    if (isManuallyEntered) {
      return (
        <ManualEntrySection
          fieldNamePrefix={ fieldNamePrefix }
          disabled={ disabled }
          datae2ePrefix={ datae2ePrefix }
          isSinglePageCheckout={ isSinglePageCheckout }
        />
      );
    }
    return null;
  }

  function onFindPostCode (e, { suggestion }) {
    const { company, address, postcode, city } = suggestion;
    const addressLine = address && suggestion.address.join(', ');
    const companyName = company ? `${company}, ` : '';
    const addressValue = `${companyName}${addressLine}, ${city}, ${postcode}`;

    change(quickFindPostCodeName, addressValue);
    toggleShowManually(false);
    requestAddressForId(suggestion);
  }

  function enterManually () {
    toggleShowManually(true);
  }

  function showEnterManuallyBlock () {
    if (!isManuallyEntered) {
      if (addressList && addressList.length) {
        return (
          <div>
            <span>Can&apos;t find address?</span>&nbsp;
            <SecondaryButton
              className="enterManuallyButton"
              disabled={ disabled }
              datae2e={ fieldNamePrefix + 'enterManually' }
              text={ getLocalizedString('postcodeInput.label.enterManually') }
              onClick={ () => enterManually() } />
          </div>
        );
      }
      return (
        <div className="row col-xs-12">
          <a className="AddressFormPostCode_manualLink"
            disabled={ disabled }
            data-e2e={ fieldNamePrefix + 'enterManually' }
            onClick={ () => enterManually() }
          >{ getLocalizedString('postcodeInput.label.enterManually') }</a>
        </div>
      );
    }
  }

  const stripPostCode = (e) => {
    const emptyAddress = [];
    const value = e.target.value;
    e.preventDefault();
    const postcodeInput = value ? value.trimStart() : '';
    change(quickFindPostCodeName, postcodeInput);

    if (postcodeInput.length > 2) {
      requestAddress(postcodeInput);
    } else {
      clearRequestedAddress(emptyAddress);
    }
  };

  const inputProps = {
    name: quickFindPostCodeName,
    onChange: stripPostCode,
    value: quickFindPostCodeValue ? quickFindPostCodeValue : '',
    datae2e: quickFindPostCodeE2E,
    placeholder: getLocalizedString('postcodeInput.label.addressFinderMsg')
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => {
    const { company, address, postcode, city } = suggestion;
    const companyName = company ? `${company}, ` : '';
    const addressLine = address && suggestion.address.join(', ');
    return <div className="react-autosuggest__suggestions">
      <span>{`${companyName}${addressLine}, ${city}, `}<b>{postcode}</b></span>
    </div>;
  };

  const onSuggestionsFetchRequested = () => '';

  const onSuggestionsClearRequested = () => [];
  return (
    <div className="AddressFormPostCode">
      <div className="form-group AddressFormPostCode_quickFinder">
        <div className="row col-xs-12">
          <SvgLoader xlinkHref={ `#addressFinder-icon` } className="AddressFormPostCode_quickFinder_logo"/>
          <label className="control-label AddressFormPostCode_formGroup_label--textBold">
            { getLocalizedString('postcodeInput.label.addressFinder') }
          </label>
        </div>
        <div className="AddressFormPostCode_quickFinder_Content row col-xs-12">
          <Autosuggest
            id="addressFinder"
            onSuggestionSelected={ onFindPostCode }
            suggestions={ addressList }
            onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
            onSuggestionsClearRequested={ onSuggestionsClearRequested }
            getSuggestionValue={ getSuggestionValue }
            renderSuggestion={ renderSuggestion }
            inputProps={ inputProps }
          />
        </div>
      </div>
      {showAddressEntrySection()}
      {showEnterManuallyBlock()}
      {errorMessage
        ? <div className="row col-xs-12 AddressFormPostCode_message AddressFormPostCode_message_notFoundWarning">
          { getLocalizedString('postcodeInput.error.unrecognisedAddressPart1') }{ getLocalizedString('postcodeInput.error.unrecognisedAddressPart2') }.
        </div> : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const componentState = state.getIn(['ui', 'postCode', ownProps.selector]);
  const compState = componentState ? componentState.toJS() : {};

  if (ownProps.isManuallyEntered && Object.keys(compState).length) {
    compState.isManuallyEntered = true;
  }

  return compState;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  change: (name, value) => dispatch(change(ownProps.form, name, value)),
  requestAddress: (postCode) => dispatch(requestAddressGen(ownProps.selector, postCode)),
  requestAddressForId: (postcodeId) => dispatch(requestFullAddress(ownProps.form, ownProps.selector, ownProps.fieldNamePrefix, postcodeId)),
  toggleShowManually: (shouldEnterManually) => dispatch(toggleEnterManually(ownProps.selector, shouldEnterManually)),
  clearRequestedAddress: (address) => dispatch(requestAddressSuccess(ownProps.selector, address))
});

export default connect(mapStateToProps, mapDispatchToProps)(localState(PostcodeInput));
