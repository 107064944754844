import { createAction } from 'redux-actions';

// CMS PAGE DATA
export const LOAD_CMS_PAGE_DATA = 'cmsActions.LOAD_CMS_PAGE_DATA';

export const loadCmsPageData = (cmsParameters) => ({
  type: LOAD_CMS_PAGE_DATA,
  cmsParameters
});

export const LOAD_CMS_PAGE_DATA_SUCCESS = 'cmsActions.LOAD_CMS_PAGE_DATA_SUCCESS';

export const loadCmsPageDataSuccess = (cmsParameters, pageData) => ({
  type: LOAD_CMS_PAGE_DATA_SUCCESS,
  cmsParameters,
  pageData
});

export const LOAD_CMS_PAGE_DATA_FAILURE = 'cmsActions.LOAD_CMS_PAGE_DATA_FAILURE';

export const loadCmsPageDataFailure = createAction(LOAD_CMS_PAGE_DATA_FAILURE);

// CMS OFFERS
export const REQUEST_CMS_OFFERS = 'cmsOffers.REQUEST_CMS_OFFERS';

export const requestCmsOffers = (query) => ({
  type: REQUEST_CMS_OFFERS,
  query
});

export const REQUEST_CMS_OFFERS_SUCCESS = 'cmsOffers.REQUEST_CMS_OFFERS_SUCCESS';

export const requestCmsOffersSuccess = (query, results) => ({
  type: REQUEST_CMS_OFFERS_SUCCESS,
  query,
  results
});

// CMS SKUS DATA
export const REQUEST_SKUS_DATA = 'cmsOffers.REQUEST_SKUS_DATA';

export const requestSkusData = (skusString, skus) => ({
  type: REQUEST_SKUS_DATA,
  skusString,
  skus
});

export const SUCCESS_SKUS_DATA = 'cmsOffers.SUCCESS_SKUS_DATA';

export const successSkusData = (skusString, results) => ({
  type: SUCCESS_SKUS_DATA,
  skusString,
  results
});

export const REQUEST_CMS_STOCK_INFO = 'REQUEST_CMS_STOCK_INFO';
export const requestCmsStockInfo = createAction(REQUEST_CMS_STOCK_INFO);

export const RECEIVE_CMS_STOCK_INFO = 'RECEIVE_CMS_STOCK_INFO';
export const receiveCmsStockInfo = createAction(RECEIVE_CMS_STOCK_INFO);

export const UPDATE_CMS_APPLIED_DISCOUNT_INFO = 'UPDATE_CMS_APPLIED_DISCOUNT_INFO';
export const receiveCmsAppliedDiscountInfo = createAction(UPDATE_CMS_APPLIED_DISCOUNT_INFO);
