import React from 'react';
import { Map } from 'immutable';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import SvgLoader from 'client/components/svg/SvgLoader';

import HTMLComponent from '../htmlComponent/HTMLComponent';
import CmsFAQHeader from '../cmsFAQHeader/CmsFAQHeader';

if (process.browser) {
  require('./CmsFaqPanel.scss');
}
class CmsFaqPanel extends React.Component {
  renderQnA = (qna, i) => {
    return (
      <Panel eventKey={ `${i}` }>
        <Panel.Heading>
          <Panel.Title toggle>
            {qna.get('question')}
            <SvgLoader xlinkHref="#down-arrow" className="CmsFaqPanel_detailIcon"/>
          </Panel.Title>
        </Panel.Heading>
        { this.renderAnswer(qna.get('answer')) }
      </Panel>
    );
  }

  renderAnswer (answer) {
    const paragraph    = typeof answer === 'string' ? answer : answer.get('paragraph', null); // eslint-disable-line no-multi-spaces
    const paragraphCss = typeof answer === 'string' ? null   : answer.get('paragraphCss', Map()).toJS(); // eslint-disable-line no-multi-spaces
    const html         = typeof answer === 'string' ? null   : answer.get('html', null); // eslint-disable-line no-multi-spaces

    return (
      <Panel.Body collapsible>
        { this.renderHtmlOrPara(html, paragraph, paragraphCss) }
      </Panel.Body>
    );
  }

  renderHtmlOrPara (html, paragraph, paragraphCss) {
    if (html) {
      return <HTMLComponent html={ html }/>;
    } else if (paragraph) {
      return <p className="ContentPageText" style={ paragraphCss }>{ paragraph }</p>;
    }
    return null;
  }

  render () {
    const data = this.props.data;
    const heading = data.get('heading');
    const headingCss = data.get('headingCss', Map()).toJS();
    const faq = data.get('faq', []);
    // render heading and go over faq
    return (
      <div className="Product-faq">
        { heading ? <h1 className="CmsFaqPanel__heading" style={ headingCss }>{ heading }</h1> : null}
        <div className="CmsHeader">
          { heading && (heading.endsWith('FAQ') || heading.endsWith('FAQs'))
            ? <CmsFAQHeader pageUrl = { heading } />
            : null }
        </div>
        <div className="CmsFaqPanel">
          <PanelGroup accordion defaultActiveKey="0">
            { faq.map(this.renderQnA) }
          </PanelGroup>
        </div>
      </div>
    );
  }
}

export default CmsFaqPanel;
