import { ACCOUNT, PAYPAL, CARD } from 'shared/constants/braintree';
import { PAYMENT_OPTIONS_WRAPPER, PAY_BY_ACCOUNT, PAY_BY_APPLE_PAY, PAY_BY_EXISTING_CARD, PAY_BY_GOOGLE_PAY, PAY_BY_NEW_CARD, PAY_BY_PAYPAL } from '../PaymentMethod/constants';

export const getPaymentMethodConstant = (paymentMethod, savedCardsCount) => {
  const cardPayment = savedCardsCount === 0 ? PAY_BY_NEW_CARD : PAY_BY_EXISTING_CARD;
  const accountOrCard = paymentMethod === ACCOUNT ? PAY_BY_ACCOUNT : cardPayment;
  const paymentMethodConstant = paymentMethod === PAYPAL ? PAY_BY_PAYPAL : accountOrCard;
  return paymentMethodConstant;
};

export const getPaymentMethod = (paymentMethod) => {
  const accountOrCard = paymentMethod === PAY_BY_ACCOUNT ? ACCOUNT : CARD;
  const defaultPaymentMethod = paymentMethod === PAY_BY_PAYPAL ? PAYPAL : accountOrCard;
  return defaultPaymentMethod;
};

export function filterPaymentOptions (isApplePayEnabled, isGooglePayEnabled) {
  return PAYMENT_OPTIONS_WRAPPER.filter((option) =>
    !((option.paymentType === PAY_BY_GOOGLE_PAY && !isGooglePayEnabled) ||
      (option.paymentType === PAY_BY_APPLE_PAY && !isApplePayEnabled))
  );
}
