import React, { useEffect, useState, useMemo } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getZoroFormatDate } from 'client/utils/zoroDateFormatUtils';
import { getLocalizedStringWithParam } from 'localization/localizer';
import moment from 'moment';
import { ACTIVE } from 'shared/constants/account';

export const isRequestWithinFiveDays = (requestDate) => {
  if (!requestDate) return true;
  const currentDate = moment();
  const daysDifference = moment(requestDate).diff(currentDate, 'days');
  return daysDifference >= 0 && daysDifference <= 5;
};

export const RequestCreditLimitIncrease = ({ tradeAccount, cartPrice, handleRequestLimitIncrease, requestCreditLimitData, accountStatusMessage }) => {
  const primaryContact = tradeAccount ? tradeAccount.getIn(['primaryContact', 'email']) : '';
  const primaryContactStatus = tradeAccount ? tradeAccount.getIn(['primaryContact', 'status']) : '';
  const requestCreditLimitAdminEmail = useMemo(() => requestCreditLimitData ? requestCreditLimitData.email : null, [requestCreditLimitData]);
  const internalRequestDate = tradeAccount ? tradeAccount.getIn(['internalLimitRequestDate']) : moment();
  const showRequestCreditLimitButton = tradeAccount ? tradeAccount.getIn(['showRequestCreditLimitButton'], !isRequestWithinFiveDays(internalRequestDate)) : !isRequestWithinFiveDays(internalRequestDate);
  const [internalLimitRequestDate, setInternalLimitRequestDate] = useState(internalRequestDate);
  const [showRequestLimitMessage, setShowRequestLimitMessage] = useState(showRequestCreditLimitButton);
  const isPrimaryContactActive = primaryContactStatus === ACTIVE;

  useEffect(() => {
    if (requestCreditLimitData?.email) {
      setShowRequestLimitMessage(false);
      setInternalLimitRequestDate(requestCreditLimitData?.internalLimitRequestDate || moment());
    }
  }, [requestCreditLimitAdminEmail]);

  return (
    <div className="CreditLimitIncreaseRequestInfo" data-e2e="warning-alert">
      {accountStatusMessage?.requestIncreaseLimit
        ? <>
          {showRequestLimitMessage ? (
            <div className="CreditLimitIncreaseRequestInfo_Warning">
              <SvgLoader xlinkHref="#warning-circle" className="CreditLimitIncreaseRequestInfo_warning-circle" title="warning-circle"/>
              <span className="CreditLimitIncreaseRequestInfo_Message">
                <span className="CreditLimitIncreaseRequestInfo_heading">{`${accountStatusMessage.heading}: `}</span>
                {accountStatusMessage?.content}
                <span className="CreditLimitIncreaseRequestInfo_ClickHereText" onClick={ () => handleRequestLimitIncrease() }> here.</span>
              </span>
            </div>
          ) : (
            <div className="CreditLimitIncreaseRequestInfo_Success">
              <SvgLoader xlinkHref="#info-filled" className="CreditLimitIncreaseRequestInfo_info_icon" title="info-filled"/>
              <span className="CreditLimitIncreaseRequestInfo_Message">
                {getLocalizedStringWithParam('requestCreditLimitIncrease.request.sent.success.message', { requestLimitDate: getZoroFormatDate(internalLimitRequestDate) })}
                {isPrimaryContactActive ? <span className="CreditLimitIncreaseRequestInfo_EmailText">{primaryContact}</span> : null}
                {isPrimaryContactActive ? <>&nbsp;</> : null}for updates
              </span>
            </div>
          )}
        </>
        : <div className="CreditLimitIncreaseRequestInfo_Warning">
          <span><SvgLoader xlinkHref="#warning-circle" className="CreditLimitIncreaseRequestInfo_warning-circle" title="warning-circle"/></span>
          <span className="CreditLimitIncreaseRequestInfo_Message">
            <span className="CreditLimitIncreaseRequestInfo_heading">{`${accountStatusMessage?.heading}: `}</span>
            {accountStatusMessage?.content}
          </span>
        </div>
      }
    </div>
  );
};
