import find from 'lodash/find';

export class ActionSubscriber {
  constructor () {
    this.subscribedActions = [];
  }

  middleware = () => {
    return (next) => (action) => {
      const actionSubscriber = this._findAction(action.type);
      if (actionSubscriber) {
        actionSubscriber.beforeCallbacks.forEach((cb) => cb());
      }
      const nextValue = next(action);
      if (actionSubscriber) {
        actionSubscriber.afterCallbacks.forEach((cb) => cb());
      }
      return nextValue;
    };
  }

  _findAction (actionType) {
    return find(this.subscribedActions, { actionType });
  }

  _getActionSubscription (actionType) {
    const foundAction = this._findAction(actionType);
    if (foundAction) {
      return foundAction;
    }
    const actionSubscription = {
      actionType,
      beforeCallbacks: [],
      afterCallbacks: []
    };
    this.subscribedActions.push(actionSubscription);
    return actionSubscription;
  }

  on (actionType, beforeCallback, afterCallback) {
    if (beforeCallback) {
      this._getActionSubscription(actionType).beforeCallbacks.push(beforeCallback);
    }
    if (afterCallback) {
      this._getActionSubscription(actionType).afterCallbacks.push(afterCallback);
    }
    return () => {
      const actionSubscriber = this._findAction(actionType);
      actionSubscriber.beforeCallbacks = actionSubscriber.beforeCallbacks.filter((cb) => cb !== beforeCallback);
      actionSubscriber.afterCallbacks = actionSubscriber.afterCallbacks.filter((cb) => cb !== afterCallback);
    };
  }
}

export default new ActionSubscriber();
