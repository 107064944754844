
export const isValidQuantity = (quantity, qtyMultiplier) => (quantity % qtyMultiplier) === 0;

export const isValidQuantityWithMin = (quantity, minOrderQuantity) => quantity >= minOrderQuantity;

export const isValidQuantityWithMax = (quantity, maxOrderQuantity = null) => {
  if (maxOrderQuantity === null) {
    return true;
  }
  return quantity <= maxOrderQuantity;
};

export const isValidQuantityWithMinMaxMultiplier = (quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity) => {
  const isQtyMultiplier = isValidQuantity(quantity, qtyMultiplier);
  const isValidQuantityMin = isValidQuantityWithMin(quantity, minOrderQuantity);
  const isValidQuantityMax = isValidQuantityWithMax(quantity, maxOrderQuantity);
  return isQtyMultiplier && isValidQuantityMin && isValidQuantityMax;
};
