import { createAction } from 'redux-actions';

export const INITIALIZE_APPLE_PAY = 'payByApplePay.INITIALIZE_APPLE_PAY';
export const initializeApplePay = createAction(INITIALIZE_APPLE_PAY);

export const SET_DETAILS = 'payByApplePay.SET_DETAILS';
export const setDetails = createAction(SET_DETAILS);

export const SET_DATA = 'payByApplePay.SET_DATA';
export const setData = createAction(SET_DATA);

export const PAY_BY_APPLE_PAY = 'payByApplePay.PAY';
export const payByApplePay = createAction(PAY_BY_APPLE_PAY);
