import { SET_UNSUBSCRIBE } from '../actions/unsubscribeActions';

const unsubscribeState = (state = 'pending', action) => {
  switch (action.type) {
    case SET_UNSUBSCRIBE:
      return action.status;
    default:
      return state;
  }
};

export default unsubscribeState;
