import { ApiRequest } from 'shared/utils/apiUtils';

export const removeProductFromCart = (id) => {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/cart/products?SKU=${encodeURIComponent(id)}`
  });

  return req.executeAndGetBody();
};

export const removeBundleProductFromCart = (body) => {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/cart/bundles`,
    body
  });

  return req.executeAndGetBody();
};

export const getCart = (discountCode, type, eligibleForDeliveryOptions, addressId = null) => {
  const query = discountCode ? { discountCode, type, eligibleForDeliveryOptions, ...(addressId ? { addressId } : {}) } : { eligibleForDeliveryOptions, ...(addressId ? { addressId } : {}) };
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/user-cart`,
    query
  });

  return req.executeAndGetBody();
};

export const addProductToCart = (body) => {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/cart/add-products',
    body
  });

  return req.executeAndGetBody();
};

export const getCartQuantity = () => {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/cart/quantity'

  });

  return req.executeAndGetBody();
};

export const setOrderLineQuantity = (body) => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/cart/products',
    body: body
  });
  return req.executeAndGetBody();
};

export const setBundleQuantity = (body) => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/cart/bundles',
    body
  });

  return req.executeAndGetBody();
};

export const applyDiscountCodeToCart = (discountCode, addressId = null) => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/cart/applyDiscountCode',
    body: {
      discountCode: discountCode ? discountCode.toUpperCase() : discountCode,
      addressId
    }
  });

  return req.executeAndGetBody();
};

export const removeDiscountCode = () => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/cart/removeDiscountCode'
  });

  return req.executeAndGetBody();
};
