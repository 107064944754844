import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { Empty } from 'client/components/elements/empty/Empty';
if (process.browser) {
  require('./primaryFormTitle.scss');
}

const PrimaryFormTitle = ({ titleText, iconName = '' }) => {
  return (
    <h1 className="PrimaryFormTitle">
      {
        iconName ? <SvgLoader xlinkHref = { iconName } className = "TitleSvg"/> : <Empty/>
      }
      { `${iconName ? ' ' : ''}${titleText}` }
    </h1>
  );
};

export default PrimaryFormTitle;
