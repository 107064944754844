//
// THESE FILE IS FOR THE OLD FAMILY PAGE. DO NOT USE IT
//
// TODO: REMOVE THIS FILE AFTER NEW PRODUCT PAGE GOES LIVE (see isNewFamilyPageEnabled)

import Immutable from 'immutable';

import { handleActions } from 'redux-actions';
import { calculateDiscountPercentage } from '../utils/discountUtils';

import {
  UPDATE_PRICE_WITH_BREAK_PRICE_ON_PRODUCT_FAMILY_TABLE,
  SORT_FEEFO_REVIEWS,
  SCROLL_TO_PRODUCT_FAMILY_TABLE,
  ON_SEARCH_KEYWORD_CHANGE
} from 'client/actions/productFamilyActions';
import { RESET_STORE } from 'client/actions/appActions';

export const defaultState = Immutable.fromJS({ isScrollToProductFamilyTableSection: false });

const getProductReviewsSortedByRatingAndDate = (reviews, sortBy) => {
  const reviewsArr = reviews && reviews.toJS();
  const reviewSorted = reviewsArr.sort((a, b) => {
    const byRating = a.customerRating - b.customerRating;
    const byDate = new Date(a.reviewDate) - new Date(b.reviewDate);

    if (byRating !== 0) {
      return sortBy === 'rating' ? byRating : -byRating;
    }

    return -byDate;
  });

  return Immutable.fromJS(reviewSorted);
};

export const actionMap = {
  [SORT_FEEFO_REVIEWS]: (state = defaultState, action) => {
    const sortedReviews = getProductReviewsSortedByRatingAndDate(action.reviews, action.sortBy);
    const oldState = state.get(action.familyId) ? state.get(action.familyId).toJS() : {};
    const newState = state.set(action.familyId, Immutable.fromJS({
      ...oldState,
      productFamilyData: {
        ...oldState.productFamilyData,
        family: {
          ...oldState.productFamilyData.family,
          feefoReviews: {
            ...oldState.productFamilyData.family.feefoReviews,
            familyReviews: sortedReviews
          }
        }
      }
    }));
    return newState;
  },

  [UPDATE_PRICE_WITH_BREAK_PRICE_ON_PRODUCT_FAMILY_TABLE]: (state = defaultState, { type, payload }) => {
    if (!payload.breakPriceValue) {
      return state;
    }

    const family = state.get(`${payload.familyId}`) || Immutable.fromJS(state.get(payload.familyId));
    if (!family) {
      return state;
    }

    const variantIndex = family.get('productFamilyProductsData', []).findIndex((variant) => variant.get('sku') === payload.sku);
    const productVariantIndex = family.getIn(['productVariants', 'products'], []).findIndex((variant) => {
      return variant.get('sku') === payload.sku;
    });
    if (variantIndex < 0 || productVariantIndex < 0) {
      return state;
    }

    const familyId = state.get(`${payload.familyId}`) ? `${payload.familyId}` : payload.familyId;

    const newState = state
      .setIn([
        familyId,
        'productFamilyProductsData',
        variantIndex,
        'price',
        'packPriceWithVat'
      ],
      Immutable.fromJS(payload.breakPriceValue))
      .setIn([
        familyId,
        'productFamilyProductsData',
        variantIndex,
        'price',
        'finalPromoDiscount'
      ],
      Immutable.fromJS(calculateDiscountPercentage(
        state.getIn([
          familyId,
          'productFamilyProductsData',
          variantIndex,
          'price',
          'promoDiscount'
        ]),
        payload.breakDiscount * 100
      )))
      .setIn([
        familyId,
        'productVariants',
        'products',
        productVariantIndex,
        'price',
        'packPriceWithVat'
      ],
      Immutable.fromJS(payload.breakPriceValue))
      .setIn([
        familyId,
        'productVariants',
        'products',
        productVariantIndex,
        'price',
        'finalPromoDiscount'
      ],
      Immutable.fromJS(calculateDiscountPercentage(
        state.getIn([
          familyId,
          'productVariants',
          'products',
          productVariantIndex,
          'price',
          'promoDiscount'
        ]),
        payload.breakDiscount * 100
      )));
    return newState;
  },
  [ON_SEARCH_KEYWORD_CHANGE]: (state = defaultState, action) => {
    const { searchKeyword = '', familyId } = action.payload;
    return state.setIn([familyId, 'searchKeyword'], searchKeyword);
  },
  [SCROLL_TO_PRODUCT_FAMILY_TABLE]: (state = defaultState, action) => {
    return state.set('isScrollToProductFamilyTableSection', action.payload);
  },
  [RESET_STORE]: (state = defaultState, action) => {
    return defaultState;
  }

};

export default handleActions(actionMap, defaultState);
