import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Modal from 'react-bootstrap/lib/Modal';
import Helmet from 'react-helmet';
import { ASSETS_S3_LOCATION } from 'shared/constants/images';
import NewHeader from 'client/components/elements/NewHeader/Header';
import Footer from 'client/components/elements/footer/Footer';
import DialogContainer from 'client/components/elements/dialogContainer/DialogContainer';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { StickyContainer } from 'react-sticky';
import { ToastContainer, Slide } from 'react-toastify';
import { SecondaryLink, PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import _isEqual from 'lodash/isEqual';
import { getPages } from 'shared/constants/seo';
import { getMetaTagsByUrl } from 'client/utils/seoUtils';
import BasketConfirmationDropdown from 'client/components/elements/basketConfirmationDropdown/BasketConfirmationDropdown';
import { ZORO_META_TITLE, ZORO_META_SITE_NAME } from 'shared/constants/common';

if (process.browser) {
  require('./Root.scss');
}

/*
 Root component needs to be class-based in order for hot reloading to work.
 This is until they fix babel -react-transform plugin
 */
export default class Root extends Component {
  static propTypes = {
    url: PropTypes.string,
    children: PropTypes.object,
    cart: PropTypes.object,
    user: PropTypes.object,
    categories: PropTypes.object,
    dialogs: PropTypes.object,
    cookiePolicy: PropTypes.object,
    logoutUser: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    showLoginDialog: PropTypes.func,
    categoryBrowsingStarted: PropTypes.func,
    addToCart: PropTypes.func,
    screenName: PropTypes.string,
    query: PropTypes.object,
    setMobileFiltersVisibility: PropTypes.func,
    mobileFilters: PropTypes.object,
    sale: PropTypes.object,
    toggleDropdownMenu: PropTypes.func,
    dispatchToggleAllCategoryPage: PropTypes.func,
    showDropdownMenu: PropTypes.bool,
    mouseleaveEventFired: PropTypes.bool,
    clickEventFired: PropTypes.bool,
    abSelectedHeader: PropTypes.string,
    publicHolidays: PropTypes.array
  };

  componentWillMount () { // eslint-disable-line react/no-deprecated
    const {
      loadCartQuantity,
      checkDiscountCodeDialog,
      requestPublicHolidays,
      publicHolidays = [],
      reloadAccountDetails
    } = this.props;
    loadCartQuantity();
    if (!process.browser || (publicHolidays && Array.isArray(publicHolidays) && !publicHolidays.length)) {
      requestPublicHolidays();
    }
    checkDiscountCodeDialog(this.props.dialogs.getIn(['discount', 'code']));
    reloadAccountDetails();
  }

  shouldComponentUpdate (nextProps) {
    if (!_isEqual(nextProps, this.props)) {
      return true;
    }
    return false;
  }

  render () {
    const {
      children,
      dialogs,
      screenName,
      setMobileFiltersVisibility,
      mobileFilters,
      url,
      hideDiscountCodeDialog,
      cart,
      location,
      isBasketConfirmationDropdown,
      updateDataLayer,
      totalProductsCount,
      params
    } = this.props;

    const toggleMobileFilters = () => {
      setMobileFiltersVisibility(!mobileFilters.get('isOpen'));
    };

    const defaultMetaTags = [
      { 'name': 'description', 'content': getLocalizedStringWithParam('html.description', { totalProductsCount }) },
      { 'name': 'keywords', 'content': getLocalizedString('html.keywords') },

      { 'name': 'itemprop:name', 'content': ZORO_META_TITLE },
      { 'name': 'itemprop:description', 'content': getLocalizedStringWithParam('html.description', { totalProductsCount }) },
      { 'name': 'itemprop:image', 'content': `${ASSETS_S3_LOCATION}/mimg/assets/logos/zoro-logo-meta-tag.jpg` },

      { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
      { 'property': 'og:title', 'content': ZORO_META_TITLE },
      { 'property': 'og:description', 'content': getLocalizedStringWithParam('html.description', { totalProductsCount }) },
      { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/' },
      { 'property': 'og:type', 'content': 'website' },
      { 'property': 'og:image', 'content': `${ASSETS_S3_LOCATION}/mimg/assets/logos/zoro-logo-meta-tag.jpg` },

      { 'name': 'twitter:site', 'content': '@zoro_uk' },
      { 'name': 'twitter:creator', 'content': '@zoro_uk' },
      { 'name': 'twitter:title', 'content': ZORO_META_TITLE },
      { 'name': 'twitter:description', 'content': getLocalizedStringWithParam('html.description', { totalProductsCount }) },
      { 'name': 'twitter:card', 'content': 'summary' },
      { 'name': 'twitter:image', 'content': `${ASSETS_S3_LOCATION}/mimg/assets/logos/zoro-logo-meta-tag.jpg` }
    ];

    const contentUrlRegex = /info\//;
    const isNotIndexed = !['/', '', '/reviews'].includes(url) && !contentUrlRegex.test(url);
    if (isNotIndexed) {
      defaultMetaTags.push(
        { 'name': 'ROBOTS', 'content': 'NOINDEX, NOFOLLOW' }
      );
    }

    const page = getPages(url, totalProductsCount, params);
    const pageTitle = page ? page.title : getLocalizedString('html.title');
    const pageMetaTags = getMetaTagsByUrl(url, defaultMetaTags, totalProductsCount, params);

    return (
      <div className={ `wrapper ${isBasketConfirmationDropdown ? 'Root_withBasket' : ''}` }>
        <div className="content">
          <StickyContainer className={ `Root ${screenName || ''}` }>
            <Helmet
              title={ pageTitle }
              meta={ pageMetaTags }
            />
            {Object.keys(location?.query || {}).length === 0 ? <link rel='canonical' href={ `${this.props.websiteUrl}${location?.pathname || ''}` }></link> : null}
            <NewHeader { ...this.props } toggleMobileFilters={ toggleMobileFilters } />
            { isBasketConfirmationDropdown
              ? <div className={ `HeaderB_masthead container HeaderB_Container_StickyMenu' ${this.props.csAgentName ? 'CsAgentSticky' : ''}` }>
                <BasketConfirmationDropdown /> </div> : null }
            <ToastContainer
              transition={ Slide }
              className="Root_ToastContainer"
              limit = { 3 } />
            {
              this.props.csAgentName ? <div className="Root_csAgentBanner"></div> : null
            }
            <div className="stickyMenuContainer">
              <Grid>
                { children }
              </Grid>
            </div>
            <Modal show={ dialogs.getIn(['discount', 'show']) } onHide={ () => hideDiscountCodeDialog(dialogs.getIn(['discount', 'code'])) } className="Root_Modal">
              <Modal.Header closeButton>
                <h1>{ cart.getIn(['discount', 'headMessage'], '') }</h1>
              </Modal.Header>
              <Modal.Body>
                <div className="Root_Modal_Message">{ cart.getIn(['discount', 'bodyMessage'], '') }</div>
                <div className="Root_Modal_actions">
                  <PrimaryLink text={ getLocalizedString('product.addToBasket.continueShopping') } className="Root_Modal_actions-continueShopping" onClick={ () => hideDiscountCodeDialog(dialogs.getIn(['discount', 'code'])) }/>
                  <SecondaryLink to="/cart" text={ getLocalizedString('product.addToBasket.goToBasket') } className="Root_Modal_actions-toCart" onClick={ () => hideDiscountCodeDialog(dialogs.getIn(['discount', 'code'])) } isExternalLink />
                </div>
              </Modal.Body>
            </Modal>

            <DialogContainer dialogs={ dialogs } location={ location } updateDataLayer = { updateDataLayer }/>
            <div className="cromwellAdobeTargetMbox"/>
          </StickyContainer>
        </div>
        <Footer screenName = { screenName }/>

      </div>
    );
  }
}
