import { fromJS } from 'immutable';
import {
  ADD_PRODUCT_BUNDLE_LINE_ITEMS,
  PRODUCT_BUNDLE_LINE_UPDATING_QUANTITY,
  CLEAR_PRODUCT_BUNDLE_LINES
} from 'client/actions/productBundleLineActions';

const defaultState = fromJS({});
const HYPHEN = '-';

const productLineReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_BUNDLE_LINES:
      return defaultState;
    case ADD_PRODUCT_BUNDLE_LINE_ITEMS:
      return fromJS(action.payload);
    case PRODUCT_BUNDLE_LINE_UPDATING_QUANTITY:
      return state.setIn(
        [
          [action.id, action.skuOfPrimaryProduct].join(HYPHEN),
          'isUpdatingQuantity'
        ], action.flag);
    default:
      return state;
  }
};

export default productLineReducer;
