import React from 'react';
import { connect } from 'react-redux';
import {
  LOGIN_VIEW,
  BUSINESS_ACCOUNT_IMAGE,
  INDIVIDUAL_ACCOUNT_IMAGE,
  USER_TYPE_INDIVIDUALS,
  USER_TYPE_BUSINESS,
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CREATE_INDIVIDUAL_ACCOUNT_VIEW
} from '../constants';
import { setUserType, setView } from 'client/actions/loginAndRegistration';
import get from 'lodash/get';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import SvgLoader from 'client/components/svg/SvgLoader';
import { PrimaryButton } from 'client/components/controls/StyledForms';
import ZoroBenefits from '../zoroBenefits';

if (process.browser) {
  require('./welcomeToZoroForm.scss');
}

const WelcomeToZoroForm = (props) => {
  const {
    dispatchSetView,
    userType,
    dispatchSetUserType
  } = props;
  return (<div className="col-xs-12 WelcomeToZoro_container">
    <div className="hidden-xs hidden-sm col-xs-5 WelcomeToZoro_leftImageContainer">
      <ZoroImage
        className="WelcomeToZoro_leftImage"
        src={ userType === USER_TYPE_INDIVIDUALS ? INDIVIDUAL_ACCOUNT_IMAGE : BUSINESS_ACCOUNT_IMAGE } />
      <ZoroBenefits userType={ userType } />
    </div>
    <div className="col-xs-12 col-md-7 WelcomeToZoro">
      <div className="NewLoginForm_logo">
        <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
      </div>
      <div className="col-xs-12 BusinessAccountRegistrationForm_mainContainer WelcomeToZoro_userTypeContainer">
        <h1 className="WelcomeToZoro_title">
            Welcome to Zoro
        </h1>
        <form className="WelcomeToZoro_form">
          <label onClick={ () => dispatchSetUserType(USER_TYPE_BUSINESS) } htmlFor="b2bType" className="WelcomeToZoro_userTypeCard col-xs-12">
            <input data-e2e="userType_Business" className="WelcomeToZoro_userTypeInput col-xs-2" id="b2bType" type="radio" checked={ userType === USER_TYPE_BUSINESS } name="userType" />
            <div className="WelcomeToZoro_userTypeCard-textContainer col-xs-10">
              <div className="WelcomeToZoro_userTypeCard-title">
                    I’m buying for a business
              </div>
              <div className="WelcomeToZoro_userTypeCard-text">
                    I am looking to open business account for me and my team members
              </div>
            </div>
          </label>
          <label onClick={ () => dispatchSetUserType(USER_TYPE_INDIVIDUALS) } htmlFor="b2cType" className="WelcomeToZoro_userTypeCard col-xs-12">
            <input data-e2e="userType_Individual" className="WelcomeToZoro_userTypeInput col-xs-2" id="b2cType" type="radio" checked={ userType === USER_TYPE_INDIVIDUALS } name="userType" />
            <div className="WelcomeToZoro_userTypeCard-textContainer col-xs-10">
              <div className="WelcomeToZoro_userTypeCard-title">
                    I’m buying for myself
              </div>
              <div className="WelcomeToZoro_userTypeCard-text">
                    I’m not buying for a business or commercial reasons and want to open a standard account
              </div>
            </div>
          </label>
          <PrimaryButton
            datae2e="continue"
            className="WelcomeToZoro_continueBtn"
            onClick={ () => dispatchSetView(userType === USER_TYPE_INDIVIDUALS ? CREATE_INDIVIDUAL_ACCOUNT_VIEW : CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW) }
          >
                Continue
          </PrimaryButton>
        </form>
        <div className="WelcomeToZoro_alreadyHaveAnAccount">
            Already have an account? <span data-e2e="Log in" onClick={ () => dispatchSetView(LOGIN_VIEW) } className="WelcomeToZoro_backToLogin">Log in</span>
        </div>
      </div>
    </div>
  </div>);
};

export const mapStateToProps = (state, ownProps) => {
  const email = (ownProps) ? get(ownProps, 'email', '') : '';

  return {
    registrationError: state.getIn(['ui', 'registrationForm', 'registrationError']),
    view: state.getIn(['loginAndRegistration', 'view']),
    userType: state.getIn(['loginAndRegistration', 'userType'], USER_TYPE_BUSINESS),
    loginError: state.getIn(['ui', 'loginForm', 'error']),
    initialValues: {
      email
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetView: (view) => dispatch(setView({ view })),
  dispatchSetUserType: (userType) => dispatch(setUserType({ userType }))
});

export { WelcomeToZoroForm };
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeToZoroForm);
