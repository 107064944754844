import React from 'react';
import TapButton from 'client/components/controls/tapButton/TapButton';
import SvgLoader from 'client/components/svg/SvgLoader';
import MyAccountButton from 'client/components/elements/header/MyAccountButton';

if (process.browser) {
  require('client/components/elements/header/Header.scss');
}

const UserItem = ({ isLoggedInOrhasValidLongSessionToken, showLoginDialog, isLoggedIn, myAccountListVisible, toggleMyAccount, user }) => {
  if (isLoggedInOrhasValidLongSessionToken) {
    return (
      <MyAccountButton
        isLoggedIn={ isLoggedIn }
        myAccountListVisible = { myAccountListVisible }
        toggleMyAccount = { toggleMyAccount }
        loggedUser={ user }
      />
    );
  }
  return (
    <TapButton id="HeaderMenu.showLoginDialogButton" onTap={ showLoginDialog } styles="HeaderB_user-link nav-link" aria-label="Log in">
      <SvgLoader xlinkHref="#logged-out-icon" />
      <span className="HeaderB_user_loginText">Login / Register</span>
    </TapButton>
  );
};

export default UserItem;
