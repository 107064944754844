import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import { Link } from 'react-router';
import { getBannerData } from 'shared/utils/saleUtils';
import { Empty } from 'client/components/elements/empty/Empty';

if (process.browser) {
  require('./WideRibbon.scss');
}

const WideRibbon = ({ sale, isMobile }) => {
  const data = getBannerData(sale, 'WIDE_RIBBON');
  if (!data) {
    return <Empty />;
  }

  const text = isMobile && data.mobileText !== undefined ? data.mobileText : data.text;
  const css = isMobile && data.mobileCss !== undefined ? data.mobileCss : data.css;
  return (
    <div className="WideRibbon container">
      <Row>
        <Link className="WideRibbon_link" to={ data.link }>
          <div className="WideRibbon_text" style={ css }>{ text }</div>
        </Link>
      </Row>
    </div>
  );
};

WideRibbon.propTypes = {
  sale: PropTypes.object,
  isMobile: PropTypes.bool
};

export default WideRibbon;
