import React, { useEffect, useState } from 'react';
import { FlagProvider, UnleashClient } from '@graingerglobalonline/curly-eureka';
import { unleashSsrConfig } from 'shared/utils/unleashUtils';

const UnleashFlagProviderWrapper = ({ unleashConfig, isUnleashEnabled, children, prefetchedToggles, context }) => {
  const [client, setClient] = useState();

  useEffect(() => {
    if (client) {
      client.updateContext(context);
    }
  }, [JSON.stringify(context)]);

  if (!client && isUnleashEnabled) {
  // storageProvider needs to be passed for server side as server side dont have localstorage
    try {
      const ssrOptions = process.browser ? {} : unleashSsrConfig;
      const unleashClient = new UnleashClient({ ...unleashConfig, ...ssrOptions, bootstrap: prefetchedToggles });
      unleashClient.updateContext(context);
      setClient(unleashClient);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`UnleashFlagProviderWrapper - error while setting unleash client, Error ${err}`);
    }
  }
  return (
    isUnleashEnabled
      ? <FlagProvider unleashClient={ client }>
        {children}
      </FlagProvider>
      : children
  );
};

export default UnleashFlagProviderWrapper;
