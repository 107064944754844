import React from 'react';
import { connect } from 'react-redux';
import SvgLoader from 'client/components/svg/SvgLoader';
import { setView } from 'client/actions/loginAndRegistration';
import PrimaryContactDetailsForm from './primaryContactDetailsForm';
import CreditLineApplicationForm from './creditLineApplicationForm';
import {
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW,
  CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED
} from '../constants';
import FinanceContactForm from './financeContactForm';
import AdditionalUsersForm from './additionalUsersForm';
import FinalStepForm from './finalStepForm';
import FinalStepOnlyRegisterForm from './finalStepOnlyRegister';
import SuccesfullyRegistered from './succesfullyRegistered/index';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

if (process.browser) {
  require('./businessAccountRegistrationForm.scss');
}

export const ToolTipInfo = ({ title, text }) => {
  return <OverlayTrigger
    placement="right"
    delayShow={ 0 }
    delayHide={ 500 }
    overlay={ <Tooltip className="BusinessAccountRegistrationForm_toolTip">
      <div className="BusinessAccountRegistrationForm_toolTip-title">{title}</div>
      <div className="BusinessAccountRegistrationForm_toolTip-text">{text}</div>
    </Tooltip> }>
    <div className="BusinessAccountRegistrationForm_toolTip-icon">
      <SvgLoader xlinkHref="#info-filled"/>
    </div>
  </OverlayTrigger>;
};

const views = {
  [CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW]: () => <PrimaryContactDetailsForm />,
  [CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW]: () => <CreditLineApplicationForm />,
  [CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW]: () => <FinanceContactForm />,
  [CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW]: () => <AdditionalUsersForm />,
  [CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW]: () => <FinalStepForm />,
  [CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW]: () => <FinalStepOnlyRegisterForm />,
  [CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED]: () => <SuccesfullyRegistered />
};

const BusinessRegistrationForm = ({ view }) => {
  const isFinalStepForm = view === CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW;
  return <React.Fragment>
    { !isFinalStepForm ? <div className="NewLoginForm_logo">
      <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
    </div> : null }
    <div className={ !isFinalStepForm ? `col-xs-12 BusinessAccountRegistrationForm_mainContainer ${view === CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW ? 'BusinessAccountRegistrationForm_mainContainer-lessPadding' : ''}` : '' }>
      {view && views[view] ? views[view]() : null}
    </div>
  </React.Fragment>;
};

export const mapStateToProps = (state) => {
  return {
    view: state.getIn(['loginAndRegistration', 'view']),
    forgotPasswordError: state.getIn(['ui', 'forgotPasswordForm', 'error'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetView: (view) => dispatch(setView({ view }))
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessRegistrationForm);
