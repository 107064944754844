import { fromJS } from 'immutable';
import { EDIT_ADDRESS, ON_NEW_ADDRESS_CLICK } from '../../actions/addressActions';
import {
  REQUEST_ADDRESS_WITH_POSTCODE
} from 'client/actions/ui/postCodeActions';

const defaultState = fromJS({
  editAddressIndex: null,
  isDefault: null,
  isPostCodeSubmitted: false
});

const editAddressState = (state = defaultState, action) => {
  switch (action.type) {
    case EDIT_ADDRESS:
      return state
        .set('editAddressIndex', action.payload.editAddressIndex)
        .set('isDefault', action.payload.isDefault)
        .set('isPostCodeSubmitted', false);
    case ON_NEW_ADDRESS_CLICK:
      return state
        .set('editAddressIndex', null)
        .set('isDefault', null);
    case REQUEST_ADDRESS_WITH_POSTCODE:
      return state.set('isPostCodeSubmitted', true);
    default:
      return state;
  }
};

export default editAddressState;
