import React, { Fragment } from 'react';
import SvgLoader from '../../svg/SvgLoader';
import { Link } from 'react-router';

if (process.browser) {
  require('./toastWrapperComponent.scss');
}

const getToasterE2EAttribute = (type) => `${type || ''}-toaster`;

const toastWrapperComponent = (props) => {
  return (
    <div className="ToastWrapper" data-e2e={ getToasterE2EAttribute(props?.toastProps?.type) }>
      <div className="row">
        <div className="col-md-1 col-xs-1">
          <SvgLoader
            xlinkHref={ props.toastIcon }/>
        </div>
        <div className="col-md-11 col-xs-11 ToastWrapper_ToastMessage">
          {props.toastMessage}
        </div>
      </div>
    </div>
  );
};

const getToastMessage = (heading, message, link) => {
  return <Fragment>
    <div className = { `${message ? 'ToastWrapper_ToastMessage-heading' : 'ToastWrapper_ToastMessage-subHeading'}` }>
      { heading }
    </div>
    { message ? <div className = 'ToastWrapper_ToastMessage-subHeading'>
      { message }{ link ? <strong><Link to={ link }>here</Link></strong> : null}
    </div> : null }
  </Fragment>;
};

export default toastWrapperComponent;
export { getToastMessage };
