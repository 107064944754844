import { ApiRequest } from 'shared/utils/apiUtils';

export function getClientToken () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/braintree/clientToken'
  });

  return req.execute();
}

export function getPaymentMethods () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/braintree/paymentMethods'
  });

  return req.executeAndGetBody();
}

export function createNonce (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/braintree/createNonce',
    body
  });
  return req.executeAndGetBody();
}
export function updateDefaultPaymentMethod (body) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/braintree/paymentMethods/default',
    body
  });
  return req.executeAndGetBody();
}

export function updateSavedForFuturePaymentMethod (body) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/braintree/paymentMethods/savedForFuturePayment',
    body
  });
  return req.executeAndGetBody();
}

export function getAllSavedPaymentMethods () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/braintree/paymentMethods/saved'
  });

  return req.executeAndGetBody();
}

export function verifyCard (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/braintree/verifyCard',
    body
  });
  return req.executeAndGetBody();
}

export function saveCard (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/braintree/card',
    body
  });
  return req.executeAndGetBody();
}
