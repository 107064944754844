import React from 'react';
import _get from 'lodash/get';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';
import { PrimaryButton } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./AddToBasketButton.scss');
}

const AddToBasketButton = (props) =>
  <PrimaryButton
    { ...props }
    className={ `AddToBasketButton ${_get(props, 'buttonClassName', '')}` }
  >
    { props.showBasketIcon ? <span className="AddToBasketButton_BasketIcon">
      <SvgLoader
        className="AddToBasketButton_Svg"
        xlinkHref="#cart-icon-plus"
        title="add-to-basket"
      />
    </span> : null }
    <span className="AddToBasketButton_Text">
      { getLocalizedString('product.addToBasket.addToBasket') }
    </span>
  </PrimaryButton>;

export default AddToBasketButton;
