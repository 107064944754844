import PropTypes from 'prop-types';
import React from 'react';
import { BACKUP_IMAGE } from 'shared/constants/images';

const ZoroImage = (props) => {
  const {
    src,
    alt,
    className,
    title
  } = props;

  const isImageValid = (src) => {
    const promise = new Promise(resolve => {
      const img = document.createElement('img');
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
      img.src = src;
    });

    return promise;
  };

  const imgEl = React.useRef(null);
  React.useEffect(
    () => {
      isImageValid(src).then(isValid => {
        if (!isValid) {
          imgEl.current.src = BACKUP_IMAGE;
        }
      });
    },
    [src]
  );

  return (<img ref={ imgEl } src={ src } className={ className } title={ title } alt= { alt }/>);
};

ZoroImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default ZoroImage;
