import React from 'react';
import PropTypes from 'prop-types';
import TapButton from 'client/components/controls/tapButton/TapButton';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { connect } from 'react-redux';
import { formatPrice } from 'client/utils/priceUtils';
import TotalSaving from '../TotalSaving/TotalSaving';
import QuantityInput from '../quantityInput/quantityInput';
import { fromJS } from 'immutable';
import NewStockMessage from 'client/components/elements/newStockMessage/NewStockMessage';
import DeliveryOptionList from 'client/components/elements/deliveryOptions/DeliveryOptionList';
import flowRight from 'lodash/flowRight';
import { toggleDeliveryOptionAccordion } from 'client/actions/orderReviewActions';
import { hoverDeliveryOption } from 'client/actions/ui/toggleElementActions';
import QuantityErrorMessage from 'client/components/elements/quantityInput/quantityErrorMessage';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import { toastInfo } from 'client/actions/showNotificationsActions';
import SvgLoader from 'client/components/svg/SvgLoader';
import { copy } from 'client/components/elements/copyPopover/copyPopOver';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import NonRefundableWarning from 'client/components/elements/nonRerfundableWarning/NonRefundableWarning';
import { validateNonDeliverableStatus } from 'client/utils/validators';
import ScheduleOrderLine from 'client/components/elements/scheduledOrders/ScheduleOrderLine';
import { Price } from 'client/components/elements/productLine/Price';
import { mySubscriptionsUrlRegex, getMappedSubscriptionPriceProps, isCheckoutSubscriptionPage, isSubscribableProduct, isConfirmationsScreen } from 'client/components/elements/scheduledOrders/utils';
import { FREE_SHIPPING_OFFER_TYPE } from 'client/components/elements/productLine/constants.js';
import { AgeRestrictedBadge } from '../AgeRestrictedBadge/AgeRestrictedBadge';

if (process.browser) {
  require('./ProductLine.scss');
}

const stockMessageProps = (orderLine) => {
  const orderLinePrice = orderLine.get('orderLinePrice');
  const stockStatusToJS = orderLine.getIn(['stock', 'stockMessage'], fromJS({})).toJS();
  const productPrice = orderLinePrice.get('unitPriceWithVat');
  const productCurrency = orderLine.getIn(['price', 'currency'], 'GBP');
  const isForCart = true;
  const isSearchable = orderLine.get('isSearchable');

  return {
    productPrice,
    productCurrency,
    stockStatus: stockStatusToJS,
    isForCart,
    isSearchable
  };
};

class ProductLine extends React.PureComponent {
  render () {
    const {
      sku,
      isEditable,
      onQuantityChange,
      onRemove,
      isIncludingVat,
      quantity,
      isAvailable,
      hideStockMessages,
      hideSavingsLabels,
      productLineItems,
      hideRemoveButton,
      seeMoreOptions,
      toggleDeliveryOptionAccordion,
      changeDeliveryOption,
      eligibleProductCountForDeliveryOption,
      hoverOnOption,
      hoverOptionFlag,
      eligibleForDeliveryOptions,
      deliveryOptions,
      selectedDeliveryOption,
      isUpdatingDeliveryOption,
      toastInfo,
      nonRefundableType,
      shippingAddress,
      isSinglePageCheckout,
      isGoldOrAboveLoyaltyDiscount,
      isDiscountApplied,
      discountCode,
      offerType
    } = this.props;
    const { postalCode = '', addressId } = (shippingAddress && shippingAddress.toJS()) || {};
    const productLine = productLineItems.get(sku);
    if ((!productLine || !productLine.size) || (!productLineItems || !productLineItems.size)) {
      return <div className="ProductLine_loader"/>;
    }
    const isUpdatingQuantityFlag = productLine.get('isUpdatingQuantity');
    const productLinePrice = productLine.get('orderLinePrice');
    const unitPrice = productLinePrice.get(isIncludingVat ? 'unitPriceWithVat' : 'unitPriceWithoutVat');
    const linePrice = productLinePrice.get(isIncludingVat ? 'orderLinePriceWithVat' : 'orderLinePriceWithoutVat');
    const currency = productLine.getIn(['price', 'currency'], 'GBP') || 'GBP';
    const priceChange = productLine.get('priceChange', null);
    const price = productLine.get('price');
    const productUrl = productLine.get('productUrl');
    const unitPriceCurrency = formatPrice(currency, unitPrice);
    const qtyMultiplier = productLine.getIn(['price', 'qtyMultiplier'], 1);
    const minOrderQuantity = productLine.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
    const maxOrderQuantity = productLine.getIn(['stock', 'maxOrderQuantity'], null);
    const isQuantityValid = isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    const linePriceCurrency = formatPrice(currency, linePrice);
    const isScheduleOrderAvailable = productLine.get('isScheduleOrderAvailable', false);

    const name = productLine.get('name');
    const imageSrc = productLine.get('imageSrc');
    const isTotalSaving = isAvailable && !hideSavingsLabels && price && price.get('isInPromo') && !price.get('isFamilyPrice');
    const isproductLineHover = hoverOptionFlag && deliveryOptions.size;
    const isUpdatingOptionFlag = isUpdatingDeliveryOption && deliveryOptions.size;
    const isUpdatingFlag = isUpdatingQuantityFlag || isUpdatingOptionFlag;
    const nonDeliverableTo = productLine.getIn(['stock', 'nonDeliverableTo']);
    const strippedProduct = [{ nonDeliverableTo: nonDeliverableTo && nonDeliverableTo.toJS() }];
    const { validNonDeliverableStatus } = validateNonDeliverableStatus(postalCode, strippedProduct);
    const showNonDeliverableMessage = validNonDeliverableStatus && !!isSinglePageCheckout;
    const isAgeRestricted = productLine.get('ageRestricted', false);
    const showSubscriptionForm = isScheduleOrderAvailable && isSubscribableProduct(productLine) &&
      (isCheckoutSubscriptionPage() || isConfirmationsScreen()) && !isAgeRestricted;
    const isMySubscriptionsPage = process?.browser && typeof window !== 'undefined' && mySubscriptionsUrlRegex.test(window?.location.pathname);
    const priceProps = { linePriceCurrency, unitPriceCurrency, priceChange, isIncludingVat };
    const isFromBasketInterstitial = process?.browser && typeof window !== 'undefined' && window?.location?.pathname.startsWith('/added-to-basket');
    return (
      <div className={ `row ${isproductLineHover ? 'ProductLineRowHover' : 'ProductLineRow'}` }>
        <div className={ `col-md-12 ProductLine ${isUpdatingFlag ? 'ProductLine_loading' : ''} ${showNonDeliverableMessage ? 'ProductLine_nonDeliverableBG' : ''}` }
          data-e2e={ `ProductLine-${sku}` }>
          <div className='row'>
            <div className="col-xs-12 col-sm-7">
              <a href={ productUrl } rel="noopener noreferrer">
                <ZoroImage src={ imageSrc } alt={ name } title={ name } className="ProductLine_image col-xs-4"/>
              </a>
              <div className="ProductLine_info col-xs-8">
                <a
                  id={ `ProductLine_title-${sku}` }
                  className="ProductLine_title-sku"
                  href={ productUrl }>
                  { name }
                </a>
                <div className="ProductLine_title-skuId" onClick={ () => copy(sku, toastInfo) }>{ getLocalizedString('cart.cartOrderLine.skuLabel') }
                  { sku }
                  <SvgLoader xlinkHref="#copy-regular" className="ProductLine_copyIcon" title={ getLocalizedStringWithParam('copy.sku', { sku }) } />
                </div>
                {
                  !hideStockMessages
                    ? <NewStockMessage sku = { sku } { ...stockMessageProps(productLine) }/>
                    : null
                }
                {
                  showNonDeliverableMessage
                    ? <div className="DeliveryInfo" data-e2e="orderReview-nonDeliverableMessage">
                      <SvgLoader xlinkHref="red-alert" />
                      <span>
                        <span>{getLocalizedString('singlePageCheckout.non.deliverable.product.text.1')}</span>
                        <span>{getLocalizedString('singlePageCheckout.non.deliverable.product.text.2')}</span>
                      </span>
                    </div>
                    : null
                }
                {
                  nonRefundableType
                    ? <NonRefundableWarning nonRefundableType = { nonRefundableType }/>
                    : null
                }
                { isAgeRestricted ? <AgeRestrictedBadge/> : null}
              </div>
            </div>
            <div className="col-xs-6 col-sm-2">
              <div className="form-group ProductLine_QuantityInput" data-e2e={ `ProductLineQuantityInput-${sku}` }>
                { (isUpdatingQuantityFlag || isUpdatingOptionFlag) ? <div className="ProductLine_spinner"/> : null }
                <QuantityInput
                  disabled={ !isEditable }
                  setQuantity={ onQuantityChange }
                  removeProductLine={ onRemove }
                  product={ productLine }
                  quantity={ quantity }
                  unavailableStatus={ isAvailable || !isQuantityValid }
                  eligibleForDeliveryOptions={ eligibleForDeliveryOptions }
                  qtyMultiplier= { qtyMultiplier }
                  minOrderQuantity = { minOrderQuantity }
                  maxOrderQuantity = { maxOrderQuantity }
                  addressId = { addressId }
                  isFromBasketInterstitial={ isFromBasketInterstitial }
                />
                {
                  isEditable && !hideRemoveButton
                    ? <TapButton
                      dataE2e="removeProductLineButton"
                      styles="ProductLine_tapButton"
                      id={ `cart.orderLine.remove.${sku}` }
                      onTap={ () => onRemove(productLine, eligibleForDeliveryOptions) }>
                      { getLocalizedString('cart.orderLine.remove') }
                    </TapButton>
                    : null
                }
                { !isQuantityValid
                  ? <div className="ProductLine_QtyErrorMessage">
                    <QuantityErrorMessage
                      quantity= { quantity }
                      qtyMultiplier = { qtyMultiplier }
                      minOrderQuantity = { minOrderQuantity }
                      maxOrderQuantity = { maxOrderQuantity }
                    />
                  </div>
                  : null
                }
              </div>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 ProductLine_priceInfo">
              <Price { ...(isMySubscriptionsPage && !isGoldOrAboveLoyaltyDiscount ? getMappedSubscriptionPriceProps(priceProps) : priceProps) }/>
              <div className="ProductLine_totalSaving">
                {
                  isTotalSaving
                    ? <TotalSaving
                      totalSaving={ productLinePrice.get('totalSaving') }
                      vat={ price.get('vat') }
                      currency={ currency }
                      includeVat={ isIncludingVat }
                    /> : null
                }
              </div>
            </div>
            { !isDiscountApplied && discountCode && offerType !== FREE_SHIPPING_OFFER_TYPE
              ? <div className="col-sm-offset-2 col-xs-12">
                <div className="ProductLine_discountExcludeMsg">
                  <p><SvgLoader xlinkHref="#information-icon" className="infoIcon"/> {getLocalizedStringWithParam('cart.orderLine.discount.not.applied.message', { discountCode })}</p>
                </div>
              </div>
              : null }
          </div>
          {
            eligibleProductCountForDeliveryOption
              ? <DeliveryOptionList
                deliveryOptions={ deliveryOptions }
                sku={ sku }
                currency={ currency }
                seeMoreOptions={ seeMoreOptions }
                toggleDeliveryOptionAccordion={ toggleDeliveryOptionAccordion }
                selectedDeliveryOption={ selectedDeliveryOption }
                changeDeliveryOption={ changeDeliveryOption }
                eligibleProductCountForDeliveryOption={ eligibleProductCountForDeliveryOption }
                hoverDeliveryOption={ hoverOnOption }
                eligibleForDeliveryOptions={ eligibleForDeliveryOptions }
              /> : null
          }
          {
            showSubscriptionForm ? <ScheduleOrderLine
              sku={ sku }
              priceProps={{ ...priceProps, currency, vat: price.get('vat') }}
              orderQuantity={ quantity }
              isGoldOrAboveLoyaltyDiscount={ isGoldOrAboveLoyaltyDiscount }
            /> : null
          }
        </div>
      </div>
    );
  }
}

ProductLine.propTypes = {
  sku: PropTypes.string,
  isEditable: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  onRemove: PropTypes.func,
  includeVat: PropTypes.bool,
  quantity: PropTypes.number,
  isAvailable: PropTypes.bool,
  hideStockMessages: PropTypes.bool,
  hideSavingsLabels: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    productLineItems: state.get('productLineItems', fromJS({})),
    seeMoreOptions: state.getIn(['orderReview', 'showDeliveryOptions']),
    hoverOptionFlag: state.getIn(['ui', 'toggleElement', 'deliveryOptionHover']),
    isUpdatingDeliveryOption: state.getIn(['orderReview', 'isUpdatingDeliveryOption'], null),
    shippingAddress: state.getIn(['user', 'address', 'delivery'], fromJS({})),
    publicHolidays: state.get('publicHolidays', fromJS([])).toJS(),
    isGoldOrAboveLoyaltyDiscount: [2, 3].includes(state.getIn(['user', 'accountDetails', 'loyaltyDiscount', 'loyaltyTier'], 0))
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDeliveryOptionAccordion: flowRight(dispatch, toggleDeliveryOptionAccordion),
    hoverOnOption: flowRight(dispatch, hoverDeliveryOption),
    toastInfo: (message, position, duration) => dispatch(toastInfo(message, position, duration))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLine);
export { ProductLine };
