import React, { useCallback, useState } from 'react';
import { Link } from 'react-router';
import { getTruncatedTitle } from 'client/utils/recommendationUtils';
import ProductAddToCart from '../../productAddToCart/ProductAddToCart';
import {
  RecommendationBrandName,
  RecommendationReview,
  RecommendationDeliveryDetails,
  RecommendationPriceWithMultiplier,
  RecommendationPrice
} from '../baseComponents/index';
import { RecommendationFamilyPrice } from '../baseComponents/RecommendationFamilyPrice';
import NextStepsFamily from 'client/components/elements/recommendations/elements/NextStepsFamily';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import toTitleCase from 'to-title-case';

const TITLE_LENGTH = 19;
const TITLE_LENGTH_AT_HOVER = 50;

export const VerticalRecommendationProductCard = ({
  link,
  clickHandler,
  thumbnail,
  name,
  brandName,
  actionButtonProps,
  promoLabelProps = {},
  reviewProps = {},
  deliveryDetailsProps = {},
  quantityOfProps = {},
  priceWithMultiplierProps = {},
  priceProps = {},
  isFamilyProduct = false,
  familyPriceProps = {}
}) => {
  const [hoverEvent, setHoverEvent] = useState(false);
  const setHoverEventToTrue = useCallback(() => setHoverEvent(true), []);
  const setHoverEventToFalse = useCallback(() => setHoverEvent(false), []);

  return (
    <div
      className="VerticalRecommendationProductCard"
      onMouseEnter={ setHoverEventToTrue }
      onMouseLeave={ setHoverEventToFalse }
    >
      <span title={ getTruncatedTitle(toTitleCase(name), hoverEvent ? TITLE_LENGTH_AT_HOVER : TITLE_LENGTH) }>
        <div>
          <Link to={ link } onClick={ clickHandler }>
            <div className="productContainer">
              <div className="summary">
                <div className="productImgContainer">
                  <div className="RecommendationTile_Image">
                    <ZoroImage className="gtm_recommended_image" src={ thumbnail } alt={ name } />
                  </div>
                </div>
                <div className="productDescription">
                  <div className="productTitle">
                    {getTruncatedTitle(toTitleCase(name), TITLE_LENGTH)}
                  </div>
                  <RecommendationBrandName brandName={ brandName } />

                  {!isFamilyProduct ? (
                    <>
                      <RecommendationDeliveryDetails
                        { ...deliveryDetailsProps }
                      />
                      <RecommendationPrice { ...priceProps } />
                    </>
                  ) : (
                    <RecommendationFamilyPrice { ...familyPriceProps } />
                  )}

                </div>
              </div>

              {!isFamilyProduct ? (
                <div className="expanded-content">
                  <RecommendationDeliveryDetails { ...deliveryDetailsProps } />
                  <RecommendationReview { ...reviewProps } />
                  <RecommendationPrice { ...priceProps } />
                  <RecommendationPriceWithMultiplier
                    { ...priceWithMultiplierProps }
                  />
                </div>
              ) : null}
            </div>
          </Link>
        </div>
      </span>
      <div className="action-container">
        {!isFamilyProduct ? (
          <ProductAddToCart { ...actionButtonProps } basketDropDown />
        ) : (
          <NextStepsFamily { ...actionButtonProps } />
        )}
      </div>
    </div>
  );
};
