import React from 'react';
import { MobileSubCategoriesList } from './MobileSubCategoriesList';
import NewCategoriesItem from './NewCategoriesItem';
import { SubCategoriesList } from './SubCategoriesList';
import { getLocalizedString } from 'localization/localizer';

export const NewCategoryList = ({
  showSubCategories,
  isMobile,
  categories,
  allCategories,
  selectedCategoryId,
  categoryBrowsingStarted,
  setCategoryIdHandler,
  goBackHandler,
  ...props
}) => {
  return (
    <div className={ `row category-list-row` }>
      <div className="col-md-12">
        <div className="col-md-30">
          <ul className="category-list-categoryItems">
            {
              showSubCategories && isMobile
                ? <MobileSubCategoriesList categories={ categories }
                  goBackHandler={ goBackHandler }
                  selectedCategoryId={ selectedCategoryId } allCategories={ allCategories }/>
                : <React.Fragment>
                  <li className="category-list-item category_item_desktop">
                    <div>{ getLocalizedString('label.categories') }</div>
                    <a href="/shop/all-categories">{ getLocalizedString('label.view.all') }</a>
                  </li>
                  <li className="category-list-item category_item_mobile">
                    <div>{ getLocalizedString('label.shop.categories') }</div>
                  </li>
                  { categories.map((category) => (
                    <li className="category-list-item" key={ category.get('id') }>
                      <NewCategoriesItem
                        category={ category }
                        categoryBrowsingStarted={ categoryBrowsingStarted }
                        setCategoryId={ setCategoryIdHandler }
                        isMobile={ isMobile }
                        { ...props }/>
                    </li>
                  ))
                  }
                </React.Fragment>
            }

          </ul>
        </div>
        <div className="col-md-80">
          <SubCategoriesList allCategories={ allCategories } parentCategoryId={ selectedCategoryId } />
        </div>
      </div>
    </div>
  );
};
