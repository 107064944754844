import {
  takeLatest, put, fork, all, select, call, take
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { List, fromJS } from 'immutable';
import { loadCart } from 'client/actions/cartActions';
import {
  GET_NECESSARY_DETAILS_CHECKOUT_PROCESS,
  setCheckoutLoadState,
  setActivePanelId,
  setDeliveryPanelLoadState,
  TOGGLE_ACCORDION_STATE,
  START_EDIT_DELIVERY_ADDRESS,
  showDeliveryAddressForm,
  addressSavedSuccess,
  REQUEST_SHOW_ADDRESS_BOOK,
  showMyAddressBook,
  CONFIRM_DELIVERY_ADDRESS,
  BACK_DELIVERY_PANEL,
  CONFIRM_DELIVERY_ADDRESS_FROM_ADDRESSLIST_DROPDOWN,
  onSelectNewAddress,
  CANCEL_EDIT_ADDRESS,
  SAVE_ADDRESS,
  REQUEST_ADD_NEW_ADDRESS,
  setDeliveryIdBeingEdited,
  REQUEST_GO_TO_REVIEW_ORDER_PANEL,
  scrollToSection,
  REQUEST_SCROLL_TO_SECTION, SET_ACTIVE_PANEL_ID
} from 'client/actions/checkoutActions';

import { scrollToTop } from 'client/actions/scrollActions';
import { logAction } from 'client/actions/logActions';
import {
  getUserAddresses,
  setAddress,
  saveAddress,
  updateAddress,
  SET_ADDRESS
} from 'client/actions/addressActions';
import {
  ORDER_REVIEW_ACCORDION_PANEL_ID,
  DELIVERY_ADDRESS_TYPE,
  SINGLE_PAGE_CHECKOUT,
  PAYMENT_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';

import { PENDING, SUCCESS, ERROR } from 'shared/constants/loadStateType';
import {
  DELIVERY_ADDRESS_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';
import { toastError, toastSuccess } from 'client/actions/showNotificationsActions';
import { CHANGE_PAYMENT_METHOD } from '../components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { MASK_CALL_DIALOG_VARIANTS, PAY_BY_NEW_CARD } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { orderSelectors } from '../components/elements/paymentMethod/shared/selectors';
import { hideNonDeliverableOrderMessage, showMaskCallDialog } from 'client/actions/ui/dialogActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedString } from 'localization/localizer';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { getCheckoutAnalyticsEvent, getCheckoutOptionAnalyticsEvent } from '../utils/googleTagManagerUtils';
import { reloadScheduledOrders } from 'client/actions/scheduledOrders';
import { toProductLineItems } from 'client/utils/productLineUtils';

const SAGA_DELAY = 500;
const TOASTER_DELAY = 5000;
const getDeliveryAddress = (state) => state.getIn(['user', 'address', 'delivery'], null);
const getAllDeliveryAddresses = (state) => state.getIn(['user', 'address', 'deliveryAddresses', 'data']);
const getOrderLines = (state) => state.getIn(['cart', 'orderLines'], List()).toJS();
const getCheckoutPaymentMethod = (state) => state.getIn(['ui', 'paymentMethod'], fromJS({})).toJS();

export function * getNecessaryCheckoutDetails () {
  try {
    yield put(setCheckoutLoadState(PENDING));
    yield put(loadCart({ location: SINGLE_PAGE_CHECKOUT }));
    const cart = yield select(state => state.get('cart'));
    const scheduledOrdersFormSettings = yield select(state => state.getIn(['scheduledOrders', 'forms'], fromJS({})));
    const shouldReloadScheduleSettings = !(scheduledOrdersFormSettings && scheduledOrdersFormSettings.size > 0) && cart && cart.get('orderLines') && cart.get('orderLines').size > 0;

    if (shouldReloadScheduleSettings) {
      yield put(reloadScheduledOrders(fromJS(toProductLineItems(cart.toJS(), 'cart'))));
    }
    yield put(setActivePanelId({ panelId: -1 }));
    yield put(setActivePanelId({ panelId: DELIVERY_ADDRESS_ACCORDION_PANEL_ID }));
    // all actions for delivery panel
    yield put(setDeliveryPanelLoadState(PENDING));
    yield put(getUserAddresses({ addressesDisplayed: 'ALL', addressesSkip: 0 }));
    yield take(SET_ADDRESS);

    const deliveryAddress = yield select(getDeliveryAddress);
    yield put(showDeliveryAddressForm({
      showDeliveryAddressForm: !deliveryAddress,
      isEditingDeliveryAddress: false,
      showCancelButtonOnAddressForm: !!deliveryAddress
    }));
    yield put(setDeliveryPanelLoadState(SUCCESS));

    // all actions for payment panel
    // all actions for order review panel

    yield put(setCheckoutLoadState(SUCCESS));
    const checkoutOrderLines = yield select(getOrderLines);
    const checkoutEvent = getCheckoutAnalyticsEvent(checkoutOrderLines);
    yield put(updateDataLayer(checkoutEvent));
  } catch (error) {
    yield put(setCheckoutLoadState(ERROR));
  }
}

export function * toggleAccordionState ({ payload }) {
  const { panelId, csAgentName } = payload;
  yield put(setActivePanelId({ panelId, csAgentName }));
}

export function * editDeliveryAddress ({ payload: deliveryAddressId }) {
  yield put(onSelectNewAddress(deliveryAddressId));
  yield call(delay, SAGA_DELAY);
  yield put(setDeliveryIdBeingEdited(deliveryAddressId));
  yield put(showMyAddressBook(false));
  yield put(showDeliveryAddressForm({
    showDeliveryAddressForm: true,
    isEditingDeliveryAddress: true,
    showCancelButtonOnAddressForm: true,
    deliveryIdBeingEdited: deliveryAddressId
  }));
}

export function * cancelEditAddress ({ payload: deliveryAddressId }) {
  yield put(scrollToTop());
  yield put(showDeliveryAddressForm({
    showDeliveryAddressForm: false,
    isEditingDeliveryAddress: false,
    showCancelButtonOnAddressForm: false,
    deliveryIdBeingEdited: null
  }));
  yield put(onSelectNewAddress(null));
}

export function * saveUserAddress ({ payload }) {
  try {
    const { addressIdBeingEdited } = payload;
    const addressIdObj = addressIdBeingEdited ? { addressId: addressIdBeingEdited } : {};
    const addressObj = {
      addressType: DELIVERY_ADDRESS_TYPE,
      address: {
        ...payload,
        ...addressIdObj
      }
    };
    yield put(loadCart({ addressIdBeingEdited }));
    yield put(scrollToTop());
    const saveOrUpdateHandler = addressIdBeingEdited ? updateAddress : saveAddress;
    yield put(saveOrUpdateHandler(addressObj));
    yield put(setDeliveryPanelLoadState(PENDING));
    yield call(delay, SAGA_DELAY);
    yield put(getUserAddresses({ addressesDisplayed: 'ALL', addressesSkip: 0 }));

    if (addressIdBeingEdited) {
      yield call(delay, SAGA_DELAY);
      const deliveryAddressess = yield select(getAllDeliveryAddresses);
      const selectedDeliveryAddress = deliveryAddressess.find(l => l.get('addressId') === addressIdBeingEdited);
      yield put(setAddress({ addressType: DELIVERY_ADDRESS_TYPE, address: selectedDeliveryAddress }));
    }
    yield put(setDeliveryPanelLoadState(SUCCESS));
    yield put(addressSavedSuccess());
    if (!addressIdBeingEdited) {
      yield put(
        toastSuccess(
          getToastMessage(
            getLocalizedString('myAccount.addressBook.deliveryAddressUpdate.successMessage')
          ),
          'top-right', TOASTER_DELAY
        )
      );
    }
    yield put(showDeliveryAddressForm({
      showDeliveryAddressForm: false,
      isEditingDeliveryAddress: false,
      showCancelButtonOnAddressForm: false,
      deliveryIdBeingEdited: null
    }));
    yield put(setActivePanelId({ panelId: addressIdBeingEdited ? -1 : 2 }));
    yield put(setActivePanelId({ panelId: addressIdBeingEdited ? DELIVERY_ADDRESS_ACCORDION_PANEL_ID : PAYMENT_ACCORDION_PANEL_ID }));
    yield put(hideNonDeliverableOrderMessage());
  } catch (error) {
    yield put(logAction({ message: `Error saving address ${error}` }));
    yield put(
      toastError(
        getToastMessage(
          getLocalizedString('myAccount.addressBook.deliveryAddressUpdate.error')
        ), 'top-right', TOASTER_DELAY
      )
    );
  }
}

export function * showAddressBook () {
  yield put(showMyAddressBook(true));
}

export function * hideAddressBook () {
  const deliveryAddress = yield select(getDeliveryAddress);
  const defaultDeliveryId = deliveryAddress.get('addressId');
  yield put(onSelectNewAddress(defaultDeliveryId));
  yield put(showMyAddressBook(false));
}

export function * deliverToAddress ({ payload }) {
  const deliveryAddress = yield select(getDeliveryAddress);
  const defaultDeliveryId = deliveryAddress.get('addressId');
  const selectedId = payload || defaultDeliveryId;
  const deliveryAddresses = yield select(getAllDeliveryAddresses);
  const selectedDeliveryAddress = deliveryAddresses.find(l => l.get('addressId') === selectedId);
  yield put(setAddress({ addressType: DELIVERY_ADDRESS_TYPE, address: selectedDeliveryAddress }));
  yield put(onSelectNewAddress(selectedId));
  yield put(showMyAddressBook(false));
  yield put(loadCart());
  yield put(hideNonDeliverableOrderMessage());
}

export function * addNewDeliveryAddress () {
  yield put(showMyAddressBook(false));
  yield put(showDeliveryAddressForm({
    showDeliveryAddressForm: true,
    showCancelButtonOnAddressForm: true,
    isEditingDeliveryAddress: false,
    deliveryIdBeingEdited: null
  }));
}

export function * handleSetActivePanelId ({ payload }) {
  const isOrderReviewPanel = payload.panelId === ORDER_REVIEW_ACCORDION_PANEL_ID;
  if (isOrderReviewPanel) {
    window && window.scrollTo({ top: 150, behavior: 'smooth' });
  }

  const payment = isOrderReviewPanel ? yield select(getCheckoutPaymentMethod) : {};
  const checkoutOptionEvent = getCheckoutOptionAnalyticsEvent(payload.panelId, payment.SINGLE_PAGE_CHECKOUT?.method);
  yield put(updateDataLayer(checkoutOptionEvent));
}

export function * onRequestGoToReviewOrderPanel () {
  yield put(setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID }));
}

export function * handleScrollToSection ({ payload }) {
  yield put(scrollToSection(payload));
}

export function * watchGetNecessaryCheckoutDetailsState () {
  yield takeLatest(GET_NECESSARY_DETAILS_CHECKOUT_PROCESS, getNecessaryCheckoutDetails);
}

export function * watchToggleAccordionState () {
  yield takeLatest(TOGGLE_ACCORDION_STATE, toggleAccordionState);
}

export function * watchStartEditDeliveryAddress () {
  yield takeLatest(START_EDIT_DELIVERY_ADDRESS, editDeliveryAddress);
}

export function * watchCancelEditAddress () {
  yield takeLatest(CANCEL_EDIT_ADDRESS, cancelEditAddress);
}

export function * watchSaveAddress () {
  yield takeLatest(SAVE_ADDRESS, saveUserAddress);
}

export function * watchShowAddressBook () {
  yield takeLatest(REQUEST_SHOW_ADDRESS_BOOK, showAddressBook);
}

export function * watchConfirmDeliveryAddress () {
  yield takeLatest(CONFIRM_DELIVERY_ADDRESS, deliverToAddress);
}

export function * watchBackToDeliveryPanel () {
  yield takeLatest(BACK_DELIVERY_PANEL, hideAddressBook);
}

export function * watchAddNewDeliveryAddress () {
  yield takeLatest(REQUEST_ADD_NEW_ADDRESS, addNewDeliveryAddress);
}

export function * watchConfirmDeliveryAddressFromAddressListDropDown () {
  yield takeLatest(CONFIRM_DELIVERY_ADDRESS_FROM_ADDRESSLIST_DROPDOWN, deliverToAddress);
}

export function * watchRequestGoToReviewOrderPanel () {
  yield takeLatest(REQUEST_GO_TO_REVIEW_ORDER_PANEL, onRequestGoToReviewOrderPanel);
}

export function * watchScrollToSection () {
  yield takeLatest(REQUEST_SCROLL_TO_SECTION, handleScrollToSection);
}

export function * watchSetActivePanelId () {
  yield takeLatest(SET_ACTIVE_PANEL_ID, handleSetActivePanelId);
}

export function * maskCallDialog ({ payload }) {
  const csAgentName = yield select(orderSelectors.csAgent.name);
  if (csAgentName && payload.paymentMethod === PAY_BY_NEW_CARD) {
    yield put(showMaskCallDialog({ variant: MASK_CALL_DIALOG_VARIANTS.MASK }));
  }
}

export function * watchMaskCallDialog () {
  yield takeLatest(CHANGE_PAYMENT_METHOD, maskCallDialog);
}

export function * watchAllCheckoutSagas () {
  yield all([
    fork(watchGetNecessaryCheckoutDetailsState),
    fork(watchToggleAccordionState),
    fork(watchStartEditDeliveryAddress),
    fork(watchCancelEditAddress),
    fork(watchSaveAddress),
    fork(watchShowAddressBook),
    fork(watchConfirmDeliveryAddress),
    fork(watchBackToDeliveryPanel),
    fork(watchAddNewDeliveryAddress),
    fork(watchConfirmDeliveryAddressFromAddressListDropDown),
    fork(watchRequestGoToReviewOrderPanel),
    fork(watchScrollToSection),
    fork(watchSetActivePanelId),
    fork(watchMaskCallDialog)
  ]);
}
