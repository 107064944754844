export const GOTO_ORDER_REVIEW = 'GOTO_ORDER_REVIEW';
export const ROUTE_ACCESS_DENIED = 'ROUTE_ACCESS_DENIED';
export const routeAccessDenied = (replace, cb, redirectUrl, finalDestination, isRedirectLoading) => ({
  type: ROUTE_ACCESS_DENIED,
  cb,
  replace,
  redirectUrl,
  finalDestination,
  isRedirectLoading
});

export const gotoOrderReview = (deliveryAddressId, billingAddressId, poReference, payOnAccount, fromQuotationId, isGlobal) => ({
  type: GOTO_ORDER_REVIEW,
  deliveryAddressId,
  billingAddressId,
  poReference,
  payOnAccount,
  fromQuotationId,
  isGlobal
});

export const ON_SCREEN_READY = 'ON_SCREEN_READY';

export const onScreenReady = () => {
  return {
    type: ON_SCREEN_READY
  };
};

export const GOTO_URL = 'GOTO_URL';
export const goToUrl = (url, handleInServer = false, openInANewTab = false) => ({
  type: GOTO_URL,
  url,
  handleInServer,
  openInANewTab
});

export const REPLACE_URL = 'REPLACE_URL';
export const replaceUrl = (url) => ({
  type: REPLACE_URL,
  url
});

export const GOTO_EXTERNAL_URL = 'GOTO_EXTERNAL_URL';
export const gotoExternalUrl = (url) => ({
  type: GOTO_EXTERNAL_URL,
  url
});

export const INITIALISE_ADDRESS_DETAILS = 'INITIALISE_ADDRESS_DETAILS';
