import React from 'react';
import { connect } from 'react-redux';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { Modal } from '@graingerglobalonline/curly-eureka';
import flowRight from 'lodash/flowRight';
import { showReturnConfirmationDialog } from 'client/actions/ui/dialogActions';
import { getLocalizedString } from 'localization/localizer';

const ReturnConfirmationDialog = ({
  showReturnConfirmationDialog, showReturnConfirmation, proceedHandler
}) => {
  const onClickHandler = () => {
    showReturnConfirmationDialog(!showReturnConfirmation);
  };

  return (
    <Modal.Container shouldDisplayModal={ showReturnConfirmation } className="ReturnConfirmationDialog" closeModal={ onClickHandler } >
      <Modal.Header onClose={ onClickHandler }>
        { getLocalizedString('return.request.confirmation') }
      </Modal.Header>
      <Modal.Content className="ReturnConfirmationDialog_content">
        { getLocalizedString('return .request.message') }
      </Modal.Content>
      <div className="ReturnConfirmationDialog_buttons">
        <SecondaryButton onClick={ onClickHandler }>
          { getLocalizedString('cart.dialog.cancel.button') }
        </SecondaryButton>
        <PrimaryButton onClick={ proceedHandler }>
          { getLocalizedString('button.label.proceed') }
        </PrimaryButton>
      </div>
    </Modal.Container>
  );
};

const mapStateToProps = (state) => {
  return {
    showReturnConfirmation: state.getIn(['ui', 'dialogs', 'returnRefund', 'showReturnConfirmation']),
    proceedHandler: state.getIn(['ui', 'dialogs', 'returnRefund', 'proceedHandler'], () => {})
  };
};

const mapDispatchToProps = (dispatch) => ({
  showReturnConfirmationDialog: flowRight(dispatch, showReturnConfirmationDialog)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnConfirmationDialog);
