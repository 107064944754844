import { ApiRequest } from 'shared/utils/apiUtils';

export function forgotPassword (email) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/forgot_password',
    body: { email }
  });
  return req.executeAndGetBody();
}

export function resetPassword (resetPasswordToken, newPassword) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/security/reset_password',
    body: {
      resetPasswordToken: resetPasswordToken,
      newPassword
    }
  });
  return req.executeAndGetBody();
}

export function updatePassword (oldPassword, newPassword) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/security/change_password',
    body: {
      oldPassword,
      newPassword
    }
  });
  return req.executeAndGetBody();
}
