import React from 'react';
import { getFormattedDate } from 'client/utils/recommendationUtils';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';

export const BoughtOn = props => {
  return <React.Fragment>
    <SvgLoader xlinkHref="#order-placed-cart-icon" />
    <span className='text-sm'>
      { props?.boughtOn
        ? `Bought ${getFormattedDate(new Date(props?.boughtOn))}`
        : getLocalizedString('recommendations.product.tile.previously.bought.label')
      }
    </span>
  </React.Fragment>;
};
