
import { put, select, takeLatest, call, throttle } from 'redux-saga/effects';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { getUrlWithQuery } from 'client/utils/urlUtils';
import _get from 'lodash/get';
import {
  changeSearchTermSucceeded,
  changeSearchTermFailed,
  SEARCH_TERM_CHANGED,
  SEARCH_TERM_SELECTED,
  GET_RECENT_SEARCHES,
  FETCH_PRODUCTS_ON_SEARCH_TERM_HOVER,
  changeProductOnSearchTermHoverSucceeded,
  changeProductOnSearchTermHoverFailed,
  SEARCH_TERM_UPDATE_AQ_TERM,
  searchBarUpdateAqTermSuccess,
  getRecentSearchesSuccess
} from 'client/actions/searchBarActions';
import searchSuggestionTransform from 'client/utils/searchSuggestionTransform';
import { goToUrl } from 'client/actions/routeActions';
import {
  getSearchSuggestions,
  getProductsOnSearchTermHover,
  getRecentSearches
} from 'shared/endpoints/searchEndpoint';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

const SEARCH_DEBOUNCE_TIME = 600;

const abSearchSuggestionOrder = (state) =>
  state.getIn(['abTesting', 'abSearchSuggestionOrder']);

const searchBarTermSelector = (state) => state.getIn(['searchBar', 'term'], '');
const searchBarAqSelector = (state) => state.getIn(['searchbar', 'aq'], '');

export const fetchSuggestions = function * fetchSuggestions (action) {
  const { minSearchTermLength = 3 } = action;
  try {
    if (action.term.length >= minSearchTermLength) {
      const result = yield call(getSearchSuggestions, action.term);
      const order = yield select(abSearchSuggestionOrder);
      yield put(changeSearchTermSucceeded(action.term, searchSuggestionTransform(result, { order })));
    } else {
      yield put(changeSearchTermSucceeded(action.term, []));
    }
  } catch (e) {
    yield put(changeSearchTermFailed(action.term));
  }
};

function convertSuggestions (suggestions, params) {
  const brandId = suggestions?.params?.brandId;
  const categoryId = suggestions?.params?.id;
  const event = {
    type: brandId ? 'brand' : categoryId ? 'category' : 'suggest',
    searchTerm: params?.query || suggestions?.text,
    brandId: brandId,
    categoryId: categoryId,
    brandName: suggestions?.params?.brandName,
    categoryName: suggestions?.params?.label
  };
  return omitBy(event, isUndefined);
}

export function * redirectUserToSearchPage ({ params = {}, path = '', term, event, suggestion }) {
  const queryStringParams = term ? { query: term } : params;
  const aq = yield select(searchBarAqSelector);
  if (event === 'click') {
    yield put(updateDataLayer({
      event: 'auto_complete',
      aq,
      ...convertSuggestions(suggestion, params)
    }));
  }

  if (params.productId) {
    yield put(goToUrl(`/${params.productId}`, true));
  } else {
    yield put(goToUrl(getUrlWithQuery(`/shop${path}`, queryStringParams), true));
  }
}

export function * fetchProductsOnSearchTermHover (action) {
  try {
    const result = yield call(getProductsOnSearchTermHover, action.payload);
    const order = yield select(abSearchSuggestionOrder);
    const suggestions = searchSuggestionTransform(result, { order });
    const defaultTopProduct = {
      title: 'Top Products',
      type: 'products',
      results: []
    };
    const products = _get(suggestions, '[3]', defaultTopProduct);
    yield put(changeProductOnSearchTermHoverSucceeded(action.payload.text, { productIndex: 3, products }));
  } catch (e) {
    yield put(changeProductOnSearchTermHoverFailed(action.payload.text));
  }
}

export function * fetchRecentSearches (action) {
  try {
    const { recentSearches = [] } = yield call(getRecentSearches, action?.searchTerm || '');
    yield put(getRecentSearchesSuccess(recentSearches));
  } catch (err) {
    yield put(getRecentSearchesSuccess([]));
  }
}

function * searchBarUpdateAqTerm () {
  const queryTerm = yield select(searchBarTermSelector);
  yield put(searchBarUpdateAqTermSuccess(queryTerm));
}

export const watchTermHovered = function * watchTermHovered () {
  yield throttle(SEARCH_DEBOUNCE_TIME, FETCH_PRODUCTS_ON_SEARCH_TERM_HOVER, fetchProductsOnSearchTermHover);
};

export const watchTermSelected = function * watchTermSelected () {
  yield takeLatest(SEARCH_TERM_SELECTED, redirectUserToSearchPage);
};

export const watchFetchSuggestions = function * watchFetchSuggestions () {
  yield throttle(SEARCH_DEBOUNCE_TIME, SEARCH_TERM_CHANGED, fetchSuggestions);
};

export const watchSearchBarUpdateAqTerm = function * watchSearchBarUpdateAqTerm () {
  yield takeLatest(SEARCH_TERM_UPDATE_AQ_TERM, searchBarUpdateAqTerm);
};

export function * watchRecentSearches () {
  yield throttle(SEARCH_DEBOUNCE_TIME, GET_RECENT_SEARCHES, fetchRecentSearches);
}
