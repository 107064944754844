import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./SubscriptionDetails.scss');
}

export const AddressDisplay = ({ className, title, address = {}, phoneNumber }) => {
  const { firstName, lastName, companyName, addressLine1, addressLine2, city, state, postalCode, addressPhoneNumber } = address;
  return <div className={ `SubscriptionAddress ${className}` }>
    <span className="SubscriptionAddress_title">{title}</span>
    <div className="SubscriptionAddress_small_title">{firstName} {lastName},</div>
    <div className="SubscriptionAddress_companyName">{companyName}</div>
    <div>{addressLine1}</div>
    { addressLine2 ? <div>{addressLine2}</div> : null }
    <div>{city}</div>
    <div>{state}</div>
    <div>{postalCode}</div>
    {
      phoneNumber || addressPhoneNumber
        ? <div className='SubscriptionAddress_small_title'>{ getLocalizedString('addressForm.label.telephoneNumber')}</div>
        : null
    }
    <div id="phone-number">{phoneNumber || addressPhoneNumber}</div>
  </div>;
};
