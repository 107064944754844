import React from 'react';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { Link, withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  fetchGlobalQuotationByIdOrQuoteRef,
  removeProductFromGlobalQuotationConfirm,
  changeGlobalQuotationQty,
  lockGlobalQuotation,
  clearAddedProductToQuotation,
  retrieveGlobalQuotation,
  updateField,
  fetchGlobalQuotation,
  mergeGlobalQuotes,
  isGlobalQuotePaidOnAccount,
  addQuoteLineToBasket
} from 'client/actions/globalQuotationActions';
import { sendQuotationEmail, fetchQuotationProductStockInfo } from 'client/actions/quotationsActions';
import ProductLine from 'client/components/elements/productLine/ProductLine';
import QuotationTotalizer from 'client/components/screens/quotationDetailsScreen/QuotationTotalizer';
import { fromJS, List } from 'immutable';
import _inRange from 'lodash/inRange';
import _find from 'lodash/find';
import SvgLoader from 'client/components/svg/SvgLoader';
import moment from 'moment';
import { PrimaryButton, PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import { showCustomAlertDialog, showGlobalQuoteRefReminder, showLoginDialog, showSavingQuoteDialog } from 'client/actions/ui/dialogActions';
import { toastInfo, toastSuccess } from 'client/actions/showNotificationsActions';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import FreeDeliveryShortfallMessage from 'client/components/screens/cartScreen/FreeDeliveryShortfallMessage';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { updateMarketingPreferences } from 'client/actions/accountActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { LOGIN_IMAGE_DESKTOP, LOGIN_IMAGE_MOBILE } from 'shared/constants/quotation';
if (process.browser) {
  require('./globalQuotationScreen.scss');
}

const NUMBER_OF_DESCRIPTION_SUBPOINTS = 4;
const noQuoteRefFound = (quoteRefNo) => {
  return (<div className="noQuoteRefFound">
    <h1>{ getLocalizedStringWithParam('globalQuotation.noQuoteFoundWithRef', { quoteRefNo }) }</h1>
    <h2>{ getLocalizedString('globalQuotation.tryDifferentCode') }</h2>
  </div>);
};

const isWhitelistedRoute = (nextLocation) => {
  const WHITE_LISTED_ROUTES = ['/checkout/', 'global-quotation', '/register'];
  if (nextLocation === '/') {
    return true;
  }
  return WHITE_LISTED_ROUTES.some(word => nextLocation.includes(word));
};

const isQtyAvailable = (quote) => {
  const allProductsAreAvailable = quote.get('products', []).reduce((isQtyAvailable, product) => {
    if (isQtyAvailable) {
      isQtyAvailable = product.getIn(['stock', 'stockMessage', 'isDeliverable'], false);
    }
    return isQtyAvailable;
  }, true);
  return allProductsAreAvailable;
};

const activateQuotaionAlertsPayload = {
  marketingPreferences: { 'quotationAlerts': true },
  source: 'Quotation Confirmation Page',
  csAgentName: null
};

class GlobalQuotationScreen extends React.PureComponent {
  componentDidMount () {
    const quoteRefNo = this.props.location.pathname.split('/global-quotation/')[1];
    if (quoteRefNo) {
      this.props.fetchGlobalQuotationByIdOrQuoteRef(quoteRefNo);
    }

    this.props.router.setRouteLeaveHook(this.props.route, nextLocation => {
      const quoteRef = this.props.globalQuotation.get('quoteRef', '');
      if (quoteRef && !isWhitelistedRoute(nextLocation.pathname) && !this.props.isReminderVisible && !this.props.isLoggedInOrhasValidLongSessionToken) {
        this.props.showGlobalQuoteRefReminder(nextLocation.pathname === '/shop' ? `${nextLocation.pathname}${nextLocation.search}` : nextLocation.pathname);
        return false;
      }
      return true;
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps (nextProps) {
    const {
      globalQuotation,
      isLoggedInOrhasValidLongSessionToken,
      isLoggedIn,
      isQuotationAlertsEnabled,
      toastSuccess
    } = this.props;
    const quotationId = globalQuotation.get('_id');

    if (isLoggedInOrhasValidLongSessionToken !== nextProps.isLoggedInOrhasValidLongSessionToken && quotationId) {
      this.props.fetchGlobalQuotationByIdOrQuoteRef(quotationId, false);
    }

    // For successfully subscribing to Quotations Email
    if (isLoggedIn && nextProps.isLoggedIn && isQuotationAlertsEnabled === false && nextProps.isQuotationAlertsEnabled) {
      const toastMessage = getLocalizedString('globalQuotation.successfullySubscribed.toastMessage');
      const toastPosition = 'top-right';
      const toastDuration = 3500;
      toastSuccess(
        getToastMessage(
          toastMessage
        ),
        toastPosition,
        toastDuration);
    }
  }

  componentWillUnmount () {
    const quoteRef = this.props.globalQuotation.get('quoteRef', '');
    if (quoteRef) {
      this.props.clearAddedProductToQuotation();
    }
  }

  render () {
    const {
      globalQuotation,
      isLoggedInOrhasValidLongSessionToken,
      removeProductFromGlobalQuotationConfirm,
      changeGlobalQuotationQty,
      lockGlobalQuotation,
      quoteRefToRetrieve,
      isQuoteRefValid,
      updateField,
      retrieveGlobalQuotation,
      quoteSearches,
      sendQuotationEmail,
      showLoginDialog,
      isLoggedIn,
      invalidQuoteRef,
      fetchQuotationProductStockInfo,
      loggedInCustomerId,
      showSavingQuoteDialog,
      toastInfo,
      includeVat,
      isQuotationAlertsEnabled,
      updateMarketingPreferences,
      addQuoteLineToBasketAction,
      isLockingGlobalQuotation
    } = this.props;

    const quoteRefNo = this.props.location.pathname.split('/global-quotation/')[1];
    if (quoteRefNo && quoteSearches.get(quoteRefNo, false)) {
      return noQuoteRefFound(quoteRefNo);
    }

    if (quoteRefNo && globalQuotation && !globalQuotation.size) {
      return (<ScreenLoadPending />);
    }

    const onRemove = (product) => {
      const quotationId = globalQuotation.get('_id');
      removeProductFromGlobalQuotationConfirm(quotationId, product, globalQuotation.get('products').size === 1);
    };

    const getQuantityDiscountLabel = (qty, breakPrices) => {
      const foundNewBreakPriceObject = _find(breakPrices, (breakPrice) => {
        return _inRange(qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
      });

      return foundNewBreakPriceObject && foundNewBreakPriceObject.breakDiscount && foundNewBreakPriceObject.breakDiscount !== 0
        ? (<div className="QuotationProductRow_qtyDiscountLabel">
          <span>Quantity Discount</span>
        </div>) : '';
    };

    const onQtyChange = ({ quantity, orderLine }) => {
      const quotationId = globalQuotation.get('_id');
      const breakPricesList = orderLine.getIn(['price', 'breakPrices'], List());
      const qty = orderLine.get('quantity');
      const sku = orderLine.get('sku');
      fetchQuotationProductStockInfo(quotationId, sku, quantity, true);
      getQuantityDiscountLabel(quantity, breakPricesList.toJS());
      return changeGlobalQuotationQty(qty, quotationId, sku, quantity, breakPricesList);
    };

    const retrieveQuoteIfEnter = (e, ref) => {
      if (e.which === 13 && e.target.value) {
        retrieveGlobalQuotation(ref);
      }
    };

    const getDaysUntilExpiryTime = (expiryTime) => {
      const expirationDate = moment(expiryTime);
      const today = moment(new Date());
      const daysDifference = expirationDate.diff(today, 'days') + 1;
      return daysDifference === 1 ? `${daysDifference} Day` : `${daysDifference} Days`;
    };

    const isQuoteOwner = (customerId) => {
      return customerId === loggedInCustomerId;
    };

    const copyQuoteRef = (quoteRef) => {
      const tempInput = document.createElement('input');
      tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
      tempInput.value = quoteRef;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      showCopyPopOver(quoteRef);
    };

    const showCopyPopOver = (quoteRef) => {
      const copyPopoverDisplayTime = 1500;
      toastInfo(
        getToastMessage(
          getLocalizedStringWithParam('quotation.reference.copied', { quoteRef })
        ),
        'top-right',
        copyPopoverDisplayTime
      );
    };

    const isQtyValid = globalQuotation && globalQuotation.size ? globalQuotation.get('products', [])
      .map((product) => {
        const quantity = product.get('qty', 1);
        const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
        const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
        const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);
        return isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
      }).every(el => el === true) : true;

    const freeDeliveryShortfall = FREE_DELIVERY_THRESHOLD - globalQuotation.getIn(['quotationPrice', 'cartOrderLinesTotalWithVat'], 0);
    const quotationTitle = `${getLocalizedString('globalQuotation.yourQuote')} ${globalQuotation.get('quoteRef') || ''}`;

    return <div className="globalQuotationContainer row">
      { globalQuotation && globalQuotation.size ? <React.Fragment>
        <div>
          <h2>
            <b>{ quotationTitle }</b>
          </h2>
          { !isLoggedInOrhasValidLongSessionToken && !globalQuotation.get('isLocked') ? <div>{ getLocalizedString('globalQuotation.lockQuoteToGetRefOne') } <Link style={{ cursor: 'pointer' }} onClick={ () => showLoginDialog() } className="globalQuotationContainer_login">Login</Link>{ getLocalizedString('globalQuotation.lockQuoteToGetRefTwo') }</div> : null }
        </div>
        <div className={ `col-xs-12 col-md-9 ${!globalQuotation.get('isLocked') && globalQuotation.get('quoteRef') ? 'globalQuotationContainer_noProductLineRemove' : ''}` }>
          <div className="globalQuotationContainer_productLineHeader row" />
          {
            globalQuotation.get('products').map((product) => {
              return <ProductLine
                sku={ product.get('sku') }
                key={ product.get('sku') }
                isAvailable={ true }
                isEditable={ !globalQuotation.get('isLocked') && !globalQuotation.get('quoteRef') }
                quantity={ product.get('qty') }
                onQuantityChange={ onQtyChange }
                onRemove={ onRemove }
                isIncludingVat={ includeVat }
                nonRefundableType = { product.get('nonRefundableType') }

              />;
            })
          }
        </div>
        <div className="col-xs-12 col-md-3 globalQuotationContainer_rightHandContainers">
          {
            !globalQuotation.get('isLocked') && !globalQuotation.get('quoteRef') ? <React.Fragment>
              <button className="globalQuotationContainer_lockButton" disabled={ !isQtyValid || isLockingGlobalQuotation } onClick={ () => lockGlobalQuotation(globalQuotation.get('_id'), true, isLoggedInOrhasValidLongSessionToken) } data-e2e="lockGlobalQuotationButton">
                <SvgLoader xlinkHref="#unlock-plock" className="globalQuotationContainer_rightHandContainers-unlockPlock" />
                <div className="globalQuotationContainer_lockButton-reQuoteBlock">
                  <div>{ getLocalizedString(isLoggedInOrhasValidLongSessionToken ? 'globalQuotation.lockAndSave' : 'globalQuotation.lockForReference') }</div>
                  <span>{ getLocalizedString('globalQuotation.lockPricesFor14Days') }</span>
                </div>
              </button>
              <span><b>{ getLocalizedString('globalQuotation.pleaseNote') } </b>{ getLocalizedString('globalQuotation.itemsCan') }<b>not</b>{ getLocalizedString('globalQuotation.notChange') }</span>
            </React.Fragment> : null
          }
          {
            globalQuotation.get('isLocked') && globalQuotation.get('quoteRef')
              ? <div className="globalQuotationContainer_quoteRefContainer">
                <div className={ globalQuotation.get('quoteRef') }>
                  <div className="globalQuotationContainer_quoteRefLabel">Quote Ref:</div>
                </div>
                <div className="globalQuotationContainer_quoteRefCopyContainer">
                  <div className="globalQuotationContainer_quoteRefText">{ globalQuotation.get('quoteRef') }</div>
                  <Link title={ `Copy ${globalQuotation.get('quoteRef')}` } onClick={ () => copyQuoteRef(globalQuotation.get('quoteRef')) } className="globalQuotationContainer_copyQuoteRef">
                    <SvgLoader xlinkHref="#copy" className="copy-icon" title={ `Copy ${globalQuotation.get('quoteRef')}` } />
                  </Link>
                </div>
                <div>{ getLocalizedString('globalQuotation.noteThisRef') }</div>
                <div className="globalQuotationContainer_expirationTime">
                  <SvgLoader xlinkHref="#quotation-icon" className="quotation-icon" />
                  <b>Expires in:</b> { getDaysUntilExpiryTime(globalQuotation.get('expiryTime')) }
                </div>
              </div> : null
          }
          {
            !globalQuotation.get('isLocked') && globalQuotation.get('quoteRef') ? <React.Fragment>
              <button className="globalQuotationContainer_lockButton"
                disabled= { isLockingGlobalQuotation }
                onClick={ () => lockGlobalQuotation(globalQuotation.get('_id'), false, isLoggedInOrhasValidLongSessionToken) }
                data-e2e="requoteButton">
                <SvgLoader xlinkHref="#refresh-icon" className="refresh-icon" title="Re-Quote" />
                <div className="globalQuotationContainer_lockButton-reQuoteBlock">
                  <div>{ getLocalizedString('globalQuotation.reQuote') }</div>
                  <span>{ getLocalizedString('globalQuotation.lockPricesFor14Days') }</span>
                </div>
              </button>
              <span><b>{ getLocalizedString('globalQuotation.pleaseNote') } </b>{ getLocalizedString('globalQuotation.itemsCan') }<b>not</b>{ getLocalizedString('globalQuotation.notChange') }</span>
            </React.Fragment> : null
          }
          { globalQuotation.get('isLocked') ? <div className="globalQuotationContainer_actions">
            <a
              download
              className="globalQuotationContainer_pdfButton"
              rel="noopener noreferrer" target="_blank"
              href={ `/global-quotation/pdf/${globalQuotation.get('quoteRef')}.pdf` }
            >
              <SvgLoader xlinkHref="#pdf2-icon" className="pdf2-icon" title="Download PDF" />
              <span>{ getLocalizedString('globalQuotation.downloadGlobalQuote') }</span>
            </a>
            { isLoggedInOrhasValidLongSessionToken && isQuoteOwner(globalQuotation.get('customerId'))
              ? <Link className="globalQuotationContainer_actions-emailButton" onClick={ () => sendQuotationEmail(globalQuotation.get('_id')) }>
                <SvgLoader xlinkHref="#email-icon" className="email-icon" title="Send Email" />
                <span>Email to myself</span>
              </Link> : null
            }
            {
              isLoggedIn && !isQuoteOwner(globalQuotation.get('customerId')) ? <Link onClick={ showSavingQuoteDialog } className="globalQuotationContainer_actions-emailButton">
                <SvgLoader xlinkHref="#save" className="save-icon" title="Save to my quotes" />
                Save to my account
              </Link> : null
            }
          </div> : null }
          <div className="globalQuotationContainer_totals">
            <QuotationTotalizer quotationDetails={ globalQuotation } includeVat={ includeVat }/>
            { globalQuotation.get('isLocked') && globalQuotation.get('quoteRef') && isQtyAvailable(globalQuotation) ? <React.Fragment>
              {
                !isLoggedIn ? <PrimaryButton
                  className="CartTotalizer_CTAButton"
                  onClick={ () => showLoginDialog() }
                  datae2e="loginToCheckoutButton"
                  text={ getLocalizedString('cart.buttons.loginToCheckout') }
                /> : <React.Fragment>
                  { isQtyValid &&
                      <PrimaryLink to={ `${SINGLE_PAGE_CHECKOUT_ROUTE}?fromQuotationId=${globalQuotation.get('_id')}` }>
                        { getLocalizedString('cart.buttons.checkout') }
                      </PrimaryLink>
                  }
                  <br/>
                </React.Fragment>
              }
              { isQtyValid
                ? <div className="globalQuotationContainer_quoteToBasket">
                    Or&nbsp;
                  <Link onClick={ () => addQuoteLineToBasketAction(globalQuotation.get('_id')) } data-e2e="addQuoteLineToBasketLink">
                    { getLocalizedString('quotation.moveItemsToBasket') }
                  </Link>
                </div> : null
              }
              { isLoggedInOrhasValidLongSessionToken &&
                  globalQuotation.getIn(['isTradeAccountButtonToBeShown', 'showPayOnAccountButton']) &&
                  globalQuotation.getIn(['isTradeAccountButtonToBeShown', 'tradeAccountLimitExceeded'])
                ? getLocalizedString('globalQuotation.totalExceedsLimit')
                : null }
            </React.Fragment> : isQtyAvailable(globalQuotation)
              ? isQtyValid
                ? <React.Fragment><button
                  className="button-primary globalQuotationContainer_totals-noPayButtons"
                  data-e2e="lockGlobalQuotationButton2"
                  disabled={ isLockingGlobalQuotation }
                  onClick={ () => lockGlobalQuotation(globalQuotation.get('_id'), !globalQuotation.get('isLocked') && !globalQuotation.get('quoteRef'), isLoggedInOrhasValidLongSessionToken) }
                  type="button">
                  <div className="text-center">
                    <SvgLoader xlinkHref="#unlock-plock" className="quotation-icon" />
                    { !globalQuotation.get('isLocked') && globalQuotation.get('quoteRef') ? getLocalizedString('globalQuotation.reQuote') : getLocalizedString('globalQuotation.lockQuoteToPay') }
                  </div>
                </button>
                {freeDeliveryShortfall > 0
                  ? <FreeDeliveryShortfallMessage
                    freeDeliveryShortfall={ freeDeliveryShortfall }/>
                  : null}
                </React.Fragment>
                : <div className="alertMessage"><i className="far fa-times-circle"/> { getLocalizedString('globalQuotation.invalid.quantity') }</div>
              : <div className="alertMessage"><i className="far fa-times-circle"/> { getLocalizedString('cart.checkout.error') }</div>
            }
            { (isLoggedIn && isQuotationAlertsEnabled === false)
              ? <div className="globalQuotationContainer_subscribeQuotationAlerts">
                {getLocalizedString('globalQuotation.subscribeAlerts.beforeLinkMessage')}
                <Link onClick={ () => updateMarketingPreferences(activateQuotaionAlertsPayload) } data-e2e="subscribeQuotationAlertsLink">{getLocalizedString('globalQuotation.subscribeAlerts.linkMessage')}</Link>
                {getLocalizedString('globalQuotation.subscribeAlerts.afterLinkMessage')}
              </div>
              : null
            }
          </div>
          <div className="globalQuotationContainer_retrieveQuote">
            <div>{ getLocalizedString('globalQuotation.retrieveQuoteText') }</div>
            <div>
              <input type="text" value={ quoteRefToRetrieve } onKeyUp={ (e) => retrieveQuoteIfEnter(e, quoteRefToRetrieve) } onChange={ (e) => updateField(e.target.value, 'quoteRefToRetrieve') } data-e2e="quoteRefToRetrieveInput"/>
              <PrimaryButton text={ getLocalizedString('quotationDetailsScrn.button.find') } disabled={ !quoteRefToRetrieve.length } onClick={ () => retrieveGlobalQuotation(quoteRefToRetrieve) } datae2e="retrieveGlobalQuotationButton"/>
            </div>
            {
              !invalidQuoteRef ? <small className={ quoteRefToRetrieve && !isQuoteRefValid ? 'globalQuotationContainer_retrieveQuote-invalid' : '' }>Enter a valid code</small>
                : <small className="globalQuotationContainer_retrieveQuote-invalid">No quotation with quote ref: {invalidQuoteRef} found.</small>
            }
          </div>
        </div>
      </React.Fragment> : <div className="noProductsInQuotation row noProductsInQuotation_row">
        <div className="col-md-12 globalQuotationContainer_Col12">
          <div className="row globalQuotationContainer_row">
            <div className="col-xs-12 col-md-9 globalQuotationContainer_quotationEmptyText">
              <SvgLoader xlinkHref="#quotations-icon"/>
              <span>{ getLocalizedString('globalQuotation.noProducts.title') }</span>
            </div>
            <div className="col-xs-12 col-md-3 globalQuotationContainer_retrieveQuoteColumn">
              <div className="globalQuotationContainer_retrieveQuote globalQuotationContainer_retrieveQuote-noProducts">
                <div>{ getLocalizedString('globalQuotation.retrieveQuoteText') }</div>
                <div>
                  <input type="text" value={ quoteRefToRetrieve } onKeyUp={ (e) => retrieveQuoteIfEnter(e, quoteRefToRetrieve) } onChange={ (e) => updateField(e.target.value, 'quoteRefToRetrieve') } data-e2e="quoteRefToRetrieveInput"/>
                  <PrimaryButton text={ getLocalizedString('quotationDetailsScrn.button.find') } disabled={ !quoteRefToRetrieve.length } onClick={ () => retrieveGlobalQuotation(quoteRefToRetrieve) } datae2e="retrieveGlobalQuotationButton"/>
                </div>
                {
                  !invalidQuoteRef ? <small className={ quoteRefToRetrieve && !isQuoteRefValid ? 'globalQuotationContainer_retrieveQuote-invalid' : '' }>Enter a valid code</small>
                    : <small className="globalQuotationContainer_retrieveQuote-invalid">No quotation with quote ref: {invalidQuoteRef} found.</small>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="noProductsInQuotation">
          {/* Right column, but first in mobile view */}
          {!isLoggedInOrhasValidLongSessionToken && <div className="col-sm-4 col-sm-push-8 col-md-3 col-md-push-9 col-lg-2 col-lg-push-10
            globalQuotationContainer_loginImageContainer
            ">
            <a href="/info/zoro-business-account"
            >
              <picture>
                <source srcSet={ `${LOGIN_IMAGE_MOBILE} 1x` } media="(max-width: 425px)" />
                <img
                  className="logintosaveImage globalQuotationContainer_businessAccountImage"
                  srcSet={ `${LOGIN_IMAGE_DESKTOP} ` }
                  alt="Business Accounts"
                  title="Business Accounts"/>
              </picture>
            </a>
          </div>}
          {/* Left column, but second in mobile view */}
          <div className={ !isLoggedInOrhasValidLongSessionToken ? `col-sm-8 col-sm-pull-4 col-md-9 col-md-pull-3 col-lg-10 col-lg-pull-2 globalQuotationContainer_titleContainer` : `col-sm-12` }>
            <h1 className="globalQuotationContainer_h1">{ getLocalizedString('globalQuotation.noProducts.howQuotationWorks') }</h1>
            <h2 className="noProductsInQuotation_descriptionTitle">{ getLocalizedString('globalQuotation.noProducts.descriptionTitle1') }</h2>
            <ul className="noProductsInQuotation_list">
              { [...Array(NUMBER_OF_DESCRIPTION_SUBPOINTS).keys()].map(point => <li key={ point } className="noProductsInQuotation_list-item"><b>{getLocalizedString(`globalQuotation.noProducts.description.bold.${point + 1}`) }</b>{ getLocalizedString(`globalQuotation.noProducts.description.${point + 1}`) }
                {point + 1 === 4 ? <a href="https://www.zoro.co.uk/info/business-accounts-quotations#"> <b>here</b></a> : <></>}
              </li>) }
            </ul>
          </div>
        </div>
      </div>
      }
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchinQuoteByRef: state.getIn(['globalQuotation', 'isFetchinQuoteByRef'], false),
    isLockingGlobalQuotation: state.getIn(['globalQuotation', 'isLockingGlobalQuotation'], false),
    loggedInCustomerId: state.getIn(['user', 'accountDetails', 'customerId'], ''),
    isReminderVisible: state.getIn(['ui', 'dialogs', 'globalQuoteReminder', 'isVisible'], false),
    quoteSearches: state.getIn(['globalQuotation', 'quotesNotFound'], fromJS({})),
    isQuoteRefValid: state.getIn(['globalQuotation', 'isQuoteRefValid'], true),
    invalidQuoteRef: state.getIn(['globalQuotation', 'invalidQuoteRef'], ''),
    globalQuotation: state.getIn(['globalQuotation', 'createdQuotation'], fromJS({})),
    quoteRefToRetrieve: state.getIn(['globalQuotation', 'quoteRefToRetrieve'], ''),
    isLoggedIn: state.getIn(['auth', 'isLoggedIn']),
    isLoggedInOrhasValidLongSessionToken: state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken']),
    includeVat: state.getIn(['user', 'isPriceWithVat']),
    isQuotationAlertsEnabled: state.getIn(['user', 'accountDetails', 'marketingPreferences', 'quotationAlerts'], null)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isGlobalQuotePaidOnAccount: () => dispatch(isGlobalQuotePaidOnAccount()),
    toastInfo: (message, position, duration) => dispatch(toastInfo(message, position, duration)),
    showSavingQuoteDialog: () => dispatch(showSavingQuoteDialog()),
    showLoginDialog: () => dispatch(showLoginDialog()),
    fetchQuotationProductStockInfo: (quotationId, sku, quantity, isGlobal) => dispatch(fetchQuotationProductStockInfo(quotationId, sku, quantity, isGlobal)),
    showGlobalQuoteRefReminder: (nextPathToNavigate) => dispatch(showGlobalQuoteRefReminder(nextPathToNavigate)),
    mergeGlobalQuotes: (quotationId) => dispatch(mergeGlobalQuotes(quotationId)),
    showCustomAlertDialog: (message) => dispatch(showCustomAlertDialog(message)),
    sendQuotationEmail: (quotationId) => dispatch(sendQuotationEmail(quotationId)),
    updateField: (value, fieldName) => dispatch(updateField(value, fieldName)),
    retrieveGlobalQuotation: (quoteRef) => dispatch(retrieveGlobalQuotation(quoteRef)),
    clearAddedProductToQuotation: () => dispatch(clearAddedProductToQuotation()),
    lockGlobalQuotation: (quotationId, isLockingQuote, isLoggedInOrhasValidLongSessionToken) => dispatch(lockGlobalQuotation(quotationId, isLockingQuote, isLoggedInOrhasValidLongSessionToken)),
    changeGlobalQuotationQty: (prevQty, quotationId, sku, qty, breakPrices) => dispatch(changeGlobalQuotationQty(prevQty, quotationId, sku, qty, breakPrices)),
    fetchGlobalQuotationByIdOrQuoteRef: (quoteRef, redirectToGlobalQuotation) => dispatch(fetchGlobalQuotationByIdOrQuoteRef(quoteRef, redirectToGlobalQuotation)),
    fetchGlobalQuotation: () => dispatch(fetchGlobalQuotation()),
    removeProductFromGlobalQuotationConfirm: (quotationId, product, isLastItem) => dispatch(removeProductFromGlobalQuotationConfirm(quotationId, product, isLastItem)),
    updateMarketingPreferences: (preferencePayload) => dispatch(updateMarketingPreferences(preferencePayload)),
    toastSuccess: (message, position, duration) => dispatch(toastSuccess(message, position, duration)),
    addQuoteLineToBasketAction: (quoteId) => dispatch(addQuoteLineToBasket(quoteId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalQuotationScreen));
export { GlobalQuotationScreen };
