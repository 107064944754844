import PropTypes from 'prop-types';
import React from 'react';

// NOTE This component should be used for all non Bootstrap buttons.
// NOTE: There are main purposes
//  1. Automatically call preventDefault() on interaction events with links to avoid having to have
//  event.preventDefault() in more than one location in the codebase.
//  2. Add onTap as a device agnostic way of expressing a touch/click interaction and remove the
//  300ms delay on mobile devices. (The 300ms fix is yet to be implemented)
const TapButton = ({ children, onTap, id, styles, dataE2e }) => (
  <a
    id={ id }
    className={ `TapButton ${styles}` }
    href="#"
    data-e2e={ dataE2e }
    onClick={ (event) => {
      event.preventDefault();
      if (onTap) {
        onTap();
      }
    } }
  >
    { children }
  </a>
);

TapButton.propTypes = {
  onTap: PropTypes.func
};

TapButton.defaultProps = {
  styles: ''
};

export default TapButton;
