import {
  SELECTED_INDEX,
  RESET_SELECTED_INDEX,
  ALL_TABS,
  GO_TO_SELECTED_TAB
} from 'client/actions/ui/selectedIndexActions';
import { fromJS } from 'immutable';
import isEqual from 'lodash/isEqual';
import { CREATE_NEW_SUBSCRIPTION } from 'client/actions/repeatOrdersActions';

const defaultState = fromJS({
  productTabs: {
    selectedIndex: -1
  }
});

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SELECTED_INDEX:
      const prevSelectedIndex = state.getIn(['selectedIndex']);
      return state.setIn(['selectedIndex'], payload === prevSelectedIndex ? -1 : payload);

    case RESET_SELECTED_INDEX:
      return state.setIn(['selectedIndex'], -1);

    case CREATE_NEW_SUBSCRIPTION:
      return state.setIn(['selectedIndex'], 'addToSubscription');

    case GO_TO_SELECTED_TAB:
      const allIndexes = state.get('allIndexes');
      const selectedIndex = allIndexes.find(item => item.id === payload);
      return state.set('selectedIndex', selectedIndex.id);

    case ALL_TABS:

      const indexes = payload.map((item, index) => {
        return {
          id: item.id,
          index: index
        };
      });
      if (!isEqual(state.get('allIndexes'), indexes)) {
        return state.setIn(['allIndexes'], indexes);
      }

    default:
      return state;
  }
};
