import { createAction } from 'redux-actions';

export const RECEIVE_LOAD_PRODUCT_VARIANT_CACHE = 'productVariantActions.RECEIVE_LOAD_PRODUCT_VARIANT_CACHE';
export const RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS = 'productVariantActions.RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS';
export const RECIEVE_LOAD_PRODUCT_VARIANT_ERROR = 'productVariantActions.RECIEVE_LOAD_PRODUCT_VARIANT_ERROR';
export const recieveLoadProductCache = createAction(RECEIVE_LOAD_PRODUCT_VARIANT_CACHE);
export const recieveLoadProductSuccess = createAction(RECIEVE_LOAD_PRODUCT_VARIANT_SUCCESS);
export const recieveLoadProductError = createAction(RECIEVE_LOAD_PRODUCT_VARIANT_ERROR);

export const RECOMMENDED_LINK_CLICKED = 'RECOMMENDED_LINK_CLICKED';
export const recommendedLinkClicked = createAction(RECOMMENDED_LINK_CLICKED);

export const UPDATE_PRICE_WITH_BREAK_PRICE = 'UPDATE_PRICE_WITH_BREAK_PRICE';
export const updatePriceWithBreakPrice = createAction(UPDATE_PRICE_WITH_BREAK_PRICE);

export const SCROLL_TO_THE_TOP = 'SCROLL_TO_THE_TOP';
export const scrollToTop = createAction(SCROLL_TO_THE_TOP);

export const SCROLL_TO_REPEAT_ORDER_SECTION = 'SCROLL_TO_REPEAT_ORDER_SECTION';
export const scrollToRepeatOrderSection = createAction(SCROLL_TO_REPEAT_ORDER_SECTION);

export const RESET_SCROLLS = 'RESET_SCROLLS';
export const resetScrolls = createAction(RESET_SCROLLS);
