import React from 'react';
import { connect } from 'react-redux';
import {
  clearAddedProductToQuotation,
  getProductBySku,
  addToQuote
} from 'client/actions/globalQuotationActions';
import ProductLine from 'client/components/elements/productLine/ProductLine';
import { PrimaryButton, PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import FamilyBottomRecommendations from 'client/components/elements/recommendations/composers/FamilyBottom';
import { RECOMMENDATIONS_LOCATIONS } from 'shared/constants/recommendation';
import { addToCart } from 'client/actions/cartActions';
import { gotoExternalUrl } from 'client/actions/routeActions';
import { fetchQuotations, addProductsToQuotation } from 'client/actions/quotationsActions';
import { fromJS } from 'immutable';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';
import { LOGIN_IMAGE_DESKTOP, LOGIN_IMAGE_MOBILE } from 'shared/constants/quotation';

if (process.browser) {
  require('./addToQuotationInterstitial.scss');
}
const NUMBER_OF_DESCRIPTION_SUBPOINTS = 4;

const sortExistingQuotes = (a, b) => {
  return new Date(b.get('createdAt')) - new Date(a.get('createdAt'));
};

class AddToQuotationInterstitial extends React.PureComponent {
  componentDidMount () {
    const sku = this.props.location.pathname.split('/add-to-quotation/')[1];
    const quantity = this.props.location.query.qty;
    this.props.getProductBySku(sku, quantity);
    if (this.props.isLoggedInOrhasValidLongSessionToken) {
      this.props.fetchQuotations();
    }
  }

  render () {
    const {
      productLineItems,
      location,
      isLoggedInOrhasValidLongSessionToken,
      addToCart,
      existingQuotations,
      addProductsToQuotation,
      addToQuote,
      loadingGlobalQuote,
      gotoExternalUrl,
      includeVat
    } = this.props;
    const sku = location.pathname.split('/add-to-quotation/')[1];
    const quantity = location.query.qty;
    const product = productLineItems.get(sku);
    const backToProductPageUrl = productLineItems.get(sku) ? product.get('productUrl') : '/shop';
    const nonRefundableType = productLineItems.getIn([sku, 'nonRefundableType']);
    const productToAddToQuotation = {
      name: productLineItems.getIn([sku, 'name']),
      sku: productLineItems.getIn([sku, 'sku']),
      img: productLineItems.getIn([sku, 'imageSrc']),
      qty: location.query.qty
    };

    const addToCurrentQuotation = () => {
      const productToAdd = {
        sku: productToAddToQuotation.sku,
        qty: productToAddToQuotation.qty
      };
      addToQuote(productToAdd, true);
    };

    const addToSavedQuote = (quotationId) => {
      const productToAdd = {
        sku: productToAddToQuotation.sku,
        qty: productToAddToQuotation.qty
      };
      addProductsToQuotation(quotationId, [productToAdd]);
      gotoExternalUrl('/my-account/quotations');
    };

    return productLineItems.get(sku) ? <React.Fragment>
      <div className="AddToQuotationInterstitial row">
        <div className="row AddToQuotationInterstitial_header col-md-12">
          {
            !isLoggedInOrhasValidLongSessionToken || (isLoggedInOrhasValidLongSessionToken && !existingQuotations.size) ? <React.Fragment>
              <div className="col-xs-12 ">
                <div className="row">
                  <div className="col-xs-12 col-sm-5">
                    <SvgLoader xlinkHref="#right-check-icon" className="AddToQuotationInterstitial_rightCheckIcon" />
                    <span className="AddToQuotationInterstitial_addToQuotationText"> {getLocalizedString('globalQuotation.addedToQuotation') }</span>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-3">
                    <a href={ backToProductPageUrl } className="AddToQuotationInterstitial_toProductPage">Continue Shopping</a>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-2 pull-right goToCheckout">
                    <PrimaryLink to='/global-quotation' text="View Quotation" className="AddToQuotationInterstitial_toQuotation"/>
                  </div>
                </div>
              </div>

            </React.Fragment> : <React.Fragment>
              <div className="col-xs-12 ">

                <div className="col-xs-12  col-md-2 col-lg-2 AddToQuotationInterstitial_selectQuotationLableContainer">
                  <h2 className=" AddToQuotationInterstitial_selectQuotationLable">
                    {getLocalizedString('globalQuotation.selectQuotation') }</h2>
                </div>
                <div className="col-xs-12  col-md-8 col-lg-8">
                  <PrimaryButton disabled={ loadingGlobalQuote } className="AddToQuotationInterstitial_addToQuoteDialogButton" onClick={ () => addToCurrentQuotation() }>
                    { !loadingGlobalQuote ? 'Add to Quotation' : <div className="AddToQuotationInterstitial_addToQuoteDialogButton-loading"/> }
                  </PrimaryButton>
                  {
                    existingQuotations && existingQuotations.size ? <React.Fragment>
                      <span className="AddToQuotationInterstitial_orText">OR</span>
                      <DropdownButton
                        id="sort-by-in-reviews"
                        className="AddToQuotationInterstitial_addToSavedQuote"
                        title="Add to saved quote"
                        onSelect={ (value) => addToSavedQuote(value) }
                      >
                        {
                          existingQuotations.sort(sortExistingQuotes).map((quote) => {
                            const quoteId = quote.get('_id');
                            return (<MenuItem

                              eventKey={ quoteId }
                              key={ quoteId }
                              value={ quoteId }
                              data-e2e={ quoteId }
                              style={{ outline: 'none', textDecoration: 'none' }}
                            >
                              { quote.get('name') }
                            </MenuItem>
                            );
                          })
                        }
                      </DropdownButton>
                    </React.Fragment> : null
                  }
                </div>
              </div>

            </React.Fragment>
          }
        </div>
        <div className="AddToQuotationInterstitial_productLine col-md-12">
          <ProductLine
            sku={ sku }
            isAvailable={ true }
            isEditable={ false }
            quantity={ Number(quantity) }
            onQuantityChange={ () => {} }
            onRemove={ () => {} }
            isIncludingVat={ includeVat }
            hideStockMessages={ false }
            hideSavingsLabels={ false }
            nonRefundableType= { nonRefundableType }
          />
        </div>
        <div className="noProductsInQuotation">
          {!isLoggedInOrhasValidLongSessionToken && <div className="col-sm-4 col-sm-push-8 col-md-3 col-md-push-9 col-lg-2 col-lg-push-10
            AddToQuotationInterstitial_loginImageContainer
            ">
            <a href="/info/zoro-business-account"
            >
              <picture>
                <source srcSet={ `${LOGIN_IMAGE_MOBILE} 1x` } media="(max-width: 425px)" />
                <img
                  className="logintosaveImage AddToQuotationInterstitial_businessAccountImage"
                  srcSet={ `${LOGIN_IMAGE_DESKTOP} ` }
                  alt="Business Accounts"
                  title="Business Accounts"/>
              </picture>
            </a>
          </div>}
          <div className={ !isLoggedInOrhasValidLongSessionToken ? `col-sm-8 col-sm-pull-4 col-md-9 col-md-pull-3 col-lg-10 col-lg-pull-2 noProductsInQuotation_titleContainer` : `col-sm-12` }>
            <h1>{ getLocalizedString('globalQuotation.noProducts.howQuotationWorks') }</h1>
            <h2 className="noProductsInQuotation_descriptionTitle">{ getLocalizedString('globalQuotation.noProducts.descriptionTitle1') }</h2>
            <ul className="noProductsInQuotation_list">
              { [...Array(NUMBER_OF_DESCRIPTION_SUBPOINTS).keys()].map(point => <li key={ point } className="noProductsInQuotation_list-item"><b>{getLocalizedString(`globalQuotation.noProducts.description.bold.${point + 1}`) }</b>{ getLocalizedString(`globalQuotation.noProducts.description.${point + 1}`) }
                {point + 1 === 4 ? <a href="https://www.zoro.co.uk/info/business-accounts-quotations#"> <b>here</b></a> : <></>}
              </li>) }
            </ul>
          </div>
        </div>
        <div className="col-xs-12">
          <FamilyBottomRecommendations
            addToCart={ addToCart }
            location={ RECOMMENDATIONS_LOCATIONS.add_to_quotation_interstitial }
            isPriceWithVat={ includeVat }
          />
        </div>
      </div>
    </React.Fragment> : <div className="AddToQuotationInterstitial_loading" />;
  }
}

const mapStateToProps = (state) => ({
  productLineItems: state.get('productLineItems'),
  loadingGlobalQuote: state.getIn(['globalQuotation', 'loadingGlobalQuote'], false),
  existingQuotations: state.getIn(['quotations', 'all'], fromJS([])),
  isLoggedInOrhasValidLongSessionToken: state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken']),
  isLoggedIn: state.getIn(['auth', 'isLoggedIn']),

  includeVat: state.getIn(['user', 'isPriceWithVat'])
});

const mapDispatchToProps = (dispatch) => {
  return {
    addToQuote: (product, redirectToGlobalQuotation) => dispatch(addToQuote(product, redirectToGlobalQuotation)),
    getProductBySku: (sku, quantity) => dispatch(getProductBySku(sku, quantity)),
    clearAddedProductToQuotation: () => dispatch(clearAddedProductToQuotation()),
    addToCart: (orderLinesToBeAdded) => dispatch(addToCart(orderLinesToBeAdded)),
    fetchQuotations: () => dispatch(fetchQuotations()),
    gotoExternalUrl: (url) => dispatch(gotoExternalUrl(url)),
    addProductsToQuotation: (quotationId, products) => dispatch(addProductsToQuotation(quotationId, products))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToQuotationInterstitial);
