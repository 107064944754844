import { put, takeLatest, call, take } from 'redux-saga/effects';
import {
  LOAD_PRODUCTS_BY_FAMILIES,
  STORE_FAMILIES_BY_CATEGORY,
  storeFamiliesByCategory,
  setCategoryFamilyLoading
} from 'client/actions/categoryFamiliesActions';
import { getProductFamilyProducts } from 'shared/endpoints/productFamilyEndpoint';
import groupBy from 'lodash/groupBy';
import { fromJS } from 'immutable';
import { sendImpressionsToDataLayer } from 'shared/utils/sagaUtils.js';

export function * categoryFamiliesChannelHandler (channel) {
  while (true) {
    const action = yield take(channel);
    yield put(setCategoryFamilyLoading());
    yield call(fetchProductsForFamilies, action);
  }
}

export const fetchProductsForFamilies = function * fetchFamiliesByCategoryId ({ categoryId, families, skip, take, location }) {
  const totalCount = families.size;
  const productSkus = families.toSeq()
    .skip(skip)
    .take(take)
    .map((f) => f.get('topProduct'))
    .flatten()
    .toArray();

  const productsForAllFamilies = yield call(getProductFamilyProducts, productSkus);
  const prodsGroupedByFamilyId = fromJS(groupBy(productsForAllFamilies, (prod) => prod.family.familyId));
  const familiesWithProductInfo = families.map((fam) => {
    const prodsForFam = prodsGroupedByFamilyId.get(fam.get('id') + '');
    // TODO: check why this fails sometimes (prodsForFam is null/undefined)
    return fam.set('productsInfo', prodsForFam);
  });

  yield put(storeFamiliesByCategory(categoryId, familiesWithProductInfo, totalCount, location));
};

export function * watchLoadFamiliesByCategory () {
  yield takeLatest(LOAD_PRODUCTS_BY_FAMILIES, fetchProductsForFamilies);
}

export const handleStoreFamiliesByCategory = function * handleStoreFamiliesByCategory ({ families, location }) {
  const impressionListName = (location && location.query && location.query.query) ? 'Search Result' : 'Category List';
  yield * sendImpressionsToDataLayer(families, impressionListName);
};

export function * watchStoreFamiliesByCategory () {
  yield takeLatest(STORE_FAMILIES_BY_CATEGORY, handleStoreFamiliesByCategory);
}
