import React, { useEffect } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { Heading } from '@graingerglobalonline/curly-eureka';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';

import {
  PAY_BY_ACCOUNT,
  PAY_BY_EXISTING_CARD,
  PAY_BY_PAYPAL,
  PAY_BY_PAYPAL_ICON_LINK,
  PAY_BY_ACCOUNT_ICON_LINK,
  PAY_BY_CARD_ICON_LINK,
  PAY_BY_NEW_CARD,
  DEFAULT_PAYMENT_METHOD,
  PAY_BY_APPLE_PAY_ICON_LINK,
  PAY_BY_APPLE_PAY,
  PAY_BY_GOOGLE_PAY_ICON_LINK,
  PAY_BY_GOOGLE_PAY
} from './constants';
import {
  ACCOUNT,
  CARD,
  PAYPAL,
  GOOGLE_PAY
} from 'shared/constants/braintree';

import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { filterPaymentOptions } from '../shared/paymentHelpers';
import { RequestCreditLimitIncrease } from '../RequestCreditLimit/RequestCreditLimitIncrease';
import { ACTIVE } from '../../../screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { APPROVED } from '../../../../../shared/constants/account';
import { TOP_BOX_MESSAGE } from 'client/components/elements/paymentMethod/PaymentMethod/constants';

if (process.browser) {
  require('./paymentTypeSelector.scss');
}

export const ICON_MAP = {
  PAY_BY_PAYPAL: PAY_BY_PAYPAL_ICON_LINK,
  PAY_BY_ACCOUNT: PAY_BY_ACCOUNT_ICON_LINK,
  PAY_BY_NEW_CARD: PAY_BY_CARD_ICON_LINK,
  PAY_BY_EXISTING_CARD: PAY_BY_CARD_ICON_LINK,
  PAY_BY_APPLE_PAY: PAY_BY_APPLE_PAY_ICON_LINK,
  PAY_BY_GOOGLE_PAY: PAY_BY_GOOGLE_PAY_ICON_LINK
};

const DEFAULT_PAYMENT_METHOD_MAP = {
  [ACCOUNT]: PAY_BY_ACCOUNT,
  [CARD]: PAY_BY_EXISTING_CARD,
  [PAYPAL]: PAY_BY_PAYPAL,
  [GOOGLE_PAY]: PAY_BY_GOOGLE_PAY
};

export const PaymentTypeSelector = ({
  selector,
  text,
  checked,
  paymentType,
  onClick,
  disablePayOnAccountButton
}) => {
  const isCheckout = selector === CHECKOUT_SELECTOR;
  const isApplePay = paymentType === PAY_BY_APPLE_PAY;
  const isApplePaySupported = isApplePay && typeof window !== 'undefined' && !window.ApplePaySession;
  return (
    !isApplePaySupported ? (
      <div className={ `PaymentTypeSelector col-md-4 ${disablePayOnAccountButton ? 'PaymentTypeSelector_BasketExceedByLimit' : ''} ${(isCheckout && checked) ? 'PaymentTypeSelector_containerChecked' : ''} ${isCheckout ? 'PaymentTypeSelector_checkout' : ''}` } onClick={ onClick }>
        {!isCheckout ? <div className="PaymentTypeSelector_selector">
          {checked ? (
            <SvgLoader
              xlinkHref="#payment-option-checked"
              className="SearchIcon"
            />
          ) : (
            <SvgLoader
              xlinkHref="#payment-option-unchecked"
              className="SearchIcon"
            />
          )}
        </div> : null }
        <div className="PaymentTypeSelector_text">
          <Heading variant="small">{text}</Heading>
        </div>
        <div className="PaymentTypeSelector_img">
          <SvgLoader
            xlinkHref={ `#${ICON_MAP[paymentType]}` }
            className="SearchIcon"
          />
        </div>
      </div>
    ) : null
  );
};

export const PaymentOptionsWrapper = ({
  onChangePaymentType,
  initialPaymentType,
  selector,
  showPayOnAccountButton,
  showApplePay,
  showGooglePay,
  defaultPaymentMethod,
  tradeAccount,
  cartPrice,
  requestCreditLimitData,
  handleRequestLimitIncrease,
  disablePayOnAccountButton,
  accountStatusMessage,
  messagePosition
}) => {
  const isCheckout = selector === CHECKOUT_SELECTOR;
  const isDefaultPayment = selector === DEFAULT_PAYMENT_METHOD;
  const isNewCardPayment = initialPaymentType === PAY_BY_NEW_CARD;
  const paymentOptions = filterPaymentOptions(showApplePay, showGooglePay);
  const accountUserStatus = tradeAccount ? tradeAccount.getIn(['accountUser', 'status']) : null;
  const tradeAccountStatus = tradeAccount ? tradeAccount.get('status') : null;
  const isApprovedActiveAccount = accountUserStatus === ACTIVE && tradeAccountStatus === APPROVED;
  useEffect(() => {
    if (isCheckout) {
      if (disablePayOnAccountButton && initialPaymentType === PAY_BY_ACCOUNT && isApprovedActiveAccount) {
        onChangePaymentType(PAY_BY_EXISTING_CARD);
      } else if (initialPaymentType !== PAY_BY_ACCOUNT && tradeAccountStatus) {
        onChangePaymentType(PAY_BY_ACCOUNT);
      }
    }
  }, [disablePayOnAccountButton]);
  return (
    <div className="DisplayPaymentOptions_wrapper">
      {(disablePayOnAccountButton && isCheckout && messagePosition === TOP_BOX_MESSAGE) ? <RequestCreditLimitIncrease { ...{ tradeAccount, cartPrice, handleRequestLimitIncrease, requestCreditLimitData, disablePayOnAccountButton, accountStatusMessage } } /> : null}
      {showPayOnAccountButton !== null ? paymentOptions.map((values) => {
        const hidePayOnAccount = (values.paymentType === PAY_BY_ACCOUNT && (!showPayOnAccountButton || (!isCheckout && disablePayOnAccountButton)));
        const hidePayPalOption = (values.paymentType === PAY_BY_PAYPAL && !isCheckout && !isDefaultPayment);
        const hideApplePayOption = (values.paymentType === PAY_BY_APPLE_PAY && !isCheckout);
        const hideGooglePayOption = (values.paymentType === PAY_BY_GOOGLE_PAY && !isCheckout);
        const hidePaymentOption = hidePayPalOption || hidePayOnAccount || hideApplePayOption || hideGooglePayOption;
        const disableAccountButton = disablePayOnAccountButton && values.paymentType === PAY_BY_ACCOUNT && isCheckout;

        const isChecked = isDefaultPayment
          ? DEFAULT_PAYMENT_METHOD_MAP[defaultPaymentMethod] === values.paymentType
          : initialPaymentType === values.paymentType || (isNewCardPayment && values.paymentType === PAY_BY_EXISTING_CARD);

        return !hidePaymentOption ? (
          <PaymentTypeSelector
            selector={ selector }
            checked={ isChecked }
            key={ `${values.text}_${values.paymentType}` }
            text={ values.text }
            paymentType={ values.paymentType }
            onClick={ () => onChangePaymentType(values.paymentType) }
            disablePayOnAccountButton={ disableAccountButton }
          />
        ) : null;
      }) : <ScreenLoadPending className="smallLoader" /> }
    </div>
  );
};
