import { fromJS } from 'immutable';

const defaultState = fromJS({
  on: true
});

export default (state = defaultState, { type, on }) =>
  (type === 'TOGGLER_STATE_CHANGED')
    ? state.set('on', on)
    : state;
