import React from 'react';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import isEmailValidator from 'validator/lib/isEmail';
import { NON_DELIVERABLE_TO_REGEXES } from '../../shared/constants/orderSummaryConstants';

// const SAGEPAY_TEST_POSTCODE = '412';
const ADDRESSLINE_LENGTH = 30;
const ONLY_ZERO_REGEX = /^00+$/;
const ONLY_DECIMAL_NUMBER_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/;

export const isMaxLength = (errorMessage, max) => ({ maxLength: (str) => (str + '').length <= max || errorMessage });

export function validationHelper (field) {
  return {
    addClass: () => field.help && field.error && 'has-error',
    addMessage: () =>
      field.help && field.error
        ? (<span className="help-block" key="help">{ field.help }</span>)
        : null
  };
}

export const isValidEmail = (errorMessage) => ({
  validEmail: (value) => {
    if (value.includes(',')) {
      const emails = value.split(',');
      const emailCheck = emails.filter((email) => {
        return !isEmailValidator(email.trim());
      });
      if (emailCheck.length >= 1) {
        return errorMessage;
      }
      return true;
    }
    return isEmailValidator(value) || errorMessage;
  }
});

// isRequired fails when no value or only whitespaces are entered
export function isRequired (config) {
  let field, message;
  if (typeof config === 'object') {
    field = config.field;
    message = config.message;
  } else {
    field = config;
  }

  if (!message) {
    message = getLocalizedString('error.required').replace('{field}', field);
  }

  return function (value) {
    const isDefined = (typeof value === 'string') ? !!value.trim() : !!value;

    if (!isDefined) {
      return message;
    }
  };
}

export function isRequiredIf (predicate, config) {
  return function (value, values) {
    if (predicate(values)) return isRequired(config)(value);
  };
}

export function isNumberRequiredIf (predicate, config) {
  return function (value, values) {
    if (predicate(values)) return isNumber(config)(value);
  };
}

const validateEmail = (email) => {
  return isEmailValidator(email, { allow_utf8_local_part: false });
};

export function isEmailAddressesList (existingEmails) {
  return function (value) {
    if (!value) {
      return getLocalizedString('error.invalid.email');
    }

    const emailAddresses = [...value.split(','), ...(existingEmails || [])]
      .map((email) => email.toLowerCase());

    const areEmailsInvalid = emailAddresses
      .some((value) => value.includes(',') || !value || !validateEmail(value));

    if (areEmailsInvalid) {
      return getLocalizedString('error.invalid.email');
    }

    const isDuplicatedEmail = value && emailAddresses.filter(el => el).length !== new Set(emailAddresses.filter(el => el)).size;
    if (isDuplicatedEmail) {
      return 'Entered duplicate value';
    }
  };
}
export function isValidEmailAddress () {
  return function (value) {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]{2,}.[A-Za-z0-9]{2,6}$/;
    if (!regex.test(value)) {
      return false;
    }
    return true;
  };
}
export function isValidEmailForNewUser () {
  return function (value) {
    if (value && !isValidEmailAddress()(value)) {
      return getLocalizedString('error.invalid.email');
    }
  };
}

export function isEmail () {
  return function (value) {
    if (value && !validateEmail(value)) {
      return getLocalizedString('error.invalid.email');
    }
  };
}
export function isNumber () {
  return function (value) {
    const isANumber = !isNaN(value);
    const isPositiveNumber = isANumber && Number(value) > 0;
    if (!isANumber || !isPositiveNumber) {
      return getLocalizedString('error.invalid.number');
    }
  };
}

export function isAPositiveNumber () {
  return function (value) {
    const isANumber = !isNaN(value);
    const isPositiveNumber = isANumber && Number(value) >= 0;
    if ((value && (!isANumber || !isPositiveNumber))) {
      return getLocalizedString('error.invalid.positive.number');
    }
  };
}

export const isPositiveNumber = () => (value) => {
  if (ONLY_DECIMAL_NUMBER_REGEX.test(value) && value >= 0) {
    return;
  }

  return getLocalizedString('error.invalid.positive.number');
};

export const isZeroRepeated = (errorMessage) => (value) => {
  if (ONLY_ZERO_REGEX.test(value)) {
    return errorMessage;
  }
};

export const isWholeNumber = (errorMessage) => (value) => {
  if (ONLY_DECIMAL_NUMBER_REGEX.test(value) && value >= 0 && value % 1 === 0) {
    return;
  }
  return errorMessage;
};

export function isValidOrderReferenceNumber () {
  return function (value) {
    if (!value || value === '') {
      return;
    }
    const regex = /^CD-[0-9]{6}-[0-9]{6}$/;
    const orderRef = regex.test(value);
    if (!orderRef) {
      return getLocalizedString('error.invalid.orderReferenceNumber');
    }
  };
}

export function minValue (min) {
  return function (value) {
    if (parseInt(value, 10) < min) {
      return getLocalizedString('error.minValue')
        .replace('{minValue}', min);
    }
  };
}

export function maxValue (max) {
  return function (value) {
    if (parseInt(value, 10) > max) {
      return getLocalizedString('error.maxValue')
        .replace('{maxValue}', max);
    }
  };
}

export function validateUKPostCode (postcode) {
  const exp = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

  if (!exp.test(postcode)) {
    return false;
  }

  return true;
}

export const checkNonDeliverablePostCode = (postCode) => {
  /* Jersey or Guernsey post codes are non deliverable and
  their post codes starts with "JE" & "GY" respectively */
  const exp = /\b(je|gy)/i;

  return exp.test(postCode);
};

export function isCompanyRegNoIf (predicate) {
  return function (value, values) {
    if (predicate(values)) return isCompanyRegNo(value);
  };
}

export function validateCompanyRegNo (companyName) {
  const ValidCompanyRegExp = /^(((AC|CE|CS|FC|FE|GE|GS|IC|LP|NC|NF|NI|NL|NO|NP|OC|OE|PC|R0|RC|SA|SC|SE|SF|SG|SI|SL|SO|SR|SZ|ZC|\d{2})\d{6})|((IP|SP|RS)[A-Z\d]{6})|(SL\d{5}[\dA]))$/i;
  return (ValidCompanyRegExp.test(companyName));
}

export function isCompanyRegNo (value) {
  if (!validateCompanyRegNo(value)) {
    return getLocalizedString('error.invalid.companyRegNo');
  }
}

export function isVatRegNoIf (predicate) {
  return function (value, values) {
    if (predicate(values)) return isVatRegNo(value);
  };
}

export function isVatRegNo (value) {
  if (!validateVatRegNo(value)) {
    return getLocalizedString('error.invalid.vatRegNo');
  }
}

function validateVatRegNo (vatRegNo) {
  const exp1 = /^GB\d{9}$/i;
  const exp2 = /^IE[\w]\d{8}$/i;
  return (exp1.test(vatRegNo) || exp2.test(vatRegNo));
}

function validateUKPhoneNumber (phoneNumber) {
  // eslint-disable-next-line
  const exp = /^[0-9+ \-\(\)]{7,15}$/;
  return exp.test(phoneNumber);
}

export function isValidPostCode () {
  return (value) => {
    // Check for Non Deliverable post code
    if (checkNonDeliverablePostCode(value)) {
      return getLocalizedString('postCodeInput.error.nonDeliverablePostCode');
    }
    //
    if (!validateUKPostCode(value)) {
      return getLocalizedString('error.invalid.postCode');
    }
  };
}

export function isUKPhoneNumber () {
  return (value) => {
    if (value && !validateUKPhoneNumber(value)) {
      return getLocalizedString('error.invalid.phoneNumber');
    }
  };
}

export function minLength (config) {
  return (value) => {
    if (value && value.length < config.minLength) {
      return getLocalizedString('error.minLength')
        .replace('{field}', config.fieldName)
        .replace('{minLength}', config.minLength);
    }
  };
}

export function maxLength (config) {
  return (value) => {
    if (value && value.length > config.maxLength) {
      return getLocalizedString('error.maxLength')
        .replace('{field}', config.fieldName)
        .replace('{maxLength}', config.maxLength);
    }
  };
}

export function maxLengthWithCustomError (config) {
  return (value) => {
    if (value && value.length > config.maxLength) {
      return config.errorMessage;
    }
  };
}

export function matches (config) {
  return (value, values) => {
    if (value !== values.get(config.otherField)) {
      return config.message ? config.message : getLocalizedString('error.match')
        .replace('{field}', config.fieldName)
        .replace('{matchField}', config.otherFieldName);
    }
  };
}

export function passwordValidator (config) {
  return (value) => {
    if (value && !(/\d+/.test(value))) {
      return getLocalizedStringWithParam('error.password.atleastOne', { field: config.fieldName, type: 'number' });
    } else if (value && !(/[a-z]+/.test(value))) {
      return getLocalizedStringWithParam('error.password.atleastOne', { field: config.fieldName, type: 'lowercase character' });
    } else if (value && !(/[A-Z]+/.test(value))) {
      return getLocalizedStringWithParam('error.password.atleastOne', { field: config.fieldName, type: 'uppercase character' });
    } else if (value && !(/[!@#$%^&*()-.,£+]+/.test(value))) {
      return getLocalizedStringWithParam('error.password.special.character', { field: config.fieldName, type: 'special character' });
    }
  };
}

function getErrorMessagesForField (fieldValue, validators, values, field) {
  let errorMessage;
  for (let i = 0; i < validators.length; i++) {
    errorMessage = validators[i](fieldValue, values, field);
    if (errorMessage) {
      break;
    }
  }
  return errorMessage;
}

function getErrorObject (fieldErrors) {
  return fieldErrors.reduce((errors, fieldError) => {
    errors[fieldError.field] = fieldError.errorMessage;
    return errors;
  }, {});
}

export function makeValidator (config) {
  const fields = Object.keys(config);
  return (values) => {
    const fieldErrors = fields.map((field) => {
      return {
        field,
        errorMessage: getErrorMessagesForField(values.get(field), config[field], values, field)
      };
    });
    return getErrorObject(fieldErrors);
  };
}

export function splitAddressToTwoAddressLines (addressesArray) {
  const addressLine1Array = addressesArray.reduce((acc, address) => {
    const line = acc.join(', ');
    return line.length < ADDRESSLINE_LENGTH
      ? [...acc, address]
      : acc;
  }, []);
  const addressLine2Array = addressesArray.slice(addressLine1Array.length);

  return { addressLine1: addressLine1Array.join(', '),
    addressLine2: addressLine2Array.join(', ') };
}

const ADDRESS_LINE_LIMIT = 100;

export const defaultAddressFormValidator = {
  firstName: [
    isRequired(getLocalizedString('addressForm.label.firstName')),
    maxLength({
      maxLength: 15,
      fieldName: getLocalizedString('addressForm.label.firstName')
    })
  ],
  lastName: [
    isRequired(getLocalizedString('addressForm.label.lastName')),
    maxLength({
      maxLength: 15,
      fieldName: getLocalizedString('addressForm.label.lastName')
    })
  ],
  addressName: [
    maxLength({
      maxLength: ADDRESS_LINE_LIMIT,
      fieldName: getLocalizedString('addressForm.label.addressName')
    })
  ],
  companyName: [
    maxLength({
      maxLength: 50,
      fieldName: getLocalizedString('postcodeInput.label.companyName')
    })
  ],
  addressPhoneNumber: [
    isRequired(getLocalizedString('addressForm.label.telephoneNumber')),
    isUKPhoneNumber(getLocalizedString('addressForm.label.telephoneNumber'))
  ],
  addressLine1: [
    isRequired(getLocalizedString('addressForm.label.address')),
    maxLength({
      maxLength: ADDRESS_LINE_LIMIT,
      fieldName: getLocalizedString('addressForm.label.address')
    })
  ],
  addressLine2: [
    maxLength({
      maxLength: ADDRESS_LINE_LIMIT,
      fieldName: getLocalizedString('addressForm.label.address')
    })
  ],
  city: [
    isRequired(getLocalizedString('postcodeInput.label.city')),
    maxLength({
      maxLength: 30,
      fieldName: getLocalizedString('postcodeInput.label.city')
    })
  ],
  state: [
    maxLength({
      maxLength: 50,
      fieldName: getLocalizedString('postcodeInput.label.state')
    })
  ],
  postalCode: [
    isRequired(getLocalizedString('postcodeInput.label.postcode')),
    maxLength({
      maxLength: 10,
      fieldName: getLocalizedString('postcodeInput.label.postcode')
    }),
    isValidPostCode(getLocalizedString('addressForm.label.postcode'))
  ],
  quickFindPostCode: []
};

// checks array, string
export const isRequiredField = (validationMessage) => value => {
  const fieldValue = Array.isArray(value) ? value.length : value;
  return fieldValue ? undefined : validationMessage;
};

export const isNonDeliverableToKey = (key) => {
  return key in NON_DELIVERABLE_TO_REGEXES;
};

export const validateNonDeliverableStatus = (postalCode, products) => {
  const validNonDeliverablePostalCode = Object.values(NON_DELIVERABLE_TO_REGEXES).some(regex => regex.test(postalCode));
  const validNonDeliverableStatus = products?.some(({ nonDeliverableTo }) => {
    return nonDeliverableTo?.some(region => {
      return isNonDeliverableToKey(region) && NON_DELIVERABLE_TO_REGEXES[region].test(postalCode);
    });
  });
  return {
    validNonDeliverablePostalCode,
    validNonDeliverableStatus: validNonDeliverableStatus
  };
};
