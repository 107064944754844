export const GET_INDUSTRIES_MAP = 'GET_INDUSTRIES_MAP';
export const STORE_INDUSTRIES_MAP = 'STORE_INDUSTRIES_MAP';

export const getIndustriesMap = () => ({
  type: GET_INDUSTRIES_MAP
});

export const storeIndustriesMap = (industriesMap) => ({
  type: STORE_INDUSTRIES_MAP,
  industriesMap
});
