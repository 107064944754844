export const REQUEST_REGISTER_CREDIT_ACCOUNT = 'REQUEST_REGISTER_CREDIT_ACCOUNT';
export const RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS = 'RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS';
export const RECEIVE_REGISTER_CREDIT_ACCOUNT_ERROR = 'RECEIVE_REGISTER_CREDIT_ACCOUNT_ERROR';
export const CREDIT_APPLICATION_FORM_ADD_USERS = 'CREDIT_APPLICATION_FORM_ADD_USERS';
export const CREDIT_APPLICATION_FORM_REMOVE_MULTI_USER = 'CREDIT_APPLICATION_FORM_REMOVE_MULTI_USER';
export const INVITE_USERS_TO_TRADE_ACCOUNT = 'INVITE_USERS_TO_TRADE_ACCOUNT';

export const REQUEST_ACCOUNT_ORDER_MESSAGE = 'REQUEST_ACCOUNT_ORDER_MESSAGE';
export const RECEIVE_ACCOUNT_ORDER_MESSAGE_SUCCESS = 'RECEIVE_ACCOUNT_ORDER_MESSAGE_SUCCESS';
export const RECEIVE_BUSINESS_ACCOUNT_ORDER__MESSAGE_ERROR = 'RECEIVE_BUSINESS_ACCOUNT_ORDER__MESSAGE_ERROR';

export function registerCreditAccount (body) {
  return {
    type: REQUEST_REGISTER_CREDIT_ACCOUNT,
    body,
    currentPath: window?.location?.pathname || '/'
  };
}

export function creditAccountRegistrationSuccess (tradeAccount) {
  return {
    type: RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS,
    tradeAccount
  };
}

export function creditAccountRegistrationError (error) {
  return {
    type: RECEIVE_REGISTER_CREDIT_ACCOUNT_ERROR,
    error
  };
}

export const validateBusinessAccountOrder = (cartTotal, quotationId) => ({
  type: REQUEST_ACCOUNT_ORDER_MESSAGE,
  cartTotal,
  quotationId
});

export function validateBusinessAccountOrderSuccess (businessAccountOnHoldMessage) {
  return {
    type: RECEIVE_ACCOUNT_ORDER_MESSAGE_SUCCESS,
    businessAccountOnHoldMessage
  };
}

export function validateBusinessAccountOrderError (error) {
  return {
    type: RECEIVE_BUSINESS_ACCOUNT_ORDER__MESSAGE_ERROR,
    error
  };
}

export function addUsersToTradeAccount (colleaguesEmail, shortMessage, accountRole, spendLimit) {
  return {
    type: CREDIT_APPLICATION_FORM_ADD_USERS,
    colleaguesEmail,
    shortMessage,
    accountRole,
    spendLimit
  };
}

export function removeUserFromList (email) {
  return {
    type: CREDIT_APPLICATION_FORM_REMOVE_MULTI_USER,
    email
  };
}

export function inviteUsersToTradeAcccount (users) {
  return {
    type: INVITE_USERS_TO_TRADE_ACCOUNT,
    users
  };
}
