import { IMAGERESOLUTION, BACKUP_IMAGE } from 'shared/constants/images';
import _get from 'lodash/get';
const HYPHEN = '-';

export const getOrderLineImage = (orderLine, type) => {
  const orderLineImageFor = {
    cart: () => {
      return _get(orderLine, `image.mainPicture.shovelerImage[${IMAGERESOLUTION}]`) ||
      _get(orderLine, `image[0].mainPicture.shovelerImage[${IMAGERESOLUTION}]`, BACKUP_IMAGE);
    },
    quotations: () => {
      const orderLineQuotationImage = _get(orderLine, `mediaContent.mainPicture.productThumbnail[${IMAGERESOLUTION}]`, BACKUP_IMAGE);
      return orderLineQuotationImage || BACKUP_IMAGE;
    },
    bundle: () => {
      const orderLineQuotationImage = _get(orderLine, `mediaContent.mainPicture.shovelerImage[${IMAGERESOLUTION}]`, BACKUP_IMAGE);
      return orderLineQuotationImage || BACKUP_IMAGE;
    }
  };
  return orderLineImageFor[type]();
};

export const toProductLineItems = (data, type) => {
  const productLines = {};
  const productLineTypes = {
    cart: () => {
      data.orderLines.forEach((productLine) => {
        const imageSrcLowResolution = _get(productLine, 'image.mainPicture.productThumbnail.lowResolution') ||
          _get(productLine, 'image[0].mainPicture.productThumbnail.lowResolution', BACKUP_IMAGE);
        const productLineItem = {
          ageRestricted: productLine.ageRestricted,
          fulfillmentType: productLine.fulfillmentType,
          family: productLine.family,
          breakPriceObject: productLine.breakPriceObject,
          imageSrc: getOrderLineImage(productLine, 'cart'),
          imageSrcLowResolution,
          name: productLine.name,
          orderLinePrice: productLine.orderLinePrice,
          orderLineShippingDetails: productLine.orderLineShippingDetails,
          packQty: productLine.packQty,
          qtyMultiplier: productLine.price.qtyMultiplier,
          price: productLine.price,
          productId: productLine.productId,
          quantity: productLine.quantity,
          isDiscountApplied: productLine.isDiscountApplied,
          sku: productLine.sku,
          maxDeliveryDays: productLine.maxDeliveryDays,
          brand: productLine.brand,
          category: productLine.category,
          stock: productLine.stock,
          priceChange: productLine.priceChange,
          isUpdatingQuantity: false,
          deliveryOptionList: productLine.deliveryOptionList,
          selectedDeliveryOption: productLine.selectedDeliveryOption,
          isSearchable: productLine.isSearchable,
          isDiscontinued: productLine.isDiscontinued,
          isAvailableOnLeadTime: productLine.isAvailableOnLeadTime,
          productUrl: productLine.productUrl,
          nonRefundableType: productLine.nonRefundableType,
          scheduledOrder: productLine.scheduledOrder,
          isScheduleOrderAvailable: true
        };
        productLines[productLine.sku] = productLineItem;
      });
      return productLines;
    },
    quotationInterstitial: () => {
      const orderLinePrice = {
        unitPriceWithVat: data.orderLinePrice.unitPriceWithVat,
        unitPriceWithoutVat: data.orderLinePrice.unitPriceWithoutVat,
        orderLinePriceWithVat: data.orderLinePrice.orderLinePriceWithVat,
        orderLinePriceWithoutVat: data.orderLinePrice.orderLinePriceWithoutVat,
        totalSaving: data.orderLinePrice.totalSaving
      };
      const stock = {
        ...data.stock,
        quantityIsAvailable: data.stock.cart.isQtyAvailable,
        quantityIsAvailableInWDC: data.stock.cart.isQtyAvailableInWDC,
        quantityIsAvailableViaDropShipping: data.stock.cart.isQtyAvailableViaDropShipping
      };
      const productLineItem = {
        ageRestricted: data.ageRestricted,
        orderLinePrice,
        fulfillmentType: data.fulfillmentType,
        breakPriceObject: data.orderLinePrice.appliedBreakPriceObject,
        imageSrc: getOrderLineImage(data, 'quotations'),
        name: data.name,
        orderLineShippingDetails: data.orderLineShippingDetails,
        packQty: data.price.packQty,
        qtyMultiplier: data.price.qtyMultiplier,
        price: data.price,
        productId: data._id,
        isDiscountApplied: data.isDiscountApplied,
        isDiscontinued: stock.isDiscontinued,
        isAvailableOnLeadTime: stock.isAvailableOnLeadTime,
        quantity: data.qty,
        sku: data.sku,
        maxDeliveryDays: data.maxDeliveryDays,
        brand: data.brand,
        category: data.category,
        stock,
        isUpdatingQuantity: false,
        productUrl: data.productUrl
      };
      productLines[data.sku] = productLineItem;
      return productLines;
    },
    quotations: () => {
      data.products.forEach((productLine) => {
        const { qty, lockedPrice: { packPriceWithVat, packPrice } } = productLine;
        const orderLinePrice = {
          ...productLine.lockedPrice,
          unitPriceWithVat: packPriceWithVat,
          unitPriceWithoutVat: packPrice,
          orderLinePriceWithVat: qty * packPriceWithVat,
          orderLinePriceWithoutVat: qty * packPrice,
          totalSaving: productLine.orderPrice.totalSaving || productLine.orderPrice.totalSavingWithVat
        };
        const stock = {
          ...productLine.stock,
          quantityIsAvailable: productLine.stock.cart.isQtyAvailable,
          quantityIsAvailableInWDC: productLine.stock.cart.isQtyAvailableInWDC,
          quantityIsAvailableViaDropShipping: productLine.stock.cart.isQtyAvailableViaDropShipping
        };

        const productLineItem = {
          ageRestricted: productLine.ageRestricted,
          orderLinePrice,
          fulfillmentType: productLine.fulfillmentType,
          breakPriceObject: productLine.lockedPrice.appliedBreakPriceObject,
          imageSrc: getOrderLineImage(productLine, 'quotations'),
          name: productLine.name,
          orderLineShippingDetails: productLine.orderLineShippingDetails,
          packQty: productLine.price.packQty,
          qtyMultiplier: productLine.price.qtyMultiplier,
          price: productLine.price,
          productId: productLine._id,
          quantity: productLine.qty,
          sku: productLine.sku,
          isDiscountApplied: productLine.isDiscountApplied,
          maxDeliveryDays: productLine.maxDeliveryDays,
          brand: productLine.brand,
          category: productLine.category,
          stock,
          isUpdatingQuantity: false,
          isSearchable: productLine.isSearchable,
          isDiscontinued: stock.isDiscontinued,
          isAvailableOnLeadTime: stock.isAvailableOnLeadTime,
          productUrl: productLine.productUrl
        };
        productLines[productLine.sku] = productLineItem;
      });
      return productLines;
    },
    bundle: () => {
      data.bundles.map((productBundleLine) => {
        const products = [];
        productBundleLine.products.map((product) => {
          const productItem = {
            imageSrc: getOrderLineImage(product, 'bundle'),
            imageSrcLowResolution: _get(product, 'mediaContent.mainPicture.largeIcon.lowResolution', BACKUP_IMAGE),
            name: product.name,
            price: product.price,
            sku: product.sku,
            brand: product.brand,
            category: product.category,
            stock: product.stock,
            isSearchable: product.isSearchable,
            isUpsell: product.isUpsell,
            isPrimary: product.isPrimary,
            message: product.message
          };
          products.push(productItem);
        });
        const primaryProduct = productBundleLine.products.find(product => product.isPrimary);
        const productBundleLineItem = {
          products: products,
          fulfillmentType: primaryProduct.stock.fulfillmentType,
          imageSrc: getOrderLineImage(primaryProduct, 'bundle'),
          imageSrcLowResolution: _get(primaryProduct, 'mediaContent.mainPicture.productThumbnail.lowResolution', BACKUP_IMAGE),
          name: primaryProduct.name,
          orderLinePrice: productBundleLine.bundlePrice,
          orderLineShippingDetails: productBundleLine.orderLineShippingDetails,
          price: primaryProduct.price,
          bundleFamilyId: productBundleLine.bundleFamilyId,
          _id: productBundleLine._id,
          sku: productBundleLine.sku,
          bundleQuantity: productBundleLine.bundleQuantity,
          skuOfPrimaryProduct: primaryProduct.sku,
          brand: primaryProduct.brand,
          category: primaryProduct.category,
          isDiscountApplied: primaryProduct.isDiscountApplied,
          stock: productBundleLine.stock,
          isUpdatingQuantity: false,
          isSearchable: primaryProduct.isSearchable,
          message: productBundleLine.message,
          isDeleted: productBundleLine.isDeleted,
          leadTime: productBundleLine.leadTime,
          isBundleSearchable: productBundleLine.isBundleSearchable,
          label: productBundleLine.label
        };
        productLines[
          [productBundleLine.sku, primaryProduct.sku].join(HYPHEN)
        ] = productBundleLineItem;
      });
      return productLines;
    }
  };
  return productLineTypes[type]();
};

export const isRefundableProduct = (productDetails) => productDetails?.nonRefundableType === null || productDetails?.nonRefundableType === undefined;
export const isNonReturnableProduct = (productDetails) => !!productDetails?.isNonReturnable;
