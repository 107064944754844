import React, { useEffect, useState } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { makeTextClickable } from 'client/utils/stockMessageUtil';
import ScheduleOrdersForm from 'client/components/elements/scheduledOrders/ScheduleOrdersForm';
import { submittedSubscriptionRegularityMap, ScheduleOrderFormStatus, WEEKLY } from 'client/components/elements/scheduledOrders/constants';
import { getDeliveryDateNewSubscription } from 'shared/utils/subscriptionUtils';
import { getMappedSubscriptionPriceProps, isConfirmationsScreen, parseConfirmedOrderIdFromUrl } from 'client/components/elements/scheduledOrders/utils';
import { removeScheduledOrderSettings, saveScheduledOrderFormSettings, scheduleOrderlineSubscription } from 'client/actions/scheduledOrders';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { SEND_PARTIAL_ORDER } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import dayjs from 'dayjs';

if (process.browser) {
  require('./ScheduledOrders.scss');
}

const { FORM, SUBMITTED, SUBSCRIPTION_CREATED, VIEW, HIDDEN } = ScheduleOrderFormStatus;

export const ScheduleOrderLine = (props) => {
  const {
    sku,
    priceProps,
    orderQuantity,
    formValues,
    saveScheduledOrderSettings,
    removeScheduledOrderSettings,
    cartLevelFormStatus,
    isGoldOrAboveLoyaltyDiscount,
    isScheduledOrderPayment,
    earliestDeliveryDate,
    isPersistentState,
    scheduleSubscription,
    isConfirmationScreenScheduledOrder,
    isLoadingCreateSubscriptionButton,
    isMobile
  } = props;
  const isOrderConfirmationScreen = isConfirmationsScreen();

  const subscriptionPriceProps = !isGoldOrAboveLoyaltyDiscount ? getMappedSubscriptionPriceProps(priceProps) : priceProps;
  const isSubscriptonAvailable = isScheduledOrderPayment || !isOrderConfirmationScreen;
  const isFormSettings = !!formValues?.regularity;
  const existingSubscriptionId = formValues._id;

  const isCartFormOpen = [SUBMITTED, FORM, SUBSCRIPTION_CREATED].includes(cartLevelFormStatus);

  const initialState = !isSubscriptonAvailable ? HIDDEN
    : existingSubscriptionId ? SUBSCRIPTION_CREATED
      : isFormSettings ? SUBMITTED : VIEW;

  const [formStatus, setFormStatus] = useState(initialState);
  const [isFormExpanded, setIsFormExpanded] = useState(false);

  useEffect(() => {
    if (cartLevelFormStatus === VIEW && !formValues.regularity) {
      setFormStatus(VIEW);
    }

    if (isCartFormOpen) {
      const originalStatus = existingSubscriptionId ? SUBSCRIPTION_CREATED : SUBMITTED;
      setFormStatus(originalStatus);
    }
  }, [isCartFormOpen]);

  const submitFormValues = (formValues) => {
    removeScheduledOrderSettings('cart', false);
    saveScheduledOrderSettings(sku, formValues);
    setFormStatus(SUBMITTED);
  };

  const handleShowSubscriptionForm = () => {
    const newFormState = formStatus === VIEW ? FORM : VIEW;
    if (newFormState === VIEW && isFormSettings && !existingSubscriptionId) {
      removeScheduledOrderSettings(sku, isPersistentState);
      setIsFormExpanded(false);
    } else {
      setIsFormExpanded(true);
      saveScheduledOrderSettings(sku, {
        startDate: dayjs(earliestDeliveryDate).startOf('day').toDate(),
        endDate: null,
        regularity: WEEKLY,
        outOfStockDeliveryPreference: SEND_PARTIAL_ORDER,
        numberOfOrders: ''
      });
    }

    setFormStatus(newFormState);
  };

  const toggleExpandForm = () => {
    setIsFormExpanded(!isFormExpanded);
  };

  if (!isSubscriptonAvailable) {
    return null;
  }

  return (
    <div className={ `scheduledOrderLine ${isFormSettings ? 'border-active' : 'border-inactive'}` }>
      { existingSubscriptionId ? null
        : <section className="showScheduleOrderForm" data-e2e={ `showScheduleOrderForm-${sku}` }>
          <div>
            <input id={ `show-subscription-form-${sku}` }
              name={ `show-subscription-form-${sku}` }
              checked={ isFormSettings }
              type="checkbox"
              onChange={ handleShowSubscriptionForm } />
            <label htmlFor={ `show-subscription-form-${sku}` }>{getLocalizedString(
              isGoldOrAboveLoyaltyDiscount ? 'scheduledOrders.info.info.save.orderline.gold.platinum' : `scheduledOrders.info.info.save.orderline${isMobile ? '.mobile' : ''}`
            )}
            <OverlayTrigger
              overlay={ <Tooltip id="subscription-tooltip" className="createSubscriptionTooltip">
                <p className="title">{getLocalizedString('scheduledOrders.goto.scheduledOrders.info.title')}</p>
                <p className="content">{makeTextClickable(
                  getLocalizedString('scheduledOrders.goto.scheduledOrders.info.content'),
                  /click here/,
                  '/info/subscriptions',
                  '_blank')}</p>
              </Tooltip> }
              placement="right"
              delayShow={ 500 }
              delayHide={ 1500 }
            >
              <SvgLoader xlinkHref="#information-icon" className="overlayIcon"/>
            </OverlayTrigger>
            </label>
          </div>
          { isFormSettings && !existingSubscriptionId
            ? <div onClick={ () => toggleExpandForm() }>
              <SvgLoader xlinkHref={ isFormExpanded ? 'chevron-up' : 'chevron-down' } />
            </div> : null }
        </section>
      }
      {isFormExpanded && !existingSubscriptionId ? <ScheduleOrdersForm
        subscriptionPriceProps={ subscriptionPriceProps }
        formValues={ formValues }
        saveFormValues={ submitFormValues }
        earliestDeliveryDate={ earliestDeliveryDate }
        isGoldOrAboveLoyaltyDiscount={ isGoldOrAboveLoyaltyDiscount }
        submitSubscription={ scheduleSubscription }
        isConfirmationScreenScheduledOrder={ isConfirmationScreenScheduledOrder }
        isLoadingCreateSubscriptionButton={ isLoadingCreateSubscriptionButton }
      />
        : existingSubscriptionId
          ? <span className="submittedScheduleOrder" data-e2e={ `submittedScheduleOrder-${sku}` }>
            <p>
              <SvgLoader xlinkHref="#schedule" />
              {getLocalizedStringWithParam('scheduledOrders.subscription.created', {
                orderQuantity,
                regularity: submittedSubscriptionRegularityMap[formValues.regularity],
                endDate: formValues.endDate ? dayjs(formValues.endDate).format('DD/MM/YYYY') : 'further notice'
              })}
            </p>
            <p>{makeTextClickable('Edit schedule order', /Edit schedule order/, `/my-account/repeat-orders/${existingSubscriptionId}`)}</p>
          </span>
          : isFormSettings
            ? <span className="submittedScheduleOrder" data-e2e={ `submittedScheduleOrder-${sku}` }>
              <p>
                <SvgLoader xlinkHref="#schedule" />
                {getLocalizedStringWithParam('scheduledOrders.saved.settings', {
                  orderQuantity,
                  regularity: submittedSubscriptionRegularityMap[formValues.regularity],
                  endDate: formValues.endDate ? dayjs(formValues.endDate).format('DD/MM/YYYY') : 'further notice'
                })}
              </p>
            </span>
            : null}
    </div>
  );
};

const mapStateToProps = (state, { sku }) => {
  const orderId = parseConfirmedOrderIdFromUrl();
  const orderLineItem = (orderId ? state.getIn(['orders', 'order', orderId, 'orderLines']) : List()).find(orderLine => orderLine.get('sku') === sku);
  return {
    isMobile: state.getIn(['deviceInfo', 'isMobile']),
    formValues: state.getIn(['scheduledOrders', 'forms', sku], Map({})).toJS(),
    earliestDeliveryDate: getDeliveryDateNewSubscription(state.get('publicHolidays', List([])).toJS()),
    cartLevelFormStatus: state.getIn(['scheduledOrders', 'cartLevelFormStatus']),
    isScheduledOrderPayment: !!state.getIn(['scheduledOrders', 'paymentMethod', 'paymentType'], ''),
    isPersistentState: state.hasIn(['productLineItems', sku, 'scheduledOrder', 'regularity']),
    isConfirmationScreenScheduledOrder: !!orderLineItem && !orderLineItem.get('scheduledOrder') && state.hasIn(['scheduledOrders', 'forms', sku, 'regularity']),
    isLoadingCreateSubscriptionButton: state.getIn(['scheduledOrders', 'loadingCreateSubscription', sku]) ?? state.getIn(['scheduledOrders', 'loadingCreateSubscription', 'cart'])
  };
};

const mapDispatchToProps = (dispatch, { sku }) => ({
  saveScheduledOrderSettings: (sku, settings) => dispatch(saveScheduledOrderFormSettings(sku, settings)),
  removeScheduledOrderSettings: (formId, isPersistentState) => dispatch(removeScheduledOrderSettings(formId, isPersistentState)),
  scheduleSubscription: (settings) => dispatch(scheduleOrderlineSubscription(sku, settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleOrderLine);
