import { put, select, call, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_REGISTER_USER,
  REQUEST_REGISTER_USER_AND_ACCOUNT,
  REQUEST_REGISTER_USER_AND_REDIRECT,
  userRegistrationError,
  loginUserSuccess
} from 'client/actions/userActions';
import {
  SAVE_USER_TELEPHONE
} from 'client/actions/ui/telephoneFieldActions';
import {
  updateJWTToken
} from 'client/actions/tokenActions';
import {
  gotoExternalUrl
} from 'client/actions/routeActions';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';
import { fetchAccountDetails } from 'shared/endpoints/accountEndpoint';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { registerUser, saveUserTelephone } from 'shared/endpoints/userEndpoint';
import { setToken, getTradeAccountDataFromToken } from 'shared/utils/jwtUtils';
import { isUserComingFromCartPage, isUserComingFromGlobalQuote } from 'client/actions/userActions';
import { hideLoginDialog } from 'client/actions/ui/dialogActions';
import { DELIVERY_CHECKOUT_ROUTE, CART_ROUTE, SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { getCart } from 'shared/endpoints/cartEndpoint';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { createAccountForBusiness } from 'client/components/elements/LoginAndRegistrationForm/businessAccountRegistration/utils.js';
import { MAYBE_LATER } from 'client/components/elements/LoginAndRegistrationForm/constants';
import { isZoneTwoOrThree } from 'client/utils/userDataUtils';

const finalDestinationSelector = (state) => state.getIn(['login', 'finalDestination'], '/');
const actionToDispatchSelector = (state) => state.getIn(['login', 'actionToDispatch']);
const parcelForceZoneSelector = (state) => state.getIn(['user', 'accountDetails', 'parcelForceZone']);

export function * onSuccessfullyRegistered (action) {
  try {
    const { currentPath, ...restOfBody } = action.body;
    const { token, longSessionToken } = yield call(registerUser, restOfBody);
    yield call(setToken, token, longSessionToken);
    const { tradeAccountIsValidForStatement, tradeAccountIsValid } = getTradeAccountDataFromToken(token, longSessionToken);
    yield put(updateJWTToken({ token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid }));
    yield put(loginUserSuccess(
      { token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid },
      (yield select(finalDestinationSelector)),
      (yield select(actionToDispatchSelector))
    ));

    yield put(hideLoginDialog());
    const accountDetails = yield call(fetchAccountDetails);
    yield put(updateDataLayer({ event: 'new_account', crom_user_id: accountDetails.customerId }));

    if (CART_ROUTE === currentPath) {
      const cart = yield call(getCart);
      const parcelForceZone = yield select(parcelForceZoneSelector);
      const isEligibleForFreeDelivery = cart?.cartPrice.cartOrderLinesTotalWithVat >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
      if (isEligibleForFreeDelivery) {
        yield put(gotoExternalUrl(SINGLE_PAGE_CHECKOUT_ROUTE));
      } else {
        yield put(gotoExternalUrl(DELIVERY_CHECKOUT_ROUTE));
      }
    }
  } catch (err) {
    yield put(userRegistrationError(buildErrorMessageFromError(err)));
  }
}

export function * onSuccessfullyRegisteredUserAndAccount (action) {
  try {
    const { currentPath, ...restOfBody } = action.body;
    const { token, longSessionToken } = yield call(registerUser, restOfBody);

    const {
      companyName,
      email,
      firstName,
      lastName,
      jobTitle,
      phoneNumber,
      companyRegNo,
      csAgentName = ''
    } = restOfBody;
    const { accountNo } = yield call(createAccountForBusiness, {
      primaryContactForm: {
        companyName,
        email,
        firstName,
        lastName,
        jobTitle,
        openCreditLine: MAYBE_LATER,
        phoneNumber,
        companyRegNo
      },
      financeContactForm: {
        sameAsRegisteredAddress: true,
        sameAsPrimaryContact: true
      },
      creditLineApplicationForm: {
        financeContact: MAYBE_LATER
      },
      csAgentName
    });
    yield put(updateDataLayer({ event: 'baApplicationSubmitted', accountNo }));

    yield call(setToken, token, longSessionToken);
    const { tradeAccountIsValidForStatement, tradeAccountIsValid } = getTradeAccountDataFromToken(token, longSessionToken);
    yield put(updateJWTToken({ token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid }));
    yield put(loginUserSuccess(
      { token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid }
    ));

    const accountDetails = yield call(fetchAccountDetails);
    yield put(updateDataLayer({ event: 'new_account', crom_user_id: accountDetails.customerId }));
    yield put(hideLoginDialog());

    if (CART_ROUTE === currentPath) {
      const cart = yield call(getCart);
      const parcelForceZone = yield select(parcelForceZoneSelector);
      const isEligibleForFreeDelivery = cart?.cartPrice.cartOrderLinesTotalWithVat >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
      if (isEligibleForFreeDelivery) {
        yield put(gotoExternalUrl(SINGLE_PAGE_CHECKOUT_ROUTE));
      } else {
        yield put(gotoExternalUrl(DELIVERY_CHECKOUT_ROUTE));
      }
    }
  } catch (err) {
    yield put(userRegistrationError(buildErrorMessageFromError(err)));
  }
}

export function * onRegistrationSuccess (action) {
  try {
    const { token, longSessionToken } = yield call(registerUser, action.body);
    yield call(setToken, token, longSessionToken);
    const { tradeAccountIsValidForStatement, tradeAccountIsValid } = getTradeAccountDataFromToken(token, longSessionToken);
    yield put(updateJWTToken({ token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid }));
    yield put(loginUserSuccess(
      { token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid },
      (yield select(finalDestinationSelector)),
      (yield select(actionToDispatchSelector))
    ));
    const accountDetails = yield call(fetchAccountDetails);
    yield put(updateDataLayer({ event: 'new_account', crom_user_id: accountDetails.customerId }));
    yield put(hideLoginDialog());

    const finalDestination = yield select(finalDestinationSelector);
    const userIsComingFromCartPage = finalDestination === '/cart';
    const userIsComingFromGlobalQuotePage = finalDestination === '/global-quotation';
    const userIsAcceptingMultiUserInvite = finalDestination ? finalDestination.includes('/verify-account-user') : false;
    yield put(isUserComingFromCartPage(userIsComingFromCartPage));
    yield put(isUserComingFromGlobalQuote(userIsComingFromGlobalQuotePage));
    const quotationRef = yield select(state => state.globalQuotation?.quotationQuoteRef);
    const redirectTo = userIsComingFromCartPage
      ? SINGLE_PAGE_CHECKOUT_ROUTE
      : userIsComingFromGlobalQuotePage
        ? `global-quotation/${quotationRef}`
        : finalDestination || SINGLE_PAGE_CHECKOUT_ROUTE;
    const notMultiUserAndCompanyName = !userIsAcceptingMultiUserInvite && action.body.companyName;
    if (notMultiUserAndCompanyName) {
      yield put(gotoExternalUrl(`/new-zoro-business-account?redirectTo=${redirectTo}`));
    }
  } catch (err) {
    yield put(userRegistrationError(buildErrorMessageFromError(err)));
  }
}

export function * saveTelephone (action) {
  yield call(saveUserTelephone, action.payload);
}

export function * watchSaveUserTelephone () {
  yield takeLatest(SAVE_USER_TELEPHONE, saveTelephone);
}

export function * watchUserNewRegistration () {
  yield takeLatest(REQUEST_REGISTER_USER_AND_REDIRECT, onSuccessfullyRegistered);
}

export function * watchUserRegistration () {
  yield takeLatest(REQUEST_REGISTER_USER, onRegistrationSuccess);
}

export function * watchUserAndAccountRegistration () {
  yield takeLatest(REQUEST_REGISTER_USER_AND_ACCOUNT, onSuccessfullyRegisteredUserAndAccount);
}
