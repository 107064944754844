export const CREATE_RETURN_OR_REPLACEMENT = 'CREATE_RETURN_OR_REPLACEMENT';
export const SELECT_UPDATE_ITEM_SELECTION = 'SELECT_UPDATE_ITEM_SELECTION';

export const RESET_SELECTION = 'RESET_SELECTION';
export const SET_VERIFY = 'SET_VERIFY';

export const SUBMIT_RETURN_FORM = 'SUBMIT_RETURN_FORM';

export const INIT_RETURN_REPLACEMENT = 'INIT_RETURN_REPLACEMENT';
export const RETURN_REPLACEMENT_SUCCESS = 'RETURN_REPLACEMENT_SUCCESS';
export const RETURN_REPLACEMENT_ERROR = 'RETURN_REPLACEMENT_ERROR';

export const INIT_RETURN_FILE_UPLOAD = 'INIT_RETURN_FILE_UPLOAD';
export const INIT_DELETE_RETURN_FILE = 'INIT_DELETE_RETURN_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const ON_FILE_UPLOAD_ERROR = 'ON_FILE_UPLOAD_ERROR';

export const UPDATE_MY_RETURNS_STATE = 'UPDATE_MY_RETURNS_STATE';
export const REQUEST_MY_RETURNS = 'REQUEST_MY_RETURNS';
export const LOAD_MY_RETURNS_SUCCESS = 'LOAD_MY_RETURNS_SUCCESS';

export const FETCH_MY_RETURNS = 'FETCH_MY_RETURNS';
export const ON_MY_RETURNS_SUCESS = 'ON_MY_RETURNS_SUCESS';

export const FETCH_CONFIRMED_RETURNS = 'FETCH_CONFIRMED_RETURNS';
export const ON_SUCCESS_CONFIRMED_RETURNS = 'ON_SUCCESS_CONFIRMED_RETURNS';

export const createReturnOrReplacement = (order) => ({
  type: CREATE_RETURN_OR_REPLACEMENT,
  order
});

export const updateItemSelection = (payload) => ({
  type: SELECT_UPDATE_ITEM_SELECTION,
  payload
});

export const setVerifyReturn = (payload) => ({
  type: SET_VERIFY,
  payload
});

export const resetReturnRequestDetails = () => ({
  type: RESET_SELECTION
});

export const initReturnReplacement = (payload) => ({
  type: INIT_RETURN_REPLACEMENT,
  payload
});

export const onReturnReplacementError = (error) => ({
  type: RETURN_REPLACEMENT_ERROR,
  error
});

export const initReturnFileUpload = (payload, fieldName) => ({
  type: INIT_RETURN_FILE_UPLOAD,
  payload,
  fieldName
});

export const deleteReturnFile = (payload) => ({
  type: INIT_DELETE_RETURN_FILE,
  payload
});

export const onDeleteFileSuccess = (payload) => ({
  type: DELETE_FILE_SUCCESS,
  payload
});

export const onFileUploadError = (payload) => ({
  type: ON_FILE_UPLOAD_ERROR,
  payload
});

export const onSuccessReturns = (payload) => ({
  type: ON_MY_RETURNS_SUCESS,
  payload
});

export const fetchConfirmedReturns = (reqId) => ({
  type: FETCH_CONFIRMED_RETURNS,
  reqId
});

export const onConfirmReturnsSuccess = (payload) => ({
  type: ON_SUCCESS_CONFIRMED_RETURNS,
  payload
});

export const submitReturnForm = (payload) => ({
  type: SUBMIT_RETURN_FORM,
  payload
});
