import { put, take, all, fork, takeEvery, race } from 'redux-saga/effects';
import {
  ON_API_BUTTON_LOAD,
  ON_API_BUTTON_UNLOAD,
  onApiButtonLoad,
  onApiButtonSuccess,
  onApiButtonError
} from 'client/actions/ui/apiCallButtonActions';

function * onApiButtonTrigger ({ selector, triggerAction, successAction, errorAction, resetOnSuccess }) {
  const { success, error } = yield race({
    success: take(successAction),
    error: take(errorAction),
    unload: take(ON_API_BUTTON_UNLOAD)
  });
  if (success) {
    if (resetOnSuccess) {
      yield put(onApiButtonLoad(selector, triggerAction, successAction, errorAction, resetOnSuccess));
    } else {
      yield put(onApiButtonSuccess(selector));
    }
  }
  if (error) {
    yield put(onApiButtonError(selector));
    yield * onApiCallButtonLoad({ payload: { selector, triggerAction, successAction, errorAction, resetOnSuccess } });
  }
}

function * onApiCallButtonLoad (action) {
  const { selector, triggerAction, successAction, errorAction, resetOnSuccess } = action.payload;
  const { trigger } = yield race({
    trigger: take(triggerAction),
    unload: take(ON_API_BUTTON_UNLOAD)
  });
  if (trigger) {
    // yield put(onApiButtonClick(selector));
    yield * onApiButtonTrigger({ selector, triggerAction, successAction, errorAction, resetOnSuccess });
  }
}

export function * watchApiCallButtonLoad () {
  yield takeEvery(ON_API_BUTTON_LOAD, onApiCallButtonLoad);
}

export function * watchApiCallButtonActions () {
  yield all([
    fork(watchApiCallButtonLoad)
  ]);
}
