import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD } from '../PaymentMethod/constants';
import { getLocalizedString } from 'localization/localizer';
import { getExistingCards } from 'client/actions/braintreeGatewayActions';
import { List } from 'immutable';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
if (process.browser) {
  require('./buttons.scss');
}

export const ChangePaymentMethodButton = ({
  paymentMethodSelector,
  changePaymentMethod,
  getExistingCards,
  hasExistingCards
}) => {
  useEffect(() => {
    getExistingCards({
      selector: paymentMethodSelector
    });
  }, []);
  return (
    <button
      onClick={ () => {
        changePaymentMethod({
          selector: paymentMethodSelector,
          paymentMethod: hasExistingCards ? PAY_BY_EXISTING_CARD : PAY_BY_NEW_CARD
        });
      } }
      data-e2e="PaymentMethodButton_changePaymentMethod"
      className="ChangePaymentMethodButton_text">
      {getLocalizedString('singlePageCheckout.label.change.payment')}
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    hasExistingCards: state.getIn(['braintree', 'cards'], List()).toJS().length !== 0
  };
};

const mapDispatchToProps = {
  changePaymentMethod,
  getExistingCards
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePaymentMethodButton);
