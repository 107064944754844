import { createAction } from 'redux-actions';

export const INITIALIZE_PAYPAL = 'payByPaypal.INITIALIZE_PAYPAL';
export const initializePaypal = createAction(INITIALIZE_PAYPAL);

export const SET_DETAILS = 'payByPaypal.SET_DETAILS';
export const setDetails = createAction(SET_DETAILS);

export const SET_DATA = 'payByPaypal.SET_DATA';
export const setData = createAction(SET_DATA);

export const PAY_BY_PAYPAL = 'payByPaypal.PAY';
export const payByPaypal = createAction(PAY_BY_PAYPAL);
