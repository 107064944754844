import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { PaymentAddress } from 'client/components/elements/paymentMethod/shared/PaymentAddress';
import { initializePayOnAccount } from './payByAccountActions';
import { SUBSCRIPTION_SELECTOR } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

if (process.browser) {
  require('./payByAccount.scss');
}

export const PayByAccount = ({
  selector,
  reviewOrderButton,
  billingAddress,
  initializePayOnAccount,
  addressId
}) => {
  useEffect(() => {
    initializePayOnAccount({ isSubscription: selector === SUBSCRIPTION_SELECTOR, addressId });
  }, [initializePayOnAccount]);

  return <div className="PayByAccount">
    <PaymentAddress
      selector={ selector }
      data-e2e="PayByAccount_payment_address"
      address={ billingAddress }
      title={ getLocalizedString('payment.label.billing.details') }
      button={ reviewOrderButton } />
  </div>;
};

function mapStateToProps (state) {
  const billingAddressFromState = state.getIn(['user', 'accountDetails', 'tradeAccount', 'invoiceContact']);
  const billingAddress = billingAddressFromState ? billingAddressFromState.toJS() : {};
  return {
    billingAddress
  };
}

const mapDispatchToProps = {
  initializePayOnAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByAccount);
