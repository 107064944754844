import { routeAccessDenied } from 'client/actions/routeActions';
import {
  setFinalDestination,
  reachedInterstitialPage,
  setRedirectLoadingState
} from 'client/actions/userActions';
import curry from 'lodash/curry';
import { getTokenStatus, TokenStatus } from 'shared/utils/jwtUtils';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
const IS_REDIRECT_LOADING = false;

export const hasValidToken = (state) => {
  const tokenStatus = getTokenStatus(state.getIn(['auth', 'jwtToken']));
  return tokenStatus === TokenStatus.VALID || tokenStatus === TokenStatus.ALMOST_EXPIRED;
};

export const isUserLoggedIn = (state) => {
  return hasValidToken(state) && state.getIn(['auth', 'isLoggedIn'], false);
};

export const hasValidLongSessionToken = (state) => {
  const tokenStatus = getTokenStatus(state.getIn(['auth', 'longSessionJwtToken'], null));
  return tokenStatus === TokenStatus.VALID || tokenStatus === TokenStatus.ALMOST_EXPIRED;
};

export const redirectIfNotLoggedIn = curry(function redirectIfNotLoggedIn (store, type, { location: { pathname: finalDestination } }, replace, cb) {
  const state = store.getState();
  if (process.browser) {
    store.dispatch(setRedirectLoadingState(true));
    if (!isUserLoggedIn(state)) {
      finalDestination = `${location.pathname}${location.search}`;
      const route = (type) ? `/login?type=${type}` : '/login';
      store.dispatch(routeAccessDenied(replace, cb, route, finalDestination, IS_REDIRECT_LOADING));
    } else {
      // don't change finalDestination when at an interstitial page
      if (!['/new-zoro-business-account', '/zoro-business-account/apply', '/zoro-business-account-applied'].includes(finalDestination)) {
        if (location.pathname === SINGLE_PAGE_CHECKOUT_ROUTE) {
          const braintreePaymentConfig = state.getIn(['config', 'braintreePayment']).toJS();
          const userEmail = state.getIn(['user', 'accountDetails', 'email'], null);
          checkIsValidSinglePageCheckout(braintreePaymentConfig, userEmail)
            ? store.dispatch(setFinalDestination(finalDestination, null, IS_REDIRECT_LOADING))
            : store.dispatch(routeAccessDenied(replace, cb, '/checkout/order', null, IS_REDIRECT_LOADING));
        } else {
          store.dispatch(setFinalDestination(finalDestination, null, IS_REDIRECT_LOADING));
        }
      } else {
        store.dispatch(reachedInterstitialPage(IS_REDIRECT_LOADING));
      }
      cb();
    }
  }
});

export const checkIsValidSinglePageCheckout = (braintreePaymentConfig, userEmail) => {
  const isBraintreePaymentEnabled = braintreePaymentConfig.enabled;
  const braintreeEmailList = braintreePaymentConfig.enabledForEmails;
  const shouldShowSinglePageCheckout = isBraintreePaymentEnabled
    ? isBraintreePaymentEnabled
    : braintreeEmailList.includes(userEmail);
  return shouldShowSinglePageCheckout;
};
