import { createAction } from 'redux-actions';

export const UI_TOGGLE_ELEMENT = 'toggleElementActions';
export const HANDLE_BILLING_ADDRESS_TOGGLE = 'HANDLE_BILLING_ADDRESS_TOGGLE';
export const TOGGLE_MULTI_USER_ACCOUNT_FORM = 'TOGGLE_MULTI_USER_ACCOUNT_FORM';
export const toggleElement = createAction(UI_TOGGLE_ELEMENT);
export const handleToggle = createAction(HANDLE_BILLING_ADDRESS_TOGGLE);
export const toggleMultiUserAccountForm = createAction(TOGGLE_MULTI_USER_ACCOUNT_FORM);

export const TOGGLE_BASKET_CONFIRMATION_LOADER = 'TOGGLE_BASKET_CONFIRMATION_LOADER';
export const toggleBasketConfirmationLoader = (toggleFlag) => {
  return {
    type: TOGGLE_BASKET_CONFIRMATION_LOADER,
    toggleFlag
  };
};

export const TOGGLE_BASKET_CONFIRMATION_DROPDOWN = 'TOGGLE_BASKET_CONFIRMATION_DROPDOWN';
export const toggleBasketConfirmationDropdown = (toggleFlag) => {
  return {
    type: TOGGLE_BASKET_CONFIRMATION_DROPDOWN,
    toggleFlag
  };
};

// toggle cancelation request dialogs
export const UPDATE_QTY_TO_CANCEL = 'UPDATE_QTY_TO_CANCEL';
export const updateQtyToCancel = (qty, sku) => ({
  type: 'UPDATE_QTY_TO_CANCEL',
  sku,
  qty
});

export const CANCELLATION_REQUEST_FAILED = 'CANCELLATION_REQUEST_FAILED';
export const cancellationRequestFailed = () => {
  return {
    type: 'CANCELLATION_REQUEST_FAILED'
  };
};

export const TOGGLE_SUBMIT_CANCELLATION_REQUEST = 'TOGGLE_SUBMIT_CANCELLATION_REQUEST';
export const toggleSubmitCancelItemDialog = (sku, orderId) => ({
  type: TOGGLE_SUBMIT_CANCELLATION_REQUEST,
  sku,
  orderId
});

export const TOGGLE_CANCEL_ITEM_DIALOG = 'TOGGLE_CANCEL_ITEM_DIALOG';
export const toggleCancelItemDialog = (sku, orderId) => ({
  type: TOGGLE_CANCEL_ITEM_DIALOG,
  sku,
  orderId
});

export const CLOSE_SUBMIT_CANCEL_ITEM_DIALOG = 'CLOSE_SUBMIT_CANCEL_ITEM_DIALOG';
export const closeSubmitCancellationDialog = () => ({
  type: CLOSE_SUBMIT_CANCEL_ITEM_DIALOG
});

export const CLOSE_CANCEL_ITEM_DIALOG = 'CLOSE_CANCEL_ITEM_DIALOG';
export const closeCancellationDialog = () => ({
  type: CLOSE_CANCEL_ITEM_DIALOG
});

export const TOGGLE_SHOW_MORE_ATTRIBUTES = 'TOGGLE_SHOW_MORE_ATTRIBUTES';
export const toggleShowMoreAttributes = (toggleFlag) => ({
  type: TOGGLE_SHOW_MORE_ATTRIBUTES,
  toggleFlag
});

export const TOGGLE_SHOW_MORE_ATTRIBUTES_BUTTON = 'TOGGLE_SHOW_MORE_ATTRIBUTES_BUTTON';
export const toggleShowMoreAttributesButton = (toggleFlag) => ({
  type: TOGGLE_SHOW_MORE_ATTRIBUTES_BUTTON,
  toggleFlag
});

export const DELIVERY_OPTION_HOVER = 'DELIVERY_OPTION_HOVER';
export const hoverDeliveryOption = createAction(DELIVERY_OPTION_HOVER);
