import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm, Field } from 'redux-form/immutable';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidations';
import { PrimaryButton, SecondaryButton } from '../../../../controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
if (process.browser) {
  require('./QuickOrderForm.scss');
}

const QuickOrderForm = (props) => {
  const {
    handleSubmit,
    addToCart,
    closeQuickOrder,
    reset
  } = props;

  const FORM_ROWS = 5;

  const submit = (values) => {
    const orderLinesToBeAdded = [];
    for (let i = 0; i <= FORM_ROWS; i++) {
      if (values.get('sku' + (i + 1)) && values.get('qty' + (i + 1))) {
        const data = {
          sku: values.get('sku' + (i + 1)).toUpperCase(),
          qty: values.get('qty' + (i + 1))
        };
        orderLinesToBeAdded.push(data);
      }
    }

    addToCart({ orderLinesToBeAdded, redirectUrl: '/cart' });
    closeQuickOrder();
    reset();
  };

  const hideAndClear = () => {
    closeQuickOrder();
    reset();
  };

  return (
    <div className="QuickOrder">
      <p className="QuickOrder_InfoText">{ getLocalizedString('label.quickOrder.model') }</p>

      <form
        data-e2e="quickOrderForm"
        className="form-inline QuickOrder_Form"
        onSubmit={ handleSubmit(submit) }>

        <div className = "row QuickOrder_InputRow">
          <div className = "col-md-9 col-xs-9">
            <Field
              name="sku1"
              className = "QuickOrder_SkuCode form-control"
              formClassName = "QuickOrder_SkuCodeGroup"
              autoComplete="off"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.sku"
              datae2e="sku1" />
          </div>

          <div className = "col-md-3 col-xs-3">
            <Field
              name="qty1"
              className = "QuickOrder_Quantity form-control"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.qty"
              datae2e="qty1" />
          </div>
        </div>

        <div className = "row QuickOrder_InputRow">
          <div className = "col-md-9 col-xs-9">
            <Field
              name="sku2"
              className = "QuickOrder_SkuCode form-control"
              formClassName = "QuickOrder_SkuCodeGroup"
              autoComplete="off"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.sku"
              datae2e="sku2" />
          </div>

          <div className = "col-md-3 col-xs-3">
            <Field
              name="qty2"
              className = "QuickOrder_Quantity form-control"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.qty"
              datae2e="qty2" />
          </div>
        </div>

        <div className = "row QuickOrder_InputRow">
          <div className = "col-md-9 col-xs-9">
            <Field
              name="sku3"
              className = "QuickOrder_SkuCode form-control"
              formClassName = "QuickOrder_SkuCodeGroup"
              autoComplete="off"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.sku"
              datae2e="sku3" />
          </div>

          <div className = "col-md-3 col-xs-3">
            <Field
              name="qty3"
              className = "QuickOrder_Quantity form-control"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.qty"
              datae2e="qty3" />
          </div>
        </div>

        <div className = "row QuickOrder_InputRow">
          <div className = "col-md-9 col-xs-9">
            <Field
              name="sku4"
              className = "QuickOrder_SkuCode form-control"
              formClassName = "QuickOrder_SkuCodeGroup"
              autoComplete="off"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.sku"
              datae2e="sku4" />
          </div>

          <div className = "col-md-3 col-xs-3">
            <Field
              name="qty4"
              className = "QuickOrder_Quantity form-control"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.qty"
              datae2e="qty4" />
          </div>
        </div>

        <div className = "row QuickOrder_InputRow">
          <div className = "col-md-9 col-xs-9">
            <Field
              name="sku5"
              className = "QuickOrder_SkuCode form-control"
              formClassName = "QuickOrder_SkuCodeGroup"
              autoComplete="off"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.sku"
              datae2e="sku5" />
          </div>

          <div className = "col-md-3 col-xs-3">
            <Field
              name="qty5"
              className = "QuickOrder_Quantity form-control"
              type="text"
              component={ FieldWithValidations }
              placeholderResourceName="label.qty"
              datae2e="qty5" />
          </div>
        </div>

        <div className="form-group">
          <SecondaryButton
            className="QuickOrder_cancel"
            value={ getLocalizedString('cart.dialog.cancel.button') }
            text={ getLocalizedString('cart.dialog.cancel.button') }
            data-e2e="quickOrderCancelButton"
            onClick={ hideAndClear } />
          <PrimaryButton
            text={ getLocalizedString('product.addToBasket.addToBasket') }
            data-e2e="quickOrderSubmitButton"
            value={ getLocalizedString('product.addToBasket.addToBasket') }
            className="QuickOrder_addToBasket"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

QuickOrderForm.propTypes = {
  handleSubmit: PropTypes.func,
  addToCart: PropTypes.func.isRequired,
  closeQuickOrder: PropTypes.func.isRequired
};

const ReduxFormQuickOrderForm = reduxForm({
  form: 'quickOrder',
  destroyOnUnmount: false
})(QuickOrderForm);

export { QuickOrderForm };
export default ReduxFormQuickOrderForm;
