
import React from 'react';
import _get from 'lodash/get';
import { getLocalizedString } from 'localization/localizer';
import { PrimaryButton } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./RequestPriceButton.scss');
}

const RequestPriceButton = (props) =>
  <PrimaryButton
    { ...props }
    className={ `RequestPriceButton ${_get(props, 'buttonClassName', '')}` }
  >
    <span className="RequestPriceButton_Text">
      { getLocalizedString('POA.product.request.price') }
    </span>
  </PrimaryButton>;

export default RequestPriceButton;
