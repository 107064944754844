'use strict';

import { fromJS } from 'immutable';
import {
  RESET_ENQUIRIES_STATE,
  REQUEST_USER_ENQUIRIES,
  RECIEVE_ENQUIRIES_USER_SUCCESS,
  RECIEVE_ENQUIRIES_USER_ERROR
} from 'client/actions/userActions';

export const defaultState = fromJS({ enquiryError: null });

const contactUsFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_ENQUIRIES_STATE:
      return defaultState;
    case REQUEST_USER_ENQUIRIES:
      return state.set('enquiryError', null);
    case RECIEVE_ENQUIRIES_USER_SUCCESS:
      return state.set('enquiryError', null);
    case RECIEVE_ENQUIRIES_USER_ERROR:
      return state.set('enquiryError', action.error);
    default:
      return state;
  }
};

export default contactUsFormReducer;
