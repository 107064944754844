import { all, fork, takeLatest, select, put, take } from 'redux-saga/effects';
import { changePaymentMethod, clearPaymentMethod, INITIALIZE } from './paymentMethodActions';
import { PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD } from './constants';
import { getExistingCards, SET_EXISTING_CARDS } from 'client/actions/braintreeGatewayActions';
import { scrollToReviewPanel } from 'client/actions/braintreeActions';
import { braintreeSelectors, paymentMethodSelectors, accountSelectors } from 'client/components/elements/paymentMethod/shared/selectors';
import { hideBusinessAccountOnHoldDialog } from 'client/actions/ui/dialogActions';
import { getUserAddresses } from 'client/actions/addressActions';
import {
  ACCOUNT, EXISTING_SUBSCRIPTION_SELECTOR
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { getPaymentMethodConstant } from '../shared/paymentHelpers';
const getCart = (state) => state.getIn(['cart']);

export function * initialize ({ payload }) {
  const currentPaymentMethod = yield select(paymentMethodSelectors.paymentMethod(payload.selector));
  if (currentPaymentMethod === PAY_BY_ACCOUNT) {
    yield put(hideBusinessAccountOnHoldDialog());
  }

  if (payload.selector === CHECKOUT_SELECTOR) {
    yield put(scrollToReviewPanel(payload.location));
  }
  yield put(getUserAddresses({
    addressesDisplayed: 'ALL',
    addressesSkip: 0
  }));
  yield put(clearPaymentMethod());
  yield put(getExistingCards({ selector: payload.selector }));
  yield take(SET_EXISTING_CARDS);

  const cards = yield select(braintreeSelectors.cards);
  const userDefaultPaymentMethod = yield select(accountSelectors.defaultPaymentMethod);
  const defaultPaymentMethod = getPaymentMethodConstant(userDefaultPaymentMethod, cards.length);

  const payOnAccount = payload?.location?.query?.onAccount || payload?.subscriptionPayment?.paymentType.toLowerCase() === ACCOUNT.toLowerCase();
  const accountOrCard = payOnAccount ? PAY_BY_ACCOUNT : (cards.length === 0 ? PAY_BY_NEW_CARD : PAY_BY_EXISTING_CARD);
  let paymentMethod = payload.selector !== EXISTING_SUBSCRIPTION_SELECTOR ? defaultPaymentMethod : accountOrCard;
  if (paymentMethod === PAY_BY_ACCOUNT) {
    const cart = yield select(getCart);
    const disablePayOnAccountButton = cart && cart.getIn(['tradeAccountButton', 'disablePayOnAccountButton'], false);
    paymentMethod = disablePayOnAccountButton ? accountOrCard : paymentMethod;
  }
  yield put(changePaymentMethod({
    selector: payload.selector,
    paymentMethod,
    initialPaymentMethod: true
  }));
}

function * watchInitialize () {
  yield takeLatest(INITIALIZE, initialize);
}

export function * watchAllPaymentMethodSagas () {
  yield all([
    fork(watchInitialize)
  ]);
}
