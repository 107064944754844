import PropTypes from 'prop-types';
import React from 'react';
import { fromJS, Map } from 'immutable';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideAddToQuotationDialog } from 'client/actions/ui/dialogActions';
import { goToUrl } from 'client/actions/routeActions';
import {
  createQuotation,
  fetchQuotations,
  addProductsToQuotation
} from 'client/actions/quotationsActions';
import {
  ADD_TO_QUOTATION_STATUS__SUCCESS
} from 'client/reducers/ui/dialogReducer';
import AddToQuotationForm from 'client/components/elements/addToQuotationForm/AddToQuotationForm';
import { PrimaryButton, SecondaryLink } from '../../controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./AddToQuotationDialog.scss');
}

function mapStateToProps (state) {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'addToQuotation', 'isVisible']),
    products: state.getIn(['ui', 'dialogs', 'addToQuotation', 'products'], fromJS([])),
    status: state.getIn(['ui', 'dialogs', 'addToQuotation', 'status']),
    quotations: state.getIn(['quotations', 'all'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideAddToQuotationDialog: flowRight(dispatch, hideAddToQuotationDialog),
    createQuotation: flowRight(dispatch, createQuotation),
    fetchQuotations: flowRight(dispatch, fetchQuotations),
    addProductsToQuotation: flowRight(dispatch, addProductsToQuotation),
    goToUrl: flowRight(dispatch, goToUrl)
  };
}

export class AddToQuotationDialog extends React.Component {
  static propTypes = {
    hideAddToQuotationDialog: PropTypes.func,
    isVisible: PropTypes.bool,
    products: PropTypes.object,
    status: PropTypes.number
  };

  componentWillReceiveProps (newProps) { // eslint-disable-line react/no-deprecated
    if (!this.props.isVisible && newProps.isVisible) {
      this.props.fetchQuotations();
    }
  }

  productInfo () {
    const { products, status } = this.props;
    if (!products || products.size === 0) return null;
    const product = products.get(0);
    if (products.size === 1) {
      const message = status === ADD_TO_QUOTATION_STATUS__SUCCESS
        ? 'This product has been added to your quotation'
        : 'Which quotation do you want to add this product to?';
      return (<div className="AddToQuotationDialog_productInfo">
        <div className="row">
          <div className="col-md-2">
            <ZoroImage className="AddToQuotationDialog_img" src={ product.get('img') } alt={ product.get('name') } />
          </div>
          <div className="col-md-10">
            <div className="AddToQuotationDialog_info">{ message }</div>
            <p className="AddToQuotationDialog_prodName">{ product ? product.get('name') : '' }</p>
          </div>
        </div>
      </div>);
    }
    const message = status === ADD_TO_QUOTATION_STATUS__SUCCESS
      ? 'The basket has been added to your quotation'
      : 'Which quotation would you like to add this basket to?';
    return (<div className="AddToQuotationDialog_productInfo">
      <div className="row">
        <div className="col-md-12">
          <div className="AddToQuotationDialog_info">{ message }</div>
        </div>
      </div>
    </div>);
  }

  addToQuotationBody () {
    const { products, createQuotation, addProductsToQuotation, quotations } = this.props;
    if (!products || products.size === 0) return null;
    return (<div>
      { this.productInfo() }
      <div className="row AddToQuotationDialog_form">
        <div className="col-md-10 col-md-offset-2">
          <AddToQuotationForm
            form="addToQuotationForm"
            createQuotation={ createQuotation }
            addProductsToQuotation={ addProductsToQuotation }
            products={ products.map((p) => (new Map({ sku: p.get('sku'), qty: p.get('qty') }))) }
            quotations={ quotations }
          />
        </div>
      </div>
    </div>);
  }

  onViewQuotations = () => {
    this.props.hideAddToQuotationDialog();
  }

  onContinueShopping = () => {
    this.props.hideAddToQuotationDialog();
  }

  confirmationBody (hideAddToQuotationDialog) {
    return (<div className="AddToQuotationDialog_confirmation">
      { this.productInfo() }
      <div className="col-md-12 AddToQuotationDialog_buttonsPanel AddToQuotationDialog_btnPosition">

        <SecondaryLink isExternalLink={ true } to="/my-account/quotations"
          className="AddToQuotationDialog_linkMargin"
          onClick={ this.onViewQuotations }
          text={ getLocalizedString('quotationDialog.label.viewQuotations') } />

        <PrimaryButton onClick={ this.onContinueShopping }
          text={ getLocalizedString('quotationDialog.label.continueShopping') }/>

      </div>
    </div>);
  }

  render () {
    const { hideAddToQuotationDialog, isVisible, status } = this.props;

    const body = status === ADD_TO_QUOTATION_STATUS__SUCCESS
      ? this.confirmationBody(hideAddToQuotationDialog) : this.addToQuotationBody();

    return (
      <Modal className="AddToQuotationDialog" show={ isVisible } onHide={ hideAddToQuotationDialog }>
        <Modal.Header className="AddToQuotationDialog_header" closeButton>
          <div style={{ width: '100%' }} />
        </Modal.Header>
        <Modal.Body className="AddToQuotationDialog_body">
          { body }
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToQuotationDialog);
