import { getLocalizedStringWithParam } from 'localization/localizer';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
if (process.browser) {
  require('./copyPopOver.scss');
}

const showCopyPopOver = (sku, showToaster) => {
  const copyPopoverDisplayTime = 1500;
  showToaster(getToastMessage(
    getLocalizedStringWithParam('sku.copied', { sku })
  ), 'top-right', copyPopoverDisplayTime);
};

export const copy = (sku, showToaster) => {
  const input = document.createElement('textarea');
  input.innerHTML = sku;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  showCopyPopOver(sku, showToaster);
  return result;
};
