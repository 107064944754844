//
// THESE FILE IS FOR THE OLD FAMILY PAGE. DO NOT USE IT
//
// TODO: REMOVE THIS FILE AFTER NEW PRODUCT PAGE GOES LIVE (see isNewFamilyPageEnabled)

import { ApiRequest } from 'shared/utils/apiUtils';

export function getProductFamilyProducts (ids) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/products',
    query: {
      'sku': ids.join(',')
    }
  });
  return req.executeAndGetBody();
}

export function getProductFamily (id) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/productFamily',
    query: { familyId: id }
  });
  return req.executeAndGetBody();
}
