import React, { Component } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import _get from 'lodash/get';
import { Iterable, List } from 'immutable';

import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';

import Root from './Root';
import { loadCartQuantity, addToCart } from 'client/actions/cartActions';
import { requestPublicHolidays } from 'client/actions/publicHolidaysActions';
import { showLoginDialog, hideDiscountCodeDialog, checkDiscountCodeDialog } from 'client/actions/ui/dialogActions';
import { logoutUser } from 'client/actions/userActions';
import { terminateCustomerServiceAgentSession } from 'client/actions/ui/cookieActions';
import { setMobileFiltersVisibility } from 'client/actions/ui/filterPanelActions';
import { categoryBrowsingStarted } from 'client/actions/ui/categoriesDropdownActions';
import {
  toggleDropdownMenu,
  toggleAllCategoryPage
} from 'client/actions/ui/dropdownMenuActions';
import { NAV_SEARCH_FIELD } from 'client/components/elements/NewHeader/components/MenuItems/SearchBarItem';
import {
  reloadAccountDetails
} from 'client/actions/accountActions';
import { applyDiscountCodeToExistingCart } from 'client/actions/discountCodeActions';
import UnleashFlagProviderWrapper from '../../elements/unleashABTest/UnleashFlagProviderWrapper';
import { getJwtUnleashUserId } from 'shared/utils/jwtUtils';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { getContext } from 'shared/utils/unleashUtils';
import { setUnleashValuesRequest } from 'client/actions/unleashActions';

const mapStateToProps = (state, ownProps) => {
  const publicHolidays = state.get('publicHolidays', []);
  const publicHolidaysArray = publicHolidays && Iterable.isIterable(publicHolidays) ? publicHolidays.toJS() : publicHolidays;
  const token = state.getIn(['auth', 'jwtToken']);
  const longSessionToken = state.getIn(['auth', 'longSessionJwtToken'], '');
  const isLoggedIn = state.getIn(['auth', 'isLoggedIn']);
  const isLoggedInOrhasValidLongSessionToken = state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken']);
  const { unleashUserId, zoroUserId } = getJwtUnleashUserId(token, longSessionToken, isLoggedInOrhasValidLongSessionToken);
  const deviceInfo = state.get('deviceInfo').toJS();
  const context = getContext({ unleashUserId, zoroUserId, isLoggedIn, ...deviceInfo });
  return {
    url: ownProps.location.pathname,
    cart: state.get('cart'),
    user: state.get('user'),
    categories: state.get('categories'),
    dialogs: state.getIn(['ui', 'dialogs']),
    isLoggedIn: state.getIn(['auth', 'isLoggedIn']),
    screenName: _get(ownProps, 'children.props.route.screenName', ''),
    routeParams: ownProps.routeParams,
    query: ownProps.location.query.query,
    mobileFilters: state.getIn(['filterPanel', 'mobile']),
    sale: state.get('sale'),
    showDropdownMenu: state.getIn(['showDropdownMenu', 'visible']),
    searchFieldIsVisible: state.getIn(['ui', 'toggleElement', NAV_SEARCH_FIELD], true), // Used with "New" header
    csAgentName: state.getIn(['customerService', 'csAgentName']),
    csAgentDisplayName: state.getIn(['customerService', 'csAgentDisplayName']),
    quickOrderVisible: state.getIn(['showDropdownMenu', 'visibleDropdown'], VISIBLE_DROPDOWN.NONE) === VISIBLE_DROPDOWN.QUICK_ORDER,
    publicHolidays: publicHolidaysArray,
    isBasketConfirmationDropdown: state.getIn(['ui', 'toggleElement', 'showBasketConfirmationDropdown']),
    unleashConfig: state.getIn(['config', 'unleashProxy']).toJS(),
    totalProductsCount: state.getIn(['config', 'totalProductsCount'], '1.6m'),
    isUnleashEnabled: state.getIn(['config', 'isUnleashEnabled']),
    unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS(),
    context,
    websiteUrl: state.getIn(['config', 'websiteUrl'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  applyDiscountCodeToExistingCart: (discountCode) => dispatch(applyDiscountCodeToExistingCart(discountCode)),
  showLoginDialog: (nextOperation) => dispatch(showLoginDialog(nextOperation)),
  setMobileFiltersVisibility: (value) => dispatch(setMobileFiltersVisibility(value)),
  categoryBrowsingStarted: (category) => dispatch(categoryBrowsingStarted(category)),
  logoutUser: () => dispatch(logoutUser()),
  addToCart: (orderLinesToBeAdded) => dispatch(addToCart(orderLinesToBeAdded)),
  dispatchToggleAllCategoryPage: (...args) => dispatch(toggleAllCategoryPage(...args)),
  toggleDropdownMenu: (visibleDropdown) => dispatch(toggleDropdownMenu(visibleDropdown)),
  loadCartQuantity: () => dispatch(loadCartQuantity()),
  requestPublicHolidays: () => dispatch(requestPublicHolidays()),
  terminateCustomerServiceAgentSession: flowRight(dispatch, terminateCustomerServiceAgentSession),
  checkDiscountCodeDialog: (discountCode) => dispatch(checkDiscountCodeDialog({ code: discountCode })),
  hideDiscountCodeDialog: () => {
    return dispatch(hideDiscountCodeDialog());
  },
  reloadAccountDetails: () => dispatch(reloadAccountDetails()),
  updateDataLayer: flowRight(dispatch, updateDataLayer),
  setUnleashValuesRequest: (unlFeature, unlVariant) => dispatch(setUnleashValuesRequest({ unlFeature, unlVariant }))
});

class RootInjectionWrapper extends Component {
  componentWillMount () { // eslint-disable-line react/no-deprecated
    const checkForDiscountCode = this.props.location && this.props.location.query && this.props.location.query.discount_code;
    if (!!checkForDiscountCode && this.props.cart.get('isSaleActive', false)) {
      this.props.applyDiscountCodeToExistingCart(this.props.location.query.discount_code);
    }
    const unlFeature = this.props.location && this.props.location.query && this.props.location.query.unl_feature;
    const unlVariant = this.props.location && this.props.location.query && this.props.location.query.unl_variant;
    const { setUnleashValuesRequest } = this.props;
    if (unlFeature && unlVariant) {
      setUnleashValuesRequest(unlFeature, unlVariant);
    }
  }

  render () {
    const { unleashConfig, isUnleashEnabled, unleashToggles, context } = this.props;
    return (
      <UnleashFlagProviderWrapper
        unleashConfig={ unleashConfig }
        isUnleashEnabled= { isUnleashEnabled }
        prefetchedToggles= { unleashToggles }
        context= { context }>
        <Root { ...this.props } />
      </UnleashFlagProviderWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  RootInjectionWrapper
);
