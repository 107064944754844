import { call, put, select } from 'redux-saga/effects';
import { orderSelectors } from './selectors';
import { setApiButtonStatus } from 'client/actions/ui/apiCallButtonActions';
import { ERROR } from 'shared/constants/apiButtonConstants';
import { placeOrderFailed } from 'client/actions/checkoutActions';
import { INSUFFICIENT_FUNDS_CODE, TRANSACTION_AUTHORIZED } from 'shared/constants/braintree';
import { gotoExternalUrl } from 'client/actions/routeActions';
import { getLocalizedString } from 'localization/localizer';
import { CHECKOUT_SELECTOR, SINGLE_PAGE_PAYMENT } from 'shared/constants/singlePageCheckout';

export function * createOrder (paymentType, billingAddress, paymentInformation) {
  const isPaymentFlowSkipped = yield select(orderSelectors.isPaymentFlowSkipped);
  return {
    order: {
      shippingDetails: {
        shippingAddress: yield select(orderSelectors.shippingAddress),
        shippingNotes: ''
      },
      billingDetails: {
        billingAddress
      },
      discountCode: yield select(orderSelectors.discountCode),
      poReference: yield select(orderSelectors.poReference),
      csAgentName: yield select(orderSelectors.csAgent.name),
      csAgentDisplayName: yield select(orderSelectors.csAgent.displayName),
      isPaymentFlowSkipped,
      paymentType: isPaymentFlowSkipped ? undefined : paymentType
    },
    paymentInformation
  };
}

export function * orderFailed (message) {
  yield put(setApiButtonStatus(CHECKOUT_SELECTOR, ERROR));
  yield put(placeOrderFailed({ message }));
  yield put(setApiButtonStatus(SINGLE_PAGE_PAYMENT, ERROR));
}

export function * handleResponse (response) {
  if (response.status === TRANSACTION_AUTHORIZED) {
    yield put(gotoExternalUrl(response.nexturl));
  } else {
    yield call(orderFailed, getOrderErrorMessage(response.transaction.errorCode));
  }
}

function getOrderErrorMessage (errorCode) {
  return INSUFFICIENT_FUNDS_CODE === errorCode
    ? getLocalizedString('singlePageCheckout.card.declined.message')
    : getLocalizedString('singlePageCheckout.place.order.error');
}
