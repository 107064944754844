import Immutable from 'immutable';
import { getTotalQuantity } from 'client/utils/cartUtils';
import {
  handleActions
} from 'redux-actions';

import {
  PENDING,
  SUCCESS
} from 'shared/constants/loadStateType';

import {
  REQUEST_LOAD_CART,
  RECIEVE_LOAD_CART_SUCCESS,
  RECIEVE_LOAD_CART_ERROR,

  RECEIVE_LOAD_CART_QUANTITY_SUCCESS,

  REQUEST_SET_ORDER_LINE_QUANTITY,
  RECIEVE_SET_ORDER_LINE_QUANTITY_SUCCESS,
  RECIEVE_SET_ORDER_LINE_QUANTITY_ERROR,

  REQUEST_SET_BUNDLE_QUANTITY,
  RECEIVE_SET_BUNDLE_QUANTITY_SUCCESS,
  RECEIVE_SET_BUNDLE_QUANTITY_ERROR,

  REQUEST_ADD_TO_CART,

  REQUEST_REMOVE_ORDER_LINE_CONFIRM,
  RECIEVE_REMOVE_ORDER_LINE_SUCCESS,
  RECIEVE_REMOVE_ORDER_LINE_ERROR,

  REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM,
  RECEIVE_REMOVE_BUNDLE_ORDER_LINE_SUCCESS,
  RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR
} from 'client/actions/cartActions';

import {
  REQUEST_APPLY_DISCOUNT_CODE,
  REQUEST_REMOVE_DISCOUNT_CODE,
  APPLYING_DISCOUNT_CODE
} from 'client/actions/discountCodeActions';

import {
  returnActionPayloadWithLoadStateTypeSuccess,
  returnCurrentStateWithLoadStateTypeError
} from 'shared/utils/reducerUtils';

export const defaultState = Immutable.fromJS({
  loadState: {
    type: PENDING
  },
  orderLines: [],
  bundles: [],
  quantity: 0,
  discount: {
    code: null,
    campaignTitle: null,
    isValid: false,
    type: null,
    termsAndConditions: [],
    applyingDiscountCodeRequest: false
  },
  showDeliveryOptions: null,
  selectedDeliveryOptions: null,
  eligibleProducForDeliveryOptionList: []
});

export const actionMap = {

  // --------------------------------------------------
  //
  // Cart reducers

  [REQUEST_LOAD_CART]: (state = defaultState, action) => {
    if (state.get('orderLines').size === 0 && state.get('bundles').size === 0) {
      return state.setIn(['loadState', 'type'], PENDING);
    }
    return state;
  },

  [RECIEVE_LOAD_CART_SUCCESS]: (state = defaultState, action) => {
    const quantity = action && action.payload ? getTotalQuantity(action.payload) : 0;

    // If no action.payload is passed in pass back the initial state
    return Immutable
      .fromJS(action && action.payload ? action.payload : {})
      .set('quantity', quantity)
      .setIn(['discount', 'isValid'], !!action.payload.discount.campaignTitle)
      .setIn(['loadState', 'type'], SUCCESS);
  },

  [RECIEVE_LOAD_CART_ERROR]: returnCurrentStateWithLoadStateTypeError(defaultState),

  // --------------------------------------------------
  // Set order line quantity

  [REQUEST_SET_ORDER_LINE_QUANTITY]: (state = defaultState, action) => {
    const orderLineIndex = state.get('orderLines').findIndex((orderLine) => orderLine.get('sku') === action.payload.id);
    return state.setIn(['orderLines', orderLineIndex, 'quantity'], action.payload.quantity);
  },

  [RECIEVE_SET_ORDER_LINE_QUANTITY_SUCCESS]: returnActionPayloadWithLoadStateTypeSuccess(defaultState),

  [RECIEVE_SET_ORDER_LINE_QUANTITY_ERROR]: returnCurrentStateWithLoadStateTypeError(defaultState),

  // Set bundle quantity

  [REQUEST_SET_BUNDLE_QUANTITY]: (state = defaultState, action) => {
    const bundleIndex = state.get('bundles').findIndex((bundle) => {
      const primaryProduct = bundle.get('products').find(product => product.get('isPrimary') === true);
      return bundle.get('sku') === action.payload.orderLine.get('sku') &&
        primaryProduct.get('sku') === action.payload.orderLine.get('skuOfPrimaryProduct');
    });
    return state.setIn(['bundles', bundleIndex, 'bundleQuantity'], action.payload.quantity);
  },

  [RECEIVE_SET_BUNDLE_QUANTITY_SUCCESS]: returnActionPayloadWithLoadStateTypeSuccess(defaultState),

  [RECEIVE_SET_BUNDLE_QUANTITY_ERROR]: returnCurrentStateWithLoadStateTypeError(defaultState),

  // --------------------------------------------------
  // Add product variant

  [REQUEST_ADD_TO_CART]: (state = defaultState, action) => {
    // const orderLineIndex = state.getIn(['dirty', 'orderLines']).findIndex(orderLine => orderLine.get('id') === action.payload.id)
    // return state.setIn(['dirty', 'orderLines', orderLineIndex, 'quantity'], action.payload.quantity);
    return state;
  },

  // --------------------------------------------------
  // Remove order line

  [REQUEST_REMOVE_ORDER_LINE_CONFIRM]: (state = defaultState, action) => {
    const orderLines = state.get('orderLines').toJS();
    const updatedOrderLines = orderLines.filter(orderLine => orderLine.sku !== action.payload.get('sku'));

    return state.set('orderLines', Immutable.fromJS(updatedOrderLines));
  },

  [RECIEVE_REMOVE_ORDER_LINE_SUCCESS]: returnActionPayloadWithLoadStateTypeSuccess(defaultState),

  [RECIEVE_REMOVE_ORDER_LINE_ERROR]: returnCurrentStateWithLoadStateTypeError(defaultState),

  // Remove bundle order line

  [REQUEST_REMOVE_BUNDLE_ORDER_LINE_CONFIRM]: (state = defaultState, action) => {
    const bundleOrderLineIndex = state.get('bundles').findIndex((item) => {
      const primaryProduct = item.get('products').find(product => product.get('isPrimary') === true);
      return item.get('sku') === action.payload.get('sku') &&
        primaryProduct.get('sku') === action.payload.get('skuOfPrimaryProduct');
    });
    return state.set('bundles', state.get('bundles').delete(bundleOrderLineIndex));
  },

  [RECEIVE_REMOVE_BUNDLE_ORDER_LINE_SUCCESS]: returnActionPayloadWithLoadStateTypeSuccess(defaultState),

  [RECEIVE_REMOVE_BUNDLE_ORDER_LINE_ERROR]: returnCurrentStateWithLoadStateTypeError(defaultState),

  [RECEIVE_LOAD_CART_QUANTITY_SUCCESS]: (state = defaultState, action) => {
    return state.set('quantity', action.payload);
  },

  [REQUEST_APPLY_DISCOUNT_CODE]: (state = defaultState, action) => {
    return state
      .setIn(['discount', 'type'], action.payload.type)
      .setIn(['discount', 'isValid'], false)
      .setIn(['discount', 'code'], action.payload.discountCode);
  },

  [REQUEST_REMOVE_DISCOUNT_CODE]: (state = defaultState, action) => {
    return state
      .setIn(['discount', 'code'], null)
      .setIn(['discount', 'campaignTitle'], null)
      .setIn(['discount', 'termsAndConditions'], null);
  },

  [APPLYING_DISCOUNT_CODE]: (state = defaultState, action) => {
    return state.setIn(['discount', 'applyingDiscountCodeRequest'], action.flag);
  }
};

export default handleActions(actionMap, defaultState);
