import { createAction } from 'redux-actions';

export const REQUEST_SAVED_CARDS = 'REQUEST_SAVED_CARDS';
export const requestSavedCards = createAction(REQUEST_SAVED_CARDS);

export const REQUEST_SAVED_CARDS_SUCCESS = 'REQUEST_SAVED_CARDS_SUCCESS';
export const requestSavedCardsSuccess = createAction(REQUEST_SAVED_CARDS_SUCCESS);

export const REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE = 'REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE';
export const requestDefaultPaymentMethodChange = createAction(REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE);

export const REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE_SUCCESS = 'REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE_SUCCESS';
export const requestDefaultPaymentMethodChangeSuccess = createAction(REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE_SUCCESS);

export const DELETE_PAYMENT_METHOD_CONFIRM = 'DELETE_PAYMENT_METHOD_CONFIRM';
export const deletePaymentMethodConfirm = createAction(DELETE_PAYMENT_METHOD_CONFIRM);

export const DELETE_PAYMENT_METHOD_CONFIRM_SUCCESS = 'DELETE_PAYMENT_METHOD_CONFIRM_SUCCESS';
export const deletePaymentMethodConfirmSuccess = createAction(DELETE_PAYMENT_METHOD_CONFIRM_SUCCESS);

export const REQUEST_REMOVE_PAYMENT_METHOD_CONFIRM = 'REQUEST_REMOVE_PAYMENT_METHOD_CONFIRM';
export const requestRemovePaymentMethodConfirm = createAction(REQUEST_REMOVE_PAYMENT_METHOD_CONFIRM);

export const CLEAR_SAVED_CARDS = 'CLEAR_SAVED_CARDS';
export const clearSavedCards = createAction('CLEAR_SAVED_CARDS');
