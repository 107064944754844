import React, { Component } from 'react';
import _truncate from 'lodash/truncate';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

export default class CmsBrandsTile extends Component {
  render = () => (
    <a href={ this.props.item.to }>
      <div className="CmsShoveler_Brands_Tile">
        <div className="CmsShoveler_Brands_Tile_img">
          <ZoroImage src={ this.props.item.imgUrl } alt={ this.props.item.name }/>
        </div>
        <p className="CmsShoveler_Brands_Tile_title">
          { _truncate(this.props.item.name, { 'length': 40, 'separator': /,? +/ }) }
        </p>
      </div>
    </a>
  );
}
