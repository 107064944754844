import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { setDefaultDeliveryAddress, addressFormDefaultDeliveryToggle } from 'client/actions/addressActions';
import { connect } from 'react-redux';

if (process.browser) {
  require('./AddressBlock.scss');
}

const DefaultDelivery = (props) => {
  const {
    address,
    addressId,
    setDefaultDeliveryAddress,
    isDefault,
    isAddressForm,
    addressFormDefaultDeliveryToggle,
    addressesDisplayed,
    addressesSkip
  } = props;
  const submitHandler = () => isAddressForm
    ? addressFormDefaultDeliveryToggle(isDefault)
    : setDefaultDeliveryAddress(address, addressId, isDefault, addressesDisplayed, addressesSkip);
  return (
    <div className="AddressSection_defaultDelivery">
      <a
        onClick={ () => submitHandler() }
        className={
          `AddressSection_defaultDelivery_link 
          ${isDefault ? 'AddressSection_defaultDelivery_activeLink' : 'AddressSection_defaultDelivery_inActiveLink'}`
        }
        title={ isDefault ? 'Default Delivery Address' : 'Set this as Default Delivery Address' }
      >
        <SvgLoader xlinkHref="#home-icon" className={
          `AddressSection_defaultDelivery_icon 
          ${isDefault ? 'AddressSection_defaultDelivery_activeIcon' : ''}`
        } title="Edit Address" />
        <span className="AddressSection_defaultDelivery_label">
          {
            isDefault
              ? 'Default Delivery'
              : 'Make Default Delivery'
          }
        </span>
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addressFormDefaultDeliveryToggle: (isDefault) => dispatch(addressFormDefaultDeliveryToggle({ isDefault })),
    setDefaultDeliveryAddress: (address, addressId, isDefault, addressesDisplayed, addressesSkip) => dispatch(setDefaultDeliveryAddress({ address, addressId, isDefault, addressesDisplayed, addressesSkip }))
  };
};

export default connect(null, mapDispatchToProps)(DefaultDelivery);
