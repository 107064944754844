import { all, call, fork, takeLatest, put } from 'redux-saga/effects';
import { REQUEST_CREDIT_LIMIT_INCREASE, requestCreditLimitIncreaseSuccess, requestCreditLimitIncreaseFailure } from './RequestCreditLimitActions';
import { requestLimitIncreaseForUsers } from '../../../../../shared/endpoints/creditAccountEndpoint';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { toastError } from 'client/actions/showNotificationsActions';
import { getLocalizedString } from 'localization/localizer';

const TOASTER_DELAY = 3000;

export function * requestLimitIncrease () {
  try {
    const requestLimitResponse = yield call(requestLimitIncreaseForUsers, {});
    yield put(requestCreditLimitIncreaseSuccess(requestLimitResponse));
  } catch (error) {
    yield put(requestCreditLimitIncreaseFailure(error));
    yield put(toastError(
      getToastMessage(
        'Request sent failed.',
        getLocalizedString('requestCreditLimitIncrease.request.sent.failed.error')
      ), 'top-right', TOASTER_DELAY
    ));
  }
}

function * watchInitialize () {
  yield takeLatest(REQUEST_CREDIT_LIMIT_INCREASE, requestLimitIncrease);
}
export function * watchAllRequestCreditLimitSagas () {
  yield all([
    fork(watchInitialize)
  ]);
}
