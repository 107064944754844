import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { fromJS, List } from 'immutable';
import { SecondaryButton, PrimaryLink } from 'client/components/controls/StyledForms';
import SvgLoader from 'client/components/svg/SvgLoader';
import { hideLoginDialog } from 'client/actions/ui/dialogActions';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { DELIVERY_CHECKOUT_ROUTE, CART_ROUTE, SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { isZoneTwoOrThree } from 'client/utils/userDataUtils';
import { getCheckoutProductCountAbTestActive, getCheckoutProductCountAbTestThreshold } from 'client/utils/unleashUtils';
import { SHOW } from 'shared/constants/abTesting';

const SuccesfullyRegistered = ({ primaryContactForm, cartTotal, hideLoginDialog, parcelForceZone, beforeCheckoutproductCountThreshold, cartLines, isCheckoutProductCountActive }) => {
  const isCartLineAboveCountThreshold = beforeCheckoutproductCountThreshold && cartLines.length >= beforeCheckoutproductCountThreshold;
  const isCartTotalAboveThreshold = (cartTotal?.cartGrandTotalWithVat || 0) >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
  const isCartPage = typeof window !== 'undefined' && window?.location?.pathname === CART_ROUTE;

  return <div className="BusinessAccountRegistrationForm_succesfullyRegistered">
    <div className="BusinessAccountRegistrationForm_succesfullyRegistered-iconContainer">
      <SvgLoader className="BusinessAccountRegistrationForm_succesfullyRegistered-icon" xlinkHref="email-sent" />
    </div>
    <div className="BusinessAccountRegistrationForm_succesfullyRegistered-title">Welcome {primaryContactForm?.firstName}! Your Zoro account is nearly ready.</div>
    <div className="BusinessAccountRegistrationForm_succesfullyRegistered-text">
        To process your credit line application, please verify your email address by clicking the link in the email we just sent to <span className="BusinessAccountRegistrationForm_succesfullyRegistered-email">{primaryContactForm?.email}</span>.
    </div>
    <div className="BusinessAccountRegistrationForm_succesfullyRegistered-text">
        Please allow us up to 48 hours to review your application and decision will be sent via email. In the meantime, you can place orders using your business account and we will put them on hold until account has been approved.
    </div>
    <div>
      { !isCartPage ? <SecondaryButton
        datae2e="continueShopping"
        className="BusinessAccountRegistrationForm_succesfullyRegistered-continueShopping"
        onClick={ () => hideLoginDialog() }
      >
        Continue shopping
      </SecondaryButton> : <PrimaryLink
        isExternalLink={ true }
        className="BusinessAccountRegistrationForm_succesfullyRegistered-continuePayment"
        text="Continue to payment"
        datae2e="continueToPayment"
        to={ (!isCartTotalAboveThreshold && !isCheckoutProductCountActive) || isCartLineAboveCountThreshold ? DELIVERY_CHECKOUT_ROUTE : SINGLE_PAGE_CHECKOUT_ROUTE }
      /> }
    </div>
  </div>;
};

const mapDispatchToProps = (dispatch) => ({
  hideLoginDialog: () => dispatch(hideLoginDialog())
});

const mapStateToProps = (state) => {
  return {
    cartTotal: (state.getIn(['cart', 'cartPrice']) || fromJS({})).toJS(),
    cartLines: state.getIn(['cart', 'orderLines'], fromJS([])).toJS(),
    primaryContactForm: (getFormValues('primaryContactForm')(state) || fromJS({})).toJS(),
    parcelForceZone: state.getIn(['user', 'accountDetails', 'parcelForceZone']),
    beforeCheckoutproductCountThreshold: getCheckoutProductCountAbTestThreshold(state.getIn(['unleash', 'toggles'], List()).toJS(), SHOW),
    isCheckoutProductCountActive: getCheckoutProductCountAbTestActive(state.getIn(['unleash', 'toggles'], List()).toJS())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccesfullyRegistered);
