import { select, call, takeLatest, takeEvery, put, all, fork } from 'redux-saga/effects';
import {
  RELOAD_SCHEDULED_ORDERS,
  REMOVE_CART_SCHEDULED_ORDERS_SETTINGS,
  REMOVE_SCHEDULED_ORDER_SETTINGS,
  SAVE_SCHEDULED_ALL_ORDERLINES_SETTINGS,
  SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS,
  SAVE_SCHEDULED_ORDER_FORM_SETTINGS,
  SCHEDULE_SUBSCRIPTION_ALL_ORDER_LINES,
  saveScheduledOrderCartFormSettings,
  saveScheduledOrderFormSettings,
  setCartLevelFormStatus,
  setConfirmationScreenSubscriptionPayment,
  setInvalidSubscriptionPaymentMethod,
  SCHEDULE_ORDERLINE_SUBSCRIPTION,
  setIsLoadingCreateSubscriptionButton,
  setProductLineScheduledOrder
} from 'client/actions/scheduledOrders';
import { setScheduledOrders, removeScheduledOrdersSettings, createScheduledOrders } from 'shared/endpoints/scheduledOrdersEndpoint';
import { ScheduleOrderFormStatus } from 'client/components/elements/scheduledOrders/constants';
import { isSubscribableProduct, getMappedScheduledOrderLine, parseConfirmedOrderIdFromUrl, groupScheduledOrders, getFormStatus, isCheckoutSubscriptionPage, isConfirmationsScreen } from 'client/components/elements/scheduledOrders/utils';

import { toastError, toastSuccess } from 'client/actions/showNotificationsActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { getLocalizedString } from 'localization/localizer';
import { productLineItemsSelector, subscriptionUserInfoSelector, confirmedOrderSelector, scheduledOrderCartFormStatusSelector, scheduledOrderFormValuesSelector } from 'client/selectors/scheduledOrdersSelectors';
import { getOrderConfirmationOrder } from 'client/actions/ordersActions';
import { confirmScreenSubscribableProductsSelector } from 'client/selectors/scheduledOrdersSelectors';
import { fromJS, List } from 'immutable';
import { isLoggedInSelector } from 'client/sagas/accountSagaSelectors';
import { ACCOUNT, CARD } from 'shared/constants/braintree';
import { getAllSavedPaymentMethods } from 'shared/endpoints/braintreeEndpoint';
import { CARD as SUBSCRIPTION_CARD } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { CHANGE_PAYMENT_METHOD } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { orderSelectors, paymentMethodSelectors } from 'client/components/elements/paymentMethod/shared/selectors';
import { PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';

export function * updateCartScheduledOrders () {
  try {
    const isSubscriptionPage = isCheckoutSubscriptionPage();
    if (!isSubscriptionPage) {
      return;
    }
    const productLineItems = yield select(productLineItemsSelector);
    const formsSettings = yield select(scheduledOrderFormValuesSelector);

    if (!productLineItems || !formsSettings || !productLineItems.size || !formsSettings.size) {
      return;
    }

    const subscribableProducts = productLineItems.filter((item) => isSubscribableProduct(item) && formsSettings.has(item.get('sku')));

    const scheduledOrders = Object.keys(subscribableProducts.toJS()).map((sku) => {
      const { startDate, endDate, outOfStockDeliveryPreference, regularity } = formsSettings.get(sku, fromJS({})).toJS();
      return { sku, startDate, endDate, outOfStockDeliveryPreference, regularity };
    });

    yield all(scheduledOrders.map(scheduledOrder => put(setProductLineScheduledOrder(scheduledOrder.sku, scheduledOrder))));
    yield call(setScheduledOrders, { scheduledOrders });
  } catch (error) {
    yield put(toastError(
      getToastMessage('Failed to update cart scheduled orders'),
      'top-right', 5000)
    );
  }
}

function * getSubscribableProducts () {
  if (isConfirmationsScreen()) {
    const confirdedOrderId = parseConfirmedOrderIdFromUrl();
    const confirmedOrder = yield select(confirmedOrderSelector, confirdedOrderId);
    const orderlines = yield select(confirmScreenSubscribableProductsSelector, confirmedOrder.get('orderLines'));
    return orderlines;
  }

  const productLineItems = yield select(productLineItemsSelector);
  return productLineItems.filter(isSubscribableProduct);
}

export function * removeCartScheduledOrdersSettings () {
  try {
    const productLineItems = yield select(productLineItemsSelector);

    const subscribableProducts = yield * getSubscribableProducts();
    const skus = subscribableProducts ? Object.keys(subscribableProducts.toJS()) : [];
    yield put(setCartLevelFormStatus(getFormStatus(productLineItems)));
    const isOrderConfirmationScreen = parseConfirmedOrderIdFromUrl();

    if (!isOrderConfirmationScreen) {
      yield call(removeScheduledOrdersSettings, skus);
    }
  } catch (error) {
    yield put(toastError(
      getToastMessage('Failed to remove scheduled order settings'),
      'top-right', 5000)
    );
  }
}

export function * removeScheduledOrderSettings ({ payload: { formId, isPersistentState } = {} }) {
  try {
    const isSubscriptionPage = isCheckoutSubscriptionPage();

    const productLineItems = yield select(productLineItemsSelector);
    yield put(setCartLevelFormStatus(getFormStatus(productLineItems)));

    if (!isSubscriptionPage || !isPersistentState) {
      return;
    }

    yield call(removeScheduledOrdersSettings, [formId]);
  } catch (error) {
    yield put(toastError(
      getToastMessage(`Failed to remove scheduled order settings for ${formId}`),
      'top-right', 5000)
    );
  }
}

export function * getConfirmationScreenSubscriptionsPayment () {
  const defaultUserPaymentType = yield select(state => state.getIn(['user', 'accountDetails', 'defaultPaymentMethod']));
  const paymentMethods = yield call(getAllSavedPaymentMethods);
  const cardToken = (paymentMethods.find(payment => payment.token) || {}).token || null;
  const accountNumber = yield select(state => state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountNo'], ''));

  return cardToken && defaultUserPaymentType === CARD
    ? { paymentType: SUBSCRIPTION_CARD, cardToken }
    : accountNumber
      ? { paymentType: ACCOUNT }
      : cardToken ? { paymentType: SUBSCRIPTION_CARD, cardToken }
        : null;
}

export function * reloadScheduledOrders ({ payload: { productLineItems } = {} }) {
  if (!(productLineItems && productLineItems.size)) {
    yield put(setCartLevelFormStatus(ScheduleOrderFormStatus.HIDDEN));
    return;
  }
  const savedSettings = productLineItems.filter(item => item.hasIn(['scheduledOrder', 'regularity'])).map(item => item.get('scheduledOrder'));
  const subscribableProducts = productLineItems.filter(isSubscribableProduct);
  const isSubscribableProducts = !!(subscribableProducts && subscribableProducts.size);

  const firstSavedSettings = isSubscribableProducts && savedSettings.size === subscribableProducts.size ? savedSettings.first() : fromJS({});
  const isCartSettings = !!firstSavedSettings.size && !savedSettings.find(settings => {
    return settings.get('startDate') !== firstSavedSettings.get('startDate') ||
    settings.get('stopDate') !== firstSavedSettings.get('stopDate') ||
    settings.get('regularity') !== firstSavedSettings.get('regularity');
  });

  yield put(setCartLevelFormStatus(getFormStatus(productLineItems)));
  if (isCartSettings) {
    yield put(saveScheduledOrderCartFormSettings(firstSavedSettings.toJS()));
  }

  yield all(Object.keys(savedSettings.toJS()).map(sku => put(saveScheduledOrderFormSettings(sku, savedSettings.get(sku, fromJS({})).toJS()))));

  const confirmedOrderId = parseConfirmedOrderIdFromUrl();

  const confirmationScreenPayment = confirmedOrderId && isSubscribableProducts
    ? yield * getConfirmationScreenSubscriptionsPayment()
    : null;

  if (confirmationScreenPayment) {
    yield put(setConfirmationScreenSubscriptionPayment(fromJS(confirmationScreenPayment)));
  }
}

export function * scheduleOrderlineSubscription ({ payload: { settings, sku } }) {
  try {
    const confirdedOrderId = parseConfirmedOrderIdFromUrl();
    const isLoggedInShort = yield select(isLoggedInSelector);

    if (!confirdedOrderId || !isLoggedInShort) {
      yield put(
        toastError(getToastMessage(getLocalizedString('mySubscriptions.account.create.error')), 'top-right', 5000)
      );
      return;
    }

    const paymentAndDeliveryInfo = yield select(subscriptionUserInfoSelector);
    const confirmedOrder = yield select(confirmedOrderSelector, confirdedOrderId);
    const orderLine = confirmedOrder.get('orderLines', List()).find(orderLine => orderLine.get('sku') === sku) || fromJS({});
    const subscription = getMappedScheduledOrderLine(paymentAndDeliveryInfo, settings)(orderLine);

    if (!subscription) {
      return;
    }
    yield put(setIsLoadingCreateSubscriptionButton(sku, true));
    yield call(createScheduledOrders, {
      merlinOrderReference: confirmedOrder.get('merlinOrderReference'),
      subscriptions: [subscription]
    });
    yield put(getOrderConfirmationOrder(confirdedOrderId));
    const cartLevelFormStatus = yield select(scheduledOrderCartFormStatusSelector);

    if (cartLevelFormStatus !== ScheduleOrderFormStatus.HIDDEN) {
      yield put(setCartLevelFormStatus(getFormStatus(confirmedOrder.get('orderLines', List()))));
    }
    yield put(setIsLoadingCreateSubscriptionButton(sku, false));

    yield put(
      toastSuccess(getToastMessage(getLocalizedString('scheduledOrders.toast.create.success')), 'top-right', 5000)
    );
  } catch (error) {
    yield put(
      toastError(getToastMessage(getLocalizedString('mySubscriptions.account.create.error')), 'top-right', 5000)
    );
    yield put(setIsLoadingCreateSubscriptionButton(sku, false));
  }
}

export function * createSubscriptionAllOrderLines ({ payload: { settings } }) {
  try {
    const confirdedOrderId = parseConfirmedOrderIdFromUrl();
    const isLoggedInShort = yield select(isLoggedInSelector);

    if (!confirdedOrderId || !isLoggedInShort) {
      yield put(
        toastError(getToastMessage(getLocalizedString('mySubscriptions.account.create.error')), 'top-right', 5000)
      );
      return;
    }
    yield put(setIsLoadingCreateSubscriptionButton('cart', true));
    const paymentAndDeliveryInfo = yield select(subscriptionUserInfoSelector);
    const confirmedOrder = yield select(confirmedOrderSelector, confirdedOrderId);
    const productLineCreateSubscription = yield select(confirmScreenSubscribableProductsSelector, confirmedOrder.get('orderLines'));
    const subscriptions = productLineCreateSubscription.map(getMappedScheduledOrderLine(paymentAndDeliveryInfo, settings)).toArray();
    yield call(createScheduledOrders, {
      merlinOrderReference: confirmedOrder.get('merlinOrderReference'),
      subscriptions: groupScheduledOrders(subscriptions)
    });
    yield put(getOrderConfirmationOrder(confirdedOrderId));
    const cartLevelFormStatus = yield select(scheduledOrderCartFormStatusSelector);

    if (cartLevelFormStatus === ScheduleOrderFormStatus.SUBMITTED) {
      yield put(setCartLevelFormStatus(ScheduleOrderFormStatus.SUBSCRIPTION_CREATED));
    }

    yield put(
      toastSuccess(getToastMessage(getLocalizedString('scheduledOrders.toast.create.success')), 'top-right', 5000)
    );
    yield put(setIsLoadingCreateSubscriptionButton('cart', false));
  } catch (error) {
    yield put(
      toastError(getToastMessage(getLocalizedString('mySubscriptions.account.create.error')), 'top-right', 5000)
    );
    yield put(setIsLoadingCreateSubscriptionButton('cart', false));
  }
}

function * updateOrderLinesFormValues ({ payload: { settings } }) {
  try {
    const subscribableProducts = yield * getSubscribableProducts();
    const skus = subscribableProducts ? subscribableProducts.map(item => item.get('sku')).toIndexedSeq().toArray() : [];
    yield all(skus.map(sku => put(saveScheduledOrderFormSettings(sku, settings))));
  } catch (error) {
    yield put(
      toastError(getToastMessage('Error updating scheduled order settings'), 'top-right', 5000)
    );
  }
}

function * checkScheduledOrderOtherPayments () {
  const isPaymentFlowSkipped = yield select(orderSelectors.isPaymentFlowSkipped);
  const isSubscriptionPage = isCheckoutSubscriptionPage();

  if (!isSubscriptionPage || isPaymentFlowSkipped) {
    return;
  }
  const paymentMethod = yield select(paymentMethodSelectors.paymentMethod(CHECKOUT_SELECTOR));
  const isOtherPymentMethod = ![PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD].includes(paymentMethod);

  if (!isOtherPymentMethod) {
    return;
  }

  const scheduledOrdersFormsValues = yield select(scheduledOrderFormValuesSelector);
  const isSavedSettings = scheduledOrdersFormsValues.remove('cart').size && scheduledOrdersFormsValues.remove('cart').find(settings => settings.get('regularity'));

  if (isSavedSettings) {
    yield put(setInvalidSubscriptionPaymentMethod(paymentMethod));
  }
}

function * watchReloadScheduledOrders () {
  yield takeLatest(RELOAD_SCHEDULED_ORDERS, reloadScheduledOrders);
}

function * watchSaveScheduledOrderCartFormSettings () {
  yield takeLatest(SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS, updateOrderLinesFormValues);
}

function * watchSaveScheduledAllOrderLinesSettings () {
  yield takeLatest(SAVE_SCHEDULED_ALL_ORDERLINES_SETTINGS, updateCartScheduledOrders);
}

function * watchRemoveScheduledOrderSettings () {
  yield takeEvery(REMOVE_SCHEDULED_ORDER_SETTINGS, removeScheduledOrderSettings);
}

function * watchRemoveCartScheduledOrdersSettings () {
  yield takeLatest(REMOVE_CART_SCHEDULED_ORDERS_SETTINGS, removeCartScheduledOrdersSettings);
}

function * watchSaveScheduledOrderFormSettings () {
  yield takeLatest(SAVE_SCHEDULED_ORDER_FORM_SETTINGS, checkScheduledOrderOtherPayments);
}

function * watchChangePaymentMethod () {
  yield takeLatest(CHANGE_PAYMENT_METHOD, checkScheduledOrderOtherPayments);
}

function * watchScheduleSubscriptionAllOrderLines () {
  yield takeLatest(SCHEDULE_SUBSCRIPTION_ALL_ORDER_LINES, createSubscriptionAllOrderLines);
}

function * watchScheduleOrderlineSubscription () {
  yield takeEvery(SCHEDULE_ORDERLINE_SUBSCRIPTION, scheduleOrderlineSubscription);
}

export function * watchScheduledOrders () {
  yield all([
    fork(watchReloadScheduledOrders),
    fork(watchSaveScheduledOrderCartFormSettings),
    fork(watchSaveScheduledAllOrderLinesSettings),
    fork(watchRemoveScheduledOrderSettings),
    fork(watchRemoveCartScheduledOrdersSettings),
    fork(watchSaveScheduledOrderFormSettings),
    fork(watchChangePaymentMethod),
    fork(watchScheduleSubscriptionAllOrderLines),
    fork(watchScheduleOrderlineSubscription)
  ]);
}
