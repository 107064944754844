import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  makeValidator,
  isRequired,
  minValue
} from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import { getLocalizedString } from 'localization/localizer';
import QuantityInput from '../quantityInput/quantityInput';
import { fromJS } from 'immutable';
import QuantityErrorMessage from 'client/components/elements/quantityInput/quantityErrorMessage';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import SelectWithValidationsNewStyles from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import {
  CREATE_NEW_SUBSCRIPTION_STATUS_SUBMITTED
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

if (process.browser) {
  require('./AddToMySubscriptionForm.scss');
}

const validate = makeValidator({
  subscriptionProductQuantity: [
    isRequired(getLocalizedString('subscriptionForm.label.productQuantity')),
    minValue(1)
  ],
  existingSubscriptionId: [
    isRequired({
      field: getLocalizedString('subscriptionForm.label.existingSubscription'),
      message: getLocalizedString('subscription.fieldError.newOrExistingSubscription')
    })
  ]
});

const AddToMySubscriptionForm = (props) => {
  const {
    subscriptions,
    sku,
    quantity,
    handleSubmit,
    addProductToSubscription,
    onQuantityChange,
    isDeliverable,
    name,
    qtyMultiplier,
    confirmAddProductsToSubscriptionStatus,
    minOrderQuantity,
    maxOrderQuantity,
    itemQuantity
  } = props;
  const isQuantityValid = isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
  const showForm = ['readyToSubmit', 'bannerOnly', 'submitted'].includes(confirmAddProductsToSubscriptionStatus);
  const activeSubscriptions = subscriptions.filter(subscription => subscription.state !== 'STOP');
  const isAddToSubscriptionBtnDisabled = !isDeliverable || !isQuantityValid || confirmAddProductsToSubscriptionStatus === CREATE_NEW_SUBSCRIPTION_STATUS_SUBMITTED;
  const productFormQuantity = itemQuantity !== 1 ? itemQuantity : quantity;

  const submit = (values, dispatch) => {
    values.set('subscriptionProductQuantity', quantity);
    return validateForm(values, validate)
      .then(() => {
        const { existingSubscriptionId, subscriptionProductQuantity } = values.toJS();
        const subscription = find(activeSubscriptions, (subscription) => subscription._id === existingSubscriptionId);
        const productQuantity = subscriptionProductQuantity === 1 ? quantity : subscriptionProductQuantity;
        addProductToSubscription(existingSubscriptionId, sku, productQuantity, subscription.orderReference, name ? name : subscription.orderReference);
      })
      .catch((errors) => {
        throw new SubmissionError(errors);
      });
  };

  const subscriptionSelectionInput = activeSubscriptions.length
    ? (
      <div className="AddToMySubscriptionForm_Form_Details_existingSubscriptionSelection">
        <div className="row AddToMySubscriptionForm_formGroup_select_section">
          <Field
            name="existingSubscriptionId"
            id="existingSubscriptionId"
            className="AddToMySubscriptionForm_select_existing AddToMySubscriptionForm_formGroup_select AddToMySubscriptionForm_formGroup_select-required form-control col-md-5"
            component={ SelectWithValidationsNewStyles }
            data-e2e="existingSubscriptionId"
            labelResourceName="subscriptionForm.label.existingSubscription.select"
            labelClassName="AddToMySubscriptionForm_formGroup_text"
            value=""
          >
            <option key={ 0 } value="" disabled>{ getLocalizedString('addToMySubscriptionForm.selectSubscription') }</option>
            {
              activeSubscriptions.map(
                (entry, i) => <option key={ get(entry, '_id') } value={ get(entry, '_id') }>{get(entry, 'orderReference')}</option>
              )
            }
          </Field>
        </div>
      </div>
    )
    : null;
  return (
    <div className="AddToSubscriptionForm">
      { showForm ? <div className="AddToMySubscriptionForm_Form_Details col-xs-12">
        <form onSubmit={ handleSubmit(submit) } className="AddToMySubscriptionForm_container">
          <div className="AddToMySubscriptionForm_Form_Details_Quantity">
            <div className="Quantity_label AddToMySubscriptionForm_formGroup_label">
              { getLocalizedString('myAccount.myOrders.label.quantity') }
            </div>
            <QuantityInput
              disabled={ false }
              setQuantity={ onQuantityChange }
              product={ fromJS({ sku: sku }) }
              quantity={ productFormQuantity }
              unavailableStatus={ true }
              qtyMultiplier={ qtyMultiplier }
              minOrderQuantity = { minOrderQuantity }
              maxOrderQuantity = { maxOrderQuantity }
            />
            {/* { !isQuantityValid ? <div className="AddToMySubscriptionForm_Form_Details_ErrorMessage">
              <QuantityErrorMessage
                quantity={ productFormQuantity }
                qtyMultiplier={ qtyMultiplier }
                shortMessage={ true } />
            </div> : null } */}
            { !isQuantityValid
              ? <div className="AddToMySubscriptionForm_Form_Details_ErrorMessage">
                <QuantityErrorMessage
                  quantity= { productFormQuantity }
                  qtyMultiplier = { qtyMultiplier }
                  shortMessage = { true }
                  minOrderQuantity = { minOrderQuantity }
                  maxOrderQuantity = { maxOrderQuantity }
                />
              </div> : null
            }
          </div>

          {subscriptionSelectionInput}
          <div className="AddToMySubscriptionForm_button">
            <PrimaryButton
              type="button"
              disabled={ isAddToSubscriptionBtnDisabled }
              className="AddToMySubscriptionForm_submitButton col-md-5"
              data-e2e="submit"
              text={ getLocalizedString('addToMySubscriptionForm.addToSubscription') }
              onClick={ handleSubmit(submit) } />
          </div>
        </form>
      </div> : <div className="AddToMySubscriptionForm_addingProductsSpinnerContainer">
        <div className="AddToMySubscriptionForm_addingProductsSpinner"/>
        <div className="AddToMySubscriptionForm_addingProductsSpinner-text">{ getLocalizedString('createNewOrderForm.addingToSubscription') }</div>
      </div>
      }
    </div>

  );
};

let reduxAddToMySubscriptionForm = reduxForm({
  form: 'addToMySubscription',
  validate
})(AddToMySubscriptionForm);
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      existingSubscriptionId: '',
      subscriptionProductQuantity: 1
    },
    itemQuantity: state.getIn(['form', 'addToMySubscription', 'values', 'subscriptionProductQuantity'])
  };
};

reduxAddToMySubscriptionForm = connect(mapStateToProps)(reduxAddToMySubscriptionForm);

export { AddToMySubscriptionForm };
export default reduxAddToMySubscriptionForm;
