import { takeLatest, call, put,
  fork, all, select } from 'redux-saga/effects';
import {
  getPaymentMethods
} from 'shared/endpoints/braintreeEndpoint';
import {
  REQUEST_SCROLL_TO_REVIEW_PANEL
} from 'client/actions/braintreeActions';
import {
  setActivePanelId
} from 'client/actions/checkoutActions';
import {
  ORDER_REVIEW_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';
import { getBraintreeClientInstance } from './braintreeGatewaySagas';
import Braintree from 'braintree-web';

const getShippingAddress = (state) => state.getIn(['user', 'address', 'delivery']);
const getCart = (state) => state.getIn(['cart']);

export function preparingBillingAddressForPaypal (billingAddress) {
  if (billingAddress) {
    const {
      line1: addressLine1,
      firstName,
      lastName,
      city,
      state,
      postalCode
    } = billingAddress;
    return {
      addressLine1,
      firstName,
      lastName,
      city,
      state,
      postalCode
    };
  }
}

export function * getBraintreeDataForPaypal ({ payPalData }) {
  const clientInstance = yield call(getBraintreeClientInstance);
  const paypalCheckoutInstance = yield Braintree.paypalCheckout.create({ client: clientInstance });
  const { deviceData } = yield Braintree.dataCollector.create({ client: clientInstance, paypal: true });
  const { nonce, details } = yield paypalCheckoutInstance.tokenizePayment(payPalData);
  return {
    braintreePayload: {
      nonce,
      details
    },
    deviceData
  };
}

export function * handleRequestScrollToReviewPanel ({ payload }) {
  const { query } = payload;
  const isPayOnAccountQueryParam = query && query.onAccount;
  const { paymentMethods = [] } = yield call(getPaymentMethods);
  const shippingAddress = yield select(getShippingAddress);

  const cart = yield select(getCart);
  const showPayOnAccountButton = cart && cart.getIn(['tradeAccountButton', 'showPayOnAccountButton']);
  const hasTradeLimit = showPayOnAccountButton && cart && cart.getIn(['tradeAccountButton', 'hasTradeLimit']);
  const tradeAccountLimitExceeded = showPayOnAccountButton && cart && cart.getIn(['tradeAccountButton', 'tradeAccountLimitExceeded']);

  const shouldScrollToReviewPanelOnLoad = isPayOnAccountQueryParam
    ? !!shippingAddress && hasTradeLimit && !tradeAccountLimitExceeded
    : !!(shippingAddress && paymentMethods && paymentMethods.length);

  if (shouldScrollToReviewPanelOnLoad) {
    yield put(setActivePanelId({ panelId: -1 }));
    yield put(setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID }));
  }
}

export function * watchRequestScrollToReviewPanel () {
  yield takeLatest(REQUEST_SCROLL_TO_REVIEW_PANEL, handleRequestScrollToReviewPanel);
}

export function * watchAllBraintreeSagas () {
  yield all([
    fork(watchRequestScrollToReviewPanel)
  ]);
}
