import { put, call, takeLatest } from 'redux-saga/effects';

import {
  REQUEST_USER_QUESTION,
  responseUserQuestionSuccess,
  responseUserQuestionError
} from 'client/actions/userActions';
import { userEnquiry } from 'shared/endpoints/userEndpoint';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';

export function * onRequestReportHandler (action) {
  try {
    yield call(userEnquiry, action.body);
    yield put(responseUserQuestionSuccess());
  } catch (err) {
    yield put(responseUserQuestionError(buildErrorMessageFromError(err)));
  }
}

export function * watchUserReport () {
  yield takeLatest(REQUEST_USER_QUESTION, onRequestReportHandler);
}
