import PropTypes from 'prop-types';
import React from 'react';
import CmsComponentWrapper from 'client/components/elements/cms/cmsComponentWrapper/CmsComponentWrapper';
import CmsTextPanel from 'client/components/elements/cms/cmsTextPanel/CmsTextPanel';
import CmsImage from 'client/components/elements/cms/cmsImage/CmsImage';
import CmsOffersPanel from 'client/components/elements/cms/cmsOffersPanel/CmsOffersPanel';
import CmsSkusCarousel from 'client/components/elements/cms/cmsSkusCarousel/CmsSkusCarousel';
import CmsTilesPanel from 'client/components/elements/cms/cmsTilesPanel/CmsTilesPanel';
import CmsTwoColPanel from 'client/components/elements/cms/cmsTwoColPanel/CmsTwoColPanel';
import CmsFaqPanel from 'client/components/elements/cms/cmsFaqPanel/CmsFaqPanel';
import CmsPromos from 'client/components/elements/cms/cmsPromos/CmsPromos';
import CmsJumboCarousel from '../../elements/cms/cmsJumboCarousel/cmsJumboCarousel';
import CmsIndustriesShoveler from 'client/components/elements/cms/cmsShoveler/cmsIndustriesShoveler';
import CmsBrandsShoveler from 'client/components/elements/cms/cmsShoveler/cmsBrandsShoveler';
import CmsCategoriesShoveler from 'client/components/elements/cms/cmsShoveler/cmsCategoriesShoveler';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import CmsMultiTextPanel from 'client/components/elements/cms/cmsMultiTextPanel/CmsMultiTextPanel';
import { Map } from 'immutable';

if (process.browser) {
  require('./CmsContentScreen.scss');
}
const componentClasses = {
  TEXT_PANEL: CmsTextPanel,
  MULTI_TEXT_PANEL: CmsMultiTextPanel,
  IMAGE: CmsImage,
  OFFERS_PANEL: CmsOffersPanel,
  HORIZONTAL_LINE: 'hr',
  TILES_PANEL: CmsTilesPanel,
  SKUS_CAROUSEL: CmsSkusCarousel,
  TWO_COL_PANEL: CmsTwoColPanel,
  FAQ_PANEL: CmsFaqPanel,
  PROMOS: CmsPromos,
  JUMBO_CAROUSEL: CmsJumboCarousel,
  INDUSTRIES_SHOVELER: CmsIndustriesShoveler,
  BRANDS_SHOVELER: CmsBrandsShoveler,
  CATEGORIES_SHOVELER: CmsCategoriesShoveler
};

class CmsContentScreen extends React.Component {
  render () {
    const { pageData, offers, skusData, requestCmsOffers, dispatchRequestSkusData, isPriceWithVat, pageUrl } = this.props;
    if (!pageData) {
      return <ScreenLoadPending className="smallLoader" />;
    }

    const components = pageData.get('cmsComponents', []).map((compJson, i) => {
      const componentClass = componentClasses[compJson.get('type', '')];
      if (componentClass && componentClass !== 'hr') {
        const componentElement = React.createElement(
          componentClass,
          {
            data: compJson.get('data'),
            css: compJson.get('css'),
            offers: offers,
            skusData: skusData,
            requestCmsOffers: requestCmsOffers,
            dispatchRequestSkusData: dispatchRequestSkusData,
            isPriceWithVat: isPriceWithVat,
            panelIndex: i
          }
        );
        return (
          <CmsComponentWrapper
            key={ `component-${i}` }
            css={ compJson.get('css') }
            componentClass={ pageUrl }
          >
            { componentElement }
          </CmsComponentWrapper>
        );
      } else if (componentClass === 'hr') {
        return (
          <hr
            style={ compJson.get('css', Map()).toJS() }
            key={ `component-${i}` }
          />
        );
      }
      return null;
    });
    return (<div className="row CmsScreen"> { components } </div>);
  }
}

CmsContentScreen.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  pageData: PropTypes.object,
  location: PropTypes.object,
  offers: PropTypes.object,
  skusData: PropTypes.object,
  requestCmsOffers: PropTypes.func,
  dispatchRequestSkusData: PropTypes.func
};

export default CmsContentScreen;
