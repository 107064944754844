import dayjs from 'dayjs';

export const WEEKLY = 'WEEKLY';
export const FORTNIGHTLY = 'FORTNIGHTLY';
export const MONTHLY = 'MONTHLY';

export const SUBSCRIPTION_DISCOUNT_RATE = 5;

export const frequencyMap = {
  [WEEKLY]: 7,
  [FORTNIGHTLY]: 14,
  [MONTHLY]: dayjs().daysInMonth()
};

export const ScheduleOrderFormStatus = {
  VIEW: 'VIEW',
  FORM: 'FORM',
  SUBMITTED: 'SUBMITTED',
  SUBSCRIPTION_CREATED: 'SUBSCRIPTION_CREATED',
  HIDDEN: 'HIDDEN'
};

export const submittedSubscriptionRegularityMap = {
  [WEEKLY]: 'week',
  [FORTNIGHTLY]: '2 weeks',
  [MONTHLY]: 'month'
};
