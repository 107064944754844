import { select } from 'redux-saga/effects';
import { newRepeatOrderSelect } from 'client/sagas/repeatOrdersSaga';

export function * createSubscription (paymentType, cardToken) {
  const repeatOrderDetails = yield select(newRepeatOrderSelect);
  return {
    sku: repeatOrderDetails.sku,
    orderReference: repeatOrderDetails.orderReference,
    regularity: repeatOrderDetails.regularity,
    deliveryAddressId: repeatOrderDetails.deliveryAddressId,
    startDate: repeatOrderDetails.startDate,
    endDate: repeatOrderDetails.endDate,
    state: repeatOrderDetails.state,
    outOfStockDeliveryPreference: repeatOrderDetails.outOfStockDeliveryPreference,
    name: repeatOrderDetails.name || repeatOrderDetails.orderReference,
    quantity: repeatOrderDetails.subscriptionProductQuantity,
    paymentType,
    cardToken
  };
}
