import React from 'react';
import { connect } from 'react-redux';
import { PAY_BY_NEW_CARD } from '../PaymentMethod/constants';
import { changePaymentMethod } from '../PaymentMethod/paymentMethodActions';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./buttons.scss');
}

export const PayByNewCardButton = ({
  paymentMethodSelector,
  changePaymentMethod,
  setPaymentType
}) => {
  return (
    <button className="PaymentMethodButton" data-e2e="PaymentMethodButton_payByNewCard" onClick={ () => {
      setPaymentType(PAY_BY_NEW_CARD);
      changePaymentMethod({
        selector: paymentMethodSelector,
        paymentMethod: PAY_BY_NEW_CARD
      });
    } }>
      <SvgLoader
        xlinkHref="#card-icon"
        className="PaymentMethodButton_new_card_svg"
      />
      {getLocalizedString('payment.label.add.new.card')}
    </button>
  );
};

const mapDispatchToProps = {
  changePaymentMethod
};

export default connect(null, mapDispatchToProps)(PayByNewCardButton);
