import {
  CLEAR_SALE
} from 'client/actions/saleActions';

const defaultState = null;

const order = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_SALE:
      return null;
    default:
      return state;
  }
};

export default order;
