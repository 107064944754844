import _find from 'lodash/find';

export const topCategories = [
  { id: '49', title: 'Tools & Machining', menuOrder: 1 },
  { id: '24', title: 'Automotive', menuOrder: 2 },
  { id: '46', title: 'Safety', menuOrder: 3 },
  { id: '47', title: 'Office Supplies & Packaging', menuOrder: 4 },
  { id: '02', title: 'Laboratory Equipment', menuOrder: 5 },
  { id: '08', title: 'Cleaning & Hygiene', menuOrder: 6 },
  { id: '06', title: 'Storage & Handling', menuOrder: 7 },
  { id: '48', title: 'Power Transmission & Flow Control', menuOrder: 8 },
  { id: '09', title: 'Abrasives', menuOrder: 9 },
  { id: '18', title: 'Catering Supplies & Appliances', menuOrder: 10 },
  { id: '01', title: 'Welding, Brazing & Soldering', menuOrder: 11 },
  { id: '43', title: 'Connectors', menuOrder: 12 },
  { id: '05', title: 'Measuring & Test Equipment', menuOrder: 13 },
  { id: '04', title: 'Adhesives & Sealants', menuOrder: 14 },
  { id: '21', title: 'Computers & Electronics', menuOrder: 15 }
];

export const categories = [
  { id: '49', title: 'Tools & Machining', menuOrder: 1 },
  { id: '24', title: 'Automotive', menuOrder: 2 },
  { id: '46', title: 'Safety', menuOrder: 3 },
  { id: '47', title: 'Office Supplies & Packaging', menuOrder: 4 },
  { id: '02', title: 'Laboratory Equipment', menuOrder: 5 },
  { id: '08', title: 'Cleaning & Hygiene', menuOrder: 6 },
  { id: '06', title: 'Storage & Handling', menuOrder: 7 },
  { id: '48', title: 'Power Transmission', menuOrder: 8 },
  { id: '09', title: 'Abrasives', menuOrder: 9 },
  { id: '18', title: 'Catering Supplies & Appliances', menuOrder: 10 },
  { id: '01', title: 'Welding, Brazing & Soldering', menuOrder: 11 },
  { id: '43', title: 'Connectors', menuOrder: 12 },
  { id: '05', title: 'Measuring & Test Equipment', menuOrder: 13 },
  { id: '04', title: 'Adhesives & Sealants', menuOrder: 14 },
  { id: '21', title: 'Computers & Electronics', menuOrder: 15 },
  { id: '23', title: 'Fasteners', menuOrder: 16 },
  { id: '33', title: 'Spindle Nose & Workholding', menuOrder: 17 },
  { id: '19', title: 'Display Material', menuOrder: 18 },
  { id: '13', title: 'Fluid & Water Control', menuOrder: 19 },
  { id: '17', title: 'Furniture, Linens & Decor', menuOrder: 20 },
  { id: '20', title: 'Fluid Power', menuOrder: 21 },
  { id: '22', title: 'Standard Parts and Machine Elements', menuOrder: 22 },
  { id: '45', title: 'Electrical & Lighting', menuOrder: 23 },
  { id: '16', title: 'Materials & Maintenance', menuOrder: 24 }
];

// This is just a temporary list until we have a decent endpoint returning top category data

export const topCategoriesNames = {
  '01': 'Welding, Brazing & Soldering',
  26: 'Technical & Engineering & Services',
  '02': 'Laboratory Equipment',
  '05': 'Measuring & Test Equipment',
  25: 'Electronic Components',
  '04': 'Adhesives & Sealants',
  21: 'Computers & Electronics',
  24: 'Automotive',
  '06': 'Storage & Handling',
  '08': 'Cleaning & Hygiene',
  19: 'Display Material',
  20: 'Fluid Power',
  '09': 'Abrasives',
  17: 'Furniture, Linens & Decor',
  16: 'Materials & Maintenance',
  23: 'Fasteners',
  13: 'Fluid & Water Control',
  18: 'Catering Equipment & Supplies',
  49: 'Tools & Machining',
  46: 'Safety',
  47: 'Office Supplies & Packaging',
  48: 'Power Transmission',
  45: 'Electrical & Lighting',
  43: 'Connectors'
};

export const MISCELLANEOUS = 'miscellaneous';

export const getCategoryById = (id) => {
  const strId = id.toString();
  return _find(categories, (category) => {
    return category.id === strId;
  });
};

export default categories;
