import { fromJS } from 'immutable';
import {
  FETCH_QUOTATIONS_SUCCESS,
  FETCH_QUOTATION_DETAILS_SUCCESS,
  UPDATE_QUOTATION_PRODUCT_QTY_SUCCESS,
  FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS,
  REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS,
  DELETE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_ON_QTY_CHANGE,
  UPDATE_QUOTE_STATE,
  INIT_QUOTATIONS,
  INIT_QUOTATIONS_SUCCESS
} from 'client/actions/quotationsActions';
import _find from 'lodash/find';
import _inRange from 'lodash/inRange';
import { DEFAULT_PAGESIZE, DEFAULT_SORTBY, ALL_QUOTES, ALL_DAYS } from 'shared/constants/uiConstants';

const defaultState = fromJS({
  all: [],
  totalQuotationCount: 0,
  details: {},
  quotationState: { sortField: DEFAULT_SORTBY,
    limit: DEFAULT_PAGESIZE,
    pageNumber: 1,
    quoteType: ALL_QUOTES,
    dateRange: ALL_DAYS,
    searchInput: '',
    loading: false }
});

let products, productIndex;

const quotations = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_QUOTATIONS_SUCCESS:
      return state.set('all', fromJS(action.quotations || []));

    case FETCH_QUOTATION_DETAILS_SUCCESS:
      return state
        .setIn(['details', action.details._id], fromJS(action.details));

    case FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS:
      if (!action.isQuoteGlobal) {
        products = state.getIn(['details', action.quotationId, 'products']);
        productIndex = products ? products.findIndex((p) => p.get('sku') === action.sku) : -1;
        return productIndex === -1 ? state
          : state.setIn(['details', action.quotationId, 'products', productIndex, 'stock'], fromJS(action.stockInfo));
      }
      return state;
    case UPDATE_QUOTATION_PRODUCT_QTY_SUCCESS:
      products = state.getIn(['details', action.quotationId, 'products']);
      productIndex = products ? products.findIndex((p) => p.get('sku') === action.sku) : -1;

      const newBreakPrice = _find(action.breakPrices, (breakPrice) => {
        return _inRange(action.qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
      });

      const getNewState = (newBreakPrice) => {
        return newBreakPrice ? state
          .setIn(['details', action.quotationId, 'products', productIndex, 'qty'], fromJS(action.qty))
          .setIn(['details', action.quotationId, 'products', productIndex, 'lockedPrice', 'packPriceWithVat'], fromJS(newBreakPrice.breakPriceValue))
          .setIn(['details', action.quotationId, 'products', productIndex, 'lockedPrice', 'packPrice'], fromJS(newBreakPrice.packPriceValue))
          .setIn(['details', action.quotationId, 'products', productIndex, 'lockedPrice', 'qtyDiscount'], newBreakPrice.breakDiscount)
          : state
            .setIn(['details', action.quotationId, 'products', productIndex, 'qty'], fromJS(action.qty));
      };

      return productIndex === -1
        ? state
        : getNewState(newBreakPrice);

    case REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS:
      products = state.getIn(['details', action.quotationId, 'products']);
      productIndex = products ? products.findIndex((p) => p.get('sku') === action.sku) : -1;
      const updatedProducts = state.getIn(['details', action.quotationId, 'products']).delete(productIndex);
      return productIndex === -1 ? state
        : state.setIn(['details', action.quotationId, 'products'], updatedProducts);

    case DELETE_QUOTATION_SUCCESS:
      const quoteIndex = state.get('all') ? state.get('all').findIndex((q) => q.get('_id') === action.quotationId) : -1;
      const updatedQuotes = state.get('all').delete(quoteIndex);
      return state.set('all', updatedQuotes);
    case UPDATE_QUOTATION_ON_QTY_CHANGE:
      if (action.orderLines && action.orderLines.length) {
        return state
          .setIn(['details', action.quotationId, 'quotationPrice'], fromJS(action.quotationPrice))
          .setIn(['details', action.quotationId, 'orderLines'], fromJS(action.orderLines));
      }
      return state.setIn(['details', action.quotationId, 'quotationPrice'], fromJS(action.quotationPrice));
    case UPDATE_QUOTE_STATE:
      const stateObj = state.toJS();
      const { quotationState } = stateObj;
      const updated = { ...quotationState, ...action.payload };
      return fromJS({ ...stateObj, quotationState: updated });
    case INIT_QUOTATIONS:
      return state.setIn(['quotationState', 'loading'], true);
    case INIT_QUOTATIONS_SUCCESS:
      return state.set('all', fromJS(action.quotations.quotations || []))
        .setIn(['quotationState', 'loading'], false)
        .set('totalQuotationCount', action.quotations.totalQuotationCount);
    default:
      return state;
  }
};

export default quotations;
