import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideCustomAlertDialog } from 'client/actions/ui/dialogActions';

if (process.browser) {
  require('./CustomAlertDialog.scss');
}

function mapStateToProps (state) {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'customAlert', 'isVisible']),
    message: state.getIn(['ui', 'dialogs', 'customAlert', 'message'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideCustomAlertDialog: flowRight(dispatch, hideCustomAlertDialog)
  };
}

export class CustomAlertDialog extends React.Component {
  static propTypes = {
    hideCustomAlertDialog: PropTypes.func,
    isVisible: PropTypes.bool,
    message: PropTypes.string
  };

  render () {
    const { hideCustomAlertDialog, isVisible, message } = this.props;

    return (
      <Modal className="CustomAlertDialog" show={ isVisible } onHide={ hideCustomAlertDialog }>
        <Modal.Header className="CustomAlertDialog_header" closeButton>
          <div style={{ width: '100%' }} />
        </Modal.Header>
        <Modal.Body className="CustomAlertDialog_body">
          <div className="CustomAlertDialog_message">
            { message }
          </div>
          <div className="CustomAlertDialog_buttonsPanel">
            <PrimaryButton
              onClick={ hideCustomAlertDialog }
            >
                OK
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAlertDialog);
