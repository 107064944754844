import { fromJS } from 'immutable';

import {
  RESET_CHECKOUT_STATE,
  SET_LOAD_STATE_TYPE,
  SET_ACTIVE_PANEL_ID,
  SET_DELIVERY_PANEL_LOAD_STATE_TYPE,
  SHOW_DELIVERY_ADDRESS_FORM,
  SHOW_MY_ADDRESS_BOOK,
  REQUEST_CHANGE_DELIVERY_ADDRESS,
  CHANGE_ADDRESSBOOK_VIEWTYPE,
  SET_DELIVERY_ID_BEING_EDITED,
  PROCEED_TO_NEXT_STAGE,
  REQUEST_CHANGE_BILLING_ADDRESS,
  RESET_ALL_SCROLLS,
  SCROLL_TO_SECTION,
  PLACE_ORDER_FAILED,
  PLACE_ORDER_SUCCESS,
  RESET_PLACE_ORDER_ERROR,
  ADDRESS_SAVED_SUCCESS,
  REQUEST_SKIP_PAYMENT_FLOW
} from 'client/actions/checkoutActions';
import {
  UPDATE_HOSTED_FIELDS_STATUS,
  RECEIVE_PAYMENT_INFORMATION_SUCCESS
} from 'client/actions/braintreeActions';

import { PENDING } from 'shared/constants/loadStateType';

import {
  ADDRESSBOOK_GRID_VIEW
} from 'shared/constants/singlePageCheckout';

const defaultState = fromJS({
  loadState: {
    type: PENDING
  },
  activePanelId: '1',
  scrollToTop: false,
  deliveryPanel: {
    loadState: {
      type: PENDING
    },
    scrollToDeliveryPanel: false,
    showDeliveryAddressForm: false,
    isEditingDeliveryAddress: false,
    showCancelButtonOnAddressForm: false,
    showMyAddressBook: false,
    addressBookViewType: ADDRESSBOOK_GRID_VIEW
  },
  paymentPanel: {
    scrollToPaymentPanel: false,
    scrollToConfirmPaymentDetails: false,
    isBillingAddressSameAsDeliveryAddress: true,
    isEditingBillingAddress: false,
    isBillingAddressEditable: true
  },
  orderReviewPanel: {
    scrollToOrderReviewPanel: false
  }
});

const checkoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_CHECKOUT_STATE:
      return defaultState;
    case SET_LOAD_STATE_TYPE:
      return state.setIn(['loadState', 'type'], action.payload);
    case SET_DELIVERY_PANEL_LOAD_STATE_TYPE:
      return state.setIn(['deliveryPanel', 'loadState', 'type'], action.payload);
    case SET_ACTIVE_PANEL_ID:
      return state.set('activePanelId', action.payload.panelId);
    case PROCEED_TO_NEXT_STAGE:
      const nextActivePanelId = `${parseInt(action.payload) + 1}`;
      return state.set('activePanelId', nextActivePanelId);
    case SHOW_DELIVERY_ADDRESS_FORM:
      return state.setIn(['deliveryPanel', 'showDeliveryAddressForm'], action.payload.showDeliveryAddressForm)
        .setIn(['deliveryPanel', 'isEditingDeliveryAddress'], action.payload.isEditingDeliveryAddress)
        .setIn(['deliveryPanel', 'showCancelButtonOnAddressForm'], action.payload.showCancelButtonOnAddressForm)
        .setIn(['deliveryPanel', 'deliveryIdBeingEdited'], action.payload.deliveryIdBeingEdited);
    case SHOW_MY_ADDRESS_BOOK:
      return state.setIn(['deliveryPanel', 'showMyAddressBook'], action.payload)
        .setIn(['deliveryPanel', 'addressBookViewType'], ADDRESSBOOK_GRID_VIEW);
    case CHANGE_ADDRESSBOOK_VIEWTYPE:
      return state.setIn(['deliveryPanel', 'addressBookViewType'], action.payload);
    case REQUEST_CHANGE_DELIVERY_ADDRESS:
      return state.setIn(['deliveryPanel', 'selectedAddressIdForDelivery'], action.payload);
    case SET_DELIVERY_ID_BEING_EDITED:
      return state.setIn(['deliveryPanel', 'deliveryIdBeingEdited'], action.payload);
    case REQUEST_CHANGE_BILLING_ADDRESS:
      return state
        .setIn(['paymentPanel', 'isEditingBillingAddress'], action.payload.isEditingBillingAddress)
        .setIn(['paymentPanel', 'isBillingAddressSameAsDeliveryAddress'], action.payload.isBillingAddressSameAsDeliveryAddress)
        .setIn(['paymentPanel', 'cardBillingAddress'], action.payload.cardBillingAddress);
    case REQUEST_SKIP_PAYMENT_FLOW:
      return state.set('isPaymentFlowSkipped', action.payload.isPaymentFlowSkipped);
    case RESET_ALL_SCROLLS:
      return state
        .setIn(['deliveryPanel', 'scrollToDeliveryPanel'], false)
        .setIn(['paymentPanel', 'scrollToPaymentPanel'], false)
        .setIn(['orderReviewPanel', 'scrollToOrderReviewPanel'], false)
        .setIn(['paymentPanel', 'scrollToConfirmPaymentDetails'], false)
        .setIn('scrollToTop', false);
    case SCROLL_TO_SECTION:
      const panelIdNameMap = {
        1: { name: 'deliveryPanel', reference: 'scrollToDeliveryPanel' },
        2: { name: 'paymentPanel', reference: 'scrollToPaymentPanel' },
        3: { name: 'orderReviewPanel', reference: 'scrollToOrderReviewPanel' },
        4: { name: 'paymentPanel', reference: 'scrollToConfirmPaymentDetails' }
      };
      return state
        .setIn([panelIdNameMap[action.payload].name, panelIdNameMap[action.payload].reference], true);
    case PLACE_ORDER_FAILED:
      const message = action.payload.message;
      return state.set('placeOrderError', message);
    case ADDRESS_SAVED_SUCCESS:
      return state.set('placeOrderError', null);
    case PLACE_ORDER_SUCCESS:
    case RECEIVE_PAYMENT_INFORMATION_SUCCESS:
    case UPDATE_HOSTED_FIELDS_STATUS:
    case RESET_PLACE_ORDER_ERROR:
      return state.set('placeOrderError', null);
    default:
      return state;
  }
};

export default checkoutReducer;
