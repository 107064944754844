import { createAction } from 'redux-actions';

export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const saveAddress = createAction(SAVE_ADDRESS);

export const SAVE_ADDRESS_ERROR = 'SAVE_ADDRESS_ERROR';
export const saveAddressError = createAction(SAVE_ADDRESS_ERROR);

export const ENTER_BILLING_ADDRESS = 'ENTER_BILLING_ADDRESS';
export const enterBillingAddress = createAction(ENTER_BILLING_ADDRESS);

export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
export const clearAddress = createAction(CLEAR_ADDRESS);

export const ENTER_DELIVERY_ADDRESS = 'ENTER_DELIVERY_ADDRESS';
export const enterDeliveryAddress = createAction(ENTER_DELIVERY_ADDRESS);

export const VERIFY_ADDRESSES = 'VERIFY_ADDRESSES';
export const verifyAddresses = createAction(VERIFY_ADDRESSES);

export const VERIFY_ADDRESS_COMPLETED = 'VERIFY_ADDRESS_COMPLETED';
export const verifyAddressCompleted = createAction(VERIFY_ADDRESS_COMPLETED);

export const SET_PO_REFERENCE = 'SET_PO_REFERENCE';
export const setPoReference = createAction(SET_PO_REFERENCE);

export const GET_USER_ADDRESSES = 'GET_USER_ADDRESSES';
export const getUserAddresses = createAction(GET_USER_ADDRESSES);

export const UPDATE_DEFAULT_DELIVERY_ID = 'UPDATE_DEFAULT_DELIVERY_ID';
export const updateDefaultDeliveryId = createAction(UPDATE_DEFAULT_DELIVERY_ID);

export const GET_USER_ADDRESSES_SUCCESS = 'GET_USER_ADDRESSES_SUCCESS';
export const getUserAddressesSuccess = createAction(GET_USER_ADDRESSES_SUCCESS);

export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const setDefaultDeliveryAddress = createAction(SET_DEFAULT_ADDRESS);

export const SET_ADDRESS = 'SET_ADDRESS';
export const setAddress = createAction(SET_ADDRESS);

export const SEND_TO_THIS_ADDRESS = 'SEND_TO_THIS_ADDRESS';
export const sendToThisAddress = createAction(SEND_TO_THIS_ADDRESS);

export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const editAddress = createAction(EDIT_ADDRESS);

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const deleteAddress = createAction(DELETE_ADDRESS);

export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export const getUserAddress = createAction(GET_USER_ADDRESS);

export const CHANGE_ADDRESS_FROM_ADDRESSBOOK = 'CHANGE_ADDRESS_FROM_ADDRESSBOOK';
export const changeAddressFromAddressBook = createAction(CHANGE_ADDRESS_FROM_ADDRESSBOOK);

export const ADDRESS_FORM_DEFAULT_DELIVERY_TOGGLE = 'ADDRESS_FORM_DEFAULT_DELIVERY_TOGGLE';
export const addressFormDefaultDeliveryToggle = createAction(ADDRESS_FORM_DEFAULT_DELIVERY_TOGGLE);

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const updateAddress = createAction(UPDATE_ADDRESS);

export const ON_NEW_ADDRESS_CLICK = 'ON_NEW_ADDRESS_CLICK';
export const onAddressActionClick = createAction(ON_NEW_ADDRESS_CLICK);

export const REQUEST_REMOVE_ADDRESS_CONFIRM = 'addressActions.REQUEST_REMOVE_ADDRESS_CONFIRM';
export const requestRemoveAddressConfirm = createAction(REQUEST_REMOVE_ADDRESS_CONFIRM);
