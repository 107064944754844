import { fromJS } from 'immutable';
import { ON_SCREEN_READY } from '../actions/routeActions';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';

const defaultState = fromJS({
  visibleDropdown: VISIBLE_DROPDOWN.NONE
});

export default (state = defaultState, { type, visibleDropdown }) => {
  if (type === 'TOGGLE_DROPDOWN_MENU') {
    return visibleDropdown === state.get('visibleDropdown', VISIBLE_DROPDOWN.NONE)
      ? state.merge(fromJS({ visibleDropdown: VISIBLE_DROPDOWN.NONE }))
      : state.merge(fromJS({ visibleDropdown }));
  } else if (type === ON_SCREEN_READY) {
    return defaultState;
  }
  return state;
};
