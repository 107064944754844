import React from 'react';
import { Map } from 'immutable';

import HTMLComponent from '../htmlComponent/HTMLComponent';

if (process.browser) {
  require('./CmsTextPanel.scss');
}
class CmsTextPanel extends React.Component {
  renderHtmlOrPara (html, htmlCss, paragraph, paragraphCss) {
    if (html) {
      return <HTMLComponent html={ html } htmlCss={ htmlCss }/>;
    } else if (paragraph) {
      return <p className="ContentPageText" style={ paragraphCss }>{ paragraph }</p>;
    }
    return null;
  }

  render () {
    const data = this.props.data;
    const heading = data.get('heading');
    const headingCss = data.get('headingCss', Map()).toJS();

    const html = data.get('html', null);
    const htmlCss = data.get('htmlCss', Map()).toJS();
    const paragraph = data.get('paragraph', null);
    const paragraphCss = data.get('paragraphCss', Map()).toJS();

    return (
      <div className="CmsTextPanel">
        { heading ? <h1 className="CmsTextPanel__heading" style={ headingCss }>{ heading }</h1> : null}
        { this.renderHtmlOrPara(html, htmlCss, paragraph, paragraphCss) }
      </div>
    );
  }
}

export default CmsTextPanel;
