export const PAY_BY_ACCOUNT = 'PAY_BY_ACCOUNT';
export const PAY_BY_NEW_CARD = 'PAY_BY_NEW_CARD';
export const PAY_BY_EXISTING_CARD = 'PAY_BY_EXISTING_CARD';
export const PAY_BY_PAYPAL = 'PAY_BY_PAYPAL';
export const PAY_BY_APPLE_PAY = 'PAY_BY_APPLE_PAY';
export const PAY_BY_GOOGLE_PAY = 'PAY_BY_GOOGLE_PAY';

export const PAY_BY_CARD_ICON_LINK = 'pay-by-card';
export const PAY_BY_ACCOUNT_ICON_LINK = 'pay-by-account';
export const PAY_BY_PAYPAL_ICON_LINK = 'pay-by-paypal';
export const PAY_BY_APPLE_PAY_ICON_LINK = 'pay-by-apple-pay-icon';
export const PAY_BY_GOOGLE_PAY_ICON_LINK = 'pay-by-google-pay-icon';

export const PAY_BY_CARD_TEXT_DISPLAY = 'Credit/Debit Card';
export const PAY_BY_ACCOUNT_TEXT_DISPLAY = 'Pay on Account';
export const PAY_BY_PAYPAL_TEXT_DISPLAY = 'Paypal';
export const PAY_BY_APPLE_PAY_TEXT_DISPLAY = 'Apple Pay';
export const PAY_BY_GOOGLE_PAY_TEXT_DISPLAY = 'Google Pay';

export const MASK_CALL_DIALOG_VARIANTS = { MASK: 'mask', UNMASK: 'unmask' };

export const DEFAULT_PAYMENT_METHOD = 'DEFAULT_PAYMENT_METHOD';

export const PAYMENT_OPTIONS_WRAPPER = [
  {
    text: PAY_BY_ACCOUNT_TEXT_DISPLAY,
    paymentType: PAY_BY_ACCOUNT
  },
  {
    text: PAY_BY_CARD_TEXT_DISPLAY,
    paymentType: PAY_BY_EXISTING_CARD
  },
  {
    text: PAY_BY_PAYPAL_TEXT_DISPLAY,
    paymentType: PAY_BY_PAYPAL
  },
  {
    text: PAY_BY_GOOGLE_PAY_TEXT_DISPLAY,
    paymentType: PAY_BY_GOOGLE_PAY
  },
  {
    text: PAY_BY_APPLE_PAY_TEXT_DISPLAY,
    paymentType: PAY_BY_APPLE_PAY
  }
];
export const PENDING_UNVERIFIED_MESSAGE = ['Account unverified', 'Account under review'];

export const BOTTOM_BOX_MESSAGE = 'BottomBoxMessage';
export const TOP_BOX_MESSAGE = 'TopBoxMessage';
export const MODAL = 'modal';
