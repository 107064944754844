import PropTypes from 'prop-types';
import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';

export const INITIALIZE_COMPONENT_STATE = 'INITIALIZE_COMPONENT_STATE';
export const REMOVE_COMPONENT_STATE = 'REMOVE_COMPONENT_STATE';

const initialiseComponentState = (selector) => {
  return {
    type: INITIALIZE_COMPONENT_STATE,
    selector: selector
  };
};

const removeComponentState = (selector) => {
  return {
    type: REMOVE_COMPONENT_STATE,
    selector: selector
  };
};

class LocalStateComponent extends Component {
  componentWillMount () { // eslint-disable-line react/no-deprecated
    this.props.startSelector(this.props.selector);
  }

  componentWillUnmount () {
    this.props.removeSelector(this.props.selector);
  }

  render () {
    const { component, componentProps } = this.props;
    return createElement(component, componentProps);
  }
}

LocalStateComponent.propTypes = {
  startSelector: PropTypes.func.isRequired,
  removeSelector: PropTypes.func.isRequired,
  selector: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.object.isRequired
};

function mapStateToProps (state) {
  return {};
}

function mapDispatchToProps (dispatch) {
  return {
    startSelector: (selector) => dispatch(initialiseComponentState(selector)),
    removeSelector: (selector) => dispatch(removeComponentState(selector))
  };
}

const ConnectedLocalStateComponent = connect(mapStateToProps, mapDispatchToProps)(LocalStateComponent);

const localState = (component) => {
  const localStateWrapper = ({ selector, ...rest }) => {
    return (<ConnectedLocalStateComponent
      selector={ selector }
      component={ component }
      componentProps={{ ...rest, selector }}
    />);
  };

  localStateWrapper.propTypes = {
    selector: PropTypes.string.isRequired
  };
  return localStateWrapper;
};

export default localState;
