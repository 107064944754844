import React, { useEffect } from 'react';
import { setDefaultCard, setSelectedCard, initialize } from './payByExistingCardActions';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import { PaymentAddress } from '../shared/PaymentAddress';
import SavedCardsList from './savedCardsList';

if (process.browser) {
  require('./payByExistingCard.scss');
}

export const PayByExistingCard = ({
  selector,
  paymentMethodsComponent,
  reviewOrderButton,
  selectedCard,
  existingCards,
  setSelectedCard,
  setDefaultCard,
  initialize,
  subscriptionPayment,
  paymentType }) => {
  useEffect(() => {
    initialize({
      selector,
      subscriptionPayment
    });
  }, []);

  return <div className="PayByExistingCard">
    <div className="col-md-6 PayByExistingCard_payment_options_container">
      <SavedCardsList
        selector={ selector }
        paymentType={ paymentType }
        styles="PayByExistingCard_savedCardsListContainer"
      />

      <div className="PayByExistingCard_payment_methods_container" data-e2e="PayByExistingCard_payment_methods">
        {paymentMethodsComponent}
      </div>
    </div>

    <PaymentAddress
      selector={ selector }
      address={ selectedCard.billingAddress }
      title={ getLocalizedString('payment.label.billing.details') }
      button={ reviewOrderButton }
      data-e2e="PayByExistingCard_billing_address"/>
  </div>;
};

function mapStateToProps (state, ownProps) {
  const braintree = state.getIn(['braintree']).toJS();
  return {
    existingCards: braintree.cards ? braintree.cards : [],
    selectedCard: state.getIn(['ui', 'payByExistingCard', ownProps.selector, 'selectedCard'], Map()).toJS()
  };
}

const mapDispatchToProps = {
  initialize,
  setSelectedCard,
  setDefaultCard
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByExistingCard);
