import React from 'react';
import { formatPrice } from 'client/utils/priceUtils';
import { getLocalizedString } from 'localization/localizer';
import _get from 'lodash/get';

if (process.browser) {
  require('./BasketItemsDetails.scss');
}
const CART_TOTALIZER = 'cart';
const GBP_CURRENCY = getLocalizedString('currencySymbol');

const BasketItemsDetails = ({ items, currency = GBP_CURRENCY, includeVat, totalizer = CART_TOTALIZER }) => {
  return (items && items.length ? (<div className="BasketItemsDetails">
    {
      items.map((item) => {
        const { name, sku, quantity, qty } = item;
        const orderLinePriceWithVatFormatted = formatPrice(currency, _get(item, 'orderLinePrice.orderLinePriceWithVat'));
        const orderLinePriceWithoutVatFormatted = formatPrice(currency, _get(item, 'orderLinePrice.orderLinePriceWithoutVat'));
        const orderLinePriceWithVat = `${orderLinePriceWithVatFormatted.symbol}${orderLinePriceWithVatFormatted.amount}`;
        const orderLinePriceWithoutVat = `${orderLinePriceWithoutVatFormatted.symbol}${orderLinePriceWithoutVatFormatted.amount}`;

        return (<div key={ sku } className="BasketItemsDetails_itemRow">
          <div className="BasketItemsDetails_itemRow-name" title={ name }>{ name }</div>
          <div className="BasketItemsDetails_itemRow-price">{ includeVat ? orderLinePriceWithVat : orderLinePriceWithoutVat }</div>
          <div className="BasketItemsDetails_itemRow-qty">{ getLocalizedString('basket.label.unitQty') } { totalizer !== CART_TOTALIZER ? qty : quantity }</div>
        </div>);
      })
    }
  </div>) : null);
};

export default BasketItemsDetails;
