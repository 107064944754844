import { COUNTRY_CODE_UK } from 'shared/constants/braintree';

export function preparePaypalAddress (deliveryAddress) {
  return {
    recipientName: `${deliveryAddress.firstName} ${deliveryAddress.lastName}`,
    line1: deliveryAddress.addressLine1,
    line2: deliveryAddress.addressLine2 || deliveryAddress.addressLine1,
    city: deliveryAddress.city,
    state: deliveryAddress.state || deliveryAddress.city,
    postalCode: deliveryAddress.postalCode,
    phone: deliveryAddress.addressPhoneNumber,
    countryCode: COUNTRY_CODE_UK
  };
}
