import React from 'react';
import { Address } from './Address';
import { getLocalizedString } from 'localization/localizer';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
if (process.browser) {
  require('./paymentAddress.scss');
}

export const PayPalEmail = ({ email }) => {
  return (
    <div className="PaymentAddress_paypal">
      <div className="PaymentAddress_paypal_title">{getLocalizedString('payment.label.paypal.account')}</div>
      <div className="PaymentAddress_paypal_email">{email}</div>
    </div>
  );
};

export const PaymentAddress = ({
  selector,
  title,
  address,
  button,
  paypalEmail
}) => {
  const isCheckout = selector === CHECKOUT_SELECTOR;
  return (
    <div className="col-md-4 PaymentAddress_address">
      <div>
        <div className="PaymentAddress_address_title">{title}</div>
        {
          paypalEmail ? <PayPalEmail email={ paypalEmail } /> : null
        }
        <Address address={ address } />
      </div>
      {isCheckout ? <div className="PaymentAddress_button">
        {button}
      </div> : null}
    </div>
  );
};

export const mapGooglePayAddressToZoro = (billingAddress) => {
  return {
    firstName: billingAddress.name,
    addressLine1: billingAddress.address1,
    addressLine2: billingAddress.address2,
    city: billingAddress.locality,
    postalCode: billingAddress.postalCode
  };
};
