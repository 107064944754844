import { orderSelectors } from 'client/components/elements/paymentMethod/shared/selectors';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { PAY_BY_SKIPPING_METHOD } from 'client/components/elements/paymentMethod/PayBySkippingMethod/actions';
import { gotoExternalUrl } from 'client/actions/routeActions';
import { createOrder, orderFailed } from 'client/components/elements/paymentMethod/shared/orderHelpers';
import { postOrder } from 'shared/endpoints/ordersEndpoint';
import { getLocalizedString } from 'localization/localizer';
import isEmpty from 'lodash/isEmpty';

export function hasRequiredFields ({ addressLine1, city, postalCode, firstName, lastName }) {
  return !!addressLine1 && !!city && !!postalCode && !!firstName && !!lastName;
}

export function * getBillingAddress () {
  const accountBillingAddress = yield select(orderSelectors.tradeAccountAddress);

  if (!isEmpty(accountBillingAddress) && hasRequiredFields(accountBillingAddress)) {
    return accountBillingAddress;
  }

  const userBillingAddress = yield select(orderSelectors.billingAddress);

  if (!isEmpty(userBillingAddress) && hasRequiredFields(userBillingAddress)) {
    return userBillingAddress;
  }

  const shippingAddress = yield select(orderSelectors.shippingAddress);
  return shippingAddress;
}

export function * payBySkippingMethod ({ payload }) {
  try {
    const orderAddress = yield * getBillingAddress();
    const order = yield call(createOrder, undefined, orderAddress);

    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrder, order);
    yield put(gotoExternalUrl(postOrderResponse.nexturl));
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

function * watchPayBySkippingMethod () {
  yield takeLatest(PAY_BY_SKIPPING_METHOD, payBySkippingMethod);
}

export function * watchAllPayBySkippingPaymentMethod () {
  yield all([
    fork(watchPayBySkippingMethod)
  ]);
}
