import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import React from 'react';
import { getToolTipContent } from 'client/components/elements/braintree/braintreeTooltip';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./infoIcon.scss');
}

export const overlayInnerStyle = (e) => {
  const toolTipInner = e.children[1];
  toolTipInner.style.fontWeight = 'normal';
  toolTipInner.style.fontFamily = 'Roboto, sans-serif';
  toolTipInner.style.opacity = '1';
};

export const InfoDefaultCards = (
  <OverlayTrigger placement="bottom" onEntering={ overlayInnerStyle } overlay={ getToolTipContent(['singlePageCheckout.tooltip.defaultCardInformation']) }>
    <SvgLoader xlinkHref="#information-icon" className="InfoIcon InfoIcon_existingCard" />
  </OverlayTrigger>
);

export const InfoCvv = (
  <OverlayTrigger placement="right" onEntering={ overlayInnerStyle } overlay={ getToolTipContent(['singlePageCheckout.cvv.info.line1', 'singlePageCheckout.cvv.info.line2']) }>
    <SvgLoader xlinkHref="#information-icon" className="InfoIcon InfoIcon_cvv" />
  </OverlayTrigger>
);

export const InfoSaveCard = (
  <OverlayTrigger placement="right" onEntering={ overlayInnerStyle } overlay={ getToolTipContent(['singlePageCheckout.tooltip.future.payments']) }>
    <SvgLoader xlinkHref="#information-icon" className="InfoIcon InfoIcon_saveCard" />
  </OverlayTrigger>
);
