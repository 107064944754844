import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import MobileMenuHeader from './MobileMenuHeader';
import SvgLoader from 'client/components/svg/SvgLoader';

const MobileToggle = () => {
  const [isActive, setActive] = useState(false);
  return (
    <div>
      <OverlayTrigger trigger="click" placement="bottom" overlay={ <MobileMenuHeader /> }>
        <div onClick={ () => setActive(!isActive) } className="HeaderB_MobileToggle">
          {
            isActive
              ? <SvgLoader xlinkHref="#close-icon"/>
              : <SvgLoader xlinkHref="#vertical-dots"/>
          }
        </div>
      </OverlayTrigger>
    </div>
  );
};
export default MobileToggle;
