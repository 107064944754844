import { createAction } from 'redux-actions';

export const REQUEST_CREDIT_LIMIT_INCREASE = 'requestCreditLimit.REQUEST_CREDIT_LIMIT_INCREASE';
export const requestCreditLimitIncrease = createAction(REQUEST_CREDIT_LIMIT_INCREASE);

export const REQUEST_CREDIT_LIMIT_INCREASE_SUCCESS = 'requestCreditLimit.REQUEST_CREDIT_LIMIT_INCREASE_SUCCESS';
export const requestCreditLimitIncreaseSuccess = createAction(REQUEST_CREDIT_LIMIT_INCREASE_SUCCESS);

export const REQUEST_CREDIT_LIMIT_INCREASE_FAILURE = 'requestCreditLimit.REQUEST_CREDIT_LIMIT_INCREASE_FAILURE';
export const requestCreditLimitIncreaseFailure = createAction(REQUEST_CREDIT_LIMIT_INCREASE_FAILURE);
