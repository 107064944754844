
import _compact from 'lodash/compact';
import _values from 'lodash/values';

export function validateForm (values, validate) {
  return new Promise((resolve, reject) => {
    const errors = validate(values);
    _compact(_values(errors)).length ? reject(errors) : resolve();
  });
}
