import PropTypes from 'prop-types';
import React from 'react';
import AnimateOnChange from 'react-animate-on-change';

import { getLocalizedStringWithParam } from 'localization/localizer';
import {
  getPriceInfo,
  formatPrice
} from 'client/utils/priceUtils';

import PromoLabel from 'client/components/elements/promoLabel/PromoLabel';
import SaleTag from '../promoLabel/SaleTag';
import EachMinOrderPrice from 'client/components/elements/productVariantPrice/eachMinOrderPrice';

if (process.browser) {
  require('./ProductVariantPrice.scss');
}

class ProductVariantPrice extends React.Component {
  constructor (props) {
    super(props);
    this.animate = false;
  }

  componentWillReceiveProps (nextProps) { // eslint-disable-line react/no-deprecated
    this.animate = (nextProps.minPrice !== this.props.minPrice) || (nextProps.sku === this.props.sku && getPriceInfo(nextProps.price).priceWithVat !== getPriceInfo(this.props.price).priceWithVat);
  }

  getPriceWithMultiplesFormatted (minPrice, priceWithVat, qtyMultiplier = 1, currency) {
    const priceWithOrderMultiple = minPrice
      ? (minPrice * qtyMultiplier).toFixed(2)
      : (priceWithVat * qtyMultiplier).toFixed(2);
    return formatPrice(currency, priceWithOrderMultiple, true);
  }

  getPriceWithMinOrderQtyFormatted (price, minOrderQuantity = 1, currency) {
    const priceWithMinOrderQty = (price * minOrderQuantity).toFixed(2);
    return formatPrice(currency, priceWithMinOrderQty, true);
  }

  render () {
    const {
      price,
      pricePrefix,
      minPrice,
      isPriceWithVat = true,
      packPriceWithoutVat,
      qtyMultiplier = 1,
      minOrderQuantity = 1
    } = this.props;

    if (!price) {
      return <div className="ProductVariantPrice" />;
    }
    const vat = price.get('vat');
    const currency = price.get('currency');
    const priceInfo = getPriceInfo(price);
    const { priceWithVat, priceWithoutVat } = priceInfo;
    const minPriceFormatted = isPriceWithVat ? formatPrice(currency, minPrice, true) : formatPrice(currency, packPriceWithoutVat, true);
    const priceWithCurrency = isPriceWithVat ? formatPrice(currency, priceWithVat, true) : formatPrice(currency, priceWithoutVat, true);
    const priceVatTagLabel = isPriceWithVat ? getLocalizedStringWithParam('product.incVat', { vat })
      : getLocalizedStringWithParam('product.excVat', { vat });
    const oldPrice = isPriceWithVat ? price.get('packWasPriceWithVat') : price.get('packWasPriceWithoutVat');

    const priceWithOrderMultipleFormatted = isPriceWithVat
      ? this.getPriceWithMultiplesFormatted(minPrice, priceWithVat, qtyMultiplier, currency)
      : this.getPriceWithMultiplesFormatted(packPriceWithoutVat, priceWithoutVat, qtyMultiplier, currency);
    const priceWithMinOrderQty = isPriceWithVat
      ? this.getPriceWithMinOrderQtyFormatted(priceWithVat, minOrderQuantity, currency)
      : this.getPriceWithMinOrderQtyFormatted(priceWithoutVat, minOrderQuantity, currency);
    const symbol = minPrice ? minPriceFormatted.symbol : priceWithCurrency.symbol;

    const isQtyMultiplier = qtyMultiplier > 1;
    const isMinOrderQtyGreaterThanOne = minOrderQuantity > 1;
    const isMinOrderQtyGreaterThanMultiplier = minOrderQuantity > qtyMultiplier;

    return (
      <div className="variantA">
        <div className="ProductVariantPrice">
          { isQtyMultiplier ? <strong className="ProductVariantPrice_qtyOf">
            { getLocalizedStringWithParam('product.qtyOf', { qty: qtyMultiplier }) }
          </strong> : null }
          <p className="ProductVariantPrice_MainPrice" data-e2e="mainPrice">
            {
              pricePrefix
                ? <span className="ProductPricePrefix">{pricePrefix}</span>
                : ''
            }
            <strong>
              <AnimateOnChange
                baseClassName="ProductVariantPrice_OnlyPrice"
                animationClassName="ProductVariantPrice_OnlyPrice-animate"
                animate={ this.animate }
              >
                <>
                  {
                    isQtyMultiplier
                      ? `${symbol}${priceWithOrderMultipleFormatted.amount}`
                      : minPrice
                        ? `${minPriceFormatted.symbol}${minPriceFormatted.amount}`
                        : `${priceWithCurrency.symbol}${priceWithCurrency.amount}`
                  }
                </>
              </AnimateOnChange>
            </strong>
            <span className="ProductVariantPrice_MainPrice-priceVatSpan">
              { priceVatTagLabel }
            </span>
          </p>
          <EachMinOrderPrice
            isQtyMultiplier={ isQtyMultiplier }
            isMinOrderQtyGreaterThanOne={ isMinOrderQtyGreaterThanOne }
            minPrice={ minPrice }
            minPriceFormatted={ minPriceFormatted }
            priceWithCurrency={ priceWithCurrency }
            priceWithMinOrderQty={ priceWithMinOrderQty }
            isMinOrderQtyGreaterThanMultiplier={ isMinOrderQtyGreaterThanMultiplier }
          />
          {
            price && price.get('appliedDiscounts').size
              ? price.get('isFamilyPrice')
                ? <SaleTag label={ price.get('promoLabel') }
                  promoUrl={ price.get('promoUrl') }
                />
                : <PromoLabel
                  oldPrice={ oldPrice }
                  currency={ currency }
                  discount={ price.get('promoDiscount') }
                  labels={ price.get('appliedDiscounts') }
                  promoUrl={ price.get('promoUrl') }
                  finalDiscount={ price.get('finalPromoDiscount') }
                />
              : null
          }
        </div>
      </div>
    );
  }
}

ProductVariantPrice.propTypes = {
  price: PropTypes.object,
  sale: PropTypes.object
};

export default ProductVariantPrice;
