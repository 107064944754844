export function buildErrorMessageFromError (err) {
  if (err.apiResponse) {
    const extractedError = extractApiErrorMessage(err.apiResponse);
    const errorMessages = extractedError.startsWith('[') ? JSON.parse(extractedError) : extractedError;
    const errorMessage = errorMessages.join ? errorMessages.join(', ') : errorMessages;
    return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
  }

  return err.message;
}

function extractApiErrorMessage (res) {
  if (res.body && res.body.message) {
    return res.body.message;
  }
}
