import Immutable from 'immutable';

/**
 * An immutable version of redux.combineReducers
 * @param  {object} reducerMap - An object with property names for keys and reducers for values
 * @return {function} A method with a reducer signature that will run all the reducers contained in
 * the reducerMap for each execution.
 */
export default function combineReducers (reducerMap) {
  return (state = new Immutable.Map(), action) => {
    let nextState = state;

    // Run reducers for top level keys
    nextState = Object.keys(reducerMap).reduce((previousState, key) => {
      const reducer = reducerMap[key];
      return previousState.set(key, reducer(previousState.get(key), action));
    }, nextState);

    return nextState;
  };
}
