import { SET_VERIFY_EMAIL } from '../../actions/verifyEmailActions';

const verifyEmailState = (state = 'pending', action) => {
  switch (action.type) {
    case SET_VERIFY_EMAIL:
      return action.status;
    default:
      return state;
  }
};

export default verifyEmailState;
