import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { makeTextClickable } from 'client/utils/stockMessageUtil';
import ScheduleOrdersForm from 'client/components/elements/scheduledOrders/ScheduleOrdersForm';
import { ScheduleOrderFormStatus, WEEKLY, submittedSubscriptionRegularityMap } from 'client/components/elements/scheduledOrders/constants';
import {
  removeCartScheduledOrdersSettings,
  saveScheduledOrderCartFormSettings,
  scheduleSubscriptionsAllOrderLines,
  setCartLevelFormStatus
} from 'client/actions/scheduledOrders';
import { connect } from 'react-redux';
import { getDeliveryDateNewSubscription } from 'shared/utils/subscriptionUtils';
import { List, Map } from 'immutable';
import { SEND_PARTIAL_ORDER } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import dayjs from 'dayjs';
import { parseConfirmedOrderIdFromUrl } from 'client/components/elements/scheduledOrders/utils';

if (process.browser) {
  require('./ScheduledOrders.scss');
}

const { VIEW, FORM, SUBMITTED, HIDDEN, SUBSCRIPTION_CREATED } = ScheduleOrderFormStatus;

export const ScheduledOrdersCart = (props) => {
  const {
    formValues,
    saveScheduledOrderSettings,
    cartLevelFormStatus,
    setCartLevelFormStatus,
    removeCartScheduledOrdersSettings,
    earliestDeliveryDate,
    isGoldOrAboveLoyaltyDiscount,
    defaultFormValues,
    scheduleSubscriptions,
    isConfirmationScreenScheduledOrders,
    isLoadingCreateSubscriptionButton
  } = props;

  const isFormValues = !!formValues.regularity;

  const submitFormSettings = (settings) => {
    saveScheduledOrderSettings(settings);
    setCartLevelFormStatus(SUBMITTED);
  };

  const submitSubscription = (settings) => {
    scheduleSubscriptions(settings);
    setCartLevelFormStatus(SUBSCRIPTION_CREATED);
  };

  const handleViewSubscriptionForm = () => {
    const newFormState = cartLevelFormStatus === VIEW ? FORM : VIEW;
    if (newFormState === VIEW && formValues?.regularity && !formValues._id) {
      removeCartScheduledOrdersSettings();
    } else {
      saveScheduledOrderSettings({
        startDate: dayjs(defaultFormValues.startDate || earliestDeliveryDate).startOf('day').toDate(),
        endDate: defaultFormValues.endDate || null,
        regularity: defaultFormValues.regularity || WEEKLY,
        outOfStockDeliveryPreference: SEND_PARTIAL_ORDER,
        ...formValues
      });
    }
    setCartLevelFormStatus(newFormState);
  };

  if (cartLevelFormStatus === HIDDEN) {
    return null;
  }

  const isFormActive = isFormValues && [FORM, SUBMITTED, SUBSCRIPTION_CREATED].includes(cartLevelFormStatus);

  return <div className={ `scheduledOrders ${isFormActive ? 'border-active' : 'border-inactive'}` }>
    {
      formValues._id
        ? <span className="submittedScheduleOrder" data-e2e="submittedScheduleOrder-cart">
          <p>
            <SvgLoader xlinkHref="#schedule" />
            {getLocalizedStringWithParam('scheduledOrders.subscription.created.all.orderlines', {
              regularity: submittedSubscriptionRegularityMap[formValues?.regularity],
              endDate: formValues?.endDate ? dayjs(formValues?.endDate).format('DD/MM/YYYY') : 'further notice'
            })}
          </p>
          <p>{makeTextClickable('Edit ', /Edit/, `/my-account/repeat-orders/${formValues._id || ''}`)}</p>
        </span>
        : <><section className="showScheduleOrderForm" data-e2e="showScheduleOrderForm-cart">
          <div>
            <input id="show-subscription-form"
              name="show-subscription-form"
              checked={ isFormActive }
              type="checkbox"
              onChange={ handleViewSubscriptionForm }
            />
            <label htmlFor="show-subscription-form">{getLocalizedString('scheduledOrders.info.info.save.order')}
              <OverlayTrigger
                overlay={ <Tooltip id="subscription-tooltip" className="createSubscriptionTooltip">
                  <p className="title">{getLocalizedString('scheduledOrders.goto.scheduledOrders.info.title')}</p>
                  <p className="content">{makeTextClickable(getLocalizedString('scheduledOrders.goto.scheduledOrders.info.content'), /click here/, '/info/subscriptions', '_blank')}</p>
                </Tooltip> }
                placement="right"
                delayHide={ 1500 }
              >
                <SvgLoader xlinkHref="#information-icon" className="overlayIcon" />
              </OverlayTrigger>
            </label>
          </div>
        </section>
        {
          isFormActive
            ? <ScheduleOrdersForm
              saveFormValues={ submitFormSettings }
              earliestDeliveryDate={ earliestDeliveryDate }
              formValues={ formValues }
              isGoldOrAboveLoyaltyDiscount={ isGoldOrAboveLoyaltyDiscount }
              submitSubscription={ submitSubscription }
              isConfirmationScreenScheduledOrder={ isConfirmationScreenScheduledOrders }
              isLoadingCreateSubscriptionButton={ isLoadingCreateSubscriptionButton }
            />
            : null
        }
        </>
    }
  </div>;
};

const mapStateToProps = (state) => {
  const orderId = parseConfirmedOrderIdFromUrl();
  const orderLines = orderId ? state.getIn(['orders', 'order', orderId, 'orderLines']) : List();
  const formValues = state.hasIn(['scheduledOrders', 'forms', 'cart', 'regularity']) ? state.getIn(['scheduledOrders', 'forms', 'cart'], Map({}))?.toJS() : {};
  const cartLevelFormStatus = formValues.regularity ? ScheduleOrderFormStatus.SUBMITTED : state.getIn(['scheduledOrders', 'cartLevelFormStatus'], VIEW);
  return {
    cartLevelFormStatus,
    formValues,
    defaultFormValues: state.getIn(['scheduledOrders', 'forms'], List([])).find(form => form?.has('regularity'))?.toJS() || {},
    earliestDeliveryDate: getDeliveryDateNewSubscription(state.get('publicHolidays', List([])).toJS()),
    isGoldOrAboveLoyaltyDiscount: [2, 3].includes(state.getIn(['user', 'accountDetails', 'loyaltyDiscount', 'loyaltyTier'], 0)),
    isConfirmationScreenScheduledOrders: state.hasIn(['scheduledOrders', 'forms', 'cart', 'regularity']) && !!state.getIn(['productLineItems'], List([])).filter(
      item => {
        const orderLineItem = orderLines.find(orderLine => orderLine.get('sku') === item.get('sku'));
        return !!orderLineItem && !orderLineItem.get('scheduledOrder');
      }
    ).size,
    isLoadingCreateSubscriptionButton: state.getIn(['scheduledOrders', 'loadingCreateSubscription', 'cart'])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveScheduledOrderSettings: (settings) => dispatch(saveScheduledOrderCartFormSettings(settings)),
    setCartLevelFormStatus: (status) => dispatch(setCartLevelFormStatus(status)),
    removeCartScheduledOrdersSettings: () => dispatch(removeCartScheduledOrdersSettings()),
    scheduleSubscriptions: (settings) => dispatch(scheduleSubscriptionsAllOrderLines(settings))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrdersCart);
