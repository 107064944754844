import { put, select, takeLatest, call, all, fork } from 'redux-saga/effects';
import { FETCH_ANONYMOUS_TOKEN,
  VALIDATE_TOKEN,
  UPDATE_JWT_TOKEN, updateJWTToken,
  MISSING_TOKEN, missingToken,
  ALMOST_EXPIRED_TOKEN, almostExpiredToken,
  INVALID_TOKEN, invalidToken,
  EXPIRED_TOKEN, expiredToken,
  VALID_TOKEN, validToken
} from 'client/actions/tokenActions';
import { fetchNewAnonymousToken, renewToken } from 'shared/endpoints/tokenEndpoint';
import { getTokenStatus, setToken, TokenStatus } from 'shared/utils/jwtUtils';
import { delay } from 'shared/utils/sagaUtils';

export function * handleFetchAnonymousToken () {
  const token = yield call(fetchNewAnonymousToken);
  yield call(setToken, token);
  yield put(updateJWTToken({ token }));
}

export const tokenSelector = (state) => state.getIn(['auth', 'jwtToken']);

export function * handleValidateToken () {
  const token = yield select(tokenSelector);
  const tokenStatus = getTokenStatus(token);
  const statusHandlers = {
    [TokenStatus.MISSING]: missingToken,
    [TokenStatus.ALMOST_EXPIRED]: almostExpiredToken,
    [TokenStatus.INVALID]: invalidToken,
    [TokenStatus.EXPIRED]: expiredToken,
    [TokenStatus.VALID]: validToken
  };
  const action = statusHandlers[tokenStatus];
  yield put(action(token));
}

export function * watchFetchAnonymousToken () {
  yield takeLatest(FETCH_ANONYMOUS_TOKEN, handleFetchAnonymousToken);
}

export function * watchValidateToken () {
  yield takeLatest(VALIDATE_TOKEN, handleValidateToken);
}

export function * handleMissingToken () {
  yield * handleFetchAnonymousToken();
}

export function * handleRenewToken (action) {
  try {
    const tokens = yield call(renewToken, action.token, 'token,longSessionToken');
    yield call(setToken, tokens.token, tokens.longSessionToken);
    yield put(updateJWTToken(tokens));
  } catch (err) {
    console.log('Token renewal failure: ', err); // eslint-disable-line no-console
  }
}

export function * resetTokenTimer (action) {
  if (process.browser) {
    const token = action.token || action.tokens.token;
    yield call(delay, 1000 * 60 * 10); // 10 minutes delay for token renewal
    yield put(almostExpiredToken(token));
  }
}

export function * watchMissingToken () {
  yield takeLatest(MISSING_TOKEN, handleMissingToken);
}

export function * watchAlmostExpiredToken () {
  yield takeLatest(ALMOST_EXPIRED_TOKEN, handleAlmostExpiredToken);
}

export function * watchResetTokenTimer () {
  // triggering again will cancel current timer
  yield takeLatest([VALID_TOKEN, UPDATE_JWT_TOKEN], resetTokenTimer);
}

export function * handleAlmostExpiredToken (action) {
  yield * handleRenewToken(action);
}

export function * handleInvalidOrExpiredToken () {
  yield * handleFetchAnonymousToken();
}

export function * watchInvalidOrExpiredToken () {
  yield takeLatest([ INVALID_TOKEN, EXPIRED_TOKEN ], handleInvalidOrExpiredToken);
}

export function * watchAllTokenSagas () {
  yield all([
    fork(watchMissingToken),
    fork(watchFetchAnonymousToken),
    fork(watchInvalidOrExpiredToken),
    fork(watchAlmostExpiredToken),
    fork(watchValidateToken),
    fork(watchResetTokenTimer)
  ]);
}
