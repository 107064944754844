
import { S3_LOCATION } from 'shared/constants/images';
import { fromJS } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import {
  MAESTRO_CARD_TYPE,
  MASTER_CARD_TYPE,
  AMERICAN_EXPRESS_CARD_TYPE,
  DISCOVER_CARD_TYPE,
  VISA_CARD_TYPE,
  PAYPAL_CARD_TYPE,
  CARD,
  PAYPAL,
  PAY_ON_ACCOUNT,
  APPLE_PAY_CARD_TYPE,
  GOOGLE_PAY_CARD_TYPE
} from 'shared/constants/braintree';
import { PAY_BY_APPLE_PAY, PAY_BY_PAYPAL, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD, PAY_BY_ACCOUNT, PAY_BY_GOOGLE_PAY } from 'client/components/elements/paymentMethod/PaymentMethod/constants';

export const getErrorMessage = {
  HOSTED_FIELDS_EMPTY: getLocalizedString('braintree.hostedFieldsEmpty'),
  HOSTED_FIELDS_INVALID: getLocalizedString('braintree.hostedFieldsInvalid'),
  HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE: getLocalizedString('braintree.hostedFieldsTokenizationFailOnDuplicate'),
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED: getLocalizedString('braintree.hostedFieldsTokenizationCvvFail'),
  HOSTED_FIELDS_FAILED_TOKENIZATION: getLocalizedString('braintree.hostedFieldsFailedTokenization'),
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR: getLocalizedString('braintree.hostedFieldsTokenizationNetworkError'),
  TEAR_DOWN_ERROR: getLocalizedString('braintree.hostedFieldsTearDownError')
};

export const getCardImageUrl = {
  [MAESTRO_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/maestro.png`,
  [MASTER_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/mastercard.png`,
  [AMERICAN_EXPRESS_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/amex.png`,
  [DISCOVER_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/discover.png`,
  [VISA_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/visa.png`,
  [PAYPAL_CARD_TYPE]: `${S3_LOCATION}/content/images/payment-icons/paypal.png`
};
export const getCardSvgId = {
  [MAESTRO_CARD_TYPE]: 'maestro-icon',
  [MASTER_CARD_TYPE]: 'mastercard-icon',
  [AMERICAN_EXPRESS_CARD_TYPE]: 'americanExpress-icon',
  [DISCOVER_CARD_TYPE]: 'discover-icon',
  [VISA_CARD_TYPE]: 'visa-icon',
  [PAYPAL_CARD_TYPE]: 'paypal-icon',
  [PAY_ON_ACCOUNT]: 'pay-on-account-icon',
  [APPLE_PAY_CARD_TYPE]: 'pay-by-apple-pay-icon',
  [GOOGLE_PAY_CARD_TYPE]: 'pay-by-google-pay-icon'
};

export const mapPaymentTermsText = (selectedPaymentMethod) => {
  const paymentTypeMap = {
    [PAY_BY_APPLE_PAY]: 'singlePageCheckout.termsOfSale.applePay.beforeLink',
    [PAY_BY_PAYPAL]: 'singlePageCheckout.termsOfSale.paypal.beforeLink',
    [PAY_BY_EXISTING_CARD]: 'singlePageCheckout.termsOfSale.beforeLink',
    [PAY_BY_NEW_CARD]: 'singlePageCheckout.termsOfSale.beforeLink',
    [PAY_BY_ACCOUNT]: 'singlePageCheckout.termsOfSale.beforeLink',
    [PAY_BY_GOOGLE_PAY]: 'singlePageCheckout.termsOfSale.googlePay.beforeLink'
  };
  return paymentTypeMap[selectedPaymentMethod];
};

export const isSavedPaymentMethodExist = (savedPaymentMethods, token) => {
  return savedPaymentMethods.filter(i => i.token !== CARD).some(paymentMethod => paymentMethod.token === token);
};

export const getDefaultPaymentMethodToken = (savedPaymentMethods) => {
  const defaultCard = getDefaultPayment(savedPaymentMethods);
  return (defaultCard) ? defaultCard.token : null;
};

export const getBillingAddressForSavedCard = (savedPaymentMethods, token) => {
  const savedPaymentMethod = savedPaymentMethods.find(paymentMethod => paymentMethod.token === token);
  return savedPaymentMethod.billingAddress;
};

export const getSavedCardByToken = (savedPaymentMethods, token) => {
  return savedPaymentMethods.find(paymentMethod => paymentMethod.token === token);
};

export const isPaypalPayment = (savedPaymentMethods, token) => {
  const paymentMethod = savedPaymentMethods.find(paymentMethod => paymentMethod.token === token);
  return (paymentMethod && paymentMethod.paymentType === PAYPAL);
};

export const isPaypalInSavedPaymentMethods = (savedPaymentMethods) => {
  return savedPaymentMethods.some(paymentMethod => paymentMethod.paymentType === PAYPAL);
};

export const getDefaultPayment = (savedPaymentMethods) => {
  return savedPaymentMethods.find(paymentMethod => paymentMethod.isDefault);
};

export const getDefaultPaymentData = (savedPaymentMethods, token) => {
  const selectedCard = savedPaymentMethods.find(paymentMethod => paymentMethod.token === token);
  const defaultCard = savedPaymentMethods.find(l => l.isDefault);
  const [firstCard] = savedPaymentMethods;
  const { length, [length - 1]: lastCard } = savedPaymentMethods;

  const card = selectedCard ? selectedCard : defaultCard ? defaultCard : lastCard ? lastCard : (firstCard && firstCard.value) ? firstCard : null;

  return card ? {
    billingAddress: fromJS(card.billingAddress),
    paymentType: card.paymentType === PAYPAL ? PAYPAL : CARD,
    cardType: card.cardType,
    payPalEmail: card.email,
    cardEnding: card.cardLastFourDigits,
    token: card.token
  } : null;
};

export const getDefaultPaymentMethodType = (savedPaymentMethods) => {
  const paymentMethod = getDefaultPayment(savedPaymentMethods);
  const { paymentType = CARD } = paymentMethod || {};
  return paymentType;
};

export const isPayPalDeliveryAddressChanged = (paymentMethod, addressId, isShippingDetailsUpdated) => { // hiding PayPal vaulted accounts if delivery address has been changed and legacy users
  const { paymentType, shippingAddressesIds = [] } = paymentMethod;
  const isNewShippingAddress = shippingAddressesIds.length
    ? !shippingAddressesIds.includes(addressId)
    : true;
  return paymentType === PAYPAL && (isNewShippingAddress || isShippingDetailsUpdated);
};

export const getSelectedSavedCardDetails = (savedPaymentMethods = [], token, shippingAddressId) => {
  const storedCard = savedPaymentMethods.find(paymentMethod => paymentMethod.token === token);
  const { shippingAddressesIds = [] } = storedCard || {};
  const isNewShippingDetails = shippingAddressesIds.length
    ? !shippingAddressesIds.includes(shippingAddressId)
    : true;
  return {
    isNewShippingDetails,
    storedCard
  };
};

export const getStoredCards = (savedPaymentMethods, shippingAddressId, isShippingDetailsUpdated) => {
  return savedPaymentMethods.filter(paymentMethod => !isPayPalDeliveryAddressChanged(paymentMethod, shippingAddressId, isShippingDetailsUpdated));
};

export const getSavedCardsForNewShippingDetails = (savedPaymentMethods, shippingAddressId, isShippingDetailsUpdated) => {
  const isNewDeliveryAddressAddedForSavedPayPal = savedPaymentMethods.some(paymentMethod =>
    isPayPalDeliveryAddressChanged(paymentMethod, shippingAddressId, isShippingDetailsUpdated));
  const savedCards = isNewDeliveryAddressAddedForSavedPayPal
    ? getStoredCards(savedPaymentMethods, shippingAddressId, isShippingDetailsUpdated)
    : savedPaymentMethods;
  const defaultType = getDefaultPaymentMethodType(savedPaymentMethods);
  return {
    isNewDeliveryAddressAddedForSavedPayPal,
    defaultType,
    savedCards
  };
};

export const isStringEmpty = (str) => {
  return (!str || /^\s*$/.test(str));
};

export const isValidString = (str) => {
  const regex = /^([a-zA-Z '-]+\s)*[a-zA-Z '-]+$/;
  return regex.test(str);
};

export const getHostedFieldConfig = () => ({
  number: {
    selector: '#cc-number',
    placeholder: ''
  },
  cvv: {
    selector: '#cc-cvv',
    placeholder: ''
  },
  expirationDate: {
    selector: '#cc-expiration',
    placeholder: 'MM/YY'
  }
});

export const getCvvHostedFieldConfig = () => ({
  cvv: {
    selector: '#cc-auth-cvv',
    placeholder: ''
  }
});
const colorDarkGrey = '#383838';
const colorMainGrey = '#B3B5B5';
export const getHostedFieldStylesConfig = () => ({
  'input': {
    'font-size': '14px',
    'color': colorDarkGrey,
    'font-family': 'Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif'
  },
  ':focus': {
    'color': colorDarkGrey
  },
  '::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
    'font-size': '14px',
    'line-height': '20px',
    color: colorMainGrey,
    opacity: 1 /* Firefox */
  },
  ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
    'font-size': '14px',
    'line-height': '20px',
    color: colorMainGrey
  },
  '::-ms-input-placeholder': { /* Microsoft Edge */
    'font-size': '14px',
    'line-height': '20px',
    color: colorMainGrey
  }
});

export const getBraintreeSavedCards = (paymentMethods) => {
  return paymentMethods
    .filter(paymentMethod => paymentMethod.paymentType === CARD)
    .sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
};

export const getStyleConfigForPaypalButton = () => ({
  color: 'gold',
  shape: 'rect',
  label: 'pay',
  size: 'responsive',
  tagline: false
});
