import { fromJS } from 'immutable';
import {
  SET_MOBILE_FILTERS_VISIBILITY
} from 'client/actions/ui/filterPanelActions';
import {
  INITIALIZE_COMPONENT_STATE,
  REMOVE_COMPONENT_STATE
} from 'shared/utils/localState';

export const defaultState = fromJS({
  mobile: {
    isOpen: false
  }
});

const filterPanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INITIALIZE_COMPONENT_STATE:
      return state.set(action.selector, fromJS({
        open: true
      }));
    case REMOVE_COMPONENT_STATE:
      return state.remove(action.selector);
    case SET_MOBILE_FILTERS_VISIBILITY:
      return state.setIn(['mobile', 'isOpen'], action.payload);
    default:
      return state;
  }
};

export default filterPanelReducer;
