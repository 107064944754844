import { fromJS } from 'immutable';
import {
  RECEIVE_BRAINTREE_AUTHORIZATION_FAIL,
  RECEIVE_BRAINTREE_CLIENT_TOKEN,
  RESET_BRAINTREE_AUTHORIZATION,
  RECEIVE_BRAINTREE_DATA_COLLECTOR_SUCCESS,
  RECEIVE_SAVED_PAYMENT_METHODS_SUCCESS,
  RECEIVE_SAVED_PAYMENT_METHODS_FAIL,
  SET_PAYMENT_METHOD_TYPE,
  TOGGLE_PAY_WITH_CARD,
  RECEIVE_BRAINTREE_PAYPAL_DATACOLLECTOR_SUCCESS,
  RESET_BRAINTREE_PAYPAL_AUTHORIZATION,
  SET_NAME_ON_CARD,
  RECEIVE_PAYMENT_INFORMATION,
  RECEIVE_PAYMENT_INFORMATION_SUCCESS,
  TOGGLE_PAYPAL,
  RESET_PAYMENT_METHOD_DATA,
  UPDATE_HOSTED_FIELDS_STATUS,
  RECEIVE_BRAINTREE_CVV_HOSTED_FIELD_INSTANCE_SUCCESS,
  SET_SELECTED_SAVED_CARD,
  SET_SAVED_CARDS
} from 'client/actions/braintreeActions';
import { SUCCESS } from 'shared/constants/loadStateType';

const defaultState = fromJS({
  hostedFields: null,
  isBraintreeReady: false,
  clientToken: null,
  dataCollectorInstance: null,
  threeDSecureInstance: null,
  error: null,
  storePaymentMethodStatus: true,
  savedPaymentMethods: [],
  transaction: null,
  paymentMethodError: null,
  paymentMethod: null,
  togglePaymentMethod: false,
  isDefaultPaymentMethod: false,
  isPayPalReady: false,
  paypalCheckoutInstance: null,
  paypalDataCollectorInstance: null,
  paypalError: null,
  nameOnCard: '',
  isSpinnerActive: false,
  PayPalButton: null,
  paymentInformation: null,
  paymentType: null,
  isPaypalPaymentMethodSelected: false,
  isPaypalInSavedPaymentMethods: false,
  isPaypalPaymentAuthorizedFromPaypal: false,
  togglePaypal: false,
  cvvHostedFieldInstance: null,
  selectedSavedCard: {},
  number: {
    isEmpty: true,
    isValid: true
  },
  cvv: {
    isEmpty: true,
    isValid: true
  },
  expirationDate: {
    isEmpty: true,
    isValid: true
  },
  cardholderName: {
    isEmpty: true,
    isValid: true
  },
  verifyCvv: {
    isEmpty: true,
    isValid: true
  },
  addNewPaymentMethod: {
    loadStateType: SUCCESS,
    openFormInModal: false,
    isAddingNewCard: false,
    isEditingCard: false
  }
});

const braintreePayment = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_HOSTED_FIELDS_STATUS:
      const hostedFieldName = action.payload.hostedFieldName;
      const hostedFieldStatus = action.payload.hostedFieldStatus;
      return state
        .set(hostedFieldName, fromJS(hostedFieldStatus));
    case RECEIVE_BRAINTREE_CLIENT_TOKEN:
      return state
        .set('clientToken', action.clientToken);
    case RECEIVE_BRAINTREE_DATA_COLLECTOR_SUCCESS:
      return state
        .set('dataCollectorInstance', action.dataCollectorInstance);
    case RECEIVE_BRAINTREE_AUTHORIZATION_FAIL:
      return state
        .set('isBraintreeReady', false)
        .set('error', action.error);
    case RECEIVE_PAYMENT_INFORMATION_SUCCESS:
      return state.set('error', null);
    case RECEIVE_SAVED_PAYMENT_METHODS_FAIL:
      return state.set('paymentMethodError', action.error);
    case RECEIVE_SAVED_PAYMENT_METHODS_SUCCESS:
      const paymentMethods = action.payload.paymentMethods;
      const toggleCardPayment = action.payload.toggleCardPayment;
      const paymentMethod = action.payload.paymentMethod;
      const isPaypal = action.payload.isPaypal;
      return state
        .set('savedPaymentMethods', fromJS(paymentMethods))
        .set('paymentMethod', paymentMethod)
        .set('isPaypalInSavedPaymentMethods', isPaypal)
        .set('togglePaymentMethod', toggleCardPayment);
    case SET_PAYMENT_METHOD_TYPE:
      return state.set('paymentMethod', action.paymentMethod);
    case TOGGLE_PAY_WITH_CARD:
      return state.set('togglePaymentMethod', action.payload)
        .setIn(['addNewPaymentMethod', 'isAddingNewCard'], action.payload);
    case TOGGLE_PAYPAL:
      return state.set('togglePaypal', action.payload);
    case RECEIVE_BRAINTREE_PAYPAL_DATACOLLECTOR_SUCCESS:
      return state.set('paypalDataCollectorInstance', action.payload);
    case SET_NAME_ON_CARD:
      return state.set('nameOnCard', action.payload);
    case RESET_BRAINTREE_PAYPAL_AUTHORIZATION:
      return state
        .set('paypalCheckoutInstance', null)
        .set('isPayPalReady', false)
        .set('isPaypalPaymentAuthorizedFromPaypal', false)
        .set('paypalDataCollectorInstance', null);
    case RESET_BRAINTREE_AUTHORIZATION:
      return state
        .set('hostedFields', null)
        .set('nameOnCard', '')
        .set('dataCollectorInstance', null)
        .set('threeDSecureInstance', null)
        .set('paymentInformation', null)
        .set('transaction', null)
        .set('isBraintreeReady', false)
        .set('clientToken', null)
        .set('error', null)
        .set('savedPaymentMethods', fromJS([]))
        .set('togglePaypal', false)
        .set('selectedSavedCard', fromJS({}))
        .set('storePaymentMethodStatus', true)
        .set('paymentMethod', null);
    case RECEIVE_PAYMENT_INFORMATION:
      return state.set('paymentInformation', action.payload);
    case RESET_PAYMENT_METHOD_DATA:
      return state.set('paymentInformation', null);
    case RECEIVE_BRAINTREE_CVV_HOSTED_FIELD_INSTANCE_SUCCESS:
      return state.set('cvvHostedFieldInstance', action.payload);
    case SET_SELECTED_SAVED_CARD:
      return state.set('selectedSavedCard', fromJS(action.payload));
    case SET_SAVED_CARDS:
      return state
        .set('savedPaymentMethods', fromJS(action.payload));
    default:
      return state;
  }
};

export default braintreePayment;
