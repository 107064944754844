/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { getLocalizedString } from 'localization/localizer';
import { Field, reduxForm, change, SubmissionError, reset, updateSyncErrors, getFormValues } from 'redux-form/immutable';
import { validateForm } from 'client/utils/formValidation';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { setView, updateAdditionalUsersList, toggleAddAnotherUser } from 'client/actions/loginAndRegistration';
import TextareaWithValidations from 'client/components/elements/formField/TextareaWithValidations';
import SvgLoader from 'client/components/svg/SvgLoader';
import { makeValidator, isRequired, isNumberRequiredIf, isEmailAddressesList } from 'client/utils/validators';
import RadioButtonWithValidation from 'client/components/elements/formField/RadioButtonWithValidation';
import {
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  YES,
  ADMIN,
  FINANCE,
  STANDARD,
  accountStatusMapper
} from '../../constants';
import {
  checkForExistingTradeAccounts
} from '../utils';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';

const ADDITIONAL_USERS_FORM = 'additionalUsersForm';
const ACCOUNT_LIMIT = 'Account Limit';
const validateFunc = (existingEmails, openCreditLine) => makeValidator({
  emailAddresses: [
    isRequired({
      message: 'Please ensure all email addresses are valid'
    }),
    isEmailAddressesList(existingEmails)
  ],
  spendLimit: [
    isNumberRequiredIf(
      (values) => {
        return openCreditLine === YES && values.get('accountRole') !== ADMIN;
      },
      getLocalizedString('error.invalid.number')
    )
  ],
  message: [],
  accountRole: []
});

const AdditionalUsersForm = ({
  dispatchSetView,
  onResetReduxForm,
  primaryContactDetailsForm,
  toggleAnotherUser,
  dispatchToggleAddAnotherUser,
  dispatchUpdateAdditionalUsersList,
  additionalUsersList,
  handleSubmit,
  onResetField,
  onUpdateFieldError,
  additionalUserForm,
  creditLineApplicationForm,
  valid,
  hideExitConfirmation
}) => {
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const isOpenCreditLine = primaryContactDetailsForm?.openCreditLine === YES;

  const submit = (values) => {
    const financeContact = additionalUsersList.find((el) => el.isFinanceUser) || {};
    const existingEmails = [primaryContactDetailsForm?.email, financeContact?.email].filter((el) => el);
    const openCreditLine = primaryContactDetailsForm?.openCreditLine;
    return validateForm(values, validateFunc(existingEmails, openCreditLine))
      .then(async (res) => {
        hideExitConfirmation();
        setIsAddingNewUser(true);
        const usersEmails = uniq(values.get('emailAddresses', '').trim().split(','));
        try {
          await checkForExistingTradeAccounts(usersEmails);
          dispatchUpdateAdditionalUsersList(fromJS([
            ...additionalUsersList.filter((user) => !usersEmails.includes(user.email)),
            ...usersEmails.map((emailAddress) => ({
              email: emailAddress,
              message: values.get('message'),
              spendLimit: values.get('spendLimit', ACCOUNT_LIMIT),
              role: values.get('accountRole', 'STANDARD')
            }))
          ]));
          dispatchToggleAddAnotherUser(false);
          onResetReduxForm();
        } catch (err) {
          hideExitConfirmation();
          onUpdateFieldError('emailAddresses', err.message);
        }
        setIsAddingNewUser(false);
      }).catch((errors) => {
        hideExitConfirmation();
        throw new SubmissionError(errors);
      });
  };

  const removeUser = (userEmail) => {
    dispatchUpdateAdditionalUsersList(fromJS(additionalUsersList.filter(user => user.email !== userEmail)));
  };

  const editUser = (userEmail) => {
    const user = additionalUsersList.find((user) => user.email === userEmail) || {};
    dispatchToggleAddAnotherUser(true);
    onResetField('emailAddresses', user.email);
    onResetField('accountRole', user.role);
    onResetField('spendLimit', user.spendLimit !== ACCOUNT_LIMIT ? user.spendLimit : '');
    onResetField('message', user.message || '');
  };

  useEffect(() => {
    onResetField('spendLimit',
    additionalUserForm?.accountRole === ADMIN
      ? ACCOUNT_LIMIT
      : additionalUserForm?.spendLimit === ACCOUNT_LIMIT
        ? '' : additionalUserForm?.spendLimit || '');
  }, [additionalUserForm.accountRole]);

  return <div className="row">
    <div className="col-xs-12">
      <div className="BusinessAccountRegistrationForm_additionalUsersTitle">
        Add Colleagues to your Your Business Account
      </div>
      <div className="BusinessAccountRegistrationForm_additionalUsersSubTitle">
        This can also be done at a later time by navigating to your My Account section.
      </div>
    </div>
    <div className="row col-xs-12 BusinessAccountRegistrationForm_additionalUsersTable">
      <div className="col-xs-12 col-md-4 BusinessAccountRegistrationForm_additionalUsersTable-header">
        Email address
      </div>
      <div className="col-xs-12 col-md-2 BusinessAccountRegistrationForm_additionalUsersTable-header">
        Role
      </div>
      <div className="col-xs-12 col-md-4 BusinessAccountRegistrationForm_additionalUsersTable-header">
        Monthly Spend Limit
      </div>
      <div className="col-xs-12  col-md-2 BusinessAccountRegistrationForm_additionalUsersTable-header">
        Actions
      </div>
    </div>
    <div data-e2e="headers" className="row col-xs-12 BusinessAccountRegistrationForm_additionalUsersTable-rows">
      <div className="col-xs-12 col-md-4">{primaryContactDetailsForm?.email || ''}</div>
      <div className="col-xs-12 col-md-2">Admin</div>
      <div className="col-xs-12 col-md-4">{ACCOUNT_LIMIT}</div>
      <div className="col-xs-12 col-md-2"></div>
    </div>
    {
      additionalUsersList.map((user, i) => {
        return (<div data-e2e={ `user-${i + 1}` } key={ user.email }className="row col-xs-12 BusinessAccountRegistrationForm_additionalUsersTable-rows">
          <div className="col-md-4" data-e2e={ `user-${i + 1}-${user.email}` }>{user.email}</div>
          <div className="col-md-2" data-e2e={ `user-${i + 1}-${user.role}` }>{accountStatusMapper[user.role]}</div>
          <div className="col-md-4" data-e2e={ `user-${i + 1}-${user.spendLimit}` }>{user?.spendLimit !== ACCOUNT_LIMIT ? `£${user.spendLimit}` : !isOpenCreditLine ? '' : ACCOUNT_LIMIT}</div>
          { !user?.isFinanceUser ? <div className="col-md-2" data-e2e="actions">
            <span
              onClick={ () => removeUser(user.email) }
              className="BusinessAccountRegistrationForm_additionalUsersTable-action">
              <SvgLoader xlinkHref="trash-icon" className="BusinessAccountRegistrationForm_additionalUsersTable-actionDelete" />
            </span>
            <span
              onClick={ () => editUser(user.email) }
              className="BusinessAccountRegistrationForm_additionalUsersTable-action">
              <SvgLoader xlinkHref="edit-icon" className="BusinessAccountRegistrationForm_additionalUsersTable-actionDelete" />
            </span>
          </div> : null }
        </div>);
      })
    }
    { !toggleAnotherUser ? <div className="col-xs-12 BusinessAccountRegistrationForm_addAnotherUser">
      <a className="" data-e2e="addAnother" onClick={ () => dispatchToggleAddAnotherUser(true) }>
          + Add another
      </a>
    </div> : null }
    { toggleAnotherUser ? <form onSubmit={ handleSubmit(submit) }>
      <div className="row col-xs-12 BusinessAccountRegistrationForm_addUserForm">
        <div className="BusinessAccountRegistrationForm_addUserForm-title">Add a user</div>
        <SvgLoader className="BusinessAccountRegistrationForm_addUserForm-closeIcon" xlinkHref="close-x" onClick={ () => dispatchToggleAddAnotherUser(false) } />
        <div className="row col-xs-12">
          <div className="col-xs-12 col-md-5">
            <Field
              id="emailAddresses"
              className="BusinessAccountRegistrationForm_addUserForm-emailAddressesTextarea"
              labelClassName="BusinessAccountRegistrationForm_addUserForm-textareaLabel"
              labelResourceName="registration.placeholder.emailAddresses"
              name="emailAddresses"
              placeholderResourceName="additionalUsers.emailAddresses.placeholder"
              datae2e="adduser.email"
              isShowLength={ false }
              component={ TextareaWithValidations }
            />
            <Field
              id="spendLimit"
              className="form-control"
              labelClassName="BusinessAccountRegistrationForm_addUserForm-textareaLabel"
              labelResourceName="registration.placeholder.setAMontlySpendLimit"
              name="spendLimit"
              placeholderResourceName="additionalUsers.spendLimit.placeholder"
              datae2e="adduser.monthlySpend"
              subTitle={ !isOpenCreditLine ? <div className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine">
                <SvgLoader xlinkHref="warn-icon" className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine-icon" />
                Only available if applying for a <div onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW) }>Credit Line</div>
              </div> : null }
              disabled={ !isOpenCreditLine || additionalUserForm?.accountRole === ADMIN }
              component={ FieldWithValidations }
            />
            <Field
              id="message"
              className="BusinessAccountRegistrationForm_addUserForm-includeMessageTextarea"
              labelClassName="BusinessAccountRegistrationForm_addUserForm-textareaLabel"
              labelResourceName="registration.placeholder.includeMessage"
              name="message"
              datae2e="adduser.message"
              isShowLength={ false }
              component={ TextareaWithValidations }
            />
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="col-xs-12">
              <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleTitle">Select an Account Role</div>
              <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox">
                <Field
                  component={ RadioButtonWithValidation }
                  name="accountRole"
                  labelClassName="control-label"
                  className="form-control"
                  options={ [
                    { value: ADMIN,
                      disabled: !isOpenCreditLine,
                      datae2e: 'adminRole',
                      title: <div>
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-role">Admin</div>
                        { !isOpenCreditLine ? <div className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine">
                          <SvgLoader xlinkHref="warn-icon" className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine-icon" />
                          Only available if applying for a <div onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW) }>Credit Line</div>
                        </div> : null }
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-subText">
                          Authorised to spend on the company business account, add users, view account invoices and orders.
                        </div>
                        <SvgLoader xlinkHref="lightning" className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-icon" />
                      </div> },
                    { value: STANDARD,
                      datae2e: 'standardRole',
                      disabled: false,
                      title: <div>
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-role">Standard</div>
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-subText">
                          { isOpenCreditLine ? 'Authorised to spend on the company business account and view own account purchases.' : 'Authorised to spend on your business account and benefit from other features. Unable to add or manage user spending.' }
                        </div>
                        <SvgLoader xlinkHref="pyramid" className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-icon" />
                      </div>
                    },
                    { value: FINANCE,
                      disabled: !isOpenCreditLine,
                      datae2e: 'financeRole',
                      title: <div>
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-role">Finance</div>
                        { !isOpenCreditLine ? <div className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine">
                          <SvgLoader xlinkHref="warn-icon" className="BusinessAccountRegistrationForm_additionalUsersTable-warnLine-icon" />
                          Only available if applying for a <div onClick={ () => dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW) }>Credit Line</div>
                        </div> : null }
                        <div className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-subText">
                          Authorised to view all account invoices, orders and purchase on account.
                        </div>
                        <SvgLoader xlinkHref="pound-coin" className="BusinessAccountRegistrationForm_additionalUsersTable-accountRoleBox-icon" />
                      </div> }
                  ] }
                  datae2e="accountRole"
                  validatorName="accountRole"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <PrimaryButton
              className="BusinessAccountRegistrationForm_additionalUsersTable-saveUserBtn"
              type="submit"
              disabled={ isAddingNewUser }
              datae2e="saveInvitee">
              { isAddingNewUser ? 'Processing' : 'Save User Details' }
            </PrimaryButton>
            <SecondaryButton
              className="BusinessAccountRegistrationForm_additionalUsersTable-cancelBtn"
              datae2e="back"
              onClick={ () => {
                dispatchToggleAddAnotherUser(false);
                onResetReduxForm(reset(ADDITIONAL_USERS_FORM));
              } }
            >
                Cancel
            </SecondaryButton>
          </div>
        </div>
      </div>
    </form> : null }
    <div className="row BusinessAccountRegistrationForm_addUserForm-btnsContainer">
      <div className="BusinessAccountRegistrationForm_addUserForm-btns">
        <div className="col-xs-12">
          <div className="col-xs-6 BusinessAccountRegistrationForm_backBtn ForgotPasswordNewForm_noLeftPadding">
            <SecondaryButton
              datae2e="back"
              onClick={ () => {
                hideExitConfirmation();
                dispatchSetView(creditLineApplicationForm?.financeContact === YES && isOpenCreditLine
                  ? CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW
                  : isOpenCreditLine
                    ? CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW
                    : CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW);
              }
              }
            >
                Back
            </SecondaryButton>
          </div>
          <div className="col-xs-6 BusinessAccountRegistrationForm_continueBtn ForgotPasswordNewForm_noRightPadding">
            <PrimaryButton
              className="btn"
              disabled={ additionalUserForm?.emailAddresses && !valid }
              onClick={ () => {
                hideExitConfirmation();
                dispatchSetView(CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW);
              } }
              datae2e="continue">
                Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

const ReduxAdditionalUsersForm = reduxForm({
  form: ADDITIONAL_USERS_FORM,
  validate: validateFunc(),
  destroyOnUnmount: false
})(AdditionalUsersForm);

const mapStateToProps = (state) => {
  const primaryContactDetailsForm = (getFormValues('primaryContactForm')(state) || fromJS({})).toJS();
  const additionalUserForm = (getFormValues(ADDITIONAL_USERS_FORM)(state) || fromJS({})).toJS();
  const message = `Hi,
Please accept this invitation to join the Zoro business account.
Kind Regards,
${primaryContactDetailsForm?.firstName || ''}`.trim();

  return {
    toggleAnotherUser: state.getIn(['loginAndRegistration', 'toggleAnotherUser']),
    additionalUsersList: (state.getIn(['loginAndRegistration', 'additionalUsersList']) || fromJS([])).toJS(),
    additionalUserForm,
    creditLineApplicationForm: (getFormValues('creditLineApplicationForm')(state) || fromJS({})).toJS(),
    primaryContactDetailsForm,
    initialValues: {
      accountRole: 'STANDARD',
      message
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateFieldError: (field, message) => dispatch(updateSyncErrors(ADDITIONAL_USERS_FORM, {
    [field]: message
  })),
  onResetField: (field, value) => dispatch(change(ADDITIONAL_USERS_FORM, field, value)),
  dispatchSetView: (view) => dispatch(setView({ view })),
  dispatchToggleAddAnotherUser: (flag) => dispatch(toggleAddAnotherUser(flag)),
  dispatchUpdateAdditionalUsersList: (list) => dispatch(updateAdditionalUsersList(list)),
  onResetReduxForm: () => dispatch(reset(ADDITIONAL_USERS_FORM)),
  hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxAdditionalUsersForm);
