import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';

export function getTotalizedValues (cart) {
  const deliveryExVat = cart.getIn(['cartPrice', 'totalShippingPriceWithoutVat']) + cart.getIn(['cartPrice', 'additionalShippingTotalWithoutVat']);
  const currency = cart.getIn(['currency']);
  const deliveryIncVat = cart.getIn(['cartPrice', 'totalShippingPriceWithVat'], 0);
  const vat = cart.getIn(['cartPrice', 'cartGrandTotalVat'], 0);

  const discountCodeApplied = cart.getIn(['discount', 'code'], undefined);
  const discountCampaignTitle = cart.getIn(['discount', 'campaignTitle'], '');
  const discountWithVat = cart.getIn(['cartPrice', 'totalDiscountWithVat'], 0);
  const discountWithoutVat = cart.getIn(['cartPrice', 'totalDiscountWithoutVat'], 0);

  const freeDeliveryShortfall = deliveryExVat > 0 ? FREE_DELIVERY_THRESHOLD - cart.getIn(['cartPrice', 'cartOrderLinesTotalWithVat'], 0) : 0;

  const totalGoodsExVat = discountWithoutVat > 0 ? cart.getIn(['cartPrice', 'beforeDiscountCartOrderLinesTotalWithoutVat'], 0) : cart.getIn(['cartPrice', 'cartOrderLinesTotalWithoutVat'], 0);
  const totalGoodsIncVat = discountWithVat > 0 ? cart.getIn(['cartPrice', 'beforeDiscountCartOrderLinesTotalWithVat'], 0) : cart.getIn(['cartPrice', 'cartOrderLinesTotalWithVat'], 0);

  const grandTotalExVat = cart.getIn(['cartPrice', 'cartGrandTotalWithoutVat'], 0);
  const grandTotalIncVat = cart.getIn(['cartPrice', 'cartGrandTotalWithVat'], 0);
  const totalSavingsWithVat = cart.getIn(['cartPrice', 'totalSavingsWithVat'], 0);
  const totalSavingsWithoutVat = cart.getIn(['cartPrice', 'totalSavingsWithoutVat'], 0);
  const totalBundleSavingWithVat = cart.getIn(['cartPrice', 'totalBundleSavingWithVat'], 0);
  const totalBundleSavingWithoutVat = cart.getIn(['cartPrice', 'totalBundleSavingWithoutVat'], 0);

  return {
    freeDeliveryShortfall,
    totalGoodsExVat,
    totalGoodsIncVat,
    deliveryExVat,
    deliveryIncVat,
    vat,
    grandTotalExVat,
    grandTotalIncVat,
    currency,
    discountCodeApplied,
    discountCampaignTitle,
    discountWithVat,
    discountWithoutVat,
    totalSavingsWithVat,
    totalSavingsWithoutVat,
    totalBundleSavingWithVat,
    totalBundleSavingWithoutVat
  };
}

export function getTotalQuantity ({ orderLines, bundles }) {
  const orderLineQuantity = orderLines.reduce((acc, item) => acc + item.quantity, 0);
  const bundleQuantity = bundles.reduce((acc, item) => acc + item.bundleQuantity, 0);
  return (orderLineQuantity + bundleQuantity);
}
