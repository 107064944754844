import { change } from 'redux-form/immutable';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { applyDiscountCodeToCart, removeDiscountCode } from 'shared/endpoints/cartEndpoint';

import {
  CHECK_DISCOUNT_CODE_DIALOG
} from 'client/actions/ui/dialogActions';

import {
  REQUEST_APPLY_DISCOUNT_CODE,
  REQUEST_REMOVE_DISCOUNT_CODE,
  APPLY_DISCOUNT_CODE_TO_EXISTING_CART,
  applyDiscountCode,
  applyingDiscountCode
} from 'client/actions/discountCodeActions';
import {
  loadCart
} from 'client/actions/cartActions';

export function * onRequestApplyDiscountCode (action) {
  yield put(change(action.payload.form, 'discountCodeInput', ''));
  yield put(applyingDiscountCode(true));
  yield put(updateDataLayer({
    event: 'discountCodeAdded',
    discountCode: action.payload.discountCode
  }));

  try {
    const addressId = action.payload.addressId;
    const discountCode = action.payload.discountCode;
    yield call(applyDiscountCodeToCart, discountCode, addressId);
    const loadCartAction = (action.payload.eligibleForDeliveryOptions &&
      loadCart({ eligibleForDeliveryOptions: action.payload.eligibleForDeliveryOptions, addressId })) || loadCart({ addressId });
    yield put(loadCartAction);
  } catch (err) {
    yield put(applyingDiscountCode(false));
    throw new Error(err);
  }
}

export function * watchApplyDiscountCode () {
  yield takeLatest(REQUEST_APPLY_DISCOUNT_CODE, onRequestApplyDiscountCode);
}

export function * onRequestRemoveDiscountCode (action) {
  yield put(updateDataLayer({
    event: 'discountCodeRemoved',
    discountCode: action.payload.discountCode
  }));

  yield call(removeDiscountCode);
  const addressId = action.payload.addressId;
  const loadCartAction = (action.payload.eligibleForDeliveryOptions &&
    loadCart({ eligibleForDeliveryOptions: action.payload.eligibleForDeliveryOptions, addressId })) || loadCart({ addressId });
  yield put(loadCartAction);
}

export function * watchRemoveDiscountCode () {
  yield takeLatest(REQUEST_REMOVE_DISCOUNT_CODE, onRequestRemoveDiscountCode);
}

export function * watchDiscountCodeChanges () {
  yield * [
    fork(watchApplyDiscountCode),
    fork(watchRemoveDiscountCode)
  ];
}

export function * onRequestCheckDiscountCodeDialog ({ payload }) {
  if (!payload) {
    return;
  }
  const { code, shown } = payload;
  if (code && !shown) {
    yield put(applyDiscountCode({
      discountCode: code,
      type: 'modal'
    }));
  }
}

export function * onApplyDiscountCodeToExistingCart (action) {
  try {
    const discountCode = action.discountCode.discountCode;
    yield put(applyingDiscountCode(true));
    yield call(applyDiscountCodeToCart, discountCode);
    yield put(applyingDiscountCode(false));
  } catch (err) {
    throw new Error(err);
  }
}

export function * watchApplyDiscountCodeToExistingCart () {
  yield takeLatest(APPLY_DISCOUNT_CODE_TO_EXISTING_CART, onApplyDiscountCodeToExistingCart);
}

export function * watchCheckDiscountCodeDialog () {
  yield takeLatest(CHECK_DISCOUNT_CODE_DIALOG, onRequestCheckDiscountCodeDialog);
}
