import { put, takeLatest, select, call } from 'redux-saga/effects';

import {
  RECIEVE_LOGIN_USER_SUCCESS,
  LOGIN_USER, loginUserError,
  loginUserSuccess,
  DOES_USER_EXIST,
  setUserExistence,
  onUserAccountDetailsReceived,
  logoutUser
} from 'client/actions/userActions';
import { loadCart } from 'client/actions/cartActions';
import { getAccountDetails } from 'client/actions/accountActions';
import { loginUser, doesUserExist } from 'shared/endpoints/userEndpoint';
import { fetchAccountDetails } from 'shared/endpoints/accountEndpoint';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';
import { hideLoginDialog, SHOW_LOGIN_DIALOG } from 'client/actions/ui/dialogActions';
import { setToken, getTradeAccountDataFromToken } from 'shared/utils/jwtUtils';
import { goToUrl, gotoExternalUrl } from 'client/actions/routeActions';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { toastSuccess, toastError } from 'client/actions/showNotificationsActions';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import {
  updateJWTToken
} from 'client/actions/tokenActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { mergeGlobalQuotes } from 'client/actions/globalQuotationActions';
import { ONHOLD, ADMIN } from 'shared/constants/account';
import {
  receiveAccountDetails
} from 'client/actions/accountActions';
import { displayVATToast } from 'client/sagas/myAccountUsersSagas';
import { setView, isLoginLoading } from 'client/actions/loginAndRegistration';
import { DELIVERY_CHECKOUT_ROUTE, CART_ROUTE, SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { getCart } from 'shared/endpoints/cartEndpoint';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { hasTradeAccount } from 'shared/endpoints/accountEndpoint';
import { isZoneTwoOrThree } from 'client/utils/userDataUtils';
import { saveScheduledAllOrderlinesSettings } from 'client/actions/scheduledOrders';
import { showAddToQuotationDialog as showAddToQuotationDialogNonDispatch } from 'client/actions/ui/dialogActions';
import { List } from 'immutable';
import { getCheckoutProductCountAbTestActive, getCheckoutProductCountAbTestThreshold } from 'client/utils/unleashUtils';
import { SHOW } from 'shared/constants/abTesting';
import { isMyAccountPageUrl } from 'client/utils/myAccountUtils';

const TOASTER_DELAY = 3000;

const globalQuotationSelector = (state) => state.getIn(['globalQuotation', 'createdQuotation'], {});
const parcelForceZoneSelector = (state) => state.getIn(['user', 'accountDetails', 'parcelForceZone']);

export const asyncValidate = async (values) => {
  const response = await doesUserExist(values.get('email'));
  if (response.registered) {
    return new Promise((resolve, reject) => {
      reject({ email: getLocalizedString('registration.duplicate.email.error') });
    });
  }
};

export const asyncValidateCheckEmailForTradeAccount = async (values) => {
  if (!values.get('email')) {
    return null;
  }

  const response = await hasTradeAccount(values.get('email'));
  if (response.hasValidTradeAccount) {
    return new Promise((resolve, reject) => {
      reject({ email: 'This email is already a part of another credit line account.'
      });
    });
  }
};

const isAccountOnHold = (tradeAccount) => {
  const accountUser = tradeAccount && tradeAccount.accountUser;
  const accountRole = accountUser ? accountUser.accountRole : null;
  const accountStatus = tradeAccount && tradeAccount.status;
  return accountRole === ADMIN && accountStatus === ONHOLD;
};

export function * onLoginSuccess () {
  const accountDetails = yield call(fetchAccountDetails);
  const { firstName, customerId, tradeAccount, isPriceWithVat } = accountDetails;

  yield put(receiveAccountDetails(accountDetails, true));
  if (isAccountOnHold(tradeAccount)) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('account.onhold.message'),
        getLocalizedString('account.onhold.subMessage'),
        '/my-account/account-details'
      ), 'top-right', TOASTER_DELAY
    ));
  } else {
    yield put(toastSuccess(
      getToastMessage(
        getLocalizedStringWithParam('login.user.success.message', { userName: firstName }),
        getLocalizedString('login.success.message')
      ), 'top-right', TOASTER_DELAY
    ));
  }

  yield call(displayVATToast, isPriceWithVat);

  const globalQuote = yield select(globalQuotationSelector);
  const quotationId = globalQuote.get('_id');
  if (quotationId) {
    yield put(mergeGlobalQuotes(quotationId));
  }
  yield put(loadCart());
  yield put(getAccountDetails(true));
  yield put(onUserAccountDetailsReceived({ customerId }));
}

export function * watchLoginSuccess () {
  yield takeLatest(RECIEVE_LOGIN_USER_SUCCESS, onLoginSuccess);
}

export function * watchRequestLogin () {
  yield takeLatest(LOGIN_USER, onRequestLogin);
}

export function * watchDoesUserExist () {
  yield takeLatest(DOES_USER_EXIST, onDoesUserExistRequest);
}

export function * onDoesUserExistRequest (action) {
  const { email } = action;
  const userStatus = yield call(doesUserExist, email);
  yield put(setUserExistence(email, userStatus));
  if (!userStatus.registered) {
    yield put(hideLoginDialog());
    yield put(gotoExternalUrl('/register'));
  }
  if (userStatus.registered && !userStatus.hasUserLoggedIn) {
    yield put(updateDataLayer({
      event: 'cromwell_user_email',
      email
    }));
  }
}

export function * onRequestLogin (action) {
  const { email, password, currentPath } = action;
  try {
    yield put(isLoginLoading(true));
    const { token, longSessionToken, hasActiveTradeAccount, forcedPasswordReset } = yield call(loginUser, email, password);
    if (forcedPasswordReset) {
      yield put(hideLoginDialog());
      yield put(gotoExternalUrl(`/reset-password-policy`));
      return;
    }
    yield put(hideLoginDialog());
    const { tradeAccountIsValidForStatement, tradeAccountIsValid } = getTradeAccountDataFromToken(token, longSessionToken);
    const finalDestinationSelector = (state) => state.getIn(['login', 'finalDestination'], null);
    const finalDestination = yield select(finalDestinationSelector);
    if (isMyAccountPageUrl(finalDestination)) {
      yield call(setToken, token, longSessionToken, hasActiveTradeAccount, tradeAccountIsValidForStatement, tradeAccountIsValid);
      yield put(updateJWTToken({ token, longSessionToken, hasActiveTradeAccount, tradeAccountIsValidForStatement, tradeAccountIsValid }));
      yield put(gotoExternalUrl(finalDestination));
      return;
    }
    const actionToDispatchSelector = (state) => state.getIn(['login', 'actionToDispatch'], null);
    const actionToDispatch = yield select(actionToDispatchSelector);
    yield call(setToken, token, longSessionToken, hasActiveTradeAccount, tradeAccountIsValidForStatement, tradeAccountIsValid);
    yield put(updateJWTToken({ token, longSessionToken, hasActiveTradeAccount, tradeAccountIsValidForStatement, tradeAccountIsValid }));
    yield put(loginUserSuccess({ token, longSessionToken, hasActiveTradeAccount, tradeAccountIsValidForStatement, tradeAccountIsValid }, finalDestination, actionToDispatch));
    const finalDialogDestinationSelector = (state) => state.getIn(['ui', 'dialogs', 'login', 'finalDestination'], null);
    const cartPageDestination = yield select(finalDialogDestinationSelector);
    if (CART_ROUTE === currentPath) {
      const cart = yield call(getCart);
      const parcelForceZone = yield select(parcelForceZoneSelector);
      const isEligibleForFreeDelivery = cart?.cartPrice.cartOrderLinesTotalWithVat >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
      const scheduledOrdersFormSettings = yield select(state => state.getIn(['scheduledOrders', 'forms']));
      if (scheduledOrdersFormSettings?.size) {
        yield put(saveScheduledAllOrderlinesSettings());
      }
      if (cartPageDestination === CART_ROUTE) {
        const cartSkus = cart?.orderLines
          ? cart.orderLines.map((p) => ({ sku: p.sku, qty: p.quantity })) : [];
        yield put(showAddToQuotationDialogNonDispatch(cartSkus));
        return;
      }
      const togglesSelector = (state) => state.getIn(['unleash', 'toggles'], List());
      const unleashToggles = yield select(togglesSelector);
      const beforeCheckoutproductCountThreshold = getCheckoutProductCountAbTestThreshold(unleashToggles.toJS(), SHOW);
      const isCheckoutProductCountActive = getCheckoutProductCountAbTestActive(unleashToggles.toJS());
      const isCartLineAboveCountThreshold = cart?.orderLines?.length >= beforeCheckoutproductCountThreshold;

      if ((isEligibleForFreeDelivery && !isCheckoutProductCountActive) || (beforeCheckoutproductCountThreshold && !isCartLineAboveCountThreshold)) {
        yield put(goToUrl(SINGLE_PAGE_CHECKOUT_ROUTE));
      } else {
        yield put(gotoExternalUrl(DELIVERY_CHECKOUT_ROUTE));
      }
      yield put(isLoginLoading(false));
    }
  } catch (err) {
    const errorMessage = err.response?.body?.message;
    if (err?.response?.body?.statusCode === 401 && (errorMessage === 'secret or public key must be provided' || errorMessage === 'invalid signature')) {
      yield put(logoutUser());
      return;
    }
    yield put(loginUserError(buildErrorMessageFromError(err)));
    yield put(isLoginLoading(false));
  }
}

export function * checkShowLoginDialog () {
  yield put(setView({ view: 1 }));
}

export function * watchShowLoginDialog () {
  yield takeLatest(SHOW_LOGIN_DIALOG, checkShowLoginDialog);
}
