import React, { Component } from 'react';
import Slider from 'react-slick';
import { Iterable } from 'immutable';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { getLocalizedString } from 'localization/localizer';
import SvgLoader from 'client/components/svg/SvgLoader.js';

if (process.browser) {
  require('./cmsShoveler.scss');
}

const HiddenArrow = () => <div className="CmsShoveler_VisibleNone" />;

const NoSearchResults = (props) => (
  <div
    className={ `CmsShoveler_NoResults ${_get(props, 'ifEmptyCustomClassName', '')}` }
  >
    { `${getLocalizedString('shoveler.noResults')}"${props.searchQuery}"` }
  </div>
);

const ButtonGroup = ({ next, previous, searchPlaceHolder, heading, onChangeHandler }) => {
  return (
    <div className="CmsShoveler_ButtonsGroup">
      <span className="CmsShoveler_ButtonsGroup_HeadingWrapper">
        <span className="CmsShoveler_ButtonsGroup_Heading">
          <p>{ heading }</p>
        </span>
      </span>
      <span className="CmsShoveler_ButtonsGroup_Arrows">
        <div className="CmsShoveler_ButtonsGroup_Button" onClick={ () => previous() } >
          <SvgLoader xlinkHref="left-arrow-icon"/>
        </div>
        <div className="CmsShoveler_ButtonsGroup_Button" onClick={ () => next() } >
          <SvgLoader className="CmsShoveler_ButtonsGroup_Button-transform" xlinkHref="left-arrow-icon"/>
        </div>
      </span>
    </div>
  );
};

export default class CmsShoveler extends Component {
  constructor (props) {
    super(props);
    this.state = { searchQuery: '' };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.getSettings = this.getSettings.bind(this);
  }

  debounced = _debounce((searchQuery) => {
    this.setState({ searchQuery });
  }, 500)

  onChangeHandler = (e) => {
    e.preventDefault();
    this.debounced(e.target.value);
  }
  next () {
    this.slider.slickNext();
  }

  previous () {
    this.slider.slickPrev();
  }

  getSettings (currentLength) {
    const getSlidesToShow = (defaultLength) => (currentLength < defaultLength) ? currentLength : defaultLength;
    return {
      dots: false,
      infinite: true,
      lazyLoad: true,
      slidesToShow: getSlidesToShow(7),
      slidesToScroll: 2,
      nextArrow: <HiddenArrow />,
      prevArrow: <HiddenArrow />,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: getSlidesToShow(5),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: getSlidesToShow(4),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: getSlidesToShow(3),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 550,
          centerMode: true,
          settings: {
            slidesToShow: getSlidesToShow(2),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          centerMode: true,
          settings: {
            slidesToShow: getSlidesToShow(1),
            slidesToScroll: 1
          }
        }
      ]
    };
  }

  render () {
    const {
      data,
      TileComponent,
      searchPlaceHolder,
      heading,
      ifEmptyCustomClassName
    } = this.props;

    const itemsData = data && Iterable.isIterable(data) ? data.toJS() : data;

    if (itemsData && Array.isArray(itemsData) && itemsData.length) {
      const filteredItems = itemsData
        .filter(i => {
          if (this.state.searchQuery.trim() === '') return true;
          return i.name.search(new RegExp(this.state.searchQuery.trim(), 'i')) >= 0;
        });
      const settings = this.getSettings(filteredItems.length);
      return (
        <div className="CmsShoveler">
          <ButtonGroup
            heading={ heading }
            searchPlaceHolder={ searchPlaceHolder }
            onChangeHandler={ this.onChangeHandler }
            next={ this.next }
            previous={ this.previous }
          />
          <Slider ref={ (c) => (this.slider = c) } { ...settings }>
            {
              filteredItems.length
                ? filteredItems.map(
                  (item) => <TileComponent key={ item.name } item={ item } />
                )
                : <NoSearchResults
                  searchQuery={ this.state.searchQuery }
                  ifEmptyCustomClassName={ ifEmptyCustomClassName } />
            }
          </Slider>
        </div>
      );
    }
    console.error('Error: data error in cmsJumboCarousel; no carousel displayed!'); // eslint-disable-line no-console
    return null;
  }
}
