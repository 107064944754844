export const NO_OF_SEARCH_RESULTS = 20;
export const GRID = 'grid';
export const LIST = 'list';
export const PAGE_ZERO = 0;
export const NEXT = 'next';
export const PREVIOUS = 'previous';
export const SORT_BY_PRICE_DESC = 'sortByPriceDesc';
export const SORT_BY_PRICE_ASC = 'sortByPriceAsc';
export const SHOP = '/shop';
export const queryParams = [SHOP, `${SHOP}/`];
export const PAGE_NOT_FOUND = 'page-not-found';
export const CATEGORIES_MAX_LABELS_SHOWSHADOW = 5;
export const SEARCH_RESULT_VIEW_TYPE_DEFAULT = 'product';

export const sortOptions = [
  { name: 'Relevance', value: 'sortByRelevence' },
  { name: 'Price: Low to High', value: 'sortByPriceAsc' },
  { name: 'Price: High to Low', value: 'sortByPriceDesc' },
  { name: 'Customer Rating', value: 'sortByReviews' }
];

export const TWENTY_SEARCH_RESULTS = 20;
export const SIXTY_SEARCH_RESULTS = 60;
export const ONE_HUNDRED_SEARCH_RESULTS = 100;
export const noOfSearchResultOptions = [
  { name: '20', value: TWENTY_SEARCH_RESULTS },
  { name: '60', value: SIXTY_SEARCH_RESULTS },
  { name: '100', value: ONE_HUNDRED_SEARCH_RESULTS }
];

export const CATEGORY = 'category';
export const INDUSTRY_ID = 'industryId';
export const SORT_BY = 'sortBy';
export const AUTO_CORRECTED_QUERY = 'autoCorrectQuery';
export const UNBRANDED = '(Unbranded)';
export const NUMBER_OF_GRID_ROWS = 4;
export const EX_FAMILIES = 'exfam';
export const OFFSET = 'offset';

// filterList const starts here
export const WITHOUT_SEARCH_BAR_LIMIT = 5;
export const NO_OPTION_MESSAGE = 'No Results Found';
export const RATING = 'Rating';
export const SEARCH = 'Search';
export const DESELECT_OPTION = 'deselect-option';
export const SELECT_OPTION = 'select-option';
export const MAX_MENU_HEIGHT = 150;
export const PAGE_SIZE = 3;
export const MAX_STARS_RATING = 5;

export const SELECTED = 'selected';
export const UNSELECTED = 'unSelected';
