import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import {
  getLocalizedString
} from 'localization/localizer';
import { FREE_DELIVERY_THRESHOLD, FREE_DELIVERY_LOYALTY_TIERS } from 'shared/constants/common';

if (process.browser) {
  require('./BottomPanel.scss');
}

class BottomPanel extends React.Component {
  getColumns () {
    const {
      isMobile,
      loyaltyTier
    } = this.props;
    const hasTPPFreeDelivery = FREE_DELIVERY_LOYALTY_TIERS.includes(loyaltyTier);
    return [
      {
        xlinkHref: '#freedelivery-icon',
        label: isMobile ? `Free Shipping over £${FREE_DELIVERY_THRESHOLD}` : 'Free Shipping',
        text: hasTPPFreeDelivery ? getLocalizedString('label.free.delivery.all.orders') : getLocalizedString('footer.freeShipping.text'),
        url: '/info/delivery',
        className: 'freedelivery-icon'
      },
      {
        xlinkHref: '#customer-service-icon',
        label: 'Customer Support',
        text: getLocalizedString('footer.customerSupport.text'),
        url: '/contact-us',
        className: 'customer-service-icon'
      },
      {
        xlinkHref: '#return-icon',
        label: 'Returns',
        text: getLocalizedString('footer.returns.text'),
        url: '/info/returns',
        className: 'quality-icon'
      }
    ];
  }

  render () {
    const columns = this.getColumns();

    return (
      <div className="BottomPanelContainer">
        <div className="BottomPanel">
          {
            Object.keys(columns).map(function (key) {
              const column = columns[key];
              return (
                <div key={ key } className={ 'BottomPanel_column' }>
                  <a href={ column.url }>
                    <div className="BottomPanel_Container">
                      <SvgLoader xlinkHref={ column.xlinkHref } className={ column.className } />
                      <div className="BottomPanel_Title">{ column.label }</div>
                      <div className="BottomPanel_Text"><p>{ column.text }</p></div>
                    </div>
                  </a>
                </div>);
            })
          }
        </div>
      </div>
    );
  }
}

export default BottomPanel;
