import { fromJS } from 'immutable';
import {
  ADD_PRODUCT_LINE_ITEMS,
  PRODUCT_LINE_UPDATING_QUANTITY,
  CLEAR_PRODUCT_LINES,
  PRODUCT_LINE_UPDATE_QUANTITY
} from 'client/actions/productLineActions';
import { REMOVE_CART_SCHEDULED_ORDERS_SETTINGS, REMOVE_SCHEDULED_ORDER_SETTINGS, SET_PRODUCT_LINE_SCHEDULED_ORDER } from 'client/actions/scheduledOrders';

const defaultState = fromJS({});

const productLineReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_LINES:
      return defaultState;
    case ADD_PRODUCT_LINE_ITEMS:
      if (state.size) {
        return fromJS({
          ...state.toJS(),
          ...action.payload
        });
      }
      return fromJS(action.payload);
    case PRODUCT_LINE_UPDATING_QUANTITY:
      return state.setIn([action.sku, 'isUpdatingQuantity'], action.flag);
    case PRODUCT_LINE_UPDATE_QUANTITY:
      return state.setIn([action.sku, 'quantity'], action.qty);
    case REMOVE_CART_SCHEDULED_ORDERS_SETTINGS: {
      return state.map(orderLine => {
        return orderLine.delete('scheduledOrder');
      });
    }
    case REMOVE_SCHEDULED_ORDER_SETTINGS: {
      return state.deleteIn([action.payload.formId, 'scheduledOrder']);
    }
    case SET_PRODUCT_LINE_SCHEDULED_ORDER: {
      return state.setIn([action.payload.sku, 'scheduledOrder'], action.payload.scheduledOrder);
    }
    default:
      return state;
  }
};

export default productLineReducer;
