import { fromJS } from 'immutable';
import { SET_DEFAULT_CARD, SET_SELECTED_CARD } from './payByExistingCardActions';

const defaultState = fromJS({});

const payByExistingCard = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SELECTED_CARD:
      return state.setIn([action.payload.selector, 'selectedCard'], fromJS(action.payload.selectedCard));
    case SET_DEFAULT_CARD:
      return state.setIn([action.payload.selector, 'selectedCard', 'isDefault'], fromJS(action.payload.isDefault));
    default:
      return state;
  }
};

export default payByExistingCard;
