import React from 'react';
import { connect } from 'react-redux';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { showMaskCallDialog } from 'client/actions/ui/dialogActions';
import { PrimaryButton } from 'client/components/controls/StyledForms';
import { MASK_CALL_DIALOG_VARIANTS } from '../PaymentMethod/constants';
import { ORDER_REVIEW_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';
import { subscribeByNewCardForExistingSubscription } from 'client/components/elements/paymentMethod/PayByNewCard/payByNewCardActions';
import {
  EXISTING_SUBSCRIPTION_SELECTOR,
  PROCESSING
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

const ContinueButton = ({
  continueDisabled,
  text,
  selector,
  saveNewCardStatus,
  subscriptionId,
  setActivePanelId,
  subscribeByNewCardForExistingSubscription,
  showMaskCallDialog,
  csAgentName,
  scrollToInValidField
}) => {
  if (saveNewCardStatus === PROCESSING) {
    return (
      <div className="PayByNewCard_spinner"/>
    );
  }
  return (
    <PrimaryButton
      data-e2e="PayByNewCard_continue_button"
      className="PayByNewCard_button"
      text={ text }
      onClick={ () => {
        if (!continueDisabled) {
          if (selector === EXISTING_SUBSCRIPTION_SELECTOR) {
            subscribeByNewCardForExistingSubscription({ selector, subscriptionId });
          } else {
            csAgentName && showMaskCallDialog({ variant: MASK_CALL_DIALOG_VARIANTS.UNMASK });
            setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID });
          }
        } else {
          scrollToInValidField();
        }
      } } />
  );
};

const mapDispatchToProps = {
  setActivePanelId,
  showMaskCallDialog,
  subscribeByNewCardForExistingSubscription
};
const mapStateToProps = (state) => ({ csAgentName: state.getIn(['customerService', 'csAgentName']) });

export default connect(mapStateToProps, mapDispatchToProps)(ContinueButton);
