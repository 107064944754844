import { fromJS } from 'immutable';
import {
  SET_CART_LEVEL_FORM_STATUS,
  SET_CONFIRMATION_SCREEN_SUBSCRIPTION_PAYMENT,
  SET_INVALID_SUBSCRIPTION_PAYMENT_METHOD,
  SAVE_SCHEDULED_ORDER_FORM_SETTINGS,
  REMOVE_SCHEDULED_ORDER_SETTINGS,
  REMOVE_CART_SCHEDULED_ORDERS_SETTINGS,
  SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS,
  SET_IS_LOADING_CREATE_SUBSCRIPTION_BUTTON
} from 'client/actions/scheduledOrders';

const defaultState = fromJS({});

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_CART_LEVEL_FORM_STATUS:
      return state.set('cartLevelFormStatus', payload.cartLevelFormStatus);
    case SET_INVALID_SUBSCRIPTION_PAYMENT_METHOD:
      return state.set('invalidSubscriptionPaymentMethod', payload.paymentMethod);
    case SET_CONFIRMATION_SCREEN_SUBSCRIPTION_PAYMENT:
      return state.set('paymentMethod', payload.paymentMethod);
    case SAVE_SCHEDULED_ORDER_CART_FORM_SETTINGS:
      return state.setIn(['forms', 'cart'], fromJS(payload.settings));
    case SAVE_SCHEDULED_ORDER_FORM_SETTINGS:
      return state.setIn(['forms', payload.sku], fromJS(payload.settings));
    case REMOVE_SCHEDULED_ORDER_SETTINGS:
      return state.removeIn(['forms', payload.formId]).removeIn(['forms', 'cart']);
    case REMOVE_CART_SCHEDULED_ORDERS_SETTINGS: {
      const createdSubscriptions = state.get('forms').filter(form => form.has('_id'));
      return state.set('forms', createdSubscriptions);
    }
    case SET_IS_LOADING_CREATE_SUBSCRIPTION_BUTTON:
      return state.setIn(['loadingCreateSubscription', payload.formId], payload.isLoading);
    default:
      return state;
  }
};
