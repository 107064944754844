import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { formatPrice } from 'client/utils/priceUtils';
import { Body } from '@graingerglobalonline/curly-eureka';

export const RecommendationFamilyPrice = ({
  familyPrice,
  priceVatTagLabel
}) => (
  <div className="familyPrice">
    <div>{getLocalizedString('family.priceFrom')}</div>
    <div className="RecommendationTile_FamilyAmount">
      <strong>{`${formatPrice('GBP', familyPrice).symbol}${
        formatPrice('GBP', familyPrice).amount
      }`}</strong>
      <Body variant="xSmall"> {` ${priceVatTagLabel}`}</Body>
    </div>
  </div>
);
