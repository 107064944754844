import PropTypes from 'prop-types';
import React from 'react';

const FormCheckBox = (props) => {
  const {
    input,
    datae2e,
    className,
    labelClassName,
    textAndLink,
    small,
    meta: { touched, error }
  } = props;
  return (
    <div className={ `form-group ${touched && error && 'has-error'}` }>
      <div className="checkbox">
        <label className={ labelClassName }>
          <input { ...input }
            type="checkbox"
            checked={ input?.value }
            className={ className }
            data-e2e={ datae2e }/>
          { small && <small></small>}
          { textAndLink }
        </label>
      </div>
      {
        touched && error &&
            <div className="help-block">
              <div data-e2e={ input.name + 'Error' }>{ error }</div>
            </div>
      }
    </div>);
};

FormCheckBox.propTypes = {
  input: PropTypes.object.isRequired,
  datae2e: PropTypes.string.isRequired,
  textAndLink: PropTypes.element.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default FormCheckBox;
