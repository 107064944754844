import {
  makeValidator,
  isRequired,
  minValue
} from 'client/utils/validators';
import { getLocalizedString } from 'localization/localizer';
import { validateForm } from 'client/utils/formValidation';
import { SubmissionError } from 'redux-form/immutable';
import { fromJS } from 'immutable';

const getCreateNewSubscriptionValidators = () => {
  const validators = {};

  validators.subscriptionProductQuantity = [
    isRequired(getLocalizedString('subscriptionForm.label.productQuantity')),
    minValue(1)
  ];

  validators.orderReference = [
    isRequired({
      field: getLocalizedString('createNewOrderForm.label.subscriptionName'),
      message: getLocalizedString('subscription.fieldError.subscriptionName')
    })
  ];

  validators.outOfStockDeliveryPreference = [
    isRequired({
      field: getLocalizedString('createNewOrderForm.label.outOfStockDeliveryPreference'),
      message: getLocalizedString('subscription.fieldError.outOfStockDeliveryPreference')
    })
  ];

  validators.regularity = [
    isRequired({
      field: getLocalizedString('createNewOrderForm.label.regularity'),
      message: getLocalizedString('subscription.fieldError.regularity')
    })
  ];

  validators.startDate = [
    isRequired({
      field: getLocalizedString('createNewOrderForm.label.startDate'),
      message: getLocalizedString('subscription.fieldError.startDate')
    })
  ];

  return makeValidator(validators);
};

const getDeliveryAddressFormValidators = () => {
  const validators = {};
  validators.deliveryAddressId = [
    isRequired({
      field: getLocalizedString('createNewOrderForm.label.deliveryAddressId'),
      message: getLocalizedString('subscription.fieldError.deliveryAddressId')
    })
  ];

  return makeValidator(validators);
};

const updateNewRepeatOrderDetails = (createNewSubscriptionNextStep, updatedValues, newRepeatOrderDetails, sku, name, price) => {
  createNewSubscriptionNextStep();
  const { subscriptionProductQuantity, orderReference, regularity, startDate, endDate, deliveryAddressId, outOfStockDeliveryPreference } = updatedValues.toJS();
  return newRepeatOrderDetails(sku, subscriptionProductQuantity, orderReference, regularity, startDate, endDate, deliveryAddressId, outOfStockDeliveryPreference, name, price);
};

export const getFormSubmit = (createNewSubscriptionNextStep, newRepeatOrderDetails, sku, price, quantity, name, formType) => {
  return (values, dispatch) => {
    const { ...rest } = values.toJS();
    rest.subscriptionProductQuantity = quantity;
    const updatedValues = fromJS(rest);
    const validate = formType === 'delivery' ? getDeliveryAddressFormValidators() : getCreateNewSubscriptionValidators();
    return validateForm(updatedValues, validate)
      .then(() => updateNewRepeatOrderDetails(createNewSubscriptionNextStep, updatedValues, newRepeatOrderDetails, sku, name, price))
      .catch((errors) => {
        throw new SubmissionError(errors);
      });
  };
};
