import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./FieldInputStyles.scss');
}

const FormField = (props) => {
  const {
    input,
    type,
    placeholderResourceName,
    className,
    label,
    labelResourceName,
    labelClassName,
    datae2e,
    tooltip,
    id,
    autoComplete,
    autoFocus,
    formClassName,
    disabled,
    min,
    rows,
    maxLength = 5000,
    cols,
    isShowLength = true,
    meta: { touched, error, active, visited }
  } = props;

  return (
    <div className={ `form-group FieldTextArea ${formClassName ? formClassName : ''} ${(touched && error) ? 'has-error' : ''}` }>
      { id && <label htmlFor={ id } className={ labelClassName }>{ labelResourceName ? getLocalizedString(labelResourceName) : label }</label> }
      { tooltip ? tooltip : null }
      <div className={ `CounterContainer ${active ? 'active' : 'disabled'} ${error && visited ? 'error' : ''} ` }>
        <textarea { ...input }
          disabled={ disabled }
          min={ min }
          rows={ rows }
          cols={ cols }
          type={ type }
          className={ className }
          placeholder={ placeholderResourceName ? getLocalizedString(placeholderResourceName) : null }
          autoComplete={ autoComplete || 'on' }
          autoFocus={ autoFocus }
          data-e2e={ datae2e }/>
        { isShowLength ? <div className={ `counter ${input.value.length > maxLength ? 'error' : ''}` }>{input.value.length} / {maxLength}</div> : null }
      </div>
      {
        touched && error &&
        <div className="help-block">
          <div data-e2e={ input.name + 'Error' }>{ error }</div>
        </div>
      }
    </div>
  );
};

FormField.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholderResourceName: PropTypes.string,
  datae2e: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelResourceName: PropTypes.string,
  tooltip: PropTypes.object,
  formClassName: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};
export default FormField;
