import React from 'react';
import { connect } from 'react-redux';
import { ToggleButton } from 'client/components/elements/toggleButton/toggleButton';
import { onToggleGlobalVat } from 'client/actions/userActions';
import flowRight from 'lodash/flowRight';
import { fromJS } from 'immutable';
import { getLocalizedString } from 'localization/localizer';
import { toastSuccess } from 'client/actions/showNotificationsActions';
import QuickOrderDialog from '../QuickOrderDialog/QuickOrderDialog';
import GlobalQuotationButton from 'client/components/elements/GlobalQuotationButton/GlobalQuotationButton';
import { clearAddedProductToQuotation } from 'client/actions/globalQuotationActions';
import { VAT_TOGGLE } from 'shared/constants/unleash';
import ConnectedUnleashWrapper from 'client/components/elements/unleashABTest/ConnectedUnleashWrapper';
import SvgLoader from '../../../../svg/SvgLoader';

if (process.browser) {
  require('./InfoRibbon.scss');
}

export const InfoRibbon = (props) => {
  const { changeGlobalVATState, isPriceWithVat } = props;
  const shouldQuotesClear = props.lockedQuotes.toJS().includes(props.quoteRefId);
  return (
    <div className="infoRibbon hidden-sm">
      <div className="container-fluid infoRibbonBackground">
        <div className="container infoRibbonContainer">
          <div className="row infoRibbonLinks">
            <div className="text-center infoRibbonBorder infoRibbonLinks_quotation">
              <GlobalQuotationButton
                shouldQuotesClear={ shouldQuotesClear }
                clearQuote={ props.clearQuote }
                quoteRef={ props.quoteRef }
                numberOfQuotationProducts={ props.numberOfQuotationProducts } />
            </div>
            <div className="text-center infoRibbonBorder infoRibbonLinks_quotation">
              <a className="globalQuotationButton" href="/favourites" data-e2e="favourites-button">
                <SvgLoader xlinkHref="#favourites-header-icon" className="infoRibbonLinks_favourite_icon" />
                <span className="infoRibbonLinks_favourite_text">Favourites</span>
              </a>
            </div>
            <div className="text-center infoRibbonBorder infoRibbonLinks_quickOrder">
              <QuickOrderDialog />
            </div>
            <div className="text-center infoRibbonBorder infoRibbonLinks_quotation">
              <a className="globalQuotationButton" href="/help-support" data-e2e="help-support">
                <SvgLoader xlinkHref="#customer-service-icon" className="infoRibbonLinks_helpIcon" />
                <span className="infoRibbonLinks_favourite_text">Help & Support</span>
              </a>
            </div>
            <div className="infoRibbonFastQuotes infoRibbonLinks_toggle">
              <span className="infoRibbonQuoteBold">{ getLocalizedString('header.label.showPrices') }:</span>
              <span className="infoRibbonIncVATSmall">{ getLocalizedString('excVat') }</span>
              {props.isVatPreferenceFromUnleash ? <ConnectedUnleashWrapper flag={ VAT_TOGGLE } /> : null}
              <ToggleButton
                id='globalIncExcVAT'
                name='globalIncExcVAT'
                checked={ isPriceWithVat }
                showLabel={ false }
                onChange={ () => changeGlobalVATState(isPriceWithVat) }
              />
              <span className="infoRibbonExcVATSmall">{ getLocalizedString('incVat') }</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isPriceWithVat: state.getIn(['user', 'isPriceWithVat']),
    lockedQuotes: state.getIn(['globalQuotation', 'lockedQuotes'], fromJS([])),
    quoteRefId: state.getIn(['globalQuotation', 'createdQuotation', '_id'], ''),
    quoteRef: state.getIn(['globalQuotation', 'createdQuotation', 'quoteRef']),
    numberOfQuotationProducts: state.getIn(['globalQuotation', 'createdQuotation', 'products'], fromJS([])),
    isVatPreferenceFromUnleash: state.getIn(['user', 'isVatPreferenceFromUnleash'], false)
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearQuote: () => dispatch(clearAddedProductToQuotation()),
  changeGlobalVATState: flowRight(dispatch, onToggleGlobalVat),
  toastSuccess: flowRight(dispatch, toastSuccess)
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoRibbon);
