import React, { Fragment } from 'react';
import { PENDING, SUCCESS, ERROR } from 'shared/constants/loadStateType';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import { AddressDisplay } from 'client/components/screens/checkoutScreen/element/addressDisplay/AddressDisplay';
import { MyAddressBook } from 'client/components/screens/checkoutScreen/element/addressBook/MyAddressBook';
import { getLocalizedString } from 'localization/localizer';
import AddressForm from 'client/components/screens/checkoutScreen/element/addressForm/AddressForm';
import { PrimaryButton } from 'client/components/controls/StyledForms';
import { fromJS, Map } from 'immutable';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { DELIVERY_ADDRESS_FORM_NAME } from 'shared/constants/singlePageCheckout';
import SvgLoader from 'client/components/svg/SvgLoader';
import { displayFarFlungDelvMessage } from 'client/components/elements/postcodeInput/postcodeInput';
import { isValidTradeAccount } from 'client/utils/myAccountUtils';
import { ADMIN } from 'shared/constants/account';
import { AgeRestrictedUnableToDeliverBadge } from 'client/components/elements/AgeRestrictedUnableToDeliverBadge/AgeRestrictedUnableToDeliverBadge';
import { BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, LEGACY_BUSINESS_ADDRESS } from 'shared/constants/businessAddressStatus';

const showSelectedAddress = (deliveryAddress) => {
  return <AddressDisplay address={ deliveryAddress ? deliveryAddress.toJS() : Map() }/>;
};

const showNonDeliverableMessage = () => {
  const nonDeliverableMessage = `${getLocalizedString('singlePageCheckout.non.deliverable.address.text.1')} ${getLocalizedString('singlePageCheckout.non.deliverable.product.text.2')}`;
  return (
    <div
      data-e2e="deliveryDetails-nonDeliverableMessage"
      className="CheckoutPage_DeliveryDetailsPanel_nonDeliverable">
      <SvgLoader xlinkHref="yellow-alert" width="60" />
      <span>{nonDeliverableMessage}</span>
    </div>
  );
};

const onPoReferenceChange = (e, setPoReference) => {
  setPoReference(e.target.value);
};

const showEditDeliveryAddressLink = (deliveryAddressId, onEditDeliveryAddressClick) => {
  return (
    <div className="CheckoutPage_DeliveryDetailsPanel_EditDeliveryAddress row">
      <div className="col-md-8">
        <span className="CheckoutPage_DeliveryDetailsPanel_EditDeliveryAddress_Title" onClick={ () => { onEditDeliveryAddressClick(deliveryAddressId); } }>{getLocalizedString('singlePageCheckout.editDeliveryAddress')}</span>
      </div>
    </div>
  );
};

export const AddressList = ({ addressList, onChangeDeliveryAddressFromAddressListDropDown, loadCart, fetchQuotationDetails, fromQuotationId }) => {
  const shouldShowAddressList = addressList && addressList.size >= 1;
  const handleDeliveryAddressChange = (addressId) => {
    const eligibleForDeliveryOptions = false;
    fromQuotationId && fetchQuotationDetails(fromQuotationId, eligibleForDeliveryOptions, addressId);
    onChangeDeliveryAddressFromAddressListDropDown(addressId);
    loadCart({ addressId });
  };

  return shouldShowAddressList ? (
    <div className="CheckoutPage_DeliveryDetailsPanel_AddressList row">
      <div className="form-group CheckoutPage_DeliveryDetailsPanel_AddressList_Form col-md-8">
        <div className="CheckoutPage_DeliveryDetailsPanel_AddressList_Form_ChooseAnotherAddress">{getLocalizedString('singlePageCheckout.chooseAnotherAddress')}</div>
        <div className="CheckoutPage_DeliveryDetailsPanel_AddressList_Form_ChooseAnotherAddress_DropdownButton">
          <ButtonGroup justified>
            <DropdownButton
              title= { <span className="CheckoutPage_DeliveryDetailsPanel_AddressList_Form_ChooseAnotherAddress_DropdownButton-text">{ getLocalizedString('singlePageCheckout.selectAnotherAddress') }</span> }
            >
              {
                <MenuItem disabled>
                  <span className="CheckoutPage_DeliveryDetailsPanel_AddressList_Form_ChooseAnotherAddress_DropdownButton-options">
                    { getLocalizedString('singlePageCheckout.selectAnotherAddress') }
                  </span>
                </MenuItem>
              }
              {
                addressList.map((address, index) => {
                  return (<MenuItem
                    key={ index }
                    eventKey={ address.get('addressId') }
                    value={ address.get('addressId') }
                    data-e2e={ address.get('addressId') }
                    onSelect={ () => handleDeliveryAddressChange(address.get('addressId')) }
                  >
                    <span className="CheckoutPage_DeliveryDetailsPanel_AddressList_Form_ChooseAnotherAddress_DropdownButton-options">
                      { address.get('addressNickName') }
                    </span>
                  </MenuItem>);
                })
              }
            </DropdownButton>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
    : null;
};

const showAddressBookLink = (onDisplayAddressBookClick) => {
  return (
    <div className="CheckoutPage_DeliveryDetailsPanel_AddressBook row">
      <div className="col-md-8">
        <span className="CheckoutPage_DeliveryDetailsPanel_AddressBook_Title" onClick={ () => onDisplayAddressBookClick() }>{ getLocalizedString('myAccount.addressBook.viewAddressBook') }</span>
      </div>
    </div>
  );
};

const showPoReferenceBox = (setPoReference, poReference) => {
  return (
    <div className="CheckoutPage_DeliveryDetailsPanel_POReference row">
      <div className="CheckoutPage_DeliveryDetailsPanel_POReference_Title col-md-8">
        <div className="CheckoutPage_DeliveryDetailsPanel_POReference_Title_Main">{getLocalizedString('addressForm.title.orderReference')}
          <span className="CheckoutPage_DeliveryDetailsPanel_POReference_Title_Optional displayInline">{getLocalizedString('addressForm.title.orderReference.optional')}</span>
        </div>

        <div className="CheckoutPage_DeliveryDetailsPanel_POReference_Title_InputBox">
          <input type="text" className="form-control"
            value={ poReference }
            maxLength="40"
            onChange={ (e) => onPoReferenceChange(e, setPoReference) }
            data-e2e="poReference"
            placeholder={ getLocalizedString('order.yourReference.placeholder') }
          />
        </div>
      </div>
    </div>
  );
};

export const DeliveryDetailsPanel = (props) => {
  const {
    accountDetails,
    deliveryAddress = Map(),
    showDeliveryAddressForm,
    isEditingDeliveryAddress,
    showCancelButtonOnAddressForm,
    deliveryIdBeingEdited,
    addressList,
    onSelectNewAddress,
    onConfirmDeliveryAddress,
    onBackToDeliveryPanel,
    cancelEditAddresss,
    saveAddresss,
    selectedAddressIdForDelivery,
    onChangeDeliveryAddressFromAddressListDropDown,
    showMyAddressBook,
    addressBookViewType,
    onDisplayAddressBookClick,
    onEditDeliveryAddressClick,
    setPoReference,
    poReference,
    deliveryPanelLoadState,
    handleAddNewAddressClick,
    changeAddressBookViewType,
    proceedToNextStage,
    activePanelId,
    validNonDeliverableStatus,
    loadCart,
    fetchQuotationDetails,
    quotationDetails,
    fromQuotationId,
    cart
  } = props;
  const cartOrQuotation = fromQuotationId ? quotationDetails : cart;
  const hasAgeRestrictedProducts = cartOrQuotation?.get('orderLines').some(orderLine => orderLine.get('ageRestricted'));
  const tradeAccount = accountDetails.get('tradeAccount');
  const isTradeAccount = Object.keys(tradeAccount || {}).length > 0;
  const isAllowedBusinessAddress = [BUSINESS_ADDRESS_NEW, BUSINESS_ADDRESS_APPROVED, ...LEGACY_BUSINESS_ADDRESS].includes(deliveryAddress?.get('businessAddressApprovalStatus'));
  const showAgeRestrictedBadge = hasAgeRestrictedProducts && isTradeAccount && !isAllowedBusinessAddress;
  let content;
  const deliveryZone = deliveryAddress?.get('parcelForceZone');
  const user = accountDetails.toJS();
  const isValidBusinessAccount = isValidTradeAccount(user?.tradeAccount);
  const loggedInUserCustomerId = user?.customerId || '';
  const isAccountAdmin = user?.tradeAccount?.accountUser.accountRole === ADMIN;
  const isValidBAWithAdminRole = isValidBusinessAccount && isAccountAdmin;
  const isLoggedInUserAddress = deliveryAddress?.get('customerId') === loggedInUserCustomerId;
  const showEditAction = isLoggedInUserAddress || isValidBAWithAdminRole;

  const getPrePopulatedAddress = () => {
    return deliveryIdBeingEdited
      ? addressList.find(l => l.get('addressId') === deliveryIdBeingEdited) || Map()
      : fromJS({
        firstName: accountDetails.get('firstName'),
        lastName: accountDetails.get('lastName'),
        companyName: accountDetails.get('companyName'),
        addressPhoneNumber: accountDetails.getIn(['phoneNumbers', 'mobile'])
      });
  };
  const newOrEditAddressFormLayout = () => {
    const address = getPrePopulatedAddress().toJS();
    return (<AddressForm
      form={ DELIVERY_ADDRESS_FORM_NAME }
      formName={ DELIVERY_ADDRESS_FORM_NAME }
      address={ address }
      isEditingAddress={ isEditingDeliveryAddress }
      showCancelButtonOnAddressForm={ showCancelButtonOnAddressForm }
      addressIdBeingEdited={ deliveryIdBeingEdited }
      handleBackButtonClick={ cancelEditAddresss }
      onAddressSubmit={ saveAddresss }
    />);
  };

  const defaultLayout = () => {
    return (
      <Fragment>
        { validNonDeliverableStatus ? showNonDeliverableMessage() : null }
        <div className="CheckoutPage_DeliveryDetailsPanel row">
          {
            (deliveryZone === 2 || deliveryZone === 3) ? displayFarFlungDelvMessage() : null
          }
          <div className="col-md-5">
            { showSelectedAddress(deliveryAddress) }
          </div>
          <div className="col-md-7">
            { deliveryAddress && showEditAction && showEditDeliveryAddressLink(deliveryAddress.get('addressId'), onEditDeliveryAddressClick)}
            { showAddressBookLink(onDisplayAddressBookClick) }
            { <AddressList addressList= { addressList } onChangeDeliveryAddressFromAddressListDropDown ={ onChangeDeliveryAddressFromAddressListDropDown } loadCart = { loadCart } fetchQuotationDetails = { fetchQuotationDetails } fromQuotationId = { fromQuotationId } /> }
            { showPoReferenceBox(setPoReference, poReference) }
          </div>
        </div>
        {showAgeRestrictedBadge && <AgeRestrictedUnableToDeliverBadge />}
        <div className="CheckoutPage_DeliveryDetailsPanel_cta row">
          <PrimaryButton
            className={ `CheckoutPage_DeliveryDetailsPanel_cta_proceedToNextStage ${showAgeRestrictedBadge ? 'button-disabled' : ''}` }
            onClick={ () => proceedToNextStage(activePanelId) } text={ getLocalizedString('singlePageCheckout.proceedToPayment') }
            datae2e="proceedToNextStage"
            disabled={ showAgeRestrictedBadge }
          />
        </div>
      </Fragment>
    );
  };

  const showAddressBookLayout = () => {
    return (<MyAddressBook
      addressList={ addressList }
      addressBookViewType={ addressBookViewType }
      defaultDeliveryId={ deliveryAddress.get('addressId') }
      onSelectNewAddress={ onSelectNewAddress }
      selectedAddressIdForDelivery={ selectedAddressIdForDelivery }
      onConfirmDeliveryAddress={ onConfirmDeliveryAddress }
      onBackToDeliveryPanel={ onBackToDeliveryPanel }
      handleAddNewAddressClick={ handleAddNewAddressClick }
      onEditDeliveryAddressClick={ onEditDeliveryAddressClick }
      changeAddressBookViewType={ changeAddressBookViewType }
      isValidBAWithAdminRole={ isValidBAWithAdminRole }
      loggedInUserCustomerId={ loggedInUserCustomerId }
    ></MyAddressBook>);
  };

  const existingAddressLayout = (showMyAddressBook) => {
    const existingAddressLayoutMap = {
      'showAddressBookLayout': showAddressBookLayout,
      'defaultLayout': defaultLayout
    };

    return showMyAddressBook
      ? existingAddressLayoutMap.showAddressBookLayout()
      : existingAddressLayoutMap.defaultLayout();
  };

  const layoutMap = {
    'newAddressFormLayout': newOrEditAddressFormLayout,
    'existingAddressFormLayout': existingAddressLayout
  };
  const displayDeliveryAddress = () => {
    return showDeliveryAddressForm
      ? layoutMap.newAddressFormLayout(isEditingDeliveryAddress)
      : layoutMap.existingAddressFormLayout(showMyAddressBook);
  };
  const getDeliveryDetailsLayout = () => {
    return (
      <React.Fragment>
        { displayDeliveryAddress() }
      </React.Fragment>
    );
  };

  switch (deliveryPanelLoadState) {
    case PENDING:
      content = <ScreenLoadPending className="smallLoader" />;
      break;
    case SUCCESS:
      content = getDeliveryDetailsLayout();
      break;
    // To Do: common error page?
    case ERROR:
      content = <ScreenLoadPending location={ location } />;
      break;
  }

  return (
    <React.Fragment>
      { content }
    </React.Fragment>
  );
};
