import React, { useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import LoginForm from 'client/components/elements/LoginAndRegistrationForm/loginForm/LoginForm';
import {
  hideLoginDialog,
  showNewLoginExitConfirmationDialog,
  hideNewLoginExitConfirmationDialog
} from 'client/actions/ui/dialogActions';
import {
  LOGIN_VIEW,
  CREATE_INDIVIDUAL_ACCOUNT_VIEW,
  WELCOME_ACCOUNT_VIEW,
  FORGOT_PASSWORD_VIEW,
  CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW
} from 'client/components/elements/LoginAndRegistrationForm/constants';
import SvgLoader from 'client/components/svg/SvgLoader';
import { setView, updateAdditionalUsersList } from 'client/actions/loginAndRegistration';
import WelcomeToZoroForm from 'client/components/elements/LoginAndRegistrationForm/welcomeToZoro/welcomeToZoroForm';
import AboutYouForm from 'client/components/elements/LoginAndRegistrationForm/individualAccountRegistration/AboutYouForm';
import BusinessRegistration from 'client/components/elements/LoginAndRegistrationForm/businessAccountRegistration';
import ForgotPasswordForm from 'client/components/elements/LoginAndRegistrationForm/forgotPasswordForm/ForgotPasswordForm';
import { destroy } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./LoginDialog.scss');
}

const mapStateToProps = (state) => {
  return {
    isLoginDialogVisible: state.getIn(['ui', 'dialogs', 'login', 'isVisible']),
    isExitConfirmationVisible: state.getIn(['ui', 'dialogs', 'newLoginExitConfirmation', 'isVisible'], false),
    view: state.getIn(['loginAndRegistration', 'view'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  showExitConfirmation: () => dispatch(showNewLoginExitConfirmationDialog()),
  hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog()),
  hideLoginDialog: () => dispatch(hideLoginDialog()),
  dispatchSetView: (view) => dispatch(setView({ view })),
  onDestroyForm: (formName) => dispatch(destroy(formName)),
  dispatchUpdateAdditionalUsersList: (list) => dispatch(updateAdditionalUsersList(list))
});

const listOfStatusesForNotClosingTheModal = [
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED
];

const useOutsideAlerter = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const OutsideAlerter = ({ onClickOutside, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOutside);

  return <div ref={ wrapperRef }>{children}</div>;
};

const ExitConfirmation = ({ hideExitConfirmation, closeModal }) => {
  return <div className="ExitConfirmation">
    <div className="ExitConfirmation_triangle" />
    <div className="ExitConfirmation_text">
      Your registration is incomplete, are you sure you want to leave?
    </div>
    <div className="ExitConfirmation_actions">
      <SecondaryButton
        datae2e="yes"
        className="ExitConfirmation_yes"
        onClick={ () => closeModal() }>
        Yes
      </SecondaryButton>
      <PrimaryButton
        datae2e="no"
        className="ExitConfirmation_no"
        onClick={ () => hideExitConfirmation() }>
        No
      </PrimaryButton>
    </div>
  </div>;
};

const LoginAndRegistrationDialog = ({
  hideLoginDialog,
  showExitConfirmation,
  hideExitConfirmation,
  isLoginDialogVisible,
  isExitConfirmationVisible,
  view,
  onDestroyForm,
  dispatchUpdateAdditionalUsersList
}) => {
  const VIEWS = {
    [LOGIN_VIEW]: () => <LoginForm />,
    [FORGOT_PASSWORD_VIEW]: () => <ForgotPasswordForm />,
    [WELCOME_ACCOUNT_VIEW]: () => <WelcomeToZoroForm />,
    [CREATE_INDIVIDUAL_ACCOUNT_VIEW]: () => <AboutYouForm />,
    [CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW]: () => <AboutYouForm />,
    [CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED]: () => <BusinessRegistration />,
    [CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW]: () => <BusinessRegistration />
  };

  const closeModalWithConfirmation = () => {
    showExitConfirmation();
  };

  const closeModal = () => {
    onDestroyForm('primaryContactDetails');
    onDestroyForm('financeContactForm');
    onDestroyForm('creditLineApplicationForm');
    dispatchUpdateAdditionalUsersList(fromJS([]));
    onDestroyForm('additionalUsersForm');
    onDestroyForm('aboutYouForm');
    hideLoginDialog();
    hideExitConfirmation();
  };

  return (
    <Modal
      autoFocus={ false }
      enforceFocus
      restoreFocus
      className="NewFormLoginDialog_new"
      show={ isLoginDialogVisible }
      onHide={ !listOfStatusesForNotClosingTheModal.includes(view) ? closeModal : () => {} }>
      <SvgLoader className="NewFormLoginDialog_CloseIcon" xlinkHref="close-x" onClick={
        listOfStatusesForNotClosingTheModal.includes(view) ? closeModalWithConfirmation : closeModal
      } />
      { isExitConfirmationVisible ? <OutsideAlerter onClickOutside={ hideExitConfirmation }><ExitConfirmation closeModal={ closeModal } hideExitConfirmation={ hideExitConfirmation } /></OutsideAlerter> : null }
      <Modal.Body className="container NewFormLoginDialog_Body">
        {view && VIEWS[view] ? VIEWS[view]() : null}
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAndRegistrationDialog);
