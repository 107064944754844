import React from 'react';

if (process.browser) {
  require('./cmsButton.scss');
}

export const CmsButton = ({ buttonCss, href, text }) => {
  return <div style={ buttonCss }><a className="CmsButton" href={ href }>
    {text}
  </a></div>;
};
