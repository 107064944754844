import num from 'num';
import { fromJS } from 'immutable';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';

const standardShippingCost = 4.99;

export const quotationCalculator = (quotationDetails) => {
  const { totalGoods, totalWithVat, lockTimeTotalGoods, lockTimeTotalWithVat, totalWithoutVat, lockTimeTotalWithoutVat } = quotationDetails && quotationDetails.get('products', []).reduce(
    (totals, product) => {
      const packPrice = product.getIn(['lockedPrice', 'packPrice']);
      const packPriceWithVat = product.getIn(['lockedPrice', 'packPriceWithVat'], 0);
      const lockTimePackPrice = product.getIn(['lockTimePrice', 'packPrice'], 0);
      const lockTimePackPriceWithVat = product.getIn(['lockTimePrice', 'packPriceWithVat']);
      const qty = product.get('qty', 1);
      return {
        totalGoods: num(totals.totalGoods).add(num(packPrice).mul(qty)),
        totalWithoutVat: num(totals.totalWithVat).add(num(packPrice).mul(qty)),
        totalWithVat: num(totals.totalWithVat).add(num(packPriceWithVat).mul(qty)),
        lockTimeTotalGoods: num(totals.lockTimeTotalGoods).add(num(lockTimePackPrice).mul(qty)),
        lockTimeTotalWithoutVat: num(totals.lockTimeTotalWithVat).add(num(lockTimePackPrice).mul(qty)),
        lockTimeTotalWithVat: num(totals.lockTimeTotalWithVat).add(num(lockTimePackPriceWithVat).mul(qty))
      };
    },
    { totalGoods: 0, totalWithVat: 0, lockTimeTotalGoods: 0, lockTimeTotalWithVat: 0 }
  );
  const currency = quotationDetails.getIn(['products', 0, 'lockedPrice', 'currency'], '£');
  const totalVat = totalWithVat - totalGoods;
  const deliveryOptions = quotationDetails.getIn(['products', 0, 'deliveryOptionList', 'optionList'], fromJS([])).toJS();
  const selectedDeliveryOption = quotationDetails.getIn(['products', 0, 'deliveryOptionList', 'selectedDeliveryOption'], null);
  const optionShippingAmount = selectedDeliveryOption && deliveryOptions.find(option => option.type === selectedDeliveryOption);

  const shippingAmount = (optionShippingAmount && optionShippingAmount.cost) || ((totalWithVat >= FREE_DELIVERY_THRESHOLD) ? 0 : standardShippingCost);
  const lockTimeShippingAmount = (optionShippingAmount && optionShippingAmount.cost) || ((lockTimeTotalWithVat >= FREE_DELIVERY_THRESHOLD) ? 0 : standardShippingCost);
  const totalPriceWithVat = Number(Number((num(totalWithVat).add(num(shippingAmount)))).toFixed(2));

  return {
    currency: currency,
    totalGoods: totalGoods,
    totalWithVat: totalWithVat,
    totalVat: totalVat,
    shippingAmount: shippingAmount,
    lockTimeTotalGoods: lockTimeTotalGoods,
    lockTimeTotalWithVat: lockTimeTotalWithVat,
    lockTimeShippingAmount: lockTimeShippingAmount,
    totalWithoutVat: totalWithoutVat,
    lockTimeTotalWithoutVat: lockTimeTotalWithoutVat,
    totalPriceWithVat
  };
};
