import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./IEBrowserScreen.scss');
}
export default class IEBrowserScreen extends React.Component {
  render () {
    return (
      <div className="IEBrowserScreen">
        <div className="row">
          <div className="col-xs-12 unsupportedBrowserHeading">
            { getLocalizedString('unsupportedBrowser.heading') }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className="unsupportedBrowserMessage">{ getLocalizedString('unsupportedBrowser.subHeading') }</p>
          </div>
        </div>
      </div>
    );
  }
}
