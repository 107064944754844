import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import {
  isRequiredIf,
  matches,
  minLength,
  passwordValidator
} from 'client/utils/validators';
import {
  registerUserAndAccountAndGoToCheckout
} from 'client/actions/userActions';
import {
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  USER_TYPE_BUSINESS
} from '../../constants';
import { makeValidator } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import PasswordStengthIndicator from 'client/components/elements/formField/PasswordStrengthIndicator';
import { validatePasswordChecklist } from 'client/actions/passwordChecklistAction';
import { PasswordChecklistAndSvg } from '../../individualAccountRegistration/PasswordChecklistAndSvg';
import { setView } from 'client/actions/loginAndRegistration';
import { fromJS } from 'immutable';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';

if (process.browser) {
  require('./EmailAndPassword.scss');
}

const privacyPolicyLink = (
  <a id="PrivacyPolicyAgreement.checkbox.PrivacyPolicyLink" href="/info/privacy-policy" target="_blank">
    { getLocalizedString('registration.label.privacyPolicy') }
  </a>
);

const termsofAccessLink = (
  <a id="TAndCAcceptance.checkbox.TAndCLink" href="/info/zoro-business-account-terms" target="_blank">
    Terms of Access
  </a>
);

const validateFunc = (csAgentName) => makeValidator({
  password: [
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.password')
    ),
    passwordValidator({
      fieldName: getLocalizedString('registration.label.password')
    }),
    minLength({
      minLength: 8,
      fieldName: getLocalizedString('registration.label.password')
    })
  ],
  confirmPassword: [
    // Password is not required when customer-agent session is active
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.confirmPassword')
    ),
    matches({
      otherField: 'password',
      fieldName: getLocalizedString('registration.label.confirmPassword'),
      otherFieldName: getLocalizedString('registration.label.password'),
      message: getLocalizedString('error.match.password')
    })
  ]
});

const FORM_NAME = 'emailAndPasswordFinalStepRegister';
const EmailAndPassword = ({
  csAgentName,
  handleSubmit,
  dispatchSetView,
  onRegister,
  primaryContactForm,
  hideExitConfirmation
}) => {
  const [, setConfirmPasswordVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordChecklist, setPasswordChecklist] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false
  });

  const createAccount = (handleSubmit, submit) => {
    return handleSubmit(submit)();
  };

  const submitCreateAccount = (values) => {
    return validateForm(values, validateFunc(csAgentName)).then(() => {
      hideExitConfirmation();
      return onRegister({
        title: primaryContactForm.title,
        password: values.get('password'),
        email: primaryContactForm.email,
        firstName: primaryContactForm.firstName,
        lastName: primaryContactForm.lastName,
        phoneNumber: primaryContactForm.phoneNumber,
        companyName: primaryContactForm?.companyName || '',
        jobTitle: primaryContactForm?.jobTitle || '',
        companyRegNo: primaryContactForm?.companyRegNo || '',
        marketingConsent: true,
        captchaResponse: true,
        csAgentName,
        userType: USER_TYPE_BUSINESS
      });
    });
  };

  const updatePasswordChecklist = (password) => {
    setPasswordVal(password);
    const checklist = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*()+.,£-]/.test(password)
    };

    setPasswordChecklist(checklist);
    if (
      checklist.length &&
      checklist.uppercase &&
      checklist.lowercase &&
      checklist.digit &&
      checklist.specialChar
    ) {
      setPasswordStrength('strong');
    } else if (checklist.lowercase && checklist.uppercase) {
      setPasswordStrength('average');
    } else {
      setPasswordStrength('weak');
    }
  };

  return (<div className="EmailAndRegistration">
    <h1 className="EmailAndRegistration_finalStep">Final Step</h1>
    { csAgentName
      ? <div className="EmailAndRegistration_getStartedTitle">{ getLocalizedString('login.csAgentNameLogin.text') }</div>
      : <React.Fragment>
        <div className="EmailAndRegistration_getStartedInput">
          <Field
            id="password"
            name="password"
            className={ `form-control password focus-shadow-none:focus ${passwordStrength === 'weak' ? 'border-weak weak-pass' : passwordStrength === 'average' ? 'border-average average-pass' : passwordStrength === 'strong' ? 'border-success success-pass' : ''}` }
            labelClassName="control-label required"
            type="password"
            placeholderResourceName="login.password.placeholder"
            labelResourceName="login.createAPassword.placeholder"
            component={ PasswordStengthIndicator }
            formClassName="password"
            datae2e="password"
            updatePasswordChecklist={ updatePasswordChecklist }
            passwordStrength={ passwordStrength }
            validatePasswordChecklist={ validatePasswordChecklist }
          />
        </div>
        <div className="EmailAndRegistration_getStartedInput">
          <Field
            id="confirmPassword"
            name="confirmPassword"
            className="form-control confirmPassword"
            labelClassName="control-label required"
            type="password"
            placeholderResourceName="login.confirmPassword.placeholder"
            labelResourceName="login.confirmPassword.placeholder"
            component={ FieldWithValidations }
            datae2e="confirm-password"
            onChange= { (e) => setConfirmPasswordVal(e.target.value) }
          />
        </div>
        <h3 className="passContain">{ getLocalizedString('your.password.must.contain') }</h3>
        <div className="passwordChecklist passwordChecklistMobile">
          <div className="passwordChecklistContent_1 fontWeightNormal">
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.length } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.length') } />
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.lowercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.lowercase') } />
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.uppercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.uppercase') }/>
            }
          </div>

          <div className="passwordChecklistContent_2">
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.digit } passwordVal={ passwordVal } name={ getLocalizedString('password.checklist.digit') }/>
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.specialChar } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.specialChar') } />
            }
          </div>

        </div>
      </React.Fragment>
    }
    <div className="row">
      <div className="col-xs-12 BusinessAccountRegistrationForm_finalStep-btns">
        <div className="BusinessAccountRegistrationForm_finalStep-back">
          <SecondaryButton
            datae2e="back"
            className="ForgotPasswordNewForm_backToLogin"
            onClick={ () => {
              hideExitConfirmation();
              dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW);
            } }
          >
              Back
          </SecondaryButton>
        </div>
        <div className="ForgotPasswordNewForm_submitBtn BusinessAccountRegistrationForm_finalStep-createAccount">
          <PrimaryButton
            className="btn btn-primary"
            onClick={ () => {
              hideExitConfirmation();
              return createAccount(handleSubmit, submitCreateAccount);
            } }
            datae2e="continue">
              Create account
          </PrimaryButton>
        </div>
      </div>
    </div>
    <div className="EmailAndRegistration_terms">
      { getLocalizedString('contactus.thank.you.message') } { termsofAccessLink }
      { '.' } { getLocalizedString('register.tAndCpart2.text') } { privacyPolicyLink } { getLocalizedString('register.tAndCpart3.text') }<br />
    </div>
  </div>);
};

const mapStateToProps = (state) => {
  return {
    primaryContactForm: (getFormValues('primaryContactForm')(state) || fromJS({})).toJS(),
    passwordValidationChecklist: state.getIn(['passwordChecklist']),
    csAgentName: state.getIn(['customerService', 'csAgentName'])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validatePasswordChecklist: () => dispatch(validatePasswordChecklist()),
    dispatchSetView: (view) => dispatch(setView({ view })),
    hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog()),
    onRegister: (args) => dispatch(registerUserAndAccountAndGoToCheckout(args))
  };
};

const reduxLoginForm = reduxForm({
  form: FORM_NAME,
  validate: validateFunc()
})(EmailAndPassword);

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxLoginForm
);
