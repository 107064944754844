import React from 'react';
import List from 'client/components/elements/recommendations/elements/List';

export const CmsOfferItem = ({ items, title, seeMoreUrl, isPriceWithVat, panelIndex, query }) => {
  return (
    <div className="CmsOfferItem">
      <List
        items={ items }
        title={ title }
        seeMoreUrl={ seeMoreUrl }
        isPriceWithVat={ isPriceWithVat }
        panelIndex= { panelIndex }
        disableModal = { false }
        isMobile = { false }
        bottomBorder = { undefined }
        cmsFlag = { true }
        query = { query }
      />
    </div>
  );
};

export class CmsOffersPanel extends React.Component {
  fetchOffers () {
    const data = this.props.data;
    const query = data ? data.get('searchQuery') : null;
    const offers = this.props.offers;
    if (query && (!offers || !offers.get(query))) {
      this.props.requestCmsOffers({
        query,
        skip: 0,
        limit: 9
      });
    }
  }

  componentDidMount () {
    this.fetchOffers();
  }

  render () {
    const { data, panelIndex, isPriceWithVat } = this.props;
    const query = data.get('searchQuery');
    const title = data.get('title');
    const seeMoreUrl = data.get('seeMoreUrl');

    const offers = this.props.offers ? this.props.offers.get(query) : null;
    const items = !offers ? null : offers.toJS().map((offer) => {
      const theTrio = {
        lowestPrice: offer.minPrice,
        priceWithVat: offer.packPriceWithVat,
        priceWithoutVat: offer.packPriceWithoutVat,
        topTitle: offer.familyObject.topCategoryName,
        terminalTitle: offer.productsInfo[0].category.categoryName
      };
      if (offer.numberOfMatchingProducts === 1) {
        return {
          ...offer.productsInfo[0],
          ...theTrio
        };
      }
      return {
        ...offer.familyObject,
        mediaContent: [offer.familyObject.mediaContent],
        ...theTrio
      };
    });

    return (
      <CmsOfferItem items={ items } title={ title } seeMoreUrl={ seeMoreUrl }
        isPriceWithVat= { isPriceWithVat }
        panelIndex= { panelIndex }
        query= { query } />
    );
  }
}

export default CmsOffersPanel;
