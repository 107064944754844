import { all, fork, put, takeLatest, select, call } from 'redux-saga/effects';
import { INITIALIZE_PAYPAL, PAY_BY_PAYPAL, setData, setDetails } from './payByPaypalActions';
import { orderSelectors, paymentMethodSelectors } from '../shared/selectors';
import { getBraintreeDataForPaypal, preparingBillingAddressForPaypal } from 'client/sagas/braintreeSagas';
import { saveAddress } from 'client/actions/addressActions';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { PAYPAL } from 'shared/constants/braintree';
import { postOrderWithPaymentInformation } from 'shared/endpoints/ordersEndpoint';
import { getLocalizedString } from 'localization/localizer';
import { createOrder, handleResponse, orderFailed } from '../shared/orderHelpers';

export const paypalInstanceSelector = (state) => state.getIn(['braintree', 'paypal', 'instance']);
export const paypalDataSelector = (state) => state.getIn(['braintree', 'paypal', 'data']);

export function * initializePaypal ({ payload }) {
  const paypalInstance = yield select(paypalInstanceSelector);
  if (paypalInstance) {
    const data = yield select(paypalDataSelector);
    yield put(setData({
      data,
      selector: payload.selector
    }));
    const { details } = yield paypalInstance.tokenizePayment(data);
    yield put(setDetails({
      details,
      selector: payload.selector
    }));
  }
}

export function * payByPaypal ({ payload }) {
  try {
    const payPalData = yield select(paymentMethodSelectors.paypal.data(payload.selector));
    const braintreePaypalData = yield call(getBraintreeDataForPaypal, { payPalData });

    const billingAddress = preparingBillingAddressForPaypal({
      ...braintreePaypalData.braintreePayload.details.billingAddress,
      firstName: braintreePaypalData.braintreePayload.details.firstName,
      lastName: braintreePaypalData.braintreePayload.details.lastName
    });
    yield put(saveAddress({ addressType: 'billing', address: billingAddress, redirectToUrl: SINGLE_PAGE_CHECKOUT_ROUTE }));

    const paymentInformation = {
      paymentType: PAYPAL,
      isPriceWithVat: yield select(orderSelectors.isPriceWithVat),
      deviceData: braintreePaypalData.deviceData,
      braintreePayload: {
        nonce: braintreePaypalData.braintreePayload.nonce,
        details: braintreePaypalData.braintreePayload.details
      },
      shouldUpdateBillingAddress: true,
      csAgentName: yield select(orderSelectors.csAgent.name),
      storePaymentMethodStatus: true
    };

    const order = yield call(createOrder, PAYPAL, billingAddress, paymentInformation);
    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrderWithPaymentInformation, order);
    yield call(handleResponse, postOrderResponse);
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

function * watchInitializePaypal () {
  yield takeLatest(INITIALIZE_PAYPAL, initializePaypal);
}
function * watchPayByPaypal () {
  yield takeLatest(PAY_BY_PAYPAL, payByPaypal);
}
export function * watchAllPayByPaypalSagas () {
  yield all([
    fork(watchInitializePaypal),
    fork(watchPayByPaypal)
  ]);
}
