import {
  FAMILY_RECOMMENDATIONS_SUCCESS,
  RECEIVE_RECOMMENDATIONS_STOCK_INFO,
  UPDATE_RECOMMENDATIONS_PRODUCT_PRICE_WITH_BREAK_PRICE,
  STORE_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION_APPLIED_DISCOUNT_INFO,
  CLEAR_RECOMMENDATIONS
} from 'client/actions/recommendationsActions';
import { fromJS } from 'immutable';

export default (state, { type, payload }) => {
  switch (type) {
    case FAMILY_RECOMMENDATIONS_SUCCESS:
      return fromJS(payload);
    case RECEIVE_RECOMMENDATIONS_STOCK_INFO:
      const panelIndex = payload.panelIndex || 0;
      const isResult = state.getIn([panelIndex]);
      if (isResult) {
        const itemIndex = state
          .getIn([panelIndex, 'results']).toJS()
          .findIndex((item) => item.sku === payload.stockInfo.sku);
        return state.setIn(
          [panelIndex, 'results', itemIndex, 'stock'],
          fromJS(payload.stockInfo)
        );
      }
      return state;
    case UPDATE_RECOMMENDATIONS_PRODUCT_PRICE_WITH_BREAK_PRICE:
      if (!payload.breakPriceValue) {
        return state;
      }

      const recommendationIndex = state.findIndex((recommendation) => recommendation.get('recommendationType') === payload.recommendationType);

      if (recommendationIndex < 0) {
        return state;
      }

      const productIndex = state.getIn([recommendationIndex, 'results']).findIndex((result) => result.get('sku') === payload.sku);

      if (productIndex < 0) {
        return state;
      }

      const newState = state.setIn([
        recommendationIndex,
        'results',
        productIndex,
        'price',
        'packPriceWithVat'
      ], fromJS(payload.breakPriceValue));

      return newState;
    case UPDATE_RECOMMENDATION_APPLIED_DISCOUNT_INFO:
      const index = payload.panelIndex || 0;
      const ispanelResult = state.getIn([index]);
      if (ispanelResult) {
        const itemIndex = state
          .getIn([index, 'results']).toJS()
          .findIndex((item) => item.sku === payload.productStockInfo.sku);
        return state.setIn(
          [index, 'results', itemIndex, 'price'],
          fromJS(payload.productStockInfo.price)
        );
      }
      return state;
    case STORE_RECOMMENDATIONS:
      return !payload.appendRecommendation ? fromJS(payload.data) : fromJS([...state.toJS(), ...payload.data]);
    case CLEAR_RECOMMENDATIONS:
      return fromJS([]);
    default:
      return state;
  }
};
