import { buildErrorMessageFromError } from '../../utils/errorUtils';

export const TOGGLE_ENTER_MANUALLY = 'TOGGLE_ENTER_MANUALLY';

export const toggleEnterManually = (selector, shouldEnterManually) => {
  return {
    type: TOGGLE_ENTER_MANUALLY,
    selector,
    shouldEnterManually
  };
};

export const REQUEST_ADDRESS_WITH_POSTCODE = 'REQUEST_ADDRESS_WITH_POSTCODE';

export const requestAddressGen = (selector, postcode) => {
  return {
    type: REQUEST_ADDRESS_WITH_POSTCODE,
    selector,
    postcode
  };
};

export const REQUEST_ADDRESS_WITH_POSTCODE_SUCCESS = 'REQUEST_ADDRESS_WITH_POSTCODE_SUCCESS';

export const requestAddressSuccess = (selector, addressList) => {
  return {
    type: REQUEST_ADDRESS_WITH_POSTCODE_SUCCESS,
    selector,
    addressList
  };
};

export const RECEIVE_ADDRESS_ERROR = 'RECEIVE_ADDRESS_ERROR';
export function receiveAddressError (selector, error) {
  return {
    type: RECEIVE_ADDRESS_ERROR,
    selector,
    error: buildErrorMessageFromError(error)
  };
}

export const REQUEST_FULL_ADDRESS = 'REQUEST_FULL_ADDRESS';

export const requestFullAddress = (form, selector, fieldNamePrefix, postcodeid) => {
  return {
    type: REQUEST_FULL_ADDRESS,
    form,
    selector,
    fieldNamePrefix,
    postcodeid
  };
};

export const REQUEST_FULL_ADDRESS_SUCCESS = 'REQUEST_FULL_ADDRESS_SUCCESS';

export const requestFullAddressSuccess = (selector, address) => {
  return {
    type: REQUEST_FULL_ADDRESS_SUCCESS,
    selector,
    address
  };
};

export const REQUEST_POST_CODE_CHANGE = 'REQUEST_POST_CODE_CHANGE';
export const REQUEST_POST_CODE_ZONE_SUCCESS = 'REQUEST_POST_CODE_ZONE_SUCCESS';

export function onChangePostalCode (selector, postcode) {
  return {
    type: REQUEST_POST_CODE_CHANGE,
    selector,
    postcode
  };
}

export const onPostalCodeZoneSuccess = (selector, postCodeZone) => {
  return {
    type: REQUEST_POST_CODE_ZONE_SUCCESS,
    selector,
    postCodeZone
  };
};
