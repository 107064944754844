import React from 'react';
import { formatNumberCommaSeparated } from 'client/utils/priceUtils';
import { getLocalizedString } from 'localization/localizer';

const MAX_L3_CATEGORY = 5;
export const L3Categories = ({
  l3CatoriesList,
  parentUrl
}) => {
  const showViewAll = l3CatoriesList?.length > MAX_L3_CATEGORY;
  return (
    <React.Fragment>
      {l3CatoriesList?.slice(0, MAX_L3_CATEGORY)?.map((l3Category) => {
        const { id, name, categoryUrl } = l3Category;
        return (
          <div key={ id } className="SubCategoriesList_l3Category">
            <a data-e2e={ id + l3Category.name }
              href={ categoryUrl }
            >
              <span>{name}</span>
            </a>
          </div>
        );
      })
      }
      {
        showViewAll
          ? <div className="SubCategoriesList_l3Category viewAll">
            <a
              href={ parentUrl }
            >
              <span>{ getLocalizedString('label.view.all')}</span>
            </a>
          </div>
          : null
      }
    </React.Fragment>
  );
};

export const SubCategoriesList = ({
  parentCategoryId,
  allCategories
}) => {
  const l2CatoriesList = allCategories?.find((cat) => cat.id === parentCategoryId) || {};
  return (
    <div className="SubCategoriesList">
      {
                l2CatoriesList?.childCategories?.filter((cat) => !!cat.count)?.map((l2Category) => {
                  const { id, name, categoryUrl, count, childCategories } = l2Category;
                  return (
                    <div className="SubCategoriesList_l2Category" key={ id }>
                      <div key={ id } className="SubCategoriesList_l2Category_link">
                        <a data-e2e={ id + l2Category.name }
                          href={ categoryUrl }
                        >
                          <span>{name} ({formatNumberCommaSeparated(count)})</span>
                        </a>
                      </div>
                      <L3Categories l3CatoriesList={ childCategories } parentUrl={ categoryUrl } />
                    </div>
                  );
                })
      }
    </div>
  );
};
