import { takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { logAction } from 'client/actions/logActions';
import {
  RECOMMENDED_LINK_CLICKED,
  recieveLoadProductSuccess,
  recieveLoadProductError
} from 'client/actions/productVariantActions';
import {
  getProductById
} from 'shared/endpoints/productVariantEndpoint';
import { updateDataLayer } from 'client/actions/dataLayerActions';

export function * fetchProductById ({ id, query }) {
  try {
    const { body } = yield call(getProductById, id);
    yield put(recieveLoadProductSuccess(fromJS(body)));
    return fromJS(body);
  } catch (e) {
    yield put(logAction({ message: 'Failure within fetchProductById', e }));
    yield put(recieveLoadProductError(e));
  }
}

export function * handleRecommendedLinkClicked ({ payload }) {
  const data = Object.assign({}, { event: 'RecommendationClick' }, payload);
  yield put(updateDataLayer(data));
}

export function * watchRecommendedLinkClicked () {
  yield takeLatest(RECOMMENDED_LINK_CLICKED, handleRecommendedLinkClicked);
}
