
import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./RadioButtonWithStyles.scss');
}

const RadioButtonWithStyles = (props) => {
  const { input, meta, options, validatorName, tooltip = null, labelResourceName, labelClassName = '', id } = props;
  const hasError = meta.touched && meta.error;
  const classNameAndValidatorName = (validatorName) ? `RadioButtonWithStyles ${validatorName}` : 'FieldRadioButton';
  return (
    <div className={ classNameAndValidatorName } id={ id }>
      { labelResourceName
        ? <div className={ `RadioButtonWithStyles_label ${labelClassName}` }>
          { getLocalizedString(labelResourceName) }
          { tooltip ? <span className="RadioButtonWithStyles_tooltip">{tooltip}</span> : null}
        </div>
        : null }
      {options.map(radioButton => <label key={ radioButton.value } className="RadioButtonWithStyles_radio-label">
        <input type="radio" data-e2e={ radioButton.datae2e } className="RadioButtonWithStyles_radio-input" { ...input }
          value={ radioButton.value } checked={ radioButton.value === input.value }/>
        <span className="RadioButtonWithStyles_radio-text">{radioButton.title}</span>
      </label>)}
      {hasError ? <div className="RadioButtonWithStyles_radio-error">{meta.error}</div> : null }
    </div>

  );
};

export default RadioButtonWithStyles;
