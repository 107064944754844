import { ApiRequest } from 'shared/utils/apiUtils';

export const setScheduledOrders = (body) => {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/cart/scheduled-order',
    body: body
  });
  return req.executeAndGetBody();
};

export const removeScheduledOrdersSettings = (skus = []) => {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/cart/scheduled-order?skus=${skus.join()}`
  });
  return req.executeAndGetBody();
};

export const createScheduledOrders = (payload) => {
  const req = new ApiRequest({
    method: 'POST',
    body: payload,
    apiPath: '/repeatOrders'
  });
  return req.executeAndGetBody();
};
