import _curry from 'lodash/curry';
import _invert from 'lodash/invert';
import _first from 'lodash/first';
import _last from 'lodash/last';
import _inRange from 'lodash/inRange';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import { Map } from 'immutable';
import numeral from 'numeral';
import { UNDER, OVER } from 'client/utils/facetUtils';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';

const currencyCodeMap = {
  'GBP': '£'
};

export const getPriceWithMultiplier = (priceObj, qtyMultiplier, isIncludingVat) => {
  const price = priceObj.get(isIncludingVat ? 'packPriceWithVat' : 'packPrice');
  const unitPriceWithMultiplier = Number(qtyMultiplier) * Number(price);
  return parseFloat(Math.round(unitPriceWithMultiplier * 100) / 100).toFixed(2);
};

export const getAbbrNum = (number, decimals = 0) => {
  const abbrDecPlaces = Math.pow(10, decimals);
  const oneThousand = 1000;
  const abbrev = [ 'K', 'M', 'B', 'T' ];
  let i, size;
  for (i = abbrev.length - 1; i >= 0; i--) {
    size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round(number * abbrDecPlaces / size) / abbrDecPlaces;
      if ((number === oneThousand) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break;
    }
  }
  return number;
};

// To get more precise result than getAbbrNum returns.
// eg. when value is 8.37 then getAbbrNum returns 8.370000000000001
export const getNumRoundedExponential = (number, decimal = 2) => {
  return Number(`${Math.round(`${getAbbrNum(number)}e${decimal}`)}e-${decimal}`);
};

export const formatPrice = _curry((currency, priceValue = 0, microdata = false, formatType = '0,0.00') => {
  if (priceValue === undefined || priceValue === null) {
    throw new Error('formatPrice(): Price value is incorrect');
  }

  const symbol = currencyCodeMap[currency] || currency;
  const alpha3 = _invert(currencyCodeMap)[symbol];
  const amount = numeral(priceValue).format(formatType);

  return microdata
    ? {
      alpha3,
      symbol,
      amount
    }
    : {
      symbol,
      amount
    };
});

export const formatNumberCommaSeparated = (num) => numeral(num).format('0,0');

export const formatPriceWrapper = (currency, priceValue = 0) => {
  const abbrDecPlaces = 2;
  const formattedPrice = formatPrice(currency, priceValue, false, '0');
  return { ...formattedPrice, amount: getAbbrNum(formattedPrice.amount, abbrDecPlaces) };
};

export const getCheapestPriceObject = (prodFamData) => {
  if (!prodFamData) {
    return null;
  }

  return prodFamData.reduce((aggregation, prod) => {
    const finalPrice = bestPriceWithinProduct(prod.get('price'));
    return !aggregation || finalPrice < aggregation.get('derivedPrice') ? prod.set('derivedPrice', finalPrice) : aggregation;
  }, null).get('price', Map());
};

export const familyHasPromo = (prodFamData) => {
  if (!prodFamData) {
    return null;
  }
  return prodFamData.reduce((promo, prod) => {
    if (prod.getIn(['price', 'isInPromo']) === true) {
      return { hasPromo: true, promoLabel: prod.getIn(['price', 'promoLabel']) };
    }
    return promo;
  }, { hasPromo: false, promoLabel: '' });
};

export const bestPriceWithinProduct = (productPrice, options = {}) => {
  return productPrice.get('packPrice');
};

export const formatString = _curry((currency, priceValue) => {
  const displayCurrency = currencyCodeMap[currency]
    ? currencyCodeMap[currency]
    : currency;
  return displayCurrency + priceValue.toFixed(2);
});

export const getPriceInfo = (price) => {
  if (!price) {
    return {};
  }
  return {
    priceWithVat: price.get('packPriceWithVat'),
    priceWithoutVat: price.get('packPrice')
  };
};

export const newBreakPriceObject = (qty, breakPrices) => {
  if (breakPrices && breakPrices.length) {
    return _find(breakPrices, (breakPrice) => {
      return _inRange(qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
    });
  }
  return {};
};

export const isEqualObject = (object1, object2) => {
  return _isEqual(object1, object2);
};

export const getFreeDeliveryPrice = (priceSymbol) => {
  return `${priceSymbol}${FREE_DELIVERY_THRESHOLD}`;
};

export const getPriceFacet = (facetName) => {
  if (facetName.includes(UNDER)) {
    const startPrice = numeral(facetName).value();
    return encodeURIComponent(`${UNDER}-${startPrice}`);
  }
  if (facetName.includes(OVER)) {
    const endPrice = numeral(facetName).value();
    return encodeURIComponent(`${endPrice}-${OVER}`);
  }
  const facetPrices = facetName.split(' ');
  const startPrice = numeral(_first(facetPrices)).value();
  const endPrice = numeral(_last(facetPrices)).value();
  return encodeURIComponent(`${startPrice}-${endPrice}`);
};
