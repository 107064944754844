import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import { PERSONAL } from 'shared/constants/singlePageCheckout';

if (process.browser) {
  require('./addressDisplay.scss');
}

const showPhoneNumber = (address) => {
  const phoneNumber = address.addressPhoneNumber
    ? address.addressPhoneNumber
    : address.number;

  return (
    <React.Fragment>
      {
        phoneNumber
          ? <div className="AddressDisplay_address_deliveryAddress_phoneNumber">
            <div className="AddressDisplay_address_deliveryAddress_phoneNumber_title">{getLocalizedString('singlePageCheckout.phoneNumber')} </div>
            <div className="AddressDisplay_address_deliveryAddress_phoneNumber_value">{phoneNumber}</div>
          </div>
          : null}
    </React.Fragment>
  );
};

const showAddressType = (address) => (<div className="AddressDisplay_address_deliveryAddress_addressType">
  <div className="AddressDisplay_address_deliveryAddress_addressType_title">{getLocalizedString('singlePageCheckout.addressType')} </div>
  <div className="AddressDisplay_address_deliveryAddress_addressType_value">{address.addressType || PERSONAL}</div>
</div>);

export const AddressDisplay = ({ address, showTitle = true }) => {
  if (!address) {
    return null;
  }
  return (
    <div className="AddressDisplay">
      <div className="AddressDisplay_address row">
        {
          showTitle ? <div className="col-md-6 AddressDisplay_address_deliverToHeading">{getLocalizedString('singlePageCheckout.deliverTo')}</div>
            : null
        }
        <div className="col-md-6 AddressDisplay_address_deliveryAddress">
          <div className="AddressDisplay_address_deliveryAddress_name">{ address.firstName} { address.lastName }</div>
          <div className="AddressDisplay_address_deliveryAddress_companyName">{address.companyName}</div>
          <div className="AddressDisplay_address_deliveryAddress_addressLine1">{address.addressLine1}</div>
          <div className="AddressDisplay_address_deliveryAddress_addressLine2">{address.addressLine2}</div>
          <div className="AddressDisplay_address_deliveryAddress_state">{address.state}</div>
          <div className="AddressDisplay_address_deliveryAddress_city">{address.city}</div>
          <div className="AddressDisplay_address_deliveryAddress_postalCode">{address.postalCode}</div>
          { showPhoneNumber(address) }
          { showAddressType(address) }
        </div>
      </div>
    </div>
  );
};

AddressDisplay.propTypes = {
  address: PropTypes.object,
  onEdit: PropTypes.func,
  sameAddress: PropTypes.bool
};
