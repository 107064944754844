import React from 'react';
if (process.browser) {
  require('./centredForm.scss');
}

const CentredForm = ({ children }) => {
  return (
    <div className="CentredForm">
      <div className="FormContainer">
        { children }
      </div>
    </div>
  );
};

export const FormLineBreak = () => <hr className = "FormLineBreak"/>;

export default CentredForm;
