import _get from 'lodash/get';
import { BACKUP_IMAGE } from 'shared/constants/images';
import { flattenProducts, calculateItemTotalQuantity, calculateTotalPrice } from './orderUtils';
import moment from 'moment';
import { MAX_RETURNS_DAYS_DIFFERENCE, SF_CLOSED_NOT_RETURNED, SF_REJECTED } from 'shared/constants/returnRefundConstants';
import { isRefundableProduct } from 'client/utils/productLineUtils';

function mergeQuantities (list, skuDataMap) {
  return list.reduce((acc, value) => {
    const { sku } = value;
    const { unitPrice, unitPriceWithoutVat } = skuDataMap[sku];
    const calculatedQty = calculateItemTotalQuantity(value, skuDataMap);
    const qty = acc[sku] ? acc[sku].qty + calculatedQty : calculatedQty;

    const totalPrice = calculateTotalPrice(qty, unitPrice);
    const totalPriceWithoutVat = calculateTotalPrice(qty, unitPriceWithoutVat);

    const orderLinePrice = skuDataMap[sku].orderLinePrice;
    return {
      ...acc,
      [sku]: {
        ...value,
        qty,
        totalPrice,
        totalPriceWithoutVat,
        orderLinePrice
      }
    };
  }, {});
}

function processCancelled (cancellationDetails, skuDataMap) {
  const flattened = flattenProducts(cancellationDetails);
  return mergeQuantities(flattened, skuDataMap);
}

function removeDispatchedItemsFromOrder (orderLines, dispatchDetails = [], skuDataMap) {
  return orderLines.reduce((acc, current) => {
    const orderLine = Object.assign({}, current);
    dispatchDetails.forEach((dispatch, _) => {
      dispatch.products.forEach((product, _) => {
        if (product.sku === current.sku) {
          orderLine.quantity -= calculateItemTotalQuantity(product, skuDataMap);
        }
      });
    });
    if (orderLine.quantity) {
      acc[current.sku] = orderLine;
    }
    return acc;
  }, {});
}

function removeCancelledFromPending (pendingAndCancelledItems, cancelled, skuDataMap, orderDate, publicHolidays) {
  return Object.keys(pendingAndCancelledItems).reduce((acc, value) => {
    const notDispatchedItem = pendingAndCancelledItems[value];
    notDispatchedItem.quantity -= _get(cancelled, `${value}.qty`, 0);
    acc[value] = notDispatchedItem;
    acc[value].totalPrice = calculateTotalPrice(notDispatchedItem.quantity, skuDataMap[value].unitPrice);
    acc[value].totalPriceWithoutVat = calculateTotalPrice(notDispatchedItem.quantity, skuDataMap[value].unitPriceWithoutVat);
    if (notDispatchedItem.deliveryBy) {
      acc[value].deliveryBy = moment(notDispatchedItem.deliveryBy)
        .format('DD/MM/YYYY');
    } else {
      acc[value].deliveryBy = 'Processing';
    }
    acc[value].orderLinePrice = skuDataMap[value].orderLinePrice;
    if (notDispatchedItem.quantity === 0) {
      delete acc[value];
    }
    return acc;
  }, {});
}

function buildSkuDataMap (data) {
  return data.reduce((acc, current) => {
    const formattedPrice = parseFloat(Math.round(current.orderLinePrice.unitPriceWithVat * 100) / 100).toFixed(2);
    const formattedPriceWithoutVat = parseFloat(Math.round(current.orderLinePrice.unitPriceWithoutVat * 100) / 100).toFixed(2);
    const image = _get(current, 'image[0].mainPicture.shovelerImage.lowResolution') ||
      _get(current, 'image.mainPicture.shovelerImage.lowResolution', BACKUP_IMAGE);

    acc[current.sku] = {
      name: current.name,
      unitPrice: formattedPrice,
      unitPriceWithoutVat: formattedPriceWithoutVat,
      image,
      packQty: current.packQty || 1,
      zoroSku: current.zoroSku || current.sku,
      orderLinePrice: current.orderLinePrice,
      supplierSku: current.supplierSku
    };
    return acc;
  }, {});
}

function addQuantityPriceToProducts (products, skuDataMap) {
  return products.reduce((accProd, currentPro) => {
    const totalQuantity = calculateItemTotalQuantity(currentPro, skuDataMap);
    const totalPrice = calculateTotalPrice(totalQuantity, skuDataMap[currentPro.sku].unitPrice);
    const totalPriceWithoutVat = calculateTotalPrice(totalQuantity, skuDataMap[currentPro.sku].unitPriceWithoutVat);
    const orderLinePrice = skuDataMap[currentPro.sku].orderLinePrice;
    const productItem = Object.assign({}, currentPro, { totalQuantity, totalPrice, orderLinePrice, totalPriceWithoutVat });
    accProd.push(productItem);
    return accProd;
  }, []);
}

function processDispatched (data, skuDataMap) {
  return data.reduce((acc, current) => {
    current.products = addQuantityPriceToProducts(current.products, skuDataMap);
    acc.push(current);
    return acc;
  }, []);
}

const appendZoroSku = (productsMetadataArray) => (orderLine) => {
  const orderLineSku = orderLine.sku;
  const productMetadata = productsMetadataArray.find((product) => {
    return product.supplierSku === orderLineSku || product.sku === orderLineSku;
  });

  orderLine.zoroSku = productMetadata ? productMetadata.sku : null;
  return orderLine;
};

const appendIsDeliverable = (productsMetadataArray) => (orderLine) => {
  const orderLineSku = orderLine.sku;
  const productMetadata = productsMetadataArray.find((product) => {
    return product.supplierSku === orderLineSku || product.sku === orderLineSku;
  });

  orderLine.isDeliverable = productMetadata ? productMetadata.stock.stockMessage.isDeliverable : null;
  return orderLine;
};

const buildSkuMapFromDispatches = (dispatchedList) => {
  const dispatchMapGroupedBySku = dispatchedList.reduce((dispatchMap, dispatch) => {
    const { products, ...details } = dispatch;
    for (const product of products) {
      const { sku, qty: dispatchedQty } = product;
      const skuDetails = dispatchMap[sku];
      const updatedQty = skuDetails ? skuDetails.qty + dispatchedQty : dispatchedQty;
      dispatchMap[sku] = {
        ...details,
        qty: updatedQty,
        sku
      };
    }
    return dispatchMap;
  }, {});
  return Object.values(dispatchMapGroupedBySku);
};

const getSkuReturnedQuantityMap = (returnRequest, isCsAgent) => {
  const allReturnRequests = returnRequest || [];
  const orderLinesSkuList = [].concat(...(allReturnRequests.map((request) => Object.values(request.orderLines))));
  return orderLinesSkuList.reduce((acc, orderLine) => {
    const { sku, qty, decision } = orderLine;
    const returnedQuantity = isCsAgent && [SF_CLOSED_NOT_RETURNED, SF_REJECTED].includes(decision) ? 0 : qty;
    const totalQty = acc[sku] ? acc[sku] + returnedQuantity : returnedQuantity;
    return {
      ...acc,
      [sku]: totalQty
    };
  }, {});
};

const getValidDispatchList = (dispatchedList, returnRequests = [], productsMetadataArray = [], isCsAgent, orderLines) => {
  const skuQuantityMap = getSkuReturnedQuantityMap(returnRequests, isCsAgent);
  return dispatchedList.reduce((newDispatchList, dispatch) => {
    const { qty, sku, dispatchDateTime } = dispatch;
    const isDateValid = moment().diff(moment(dispatchDateTime), 'days') <= MAX_RETURNS_DAYS_DIFFERENCE;
    if (!isDateValid && !isCsAgent) {
      return newDispatchList;
    }
    const createdReturnQuantityForSku = skuQuantityMap[sku];
    const updatedQty = createdReturnQuantityForSku ? qty - createdReturnQuantityForSku : qty;
    // checking if returns already created for the dispatched item
    if (updatedQty <= 0) return newDispatchList;

    const orderLine = orderLines.find((orderLine) => orderLine.sku === sku);
    if (!isRefundableProduct(orderLine) && !isCsAgent) return newDispatchList;

    return [...newDispatchList, {
      ...dispatch,
      qty: updatedQty,
      nonRefundableType: orderLine?.nonRefundableType,
      isNonReturnable: !!orderLine?.isNonReturnable
    }];
  }, []);
};

function isOrderDispatchedForReturnRequest ({ dispatchDetails, returnRequests, orderLines }, products, isCsAgent) {
  const groupedDispatchList = buildSkuMapFromDispatches(dispatchDetails);
  const validDispatchList = getValidDispatchList(groupedDispatchList, returnRequests, products, isCsAgent, orderLines);
  return validDispatchList.length;
}

const getOrderLineDetails = (dispatchDetails, products, returnRequests, isCsAgent) => {
  const groupedDispatchList = buildSkuMapFromDispatches(dispatchDetails);
  const isNonRefundableOrderLine = groupedDispatchList.length && groupedDispatchList.every(
    ({ sku }) => products.find(
      (product) => product.sku === sku && !isRefundableProduct(product)
    )
  );

  const skuQuantityMap = getSkuReturnedQuantityMap(returnRequests, isCsAgent);
  const totalDispatchQty = groupedDispatchList.reduce((total, { qty }) => (total += qty), 0);
  const totalReturnQty = Object.values(skuQuantityMap).reduce((total, qty) => (total += qty), 0);
  const isAllItemReturned = totalReturnQty > 0 ? totalDispatchQty === totalReturnQty : false;

  const isReturnPeriodValid = groupedDispatchList.filter(({ sku, qty }) => skuQuantityMap[sku] ? !(skuQuantityMap[sku] === qty) : true)
    .every(({ dispatchDateTime }) => moment().diff(moment(dispatchDateTime), 'days') <= MAX_RETURNS_DAYS_DIFFERENCE);
  return {
    isNonRefundableOrderLine,
    isAllItemReturned,
    isReturnPeriodValid
  };
};

const getNonRefundableProducts = (dispatchedList, productsMetadataArray = [], orderLines) => {
  return dispatchedList.reduce((newDispatchList, dispatch) => {
    const filterDropshipProducts = orderLines.find((orderLine) => orderLine.sku === dispatch.sku && !isRefundableProduct(orderLine));
    if (filterDropshipProducts) {
      return [...newDispatchList, { ...dispatch }];
    }
    return newDispatchList;
  }, []);
};

export {
  buildSkuDataMap,
  removeCancelledFromPending,
  removeDispatchedItemsFromOrder,
  processCancelled,
  processDispatched,
  appendZoroSku,
  appendIsDeliverable,
  buildSkuMapFromDispatches,
  getValidDispatchList,
  isOrderDispatchedForReturnRequest,
  getOrderLineDetails,
  getNonRefundableProducts
};
