import { put, call, takeLatest } from 'redux-saga/effects';

import {
  REQUEST_USER_ENQUIRIES,
  recieveEnquirySuccess,
  userContactUsError
} from 'client/actions/userActions';

import { userEnquiry } from 'shared/endpoints/userEndpoint';
import { goToUrl } from 'client/actions/routeActions';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';

export function * onEnquirySuccess (action) {
  try {
    yield call(userEnquiry, action.body);
    yield put(recieveEnquirySuccess());
    yield put(goToUrl('/info/contact-us-thankyou'));
  } catch (err) {
    yield put(userContactUsError(buildErrorMessageFromError(err)));
  }
}

export function * watchUserEnquiry () {
  yield takeLatest(REQUEST_USER_ENQUIRIES, onEnquirySuccess);
}
