import { fromJS } from 'immutable';
import { SET_DATA, SET_DETAILS } from './payByGooglePayActions';
import { SET_IS_READY_TO_PAY } from './payByGooglePayActions';

const defaultState = fromJS({});

const payByGooglePay = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DETAILS:
      return state.setIn([action.payload.selector, 'details'], action.payload.details);
    case SET_DATA:
      return state.setIn([action.payload.selector, 'data'], action.payload.data);
    case SET_IS_READY_TO_PAY:
      return state.set('isReadyToPay', action.payload);
    default:
      return state;
  }
};

export default payByGooglePay;
