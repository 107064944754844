import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/lib/Carousel';
import { Link } from 'react-router';
import { Iterable } from 'immutable';

import { PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import SvgLoader from '../../../svg/SvgLoader';

if (process.browser) {
  require('./cmsJumboCarousel.scss');
}

export default class CmsJumboCarousel extends Component {
  static propTypes = {
    jumboCarouselData: PropTypes.object
  }

  render () {
    const {
      data
    } = this.props;

    const jumboCarouselData = data && Iterable.isIterable(data) ? data.toJS() : [];
    const BANNER_HEADING_LIMIT = 29;

    if (jumboCarouselData && Array.isArray(jumboCarouselData) && jumboCarouselData.length) {
      const imgs = jumboCarouselData.slice(0, 6);
      return (
        <Carousel
          prevIcon={ <SvgLoader className="CmsJumboCarousel_icon" xlinkHref="#left-arrow-icon"/> }
          nextIcon={ <SvgLoader className="CmsJumboCarousel_icon CmsJumboCarousel_icon-transform" xlinkHref="#left-arrow-icon"/> }
          className="CmsJumboCarousel"
        >
          { imgs.map((img, i) => {
            const { heading } = img;
            let { headingColour, subHeadingColour, buttonColour } = img;
            if (img.colour) {
              headingColour = img.colour;
              subHeadingColour = img.colour;
              buttonColour = img.colour;
            }
            return (
              <Carousel.Item key={ i } className="CmsJumboCarousel_item">
                <Link to={ img.to }>
                  <img className="CmsJumboCarousel_item_img" alt="jumbotron_img" src={ img.imgUrl } />
                  <Carousel.Caption bsClass={ `CmsJumboCarousel_item_caption ${img.align ? `CmsJumboCarousel_item_caption-${img.align}` : ''}` }>
                    <h3 className={ `CmsJumboCarousel_item_caption_heading ${headingColour ? `CmsJumboCarousel_item_caption_heading-${headingColour}` : ''}` }>
                      { typeof heading === 'string' ? heading.substring(0, BANNER_HEADING_LIMIT) : null }
                    </h3>
                    <p className={ `CmsJumboCarousel_item_caption_subHeading ${subHeadingColour ? `CmsJumboCarousel_item_caption_subHeading-${subHeadingColour}` : ''}  ` }>
                      { typeof img.subHeading === 'string' ? img.subHeading.substring(0, 32) : null }
                    </p>
                    <PrimaryLink
                      className={ `CmsJumboCarousel_item_caption_button ${buttonColour ? `CmsJumboCarousel_item_caption_button-${buttonColour}` : ''}` }
                      to={ img.to }
                      text={ typeof img.buttonText === 'string' ? img.buttonText.substring(0, 12) : null }
                    />
                  </Carousel.Caption>
                </Link>
              </Carousel.Item>
            );
          }) }
        </Carousel>
      );
    }
    console.error('Error: data error in cmsJumboCarousel; no carousel displayed!'); // eslint-disable-line no-console
    return null;
  }
}
