export const SET_VIEW = 'SET_VIEW';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const ADD_ANOTHER_USER_TOGGLE = 'ADD_ANOTHER_USER_TOGGLE';
export const UPDATE_ADDITIONAL_USERS_LIST = 'UPDATE_ADDITIONAL_USERS_LIST';
export const SET_FINAL_STEP_ERROR = 'SET_FINAL_STEP_ERROR';

export const setView = (action) => ({
  type: SET_VIEW,
  payload: action
});

export const toggleAddAnotherUser = (action) => ({
  type: ADD_ANOTHER_USER_TOGGLE,
  payload: action
});

export const updateAdditionalUsersList = (action) => ({
  type: UPDATE_ADDITIONAL_USERS_LIST,
  payload: action
});

export const setUserType = (action) => ({
  type: SET_USER_TYPE,
  payload: action
});

export const setFinalStepError = (action) => ({
  type: SET_FINAL_STEP_ERROR,
  payload: action
});

export const IS_LOGIN_LOADING = 'IS_LOGIN_LOADING';
export function isLoginLoading (isLoading = false) {
  return {
    type: IS_LOGIN_LOADING,
    payload: { isLoading }
  };
}
