import { createAction } from 'redux-actions';

export const CREATE_QUOTATION = 'quotationsActions.CREATE_QUOTATION';

export const createQuotation = (quotationName, products) => ({
  type: CREATE_QUOTATION,
  quotationName,
  products
});

export const CREATE_QUOTATION_SUCCESS = 'quotationsActions.CREATE_QUOTATION_SUCCESS';

export const createQuotationSuccess = () => ({
  type: CREATE_QUOTATION_SUCCESS
});

export const CREATE_QUOTATION_ERROR = 'quotationsActions.CREATE_QUOTATION_ERROR';

export const createQuotationError = (error) => ({
  type: CREATE_QUOTATION_ERROR,
  payload: {
    error
  }
});

export const FETCH_QUOTATIONS = 'quotationsActions.FETCH_QUOTATIONS';

export const fetchQuotations = () => ({
  type: FETCH_QUOTATIONS
});

export const FETCH_QUOTATIONS_SUCCESS = 'quotationsActions.FETCH_QUOTATIONS_SUCCESS';

export const fetchQuotationsSuccess = (quotations) => ({
  type: FETCH_QUOTATIONS_SUCCESS,
  quotations
});

export const ADD_PRODUCT_TO_QUOTATION = 'quotationsActions.ADD_PRODUCT_TO_QUOTATION';

export const addProductsToQuotation = (quotationId, products) => ({
  type: ADD_PRODUCT_TO_QUOTATION,
  quotationId,
  products
});

export const ADD_TO_QUOTATION_SUCCESS = 'quotationsActions.ADD_TO_QUOTATION_SUCCESS';

export const addToQuotationSuccess = () => ({
  type: ADD_TO_QUOTATION_SUCCESS
});

export const ADD_TO_QUOTATION_ERROR = 'quotationsActions.ADD_TO_QUOTATION_ERROR';

export const addToQuotationError = () => ({
  type: ADD_TO_QUOTATION_ERROR
});

export const REMOVE_PRODUCT_FROM_QUOTATION_CONFIRM = 'quotationsActions.REMOVE_PRODUCT_FROM_QUOTATION_CONFIRM';

export const removeProductFromQuotationConfirm = (quotationId, product, delQuotation) => ({
  type: REMOVE_PRODUCT_FROM_QUOTATION_CONFIRM,
  quotationId,
  product,
  delQuotation
});

export const REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS = 'quotationsActions.REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS';

export const removeProductFromQuotationSuccess = (quotationId, sku) => ({
  type: REMOVE_PRODUCT_FROM_QUOTATION_SUCCESS,
  quotationId,
  sku
});

export const FETCH_QUOTATION_DETAILS = 'quotationsActions.FETCH_QUOTATION_DETAILS';

export const fetchQuotationDetails = (quotationId, eligibleForDeliveryOptions, addressId) => ({
  type: FETCH_QUOTATION_DETAILS,
  quotationId,
  eligibleForDeliveryOptions,
  addressId
});

export const FETCH_QUOTATION_DETAILS_SUCCESS = 'quotationsActions.FETCH_QUOTATION_DETAILS_SUCCESS';

export const fetchQuotationDetailsSuccess = (details) => ({
  type: FETCH_QUOTATION_DETAILS_SUCCESS,
  details
});

export const UPDATE_QUOTATION_PRODUCT_QTY = 'quotationsActions.UPDATE_QUOTATION_PRODUCT_QTY';

export const updateQuotationProductQty = (quotationId, sku, qty, breakPrices) => ({
  type: UPDATE_QUOTATION_PRODUCT_QTY,
  quotationId,
  sku,
  qty,
  breakPrices
});

export const UPDATE_QUOTATION_PRODUCT_QTY_SUCCESS = 'quotationsActions.UPDATE_QUOTATION_PRODUCT_QTY_SUCCESS';

export const updateQuotationProductQtySuccess = (quotationId, sku, qty, breakPrices) => ({
  type: UPDATE_QUOTATION_PRODUCT_QTY_SUCCESS,
  quotationId,
  sku,
  qty,
  breakPrices
});

export const FETCH_QUOTATION_PRODUCT_STOCK_INFO = 'quotationsActions.FETCH_QUOTATION_PRODUCT_STOCK_INFO';

export const fetchQuotationProductStockInfo = (quotationId, sku, qty, isQuoteGlobal = false) => ({
  type: FETCH_QUOTATION_PRODUCT_STOCK_INFO,
  quotationId,
  sku,
  qty,
  isQuoteGlobal
});

export const FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS = 'quotationsActions.FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS';

export const fetchQuotationProductStockInfoSuccess = (quotationId, sku, stockInfo, isQuoteGlobal = false) => ({
  type: FETCH_QUOTATION_PRODUCT_STOCK_INFO_SUCCESS,
  quotationId,
  sku,
  stockInfo,
  isQuoteGlobal
});

export const ADD_QUOTATION_TO_CART = 'quotationsActions.ADD_QUOTATION_TO_CART';

export const addQuotationToCart = (quotationId, orderLines) => ({
  type: ADD_QUOTATION_TO_CART,
  quotationId,
  orderLines
});

export const SEND_QUOTATION_EMAIL = 'quotationsActions.SEND_QUOTATION_EMAIL';

export const sendQuotationEmail = (quotationId) => ({
  type: SEND_QUOTATION_EMAIL,
  quotationId
});

export const DELETE_QUOTATION_CONFIRM = 'quotationsActions.DELETE_QUOTATION_CONFIRM';

export const deleteQuotationConfirm = (quotationId, page) => ({
  type: DELETE_QUOTATION_CONFIRM,
  quotationId,
  page
});

export const DELETE_QUOTATION_SUCCESS = 'quotationsActions.DELETE_QUOTATION_SUCCESS';
export const deleteQuotationSuccess = (quotationId) => ({
  type: DELETE_QUOTATION_SUCCESS,
  quotationId: quotationId
});

export const LOCK_QUOTATION = 'quotationsActions.LOCK_QUOTATION';

export const lockQuotation = (quotationId, page) => ({
  type: LOCK_QUOTATION,
  quotationId: quotationId,
  page: page
});

export const REQUEST_REMOVE_QUOTE_LINE_CONFIRM = 'quotationsActions.REQUEST_REMOVE_QUOTE_LINE_CONFIRM';
export const requestRemoveQuoteLineConfirm = createAction(REQUEST_REMOVE_QUOTE_LINE_CONFIRM);

export const REQUEST_REMOVE_QUOTATION_CONFIRM = 'quotationsActions.REQUEST_REMOVE_QUOTATION_CONFIRM';
export const requestRemoveQuotationConfirm = createAction(REQUEST_REMOVE_QUOTATION_CONFIRM);

export const REQUEST_CHANGE_IN_QUOTE_LINE_QTY = 'quotationsActions.REQUEST_CHANGE_IN_QUOTE_LINE_QTY';

export const requestChangeInQuoteLineQty = (prevQty, quotationId, sku, qty, breakPrices) => ({
  type: REQUEST_CHANGE_IN_QUOTE_LINE_QTY,
  quotationId,
  prevQty,
  qty,
  sku,
  breakPrices: breakPrices.toJS()
});

export const CHANGE_IN_QUOTE_LINE_QTY_CONFIRM = 'quotationsActions.CHANGE_IN_QUOTE_LINE_QTY_CONFIRM';
export const changeInQuoteLineQtyConfirm = createAction(CHANGE_IN_QUOTE_LINE_QTY_CONFIRM);

// delivery option change
export const CHANGE_DELIVERY_OPTION_FROM_QUOTATION = 'quotationsActions.CHANGE_DELIVERY_OPTION_FROM_QUOTATION';
export const changeDeliveryOptionFromQuotation = (payload, quotationId) => ({
  type: CHANGE_DELIVERY_OPTION_FROM_QUOTATION,
  quotationId,
  payload
});

export const INIT_QUOTATION_DETAILS = 'quotationsActions.INIT_QUOTATION_DETAILS';

export const initQuotationDetails = (quotationId, eligibleForDeliveryOptions, addressId) => ({
  type: INIT_QUOTATION_DETAILS,
  quotationId,
  eligibleForDeliveryOptions,
  addressId
});

export const UPDATE_QUOTATION_ON_QTY_CHANGE = 'quotationsActions.UPDATE_QUOTATION_ON_QTY_CHANGE';

export const updateQuotationOnChange = (quotationId, quotationPrice, orderLines) => ({
  type: UPDATE_QUOTATION_ON_QTY_CHANGE,
  quotationId,
  quotationPrice,
  orderLines
});

export const UPDATE_QUOTE_STATE = 'UPDATE_QUOTE_STATE';

export const INIT_QUOTATIONS = 'quotationsActions.INIT_QUOTATIONS';

export const initQuotations = (payload) => ({
  type: INIT_QUOTATIONS,
  payload
});

export const INIT_QUOTATIONS_SUCCESS = 'quotationsActions.INIT_QUOTATIONS_SUCCESS';

export const quotationSuccess = (quotations) => ({
  type: INIT_QUOTATIONS_SUCCESS,
  quotations
});
