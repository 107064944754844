import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { getStyleConfigForPaypalButton } from 'client/utils/braintreeUtils';
import { setBraintreePaypalInstance, setPaypalData } from 'client/actions/braintreeGatewayActions';
import { getLocalizedString } from 'localization/localizer';
import { preparePaypalAddress } from './utils/preparePaypalAddress';
let paypal;
if (process.browser) {
  paypal = require('paypal-checkout');
}
export const PAYPAL_FLOW = 'vault';

export const PayByPaypalButton = ({
  braintreeEnvironment,
  setPaypalData,
  setBraintreePaypalInstance,
  paypalInstance,
  deliveryAddress,
  onClick
}) => {
  useEffect(() => {
    setBraintreePaypalInstance();
  }, []);

  const PayPalButton = (paypal) ? paypal.Button.driver('react', { React, ReactDOM }) : null;
  return (
    PayPalButton ? <PayPalButton data-e2e="PaymentMethodButton_payByPaypal" env={ braintreeEnvironment }
      style={ getStyleConfigForPaypalButton() }
      payment={ () => {
        return paypalInstance && paypalInstance.createPayment({
          flow: PAYPAL_FLOW,
          billingAgreementDescription: getLocalizedString('braintree.billingAgreementDescription'),
          enableShippingAddress: true,
          shippingAddressEditable: false,
          shippingAddressOverride: preparePaypalAddress(deliveryAddress)
        });
      } }
      onAuthorize={ (data) => {
        setPaypalData({
          data
        });
        onClick();
      } }
      onError={ () => { } }
    /> : null
  );
};

function mapStateToProps (state) {
  const addresses = state.getIn(['user', 'address']).toJS();
  return {
    deliveryAddress: addresses.delivery ? addresses.delivery : {},
    braintreeEnvironment: state.getIn(['config', 'braintreeEnvironment']),
    paypalInstance: state.getIn(['braintree', 'paypal', 'instance'])
  };
}

const mapDispatchToProps = {
  setPaypalData,
  setBraintreePaypalInstance
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByPaypalButton);
