import { put, call, takeLatest, select } from 'redux-saga/effects';

import { buildErrorMessageFromError } from 'client/utils/errorUtils';
import { goToUrl } from 'client/actions/routeActions';
import {
  REQUEST_REGISTER_CREDIT_ACCOUNT,
  REQUEST_ACCOUNT_ORDER_MESSAGE,
  validateBusinessAccountOrderSuccess,
  validateBusinessAccountOrderError
} from '../actions/creditAccountActions';
import {
  onApiButtonUnLoad
} from '../actions/ui/apiCallButtonActions';
import {
  gotoExternalUrl
} from 'client/actions/routeActions';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { createAccountForIndividuals, inviteUsersToTradeAccount, createAccountForBusiness } from 'client/components/elements/LoginAndRegistrationForm/businessAccountRegistration/utils.js';
import { setView } from 'client/actions/loginAndRegistration';
import { getJwtCustomerId } from 'shared/utils/jwtUtils.js';
import { setFinalStepError } from 'client/actions/loginAndRegistration';
import { CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED, YES } from 'client/components/elements/LoginAndRegistrationForm/constants';
import { setToken, getTradeAccountDataFromToken } from 'shared/utils/jwtUtils';
import {
  updateJWTToken
} from 'client/actions/tokenActions';
import { hideLoginDialog } from '../actions/ui/dialogActions';
import { getCart } from 'shared/endpoints/cartEndpoint';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { DELIVERY_CHECKOUT_ROUTE, CART_ROUTE, SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { isZoneTwoOrThree } from 'client/utils/userDataUtils';
import { saveAddress } from 'client/actions/addressActions';

const parcelForceZoneSelector = (state) => state.getIn(['user', 'accountDetails', 'parcelForceZone']);

export function * tryCreditAccountRegistration (action) {
  try {
    const { body: {
      userPayload,
      primaryContactForm,
      financeContactForm,
      creditLineApplicationForm,
      additionalUsersList,
      csAgentName
    }, currentPath } = action || {};
    const { token, longSessionToken } = yield call(createAccountForIndividuals, userPayload);
    const customerId = getJwtCustomerId(token);
    yield put(updateDataLayer({ event: 'new_account', crom_user_id: customerId }));
    yield call(setToken, token, longSessionToken);
    const { tradeAccountIsValidForStatement, tradeAccountIsValid } = getTradeAccountDataFromToken(token, longSessionToken);
    yield put(updateJWTToken({ token, longSessionToken, tradeAccountIsValidForStatement, tradeAccountIsValid }));

    const { accountNo } = yield call(createAccountForBusiness, {
      primaryContactForm,
      financeContactForm,
      creditLineApplicationForm,
      csAgentName
    });
    if (accountNo) {
      const { firstName, lastName, companyName = '' } = primaryContactForm;
      const {
        primary_addressLine1,
        primary_city,
        primary_postalCode,
        primary_state,
        primary_addressLine2
      } = creditLineApplicationForm;

      const address = {
        addressNickName: `${primary_addressLine1?.trim()}${primary_addressLine2?.trim()}`,
        companyName,
        firstName,
        lastName,
        city: primary_city?.trim(),
        state: primary_state?.trim(),
        postalCode: primary_postalCode?.trim(),
        addressLine1: primary_addressLine1?.trim(),
        addressLine2: primary_addressLine2?.trim()
      };

      const addressPayload = {
        addressType: 'delivery',
        address,
        fromRepeatOrder: false
      };
      yield put(saveAddress(addressPayload));
    }

    if (additionalUsersList?.length) {
      yield call(inviteUsersToTradeAccount, additionalUsersList, customerId, primaryContactForm);
      for (const user of additionalUsersList) {
        yield put(updateDataLayer({ event: 'multiUserAdded', accountNo, role: user.role }));
      }
    }

    yield put(updateDataLayer({ event: 'baApplicationSubmitted', accountNo }));
    if (primaryContactForm?.openCreditLine !== YES) {
      yield put(hideLoginDialog());
      if (CART_ROUTE === currentPath) {
        const cart = yield call(getCart);
        const parcelForceZone = yield select(parcelForceZoneSelector);
        const isEligibleForFreeDelivery = cart?.cartPrice.cartOrderLinesTotalWithVat >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
        if (isEligibleForFreeDelivery) {
          yield put(gotoExternalUrl(SINGLE_PAGE_CHECKOUT_ROUTE));
        } else {
          yield put(gotoExternalUrl(DELIVERY_CHECKOUT_ROUTE));
        }
      }
    } else {
      yield put(setView({ view: CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED }));
    }
  } catch (err) {
    yield put(setFinalStepError(buildErrorMessageFromError(err)));
  }
}

export function * watchCreditAccountRegistration () {
  yield takeLatest(REQUEST_REGISTER_CREDIT_ACCOUNT, tryCreditAccountRegistration);
}

export function * validateBusinessAccountOrder (payload) {
  try {
    const quotationId = payload.quotationId;
    yield put(validateBusinessAccountOrderSuccess({ quotationId }));

    yield put(onApiButtonUnLoad('payOnAccountButton'));

    if (quotationId) {
      yield put(goToUrl(`/checkout/order?fromQuotationId=${quotationId}&onAccount=y`));
    }
  } catch (error) {
    yield put(validateBusinessAccountOrderError(buildErrorMessageFromError(error)));
  }
}

export function * watchValdiateBusinessAccountOrder () {
  yield takeLatest(REQUEST_ACCOUNT_ORDER_MESSAGE, validateBusinessAccountOrder);
}
