import { replaceLastValueOfArray } from './arrayUtils';

export const capitalizeFirstWord = (str) => {
  const strArr = str.split(' ');
  const [firstWord, ...restOfTheStr] = strArr;
  const firstWordCapitalised = firstWord.toUpperCase();
  return [firstWordCapitalised, restOfTheStr].join(' ');
};

export const splitStringIntoLines = (value, maxCharsPerLine) => {
  const words = value.split(' ');

  const arrayOfLines = words.reduce((lines, word) => {
    const lastLineIndex = lines.length - 1;
    const doesLastLineExist = lines.length !== 0;

    const newValueOfLastLine = `${lines[lastLineIndex]} ${word}`;

    if (!doesLastLineExist || newValueOfLastLine.length >= maxCharsPerLine) {
      const newLine = word;
      return [...lines, newLine];
    }

    return replaceLastValueOfArray(lines, newValueOfLastLine);
  }, []);

  return arrayOfLines;
};
