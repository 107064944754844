import { createAction } from 'redux-actions';
export const CATEGORY_BROWSING_STARTED = 'categoryDropdownActions.CATEGORY_BROWSING_STARTED';

export const FETCH_CATEGORY_LIST = 'categoryDropdownActions.FETCH_CATEGORY_LIST';
export const SET_CATEGORY_LIST = 'categoryDropdownActions.SET_CATEGORY_LIST';

export const categoryBrowsingStarted = (category) => (
  {
    type: CATEGORY_BROWSING_STARTED,
    category
  }
);
export const fetchCategories = createAction(FETCH_CATEGORY_LIST);
export const loadCategories = createAction(SET_CATEGORY_LIST);
