import { all, fork, put, takeLatest, select, call } from 'redux-saga/effects';
import { orderSelectors, paymentMethodSelectors } from '../shared/selectors';
import { saveAddress } from 'client/actions/addressActions';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { APPLE_PAY } from 'shared/constants/braintree';
import { postOrderWithPaymentInformation } from 'shared/endpoints/ordersEndpoint';
import { getLocalizedString } from 'localization/localizer';
import { createOrder, handleResponse, orderFailed } from '../shared/orderHelpers';
import { INITIALIZE_APPLE_PAY, PAY_BY_APPLE_PAY, setData, setDetails } from './payByApplePayActions';
import { getDeviceData } from 'client/sagas/braintreeGatewaySagas';

export const applePayInstanceSelector = (state) => state.getIn(['braintree', 'applePay', 'instance']);
export const applePayDataSelector = (state) => state.getIn(['braintree', 'applePay', 'data']);

export function * initializeApplePay ({ payload }) {
  try {
    const applePayInstance = yield select(applePayInstanceSelector);
    if (applePayInstance) {
      const data = yield select(applePayDataSelector);
      yield put(setData({
        data,
        selector: payload.selector
      }));
      yield put(setDetails({
        details: data.details,
        selector: payload.selector
      }));
    }
  } catch (error) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

export function * payByApplePay ({ payload }) {
  try {
    const applePayData = yield select(paymentMethodSelectors.applePay.data(payload.selector));
    const billingAddress = {
      firstName: applePayData.billingAddress.givenName,
      lastName: applePayData.billingAddress.familyName,
      addressLine1: applePayData.billingAddress.addressLines[0],
      postalCode: applePayData.billingAddress.postalCode,
      city: applePayData.billingAddress.locality
    };

    yield put(saveAddress({ addressType: 'billing',
      address: billingAddress,
      redirectToUrl: SINGLE_PAGE_CHECKOUT_ROUTE }));

    const paymentInformation = {
      paymentType: APPLE_PAY,
      isPriceWithVat: yield select(orderSelectors.isPriceWithVat),
      deviceData: yield call(getDeviceData),
      braintreePayload: {
        nonce: applePayData.nonce,
        details: applePayData.details
      },
      shouldUpdateBillingAddress: true,
      csAgentName: yield select(orderSelectors.csAgent.name),
      storePaymentMethodStatus: true
    };

    const order = yield call(createOrder, APPLE_PAY, billingAddress, paymentInformation);

    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrderWithPaymentInformation, order);
    yield call(handleResponse, postOrderResponse);
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

function * watchInitializeApplePay () {
  yield takeLatest(INITIALIZE_APPLE_PAY, initializeApplePay);
}
function * watchPayByApplePay () {
  yield takeLatest(PAY_BY_APPLE_PAY, payByApplePay);
}
export function * watchAllPayByApplePaySagas () {
  yield all([
    fork(watchInitializeApplePay),
    fork(watchPayByApplePay)
  ]);
}
