export const LOGIN_VIEW = 1;
export const CREATE_INDIVIDUAL_ACCOUNT_VIEW = 2;
export const FORGOT_PASSWORD_VIEW = 3;
export const WELCOME_ACCOUNT_VIEW = 4;
export const CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW = 5;
export const CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW = 6;
export const CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW = 7;
export const CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW = 8;
export const CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW = 9;
export const CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW = 10;
export const CREATE_BUSINESS_ACCOUNT_FINAL_STEP_ONLY_REGISTER_VIEW = 11;
export const CREATE_BUSINESS_ACCOUNT_SUCCESSFULLY_REGISTERED = 12;

export const YES = 'Yes';
export const MAYBE_LATER = 'Maybe Later';

export const STANDARD = 'STANDARD';
export const USER = 'USER';
export const CREDIT = 'CREDIT';
export const CHARITY = 'Charity';
export const USER_TYPE_INDIVIDUALS = 'Individual';
export const USER_TYPE_BUSINESS = 'Business';
export const COMPANY_TYPE_FOR_REGNO = '';
export const INVITED = 'INVITED';
export const MONTHLY = 'MONTHLY';
export const FINANCE = 'FINANCE';
export const ADMIN = 'ADMIN';
export const TRADE_ACCOUNT = 'TRADE_ACCOUNT';

export const LOGIN_VIEW_IMAGE = 'https://assets.zoro.co.uk/Images/businessImages/login.jpg';
export const BUSINESS_ACCOUNT_IMAGE = 'https://assets.zoro.co.uk/Images/businessImages/business.jpg';
export const INDIVIDUAL_ACCOUNT_IMAGE = 'https://assets.zoro.co.uk/Images/businessImages/individual.jpg';

export const accountStatusMapper = {
  [STANDARD]: 'Standard',
  [FINANCE]: 'Finance',
  [ADMIN]: 'Admin'
};

export const COMPANY_INFO_URL = 'https://find-and-update.company-information.service.gov.uk/';
