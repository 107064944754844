import { createAction } from 'redux-actions';

export const ADD_PRODUCT_BUNDLE_LINE_ITEMS = 'ADD_PRODUCT_BUNDLE_LINE_ITEMS';
export const addProductBundleLineItems = createAction(ADD_PRODUCT_BUNDLE_LINE_ITEMS);

export const CLEAR_PRODUCT_BUNDLE_LINES = 'CLEAR_PRODUCT_BUNDLE_LINES';
export const clearProductBundleLines = createAction(CLEAR_PRODUCT_BUNDLE_LINES);

export const PRODUCT_BUNDLE_LINE_UPDATING_QUANTITY = 'PRODUCT_BUNDLE_LINE_UPDATING_QUANTITY';
export const updatingBundleQuantity = (id, flag, skuOfPrimaryProduct) => {
  return {
    type: PRODUCT_BUNDLE_LINE_UPDATING_QUANTITY,
    id,
    flag,
    skuOfPrimaryProduct
  };
};
