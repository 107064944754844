import { ApiRequest } from 'shared/utils/apiUtils';

export function publishLog (customerId, csAgentId, source = 'website') {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/log',
    body: { customerId, csAgentId, source }
  });
  return req.executeAndGetBody();
}
