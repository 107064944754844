import find from 'lodash/find';
import { fromJS } from 'immutable';
import moment from 'moment';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';

// Enables a general sale campaign with variable price reductions
// rather than a targeted discount sale via a saleObject in the Sales collection.
export const generalSaleObject = fromJS({
  name: 'Hardcoded General Sale',
  websiteLabel: undefined,
  startDate: '2017-09-01T15:00:00.000+0100',
  endDate: '2017-09-10T23:59:59.000+0100',
  banners: [
    {
      location: 'HERO',
      image: 'https://content.zoro.co.uk/content/images/promo/heroImages/2017_September_Sale_Webhero2.jpg',
      link: '/sale/cromwell-september-17-sale',
      showCountdown: false
    },
    {
      location: 'WIDE_RIBBON',
      text: `September Sale – Savings of up to 20% on selected items – Free Delivery on orders over £${FREE_DELIVERY_THRESHOLD}`,
      mobileText: 'September Sale – Savings of up to 20%',
      link: '/sale/cromwell-september-17-sale',
      css: {
        backgroundColor: '#f5a623',
        color: 'white',
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: 20,
        lineHeight: '40px',
        textAlign: 'center',
        textDecoration: 'none'
      },
      mobileCss: {
        backgroundColor: '#f5a623',
        color: 'white',
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: 20,
        lineHeight: '40px',
        textAlign: 'center',
        textDecoration: 'none',
        marginTop: 12
      }
    }
    // {
    //   location: 'RIBBON',
    //   image: 'https://content.zoro.co.uk/content/images/promo/heroImages/ribbon_images/2017_Spring_Sale2_Ribbon_Extended2.jpg',
    //   link: '/shop?general=In%20Stock',
    //   showCountdown: false,
    //   countdownStyle:
    //   {
    //     top: 2,
    //     left: 450,
    //     fontFamily: '"digital_dreamregular", Arial, sans-serif',
    //     fontSize: 20,
    //     color: '#ffffff'
    //   }
    // }
  ]
});

export const isSaleActive = (saleObject) => {
  if (saleObject) {
    const now = moment().utc();

    // Verify startDate (if defined)
    const startDateObject = saleObject.get('startDate');
    if (startDateObject) {
      const startDate = moment(startDateObject).utc();
      if (startDate.isAfter(now)) {
        return false;
      }
    }

    // Verify endDate (required)
    const endDate = moment(saleObject.get('endDate')).utc();
    if (endDate.isAfter(now)) {
      return true;
    }
  }

  return false;
};

export const timeLeftAsMomentDuration = (saleObject) => {
  const endsMoment = moment(saleObject.get('endDate')).utc();
  const nowMoment = moment().utc();
  const duration = moment.duration(endsMoment.diff(nowMoment));
  return duration;
};

export const getBannerData = (saleObject, bannerLocation) => {
  return find(saleObject.get('banners').toJS(), (banner) => banner.location === bannerLocation);
};

export const getActiveSale = (dbSaleObject) => {
  let result = null;
  if (isSaleActive(dbSaleObject)) {
    result = dbSaleObject;
  } else if (isSaleActive(generalSaleObject)) {
    result = generalSaleObject;
  }
  return result;
};
