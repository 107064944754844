import React from 'react';
import {
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILED
} from 'client/actions/checkoutActions';
import PayPalButtonWrapper from '../../elements/paymentMethod/PayByPaypal/payPalButtonWrapper';
import ApplePayButtonWrapper from '../../elements/paymentMethod/PayByApplePay/payByApplePayButtonWrapper';
import ApiCallButton from 'client/components/elements/apiCallButton/ApiCallButton';
import { POST_ORDER_FAILED, POST_ORDER_SUCCESS } from 'client/actions/orderReviewActions.js';
import { PrimaryButton } from '../../controls/StyledForms/formButtons';
const ORDER_REVIEW_SAVE_ORDER_LABEL = 'order.review.saveOrder';
const ORDER_REVIEW_PAY_ON_ACCOUNT_LABEL = 'order.review.payOnAccount';
const SINGLE_PAGE_CHECKOUT_BUY_NOW = 'singlePageCheckout.buyNow.button.name';
const CONTINUE_TO_PAYMENT = 'order.review.continue2payment';
import { POST_ORDER } from '../../../actions/ordersActions';
import { getLocalizedString } from 'localization/localizer';
import GooglePayButtonWrapper from 'client/components/elements/paymentMethod/PayByGooglePay/GooglePayButtonWrapper';

function OrderReviewButtonWrapper ({
  isSkipPaymentChecked,
  isPayPalSelected,
  isApplePaySelected,
  isGooglePaySelected,
  selector,
  isSinglePageCheckout,
  csAgentName,
  payOnAccount,
  placeOrderError,
  postOrderError,
  payPaldata,
  placeOrderBtnSelector,
  fromQuotationId,
  postOrder,
  placeOrderBtnDatae2e,
  validNonDeliverableStatus,
  showOnHoldModal,
  disabled
}) {
  const saveOrderLabelLocalizedString = csAgentName
    ? ORDER_REVIEW_SAVE_ORDER_LABEL
    : payOnAccount
      ? ORDER_REVIEW_PAY_ON_ACCOUNT_LABEL
      : isSinglePageCheckout
        ? SINGLE_PAGE_CHECKOUT_BUY_NOW
        : CONTINUE_TO_PAYMENT;
  const errorText = isSinglePageCheckout
    ? placeOrderError
    : postOrderError;
  const successAction = isSinglePageCheckout ? PLACE_ORDER_SUCCESS : POST_ORDER_SUCCESS;
  const errorAction = isSinglePageCheckout ? PLACE_ORDER_FAILED : POST_ORDER_FAILED;

  if (validNonDeliverableStatus) {
    return null;
  }

  if (disabled) {
    return <PrimaryButton disabled className="button-disabled">{ getLocalizedString(saveOrderLabelLocalizedString) }</PrimaryButton>;
  }

  if (!isSkipPaymentChecked && isPayPalSelected && !validNonDeliverableStatus) {
    return (
      <>
        <PayPalButtonWrapper
          selector={ placeOrderBtnSelector }
          onClick={ () => postOrder({ selector, quotationId: fromQuotationId }) }
          payPaldata={ payPaldata }
        />
      </>
    );
  }

  if (!isSkipPaymentChecked & isApplePaySelected) {
    return (
      <>
        <ApplePayButtonWrapper
          selector={ placeOrderBtnSelector }
          onClick={ () => postOrder({ selector, quotationId: fromQuotationId, validNonDeliverableStatus }) }
        />
      </>
    );
  }

  if (!isSkipPaymentChecked & isGooglePaySelected) {
    return (
      <>
        <GooglePayButtonWrapper
          selector={ placeOrderBtnSelector }
          errorText={ errorText }
          onClick={ () => postOrder({ selector, quotationId: fromQuotationId, validNonDeliverableStatus }) }
        />
      </>
    );
  }

  return (
    <>
      <ApiCallButton
        onClick={ () => postOrder({ selector, quotationId: fromQuotationId, validNonDeliverableStatus, showOnHoldModal }) }
        triggerAction = { POST_ORDER }
        onSuccessAction={ successAction }
        onErrorAction={ errorAction }
        selector={ placeOrderBtnSelector }
        datae2e={ placeOrderBtnDatae2e }
        text={ getLocalizedString(saveOrderLabelLocalizedString) }
        textWhenProcessing={ getLocalizedString('payment.label.pleaseWait') }
        textWhenComplete=''
        textWhenError={ errorText }
      />
    </>
  );
}

export default OrderReviewButtonWrapper;
