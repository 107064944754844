import num from 'num';

export const calculateZoroPromoRounding = (promoDiscount, shouldMultiplyByHundred = true) => {
  const discount = shouldMultiplyByHundred ? num(promoDiscount).mul(100) : promoDiscount;
  const parseToNumber = Number(discount.toString());
  return (Math.round(parseToNumber * 2) / 2).toString();
};

export const calculatePromoRounding = (promoDiscount) => {
  return promoDiscount > 0 ? calculateZoroPromoRounding(promoDiscount) : num(0).toString();
};

export const calculateDiscountPercentage = (discount1, discount2) => (
  num(discount1)
    .add(num(discount2))
    .sub(
      num(discount1).mul(num(discount2)).div(100)
    ).toString());
