import { createAction } from 'redux-actions';

export const STORE_FAMILIES_BY_CATEGORY = 'STORE_FAMILIES_BY_CATEGORY';
export const LOAD_PRODUCTS_BY_FAMILIES = 'LOAD_PRODUCTS_BY_FAMILIES';

export const storeFamiliesByCategory = (categoryId, families, totalCount, location) => {
  return {
    type: STORE_FAMILIES_BY_CATEGORY,
    categoryId,
    families,
    totalCount,
    location
  };
};

export const loadProductsByFamilies = (categoryId, families, skip, take, location) => ({
  type: LOAD_PRODUCTS_BY_FAMILIES,
  families,
  categoryId,
  skip,
  take,
  location
});

export const SET_CATEGORY_FAMILY_LOADING = 'SET_CATEGORY_FAMILY_LOADING';
export const setCategoryFamilyLoading = createAction(SET_CATEGORY_FAMILY_LOADING);

export const RECEIVE_CAT_FAMILY_STOCK_INFO = 'RECEIVE_CAT_FAMILY_STOCK_INFO';
export const receiveCatFamilyStockInfo = createAction(RECEIVE_CAT_FAMILY_STOCK_INFO);

export const UPDATE_CAT_FAMILY_PRICE_WITH_BREAK_PRICE = 'UPDATE_CAT_FAMILY_PRICE_WITH_BREAK_PRICE';
export const updateCatFamilyPriceWithBreakPrice = createAction(UPDATE_CAT_FAMILY_PRICE_WITH_BREAK_PRICE);
