import React from 'react';
import { UNBRANDED_BRANDNAME, BRAND_LENGTH_LIMIT } from 'shared/constants/recommendation';
import { Body } from '@graingerglobalonline/curly-eureka';
import { getTruncatedTitle } from '../../../../utils/recommendationUtils';

export const RecommendationBrandName = ({ brandName }) => {
  const truncatedBrandName = getTruncatedTitle(brandName, BRAND_LENGTH_LIMIT);
  return brandName !== UNBRANDED_BRANDNAME ? (
    <div className="RecommendationTile_BrandName">
      <Body variant="small" tag="p">
        <strong>{ truncatedBrandName }</strong>
      </Body>
    </div>
  ) : null;
};
