import { FrontendApiRequest } from 'shared/utils/apiUtils';

export const acceptBrowserRecommendation = () => {
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/browserRecommendation'
  });

  return req.executeAndGetBody();
};

export const setCookieCJEvent = (cjeventId) => {
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/cookieCJEvent',
    body: { cjeventId }
  });

  return req.executeAndGetBody();
};

export const getCJEventFromCookie = () => {
  const req = new FrontendApiRequest({
    method: 'GET',
    apiPath: 'api/cookieCJEvent'
  });

  return req.executeAndGetBody();
};

export const setCookieAWC = (awinId) => {
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/cookieAWC',
    body: { awinId }
  });

  return req.executeAndGetBody();
};

export const getAWCFromCookie = () => {
  const req = new FrontendApiRequest({
    method: 'GET',
    apiPath: 'api/cookieAWC'
  });

  return req.executeAndGetBody();
};

export const updateConsents = (body) => {
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/consent',
    body
  });

  return req.executeAndGetBody();
};

export const setCookieUserPreferences = (zoroUserPreferences) => {
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/userPreferences',
    body: { zoroUserPreferences }
  });

  return req.executeAndGetBody();
};

export const getUserPreferencesFromCookie = () => {
  const req = new FrontendApiRequest({
    method: 'GET',
    apiPath: 'api/userPreferences'
  });

  return req.executeAndGetBody();
};
