
export const REFRESH_ACCOUNT_USERS = 'REFRESH_ACCOUNT_USERS';
export const UPDATE_ACCOUNT_USERS = 'UPDATE_ACCOUNT_USERS';
export const CHECK_IF_USER_IS_REGISTERED = 'CHECK_IF_USER_IS_REGISTERED';
export const UPDATE_ACCOUNT_USER_FORM = 'UPDATE_ACCOUNT_USER_FORM';
export const IS_USER_REGISTERED = 'IS_USER_REGISTERED';
export const RESET_FORM_FIELDS = 'RESET_FORM_FIELDS';
export const INVITE_TO_ACCOUNT = 'INVITE_TO_ACCOUNT';
export const CHECK_EMAIL_FIELD_VALIDITY = 'CHECK_EMAIL_FIELD_VALIDITY';
export const RESEND_ACCOUNT_INVITATION = 'RESEND_ACCOUNT_INVITATION';
export const REFRESH_ACCOUNT_USERS_SUCCESS = 'REFRESH_ACCOUNT_USERS_SUCCESS';
export const UPDATE_ACCOUNT_USER_DETAILS = 'UPDATE_ACCOUNT_USER_DETAILS';
export const REFRESH_ACCOUNT_USER_DATA = 'REFRESH_ACCOUNT_USER_DATA';

export function refreshUserData (email) {
  return {
    type: REFRESH_ACCOUNT_USER_DATA,
    email
  };
}

export function refreshAccountUsers () {
  return {
    type: REFRESH_ACCOUNT_USERS
  };
}

export function updateUser (user) {
  return {
    type: UPDATE_ACCOUNT_USER_DETAILS,
    user
  };
}

export function refreshAccountUsersSuccess (usersList) {
  return {
    type: REFRESH_ACCOUNT_USERS_SUCCESS,
    usersList
  };
}

export function resendInvite (user) {
  return {
    type: RESEND_ACCOUNT_INVITATION,
    user
  };
}

export function updateAccountUsers (currentUser, key, value) {
  return {
    type: UPDATE_ACCOUNT_USERS,
    currentUser,
    key,
    value
  };
}

export function updateFormValues (value, field) {
  return {
    type: UPDATE_ACCOUNT_USER_FORM,
    value,
    field
  };
}

export function checkFieldValidity () {
  return {
    type: CHECK_EMAIL_FIELD_VALIDITY
  };
}

export function resetFormFields () {
  return {
    type: RESET_FORM_FIELDS
  };
}

export function isUserRegistered (flag) {
  return {
    type: IS_USER_REGISTERED,
    flag
  };
}

export function inviteUserToAccount (user) {
  return {
    type: INVITE_TO_ACCOUNT,
    user
  };
}
