
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { List, Map } from 'immutable';
import ProductVariantPrice from 'client/components/elements/productVariantPrice/ProductVariantPrice';
import {
  newRepeatOrderDetails,
  addProductsToRepeatOrder,
  displayCreateRepeatOrderForm,
  displayAddressForm,
  saveNewDeliveryAddress,
  setProductAndQuantity,
  setDefaultDeliveryAddress,
  onDeliveryAddressChange,
  requestAllNotExpiredSavedCards,
  changePaymentMethodSelected,
  createSubscription,
  resetNewRepeatOrderDetails,
  resetBannerStatuses, initialize, updateCurrentDisplay, resetDisplay,
  toggleAccordion
} from 'client/actions/repeatOrdersActions';
import { getUserAddresses } from 'client/actions/addressActions';
import AddToMySubscriptionForm from './AddToMySubscriptionForm';
import CreateNewSubscriptionForm from './CreateNewSubscriptionForm';
import SubscriptionOverview from './SubscriptionOverview';
import { PENDING, SUCCESS } from 'shared/constants/loadStateType';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { SubscriptionBanner } from 'client/components/elements/subscriptionDetails/SubscriptionBanner';
import { Link } from 'react-router';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import {
  STOP,
  ADD_TO_SUBSCRIPTION_FORM_NAME,
  NEW_SUBSCRIPTION_FORM_NAME,
  CREATE_NEW_SUBSCRIPTION_STATUS_BANNERONLY,
  CREATE_NEW_SUBSCRIPTION_STATUS_FAILED,
  CREATE_NEW_SUBSCRIPTION_STATUS_SUBMITTED,
  PROCESSING
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

if (process.browser) {
  require('./AddToMySubscription.scss');
}

const getBannerSubText = (subscriptions) => (<>
  { subscriptions && subscriptions.length === 1 ? getLocalizedString('createNewOrderForm.createdSuccesfully.subTextOne') : null }
  { getLocalizedString('createNewOrderForm.createdSuccesfully.subTextTwo') }
  <a
    href="/my-account/repeat-orders">
    <div className="AddToMySubscription_link">{ getLocalizedString('createNewOrderForm.orderSubscriptions') }</div>
  </a>
</>);

const getAddProductSubText = () => (<>
  { getLocalizedString('createNewOrderForm.createdSuccesfully.subTextTwo') }
  <a
    href="/my-account/repeat-orders">
    <div className="AddToMySubscription_link">{ getLocalizedString('createNewOrderForm.orderSubscriptions') }</div>
  </a>
</>);

export const AddToMySubscription = (props) => {
  useEffect(() => {
    props.initialize({ sku: props.sku, qty: props.newRepeatOrderQty });
  }, [props.isLoggedIn]);

  const addToSubscriptionOnQuantityChange = (data) => {
    props.setProductAndQuantity({ sku: props.sku, quantity: data.quantity, form: ADD_TO_SUBSCRIPTION_FORM_NAME });
  };

  const newSubscriptionOnQuantityChange = (data) => {
    props.setProductAndQuantity({ sku: props.sku, quantity: data.quantity, form: NEW_SUBSCRIPTION_FORM_NAME });
  };

  const createNewSubscriptionNextStep = (saveSubscription = false, handleSubmit, submit) => {
    if (saveSubscription) {
      return handleSubmit(submit)();
    }

    props.updateCurrentDisplay({
      display: 1
    });
  };

  const newSubscriptionFinalStep = () => {
    props.resetDisplay();
  };

  const createNewSubscriptionPreviousStep = () => {
    props.updateCurrentDisplay({
      display: -1
    });
  };

  const onAddressFormBackButtonClick = () => {
    const { addressList, displayAddressForm } = props;
    if (!addressList.length) {
      createNewSubscriptionPreviousStep();
      return;
    }

    displayAddressForm(false);
  };

  const {
    subscriptions,
    sku,
    name,
    addProductToSubscription,
    addressList,
    location,
    newRepeatOrderQty,
    confirmSubscriptionButtonStatus,
    isProductAvailable,
    newRepeatOrder,
    isPriceWithVat,
    confirmAddProductsToSubscriptionStatus,
    newRepeatOrderCreatedName,
    loadStateType,
    existingSubscriptionName,
    repeatOrderState,
    initializeStatus,
    product,
    toggleAccordion,
    sale
  } = props;
  const productImage = product.getIn(['mediaContent', 'mainPicture', 'productImage', 'highResolution'], '');
  const productBrand = product.getIn(['brand', 'brandName']);
  const isDeliverable = product.getIn(['stock', 'stockMessage', 'isDeliverable'], false);
  const qtyMultiplier = product.getIn(['price', 'qtyMultiplier'], 1);
  const minOrderQuantity = product.getIn(['price', 'minOrderQuantity'], 1);
  const maxOrderQuantity = product.getIn(['price', 'maxOrderQuantity']);
  const activeSubscriptions = subscriptions.filter(subscription => subscription.state !== STOP);
  const loadState = loadStateType ? loadStateType : PENDING;
  const currentDisplay = repeatOrderState && repeatOrderState.currentDisplay;
  const isCurrentDisplayOne = currentDisplay === 1;
  const showAddProductToSubscriptionForm = activeSubscriptions.length && isCurrentDisplayOne;
  const newSubscriptionCreationWasSuccessful = confirmSubscriptionButtonStatus === CREATE_NEW_SUBSCRIPTION_STATUS_SUBMITTED;
  const newSubscriptionCreationFailed = confirmSubscriptionButtonStatus === CREATE_NEW_SUBSCRIPTION_STATUS_FAILED;
  const isSubscription = newSubscriptionCreationWasSuccessful || newSubscriptionCreationFailed;
  const shouldShowSubscriptionBanner = repeatOrderState && repeatOrderState.showAddToSubscription;
  const showCreationResponseBanner = isSubscription && isCurrentDisplayOne;
  const shouldShowAddProductResponseBanner = !showCreationResponseBanner && isCurrentDisplayOne && shouldShowSubscriptionBanner;
  const showAddProductsResponseBanner = confirmAddProductsToSubscriptionStatus === CREATE_NEW_SUBSCRIPTION_STATUS_BANNERONLY && shouldShowAddProductResponseBanner;

  if (loadState === PENDING || initializeStatus === PROCESSING) {
    return (
      <div className="AddToMySubscription">
        <div className="AddToMySubscription_loading" />
      </div>
    );
  }

  const addToMySubscriptionQty = newRepeatOrder.quantitySelected ? newRepeatOrder.quantitySelected : newRepeatOrderQty || product.getIn(['price', 'minOrderQuantity'], 1);
  const createNewSubscriptionQty = newRepeatOrder.quantitySelected ? newRepeatOrder.quantitySelected : newRepeatOrderQty || product.getIn(['price', 'minOrderQuantity'], 1);

  if (loadState === SUCCESS) {
    return (<div className="container">
      <div className="AddToMySubscription col-xs-12">
        <div className={ `col-xs-12 col-md-8 AddToMySubscription_details` }>
          {
            showAddProductsResponseBanner ? <div className="AddToMySubscription_banner">
              <SubscriptionBanner
                text={ getLocalizedStringWithParam('createNewOrderForm.addProductsToSubscription.success', { subscriptionName: existingSubscriptionName }) }
                subtext={ getAddProductSubText() }
                icon="tick-circle" />
            </div>
              : null
          }
          { showCreationResponseBanner
            ? <div className="AddToMySubscription_banner">
              <SubscriptionBanner
                text={ getLocalizedStringWithParam('createNewOrderForm.createdSuccesfully.text', { subscriptionName: newRepeatOrderCreatedName }) }
                subtext={ getBannerSubText(subscriptions) }
                icon={ newSubscriptionCreationWasSuccessful ? 'tick-circle' : 'close-cross' } />
            </div>
            : null }

          { isCurrentDisplayOne
            ? <div className="AddToMySubscription_title" onClick={ () => toggleAccordion() }>
              { getLocalizedString('subscriptionForm.title.createNewSubscription') }
              <SvgLoader
                xlinkHref="arrow-down"
                className={ `AddToMySubscription_titleArrows ${repeatOrderState.showNewSubscription ? 'AddToMySubscription_titleArrows-up' : ''}` } />
            </div>
            : <div className="row AddToMySubscription_title-padding">
              <Link className="row AddToMySubscription_backButton" onClick={ () => createNewSubscriptionPreviousStep() }>
                <SvgLoader xlinkHref="left-arrow-icon" className="CreateNewOrderForm_arrowLeft" />
                { getLocalizedString('payment.label.button.back') }
              </Link>
              <div className="AddToMySubscription_title AddToMySubscription_title-center">
                { getLocalizedString('subscriptionForm.title.createNewSubscription') }
              </div>
            </div> }

          { repeatOrderState && repeatOrderState.showNewSubscription ? <CreateNewSubscriptionForm
            { ...props }
            quantity={ createNewSubscriptionQty }
            onAddressFormBackButtonClick={ onAddressFormBackButtonClick }
            newSubscriptionFinalStep={ newSubscriptionFinalStep }
            currentDisplay={ currentDisplay }
            createNewSubscriptionNextStep={ createNewSubscriptionNextStep }
            createNewSubscriptionPreviousStep={ createNewSubscriptionPreviousStep }
            onQuantityChange={ newSubscriptionOnQuantityChange }
            minOrderQuantity={ minOrderQuantity }
          /> : null }
          {
            showAddProductToSubscriptionForm
              ? <>
                <hr />
                <div className="AddToMySubscription_title" onClick={ () => toggleAccordion() }>
                  { getLocalizedString('subscriptionForm.title.existingSubscription') }
                  <SvgLoader
                    xlinkHref="arrow-down"
                    className={ `AddToMySubscription_titleArrows ${repeatOrderState.showAddToSubscription ? 'AddToMySubscription_titleArrows-up' : ''}` } />
                </div>
                { repeatOrderState && repeatOrderState.showAddToSubscription ? <AddToMySubscriptionForm
                  subscriptions={ subscriptions }
                  sku={ sku }
                  confirmAddProductsToSubscriptionStatus={ confirmAddProductsToSubscriptionStatus }
                  name={ name }
                  addProductToSubscription={ addProductToSubscription }
                  displayCreateRepeatOrderForm={ displayCreateRepeatOrderForm }
                  location={ location }
                  quantity={ addToMySubscriptionQty }
                  onQuantityChange={ addToSubscriptionOnQuantityChange }
                  isProductAvailable={ isProductAvailable }
                  isDeliverable={ isDeliverable }
                  qtyMultiplier={ qtyMultiplier }
                  minOrderQuantity={ minOrderQuantity }
                  maxOrderQuantity={ maxOrderQuantity }
                /> : null
                }
              </> : null
          }
        </div>
        <div className={ `col-xs-12 col-md-6 AddToMySubscription_subscriptionOverviewContainer` }>
          { product && product.get('name') ? <div className="AddToMySubscription_product">
            <ZoroImage
              src={ productImage }
              alt={ product.get('name') }
              className="AddToMySubscription_product-image"
            />
            <div className="AddToMySubscription_product-info">
              <a href={ product.get('productUrl') }>
                <div className="AddToMySubscription_product-title">
                  {product.get('name')}
                </div>
              </a>
              { productBrand && productBrand !== '(Unbranded)' ? <div className="AddToMySubscription_product-brand">
            By <b>{productBrand}</b>
              </div> : null }

              <div className="AddToMySubscription_product-brand">
            Order code: {sku}
              </div>
              <div>
                <ProductVariantPrice
                  price={ product.get('price') }
                  showBulk
                  sale={ sale }
                  sku={ sku }
                  isPriceWithVat={ isPriceWithVat }
                  qtyMultiplier= { qtyMultiplier }
                  minOrderQuantity= { minOrderQuantity }
                />
              </div>
            </div>
          </div> : null }
          <SubscriptionOverview
            deliveryAddressId={ props.deliveryAddressId }
            currentDisplay={ currentDisplay }
            isPriceWithVat={ isPriceWithVat }
            newRepeatOrder={ newRepeatOrder }
            addressList={ addressList }
            productPrice={ product.get('price') }
          />
        </div>
      </div>
    </div>);
  }
};

const mapDispatchToProps = (dispatch) => ({
  addProductToSubscription: (existingSubscriptionId, sku, subscriptionProductQuantity, orderReference, name) => dispatch(
    addProductsToRepeatOrder(
      existingSubscriptionId,
      sku,
      subscriptionProductQuantity,
      orderReference,
      name
    )
  ),
  displayCreateRepeatOrderForm: flowRight(dispatch, displayCreateRepeatOrderForm),
  displayAddressForm: flowRight(dispatch, displayAddressForm),
  newRepeatOrderDetails: (
    sku,
    subscriptionProductQuantity,
    orderReference,
    regularity,
    startDate,
    endDate,
    deliveryAddressId,
    outOfStockDeliveryPreference,
    name,
    price,
    createRepeatOrder
  ) => dispatch(
    newRepeatOrderDetails(
      sku,
      subscriptionProductQuantity,
      orderReference,
      regularity,
      startDate,
      endDate,
      deliveryAddressId,
      outOfStockDeliveryPreference,
      name,
      price,
      createRepeatOrder
    )
  ),
  setProductAndQuantity: flowRight(dispatch, setProductAndQuantity),
  saveNewDeliveryAddress: (address, createNewSubscriptionNextStep) => dispatch(saveNewDeliveryAddress(address, createNewSubscriptionNextStep)),
  getUserAddresses: flowRight(dispatch, getUserAddresses),
  setDefaultDeliveryAddress: flowRight(dispatch, setDefaultDeliveryAddress),
  onDeliveryAddressChange: flowRight(dispatch, onDeliveryAddressChange),
  requestAllNotExpiredSavedCards: flowRight(dispatch, requestAllNotExpiredSavedCards),
  changePaymentMethodSelected: flowRight(dispatch, changePaymentMethodSelected),
  createSubscription: flowRight(dispatch, createSubscription),
  resetNewRepeatOrderDetails: flowRight(dispatch, resetNewRepeatOrderDetails),
  resetBannerStatuses: flowRight(dispatch, resetBannerStatuses),
  initialize: flowRight(dispatch, initialize),
  updateCurrentDisplay: flowRight(dispatch, updateCurrentDisplay),
  resetDisplay: flowRight(dispatch, resetDisplay),
  toggleAccordion: flowRight(dispatch, toggleAccordion)
});

const getDefaultSelectedAddressId = (addressList) => {
  if (addressList && addressList.length) {
    const lastAddress = addressList[addressList.length - 1];
    return lastAddress ? lastAddress.addressId : 'undefined';
  }
};

const mapStateToProps = (state, ownProps) => {
  const product = state.getIn(['repeatOrders', 'newRepeatOrder', `${ownProps.sku}`], Map());
  const newRepeatOrder = state.getIn(['repeatOrders', 'newRepeatOrder'], Map()).toJS();
  const addressListState = state.getIn(['user', 'address', 'deliveryAddresses', 'data'], List());
  const addressList = addressListState === null ? [] : List(addressListState).toJS();
  const repeatOrderForm = state.getIn(['repeatOrders', 'form']);
  return {
    deliveryAddressId: state.getIn(['form', 'createNewSubscriptionForm', 'values', 'deliveryAddressId']),
    savedCards: state.getIn(['repeatOrders', 'savedCards'], List()).toJS(),
    subscriptions: state.getIn(['repeatOrders', 'all'], List()).toJS(),
    repeatOrderState: repeatOrderForm ? repeatOrderForm.toJS().state : {},
    addressList,
    selectedDeliveryAddress: state.getIn(['user', 'address', 'delivery']),
    showNewRepeatOrderForm: state.getIn(['repeatOrders', 'showNewRepeatOrderForm'], false),
    existingSubscriptionName: state.getIn(['repeatOrders', 'existingSubscriptionName']),
    newRepeatOrderCreatedName: state.getIn(['repeatOrders', 'newRepeatOrderCreatedName'], ''),
    showNewAddressForm: state.getIn(['repeatOrders', 'showNewAddressForm'], false),
    confirmSubscriptionButtonStatus: state.getIn(['repeatOrders', 'confirmSubscriptionButtonStatus']),
    confirmAddProductsToSubscriptionStatus: state.getIn(['repeatOrders', 'confirmAddProductsToSubscriptionStatus']),
    accountDetails: state.getIn(['user', 'accountDetails']),
    addToMySubscriptionQuantity: state.getIn(['repeatOrders', 'ADD_TO_SUBSCRIPTION', 'quantity']),
    createNewSubscriptionQuantity: ownProps.newRepeatOrderQty,
    setNewAddressAsSelected: state.getIn(['repeatOrders', 'setNewAddressAsSelected']),
    loadStateType: state.getIn(['repeatOrders', 'loadStateType']),
    defaultSelectedAddressId: getDefaultSelectedAddressId(addressList),
    initializeStatus: repeatOrderForm && repeatOrderForm.initialize,
    newRepeatOrder,
    product,
    isPriceWithVat: state.getIn(['user', 'isPriceWithVat']),
    isLoggedIn: state.getIn(['auth', 'isLoggedIn']),
    sale: state.get('sale')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToMySubscription);
