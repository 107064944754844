import React from 'react';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
import QuantityErrorMessage from 'client/components/elements/quantityInput/quantityErrorMessage';
import QuantityInput from 'client/components/elements/quantityInput/quantityInput';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import RadioButtonWithValidationNewStyles from 'client/components/elements/formField/RadioButtonWithValidationNewStyles';
import { Field } from 'redux-form/immutable';
import FormSelectField from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import { Map } from 'immutable';
import { FieldDatePicker } from 'client/components/elements/formField/FieldDatePicker';
import {
  WEEKLY,
  FORTNIGHTLY,
  MONTHLY,
  SEND_PARTIAL_ORDER,
  STOP_DELIVERY, SUBSCRIPTION_SELECTOR
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';

export const createNewSubscriptionContinueButton = (saveSubscription = false, handleSubmit, submit, createNewSubscriptionNextStep) => (
  <div className="CreateNewOrderForm_nextStepBtn row" data-e2e="ContinueContainer">
    <PrimaryButton
      text="Continue"
      className="CreateNewOrderForm_nextStepBtn"
      onClick={ () => createNewSubscriptionNextStep(saveSubscription, handleSubmit, submit) } />
  </div>
);

export const createSubscriptionButton = (createSubscription, newSubscriptionFinalStep, processSubscriptionState, sku) => {
  return (
    <div className="CreateNewOrderForm_nextStepBtn row" data-e2e="CreateNewOrderContainer">
      {processSubscriptionState === 'processing' ? <div className="CreateNewOrderForm_spinner" /> : <PrimaryButton
        text={ getLocalizedString('createNewScheduledOrder.button') }
        onClick={ () => createSubscription({ selector: SUBSCRIPTION_SELECTOR, sku }) }
        className="CreateNewOrderForm_nextStepBtn"
      />
      }
    </div>
  );
};

const regularity = [
  { value: WEEKLY },
  { value: FORTNIGHTLY },
  { value: MONTHLY }
];

const outOfStockDeliveryPreference = [
  { value: SEND_PARTIAL_ORDER, title: getLocalizedString('subscription.regularity.sendPartialOrder') },
  { value: STOP_DELIVERY, title: getLocalizedString('subscription.regularity.stopDelivery') }
];

export const createNewSubscriptionForm = (
  confirmSubscriptionButtonStatus,
  onQuantityChange,
  quantity,
  sku,
  qtyMultiplier,
  isQuantityValid,
  datePickersProps,
  minOrderQuantity
) => {
  const isSubscriptionStatusProcessing = confirmSubscriptionButtonStatus === 'processing';
  const {
    startDate,
    endDate,
    startDateMinDate,
    startDateMaxDate,
    endDateMinDate,
    disabled
  } = datePickersProps;
  return (
    <div className="form-group">
      { isSubscriptionStatusProcessing ? <div className="CreateNewOrderForm_loading"/> : null }
      <div className="row">
        <div className="col-xs-12 col-md-10">
          <Field
            name="orderReference"
            id="orderReference"
            disabled={ isSubscriptionStatusProcessing }
            className="CreateNewOrderForm_formGroup_input form-control"
            labelClassName="CreateNewOrderForm_formGroup_label CreateNewOrderForm_formGroup_label-required"
            type="text"
            labelResourceName="createNewOrderForm.label.subscriptionName"
            component={ FieldWithValidations }
            data-e2e="orderReference" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-2">
          <div className="CreateNewOrderForm_quantityInput">
            <div className="Quantity_label">{ getLocalizedString('myAccount.myOrders.label.quantity') }</div>
            <QuantityInput
              disabled={ isSubscriptionStatusProcessing }
              setQuantity={ onQuantityChange }
              product={ Map({ sku }) }
              quantity={ quantity }
              unavailableStatus={ true }
              qtyMultiplier={ qtyMultiplier }
            />
          </div>
          { !isQuantityValid ? <div className="AddToMySubscriptionForm_Form_Details_ErrorMessage">
            <QuantityErrorMessage
              quantity= { quantity }
              qtyMultiplier = { qtyMultiplier }
              shortMessage = { true }
              minOrderQuantity = { minOrderQuantity } />
          </div> : null }
        </div>
        <div className="col-xs-12 col-md-8 CreateNewOrderForm_regularity_input">
          <Field
            name="regularity"
            id="regularity"
            disabled={ isSubscriptionStatusProcessing }
            className="CreateNewOrderForm_formGroup_select form-control"
            labelClassName="CreateNewOrderForm_formGroup_label CreateNewOrderForm_formGroup_label-required"
            component={ FormSelectField }
            labelResourceName="createNewOrderForm.label.regularity"
            datae2e="regularity"
            value=""
          >
            <option key={ 0 } value="" disabled>{ getLocalizedString('createNewSubscriptionForm.selectRegularity') }</option>
            {
              regularity.map(
                (entry) => <option key={ entry.value } value={ entry.value }>{ entry.value }</option>
              )
            }
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-10">
          <Field
            component={ RadioButtonWithValidationNewStyles }
            name="outOfStockDeliveryPreference"
            labelResourceName="createNewOrderForm.label.outOfStockDeliveryPreference"
            labelClassName="CreateNewOrderForm_formGroup_label CreateNewOrderForm_formGroup_label-required"
            className="CreateNewOrderForm_formGroup_label form-control"
            options={ outOfStockDeliveryPreference }
            data-e2e="outOfStockDeliveryPreference"
            validatorName="outOfStockDeliveryPreference" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <Field
            name="startDate"
            id="startDate"
            updatedDate={ startDate }
            minDate={ startDateMinDate }
            maxDate={ startDateMaxDate }
            disabled={ disabled }
            fieldClassName="CreateNewOrderForm_startDatePicker"
            className="CreateNewOrderForm_startDate"
            datePickerPlacement="top-start"
            labelResourceName="createNewOrderForm.label.startDate"
            labelClassName="CreateNewOrderForm_formGroup_label CreateNewOrderForm_formGroup_label-required"
            component={ FieldDatePicker }
            data-e2e="startDate" />
        </div>
        <div className="col-xs-12 col-md-5">
          <Field
            name="endDate"
            id="endDate"
            updatedDate={ endDate }
            minDate={ endDateMinDate }
            disabled={ disabled }
            datePickerPlacement="top-start"
            className="CreateNewOrderForm_endDate"
            fieldClassName="CreateNewOrderForm_endDatePicker"
            labelClassName="CreateNewOrderForm_datePickerLabel"
            labelResourceName="createNewOrderForm.label.endDate"
            component={ FieldDatePicker }
            data-e2e="endDate" />
        </div>
      </div>
    </div>
  );
};
