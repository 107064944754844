import { createAction } from 'redux-actions';

export const FETCHING_RECOMMENDATIONS = 'FETCHING_RECOMMENDATIONS';
export const toggleFetchingRecommendations = createAction(FETCHING_RECOMMENDATIONS);

export const LOAD_FAMILY_RECOMMENDATIONS = 'LOAD_FAMILY_RECOMMENDATIONS';
export const loadFamilyRecommendations = createAction(LOAD_FAMILY_RECOMMENDATIONS);

export const FAMILY_RECOMMENDATIONS_SUCCESS = 'FAMILY_RECOMMENDATIONS_SUCCESS';
export const familyRecomendationsSuccess = createAction(FAMILY_RECOMMENDATIONS_SUCCESS);

export const REQUEST_RECOMMENDATIONS_STOCK_INFO = 'REQUEST_RECOMMENDATIONS_STOCK_INFO';
export const requestRecommendationsStockInfo = createAction(REQUEST_RECOMMENDATIONS_STOCK_INFO);

export const RECEIVE_RECOMMENDATIONS_STOCK_INFO = 'RECEIVE_RECOMMENDATIONS_STOCK_INFO';
export const receiveRecommendationsStockInfo = createAction(RECEIVE_RECOMMENDATIONS_STOCK_INFO);

export const UPDATE_RECOMMENDATIONS_PRODUCT_PRICE_WITH_BREAK_PRICE = 'UPDATE_RECOMMENDATIONS_PRODUCT_PRICE_WITH_BREAK_PRICE';
export const updateRecommendationsProductPriceWithBreakPrice = createAction(UPDATE_RECOMMENDATIONS_PRODUCT_PRICE_WITH_BREAK_PRICE);

export const STORE_RECOMMENDATIONS = 'STORE_RECOMMENDATIONS';
export const storeRecommendations = createAction(STORE_RECOMMENDATIONS);

export const UPDATE_RECOMMENDATION_APPLIED_DISCOUNT_INFO = 'UPDATE_RECOMMENDATION_APPLIED_DISCOUNT_INFO';
export const receiveRecommendationsAppliedDiscountInfo = createAction(UPDATE_RECOMMENDATION_APPLIED_DISCOUNT_INFO);

export const CLEAR_RECOMMENDATIONS = 'CLEAR_RECOMMENDATIONS';
export const clearRecommendations = createAction(CLEAR_RECOMMENDATIONS);
