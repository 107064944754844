import { createAction } from 'redux-actions';

export const TOGGLE_PAY_WITH_CARD = 'TOGGLE_PAY_WITH_CARD';
export const RECEIVE_SAVED_PAYMENT_METHODS_SUCCESS = 'RECEIVE_SAVED_PAYMENT_METHODS_SUCCESS';
export const RECEIVE_SAVED_PAYMENT_METHODS_FAIL = 'RECEIVE_SAVED_PAYMENT_METHODS_FAIL';
export const RECEIVE_BRAINTREE_DATA_COLLECTOR_SUCCESS = 'RECEIVE_BRAINTREE_DATACOLLECTOR_SUCCESS';
export const RECEIVE_BRAINTREE_CLIENT_TOKEN = 'RECEIVE_BRAINTREE_CLIENT_TOKEN';
export const RECEIVE_BRAINTREE_AUTHORIZATION_FAIL = 'RECEIVE_BRAINTREE_AUTHORIZATION_FAIL';
export const RESET_BRAINTREE_AUTHORIZATION = 'RESET_BRAINTREE_AUTHORIZATION';
export const SET_PAYMENT_METHOD_TYPE = 'SET_PAYMENT_METHOD_TYPE';
export const RECEIVE_BRAINTREE_PAYPAL_DATACOLLECTOR_SUCCESS = 'RECEIVE_BRAINTREE_PAYPAL_DATACOLLECTOR_SUCCESS';
export const RESET_BRAINTREE_PAYPAL_AUTHORIZATION = 'RESET_BRAINTREE_PAYPAL_AUTHORIZATION';
export const SET_NAME_ON_CARD = 'SET_NAME_ON_CARD';
export const RECEIVE_PAYMENT_INFORMATION = 'RECEIVE_PAYMENT_INFORMATION';
export const RECEIVE_PAYMENT_INFORMATION_SUCCESS = 'RECEIVE_PAYMENT_INFORMATION_SUCCESS';
export const TOGGLE_PAYPAL = 'TOGGLE_PAYPAL';
export const RESET_PAYMENT_METHOD_DATA = 'RESET_PAYMENT_METHOD_DATA';
export const UPDATE_HOSTED_FIELDS_STATUS = 'UPDATE_HOSTED_FIELDS_STATUS';
export const RECEIVE_BRAINTREE_CVV_HOSTED_FIELD_INSTANCE_SUCCESS = 'RECEIVE_BRAINTREE_CVV_HOSTED_FIELD_INSTANCE_SUCCESS';
export const CREATE_CVV_HOSTED_FIELD = 'CREATE_CVV_HOSTED_FIELD';
export const CVV_VERIFICATION_SUCCESS = 'CVV_VERIFICATION_SUCCESS';
export const CVV_VERIFICATION_FAILED = 'CVV_VERIFICATION_FAILED';
export const GENERATE_CVV_NONCE = 'GENERATE_CVV_NONCE';
export const SET_SELECTED_SAVED_CARD = 'SET_SELECTED_SAVED_CARD';
export const CVV_VERIFICATION_CANCEL = 'CVV_VERIFICATION_CANCEL';
export const SET_SAVED_CARDS = 'SET_SAVED_CARDS';
export const REQUEST_SCROLL_TO_REVIEW_PANEL = 'REQUEST_SCROLL_TO_REVIEW_PANEL';

export const updateHostedFieldsStatus = createAction(UPDATE_HOSTED_FIELDS_STATUS);
export const receiveBraintreeCvvHostedFieldInstance = createAction(RECEIVE_BRAINTREE_CVV_HOSTED_FIELD_INSTANCE_SUCCESS);
export const createCvvHostedField = createAction(CREATE_CVV_HOSTED_FIELD);
export const setCvvVerificationSuccess = createAction(CVV_VERIFICATION_SUCCESS);
export const setCvvVerificationFailed = createAction(CVV_VERIFICATION_FAILED);
export const generateCvvNonce = createAction(GENERATE_CVV_NONCE);
export const setCvvVerificationCancel = createAction(CVV_VERIFICATION_CANCEL);
export const scrollToReviewPanel = createAction(REQUEST_SCROLL_TO_REVIEW_PANEL);
