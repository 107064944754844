import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import { toggleDropdownMenu } from 'client/actions/ui/dropdownMenuActions';
import { categoryBrowsingStarted } from 'client/actions/ui/categoriesDropdownActions';
import { VISIBLE_DROPDOWN } from 'shared/constants/dropdownConstants';
import { NewCategoryList } from './NewCategoryList';
import { goToUrl } from 'client/actions/routeActions';
import { topCategories } from 'shared/constants/categories';
import { CATEGORY_ITEM_MENU, CATEGORY_WHITE_CROSS_SVG } from '../MenuItems/CategoryItem';

if (process.browser) {
  require('./CategoriesList.scss');
}

class CategoriesList extends Component {
  constructor (props) {
    super(props);
    this.anchorEl = null;
    this.setAnchorEl = (node) => {
      this.anchorEl = node;
    };
    this.state = {
      categoryId: topCategories[0].id,
      showSubCategories: false
    };
  }

  componentWillMount () { // eslint-disable-line react/no-deprecated
    process.browser && document.addEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount () {
    // Only for use on XS screen - CSS media query manages apply the behaviour
    document.body.classList.add('no-scroll');

    // TODO Hacky. Let's use CSSTransitionGroup at next opportunity and sort out the CSS.
    setTimeout(() => {
      this.anchorEl.classList.add('appear-from-top');
    }, 0);
  }

  componentWillUnmount () {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('mousedown', this.handleClick, false);
    this.anchorEl.classList.remove('appear-from-top');
  }

  handleClick = (event) => {
    if (!this.props.dropdownMenuVisible ||
      this.anchorEl.contains(event.target) ||
      event.srcElement.id === CATEGORY_ITEM_MENU ||
      event.srcElement.id === CATEGORY_WHITE_CROSS_SVG) {
      return;
    }
    this.props.closeCategoriesList();
  };

  setCategoryIdHandler = (id) => {
    this.setState({ categoryId: id, showSubCategories: true });
  }
  goBackHandler = () => {
    this.setState({ showSubCategories: false });
  }

  render () {
    const { categories, categoryBrowsingStarted, dropdownMenuVisible, isMobile, allCategories } = this.props;

    if (!categories) {
      return <div className="CategoriesDropdown">Loading</div>;
    }

    return (
      <div
        className={ `container category-list ${(dropdownMenuVisible ? 'show' : 'hide') + 'DowndownMenu'} category-list-newMenu` }
        ref={ this.setAnchorEl.bind(this) }
      >
        <NewCategoryList
          showSubCategories={ this.state.showSubCategories }
          isMobile={ isMobile }
          categories={ categories }
          allCategories={ allCategories }
          selectedCategoryId={ this.state.categoryId }
          categoryBrowsingStarted={ categoryBrowsingStarted }
          setCategoryIdHandler={ this.setCategoryIdHandler }
          goBackHandler={ this.goBackHandler }
          { ...this.props }/>
      </div>
    );
  }
}

CategoriesList.propTypes = {
  // toggleDropdownMenu: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  categoryBrowsingStarted: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const allCategories = state.getIn(['ui', 'categoriesDropdown', 'categoriesList'], fromJS([]));
  return {
    allCategories,
    unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS()
  };
};

const mapDispatchToProps = (dispatch) => ({
  categoryBrowsingStarted: (category) => dispatch(categoryBrowsingStarted(category)),
  closeCategoriesList: () => dispatch(toggleDropdownMenu(VISIBLE_DROPDOWN.NONE)),
  goToUrl: (url, handleInServer) => dispatch(goToUrl(url, handleInServer))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
