import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import React, { Component } from 'react';
import { List } from 'immutable';
import AddToBasketInterstitialScreen from './AddToBasketInterstitialScreen';
import {
  loadCart,
  setOrderLineQuantity,
  removeOrderLine,
  addToCart,
  setBundleQuantity,
  removeBundleOrderLine
} from 'client/actions/cartActions';
import {
  loadCmsPageData,
  requestCmsOffers,
  requestSkusData
} from 'client/actions/cmsActions';
import { showLoginDialog, showAddToQuotationDialog } from 'client/actions/ui/dialogActions';
import { getAccountDetails } from 'client/actions/accountActions';
import { isUserLoggedIn, hasValidLongSessionToken } from 'client/utils/routeUtils';
import { toastSuccess } from 'client/actions/showNotificationsActions';
import { goToUrl } from 'client/actions/routeActions';
import {
  applyDiscountCode,
  removeDiscountCode
} from 'client/actions/discountCodeActions';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { RECOMMENDATIONS_LOCATIONS } from 'shared/constants/recommendation';
import { DEFAULT_SHOW_FAMILY_RECOMMENDATIONS_ON_CHECKOUT } from 'shared/constants/abTesting';
import {
  mapFavouriteItemToDataLayerCartObject,
  mapItemToDataLayerCartObject,
  mapTopProductClickToDataLayerCartObject
} from 'shared/utils/sagaUtils';
import { TOP_PRODUCT_CLICK_EVENT } from './constants';

const HYPHEN = '-';

const mapStateToProps = (state, ownProps) => {
  const {
    params: {
      productVariantId,
      primaryProductVariantId
    }
  } = ownProps;
  const uppercaseProductVariantId = productVariantId && productVariantId.toUpperCase();
  const uppercasePrimaryProductVariantId = primaryProductVariantId && primaryProductVariantId.toUpperCase();
  const productSku = state.getIn(['cms', 'pages', 'PRODUCT', 'productSku']);
  return {
    cart: state.getIn(['cart']),
    discountCode: state.getIn(['discountCode']),
    isCartEditable: true,
    isLoggedIn: isUserLoggedIn(state),
    hasValidLongSessionToken: hasValidLongSessionToken(state),
    includeVat: state.getIn(['user', 'isPriceWithVat']),
    showFamilyRecommendationsOnCheckout: DEFAULT_SHOW_FAMILY_RECOMMENDATIONS_ON_CHECKOUT,
    csAgentName: state.getIn(['customerService', 'csAgentName']),
    publicHolidays: state.get('publicHolidays'),
    tradeAccount: state.getIn(['user', 'accountDetails', 'tradeAccount'], new Map()),
    productVariantId: uppercaseProductVariantId,
    productCmsId: productSku,
    pageData: state.getIn(['cms', 'pages', 'PRODUCT']),
    offers: state.getIn(['cms', 'offers']),
    skusData: state.getIn(['cms', 'skusData']),
    orders: state.getIn(['productLineItems', productVariantId]),
    productImage: state.getIn(['productLineItems', productVariantId, 'imageSrc']),
    productItem: state.getIn(['productLineItems', productVariantId], List()),
    qtyAddedToCart: state.getIn(['ui', 'addToBasket', 'qtyAddedToCart'], null),
    bundleProductItem: state.getIn(['productBundleLineItem',
      [productVariantId, primaryProductVariantId].join(HYPHEN)
    ]),
    primaryProductVariantId: uppercasePrimaryProductVariantId
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchRequestSkusData: (...args) => dispatch(requestSkusData(...args)),
  setOrderLineQuantity: flowRight(dispatch, setOrderLineQuantity),
  removeOrderLine: flowRight(dispatch, removeOrderLine),
  onApplyDiscountCode: flowRight(dispatch, applyDiscountCode),
  onRemoveDiscountCode: flowRight(dispatch, removeDiscountCode),
  addToCart: flowRight(dispatch, addToCart),
  loadCart: flowRight(dispatch, loadCart),
  showAddToQuotationDialog: flowRight(dispatch, showAddToQuotationDialog),
  showLoginDialog: flowRight(dispatch, showLoginDialog),
  getAccountDetails: flowRight(dispatch, getAccountDetails),
  toastSuccess: flowRight(dispatch, toastSuccess),
  requestCmsOffers: flowRight(dispatch, requestCmsOffers),
  loadCmsPageData: flowRight(dispatch, loadCmsPageData),
  goToUrl: flowRight(dispatch, goToUrl),
  setBundleQuantity: flowRight(dispatch, setBundleQuantity),
  removeBundleOrderLine: flowRight(dispatch, removeBundleOrderLine),
  updateDataLayer: flowRight(dispatch, updateDataLayer)
});

export class AddToBasketInterstitialInjectionWrapper extends Component {
  componentDidMount () {
    const {
      loadCart,
      getAccountDetails,
      loadCmsPageData,
      productVariantId,
      primaryProductVariantId
    } = this.props;

    getAccountDetails();
    const productSku = primaryProductVariantId || productVariantId;
    const cmsPageData = {
      productSku,
      type: 'PRODUCT'
    };

    loadCmsPageData(cmsPageData);
    loadCart({ showMultimessageToastInfo: false,
      location: RECOMMENDATIONS_LOCATIONS.basket_interstitial });
  }

  componentDidUpdate (prevProps) {
    const {
      updateDataLayer,
      productItem,
      location,
      productVariantId,
      primaryProductVariantId
    } = this.props;
    if (productItem?.size && productItem.size !== prevProps?.productItem?.size) {
      const currency = productItem.getIn(['price', 'currency'], 'GBP');
      const product = productItem.toJS();
      const productSku = primaryProductVariantId || productVariantId;
      const recommendationData = {
        recommendationType: location.query['recommendation-type'],
        recommendationPosition: location.query['recommendation-position'],
        recommendationLocation: location.query['recommendation-location']
      };
      const event = location?.query?.['recommendation-position']
        ? mapItemToDataLayerCartObject(product, currency, product.quantity, 'RecommendationAddToCart', recommendationData.recommendationType, recommendationData.recommendationPosition, recommendationData.recommendationLocation)
        : location?.query?.type === 'favourite'
          ? mapFavouriteItemToDataLayerCartObject(product, currency, product.quantity)
          : mapItemToDataLayerCartObject(product, currency, product.quantity, 'addToCart');
      updateDataLayer(event);

      if (location?.query?.event === TOP_PRODUCT_CLICK_EVENT) {
        const searchTerm = location.query.search_term || '';
        const event = mapTopProductClickToDataLayerCartObject(searchTerm, productSku);
        updateDataLayer(event);
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        <AddToBasketInterstitialScreen { ...this.props } />
      </React.Fragment>
    );
  }
}

export default
connect(mapStateToProps, mapDispatchToProps)(
  AddToBasketInterstitialInjectionWrapper
);
