import { takeLatest, call } from 'redux-saga/effects';
import { EMAIL_LINK_CLICKED } from 'client/actions/emailActions';
import { handleEmailClick } from 'client/utils/emailUtils';

export function * handleEmailLinkClicked (action) {
  try {
    yield call(handleEmailClick, action.payload);
  } catch (e) {
    console.error('Error handling email link click in emailSagas.js', e); // eslint-disable-line no-console
  }
}

export function * watchEmailLinkClicked () {
  yield takeLatest(EMAIL_LINK_CLICKED, handleEmailLinkClicked);
}
