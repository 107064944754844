import {
  SET_FINAL_DESTINATION,
  REMOVE_FINAL_DESTINATION,
  GET_LOGIN_IMAGES,
  REACHED_INTERSTITIAL_PAGE,
  UNLOAD_REACHED_INTERSTITIAL_PAGE,
  SET_REDIRECT_LOADING_STATE
} from 'client/actions/userActions';
import { fromJS } from 'immutable';

const defaultObject = {
  finalDestination: '/',
  loginImages: [
    { key: 'businessAccount', text: 'login.busineesaccount.headertext', showLearnMoreBtn: true }
  ],
  loginType: 'login'
};

const loginReducer = (state = fromJS(defaultObject), { type, finalDestination, actionToDispatch, isRedirectLoading }) => {
  switch (type) {
    case SET_REDIRECT_LOADING_STATE:
      return state
        .set('redirectLoadingState', isRedirectLoading);
    case SET_FINAL_DESTINATION:
      return state
        .set('finalDestination', finalDestination)
        .set('actionToDispatch', actionToDispatch)
        .set('redirectLoadingState', isRedirectLoading);

    case REACHED_INTERSTITIAL_PAGE:
      return state
        .set('reachedInterstitialPage', true)
        .set('redirectLoadingState', isRedirectLoading);
    case UNLOAD_REACHED_INTERSTITIAL_PAGE:
      return state
        .set('reachedInterstitialPage', false);
    case REMOVE_FINAL_DESTINATION:
      return state.delete('finalDestination').delete('actionToDispatch').delete('reachedInterstitialPage');
    case GET_LOGIN_IMAGES:
      return state;
    default:
      return state;
  }
};

export default loginReducer;
