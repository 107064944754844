import React, { Component } from 'react';
import { Link } from 'react-router';
import _truncate from 'lodash/truncate';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

export default class CmsCategoriesTile extends Component {
  render = () => (
    <Link to={ this.props.item.to }>
      <div className="CmsShoveler_Categories_Tile">
        <div className="CmsShoveler_Categories_Tile_img">
          <ZoroImage src={ this.props.item.imgUrl } alt={ this.props.item.name }/>
        </div>
        <p className="CmsShoveler_Categories_Tile_title">
          { _truncate(this.props.item.name, { 'length': 60, 'separator': /,? +/ }) }
        </p>
      </div>
    </Link>
  );
}
