import React from 'react';

if (process.browser) {
  require('./AgeRestrictedBadge.scss');
}

export const AgeRestrictedBadge = ({ className, children, ...props }) => (
  <div { ...props } className="AgeRestrictedBadge">
    <div>Age restricted product. <a href="/info/delivery" className="AgeRestrictedBadge_boldMessage">Delivery restrictions apply</a></div>
  </div>
);
