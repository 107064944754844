import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import flowRight from 'lodash/flowRight';
import AddToMySubscription from 'client/components/elements/addToMySubscription/AddToMySubscription';
import FamilyBottomRecommendations from 'client/components/elements/recommendations/composers/FamilyBottom';
import { RECOMMENDATIONS_LOCATIONS } from 'shared/constants/recommendation';
import { addToCart } from 'client/actions/cartActions';
import { loadFamilyRecommendations } from 'client/actions/recommendationsActions';

if (process.browser) {
  require('./subscriptionsWrapper.scss');
}

const SubscriptionsWrapper = (props) => {
  const { sku, qty } = props.location.query;
  useEffect(() => {
    props.loadFamilyRecommendations(fromJS({ location: RECOMMENDATIONS_LOCATIONS.subscriptions, familyId: props.productFamilyId }));
  }, [props.productFamilyId]);

  return <div className="subscriptionsWrapper">
    <AddToMySubscription
      sku={ sku }
      newRepeatOrderQty={ qty }/>
    <div className="row">
      <FamilyBottomRecommendations
        addToCart={ props.addToCart }
        location={ RECOMMENDATIONS_LOCATIONS.basket_interstitial }
        isPriceWithVat={ props.isPriceWithVat }
      />
    </div>
  </div>;
};

const mapDispatchToProps = (dispatch) => ({
  loadFamilyRecommendations: flowRight(dispatch, loadFamilyRecommendations),
  addToCart: flowRight(dispatch, addToCart)
});

const mapStateToProps = (state, ownProps) => {
  const { sku } = ownProps.location.query;
  return {
    isPriceWithVat: state.getIn(['user', 'isPriceWithVat'], true),
    productFamilyId: state.getIn(['repeatOrders', 'newRepeatOrder', sku, 'family', 'familyId'], '')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SubscriptionsWrapper
);
