import { put, takeLatest, call } from 'redux-saga/effects';
import { updateUnleashUserId } from 'shared/endpoints/unleashEndpoint';
import { REQUEST_UNLEASH_VALUES, setUnleashValuesSuccess } from '../actions/unleashActions';

export function * onRequestUnleashOverride (action) {
  try {
    const data = yield call(updateUnleashUserId, action.payload.unlFeature, action.payload.unlVariant);
    yield put(setUnleashValuesSuccess(data));
  } catch (err) {
    throw new Error(err);
  }
}

export function * watchUnleashOverrideValuesSuccess () {
  yield takeLatest(REQUEST_UNLEASH_VALUES, onRequestUnleashOverride);
}
