import { Map, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import moment from 'moment';

import {
  SHOW_LOGIN_DIALOG,
  HIDE_LOGIN_DIALOG,
  SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY,
  SET_REMOVE_PRODUCT_DIALOG_VISIBILITY,
  SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY,
  SHOW_REMOVE_QUOTE_LINE_DIALOG,
  HIDE_REMOVE_QUOTE_LINE_DIALOG,
  SHOW_REMOVE_QUOTATION_DIALOG,
  HIDE_REMOVE_QUOTATION_DIALOG,
  SHOW_REMOVE_ADDRESS_DIALOG,
  HIDE_REMOVE_ADDRESS_DIALOG,
  SHOW_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG,
  HIDE_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG,
  SHOW_ADD_TO_QUOTATION_DIALOG,
  SHOW_ADD_TO_BASKET_DIALOG,
  HIDE_ADD_TO_BASKET_DIALOG,
  HIDE_ADD_TO_QUOTATION_DIALOG,
  SHOW_CUSTOM_ALERT_DIALOG,
  HIDE_CUSTOM_ALERT_DIALOG,
  SHOW_DISCOUNT_CODE_DIALOG,
  HIDE_DISCOUNT_CODE_DIALOG,
  SHOW_CHANGE_IN_QUOTE_LINE_QTY_DIALOG,
  HIDE_CHANGE_IN_QUOTE_LINE_QTY_DIALOG,
  SHOW_REMOVE_REPEAT_ORDER_DIALOG,
  HIDE_REMOVE_REPEAT_ORDER_DIALOG,
  SHOW_PAUSE_PLAY_REPEAT_ORDER_DIALOG,
  HIDE_PAUSE_PLAY_REPEAT_ORDER_DIALOG,
  SHOW_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG,
  HIDE_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG,
  SHOW_EDIT_REPEAT_ORDER_DIALOG,
  HIDE_EDIT_REPEAT_ORDER_DIALOG,
  SHOW_GLOBAL_QUOTE_REF_REMINDER,
  HIDE_GLOBAL_QUOTE_REF_REMINDER,
  HIDE_LOCKING_AND_SAVING_QUOTE_DIALOG,
  SHOW_LOCKING_AND_SAVING_QUOTE_DIALOG,
  SHOW_SAVING_QUOTE_DIALOG,
  HIDE_SAVING_QUOTE_DIALOG,
  SHOW_REMOVE_PAYMENT_METHOD_DIALOG,
  HIDE_REMOVE_PAYMENT_METHOD_DIALOG,
  TOGGLE_LOGIN_PAGE,
  GO_TO_CONTACT_DETAILS,
  DEFAULT_LOGIN_VALUES,
  GO_TO_GET_STARTED,
  GO_TO_RESET_PASSWORD,
  SHOW_BRAINTREE_CVV_CHECK_DIALOG,
  HIDE_BRAINTREE_CVV_CHECK_DIALOG,
  SHOW_CANCEL_ORDER_CONFIRMATION_DIALOG,
  HIDE_CANCEL_ORDER_CONFIRMATION_DIALOG,
  SET_CANCEL_ORDER_CHECKED,
  SHOW_CHECKED_ERROR,
  HIDE_MASK_CALL_DIALOG,
  SHOW_MASK_CALL_DIALOG,
  SHOW_BUSINESS_ACCOUNT_ONHOLD_DIALOG,
  HIDE_BUSINESS_ACCOUNT_ONHOLD_DIALOG,
  HIDE_CANCEL_RETURN_CONFIRMATION_DIALOG,
  SHOW_VALIDATION_MESSAGE_DIALOG,
  SHOW_RETURN_CONFIRMATION_DIALOG,
  SHOW_NON_DELIVERABLE_ORDER_MESSAGE,
  HIDE_NON_DELIVERABLE_ORDER_MESSAGE,
  SHOW_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG,
  HIDE_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG
} from 'client/actions/ui/dialogActions';

import {
  CREATE_QUOTATION_SUCCESS,
  ADD_TO_QUOTATION_SUCCESS
} from 'client/actions/quotationsActions';

export const ADD_TO_QUOTATION_STATUS__UNDEFINED = 0;
export const ADD_TO_QUOTATION_STATUS__SUCCESS = 1;

export const defaultState = fromJS({
  login: {
    isVisible: false,
    isLoginPage: true,
    showContactDetails: false,
    isResetPassword: false
  },
  addressForm: {
    isVisible: false
  },
  quickOrderForm: {
    isVisible: false
  },
  changeAccountDetails: {
    isVisible: false
  },
  unsupportedBrowser: {
    isVisible: false
  },
  removeProduct: {
    isVisible: false,
    orderLine: Map({})
  },
  removeQuoteLine: {
    isVisible: false,
    quoteLine: Map({})
  },
  removeQuotation: {
    isVisible: false
  },
  fullBlogPost: {
    isVisible: false,
    currentBlog: {}
  },
  discountTermsAndConditions: {
    isVisible: false
  },
  addToQuotation: {
    isVisible: false,
    products: undefined,
    status: ADD_TO_QUOTATION_STATUS__UNDEFINED
  },
  addToBasket: {
    isVisible: false,
    product: undefined
  },
  customAlert: {
    isVisible: false,
    message: undefined
  },
  globalQuoteReminder: {
    isVisible: false,
    nextPageUrl: undefined
  },
  saveAndLockGlobalQuote: {
    isVisible: false
  },
  saveQuoteToList: {
    isVisible: false
  },
  removePaymentMethod: {
    isVisible: false,
    cardType: '',
    cardLastFourDigits: ''
  },
  braintreeCvvCheck: {
    isVisible: false
  },
  cancelOrderConfirmation: {
    isVisible: false,
    isChecked: false,
    showError: false
  },
  returnRefund: {
    isVisible: false,
    showReturnConfirmation: false
  },
  validationDialog: {
    isVisible: false,
    title: '',
    bodyText: ''
  },
  businessAccountOnHold: {
    isVisible: false
  }
});

export const actionMap = {
  [HIDE_SAVING_QUOTE_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['saveQuoteToList', 'isVisible'], (value) => false),
  [SHOW_SAVING_QUOTE_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['saveQuoteToList', 'isVisible'], (value) => true),
  [HIDE_LOCKING_AND_SAVING_QUOTE_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['saveAndLockGlobalQuote', 'isVisible'], (value) => false),
  [SHOW_LOCKING_AND_SAVING_QUOTE_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['saveAndLockGlobalQuote', 'isVisible'], (value) => true),

  [HIDE_MASK_CALL_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['maskCall', 'isVisible'], (value) => false),
  [SHOW_MASK_CALL_DIALOG]: (state = defaultState, action) =>
    state
      .updateIn(['maskCall', 'isVisible'], (value) => true)
      .updateIn(['maskCall', 'variant'], (value) => action.variant),

  [SHOW_GLOBAL_QUOTE_REF_REMINDER]: (state = defaultState, action) =>
    state
      .setIn(['globalQuoteReminder', 'isVisible'], true)
      .setIn(['globalQuoteReminder', 'nextPageUrl'], action.nextPageUrl),
  [HIDE_GLOBAL_QUOTE_REF_REMINDER]: (state = defaultState, action) =>
    state
      .setIn(['globalQuoteReminder', 'isVisible'], false)
      .setIn(['globalQuoteReminder', 'nextPageUrl'], ''),
  [DEFAULT_LOGIN_VALUES]: (state = defaultState) =>
    state
      .setIn(['login', 'isVisible'], true)
      .setIn(['login', 'showContactDetails'], false)
      .setIn(['login', 'isLoginPage'], true)
      .setIn(['login', 'isResetPassword'], false),

  [GO_TO_GET_STARTED]: (state = defaultState) =>
    state
      .setIn(['login', 'showContactDetails'], false)
      .setIn(['login', 'isLoginPage'], false),
  [GO_TO_RESET_PASSWORD]: (state = defaultState) =>
    state
      .setIn(['login', 'isResetPassword'], true)
      .setIn(['login', 'isLoginPage'], false),

  [GO_TO_CONTACT_DETAILS]: (state = defaultState, action) =>
    state.setIn(['login', 'showContactDetails'], !state.getIn(['login', 'showContactDetails'])),

  [TOGGLE_LOGIN_PAGE]: (state = defaultState, action) =>
    state
      .setIn(['login', 'isLoginPage'], !state.getIn(['login', 'isLoginPage']))
      .setIn(['login', 'isResetPassword'], false)
      .setIn(['login', 'showContactDetails'], false),

  [SHOW_LOGIN_DIALOG]: (state = defaultState, action) =>
    state
      .setIn(['login', 'isVisible'], true)
      .setIn(['login', 'finalDestination'], action.finalDestination)
      .setIn(['login', 'actionToDispatch'], action.actionToDispatch),
  [SHOW_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG]: (state = defaultState) =>
    state
      .setIn(['newLoginExitConfirmation', 'isVisible'], true),
  [HIDE_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG]: (state = defaultState) =>
    state
      .setIn(['newLoginExitConfirmation', 'isVisible'], false),
  [HIDE_LOGIN_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['login', 'isVisible'], (value) => false),

  [SET_REMOVE_PRODUCT_DIALOG_VISIBILITY]: (state = defaultState, { visible, orderLine }) => state
    .setIn(['removeProduct', 'orderLine'], fromJS(orderLine))
    .setIn(['removeProduct', 'isVisible'], visible),

  [SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY]: (state = defaultState, { visible, bundleLine }) => state
    .setIn(['removeProductBundle', 'bundleLine'], fromJS(bundleLine ? bundleLine : {}))
    .setIn(['removeProductBundle', 'isVisible'], visible),

  [SHOW_REMOVE_QUOTE_LINE_DIALOG]: (state = defaultState, { quoteLine, delQuotation }) => state
    .setIn(['removeQuoteLine', 'quoteLine'], quoteLine)
    .setIn(['removeQuoteLine', 'isVisible'], true)
    .setIn(['removeQuoteLine', 'delQuotation'], delQuotation),

  [HIDE_REMOVE_QUOTE_LINE_DIALOG]: (state = defaultState, { quoteLine }) => state
    .setIn(['removeQuoteLine', 'quoteLine'], fromJS(quoteLine))
    .setIn(['removeQuoteLine', 'isVisible'], false)
    .setIn(['removeQuoteLine', 'delQuotation'], false),

  [SHOW_REMOVE_QUOTATION_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeQuotation', 'isVisible'], true),

  [HIDE_REMOVE_QUOTATION_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeQuotation', 'isVisible'], false),

  [SHOW_REMOVE_ADDRESS_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeAddress', 'isVisible'], true),

  [HIDE_REMOVE_ADDRESS_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeAddress', 'isVisible'], false),

  [SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY]: (state = defaultState, { payload }) =>
    state.setIn(['unsupportedBrowser', 'isVisible'], payload),

  [SHOW_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['discountTermsAndConditions', 'isVisible'], (value) => true),

  [HIDE_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG]: (state = defaultState, action) =>
    state.updateIn(['discountTermsAndConditions', 'isVisible'], (value) => false),

  [SHOW_ADD_TO_QUOTATION_DIALOG]: (state = defaultState, { products }) =>
    state
      .setIn(['addToQuotation', 'products'], fromJS(products))
      .setIn(['addToQuotation', 'status'], ADD_TO_QUOTATION_STATUS__UNDEFINED)
      .setIn(['addToQuotation', 'isVisible'], true),

  [SHOW_ADD_TO_BASKET_DIALOG]: (state = defaultState, { products }) =>
    state
      .setIn(['addToBasket', 'product'], fromJS(products))
      .setIn(['addToBasket', 'isVisible'], true),

  [HIDE_ADD_TO_BASKET_DIALOG]: (state = defaultState, action) =>
    state.setIn(['addToBasket', 'isVisible'], false),

  [HIDE_ADD_TO_QUOTATION_DIALOG]: (state = defaultState, action) =>
    state.setIn(['addToQuotation', 'isVisible'], false),

  [CREATE_QUOTATION_SUCCESS]: (state = defaultState) =>
    state
      .setIn(['addToQuotation', 'status'], ADD_TO_QUOTATION_STATUS__SUCCESS),

  [ADD_TO_QUOTATION_SUCCESS]: (state = defaultState) =>
    state
      .setIn(['addToQuotation', 'status'], ADD_TO_QUOTATION_STATUS__SUCCESS),

  [SHOW_CUSTOM_ALERT_DIALOG]: (state = defaultState, { message }) =>
    state
      .setIn(['customAlert', 'message'], message)
      .setIn(['customAlert', 'isVisible'], true),

  [HIDE_CUSTOM_ALERT_DIALOG]: (state = defaultState, action) =>
    state.setIn(['customAlert', 'isVisible'], false),

  [SHOW_DISCOUNT_CODE_DIALOG]: (state = defaultState, action) => {
    if (process.browser) {
      return state
        .updateIn(['discount', 'show'], (value) => (state.getIn(['discount', 'shown']) !== true))
        .updateIn(['discount', 'shown'], (value) => true);
    }
    return state;
  },

  [HIDE_DISCOUNT_CODE_DIALOG]: (state = defaultState, action) => state
    .updateIn(['discount', 'show'], (value) => false),

  [SHOW_CHANGE_IN_QUOTE_LINE_QTY_DIALOG]: (state = defaultState, { visible, quotationId, qty, sku, breakPrices }) =>
    state
      .setIn(['quoteLineQtyChange', 'isVisible'], visible)
      .setIn(['quoteLineQtyChange', 'quotationId'], quotationId)
      .setIn(['quoteLineQtyChange', 'qty'], qty)
      .setIn(['quoteLineQtyChange', 'sku'], sku)
      .setIn(['quoteLineQtyChange', 'breakPrices'], breakPrices),

  [HIDE_CHANGE_IN_QUOTE_LINE_QTY_DIALOG]: (state = defaultState, { visible }) =>
    state
      .setIn(['quoteLineQtyChange', 'isVisible'], visible),

  [SHOW_REMOVE_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeRepeatOrder', 'isVisible'], true),

  [HIDE_REMOVE_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeRepeatOrder', 'isVisible'], false),

  [SHOW_PAUSE_PLAY_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['pausePlayRepeatOrder', 'isVisible'], true)
    .setIn(['pausePlayRepeatOrder', 'repeatOrder'], action.repeatOrder)
    .setIn(['pausePlayRepeatOrder', 'newState'], action.newState)
    .setIn(['pausePlayRepeatOrder', 'nextDeliveryDate'], action.nextDeliveryDate),

  [HIDE_PAUSE_PLAY_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['pausePlayRepeatOrder', 'isVisible'], false)
    .setIn(['pausePlayRepeatOrder', 'repeatOrder'], Map({}))
    .setIn(['pausePlayRepeatOrder', 'newState'], null)
    .setIn(['pausePlayRepeatOrder', 'nextDeliveryDate'], null),

  [SHOW_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeRepeatOrderProduct', 'isVisible'], true),

  [HIDE_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['removeRepeatOrderProduct', 'isVisible'], false),

  [SHOW_EDIT_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['editRepeatOrder', 'isVisible'], true)
    .setIn(['editRepeatOrder', 'repeatOrderReference'], action.orderReference)
    .setIn(['editRepeatOrder', 'repeatOrderId'], action.repeatOrderId)
    .setIn(['editRepeatOrder', 'newRegularity'], action.newRegularity)
    .setIn(['editRepeatOrder', 'endDate'], action.endDate ? moment(action.endDate).format('MMMM Do, YYYY') : null),

  [HIDE_EDIT_REPEAT_ORDER_DIALOG]: (state = defaultState, action) => state
    .setIn(['editRepeatOrder', 'isVisible'], false)
    .setIn(['editRepeatOrder', 'repeatOrderReference'], null)
    .setIn(['editRepeatOrder', 'repeatOrderId'], null)
    .setIn(['editRepeatOrder', 'newRegularity'], null)
    .setIn(['editRepeatOrder', 'endDate'], null),

  [SHOW_REMOVE_PAYMENT_METHOD_DIALOG]: (state = defaultState, action) => state
    .setIn(['removePaymentMethod', 'isVisible'], true)
    .setIn(['removePaymentMethod', 'cardType'], action.cardType)
    .setIn(['removePaymentMethod', 'cardLastFourDigits'], action.cardLastFourDigits),

  [HIDE_REMOVE_PAYMENT_METHOD_DIALOG]: (state = defaultState, action) => state
    .setIn(['removePaymentMethod', 'isVisible'], false)
    .setIn(['removePaymentMethod', 'cardType'], '')
    .setIn(['removePaymentMethod', 'cardLastFourDigits'], ''),

  [SHOW_BRAINTREE_CVV_CHECK_DIALOG]: (state = defaultState, action) => state
    .setIn(['braintreeCvvCheck', 'isVisible'], true),

  [HIDE_BRAINTREE_CVV_CHECK_DIALOG]: (state = defaultState, action) => state
    .setIn(['braintreeCvvCheck', 'isVisible'], false),

  [HIDE_CANCEL_ORDER_CONFIRMATION_DIALOG]: (state = defaultState, action) => state
    .setIn(['cancelOrderConfirmation', 'isVisible'], false)
    .setIn(['cancelOrderConfirmation', 'isChecked'], false)
    .setIn(['cancelOrderConfirmation', 'showError'], false),

  [SHOW_CANCEL_ORDER_CONFIRMATION_DIALOG]: (state = defaultState, action) => state
    .setIn(['cancelOrderConfirmation', 'isVisible'], true),

  [SET_CANCEL_ORDER_CHECKED]: (state = defaultState, { checked }) => state
    .setIn(['cancelOrderConfirmation', 'isChecked'], checked)
    .setIn(['cancelOrderConfirmation', 'showError'], !checked),

  [SHOW_CHECKED_ERROR]: (state = defaultState, action) => state
    .setIn(['cancelOrderConfirmation', 'showError'], true),
  [HIDE_CANCEL_RETURN_CONFIRMATION_DIALOG]: (state = defaultState, { isVisible }) => state
    .setIn(['returnRefund', 'isVisible'], isVisible),
  [SHOW_VALIDATION_MESSAGE_DIALOG]: (state = defaultState, { payload }) => state
    .setIn(['validationDialog'], fromJS(payload)),

  [SHOW_BUSINESS_ACCOUNT_ONHOLD_DIALOG]: (state = defaultState, action) => state
    .setIn(['businessAccountOnHold', 'isVisible'], true),

  [HIDE_BUSINESS_ACCOUNT_ONHOLD_DIALOG]: (state = defaultState, action) => state
    .setIn(['businessAccountOnHold', 'isVisible'], false),

  [SHOW_RETURN_CONFIRMATION_DIALOG]: (state = defaultState, { isVisible, proceedHandler }) => state
    .setIn(['returnRefund', 'showReturnConfirmation'], isVisible).setIn(['returnRefund', 'proceedHandler'], !isVisible ? null : proceedHandler),

  [SHOW_NON_DELIVERABLE_ORDER_MESSAGE]: (state = defaultState, action) => state
    .setIn(['nonDeliverableOrderMessage', 'isVisible'], true),

  [HIDE_NON_DELIVERABLE_ORDER_MESSAGE]: (state = defaultState, action) => state
    .setIn(['nonDeliverableOrderMessage', 'isVisible'], false)
};

export default handleActions(actionMap, defaultState);
