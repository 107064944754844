import { TITLE_LENGTH_LIMIT } from 'shared/constants/recommendation';
import { getLocalizedString } from 'localization/localizer';
import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const getTruncatedTitle = (name, customLengthLimit) => {
  const limit = customLengthLimit ? customLengthLimit : TITLE_LENGTH_LIMIT;
  return (name?.length > limit)
    ? `${name.substring(0, limit)}${getLocalizedString('recommendations.title.ellipsis')}`
    : name;
};

export const getFormattedDate = (date) => {
  return dayjs(date).isYesterday()
    ? 'Yesterday'
    : dayjs(date).isToday()
      ? 'Today'
      : `on ${dayjs(date).format('DD/MM/YY')}`;
};
