import { REFUND, REPLACE,
  REPLACE_AND_REFUND,
  COMMENT_REQUIRED_FOR_OPTION,
  MAX_RETURNS_DAYS_DIFFERENCE,
  VALID_RETURN_ORDER_STATUS,
  MAX_COMMENT_LENGTH,
  CHANGED_MIND } from 'shared/constants/returnRefundConstants';
import { buildSkuDataMap,
  isOrderDispatchedForReturnRequest,
  getOrderLineDetails } from 'client/sagas/processOrders/processItemsUtil';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _pickBy from 'lodash/pickBy';
import { getLocalizedString } from 'localization/localizer';
import {
  isRequiredField
} from 'client/utils/validators';

export const STILL_PROCESSING = 'STILL_PROCESSING';
export const NON_REFUNDABLE_PRODUCTS = 'NON_REFUNDABLE_PRODUCTS';
export const RETURN_EXISTS = 'RETURN_EXISTS';
export const OUT_OF_RETURN_PERIOD = 'OUT_OF_RETURN_PERIOD';
export const PENDING_DROPSHIP = 'PENDING_DROPSHIP';

export const transfromReturnPayload = (selectedReturns) => {
  return selectedReturns.map(({ sku, qty, reason,
    returnOption, comment, imageObjectList = [], supplierSku }) => {
    return {
      sku,
      qty,
      reason,
      returnOption,
      comment,
      supplierSku,
      imagePaths: imageObjectList.map((image) => image.Key)
    };
  });
};

export const getSubTotalOfRefundTypeRequest = (selectedReturns, includeVat, skuDataMap) => {
  return selectedReturns.reduce((acc, { qty, returnOption, sku, customerRequestType }) => {
    if (returnOption === REFUND || customerRequestType === REFUND) {
      const { orderLinePrice } = skuDataMap[sku];
      const unitPrice = includeVat ? orderLinePrice.unitPriceWithVat : orderLinePrice.unitPriceWithoutVat;
      return acc + (qty * unitPrice);
    }
    return acc;
  }, 0);
};

export const getRequestType = (returnRequests, requestTypeKey) => {
  const refundOnlyRequestList = returnRequests.filter((request) => request[requestTypeKey] === REFUND);
  const replacementOnlyRequestList = returnRequests.filter((request) => request[requestTypeKey] !== REFUND);
  if (refundOnlyRequestList.length && replacementOnlyRequestList.length) {
    return REPLACE_AND_REFUND;
  }
  return refundOnlyRequestList.length ? REFUND : REPLACE;
};

export const processConfirmationDetails = (orders) => {
  const { merlinOrderReference, returnRequests, orderLines } = orders;
  const skuDataMap = buildSkuDataMap(orderLines);
  const { orderLines: returnOrderLines, returnReference } = returnRequests;
  const returnOrderLinesMap = Object.values(returnOrderLines);
  const returnType = getRequestType(returnOrderLinesMap, 'customerRequestType');
  return {
    merlinOrderReference,
    returnType,
    skuDataMap,
    returnOrderLines: returnOrderLinesMap,
    returnReference
  };
};

export const getUniqFileName = (fileName, orderId) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  const fileExtension = fileName.substr(lastDotIndex + 1);
  const uuid = uuidv4();
  return `${orderId}-${uuid}.${fileExtension}`;
};

export const isDeliveryDateValidForReturn = (deliveryDate) => {
  return moment().diff(moment(deliveryDate), 'days') <= MAX_RETURNS_DAYS_DIFFERENCE;
};

export const imageRequired = isRequiredField(getLocalizedString('myAccount.returns.imageRequired'));
export const isUploading = value => {
  const isValid = value && value.length ? value.some((img) => img.isUploading) : false;
  return isValid ? getLocalizedString('myAccount.returns.uploadMessage') : undefined;
};

export const checkFieldsValidation = (toggle, qty, reason, initialQty, returnOption, comment) => {
  const requiredLabel = getLocalizedString('myAccount.returns.optionRequired');
  const isCommentRequired = COMMENT_REQUIRED_FOR_OPTION.includes(reason);
  const error = {
    toggle: !toggle ? requiredLabel : undefined,
    reason: !reason ? requiredLabel : undefined,
    qty: (!qty || qty < 1) ? getLocalizedString('myAccount.returns.qtyRequired')
      : (qty > initialQty)
        ? getLocalizedString('myAccount.returns.wrongQty')
        : undefined,
    returnOption: !returnOption || (reason === CHANGED_MIND && returnOption === REPLACE)
      ? requiredLabel
      : undefined,
    comment: (isCommentRequired && !comment)
      ? getLocalizedString('myAccount.returns.commentRequired')
      : (comment && comment.length > MAX_COMMENT_LENGTH)
        ? getLocalizedString('error.maxLength').replace('{field}', 'Comment').replace('{maxLength}', MAX_COMMENT_LENGTH)
        : undefined
  };
  return _pickBy(error, field => field !== undefined);
};

export const validate = values => {
  const parsedValues = values.toJS();
  const { dispatchList } = parsedValues;
  const errors = {};
  if (!dispatchList || !dispatchList.length) {
    errors.dispatchList = { _error: getLocalizedString('myAccount.returns.optionRequired') };
  } else {
    const noneSelected = dispatchList.every((item) => !item.toggle);
    const returnLineErrors = dispatchList.reduce((fieldErrors, { toggle, qty, reason, initialQty, returnOption, comment }, index) => {
      const error = checkFieldsValidation(toggle, qty, reason, initialQty, returnOption, comment);
      const errorLength = Object.keys(error).length;
      if (toggle && !noneSelected && errorLength) {
        fieldErrors[index] = error; // we doing this because field name are based on index
      }
      if (errorLength && noneSelected) {
        fieldErrors[index] = error;
      }
      return fieldErrors;
    }, []);
    if (returnLineErrors.length) {
      errors.dispatchList = returnLineErrors;
    }
  }
  return errors;
};

export const isOrderDispatched = (status) => {
  return VALID_RETURN_ORDER_STATUS.includes(status);
};

export const checkReturnEligibility = (order, isCsAgent) => {
  const { raw, products, dispatchStatus } = order;
  const { dispatchDetails, returnRequests = [] } = raw;
  const isOrderLineDispatched = isOrderDispatchedForReturnRequest(raw, products, isCsAgent);
  const { isNonRefundableOrderLine, isAllItemReturned, isReturnPeriodValid } = getOrderLineDetails(dispatchDetails, products, returnRequests, isCsAgent);
  const isDispatched = isOrderDispatched(dispatchStatus);
  const returnRequestNotEligible = !isOrderLineDispatched || !isDispatched;
  const isPeriodValid = isCsAgent ? isOrderLineDispatched && !isReturnPeriodValid : !isReturnPeriodValid;
  if (!isDispatched) return { status: STILL_PROCESSING, returnRequestNotEligible };
  if (isNonRefundableOrderLine) return { status: NON_REFUNDABLE_PRODUCTS, returnRequestNotEligible };
  if (!isOrderLineDispatched && isAllItemReturned) return { status: RETURN_EXISTS, returnRequestNotEligible };
  if (isPeriodValid) return { status: OUT_OF_RETURN_PERIOD, returnRequestNotEligible };
  return { status: PENDING_DROPSHIP, returnRequestNotEligible };
};
