import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { orderSelectors } from '../shared/selectors';
import { postOrder } from 'shared/endpoints/ordersEndpoint';
import { gotoExternalUrl } from 'client/actions/routeActions';
import { getLocalizedString } from 'localization/localizer';
import { createOrder, orderFailed } from 'client/components/elements/paymentMethod/shared/orderHelpers';
import { PAY_BY_ACCOUNT, SUBSCRIBE_BY_ACCOUNT, INITIALIZE_PAY_ON_ACCOUNT } from './payByAccountActions';
import { createSubscription } from 'client/utils/subscriptionUtils';
import { createRepeatOrder as createRepeatOrderEndPoint } from 'shared/endpoints/repeatOrdersEndpoint';
import { createSubscriptionFailed, createSubscriptionSuccess } from 'client/actions/repeatOrdersActions';
import { ACCOUNT } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { toastError } from 'client/actions/showNotificationsActions';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { getExistingCards } from 'client/actions/braintreeGatewayActions';
import { getUserAddress } from 'client/actions/addressActions';
import { getTotalizedValues } from 'client/utils/cartUtils';
import { validateBusinessAccountOrder } from 'client/actions/creditAccountActions';
import { SUCCESS } from 'shared/constants/loadStateType';
import { loadCart } from 'client/actions/cartActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';

export function * initializePayOnAccount ({ payload }) {
  const addressId = payload.addressId;
  yield put(getUserAddress({ type: 'billing' }));
  yield put(loadCart({ addressId }));
  yield put(getExistingCards({
    selector: CHECKOUT_SELECTOR
  }));

  const loadState = yield select(orderSelectors.loadState);
  const fromQuotationId = yield select(orderSelectors.fromQuotationId);
  const cart = yield select(orderSelectors.cart);
  const isSubscription = payload.isSubscription;
  const totalizedValues = loadState === SUCCESS || isSubscription ? getTotalizedValues(cart) : null;

  if (totalizedValues) {
    yield put(loadCart());
    yield put(validateBusinessAccountOrder(totalizedValues.grandTotalIncVat, fromQuotationId));
  }
}

export function * payByAccount ({ payload }) {
  try {
    const billingAddress = yield select(orderSelectors.tradeAccountAddress);
    const order = yield call(createOrder, ACCOUNT, billingAddress);
    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrder, order);
    yield put(gotoExternalUrl(postOrderResponse.nexturl));
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

export function * subscribeByAccount () {
  try {
    const subscription = yield call(createSubscription, ACCOUNT);
    yield call(createRepeatOrderEndPoint, subscription);
    yield put(createSubscriptionSuccess({ repeatOrderCreatedName: subscription.orderReference }));
  } catch (error) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('mySubscriptions.account.create.error')
      ),
      'top-right', 5000));
    yield put(createSubscriptionFailed());
  }
}

function * watchSubscribeByAccount () {
  yield takeLatest(SUBSCRIBE_BY_ACCOUNT, subscribeByAccount);
}
function * watchPayByAccount () {
  yield takeLatest(PAY_BY_ACCOUNT, payByAccount);
}
function * watchInitializePayOnAccount () {
  yield takeLatest(INITIALIZE_PAY_ON_ACCOUNT, initializePayOnAccount);
}

export function * watchAllPayByAccountSagas () {
  yield all([
    fork(watchPayByAccount),
    fork(watchSubscribeByAccount),
    fork(watchInitializePayOnAccount)
  ]);
}
