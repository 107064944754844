import React from 'react';

import { getLocalizedString } from 'localization/localizer';
import CmsShoveler from './cmsShoveler';
import CmsCategoriesTile from './cmsCategoriesTile';

export default (props) =>
  <CmsShoveler
    TileComponent={ CmsCategoriesTile }
    searchPlaceHolder={ getLocalizedString('shoveler.categories.searchPlaceholder') }
    heading={ getLocalizedString('shoveler.categories.heading') }
    ifEmptyCustomClassName="CmsShoveler_Categories_NoResults"
    { ...props }
  />;
