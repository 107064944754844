import React from 'react';
import { connect } from 'react-redux';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { ORDER_REVIEW_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';
import { getLocalizedString } from 'localization/localizer';
import { PrimaryButton } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./buttons.scss');
}

export const ReviewOrderButton = ({ setActivePanelId }) => {
  return (
    <PrimaryButton className="ReviewOrderButton" data-e2e="PaymentMethodButton_reviewOrder" onClick={ () => {
      setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID });
    } }>
      {getLocalizedString('payment.label.button.reviewOrderButton')}
    </PrimaryButton>
  );
};

const mapDispatchToProps = {
  setActivePanelId
};

export default connect(null, mapDispatchToProps)(ReviewOrderButton);
