import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemoveQuotationDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveQuotationConfirm } from '../../../actions/quotationsActions';
import { PrimaryButton, SecondaryButton } from '../../controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
if (process.browser) {
  require('./RemoveQuotationDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeQuotationDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeQuotation', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveQuotationDialog: flowRight(dispatch, hideRemoveQuotationDialog),
    requestRemoveQuotationConfirm: flowRight(dispatch, requestRemoveQuotationConfirm)
  };
}

const RemoveQuotationDialog = (props) => {
  const { removeQuotationDialogIsVisible,
    requestRemoveQuotationConfirm,
    hideRemoveQuotationDialog } = props;

  return (
    <Modal className="RemoveQuotationDialog" show={ removeQuotationDialogIsVisible }
      onHide={ hideRemoveQuotationDialog }>
      <Modal.Header className="RemoveQuotationDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <div>
            { getLocalizedString('quotationDialog.confirmText.deleteQuotationWarning') }<br />
            { getLocalizedString('quotationDialog.confirmText.quotationWarningNote') }
          </div>
        </div>

        <div className="row modal-body_options ">

          <SecondaryButton
            value={ getLocalizedString('cart.dialog.cancel.button') }
            text={ getLocalizedString('cart.dialog.cancel.button') }
            className="RemoveQuotationDialog_buttonMarginRight"
            onClick={ hideRemoveQuotationDialog } />

          <PrimaryButton
            text={ getLocalizedString('cart.orderLine.remove') }
            className="RemoveQuotationDialog_buttonMargin"
            onClick={ requestRemoveQuotationConfirm }
            value={ getLocalizedString('cart.orderLine.remove') }
          />

        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveQuotationDialog.propTypes = {
  removeQuotationDialogIsVisible: PropTypes.bool,
  hideRemoveQuotationDialog: PropTypes.func,
  requestRemoveQuotationConfirm: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveQuotationDialog);
