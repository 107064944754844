import React from 'react';
import { Heading } from '@graingerglobalonline/curly-eureka';
import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./radioSelector.scss');
}
const RadioSelector = ({ checked, selectorText, onClick, styles, datae2e }) => {
  const dataE2E = datae2e ? datae2e : `radioSelectorFor-${selectorText}`;
  return (
    <div className={ `RadioSelector_selector ${styles}` } onClick={ onClick } data-e2e={ dataE2E }>
      {checked ? (
        <SvgLoader
          xlinkHref="#payment-option-checked"
          className="SearchIcon"
        />
      ) : (
        <SvgLoader
          xlinkHref="#payment-option-unchecked"
          className="SearchIcon"
        />
      )}
      <Heading variant="small" className="RadioSelector_selectorText">{selectorText}</Heading>
    </div>
  );
};
export default RadioSelector;
