import { takeLatest, put, call } from 'redux-saga/effects';
import { TRY_VERIFY_EMAIL, setVerifyEmailState } from '../actions/verifyEmailActions';
import { tryVerifyEmail } from '../../shared/endpoints/verifyEmailEndpoint';

export function * watchTryVerifyEmail () {
  yield takeLatest(TRY_VERIFY_EMAIL, handleTryVerifyEmail);
}

export function * handleTryVerifyEmail (action) {
  try {
    yield put(setVerifyEmailState('pending'));
    const res = yield call(tryVerifyEmail, action.accountNo, action.verificationToken);
    if (res && res.accountNo === action.accountNo) {
      yield put(setVerifyEmailState('success'));
    } else {
      throw new Error('Verify Email Failed');
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(setVerifyEmailState('failed'));
  }
}
