import { fromJS } from 'immutable';
import {
  SET_ACTIVE_PANEL_ID,
  SCROLL_TO_CHECKOUT_ACCORDION_SECTION,
  RESET_SCROLLS,
  VIEW_ADDRESS_BOOK_TOGGLE,
  DISPLAY_ADDRESS_TOGGLE,
  NEW_DELIVERY_ADDRESS_TOGGLE,
  DELIVERY_ACCORDION_LOADING,
  CHANGE_BILLING_ADDRESS_TOGGLE,
  SET_PAYMENT_ACCORDION_STATE,
  SET_DELIVERY_ADDRESS_ACCORDION_STATE,
  SET_BILLING_ADDRESS_ACCORDION_STATE,
  TOGGLE_APPLY_DISCOUNT_CODE,
  RESET_ALL_SINGLE_PAGE_CHECKOUT_STATUS,
  TOGGLE_PLACE_ORDER_BUTTON
} from 'client/actions/singlePageCheckoutActions';
import {
  UPDATE_HOSTED_FIELDS_STATUS,
  RECEIVE_PAYMENT_INFORMATION_SUCCESS
} from 'client/actions/braintreeActions';
import {
  PAYMENT_ACCORDION_PANEL_ID,
  BILLING_ADDRESS_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';

const defaultState = fromJS({
  activePanelId: 1,
  deliveryAccordionLoading: true,
  displayAddressToggle: false,
  newDeliveryAddressToggle: false,
  changeBillingAddressToggle: false,
  viewAddressBook: false,
  isPaymentAccordionValid: -1,
  isDeliveryDetailsAccordionValid: -1,
  isBillingAddressAccordionValid: -1,
  placeOrderError: null,
  toggleApplyDiscountCode: true,
  isPaymentFlowSkipped: false,
  togglePlaceOrderButton: true
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_PLACE_ORDER_BUTTON:
      return state.set('togglePlaceOrderButton', !state.get('togglePlaceOrderButton'));
    case SET_ACTIVE_PANEL_ID:
      const { panelId, csAgentName } = action.payload;
      const isPaymentSkipped = state.get('isPaymentFlowSkipped');
      if (csAgentName && isPaymentSkipped && panelId === PAYMENT_ACCORDION_PANEL_ID) {
        return state.set('activePanelId', BILLING_ADDRESS_ACCORDION_PANEL_ID);
      }
      return state.set('activePanelId', panelId);
    case SCROLL_TO_CHECKOUT_ACCORDION_SECTION:
      return state.set('scrollToCheckoutAccordionSection', action.payload);
    case RESET_SCROLLS:
      return state.set('scrollToCheckoutAccordionSection', false);
    case VIEW_ADDRESS_BOOK_TOGGLE:
      return state.set('viewAddressBook', action.payload);
    case DISPLAY_ADDRESS_TOGGLE:
      return state.set('displayAddressToggle', action.payload);
    case NEW_DELIVERY_ADDRESS_TOGGLE:
      return state.set('newDeliveryAddressToggle', action.payload);
    case DELIVERY_ACCORDION_LOADING:
      return state.set('deliveryAccordionLoading', action.payload);
    case TOGGLE_APPLY_DISCOUNT_CODE:
      return state.set('toggleApplyDiscountCode', action.payload);
    case CHANGE_BILLING_ADDRESS_TOGGLE:
      return state.set('changeBillingAddressToggle', action.payload);
    case SET_PAYMENT_ACCORDION_STATE:
      if (action.payload) {
        return state
          .set('isPaymentAccordionValid', action.payload)
          .set('placeOrderError', null);
      }
      return state
        .set('isPaymentAccordionValid', action.payload);
    case SET_DELIVERY_ADDRESS_ACCORDION_STATE:
      if (action.payload) {
        return state
          .set('isDeliveryDetailsAccordionValid', action.payload)
          .set('placeOrderError', null);
      }
      return state
        .set('isDeliveryDetailsAccordionValid', action.payload);
    case SET_BILLING_ADDRESS_ACCORDION_STATE:
      if (action.payload) {
        return state
          .set('isBillingAddressAccordionValid', action.payload)
          .set('placeOrderError', null);
      }
      return state
        .set('isBillingAddressAccordionValid', action.payload);
    case RECEIVE_PAYMENT_INFORMATION_SUCCESS:
    case UPDATE_HOSTED_FIELDS_STATUS:
      return state.set('placeOrderError', null);
    case RESET_ALL_SINGLE_PAGE_CHECKOUT_STATUS:
      return state
        .set('activePanelId', 1)
        .set('deliveryAccordionLoading', true)
        .set('displayAddressToggle', false)
        .set('newDeliveryAddressToggle', false)
        .set('changeBillingAddressToggle', false)
        .set('viewAddressBook', false)
        .set('isPaymentAccordionValid', -1)
        .set('isDeliveryDetailsAccordionValid', -1)
        .set('isBillingAddressAccordionValid', -1)
        .set('placeOrderError', null)
        .set('toggleApplyDiscountCode', true)
        .set('isPaymentFlowSkipped', false);
    default:
      return state;
  }
};
