export const SET_UNLEASH_TOGGLES = 'SET_UNLEASH_TOGGLES';
export const SET_UNLEASH_CONTEXT = 'SET_UNLEASH_CONTEXT';

export const setUnleashToggles = (toggles) => ({
  type: SET_UNLEASH_TOGGLES,
  toggles
});

export const setUnleashContext = (context) => ({
  type: SET_UNLEASH_CONTEXT,
  context
});

export const REQUEST_UNLEASH_VALUES = 'REQUEST_UNLEASH_VALUES';
export const setUnleashValuesRequest = (params) => ({
  type: REQUEST_UNLEASH_VALUES,
  payload: params
});

export const REQUEST_UNLEASH_VALUES_SUCCESS = 'REQUEST_UNLEASH_VALUES_SUCCESS';
export const setUnleashValuesSuccess = (unleashValues) => ({
  type: REQUEST_UNLEASH_VALUES_SUCCESS,
  payload: unleashValues
});
