import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { getLocalizedString } from 'localization/localizer';
import SvgLoader from 'client/components/svg/SvgLoader';
import { validateTradeAccount } from 'client/utils/myAccountUtils';
import { menus, menuLinks, ADMIN, ACCOUNT_STATEMENTS, ACCOUNT_USERS, menuIds, menuIcons, LinksMap, ALL_LINKS_USER, LIMITED_LINKS_USER, PathClassName, FINANCE } from 'shared/constants/account';

if (process.browser) {
  require('./MyAccount.scss');
}

class MyAccountMenu extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      collapsed: true // this is used only on smaller screens
    };
    this._componentWillLoad = false;
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  componentWillReceiveProps () { // eslint-disable-line react/no-deprecated
    this._componentWillLoad = true;
  }

  collapse = () => {
    this.setState({
      collapsed: true
    });
  };

  validateTradeAccountByStatus = (tradeAccountSubRole, tradeAccountSubStatus, tradeAccountActive, tradeAccountStatus) => {
    if (tradeAccountSubRole && tradeAccountSubStatus) {
      return true;
    } else if (tradeAccountActive && tradeAccountSubRole && !tradeAccountStatus) {
      return true;
    }
    return false;
  }
  render () {
    const { logoutUser, loggedUser, isMobile, url } = this.props;
    const { collapsed } = this.state;
    const itemsClass = 'MyAccount_items-' + (collapsed ? 'collapsed' : 'expanded');
    const collapseIcon = collapsed ? 'fa-chevron-down' : 'fa-chevron-up';

    const { tradeAccountIsValid, tradeAccountIsValidForStatement, tradeAccount } = validateTradeAccount(loggedUser.toJS());
    const { accountUser } = tradeAccount || {};
    const { accountRole } = accountUser || {};
    const userRole = (accountRole === FINANCE || accountRole === ADMIN) ? ALL_LINKS_USER : LIMITED_LINKS_USER;
    const filteredMenus = menus[userRole].filter(menu => (!(menu === ACCOUNT_STATEMENTS && !tradeAccountIsValidForStatement) && !(menu === ACCOUNT_USERS && !tradeAccountIsValid)));

    const getPartialPathClassName = (fullUrl, partialUrl) => {
      return fullUrl.includes(partialUrl) ? 'activePartialUrl' : '';
    };

    return (
      <div>
        <div className="MyAccount_menu">
          <div className="MyAccount_header" onClick={ this.toggle }>
            <span>{ getLocalizedString('myAccount.header') }</span>
            { isMobile ? <i className={ 'fa pull-right ' + collapseIcon }/> : null }
          </div>
          <hr className="MyAccount_demarkation"/>
          {
            this._componentWillLoad
              ? <div className={ 'MyAccount_items ' + itemsClass }>
                <a
                  href="/my-account/account-details"
                  onClick={ this.collapse }
                  id="myAccount.menu.accountDetails"
                >
                  <span className="MyAccount_menuLink">
                    <SvgLoader
                      xlinkHref="#my-account-icon"
                    />
                    <label> { getLocalizedString('myAccount.accountDetails.title')}</label>
                  </span>
                </a>
                {filteredMenus.map((menu, key) => {
                  if (LinksMap.includes(menu)) {
                    return <Link key={ key }
                      to={ menuLinks[menu] }
                      onClick={ this.collapse }
                      activeClassName="active"
                      id={ menuIds[menu].id }
                      className= { PathClassName.includes(menu) ? getPartialPathClassName(url, menuLinks[menu]) : '' }
                    >
                      <span className="MyAccount_menuLink">
                        {menu === ACCOUNT_STATEMENTS ? <SvgLoader
                          xlinkHref={ menuIcons[menu] }
                          id={ menuIds[menu].svgLoaderId }
                        /> : <SvgLoader
                          xlinkHref={ menuIcons[menu] }
                        />}
                        <label> {menu}</label>
                      </span>
                    </Link>;
                  }

                  return <a key={ key }
                    href={ menuLinks[menu] }
                    onClick={ this.collapse }
                    id={ menuIds[menu].id }
                  >
                    <span className="MyAccount_menuLink">
                      <SvgLoader
                        xlinkHref={ menuIcons[menu] }
                        id="statementSvg"
                      />
                      <label> {menu}</label>
                    </span>
                  </a>;
                })}
                <a onClick={ logoutUser } id="myAccount.menu.logout">
                  <span className="MyAccount_menuLink">
                    <SvgLoader
                      xlinkHref="#logOut-icon"
                    />
                    <label> { getLocalizedString('myAccount.logout') }</label>
                  </span>
                </a>
              </div> : null
          }
        </div>
      </div>
    );
  }
}

const MyAccountMain = (children, childProps) => (
  <div className="MyAccount_main">
    { children ? React.cloneElement(children, childProps) : null }
  </div>
);

const MyAccount = ({ url, user, logoutUser, children, loyaltyDiscount, isMobile, hasActiveTradeAccount }) => {
  const childProps = { url, user, loyaltyDiscount, isMobile };
  return (
    <div className="MyAccount">
      <MyAccountMenu url={ url } logoutUser={ logoutUser } loggedUser={ user } isMobile={ isMobile } hasActiveTradeAccount={ hasActiveTradeAccount }/>
      { MyAccountMain(children, childProps) }
    </div>
  );
};

MyAccount.propTypes = {
  url: PropTypes.string,
  user: PropTypes.object,
  dialogs: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  logoutUser: PropTypes.func,
  children: PropTypes.element,
  displayChangePasswordDialog: PropTypes.func
};

export default MyAccount;
