import React from 'react';
import { getLocalizedString } from 'localization/localizer';
const UNAVAILABLE_MESSAGE = getLocalizedString('stockMessage.unavailable.message');
const UNAVAILABLE_TEXT = getLocalizedString('stockMessage.unavailable.text');

const LONGER_LEAD_TIME_MESSAGE = getLocalizedString('stockMessage.longerLeadTime.message');
export const LONGER_LEAD_TIME_TEXT = getLocalizedString('stockMessage.longerLeadTime.text');
const LONGER_LEAD_TIME_MESSAGE_WITH_QTY = getLocalizedString('stockMessage.longerLeadTime.withQty.message');

const LIMITED_STOCK_MESSAGE = getLocalizedString('stockMessage.limited.stock.message');
const LIMITED_STOCK_TEXT = getLocalizedString('stockMessage.limited.stock.text');

const TOMORROW = getLocalizedString('stockMessage.tomorrow.message');
const GET_QTY_BY = getLocalizedString('stockMessage.getQtyBy.message');
const GET_QTY_ON = getLocalizedString('stockMessage.getQtyOn.message');
const GET_IT_BY = getLocalizedString('stockMessage.getItBy.message');
const GET_IT_ON = getLocalizedString('stockMessage.getItOn.message');
const GET_IT_IN = getLocalizedString('stockMessage.getItIn.message');
const GET_QTY_IN = getLocalizedString('stockMessage.getQtyIn.message');
const IN_STOCK = getLocalizedString('stockMessage.instock.message');
const AVAILABLE = getLocalizedString('stockMessage.available.message');

export const IS_AVAILABLE_ON_LONG_LEADTIME = 'IS_AVAILABLE_ON_LONG_LEADTIME';
export const IS_OUT_OF_STOCK = 'IS_OUT_OF_STOCK';
export const IS_OBSOLETE_AND_HAS_STOCK = 'IS_OBSOLETE_AND_HAS_STOCK';
export const IS_UNAVAIABLE = 'IS_UNAVAIABLE';

export const makeTextClickable = (message, regex, href, target) => {
  const isTelephone = href === 'tel';
  if (!regex.test(message)) {
    return message;
  }
  const aTarget = target === '_blank' ? `target=${target}` : '';
  const __html = message.replace(regex, (matchedText) => {
    return `<a rel="noopener noreferrer" class="showScheduleOrderForm_link" href="${isTelephone ? `tel:${matchedText}` : href}"${aTarget}><strong>${matchedText}</strong></a>`;
  });
  return (<span id="clickableMessage" dangerouslySetInnerHTML={{ __html }}/>);
};

const makeContactUsClickable = (message) => makeTextClickable(message, /contact us/g, '/contact-us');
const makeContactNumbersClickable = (message) => makeTextClickable(message, /0\d{3}\s?\d{4}\s?\d{3}/g, 'tel');

export const getMessages = (stockStatus, isMobile, isRecommendations) => {
  const {
    status,
    deliveryInfo
  } = stockStatus;
  switch (status) {
    case stockStatus.IS_DELETED:
    case stockStatus.IS_AGE_RESTRICTED:
    case stockStatus.IS_OUT_OF_STOCK:
    case stockStatus.IS_UNAVAIABLE:
      return [{
        message: UNAVAILABLE_MESSAGE,
        text: UNAVAILABLE_TEXT,
        isAvailable: false
      }];
    case stockStatus.IS_AVAILABLE_ON_LONG_LEADTIME:
      if (isNonEmptyArray(deliveryInfo)) {
        return getMessagesForDeliveryInfo(deliveryInfo, isRecommendations);
      }
      const longLeadTimeMessage = isRecommendations ? UNAVAILABLE_MESSAGE : LONGER_LEAD_TIME_MESSAGE;
      return [{
        message: makeContactUsClickable(longLeadTimeMessage),
        text: LONGER_LEAD_TIME_TEXT,
        isAvailable: false,
        isLongLeadTime: true
      }];

    case stockStatus.IS_OBSOLETE_AND_HAS_STOCK:
      const message = LIMITED_STOCK_MESSAGE.replace('{stockValue}', deliveryInfo[0].stock);
      return [{
        message: (isMobile)
          ? makeContactNumbersClickable(message)
          : message,
        text: LIMITED_STOCK_TEXT,
        isAvailable: false
      }];
    case stockStatus.IS_AVAILABLE_TOMORROW:
      if (deliveryInfo.length && deliveryInfo[0].isTomorrow) {
        return [{
          message: <span>{GET_IT_BY} <strong>{TOMORROW}</strong></span>,
          text: IN_STOCK,
          isAvailable: true,
          isTomorrow: true
        }];
      }
      return getMessagesForDeliveryInfo(deliveryInfo, isRecommendations);

    case stockStatus.IS_AVAILABLE_IN_WAREHOUSE:
    case stockStatus.IS_AVAILABLE_IN_WAREHOUSE_HAZARDOUS:
    case stockStatus.IS_CROSSDOCK_WITH_LEADTIME:
    case stockStatus.IS_AVAILABLE_ON_LEADTIME:
    case stockStatus.IS_BACKORDER_WITH_LEADTIME:
    case stockStatus.IS_ZORO_DROPSHIP_WITH_LEADTIME:
    case stockStatus.IS_AVAILABLE_ON_PURCHASEORDER:
      return getMessagesForDeliveryInfo(deliveryInfo, isRecommendations);
    default:
      return [{
        message: UNAVAILABLE_MESSAGE,
        text: UNAVAILABLE_TEXT,
        isAvailable: false
      }];
  }
};

export const isDeliveryTomorrow = (stockStatus) => {
  return stockStatus.status === stockStatus.IS_AVAILABLE_TOMORROW;
};

export const isNonEmptyArray = (arr) => arr && arr.length > 0;

export const getCustomClassForStockStatus = (stockStatus = {}, isForCart) => {
  switch (stockStatus.status) {
    case stockStatus.IS_DELETED:
    case stockStatus.IS_AVAILABLE_ON_LONG_LEADTIME:
    case stockStatus.IS_OUT_OF_STOCK:
    case stockStatus.IS_OBSOLETE_AND_HAS_STOCK:
    case stockStatus.IS_UNAVAIABLE:
    case stockStatus.IS_AGE_RESTRICTED:
      return 'unavailableAmount';
    default:
      return '';
  }
};

const getMessageForDeliveryInfo = (deliveryInfo, isMultiMessage = true, isRecommendations) => {
  const { qty, deliveryDate = null, isDeliveryBy = false,
    leadTime = null, isLongLeadTime = false, leadTimeInWeeks = null } = deliveryInfo;

  if (deliveryDate) {
    const subMessage = (isDeliveryBy)
      ? ((isMultiMessage) ? GET_QTY_BY.replace('{qty}', qty) : GET_IT_BY)
      : ((isMultiMessage) ? GET_QTY_ON.replace('{qty}', qty) : GET_IT_ON);
    return leadTimeMessage(subMessage, deliveryDate, '', IN_STOCK, true);
  }
  if (leadTime) {
    const subMessage = (isMultiMessage)
      ? GET_QTY_IN.replace('{qty}', qty)
      : GET_IT_IN;
    const leadTimeText = `${leadTime.from}-${leadTime.to}`;
    const daysMessage = `${isRecommendations ? '' : 'working'} days`;
    return leadTimeMessage(subMessage, leadTimeText, daysMessage, AVAILABLE, true);
  }
  if (leadTimeInWeeks) {
    const subMessage = (isMultiMessage)
      ? GET_QTY_IN.replace('{qty}', qty)
      : GET_IT_IN;
    const leadTimeText = (leadTimeInWeeks.from === leadTimeInWeeks.to) ? `${leadTimeInWeeks.to}` : `${leadTimeInWeeks.from}-${leadTimeInWeeks.to}`;
    const weeksMessage = leadTimeInWeeks.to > 1 ? 'weeks' : 'week';
    return leadTimeMessage(subMessage, leadTimeText, weeksMessage, AVAILABLE, true);
  }

  if (isLongLeadTime) {
    const longleadTimeMessage = isRecommendations ? UNAVAILABLE_MESSAGE : LONGER_LEAD_TIME_MESSAGE;
    const subMessage = (isMultiMessage)
      ? <span>{qty} {makeContactUsClickable(LONGER_LEAD_TIME_MESSAGE_WITH_QTY)}</span>
      : makeContactUsClickable(longleadTimeMessage);
    return leadTimeMessage(subMessage, '', '', LONGER_LEAD_TIME_TEXT, false, true);
  }
};

const leadTimeMessage = (subMessage, leadTimeText, postMessage, text, isAvailable, isLongLeadTime) => {
  const displayMessage = isLongLeadTime
    ? subMessage
    : <span>{subMessage} <strong>{leadTimeText}</strong> {postMessage}</span>;
  return {
    message: displayMessage,
    text,
    isAvailable,
    isLongLeadTime
  };
};

const getMessagesForDeliveryInfo = (deliveryInfo, isRecommendations) => {
  return deliveryInfo.map((dinfo) => {
    if (deliveryInfo.length > 1) {
      return getMessageForDeliveryInfo(dinfo, true, isRecommendations);
    }
    return getMessageForDeliveryInfo(dinfo, false, isRecommendations);
  });
};
