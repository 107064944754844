import { fromJS } from 'immutable';
import {
  REQUEST_PUBLIC_HOLIDAYS_SUCCESS
} from 'client/actions/publicHolidaysActions';

const defaultState = fromJS([]);

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case REQUEST_PUBLIC_HOLIDAYS_SUCCESS:
      return fromJS(payload);
    default:
      return state;
  }
};
