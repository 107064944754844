import { takeLatest, put, call } from 'redux-saga/effects';
import { TRY_UNSUBSCRIBE, setUnsubscribeState } from '../actions/unsubscribeActions';
import { tryUnsubscribe } from '../../shared/endpoints/unsubscribeEndpoint';
import { receiveUpdateMarketingPreferencesSuccess } from 'client/actions/accountActions';
const SUCCESS = 'success';

export function * watchTryUnsubscribe () {
  yield takeLatest(TRY_UNSUBSCRIBE, handleTryUnsubscribe);
}

export function * handleTryUnsubscribe (action) {
  try {
    yield put(setUnsubscribeState('pending'));
    const accountDetails = yield call(tryUnsubscribe, action.customerId, action.unsubscribeToken, action.emailType);
    yield put(setUnsubscribeState(SUCCESS));
    yield put(receiveUpdateMarketingPreferencesSuccess(accountDetails));
  } catch (err) {
    yield put(setUnsubscribeState('failed'));
  }
}
