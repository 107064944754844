import { ApiRequest } from 'shared/utils/apiUtils';

export function tryUnsubscribe (customerId, unsubscribeToken, emailType) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/unsubscribeEmail',
    body: { customerId, unsubscribeToken, emailType }
  });

  return req.execute()
    .then((r) => {
      return r.body;
    });
}
