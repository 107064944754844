import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { reduxForm, Field, formValueSelector, getFormSyncErrors, isValid } from 'redux-form/immutable';
import { connect } from 'react-redux';
import curry from 'lodash/curry';
import { buildUrlWithQuery } from 'client/utils/urlUtils';
import { toggleEnterManually } from 'client/actions/ui/postCodeActions';
import { validateForm } from 'client/utils/formValidation';
import { handleToggle } from 'client/actions/ui/toggleElementActions';
import CheckBoxWithValidations from 'client/components/elements/formField/CheckboxWithValidations';
import DefaultDelivery from 'client/components/elements/AddressBlock/DefaultDelivery';
import { makeValidator, defaultAddressFormValidator } from 'client/utils/validators';

import PostcodeInput from 'client/components/elements/postcodeInput/postcodeInput';
import { getLocalizedString } from 'localization/localizer';

import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { goToUrl } from 'client/actions/routeActions';
import { PrimaryButton, SecondaryButton } from '../../controls/StyledForms/formButtons';
import { SINGLE_PAGE_CHECKOUT_ROUTE, SINGLE_PAGE_CHECKOUT } from 'shared/constants/singlePageCheckout';

if (process.browser) {
  require('./AddressForm.scss');
}

const isBillingForm = (selector, address) => address && address.postalCode ? selector === 'billingDetailsAddressForm' : null;

const validate = makeValidator(defaultAddressFormValidator);

export const AddressForm = (props) => {
  const {
    quickFindPostCodeValue,
    companyName,
    addressLine1,
    postalCode,
    addressLine2,
    stateValue,
    city,
    handleSubmit,
    submitButtonText,
    formName,
    addressId,
    isInitialDeliveryForm,
    isInitialBillingForm,
    onAddressSubmit,
    expandPostCode,
    onPostcodeLinkClick = () => {},
    payOnAccount,
    fromQuotationId,
    calledFrom,
    formSyncErrors,
    isFormValid,
    isDefaultDeliveryAddress,
    isEditAddress,
    isPostCodeSubmitted,
    onBillingAddressSubmit,
    handleBackButton,
    handleBackButtonClick,
    goToUrl,
    hideDefaultDelivery,
    onIsUpdateBillingDetails,
    isDisableBackButton,
    displayAddressToggle,
    newDeliveryAddressToggle,
    viewAddressBookToggle,
    scrollToCheckoutSection,
    editAddressAction,
    onChangeBillingAddressToggle,
    isFirstAddress,
    isAddressExist = true,
    address
  } = props;
  const isInitialAddressForm = !isBillingForm(formName) && !isInitialBillingForm && !isInitialDeliveryForm;
  const showBillingAddress = !payOnAccount && !isBillingForm(formName) && isInitialDeliveryForm && !isEditAddress;
  const checkoutUrl = '/checkout/order';
  const addressbookUrl = '/my-account/address-book';
  const addressbookCheckoutUrl = '/checkout/address-book';
  const cartUrl = '/cart';
  const redirectForSinglePageCheckout = () => {
    return isEditAddress
      ? SINGLE_PAGE_CHECKOUT
      : SINGLE_PAGE_CHECKOUT_ROUTE;
  };
  const getRedirectUrl = {
    'checkout': () => buildUrlWithQuery(checkoutUrl, [
      payOnAccount ? 'onAccount=y' : null,
      fromQuotationId ? `fromQuotationId=${fromQuotationId}` : null
    ]),
    'addressbook-checkout': () => buildUrlWithQuery(addressbookCheckoutUrl, [
      payOnAccount ? 'onAccount=y' : null,
      fromQuotationId ? `fromQuotationId=${fromQuotationId}` : null
    ]),
    'singlePageCheckout': () => redirectForSinglePageCheckout(),
    'addressbook': () => addressbookUrl
  };
  const redirectToUrl = calledFrom
    ? getRedirectUrl[calledFrom]()
    : addressbookUrl;

  const isSinglePageCheckout = calledFrom === SINGLE_PAGE_CHECKOUT;

  const submit = curry((addressId, values, dispatch) => {
    return validateForm(values, validate)
      .then(() => {
        const isDefault = isInitialDeliveryForm || isSinglePageCheckout ? true : isDefaultDeliveryAddress;
        const addressName = values.get(isInitialDeliveryForm ? 'addressLine1' : 'addressName');
        const { ...rest } = values.toJS();
        // The space before addressLine2 on the line below is important
        const secondAddressLine = values.get('addressLine2') ? ` ${values.get('addressLine2')}` : '';
        const addressNickName = values.get('addressLine1') + secondAddressLine;
        const isBillingAddressSameAsDelivery = values.get('isBillingAddressSameAsDelivery');

        (onIsUpdateBillingDetails) && onIsUpdateBillingDetails();

        if (isBillingAddressSameAsDelivery) {
          onBillingAddressSubmit({ ...rest, isDefault, addressName, addressId, addressNickName, isEditAddress }, payOnAccount, fromQuotationId, redirectToUrl);
        }
        onAddressSubmit({ ...rest, isDefault, addressName, addressId, addressNickName, isEditAddress }, payOnAccount, fromQuotationId, redirectToUrl);
        onChangeBillingAddressToggle && onChangeBillingAddressToggle(false);
      });
  });

  const onSubmitClick = (e) => {
    // e.preventDefault();
    expandPostCode();

    setTimeout(() => {
      handleSubmit(submit(addressId))();
      if (!isFormValid) {
        /* Below logic is to locate field with error and focusing it */

        const errorEl = document.getElementById(getFirsterroneousField(formSyncErrors));
        if (errorEl) {
          errorEl.focus();
          errorEl.scroll();
        }
      }
    }, 0);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    editAddressAction && editAddressAction({});
    if (isSinglePageCheckout) {
      newDeliveryAddressToggle && newDeliveryAddressToggle(false);
      onChangeBillingAddressToggle && onChangeBillingAddressToggle(false);
    }
    if (isSinglePageCheckout && isEditAddress) {
      scrollToCheckoutSection(true);
      viewAddressBookToggle(true);
    } else {
      displayAddressToggle && displayAddressToggle(true);
    }
    if (!handleBackButton) {
      const url = isAddressExist ? redirectToUrl : cartUrl;
      redirectToUrl !== SINGLE_PAGE_CHECKOUT && goToUrl(url);
    } else {
      handleBackButtonClick();
    }
  };

  const backButtonClassName = (isDisableBackButton) ? 'AddressForm_saveButtonOff' : '';

  return (
    <Fragment>
      <div className="AddressForm">
        <form>
          <div>
            <div className ="AddressForm_section">
              <h2 className="AddressForm_subHeader HorizontalDividerLine">{ getLocalizedString('addressForm.title.contactDetails') }</h2>
              <Field
                name="firstName"
                id="firstName"
                className="AddressForm_formGroup_input form-control"
                labelClassName="AddressForm_formGroup_label control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="addressForm.label.firstName"
                datae2e="firstName" />

              <Field
                name="lastName"
                id="lastName"
                className="AddressForm_formGroup_input form-control"
                labelClassName="AddressForm_formGroup_label control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="addressForm.label.lastName"
                datae2e="lastName" />

              <Field
                name="addressPhoneNumber"
                id="addressPhoneNumber"
                className="PhoneNumberInput form-control"
                labelClassName="AddressForm_formGroup_label control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="addressForm.label.telephoneNumber"
                datae2e="telephoneNumber" />
            </div>
            <div className ="AddressForm_section">
              <h2 className="AddressForm_subHeader HorizontalDividerLine">{ getLocalizedString('addressForm.title.addressDetails') }</h2>
              <PostcodeInput
                quickFindPostCodeValue = { quickFindPostCodeValue }
                companyName={ companyName }
                addressId = { addressId }
                addressLine1={ addressLine1 }
                form={ formName }
                addressLine2={ addressLine2 }
                city={ city }
                isEditAddress={ isEditAddress }
                isPostCodeSubmitted={ isPostCodeSubmitted }
                state={ stateValue }
                postCode={ postalCode }
                selector={ formName }
                quickFindPostCodeDisabled = { formSyncErrors.quickFindPostCode || false }
                onPostcodeLinkClick={ onPostcodeLinkClick }
                isBillingForm={ isBillingForm(formName, address) }/>
            </div>
            { hideDefaultDelivery || isSinglePageCheckout ? null
              : isInitialAddressForm &&
                <div className="row">
                  <div className="col-md-6">
                    <DefaultDelivery isDefault={ isDefaultDeliveryAddress } isAddressForm={ true }/>
                  </div>
                </div>
            }
          </div>
          {
            showBillingAddress
              ? <div className="col-md-12 col-xs-12 CheckoutFooter noPaddingLeftRight">
                <div className="row">
                  <Field type="checkbox"
                    key="isBillingAddressSameAsDelivery"
                    name="isBillingAddressSameAsDelivery"
                    component={ CheckBoxWithValidations }
                    labelClassName="regular-checkbox"
                    small={ true }
                    datae2e="isBillingAddressSameAsDelivery"
                    textAndLink={
                      <div className="AddressForm_checkBox">
                        <span> { getLocalizedString('addressForm.checkBox.label.billingAddress') } </span>
                      </div>
                    } />
                </div>
              </div> : null }
          <div>
            <div className="pull-right AddressForm_submitBtnDiv">
              {
                !isFirstAddress &&
                <span className={ `AddressForm_backButtonSpan ${backButtonClassName}` }>
                  <SecondaryButton
                    to = { redirectToUrl }
                    onClick= { handleCancel }
                    className="back2billing"
                    datae2e="back2billing"
                    text ={ getLocalizedString('order.review.back2billing') }
                  />
                </span>
              }
              <span>
                <PrimaryButton
                  className={ `AddressForm_saveButton ${formSyncErrors && formSyncErrors.length ? 'inActive' : ''}` }
                  onClick={ onSubmitClick } text={ submitButtonText }
                  datae2e="submit"
                />
              </span>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddressForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAddressSubmit: PropTypes.func.isRequired,
  expandPostCode: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  addressId: PropTypes.any,
  isInitialAddressForm: PropTypes.bool,
  isInitialBillingForm: PropTypes.bool,
  payOnAccount: PropTypes.bool,
  fromQuotationId: PropTypes.string,
  onBillingAddressSubmit: PropTypes.func
};

function formValueSelectors (state, ownProps) {
  const selector = formValueSelector(ownProps.formName);
  const quickFindPostCodeValue = selector(state, 'quickFindPostCode');
  const companyName = selector(state, 'companyName');
  const addressLine1 = selector(state, 'addressLine1');
  const postalCode = selector(state, 'postalCode');
  const addressLine2 = selector(state, 'addressLine2');
  const stateValue = selector(state, 'state');
  const city = selector(state, 'city');

  return {
    quickFindPostCodeValue,
    companyName,
    addressLine1,
    postalCode,
    addressLine2,
    stateValue,
    city
  };
}

function getFirsterroneousField (errors) {
  /* This function returns the first field with error which will be used in focusing and scrolling
   to that input field */

  return Object
    .entries(errors)
    .find(([fieldName, value]) => value !== undefined)[0];
}

function mapStateToProps (state, ownProps) {
  let stateProps = {};
  if (ownProps.address) {
    stateProps.initialValues = {
      isBillingAddressSameAsDelivery: ownProps.isBillingAddressSameAsDelivery,
      ...ownProps.address
    };
    stateProps.addressId = ownProps.address.addressId;
  }
  stateProps = {
    ...stateProps,
    ...formValueSelectors(state, ownProps),
    formSyncErrors: formSyncErrors(ownProps, state),
    isFormValid: isValid(ownProps.formName)(state),
    isManuallyEntered: state.getIn(['ui', 'postCode', ownProps.formName, 'isManuallyEntered']),
    isDefaultDeliveryAddress: state.getIn(['ui', 'toggleElement', 'addressFormDefaultDeliveryToggle'], false),
    enableReinitialize: true
  };
  return stateProps;
}

const formSyncErrors = (ownProps, state) => {
  const errors = getFormSyncErrors(ownProps.formName)(state) || {};
  return Object
    .entries(errors)
    .filter((error) => error[1]);
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  expandPostCode: () => dispatch(toggleEnterManually(ownProps.formName, true)),
  handleToggle: (checked) => dispatch(handleToggle(checked)),
  goToUrl: (url) => dispatch(goToUrl(url))
});

const ReduxFormAddress = connect(mapStateToProps, mapDispatchToProps)(reduxForm(
  {
    validate
  }
)(AddressForm));

export default ReduxFormAddress;
