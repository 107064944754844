import { takeLatest, call, select } from 'redux-saga/effects';

import { publishLog } from 'shared/endpoints/loggingEndpoint';

import { LOG_PERSONAL_INFO_ACCESS } from 'client/actions/accessLogActions';
import { getJwtCustomerId } from 'shared/utils/jwtUtils';

const csAgentNameSelector = (state) => state.getIn(['customerService', 'csAgentName'], null);
const jwtTokenSelector = (state) => state.getIn(['auth', 'jwtToken']);
const longSessionTokenSelector = (state) => state.getIn(['auth', 'longSessionJwtToken']);

export function * onPersonalInfoAccess (action) {
  const token = yield select(jwtTokenSelector);
  const longSessionToken = yield select(longSessionTokenSelector);
  const customerId = getJwtCustomerId(token) || getJwtCustomerId(longSessionToken);
  const csAgentName = yield select(csAgentNameSelector);
  yield call(publishLog, customerId, csAgentName);
}

export function * watchPersonalInfoAccess () {
  yield takeLatest(LOG_PERSONAL_INFO_ACCESS, onPersonalInfoAccess);
}
