import { put, takeLatest, call } from 'redux-saga/effects';
import { forgotPassword } from 'shared/endpoints/securityEndpoint';
import {
  ON_FORGOT_PASSWORD,
  onForgotPasswordSuccess,
  onForgotPasswordError } from 'client/actions/userActions';
import { goToUrl } from 'client/actions/routeActions';
import { DEFAULT_MODAL } from 'shared/constants/loginModalType';

export function * onForgotPassword (action) {
  try {
    yield call(forgotPassword, action.email);
    yield put(onForgotPasswordSuccess());
    if (action.loginType === DEFAULT_MODAL) {
      yield put(goToUrl('/forgot-password?confirm=1'));
    }
  } catch (e) {
    yield put(onForgotPasswordError(e));
  }
}

export function * watchForgotPasswordScreen () {
  yield takeLatest(ON_FORGOT_PASSWORD, onForgotPassword);
}
