import { ApiRequest } from 'shared/utils/apiUtils';

export function fetchRepeatOrders () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/repeatOrder'
  });
  return req.executeAndGetBody();
}

export function fetchRepeatOrdersWithOutPriceDetails () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/repeatOrder-without-price-details'
  });
  return req.executeAndGetBody();
}

export function fetchRepeatOrderById (repeatOrderId) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/repeatOrder/${repeatOrderId}`
  });
  return req.execute();
}

export function deleteRepeatOrder (repeatOrderId) {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/repeatOrder/${repeatOrderId}`
  });
  return req.execute();
}

export function pausePlayRepeatOrder (repeatOrder) {
  const repeatOrderId = repeatOrder._id;
  const req = new ApiRequest({
    method: 'PUT',
    body: {
      ...repeatOrder
    },
    apiPath: `/repeatOrder/${repeatOrderId}`
  });
  return req.execute();
}

export function addProductToRepeatOrder (payload) {
  const {
    sku,
    orderReference,
    name
  } = payload;
  const quantity = payload.subscriptionProductQuantity;
  const _id = payload.existingSubscriptionId;

  const url = `/repeatOrder/${_id}/add-product`;

  const req = new ApiRequest({
    method: 'POST',
    body: {
      sku,
      quantity,
      _id,
      orderReference,
      name
    },
    apiPath: url
  });
  return req.executeAndGetBody();
}

export function createRepeatOrder (payload) {
  const url = `/repeatOrder`;
  const req = new ApiRequest({
    method: 'POST',
    body: {
      ...payload
    },
    apiPath: url
  });
  return req.executeAndGetBody();
}

export function removeProduct ({ orderReference, sku, quantity, repeatOrderId }) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/repeatOrder/${repeatOrderId}/remove-product`,
    body: {
      orderReference,
      sku,
      quantity
    }
  });
  return req.execute();
}

export function addProduct ({ orderReference, sku, quantity, name, repeatOrderId }) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/repeatOrder/${repeatOrderId}/add-product`,
    body: {
      orderReference,
      sku,
      quantity,
      name
    }
  });
  return req.execute();
}

export function updatePayment (body, repeatOrderId) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/repeatOrder/${repeatOrderId}/payment`,
    body
  });
  return req.execute();
}

export function editRepeatOrder (repeatOrder) {
  const repeatOrderId = repeatOrder.repeatOrderId;
  const req = new ApiRequest({
    method: 'PUT',
    body: {
      ...repeatOrder
    },
    apiPath: `/repeatOrder/${repeatOrderId}`
  });
  return req.execute();
}
