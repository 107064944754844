import { channel } from 'redux-saga';
import { fromJS } from 'immutable';
import { put, select, takeLatest, call, fork } from 'redux-saga/effects';
import {
  buildProductUrl,
  buildFamilyUrl
} from 'client/utils/urlUtils';
import first from 'lodash/first';
import flowRight from 'lodash/flowRight';
import omit from 'lodash/omit';
import { getProductById } from 'shared/endpoints/productVariantEndpoint';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import {
  REDIRECT_IF_REQUIRED,
  redirectUrl
} from 'client/actions/searchActions';
import {
  searchToGetCategories,
  searchToGetFilters
} from 'shared/endpoints/searchEndpoint';

import { goToUrl, gotoExternalUrl } from 'client/actions/routeActions';
import { TWENTY_SEARCH_RESULTS } from 'shared/constants/search';

import {
  loadProductsByFamilies
} from 'client/actions/categoryFamiliesActions';
import { categoryFamiliesChannelHandler } from './categoryFamiliesSagas';

import { buildCategoryUrl } from 'shared/utils/pathUtils';

const getWebsiteUrl = (state) =>
  state.getIn(['config', 'websiteUrl']);

export function * doCategoriesSearch ({ payload }) {
  const searchChannel = yield call(channel);
  yield fork(categoryFamiliesChannelHandler, searchChannel);
  try {
    const { location, pathname, ...query } = payload;
    const searchResults = yield call(searchToGetCategories, query);
    const websiteUrl = yield select(getWebsiteUrl);
    const { originalKeyword, keywordUsed } = searchResults || {};
    if (originalKeyword !== keywordUsed) {
      yield put(updateDataLayer({
        event: 'auto_correct',
        originalSearchTerm: originalKeyword,
        newSearchTerm: keywordUsed
      }));
    }
    if (searchResults.totalPaginationCount &&
       payload.skip >= searchResults.totalPaginationCount &&
       !payload.price) {
      yield put(goToUrl(websiteUrl + '/page-not-found'));
    } else {
      const redirectParentCategory = fromJS(searchResults.redirectParentCategory);
      if (redirectParentCategory && redirectParentCategory.size) {
        const parentCategoryUrl = buildCategoryUrl(redirectParentCategory.get(0));
        yield put(redirectUrl({ redirectUrl: parentCategoryUrl, statusCode: 302 }));
      }
      const noRowsRequired = payload.limit ? payload.limit : TWENTY_SEARCH_RESULTS;
      yield put(searchChannel, loadProductsByFamilies(payload.categoryId, fromJS(searchResults.families), 0, noRowsRequired, location));
    }
  } finally {
    searchChannel.close();
  }
}

export function * doFilterSearch ({ payload }) {
  const { location, pathname, ...query } = payload;
  const searchResults = yield call(searchToGetFilters, { ...query });
  if (searchResults && !query.price && !searchResults.totalCount) {
    yield put(updateDataLayer({ event: 'no_results_search' }));
  }
}

export function * doRedirectIfRequired ({ payload }) {
  /* Called on serverside for search page url redirection */
  const { location } = payload;
  const { brandId, rating, price } = location.query;
  const attributeIdsInQuery = Object.keys(location.query).filter((x) => /^[0-9]+$/.test(x));
  const redirectToCategory = brandId || rating || price || attributeIdsInQuery.length;
  if (redirectToCategory) {
    yield put(redirectUrl({ redirectUrl: location.pathname, statusCode: 302 }));
  }
}

export function * watchSearch () {
  yield takeLatest(REDIRECT_IF_REQUIRED, doRedirectIfRequired);
}

const checkIfFilterApplied = (appliedFilter) => {
  const keys = Object.keys(omit(appliedFilter, ['query', 'skip', 'limit']));
  return keys.length > 0;
};

export const maybeRedirect = function * ({ payload: { families, query, keywordUsed, originalKeyword, totalFamiliesCount, appliedFilter } }) {
  if (families && families.length) {
    const fam = first(families);
    const isProd = fam.numberOfMatchingProducts === 1;
    const shouldRedirect = totalFamiliesCount === 1;
    const productRedirect = keywordUsed && keywordUsed === originalKeyword;
    const isFilterApplied = checkIfFilterApplied(appliedFilter);
    if (!shouldRedirect || !process.browser || isFilterApplied) {
      return;
    }

    if (productRedirect && shouldRedirect)
    {
      const sku = first(fam.products);
      const urlBuilder = isProd ? buildProductUrl : buildFamilyUrl;
      const { body } = yield call(getProductById, sku);
      const urlSlug = isProd ? body && body.name : fam.familyObject.familyHeading;
      const urlId = isProd ? sku : fam.id;
      query.query = keywordUsed;
      const forwardedQuery = isProd ? {} : query;
      const redirect = flowRight(put, gotoExternalUrl, urlBuilder);
      yield redirect(forwardedQuery, fam.familyObject.topCategoryName, fam.familyObject.terminalCategoryName, urlSlug, urlId);
    }
  }
};
