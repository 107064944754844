import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import { validateForm } from 'client/utils/formValidation';
import { getLocalizedString } from 'localization/localizer';
import FormSelectField from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import SvgLoader from 'client/components/svg/SvgLoader';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import {
  INDIVIDUAL_ACCOUNT_IMAGE,
  USER_TYPE_INDIVIDUALS,
  CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_INDIVIDUAL_ACCOUNT_VIEW,
  WELCOME_ACCOUNT_VIEW
} from '../constants';
import { fromJS } from 'immutable';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { setView } from 'client/actions/loginAndRegistration';
import { makeValidator, isRequired, maxLengthWithCustomError, isUKPhoneNumber, isValidEmailForNewUser } from 'client/utils/validators';
import ZoroBenefits from '../zoroBenefits';
import EmailAndPassword from './EmailAndPassword';
import { asyncValidate } from 'client/sagas/loginSagas';

if (process.browser) {
  require('./AboutYouForm.scss');
}

const validate = makeValidator({
  firstName: [
    isRequired('First name'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 15 characters long',
      maxLength: 15
    })
  ],
  lastName: [
    isRequired('Last name'),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 15 characters long',
      maxLength: 15
    })
  ],
  phoneNumber: [
    isRequired('Phone number'),
    isUKPhoneNumber(getLocalizedString('error.invalid.phoneNumber'))
  ],
  email: [
    isRequired('Email address'),
    isValidEmailForNewUser()
  ]
});

const titles = {
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  ms: 'Ms',
  dr: 'Dr'
};

const FORM_NAME = 'aboutYouForm';
const AboutYouForm = (props) => {
  const {
    handleSubmit,
    dispatchSetView,
    view
  } = props;

  const submit = (values) => {
    return validateForm(values, validate)
      .then(() => {
        dispatchSetView(CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW);
      });
  };

  return (<div className="col-xs-12 WelcomeToZoro_container">
    <div className="hidden-xs hidden-sm col-xs-5 WelcomeToZoro_leftImageContainer">
      <ZoroImage
        className="WelcomeToZoro_leftImage"
        src={ INDIVIDUAL_ACCOUNT_IMAGE } />
      <ZoroBenefits userType={ USER_TYPE_INDIVIDUALS }/>
    </div>
    <div className="col-xs-12 col-md-7 AboutYou">
      <div className="WelcomeToZoro_logo">
        <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
      </div>
      <div className="col-xs-10 col-xs-offset-1 WelcomeToZoro_userTypeContainer">
        { CREATE_INDIVIDUAL_ACCOUNT_VIEW === view ? <React.Fragment>
          <h1 className="AboutYou_title">
            Personal Details
          </h1>
          <form onSubmit={ handleSubmit(submit) } className="AboutYou_Form">
            <div className="col-xs-12 AboutYou">
              <div className="row">
                <div className="col-xs-3">
                  <Field
                    name="title"
                    id="title"
                    className="AboutYou_select form-control"
                    labelClassName="AboutYou_labelInput control-label required"
                    component={ FormSelectField }
                    labelResourceName="registration.label.title"
                    datae2e="title"
                    value={ Object.values(titles)[0] }
                  >
                    {
                      Object.values(titles).map((item, i) =>
                        <option selected={ !i } key={ item } value={ item }>{ item }</option>
                      )
                    }
                  </Field>
                </div>
                <div className="col-xs-9">
                  <Field
                    name="firstName"
                    id="firstName"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.firstName"
                    datae2e="firstName"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="lastName"
                    id="lastName"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.lastName"
                    datae2e="lastName"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="email"
                    id="email"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="email"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.placeholder.emailAddress"
                    datae2e="email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.phoneNumber"
                    datae2e="phoneNumber"
                  />
                </div>
              </div>
              <div className="row AboutYou-btns">
                <div className="col-xs-12 BusinessAccountRegistrationForm_finalStep-btns">
                  <div className="BusinessAccountRegistrationForm_finalStep-back AboutYou-backBtn">
                    <SecondaryButton
                      datae2e="back"
                      className="ForgotPasswordNewForm_backToLogin"
                      onClick={ () => dispatchSetView(WELCOME_ACCOUNT_VIEW) }
                    >
                        Back
                    </SecondaryButton>
                  </div>
                  <div className="ForgotPasswordNewForm_submitBtn BusinessAccountRegistrationForm_finalStep-createAccount AboutYou-continueBtn">
                    <PrimaryButton
                      type="submit"
                      datae2e="continue">
                      Continue
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment> : null }
        {
          CREATE_INDIVIDUAL_ACCOUNT_FINAL_STEP_VIEW === view ? <React.Fragment>
            <EmailAndPassword />
          </React.Fragment> : null
        }
      </div>
    </div>
  </div>);
};

export const mapStateToProps = (state) => {
  const primaryContactDetailsForm = (getFormValues('primaryContactForm')(state) || fromJS({})).toJS();

  return {
    aboutYouFormValues: getFormValues(FORM_NAME)(state),
    view: state.getIn(['loginAndRegistration', 'view']),
    initialValues: {
      title: 'Mr',
      email: primaryContactDetailsForm?.email || '',
      firstName: primaryContactDetailsForm?.firstName || '',
      lastName: primaryContactDetailsForm?.lastName || '',
      phoneNumber: primaryContactDetailsForm?.phoneNumber || ''
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetView: (view) => dispatch(setView({ view }))
});

const reduxAboutYouForm = reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate,
  destroyOnUnmount: false,
  asyncBlurFields: ['email']
})(AboutYouForm);

export { AboutYouForm };
export default connect(mapStateToProps, mapDispatchToProps)(reduxAboutYouForm);
