import { FREE_DELIVERY_THRESHOLD, ZORO_META_TITLE, ZORO_META_SITE_NAME } from 'shared/constants/common';
import { ASSETS_S3_LOCATION } from 'shared/constants/images';
export const getPages = (url, totalProductsCount, params) => {
  const brandName = params?.pageUrl;
  const zoroLogo = `${ASSETS_S3_LOCATION}/mimg/assets/logos/zoro-logo-meta-tag.jpg`;
  const pages = {
    '/reviews': {
      title: `Reviews | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Read the latest customer reviews of Zoro.co.uk' },
        { 'name': 'keywords', 'content': 'Zoro reviews' },
        { 'name': 'itemprop:name', 'content': 'Zoro Reviews' },
        { 'name': 'itemprop:description', 'content': 'Read the latest customer reviews of Zoro.co.uk' },
        { 'property': 'og:title', 'content': 'Zoro Reviews' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/reviews' },
        { 'property': 'og:description', 'content': 'Read the latest customer reviews of Zoro.co.uk' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Reviews' },
        { 'name': 'twitter:description', 'content': 'Read the latest customer reviews of Zoro.co.uk' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/modern-slavery-act': {
      title: `Modern Slavery Act | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'itemprop:name', 'content': 'Zoro Modern Slavery Act' },
        { 'name': 'itemprop:description', 'content': 'Zoro UK is committed to the continuous improvement of our practices to combat slavery and human trafficking.' },
        { 'property': 'og:title', 'content': 'Zoro Modern Slavery Act' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/modern-slavery-act' },
        { 'property': 'og:description', 'content': 'Zoro UK is committed to the continuous improvement of our practices to combat slavery and human trafficking.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Modern Slavery Act' },
        { 'name': 'twitter:description', 'content': 'Zoro UK is committed to the continuous improvement of our practices to combat slavery and human trafficking.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/privacy-policy': {
      title: `Privacy Policy | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Our Privacy Policy applies to our websites and brands - namely www.zoro.co.uk and any other website, mobile application, or other online service that links to this Privacy Policy (collectively, our ‘Service’)' },
        { 'name': 'keywords', 'content': 'Zoro privacy policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro Privacy Policy' },
        { 'name': 'itemprop:description', 'content': 'Our Privacy Policy applies to our websites and brands - namely www.zoro.co.uk and any other website, mobile application, or other online service that links to this Privacy Policy (collectively, our ‘Service’)' },
        { 'property': 'og:title', 'content': 'Zoro Privacy Policy' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/privacy-policy' },
        { 'property': 'og:description', 'content': 'Our Privacy Policy applies to our websites and brands - namely www.zoro.co.uk and any other website, mobile application, or other online service that links to this Privacy Policy (collectively, our ‘Service’)' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Privacy Policy' },
        { 'name': 'twitter:description', 'content': 'Our Privacy Policy applies to our websites and brands - namely www.zoro.co.uk and any other website, mobile application, or other online service that links to this Privacy Policy (collectively, our ‘Service’)' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/environmental-policy': {
      title: `Environmental Policy | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Zoro UK committed to the protection of the environment, including prevention of pollution and other specific commitment(s) relevant to the context of the organization and to fulfil its compliance obligations as part of our business strategy.' },
        { 'name': 'keywords', 'content': 'Zoro environmental policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro Environmental Policy' },
        { 'name': 'itemprop:description', 'content': 'Zoro UK committed to the protection of the environment, including prevention of pollution and other specific commitment(s) relevant to the context of the organization and to fulfil its compliance obligations as part of our business strategy.' },
        { 'property': 'og:title', 'content': 'Zoro Environmental Policy' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/environmental-policy' },
        { 'property': 'og:description', 'content': 'Zoro UK committed to the protection of the environment, including prevention of pollution and other specific commitment(s) relevant to the context of the organization and to fulfil its compliance obligations as part of our business strategy.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Environmental Policy' },
        { 'name': 'twitter:description', 'content': 'Zoro UK committed to the protection of the environment, including prevention of pollution and other specific commitment(s) relevant to the context of the organization and to fulfil its compliance obligations as part of our business strategy.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/health-safety-policy': {
      title: `Health & Safety Policy | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Zoro UK Limited are committed to ensuring the health, safety and welfare of each employee and any other persons who works closely to its operations. An exemplary health and safety record goes hand in hand with high productivity and quality standards across the organisation.' },
        { 'name': 'keywords', 'content': 'Zoro health and safety policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro Health & Safety Policy' },
        { 'name': 'itemprop:description', 'content': 'Zoro UK Limited are committed to ensuring the health, safety and welfare of each employee and any other persons who works closely to its operations. An exemplary health and safety record goes hand in hand with high productivity and quality standards across the organisation.' },
        { 'property': 'og:title', 'content': 'Zoro Health & Safety Policy' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/health-safety-policy' },
        { 'property': 'og:description', 'content': 'Zoro UK Limited are committed to ensuring the health, safety and welfare of each employee and any other persons who works closely to its operations. An exemplary health and safety record goes hand in hand with high productivity and quality standards across the organisation.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Health & Safety Policy' },
        { 'name': 'twitter:description', 'content': 'Zoro UK Limited are committed to ensuring the health, safety and welfare of each employee and any other persons who works closely to its operations. An exemplary health and safety record goes hand in hand with high productivity and quality standards across the organisation.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/quality-policy': {
      title: `Quality Policy | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Zoro UK is a leading supplier of maintenance, repair and operations products. Our dedicated customer service team offers a fully supported order service alongside our website, available 24 hours a day.' },
        { 'name': 'keywords', 'content': 'Zoro quality policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro Quality Policy' },
        { 'name': 'itemprop:description', 'content': 'Zoro UK is a leading supplier of maintenance, repair and operations products. Our dedicated customer service team offers a fully supported order service alongside our website, available 24 hours a day.' },
        { 'property': 'og:title', 'content': 'Zoro Quality Policy' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/quality-policy' },
        { 'property': 'og:description', 'content': 'Zoro UK is a leading supplier of maintenance, repair and operations products. Our dedicated customer service team offers a fully supported order service alongside our website, available 24 hours a day.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Quality Policy' },
        { 'name': 'twitter:description', 'content': 'Zoro UK is a leading supplier of maintenance, repair and operations products. Our dedicated customer service team offers a fully supported order service alongside our website, available 24 hours a day.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/returns': {
      title: `Returns Policy | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'If you have purchased the Goods as a consumer (i.e. wholly or mainly outside of your business, trade or profession), you can change your mind and cancel the contract between you and us for any reason within 14 days of when the Goods are delivered.' },
        { 'name': 'keywords', 'content': 'Zoro returns, Zoro returns policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro Returns Policy' },
        { 'name': 'itemprop:description', 'content': 'If you have purchased the Goods as a consumer (i.e. wholly or mainly outside of your business, trade or profession), you can change your mind and cancel the contract between you and us for any reason within 14 days of when the Goods are delivered.' },
        { 'property': 'og:title', 'content': 'Zoro Returns Policy' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/returns' },
        { 'property': 'og:description', 'content': 'If you have purchased the Goods as a consumer (i.e. wholly or mainly outside of your business, trade or profession), you can change your mind and cancel the contract between you and us for any reason within 14 days of when the Goods are delivered.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Returns Policy' },
        { 'name': 'twitter:description', 'content': 'If you have purchased the Goods as a consumer (i.e. wholly or mainly outside of your business, trade or profession), you can change your mind and cancel the contract between you and us for any reason within 14 days of when the Goods are delivered.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/standard-terms-purchase': {
      title: `Terms of Purchase | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Zoro UK Limited shall mean Zoro UK Limited UK Limited and its subsidiary or associated Companies; Contract shall mean the Order and the Seller's acceptance of the Order. Goods shall mean any goods agreed in the Contract to be purchased by Zoro UK Limited from the Seller (including any part or parts of them)." },
        { 'name': 'keywords', 'content': 'Zoro terms of purchase' },
        { 'name': 'itemprop:name', 'content': 'Zoro Terms of Purchase' },
        { 'name': 'itemprop:description', 'content': "Zoro UK Limited shall mean Zoro UK Limited UK Limited and its subsidiary or associated Companies; Contract shall mean the Order and the Seller's acceptance of the Order. Goods shall mean any goods agreed in the Contract to be purchased by Zoro UK Limited from the Seller (including any part or parts of them)." },
        { 'property': 'og:title', 'content': 'Zoro Terms of Purchase' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/standard-terms-purchase' },
        { 'property': 'og:description', 'content': "Zoro UK Limited shall mean Zoro UK Limited UK Limited and its subsidiary or associated Companies; Contract shall mean the Order and the Seller's acceptance of the Order. Goods shall mean any goods agreed in the Contract to be purchased by Zoro UK Limited from the Seller (including any part or parts of them)." },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Terms of Purchase' },
        { 'name': 'twitter:description', 'content': "Zoro UK Limited shall mean Zoro UK Limited UK Limited and its subsidiary or associated Companies; Contract shall mean the Order and the Seller's acceptance of the Order. Goods shall mean any goods agreed in the Contract to be purchased by Zoro UK Limited from the Seller (including any part or parts of them)." },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/terms-of-access': {
      title: `Terms of Access | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "This document, and any terms, conditions, policies and notices linked or referenced from this document (collectively, the 'Terms of Access') set out the terms on which you are granted access to and use our web site at https://www.zoro.co.uk(our 'Site') and any features or functionalities that we make available on the Site." },
        { 'name': 'keywords', 'content': 'Zoro terms of access' },
        { 'name': 'itemprop:name', 'content': 'Zoro Terms of Access' },
        { 'name': 'itemprop:description', 'content': "This document, and any terms, conditions, policies and notices linked or referenced from this document (collectively, the 'Terms of Access') set out the terms on which you are granted access to and use our web site at https://www.zoro.co.uk(our 'Site') and any features or functionalities that we make available on the Site." },
        { 'property': 'og:title', 'content': 'Zoro Terms of Access' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/terms-of-access' },
        { 'property': 'og:description', 'content': "This document, and any terms, conditions, policies and notices linked or referenced from this document (collectively, the 'Terms of Access') set out the terms on which you are granted access to and use our web site at https://www.zoro.co.uk(our 'Site') and any features or functionalities that we make available on the Site." },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Terms of Access' },
        { 'name': 'twitter:description', 'content': "This document, and any terms, conditions, policies and notices linked or referenced from this document (collectively, the 'Terms of Access') set out the terms on which you are granted access to and use our web site at https://www.zoro.co.uk(our 'Site') and any features or functionalities that we make available on the Site." },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/terms-of-sale': {
      title: `Terms of Sale | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "These terms and conditions of sale (the ‘Terms and Conditions’) set out the terms on which we sell, deliver and install any products you order from us (the ‘Goods’) through our website at https://www.zoro.co.uk (the 'Site')." },
        { 'name': 'keywords', 'content': 'Zoro terms and conditions, Zoro terms of sale' },
        { 'name': 'itemprop:name', 'content': 'Zoro Terms of Sale' },
        { 'name': 'itemprop:description', 'content': "These terms and conditions of sale (the ‘Terms and Conditions’) set out the terms on which we sell, deliver and install any products you order from us (the ‘Goods’) through our website at https://www.zoro.co.uk (the 'Site')." },
        { 'property': 'og:title', 'content': 'Zoro Terms of Sale' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/terms-of-sale' },
        { 'property': 'og:description', 'content': "These terms and conditions of sale (the ‘Terms and Conditions’) set out the terms on which we sell, deliver and install any products you order from us (the ‘Goods’) through our website at https://www.zoro.co.uk (the 'Site')." },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Terms of Sale' },
        { 'name': 'twitter:description', 'content': "These terms and conditions of sale (the ‘Terms and Conditions’) set out the terms on which we sell, deliver and install any products you order from us (the ‘Goods’) through our website at https://www.zoro.co.uk (the 'Site')." },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/affiliate-program': {
      title: `Affiliate Program | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'With a growing assortment of 250k competitively priced products Zoro.co.uk is attractive to a broad range of customers and a great opportunity for affiliate partners.' },
        { 'name': 'keywords', 'content': 'Zoro affiliate program' },
        { 'name': 'itemprop:name', 'content': 'Zoro Affiliate Program' },
        { 'name': 'itemprop:description', 'content': 'With a growing assortment of 250k competitively priced products Zoro.co.uk is attractive to a broad range of customers and a great opportunity for affiliate partners.' },
        { 'property': 'og:title', 'content': 'Zoro Affiliate Program' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/affiliate-program' },
        { 'property': 'og:description', 'content': 'With a growing assortment of 250k competitively priced products Zoro.co.uk is attractive to a broad range of customers and a great opportunity for affiliate partners.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Affiliate Program' },
        { 'name': 'twitter:description', 'content': 'With a growing assortment of 250k competitively priced products Zoro.co.uk is attractive to a broad range of customers and a great opportunity for affiliate partners.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/global-sourcing': {
      title: `Global Sourcing | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Zoro.co.uk is part of the Grainger Group of companies and so has access to a global supply chain of trusted and vetted suppliers around the world.' },
        { 'name': 'keywords', 'content': 'Zoro global sourcing, Zoro sourcing' },
        { 'name': 'itemprop:name', 'content': 'Zoro Global Sourcing' },
        { 'name': 'itemprop:description', 'content': 'Zoro.co.uk is part of the Grainger Group of companies and so has access to a global supply chain of trusted and vetted suppliers around the world.' },
        { 'property': 'og:title', 'content': 'Zoro Global Sourcing' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/global-sourcing' },
        { 'property': 'og:description', 'content': 'Zoro.co.uk is part of the Grainger Group of companies and so has access to a global supply chain of trusted and vetted suppliers around the world.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Global Sourcing' },
        { 'name': 'twitter:description', 'content': 'Zoro.co.uk is part of the Grainger Group of companies and so has access to a global supply chain of trusted and vetted suppliers around the world.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/digital-services': {
      title: `Digital Services | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Zoro offers a range of digital services aimed at making our customers' lives easier, whether you are a reseller or end user." },
        { 'name': 'keywords', 'content': 'Zoro digital services' },
        { 'name': 'itemprop:name', 'content': "Zoro's Digital Services" },
        { 'name': 'itemprop:description', 'content': "Zoro offers a range of digital services aimed at making our customers' lives easier, whether you are a reseller or end user." },
        { 'property': 'og:title', 'content': "Zoro's Digital Services" },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/digital-services' },
        { 'property': 'og:description', 'content': "Zoro offers a range of digital services aimed at making our customers' lives easier, whether you are a reseller or end user." },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': "Zoro's Digital Services" },
        { 'name': 'twitter:description', 'content': "Zoro offers a range of digital services aimed at making our customers' lives easier, whether you are a reseller or end user." },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/helplines': {
      title: `Supplier Support | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'If you require further technical product support, you can refer to our supplier contact list.' },
        { 'name': 'keywords', 'content': 'Zoro technical support, Zoro suppliers, Zoro supplier list, Zoro supplier support, Zoro product support' },
        { 'name': 'itemprop:name', 'content': "Zoro's Supplier Support" },
        { 'name': 'itemprop:description', 'content': 'If you require further technical product support, you can refer to our supplier contact list.' },
        { 'property': 'og:title', 'content': "Zoro's Supplier Support" },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/helplines' },
        { 'property': 'og:description', 'content': 'If you require further technical product support, you can refer to our supplier contact list.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': "Zoro's Supplier Support" },
        { 'name': 'twitter:description', 'content': 'If you require further technical product support, you can refer to our supplier contact list.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/faq': {
      title: `Frequently Answered Questions (FAQs) | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Answers to frequently asked questions.' },
        { 'name': 'keywords', 'content': "Zoro FAQ, Zoro's frequently answered questions" },
        { 'name': 'itemprop:name', 'content': "Zoro's Frequently Answered Questions (FAQs)" },
        { 'name': 'itemprop:description', 'content': 'Answers to frequently asked questions.' },
        { 'property': 'og:title', 'content': "Zoro's Frequently Answered Questions (FAQs)" },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/faq' },
        { 'property': 'og:description', 'content': 'Answers to frequently asked questions.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': "Zoro's Frequently Answered Questions (FAQs)" },
        { 'name': 'twitter:description', 'content': 'Answers to frequently asked questions.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/delivery': {
      title: `Free next day delivery on all orders over £${FREE_DELIVERY_THRESHOLD} | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'We aim for next working day delivery on orders placed before 3pm. Orders placed after 3pm will aim to be delivered within two working days.' },
        { 'name': 'keywords', 'content': 'Zoro delivery' },
        { 'name': 'itemprop:name', 'content': `Zoro - free next day delivery on all orders over £${FREE_DELIVERY_THRESHOLD}.` },
        { 'name': 'itemprop:description', 'content': 'We aim for next working day delivery on orders placed before 3pm. Orders placed after 3pm will aim to be delivered within two working days.' },
        { 'property': 'og:title', 'content': `Zoro - free next day delivery on all orders over £${FREE_DELIVERY_THRESHOLD}.` },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/delivery' },
        { 'property': 'og:description', 'content': 'We aim for next working day delivery on orders placed before 3pm. Orders placed after 3pm will aim to be delivered within two working days.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': `Zoro - free next day delivery on all orders over £${FREE_DELIVERY_THRESHOLD}.` },
        { 'name': 'twitter:description', 'content': 'We aim for next working day delivery on orders placed before 3pm. Orders placed after 3pm will aim to be delivered within two working days.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/zoro-business-account': {
      title: `Zoro for Business | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Order everything your business needs, any time you want, and pay before the end of the following month.' },
        { 'name': 'keywords', 'content': 'Zoro for business, Zoro business accounts, Zoro credit line, Zoro trade account' },
        { 'name': 'itemprop:name', 'content': 'Zoro for Business' },
        { 'name': 'itemprop:description', 'content': 'Order everything your business needs, any time you want, and pay before the end of the following month.' },
        { 'property': 'og:title', 'content': 'Zoro for Business' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/zoro-business-account' },
        { 'property': 'og:description', 'content': 'Order everything your business needs, any time you want, and pay before the end of the following month.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro for Business' },
        { 'name': 'twitter:description', 'content': 'Order everything your business needs, any time you want, and pay before the end of the following month.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/business-accounts-multiuser': {
      title: 'Add users to your Zoro Business Account',
      metaTags: [
        { 'name': 'description', 'content': 'Add others to your trade account and consolidate your businesses spending.' },
        { 'name': 'keywords', 'content': 'Zoro Business Account, Zoro Multi-User Accounts, Zoro trade accounts, business spending' },
        { 'name': 'itemprop:name', 'content': 'Add users to your Zoro Business Account' },
        { 'name': 'itemprop:description', 'content': 'Add others to your trade account and consolidate your businesses spending.' },
        { 'property': 'og:title', 'content': 'Add users to your Zoro Business Account' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/business-accounts-multiuser' },
        { 'property': 'og:description', 'content': 'Add others to your trade account and consolidate your businesses spending.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Add multiple users to your Zoro Business Account' },
        { 'name': 'twitter:description', 'content': 'Add others to your trade account and consolidate your businesses spending.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/collections/business-accounts-pricing': {
      title: `Tiered Pricing Programme - spend more, save more! | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Every purchase you make over a 30-day period will take you closer to one of our three pricing tiers.' },
        { 'name': 'keywords', 'content': 'Tiered Pricing, Tiered Pricing Programme' },
        { 'name': 'itemprop:name', 'content': 'Tiered Pricing Programme - spend more, save more!' },
        { 'name': 'itemprop:description', 'content': 'Every purchase you make over a 30-day period will take you closer to one of our three pricing tiers.' },
        { 'property': 'og:title', 'content': 'Tiered Pricing Programme - spend more, save more!' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/collections/business-accounts-pricing' },
        { 'property': 'og:description', 'content': 'Every purchase you make over a 30-day period will take you closer to one of our three pricing tiers.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Tiered Pricing Programme - spend more, save more!' },
        { 'name': 'twitter:description', 'content': 'Every purchase you make over a 30-day period will take you closer to one of our three pricing tiers.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/business-accounts-quotations': {
      title: `Zoro Quotations - lock in prices for 14 days | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Quotations are easy to create. Click the ‘Add to Quotation’ button on any product page. Create new quotations or add the product to an existing one.' },
        { 'name': 'keywords', 'content': 'Zoro Quotations' },
        { 'name': 'itemprop:name', 'content': 'Zoro Quotations - lock in prices for 14 days.' },
        { 'name': 'itemprop:description', 'content': 'Quotations are easy to create. Click the ‘Add to Quotation’ button on any product page. Create new quotations or add the product to an existing one.' },
        { 'property': 'og:title', 'content': 'Zoro Quotations - lock in prices for 14 days.' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/business-accounts-quotations' },
        { 'property': 'og:description', 'content': 'Quotations are easy to create. Click the ‘Add to Quotation’ button on any product page. Create new quotations or add the product to an existing one.' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro Quotations - lock in prices for 14 days.' },
        { 'name': 'twitter:description', 'content': 'Quotations are easy to create. Click the ‘Add to Quotation’ button on any product page. Create new quotations or add the product to an existing one.' },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/about-us': {
      title: 'Zoro - About Us',
      metaTags: [
        { 'name': 'description', 'content': "Zoro's extensive range of over 410,000 products includes everything from power tools and chemicals to personal protective equipment." },
        { 'name': 'keywords', 'content': '410,000 products, about Zoro, Zoro delivery service, Zoro customer service, Zoro brand, Grainger family' },
        { 'name': 'itemprop:name', 'content': 'Zoro - About Us' },
        { 'name': 'itemprop:description', 'content': "Zoro's extensive range of over 410,000 products includes everything from power tools and chemicals to personal protective equipment." },
        { 'property': 'og:title', 'content': 'Zoro - About Us' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/about-us' },
        { 'property': 'og:description', 'content': "Zoro's extensive range of over 410,000 products includes everything from power tools and chemicals to personal protective equipment." },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro - About Us' },
        { 'name': 'twitter:description', 'content': "Zoro's extensive range of over 410,000 products includes everything from power tools and chemicals to personal protective equipment." },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    },
    '/info/terms-and-conditions': {
      title: `Our Terms and Conditions for the supply of goods | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Review Zoro.co.uk's official Terms and Conditions. Understand your rights, obligations, and limitations when using our website and services." },
        { 'name': 'keywords', 'content': 'Zoro Terms and Conditions' },
        { 'name': 'itemprop:name', 'content': 'Our Terms and Conditions for the supply of goods by Zoro UK' },
        { 'name': 'itemprop:description', 'content': "Review Zoro.co.uk's official Terms and Conditions. Understand your rights, obligations, and limitations when using our website and services." },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': 'Our Terms and Conditions for the supply of goods by Zoro UK' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/terms-and-conditions' },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:description', 'content': 'Our Terms and Conditions for the supply of goods by Zoro UK' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Our Terms and Conditions for the supply of goods by Zoro UK' },
        { 'name': 'twitter:description', 'content': `Review Zoro.co.uk's official Terms and Conditions. Understand your rights, obligations, and limitations when using our website and services.` },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    '/info/zoro-business-account-terms': {
      title: `Terms & Conditions related to Zoro Trade Accounts (Credit Line) | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Familiarise yourself with Zoro's terms & conditions for credit lines and credit line applciations." },
        { 'name': 'keywords', 'content': 'Zoro Trade Account Application' },
        { 'name': 'itemprop:name', 'content': 'Terms & Conditions related to Zoro Trade Accounts (Credit Line)' },
        { 'name': 'itemprop:description', 'content': "Familiarise yourself with Zoro's terms & conditions for credit lines and credit line applciations." },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': 'Terms & Conditions related to Zoro Trade Accounts (Credit Line)' },
        { 'property': 'og:description', 'content': "Familiarise yourself with Zoro's terms & conditions for credit lines and credit line applciations." },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/zoro-business-account-terms' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Terms & Conditions related to Zoro Trade Accounts (Credit Line)' },
        { 'name': 'twitter:description', 'content': `Familiarise yourself with Zoro's terms & conditions for credit lines and credit line applciations.` },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' }
      ]
    },
    '/info/discount-codes': {
      title: `Exclusive Discount Codes & Offers | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': 'Unlock exclusive savings with our latest discount codes at Zoro.co.uk! Limited-time offers on your favorite products. Shop now and save more on your purchases.' },
        { 'name': 'keywords', 'content': 'Zoro Discount Codes' },
        { 'name': 'itemprop:name', 'content': 'Exclusive Discount Codes & Offers - Save Big at Zoro UK' },
        { 'name': 'itemprop:description', 'content': 'Unlock exclusive savings with our latest discount codes at Zoro.co.uk! Limited-time offers on your favorite products. Shop now and save more on your purchases' },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': 'Exclusive Discount Codes & Offers - Save Big at Zoro UK' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/discount-codes' },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:description', 'content': 'Exclusive Discount Codes & Offers - Save Big at Zoro UK' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Exclusive Discount Codes & Offers - Save Big at Zoro UK' },
        { 'name': 'twitter:description', 'content': 'Unlock exclusive savings with our latest discount codes at Zoro.co.uk! Limited-time offers on your favorite products. Shop now and save more on your purchases' },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    '/info/subscriptions': {
      title: `Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'name': 'keywords', 'content': 'Zoro Scheduled Orders' },
        { 'name': 'itemprop:name', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'name': 'itemprop:description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/subscriptions' },
        { 'property': 'og:description', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'name': 'twitter:description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    '/info/business-accounts': {
      title: `Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'name': 'keywords', 'content': 'Zoro Scheduled Orders' },
        { 'name': 'itemprop:name', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'name': 'itemprop:description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'property': 'og:title', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/subscriptions' },
        { 'property': 'og:description', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Scheduled Orders at Zoro.co.uk - Convenient & Reliable Shopping' },
        { 'name': 'twitter:description', 'content': "Experience hassle-free shopping with Zoro.co.uk's scheduled orders. Set your preferred delivery dates, enjoy consistent stock, and never run out of essentials again. Simplify your shopping today!" },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' }
      ]
    },
    '/info/business-accounts-pricing': {
      title: `Pricing Benefits for Loyal Customers | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Unlock unparalleled savings with Zoro's Tiered Pricing Programme. Up to 7.5% Off all your orders, streamlined ordering, and dedicated support. Enhance your business shopping experience today!" },
        { 'name': 'keywords', 'content': 'Zoro Tiered Princing, Tiered Pricing Programme, Loyalty Programme' },
        { 'name': 'itemprop:name', 'content': `Pricing Benefits for Loyal Customers | ${ZORO_META_TITLE}` },
        { 'name': 'itemprop:description', 'content': "Unlock unparalleled savings with Zoro's Tiered Pricing Programme. Up to 7.5% Off all your orders, streamlined ordering, and dedicated support. Enhance your business shopping experience today!" },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': `Pricing Benefits for Loyal Customers | ${ZORO_META_TITLE}` },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/business-accounts-pricing' },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:description', 'content': 'Pricing Benefits for Loyal Customers with Zoro UK' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Pricing Benefits for Loyal Customers with Zoro UK' },
        { 'name': 'twitter:description', 'content': "Unlock unparalleled savings with Zoro's Tiered Pricing Programme. Up to 7.5% Off all your orders, streamlined ordering, and dedicated support. Enhance your business shopping experience today!" },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    '/info/security-policy': {
      title: `Zoro.co.uk Security Policy - Commitment to Data Protection | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': "Your security is our priority. Learn about Zoro.co.uk's stringent measures to safeguard your personal and financial data. Shop with confidence, knowing we uphold the highest standards of online security." },
        { 'name': 'keywords', 'content': 'Zoro Scurity Policy' },
        { 'name': 'itemprop:name', 'content': 'Zoro.co.uk Security Policy - Commitment to Data Protection' },
        { 'name': 'itemprop:description', 'content': "Your security is our priority. Learn about Zoro.co.uk's stringent measures to safeguard your personal and financial data. Shop with confidence, knowing we uphold the highest standards of online security." },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': 'Zoro.co.uk Security Policy - Commitment to Data Protection' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/info/security-policy' },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:description', 'content': 'Zoro.co.uk Security Policy - Commitment to Data Protection' },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': 'Zoro.co.uk Security Policy - Commitment to Data Protection' },
        { 'name': 'twitter:description', 'content': "Your security is our priority. Learn about Zoro.co.uk's stringent measures to safeguard your personal and financial data. Shop with confidence, knowing we uphold the highest standards of online security." },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    [`/brands/${brandName}`]: {
      title: `${brandName} Products - Trusted Quality & Innovation | ${ZORO_META_TITLE}`,
      metaTags: [
        { 'name': 'description', 'content': `Explore a wide selection of ${brandName} products at Zoro.co.uk. From trusted household essentials to innovative solutions, experience the superior quality and value of ${brandName}. Shop now!` },
        { 'name': 'keywords', 'content': `${brandName}` },
        { 'name': 'itemprop:name', 'content': `${brandName} Products - Trusted Quality & Innovation | Zoro UK` },
        { 'name': 'itemprop:description', 'content': `Explore a wide selection of ${brandName} products at Zoro.co.uk. From trusted household essentials to innovative solutions, experience the superior quality and value of ${brandName}. Shop now!` },
        { 'name': 'itemprop:image', 'content': zoroLogo },
        { 'property': 'og:title', 'content': `${brandName} Products - Trusted Quality & Innovation | Zoro UK` },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': `https://www.zoro.co.uk/brands/${brandName}` },
        { 'property': 'og:image', 'content': zoroLogo },
        { 'property': 'og:description', 'content': `${brandName} Products - Trusted Quality & Innovation | Zoro UK` },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:title', 'content': `${brandName} Products - Trusted Quality & Innovation | Zoro UK` },
        { 'name': 'twitter:description', 'content': `Explore a wide selection of ${brandName} products at Zoro.co.uk. From trusted household essentials to innovative solutions, experience the superior quality and value of ${brandName}. Shop now!` },
        { 'name': 'twitter:card', 'content': 'summary' },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:image', 'content': zoroLogo }
      ]
    },
    '/': {
      title: ZORO_META_TITLE,
      metaTags: [
        { 'name': 'description', 'content': `UK's leading Tools & MRO industry supplier with a number of business-friendly features that make B2B supply shopping quick and easy. Over ${totalProductsCount} products.` },
        { 'name': 'keywords', 'content': 'power tool, hand tool, hammer, power drill, screwdriver, wrench, abrasive, adhesive, personal protection, gloves, gardening, precision, welder, loctite' },
        { 'name': 'itemprop:name', 'content': ZORO_META_TITLE },
        { 'name': 'itemprop:description', 'content': `UK's largest tools supplier, featuring over ${totalProductsCount} products at trade prices. Next day delivery is standard, free from £${FREE_DELIVERY_THRESHOLD}.` },
        { 'property': 'og:title', 'content': ZORO_META_TITLE },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'og:url', 'content': 'https://www.zoro.co.uk/' },
        { 'property': 'og:description', 'content': `UK's largest tools supplier, featuring over ${totalProductsCount} products at trade prices. Next day delivery is standard, free from £${FREE_DELIVERY_THRESHOLD}.` },
        { 'property': 'og:site_name', 'content': ZORO_META_SITE_NAME },
        { 'name': 'twitter:site', 'content': '@zoro_uk' },
        { 'name': 'twitter:creator', 'content': '@zoro_uk' },
        { 'name': 'twitter:title', 'content': 'Zoro - Experts in Hand Tools, Power Tools and PPE' },
        { 'name': 'twitter:description', 'content': `UK's largest tools supplier, featuring over ${totalProductsCount} products at trade prices. Next day delivery is standard, free from £${FREE_DELIVERY_THRESHOLD}.` },
        { 'name': 'twitter:card', 'content': 'summary' }
      ]
    }
  };

  return pages[url];
};
