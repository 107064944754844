import { connect } from 'react-redux';
import React from 'react';
import MyAccount from './MyAccount';
import { logoutUser } from 'client/actions/userActions';
import { getAccountDetails } from 'client/actions/accountActions';

function mapStateToProps (state, ownProps) {
  const loyaltyDiscount = state.getIn(['user', 'accountDetails', 'loyaltyDiscount']);
  const isMobile = state.getIn(['deviceInfo', 'isMobile']);
  return {
    url: ownProps.location.pathname,
    user: state.get('user'),
    loyaltyDiscount: loyaltyDiscount ? loyaltyDiscount.toJS() : {},
    isMobile,
    hasActiveTradeAccount: state.getIn(['auth', 'hasActiveTradeAccount'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    logoutUser: () => dispatch(logoutUser()),
    getAccountDetails: () => dispatch(getAccountDetails())
  };
}

class MyAccountInjectionWrapper extends React.Component {
  componentWillMount () { // eslint-disable-line react/no-deprecated
    this.props.getAccountDetails();
  }

  render () {
    return <MyAccount { ...this.props } />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  MyAccountInjectionWrapper
);
