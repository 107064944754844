import { fromJS } from 'immutable';
import { MODAL } from '../PaymentMethod/constants';
export const braintreeSelectors = {
  cards: (state) => state.getIn(['braintree', 'cards']).toJS(),
  instances: {
    threeDSecure: (state) => state.getIn(['braintree', '3ds', 'instance']),
    dataCollector: (state) => state.getIn(['braintree', 'dataCollector', 'instance']),
    hostedFields: (state) => state.getIn(['braintree', 'hostedFields', 'instance'])
  }
};

export const orderSelectors = {
  billingAddress: (state) => state.getIn(['user', 'address', 'billing']),
  tradeAccountAddress: (state) => state.getIn(['user', 'accountDetails', 'tradeAccount', 'invoiceContact']),
  billingAddressForm: (state) => state.getIn(['form', 'billingAddressForm', 'values']).toJS(),
  shippingAddress: (state) => state.getIn(['user', 'address', 'delivery']).toJS(),
  discountCode: (state) => state.getIn(['cart', 'discount', 'code']),
  csAgent: {
    name: (state) => state.getIn(['customerService', 'csAgentName']),
    displayName: (state) => state.getIn(['customerService', 'csAgentDisplayName'])
  },
  poReference: (state) => state.getIn(['user', 'poReference']),
  isPriceWithVat: (state) => state.getIn(['user', 'isPriceWithVat']),
  isPaymentFlowSkipped: (state) => state.getIn(['checkout', 'isPaymentFlowSkipped']),
  accountStatus: (state) => state.getIn(['user', 'accountDetails', 'tradeAccount', 'status'], ''),
  fromQuotationId: (state) => state.getIn(['businessAccountOrderMessage', 'quotationId']),
  loadState: (state) => state.getIn([ 'cart', 'loadState', 'type' ]),
  cart: (state) => state.getIn(['cart'])
};

export const paymentMethodSelectors = {
  payByExistingCard: {
    selectedCard: (selector) => (state) => state.getIn(['ui', 'payByExistingCard', selector, 'selectedCard']).toJS(),
    token: (selector) => (state) => state.getIn(['ui', 'payByExistingCard', selector, 'selectedCard', 'token']),
    isDefaultCard: (selector) => (state) => state.getIn(['ui', 'payByExistingCard', selector, 'selectedCard', 'isDefault']),
    billingAddress: (selector) => (state) => state.getIn(['ui', 'payByExistingCard', selector, 'selectedCard', 'billingAddress'])
  },
  payByNewCard: {
    nameOnCard: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'nameOnCard']),
    saveCardForFutureOrders: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'saveCardForFutureOrders'], false),
    addressFormOpen: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'showAddressForm'], false)
  },
  paypal: {
    data: (selector) => (state) => state.getIn(['ui', 'payByPaypal', selector, 'data'])
  },
  applePay: {
    data: (selector) => (state) => state.getIn(['braintree', 'applePay', 'data'])
  },
  googlePay: {
    data: (selector) => (state) => state.getIn(['braintree', 'googlePay', 'data'])
  },
  paymentMethod: (selector) => (state) => state.getIn(['ui', 'paymentMethod', selector, 'method'])
};

export const validationSelectors = {
  braintree: {
    cvv: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'hostedFields', 'cvv']),
    cardNumber: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'hostedFields', 'number']),
    expirationDate: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'hostedFields', 'expirationDate']),
    cardHolderName: (selector) => (state) => state.getIn(['ui', 'payByNewCard', selector, 'hostedFields', 'cardholderName'])
  },
  form: {
    deliveryAddress: (state) => state.getIn(['form', 'deliveryAddressForm', 'syncErrors']),
    billingAddress: (state) => state.getIn(['form', 'billingAddressForm', 'syncErrors'])
  }
};

export const accountSelectors = {
  defaultPaymentMethod: (state) => state.getIn(['user', 'accountDetails', 'defaultPaymentMethod']),
  isBusinessAccountOrderOnHold: (state) => (state.getIn(['cart', 'tradeAccountButton', 'messagePosition'], '') === MODAL),
  accountDetails: (state) => state.getIn(['user', 'accountDetails'], fromJS({})),
  cartPrice: (state) => state.getIn(['cart', 'cartPrice'], fromJS({}))
};
