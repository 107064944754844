import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';

const FormField = (props) => {
  const {
    input,
    type,
    placeholderResourceName,
    className,
    label,
    labelResourceName,
    labelClassName,
    datae2e,
    tooltip,
    id,
    autoComplete,
    autoFocus,
    formClassName,
    disabled,
    min,
    meta: { touched, error }
  } = props;

  return (
    <div className={ `form-group ${formClassName ? formClassName : ''} ${(touched && error) ? 'has-error' : ''}` }>
      { id ? <label htmlFor={ id } className={ labelClassName }>{ labelResourceName ? getLocalizedString(labelResourceName) : label }</label> : null }
      { tooltip && tooltip }
      <input { ...input }
        id={ id }
        disabled={ disabled }
        min={ min }
        type={ type }
        className={ className }
        placeholder={ placeholderResourceName ? getLocalizedString(placeholderResourceName) : null }
        autoComplete={ autoComplete ? autoComplete : 'on' }
        autoFocus={ autoFocus }
        data-e2e={ datae2e }/>
      {
        touched && error &&
        <div className="help-block">
          <div data-e2e={ input.name + 'Error' }>{ error }</div>
        </div>
      }
    </div>
  );
};

FormField.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholderResourceName: PropTypes.string,
  datae2e: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelResourceName: PropTypes.string,
  tooltip: PropTypes.object,
  formClassName: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }).isRequired
};

export default FormField;
