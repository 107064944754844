import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import BottomPanel from 'client/components/elements/bottomPanel/BottomPanel';
import SvgLoader from 'client/components/svg/SvgLoader';
import { STORE_BADGE_URL } from 'shared/endpoints/feefoReviewEndpoint';
import { getLocalizedString } from 'localization/localizer';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import { CART_ROUTE, QUOTATION_ROUTE,
  SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { getTokenDetails } from 'shared/utils/jwtUtils';
import { ASSETS_S3_LOCATION } from 'shared/constants/images';

if (process.browser) {
  require('./Footer.scss');
}

const socialMediaLinks = [
  {
    'name': 'Facebook',
    'icon': 'facebook',
    'url': 'https://www.facebook.com/zorotoolsuk/'
  },
  {
    'name': 'Twitter',
    'icon': 'twitter-square',
    'url': 'https://twitter.com/zoro_uk'
  },
  {
    'name': 'LinkedIn',
    'icon': 'linkedin',
    'url': 'https://www.linkedin.com/company/zoro-uk/'
  }
];
const FooterLinksGroup = ({ header, links }) => (
  <div className="FooterLinksGroup">
    <div className="FooterLinksGroup__header"><strong>{ header }</strong></div>
    { links.map((link) => {
      const ExternalLink = (link.url.substr(0, 4) === 'http');
      if (link.isMicroFrontendUrl) {
        return (
          <div className="FooterLinksGroup__link" key={ link.url } data-e2e="FooterLinksGroup__link">
            <a href={ link.url }>{ link.label }</a>
          </div>
        );
      }
      const LinkTagChecker = ExternalLink ? <a rel="noopener noreferrer" target="_blank" href={ link.url }>{ link.label }</a> : <Link to={ link.url }>{ link.label }</Link>;
      return (
        <div className="FooterLinksGroup__link" key={ link.url } data-e2e="FooterLinksGroup__link">
          { LinkTagChecker }
        </div>
      );
    }) }
  </div>
);
const FooterLinksPanel = () => {
  const links = {
    Company: [
      { url: '/about-us', label: 'About Us', isMicroFrontendUrl: true },
      { url: '/careers', label: 'Careers at Zoro', isMicroFrontendUrl: true },
      { url: '/info/terms-of-sale', label: 'Terms of Sale', isMicroFrontendUrl: true },
      { url: '/info/terms-of-access', label: 'Terms of Access', isMicroFrontendUrl: true },
      { url: '/info/quality-policy', label: 'Quality Policy', isMicroFrontendUrl: true },
      { url: '/info/health-safety-policy', label: 'Health & Safety Policy', isMicroFrontendUrl: true },
      { url: '/info/environmental-policy', label: 'Environmental Policy', isMicroFrontendUrl: true },
      { url: '/info/privacy-policy', label: 'Privacy Policy', isMicroFrontendUrl: true },
      { url: `${ASSETS_S3_LOCATION}/pdfs/Grainger-UK-Tax-Strategy.pdf`, label: 'Tax Strategy' },
      { url: '/info/modern-slavery-act', label: 'Modern Slavery Act 2015', isMicroFrontendUrl: true },
      { url: '/info/security-policy', label: 'ISO27001 Information Security Policy', isMicroFrontendUrl: true }
    ],
    Customer: [
      { url: '/help-support', label: 'Help & Support', isMicroFrontendUrl: true },
      { url: '/info/delivery', label: 'Delivery', isMicroFrontendUrl: true },
      { url: '/info/returns', label: 'Returns Policy', isMicroFrontendUrl: true },
      { url: '/info/zoro-business-account', label: 'Business Accounts', isMicroFrontendUrl: true },
      { url: '/info/affiliate-program', label: 'Affiliate Program', isMicroFrontendUrl: true },
      { url: '/info/helplines', label: 'Product Helplines', isMicroFrontendUrl: true }
    ],
    Other: [
      { url: '/all-categories', label: 'Categories Sitemap', isMicroFrontendUrl: true },
      { url: '/become-a-supplier', label: 'Become a Supplier', isMicroFrontendUrl: true },
      { url: 'https://privacyportal.onetrust.com/webform/6a6816ee-1bb2-4697-934f-17c3f04d4461/a9f25591-d46e-4f91-bc60-a9f6d49b9dbb',
        label: 'Privacy requests under GDPR'
      },
      { url: '/info/digital-services', label: 'Digital Services', isMicroFrontendUrl: true },
      { url: '/info/global-sourcing', label: 'Global Sourcing', isMicroFrontendUrl: true },
      { url: 'https://www.zoro.com/', label: 'Zoro USA' }
    ]
  };
  return (
    <Row className="FooterLinksPanel" data-e2e="FooterLinksPanel">
      <Col md={ 6 } xs={ 12 }>
        <Row className="FooterLinksPanel_FeefoBadge"> <a href="/reviews" ><ZoroImage src={ STORE_BADGE_URL } /></a></Row>
        <Row className="FooterLinksPanel_SocialMedia">
          {socialMediaLinks.map(link => {
            return <a
              key={ link.name }
              target="blank"
              href={ link.url }
              className="FooterLinksPanel_SocialMedia_Link">
              <i className={ `fab fa-${link.icon} fa-2x` }></i>
            </a>;
          })}

        </Row>
        <Row className="FooterLinksPanel_Copyright">© { new Date().getFullYear() } Zoro UK</Row>
      </Col>
      <Col md={ 2 } xs={ 12 }><FooterLinksGroup header="Company info" className="FooterLinksPanel__bottom" links={ links.Company }/></Col>
      <Col md={ 2 } xs={ 12 }>
        <FooterLinksGroup header="Customer info" links={ links.Customer }/>
        <Row className="FooterLinksPanel_Cookies">
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            Manage Cookies
          </button>
        </Row>
      </Col>
      <Col md={ 2 } xs={ 12 }><FooterLinksGroup header="Other info" className="FooterLinksPanel__bottom" links={ links.Other }/></Col>
    </Row>
  );
};

class Footer extends PureComponent {
  render () {
    const {
      isMobile,
      cartLoaded,
      screenName,
      cartQty,
      loyaltyTier
    } = this.props;
    const isCheckout = process.browser && typeof window !== 'undefined' && window?.location.pathname === SINGLE_PAGE_CHECKOUT_ROUTE;
    const isQuotationPage = process.browser && typeof window !== 'undefined' && window?.location.pathname === QUOTATION_ROUTE;
    const isCartPage = process.browser && typeof window !== 'undefined' && window?.location.pathname === CART_ROUTE;
    const showStickyCheckout = (!isCheckout && !isQuotationPage && !isCartPage && isMobile && cartLoaded);

    return (
      <div className={ `Footer ${screenName || 'NonSearchScreen'}` }>
        <div className="FooterPanels__background">
          <BottomPanel
            isMobile={ isMobile }
            loyaltyTier={ loyaltyTier }/>
        </div>
        <Grid>
          <Row>
            <Col md={ 12 }>
              <FooterLinksPanel />
            </Col>
          </Row>
        </Grid>
        { showStickyCheckout &&
          <span className="CallButton">
            <a
              id="ProductAddToCart_Modal.goToCartBtn"
              href="/cart">
              { getLocalizedString('cart.buttons.goToCheckout') }
              <SvgLoader xlinkHref="#cart-icon" className="CartIcon"/>
              <span className="ml-2.5">({cartQty})</span>
            </a>
          </span>
        }
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const jwtToken = state.getIn(['auth', 'longSessionJwtToken']) || state.getIn(['auth', 'jwtToken']);
  const jwtTokenDetails = jwtToken ? getTokenDetails(jwtToken) : null;
  const loyaltyTier = jwtTokenDetails ? jwtTokenDetails.loyaltyTier : null;

  return {
    isMobile: state.getIn(['deviceInfo', 'isMobile']) && !state.getIn(['deviceInfo', 'isTablet']),
    cartLoaded: state.getIn(['cart', 'quantity']) > 0,
    cartQty: state.getIn(['cart', 'quantity']),
    loyaltyTier
  };
};

export { Footer };
export default connect(mapStatetoProps)(Footer);
