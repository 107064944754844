import React from 'react';
import { getLocalizedString } from 'localization/localizer';

export const Price = ({ linePriceCurrency, unitPriceCurrency, priceChange, isIncludingVat }) => {
  return <div className="ProductLine_UnitPrice">
    <>
      {`${linePriceCurrency.symbol}${linePriceCurrency.amount}`}
      <span className="ProductLine_incExcVat">{getLocalizedString(isIncludingVat ? 'incVat' : 'excVat')}</span>
    </>
    <span className="ProductLine_UnitPrice-priceForEach">
      <div>
        { `${unitPriceCurrency.symbol}${unitPriceCurrency.amount} ${getLocalizedString('cart.each')}` }
      </div>
    </span>
    {priceChange ? <div className="ProductLine_UnitPrice-priceChange">{getLocalizedString('cart.cartOrderLine.priceChange')}</div> : null}
  </div>;
};
