
import { fromJS } from 'immutable';
import {
  REQUEST_SAVED_CARDS,
  REQUEST_SAVED_CARDS_SUCCESS,
  REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE_SUCCESS,
  DELETE_PAYMENT_METHOD_CONFIRM_SUCCESS,
  CLEAR_SAVED_CARDS
} from 'client/actions/mySavedCardsScreenActions';

const initState = {
  isLoading: true,
  savedCards: [],
  hasRequestedSavedCards: false,
  numberOfTableRows: 10,
  pageNumber: 1,
  totalNumberOfAllSavedCards: 0
};

const defaultState = fromJS(initState);

const mySavedCardsScreen = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_SAVED_CARDS:
      return state.set('isLoading', true)
        .set('hasRequestedSavedCards', true);
    case REQUEST_SAVED_CARDS_SUCCESS:
    case REQUEST_DEFAULT_PAYMENT_METHOD_CHANGE_SUCCESS:
    case DELETE_PAYMENT_METHOD_CONFIRM_SUCCESS:
      const { savedCards, pageNumber, totalNumberOfAllSavedCards } = action.payload;
      return state.set('isLoading', false)
        .set('savedCards', fromJS(savedCards))
        .set('pageNumber', pageNumber)
        .set('totalNumberOfAllSavedCards', totalNumberOfAllSavedCards);
    case CLEAR_SAVED_CARDS:
      return defaultState;
    default:
      return state;
  }
};

export default mySavedCardsScreen;
