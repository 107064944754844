import { browserHistory } from 'react-router';
import { createAction } from 'redux-actions';
import { buildErrorMessageFromError } from 'client/utils/errorUtils';

export const REQUEST_LOGIN_USER = 'user.requestLoginUser';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RECIEVE_LOGIN_USER_SUCCESS = 'user.recieveLoginUserSuccess';
export const RECIEVE_LOGIN_USER_ERROR = 'user.recieveLoginUserError';
export const SET_FINAL_DESTINATION = 'user.setFinalDestination';
export const REACHED_INTERSTITIAL_PAGE = 'user.reachedInterstitialPage';
export const REMOVE_FINAL_DESTINATION = 'user.removeFinalDestination';
export const UPDATE_INVITED_SUB_USER_DETAILS = 'user.updateInvitedSubUsersDetails';
export const GET_LOGIN_IMAGES = 'getLoginImages';
export const REMOVE_LOGIN_ERROR = 'removeLoginError';
export const RECEIVE_INTERSTITIAL_PAGE_UNLOAD_REQUEST = 'RECEIVE_INTERSTITIAL_PAGE_UNLOAD_REQUEST';
export const UNLOAD_REACHED_INTERSTITIAL_PAGE = 'UNLOAD_REACHED_INTERSTITIAL_PAGE';
export const UPDATE_IS_LOGIN_STATE = 'UPDATE_IS_LOGIN_STATE';
export const SET_REDIRECT_LOADING_STATE = 'SET_REDIRECT_LOADING_STATE';
import { DEFAULT_MODAL } from 'shared/constants/loginModalType';

export const intertitialPageUnloadRequest = () => ({
  type: RECEIVE_INTERSTITIAL_PAGE_UNLOAD_REQUEST
});

export const unloadReachedInterstitalPage = () => ({
  type: UNLOAD_REACHED_INTERSTITIAL_PAGE
});

export function loginUserSuccess (tokens, finalDestination, actionToDispatch) {
  return {
    type: RECIEVE_LOGIN_USER_SUCCESS,
    tokens,
    finalDestination,
    actionToDispatch
  };
}

export function setFinalDestination (finalDestination, actionToDispatch, isRedirectLoading) {
  return {
    type: SET_FINAL_DESTINATION,
    finalDestination,
    actionToDispatch,
    isRedirectLoading
  };
}

export function setRedirectLoadingState (isRedirectLoading) {
  return {
    type: SET_REDIRECT_LOADING_STATE,
    isRedirectLoading
  };
}
export function reachedInterstitialPage (isRedirectLoading) {
  return {
    type: REACHED_INTERSTITIAL_PAGE,
    isRedirectLoading
  };
}

export function getLoginImages () {
  return {
    type: GET_LOGIN_IMAGES
  };
}

export function removeLoginError () {
  return {
    type: REMOVE_LOGIN_ERROR
  };
}

export function removeFinalDestination () {
  return {
    type: REMOVE_FINAL_DESTINATION
  };
}

export function updateIsLoginState () {
  return {
    type: UPDATE_IS_LOGIN_STATE
  };
}
// csUserAgent functionality depends on this function
// to refresh the page and go to /
export function logoutUser () {
  return {
    type: LOGOUT_USER
  };
}

export const LOGIN_USER = 'LOGIN_USER';
export function loginUser (email, password) {
  return {
    type: LOGIN_USER,
    email,
    password,
    currentPath: window?.location?.pathname || '/'
  };
}

export const DOES_USER_EXIST = 'DOES_USER_EXIST';
export function doesUserExist (email) {
  return {
    type: DOES_USER_EXIST,
    email
  };
}

export const SET_USER_EXISTENCE = 'SET_USER_EXISTENCE';
export function setUserExistence (email, exists) {
  return {
    type: SET_USER_EXISTENCE,
    email,
    exists
  };
}
export function loginUserError (error) {
  return {
    type: RECIEVE_LOGIN_USER_ERROR,
    error
  };
}

// --------------------------------------------------
//

export const REQUEST_REGISTER_USER = 'user.register';
export const REQUEST_REGISTER_USER_AND_REDIRECT = 'user.register.and.redirect';
export const REQUEST_REGISTER_USER_AND_ACCOUNT = 'user.register.and.account';
export const RECIEVE_REGISTER_USER_SUCCESS = 'user.register.success';
export const RECIEVE_REGISTER_USER_ERROR = 'user.register.error';
export const RESET_REGISTER_STATE = 'user.register.reset';

export const resetRegisterState = () => ({
  type: RESET_REGISTER_STATE
});

export function userRegistrationError (error) {
  return {
    type: RECIEVE_REGISTER_USER_ERROR,
    error
  };
}

export function registerUserAndAccountAndGoToCheckout ({
  title,
  password,
  email,
  firstName,
  lastName,
  phoneNumber,
  companyName,
  jobTitle,
  companyRegNo,
  marketingConsent,
  captchaResponse,
  csAgentName,
  userType
}) {
  return {
    type: REQUEST_REGISTER_USER_AND_ACCOUNT,
    body: {
      title,
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      jobTitle,
      companyRegNo,
      captchaResponse,
      csAgentName,
      marketingConsent: !!marketingConsent,
      ...(userType ? { userType } : {}),
      currentPath: window?.location?.pathname || '/'
    }
  };
}

export function registerUserAndGoToCheckout ({ title, password, email, firstName, lastName, phoneNumber, companyName, marketingConsent, captchaResponse, csAgentName, userType }) {
  return {
    type: REQUEST_REGISTER_USER_AND_REDIRECT,
    body: {
      title,
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      captchaResponse,
      csAgentName,
      marketingConsent: !!marketingConsent,
      ...(userType ? { userType } : {}),
      currentPath: window?.location?.pathname || '/'
    }
  };
}

export function registerUser (title, password, email, firstName, lastName, phoneNumber, companyName, marketingConsent, captchaResponse, csAgentName) {
  return {
    type: REQUEST_REGISTER_USER,
    body: {
      title,
      password,
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      captchaResponse,
      csAgentName,
      marketingConsent: !!marketingConsent
    }
  };
}

export const ON_FORGOT_PASSWORD = 'user.onForgotPassword';
export const ON_SET_EMAIL_FOR_FORGOT_PASSWORD = 'user.onSetEmailForForgotPassword';

export const ON_FORGOT_PASSWORD_ERROR = 'user.onForgotPasswordError';
export const ON_FORGOT_PASSWORD_SUCCESS = 'user.onForgotPasswordSuccess';
export const FORGOT_PASSWORD_DEFAULT_STATE = 'user.forgotPasswordDefaultState';

export function onForgotPassword (email, loginType = DEFAULT_MODAL) {
  return {
    type: ON_FORGOT_PASSWORD,
    loginType,
    email
  };
}
export function onSetEmailForForgotPassword (email) {
  return {
    type: ON_SET_EMAIL_FOR_FORGOT_PASSWORD,
    email
  };
}
export function onForgotPasswordError (error) {
  return {
    type: ON_FORGOT_PASSWORD_ERROR,
    error: buildErrorMessageFromError(error)
  };
}

export function onForgotPasswordSuccess () {
  return {
    type: ON_FORGOT_PASSWORD_SUCCESS
  };
}

export const resetStateToDefaults = () => {
  return {
    type: FORGOT_PASSWORD_DEFAULT_STATE
  };
};

export const ON_RESET_PASSWORD = 'user.onResetPassword';
export const ON_RESET_PASSWORD_ERROR = 'user.onResetPasswordError';
export const ON_RESET_PASSWORD_SUCCESS = 'user.onResetPasswordSuccess';

export function onResetPassword (resetPasswordToken, newPassword) {
  return {
    type: ON_RESET_PASSWORD,
    resetPasswordToken,
    newPassword
  };
}

export function onResetPasswordError (error) {
  return {
    type: ON_RESET_PASSWORD_ERROR,
    error: buildErrorMessageFromError(error)
  };
}

export function onResetPasswordSuccess () {
  return {
    type: ON_RESET_PASSWORD_SUCCESS
  };
}

export function gotoMyAccount () {
  browserHistory.push('/my-account/account-details');
}

/**
 * Ask Question or Report error actions
 */

export const REQUEST_USER_QUESTION = 'REQUEST_USER_QUESTION';
export const RESPONSE_USER_QUESTION_SUCCESS = 'RESPONSE_USER_QUESTION_SUCCESS';
export const RESPONSE_USER_QUESTION_ERROR = 'RESPONSE_USER_QUESTION_ERROR';
export const RESET_USER_QUESTION = 'RESET_USER_QUESTION';
export const TOGGLE_USER_QUESTION = 'TOGGLE_REPORT_USER_QUESTIO0N';

export const toggleUserQuestion = createAction(TOGGLE_USER_QUESTION);
export const resetUserQuestionState = createAction(RESET_USER_QUESTION);
export const responseUserQuestionSuccess = createAction(RESPONSE_USER_QUESTION_SUCCESS);

export function responseUserQuestionError (error) {
  return {
    type: RESPONSE_USER_QUESTION_ERROR,
    error
  };
}

// Contact-Us actions started

export const REQUEST_USER_ENQUIRIES = 'user.enquiries';
export const RECIEVE_ENQUIRIES_USER_SUCCESS = 'user.enquiries.success';
export const RECIEVE_ENQUIRIES_USER_ERROR = 'user.enquiries.error';
export const RESET_ENQUIRIES_STATE = 'user.enquiries.reset';

export const resetContactUsState = () => ({
  type: RESET_ENQUIRIES_STATE
});

export function userContactUsError (error) {
  return {
    type: RECIEVE_ENQUIRIES_USER_ERROR,
    error
  };
}

export function requestUserQuestion (payload) {
  return {
    type: REQUEST_USER_QUESTION,
    body: { ...payload }
  };
}

export function enquiriesUserAndGoToCheckout (payload) {
  return {
    type: REQUEST_USER_ENQUIRIES,
    body: { ...payload }
  };
}

export const recieveEnquirySuccess = () => ({
  type: RECIEVE_ENQUIRIES_USER_SUCCESS
});

export const COMING_FROM_CART_PAGE = 'COMING_FROM_CART_PAGE';
export const isUserComingFromCartPage = (flag) => ({
  type: COMING_FROM_CART_PAGE,
  flag
});

export const COMING_FROM_GLOBAL_QUOTE = 'COMING_FROM_GLOBAL_QUOTE';
export const isUserComingFromGlobalQuote = (flag) => ({
  type: COMING_FROM_GLOBAL_QUOTE,
  flag
});

export const ON_TOGGLE_GLOBAL_VAT = 'ON_TOGGLE_GLOBAL_VAT';
export const onToggleGlobalVat = (isOn) => ({
  type: ON_TOGGLE_GLOBAL_VAT,
  isPriceWithVat: isOn
});

export const CHANGE_GLOBAL_VAT_STATE = 'CHANGE_GLOBAL_VAT_STATE';
export const changeGlobalVATStateAction = (isOn) => ({
  type: CHANGE_GLOBAL_VAT_STATE,
  isPriceWithVat: isOn
});

export const SET_IS_VAT_PREFERENCE_FROM_UNLEASH = 'SET_IS_VAT_PREFERENCE_FROM_UNLEASH';

export const setIsVatPreferenceFromUnleash = (isOn) => ({
  type: SET_IS_VAT_PREFERENCE_FROM_UNLEASH,
  payload: isOn
});

export const IS_USER_UPDATED_BILLING_DETAILS = 'IS_USER_UPDATED_BILLING_DETAILS';

export const isUserUpdateBillingDetails = createAction(IS_USER_UPDATED_BILLING_DETAILS);

export const ON_USER_ACCOUNT_DETAILS_RECEIVED = 'ON_USER_ACCOUNT_DETAILS_RECEIVED';
export const onUserAccountDetailsReceived = createAction(ON_USER_ACCOUNT_DETAILS_RECEIVED);
