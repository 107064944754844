import React from 'react';
import { getLocalizedStringWithParam, getLocalizedString } from 'localization/localizer';

const eachPrice = (priceWithMessage) => {
  return (
    <div className="ProductVariantPrice_orderMultiplePrice">
      {
        priceWithMessage
      }
    </div>
  );
};

const EachMinOrderPrice = ({
  isQtyMultiplier,
  isMinOrderQtyGreaterThanOne,
  minPrice, minPriceFormatted,
  priceWithCurrency,
  priceWithMinOrderQty,
  isMinOrderQtyGreaterThanMultiplier
}) => {
  const familyEachPrice = `${minPriceFormatted.symbol}${minPriceFormatted.amount} ${getLocalizedString('cart.each')}`;
  const unitPriceWithEach = `${priceWithCurrency.symbol}${priceWithCurrency.amount} ${getLocalizedString('cart.each')}`;
  const priceWitMinOrderValue = getLocalizedStringWithParam('product.price.minimum.order.value',
    { priceWithMinOrderQty: `${priceWithMinOrderQty.symbol}${priceWithMinOrderQty.amount}` });
  const qtyMultiplierAndMinOrderEachPrice = `${unitPriceWithEach}, ${priceWitMinOrderValue}`;
  let unitPriceWithMessage;
  if (isQtyMultiplier && isMinOrderQtyGreaterThanOne && isMinOrderQtyGreaterThanMultiplier) {
    unitPriceWithMessage = qtyMultiplierAndMinOrderEachPrice;
  } else if (isMinOrderQtyGreaterThanOne && isMinOrderQtyGreaterThanMultiplier) {
    unitPriceWithMessage = priceWitMinOrderValue;
  } else if (isQtyMultiplier) {
    unitPriceWithMessage = unitPriceWithEach;
  }
  const priceWithMessage = minPrice ? familyEachPrice : unitPriceWithMessage;
  return unitPriceWithMessage ? eachPrice(priceWithMessage) : null;
};

export default EachMinOrderPrice;
