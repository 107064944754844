import React from 'react';
import { getCardImageUrl } from '../../../../utils/braintreeUtils';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
if (process.browser) {
  require('./payByExistingCard.scss');
}

export const CardItem = ({ card }) => {
  return <div className="row PayByExistingCard_card" data-e2e={ `${card.cardType}-${card.cardLastFourDigits}` }>
    <span className="PayByExistingCard_card_icon_container">
      <ZoroImage src={ getCardImageUrl[card.cardType] } className="PayByExistingCard_card_icon" alt={ card.cardType }/>
      <div className="PayByExistingCard_card_text">{card.cardType} - {card.cardLastFourDigits}</div>
    </span>
    <span>
      <div className="PayByExistingCard_card_text">{card.expirationMonth}/{card.expirationYear}</div>
    </span>
  </div>;
};

export const DropdownTitleCard = ({ card }) => {
  return card && Object.keys(card).length > 0
    ? <div className="row PayByExistingCard_card PayByExistingCard_title_container">
      <ZoroImage src={ getCardImageUrl[card.cardType] } className="PayByExistingCard_card_icon" alt="card"/>
      <div className="PayByExistingCard_card_text PayByExistingCard_card_text_caret">{card.cardType} - {card.cardLastFourDigits}</div>
    </div>
    : null;
};
