import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form/immutable';
import { getLocalizedString } from 'localization/localizer';
import moment from 'moment';
import { Map, fromJS } from 'immutable';
import { getFormSubmit } from './formValidators';
import {
  createNewSubscriptionContinueButton,
  createSubscriptionButton,
  createNewSubscriptionForm
} from './subscriptionFormElements';
import SelectWithValidationsNewStyles from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import {
  CREATE_NEW_SUBSCRIPTION_STATUS_PROCESSING, SUBSCRIPTION_SELECTOR
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import PaymentMethod from '../paymentMethod/PaymentMethod/paymentMethod';
import { DELIVERY_ADDRESS_FORM_NAME } from 'shared/constants/singlePageCheckout';
import AddressForm from 'client/components/screens/checkoutScreen/element/addressForm/AddressForm';
import { PrimaryButton } from 'client/components/controls/StyledForms';
import { getDeliveryDateNewSubscription } from 'shared/utils/subscriptionUtils';

if (process.browser) {
  require('./AddToMySubscriptionForm.scss');
}

class CreateNewSubscriptionForm extends Component {
  onEnterDeliveryAddress = (address, createNewSubscriptionNextStep) => {
    this.props.saveNewDeliveryAddress(address, createNewSubscriptionNextStep);
  };

  onPaymentMethodChange = (eventKey) => {
    this.props.changePaymentMethodSelected(eventKey);
  }

  render () {
    const {
      sku,
      name,
      handleSubmit,
      addressList,
      startDate,
      endDate,
      newRepeatOrderDetails,
      confirmSubscriptionButtonStatus,
      displayAddressForm,
      setNewAddressAsSelected,
      onQuantityChange,
      quantity = 1,
      onDeliveryAddressChange,
      qtyMultiplier = 1,
      showNewAddressForm,
      accountDetails,
      createNewSubscriptionNextStep,
      currentDisplay,
      price,
      createSubscription,
      newSubscriptionFinalStep,
      onAddressFormBackButtonClick,
      processSubscriptionState,
      minOrderQuantity = 1,
      maxOrderQuantity,
      startDateMinDate
    } = this.props;

    const isQuantityValid = isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    const deliveryAddressSubmit = getFormSubmit(createNewSubscriptionNextStep, newRepeatOrderDetails, sku, price, quantity, name, 'delivery');
    const newSubscriptionFormSubmit = getFormSubmit(createNewSubscriptionNextStep, newRepeatOrderDetails, sku, price, quantity, name, 'newSubscription');

    const newSubscriptionDeliveryAddressForm = () => {
      if (showNewAddressForm || !addressList.length) {
        return <AddressForm
          form={ DELIVERY_ADDRESS_FORM_NAME }
          address={ accountDetails.toJS() }
          formName={ DELIVERY_ADDRESS_FORM_NAME }
          showCancelButtonOnAddressForm={ true }
          handleBackButtonClick={ onAddressFormBackButtonClick }
          onAddressSubmit={ (address) => this.onEnterDeliveryAddress(address, createNewSubscriptionNextStep) }
        />;
      }
      return (<div className="CreateNewOrderForm_deliveryAddress">
        { addressList && addressList.length ? <Field
          name="deliveryAddressId"
          id="deliveryAddressId"
          disabled={ confirmSubscriptionButtonStatus === CREATE_NEW_SUBSCRIPTION_STATUS_PROCESSING }
          className="CreateNewOrderForm_formGroup_select form-control"
          formClassName="col-xs-12 col-md-6"
          labelClassName="CreateNewOrderForm_formGroup_label CreateNewOrderForm_formGroup_label-required"
          labelResourceName="createNewOrderForm.label.deliveryAddressId"
          component={ SelectWithValidationsNewStyles }
          datae2e="deliveryAddressId"
          value=''
          onChange={ (event, value) => onDeliveryAddressChange(value) }
        >
          <Fragment>
            { !setNewAddressAsSelected ? <option key={ 0 } value="" disabled>
              { getLocalizedString('createNewSubscriptionForm.selectDeliveryAddress') }
            </option> : null }
            {
              addressList.map(
                (address, index) => {
                  return <option
                    key={ address.addressId }
                    value={ address.addressId }>
                    { address.addressNickName }
                  </option>;
                }
              )
            }
          </Fragment>
        </Field> : null }
        <div className="AddToSubscriptionButton_label col-xs-12" onClick={ () => displayAddressForm(true) }>
          { getLocalizedString('createNewOrderForm.label.addNewDeliveryAddress') }
        </div>
        <div className="col-xs-12 col-md-12 CreateNewOrderForm_stepsBtn">
          { createNewSubscriptionContinueButton(true, handleSubmit, deliveryAddressSubmit, createNewSubscriptionNextStep) }
        </div>
      </div>);
    };

    const datePickersProps = {
      startDate,
      endDate,
      startDateMinDate,
      startDateMaxDate: endDate,
      endDateMinDate: startDate,
      disabled: confirmSubscriptionButtonStatus === CREATE_NEW_SUBSCRIPTION_STATUS_PROCESSING
    };
    const newSubscriptionForm = (<>
      <div className="CreateNewOrderForm_subTitle">{ getLocalizedString('createNewOrderForm.titleHeader.text') }</div>
      <div className="CreateNewOrderForm_smallText">
        { getLocalizedString('createNewOrderForm.titleHeader.secondaryText') }
        <a href="/info/scheduled-orders" className="CreateNewOrderForm_clickHereLink">{ getLocalizedString('createNewOrderForm.titleHeader.clickHere') }</a>
      </div>
      <br />
      <div className="CreateNewOrderForm col-xs-12 col-md-10">
        { createNewSubscriptionForm(
          confirmSubscriptionButtonStatus,
          onQuantityChange,
          quantity,
          sku,
          qtyMultiplier,
          isQuantityValid,
          datePickersProps,
          minOrderQuantity
        ) }
        <div className="CreateNewOrderForm_nextStepBtn row" data-e2e="ContinueContainer">
          <PrimaryButton
            text="Continue"
            className="CreateNewOrderForm_nextStepBtn"
            onClick={ () => {
              createNewSubscriptionNextStep(true, handleSubmit, newSubscriptionFormSubmit);
            } } />
        </div>
      </div>
    </>);

    const newSubscriptionPaymentView = (<>
      <PaymentMethod selector={ SUBSCRIPTION_SELECTOR } location={{}} />
      <div className="col-md-12 CreateNewOrderForm_stepsBtn">
        { createSubscriptionButton(createSubscription, newSubscriptionFinalStep, processSubscriptionState, sku) }
      </div>
    </>);

    const currentPageDisplays = {
      1: newSubscriptionForm,
      2: newSubscriptionDeliveryAddressForm(),
      3: newSubscriptionPaymentView
    };

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 CreateNewOrderForm_form">
            { currentPageDisplays[currentDisplay] || null }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const todayDate = moment().format('DD-MM-YYYY');
  const subscriptionName = `Scheduled Order-${todayDate}`;
  const tradeAccount = state.getIn(['user', 'accountDetails', 'tradeAccount']);
  const isTradeAccount = tradeAccount === null ? {} : tradeAccount.toJS();
  const publicHolidays = state.get('publicHolidays', fromJS([])).toJS();

  return {
    startDate: state.getIn(['form', 'createNewSubscriptionForm', 'values', 'startDate']),
    endDate: state.getIn(['form', 'createNewSubscriptionForm', 'values', 'endDate']),
    selectedPaymentMethod: state.getIn(['repeatOrders', 'selectedPaymentMethod'], null),
    tradeAccountBillingAddress: state.getIn(['user', 'accountDetails', 'tradeAccount', 'invoiceContact'], Map()).toJS(),
    processSubscriptionState: state.getIn(['repeatOrders', 'confirmSubscriptionButtonStatus']),
    isTradeAccount,
    initialValues: {
      orderReference: state.getIn(['repeatOrders', 'newRepeatOrder', 'orderReference'], subscriptionName),
      subscriptionProductQuantity: state.getIn(['repeatOrders', 'newRepeatOrder', 'subscriptionProductQuantity']),
      regularity: state.getIn(['repeatOrders', 'newRepeatOrder', 'regularity']),
      outOfStockDeliveryPreference: state.getIn(['repeatOrders', 'newRepeatOrder', 'outOfStockDeliveryPreference']),
      startDate: state.getIn(['repeatOrders', 'newRepeatOrder', 'startDate']),
      endDate: state.getIn(['repeatOrders', 'newRepeatOrder', 'endDate']),
      deliveryAddressId: state.getIn(['repeatOrders', 'newRepeatOrder', 'deliveryAddressId'])
    },
    isMobile: state.getIn(['deviceInfo', 'isMobile']),
    startDateMinDate: getDeliveryDateNewSubscription(publicHolidays)
  };
};

let reduxCreateNewSubscriptionForm = reduxForm({
  form: 'createNewSubscriptionForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(CreateNewSubscriptionForm);

reduxCreateNewSubscriptionForm = connect(mapStateToProps)(reduxCreateNewSubscriptionForm);

export { CreateNewSubscriptionForm };
export default reduxCreateNewSubscriptionForm;
