import { ApiRequest } from 'shared/utils/apiUtils';

export function fetchAccountDetails () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/users/me'
  });
  return req.executeAndGetBody();
}

export function updateMarketingPreferences ({ marketingPreferences, source, csAgentName, customerId, unsubscribeToken, fromEmail }) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/users/me/marketingPreferences',
    body: { marketingPreferences, source, csAgentName, customerId, unsubscribeToken, fromEmail }
  });
  return req.executeAndGetBody();
}

export const fetchAccountStatements = () => {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/account/statements`
  });
  return req.executeAndGetBody();
};

export const hasTradeAccount = (email) => {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/account/hasTradeAccount?email=${email}`
  });
  return req.executeAndGetBody();
};
