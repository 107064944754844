import { createAction } from 'redux-actions';

export const ADD_PRODUCT_LINE_ITEMS = 'ADD_PRODUCT_LINE_ITEMS';
export const addProductLineItems = createAction(ADD_PRODUCT_LINE_ITEMS);

export const CLEAR_PRODUCT_LINES = 'CLEAR_PRODUCT_LINES';
export const clearProductLines = createAction(CLEAR_PRODUCT_LINES);

export const PRODUCT_LINE_UPDATING_QUANTITY = 'PRODUCT_LINE_UPDATING_QUANTITY';
export const updatingQuantity = (sku, flag) => {
  return {
    type: PRODUCT_LINE_UPDATING_QUANTITY,
    sku,
    flag
  };
};

export const PRODUCT_LINE_UPDATE_QUANTITY = 'PRODUCT_LINE_UPDATE_QUANTITY';
export const updateQuantity = (sku, qty) => {
  return {
    type: PRODUCT_LINE_UPDATE_QUANTITY,
    sku,
    qty
  };
};
