/**
 * Try Catch Utility functions
 * Source: Dr. Boolean
 * https://egghead.io/lessons/javascript-composable-error-handling-with-either
 */
const Right = (x) => ({
  chain: (f) => f(x),
  map: (f) => Right(f(x)),
  fold: (f, g) => g(x),
  inspect: () => `Right(${x})`
});

const Left = (x) => ({
  chain: (f) => Left(x),
  map: (f) => Left(x),
  fold: (f, g) => f(x),
  inspect: () => `Left(${x})`
});

export const tryCatch = (f) => {
  try {
    return Right(f());
  } catch (error) {
    return Left(error);
  }
};

export const tryCatchContainer = (fn) => (value) => tryCatch(() => fn(value));
