import { takeLatest } from 'redux-saga/effects';
import { COPY_TEXT_TO_CLIPBOARD } from '../actions/copyTextToClipboardAction';
const copyTextToClipboard = (copyText) => {
  try {
    navigator.clipboard.writeText(copyText.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error Copying text to clipboard', error);
  }
};

export function * watchCopyTextToClipboard () {
  yield takeLatest(COPY_TEXT_TO_CLIPBOARD, copyTextToClipboard);
}
