//
// THESE FILE IS FOR THE OLD FAMILY PAGE. DO NOT USE IT
//
// TODO: REMOVE THIS FILE AFTER NEW PRODUCT PAGE GOES LIVE (see isNewFamilyPageEnabled)

import { takeLatest, put, call } from 'redux-saga/effects';
import { logAction } from 'client/actions/logActions';

import {
  REQUEST_RECOMMENDATIONS_STOCK_INFO,
  receiveRecommendationsAppliedDiscountInfo,
  receiveRecommendationsStockInfo
} from 'client/actions/recommendationsActions';
import { getStockInfo } from 'shared/endpoints/productVariantEndpoint';

export function * fetchProductStockInfo ({ payload, type }) {
  try {
    const productStockInfo = yield call(getStockInfo, payload.sku, payload.quantity);
    const panelIndex = payload.panelIndex || 0;
    yield put(receiveRecommendationsStockInfo({ panelIndex, stockInfo: productStockInfo, familyId: payload.familyId }));
    yield put(receiveRecommendationsAppliedDiscountInfo({ panelIndex, productStockInfo }));
  } catch (e) {
    yield put(logAction({ message: 'Failure within fetchProductStockInfo', e }));
  }
}

export function * watchRequestFamilyStockInfo () {
  yield takeLatest([
    REQUEST_RECOMMENDATIONS_STOCK_INFO
  ], fetchProductStockInfo);
}
