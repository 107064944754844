import React from 'react';
import { Link } from 'react-router';
import { ADMIN } from '../../shared/constants/account';

export const getAccountStatusMessage = (accountStatusMessage, hideBusinessAccountOnHoldDialog, accountRole, accountAdminEmail, tradeAccountStatus) => {
  const contactUsLink = <a onClick={ () => hideBusinessAccountOnHoldDialog() } href={ '/contact-us' } className=""> contact us </a>;
  const accountStatementsLink = <Link to={ '/my-account/account-statements' } onClick={ () => hideBusinessAccountOnHoldDialog() }> account statement </Link>;

  const accountHoldMessage = {
    heading: 'Account on hold',
    content: (
      <span>
            Your account is currently on hold due to outstanding invoices. Please refer to you { accountStatementsLink }
            for more information or {contactUsLink} for further support.
        {accountRole === ADMIN ? ' If you require the order to be immediately processed, please choose an alternative payment method.' : ''}
      </span>
    )
  };

  const orderOnHoldMessage = {
    heading: 'Order on hold',
    content: (
      <span>
            Your order will be placed on hold while we review your account balance and limit. This usually takes less than one working day.
            If you require the order to be immediately processed, please choose an alternative payment method.
      </span>
    )
  };

  const unverifiedMessage = {
    heading: 'Account unverified',
    content: (accountRole === ADMIN && !tradeAccountStatus)
      ? <span>
            Your account requires verification before we process your order. Please check your email for the verification link, or {contactUsLink}
            if you need further support. If you require the order to be processed immediately, please choose an alternative payment method.
      </span>
      : <span>
            To purchase on this account the account applicant needs to verify their email address. Please contact the account applicant at: <span className='CheckoutPage_textBold'>{accountAdminEmail}</span>.
        {accountRole === ADMIN ? ' If you require the order to be processed immediately, please choose an alternative payment method.' : ''}
      </span>,
    smallContent: (accountRole === ADMIN && !tradeAccountStatus) ? 'Your account requires verification before we process your order. Please check your email for the verification link' : 'To purchase on this account the account applicant needs to verify their email address. Please contact the account applicant'
  };

  const accountInvitationMessage = {
    heading: 'Account invitation pending',
    content: (
      <span>
            To purchase on account, you need to accept the invitation. Please check your email for the confirmation link, or
        {contactUsLink}
            if you need further support. If you require the order to be processed immediately, please choose an alternative payment method.
      </span>
    ),
    smallContent: 'To purchase on account, you need to accept the invitation. Please check your email for the confirmation link'
  };

  const userPausedMessage = {
    heading: 'Account user paused',
    content: (
      <span>
            Your access to this account has been paused by an account admin. Please contact the account admin at: <span className='CheckoutPage_textBold'>{accountAdminEmail}</span>
      </span>
    )
  };

  const orderValueOveruseMessage = {
    heading: 'Order over user limit',
    content: (
      <span>
        {'This order, combined with your spending this month, exceeds your account limit. You can request a limit increase'}
      </span>
    ),
    requestIncreaseLimit: !(accountRole === ADMIN)
  };

  const pendingOrUnderReviewMessage = {
    heading: 'Account under review',
    content: (
      <span>
          Your account is currently under review, which usually takes less than one working day.
          Your order will be processed once the review is complete.
        {accountRole === ADMIN ? ' If you require the order to be immediately processed, please choose an alternative payment method.' : ''}
      </span>
    ),
    smallContent: 'Your account is currently under review, which usually takes less than one working day.'
  };

  const accountDisabledMessage = {
    heading: 'Account disabled',
    content: (
      <span>
           Your credit facilities have been disabled. Please
        {contactUsLink}
        for further information. If you require the order to be immediately processed, please choose an alternative payment method.
      </span>
    ),
    smallContent: 'To purchase on account, you need to accept the invitation. Please check your email for the confirmation link'
  };

  const accountStatusMessages = { unverifiedMessage, accountHoldMessage, accountInvitationMessage, userPausedMessage, orderValueOveruseMessage, pendingOrUnderReviewMessage, orderOnHoldMessage, accountDisabledMessage };

  return accountStatusMessages[accountStatusMessage] || null;
};
