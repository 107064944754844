import combineReducers from 'shared/utils/reduxUtils';
import dialogReducer from './ui/dialogReducer';
import loginFormReducer from './ui/loginFormReducer';
import registrationFormReducer from './ui/registrationFormReducer';
import contactUsFormReducer from './ui/contactUsFormReducer';
import creditAccountApplicationFormReducer from './ui/creditAccountApplicationFormReducer';
import postCodeReducer from './ui/postCodeReducer';
import forgotPasswordFormReducer from './ui/forgotPasswordFormReducer';
import orderSummaryReducer from './ui/orderSummaryReducer';
import resetPasswordFormReducer from './ui/resetPasswordFormReducer';
import editAddressReducer from './ui/editAddressReducer';
import categoriesFilterPanelReducer from './ui/categoriesFilterPanelReducer';
import tooltipReducer from './ui/tooltipReducer';
import categoriesDropdownReducer from './ui/categoriesDropdownReducer';
import toggleElementReducer from './ui/toggleElementReducer';
import selectedIndexReducer from './ui/selectedIndexReducer';
import verifyEmailReducer from './ui/verifyEmailReducer';
import apiCallButtonReducer from './ui/apiCallButtonReducer';
import reportErrorOrQuestionFormReducer from './ui/ReportErrorOrQuestionFormReducer';
import addToBasketInterstitialReducer from './ui/addToBasketInterstitialReducer';
import telephoneFieldReducer from './ui/telephoneFieldReducer';
import payByExistingCardReducer from '../components/elements/paymentMethod/PayByExistingCard/payByExistingCardReducer';
import payByNewCardReducer from '../components/elements/paymentMethod/PayByNewCard/payByNewCardReducer';
import payByPaypalReducer from '../components/elements/paymentMethod/PayByPaypal/payByPaypalReducer';
import paymentMethodReducer from '../components/elements/paymentMethod/PaymentMethod/paymentMethodReducer';
import payByApplePayReducer from '../components/elements/paymentMethod/PayByApplePay/payByApplePayReducer';
import payByGooglePayReducer from '../components/elements/paymentMethod/PayByGooglePay/payByGooglePayReducer';
import requestCreditLimitReducer from '..//components/elements/paymentMethod/RequestCreditLimit/requestCreditLimitReducer';

export default combineReducers({
  dialogs: dialogReducer,
  loginForm: loginFormReducer,
  registrationForm: registrationFormReducer,
  contactUsForm: contactUsFormReducer,
  creditAccountApplicationForm: creditAccountApplicationFormReducer,
  postCode: postCodeReducer,
  forgotPasswordForm: forgotPasswordFormReducer,
  resetPasswordForm: resetPasswordFormReducer,
  orderSummary: orderSummaryReducer,
  categoriesFilterPanel: categoriesFilterPanelReducer,
  categoriesDropdown: categoriesDropdownReducer,
  tooltip: tooltipReducer,
  toggleElement: toggleElementReducer,
  productTabs: selectedIndexReducer,
  verifyEmailState: verifyEmailReducer,
  apiCallButton: apiCallButtonReducer,
  editAddress: editAddressReducer,
  payByExistingCard: payByExistingCardReducer,
  payByNewCard: payByNewCardReducer,
  paymentMethod: paymentMethodReducer,
  payByPaypal: payByPaypalReducer,
  payByGooglePay: payByGooglePayReducer,
  payByApplePay: payByApplePayReducer,
  requestCreditLimit: requestCreditLimitReducer,
  reportErrorOrQuestionForm: reportErrorOrQuestionFormReducer,
  addToBasket: addToBasketInterstitialReducer,
  telephoneField: telephoneFieldReducer
});
