let AppContainer = (props) => <div>{props.children}</div>;

if (process.env.NODE_ENV === 'development') {
  AppContainer = require('react-hot-loader').AppContainer;
}

import React from 'react';
import ReactDOM from 'react-dom';
import { getRoutes } from './routes';
import getStore from './store';
import { createClientApp } from './initialization/clientAppInitializationUtils';

const store = getStore();
const newRoutes = getRoutes(store);

createClientApp(newRoutes, store, (err, app) => {
  if (err) {
    // TODO Handle errors
  } else {
    const wrappedApp =
      process.env.NODE_ENV === 'development' ? (
        <AppContainer>{app}</AppContainer>
      ) : (
        app
      );
    ReactDOM.hydrate(wrappedApp, document.getElementById('app-dom-hook'));
  }
});
