import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemoveQuoteLineDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveQuoteLineConfirm } from '../../../actions/quotationsActions';
import { PrimaryButton, SecondaryButton } from '../../controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./RemoveQuoteLineDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeQuoteLineDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeQuoteLine', 'isVisible']),
    quoteLine: state.getIn(['ui', 'dialogs', 'removeQuoteLine', 'quoteLine']),
    delQuotation: state.getIn(['ui', 'dialogs', 'removeQuoteLine', 'delQuotation'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveQuoteLineDialog: flowRight(dispatch, hideRemoveQuoteLineDialog),
    requestRemoveQuoteLineConfirm: flowRight(dispatch, requestRemoveQuoteLineConfirm)
  };
}

const RemoveQuoteLineDialog = (props) => {
  const { removeQuoteLineDialogIsVisible,
    requestRemoveQuoteLineConfirm,
    hideRemoveQuoteLineDialog, quoteLine, delQuotation } = props;

  if (!quoteLine) {
    return null;
  }

  const imageUrl = quoteLine.getIn(['mediaContent', 'mainPicture', 'productThumbnail', 'lowResolution'], '');
  return (
    <Modal className="RemoveQuoteLineDialog" show={ removeQuoteLineDialogIsVisible }
      onHide={ hideRemoveQuoteLineDialog }>
      <Modal.Header className="RemoveQuoteLineDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <ZoroImage src={ imageUrl } />
          <div>
            { getLocalizedString('quoteLineDialog.confirmText.deleteWarning') }<br />
            { getLocalizedString('quoteLineDialog.confirmText.additionalText') }
            {delQuotation &&
              <span><br />{ getLocalizedString('quoteLineDialog.confirmText.productDelete') }</span>
            }
          </div>
        </div>

        <div className="row modal-body_options">

          <SecondaryButton
            value={ getLocalizedString('cart.dialog.cancel.button') }
            text={ getLocalizedString('cart.dialog.cancel.button') }
            className="RemoveQuoteLineDialog_cancelButton"
            onClick={ hideRemoveQuoteLineDialog } />

          <PrimaryButton
            text={ getLocalizedString('cart.orderLine.remove') }
            className="RemoveQuoteLineDialog_removeButton"
            onClick={ requestRemoveQuoteLineConfirm }
            value={ getLocalizedString('cart.orderLine.remove') }
          />

        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveQuoteLineDialog.propTypes = {
  removeQuoteLineDialogIsVisible: PropTypes.bool,
  hideRemoveQuoteLineDialog: PropTypes.func,
  requestRemoveQuoteLineConfirm: PropTypes.func,
  quoteLine: PropTypes.object,
  delQuotation: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveQuoteLineDialog);
