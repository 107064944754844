/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';
import { Field, reduxForm, reset, change, getFormValues } from 'redux-form/immutable';
import { validateForm } from 'client/utils/formValidation';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import { setView, updateAdditionalUsersList } from 'client/actions/loginAndRegistration';
import PostcodeInput from '../postCode';
import {
  CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  YES,
  FINANCE
} from '../../constants';
import { makeValidator, isValidEmailForNewUser, isValidPostCode, isRequiredIf, maxLength, isUKPhoneNumber } from 'client/utils/validators';
import CheckboxWithValidations from 'client/components/elements/formField/CheckboxWithValidations';
import { toggleEnterManually } from 'client/actions/ui/postCodeActions';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';
import { asyncValidateCheckEmailForTradeAccount } from 'client/sagas/loginSagas';

const FINANCE_CONTACT_FORM = 'financeContactForm';
const validate = makeValidator({
  sameAsPrimaryContact: [],
  sameAsRegisteredAddress: [],
  firstName: [
    isRequiredIf(
      (values) => !values.get('sameAsPrimaryContact'),
      'First name'
    ),
    maxLength(getLocalizedStringWithParam('form.maxLength.error', {
      fieldName: getLocalizedString('registration.label.firstName'),
      maxLength: 15
    }), 15)
  ],
  lastName: [
    isRequiredIf(
      (values) => !values.get('sameAsPrimaryContact'),
      'Last name'
    ),
    maxLength(getLocalizedStringWithParam('form.maxLength.error', {
      fieldName: getLocalizedString('registration.label.lastName'),
      maxLength: 15
    }), 15)
  ],
  phoneNumber: [
    isRequiredIf(
      (values) => !values.get('sameAsPrimaryContact'),
      'Phone number'
    ),
    isUKPhoneNumber(getLocalizedString('error.invalid.phoneNumber'))
  ],
  email: [
    isRequiredIf(
      (values) => !values.get('sameAsPrimaryContact'),
      'Email address'
    ),
    isValidEmailForNewUser()
  ],
  primary_addressLine1: [
    isRequiredIf(
      (values) => !values.get('sameAsRegisteredAddress'),
      getLocalizedString('postcodeInput.label.addressLine1')
    )
  ],
  primary_city: [
    isRequiredIf(
      (values) => !values.get('sameAsRegisteredAddress'),
      getLocalizedString('postcodeInput.label.city')
    )
  ],
  invoiceCompanyName: [
    isRequiredIf(
      (values) => !values.get('sameAsRegisteredAddress'),
      getLocalizedString('creditAccount.application.label.companyName')
    ),
    maxLength(getLocalizedStringWithParam('form.maxLength.error', {
      fieldName: getLocalizedString('creditAccount.application.label.companyName'),
      maxLength: 50
    }), 50)
  ],
  primary_postalCode: [
    isRequiredIf(
      (values) => !values.get('sameAsRegisteredAddress'),
      getLocalizedString('postcodeInput.label.postcode')
    ),
    isValidPostCode()
  ]
});

const FinanceContactForm = ({
  dispatchSetView,
  handleSubmit,
  financeContactForm = {},
  primaryContactDetailsForm,
  creditLineApplicationForm,
  toggleShowManually,
  onResetField,
  additionalUsersList,
  dispatchUpdateAdditionalUsersList,
  hideExitConfirmation
}) => {
  const { sameAsPrimaryContact, sameAsRegisteredAddress, primary_quickFindPostCode = '' } = financeContactForm;
  const { firstName, lastName, email, phoneNumber, additionalUsers, companyName } = primaryContactDetailsForm;
  const submit = (values) => {
    return validateForm(values, validate)
      .then(() => {
        dispatchSetView(additionalUsers === YES ? CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW : CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW);
      });
  };

  const {
    primary_addressLine1 = '',
    primary_addressLine2 = '',
    primary_city = '',
    primary_state = '',
    primary_postalCode = ''
  } = creditLineApplicationForm;

  useEffect(() => {
    if (primaryContactDetailsForm?.email === financeContactForm?.email && !sameAsPrimaryContact) {
      resetFinanceContactDetails(sameAsPrimaryContact);
    }

    if (sameAsPrimaryContact) {
      dispatchUpdateAdditionalUsersList(fromJS(additionalUsersList.filter(user => !user.isFinanceUser)));
    }
  }, [sameAsPrimaryContact]);

  useEffect(() => {
    resetFinanceAddressDetails();
  }, [sameAsRegisteredAddress]);

  const resetFinanceContactDetails = (asPrimaryContact) => {
    onResetField('firstName', asPrimaryContact ? firstName : '');
    onResetField('lastName', asPrimaryContact ? lastName : '');
    onResetField('phoneNumber', asPrimaryContact ? phoneNumber : '');
    onResetField('email', asPrimaryContact ? email : '');
  };

  const resetFinanceAddressDetails = () => {
    onResetField('primary_addressLine1', sameAsRegisteredAddress ? primary_addressLine1 : '');
    // onResetField('invoiceCompanyName', sameAsRegisteredAddress ? companyName : companyName);
    onResetField('primary_addressLine2', sameAsRegisteredAddress ? primary_addressLine2 : '');
    onResetField('primary_city', sameAsRegisteredAddress ? primary_city : '');
    onResetField('primary_state', sameAsRegisteredAddress ? primary_state : '');
    onResetField('primary_postalCode', sameAsRegisteredAddress ? primary_postalCode : '');
  };

  const onContinue = () => {
    if (primaryContactDetailsForm.email !== financeContactForm.email && !sameAsPrimaryContact) {
      const message = `Hi,
      Please accept this invitation to join the Zoro business account.
      Kind Regards,
      ${primaryContactDetailsForm?.firstName || ''}`.trim();

      const newAdditionalUsers = [
        ...(additionalUsersList || []).filter((el) => !el?.isFinanceUser),
        {
          email: financeContactForm.email,
          message: message,
          spendLimit: 0,
          role: FINANCE,
          isFinanceUser: true
        }
      ];

      dispatchUpdateAdditionalUsersList(fromJS(newAdditionalUsers));
    }

    hideExitConfirmation();
    toggleShowManually(true);
  };

  return <div className="row BusinessAccountRegistrationForm_creditLineForm">
    <form onSubmit={ handleSubmit(submit) }>
      <div className="col-xs-12">
        <div className="BusinessAccountRegistrationForm_financeContactBox">
          <div className="BusinessAccountRegistrationForm_financeContactTitle">
            Invoice Details
          </div>
          <div className="BusinessAccountRegistrationForm_financeContactTitle-subTitle">
            <Field
              id="sameAsPrimaryContact"
              name="sameAsPrimaryContact"
              onClick={ () => resetFinanceContactDetails(sameAsPrimaryContact) }
              labelClassName="control-label BusinessAccountRegistrationForm_sameAsPrimaryContact"
              component={ CheckboxWithValidations }
              textAndLink={ <div>Same as Primary Contact Details</div> }
              datae2e="sameAsPrimaryDetails" />
          </div>
        </div>
        <div className="col-xs-12 BusinessAccountRegistrationForm_financeContactFields">
          {
            sameAsPrimaryContact ? <div>
              <div>First Name: {firstName}</div>
              <div>Last Name: {lastName}</div>
              <div>Email: {email}</div>
              <div>Telephone: {phoneNumber}</div>
            </div> : <div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <Field
                    name="firstName"
                    id="firstName"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.firstName"
                    datae2e="invoice_firstName"
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <Field
                    name="lastName"
                    id="lastName"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.lastName"
                    datae2e="invoice_lastName"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <Field
                    name="email"
                    id="email"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="email"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.placeholder.emailAddress"
                    datae2e="invoice_userEmail"
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <Field
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control AboutYou_input"
                    component={ FieldWithValidations }
                    type="text"
                    labelClassName="control-label required AboutYou_labelInput"
                    labelResourceName="registration.label.phoneNumber"
                    datae2e="invoice_phoneNumber"
                  />
                </div>
              </div>
            </div>
          }
        </div>
        <div className="BusinessAccountRegistrationForm_financeContactBox">
          <div className="BusinessAccountRegistrationForm_financeContactTitle">
            Invoice Address
          </div>
          <div className="BusinessAccountRegistrationForm_financeContactTitle-subTitle">
            <Field
              id="sameAsRegisteredAddress"
              name="sameAsRegisteredAddress"
              onClick={ () => resetFinanceAddressDetails() }
              labelClassName="control-label BusinessAccountRegistrationForm_sameAsPrimaryContact"
              component={ CheckboxWithValidations }
              textAndLink={ <div>Same as Registered Address</div> }
              datae2e="sameAsPrimaryAddress" />
          </div>
        </div>
        <div className="col-xs-12 BusinessAccountRegistrationForm_financeContactFields">
          {
            sameAsRegisteredAddress ? <div>
              { companyName ? <div>{companyName}</div> : null }
              { primary_addressLine1 ? <div>{primary_addressLine1}</div> : null}
              { primary_addressLine2 ? <div>{primary_addressLine2}</div> : null}
              { primary_city ? <div>{primary_city}</div> : null}
              { primary_state ? <div>{primary_state}</div> : null}
              { primary_postalCode ? <div>{primary_postalCode}</div> : null}
            </div> : <div className="row BusinessAccountRegistrationForm_addressForm">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="invoiceCompanyName"
                  id="invoiceCompanyName"
                  className="form-control AboutYou_input"
                  component={ FieldWithValidations }
                  type="text"
                  labelClassName="control-label required AboutYou_labelInput"
                  labelResourceName="creditAccount.application.label.companyName"
                  datae2e="invoice_companyName"
                />
              </div>
              <div className="col-xs-12">
                <PostcodeInput
                  fieldNamePrefix="primary_"
                  datae2ePrefix="invoice_"
                  form={ FINANCE_CONTACT_FORM }
                  quickFindPostCodeValue={ primary_quickFindPostCode || '' }
                  selector={ FINANCE_CONTACT_FORM }
                />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 BusinessAccountRegistrationForm_actionButtons">
          <div className="col-xs-6 BusinessAccountRegistrationForm_backBtn ForgotPasswordNewForm_noLeftPadding">
            <SecondaryButton
              datae2e="back"
              onClick={ () => {
                hideExitConfirmation();
                dispatchSetView(CREATE_BUSINESS_ACCOUNT_CREDIT_LINE_VIEW);
              } }
            >
                Back
            </SecondaryButton>
          </div>
          <div className="col-xs-6 BusinessAccountRegistrationForm_continueBtn ForgotPasswordNewForm_noRightPadding">
            <PrimaryButton
              className="btn"
              type="submit"
              onClick={ () => onContinue() }
              datae2e="continue">
                Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  </div>;
};

const ReduxFinanceContactForm = reduxForm({
  form: FINANCE_CONTACT_FORM,
  validate,
  asyncValidate: asyncValidateCheckEmailForTradeAccount,
  destroyOnUnmount: false,
  asyncBlurFields: ['email']
})(FinanceContactForm);

const mapStateToProps = (state) => {
  const financeContactForm = (getFormValues(FINANCE_CONTACT_FORM)(state) || fromJS({})).toJS();
  const primaryContactDetailsForm = (getFormValues('primaryContactForm')(state) || fromJS({})).toJS();
  const creditLineApplicationForm = (getFormValues('creditLineApplicationForm')(state) || fromJS({})).toJS();

  return {
    creditLineApplicationForm,
    primaryContactDetailsForm,
    financeContactForm,
    additionalUsersList: (state.getIn(['loginAndRegistration', 'additionalUsersList']) || fromJS([])).toJS(),
    initialValues: {
      sameAsPrimaryContact: false,
      sameAsRegisteredAddress: true,
      email: financeContactForm?.email || primaryContactDetailsForm?.email || '',
      phoneNumber: financeContactForm?.phoneNumber || primaryContactDetailsForm?.phoneNumber || '',
      firstName: financeContactForm?.firstName || primaryContactDetailsForm?.firstName || '',
      lastName: financeContactForm?.lastName || primaryContactDetailsForm?.lastName || '',
      invoiceCompanyName: primaryContactDetailsForm?.companyName || '',
      primary_addressLine1: creditLineApplicationForm?.primary_addressLine1 || '',
      primary_addressLine2: creditLineApplicationForm?.primary_addressLine2 || '',
      primary_city: creditLineApplicationForm?.primary_city || '',
      primary_postalCode: creditLineApplicationForm?.primary_postalCode || ''
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  onResetField: (field, value) => dispatch(change(FINANCE_CONTACT_FORM, field, value)),
  onResetReduxForm: () => dispatch(reset(FINANCE_CONTACT_FORM)),
  toggleShowManually: (shouldEnterManually) => dispatch(toggleEnterManually(FINANCE_CONTACT_FORM, shouldEnterManually)),
  dispatchSetView: (view) => dispatch(setView({ view })),
  hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog()),
  dispatchUpdateAdditionalUsersList: (list) => dispatch(updateAdditionalUsersList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFinanceContactForm);
