import PropTypes from 'prop-types';
import React, { Children } from 'react';

if (process.browser) {
  require('./HeaderMenu.scss');
}

const Menu = ({ children }) => (
  <ul className="HeaderB_nav_options">
    {
      Children.map(children, (child) => (
        <li className={ `HeaderB_nav_item ${child.props.cssClass ? child.props.cssClass : ''}` }>
          { React.createElement(child.type, { ...child.props }) }
        </li>
      ))
    }
  </ul>
);

Menu.propTypes = {
  children: PropTypes.array.isRequired
};

export default Menu;
