import React from 'react';
import SvgLoader from '../../../svg/SvgLoader';
if (process.browser) {
  require('./payByNewCard.scss');
}

export const BraintreeField = ({ id, label, errorMessage, small }) => {
  return (
    <div className={ `PayByNewCard_braintree_field PayByNewCard_break ${small ? 'PayByNewCard_braintree_field_small' : null}` }>
      <label className="PayByNewCard_title_small" htmlFor={ id }>
        {label}<span className="PayByNewCard_braintree_input_asterisk"> *</span>
      </label>
      <div className={ `PayByNewCard_braintree_input
                        ${small ? 'PayByNewCard_braintree_input_small' : null}
                        ${errorMessage ? 'PayByNewCard_braintree_input-error' : null}` } id={ id } />
      <div className="PayByNewCard_braintree_field-errorMessage">{errorMessage}</div>
    </div>
  );
};

export const TextInputField = ({ id, label, setNameOnCard, selector, errorMessage }) => {
  return (
    <div className="PayByNewCard_braintree_field PayByNewCard_break">
      <label className="PayByNewCard_title_small" htmlFor={ id }>
        {label}<span className="PayByNewCard_braintree_input_asterisk"> *</span>
      </label>
      <div>
        <input
          onChange={ (event => setNameOnCard({
            selector,
            nameOnCard: event.target.value
          })) }
          type="text"
          className={ `PayByNewCard_braintree_input PayByNewCard_braintree_input_text ${errorMessage ? 'PayByNewCard_braintree_input-error' : null} ` } id={ id } />
        <div className="PayByNewCard_braintree_field-errorMessage">{errorMessage}</div>
      </div>
    </div>
  );
};

export const Checkbox = ({ toggle, onClick, selector, text, dataE2e }) => {
  return (
    <div className="PayByNewCard_break">
      <SvgLoader
        data-e2e={ dataE2e }
        className="PayByNewCard_checkbox"
        xlinkHref={ toggle ? '#checkbox-icon-checked' : '#checkbox-icon-unchecked' }
        onClick={ () => {
          onClick({ selector });
        } }
      /> <span className="PayByNewCard_checkbox_text">{text}</span>
    </div>
  );
};
