import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NewStockMessage from 'client/components/elements/newStockMessage/NewStockMessage';
import TapButton from 'client/components/controls/tapButton/TapButton';
import { getLocalizedString } from 'localization/localizer';
import { connect } from 'react-redux';
import { formatPrice } from 'client/utils/priceUtils';
import SaleTag from 'client/components/elements/promoLabel/SaleTag';
import SvgLoader from 'client/components/svg/SvgLoader';
import { fromJS, List } from 'immutable';
import QuantityInput from 'client/components/elements/quantityInput/quantityInput';
import { buildProductUrl } from 'shared/utils/pathUtils';
import UpsellProduct from 'client/components/elements/upsellProducts/UpsellProduct';
import _remove from 'lodash/remove';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

const HYPHEN = '-';

if (process.browser) {
  require('./ProductBundleLine.scss');
}

const stockMessageProps = (orderLine) => {
  const orderLinePrice = orderLine.get('orderLinePrice');
  const stockStatusToJS = orderLine.getIn(['stock', 'stockMessage'], {}).toJS();
  const productPrice = orderLinePrice.get('unitPriceWithVat');
  const productCurrency = orderLine.getIn(['price', 'currency'], 'GBP');
  const isForCart = true;
  const isSearchable = orderLine.get('isSearchable');

  return {
    productPrice,
    productCurrency,
    stockStatus: stockStatusToJS,
    isForCart,
    isSearchable
  };
};

class ProductBundleLine extends React.PureComponent {
  render () {
    const {
      sku,
      isEditable,
      onQuantityChange,
      onRemove,
      isIncludingVat,
      quantity,
      isAvailable,
      hideStockMessages,
      productBundleLineItem,
      primarySku,
      hideRemoveButton
    } = this.props;
    const productLine = productBundleLineItem.get([sku, primarySku].join(HYPHEN));
    if ((!productLine || !productLine.size) || (!productBundleLineItem || !productBundleLineItem.size)) {
      return <div className="ProductBundleLine_loader"/>;
    }

    const isUpdatingQuantityFlag = productLine.get('isUpdatingQuantity');
    const productLinePrice = productLine.get('orderLinePrice');
    const unitPrice = productLinePrice.get(isIncludingVat ? 'bundlePriceWithVat' : 'bundlePriceWithoutVat');
    const linePrice = productLinePrice.get(isIncludingVat ? 'bundlePriceWithVatTimeQty' : 'bundlePriceWithoutVatTimeQty');
    const currency = productLine.getIn(['price', 'currency'], 'GBP');
    const priceChange = productLine.get('priceChange', null);
    const unitPriceCurrency = formatPrice(currency, unitPrice);
    const linePriceCurrency = formatPrice(currency, linePrice);
    const upsellProductList = productLine.get('products', List()).toJS();
    const primaryProduct = _remove(upsellProductList, (prod) => prod.isPrimary);
    const productUrl = buildProductUrl(fromJS(primaryProduct[0]));
    const message = productLine.get('message');
    const label = productLine.get('label');

    const unitPriceWithVat = (
      <Fragment>
        { `${unitPriceCurrency.symbol}${unitPriceCurrency.amount}` }
      </Fragment>
    );

    const subTotalWithVat = (
      <Fragment>
        <span className="total visible-xs">
          {getLocalizedString('cart.productBundleLine.label.sub.total')}
        </span>
        {`${linePriceCurrency.symbol}${linePriceCurrency.amount}`}
      </Fragment>
    );
    const name = productLine.get('name');
    const imageSrc = productLine.get('imageSrc');

    return (<div className="row">
      <div className={ `ProductBundleLine ${isUpdatingQuantityFlag ? 'ProductBundleLine_loading' : ''}` } data-e2e={ `ProductBundleLine-${sku}` }>
        <div className="col-xs-4 col-sm-3">
          <ZoroImage src={ imageSrc } alt={ name } title={ name } className="ProductBundleLine_image"/>
          <div className="visible-sm">
            <a
              id={ `ProductBundleLine_title-${sku}` }
              className="ProductBundleLine_title-sku"
              rel="noopener noreferrer" href={ `${productUrl}` }>
              { name }
            </a>
            <div className="ProductBundleLine_title-skuId">{ getLocalizedString('cart.cartOrderLine.skuLabel') } { sku }</div>
          </div>
        </div>
        <div className="col-xs-6 col-sm-7 visible-xs">
          <a
            id={ `ProductBundleLine_title-${sku}` }
            className="ProductBundleLine_title-sku"
            rel="noopener noreferrer" href={ `${productUrl}` }>
            { name }
          </a>
          <div className="ProductBundleLine_title-skuId">{ getLocalizedString('cart.cartOrderLine.skuLabel') } { sku }</div>
        </div>
        <div className="col-xs-2 visible-xs">
          { isEditable
            ? <TapButton
              styles="ProductBundleLine_tapButton"
              id={ `cart.orderLine.remove.${sku}` }
              onTap={ () => onRemove(productLine) }>
              <SvgLoader xlinkHref="#cross-icon" className="ProductBundleLine_removeIcon" />
            </TapButton> : null }
        </div>
        <div className="col-xs-12 col-sm-9 ProductBundleLine_topLine">
          <div className="col-md-4 visible-md visible-lg hidden-sm">
            <a
              id={ `ProductBundleLine_title-${sku}` }
              className="ProductBundleLine_title-sku"
              rel="noopener noreferrer" href={ `${productUrl}` }>
              { name }
            </a>
            <div className="ProductBundleLine_title-skuId">{ getLocalizedString('cart.cartOrderLine.skuLabel') } { sku }</div>
          </div>
          <div className="col-xs-4 col-md-2 col-sm-3 form-group ProductBundleLine_QuantityInput">
            <QuantityInput
              disabled={ !isEditable }
              setQuantity={ onQuantityChange }
              removeProductLine={ onRemove }
              product={ productLine }
              quantity={ quantity }
              unavailableStatus={ isAvailable }
            />
          </div>
          <div className="col-xs-4 col-md-3 col-md-offset-0 col-sm-4 col-sm-offset-0 ProductBundleLine_UnitPrice">
            { unitPriceWithVat }
            { priceChange
              ? <div className="ProductBundleLine_UnitPrice-priceChange">
                {getLocalizedString('cart.productBundleLine.label.price.change')}
              </div>
              : null
            }
          </div>

          <div className="col-xs-4 col-md-3 col-sm-4 ProductBundleLine_SubTotalPrice">
            { subTotalWithVat }
          </div>

        </div>
        <div className="col-xs-12 col-md-9 col-sm-8 ProductBundleLine_bottomLine">
          <div className="row">
            <div className="col-xs-4">
              {
                !hideStockMessages
                  ? <div className="row">
                    <div className="col-md-12 noPadding">
                      <NewStockMessage sku = { sku } { ...stockMessageProps(productLine) }/>
                    </div>
                  </div>
                  : null
              }
            </div>
            <div className="hidden-sm hidden-xs col-md-2 noPadding">
            </div>
            <div className="col-xs-4 col-md-3">
              <div className="ProductBundleLine_PromoLabel">
                { label ? <SaleTag label= { label } promoUrl=''/> : null }
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 ProductBundleLine_message">
          <h3>{ message }</h3>
        </div>
        <div className="row">
          { fromJS(upsellProductList).map((product) => (
            <div className="col-xs-12 col-md-4 col-sm-9" key={ product.get('sku') }>
              <UpsellProduct
                sku={ product.get('sku') }
                name={ product.get('name') }
                product={ product }
                imageSrc={ product.get('imageSrcLowResolution') }
              />
            </div>
          )) }
        </div>
        <div className="col-xs-12 text-right hidden-xs">
          {
            isEditable && !hideRemoveButton
              ? <TapButton
                styles="ProductBundleLine_tapButton"
                id={ `cart.orderLine.remove.${sku}` }
                onTap={ () => onRemove(productLine) }>
                { getLocalizedString('cart.orderLine.remove') }
              </TapButton>
              : ''
          }
        </div>
      </div>
      { isUpdatingQuantityFlag && <div className="ProductBundleLine_spinner"/> }
    </div>
    );
  }
}

ProductBundleLine.propTypes = {
  sku: PropTypes.string,
  isEditable: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  onRemove: PropTypes.func,
  includeVat: PropTypes.bool,
  quantity: PropTypes.number,
  isAvailable: PropTypes.bool,
  hideStockMessages: PropTypes.bool,
  hideSavingsLabels: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    productBundleLineItem: state.get('productBundleLineItem', fromJS({}))
  };
};

export default connect(mapStateToProps, null)(ProductBundleLine);
export { ProductBundleLine };
