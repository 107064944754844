import Immutable from 'immutable';
import {
  RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS,
  RECEIVE_REGISTER_CREDIT_ACCOUNT_ERROR
} from 'client/actions/creditAccountActions';

export const defaultState = Immutable.fromJS({});

const creditAccountApplicationFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_REGISTER_CREDIT_ACCOUNT_ERROR:
      return state.set('error', action.error);
    case RECEIVE_REGISTER_CREDIT_ACCOUNT_SUCCESS:
      return state.set('error', undefined);
    default:
      return state.set('error', undefined);
  }
};

export default creditAccountApplicationFormReducer;
