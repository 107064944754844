import { createAction } from 'redux-actions';

export const VALIDATE_PASSWORD_CHECKLIST = 'VALIDATE_PASSWORD_CHECKLIST';

export const validatePasswordChecklist = createAction(({ password }) => (
  {
    type: VALIDATE_PASSWORD_CHECKLIST,
    password
  }
));
