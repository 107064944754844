import { fromJS } from 'immutable';
import { QTY_ADDED_TO_CART } from '../../actions/cartActions';

const defaultState = fromJS({
  qtyAddedToCart: null
});

const addToBasketInterstitialState = (state = defaultState, action) => {
  switch (action.type) {
    case QTY_ADDED_TO_CART:
      return state
        .set('qtyAddedToCart', action.payload);
    default:
      return state;
  }
};

export default addToBasketInterstitialState;
