import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { SubCategoriesList } from './SubCategoriesList';

export const MobileSubCategoriesList = ({
  selectedCategoryId,
  categories,
  allCategories,
  goBackHandler
}) => {
  const category = categories.find((cat) => cat.get('id') === selectedCategoryId);
  return (
    <div className="MobileSubCategoriesList">
      <div className="MobileSubCategoriesList_header">
        <SvgLoader xlinkHref={ `#left-chevron-icon` } aria-hidden="true" onClick={ goBackHandler }/>
        <SvgLoader xlinkHref={ `#cat-${selectedCategoryId}-icon` } aria-hidden="true" />
        <label>{ category.get('title') }</label>
        <a href={ category.categoryUrl }>Shop all</a>
      </div>
      <div className="MobileSubCategoriesList_all">
        <SubCategoriesList allCategories={ allCategories } parentCategoryId={ selectedCategoryId } />
      </div>
    </div>
  );
};
