import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { PrimaryButton } from 'client/components/controls/StyledForms';
import { getLocalizedString } from 'localization/localizer';
import { hideMaskCallDialog } from 'client/actions/ui/dialogActions';

if (process.browser) {
  require('./MaskCallDialog.scss');
}

export const MaskCallDialog = ({ title, body, isVisible, hideMaskCallDialog }) =>
  <>
    <Modal className="MaskCallDialog" show={ isVisible } onBackdropClick={ hideMaskCallDialog }>
      <Modal.Header>{ title }</Modal.Header>
      <Modal.Body className="MaskCallDialog_Body">
        { body }
      </Modal.Body>
      <PrimaryButton onClick={ hideMaskCallDialog }>{ getLocalizedString(`singlePageCheckout.cs.maskCallDialogAccept`) }</PrimaryButton>
    </Modal>
  </>;

const mapStateToProps = (state) => {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'maskCall', 'isVisible'])
  };
};

const mapDispatchToProps = {
  hideMaskCallDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(MaskCallDialog);
