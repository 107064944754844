import { put, takeLatest } from 'redux-saga/effects';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { ORDER_DETAILS_TOGGLE } from 'client/actions/ui/orderSummaryActions';

export function * onToggleMyOrder ({ payload }) {
  yield put(updateDataLayer({
    event: 'DispatchInfo_ToggledItem',
    order_number: payload.selector,
    order_status: payload.order_status,
    shipping_date: payload.shipping_date
  }));
}

export function * watchToggleMyOrder () {
  yield takeLatest(ORDER_DETAILS_TOGGLE, onToggleMyOrder);
}
