import React from 'react';

if (process.browser) {
  require('./CmsTitlePanel.scss');
}
const CmsTitlePanel = ({ text, css }) => {
  return <div className="CmsTitlePanel" style={ css }>
    {text}
  </div>;
};

export default CmsTitlePanel;
