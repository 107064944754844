import { ApiRequest } from 'shared/utils/apiUtils';

export const getCategoryById = function getCategory (id) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/categories/${id}`
  });
  return req.executeAndGetBody();
};

export const getAllCategories = function getCategory (id) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/allcategories`
  });
  return req.executeAndGetBody();
};
