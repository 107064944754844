import PropTypes from 'prop-types';
import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { Empty } from 'client/components/elements/empty/Empty';
import { Link } from 'react-router';
import QuotationProductRow from './QuotationProductRow';
import QuotationTotalizer from './QuotationTotalizer';
import { getLocalizedString } from 'localization/localizer';
import { PrimaryLink } from 'client/components/controls/StyledForms/formButtons';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import { checkIsValidSinglePageCheckout } from 'client/utils/routeUtils';

if (process.browser) {
  require('./QuotationDetailsScreen.scss');
}

class QuotationDetailsScreen extends React.Component {
  render () {
    const {
      quotationId,
      quotationDetails,
      addToCart,
      fetchQuotationProductStockInfo,
      updateQuotationProductQty,
      removeProductFromQuotationConfirm,
      sendQuotationEmail,
      deleteQuotationConfirm,
      lockQuotation,
      updatePriceWithBreakPrice,
      requestChangeInQuoteLineQty,
      isTradeAccountButtonToBeShown,
      includeVat,
      braintreePaymentConfig,
      userEmail,
      addQuoteLineToBasketAction,
      loyaltyTier
    } = this.props;
    if (quotationId && !quotationDetails) {
      return <ScreenLoadPending className={ 'smallLoader' } />;
    }
    if (!quotationDetails) return <Empty />;

    const isLocked = quotationDetails.get('isLocked', false);
    let validtill = 'Expired';

    if (isLocked) {
      const expiry = new Date(quotationDetails.get('expiryTime'));
      validtill = `${expiry.getDate()}/${expiry.getMonth() + 1}/${expiry.getFullYear()}`;
    }
    const tradeAccountLimitExceeded = isTradeAccountButtonToBeShown.get('tradeAccountLimitExceeded');
    const shouldShowSinglePageCheckout = checkIsValidSinglePageCheckout(braintreePaymentConfig, userEmail);
    const checkoutUrl = shouldShowSinglePageCheckout
      ? SINGLE_PAGE_CHECKOUT_ROUTE
      : '/checkout/order';
    const isQtyAvailable = quotationDetails.get('products', []).reduce((isQtyAvailable, product) => {
      if (isQtyAvailable) {
        isQtyAvailable = product.getIn(['stock', 'stockMessage', 'isDeliverable'], false);
      }
      return isQtyAvailable;
    }, true);

    const isQtyValid = quotationDetails.get('products', [])
      .map((product) => {
        const quantity = product.get('qty', 1);
        const qtyMultiplier = product.getIn(['lockTimePrice', 'qtyMultiplier'], 1);
        const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
        const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);
        return isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
      }).every(el => el === true);

    const actionButtons = (isQtyAvailable && isLocked && isQtyValid) ? (
      <div className="QuotationDetailsScreen_payButtons">
        { tradeAccountLimitExceeded ? <div className="QuotationDetailsScreen_limitExceeded">&#33; Quotation grand total exceeds account limit.</div> : null }
        <div className="QuotationDetailsScreen_payButtons-payByCard">
          <PrimaryLink
            to={ `${checkoutUrl}?fromQuotationId=${quotationId}` }
            datae2e="checkoutButton"
            text={ getLocalizedString('cart.buttons.checkout') }
          />
        </div>
      </div>
    ) : (
      <div className="QuotationDetailsScreen_qtyUnavailable">
        {
          isQtyValid
            ? isLocked ? 'Some products are unavailable. Please review your quotation!'
              : 'This quotation is expired. Please re-quote to order!'
            : getLocalizedString('myAccount.quotations.invalid.quantity')
        }
      </div>
    );

    const buttonsPanel = (
      <div>
        <div className="QuotationDetailsScreen_buttonsPanel">
          { isLocked &&
          <Link className="QuotationDetailsScreen_emailButton" onClick={ () => sendQuotationEmail(quotationId) } data-e2e="quotationsEmailButton">
            <SvgLoader xlinkHref="#email-icon" className="email-icon" title="Send Email" />
            <span>Email to myself</span>
          </Link>
          }
          { isLocked &&
          <a
            download
            className="QuotationDetailsScreen_pdfButton"
            rel="noopener noreferrer" target="_blank"
            href={ `/api/myaccount-sunrise/quotation/pdf/${quotationId}` }
            data-e2e="quotationsDownloadPdfButton"
          >
            <SvgLoader xlinkHref="#pdf2-icon" className="pdf2-icon" title="Download PDF" />
            <span>Download as pdf</span>
          </a>
          }
          <Link className="QuotationDetailsScreen_deleteButton" onClick={ () => deleteQuotationConfirm(quotationId, 'detail_page') } data-e2e="quotationsDeleteButton">
            <SvgLoader xlinkHref="#trash-icon" className="trash-icon" title="Delete Quote" />
            <span>Delete Quotation</span>
          </Link>
          { !isLocked &&
          <Link className="QuotationDetailsScreen_requoteButton" onClick={ (e) => lockQuotation(quotationId, 'detail_page') } data-e2e="quotationsRequoteButton">
            <SvgLoader xlinkHref="#refresh-icon" className="refresh-icon" title="Re-Quote" />
            <span>Re-Quote
              <span>Lock Prices<br/>for 14 days</span>
            </span>
          </Link>
          }
          { isLocked &&
          <Link className="QuotationDetailsScreen_lockedButton" data-e2e="quotationsLockedButton">
            <SvgLoader xlinkHref="#lock-icon" className="lock-icon" />
            <span>Locked</span>
          </Link>
          }
        </div>
        { actionButtons }
        <div className="QuotationDetailsScreen_quoteToBasket">
          Or&nbsp;
          <Link onClick={ () => addQuoteLineToBasketAction(quotationId) } data-e2e="moveItemsToBasketLink">
            { getLocalizedString('quotation.moveItemsToBasket') }
          </Link>
        </div>
      </div>
    );

    const isLastItem = (quotationDetails.get('products', []).size <= 1);
    return (
      <div className="QuotationDetailsScreen QuotationDetailsScreen_section">
        <div className="QuotationDetailsScreen_quotationName">
          <span className="QuotationDetailsScreen_goBack">
            <a href="/my-account/quotations" data-e2e="goBackToQuotationsLink">
              <SvgLoader xlinkHref="back-icon" className="QuotationDetailsScreen_goBack-icon" title="Go Back" />
            </a>
          </span>
          <span className="QuotationDetailsScreen_quotationTitle">{ quotationDetails.get('name') }</span>
          <span className="QuotationDetailsScreen_spec">Valid Until: <span className="QuotationDetailsScreen_validuntil">{ validtill }</span></span>
        </div>
        <div className="row QuotationDetailsScreen_TitleRow" />
        { quotationDetails.get('products', []).map((product) => {
          return (<QuotationProductRow
            key={ product.get('sku') }
            quotationId={ quotationId }
            isLocked={ quotationDetails.get('isLocked') }
            isNonEditable={ quotationDetails.get('isLocked') }
            product={ product }
            addToCart={ addToCart }
            fetchQuotationProductStockInfo={ fetchQuotationProductStockInfo }
            updateQuotationProductQty={ updateQuotationProductQty }
            removeProductFromQuotationConfirm={ removeProductFromQuotationConfirm }
            isLastItem = { isLastItem }
            updatePriceWithBreakPrice={ updatePriceWithBreakPrice }
            requestChangeInQuoteLineQty={ requestChangeInQuoteLineQty }
            includeVat={ includeVat }
          />);
        }) }
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6 QuotationTotalScreen">
            <QuotationTotalizer
              quotationDetails={ quotationDetails }
              includeVat={ includeVat }
              loyaltyTier={ loyaltyTier }
            />
          </div>
        </div>
        { quotationDetails.get('products', []).size > 0 ? buttonsPanel : null }
      </div>);
  }
}

QuotationDetailsScreen.propTypes = {
  quotationId: PropTypes.string,
  quotationDetails: PropTypes.object,
  addToCart: PropTypes.func,
  addQuotationToCart: PropTypes.func,
  fetchQuotationProductStockInfo: PropTypes.func,
  updateQuotationProductQty: PropTypes.func,
  removeProductFromQuotationConfirm: PropTypes.func,
  sendQuotationEmail: PropTypes.func,
  deleteQuotationConfirm: PropTypes.func,
  lockQuotation: PropTypes.func,
  updatePriceWithBreakPrice: PropTypes.func,
  requestChangeInQuoteLineQty: PropTypes.func,
  totalOrderPriceWithVat: PropTypes.string,
  isTradeAccountButtonToBeShown: PropTypes.object
};

export default QuotationDetailsScreen;
