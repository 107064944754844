import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import SvgLoader from 'client/components/svg/SvgLoader';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import {
  USER_TYPE_BUSINESS,
  BUSINESS_ACCOUNT_IMAGE
} from '../../constants';
import { validatePasswordChecklist } from 'client/actions/passwordChecklistAction';
import { setView } from 'client/actions/loginAndRegistration';
import { hideLoginDialog } from 'client/actions/ui/dialogActions';
import ZoroBenefits from '../../zoroBenefits/index';
import EmailAndPassword from './EmailAndPassword';

const FORM_NAME = 'finalStepRegister';
const FinalStepRegister = () => {
  return (<div className="col-xs-12 WelcomeToZoro_container">
    <div className="hidden-xs hidden-sm col-xs-5 WelcomeToZoro_leftImageContainer">
      <ZoroImage
        className="WelcomeToZoro_leftImage"
        src={ BUSINESS_ACCOUNT_IMAGE } />
      <ZoroBenefits userType={ USER_TYPE_BUSINESS }/>
    </div>
    <div className="col-xs-12 col-md-7 AboutYou">
      <div className="WelcomeToZoro_logo">
        <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
      </div>
      <div className="col-xs-10 col-xs-offset-1 WelcomeToZoro_userTypeContainer">
        <EmailAndPassword />
      </div>
    </div>
  </div>);
};

const mapStateToProps = (state) => {
  return {
    aboutYouFormValues: (getFormValues('aboutYouForm')(state) || fromJS({})).toJS(),
    csAgentName: state.getIn(['customerService', 'csAgentName']),
    passwordValidationChecklist: state.getIn(['passwordChecklist'])
  };
};

function mapDispatchToProps (dispatch) {
  return {
    validatePasswordChecklist: () => dispatch(validatePasswordChecklist()),
    dispatchSetView: (view) => dispatch(setView({ view })),
    hideLoginDialog: () => dispatch(hideLoginDialog())
  };
}

const reduxFinalStepRegister = reduxForm({
  form: FORM_NAME
})(FinalStepRegister);

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxFinalStepRegister
);
