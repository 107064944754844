import dayjs from 'dayjs';
import { SUBSCRIPTION_DISCOUNT_RATE, ScheduleOrderFormStatus, frequencyMap } from 'client/components/elements/scheduledOrders/constants';
import { RUNNING, SEND_PARTIAL_ORDER } from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { fromJS } from 'immutable';
import groupBy from 'lodash/groupBy';
import numeral from 'numeral';

const getPagePathname = () => typeof window === 'undefined' ? '' : window.location ? window.location.pathname : '';

const areAllStringsSame = (stringArr) => {
  if (stringArr.length === 0 || stringArr.every(val => !val)) {
    return false;
  }
  return new Set(stringArr).size === 1;
};

const checkoutConfirmationUrlRegex = /checkout\/confirmation/;
export const mySubscriptionsUrlRegex = /my-account\/repeat-orders/;

export const isConfirmationsScreen = () => checkoutConfirmationUrlRegex.test(getPagePathname());

export const getMinimumStopDate = (startDate, regularity) => {
  return startDate ? dayjs(startDate).add(frequencyMap[regularity] + 1, 'days').toDate() : null;
};

export const getNumberOfScheduledOrders = (endDate, startDate, regularity) => {
  return endDate && regularity ? Math.floor(dayjs(endDate).diff(startDate, 'days') / frequencyMap[regularity]) : '';
};

export const getEstimatedSubscriptionPrice = (priceAmount) => numeral(Math.round((numeral(priceAmount).value() + Number.EPSILON) * (100 - SUBSCRIPTION_DISCOUNT_RATE)) / 100).format('0,0.00');
export const getEstimatedSubscriptionSavings = (priceAmount) => numeral(Math.round((numeral(priceAmount).value() + Number.EPSILON) * SUBSCRIPTION_DISCOUNT_RATE) / 100).format('0,0.00');

export const getMappedSubscriptionPriceProps = (priceProps) => {
  return {
    ...priceProps,
    linePriceCurrency: { ...priceProps.linePriceCurrency, amount: getEstimatedSubscriptionPrice(priceProps.linePriceCurrency?.amount) },
    unitPriceCurrency: { ...priceProps.unitPriceCurrency, amount: getEstimatedSubscriptionPrice(priceProps.unitPriceCurrency?.amount) },
    totalSaving: getEstimatedSubscriptionSavings(priceProps.linePriceCurrency?.amount)
  };
};

export const getShiftedEndDate = (noOfOrders, startDate, regularity) => {
  return Number.isInteger(parseInt(noOfOrders)) ? dayjs(startDate).add(noOfOrders * frequencyMap[regularity], 'days').toDate() : null;
};

export const parseConfirmedOrderIdFromUrl = () => {
  return isConfirmationsScreen() ? getPagePathname().replace('/checkout/confirmation/', '') : null;
};

export const isCheckoutSubscriptionPage = () => {
  return [/cart/, /checkout\/order/].some(urlRegex => urlRegex.test(getPagePathname()));
};

export const isSubscribableProduct = (orderLine) => {
  return 'get' in orderLine && orderLine.get('fulfillmentType') === 0 && orderLine.getIn(['stock', 'mainWarehouseQty'], 0) > 0;
};

export const areAllSavedSettingsEqual = (linesWithSavedSettings) => {
  if (!linesWithSavedSettings.size) return false;

  const firstScheduledOrder = linesWithSavedSettings.first().get('scheduledOrder');
  if (!firstScheduledOrder) return false;

  return !linesWithSavedSettings.find((productLine) => {
    const { startDate, endDate, regularity } = productLine.get('scheduledOrder', fromJS({}))?.toJS() || {};

    return startDate !== firstScheduledOrder.get('startDate') ||
    endDate !== firstScheduledOrder.get('endDate') ||
    regularity !== firstScheduledOrder.get('regularity');
  });
};

export const getMappedScheduledOrderLine = (userInfo, scheduledOrderSettings) => (orderLine) => {
  const { startDate, endDate, regularity } = scheduledOrderSettings;
  const subscriptionName = `${orderLine.get('name')} - ${regularity} - from ${dayjs(startDate).format('DD-MM-YYYY')}`;
  return {
    products: [{ sku: orderLine.get('sku'), qty: orderLine.get('quantity') }],
    orderReference: subscriptionName,
    regularity,
    startDate,
    endDate,
    state: RUNNING,
    outOfStockDeliveryPreference: SEND_PARTIAL_ORDER,
    name: subscriptionName,
    ...userInfo
  };
};

export const groupScheduledOrders = (scheduledOrders) => {
  const groupedData = groupBy(scheduledOrders, item => `${item.outOfStockDeliveryPreference}-${item.paymentType}-${item.regularity}-${item.startDate}-${item.endDate}`);
  return Object.values(groupedData).map(subscriptionsGroup => ({
    ...subscriptionsGroup[0],
    products: [].concat(...subscriptionsGroup.map(item => item.products)),
    ...subscriptionsGroup.length > 1 ? {
      name: `Subscription - ${dayjs(subscriptionsGroup[0].startDate).format('DD-MM-YYYY')}`,
      orderReference: `Subscription - ${dayjs(subscriptionsGroup[0].startDate).format('DD-MM-YYYY')}`
    } : {}
  }));
};

export const getFormStatus = (productLines) => {
  const { VIEW, HIDDEN, SUBSCRIPTION_CREATED, SUBMITTED } = ScheduleOrderFormStatus;
  const subscribablePrducts = productLines.filter(isSubscribableProduct);

  if (!(subscribablePrducts.size > 1)) {
    return HIDDEN;
  }
  const subscribableLinesWithoutSubscription = productLines.filter((productLine) => {
    return isSubscribableProduct(productLine) && !productLine.get('scheduledOrder');
  });

  if (subscribableLinesWithoutSubscription.size > 1) { return VIEW; }

  const linesWithSavedSettings = productLines.filter((productLine) => {
    return productLine.hasIn(['scheduledOrder', 'startDate']) && !productLine.hasIn(['scheduledOrder', '_id']);
  });

  if ((linesWithSavedSettings.size && subscribableLinesWithoutSubscription.size)) {
    return VIEW;
  }

  const isAllCartSubscriptionSaved = subscribablePrducts.size === linesWithSavedSettings.size;

  if (isAllCartSubscriptionSaved) {
    return areAllSavedSettingsEqual(linesWithSavedSettings) ? SUBMITTED : VIEW;
  }

  const linesWithSubmittedSubscription = productLines.filter(productLine => productLine.hasIn(['scheduledOrder', '_id']));
  const submittedIds = linesWithSubmittedSubscription.map(productLine => productLine.getIn(['scheduledOrder', '_id'])).toArray();
  const isAllCartSubscriptionSubmitted = subscribablePrducts.size === linesWithSubmittedSubscription.size &&
    areAllStringsSame(submittedIds);
  if (isAllCartSubscriptionSubmitted) { return SUBSCRIPTION_CREATED; }

  return HIDDEN;
};
