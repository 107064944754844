import React from 'react';

if (process.browser) {
  require('./CmsComponentWrapper.scss');
}
class CmsComponentWrapper extends React.Component {
  render () {
    const { css, componentClass } = this.props;
    const style = css ? css.toJS() : {};
    return (
      <div className={ `CmsComponentWrapper ${componentClass}` } style={ style }>
        {this.props.children}
      </div>
    );
  }
}

export default CmsComponentWrapper;
