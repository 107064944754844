import React from 'react';
import Toggle from 'react-toggle';

if (process.browser) {
  require('./toggleButton.scss');
}

export const ToggleButton = (props) => {
  const { onChange, id, checked, showLabel, className, name } = props;
  return (<div className={ `ToggleButton_toggleWrapper ${className}` }>
    <Toggle
      id={ id }
      disabled={ false }
      name={ name }
      checked={ checked }
      onChange= { onChange }
    />
    {
      showLabel
        ? checked
          ? (<span className="ToggleButton_enabled">Enabled</span>)
          : (<span className="ToggleButton_disabled">Disabled</span>)
        : null
    }
  </div>);
};
