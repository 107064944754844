import { fromJS } from 'immutable';

import {
  REDIRECT_URL
} from 'client/actions/searchActions';

const defaultState = fromJS({
  filters: [],
  categories: [],
  selectedFilters: [],
  selectedPriceFilters: {},
  noResults: false,
  categoryFamilies: {},
  isFamilyLoading: false,
  isSearchFilterLoading: false,
  stores: []
});

const search = (state = defaultState, action) => {
  switch (action.type) {
    case REDIRECT_URL:
      return state.set('redirectUrl', action.payload.redirectUrl)
        .set('redirectStatusCode', action.payload.statusCode);
    default:
      return state;
  }
};

export default search;
