import { createAction } from 'redux-actions';

export const TOGGLE_SHOW_ADDRESS_FORM = 'payByNewCard.TOGGLE_SHOW_ADDRESS_FORM';
export const toggleShowAddressForm = createAction(TOGGLE_SHOW_ADDRESS_FORM);

export const TOGGLE_SAVE_CARD_FOR_FUTURE_ORDERS = 'payByNewCard.TOGGLE_SAVE_CARD_FOR_FUTURE_ORDERS';
export const toggleSaveCardForFutureOrders = createAction(TOGGLE_SAVE_CARD_FOR_FUTURE_ORDERS);

export const SET_NAME_ON_CARD = 'payByNewCard.SET_NAME_ON_CARD';
export const setNameOnCard = createAction(SET_NAME_ON_CARD);

export const INITIALIZE = 'payByNewCard.INITIALIZE';
export const initialize = createAction(INITIALIZE);

export const UPDATE_HOSTED_FIELDS_STATUS = 'payByNewCard.UPDATE_HOSTED_FIELDS_STATUS';
export const updateHostedFieldsStatus = createAction(UPDATE_HOSTED_FIELDS_STATUS);

export const PAY_BY_NEW_CARD = 'payByNewCard.PAY';
export const payByNewCard = createAction(PAY_BY_NEW_CARD);

export const SUBSCRIBE_BY_NEW_CARD = 'payByNewCard.SUBSCRIBE';
export const subscribeByNewCard = createAction(SUBSCRIBE_BY_NEW_CARD);

export const SUBSCRIBE_BY_NEW_CARD_EXISTING_SUBSCRIPTION = 'payByNewCard.SUBSCRIBE.EXISTING';
export const subscribeByNewCardForExistingSubscription = createAction(SUBSCRIBE_BY_NEW_CARD_EXISTING_SUBSCRIPTION);

export const SET_SUBSCRIBE_BY_NEW_CARD_STATUS = 'payByNewCard.SET_SUBSCRIBE_BY_NEW_CARD_STATUS';
export const setSubscribeByNewCardStatus = createAction(SET_SUBSCRIBE_BY_NEW_CARD_STATUS);
