import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedString } from 'localization/localizer';
import SvgLoader from 'client/components/svg/SvgLoader';

const cartCallToAction = getLocalizedString('cart.callToAction');

export const CartLink = ({ children }) => {
  return (
    <a className="HeaderB_cart" aria-label="Basket" href="/cart" data-e2e="HeaderB_cartButton">
      {children}
    </a>
  );
};

const CartItem = ({ cart }) => {
  const itemsInBasket = cart.get('quantity');
  const cartQuantity = itemsInBasket > 99 ? '99+' : itemsInBasket.toString();

  return (
    <div className="HeaderB_cart-inner">
      <SvgLoader xlinkHref="#cartIcon-new"/>
      <span className="cartText">{cartCallToAction}</span>
      { itemsInBasket > 0 ? <span className="HeaderB_cartCount" data-e2e="cart-product-count">{cartQuantity}</span> : null }
    </div>
  );
};

CartItem.propTypes = {
  cart: PropTypes.object.isRequired
};

export default CartItem;
