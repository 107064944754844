import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { hideGlobalQuoteReminder } from 'client/actions/ui/dialogActions';
import { PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { getLocalizedString } from 'localization/localizer';
import { goToUrl } from 'client/actions/routeActions';

if (process.browser) {
  require('./globalQuotationReminderDialog.scss');
}

function mapStateToProps (state) {
  return {
    globalQuotationReminderIsVisible: state.getIn(['ui', 'dialogs', 'globalQuoteReminder', 'isVisible']),
    globalQuotationReminderNextUrl: state.getIn(['ui', 'dialogs', 'globalQuoteReminder', 'nextPageUrl']),
    quoteRef: state.getIn(['globalQuotation', 'createdQuotation', 'quoteRef'], '')
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideGlobalQuoteReminder: () => dispatch(hideGlobalQuoteReminder()),
    goToUrl: (url) => dispatch(goToUrl(url))
  };
}

const GlobalQuotationReminderDialog = (props) => {
  const { globalQuotationReminderIsVisible, globalQuotationReminderNextUrl, hideGlobalQuoteReminder, quoteRef, goToUrl } = props;
  const navigateAndHideModal = () => {
    hideGlobalQuoteReminder();
    goToUrl(globalQuotationReminderNextUrl);
  };
  return (
    <Modal className="GlobalQuotationReminderDialog" show={ globalQuotationReminderIsVisible }
      onHide={ hideGlobalQuoteReminder }>
      <Modal.Body>
        <div className="GlobalQuotationReminderDialog_body">
          <div>{ getLocalizedString('globalQuotation.importantReminder') }<b>{quoteRef}</b></div><br/>
          <div>{ getLocalizedString('globalQuotation.navigatingAway') }</div>
        </div>
      </Modal.Body>
      <div className="GlobalQuotationReminderDialog_actions">
        <PrimaryButton className="GlobalQuotationReminderDialog_actions-stayOnThisPageButton" text="Stay on this page" onClick={ hideGlobalQuoteReminder } />
        <PrimaryButton className="GlobalQuotationReminderDialog_actions-nextPage" text="Continue" onClick={ navigateAndHideModal } />
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalQuotationReminderDialog);
