import { getLocalizedString } from 'localization/localizer';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import {
  initialize,
  setNameOnCard,
  toggleSaveCardForFutureOrders,
  toggleShowAddressForm,
  updateHostedFieldsStatus
} from './payByNewCardActions';
import {
  BILLING_ADDRESS_FORM_NAME,
  CHECKOUT_SELECTOR
} from 'shared/constants/singlePageCheckout';
import {
  BRAINTREE_BADGE_URL,
  cards
} from 'shared/constants/braintree';
import { getCardSvgId } from 'client/utils/braintreeUtils';
import { Address } from '../shared/Address';
import { BraintreeField, Checkbox, TextInputField } from './cardFields';
import isEqual from 'lodash/isEqual';
import { checkInvalidField, formIsValid, isCardFormValid } from './utils';
import ContinueButton from './continueButton';
import { InfoCvv, InfoSaveCard } from '../shared/infoIcon';
import AddressForm from 'client/components/screens/checkoutScreen/element/addressForm/AddressForm';
import SvgLoader from 'client/components/svg/SvgLoader';
import { SecondaryButton } from 'client/components/controls/StyledForms';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { PAY_BY_EXISTING_CARD } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import {
  EXISTING_SUBSCRIPTION_SELECTOR,
  SUBSCRIPTION_SELECTOR
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { AddressList } from 'client/components/screens/checkoutScreen/accordionContent/DeliveryDetailsPanel';

if (process.browser) {
  require('./payByNewCard.scss');
}

export class PayByNewCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      addressFromAddressPicker: null
    };
  }

  updateHostedField = (emittedBy, isEmpty, isValid) => {
    const emittedStatusFromState = this.props.hostedFieldsStatus[emittedBy];
    const emittedStatusFromBraintree = { isEmpty, isValid };
    const isEqualEmit = isEqual(emittedStatusFromState, emittedStatusFromBraintree);
    if (!isEqualEmit) {
      this.props.updateHostedFieldsStatus({
        selector: this.props.selector,
        hostedFieldName: emittedBy,
        hostedFieldStatus: { isEmpty, isValid }
      });
    }
  };

  validityChangeHandler = (event) => {
    const { fields, emittedBy } = event;
    const field = fields[emittedBy];
    if (field) {
      const { isEmpty, isValid } = field;
      this.updateHostedField(emittedBy, isEmpty, isValid);
    }
  };

  onChangeDeliveryAddressFromAddressListDropDown = (pickedAddressId) => {
    const { addressPickerProps, selector, deliveryAddress, showAddressFormToggle, toggleShowAddressForm } = this.props;
    const showAddressForm = Object.keys(deliveryAddress).length === 0 ? true : showAddressFormToggle;

    const addressList = addressPickerProps.addressList.toJS();
    const pickedAddress = addressList.find(address => address.addressId === pickedAddressId);
    this.setState({
      addressFromAddressPicker: pickedAddress
    }, () => {
      if (!showAddressForm) {
        toggleShowAddressForm({ selector });
      } else {
        setTimeout((
        ) => {
          toggleShowAddressForm({ selector });
        }, 100);
        toggleShowAddressForm({ selector });
      }
    });
  }

  componentDidMount () {
    this.props.initialize({
      selector: this.props.selector
    });
  }
  componentWillUnmount () {
    this.props.hostedFieldsInstance && this.props.hostedFieldsInstance.off('validityChange', this.validityChangeHandler);
    this.props.hostedFieldsInstance && this.props.hostedFieldsInstance.off('blur', this.validityChangeHandler);
  }

  render () {
    const {
      subscriptionPayment,
      selector,
      deliveryAddress,
      showAddressFormToggle,
      toggleShowAddressForm,
      saveCardForFutureOrders,
      toggleSaveCardForFutureOrders,
      setNameOnCard,
      hostedFieldsInstance,
      hostedFieldsStatus,
      hasExistingCards,
      changePaymentMethod,
      billingAddressFormErrors,
      saveNewCardStatus,
      addressPickerProps,
      loadCart,
      addressId,
      hidePayByNewCardTitle
    } = this.props;
    hostedFieldsInstance && hostedFieldsInstance.on('validityChange', this.validityChangeHandler);
    hostedFieldsInstance && hostedFieldsInstance.on('blur', this.validityChangeHandler);
    const showAddressForm = Object.keys(deliveryAddress).length === 0 ? true : showAddressFormToggle;
    const continueDisabled = !isCardFormValid(hostedFieldsStatus) || (showAddressFormToggle && !formIsValid(billingAddressFormErrors));
    const isCheckout = selector === CHECKOUT_SELECTOR;
    const isExistingSubscriptionDetails = selector === EXISTING_SUBSCRIPTION_SELECTOR;
    const isProductSubscriptions = selector === SUBSCRIPTION_SELECTOR;
    const continueButtonText = isCheckout ? getLocalizedString('singlePageCheckout.continue') : getLocalizedString('mySubscriptions.button.save');

    const cardholderNameErrorMessage = (!hostedFieldsStatus.cardholderName.isValid && hostedFieldsStatus.cardholderName.isEmpty)
      ? getLocalizedString('payment.nameOnCard.required.message')
      : (!hostedFieldsStatus.cardholderName.isValid && !hostedFieldsStatus.cardholderName.isEmpty) ? getLocalizedString('payment.nameOnCard.Invalid.message') : '';
    const cvvErrorMessage = (!hostedFieldsStatus.cvv.isValid && hostedFieldsStatus.cvv.isEmpty)
      ? getLocalizedString('payment.security.code.required.message')
      : (!hostedFieldsStatus.cvv.isValid && !hostedFieldsStatus.cvv.isEmpty) ? getLocalizedString('payment.security.code.Invalid.message') : '';
    const cardNumberErrorMessage = (!hostedFieldsStatus.number.isValid && hostedFieldsStatus.number.isEmpty)
      ? getLocalizedString('payment.cardNumber.required.message')
      : (!hostedFieldsStatus.number.isValid && !hostedFieldsStatus.number.isEmpty) ? getLocalizedString('payment.cardNumber.Invalid.message') : '';
    const expirationDateErrorMessage = (!hostedFieldsStatus.expirationDate.isValid && hostedFieldsStatus.expirationDate.isEmpty)
      ? getLocalizedString('payment.expiration.date.required.message')
      : (!hostedFieldsStatus.expirationDate.isValid && !hostedFieldsStatus.expirationDate.isEmpty) ? getLocalizedString('payment.expiration.date.Invalid.message') : '';

    const scrollToInValidField = () => {
      const invalidField = checkInvalidField(hostedFieldsStatus);
      const payByNewCardId = document.getElementById('PayByNewCard_column');

      if (payByNewCardId && invalidField) {
        const isEmpty = hostedFieldsStatus[invalidField].isEmpty;
        this.updateHostedField(invalidField, isEmpty, false);
        return payByNewCardId.scrollIntoView({ block: 'nearest' });
      }

      const firstBillingFieldWithErrorId = Object.keys(billingAddressFormErrors || {}).find(field => {
        return !!billingAddressFormErrors[field];
      });

      if (firstBillingFieldWithErrorId) {
        const firstBillingFieldWithError = document.getElementById(firstBillingFieldWithErrorId);
        firstBillingFieldWithError.focus();
        return firstBillingFieldWithError.scroll();
      }
    };

    return (
      <div>
        <div className="PayByNewCard">
          <div className={ `${hidePayByNewCardTitle ? 'PayByNewCard_withSavedCardsList' : ''}` }>
            {!hidePayByNewCardTitle ? <div className="PayByNewCard_title">{getLocalizedString('payment.label.add.new.card')}</div>
              : null}
            <div className="PayByNewCard_small_text PayByNewCard_break">{getLocalizedString('payment.label.weAccept')}</div>

            <div className="PayByNewCard_break-small" data-e2e="PayByNewCard_cards_logo">
              {
                cards.map(card => (
                  <SvgLoader key={ card } xlinkHref={ getCardSvgId[card] } className="PayByNewCard_card_logo" />
                ))
              }
            </div>

            <img
              src={ BRAINTREE_BADGE_URL }
              className="PayByNewCard_braintree_logo PayByNewCard_break-small"
              aria-hidden="true"
              data-e2e="PayByNewCard_braintree_logo" alt="braintree" />

            <div>
              <div className="PayByNewCard_column" id="PayByNewCard_column">
                <BraintreeField
                  id="cc-number"
                  label={ getLocalizedString('payment.label.card.number') }
                  errorMessage={ cardNumberErrorMessage }
                  data-e2e="PayByNewCard_cc_number"/>
                <TextInputField
                  id="cc-name"
                  label={ getLocalizedString('payment.label.default.name.on.card') }
                  setNameOnCard={ setNameOnCard }
                  selector={ selector }
                  errorMessage={ cardholderNameErrorMessage }
                  data-e2e="PayByNewCard_cc_name"/>
              </div>
              <div className="PayByNewCard_row">
                <BraintreeField
                  id="cc-expiration"
                  label={ getLocalizedString('payment.label.expiration.date') }
                  errorMessage={ expirationDateErrorMessage } small
                  data-e2e="PayByNewCard_cc_expiration"/>
                <BraintreeField
                  id="cc-cvv"
                  label={ getLocalizedString('payment.label.security.code') }
                  errorMessage={ cvvErrorMessage } small
                  data-e2e="PayByNewCard_cc_cvv"/>
                <div className="PayByNewCard_cvv_info">
                  {InfoCvv}
                </div>
              </div>
            </div>

            { isCheckout ? <div className="PayByNewCard_save_card">
              <Checkbox
                toggle={ saveCardForFutureOrders }
                text={ getLocalizedString('payment.label.future.payments') }
                selector={ selector }
                onClick={ toggleSaveCardForFutureOrders }
                dataE2e="PayByNewCard_save_card_future_orders"
              />
              <div className="PayByNewCard_save_card-icon">
                {InfoSaveCard}
              </div>
            </div> : null }

            <div className="PayByNewCard_break PayByNewCard_title">{getLocalizedString('payment.label.billing.details')}</div>

            <Checkbox
              toggle={ !showAddressForm }
              text={ getLocalizedString('payment.label.sameAsDeliveryDetails') }
              selector={ selector }
              onClick={ toggleShowAddressForm }
              dataE2e="PayByNewCard_address_form_toggle"
            />

            <div className="PayByNewCard_address-picker">

              <AddressList addressList={ addressPickerProps.addressList }
                onChangeDeliveryAddressFromAddressListDropDown={ this.onChangeDeliveryAddressFromAddressListDropDown }
                loadCart = { loadCart }
                addressId = { addressId }
              />

            </div>

            <div className="PayByNewCard_break">
              {showAddressForm
                ? <AddressForm
                  isPayment={ true }
                  form={ BILLING_ADDRESS_FORM_NAME }
                  formName={ BILLING_ADDRESS_FORM_NAME }
                  address={ this.state.addressFromAddressPicker || deliveryAddress }
                  isEditingAddress={ true }
                  addressIdBeingEdited={ null }
                  showCta={ false }
                  data-e2e="PayByNewCard_address_form"
                />
                : !isProductSubscriptions ? <Address address={ deliveryAddress } data-e2e="PayByNewCard_address" /> : null
              }
            </div>

          </div>

        </div>
        {
          isCheckout || isExistingSubscriptionDetails
            ? hasExistingCards
              ? <div className={ `PayByNewCard_buttons ${isExistingSubscriptionDetails && 'PayByNewCard_buttons-sm'}` }>
                <SecondaryButton className="PayByNewCard_button" text="Cancel" data-e2e="PayByNewCard_cancel_button" onClick={ () => {
                  changePaymentMethod({
                    selector: selector,
                    paymentMethod: PAY_BY_EXISTING_CARD,
                    cancelled: true
                  });
                } } />
                <ContinueButton
                  saveNewCardStatus={ saveNewCardStatus }
                  selector={ selector }
                  continueDisabled={ continueDisabled }
                  text={ continueButtonText }
                  subscriptionId={ subscriptionPayment && subscriptionPayment.id }
                  scrollToInValidField = { scrollToInValidField }
                />
              </div>
              : <div className="PayByNewCard_button-single">
                <ContinueButton
                  saveNewCardStatus={ saveNewCardStatus }
                  selector={ selector }
                  continueDisabled={ continueDisabled }
                  text={ continueButtonText }
                  subscriptionId={ subscriptionPayment && subscriptionPayment.id }
                  scrollToInValidField = { scrollToInValidField }
                />
              </div>
            : null
        }
      </div>
    );
  }
}

const defaultHostedFieldState = {
  isEmpty: true,
  isValid: true
};
function mapStateToProps (state, ownProps) {
  const addresses = state.getIn(['user', 'address']).toJS();

  return {
    saveNewCardStatus: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'subscribe', 'status']),
    showAddressFormToggle: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'showAddressForm'], false),
    saveCardForFutureOrders: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'saveCardForFutureOrders'], false),
    hostedFieldsInstance: state.getIn(['braintree', 'hostedFields', 'instance']),
    hostedFieldsStatus: {
      cvv: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'hostedFields', 'cvv'], defaultHostedFieldState),
      number: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'hostedFields', 'number'], defaultHostedFieldState),
      expirationDate: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'hostedFields', 'expirationDate'], defaultHostedFieldState),
      cardholderName: state.getIn(['ui', 'payByNewCard', ownProps.selector, 'hostedFields', 'cardholderName'], defaultHostedFieldState)
    },
    deliveryAddress: addresses.delivery ? addresses.delivery : {},
    hasExistingCards: state.getIn(['braintree', 'cards'], List()).toJS().length !== 0,
    billingAddressFormErrors: state.getIn(['form', 'billingAddressForm', 'syncErrors'])
  };
}

const mapDispatchToProps = {
  initialize,
  toggleShowAddressForm,
  toggleSaveCardForFutureOrders,
  setNameOnCard,
  updateHostedFieldsStatus,
  changePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByNewCard);
