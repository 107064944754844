// This file should handle ALL interactions with the dataLayer object
import { takeLatest, call } from 'redux-saga/effects';
import { UPDATE_DATA_LAYER } from 'client/actions/dataLayerActions';
import { pushGeneralData, pushViewCart } from 'client/utils/googleTagManagerUtils';

export function * pushDataToDataLayer (action) {
  if (process.browser) {
    try {
      yield call(pushGeneralData, action.payload);
      if (action.payload && action.payload.ecommerce) {
        yield call(pushGeneralData, { event: 'ecommerce-updated' });
      }

      if (action.payload && action.payload.cart) {
        yield call(pushViewCart, action.payload.cart);
      }
    } catch (e) {
      // console.log(e);
    }
  }
}

export function * watchUpdateDataLayer () {
  yield takeLatest(UPDATE_DATA_LAYER, pushDataToDataLayer);
}
