import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideSavingQuoteDialog } from 'client/actions/ui/dialogActions';
import { PrimaryButton, PrimaryLink, SecondaryLink } from 'client/components/controls/StyledForms/formButtons';
import { fromJS } from 'immutable';
import {
  updateField,
  saveQuoteToMylist
} from 'client/actions/globalQuotationActions';
import { getLocalizedStringWithParam } from 'localization/localizer';
import { MIN_QUOTE_NAME_LENGTH } from 'shared/constants/quotation';

if (process.browser) {
  require('./saveQuoteDialog.scss');
}
const MAX_QUOTE_NAME_LENGTH = 15;
function mapStateToProps (state) {
  return {
    globalQuotation: state.getIn(['globalQuotation', 'createdQuotation'], fromJS({})),
    quoteToListName: state.getIn(['globalQuotation', 'quoteToListName'], ''),
    isVisibleSaveQuoteDialog: state.getIn(['ui', 'dialogs', 'saveQuoteToList', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideSavingQuoteDialog: () => dispatch(hideSavingQuoteDialog()),
    updateField: (value, fieldName) => dispatch(updateField(value, fieldName)),
    saveQuoteToMylist: (name, products) => dispatch(saveQuoteToMylist(name, products))
  };
}
const isInRange = (value, min, max) => value >= min && value <= max;

const saveGlobalQuote = (props) => {
  const {
    isVisibleSaveQuoteDialog,
    hideSavingQuoteDialog,
    globalQuotation,
    updateField,
    quoteToListName,
    saveQuoteToMylist
  } = props;
  const quoteRef = globalQuotation && globalQuotation.get('quoteRef');
  const saveQuoteToMylistWrapper = () => {
    const products = globalQuotation.get('products').toJS();
    const productsToSave = products.map(product => {
      return {
        sku: product.sku,
        qty: product.qty
      };
    });
    saveQuoteToMylist(quoteToListName, productsToSave);
  };

  return (
    <Modal className="SaveGlobalQuote" show={ isVisibleSaveQuoteDialog }
      onHide={ hideSavingQuoteDialog }>
      <Modal.Header className="SaveGlobalQuote_header" closeButton/>
      <Modal.Body>
        <div className="SaveGlobalQuote_body">
          <h2>Save this quotation <b>{quoteRef}</b> to my quotations list</h2>
          <div className="SaveGlobalQuote_saveQuote">
            <div>
              Quote name:
              <input type="text" placeholder="Name your quote" onChange={ (e) => updateField(e.target.value, 'quoteToListName') }/>
              <PrimaryButton
                text="Save quote"
                disabled={ quoteToListName.length > MAX_QUOTE_NAME_LENGTH || !quoteToListName.length }
                onClick={ () => saveQuoteToMylistWrapper(quoteToListName, quoteRef) }/>
            </div>
            {getErrorMessageFieldLength('Quote Name', quoteToListName, MIN_QUOTE_NAME_LENGTH, MAX_QUOTE_NAME_LENGTH)}
          </div>
        </div>
      </Modal.Body>
      <div className="SaveGlobalQuote_actions">
        <PrimaryLink isExternalLink={ true } className="SaveGlobalQuote_actions-viewQuotes" text="View Quotations" to="/my-account/quotations" onClick={ hideSavingQuoteDialog } />
        <SecondaryLink className="SaveGlobalQuote_actions-continue" text="Continue Shopping" onClick={ hideSavingQuoteDialog } />
      </div>
    </Modal>
  );
};
const getErrorMessageFieldLength = (field, fieldValue, minLength, maxLength) => {
  if (!fieldValue?.length || isInRange(fieldValue.length, minLength, maxLength)) {
    return null;
  }
  return (<small className="globalQuotationContainer_saveQuote-invalid">
    { fieldValue.length < minLength ? getLocalizedStringWithParam('error.validation.minLength', { field, minLength }) : null }
    { fieldValue.length > maxLength ? getLocalizedStringWithParam('error.validation.maxLength', { field, maxLength }) : null }
  </small>);
};
export default connect(mapStateToProps, mapDispatchToProps)(saveGlobalQuote);
