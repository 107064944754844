'use strict';

import { fromJS } from 'immutable';
import {
  RESET_REGISTER_STATE,
  REQUEST_REGISTER_USER,
  RECIEVE_LOGIN_USER_SUCCESS,
  RECIEVE_REGISTER_USER_ERROR,
  COMING_FROM_CART_PAGE,
  COMING_FROM_GLOBAL_QUOTE
} from 'client/actions/userActions';

export const defaultState = fromJS({ registerButtonStatus: 'readyToSubmit', registrationError: null, isUserComingFromCartPage: false });

const registrationFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case COMING_FROM_CART_PAGE:
      return state.set('isUserComingFromCartPage', action.flag);
    case COMING_FROM_GLOBAL_QUOTE:
      return state.set('isUserComingFromGlobalQuote', action.flag);
    case RESET_REGISTER_STATE:
      return defaultState;
    case REQUEST_REGISTER_USER:
      return state.set('registrationError', null)
        .set('requestRegisterUser', true);
    case RECIEVE_LOGIN_USER_SUCCESS:
      return state.set('registrationError', null);
    case RECIEVE_REGISTER_USER_ERROR:
      return state.set('registrationError', action.error);
    default:
      return state;
  }
};

export default registrationFormReducer;
