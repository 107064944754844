import { BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE, SHOW, HIDE } from 'shared/constants/abTesting';

export const getToggle = (toggles, feature, defaultValue, overrideProps = {}) => {
  const { feature: overrideFeature, variant: overrideVariant, payload: overridePayload } = overrideProps;
  if (overrideFeature && overrideFeature === feature) {
    return {
      value: { name: overrideFeature, variant: { name: overrideVariant, payload: { value: overridePayload } } },
      isToggleFound: true,
      isOverride: true
    };
  }
  const value = toggles?.find(toggle => {
    return toggle.get ? toggle?.get('name') === feature : toggle?.name === feature;
  });
  return {
    value: value || defaultValue,
    isToggleFound: !!value,
    isOverride: false
  };
};

export const getVariant = (allToggles = [], experimentName, defaultValue, overrideProps = {}) => {
  const { feature: overrideFeature, variant: overrideVariant } = overrideProps;
  if (overrideFeature && overrideFeature === experimentName) {
    return overrideVariant;
  }
  const toggleFound = allToggles.find((toggle) => toggle.name === experimentName);
  return toggleFound?.variant?.enabled
    ? toggleFound?.variant?.name
    : defaultValue;
};

export const prepareUnleashOverridePayload = (query, overridePayload) => {
  const { unl_feature: feature, unl_variant: variant, unl_payload: payload } = query;
  const overrideValue = overridePayload?.getIn(['unl_payload', 'value']);
  return feature && variant ? { feature, variant, payload: payload || overrideValue } : {};
};

export const getUnleashDataLayerParams = (experiment, allToggles, context, query, defaultVariant) => {
  const overrideProps = prepareUnleashOverridePayload(query);
  const toggle = getToggle(allToggles, experiment, defaultVariant, overrideProps);
  return ({
    event: 'Unleash_ABTest_Set',
    experiment,
    variant: toggle?.value?.variant?.name || defaultVariant,
    context
  });
};

export const getCheckoutProductCountAbTestThreshold = (allToggles = [], type) => {
  const toggleFound = allToggles.find((toggle) => toggle.name === BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE);
  const variantPayload = toggleFound?.variant?.name === type && toggleFound?.variant?.payload?.value ? JSON.parse(toggleFound?.variant?.payload?.value) : '';

  if (!toggleFound?.enabled || !variantPayload) {
    return 0;
  }
  return variantPayload?.minimumThreshold;
};

export const getCheckoutProductCountAbTestActive = (allToggles = []) => {
  const toggleFound = allToggles.find((toggle) => toggle.name === BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE);
  return toggleFound?.variant?.name === SHOW || toggleFound?.variant?.name === HIDE;
};
