import { quotationCalculator } from 'client/utils/quotationUtils';

export const getIsReadyToPay = async (paymentsClient, paymentRequest) => {
  try {
    const response = await paymentsClient.isReadyToPay(paymentRequest);
    return response.result;
  } catch (error) {
    return false;
  }
};

export const getGoogleIsReadyToPayRequest = (googlePayInstance) => {
  const cardPaymentMethod = getPaymentMethodConfig(googlePayInstance.createPaymentDataRequest());
  return {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [cardPaymentMethod],
    existingPaymentMethodRequired: false
  };
};

export const getGooglePaymentRequest = (googlePayInstance, transactionInfo) => {
  const cardPaymentMethod = getPaymentMethodConfig(googlePayInstance.createPaymentDataRequest());
  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo
  };
  return googlePayInstance.createPaymentDataRequest(paymentRequest);
};

export const getPaymentMethodConfig = (paymentDataRequest) => {
  const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
  return {
    ...cardPaymentMethod,
    parameters: {
      ...cardPaymentMethod.parameters,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true
      }
    }
  };
};

export const getCartTotalPriceWithQuotation = (cartTotal, quotation) => {
  if (!quotation) {
    return cartTotal.cartGrandTotalWithVat;
  }
  const { totalPriceWithVat } = quotationCalculator(quotation);
  return totalPriceWithVat;
};
