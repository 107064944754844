import compact from 'lodash/compact';
import flow from 'lodash/flow';
import get from 'lodash/get';
import transform from 'lodash/transform';
import { tryCatch, tryCatchContainer } from './tryCatchUtils';

/**
 *  Parse result from API request into compatible format for Autosuggest
 */

const DEFAULT_ORDER = ['s', 'c', 'b', 'p'];
const DEFAULT_ORDER_CONCAT = 's, c, b, p';

/**
 * Use data from order (e.g. DEFAULT_ORDER) as lookup key to access section props
 */
const validSuggestionSections = {
  's': {
    id: 'suggestions',
    title: 'Suggestions',
    column: 1,
    createAutosuggestProps: (results) => results.map((result) => ({ text: get(result, 'text', '') }))
  },
  'c': {
    id: 'category',
    title: 'Categories',
    column: 1,
    createAutosuggestProps: (results) => results.map((result) => ({
      text: get(result, 'text', ''),
      params: {
        label: get(result, 'payload.categoryName', ''),
        id: get(result, 'payload.categoryId', '')
      }
    }))
  },
  'b': {
    id: 'brand',
    title: 'Brands',
    column: 1,
    createAutosuggestProps: (results) => results.map((result) => ({
      brandName: get(result, 'payload.brandName', ''),
      text: get(result, 'text', ''),
      params: {
        brandId: get(result, 'payload.brandId', ''),
        ...get(result, 'payload', '')
      }
    }))
  },
  'p': {
    id: 'products',
    title: 'Top Products',
    column: 2,
    createAutosuggestProps: (results) => results.map((result) => ({
      productName: get(result, 'payload.productName', ''),
      text: get(result, 'text', ''),
      params: {
        ...get(result, 'payload', '')
      }
    }))
  }
};

const lowerCase = (value) => value.toLowerCase();
const removeSpaces = (value) => value.replace(/ /g, '');
const splitAtCommas = (value) => value.split(',');
const createOrder = (order) => order.reduce((acc, type) => {
  const props = Object.assign({}, validSuggestionSections[type]);
  acc[props.id] = props;
  return acc;
}, {});

const getSuggestionOrder = (order = '') =>
  tryCatch(() => lowerCase(order) || DEFAULT_ORDER_CONCAT)
    .chain(tryCatchContainer(removeSpaces))
    .chain(tryCatchContainer(splitAtCommas))
    .fold((error) => {
      try {
        console.log('Error Getting AB Suggestions Order (using default order instead)', 'warning', { // eslint-disable-line no-console
          custom: { error }
        });
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }

      return DEFAULT_ORDER;
    }, (value) => value);

const putSectionsInOrder = flow(getSuggestionOrder, createOrder);

export default function (suggestions, options = {}) {
  const { order } = options;

  const suggestionSections = putSectionsInOrder(order);

  const transformToAutosuggest = (result, searchResults, key) => {
    const type = suggestionSections[key];

    if (!type) {
      return null;
    }

    const suggestionResults = type.createAutosuggestProps(searchResults);

    result[key] = {
      title: type.title,
      type: key,
      column: type.column,
      results: compact(suggestionResults)
    };

    return result;
  };

  const transformSuggestions = (suggestions) => transform(suggestions, transformToAutosuggest);
  const convertToArray = (resultSoFar) => Object.keys(suggestionSections).map((key) => resultSoFar[key]);

  return flow(transformSuggestions, convertToArray, compact)(suggestions);
}
