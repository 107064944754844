import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideEditRepeatOrderDialog } from '../../../actions/ui/dialogActions';
import { requestEditRepeatOrderConfirm } from '../../../actions/repeatOrdersActions';
import toTitleCase from 'to-title-case';

if (process.browser) {
  require('./RemoveRepeatOrderDialog.scss');
}

function mapStateToProps (state) {
  return {
    EditRepeatOrderDialogIsVisible: state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'isVisible']),
    repeatOrderReference: state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'repeatOrderReference']),
    newRegularity: state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'newRegularity']) ? toTitleCase(state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'newRegularity'])) : '',
    repeatOrderId: state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'repeatOrderId']),
    endDate: state.getIn(['ui', 'dialogs', 'editRepeatOrder', 'endDate'], false)
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideEditRepeatOrderDialog: flowRight(dispatch, hideEditRepeatOrderDialog),
    requestEditRepeatOrderConfirm: flowRight(dispatch, requestEditRepeatOrderConfirm)
  };
}

const EditRepeatOrderDialog = (props) => {
  const {
    EditRepeatOrderDialogIsVisible,
    repeatOrderReference,
    newRegularity,
    requestEditRepeatOrderConfirm,
    hideEditRepeatOrderDialog,
    endDate
  } = props;

  return (
    <Modal className="RemoveRepeatOrdersDialog" show={ EditRepeatOrderDialogIsVisible }
      onHide={ hideEditRepeatOrderDialog }>
      <Modal.Header className="RemoveRepeatOrderDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          { endDate
            ? <div>Are you sure you want to change the end date of your subscription - {repeatOrderReference} to {endDate} ?<br /></div>
            : <div>Are you sure you want to change the regularity of your subscription - {repeatOrderReference} to {newRegularity} ?<br /></div>
          }
            Please note: this action would change date of delivery for your next orders .

        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            onClick={ hideEditRepeatOrderDialog }>
                        Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={ requestEditRepeatOrderConfirm }>
             Confirm
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

EditRepeatOrderDialog.propTypes = {
  removeRepeatOrderDialogIsVisible: PropTypes.bool,
  requestEditRepeatOrderConfirm: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRepeatOrderDialog);
