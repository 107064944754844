import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import flowRight from 'lodash/flowRight';
import { setCancelReturnConfirmationDialog } from 'client/actions/ui/dialogActions';
import { getLocalizedString } from 'localization/localizer';
import { SecondaryLink, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { gotoExternalUrl } from 'client/actions/routeActions';
import { MY_ORDERS_URL } from 'shared/constants/common';

if (process.browser) { require('./cancelReturnConfirmationDialog.scss'); }

const CancelReturnConfirmationDialog = ({
  hideCancelReturnConfirmationDialog, isVisible, dispatchGotoExternalUrl
}) => {
  const onClickHandler = () => {
    hideCancelReturnConfirmationDialog(!isVisible);
  };
  const secondaryOnClickHandler = () => {
    hideCancelReturnConfirmationDialog(!isVisible);
    dispatchGotoExternalUrl(MY_ORDERS_URL);
  };

  return (
    <Modal className="CancelReturnConfirmationDialog_Modal" show={ isVisible } onHide={ onClickHandler }>
      <Modal.Header closeButton>
        <div className="CancelReturnConfirmationDialog_Modal__hgroup">
          <h4>
            { getLocalizedString('ui.dialog.cancelReturnConfirmationDialog.title') }
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          { getLocalizedString('ui.dialog.cancelReturnConfirmationDialog.message') }
        </p>
        <div>
          <PrimaryButton
            text={ getLocalizedString('ui.dialog.backEditing') }
            onClick={ onClickHandler } />
          <SecondaryLink
            id="ProductAddToCart_Modal.goToCartBtn"
            text={ getLocalizedString('ui.dialog.leave') }
            onClick={ secondaryOnClickHandler }
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'returnRefund', 'isVisible'])
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideCancelReturnConfirmationDialog: flowRight(dispatch, setCancelReturnConfirmationDialog),
  dispatchGotoExternalUrl: (url) => dispatch(gotoExternalUrl(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelReturnConfirmationDialog);
