import curry from 'lodash/curry';
import flowRight from 'lodash/flowRight';
import mergeWith from 'lodash/mergeWith';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import { parse, format } from 'url';

const QUERY_KEYS_FOR_INDEXING = ['query', 'familiesDisplayed', 'familySkip', 'page', 'flag'];
const BRAND_PATH = 'brand';
const SHOP = 'shop';
const urlAttributeSeparator = 'a';

const toLowerCase = (str) => str.toLowerCase ? str.toLowerCase() : str;
const replaceAll = curry((newSubStr, matchExp, origStr) => origStr.replace(new RegExp(matchExp, 'g'), newSubStr));
const replaceWithDash = replaceAll('-');
const replaceWithEmpty = replaceAll('');
const stringifyIfEmpty = (x) => x || '';

export const urlifyText = flowRight(
  encodeURIComponent,
  toLowerCase,
  replaceAll('inch', /[\"]/), // eslint-disable-line no-useless-escape
  replaceWithDash(/[-]+/),
  replaceWithDash(/[\,\.\s\/]/), // eslint-disable-line no-useless-escape
  replaceAll('and', /\&/), // eslint-disable-line no-useless-escape
  replaceWithEmpty(/[()®]/),
  stringifyIfEmpty
);

export const buildCategoryUrl = function buildCategoryUrl (parentCategory, slug, nextCategory, nextCategoryId, filters, sortBy) {
  if (!nextCategoryId) {
    throw new Error('nextCategoryId must be supplied');
  }

  const baseUrl = `/shop/${urlifyText(parentCategory)}`;

  const newSlug = slug ? `${slug}/` : '';
  const nextUrl = nextCategory
    ? `${baseUrl}/${urlifyText(nextCategory)}/${newSlug}${nextCategoryId}`
    : `${baseUrl}/${nextCategoryId}`;

  const queryObj = sortBy ? { ...filters, sortBy: sortBy } : filters;
  return getUrlWithQuery(nextUrl, queryObj);
};

export const buildTopSearchUrl = (query) => {
  const url = parse('/shop');
  url.query = query;
  return format(url);
};

const buildUrl = curry((type, query, parentCategory, subCategory, name, id) => {
  const { ...validQuery } = query;
  const url = `/shop/${urlifyText(parentCategory)}/${urlifyText(subCategory)}/${urlifyText(name)}/${type}/${id}`;
  return getUrlWithQuery(url, validQuery);
});

export const buildFamilyUrl = buildUrl('f');
export const buildProductUrl = buildUrl('p');

export const parseSearchPageUrl = (urlParams, pathname) => {
  const {
    parentCategory,
    subCategory,
    id: categoryId
  } = urlParams;

  const formattedUrlAttributes = getDecodedAttrFiltersObj(pathname);
  return {
    categoryId,
    parentCategory,
    subCategory,
    ...formattedUrlAttributes
  };
};

export const addFiltersToUrlPath = (searchUrlWithCategory, sortedFilters) => {
  return sortedFilters.reduce((acc, attr) => {
    const encodedAttributeValues = attr.attrValues.map((value) => encodeURIComponent(value));
    return `${acc}/${encodeURIComponent(attr.attrName)}/${encodedAttributeValues.join('__')}`;
  }, `${searchUrlWithCategory}/${urlAttributeSeparator}`);
};

export const sortAttributeFilters = (urlFiltersObj) => {
  return Object.keys(urlFiltersObj).sort((a, b) => a.localeCompare(b))
    .map(
      (attrName) => ({ attrName, attrValues: urlFiltersObj[attrName].sort((a, b) => a.localeCompare(b)) })
    );
};

export const getSearchPageUrlWithFilters = (searchUrlWithCategory, urlFiltersObj, urlQuery) => {
  const sortedAttrFiltersArray = sortAttributeFilters(urlFiltersObj);
  const urlWithAttributes = sortedAttrFiltersArray.length ? addFiltersToUrlPath(searchUrlWithCategory, sortedAttrFiltersArray) : searchUrlWithCategory;
  return getUrlWithQuery(urlWithAttributes, urlQuery);
};

export const searchPageUrlWithBrand = (searchUrlWithCategory, brandName, parentCategory, categoryId) => {
  const isParentAndSubCategory = parentCategory && categoryId;
  return `${searchUrlWithCategory}/${isParentAndSubCategory ? `${parentCategory}/${categoryId}/` : ''}${urlAttributeSeparator}/${BRAND_PATH}/${encodeURIComponent(brandName)}`;
};

export const buildSearchPageUrl = (categoryId, parentCategory, subCategory, urlFilters = {}, urlQuery) => {
  const searchUrl = `/${SHOP}`;
  const searchUrlWithCategory = categoryId
    ? `${searchUrl}/${urlifyText(parentCategory)}${subCategory ? `/${urlifyText(subCategory)}` : ''}/${categoryId}`
    : searchUrl;
  return getSearchPageUrlWithFilters(searchUrlWithCategory, urlFilters, urlQuery);
};

export function getUrlWithFilters (location, urlParams, newQuery = {}, overrideSearchQuery) {
  const urlQuery = { ...location.query };

  const urlInformation = parseSearchPageUrl(urlParams, location.pathname);

  const {
    categoryId,
    parentCategory,
    subCategory,
    ...restUrlFilters
  } = urlInformation;

  mergeWith(restUrlFilters, newQuery, (currentUrlFilterValue, newQueryValue) => {
    if (newQuery.price === '') {
      return null;
    }

    if (newQuery.price) {
      return [newQueryValue];
    }

    if (!currentUrlFilterValue) {
      return [newQueryValue];
    }

    const currentUrlFilterLength = currentUrlFilterValue.length;

    // If filter is already there in url then below condition returns obj without that filter i.e url generated will be without that filter
    if (currentUrlFilterLength === 1 &&
      currentUrlFilterValue[0] === newQueryValue
    ) {
      return null;
    }

    if (currentUrlFilterLength > 1 && currentUrlFilterValue.includes(newQueryValue)) {
      return without(currentUrlFilterValue, newQueryValue);
    }

    return [...currentUrlFilterValue, newQueryValue];
  });

  const newUrlFilters = omitBy(restUrlFilters, isEmpty);

  if (overrideSearchQuery) {
    urlQuery.query = overrideSearchQuery;
  }

  return buildSearchPageUrl(categoryId, parentCategory, subCategory, newUrlFilters, urlQuery);
}

export const isSearchUrlWithoutCategory = (urlParams) => {
  const {
    parentCategory,
    id: categoryId
  } = urlParams;
  return !(categoryId && parentCategory);
};

export function buildUrlWithQuery (url, queries) {
  return queries && queries.filter(Boolean).length > 0
    ? url + '?' + queries.filter(Boolean).join('&')
    : url;
}

export const destinationPath = (path) => {
  return path !== '/zoro-business-account/apply' ? path : '/';
};

export const getAttributesArrayFromPath = (pathname) => {
  const filtersRegex = /a\/(.*)/;
  const filtersString = pathname.match(filtersRegex) ? pathname.match(filtersRegex)[1] : '';
  return filtersString ? filtersString.split('/') : [];
};

export const prepareAttrFiltersForRequest = (pathname) => {
  const attrParams = getAttributesArrayFromPath(pathname);
  if (attrParams.length % 2 !== 0) return {};
  return attrParams.reduce((acc, attrName, attrIndex, attributesArray) => {
    if (attrIndex % 2 === 0) {
      acc[attrName] = replaceAll(',', '__', attributesArray[attrIndex + 1]);
    }
    return acc;
  }, {});
};

export const getDecodedAttrFiltersObj = (pathname) => {
  const attrParams = getAttributesArrayFromPath(pathname);
  if (attrParams.length % 2 !== 0) return {};
  return attrParams.reduce((acc, attrName, attrIndex, attributesArray) => {
    if (attrIndex % 2 === 0) {
      acc[decodeURIComponent(attrName)] = decodeURIComponent(attributesArray[attrIndex + 1]).split('__');
    }
    return acc;
  }, {});
};

export const getNumberOfAttributeFiltersApplied = (pathname) => {
  const attributesKeyValueArray = getAttributesArrayFromPath(pathname);
  const attributesFiltersArray = attributesKeyValueArray.reduce((acc, attrName, attrIndex) => {
    if (attrIndex % 2 !== 0) {
      acc.push(...attrName.split('__'));
    }
    return acc;
  }, []);
  return attributesFiltersArray.length;
};

export const getUrlWithQuery = (url, queryObject = {}) => {
  const queryString = Object.keys(queryObject).map((key) => (
    `${key}=${encodeURIComponent(queryObject[key])}`))
    .join('&')
    .replace(/%20/g, '+');
  return `${url}${queryString ? `?${queryString}` : ''}`;
};

export const getUrlWithQueryString = (url, queryObject = {}) => {
  const queryString = Object.keys(queryObject).map((key) => (
    `${key}=${queryObject[key]}`))
    .join('&');
  return `${url}${queryString ? '?' + queryString : ''}`;
};

export const getContentRobotMetatag = (location, parentCategoryName) => {
  const hasSearchQuery = !!location.query.query;
  const isShopPage = location.pathname === '/shop';
  const isEmtyQuery = location.query.query === '';
  const isSearchWithEmptyQueryResultsPage = isShopPage && isEmtyQuery;

  if (isSearchWithEmptyQueryResultsPage) {
    return 'NOINDEX';
  }

  const hasSearchQueryAndParentCategory = hasSearchQuery && !!parentCategoryName;
  const isShopPageWithFlag = isShopPage && location.search === '?flag=1';
  const attributesFilterCount = getNumberOfAttributeFiltersApplied(location.pathname);
  const hasSearchQueryWithFilters = hasSearchQuery && attributesFilterCount > 0;
  const hasMultipleSelectedFilter = attributesFilterCount > 1;

  if (hasSearchQueryAndParentCategory || isShopPageWithFlag || hasSearchQueryWithFilters || hasMultipleSelectedFilter) {
    return 'NOINDEX, NOFOLLOW';
  }

  const urlQueryKeys = Object.keys(location.query);
  const shouldIndex = urlQueryKeys.length === 0 || urlQueryKeys.every(queryKey => QUERY_KEYS_FOR_INDEXING.includes(queryKey));

  if (!shouldIndex) {
    return 'NOINDEX';
  }

  return 'all';
};
