import SearchBar from 'client/components/elements/SearchBar/SearchBar';
import React from 'react';
import { connect } from 'react-redux';
import { toggleElement } from 'client/actions/ui/toggleElementActions';

export const NAV_SEARCH_FIELD = 'NAV_SEARCH_FIELD';

const SearchBarItem = ({ searchFieldIsVisible, location }) => {
  const TERM_TYPE = 'term';
  return (<div className="HeaderB_nav_search">
    <div className={ `HeaderB_nav_searchBar ${searchFieldIsVisible ? 'appear-from-top' : ''}` }>
      <SearchBar termType={ TERM_TYPE } location = { location } />
    </div>
  </div>);
};

const mapStateToProps = (state, myProps) => {
  const location = (myProps && myProps.location) ? myProps.location : null;
  return {
    location
  };
};

const mapDispatchToProps = (dispatch) => {
  return { toggleElement: (payload) => dispatch(toggleElement(payload)) };
};

export { SearchBarItem };

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarItem);
