import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import flowRight from 'lodash/flowRight';
import { hideAddToBasketDialog } from 'client/actions/ui/dialogActions';
import { getLocalizedString } from 'localization/localizer';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) { require('./AddToBasketDialog.scss'); }

export class AddToBasketDialog extends React.Component {
  render () {
    const { hideAddToBasketDialog, isVisible, productImageUrl, productName } = this.props;
    return (
      <Modal className="AddToBasket_Modal" show={ isVisible } onHide={ hideAddToBasketDialog }>
        <Modal.Header closeButton>
          <ZoroImage
            src={ productImageUrl }
            alt={ productName }
            className="AddToBasket_Modal__image"
          />
          <div className="AddToBasket_Modal__hgroup">
            <Modal.Title>{ productName }</Modal.Title>
            <h4>{ getLocalizedString('product.addToBasket.hasBeenAddedToBasket') }</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <a
            id="ProductAddToCart_Modal.goToCartBtn"
            href="/cart"
            onClick={ hideAddToBasketDialog }
            className="t-go-to-basket-btn ProductAddToCart_Modal_goToCartBtn btn btn-md btn-primary">
            { getLocalizedString('product.addToBasket.goToBasket') }
          </a>
          <button
            type="button"
            className="btn btn-default t-continue-btn"
            onClick={ hideAddToBasketDialog }>
            { getLocalizedString('product.addToBasket.continueShopping') }
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps (state) {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'addToBasket', 'isVisible']),
    productImageUrl: state.getIn(['ui', 'dialogs', 'addToBasket', 'product', 'img']),
    productName: state.getIn(['ui', 'dialogs', 'addToBasket', 'product', 'productName'])
  };
}

const mapDispatchToProps = (dispatch) => ({
  hideAddToBasketDialog: flowRight(dispatch, hideAddToBasketDialog)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToBasketDialog);
