import React from 'react';
import CartItem from './CartItem';

const DropdownCart = ({ cart, isBasketConfirmationDropdown, dispatchToggleBasketConfirmationDropdown }) => {
  return (<>
    <button
      type="button"
      data-e2e="HeaderB_cartDropdownButton"
      className="btn nav-link nav-link-quickorder"
      onClick={ () => dispatchToggleBasketConfirmationDropdown(!isBasketConfirmationDropdown) }>
      <CartItem cart={ cart }/>
    </button>
  </>
  );
};

export default DropdownCart;
