export const SINGLE_PAGE_CHECKOUT_ROUTE = '/checkout/order';
export const DELIVERY_ADDRESS_ACCORDION_PANEL_ID = '1';
export const PAYMENT_ACCORDION_PANEL_ID = '2';
export const CONFIRM_PAYMENT_DETAILS_BUTTON_ID = '4';
export const BILLING_ADDRESS_ACCORDION_PANEL_ID = '3';
export const ORDER_REVIEW_ACCORDION_PANEL_ID = '3';
export const SINGLE_PAGE_CHECKOUT = 'singlePageCheckout';
export const SINGLE_PAGE_PAYMENT = 'singlePagePayment';
export const BILLING_ADDRESS_REQUIRED = 'BILLING_ADDRESS_REQUIRED';
export const DELIVERY_ADDRESS_REQUIRED = 'DELIVERY_ADDRESS_REQUIRED';
export const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED';
export const DELIVERY_ADDRESS_AND_BILLING_ADDRESS_REQUIRED = 'DELIVERY_ADDRESS_AND_BILLING_ADDRESS_REQUIRED';
export const DELIVERY_ADDRESS_AND_PAYMENT_REQUIRED = 'DELIVERY_ADDRESS_AND_PAYMENT_REQUIRED';
export const BILLING_ADDRESS_AND_PAYMENT_REQUIRED = 'BILLING_ADDRESS_AND_PAYMENT_REQUIRED';
export const DELIVERY_ADDRESS_BILLING_ADDRESS_AND_PAYMENT_REQUIRED = 'DELIVERY_ADDRESS_BILLING_ADDRESS_AND_PAYMENT_REQUIRED';
export const POST_ORDER_FAILED = 'POST_ORDER_FAILED';
export const THREE_D_SECURE_FAILED = 'THREE_D_SECURE_FAILED';
export const DELIVERY_ADDRESS_FORM_NOT_SAVED = 'DELIVERY_ADDRESS_FORM_NOT_SAVED';
export const BILLING_ADDRESS_FORM_NOT_SAVED = 'BILLING_ADDRESS_FORM_NOT_SAVED';
export const CONFIRM_BILLING_ADDRESS = 'CONFIRM_BILLING_ADDRESS';
export const SAVED_PAYPAL_ACCOUNT_SHIPPING_DETAILS_CHANGED = 'SAVED_PAYPAL_ACCOUNT_SHIPPING_DETAILS_CHANGED';
export const SAVED_CARD_CVV_VERIFICATION_FAILED = 'SAVED_CARD_CVV_VERIFICATION_FAILED';
export const SELECT_PAYMENT_OPTION = 'SELECT_PAYMENT_OPTION';
export const SUBSCRIPTION_SELECTOR = 'SUBSCRIPTION_SELECTOR';
export const ADDRESSBOOK_GRID_VIEW = 'gridView';
export const ADDRESSBOOK_LIST_VIEW = 'listView';
export const DEFAULT_ADDRESSBOOK_ACTIVE_PAGE_ID = 1;
export const DELIVERY_ADDRESS_FORM_NAME = 'deliveryAddressForm';
export const BILLING_ADDRESS_FORM_NAME = 'billingAddressForm';
export const DELIVERY_ADDRESS_TYPE = 'delivery';
export const BILLING_ADDRESS_TYPE = 'billing';
export const CHECKOUT_SELECTOR = 'SINGLE_PAGE_CHECKOUT';
export const BUSINESS_APPLICATION_ROUTE = '/zoro-business-account/apply';
export const QUOTATION_ROUTE = '/global-quotation';
export const CART_ROUTE = '/cart';
export const DELIVERY_CHECKOUT_ROUTE = '/recommendations-before-checkout';
export const BUSINESS = 'Business';
export const PERSONAL = 'Personal';
export const LOGIN_ROUTE = '/login';
