import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemoveProductRepeatOrderDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveProductRepeatOrderConfirm } from '../../../actions/repeatOrdersActions';

if (process.browser) {
  require('./RemoveRepeatOrderDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeProductRepeatOrderDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeRepeatOrderProduct', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveProductRepeatOrderDialog: flowRight(dispatch, hideRemoveProductRepeatOrderDialog),
    requestRemoveProductRepeatOrderConfirm: flowRight(dispatch, requestRemoveProductRepeatOrderConfirm)
  };
}

const RemoveProductRepeatOrder = (props) => {
  const {
    removeProductRepeatOrderDialogIsVisible,
    requestRemoveProductRepeatOrderConfirm,
    hideRemoveProductRepeatOrderDialog
  } = props;

  return (
    <Modal className="RemoveRepeatOrdersDialog" show={ removeProductRepeatOrderDialogIsVisible }
      onHide={ hideRemoveProductRepeatOrderDialog }>
      <Modal.Header className="RemoveRepeatOrderDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content text-center">
            Are you sure you want to delete this product from order?
        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            onClick={ hideRemoveProductRepeatOrderDialog }>
                        Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={ requestRemoveProductRepeatOrderConfirm }>
                        Remove
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveProductRepeatOrder);
