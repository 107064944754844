import React from 'react';
import { Link } from 'react-router';
import { getLocalizedString } from 'localization/localizer';
import SvgLoader from '../../svg/SvgLoader';

if (process.browser) {
  require('./GlobalQuotationButton.scss');
}

class GlobalQuotationButton extends React.PureComponent {
  render () {
    const { cssClass, quoteRef, numberOfQuotationProducts, locationHomepage, clearQuote, shouldQuotesClear } = this.props;

    const getTotalQunatity = () => numberOfQuotationProducts && numberOfQuotationProducts
      .reduce((sum, quotationProduct) => quotationProduct.get('qty') + sum, 0);
    const goToQuotation = () => {
      if (shouldQuotesClear) {
        clearQuote();
      }
    };

    return <Link className={ `${cssClass} globalQuotationButton${locationHomepage ? '_homePage' : ''}` } to="/global-quotation" data-e2e="quotation-button" onClick={ () => goToQuotation() }>
      <SvgLoader xlinkHref="#quotation-header-icon-new" className="quotation-icon" />
      {!locationHomepage ? (<>
        <span className='hidden-xs hidden-sm'>
          { getLocalizedString('myAccount.quotations.title') }
        </span>
      </>) : 'Quotation'}

      { numberOfQuotationProducts.size && !quoteRef && !locationHomepage
        ? <span className="globalQuotationButton_counter" data-e2e="globalQuotationButton_counter">
          { getTotalQunatity() }
        </span> : null }
    </Link>;
  }
}

export default GlobalQuotationButton;
