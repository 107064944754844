import { fromJS } from 'immutable';
import { REQUEST_CREDIT_LIMIT_INCREASE, REQUEST_CREDIT_LIMIT_INCREASE_SUCCESS, REQUEST_CREDIT_LIMIT_INCREASE_FAILURE } from './RequestCreditLimitActions';

const initialState = {
  data: {},
  loading: false,
  error: null
};
const defaultState = fromJS(initialState);

const requestCreditLimit = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_CREDIT_LIMIT_INCREASE:
      return state.set('loading', true).set('error', null);
    case REQUEST_CREDIT_LIMIT_INCREASE_SUCCESS:
      return state.set('data', fromJS(action.payload)).set('loading', false);
    case REQUEST_CREDIT_LIMIT_INCREASE_FAILURE:
      return state.set('error', fromJS(action.payload)).set('loading', false);
    default:
      return state;
  }
};

export default requestCreditLimit;
