import React from 'react';

import { getLocalizedString } from 'localization/localizer';

const FreeDeliveryShortfallMessage = ({ freeDeliveryShortfall, isSinglePageCheckout }) => {
  const [ leftSubString, rightSubString ] = getLocalizedString('cart.cartTotalizer.fieldLabels.freeDeliveryShortfall').split('{amount}');
  const priceWithCurrency = `£${freeDeliveryShortfall.toFixed(2)}`;

  return isSinglePageCheckout
    ? [ leftSubString, <strong>{`${priceWithCurrency}`}</strong>, rightSubString ] // eslint-disable-line react/jsx-key
    : <div className="CartTotalizer_TotalFreeDeliveryShortfall" data-e2e="freeDeliveryShortfall">
      {
        getLocalizedString('cart.cartTotalizer.fieldLabels.freeDeliveryShortfall')
          .replace('{amount}', `£${freeDeliveryShortfall.toFixed(2)}`)
      }
    </div>;
};

export { FreeDeliveryShortfallMessage };
export default FreeDeliveryShortfallMessage;
