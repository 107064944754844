import {
  UPDATE_USER_TELEPHONE
} from 'client/actions/ui/telephoneFieldActions';
import { fromJS } from 'immutable';

const defaultState = fromJS({
  telephoneValue: '',
  telephoneError: false
});

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_TELEPHONE:
      return state
        .set('telephoneValue', payload.telephoneValue)
        .set('telephoneError', payload.telephoneError);
    default:
      return state;
  }
};
