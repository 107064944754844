import React from 'react';
import FeefoStarComponent from 'client/components/elements/feefoReviewPanel/FeefoStarComponent';
import { Body } from '@graingerglobalonline/curly-eureka';

export const RecommendationReview = ({ averageRating, numberOfReviews }) => numberOfReviews ? (
  <div className="RecommendationTile_FeefoReviews">
    <FeefoStarComponent rating={ averageRating } maxRating={ 5 } />
    {numberOfReviews ? <Body variant="small" className="reviews-count" >({numberOfReviews})</Body> : null}
  </div>
) : null;
