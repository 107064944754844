/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { getLocalizedString } from 'localization/localizer';
import { Field, reduxForm, getFormValues, destroy, reset } from 'redux-form/immutable';
import { validateForm } from 'client/utils/formValidation';
import SelectWithValidations from 'client/components/elements/formField/SelectWithValidationsNewStyles';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import RadioButtonWithStyles from 'client/components/elements/formField/RadioButtonWithStyles';
import { setView, updateAdditionalUsersList } from 'client/actions/loginAndRegistration';
import {
  CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW,
  CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW,
  CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW,
  CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW,
  CHARITY,
  YES,
  MAYBE_LATER,
  COMPANY_INFO_URL
} from '../../constants';
import {
  populateCompanyTypes,
  populatePrimaryIndustry,
  COMPANY_TYPES_REQUIRING_REG_NO
} from 'client/utils/companyDataUtils';
import PostcodeInput from '../postCode';
import { makeValidator,
  isVatRegNoIf,
  isZeroRepeated,
  maxLengthWithCustomError,
  isCompanyRegNoIf,
  isValidPostCode,
  isRequiredIf,
  isRequired,
  isUKPhoneNumber,
  isPositiveNumber,
  isWholeNumber
} from 'client/utils/validators';
import CheckboxWithValidations from 'client/components/elements/formField/CheckboxWithValidations';
import { toggleEnterManually } from 'client/actions/ui/postCodeActions';
import { hideNewLoginExitConfirmationDialog } from 'client/actions/ui/dialogActions';

const isRegisteredCompany = (companyTypeValue) => {
  return COMPANY_TYPES_REQUIRING_REG_NO
    .filter((el) => el !== CHARITY)
    .includes(companyTypeValue);
};

const isCompanyRegNoRequired = (companyTypeValue) => {
  return COMPANY_TYPES_REQUIRING_REG_NO
    .includes(companyTypeValue);
};

const CREDIT_LINE_FORM = 'creditLineApplicationForm';
const validate = makeValidator({
  companyType: [
    isRequired('Company type')
  ],
  primaryIndustry: [
    isRequired('Primary industry')
  ],
  businessPhoneNumber: [
    isRequired('Business phone number'),
    isUKPhoneNumber(getLocalizedString('error.invalid.phoneNumber'))
  ],
  noOfStaff: [
    isRequired({
      message: 'No of staff is required'
    }),
    isPositiveNumber(),
    isZeroRepeated(getLocalizedString('error.invalid.positive.number'))
  ],
  expectedMonthlySpend: [
    isRequired({
      message: 'Monthly spend is required'
    }),
    isZeroRepeated(getLocalizedString('error.invalid.positive.number')),
    isWholeNumber(getLocalizedString('error.invalid.positive.number'))
  ],
  vatRegNo: [
    isRequiredIf(
      (values) => values.get('vatRegistered'),
      'Company VAT reg no'
    ),
    isVatRegNoIf(
      (values) => values.get('vatRegistered')
    )
  ],
  companyRegNo: [
    isRequiredIf(
      (values) => isRegisteredCompany(values.get('companyType')),
      'Company reg no'
    ),
    isCompanyRegNoIf(
      (values) => isRegisteredCompany(values.get('companyType'))
    )
  ],
  businessWebsite: [
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 100 characters long',
      maxLength: 100
    })
  ],
  vatRegistered: [],
  charityRegNo: [
    isRequiredIf(
      (values) => values.get('companyType') === CHARITY,
      'Charity reg no'
    ),
    maxLengthWithCustomError({
      errorMessage: 'Cannot be more than 20 characters long',
      maxLength: 20
    })
  ],
  primary_addressLine1: [
    isRequired(getLocalizedString('postcodeInput.label.addressLine1'))
  ],
  primary_city: [
    isRequired(getLocalizedString('postcodeInput.label.city'))
  ],
  primary_postalCode: [
    isRequired(getLocalizedString('postcodeInput.label.postcode')),
    isValidPostCode()
  ]
});

const CreditLineApplicationForm = ({
  dispatchSetView,
  handleSubmit,
  creditLineApplicationForm,
  toggleShowManually,
  primaryContactDetailsForm,
  onDestroyForm,
  hideExitConfirmation,
  dispatchUpdateAdditionalUsersList,
  additionalUsersList
}) => {
  const {
    companyType = '',
    vatRegistered = false,
    primary_quickFindPostCode = '',
    financeContact
  } = creditLineApplicationForm;
  const isRegisteredCompanyType = isCompanyRegNoRequired(companyType);
  const isCharity = companyType === CHARITY;
  const { additionalUsers } = primaryContactDetailsForm;

  useEffect(() => {
    if (financeContact === MAYBE_LATER) {
      onDestroyForm('financeContactForm');
      dispatchUpdateAdditionalUsersList(fromJS(
        additionalUsers === YES
          ? additionalUsersList.filter(user => !user?.isFinanceUser)
          : []
      ));
    }
  }, [financeContact]);

  const nextView = financeContact === YES
    ? CREATE_BUSINESS_ACCOUNT_FINANCE_CONTACT_VIEW
    : additionalUsers === YES
      ? CREATE_BUSINESS_ACCOUNT_ADDITIONAL_USERS_VIEW
      : CREATE_BUSINESS_ACCOUNT_FINAL_STEP_VIEW;

  const submit = (values) => {
    return validateForm(values, validate)
      .then(() => {
        dispatchSetView(nextView);
        hideExitConfirmation();
      })
      .catch(() => {
        hideExitConfirmation();
      });
  };

  return <div className="row BusinessAccountRegistrationForm_creditLineForm">
    <div className="col-md-12">
      <h3 className="BusinessAccountRegistrationForm_title">
        Credit Line Application
      </h3>
    </div>
    <form onSubmit={ handleSubmit(submit) }>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          <Field
            name="companyType"
            id="companyType"
            labelClassName="control-label required"
            className="form-control"
            component={ SelectWithValidations }
            datae2e="companyType"
            labelResourceName="creditAccount.application.label.companyType">
            { populateCompanyTypes }
          </Field>
        </div>
        <div className="col-xs-12 col-md-6">
          <Field
            id="primaryIndustry"
            name="primaryIndustry"
            className="form-control"
            labelClassName="control-label required"
            component={ SelectWithValidations }
            datae2e="primaryIndustry"
            labelResourceName="creditAccount.application.label.primaryIndustry"
          >
            <option value="">Please select</option>
            { populatePrimaryIndustry }
          </Field>
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          { isRegisteredCompanyType
            ? isCharity ? <Field
              id="charityRegNo"
              name="charityRegNo"
              className="form-control"
              labelClassName="control-label required"
              type="text"
              component={ FieldWithValidations }
              labelResourceName="creditAccount.application.label.charityRegNo"
              datae2e="charityRegNo"
            /> : <Field
              id="companyRegNo"
              name="companyRegNo"
              className="form-control"
              labelClassName="control-label required"
              type="text"
              errorLink={{
                url: COMPANY_INFO_URL,
                label: creditLineApplicationForm?.companyRegNo ? getLocalizedString('error.invalid.companyRegNo.click.here') : null
              }}
              component={ FieldWithValidations }
              labelResourceName="creditAccount.application.label.companyRegNo"
              datae2e="companyRegNo"
            /> : <Field
              name="businessPhoneNumber"
              id="businessPhoneNumber"
              className="form-control AboutYou_input"
              component={ FieldWithValidations }
              type="text"
              labelClassName="control-label required AboutYou_labelInput"
              labelResourceName="creditAccount.application.label.company.businessPhoneNumberType"
              datae2e="businessPhoneNumber"
            /> }
        </div>
        <div className="col-xs-12 col-md-6">
          { !isRegisteredCompanyType ? <div className="row">
            <div className="col-xs-12 col-md-6">
              <Field
                id="expectedMonthlySpend"
                name="expectedMonthlySpend"
                className="form-control"
                labelClassName="control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="creditAccount.application.label.expectedMonthlySpend"
                datae2e="expectedMonthlySpend"
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                id="noOfStaff"
                name="noOfStaff"
                className="form-control"
                labelClassName="control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="creditAccount.application.label.noOfStaff"
                datae2e="noOfStaff"
              />
            </div>
          </div> : <Field
            name="businessPhoneNumber"
            id="businessPhoneNumber"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label required AboutYou_labelInput"
            labelResourceName="creditAccount.application.label.company.businessPhoneNumberType"
            datae2e="businessPhoneNumber"
          /> }
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 col-md-6">
          { !isRegisteredCompanyType ? <Field
            name="businessWebsite"
            id="businessWebsite"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label AboutYou_labelInput"
            labelResourceName="creditAccount.application.label.companyWebsite"
            datae2e="businessWebsite"
          /> : <div className="row">
            <div className="col-xs-12 col-md-6">
              <Field
                id="expectedMonthlySpend"
                name="expectedMonthlySpend"
                className="form-control"
                labelClassName="control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="creditAccount.application.label.expectedMonthlySpend"
                datae2e="expectedMonthlySpend"
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                id="noOfStaff"
                name="noOfStaff"
                className="form-control"
                labelClassName="control-label required"
                type="text"
                component={ FieldWithValidations }
                labelResourceName="creditAccount.application.label.noOfStaff"
                datae2e="noOfStaff"
              />
            </div>
          </div> }
        </div>
        <div className="col-xs-12 col-md-6">
          { !isRegisteredCompanyType ? null : <Field
            name="businessWebsite"
            id="businessWebsite"
            className="form-control AboutYou_input"
            component={ FieldWithValidations }
            type="text"
            labelClassName="control-label AboutYou_labelInput"
            labelResourceName="creditAccount.application.label.companyWebsite"
            datae2e="businessWebsite"
          /> }
        </div>
      </div>
      <div className="row col-xs-12">
        <div className="col-xs-12 BusinessAccountRegistrationForm_vatReg">
          <Field
            id="vatRegistered"
            name="vatRegistered"
            labelClassName="control-label BusinessAccountRegistrationForm_vatRegisteredInput"
            component={ CheckboxWithValidations }
            textAndLink={ <div>My company is VAT registered</div> }
            datae2e="vatRegistered" />
          { vatRegistered ? <Field
            name="vatRegNo"
            className="form-control BusinessAccountRegistrationForm_vatRegisteredNumberInput"
            type="text"
            component={ FieldWithValidations }
            placeholderResourceName="creditAccount.application.label.vatRegNo"
            datae2e="companyVatRegNo"
          /> : null }
        </div>
      </div>
      <div className="col-xs-12">
        <div className="BusinessAccountRegistrationForm_hr" />
      </div>
      <div className="col-xs-12 BusinessAccountRegistrationForm_addressFormTitle">
        Registered Address
      </div>
      <div className="row col-xs-12 BusinessAccountRegistrationForm_addressForm">
        <div className="col-xs-12">
          <PostcodeInput
            fieldNamePrefix="primary_"
            datae2ePrefix=""
            form={ CREDIT_LINE_FORM }
            quickFindPostCodeValue={ primary_quickFindPostCode || '' }
            selector={ CREDIT_LINE_FORM }
          />
        </div>
      </div>
      <div className="row col-xs-12 BusinessAccountRegistrationForm_openCreditLine">
        <div className="col-xs-12">
          <Field
            component={ RadioButtonWithStyles }
            name="financeContact"
            labelResourceName="creditAccount.application.financeContact"
            labelClassName="control-label"
            className="form-control"
            options={ [
              { value: YES, title: YES, datae2e: 'financeContactYes' },
              { value: MAYBE_LATER, title: MAYBE_LATER, datae2e: 'financeContactNo' }
            ] }
            datae2e="financeContact"
            validatorName="financeContact" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="col-xs-6 BusinessAccountRegistrationForm_backBtn ForgotPasswordNewForm_noLeftPadding">
            <SecondaryButton
              datae2e="back"
              onClick={ () => {
                hideExitConfirmation();
                dispatchSetView(CREATE_BUSINESS_ACCOUNT_PRIMARY_CONTACT_DETAILS_VIEW);
              } }
            >
                Back
            </SecondaryButton>
          </div>
          <div className="col-xs-6 BusinessAccountRegistrationForm_continueBtn ForgotPasswordNewForm_noRightPadding">
            <PrimaryButton
              className="btn"
              type="submit"
              onClick={ () => {
                hideExitConfirmation();
                toggleShowManually(true);
              } }
              datae2e="continue">
                Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  </div>;
};

const ReduxCreditLineApplicationForm = reduxForm({
  form: CREDIT_LINE_FORM,
  validate,
  destroyOnUnmount: false
})(CreditLineApplicationForm);

const mapStateToProps = (state) => {
  const primaryContactDetailsForm = (getFormValues('primaryContactForm')(state) || fromJS({})).toJS();
  const creditLineApplicationForm = (getFormValues(CREDIT_LINE_FORM)(state) || fromJS({})).toJS();
  return {
    creditLineApplicationForm,
    primaryContactDetailsForm,
    additionalUsersList: (state.getIn(['loginAndRegistration', 'additionalUsersList']) || fromJS([])).toJS(),
    initialValues: {
      companyRegNo: primaryContactDetailsForm?.companyRegNo || '',
      financeContact: MAYBE_LATER,
      companyType: 'Plc/Ltd Co'
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleShowManually: (shouldEnterManually) => dispatch(toggleEnterManually(CREDIT_LINE_FORM, shouldEnterManually)),
  dispatchSetView: (view) => dispatch(setView({ view })),
  onResetReduxForm: (formName) => dispatch(reset(formName)),
  onDestroyForm: (formName) => dispatch(destroy(formName)),
  hideExitConfirmation: () => dispatch(hideNewLoginExitConfirmationDialog()),
  dispatchUpdateAdditionalUsersList: (list) => dispatch(updateAdditionalUsersList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxCreditLineApplicationForm);
