import PropTypes from 'prop-types';
import React from 'react';
import TapButton from 'client/components/controls/tapButton/TapButton';
import { formatPrice } from 'client/utils/priceUtils';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./DeliveryOptionList.scss');
}

const getOptionTitle = (optionObj, index, currency) => {
  const label = optionObj.get('label');
  const deliveryDate = optionObj.get('deliveryDate');
  const cost = formatPrice(currency, optionObj.get('cost'));
  const freeOption = <span><strong>Free </strong>{label} { deliveryDate }</span>;
  const standardOption = <span>{label} { deliveryDate }<strong> { `${cost.symbol}${cost.amount}` } </strong></span>;
  if (!index)
  {
    return optionObj.get('cost') <= 0 ? freeOption : standardOption;
  }
  return <span>{ label } { deliveryDate }<strong> { `${cost.symbol}${cost.amount}` } </strong></span>;
};

const getOption = (optionType, idx, seeMoreLessBtn, sku, eligibleForDeliveryOptions,
  selectedDeliveryOption, changeDeliveryOption, deliveryOptions, currency) => {
  const payload = {
    optionType: optionType.get('type'),
    eligibleForDeliveryOptions
  };

  return <div className="col-xs-12 col-sm-9 col-md-offset-3 DeliveryOptions_optionType" data-e2e={ optionType.get('type') }>

    <label>
      <input
        type="radio"
        name={ sku }
        value={ optionType.get('type') }
        checked = { selectedDeliveryOption === optionType.get('type') }
        onClick= { () => changeDeliveryOption(payload) }
        disabled = { deliveryOptions.size > 1 ? false : true }
      />
      { getOptionTitle(optionType, idx, currency) }
    </label>
    { deliveryOptions.size > 1 && seeMoreLessBtn }
  </div>;
};

const DeliveryOption = ({ optionType, idx, sku, seeMoreOptions,
  selectedDeliveryOption, toggleDeliveryOptionAccordion, currency,
  changeDeliveryOption, eligibleForDeliveryOptions, deliveryOptions }) => {
  const showMoreButton = <TapButton
    styles="Delivery_tapButton"
    id={ `cart.orderLine.remove.${sku}` }
    dataE2e="seeMoreOptions"
    onTap={ () => toggleDeliveryOptionAccordion(sku) }>
    { getLocalizedString('orderReview.deliveryOptions.showMore') }
  </TapButton>;

  const showLessButton = !idx ? <TapButton
    styles="Delivery_tapButton"
    id={ `cart.orderLine.remove.${sku}` }
    dataE2e="seeLessOptions"
    onTap={ () => toggleDeliveryOptionAccordion(null) }>
    { getLocalizedString('orderReview.deliveryOptions.showLess') }
  </TapButton> : null;

  const option = (accordionbtn) => getOption(optionType, idx, accordionbtn, sku, eligibleForDeliveryOptions,
    selectedDeliveryOption, changeDeliveryOption, deliveryOptions, currency);

  const optionsAccordion = (seeMoreOptions === sku && option(showLessButton)) ||
     (selectedDeliveryOption === optionType.get('type') && option(showMoreButton));

  return (
    <React.Fragment>
      {
        optionsAccordion
      }
    </React.Fragment>);
};

const DeliveryOptionList = ({ deliveryOptions, eligibleProductCountForDeliveryOption,
  hoverDeliveryOption, currency, selectedDeliveryOption, sku, changeDeliveryOption,
  toggleDeliveryOptionAccordion, seeMoreOptions, eligibleForDeliveryOptions }) => {
  const deliveryOptionsTitle = getLocalizedString('orderReview.deliveryOptions.DeliveryOptionsTitle');
  const deliveryOptionTitle = getLocalizedString('orderReview.deliveryOptions.DeliveryOptionTitle');
  const count = <strong onMouseEnter={ () => hoverDeliveryOption(true) } onMouseLeave={ () => hoverDeliveryOption(false) }>
    {eligibleProductCountForDeliveryOption} products</strong>;

  const eligibleProductLabel = !!eligibleProductCountForDeliveryOption && <span className="subTitle"> - Ship {count} with this service & cost</span>;
  return (
    <React.Fragment>
      {
        deliveryOptions.size
          ? <div className="row DeliveryOptions" data-e2e="deliveryOptions">
            <div className="col-xs-12 col-sm-9 col-md-offset-3">
              <label> <strong>{ deliveryOptions.size > 1 ? deliveryOptionsTitle : deliveryOptionTitle }</strong>{eligibleProductLabel}</label>
            </div>
            { deliveryOptions.map((optionType, idx) => (
              <DeliveryOption
                key={ optionType.get('type') + '-' + sku }
                optionType = { optionType }
                idx = { idx }
                currency = { currency }
                selectedDeliveryOption = { selectedDeliveryOption }
                sku = { sku }
                changeDeliveryOption = { changeDeliveryOption }
                toggleDeliveryOptionAccordion = { toggleDeliveryOptionAccordion }
                seeMoreOptions = { seeMoreOptions }
                eligibleForDeliveryOptions = { eligibleForDeliveryOptions }
                deliveryOptions = { deliveryOptions }
              />
            ))
            }
          </div> : null
      }
    </React.Fragment>
  );
};

DeliveryOptionList.propTypes = {
  eligibleProductCountForDeliveryOption: PropTypes.number,
  hoverDeliveryOption: PropTypes.func,
  currency: PropTypes.string,
  selectedDeliveryOption: PropTypes.string,
  sku: PropTypes.string,
  changeDeliveryOption: PropTypes.func,
  updateDeliveryAccordion: PropTypes.func,
  seeMoreOptions: PropTypes.string
};

export default DeliveryOptionList;
