import React from 'react';
import { formatPrice } from 'client/utils/priceUtils';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./TotalSaving.scss');
}

const TotalSaving = ({ totalSaving, currency, vat, includeVat }) => {
  totalSaving = !includeVat ? Number(Number(totalSaving) - (Number(totalSaving) / 100 * vat)) : Number(totalSaving);
  return (
    <span className="TotalSaving" title={ `${getLocalizedString('cart.orderLine.fieldLabels.savings')} ${formatPrice(currency, totalSaving).symbol}${formatPrice(currency, totalSaving).amount}` }>
      <span className="TotalSaving_text">{ getLocalizedString('cart.orderLine.fieldLabels.savings') }</span>
      <span className="TotalSaving__saving_price" >
        {`${formatPrice(currency, totalSaving).symbol}${formatPrice(currency, totalSaving).amount}`}
      </span>
    </span>
  );
};

export default TotalSaving;
