import React from 'react';
import { formatPrice } from 'client/utils/priceUtils';
import SaleTag from './SaleTag';
import { calculateZoroPromoRounding } from 'client/utils/discountUtils';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./PromoLabel.scss');
}

export const displayFinalPromoDiscount = (discount, finalDiscount) => {
  const displayDiscount = finalDiscount ? finalDiscount : discount;
  if (displayDiscount && displayDiscount > 0) {
    const roundedDiscount = calculateZoroPromoRounding(displayDiscount, false);
    return roundedDiscount;
  }
  return displayDiscount;
};

export const showPromoPriceLabel = (wasPriceLabel, displayDiscountLabel, children) => {
  return <div className="priceWrapper">
    <span className="PromoLabel">
      <span className="PromoLabel_was_price">{ wasPriceLabel }</span>
      <span className="PromoLabel_percent">{displayDiscountLabel}</span>
    </span>
    { children }
  </div>;
};

export const showPromoTag = (labels, promoUrl, isRecommendationPromo) => {
  const nonEmptyLabels = labels?.filter(label => label?.trim());
  return labels && (
    isRecommendationPromo && labels.length > 1
      ? nonEmptyLabels.map(
        (label, i) => label !== getLocalizedString('quantity.promo.discount') ? <span className="PromoLabel" key={ i } >
          <SaleTag label= { label } promoUrl={ promoUrl }/>
        </span> : null)
      : nonEmptyLabels.map(
        (label, i) => <span className="PromoLabel" key={ i } >
          <SaleTag label= { label } promoUrl={ promoUrl }/>
        </span>
      ));
};

const PromoLabel = ({
  oldPrice,
  discount,
  currency = 'GBP',
  labels,
  promoUrl = '',
  children,
  finalDiscount,
  isRecommendationPromo,
  hidePromoPrice = false,
  isDiscountApplied = false
}) => {
  const zeroDiscount = 0;
  const displayDiscount = displayFinalPromoDiscount(discount, finalDiscount);
  const parseToNumber = isNaN(displayDiscount) ? 0 : Number(displayDiscount);
  const wasPriceLabel = `${formatPrice(currency, oldPrice).symbol}${formatPrice(currency, oldPrice).amount}`;
  const displayDiscountLabel = `${displayDiscount}${getLocalizedString('searchPage.priceOff')}`;
  const isLoyaltyDiscount = parseToNumber !== zeroDiscount;

  return (isLoyaltyDiscount ? <React.Fragment>
    <div className="ProductVariantPrice_promoLabel row">
      {
        !hidePromoPrice
          ? showPromoPriceLabel(wasPriceLabel, displayDiscountLabel, children)
          : null
      }
    </div>
  </React.Fragment> : null);
};

export default PromoLabel;
