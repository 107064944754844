import { fromJS } from 'immutable';

import {
  STORE_INDUSTRIES_MAP
} from '../actions/industriesPageScreenActions';

const initialState = fromJS({
  industriesMap: new Map()
});

const industriesPageScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_INDUSTRIES_MAP:
      return state.set('industriesMap', fromJS(action.industriesMap));
    default:
      return state;
  }
};

export default industriesPageScreenReducer;
