import qs from 'qs';
import Cookies from 'js-cookie';

const ONETRUST_FUNCTIONAL_GROUP = 'C0003';
const ONETRUST_PERFORMANCE_GROUP = 'C0002';
const ONETRUST_ACCEPTED = '1';

const getOneTrustCookieGroups = (oneTrustCookie) => {
  const oneTrustCookieGroups = oneTrustCookie && oneTrustCookie.groups
    ? oneTrustCookie.groups.split(',').map(groupAndConsent => {
      const [group, consent] = groupAndConsent.split(':');
      return {
        group,
        consent
      };
    })
    : [];
  return oneTrustCookieGroups;
};

export const getFunctionalCookiesConsent = (cookies) => {
  const oneTrustCookie = cookies.OptanonConsent
    ? qs.parse(cookies.OptanonConsent)
    : null;
  const oneTrustCookieGroups = getOneTrustCookieGroups(oneTrustCookie);

  const functionalCookie = oneTrustCookieGroups.find(groupAndConsent => {
    return groupAndConsent.group === ONETRUST_FUNCTIONAL_GROUP && groupAndConsent.consent === ONETRUST_ACCEPTED;
  });

  return functionalCookie !== undefined;
};

export const getPerformanceCookiesConsent = (cookies) => {
  const oneTrustCookie = cookies.OptanonConsent
    ? qs.parse(cookies.OptanonConsent)
    : null;
  const oneTrustCookieGroups = getOneTrustCookieGroups(oneTrustCookie);

  const performanceCookie = oneTrustCookieGroups.find(groupAndConsent => {
    return groupAndConsent.group === ONETRUST_PERFORMANCE_GROUP && groupAndConsent.consent === ONETRUST_ACCEPTED;
  });

  return performanceCookie !== undefined;
};

export const getPerformanceCookiesConsentClient = () => {
  const oneTrustCookie = qs.parse(Cookies.get('OptanonConsent') || '');
  const oneTrustCookieGroups = getOneTrustCookieGroups(oneTrustCookie);

  const performanceCookie = oneTrustCookieGroups.find(groupAndConsent => {
    return groupAndConsent.group === ONETRUST_PERFORMANCE_GROUP && groupAndConsent.consent === ONETRUST_ACCEPTED;
  });

  return performanceCookie !== undefined;
};

export const getBotManagerCookies = (cookies) => {
  const akamaiBotManagerCookies = ['_abck', 'ak_bmsc', 'bm_mi', 'bm_sv', 'bm_sz'];

  return Object.entries(cookies)
    .filter(([cookieName]) => akamaiBotManagerCookies.includes(cookieName))
    .map(([cookieName, cookieValue]) => `${cookieName}=${cookieValue}`)
    .join(';');
};
