import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import {
  DEFAULT_PAYMENT_METHOD,
  PAY_BY_ACCOUNT,
  PAY_BY_EXISTING_CARD,
  PAY_BY_NEW_CARD,
  PAY_BY_PAYPAL,
  PAY_BY_APPLE_PAY,
  PAY_BY_GOOGLE_PAY,
  BOTTOM_BOX_MESSAGE,
  MODAL
} from './constants';
import { CARD } from 'shared/constants/braintree';
import { getLocalizedString } from 'localization/localizer';
import PayByExistingCard from '../PayByExistingCard/payByExistingCard';
import SavedCardsList from '../PayByExistingCard/savedCardsList';
import ReviewOrderButton from '../buttons/ReviewOrderButton';
import ChangePaymentMethodButton from '../buttons/ChangePaymentMethodButton';
import PayByPaypal from '../PayByPaypal/payByPaypal';
import PayByAccount from '../PayByAccount/payByAccount';
import PayByNewCard from '../PayByNewCard/payByNewCard';
import PayByApplePay from '../PayByApplePay/payByApplePay';
import PaymentMethods from '../buttons/PaymentMethods';
import PayByPaypalButton from '../buttons/PayByPaypalButton';
import { changePaymentMethod, initialize } from './paymentMethodActions';
import { requestCreditLimitIncrease } from '../RequestCreditLimit/RequestCreditLimitActions';
import { PaymentOptionsWrapper } from './paymentTypeSelector';
import { getVariant, prepareUnleashOverridePayload } from 'client/utils/unleashUtils';
import { SHOW, UNLEASH_APPLE_PAY_FEATURE_TOGGLE, UNLEASH_APPLE_PAY_SHOW } from 'shared/constants/abTesting';
import ConnectedUnleashWrapper from 'client/components/elements/unleashABTest/ConnectedUnleashWrapper';
import { withRouter } from 'react-router';
import PayByGooglePay from 'client/components/elements/paymentMethod/PayByGooglePay/payByGooglePay';
import RadioSelector from '../shared/RadioSelector';
import SvgLoader from '../../../svg/SvgLoader';
import { UNLEASH_SHOW_ONHOLD_MODAL } from 'shared/constants/abTesting';
import { getAccountStatusMessage } from '../../../../utils/getAccountStatusMessage';
import { isCheckoutSubscriptionPage } from 'client/components/elements/scheduledOrders/utils';

if (process.browser) {
  require('./paymentMethod.scss');
}

const paymentMethodComponents = {
  [PAY_BY_EXISTING_CARD]: (parentSelector, subscriptionPayment, setPaymentType, addressPickerProps, addressId, loadCart, paymentType, handleOnChangePaymentType, existingCards) => <PayByExistingCard
    selector={ parentSelector }
    paymentType={ paymentType }
    paymentMethodsComponent={
      existingCards?.length > 0
        ? <RadioSelector
          checked={ paymentType === PAY_BY_NEW_CARD }
          onClick={ () => {
            setPaymentType(PAY_BY_NEW_CARD);
            handleOnChangePaymentType(PAY_BY_NEW_CARD);
          } }
          datae2e='addNewCreditOrDebitCardSelector'
          selectorText={ getLocalizedString('singlePageCheckout.panel.addNewCreditOrDebitCard') }
          styles="PayByNewCard_radioSelectorContainer"
        />
        : <PaymentMethods paymentMethodSelector={ parentSelector } setPaymentType={ setPaymentType }/>
    }
    reviewOrderButton={ <ReviewOrderButton /> }
    subscriptionPayment={ subscriptionPayment }
    data-e2e="PayByExistingCard" />,

  [PAY_BY_NEW_CARD]: (parentSelector, subscriptionPayment, setPaymentType, addressPickerProps, addressId, loadCart, paymentType, handleOnChangePaymentType, existingcards) =>
    <>
      {existingcards?.length > 0 ? <>
        <SavedCardsList
          selector={ parentSelector }
          paymentType={ paymentType }
          styles="PayByExistingCard_savedCardsListContainer"
        />
        <RadioSelector
          checked={ paymentType === PAY_BY_NEW_CARD }
          onClick={ () => handleOnChangePaymentType(PAY_BY_NEW_CARD) }
          selectorText={ getLocalizedString('singlePageCheckout.panel.addNewCreditOrDebitCard') }
          styles="PayByNewCard_radioSelectorContainer"
          datae2e='addNewCreditOrDebitCardSelector'
        />
      </> : null}
      <PayByNewCard
        selector={ parentSelector }
        subscriptionPayment={ subscriptionPayment }
        data-e2e="PayByNewCard"
        addressPickerProps={ addressPickerProps }
        addressId = { addressId }
        loadCart = { loadCart }
        hidePayByNewCardTitle={ existingcards && existingcards?.length > 0 }
      />
    </>,

  [PAY_BY_ACCOUNT]: (parentSelector, subscriptionPayment, setPaymentType, addressPickerProps, addressId) => <PayByAccount
    addressId = { addressId }
    selector={ parentSelector }
    reviewOrderButton={ <ReviewOrderButton /> }
    changePaymentMethodButton={ <ChangePaymentMethodButton paymentMethodSelector={ parentSelector } /> }
    data-e2e="PayByAccount"/>,

  [PAY_BY_PAYPAL]: (parentSelector) => <PayByPaypal
    paypalButton={ <PayByPaypalButton paymentMethodSelector={ parentSelector } /> }
    selector={ parentSelector }
    reviewOrderButton={ <ReviewOrderButton /> }
    changePaymentMethodButton={ <ChangePaymentMethodButton paymentMethodSelector={ parentSelector } /> }
    data-e2e="PayByPaypal"/>,

  [PAY_BY_APPLE_PAY]: (parentSelector) => <PayByApplePay
    selector={ parentSelector }
    reviewOrderButton={ <ReviewOrderButton /> }
    changePaymentMethodButton={ <ChangePaymentMethodButton paymentMethodSelector={ parentSelector } /> }
    data-e2e="PayByApplePay"/>,

  [PAY_BY_GOOGLE_PAY]: (parentSelector) => <PayByGooglePay
    selector={ parentSelector }
    reviewOrderButton={ <ReviewOrderButton /> }
    changePaymentMethodButton={ <ChangePaymentMethodButton paymentMethodSelector={ parentSelector } /> }
    data-e2e="PayByGooglePay"/>
};

export const PaymentMethod = ({
  selector,
  paymentMethod,
  initialize,
  location,
  subscriptionPayment,
  addressList,
  onChangePaymentMethod,
  showPayOnAccountButton,
  existingCards,
  showApplePay,
  showGooglePay,
  defaultPaymentMethod,
  tradeAccount,
  cartPrice,
  requestCreditLimitData,
  disablePayOnAccountButton,
  onHandleRequestLimitIncrease,
  addressId,
  loadCart,
  toastInfo,
  showOnHoldBanner,
  unleashOnHoldModal,
  accountStatusMessage,
  messagePosition
}) => {
  const [paymentType, setPaymentType] = useState(paymentMethod);
  useEffect(() => {
    initialize({
      selector,
      location,
      subscriptionPayment
    });
  }, []);

  useEffect(() => {
    setPaymentType(paymentMethod);
  }, [paymentMethod]);
  const handleOnChangePaymentType = (newPaymentType) => {
    const paymentMethod = newPaymentType === PAY_BY_EXISTING_CARD && existingCards && existingCards.length === 0 ? PAY_BY_NEW_CARD : newPaymentType;
    setPaymentType(paymentMethod);
    onChangePaymentMethod({ selector, paymentMethod });
  };

  const handleRequestLimitIncrease = () => {
    onHandleRequestLimitIncrease();
  };

  return <div className="PaymentMethod_wrapper">
    {
      <ConnectedUnleashWrapper
        show={ <PaymentOptionsWrapper
          selector={ selector }
          initialPaymentType={ paymentType }
          onChangePaymentType={ (newPaymentType) => handleOnChangePaymentType(newPaymentType) }
          showPayOnAccountButton={ showPayOnAccountButton }
          showApplePay={ showApplePay }
          showGooglePay={ showGooglePay }
          defaultPaymentMethod={ defaultPaymentMethod }
          tradeAccount={ tradeAccount }
          cartPrice={ cartPrice }
          requestCreditLimitData={ requestCreditLimitData }
          handleRequestLimitIncrease={ handleRequestLimitIncrease }
          disablePayOnAccountButton={ disablePayOnAccountButton }
          addressId = { addressId }
          accountStatusMessage = { accountStatusMessage }
          messagePosition = { messagePosition }
        /> }
        hide={ <PaymentOptionsWrapper
          selector={ selector }
          initialPaymentType={ paymentType }
          onChangePaymentType={ (newPaymentType) => handleOnChangePaymentType(newPaymentType) }
          showPayOnAccountButton={ showPayOnAccountButton }
          defaultPaymentMethod={ defaultPaymentMethod }
          showApplePay={ showApplePay }
          showGooglePay={ showGooglePay }
          tradeAccount={ tradeAccount }
          cartPrice={ cartPrice }
          requestCreditLimitData={ requestCreditLimitData }
          handleRequestLimitIncrease={ handleRequestLimitIncrease }
          disablePayOnAccountButton={ disablePayOnAccountButton }
          addressId = { addressId }
          accountStatusMessage = { accountStatusMessage }
          messagePosition = { messagePosition }
        /> }
        DefaultComponent={ <PaymentOptionsWrapper
          selector={ selector }
          initialPaymentType={ paymentType }
          onChangePaymentType={ (newPaymentType) => handleOnChangePaymentType(newPaymentType) }
          showPayOnAccountButton={ showPayOnAccountButton }
          showApplePay={ showApplePay }
          showGooglePay={ showGooglePay }
          defaultPaymentMethod={ defaultPaymentMethod }
          tradeAccount={ tradeAccount }
          cartPrice={ cartPrice }
          requestCreditLimitData={ requestCreditLimitData }
          handleRequestLimitIncrease={ handleRequestLimitIncrease }
          disablePayOnAccountButton={ disablePayOnAccountButton }
          addressId = { addressId }
          accountStatusMessage = { accountStatusMessage }
          messagePosition = { messagePosition }
        /> }
        flag={ UNLEASH_APPLE_PAY_FEATURE_TOGGLE }/>
    }
    <div className="col-md-12">
      { selector !== DEFAULT_PAYMENT_METHOD && paymentType ? paymentMethodComponents[paymentType](selector, subscriptionPayment, setPaymentType, {
        addressList
      }, addressId, loadCart, paymentType, handleOnChangePaymentType, existingCards) : null }
    </div>
    { showOnHoldBanner && paymentMethod === PAY_BY_ACCOUNT ? <div data-e2e="warning-alert" className="col-md-12 ">
      <div className='AccountOnHoldBanner' >
        <div className={ `AccountOnHoldBanner_leftEdge AccountOnHoldBanner_edgeColour-information-icon` }/>
        <div className='AccountOnHoldBanner_title'>
          <div className="AccountOnHoldBanner_details">
            <SvgLoader xlinkHref={ 'information-icon' } title="banner-icon" className={ `AccountOnHoldBanner_details-information-icon` } />

            <div className="AccountOnHoldBanner_details-text"><span className="AccountOnHoldBanner_details-heading">
              {accountStatusMessage?.heading || ''}: </span> {accountStatusMessage?.content || ''}
            </div>
          </div>
        </div>
      </div>
    </div> : null }

    {unleashOnHoldModal && <ConnectedUnleashWrapper flag = { UNLEASH_SHOW_ONHOLD_MODAL } /> }
  </div>;
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = location.query ? location.query : null;
  const overrideVariantFromQuery = prepareUnleashOverridePayload(query);
  const addressId = ownProps.shippingAddress?.toJS()?.addressId;
  const defaultPaymentMethod = state.getIn(['user', 'accountDetails', 'defaultPaymentMethod'], CARD);
  const tradeAccount = state.getIn(['user', 'accountDetails', 'tradeAccount']);
  const braintree = state.getIn(['braintree']).toJS();
  const unleashToggles = state.getIn(['unleash', 'toggles'], List()).toJS();

  const unleashOverrideValues = state.getIn(['unleash', 'payload'], fromJS({})).toJS();
  const { unl_feature: unlFeature, unl_variant: unlVariant, unl_payload: unlPayload } = unleashOverrideValues;
  const overridenUnleashData = {
    unl_feature: unlFeature,
    unl_variant: unlVariant,
    unl_payload: unlPayload?.value || {}
  };
  const overrideVariant = prepareUnleashOverridePayload(overridenUnleashData);

  const toggleApplePayVariant = getVariant(unleashToggles, UNLEASH_APPLE_PAY_FEATURE_TOGGLE, '', overrideVariant);
  const isApplePayShowVariant = toggleApplePayVariant === UNLEASH_APPLE_PAY_SHOW;
  const isGooglePayReady = state.getIn(['ui', 'payByGooglePay', 'isReadyToPay'], false);
  const messagePosition = state.getIn(['cart', 'tradeAccountButton', 'messagePosition'], '');
  const unleashOnHoldModal = getVariant(unleashToggles, UNLEASH_SHOW_ONHOLD_MODAL, '', overrideVariantFromQuery);
  const accountRole = state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'accountRole'], '');
  const accountAdminEmail = state.getIn(['user', 'accountDetails', 'tradeAccount', 'primaryContact', 'email'], '');
  const accountStatusMessageKey = state.getIn(['cart', 'tradeAccountButton', 'accountStatusMessageKey'], '');
  const tradeAccountStatus = state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountUser', 'status'], '');
  const paymentMethodBannerConditions = (messagePosition === MODAL && !isCheckoutSubscriptionPage() && accountStatusMessageKey !== 'orderOnHoldMessage');
  const showOnHoldBanner = unleashOnHoldModal !== SHOW && (messagePosition === BOTTOM_BOX_MESSAGE || paymentMethodBannerConditions);
  return {
    paymentMethod: state.getIn(['ui', 'paymentMethod', ownProps.selector, 'method']),
    showPayOnAccountButton: state.getIn(['cart', 'tradeAccountButton', 'showPayOnAccountButton'], null),
    existingCards: braintree.cards || [],
    showApplePay: isApplePayShowVariant,
    showGooglePay: isGooglePayReady,
    defaultPaymentMethod,
    tradeAccount,
    cartPrice: state.getIn(['cart', 'cartPrice'], fromJS({})),
    requestCreditLimitData: state.getIn(['ui', 'requestCreditLimit', 'data'], null)?.toJS(),
    disablePayOnAccountButton: state.getIn(['cart', 'tradeAccountButton', 'disablePayOnAccountButton'], false),
    addressId,
    showOnHoldBanner,
    unleashOnHoldModal,
    accountStatusMessage: getAccountStatusMessage(accountStatusMessageKey, () => {}, accountRole, accountAdminEmail, tradeAccountStatus),
    messagePosition
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: (payload) => dispatch(initialize(payload)),
    onChangePaymentMethod: (payload) => dispatch(changePaymentMethod(payload)),
    onHandleRequestLimitIncrease: () => dispatch(requestCreditLimitIncrease())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));
