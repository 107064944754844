export const getParsedRecommendationPayload = (recommendationToggle) => {
  try {
    return recommendationToggle?.variant?.payload?.value
      ? JSON.parse(recommendationToggle?.variant?.payload?.value)
      : null;
  } catch (error) {
    return null;
  }
};

export const getIsRecomLocationActive = (parsedPayload, location) => {
  return (
    location &&
    Array.isArray(parsedPayload) &&
    parsedPayload.some((rec) => rec?.location === location)
  ) || false;
};
