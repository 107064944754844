import PropTypes from 'prop-types';
import React, { Component } from 'react';

if (process.browser) {
  require('./ErrorOutputScreen.scss');
}

export default class ErrorOutputScreen extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    body: PropTypes.string
  };

  render () {
    const { title, subtitle, body } = this.props;

    return (
      <div className="ErrorOutputScreen">
        <h1 className="ErrorOutputScreen_Title">{ title }</h1>
        <p className="ErrorOutputScreen_RefreshMessage">{ subtitle }</p>
        <p className="ErrorOutputScreen_ErrorMessage">{ body }</p>
      </div>
    );
  }
}
