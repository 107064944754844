import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getMessages, getCustomClassForStockStatus } from 'client/utils/stockMessageUtil';
import SvgLoader from 'client/components/svg/SvgLoader';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./NewStockMessage.scss');
}

const OUT_OF_STOCK = 'Out of Stock';
// https://schema.org/ItemAvailability values
const STOCK_TYPE = {
  'In Stock': 'InStock',
  'Available': 'InStock',
  'Unavailable': 'OutOfStock',
  'Discontinued': 'Discontinued',
  'Limited Stock': 'LimitedAvailability',
  [OUT_OF_STOCK]: 'OutOfStock'
};

class NewStockMessage extends React.Component {
  shouldComponentUpdate (nextProps) {
    const nextStatus = nextProps.stockStatus;
    const status = this.props.stockStatus;
    if (!nextStatus) return false;
    if (!_isEqual(nextStatus, status)) {
      return true;
    }
    return false;
  }

  christmasMessage () {
    const startChristmasMessaging = moment().month(11).date(23).utc();
    const endChristmas = moment().month(0).date(1).add(1, 'years').utc();
    const message = (<div className="StockMessage_christmasMessage">
      { getLocalizedString('stockMessage.christmas.message') }
      {/* <a href="/info/delivery" className="stockMessage__learnMore--link"> <span>{ getLocalizedString('stockMessage.learnMore.message') }</span></a> */}
    </div>);
    return moment().utc().isBetween(startChristmasMessaging, endChristmas, null, '[]') ? message : null;
  }

  render () {
    const {
      stockStatus,
      renderMicrodataAttr,
      productCurrency,
      isForCart,
      metaUrl,
      sku,
      isMobile,
      showStockMessageForChristmas,
      displayAvailabilityHeader,
      isRecommendations,
      priceWithMinOrderQty
    } = this.props;
    const messages = getMessages(stockStatus, isMobile, isRecommendations);
    // will display christmas message if showStockMessageForChristmas is set true in config
    const christmasMessage = showStockMessageForChristmas ? this.christmasMessage() : null;
    return (
      <Fragment>
        {
          displayAvailabilityHeader
            ? <div className="StockMessageHeader">
              { getLocalizedString('stockMessage.productScreen.header') }
            </div>
            : null
        }
        { messages.map((stockMessage, index) => {
          const message = _get(stockMessage, 'message', '');
          const text = _get(stockMessage, 'text', '');
          const isAvailable = _get(stockMessage, 'isAvailable', false);
          const isTomorrow = _get(stockMessage, 'isTomorrow', false);
          const customClass = (isAvailable) ? '' : getCustomClassForStockStatus(stockStatus, isForCart);
          const iconClass = displayAvailabilityHeader ? 'stockMessageIcon--product' : 'stockMessageIcon';
          const selectedStockType = STOCK_TYPE[text];
          const availability = selectedStockType || STOCK_TYPE[OUT_OF_STOCK];
          const availabilityValue = selectedStockType ? text : OUT_OF_STOCK;
          return <Fragment key={ `${sku}${index}` }> <div data-e2e={ index === 0 ? 'StockMessage' : `StockMessage_purchaseOrder_${sku}_${index}` } className={ `StockMessage ${customClass}` }>
            {
              (isAvailable)
                ? <SvgLoader xlinkHref="#tick-circle" className={ `${iconClass} availableIcon` } />
                : <SvgLoader xlinkHref="#cross-circle" className={ `${iconClass} unavailableIcon` }/>
            }
            {
              (isTomorrow && !isForCart && isAvailable && !isRecommendations) &&
              <span> { getLocalizedString('stockMessage.orderBy3pm') }</span>
            }
            {' '}
            {
              (index === 0 && isAvailable)
                ? <a href="/info/delivery" target="_blank" className="stockMessage__learnMore--link"> <span>{ message }</span></a>
                : message

            }
            {
              christmasMessage
            }
          </div>
          {renderMicrodataAttr &&
          <div className="hide" itemProp="offers" itemScope=" " itemType="http://schema.org/Offer">
            <span><link itemProp="availability" href={ `http://schema.org/${availability}` } />{ availabilityValue }</span>
            <span itemProp="priceCurrency">{ productCurrency }</span>
            <span itemProp="price">{ priceWithMinOrderQty }</span>
            <span itemProp="url">{ metaUrl }</span>
          </div>}
          </Fragment>; }
        )}
      </Fragment>
    );
  }
}

NewStockMessage.propTypes = {
  stockValue: PropTypes.number,
  productPrice: PropTypes.string,
  productCurrency: PropTypes.string,
  fulfillmentType: PropTypes.number,
  metaUrl: PropTypes.string,
  stockStatus: PropTypes.object,
  sku: PropTypes.string,
  isRecommendations: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.getIn(['deviceInfo', 'isMobile']),
    showStockMessageForChristmas: state.getIn(['config', 'showStockMessageForChristmas'])
  };
};
export { NewStockMessage };
export default connect(mapStateToProps)(NewStockMessage);
