import React, { useState, useRef, useEffect } from 'react';
import { getLocalizedString } from 'localization/localizer';
import CartTotalizer from 'client/components/elements/cartTotalizer/CartTotalizer';
import AddressDisplay from 'client/components/elements/addressDisplay/AddressDisplay';
import DiscountCode from 'client/components/elements/discountCode/DiscountCode';
import QuotationTotalizer from 'client/components/screens/quotationDetailsScreen/QuotationTotalizer';
import { FREE_DELIVERY_LOYALTY_TIERS, FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, LEGACY_BUSINESS_ADDRESS } from 'shared/constants/businessAddressStatus';
import { mapPaymentTermsText } from 'client/utils/braintreeUtils';
import FreeDeliveryShortfallMessage from 'client/components/screens/cartScreen/FreeDeliveryShortfallMessage';
import { fromJS } from 'immutable';
import _every from 'lodash/every';
import {
  DELIVERY_ADDRESS_ACCORDION_PANEL_ID,
  BILLING_ADDRESS_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';
import { isValidQuantityWithMinMaxMultiplier } from 'client/utils/messageUtils';
import OrderReviewButtonWrapper from './OrderReviewButtonWrapper';
import { PAY_BY_APPLE_PAY, PAY_BY_GOOGLE_PAY, PAY_BY_PAYPAL } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import SvgLoader from 'client/components/svg/SvgLoader';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { connect } from 'react-redux';
import { AgeVerificationDialog } from '../../dialogs/AgeVerificationDialog/AgeVerificationDialog';

if (process.browser) {
  require('./OrderReviewScreen.scss');
}

export const TotalizerSectionForQuotation = ({
  quotationDetails,
  cta,
  includeVat,
  isSinglePageCheckout,
  payOnAccountTerms,
  loyaltyTier,
  isFarFlungDelivery
}) => {
  const freeDeliveryShortfall = FREE_DELIVERY_THRESHOLD - quotationDetails.getIn(['quotationPrice', 'cartOrderLinesTotalWithVat'], 0);
  const isFreeShipping = FREE_DELIVERY_LOYALTY_TIERS.includes(loyaltyTier);
  return (<div>
    <QuotationTotalizer cta={ cta } quotationDetails={ quotationDetails } includeVat={ includeVat } cat={ cta } loyaltyTier={ loyaltyTier } isFarFlungDelivery={ isFarFlungDelivery } />
    { payOnAccountTerms }
    {freeDeliveryShortfall > 0 && !isFreeShipping && !isFarFlungDelivery
      ? <FreeDeliveryShortfallMessage
        freeDeliveryShortfall={ freeDeliveryShortfall }
        isSinglePageCheckout={ isSinglePageCheckout } />
      : null}

  </div>);
};

export const geFreeDeliveryMessageForQuotation = (quotationDetails, isSinglePageCheckout) => {
  const freeDeliveryShortfall = FREE_DELIVERY_THRESHOLD - quotationDetails.getIn(['quotationPrice', 'cartOrderLinesTotalWithVat'], 0);
  return freeDeliveryShortfall > 0
    ? <FreeDeliveryShortfallMessage
      freeDeliveryShortfall={ freeDeliveryShortfall }
      isSinglePageCheckout={ isSinglePageCheckout } />
    : null;
};

export const TotalizerSectionForCart = ({
  totalizedValues,
  cta,
  cart,
  includeVat,
  onApplyDiscountCode,
  onApplyDiscountCodeToExistingCart,
  onRemoveDiscountCode,
  eligibleForDeliveryOptions,
  payOnAccountTerms,
  openOrderReviewPanel,
  togglePlaceOrderButton,
  isSinglePageCheckout = false,
  addressId
}) => {
  const orderLines = cart.get('orderLines', fromJS({})).toJS();
  const discountNotAppliedProduct = orderLines ? orderLines.find((orderLine) => !orderLine.isDiscountApplied) : false;

  return (<div>
    <CartTotalizer
      togglePlaceOrderButton={ togglePlaceOrderButton }
      openOrderReviewPanel={ openOrderReviewPanel }
      isSinglePageCheckout={ isSinglePageCheckout }
      totalizedValues={ totalizedValues }
      orderLines={ orderLines }
      cta={ cta }
      currency={ cart.get('currency', '£') }
      includeVat={ includeVat }
      payOnAccountTerms={ payOnAccountTerms }
    />
    <DiscountCode
      discountWarning={ cart.getIn(['discount', 'warning'], null) }
      discountIsValid={ cart.getIn(['discount', 'isValid'], false) }
      discountTermsAndConditions={ cart.getIn(['discount', 'termsAndConditions'], []) }
      discountCodeApplied={ cart.getIn(['discount', 'code'], null) }
      onApplyDiscountCode={ onApplyDiscountCode }
      onApplyDiscountCodeToExistingCart = { onApplyDiscountCodeToExistingCart }
      onRemoveDiscountCode={ onRemoveDiscountCode }
      eligibleForDeliveryOptions = { eligibleForDeliveryOptions }
      saleDays={ cart.get('saleDays') }
      isSaleActive={ cart.get('isSaleActive') }
      addressId = { addressId }
      discountNotAppliedProduct = { discountNotAppliedProduct }
    />
  </div>);
};

export const DisplayDeliveryAndBillingAddress = ({
  deliveryAddress,
  payOnAccount,
  tradeAccount,
  billingAddress,
  isGlobal,
  setActivePanelId,
  setActivePanel,
  scrollToCheckoutSection,
  isOrderReviewPanel
}) => {
  return (
    <React.Fragment>
      <div className="OrderReviewScreen_addressSection">
        <div className="AddressDisplay OrderReviewScreen_removeMarginAddressTitle">
          <span className="CheckoutHeader">{ getLocalizedString('order.review.deliveryAddress') }</span>
        </div>
        <div className="AddressDisplay OrderReviewScreen_removeMarginAddressTitle">
          <span className="CheckoutHeader">{ getLocalizedString('order.review.billingAddress') }</span>
        </div>
        <AddressDisplay address={ deliveryAddress }
          isGlobal = { isGlobal }
          heading={ getLocalizedString('order.review.delivery') }
          blockEditing
          isOrderReviewPanel={ isOrderReviewPanel }
          scrollToEditAddressPanelId={ DELIVERY_ADDRESS_ACCORDION_PANEL_ID }
          setActivePanelId={ setActivePanelId }
          setActivePanel={ setActivePanel }
          scrollToCheckoutSection={ scrollToCheckoutSection }
        />
        <AddressDisplay address={ payOnAccount ? tradeAccount.get('invoiceContact') || new Map() : billingAddress }
          isGlobal
          heading={ getLocalizedString('order.review.billing') }
          blockEditing
          isOrderReviewPanel={ isOrderReviewPanel }
          scrollToEditAddressPanelId={ BILLING_ADDRESS_ACCORDION_PANEL_ID }
          setActivePanelId={ setActivePanelId }
          setActivePanel={ setActivePanel }
          scrollToCheckoutSection={ scrollToCheckoutSection }
        />
      </div>
    </React.Fragment>
  );
};

const isQuantityAvailable = (products, cartFromQuotation) => {
  return products.reduce((isQtyAvailable, product) => {
    if (isQtyAvailable) {
      isQtyAvailable = product.getIn(['stock', 'stockMessage', 'isDeliverable'], false);
    }
    return isQtyAvailable;
  }, true);
};

const isBundleQuantityAvailable = (products) => {
  return _every(products.toJS(), (product) => product.isBundleSearchable);
};

const checkQuantityAvailability = (cart) => {
  const orderLines = cart.get('orderLines', []);
  const bundles = cart.get('bundles', []);
  return ((orderLines.size > 0 && isQuantityAvailable(orderLines, false)) ||
          (bundles.size > 0 && isBundleQuantityAvailable(bundles)));
};

const checkIsQuantityValid = (cart) => {
  return cart.get('orderLines', fromJS([]))
    .map((orderLine) => {
      const quantity = orderLine.get('quantity', 1);
      const qtyMultiplier = orderLine.getIn(['price', 'qtyMultiplier'], 1);
      const minOrderQuantity = orderLine.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
      const maxOrderQuantity = orderLine.getIn(['stock', 'maxOrderQuantity'], null);
      return isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    }).every(el => el === true);
};

const isQtyValid = (products) => {
  return products
    .map((product) => {
      const quantity = product.get('qty', 1);
      const qtyMultiplier = product.getIn(['lockTimePrice', 'qtyMultiplier'], 1);
      const minOrderQuantity = product.getIn(['stock', 'minOrderQuantity'], qtyMultiplier);
      const maxOrderQuantity = product.getIn(['stock', 'maxOrderQuantity'], null);
      return isValidQuantityWithMinMaxMultiplier(quantity, qtyMultiplier, minOrderQuantity, maxOrderQuantity);
    }).every(el => el === true);
};

const eligibleProductCount = (orderLines) => {
  return orderLines.reduce((count, orderLine) => {
    const optionList = orderLine.getIn(['deliveryOptionList', 'optionList'], []);
    if (optionList.size)
    {
      count += 1;
    }
    return count;
  }, 0);
};

export const eligibleProductCountForDeliveryOption = (fromQuotationId, cart, quotationDetails) => (!fromQuotationId && eligibleProductCount(cart.get('orderLines', []))) ||
(fromQuotationId && quotationDetails && eligibleProductCount(quotationDetails.get('products', [])));

export const isShowDeliveryOptionsLabel = (cart) => !!(cart.get('orderLines', fromJS([])).toJS()
  .filter((orderLine) => {
    return orderLine.deliveryOptionList && orderLine.deliveryOptionList.optionList.length > 1; // Label to be shown if there are more than 1 option
  }).length);

export const Continue2PaymentButton = ({
  deliveryAddress,
  accountDetails,
  postOrder,
  selector,
  payOnAccount,
  fromQuotationId,
  postOrderError,
  csAgentName,
  quotationDetails,
  cart,
  isSinglePageCheckout,
  placeOrderError,
  isOrderReviewPanel,
  showTermsOfSales,
  placeOrderBtnSelector = 'singlePagePayment',
  placeOrderBtnDatae2e = 'continue2payment',
  selectedPaymentMethod,
  payPaldata,
  isPaymentFlowSkipped,
  showNonDeliverableOrderMessage,
  validNonDeliverableStatus,
  showOnHoldModal,
  disabled
}) => {
  const isQtyActuallyAvailable = (fromQuotationId && quotationDetails)
    ? isQuantityAvailable(quotationDetails.get('products', []), true)
    : checkQuantityAvailability(cart);

  const isQuantityValid = (fromQuotationId && quotationDetails)
    ? isQtyValid(quotationDetails.get('products', []), true)
    : checkIsQuantityValid(cart);

  // override if it is a csAgent session login via c1
  const isQtyAvailable = csAgentName
    ? true
    : isQtyActuallyAvailable;

  const isCartEmpty = cart.get('orderLines', []).size;
  const isQuotationOrder = (fromQuotationId && quotationDetails);
  const showPaymentButton = isQtyAvailable &&
   (!(fromQuotationId && quotationDetails) ||
    (isQtyAvailable && (fromQuotationId && quotationDetails) &&
     quotationDetails.get('isLocked', false))) &&
     isQuantityValid;

  const isPayPalSelected = selectedPaymentMethod && selectedPaymentMethod === PAY_BY_PAYPAL;
  const isApplePaySelected = selectedPaymentMethod && selectedPaymentMethod === PAY_BY_APPLE_PAY;
  const isGooglePaySelected = selectedPaymentMethod && selectedPaymentMethod === PAY_BY_GOOGLE_PAY;

  const termText = selectedPaymentMethod ? mapPaymentTermsText(selectedPaymentMethod) : '';
  const isSkipPaymentChecked = csAgentName && isPaymentFlowSkipped;
  const showLegalText = !payOnAccount && showTermsOfSales && !validNonDeliverableStatus;
  const nonDeliverableMessage = `${getLocalizedString('singlePageCheckout.non.deliverable.order')} ${getLocalizedString('singlePageCheckout.non.deliverable.address.review.order')}`;
  const showNonDeliverableMessage = validNonDeliverableStatus || showNonDeliverableOrderMessage;

  const hasAgeRestrictedProducts = cart.get('orderLines').some(orderLine => orderLine.get('ageRestricted'));
  const tradeAccount = accountDetails.get('tradeAccount');
  const isTradeAccount = tradeAccount != null && Object.keys(tradeAccount).length > 0;
  const currentSelectedAddress = deliveryAddress;
  const isCurrentSelectedAddressApproved = currentSelectedAddress && [BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, ...LEGACY_BUSINESS_ADDRESS].includes(currentSelectedAddress.get('businessAddressApprovalStatus'));
  const [showAgeVerification, setShowAgeVerification] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const ageRestrictedConsentNeeded = hasAgeRestrictedProducts && isTradeAccount && isCurrentSelectedAddressApproved && !confirmed;
  const orderReviewButtonWrapper = useRef(null);
  useEffect(() => {
    const wrapper = orderReviewButtonWrapper.current;
    if (confirmed && !isPayPalSelected && wrapper) {
      const button = wrapper.querySelector('button');
      if (button) {
        button.click();
      }
    }
  }, [confirmed]);
  if (showPaymentButton) {
    return (
      <div className="OrderReviewScreen_placeOrder">
        {ageRestrictedConsentNeeded && <AgeVerificationDialog
          isVisible={ showAgeVerification }
          onConfirmed={ () => {
            setShowAgeVerification(false);
            setConfirmed(true);
          } }
          onCloseHandler={ () => setShowAgeVerification(false) }
        />}
        <div className="OrderReviewButtonWrapper_container" ref={ orderReviewButtonWrapper }>
          {showLegalText
            ? <div className={ `OrderReviewScreen_placeOrderTerms ${isOrderReviewPanel ? 'OrderReviewScreen_orderReviewPanelTerms' : ''}` }>
              { getLocalizedString(termText) }
              <a
                href="/info/terms-of-sale"
                target="_blank">
                { getLocalizedString('singlePageCheckout.termsOfSale.link') }
              </a>
              { getLocalizedString('singlePageCheckout.ampersand') }
              <a id="PrivacyPolicyAgreement.checkbox.PrivacyPolicyLink" href="/info/privacy-policy" target="_blank">
                { getLocalizedString('singlePageCheckout.privacyPolicy.link') }
              </a>
              { getLocalizedString('singlePageCheckout.termsOfSale.afterLink') }
            </div>
            : null
          }

          <OrderReviewButtonWrapper
            isSkipPaymentChecked={ isSkipPaymentChecked }
            isPayPalSelected={ isPayPalSelected }
            isApplePaySelected={ isApplePaySelected }
            isGooglePaySelected={ isGooglePaySelected }
            selector={ selector }
            isSinglePageCheckout={ isSinglePageCheckout }
            csAgentName={ csAgentName }
            payOnAccount={ payOnAccount }
            placeOrderError={ placeOrderError }
            postOrderError={ postOrderError }
            payPaldata={ payPaldata }
            placeOrderBtnSelector={ placeOrderBtnSelector }
            fromQuotationId={ fromQuotationId }
            postOrder={ postOrder }
            placeOrderBtnDatae2e={ placeOrderBtnDatae2e }
            validNonDeliverableStatus={ validNonDeliverableStatus }
            showOnHoldModal={ showOnHoldModal }
            disabled={ disabled }
          />

          {ageRestrictedConsentNeeded
            ? <div className="OrderReviewButtonWrapper_overlay"
              onClick={ () => setShowAgeVerification(true) }
            /> : null
          }
        </div>
        { csAgentName && !isQtyActuallyAvailable
          ? <div className="OrderReviewScreen_qtyUnavailable">
            { getLocalizedString('order.review.csAgentWarning') }
          </div>
          : null
        }
        { showNonDeliverableMessage
          ? <div className="OrderReviewScreen_NonDeliverableOrderMessage" data-e2e="basketTotal-nonDeliverableMessage">
            <SvgLoader xlinkHref="red-alert" />
            <span>{nonDeliverableMessage}</span>
          </div>
          : null
        }
      </div>
    );
  }

  return !isCartEmpty
    ? <div className="text-center OrderReviewScreen_qtyUnavailable">{ getLocalizedString('singlePageCheckout.emptyBasket') }</div>
    : <div className="OrderReviewScreen_qtyUnavailable">
      { isQuantityValid
        ? !(isQuotationOrder)
          ? <div className="alertMessage"><i className="far fa-times-circle"/> { getLocalizedString('order.review.place.order.error') }</div>
          : (isQtyAvailable
            ? getLocalizedString('order.review.quotation.expired')
            : <div className="alertMessage"><i className="far fa-times-circle"/> { getLocalizedString('order.review.place.order.error') }</div>)
        : <div className="OrderReviewScreen_invalid-qty">
          <SvgLoader xlinkHref="#warning-circle" className="OrderReviewScreen_warning-circle" />
          { getLocalizedString('order.review.invalid.quantity') }
        </div>
      }
    </div>;
};

const mapDispatchToProps = () => ({
  setActivePanelId
});

export default connect(null, mapDispatchToProps)(Continue2PaymentButton);
