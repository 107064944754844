import { takeEvery, put, all, fork, call } from 'redux-saga/effects';
import { getStockInfo } from 'shared/endpoints/productVariantEndpoint';

import {
  GET_STOCK_MESSAGES,
  getStockMessagesSuccess
} from 'client/actions/getStockMessageActions';

export function * getStockMessages ({ payload }) {
  const productStockInfo = yield call(getStockInfo, payload.sku, payload.quantity);
  yield put(getStockMessagesSuccess({ sku: payload.sku, productStockInfo }));
}

export function * watchGetStockMessages () {
  yield takeEvery(GET_STOCK_MESSAGES, getStockMessages);
}

export function * watchAllStockActions () {
  yield all([
    fork(watchGetStockMessages)
  ]);
}
