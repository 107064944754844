import React from 'react';
import CartOrderLineList from 'client/components/elements/cartOrderLineList/CartOrderLineList';
import {
  geFreeDeliveryMessageForQuotation,
  Continue2PaymentButton,
  eligibleProductCountForDeliveryOption
} from 'client/components/screens/orderReviewScreen/OrderReviewScreen';
import { PAYPAL, PAY_ON_ACCOUNT } from 'shared/constants/braintree';
import { getLocalizedString } from 'localization/localizer';
import { getFreeDeliveryShortfall } from 'client/components/screens/cartScreen/CartScreen';
import QuotationProductRow from 'client/components/screens/quotationDetailsScreen/QuotationProductRow';
import ProgressCircle from 'client/components/elements/progressCircle/ProgressCircle';
import ConnectedUnleashWrapper from 'client/components/elements/unleashABTest/ConnectedUnleashWrapper';
import { UNLEASH_APPLY_FOR_BUSINESS_ACCOUNT_CHECKOUT } from 'shared/constants/abTesting';
import { FREE_DELIVERY_LOYALTY_TIERS } from 'shared/constants/common';
import { BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, LEGACY_BUSINESS_ADDRESS } from 'shared/constants/businessAddressStatus';

const cartIsEditable = true;

const ApplyForBusinessAccountLink = () => {
  return <a href="/zoro-business-account/apply?redirectTo=/checkout/order" className="SinglePageCheckout_applyForBusinessAccount">
    { getLocalizedString('cart.buttons.applyForABusinessAccount') }
  </a>;
};

export const OrderReviewPanel = (props) => {
  return (<React.Fragment>
    { displayFreeDeliveryMessage(props) }
    { displayCartOrderlines(props) }
    { displayPaymentButton(props) }
  </React.Fragment>);
};

const displayFreeDeliveryMessage = (props) => {
  const {
    fromQuotationId,
    quotationDetails,
    totalizedValues,
    loyaltyTier
  } = props;
  const isQuotationOrder = (fromQuotationId && quotationDetails);
  const isFreeShipping = FREE_DELIVERY_LOYALTY_TIERS.includes(loyaltyTier);
  const freeDeliveryMessage = isQuotationOrder && !isFreeShipping
    ? geFreeDeliveryMessageForQuotation(quotationDetails, true)
    : totalizedValues ? getFreeDeliveryShortfall(totalizedValues) : null;
  return (
    <span className="singlePageCheckout_freeDeliveryMessage">{ freeDeliveryMessage }</span>
  );
};

const displayCartOrderlines = (props) => {
  const {
    cart,
    isGlobal,
    includeVat,
    toastSuccess,
    removeOrderLine,
    fromQuotationId,
    quotationDetails,
    setBundleQuantity,
    changeDeliveryOption,
    setOrderLineQuantity,
    removeBundleOrderLine,
    eligibleForDeliveryOptions,
    fetchQuotationProductStockInfo,
    changeDeliveryOptionFromQuotation,
    changeDeliveryOptionFromGlobalQuotation
  } = props;
  return fromQuotationId && quotationDetails
    ? quotationDetails.get('products', []).map((product) => (
      <QuotationProductRow
        key={ product.get('sku') }
        quotationId={ fromQuotationId }
        isLocked={ quotationDetails.get('isLocked') }
        product={ product }
        fetchQuotationProductStockInfo={ fetchQuotationProductStockInfo }
        deliveryOptions = { product.getIn(['deliveryOptionList', 'optionList'], []) }
        selectedDeliveryOption = { product.getIn(['deliveryOptionList', 'selectedDeliveryOption']) }
        eligibleProductCountForDeliveryOption = { eligibleProductCountForDeliveryOption }
        changeDeliveryOptionFromQuotation = { isGlobal ? (payload) =>
          changeDeliveryOptionFromGlobalQuotation(payload, false, fromQuotationId)
          : (payload) => changeDeliveryOptionFromQuotation(payload, fromQuotationId) }
        eligibleForDeliveryOptions = { eligibleForDeliveryOptions }
        includeVat={ includeVat }
        isSinglePageCheckout = { true }
      />
    ))
    : <CartOrderLineList
      orderLines={ cart.get('orderLines', []) }
      setOrderLineQuantity={ setOrderLineQuantity }
      removeOrderLine={ removeOrderLine }
      isCartEditable={ cartIsEditable }
      includeVat={ includeVat }
      toastSuccess={ toastSuccess }
      bundles= { cart.get('bundles', []) }
      setBundleQuantity= { setBundleQuantity }
      removeBundleOrderLine= { removeBundleOrderLine }
      eligibleProductCountForDeliveryOption = { eligibleProductCountForDeliveryOption(fromQuotationId, cart, quotationDetails) }
      eligibleForDeliveryOptions = { eligibleForDeliveryOptions }
      changeDeliveryOption = { changeDeliveryOption }
      isSinglePageCheckout = { true }
      discount={ !cart.getIn(['discount', 'applyingDiscountCodeRequest'], false) && cart.get('discount', {}) }
    />;
};

const displayPaymentButton = (props) => {
  const {
    paymentMethod,
    togglePlaceOrderButton,
    selector,
    braintree,
    accountDetails,
    addressId,
    addressList,
    cart,
    quotationDetails,
    isMobile
  } = props;

  const braintreeCards = braintree && braintree.cards;
  const displayPlaceOrderBtn = braintreeCards && togglePlaceOrderButton;
  const isPaypalPaymentMethod = (paymentMethod === PAYPAL);
  const isPayOnAccount = paymentMethod === PAY_ON_ACCOUNT;
  const paypalNote = isPaypalPaymentMethod ? getLocalizedString('payment.label.paypal.paymentMethod.selected') : '';
  const accountInfo = accountDetails.toJS();
  const cartOrQuotation = quotationDetails || cart;
  const hasAgeRestrictedProducts = cartOrQuotation?.get('orderLines').some(orderLine => orderLine.get('ageRestricted'));
  const isTradeAccount = accountInfo && accountInfo.tradeAccount ? !!accountInfo.tradeAccount.accountNo : false;
  const currentSelectedAddress = (addressList || []).find(address => address.get('addressId') === addressId);
  const isCurrentSelectedAddressApproved = currentSelectedAddress && [BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, ...LEGACY_BUSINESS_ADDRESS].includes(currentSelectedAddress.get('businessAddressApprovalStatus'));
  const canContinueToPayment = !hasAgeRestrictedProducts || (isTradeAccount && isCurrentSelectedAddressApproved);

  return (
    <div className="row SinglePageCheckout_nextBtnSection">
      <div className={ `${!isTradeAccount ? 'col-md-4' : 'col-md-8'} SinglePageCheckout_paypalNote` }>
        { paypalNote }
      </div>
      { !isMobile ? (
        <div className={ `${!isTradeAccount ? 'col-md-8' : 'col-md-4'} SinglePageCheckout_nextBtn SinglePageCheckout_orderReviewPlaceOrder` }>
          { !isTradeAccount ? <div className="col-md-6">
            <ConnectedUnleashWrapper
              flag={ UNLEASH_APPLY_FOR_BUSINESS_ACCOUNT_CHECKOUT }
              Hide={ null }
              Show={ <ApplyForBusinessAccountLink /> }
            />
          </div> : null }
          <div className={ `${!isTradeAccount ? 'col-md-6' : ''}` }>
            { displayPlaceOrderBtn ? <Continue2PaymentButton selector={ selector } { ...props } isOrderReviewPanel disabled={ !canContinueToPayment } payOnAccount={ isPayOnAccount }/> : <React.Fragment>
              <ProgressCircle/>
              <span className="SinglePageCheckout_progressCircle_Text"> { !braintreeCards ? '' : getLocalizedString('payment.label.pleaseWait') } </span>
            </React.Fragment>}
          </div>
        </div>
      ) : (
        <div className={ `${!isTradeAccount ? 'col-md-8' : 'col-md-4'} SinglePageCheckout_nextBtn SinglePageCheckout_orderReviewPlaceOrder` }>
          <div className={ `${!isTradeAccount ? 'col-md-6' : ''}` }>
            { displayPlaceOrderBtn ? <Continue2PaymentButton selector={ selector } { ...props } isOrderReviewPanel disabled={ !canContinueToPayment } payOnAccount={ isPayOnAccount }/> : <React.Fragment>
              <ProgressCircle/>
              <span className="SinglePageCheckout_progressCircle_Text"> { !braintreeCards ? '' : getLocalizedString('payment.label.pleaseWait') } </span>
            </React.Fragment>}
          </div>
          { !isTradeAccount ? <div className="col-md-6">
            <ConnectedUnleashWrapper
              flag={ UNLEASH_APPLY_FOR_BUSINESS_ACCOUNT_CHECKOUT }
              Hide={ null }
              Show={ <ApplyForBusinessAccountLink /> }
            />
          </div> : null }
        </div>
      )}
    </div>
  );
};
