import { ApiRequest } from 'shared/utils/apiUtils';

export function fetchQuotations () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/quotations'
  });
  return req.executeAndGetBody();
}

export function fetchQuotationDetails (quotationId, eligibleForDeliveryOptions, optionType, sku, addressId) {
  const query = { eligibleForDeliveryOptions, optionType, sku, addressId };
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/quotations/${quotationId}`,
    query
  });
  return req.executeAndGetBody();
}

export function createQuotation (quotationName, products) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/quotations',
    body: {
      name: quotationName,
      products
    }
  });
  return req.executeAndGetBody();
}

export function addProductsToQuotation (quotationId, products) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/quotations/${quotationId}`,
    body: {
      products
    }
  });
  return req.executeAndGetBody();
}

export function removeProductFromQuotation (quotationId, sku) {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/quotations/${quotationId}/${sku}`
  });
  return req.executeAndGetBody();
}

export function updateQuotationProductQty (quotationId, sku, qty) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/quotations/${quotationId}/${sku}`,
    body: {
      qty
    }
  });
  return req.executeAndGetBody();
}

export function sendQuotationEmail (quotationId) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/quotations/email/${quotationId}`
  });
  return req.executeAndGetBody();
}

export function deleteQuotation (quotationId) {
  const req = new ApiRequest({
    method: 'DELETE',
    apiPath: `/quotations/${quotationId}`
  });
  return req.executeAndGetBody();
}

export function lockQuotation (quotationId) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/quotations/${quotationId}/lock`
  });
  return req.executeAndGetBody();
}

/// GLOBAL QUOTATION ENDPOINTS ----------------------------------
export function fetchGlobalQuotations () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/global-quotations'
  });
  return req.executeAndGetBody();
}

export function createGlobalQuotation (products) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/global-quotations',
    body: {
      products
    }
  });
  return req.executeAndGetBody();
}

export function mergeGlobalQuotes (quotationId) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: '/global-quotations/merge',
    body: { quotationId }
  });
  return req.executeAndGetBody();
}

export function updateGlobalQuoteName (name, quoteRef) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/global-quotations/update-name/${quoteRef}`,
    body: { name }
  });
  return req.executeAndGetBody();
}

export function saveQuoteToYourList (quotationId) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/global-quotations/save/${quotationId}`
  });
  return req.executeAndGetBody();
}

export function loadQuotations ({ limit, pageNumber, sortField, quoteType,
  dateRange, searchInput }) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/quotations-by-query',
    query: { limit,
      pageNumber,
      sortField,
      quoteType,
      dateRange,
      searchInput }
  });
  return req.executeAndGetBody();
}

export function addQuoteToBasketEndpoint (quotationId) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/cart/import-quote/${quotationId}`
  });
  return req.executeAndGetBody();
}
