import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import _find from 'lodash/find';
import _inRange from 'lodash/inRange';
import ProductLine from 'client/components/elements/productLine/ProductLine';

if (process.browser) {
  require('./QuotationProductRow.scss');
}

const QuotationProductRow = (
  {
    quotationId,
    product,
    removeProductFromQuotationConfirm,
    isLastItem,
    fetchQuotationProductStockInfo,
    requestChangeInQuoteLineQty,
    deliveryOptions,
    selectedDeliveryOption,
    eligibleProductCountForDeliveryOption,
    changeDeliveryOptionFromQuotation,
    eligibleForDeliveryOptions,
    includeVat,
    isSinglePageCheckout
  }) => {
  const sku = product.get('sku');
  const nonRefundableType = product.get('nonRefundableType');
  const getQuantityDiscountLabel = (qty, breakPrices) => {
    const foundNewBreakPriceObject = _find(breakPrices, (breakPrice) => {
      return _inRange(qty, breakPrice.breakQty, breakPrice.maxQuantity + 1);
    });

    return foundNewBreakPriceObject && foundNewBreakPriceObject.breakDiscount && foundNewBreakPriceObject.breakDiscount !== 0
      ? (<div className="QuotationProductRow_qtyDiscountLabel">
        <span>Quantity Discount</span>
      </div>) : '';
  };

  const onQtyChange = ({ quantity }) => {
    getQuantityDiscountLabel(quantity, product.getIn(['lockedPrice', 'breakPrices'], List()).toJS());
    fetchQuotationProductStockInfo(quotationId, sku, quantity);
    return requestChangeInQuoteLineQty(product.get('qty'), quotationId, sku, quantity, product.getIn(['lockedPrice', 'breakPrices'], List()));
  };
  const onRemove = (product) => {
    removeProductFromQuotationConfirm(quotationId, product, isLastItem);
  };

  return (
    <div className="QuotationProductRow_ProductRow">
      <ProductLine
        sku={ product.get('sku') }
        isAvailable={ true }
        isEditable={ true }
        quantity={ product.get('qty') }
        onQuantityChange={ onQtyChange }
        onRemove={ onRemove }
        isIncludingVat={ includeVat }
        deliveryOptions = { deliveryOptions }
        selectedDeliveryOption= { selectedDeliveryOption }
        eligibleProductCountForDeliveryOption= { eligibleProductCountForDeliveryOption }
        changeDeliveryOption= { changeDeliveryOptionFromQuotation }
        eligibleForDeliveryOptions= { eligibleForDeliveryOptions }
        nonRefundableType= { nonRefundableType }
        isSinglePageCheckout = { isSinglePageCheckout }
      />
    </div>
  );
};

QuotationProductRow.propTypes = {
  quotationId: PropTypes.string,
  isLocked: PropTypes.bool,
  product: PropTypes.object,
  addToCart: PropTypes.func,
  fetchQuotationProductStockInfo: PropTypes.func,
  updateQuotationProductQty: PropTypes.func,
  removeProductFromQuotationConfirm: PropTypes.func,
  isLastItem: PropTypes.bool,
  requestChangeInQuoteLineQty: PropTypes.func
};

export default QuotationProductRow;
