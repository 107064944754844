import React from 'react';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { IMAGERESOLUTION } from 'shared/constants/images';
import { recommendedLinkClicked } from 'client/actions/productVariantActions';
import { getLocalizedString } from 'localization/localizer';
import { getTruncatedTitle } from 'client/utils/recommendationUtils';
import { VerticalRecommendationProductCard } from './VerticalRecommendationProductCard';
import { RecommendationTileFamilyItem } from './RecommendationTileFamilyItem';

if (process.browser) {
  require('../../familyDetailsPanel/FamilyDetailsPanel.scss');
}

const thumbnailLocation = 'mediaContent.mainPicture.familyTeaser';

export class FamilyItem extends React.Component {
  constructor (props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler () {
    const {
      item: { familyId, recommendationType: type },
      position,
      linkClicked
    } = this.props;

    linkClicked({
      family_sku: familyId,
      type,
      position
    });
  }

  render () {
    const {
      item,
      isPriceWithVat = true,
      isVerticalRecommendation
    } = this.props;

    const {
      familyHeading,
      familyId,
      brand = {},
      numberOfProductVariantsInFamily,
      priceWithVat,
      priceWithoutVat,
      familyUrl,
      feefoReviews = {}
    } = item;

    const thumbnail = get(item, `${thumbnailLocation}[${IMAGERESOLUTION}]`);

    const truncatedTitle = getTruncatedTitle(familyHeading);

    const { brandName } = brand;

    const { averageRating, numberOfReviews } = feefoReviews || {};

    const familyPrice = isPriceWithVat ? priceWithVat : priceWithoutVat;
    const priceVatTagLabel = isPriceWithVat
      ? getLocalizedString('incVat')
      : getLocalizedString('excVat');

    const familyPriceProps = { familyPrice, priceVatTagLabel };
    const actionButtonProps = {
      familyId,
      url: familyUrl,
      clickHandler: this.clickHandler,
      variantsNumber: numberOfProductVariantsInFamily,
      familyPriceProps
    };

    return (
      <>
        {isVerticalRecommendation ? (
          <VerticalRecommendationProductCard
            link={ familyUrl }
            clickHandler={ this.clickHandler }
            thumbnail={ thumbnail }
            name={ familyHeading }
            familyPriceProps={ familyPriceProps }
            brandName={ brandName }
            actionButtonProps={ actionButtonProps }
            isFamilyProduct={ true }
          />
        ) : (
          <RecommendationTileFamilyItem
            brandName={ brandName }
            actionButtonProps={ actionButtonProps }
            link={ familyUrl }
            clickHandler={ this.clickHandler }
            thumbnail={ thumbnail }
            familyHeading={ familyHeading }
            truncatedTitle={ truncatedTitle }
            reviewProps={
              feefoReviews
                ? { averageRating, numberOfReviews }
                : null
            }
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    linkClicked: flowRight(dispatch, recommendedLinkClicked)
  };
};
export default connect(null, mapDispatchToProps)(FamilyItem);
