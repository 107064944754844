import React from 'react';

import { SecondaryLink } from 'client/components/controls/StyledForms';
import { RecommendationFamilyPrice } from '../baseComponents/RecommendationFamilyPrice';

const NextStepsFamily = ({
  familyId,
  url,
  variantsNumber,
  clickHandler,
  familyPriceProps
}) => (
  <div className="RecommendationTile_FamilyVariants">
    <RecommendationFamilyPrice { ...familyPriceProps } />
    <div className='RecommendationTile_ViewVariants'>
      <SecondaryLink
        id={ `RecommendationTile_FamilyVariants.${familyId}` }
        onClick={ clickHandler }
        to={ url }
        isExternalLink={ true }
        className="RecommendationTile_FamilyVariantsButton"
        text={ `View ${variantsNumber} Variants` }
      />
    </div>

  </div>
);

export default NextStepsFamily;
