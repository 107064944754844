import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializePaypal } from './payByPaypalActions';
import { getLocalizedString } from 'localization/localizer';
import { PaymentAddress } from '../shared/PaymentAddress';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
if (process.browser) {
  require('./payByPaypal.scss');
}

export const PayByPaypal = ({
  selector,
  initializePaypal,
  email,
  billingAddress,
  reviewOrderButton,
  payPaldata
}) => {
  useEffect(() => {
    if (payPaldata) {
      initializePaypal({
        selector
      });
    }
  }, [payPaldata]);
  const isCheckout = selector === CHECKOUT_SELECTOR;
  return (
    <div className="PayByPaypal">
      { isCheckout && !payPaldata ? reviewOrderButton : null}
      { billingAddress && email ? <PaymentAddress
        data-e2e="PayByPaypal_payment_address"
        address={ billingAddress }
        title={ getLocalizedString('payment.label.billing.details') }
        button={ reviewOrderButton }
        paypalEmail={ email }/> : null }
    </div>
  );
};

export function mapPaypalAddressToZoro (paypalDetails) {
  return {
    firstName: paypalDetails.firstName,
    lastName: paypalDetails.lastName,
    addressLine1: paypalDetails.billingAddress.line1,
    addressLine2: paypalDetails.billingAddress.line2,
    state: paypalDetails.billingAddress.state,
    city: paypalDetails.billingAddress.city,
    postalCode: paypalDetails.billingAddress.postalCode
  };
}
function mapStateToProps (state, ownProps) {
  const details = state.getIn(['ui', 'payByPaypal', ownProps.selector, 'details']);
  const email = details && details.email;
  const billingAddress = details && mapPaypalAddressToZoro(details);
  return {
    email,
    billingAddress,
    payPaldata: state.getIn(['braintree', 'paypal', 'data'])
  };
}

const mapDispatchToProps = {
  initializePaypal
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByPaypal);
