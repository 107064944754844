import React from 'react';
import {
  USER_TYPE_INDIVIDUALS
} from '../constants';

const ZoroBenefits = ({ userType }) => {
  return <div className="WelcomeToZoro_accountBenefitsContainer">
    { [USER_TYPE_INDIVIDUALS].includes(userType) ? <div className="WelcomeToZoro_accountBenefitsContainer-box">
      <div className="WelcomeToZoro_accountBenefitsContainer-title">
          Individual Account
      </div>
      <div className="WelcomeToZoro_accountBenefitsContainer-subTitle">
          Create A Free Account With Zoro
      </div>
      <ul data-e2e="accountBenefits" className="WelcomeToZoro_accountBenefitsContainer-text">
        <li>Fast and Free delivery on orders over £24.99</li>
        <li>Exclusive Promotions and Sales</li>
        <li>Multiple addresses and payment options</li>
        <li>Hassle-free online returns</li>
      </ul>
    </div> : <div className="WelcomeToZoro_accountBenefitsContainer-box">
      <div className="WelcomeToZoro_accountBenefitsContainer-title">
          Business Account
      </div>
      <ul data-e2e="individualBenefits" className="WelcomeToZoro_accountBenefitsContainer-text">
        <li>Multi-User Accounts</li>
        <li>Detailed Monthly Invoices</li>
        <li>No Joining Fee</li>
        <li>No Monthly Commitment - It’s 100% Free</li>
      </ul>
    </div> }
  </div>;
};

export default ZoroBenefits;
