import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

if (process.browser) {
  require('./HTMLComponent.scss');
}

export default class HTMLComponent extends Component {
  static propTypes = {
    html: PropTypes.string
  }

  sanitizeMarkup (html) {
    return DOMPurify.sanitize(html, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['target']
    });
  }

  render () {
    const { html, htmlCss } = this.props;
    return (<div
      className = { 'HTMLComponent' }
      style={ htmlCss }
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: this.sanitizeMarkup(html) }}
    />);
  }
}
