import request from 'superagent';
import _forEach from 'lodash/forEach';
import _assign from 'lodash/assign';
import qs from 'qs';

export class ApiRequest {
  constructor (params) {
    this.params = params;
    this.allowCaching = params.allowCaching;
    this.invalidateCache = params.invalidateCache;
    this.method = params.method || 'GET';
    this.apiPath = params.apiPath;
    this.apiHost = params.apiHost;
    if (!this.apiHost) {
      this.apiHost = ApiRequest.apiHost;
    }
    this.headers = params.headers || {};
    this.body = params.body;
    this.query = params.query;
    this.fileList = params.fileList;
    if (params.body && params.body.token) {
      this.token = params.body.token;
    } else {
      this.token = ApiRequest.token();
    }
    this.longSessionToken = ApiRequest.longSessionToken();
    this.botManagerCookies = ApiRequest.botManagerCookies;

    this.sendJwtToken = params.sendJwtToken === undefined ? true : params.sendJwtToken;
    this.validateToken = params.validateToken === undefined ? true : params.validateToken;

    this.url = `${this.apiHost}${this.apiPath}`;
    if (this.query) {
      this.url += '?' + qs.stringify(this.query);
    }

    // bind methods to .this
    this.createRequestWithoutToken = this.createRequestWithoutToken.bind(this);
    this.createRequest = this.createRequest.bind(this);
    this.executeRequest = this.executeRequest.bind(this);
    this.execute = this.execute.bind(this);
    this.executeAndGetBody = this.executeAndGetBody.bind(this);
  }

  createRequestWithoutToken () {
    const r = request(this.method, this.url).withCredentials();

    _forEach(this.headers, (val, key) => {
      r.set(key, val);
    });
    if (this.fileList) {
      _forEach(this.fileList, ({ key, fileBlob, fileName }) => {
        r.attach(key, fileBlob, fileName);
      });
    }
    if (this.body) {
      r.send(this.body);
    }
    return r;
  }

  createRequest () {
    const r = this.createRequestWithoutToken();
    // Wrapping request because superagent is not Promise-friendly
    // (has .then method which confuses promise chain)
    const reqWrapper = { req: r };

    if (typeof window === 'undefined') {
      reqWrapper.req.set('Cookie', this.botManagerCookies);
    }

    if (!this.sendJwtToken) {
      return Promise.resolve(reqWrapper);
    }
    const { token, longSessionToken = null } = this;
    reqWrapper.req.set('Authorization', `Bearer ${token}`);
    longSessionToken && reqWrapper.req.set('LongSessionJwt', longSessionToken);
    return Promise.resolve(reqWrapper);
  }

  executeRequest (r) {
    return new Promise((resolve, reject) => {
      r.end((err, res) => {
        const apiResult = res ? {
          body: res.body,
          text: res.text
        } : {};
        if (err) {
          console.log(err, `Request ("${this.method}") to "${r.url}" FAILED.`); // eslint-disable-line no-console
          err.apiResponse = res;
          reject(err);
        } else {
          console.log(`Successful request ("${this.method}") to "${r.url}".`); // eslint-disable-line no-console
          resolve(apiResult);
        }
      });
    });
  }

  execute () {
    let reqWrapperHolder;
    return this.createRequest()
      .then((reqWrapper) => {
        reqWrapperHolder = reqWrapper;
        return this.executeRequest(reqWrapper.req);
      })
      .catch((error) => {
        if (error.status === 401 && !(reqWrapperHolder.req.url.includes('/security/login'))) {
          window.location.href = '/login';
        } else return Promise.reject(error);
      });
  }

  executeAndGetBody () {
    return this.execute()
      .then((res) => {
        return res.body;
      });
  }
}

export class FrontendApiRequest extends ApiRequest {
  constructor (params) {
    const defaultParams = {
      apiHost: '/', // This is a call to frontend API
      sendJwtToken: false,
      validateToken: false, // to avoid infinite loop
      allowCaching: false
    };

    super(_assign(defaultParams, params));
  }
}
