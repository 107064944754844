import React from 'react';
import { getLocalizedStringWithParam } from 'localization/localizer';
import { isValidQuantity, isValidQuantityWithMin, isValidQuantityWithMax } from 'client/utils/messageUtils';
import { Empty } from 'client/components/elements/empty/Empty';

if (process.browser) {
  require('./quantityErrorMessage.scss');
}

const INCORRECT_MIN_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_ERROR_KEY = 'QuantityErrorMessage.min.multiples.error.message';
const INCORRECT_MAX_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_ERROR_KEY = 'QuantityErrorMessage.max.multiples.error.message';
const INCORRECT_MIN_ORDERLINE_QUANTITY_ERROR_KEY = 'QuantityErrorMessage.min.error.message';
const INCORRECT_MAX_ORDERLINE_QUANTITY_ERROR_KEY = 'QuantityErrorMessage.max.error.message';
const QTY_MULTIPLIER_SHORT_ERROR_KEY = 'QuantityErrorMessage.input.short.message';
const QTY_MULTIPLIER_LONG_ERROR_KEY = 'QuantityErrorMessage.multiples.message.with.bound.limit';
const MIN_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_VALID_KEY = 'QuantityErrorMessage.min.multiple.valid.message';
const QTY_MULTIPLIER_SHORT_VALID_KEY = QTY_MULTIPLIER_SHORT_ERROR_KEY;
const QTY_MULTIPLIER_LONG_VALID_KEY = 'QuantityErrorMessage.input.multiples.message';
const INCORRECT_MIN_ORDERLINE_QUANTITY_VALID_KEY = 'QuantityErrorMessage.min.valid.message';

const getNearestLowerNumber = (quantity, qtyMultiplier) => {
  return (Math.trunc(quantity / qtyMultiplier)) * qtyMultiplier;
};

const quantityErrorMessage = (params, localizedKey, shortMessage = false) => {
  return (
    shortMessage ? <div className="row">
      {
        <div className="quantityErrorMessage_shortErrorMessage col-sm-12">
          { getLocalizedStringWithParam(localizedKey, { ...params }) }
        </div>
      }
    </div>
      : <div className="row">
        {
          <div className='quantityErrorMessage_errorMessage col-sm-12'>
            { getLocalizedStringWithParam(localizedKey, { ...params }) }
          </div>
        }
      </div>
  );
};

const quantityValidMessage = (params, localizedKey, shortMessage = false) => {
  return (
    shortMessage ? <div className="row">
      {
        <div className="quantityErrorMessage_shortValidMessage col-sm-12">
          { getLocalizedStringWithParam(localizedKey, { ...params }) }
        </div>
      }
    </div>
      : <div className="row">
        {
          <div className='quantityErrorMessage_validMessage col-sm-12'>
            { getLocalizedStringWithParam(localizedKey, { ...params }) }
          </div>
        }
      </div>
  );
};

const getErrorKey = (isQtyMultiplier, isValidQuantityMin, isValidQuantityMax, shortMessage, minOrderQuantity, qtyMultiplier) => {
  const QTY_MULTIPLIER_ERROR_KEY = shortMessage ? QTY_MULTIPLIER_SHORT_ERROR_KEY : QTY_MULTIPLIER_LONG_ERROR_KEY;
  if (!isQtyMultiplier && !isValidQuantityMin) {
    return minOrderQuantity > qtyMultiplier
      ? INCORRECT_MIN_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_ERROR_KEY
      : (minOrderQuantity === qtyMultiplier && minOrderQuantity === 1) ? QTY_MULTIPLIER_LONG_VALID_KEY : QTY_MULTIPLIER_ERROR_KEY;
  } else if (!isQtyMultiplier && !isValidQuantityMax) {
    return INCORRECT_MAX_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_ERROR_KEY;
  } else if (!isValidQuantityMin) {
    return INCORRECT_MIN_ORDERLINE_QUANTITY_ERROR_KEY;
  } else if (!isValidQuantityMax) {
    return INCORRECT_MAX_ORDERLINE_QUANTITY_ERROR_KEY;
  } else if (!isQtyMultiplier) {
    return QTY_MULTIPLIER_ERROR_KEY;
  }
  return false;
};

const getValidMessageKey = (isQtyMultiplier, isValidQuantityMin, minOrderQuantity, qtyMultiplier, shortMessage) => {
  const QTY_MULTIPLIER_VALID_KEY = shortMessage ? QTY_MULTIPLIER_SHORT_VALID_KEY : QTY_MULTIPLIER_LONG_VALID_KEY;
  if (isQtyMultiplier && isValidQuantityMin && (qtyMultiplier > 1 && minOrderQuantity > 1)) {
    return minOrderQuantity > qtyMultiplier
      ? MIN_ORDERLINE_QUANTITY_AND_QTY_MULTIPLIER_VALID_KEY
      : QTY_MULTIPLIER_VALID_KEY;
  } else if (isQtyMultiplier && qtyMultiplier > 1) {
    return QTY_MULTIPLIER_VALID_KEY;
  } else if (isValidQuantityMin && minOrderQuantity > 1) {
    return INCORRECT_MIN_ORDERLINE_QUANTITY_VALID_KEY;
  }
  return false;
};

const QuantityErrorMessage = ({ quantity, qtyMultiplier, shortMessage = false, minOrderQuantity, maxOrderQuantity = null }) => {
  const nearestLowerNumber = getNearestLowerNumber(quantity, qtyMultiplier);
  const nearestUpperNumber = nearestLowerNumber + qtyMultiplier;
  const boundMessage = nearestLowerNumber === 0 ? nearestUpperNumber : `${nearestLowerNumber} or ${nearestUpperNumber}`;
  const isQtyMultiplier = isValidQuantity(quantity, qtyMultiplier);
  const isValidQuantityMin = isValidQuantityWithMin(quantity, minOrderQuantity);
  const isValidQuantityMax = isValidQuantityWithMax(quantity, maxOrderQuantity);
  const messageParams = {
    qtyMultiplier,
    minOrderQuantity,
    minOrderValue: minOrderQuantity,
    maxOrderQuantity,
    maxOrderValue: maxOrderQuantity,
    boundMessage
  };

  const errorKey = getErrorKey(isQtyMultiplier, isValidQuantityMin, isValidQuantityMax, shortMessage, minOrderQuantity, qtyMultiplier);
  const validMessageKey = getValidMessageKey(isQtyMultiplier, isValidQuantityMin, minOrderQuantity, qtyMultiplier, shortMessage);
  if (errorKey) {
    return quantityErrorMessage(messageParams, errorKey, shortMessage);
  } else if (validMessageKey) {
    return quantityValidMessage(messageParams, validMessageKey, shortMessage);
  }
  return <Empty />;
};

export default QuantityErrorMessage;
