import { fromJS } from 'immutable';
import { SET_DATA, SET_DETAILS } from './payByApplePayActions';

const defaultState = fromJS({});

const payByApplePay = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DETAILS:
      return state.setIn([action.payload.selector, 'details'], action.payload.details);
    case SET_DATA:
      return state.setIn([action.payload.selector, 'data'], action.payload.data);
    default:
      return defaultState;
  }
};

export default payByApplePay;
