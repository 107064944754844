import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { PrimaryButton } from '../../controls/StyledForms/formButtons';

if (process.browser) { require('./AgeVerificationDialog.scss'); }

export const AgeVerificationDialog = ({ isVisible, onCloseHandler, onConfirmed }) => {
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const validate = () => {
    if (!confirmed) {
      setError('Please select to confirm you are over 18 to place order');
      return;
    }
    setError(false);
  };
  const onConfirmHandler = () => {
    setSubmitted(true);
    if (confirmed && onConfirmed) {
      onConfirmed();
    }
  };
  useEffect(() => { if (submitted) { validate(); } }, [confirmed, submitted]);
  return <Modal className="AgeVerificationDialog" show={ isVisible } onHide={ onCloseHandler }>
    <Modal.Header closeButton>
      <div className="AgeVerificationDialog_title">
         Age Verification
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="">
        <input data-e2e="AgeVerificationInput" type="checkbox" checked={ confirmed } onChange={ (e) => setConfirmed(e.target.checked) }
          className={ error ? 'AgeVerificationDialog_inputError' : '' }/>
        I confirm I am over 18 years of age
      </div>
      { error && <div className="AgeVerificationDialog_error">{ error }</div> }
      <PrimaryButton
        datae2e="AgeVerificationConfirmButton"
        className="AgeVerificationDialog_confirmButton"
        text="Confirm"
        onClick={ onConfirmHandler } />
    </Modal.Body>
  </Modal>;
};
