import React from 'react';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { addToQuote } from 'client/actions/globalQuotationActions';
import { fetchQuotations } from 'client/actions/quotationsActions';
import { PrimaryButton } from 'client/components/controls/StyledForms';

if (process.browser) {
  require('./AddToQuotationButtonInterstitial.scss');
}

class AddToQuotationButtonInterstitial extends React.PureComponent {
  componentDidMount () {
    if (this.props.isLoggedInOrhasValidLongSessionToken) {
      this.props.fetchQuotations();
    }
  }

  render () {
    const {
      productsToAddCallback,
      isDeliverable,
      isLoggedInOrhasValidLongSessionToken,
      redisplayDialogOnLogin,
      addToQuote,
      isQuantityValid
    } = this.props;

    const addToQuotation = () => {
      const productsToAdd = productsToAddCallback();
      const productToAdd = {
        sku: productsToAdd[0].sku,
        qty: productsToAdd[0].qty
      };

      addToQuote(productToAdd, false);
    };

    const isDisabled = !isDeliverable || !isQuantityValid;

    return (
      <div className="AddToQuotationButtonInterstitial">
        <PrimaryButton
          className={ `AddToQuotationButton` }
          type="button"
          active={ !(isDisabled) }
          disabled={ isDisabled }
          onClick={ addToQuotation }
        >
          <span className="AddToQuotationButton_Text">
            { (!isLoggedInOrhasValidLongSessionToken && !redisplayDialogOnLogin)
              ? getLocalizedString('productVariant.buttons.label.loginToCreateQuotation')
              : getLocalizedString('productVariant.buttons.quotationLabel') }
          </span>
        </PrimaryButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedInOrhasValidLongSessionToken: state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken'])
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuotations: () => dispatch(fetchQuotations()),
    addToQuote: (product, redirectToGlobalQuotation) => dispatch(addToQuote(product, redirectToGlobalQuotation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToQuotationButtonInterstitial);
