import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

const LoadError = () => (
  <Row>
    <Col md={ 12 }>
      Sorry, there was an error loading the cart
    </Col>
  </Row>
);

export default LoadError;
