import { createAction } from 'redux-actions';

export const SHOW_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG = 'dialogActions.SHOW_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG';
export const HIDE_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG = 'dialogActions.HIDE_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG';
export const SHOW_LOGIN_DIALOG = 'dialogActions.SHOW_LOGIN_DIALOG';
export const HIDE_LOGIN_DIALOG = 'dialogActions.HIDE_LOGIN_DIALOG';
export const TOGGLE_LOGIN_PAGE = 'dialogActions.TOGGLE_LOGIN_PAGE';
export const GO_TO_CONTACT_DETAILS = 'dialogActions.GO_TO_CONTACT_DETAILS';
export const DEFAULT_LOGIN_VALUES = 'dialogActions.DEFAULT_LOGIN_VALUES';
export const GO_TO_GET_STARTED = 'dialogActions.GO_TO_GET_STARTED';
export const GO_TO_RESET_PASSWORD = 'dialogActions.GO_TO_RESET_PASSWORD';

export const toggleLoginPage = createAction(TOGGLE_LOGIN_PAGE);
export const goToResetPassword = createAction(GO_TO_RESET_PASSWORD);
export const goToContactDetails = createAction(GO_TO_CONTACT_DETAILS);
export const defaultLoginStoreValues = createAction(DEFAULT_LOGIN_VALUES);
export const goToGetStarted = createAction(GO_TO_GET_STARTED);

export const hideNewLoginExitConfirmationDialog = createAction(HIDE_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG);
export const showNewLoginExitConfirmationDialog = createAction(SHOW_NEW_LOGIN_EXIT_CONFIRMATION_DIALOG);
export const showLoginDialog = (finalDestination, actionToDispatch) => ({
  type: SHOW_LOGIN_DIALOG,
  finalDestination,
  actionToDispatch
});

export const hideLoginDialog = createAction(HIDE_LOGIN_DIALOG);

export const HIDE_GLOBAL_QUOTE_REF_REMINDER = 'HIDE_GLOBAL_QUOTE_REF_REMINDER';
export const hideGlobalQuoteReminder = createAction(HIDE_GLOBAL_QUOTE_REF_REMINDER);
export const SHOW_GLOBAL_QUOTE_REF_REMINDER = 'SHOW_GLOBAL_QUOTE_REF_REMINDER';
export const showGlobalQuoteRefReminder = (nextPageUrl) => ({
  type: SHOW_GLOBAL_QUOTE_REF_REMINDER,
  nextPageUrl
});

export const SHOW_LOCKING_AND_SAVING_QUOTE_DIALOG = 'SHOW_LOCKING_AND_SAVING_QUOTE_DIALOG';
export const HIDE_LOCKING_AND_SAVING_QUOTE_DIALOG = 'HIDE_LOCKING_AND_SAVING_QUOTE_DIALOG';
export const showLockingAndSavingQuoteDialog = createAction(SHOW_LOCKING_AND_SAVING_QUOTE_DIALOG);
export const hideLockingAndSavingQuoteDialog = createAction(HIDE_LOCKING_AND_SAVING_QUOTE_DIALOG);

export const SHOW_MASK_CALL_DIALOG = 'SHOW_MASK_CALL_DIALOG';
export const HIDE_MASK_CALL_DIALOG = 'HIDE_MASK_CALL_DIALOG';
export const showMaskCallDialog = ({ variant }) => ({ type: SHOW_MASK_CALL_DIALOG, variant });
export const hideMaskCallDialog = createAction(HIDE_MASK_CALL_DIALOG);

export const SHOW_BUSINESS_ACCOUNT_ONHOLD_DIALOG = 'SHOW_BUSINESS_ACCOUNT_ONHOLD_DIALOG';
export const HIDE_BUSINESS_ACCOUNT_ONHOLD_DIALOG = 'HIDE_BUSINESS_ACCOUNT_ONHOLD_DIALOG';
export const showBusinessAccountOnHoldDialog = createAction(SHOW_BUSINESS_ACCOUNT_ONHOLD_DIALOG);
export const hideBusinessAccountOnHoldDialog = createAction(HIDE_BUSINESS_ACCOUNT_ONHOLD_DIALOG);

export const SHOW_NON_DELIVERABLE_ORDER_MESSAGE = 'SHOW_NON_DELIVERABLE_ORDER_MESSAGE';
export const HIDE_NON_DELIVERABLE_ORDER_MESSAGE = 'HIDE_NON_DELIVERABLE_ORDER_MESSAGE';
export const showNonDeliverableOrderMessage = createAction(SHOW_NON_DELIVERABLE_ORDER_MESSAGE);
export const hideNonDeliverableOrderMessage = createAction(HIDE_NON_DELIVERABLE_ORDER_MESSAGE);

export const SHOW_SAVING_QUOTE_DIALOG = 'SHOW_SAVING_QUOTE_DIALOG';
export const HIDE_SAVING_QUOTE_DIALOG = 'HIDE_SAVING_QUOTE_DIALOG';
export const showSavingQuoteDialog = createAction(SHOW_SAVING_QUOTE_DIALOG);
export const hideSavingQuoteDialog = createAction(HIDE_SAVING_QUOTE_DIALOG);

export const SHOW_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG = 'dialogActions.SHOW_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG';
export const HIDE_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG = 'dialogActions.HIDE_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG';

export const showTermsandConditionsDialog = createAction(SHOW_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG);
export const hideTermsandConditionsDialog = createAction(HIDE_DISCOUNT_TERMS_AND_CONDITIONS_DIALOG);

export const SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY = 'SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY';
export const setUnsupportedBrowserDialogVilisbility = createAction(SET_UNSUPPORTED_BROWSER_DIALOG_VISIBILITY);

export const SET_REMOVE_PRODUCT_DIALOG_VISIBILITY = 'SET_REMOVE_PRODUCT_DIALOG_VISIBILITY';

export const showRemoveProductDialog = (orderLine) => ({
  type: SET_REMOVE_PRODUCT_DIALOG_VISIBILITY,
  visible: true,
  orderLine
});

export const hideRemoveProductDialog = () => ({
  type: SET_REMOVE_PRODUCT_DIALOG_VISIBILITY,
  visible: false,
  orderLine: {}
});

export const SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY = 'SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY';

export const showRemoveProductBundleDialog = (bundleLine) => ({
  type: SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY,
  visible: true,
  bundleLine
});

export const hideRemoveProductBundleDialog = () => ({
  type: SET_REMOVE_PRODUCT_BUNDLE_DIALOG_VISIBILITY,
  visible: false
});

export const SHOW_ADD_TO_QUOTATION_DIALOG = 'dialogActions.SHOW_ADD_TO_QUOTATION_DIALOG';
export const showAddToQuotationDialog = (products) => ({
  type: SHOW_ADD_TO_QUOTATION_DIALOG,
  products
});

export const HIDE_ADD_TO_QUOTATION_DIALOG = 'dialogActions.HIDE_ADD_TO_QUOTATION_DIALOG';
export const hideAddToQuotationDialog = createAction(HIDE_ADD_TO_QUOTATION_DIALOG);

export const SHOW_ADD_TO_BASKET_DIALOG = 'dialogActions.SHOW_ADD_TO_BASKET_DIALOG';
export const showAddToBasketDialog = (products) => ({
  type: SHOW_ADD_TO_BASKET_DIALOG,
  products
});

export const HIDE_ADD_TO_BASKET_DIALOG = 'dialogActions.HIDE_ADD_TO_BASKET_DIALOG';
export const hideAddToBasketDialog = createAction(HIDE_ADD_TO_BASKET_DIALOG);

export const SHOW_CUSTOM_ALERT_DIALOG = 'dialogActions.SHOW_CUSTOM_ALERT_DIALOG';
export const showCustomAlertDialog = (message) => ({
  type: SHOW_CUSTOM_ALERT_DIALOG,
  message
});

export const HIDE_CUSTOM_ALERT_DIALOG = 'dialogActions.HIDE_CUSTOM_ALERT_DIALOG';
export const hideCustomAlertDialog = createAction(HIDE_CUSTOM_ALERT_DIALOG);

export const CHECK_DISCOUNT_CODE_DIALOG = 'dialogActions.CHECK_DISCOUNT_CODE_DIALOG';
export const checkDiscountCodeDialog = createAction(CHECK_DISCOUNT_CODE_DIALOG);

export const SHOW_DISCOUNT_CODE_DIALOG = 'dialogActions.SHOW_DISCOUNT_CODE_DIALOG';
export const showDiscountCodeDialog = createAction(SHOW_DISCOUNT_CODE_DIALOG);

export const HIDE_DISCOUNT_CODE_DIALOG = 'dialogActions.HIDE_DISCOUNT_CODE_DIALOG';
export const hideDiscountCodeDialog = createAction(HIDE_DISCOUNT_CODE_DIALOG);

export const SHOW_REMOVE_QUOTE_LINE_DIALOG = 'dialogActions.SHOW_REMOVE_QUOTE_LINE_DIALOG';
export const showRemoveQuoteLineDialog = (product, delQuotation) => ({
  type: SHOW_REMOVE_QUOTE_LINE_DIALOG,
  quoteLine: product,
  delQuotation
});

export const HIDE_REMOVE_QUOTE_LINE_DIALOG = 'dialogActions.HIDE_REMOVE_QUOTE_LINE_DIALOG';
export const hideRemoveQuoteLineDialog = () => ({
  type: HIDE_REMOVE_QUOTE_LINE_DIALOG,
  quoteLine: {}
});

export const SHOW_REMOVE_QUOTATION_DIALOG = 'dialogActions.SHOW_REMOVE_QUOTATION_DIALOG';
export const showRemoveQuotationDialog = (quotationId) => ({
  type: SHOW_REMOVE_QUOTATION_DIALOG,
  quotationId
});

export const HIDE_REMOVE_QUOTATION_DIALOG = 'dialogActions.HIDE_REMOVE_QUOTATION_DIALOG';
export const hideRemoveQuotationDialog = createAction(HIDE_REMOVE_QUOTATION_DIALOG);

export const SHOW_CHANGE_IN_QUOTE_LINE_QTY_DIALOG = 'dialogActions.SHOW_CHANGE_IN_QUOTE_LINE_QTY_DIALOG';
export const showChangeInQuoteLineQtyDialog = (quotationId, qty, sku, breakPrices) => ({
  type: SHOW_CHANGE_IN_QUOTE_LINE_QTY_DIALOG,
  visible: true,
  quotationId,
  qty,
  sku,
  breakPrices
});

export const HIDE_CHANGE_IN_QUOTE_LINE_QTY_DIALOG = 'dialogActions.HIDE_CHANGE_IN_QUOTE_LINE_QTY_DIALOG';
export const hideChangeInQuoteLineQtyDialog = () => ({
  type: HIDE_CHANGE_IN_QUOTE_LINE_QTY_DIALOG,
  visible: false
});

export const SHOW_REMOVE_REPEAT_ORDER_DIALOG = 'dialogActions.SHOW_REMOVE_REPEAT_ORDER_DIALOG';
export const showRemoveRepeatOrderDialog = (repeatOrderId) => ({
  type: SHOW_REMOVE_REPEAT_ORDER_DIALOG,
  repeatOrderId
});

export const HIDE_REMOVE_REPEAT_ORDER_DIALOG = 'dialogActions.HIDE_REMOVE_REPEAT_ORDER_DIALOG';
export const hideRemoveRepeatOrderDialog = (repeatOrderId) => ({
  type: HIDE_REMOVE_REPEAT_ORDER_DIALOG,
  repeatOrderId
});

export const SHOW_PAUSE_PLAY_REPEAT_ORDER_DIALOG = 'dialogActions.SHOW_PAUSE_PLAY_REPEAT_ORDER_DIALOG';
export const showPausePlayRepeatOrderDialog = (repeatOrder, newState, nextDeliveryDate) => ({
  type: SHOW_PAUSE_PLAY_REPEAT_ORDER_DIALOG,
  repeatOrder,
  newState,
  nextDeliveryDate
});

export const HIDE_PAUSE_PLAY_REPEAT_ORDER_DIALOG = 'dialogActions.HIDE_PAUSE_PLAY_REPEAT_ORDER_DIALOG';
export const hidePausePlayRepeatOrderDialog = (repeatOrder) => ({
  type: HIDE_PAUSE_PLAY_REPEAT_ORDER_DIALOG,
  repeatOrder
});

export const SHOW_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG = 'dialogActions.SHOW_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG';
export const showRemoveProductRepeatOrderDialog = (repeatOrderId) => ({
  type: SHOW_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG,
  repeatOrderId
});

export const HIDE_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG = 'dialogActions.HIDE_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG';
export const hideRemoveProductRepeatOrderDialog = (repeatOrderId) => ({
  type: HIDE_REMOVE_PRODUCT_REPEAT_ORDER_DIALOG,
  repeatOrderId
});

export const SHOW_EDIT_REPEAT_ORDER_DIALOG = 'dialogActions.SHOW_EDIT_REPEAT_ORDER_DIALOG';
export const showEditRepeatOrderDialog = (newRegularity, repeatOrderId, orderReference, endDate) => ({
  type: SHOW_EDIT_REPEAT_ORDER_DIALOG,
  newRegularity,
  repeatOrderId,
  orderReference,
  endDate
});

export const HIDE_EDIT_REPEAT_ORDER_DIALOG = 'dialogActions.HIDE_EDIT_REPEAT_ORDER_DIALOG';
export const hideEditRepeatOrderDialog = (repeatOrderId) => ({
  type: HIDE_EDIT_REPEAT_ORDER_DIALOG,
  repeatOrderId
});
export const SHOW_REMOVE_ADDRESS_DIALOG = 'dialogActions.SHOW_REMOVE_ADDRESS_DIALOG';
export const showRemoveAddressDialog = (addressId) => ({
  type: SHOW_REMOVE_ADDRESS_DIALOG,
  addressId
});

export const HIDE_REMOVE_ADDRESS_DIALOG = 'dialogActions.HIDE_REMOVE_ADDRESS_DIALOG';
export const hideRemoveAddressDialog = createAction(HIDE_REMOVE_ADDRESS_DIALOG);

export const SHOW_REMOVE_PAYMENT_METHOD_DIALOG = 'dialogActions.SHOW_REMOVE_PAYMENT_METHOD_DIALOG';
export const showRemovePaymentMethodDialog = (cardType, cardLastFourDigits) => ({
  type: SHOW_REMOVE_PAYMENT_METHOD_DIALOG,
  cardType,
  cardLastFourDigits
});

export const HIDE_REMOVE_PAYMENT_METHOD_DIALOG = 'dialogActions.HIDE_REMOVE_PAYMENT_METHOD_DIALOG';
export const hideRemovePaymentMethodDialog = createAction(HIDE_REMOVE_PAYMENT_METHOD_DIALOG);

export const SHOW_BRAINTREE_CVV_CHECK_DIALOG = 'dialogActions.SHOW_BRAINTREE_CVV_CHECK_DIALOG';
export const showBraintreeCvvCheckDialog = createAction(SHOW_BRAINTREE_CVV_CHECK_DIALOG);

export const HIDE_BRAINTREE_CVV_CHECK_DIALOG = 'dialogActions.HIDE_BRAINTREE_CVV_CHECK_DIALOG';
export const hideBraintreeCvvCheckDialog = createAction(HIDE_BRAINTREE_CVV_CHECK_DIALOG);

export const HIDE_CANCEL_ORDER_CONFIRMATION_DIALOG = 'dialogActions.HIDE_CANCEL_ORDER_CONFIRMATION_DIALOG';
export const hideCancelOrderConfirmationDialog = createAction(HIDE_CANCEL_ORDER_CONFIRMATION_DIALOG);

export const SHOW_CANCEL_ORDER_CONFIRMATION_DIALOG = 'dialogActions.SHOW_CANCEL_ORDER_CONFIRMATION_DIALOG';
export const showCancelOrderConfirmationDialog = createAction(SHOW_CANCEL_ORDER_CONFIRMATION_DIALOG);

export const SET_CANCEL_ORDER_CHECKED = 'dialogActions.SET_CANCEL_ORDER_CHECKED';
export const setCancelOrderChecked = (checked) => ({
  type: SET_CANCEL_ORDER_CHECKED,
  checked
});

export const SHOW_CHECKED_ERROR = 'dialogActions.SHOW_CHECKED_ERROR';
export const showCheckedError = createAction(SHOW_CHECKED_ERROR);

export const HIDE_CANCEL_RETURN_CONFIRMATION_DIALOG = 'dialogActions.HIDE_CANCEL_RETURN_CONFIRMATION_DIALOG';
export const setCancelReturnConfirmationDialog = (isVisible) => ({
  type: HIDE_CANCEL_RETURN_CONFIRMATION_DIALOG,
  isVisible
});

export const SHOW_VALIDATION_MESSAGE_DIALOG = 'dialogActions.SHOW_VALIDATION_MESSAGE_DIALOG';
export const showValidationMessageDialog = (payload) => ({
  type: SHOW_VALIDATION_MESSAGE_DIALOG,
  payload
});

export const SHOW_RETURN_CONFIRMATION_DIALOG = 'dialogActions.SHOW_RETURN_CONFIRMATION_DIALOG';
export const showReturnConfirmationDialog = (isVisible, proceedHandler) => ({
  type: SHOW_RETURN_CONFIRMATION_DIALOG,
  isVisible,
  proceedHandler
});
