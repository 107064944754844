import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, reset } from 'redux-form/immutable';
import { Link } from 'react-router';
import { getLocalizedString } from 'localization/localizer';
import { makeValidator, isRequired, isEmail } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import {
  setFinalDestination,
  removeLoginError,
  onSetEmailForForgotPassword,
  onForgotPassword,
  loginUser
} from 'client/actions/userActions';
import { defaultLoginStoreValues } from 'client/actions/ui/dialogActions';
import {
  LOGIN_VIEW_IMAGE,
  FORGOT_PASSWORD_VIEW,
  WELCOME_ACCOUNT_VIEW,
  LOGIN_VIEW
} from '../constants';
import { setView } from 'client/actions/loginAndRegistration';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import get from 'lodash/get';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import SvgLoader from 'client/components/svg/SvgLoader';
import { fromJS } from 'immutable';

if (process.browser) {
  require('./LoginForm.scss');
}

const validate = makeValidator({
  email: [
    isRequired('Email'),
    isEmail()
  ],
  password: [
    isRequired('Password')
  ]
});
const FORM_NAME = 'newRegistrationAndLoginForm';

const LoginForm = (props) => {
  useEffect(() => {
    const {
      dispatchSetFinalDestination,
      dispatchRemoveLoginError,
      finalDestination,
      actionToDispatch,
      onResetReduxForm,
      dispatchInitialStoreValues,
      dispatchSetView
    } = props;

    onResetReduxForm(reset(FORM_NAME));
    const cleanedFinalDestination = finalDestination !== '/login' ? finalDestination : '/';
    dispatchSetFinalDestination(cleanedFinalDestination, actionToDispatch);
    dispatchRemoveLoginError();
    dispatchInitialStoreValues();
    dispatchSetView(LOGIN_VIEW);
  }, []);

  const {
    loginUser,
    loginError,
    handleSubmit,
    loginFormValues: values,
    dispatchOnSetEmailForForgotPassword,
    dispatchSetView,
    isLoading
  } = props;

  const submit = (values) => {
    return validateForm(values, validate)
      .then(() => {
        loginUser(
          values.get('email'),
          values.get('password')
        );
      });
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      handleSubmit(submit)();
    }, 0);
  };

  const validEmailEntered = (values) => {
    if (!values || (values && !values.get('email'))) {
      return false;
    }
    return !validate(values).email;
  };

  const onForgotPasswordClick = (values) => {
    if (validEmailEntered(values)) {
      dispatchOnSetEmailForForgotPassword(values.get('email'));
    }

    dispatchSetView(FORGOT_PASSWORD_VIEW);
  };

  return (<div className="col-xs-12 NewLoginForm_container">
    <div className="hidden-xs hidden-sm col-xs-5 NewLoginForm_leftImageContainer">
      <ZoroImage
        className="NewLoginForm_leftImage"
        src={ LOGIN_VIEW_IMAGE } />
    </div>
    <form onSubmit={ handleSubmit(submit) } className="col-xs-12 col-md-7 NewLoginForm">
      <div className="NewLoginForm_logo">
        <SvgLoader xlinkHref="#zoro-logo-headerBig"/>
      </div>
      <div className="NewLoginForm_formArea">
        <h1 className="NewLoginForm_title">{ getLocalizedString('login.signInToYourAccount.text') }</h1>
        <div>
          <Field
            name="email"
            id="email"
            className="form-control NewLoginForm_input"
            component={ FieldWithValidations }
            type="email"
            labelClassName="control-label required"
            placeholderResourceName="login.email.placeholder"
            datae2e="email"
          />
        </div>
        <div>
          <Field
            name="password"
            id="password"
            className="form-control NewLoginForm_input"
            component={ FieldWithValidations }
            type="password"
            labelClassName="control-label required"
            placeholderResourceName="login.password.placeholder"
            datae2e="password"
          />
        </div>
        <div className="text-right">
          <Link
            id="Login.forgotPassword"
            className="NewLoginForm_forgotPassword"
            data-e2e="Forgot your password"
            onClick={ () => onForgotPasswordClick(values) }
          >
            { getLocalizedString('login.forgotPassword.text') }
          </Link>
        </div>
        { loginError ? <span className="NewLoginForm_loginError">{loginError}</span> : null }
        <div className="row">
          <div className="NewLoginForm_loginButton col-xs-12">
            <PrimaryButton
              type="submit"
              datae2e="sign-in"
              onClick={ onSubmitClick }
              disabled={ isLoading }
            >
              { isLoading
                ? <SvgLoader xlinkHref="#loading-disc" className="loader"/>
                : getLocalizedString('registration.button.login') }
            </PrimaryButton>
          </div>
        </div>
        <div className="NewLoginForm_newUserButton col-xs-12">
          <SecondaryButton
            datae2e="go-to-registration"
            onClick={ () => dispatchSetView(WELCOME_ACCOUNT_VIEW) }
            onMouseDown={ (e) => e.preventDefault() }
          >
            { getLocalizedString('login.createAccountButton.text') }
          </SecondaryButton>
        </div>
        <div className="row">
          <div className="NewLoginForm_featuresTitle">Create a Zoro account for access to:</div>
          <ul className="NewLoginForm_featuresList">
            <li>Exclusive savings</li>
            <li>Delivery tracking</li>
            <li>Tiered pricing discounts with free delivery</li>
            <li>and lots more business friendly features</li>
          </ul>
        </div>
      </div>
    </form>
  </div>);
};

export const mapStateToProps = (state, ownProps) => {
  const email = (ownProps) ? get(ownProps, 'email', '') : '';
  return {
    loginFormValues: getFormValues(FORM_NAME)(state),
    unleashToggles: state.getIn(['unleash', 'toggles'], fromJS([])).toJS(),
    view: state.getIn(['loginAndRegistration', 'view']),
    loginError: state.getIn(['ui', 'loginForm', 'error']),
    isLoading: state.getIn(['loginAndRegistration', 'isLoading'], false),
    initialValues: {
      email
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  onForgotPassword: (email, loginType) => dispatch(onForgotPassword(email, loginType)),
  dispatchSetView: (view) => dispatch(setView({ view })),
  dispatchSetFinalDestination: (...args) => dispatch(setFinalDestination(...args)),
  dispatchOnSetEmailForForgotPassword: (...args) => dispatch(onSetEmailForForgotPassword(...args)),
  dispatchRemoveLoginError: () => dispatch(removeLoginError()),
  dispatchInitialStoreValues: () => dispatch(defaultLoginStoreValues()),
  onResetReduxForm: () => dispatch(reset(FORM_NAME)),
  loginUser: (email, password) => {
    dispatch(loginUser(email, password));
  }
});

const reduxLoginForm = reduxForm({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: true
})(LoginForm);

export { LoginForm };
export default connect(mapStateToProps, mapDispatchToProps)(reduxLoginForm);
