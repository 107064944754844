import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';

import { updateSessionCam } from 'client/actions/sessionCamActions';

import SvgLoader from 'client/components/svg/SvgLoader';

if (process.browser) {
  require('./ScreenLoadPending.scss');
}

const TIMEOUT_DELAY = 3000;

class ScreenLoadPending extends Component {
  constructor (props) {
    super(props);

    this.updateSessionTimer = null;
    this.clearUpdateSessionTime = false;
  }

  componentDidMount () {
    this.clearUpdateSessionTime = true;
    this.updateSessionTimer = setTimeout(() => {
      this.clearUpdateSessionTime = false;
      this.onTimerExpire();
    }, TIMEOUT_DELAY);
  }

  onTimerExpire () {
    const { updateSessionCam } = this.props;
    updateSessionCam({ key: 'ScreenLoadPending', value: 'Present' });
  }

  componentWillUnmount () {
    if (this.clearUpdateSessionTime) {
      clearTimeout(this.updateSessionTimer);
      this.updateSessionTimer = null;
      this.clearUpdateSessionTime = false;
    }
  }

  render () {
    return (
      <div className="ScreenLoadPending">
        <div className="spinner fadein">
          <SvgLoader className={ this.props.className } xlinkHref="#loading-nut" viewBox="0 0 100 100" />
        </div>
      </div>
    );
  }
}

ScreenLoadPending.propTypes = {
  updateSessionCam: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({
  updateSessionCam: flowRight(dispatch, updateSessionCam)
});

export { ScreenLoadPending };
export default connect(mapStateToProps, mapDispatchToProps)(ScreenLoadPending);
