import { createAction } from 'redux-actions';

export const SELECTED_INDEX = 'SELECTED_INDEX';
export const RESET_SELECTED_INDEX = 'RESET_SELECTED_INDEX';
export const selectedIndex = createAction(SELECTED_INDEX);
export const resetSelectedIndex = createAction(RESET_SELECTED_INDEX);

export const GO_TO_SELECTED_TAB = 'GO_TO_SELECTED_TAB';
export const goToSelectedTab = createAction(GO_TO_SELECTED_TAB);

export const ALL_TABS = 'ALL_TABS';
export const allTabsData = createAction(ALL_TABS);
