import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import SvgLoader from 'client/components/svg/SvgLoader';
import { getLocalizedString } from 'localization/localizer';
import {
  DELIVERY_ADDRESS_ACCORDION_PANEL_ID,
  PAYMENT_ACCORDION_PANEL_ID
} from 'shared/constants/singlePageCheckout';

import {
  PAY_ON_ACCOUNT,
  CARD,
  PAYPAL,
  SAVED_CARD
} from 'shared/constants/braintree';

import { getDefaultPaymentData } from 'client/utils/braintreeUtils';
import { removeEmptyStringFromAddress } from 'client/utils/singlePageCheckoutUtils';
import PaymentAccordionHeader from './PaymentAccordionHeader';
import { CREDIT } from 'shared/constants/account';

const PAYMENT_TYPE_NAME = 'payment';
const ADDRESS_TYPE_NAME = 'address';
const REQUIRED_TYPE_NAME = 'message';

const showExtraChargeMessage = () => {
  const delvText1 = `${getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text1')} ${getLocalizedString('singlePageCheckout.zone1.zone2.extra.delv.charge.text8')}`;
  return <div className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_rowSpace">
    <SvgLoader xlinkHref="yellow-alert" />
    {delvText1}
  </div>;
};

export const displayAddress = ({ deliveryAddress, validNonDeliverableStatus = false, showFarFlungDelvMessage, showOnHoldBanner, accountStatusMessage }, showNonDeliverableMessage, setActivePanelId) => {
  if (!deliveryAddress) {
    return null;
  }
  const displayNonDeliverableMessage = showNonDeliverableMessage && validNonDeliverableStatus;
  const formattedDeliveryAddress = removeEmptyStringFromAddress(getLocalizedString('singlePageCheckout.addessBook.formattedAddress')
    .replace('{companyName}', deliveryAddress.get('companyName') ? deliveryAddress.get('companyName') : '')
    .replace('{addressLine1}', deliveryAddress.get('addressLine1'))
    .replace('{addressLine2}', deliveryAddress.get('addressLine2') ? deliveryAddress.get('addressLine2') : '')
    .replace('{city}', deliveryAddress.get('city'))
    .replace('{postalCode}', deliveryAddress.get('postalCode')));
  const nonDeliverableMessage = `${getLocalizedString('singlePageCheckout.non.deliverable.address.text.1')} ${getLocalizedString('singlePageCheckout.non.deliverable.product.text.2')}`;
  const parcelForceZone = deliveryAddress.get('parcelForceZone');
  return (
    <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_addressInfo">
      <div>
        <span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_name">
          { deliveryAddress.get('firstName')} { deliveryAddress.get('lastName')}
        </span>
        {formattedDeliveryAddress}
        { (showFarFlungDelvMessage && parcelForceZone !== 1) ? showExtraChargeMessage() : null}
      </div>
      { showOnHoldBanner && accountStatusMessage
        ? <div className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_rowSpace">
          <SvgLoader xlinkHref="yellow-alert" />
          <span className='CheckoutPage_panelHeading_panelDetails_panelDetailsContent_rowSpace_heading'> { accountStatusMessage?.heading || '' }: </span> { accountStatusMessage?.smallContent || '' }
        </div>
        : null
      }
      { displayNonDeliverableMessage
        ? <div className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_rowSpace">
          <SvgLoader xlinkHref="yellow-alert" />
          {nonDeliverableMessage}
        </div>
        : null
      }
    </span>
  );
};

const displayRequiredMessage = (message) => {
  return (<span className="CheckoutPage_panelHeading_panelDetails_panelDetailsContent_addressInfo">{message}</span>);
};

const contentType = {
  [ADDRESS_TYPE_NAME]: (data, selector, setActivePanelId) => displayAddress(data, true, setActivePanelId),
  [PAYMENT_TYPE_NAME]: (data, selector) => <PaymentAccordionHeader selector={ selector } />,
  [REQUIRED_TYPE_NAME]: (data) => displayRequiredMessage(data)
};

const displayPanelDetails = (type, data, selector, setActivePanelId) => {
  return (
    <div className="CheckoutPage_panelHeading_panelDetails">
      <div className="row">
        <div className="col-xs-11 CheckoutPage_panelHeading_panelDetails_panelDetailsContent">
          {
            contentType[type](data, selector, setActivePanelId)
          }
        </div>
      </div>
    </div>
  );
};

export const PanelHeading = (props) => {
  const {
    activePanelId,
    panelId,
    panelName,
    deliveryAddress,
    savedPaymentMethods,
    paymentMethod,
    paymentInformation,
    isPaymentFlowSkipped,
    skipPaymentFlow,
    csAgentName,
    billingAddress,
    selector,
    validNonDeliverableStatus,
    showFarFlungDelvMessage,
    setActivePanelId,
    accountDetails
  } = props;
  const accountType = accountDetails.getIn([ 'tradeAccount', 'accountType']);
  const getLinkHref = () => {
    return activePanelId === panelId ? '#arrow-up' : '#arrow-down';
  };
  const shouldDisablePanel = csAgentName && isPaymentFlowSkipped && panelId === PAYMENT_ACCORDION_PANEL_ID;
  const isNewPaymentDetails = paymentInformation && paymentInformation.paymentType !== SAVED_CARD && paymentInformation.braintreePayload;

  const paymentData = accountType === CREDIT && paymentMethod === PAY_ON_ACCOUNT
    ? {}
    : isNewPaymentDetails
      ? {
        paymentType: paymentInformation.paymentType === CARD ? CARD : PAYPAL,
        cardType: paymentInformation.braintreePayload.details.cardType,
        cardEnding: paymentInformation.braintreePayload.details.lastFour,
        payPalEmail: paymentInformation.braintreePayload.details.email,
        billingAddress: billingAddress
      }
      : getDefaultPaymentData(savedPaymentMethods, paymentMethod);

  return (
    <Panel.Heading
      className={ `${shouldDisablePanel ? 'CheckoutPage_disablePanel' : ''}` }
      onClick={ () => props.onToggleAccordionState({ panelId, csAgentName: props.csAgentName }) }
    >
      <Panel.Title className="CheckoutPage_panelHeading_panelTitle" toggle>
        {
          getLocalizedString('singlePageCheckout.checkout.panelName').replace('{panelId}', panelId).replace('{panelName}', panelName)
        }
        <SvgLoader xlinkHref={ getLinkHref() } className="CheckoutPage_panelHeading_toggleIcon" data-e2e="CheckoutPage_panelHeading_toggleIcon" />

        {
          shouldDisablePanel ? getLocalizedString('singlePageCheckout.csAgentMessageForFoc') : ''
        }
        {
          panelId === DELIVERY_ADDRESS_ACCORDION_PANEL_ID && deliveryAddress && activePanelId !== DELIVERY_ADDRESS_ACCORDION_PANEL_ID
            ? displayPanelDetails(ADDRESS_TYPE_NAME, { deliveryAddress, validNonDeliverableStatus, showFarFlungDelvMessage }, null, setActivePanelId)
            : null
        }

        {
          panelId === PAYMENT_ACCORDION_PANEL_ID && activePanelId !== PAYMENT_ACCORDION_PANEL_ID
            ? displayPanelDetails(PAYMENT_TYPE_NAME, paymentData, selector)
            : null
        }
      </Panel.Title>
      {
        csAgentName && panelId === PAYMENT_ACCORDION_PANEL_ID
          ? <div className="CheckoutPage_skipPayment">
            <hr/>
            { getLocalizedString('singlePageCheckout.skipPayment') }
            <input type="checkbox"
              defaultChecked={ isPaymentFlowSkipped }
              onChange={ (e) => skipPaymentFlow({ isPaymentFlowSkipped: e.target.checked }) }
            />
          </div>
          : null
      }
    </Panel.Heading>
  );
};
