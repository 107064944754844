import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CmsContentScreen from '../../../screens/cmsContentScreen/CmsContentScreen';
import { fromJS } from 'immutable';

if (process.browser) {
  require('./CmsTwoColPanel.scss');
}

class CmsTwoColPanel extends Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.object
  }

  validateComponent (component) {
    return !!component && component.get('type') !== 'TWO_COL_PANEL';
  }

  render () {
    const rightComponent = this.props.data.get('rightComponent');
    const leftComponent = this.props.data.get('leftComponent');
    if (this.validateComponent(rightComponent) && this.validateComponent(leftComponent)) {
      return (
        <div className="CmsTwoColPanel">
          <div className="CmsTwoColPanel__rightComponent">
            <CmsContentScreen
              { ...this.props }
              pageUrl="rightComponent"
              pageData={ fromJS({ cmsComponents: [rightComponent.toJS()] }) }
            />
          </div>
          <div className="CmsTwoColPanel__leftComponent">
            <CmsContentScreen
              { ...this.props }
              pageUrl="leftComponent"
              pageData={ fromJS({ cmsComponents: [leftComponent.toJS()] }) }
            />
          </div>
        </div>
      );
    }
    return <div>Error in Component... (must have exactly 2 Columns)</div>;
  }
}

export default CmsTwoColPanel;
