import { fork, call, takeLatest, all, put, select } from 'redux-saga/effects';
import {
  GOTO_ORDER_REVIEW,
  GOTO_EXTERNAL_URL,
  GOTO_URL,
  REPLACE_URL,
  ROUTE_ACCESS_DENIED,
  goToUrl,
  gotoExternalUrl
} from 'client/actions/routeActions';
import { browserHistory } from 'react-router';
import { LOGOUT_USER, RECEIVE_INTERSTITIAL_PAGE_UNLOAD_REQUEST } from 'client/actions/userActions';
import { forceUserLogout } from 'shared/endpoints/userEndpoint';
import { setFinalDestination, removeFinalDestination, unloadReachedInterstitalPage, updateIsLoginState } from '../actions/userActions';
import { RECEIVE_ACCOUNT_DETAILS } from 'client/actions/accountActions';
import { CART_ROUTE, DELIVERY_CHECKOUT_ROUTE, LOGIN_ROUTE, SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { getCart } from 'shared/endpoints/cartEndpoint';
import { FREE_DELIVERY_THRESHOLD } from 'shared/constants/common';
import { isZoneTwoOrThree } from 'client/utils/userDataUtils';
import { saveScheduledAllOrderlinesSettings } from '../actions/scheduledOrders';

const parcelForceZoneSelector = (state) => state.getIn(['user', 'accountDetails', 'parcelForceZone']);

export function * gotoOrderReview ({ deliveryAddressId, billingAddressId, poReference, payOnAccount, fromQuotationId, isGlobal }) {
  let url = `/checkout/order-review?deliveryAddressId=${deliveryAddressId}&billingAddressId=${billingAddressId}${payOnAccount ? '&onAccount=y' : ''}`;
  url = fromQuotationId
    ? isGlobal
      ? `${url}&fromQuotationId=${encodeURIComponent(fromQuotationId)}&isGlobal=${encodeURIComponent(true)}`
      : `${url}&fromQuotationId=${encodeURIComponent(fromQuotationId)}`
    : url;
  url = poReference ? `${url}&poReference=${encodeURIComponent(poReference)}` : url;
  yield call(browserHistory.push, url);
}

function * watchGotoReview () {
  yield takeLatest(GOTO_ORDER_REVIEW, gotoOrderReview);
}

export function * routeAccessDenied ({
  replace,
  cb,
  redirectUrl = '/login',
  finalDestination = '/',
  isRedirectLoading
}) {
  yield put(setFinalDestination(finalDestination, null, isRedirectLoading));
  if (redirectUrl.startsWith(LOGIN_ROUTE)) {
    yield put(gotoExternalUrl(redirectUrl));
  } else {
    yield call(replace, redirectUrl);
  }
  cb();
}

function * watchRouteAccessDenied () {
  yield takeLatest(ROUTE_ACCESS_DENIED, routeAccessDenied);
}

export function * replaceUrlHandler ({ url }) {
  yield call(browserHistory.replace, url);
}

function * watchReplaceUrl () {
  yield takeLatest(REPLACE_URL, replaceUrlHandler);
}

export function * goToUrlHandler ({ url, handleInServer, openInANewTab }) {
  if (openInANewTab) {
    window.open(url, '_blank');
    return;
  }

  if (handleInServer || url === CART_ROUTE) {
    window.location.assign(url);
  } else {
    yield call(browserHistory.push, url);
  }
}

function * watchGoToUrl () {
  yield takeLatest(GOTO_URL, goToUrlHandler);
}

export function * redirectToFinalDestination (action) {
  if (action.isLoginSuccess) { // REDIRECT ONLY WHEN USER TRIES TO LOGIN
    const finalDestinationSelector = (state) => state.getIn(['login', 'finalDestination'], null);
    let finalDestination = yield select(finalDestinationSelector);
    const actionToDispatchSelector = (state) => state.getIn(['login', 'actionToDispatch'], null);
    const actionToDispatch = yield select(actionToDispatchSelector);
    const tradeAccountSelector = (state) => state.getIn(['user', 'accountDetails', 'tradeAccount'], null);
    const tradeAccount = yield select(tradeAccountSelector);
    const isRedirectToCheckout = finalDestination === '/cart' && !tradeAccount;

    if (isRedirectToCheckout) {
      // USER ISNT A TRADEACCOUNT USER & SHALL BE REDIRECTED TO BILLING PAGE
      finalDestination = SINGLE_PAGE_CHECKOUT_ROUTE;
    }

    if ([SINGLE_PAGE_CHECKOUT_ROUTE, DELIVERY_CHECKOUT_ROUTE].includes(finalDestination)) {
      const scheduledOrdersFormSettings = yield select(state => state.getIn(['scheduledOrders', 'forms']));

      if (scheduledOrdersFormSettings?.size) {
        yield put(saveScheduledAllOrderlinesSettings());
      }
    }

    const reachedInterstitialPageSelector = (state) => state.getIn(['login', 'reachedInterstitialPage'], null);
    const reachedInterstitialPage = yield select(reachedInterstitialPageSelector);
    if (finalDestination && !reachedInterstitialPage) {
      if (isRedirectToCheckout || finalDestination === DELIVERY_CHECKOUT_ROUTE) {
        const cart = yield call(getCart);
        const parcelForceZone = yield select(parcelForceZoneSelector);
        const isEligibleForFreeDelivery = cart?.cartPrice.cartOrderLinesTotalWithVat >= FREE_DELIVERY_THRESHOLD || isZoneTwoOrThree(parcelForceZone);
        if (isEligibleForFreeDelivery) {
          yield put(goToUrl(SINGLE_PAGE_CHECKOUT_ROUTE));
        } else {
          yield put(gotoExternalUrl(DELIVERY_CHECKOUT_ROUTE));
        }
      } else if (finalDestination === '/shop') {
        yield put(goToUrl(finalDestination, true));
      } else if (finalDestination === '/') {
        yield put(gotoExternalUrl(finalDestination, true));
      } else {
        yield call(browserHistory.push, finalDestination);
      }
    }
    if (actionToDispatch) {
      if (!reachedInterstitialPage) {
        yield put(actionToDispatch);
      }
    }
  }
}

function * watchLoginSuccess () {
  yield takeLatest(RECEIVE_ACCOUNT_DETAILS, redirectToFinalDestination);
}

export function goToExternalUrlHandler ({ url }) {
  window.location.href = url;
}

function * watchGoToExternalUrl () {
  yield takeLatest(GOTO_EXTERNAL_URL, goToExternalUrlHandler);
}

function * logoutUser () {
  yield put(removeFinalDestination());
  yield put(updateIsLoginState());
  yield call(forceUserLogout);
  window.localStorage.setItem('logout-event', 'logout' + Math.random());
}

function * watchLogoutUser () {
  yield takeLatest(LOGOUT_USER, logoutUser);
}

function * unloadPageAndRedirectToDestination () {
  yield put(unloadReachedInterstitalPage());
  yield call(redirectToFinalDestination, { isLoginSuccess: true });
}

export function * watchInterstitialUnloadRequest () {
  yield takeLatest(RECEIVE_INTERSTITIAL_PAGE_UNLOAD_REQUEST, unloadPageAndRedirectToDestination);
}

export function * watchRoutes () {
  yield all([
    fork(watchGotoReview),
    fork(watchLogoutUser),
    fork(watchRouteAccessDenied),
    fork(watchLoginSuccess),
    fork(watchGoToExternalUrl),
    fork(watchGoToUrl),
    fork(watchReplaceUrl),
    fork(watchInterstitialUnloadRequest)
  ]);
}
