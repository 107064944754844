import { createAction } from 'redux-actions';

export const LOAD_ORDER_SUCCESS = 'order.loadOrderSuccess';
export const LOAD_ORDER_ERROR = 'order.loadOrderError';
export const LOAD_ACCOUNT_ORDERS_SUCCESS = 'order.loadAccountOrderSuccess';
export const UPDATE_ORDER_SUMMARY = 'order.orderSummary';

export const POST_ORDER = 'paymentMethod.POST_ORDER';
export const postOrder = createAction(POST_ORDER);

export const loadOrderSuccess = (order) => ({
  type: LOAD_ORDER_SUCCESS,
  order
});

export const loadOrderError = (error) => ({
  type: LOAD_ORDER_ERROR,
  error
});

export const SUBMIT_CANCELLATION_REQUEST = 'SUBMIT_CANCELLATION_REQUEST';
export const submitCancellation = (qty, sku, orderId, myOrdersState) => ({
  type: SUBMIT_CANCELLATION_REQUEST,
  qty,
  sku,
  orderId,
  myOrdersState
});

export const REQUEST_MY_ORDERS = 'REQUEST_MY_ORDERS';
export const REQUEST_ACCOUNT_ORDERS = 'REQUEST_ACCOUNT_ORDERS';
export const LOAD_MY_ORDERS_SUCCESS = 'LOAD_MY_ORDERS_SUCCESS';

export const requestMyOrders = (limit, pageNumber, sortField, invoicesOnly, orderStatusType, dateRange, searchInput) => ({
  type: REQUEST_MY_ORDERS,
  limit,
  pageNumber,
  sortField,
  invoicesOnly,
  orderStatusType,
  dateRange,
  searchInput
});

export const requestAccountOrders = (limit, pageNumber, sortField, ordersType, selectedCustomerEmail, invoicesOnly) => ({
  type: REQUEST_ACCOUNT_ORDERS,
  limit,
  pageNumber,
  sortField,
  ordersType,
  selectedCustomerEmail,
  invoicesOnly
});

export const loadMyOrdersSuccess = (payload) => ({
  type: LOAD_MY_ORDERS_SUCCESS,
  payload
});

export const loadAccountOrderSuccess = (payload) => ({
  type: LOAD_ACCOUNT_ORDERS_SUCCESS,
  payload
});

export const REQUEST_ORDERS_RAW = 'REQUEST_ORDERS_RAW';
export const LOAD_ORDERS_RAW_SUCCESS = 'LOAD_ORDERS_RAW_SUCCESS';

export const loadOrdersRawSuccess = (orders) => ({
  type: LOAD_ORDERS_RAW_SUCCESS,
  orders
});

export const REQUEST_FETCH_ORDER = 'REQUEST_FETCH_ORDER';

export const fetchOrder = (orderId) => ({
  type: REQUEST_FETCH_ORDER,
  orderId
});
export const GET_RETURN_REPLACE_ORDER = 'GET_RETURN_REPLACE_ORDER';

export const getReturnReplaceOrder = (orderNumber, accountRequest) => ({
  type: GET_RETURN_REPLACE_ORDER,
  orderNumber,
  accountRequest
});

export const GET_ORDER_CONFIRMATION_SCREEN_ORDER = 'GET_ORDER_CONFIRMATION_SCREEN_ORDER';

export const getOrderConfirmationOrder = (orderId) => ({
  type: GET_ORDER_CONFIRMATION_SCREEN_ORDER,
  orderId
});

export const ADD_ORDER_TO_CART = 'order.addOrderToCart';

export const addOrderToCart = (order) => ({
  type: ADD_ORDER_TO_CART,
  order
});

export const SHOW_HIDE_TRACKING_DETAILS = 'SHOW_HIDE_TRACKING_DETAILS';
export const handleTrackOrderClick = (merlinOrderReference, trackingCode, orderType) => ({
  type: SHOW_HIDE_TRACKING_DETAILS,
  payload: {
    merlinOrderReference,
    trackingCode,
    orderType
  }
});

export const updateOrderSummaryState = (payload) => ({
  type: UPDATE_ORDER_SUMMARY,
  payload
});
