import { createAction } from 'redux-actions';

export const LOAD_PRODUCT_AVG_RATING = 'LOAD_PRODUCT_AVG_RATING';
export const recieveLoadProductRatingSuccess = (productRating, sku, familyId) => {
  return {
    type: LOAD_PRODUCT_AVG_RATING,
    productRating,
    sku,
    familyId
  };
};

export const LOAD_PRODUCT_REVIEWS_ERROR = 'LOAD_PRODUCT_REVIEWS_ERROR';
export const recieveLoadProductReviewsError = createAction(LOAD_PRODUCT_REVIEWS_ERROR);

export const LOAD_PRODUCT_RATING_ERROR = 'LOAD_PRODUCT_RATING_ERROR';
export const recieveLoadProductRatingError = createAction(LOAD_PRODUCT_RATING_ERROR);

export const SORT_PRODUCT_REVIEWS = 'SORT_PRODUCT_REVIEWS';
export const sortProductReviews = (sku, familyId, reviews, sortBy) => {
  return {
    type: SORT_PRODUCT_REVIEWS,
    sortBy,
    sku,
    familyId,
    reviews
  };
};

export const LOAD_SERVICE_RATING_ERROR = 'LOAD_SERVICE_RATING_ERROR';
export const receiveLoadServiceRatingError = createAction(LOAD_SERVICE_RATING_ERROR);

export const REQUEST_SERVICE_AVG_RATING = 'REQUEST_SERVICE_AVG_RATING';
export const requestServiceAverageRating = () => ({
  type: REQUEST_SERVICE_AVG_RATING
});

export const LOAD_SERVICE_AVG_RATING = 'LOAD_SERVICE_AVG_RATING';
export const receiveLoadServiceRatingSuccess = (serviceRating) => ({
  type: LOAD_SERVICE_AVG_RATING,
  serviceRating
});

export const REQUEST_SERVICE_REVIEWS = 'REQUEST_SERVICE_REVIEWS';
export const requestServiceReviews = ({ reviewDisplayed, reviewSkip }) => ({
  type: REQUEST_SERVICE_REVIEWS,
  reviewDisplayed,
  reviewSkip
});

export const LOAD_SERVICE_REVIEWS_SUCCESS = 'LOAD_SERVICE_REVIEWS_SUCCESS';
export const receiveLoadServiceReviewsSuccess = (serviceReviews, totalReviews) => ({
  type: LOAD_SERVICE_REVIEWS_SUCCESS,
  serviceReviews,
  totalReviews
});
