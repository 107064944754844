import { ApiRequest } from 'shared/utils/apiUtils';

export function cancelRequest (orderId, requestDetails) {
  const req = new ApiRequest({
    method: 'PUT',
    apiPath: `/orders/cancelRequest/${orderId}`,
    body: requestDetails
  });
  return req.execute();
}

export function loadOrder (id) {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/orders/${id}`
  });
  return req.executeAndGetBody();
}

export function loadOrdersRaw ({ limit, pageNumber, sortField, ordersType,
  selectedCustomerEmail, invoicesOnly, orderStatusType, dateRange, searchInput }) {
  selectedCustomerEmail = selectedCustomerEmail === '' ? undefined : selectedCustomerEmail;
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/ordersExtended',
    query: { limit,
      pageNumber,
      sortField,
      ordersType,
      selectedCustomerEmail,
      invoicesOnly,
      orderStatusType,
      dateRange,
      searchInput }
  });
  return req.executeAndGetBody();
}

export function loadOrders () {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: '/orders'
  });
  return req.executeAndGetBody();
}

export function postOrder (order) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/orders',
    body: order
  });
  return req.executeAndGetBody();
}

export function postOrderWithPaymentInformation (order) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/orders/payment',
    body: order
  });
  return req.executeAndGetBody();
}
