import { CREDIT, STANDARD, USER } from 'shared/constants/account';
import { MY_ACCOUNT_PAGE_URLS, MY_ACCOUNT_PAGES, myAccountPageUrlMap } from 'shared/constants/common';
export const TRADE_ACCOUNT_STATUS_FOR_STATEMENT = ['ACTIVE', 'APPROVED', 'ON-HOLD'];
export const TRADE_ACCOUNT_STATUS = ['ACTIVE', 'APPROVED'];

export const validateTradeAccountByStatus = (tradeAccountSubRole, tradeAccountSubStatus, tradeAccountActive, tradeAccountStatus) => {
  if (tradeAccountSubRole && tradeAccountSubStatus) {
    return true;
  } else if (tradeAccountActive && tradeAccountSubRole && !tradeAccountStatus) {
    return true;
  }
  return false;
};

export const validateTradeAccount = (loggedUser) => {
  const { accountDetails } = loggedUser;
  const { tradeAccount } = accountDetails;
  const { status: accountStatus, accountUser, accountType } = tradeAccount || {};
  const { accountRole, status: tradeAccountStatus } = accountUser || {};
  const tradeAccountActive = TRADE_ACCOUNT_STATUS.includes(accountStatus);
  const tradeAccountEligible = TRADE_ACCOUNT_STATUS_FOR_STATEMENT.includes(accountStatus);
  const tradeAccountSubUser = !!(accountUser && Object.keys(accountUser).length);
  const tradeAccountSubRole = tradeAccountSubUser && ['ADMIN', 'FINANCE'].includes(accountRole);
  const tradeAccountSubStatus = tradeAccountSubRole && ['DELETED', 'PAUSED', 'DECLINED'].includes(tradeAccountStatus || accountStatus);
  const tradeAccountSubStatusEligibleForStatement = tradeAccountSubRole && TRADE_ACCOUNT_STATUS_FOR_STATEMENT.includes(tradeAccountStatus);

  const tradeAccountIsValid = validateTradeAccountByStatus(tradeAccountSubRole, !tradeAccountSubStatus, tradeAccountActive, tradeAccountStatus);
  const tradeAccountIsValidForStatement = accountType === CREDIT && validateTradeAccountByStatus(tradeAccountSubRole, tradeAccountSubStatusEligibleForStatement, tradeAccountEligible, tradeAccountStatus);
  return {
    tradeAccountIsValid,
    tradeAccountIsValidForStatement,
    tradeAccount
  };
};

export const isValidTradeAccount = (tradeAccount) => {
  const { status: accountStatus = '', accountUser } = tradeAccount || {};
  const { status: tradeAccountStatus = '' } = accountUser || {};
  const tradeAccountEligible = ['ACTIVE', 'APPROVED', 'ON-HOLD'].includes(accountStatus);
  const isAccountInactive = ['DELETED', 'PAUSED', 'DECLINED'].includes(tradeAccountStatus || accountStatus);
  return !isAccountInactive && tradeAccountEligible;
};

export const getAccountRole = (role) => role === USER ? STANDARD : role;

export const getPartialPathClassName = (fullUrl, partialUrl) => {
  return fullUrl.includes(partialUrl) ? 'activePartialUrl' : '';
};

export const isMyAccountPage = (type) => {
  return MY_ACCOUNT_PAGES.includes(type);
};

export const getMyAccountPageUrl = (loginType) => {
  return myAccountPageUrlMap[loginType];
};

export const isMyAccountPageUrl = (url) => {
  return MY_ACCOUNT_PAGE_URLS.includes(url);
};
