import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  INITIALIZE,
  PAY_BY_NEW_CARD,
  SET_NAME_ON_CARD, setSubscribeByNewCardStatus,
  SUBSCRIBE_BY_NEW_CARD, SUBSCRIBE_BY_NEW_CARD_EXISTING_SUBSCRIPTION,
  updateHostedFieldsStatus
} from './payByNewCardActions';
import { getExistingCards, initializeHostedFields } from 'client/actions/braintreeGatewayActions';
import { isStringEmpty, isValidString } from 'client/utils/braintreeUtils';
import {
  braintreeSelectors,
  orderSelectors,
  paymentMethodSelectors,
  validationSelectors
} from '../shared/selectors';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { PAYMENT_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';
import { getLocalizedString } from 'localization/localizer';
import { getDeviceData, saveNewCard, verifyCardWith3ds } from 'client/sagas/braintreeGatewaySagas';
import { CARD } from 'shared/constants/braintree';
import {
  CARD as SUBSCRIPTION_CARD, COMPLETED,
  PROCESSING
} from 'client/components/screens/SubscriptionsScreen/subscriptionDetails/SubscriptionConstants';
import { postOrderWithPaymentInformation } from 'shared/endpoints/ordersEndpoint';
import { createOrder, handleResponse, orderFailed } from '../shared/orderHelpers';
import { formIsValid, isCardFormValid } from './utils';
import {
  createSubscriptionFailed,
  createSubscriptionSuccess,
  fetchRepeatOrderById,
  UPDATE_REPEAT_ORDER_DETAILS
} from 'client/actions/repeatOrdersActions';
import { createRepeatOrder as createRepeatOrderEndPoint, updatePayment } from 'shared/endpoints/repeatOrdersEndpoint';
import { saveCard } from 'shared/endpoints/braintreeEndpoint';
import { createSubscription } from 'client/utils/subscriptionUtils';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { PAY_BY_EXISTING_CARD } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { toastError } from 'client/actions/showNotificationsActions';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';

export function * initialize ({ payload }) {
  yield put(getExistingCards({ selector: payload.selector }));
  yield put(initializeHostedFields());
}

export function * validateNameOnCard ({ payload }) {
  const isEmpty = isStringEmpty(payload.nameOnCard);
  const isValidName = isValidString(payload.nameOnCard);
  const isValid = !isEmpty && isValidName;
  yield put(updateHostedFieldsStatus({
    selector: payload.selector,
    hostedFieldName: 'cardholderName',
    hostedFieldStatus: { isEmpty, isValid }
  }));
}

export function * isNewCardFormValid (selector) {
  const cvv = yield select(validationSelectors.braintree.cvv(selector));
  const cardholderName = yield select(validationSelectors.braintree.cardHolderName(selector));
  const number = yield select(validationSelectors.braintree.cardNumber(selector));
  const expirationDate = yield select(validationSelectors.braintree.expirationDate(selector));
  return isCardFormValid({
    cvv,
    cardholderName,
    number,
    expirationDate
  });
}

export function * payByNewCard ({ payload }) {
  try {
    const billingAddressIncompleteErrors = yield select(validationSelectors.form.billingAddress);
    if (!formIsValid(billingAddressIncompleteErrors)) {
      yield put(setActivePanelId({ panelId: PAYMENT_ACCORDION_PANEL_ID }));
      yield call(orderFailed, getLocalizedString('singlePageCheckout.error.billing.not.saved'));
      return;
    }
    const cardFormValid = yield call(isNewCardFormValid, payload.selector);
    if (!cardFormValid) {
      yield put(setActivePanelId({ panelId: PAYMENT_ACCORDION_PANEL_ID }));
      yield call(orderFailed, getLocalizedString('singlePageCheckout.error.new.card.empty'));
      return;
    }
    const hostedFieldsInstance = yield select(braintreeSelectors.instances.hostedFields);
    const nameOnCard = (yield select(paymentMethodSelectors.payByNewCard.nameOnCard(payload.selector))).trim();
    const { nonce, details } = yield hostedFieldsInstance.tokenize({ cardholderName: nameOnCard });
    const billingAddressFormOpen = yield select(paymentMethodSelectors.payByNewCard.addressFormOpen(payload.selector));
    const billingAddress = billingAddressFormOpen ? yield select(orderSelectors.billingAddressForm) : yield select(orderSelectors.shippingAddress);
    const threeDResponse = yield call(verifyCardWith3ds, nonce, details, billingAddress, payload.quotationId);
    if (!threeDResponse) {
      yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.error'));
      return;
    }
    const saveCardForFutureOrders = yield select(paymentMethodSelectors.payByNewCard.saveCardForFutureOrders(payload.selector));
    const paymentInformation = {
      paymentType: CARD,
      storePaymentMethodStatus: saveCardForFutureOrders,
      isPriceWithVat: yield select(orderSelectors.isPriceWithVat),
      deviceData: yield call(getDeviceData),
      braintreePayload: {
        nonce: threeDResponse.nonceFromThreeDS,
        details: threeDResponse.detailsFromThreeDS
      },
      shouldUpdateBillingAddress: true,
      csAgentName: yield select(orderSelectors.csAgent.name)
    };

    const order = yield call(createOrder, CARD, billingAddress, paymentInformation);
    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrderWithPaymentInformation, order);
    yield call(handleResponse, postOrderResponse);
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

export function * subscribeByNewCard ({ payload }) {
  try {
    const hostedFieldsInstance = yield select(braintreeSelectors.instances.hostedFields);
    const nameOnCard = (yield select(paymentMethodSelectors.payByNewCard.nameOnCard(payload.selector))).trim();
    const { nonce } = yield hostedFieldsInstance.tokenize({ cardholderName: nameOnCard });
    const deviceData = yield call(getDeviceData);
    const billingAddressFormOpen = yield select(paymentMethodSelectors.payByNewCard.addressFormOpen(payload.selector));
    const billingAddress = billingAddressFormOpen ? yield select(orderSelectors.billingAddressForm) : yield select(orderSelectors.shippingAddress);
    const saveCardPayload = {
      nonce,
      deviceData,
      billingAddress
    };
    const saveCardResponse = yield call(saveCard, saveCardPayload);
    const subscription = yield call(createSubscription, SUBSCRIPTION_CARD, saveCardResponse.token);
    yield call(createRepeatOrderEndPoint, subscription);
    yield put(createSubscriptionSuccess({ repeatOrderCreatedName: subscription.orderReference }));
  } catch (error) {
    yield put(toastError(
      getToastMessage(
        getLocalizedString('mySubscriptions.account.create.error')
      ),
      'top-right', 5000));
    yield put(createSubscriptionFailed());
  }
}

export function * subscribeByNewCardForExistingSubscription ({ payload }) {
  try {
    yield put(setSubscribeByNewCardStatus({ selector: payload.selector, status: PROCESSING }));
    const saveCardResponse = yield call(saveNewCard, payload.selector);
    const updatePaymentPayload = {
      paymentType: SUBSCRIPTION_CARD,
      cardToken: saveCardResponse.token
    };
    yield call(updatePayment, updatePaymentPayload, payload.subscriptionId);
    yield put(fetchRepeatOrderById(payload.subscriptionId));
    yield take(UPDATE_REPEAT_ORDER_DETAILS);
    yield put(setSubscribeByNewCardStatus({ selector: payload.selector, status: COMPLETED }));
    yield put(changePaymentMethod({
      selector: payload.selector,
      paymentMethod: PAY_BY_EXISTING_CARD
    }));
  } catch (err) {
    yield put(setSubscribeByNewCardStatus({ selector: payload.selector, status: COMPLETED }));
    yield put(toastError(
      getToastMessage(
        getLocalizedString('singlePageCheckout.error.saving.card')
      ),
      'top-right', 5000));
  }
}

function * watchSubscribeByNewCardForExistingSubscription () {
  yield takeLatest(SUBSCRIBE_BY_NEW_CARD_EXISTING_SUBSCRIPTION, subscribeByNewCardForExistingSubscription);
}
function * watchValidateNameOnCard () {
  yield takeLatest(SET_NAME_ON_CARD, validateNameOnCard);
}
function * watchInitialize () {
  yield takeLatest(INITIALIZE, initialize);
}
function * watchPayByNewCard () {
  yield takeLatest(PAY_BY_NEW_CARD, payByNewCard);
}
function * watchSubscribeByNewCard () {
  yield takeLatest(SUBSCRIBE_BY_NEW_CARD, subscribeByNewCard);
}

export function * watchAllPayByNewCardSagas () {
  yield all([
    fork(watchInitialize),
    fork(watchValidateNameOnCard),
    fork(watchPayByNewCard),
    fork(watchSubscribeByNewCard),
    fork(watchSubscribeByNewCardForExistingSubscription)
  ]);
}
