import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart } from 'client/actions/cartActions';
import flowRight from 'lodash/flowRight';
import _get from 'lodash/get';

import {
  fetchQuotationDetails,
  updateQuotationProductQty,
  removeProductFromQuotationConfirm,
  fetchQuotationProductStockInfo,
  sendQuotationEmail,
  deleteQuotationConfirm,
  lockQuotation,
  requestChangeInQuoteLineQty
} from 'client/actions/quotationsActions';
import {
  updatePriceWithBreakPrice
} from 'client/actions/productVariantActions';
import QuotationDetailsScreen from './QuotationDetailsScreen';
import { addQuoteLineToBasket
} from 'client/actions/globalQuotationActions';
import { getTokenDetails } from 'shared/utils/jwtUtils';
const mapStateToProps = (state, ownProps) => {
  const quotationId = _get(ownProps, 'params.quotationId');
  const isLoggedInOrhasValidLongSessionToken = state.getIn(['auth', 'isLoggedIn']) || state.getIn(['auth', 'hasValidLongSessionToken']);
  const jwtToken = state.getIn(['auth', 'longSessionJwtToken']) || state.getIn(['auth', 'jwtToken']);
  const jwtTokenDetails = jwtToken ? getTokenDetails(jwtToken) : null;
  const loyaltyTier = jwtTokenDetails ? jwtTokenDetails.loyaltyTier : null;
  return {
    isLoggedInOrhasValidLongSessionToken,
    quotationId,
    quotationDetails: quotationId ? state.getIn(['quotations', 'details', quotationId]) : undefined,
    totalOrderPriceWithVat: quotationId ? state.getIn(['quotations', 'details', quotationId, 'totalOrderPriceWithVat']) : undefined,
    isTradeAccountButtonToBeShown: quotationId ? state.getIn(['quotations', 'details', quotationId, 'isTradeAccountButtonToBeShown']) : undefined,
    tradeAccount: state.getIn(['user', 'accountDetails', 'tradeAccount'], new Map()),
    includeVat: state.getIn(['user', 'isPriceWithVat']),
    braintreePaymentConfig: state.getIn(['config', 'braintreePayment']).toJS(),
    userEmail: state.getIn(['user', 'accountDetails', 'email'], null),
    loyaltyTier
  };
};

function mapDispatchToProps (dispatch) {
  return {
    fetchQuotationDetails: flowRight(dispatch, fetchQuotationDetails),
    addToCart: flowRight(dispatch, addToCart),
    fetchQuotationProductStockInfo: flowRight(dispatch, fetchQuotationProductStockInfo),
    updateQuotationProductQty: flowRight(dispatch, updateQuotationProductQty),
    removeProductFromQuotationConfirm: flowRight(dispatch, removeProductFromQuotationConfirm),
    sendQuotationEmail: flowRight(dispatch, sendQuotationEmail),
    deleteQuotationConfirm: flowRight(dispatch, deleteQuotationConfirm),
    lockQuotation: flowRight(dispatch, lockQuotation),
    updatePriceWithBreakPrice: flowRight(dispatch, updatePriceWithBreakPrice),
    requestChangeInQuoteLineQty: flowRight(dispatch, requestChangeInQuoteLineQty),
    addQuoteLineToBasketAction: (quoteId) => dispatch(addQuoteLineToBasket(quoteId))
  };
}

class QuotationDetailsScreenInjectionWrapper extends Component {
  componentWillMount () { // eslint-disable-line react/no-deprecated
    if (this.props.isLoggedInOrhasValidLongSessionToken) {
      this.props.fetchQuotationDetails(this.props.quotationId);
    }
  }

  render () {
    return <QuotationDetailsScreen { ...this.props } />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetailsScreenInjectionWrapper);
