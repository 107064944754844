import { put } from 'redux-saga/effects';
import { updateDataLayer } from 'client/actions/dataLayerActions';
import { Iterable } from 'immutable';
import { List } from 'immutable';
import {
  TOP_PRODUCT_CLICK_EVENT,
  TOP_PRODUCT_CLICK_ACTION_ATB
} from 'client/components/screens/addToBasketInterstitialScreen/constants';

const ADD = 'add';
const REMOVE = 'remove';
const ADD_BUNDLE_TO_CART = 'add-bundle-to-cart';

export function delay (defaultTime = 5000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), defaultTime);
  });
}

// TODO: move this to dataLayerSagas
export function * sendImpressionsToDataLayer (pageData, list, isFamilyData = true) {
  let currency;
  if (!Iterable.isIterable(pageData)) {
    return;
  }

  // Needs to be reworked. TODO
  if (!pageData.first()) {
    currency = '£';
  } else {
    currency = (isFamilyData)
      ? pageData.first().get('productsInfo') &&
        pageData.first().get('productsInfo').first()
        ? pageData.first().get('productsInfo').first().getIn(['price', 'currency'])
        : currency = '£'
      : pageData.first().getIn(['price', 'currency']);
  }

  const impressions = pageData.map((product, index) => {
    if (isFamilyData) {
      if (product.get('numberOfMatchingProducts') <= 1) {
        const productInfo = product.get('productsInfo');
        if (!productInfo) {
          return {};
        }
        return mapProductToDataLayerImpressionObject(product.get('productsInfo').first(), list, index + 1);
      }
      return mapFamilyToDataLayerImpressionObject(product, list, index + 1);
    }
    return mapProductToDataLayerImpressionObject(product, list, index + 1);
  }).toArray();

  yield put(updateDataLayer({
    ecommerce: {
      currencyCode: currency,
      impressions
    }
  }));
}

// TODO: move this to dataLayerSagas
export function * sendBasketChangeToDataLayer (orderLine, currency, qty, event, recommendationType, recommendationPosition, recommendationLocation, updatedQuantity) {
  const dataLayerObject = mapItemToDataLayerCartObject(orderLine, currency, qty, event, recommendationType, recommendationPosition, recommendationLocation, updatedQuantity);
  yield put(updateDataLayer(dataLayerObject));
}

export function * sendBundleBasketChangeToDataLayer ({ orderLine, currency, quantity, event, recommendationType, recommendationPosition }) {
  const dataLayerObject = mapBundleItemToDataLayerCartObject({ orderLine, currency, quantity, event, recommendationType, recommendationPosition });
  yield put(updateDataLayer(dataLayerObject));
}
// TODO: move this to dataLayerSagas
function mapProductToDataLayerImpressionObject (product, list, position) {
  const name = product.get('name');
  const id = product.get('sku');
  const brand = product.getIn(['brand', 'brandName']);
  const category = product.getIn(['category', 'categoryName']);
  const price = Number(product.getIn(['price', 'packPriceWithVat']));
  return {
    name,
    id,
    price,
    brand,
    category,
    list,
    position
  };
}

function mapFamilyToDataLayerImpressionObject (family, list, position) {
  const id = family.get('id');
  const price = Number(family.get('minPrice').toFixed(2));
  const familyObject = family.get('familyObject', List());
  const heading = familyObject.get('familyHeading', '');
  const brand = familyObject.getIn(['brand', 'brandName'], '');
  const category = familyObject.get('topCategoryName', '');
  return {
    heading, id, price, brand, category, list, position
  };
}

export function mapItemToDataLayerCartObject (item, currency, quantity, event, recommendationType, recommendationPosition, recommendationLocation, updatedQuantity) {
  const {
    name,
    sku,
    orderLinePrice: { unitPriceWithVat },
    brand: { brandName },
    category: { categoryName }
  } = item;

  const addOrRemove = ['addToCart', 'RecommendationAddToCart', 'ItemQtyIncrease'].includes(event) ? 'add' : 'remove';

  return {
    event,
    recommendationType,
    recommendationPosition,
    recommendationLocation,
    sku: sku,
    newQuantity: updatedQuantity,
    ecommerce: {
      currencyCode: currency,
      [addOrRemove]: {
        products: [{
          name,
          id: sku,
          price: unitPriceWithVat,
          brand: brandName,
          category: categoryName,
          quantity: +quantity
        }],
        actionField: {
          list: (event === 'addToCart') ? 'Add to cart' : 'Add recommendation to cart'
        }
      }
    }
  };
}

export function mapFavouriteItemToDataLayerCartObject (item, currency, quantity) {
  const {
    name,
    sku,
    orderLinePrice: { unitPriceWithVat },
    brand: { brandName },
    category: { categoryName }
  } = item;

  return {
    event: 'FavouriteAddToCart',
    sku: sku,
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [{
          name,
          id: sku,
          price: unitPriceWithVat,
          brand: brandName,
          category: categoryName,
          quantity: +quantity
        }],
        actionField: {
          list: 'Add favourite to cart'
        }
      }
    }
  };
}

export function mapBundleItemToDataLayerCartObject ({ orderLine, currency, quantity, event, recommendationType, recommendationPosition }) {
  const {
    name,
    sku,
    bundleFamilyId,
    orderLinePrice: { unitPriceWithVat },
    category: { categoryName }
  } = orderLine;

  const addOrRemove = event === ADD_BUNDLE_TO_CART ? ADD : REMOVE;

  return {
    event,
    recommendationType,
    recommendationPosition,
    ecommerce: {
      currencyCode: currency,
      [addOrRemove]: {
        products: [{
          name,
          id: sku,
          bundleFamilyId: bundleFamilyId,
          price: unitPriceWithVat,
          category: categoryName,
          quantity: +quantity
        }],
        actionField: {
          list: event
        }
      }
    }
  };
}

export function mapTopProductClickToDataLayerCartObject (searchTerm, productSku) {
  const event = {
    event: TOP_PRODUCT_CLICK_EVENT,
    search_term: searchTerm,
    clicked_item: productSku,
    click_action: TOP_PRODUCT_CLICK_ACTION_ATB
  };
  return event;
}
