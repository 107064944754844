import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { hideRemoveRepeatOrderDialog } from '../../../actions/ui/dialogActions';
import { requestRemoveRepeatOrderConfirm } from '../../../actions/repeatOrdersActions';

if (process.browser) {
  require('./RemoveRepeatOrderDialog.scss');
}

function mapStateToProps (state) {
  return {
    removeRepeatOrderDialogIsVisible: state.getIn(['ui', 'dialogs', 'removeRepeatOrder', 'isVisible'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideRemoveRepeatOrderDialog: flowRight(dispatch, hideRemoveRepeatOrderDialog),
    requestRemoveRepeatOrderConfirm: flowRight(dispatch, requestRemoveRepeatOrderConfirm)
  };
}

const RemoveRepeatOrderDialog = (props) => {
  const {
    removeRepeatOrderDialogIsVisible,
    requestRemoveRepeatOrderConfirm,
    hideRemoveRepeatOrderDialog
  } = props;

  return (
    <Modal className="RemoveRepeatOrdersDialog" show={ removeRepeatOrderDialogIsVisible }
      onHide={ hideRemoveRepeatOrderDialog }>
      <Modal.Header className="RemoveRepeatOrderDialog_header" closeButton></Modal.Header>
      <Modal.Body>
        <div className="product modal-body_content">
          <div>
            Are you sure you want to delete this Scheduled Order?<br/>
            Please note: this action cannot be reversed.
          </div>
        </div>

        <div className="row modal-body_options">
          <SecondaryButton
            onClick={ hideRemoveRepeatOrderDialog }>
                        Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={ requestRemoveRepeatOrderConfirm }>
                        Remove
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

RemoveRepeatOrderDialog.propTypes = {
  removeRepeatOrderDialogIsVisible: PropTypes.bool,
  requestRemoveRepeatOrderConfirm: PropTypes.func,
  hideRemoveRepeatOrderDialog: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveRepeatOrderDialog);
