import { ApiRequest } from 'shared/utils/apiUtils';
import request from 'axios';

export const processReturnReplacementRequest = (orderId, data) => {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/orders/${orderId}/return-requests`,
    body: data
  });
  return req.executeAndGetBody();
};

export const uploadReturnReplacementImages = (orderId, data) => {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: `/orders/${orderId}/request-images`,
    fileList: data
  });
  return req.executeAndGetBody();
};

export const fetchReturnsRequestDetails = (reqId) => {
  const req = new ApiRequest({
    method: 'GET',
    apiPath: `/orders/return-requests/${reqId}`
  });
  return req.executeAndGetBody();
};

export const getImageBlob = (blobUrl) => {
  return request({ method: 'get',
    url: blobUrl,
    responseType: 'blob'
  }).then(r => r.data);
};
