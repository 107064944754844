import React from 'react';
import { getLocalizedString } from 'localization/localizer';

if (process.browser) {
  require('./FieldInputStyles.scss');
}

const SelectWithValidationsNewStyles = (props) => {
  const {
    input,
    children,
    placeholderResourceName,
    className,
    label,
    labelResourceName,
    labelClassName,
    datae2e,
    tooltip,
    id,
    autoFocus,
    formClassName,
    disabled,
    meta: { touched, error }
  } = props;

  return (
    <div className={ `form-group FieldInputStyles ${formClassName && formClassName} ${touched && error && 'has-error'}` }>
      { id && <label htmlFor={ id } className={ labelClassName }>{ labelResourceName ? getLocalizedString(labelResourceName) : label }</label> }
      { tooltip && tooltip }
      <div>
        <select { ...input }
          id={ id }
          disabled={ disabled }
          className={ className }
          placeholder={ placeholderResourceName ? getLocalizedString(placeholderResourceName) : null }
          autoFocus={ autoFocus }
          data-e2e={ datae2e }>
          { children }
        </select>
      </div>
      {
        touched && error &&
        <div className="help-block">
          <div data-e2e={ input.name + 'Error' }>{ error }</div>
        </div>
      }
    </div>
  );
};

export default SelectWithValidationsNewStyles;
