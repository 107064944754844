import { put, select, takeLatest } from 'redux-saga/effects';

import { TOGGLE_ALL_CATEGORY_PAGE, setPreviousLocation } from '../actions/ui/dropdownMenuActions';
import { goToUrl } from 'client/actions/routeActions';

export function * onToggleAllCategoryPage (action) {
  if (action.location === '/shop/all-categories') {
    const previousLocationSelector = (state) => state.getIn(['ui', 'categoriesDropdown', 'previousLocation']);
    const previousLocation = yield select(previousLocationSelector);
    yield put(goToUrl(previousLocation));
  } else {
    yield put(setPreviousLocation(action.location));
    yield put(goToUrl('/shop/all-categories'));
  }
}

export function * watchToggleAllCategoryPage () {
  yield takeLatest(TOGGLE_ALL_CATEGORY_PAGE, onToggleAllCategoryPage);
}
