import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';

import {
  getDetailsForCheckoutProcess,
  setActivePanelId,
  toggleAccordionState,
  onEditDeliveryAddressClick,
  onDisplayAddressBookClick,
  onSelectNewAddress,
  onConfirmDeliveryAddress,
  onBackToDeliveryPanel,
  onChangeDeliveryAddressFromAddressListDropDown,
  cancelEditAddresss,
  saveAddresss,
  handleAddNewAddressClick,
  changeAddressBookViewType,
  proceedToNextStage,
  skipPaymentFlow,
  resetAllScrolls,
  resetCheckoutState
} from 'client/actions/checkoutActions';
import {
  initOrderReview,
  changeOptionSelectionInit,
  resetOrderReviewState
} from 'client/actions/orderReviewActions';

import {
  setPoReference
} from 'client/actions/addressActions';
import { applyDiscountCode, applyDiscountCodeToExistingCart, removeDiscountCode } from 'client/actions/discountCodeActions';

import { isUserLoggedIn } from 'client/utils/routeUtils';

import { Checkout } from 'client/components/screens/checkoutScreen/Checkout';
import {
  setOrderLineQuantity,
  removeOrderLine,
  setBundleQuantity,
  removeBundleOrderLine,
  loadCart
} from 'client/actions/cartActions';
import {
  fetchQuotationProductStockInfo,
  changeDeliveryOptionFromQuotation,
  fetchQuotationDetails
} from 'client/actions/quotationsActions';
import {
  changeDeliveryOptionFromGlobalQuotation
} from 'client/actions/globalQuotationActions';
import { toastSuccess } from 'client/actions/showNotificationsActions';
import { postOrder } from '../../../actions/ordersActions';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { SUBMITTING } from 'shared/constants/apiButtonConstants';
import { List } from 'immutable';
import { setBraintreeGooglePayInstance } from 'client/actions/braintreeGatewayActions';
import { SHOW, UNLEASH_SHOW_ONHOLD_MODAL } from 'shared/constants/abTesting';
import { getVariant, prepareUnleashOverridePayload } from 'client/utils/unleashUtils';
import { validateNonDeliverableStatus } from 'client/utils/validators';
import { getTokenDetails } from 'shared/utils/jwtUtils';
import { PENDING, UNVERIFIED } from '../../../../shared/constants/account';
import { toastInfo } from 'client/actions/showNotificationsActions';

export class CheckoutScreenInjectionWrapper extends PureComponent {
  constructor (props) {
    super(props);
    const {
      isGlobal,
      query,
      fromQuotationId,
      initOrderReview,
      getDetailsForCheckoutProcess,
      dispatchResetOrderReviewState,
      isLoggedIn,
      setBraintreeGooglePayInstance
    } = props;

    if (isLoggedIn) {
      dispatchResetOrderReviewState();
      getDetailsForCheckoutProcess();
      initOrderReview({ fromQuotationId, isGlobal, query, isSinglePageCheckout: true });
    }

    if (process.browser) {
      setBraintreeGooglePayInstance();
    }
  }

  render () {
    const { cart, shippingAddress, quotationDetails, fromQuotationId } = this.props;
    const { orderLines: cartProducts } = cart?.toJS() || [];
    const { products: quotationProducts } = quotationDetails?.toJS() || {};
    const { postalCode = '' } = shippingAddress?.toJS() || {};
    const mappedCartProducts = cartProducts?.map(({ nonDeliverableTo }) => ({ nonDeliverableTo }));
    const mappedQuotationProducts = quotationProducts?.map(({ stock: { nonDeliverableTo } }) => {
      return { nonDeliverableTo };
    });
    const { validNonDeliverableStatus: cartValidNonDeliverableStatus } = validateNonDeliverableStatus(postalCode, mappedCartProducts);
    const { validNonDeliverableStatus: quotationValidNonDeliverableStatus } = validateNonDeliverableStatus(postalCode, mappedQuotationProducts);
    const validNonDeliverableStatus = (cartValidNonDeliverableStatus && !fromQuotationId) || (quotationValidNonDeliverableStatus && fromQuotationId);
    const showFarFlungDelvMessage = true;
    return <Checkout { ...{ ...this.props, validNonDeliverableStatus, showFarFlungDelvMessage } } />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = location.query ? location.query : null;
  const fromQuotationId = query && query.fromQuotationId;
  const isGlobal = query && query.isGlobal ? true : state.getIn(['globalQuotation', 'isGlobalQuotePaidOnAccount'], false);
  const unleashToggles = state.getIn(['unleash', 'toggles'], List()).toJS();
  const overrideVariant = prepareUnleashOverridePayload(query);
  const jwtToken = state.getIn(['auth', 'longSessionJwtToken']) || state.getIn(['auth', 'jwtToken']);
  const jwtTokenDetails = jwtToken ? getTokenDetails(jwtToken) : null;
  const loyaltyTier = jwtTokenDetails ? jwtTokenDetails.loyaltyTier : null;
  const tradeAccountStatus = state.getIn(['user', 'accountDetails', 'tradeAccount', 'status']);
  const unleashOnHoldModal = getVariant(unleashToggles, UNLEASH_SHOW_ONHOLD_MODAL, '', overrideVariant);
  const showOnHoldModal = unleashOnHoldModal === SHOW && [PENDING, UNVERIFIED].includes(tradeAccountStatus);
  return {
    isSinglePageCheckout: true,
    accountDetails: state.getIn(['user', 'accountDetails']),
    loadState: state.getIn(['checkout', 'loadState']),
    fromQuotationId,
    isGlobal,
    location,
    query,
    isLoggedIn: isUserLoggedIn(state),
    csAgentName: state.getIn(['customerService', 'csAgentName']),
    maskCallDialogVariant: state.getIn(['ui', 'dialogs', 'maskCall', 'variant']),
    isPaymentFlowSkipped: state.getIn(['checkout', 'isPaymentFlowSkipped']),
    isOrderSubmitting: state.getIn(['ui', 'apiCallButton', CHECKOUT_SELECTOR]) === SUBMITTING,
    websiteUrl: state.getIn(['config', 'websiteUrl']),
    isMobile: state.getIn(['deviceInfo', 'isMobile']),

    // for accordian
    activePanelId: state.getIn(['checkout', 'activePanelId']),
    scrollToDeliveryPanel: state.getIn(['checkout', 'deliveryPanel', 'scrollToDeliveryPanel']),
    scrollToTop: state.getIn(['checkout', 'scrollToTop']),
    scrollToPaymentPanel: state.getIn(['checkout', 'paymentPanel', 'scrollToPaymentPanel']),
    scrollToConfirmPaymentDetails: state.getIn(['checkout', 'paymentPanel', 'scrollToConfirmPaymentDetails']),
    scrollToOrderReviewPanel: state.getIn(['checkout', 'orderReviewPanel', 'scrollToOrderReviewPanel']),
    paymentInformation: state.getIn(['braintreePayment', 'paymentInformation']),
    savedPaymentMethods: state.getIn(['braintreePayment', 'savedPaymentMethods']).toJS(),
    billingAddress: state.getIn(['user', 'address', 'billing']),

    // for deliveryPanel
    deliveryPanelLoadState: state.getIn(['checkout', 'deliveryPanel', 'loadState', 'type']),
    showDeliveryAddressForm: state.getIn(['checkout', 'deliveryPanel', 'showDeliveryAddressForm']),
    isEditingDeliveryAddress: state.getIn(['checkout', 'deliveryPanel', 'isEditingDeliveryAddress']),
    showCancelButtonOnAddressForm: state.getIn(['checkout', 'deliveryPanel', 'showCancelButtonOnAddressForm']),
    deliveryIdBeingEdited: state.getIn(['checkout', 'deliveryPanel', 'deliveryIdBeingEdited']),
    showMyAddressBook: state.getIn(['checkout', 'deliveryPanel', 'showMyAddressBook']),
    deliveryAddress: state.getIn(['user', 'address', 'delivery']),
    addressList: state.getIn(['user', 'address', 'deliveryAddresses', 'data']),
    addressBookViewType: state.getIn(['checkout', 'deliveryPanel', 'addressBookViewType']),
    selectedAddressIdForDelivery: state.getIn(['checkout', 'deliveryPanel', 'selectedAddressIdForDelivery']),
    poReference: state.getIn(['user', 'poReference']),
    shippingAddress: state.getIn(['user', 'address', 'delivery']),
    addressId: state.getIn(['user', 'address', 'delivery', 'addressId' ]),
    showOnHoldModal,
    // for totalizer
    cart: state.getIn(['cart']),
    includeVat: state.getIn(['user', 'isPriceWithVat']),
    eligibleForDeliveryOptions: state.getIn(['user', 'address', 'delivery', 'eligibleForDeliveryOptions'], null),
    placeOrderError: state.getIn(['checkout', 'placeOrderError']),
    togglePlaceOrderButton: state.getIn(['singlePageCheckout', 'togglePlaceOrderButton']),
    showNonDeliverableOrderMessage: state.getIn(['ui', 'dialogs', 'nonDeliverableOrderMessage', 'isVisible'], false),
    loyaltyTier,
    // orderReviewPanel
    showTermsOfSales: state.getIn(['orderReview', 'showTermsOfSales']),
    paymentMethod: state.getIn(['braintreePayment', 'paymentMethod']),
    selectedPaymentMethod: state.getIn(['ui', 'paymentMethod', CHECKOUT_SELECTOR, 'method']),
    payPaldata: state.getIn(['braintree', 'paypal', 'data']),
    applePayData: state.getIn(['braintree', 'applePay', 'data']),
    braintree: state.getIn(['braintree']).toJS(),
    quotationDetails: fromQuotationId ? isGlobal
      ? state.getIn(['globalQuotation', 'createdQuotation'])
      : state.getIn(['quotations', 'details', fromQuotationId]) : undefined,
    unleashToggles: state.getIn(['unleash', 'toggles'], List()).toJS()
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // init
    getDetailsForCheckoutProcess: flowRight(dispatch, getDetailsForCheckoutProcess),
    dispatchResetOrderReviewState: (...args) => dispatch(resetOrderReviewState(args)),
    resetCheckoutState: flowRight(dispatch, resetCheckoutState),
    toastInfo: (message, position, duration) => dispatch(toastInfo(message, position, duration)),

    // accordian actions
    onToggleAccordionState: (payload) => dispatch(toggleAccordionState(payload)),
    setActivePanelId: flowRight(dispatch, setActivePanelId),
    skipPaymentFlow: flowRight(dispatch, skipPaymentFlow),
    resetAllScrolls: flowRight(dispatch, resetAllScrolls),

    // deliverypanel actions
    setPoReference: (poReference) => dispatch(setPoReference(poReference)),
    onChangeDeliveryAddressFromAddressListDropDown: (payload) => dispatch(onChangeDeliveryAddressFromAddressListDropDown(payload)),
    onEditDeliveryAddressClick: flowRight(dispatch, onEditDeliveryAddressClick),
    onDisplayAddressBookClick: flowRight(dispatch, onDisplayAddressBookClick),
    // deliveryPanel -> addressBook actions
    onSelectNewAddress: flowRight(dispatch, onSelectNewAddress),
    onConfirmDeliveryAddress: flowRight(dispatch, onConfirmDeliveryAddress),
    onBackToDeliveryPanel: flowRight(dispatch, onBackToDeliveryPanel),
    handleAddNewAddressClick: flowRight(dispatch, handleAddNewAddressClick),
    cancelEditAddresss: flowRight(dispatch, cancelEditAddresss),
    saveAddresss: flowRight(dispatch, saveAddresss),
    changeAddressBookViewType: flowRight(dispatch, changeAddressBookViewType),
    proceedToNextStage: flowRight(dispatch, proceedToNextStage),

    // totalizer actions
    onApplyDiscountCode: flowRight(dispatch, applyDiscountCode),
    onApplyDiscountCodeToExistingCart: flowRight(dispatch, applyDiscountCodeToExistingCart),
    onRemoveDiscountCode: flowRight(dispatch, removeDiscountCode),

    // orderReviewPanel
    initOrderReview: flowRight(dispatch, initOrderReview),
    setOrderLineQuantity: flowRight(dispatch, setOrderLineQuantity),
    removeOrderLine: flowRight(dispatch, removeOrderLine),
    toastSuccess: flowRight(dispatch, toastSuccess),
    setBundleQuantity: flowRight(dispatch, setBundleQuantity),
    removeBundleOrderLine: flowRight(dispatch, removeBundleOrderLine),
    changeDeliveryOption: flowRight(dispatch, changeOptionSelectionInit),
    changeDeliveryOptionFromQuotation: flowRight(dispatch, changeDeliveryOptionFromQuotation),
    changeDeliveryOptionFromGlobalQuotation: flowRight(dispatch, changeDeliveryOptionFromGlobalQuotation),
    fetchQuotationProductStockInfo: flowRight(dispatch, fetchQuotationProductStockInfo),
    // new order journey
    postOrder: flowRight(dispatch, postOrder),
    setBraintreeGooglePayInstance: flowRight(dispatch, setBraintreeGooglePayInstance),
    loadCart: flowRight(dispatch, loadCart),
    fetchQuotationDetails: flowRight(dispatch, fetchQuotationDetails)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  CheckoutScreenInjectionWrapper
);
