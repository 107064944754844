import { fromJS, Map } from 'immutable';
import {
  BRAINTREE_CLIENT_TOKEN_SUCCESS,
  SET_BRAINTREE_3D_INSTANCE_SUCCESS,
  SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS,
  SET_BRAINTREE_HOSTED_FIELDS_INSTANCE_SUCCESS,
  SET_BRAINTREE_PAYPAL_INSTANCE_SUCCESS,
  SET_EXISTING_CARDS,
  SET_PAYPAL_DATA,
  SET_APPLE_PAY_DATA,
  SET_BRAINTREE_APPLE_PAY_INSTANCE_SUCCESS,
  SET_BRAINTREE_GOOGLE_PAY_INSTANCE_SUCCESS,
  SET_GOOGLE_PAY_DATA
} from '../actions/braintreeGatewayActions';

const defaultState = new Map();

const braintree = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PAYPAL_DATA:
      return state.setIn(['paypal', 'data'], action.payload.data);
    case SET_APPLE_PAY_DATA:
      return state.setIn(['applePay', 'data'], action.payload.data);
    case SET_GOOGLE_PAY_DATA:
      return state.setIn(['googlePay', 'data'], action.payload.data);
    case BRAINTREE_CLIENT_TOKEN_SUCCESS:
      return state
        .set('clientToken', action.payload.token);
    case SET_BRAINTREE_PAYPAL_INSTANCE_SUCCESS:
      return state
        .setIn(['paypal', 'instance'], action.payload.paypalInstance);
    case SET_BRAINTREE_APPLE_PAY_INSTANCE_SUCCESS:
      return state
        .setIn(['applePay', 'instance'], action.payload.applePayInstance);
    case SET_BRAINTREE_GOOGLE_PAY_INSTANCE_SUCCESS:
      return state
        .setIn(['googlePay', 'instance'], action.payload.googlePayInstance)
        .setIn(['googlePay', 'paymentClient'], action.payload.paymentsClient);
    case SET_BRAINTREE_3D_INSTANCE_SUCCESS:
      return state
        .setIn(['3ds', 'instance'], action.payload.threeDSecureInstance);
    case SET_BRAINTREE_DATA_COLLECTOR_INSTANCE_SUCCESS:
      return state
        .setIn(['dataCollector', 'instance'], action.payload.dataCollectorInstance);
    case SET_BRAINTREE_HOSTED_FIELDS_INSTANCE_SUCCESS:
      return state
        .setIn(['hostedFields', 'instance'], action.payload.hostedFieldsInstance);
    case SET_EXISTING_CARDS:
      return state
        .setIn(['cards'], fromJS(action.payload.paymentMethods));
    default:
      return state;
  }
};

export default braintree;
