
import { getLocalizedString } from 'localization/localizer';

export const SEVEN_DAYS = 7;
export const THIRTY_DAYS = 30;
export const SIX_MONTHS = 180;
export const ONE_YEAR = 365;
export const ALL_DAYS = 'all';
export const DEBOUNCE_DELAY = 500;

export const DEFAULT_SORTBY = '-orderDate';
export const RETURN_AND_REPLACEMENT = 'return_and_replacement';
export const MY_ORDERS = 'myOrders';
export const OPEN_REFUND = 'open_refund';
export const OPEN_REPLACEMENT = 'open_replacement';
export const DEFAULT_PAGESIZE = 10;
export const CHAR_LIMIT = 2;
// dateRange options array
export const dateRangeOptions = [
  { value: SEVEN_DAYS, label: 'Last 7 days' },
  { value: THIRTY_DAYS, label: 'Last 30 days' },
  { value: SIX_MONTHS, label: 'Last 180 days' },
  { value: ONE_YEAR, label: 'Last 360 days' },
  { value: ALL_DAYS, label: 'All' }
];

export const NOT_DELIVERED = 'Not delivered';
const BOX_DAMAGED = 'Box is damaged';
const PRODUCT_DAMAGED = 'Product is damaged';
const WRONG_PRODUCT_RECEIVED = 'Wrong product received';
const DELIVERED_TOO_LATE = 'Delivered too late';
const FAULTY = 'Faulty';
const PRODUCT_NOT_AS_DESCRIBED = 'Product not as described';
export const CHANGED_MIND = 'Changed mind';
const PART_MISSING = 'Part missing';
export const PRODUCT_RECALL = 'Product Recall';
export const DUPLICATE_ORDER = 'Duplicate Order';

export const RETURNS_REASON = [
  WRONG_PRODUCT_RECEIVED,
  DELIVERED_TOO_LATE,
  FAULTY,
  BOX_DAMAGED,
  PRODUCT_DAMAGED,
  PRODUCT_NOT_AS_DESCRIBED,
  CHANGED_MIND,
  NOT_DELIVERED,
  PART_MISSING
];
export const REFUND = 'REFUND';
export const REPLACE = 'REPLACE';
export const REPLACE_AND_REFUND = 'REPLACE_AND_REFUND';
export const REFUND_OPTION = {
  value: REFUND, title: getLocalizedString('myAccount.returns.iLikeRefund')
};
export const REPLACEMENT_OPTION = {
  value: REPLACE, title: getLocalizedString('myAccount.returns.iLikeReplacement')
};
export const RETURN_OPTIONS = [
  REPLACEMENT_OPTION,
  REFUND_OPTION
];

export const IMAGES_REQUIRED_FOR_OPTION = [
  WRONG_PRODUCT_RECEIVED,
  BOX_DAMAGED,
  PRODUCT_DAMAGED];
export const COMMENT_REQUIRED_FOR_OPTION = [
  WRONG_PRODUCT_RECEIVED,
  DELIVERED_TOO_LATE,
  FAULTY,
  BOX_DAMAGED,
  PRODUCT_DAMAGED,
  PRODUCT_NOT_AS_DESCRIBED,
  NOT_DELIVERED,
  PART_MISSING];
export const returnRequestType = {
  [RETURN_AND_REPLACEMENT]: 'refunds or replacements',
  [OPEN_REFUND]: 'refunds',
  [OPEN_REPLACEMENT]: 'replacements'
};

export const PROCESSING = 'Processing';
export const FILE_SIZE_LIMIT = 1000; // in kb
export const MAX_FILE_SIZE = 5000; // 5MB in kb
export const MAX_FILE_SELECTED_LENGTH = 5;
export const MAX_COMMENT_LENGTH = 500;
export const MAX_RETURNS_DAYS_DIFFERENCE = 32;

export const RETURN_FORM = 'returnForm';
// dispatch status
const PROCESSING_PART_DISPATCHED = 'PROCESSING_PART_DISPATCHED';
const PROCESSING_PART_DISPATCHED_PART_CANCELLED = 'PROCESSING_PART_DISPATCHED_PART_CANCELLED';
const COMPLETE_DISPATCHED = 'COMPLETE_DISPATCHED';
const COMPLETE_PART_DISPATCHED_PART_CANCELLED = 'COMPLETE_PART_DISPATCHED_PART_CANCELLED';

export const SF_CLOSED_NOT_RETURNED = 'Closed not returned';
export const SF_REJECTED = 'Rejected';

export const VALID_RETURN_ORDER_STATUS = [
  PROCESSING_PART_DISPATCHED,
  PROCESSING_PART_DISPATCHED_PART_CANCELLED,
  COMPLETE_DISPATCHED,
  COMPLETE_PART_DISPATCHED_PART_CANCELLED
];
