import React from 'react';
import _times from 'lodash/times';
import SvgLoader from 'client/components/svg/SvgLoader';

const loadSvg = (xlinkHref, className, key) => {
  const props = {
    xlinkHref,
    className,
    key
  };
  return <SvgLoader { ...props } />;
};

const FeefoStarComponent = ({ rating, maxRating }) => {
  const fullStars = Math.floor(rating) > maxRating ? maxRating : Math.floor(rating);
  const halfStar = rating - Math.floor(rating) > 0 ? 1 : 0;
  const borderStar = Math.floor(maxRating - fullStars - halfStar);
  return (
    <span className="FeeforReviewPanel_StarComponent">
      {
        _times(fullStars, (i) => loadSvg('#star-icon-filled', 'star-icon-filled', i))
      }
      {
        _times(halfStar, (i) => loadSvg('#half-star-icon-filled', 'half-star-icon-filled', i))
      }
      {
        _times(borderStar, (i) => loadSvg('#quality-icon', 'quality-icon', i))
      }
    </span>
  );
};

FeefoStarComponent.propTypes = {
};

export default FeefoStarComponent;
