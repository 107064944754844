import { fromJS } from 'immutable';
import { SET_UNLEASH_TOGGLES, SET_UNLEASH_CONTEXT, REQUEST_UNLEASH_VALUES_SUCCESS } from '../actions/unleashActions';

const unleashReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case SET_UNLEASH_TOGGLES:
      return state.set('toggles', fromJS(action.toggles));
    case SET_UNLEASH_CONTEXT:
      return state.set('context', fromJS(action.context));
    case REQUEST_UNLEASH_VALUES_SUCCESS:
      return state.set('payload', fromJS(action.payload));
    default:
      return state;
  }
};

export default unleashReducer;
