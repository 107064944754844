import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import flowRight from 'lodash/flowRight';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { SecondaryButton, PrimaryButton } from 'client/components/controls/StyledForms/formButtons';
import { ORDER_REVIEW_ACCORDION_PANEL_ID, PAYMENT_ACCORDION_PANEL_ID } from 'shared/constants/singlePageCheckout';
import { setActivePanelId } from 'client/actions/checkoutActions';
import { removeCartScheduledOrdersSettings, setInvalidSubscriptionPaymentMethod } from 'client/actions/scheduledOrders';
import { CHECKOUT_SELECTOR } from 'shared/constants/singlePageCheckout';
import { PAY_BY_ACCOUNT, PAY_BY_EXISTING_CARD, PAY_BY_NEW_CARD } from 'client/components/elements/paymentMethod/PaymentMethod/constants';
import { postOrder } from 'client/actions/ordersActions';
import { toggleSaveCardForFutureOrders } from 'client/components/elements/paymentMethod/PayByNewCard/payByNewCardActions';
import { changePaymentMethod } from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethodActions';
import { List } from 'immutable';

if (process.browser) {
  require('./ScheduledOrdersPaymentDialog.scss');
}

const ScheduledOrdersPaymentDialog = (props) => {
  const {
    invalidSubscriptionPaymentMethod,
    hideDialog,
    setActivePanelId,
    toggleSaveCardForFutureOrders,
    postOrder,
    fromQuotationId,
    validNonDeliverableStatus,
    setCheckoutPaymentMethod,
    isBusinessAccount,
    isSavedBraintreeCard,
    removeScheduledOrdersSettings
  } = props;

  const content = !invalidSubscriptionPaymentMethod ? {}
    : [PAY_BY_NEW_CARD, PAY_BY_EXISTING_CARD].includes(invalidSubscriptionPaymentMethod)
      ? {
        title: getLocalizedString('scheduledOrders.paymentModal.save.card.title'),
        message: getLocalizedString('scheduledOrders.paymentModal.save.card.message'),
        primaryButtonLabel: getLocalizedString('scheduledOrders.paymentModal.save.card.button'),
        primaryButtonAction: () => {
          toggleSaveCardForFutureOrders();
          postOrder({ selector: CHECKOUT_SELECTOR, quotationId: fromQuotationId, validNonDeliverableStatus });
          hideDialog();
        },
        secondaryButtoLabel: getLocalizedString('scheduledOrders.paymentModal.remove.subscriptions'),
        secondaryButtonAction: () => {
          removeScheduledOrdersSettings();
          postOrder({ selector: CHECKOUT_SELECTOR, quotationId: fromQuotationId, validNonDeliverableStatus });
          hideDialog();
        }
      }
      : {
        title: getLocalizedString('scheduledOrders.paymentModal.change.method.title'),
        message: getLocalizedString('scheduledOrders.paymentModal.change.method.message'),
        primaryButtonLabel: 'Remove schedule order',
        primaryButtonAction: () => {
          removeScheduledOrdersSettings();
          setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID });
          hideDialog();
        },
        secondaryButtoLabel: 'Alternative payment method',
        secondaryButtonAction: () => {
          setCheckoutPaymentMethod(isBusinessAccount ? PAY_BY_ACCOUNT : isSavedBraintreeCard ? PAY_BY_EXISTING_CARD : PAY_BY_NEW_CARD);
          setActivePanelId({ panelId: PAYMENT_ACCORDION_PANEL_ID });
          hideDialog();
        }
      };

  return (
    <Modal className="ScheduledOrdersPaymentDialog"
      show={ invalidSubscriptionPaymentMethod }>
      <Modal.Header>
        <h2>{ content.title }</h2>
      </Modal.Header>
      <Modal.Body>
        <div>{ content.message }</div>
        <div className="ScheduledOrdersPaymentDialog_buttons">
          <SecondaryButton
            onClick={ () => content.secondaryButtonAction() }
            text={ content.secondaryButtoLabel } />
          <PrimaryButton
            onClick={ () => content.primaryButtonAction() }
            text={ content.primaryButtonLabel }/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const isPayOnAccountDisabled = state.getIn(['cart', 'tradeAccountButton', 'disablePayOnAccountButton']);
  return {
    invalidSubscriptionPaymentMethod: state.getIn(['scheduledOrders', 'invalidSubscriptionPaymentMethod']),
    isBusinessAccount: !isPayOnAccountDisabled && !!state.getIn(['user', 'accountDetails', 'tradeAccount', 'accountNo']),
    isSavedBraintreeCard: !!state.getIn(['braintree', 'cards'], List([])).find(card => card.get('token'))
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActivePanelId: (panelId) => dispatch(setActivePanelId(panelId)),
  hideDialog: () => dispatch(setInvalidSubscriptionPaymentMethod(null)),
  toggleSaveCardForFutureOrders: () => dispatch(toggleSaveCardForFutureOrders({ selector: CHECKOUT_SELECTOR })),
  postOrder: flowRight(dispatch, postOrder),
  removeScheduledOrdersSettings: () => dispatch(removeCartScheduledOrdersSettings()),
  setCheckoutPaymentMethod: (paymentMethod) => dispatch(changePaymentMethod({ selector: CHECKOUT_SELECTOR, paymentMethod }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrdersPaymentDialog);
export { ScheduledOrdersPaymentDialog };
