import { ApiRequest } from 'shared/utils/apiUtils';

export function registerCreditAccount (body) {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/accounts',
    body
  });
  return req.executeAndGetBody();
}

export function requestLimitIncreaseForUsers () {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/users/requestCreditLimitIncrease',
    body: { }
  });
  return req.executeAndGetBody();
}
