import React from 'react';
import { RecommendationBrandName } from 'client/components/elements/recommendations/baseComponents/RecommendationBrandName';
import { RecommendationReview } from 'client/components/elements/recommendations/baseComponents/index';
import NextStepsFamily from 'client/components/elements/recommendations/elements/NextStepsFamily';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import { Heading } from '@graingerglobalonline/curly-eureka';

export const RecommendationTileFamilyItem = ({
  brandName,
  actionButtonProps,
  link,
  clickHandler,
  thumbnail,
  familyHeading,
  truncatedTitle,
  reviewProps
}) => {
  return (
    <div className="RecommendationTile">
      <div className="RecommendationTile_Container">
        <div className="RecommendationTile_Clickable">
          <a href={ link } onClick={ clickHandler }>
            <div className="RecommendationTile_Image">
              <ZoroImage
                className="gtm_recommended_image"
                src={ thumbnail }
                alt={ familyHeading }
              />
            </div>
            <div className="RecommendationTile_Title">
              <a
                href={ link }
                onClick={ clickHandler }
                className="gtm_recommended_title"
              >
                <div className='gtm_recommended_brandName'>
                  <RecommendationBrandName brandName={ brandName } />
                </div>
                <div className='gtm_recommended_title_name'>
                  <Heading variant="small" tag="p">
                    {truncatedTitle}
                  </Heading>
                </div>
              </a>
            </div>
            <div className='gtm_recommended_review'>
              <RecommendationReview { ...reviewProps } />
            </div>
          </a>
        </div>
        <NextStepsFamily { ...actionButtonProps } />
      </div>
    </div>
  );
};
