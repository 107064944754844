import React from 'react';
import NewStockMessage from 'client/components/elements/newStockMessage/NewStockMessage';

export const RecommendationDeliveryDetails = ({
  stock,
  productPackPriceWithVat,
  currency,
  sku
}) =>
  stock ? (
    <div className="RecommendationTile_StockMessage">
      <NewStockMessage
        stockStatus={ stock }
        productPrice={ productPackPriceWithVat }
        productCurrency={ currency }
        sku={ sku }
        isRecommendations
      />
    </div>
  ) : null;
