import { all, fork, put, takeLatest, select, call } from 'redux-saga/effects';
import { setData, setDetails } from './payByGooglePayActions';
import { orderSelectors, paymentMethodSelectors } from '../shared/selectors';
import { saveAddress } from 'client/actions/addressActions';
import { SINGLE_PAGE_CHECKOUT_ROUTE } from 'shared/constants/singlePageCheckout';
import { GOOGLE_PAY } from 'shared/constants/braintree';
import { postOrderWithPaymentInformation } from 'shared/endpoints/ordersEndpoint';
import { getLocalizedString } from 'localization/localizer';
import { createOrder, handleResponse, orderFailed } from '../shared/orderHelpers';
import { INITIALIZE_GOOGLE_PAY, PAY_BY_GOOGLE_PAY } from './payByGooglePayActions';
import { getDeviceData, verifyCardWith3ds } from 'client/sagas/braintreeGatewaySagas';

export const googlePayInstanceSelector = (state) => state.getIn(['braintree', 'googlePay', 'instance']);
export const googlePayDataSelector = (state) => state.getIn(['braintree', 'googlePay', 'data']);

export function * initializeGooglePay ({ payload }) {
  const googlePayInstance = yield select(googlePayInstanceSelector);
  if (googlePayInstance) {
    const data = yield select(googlePayDataSelector);
    yield put(setData({
      data,
      selector: payload.selector
    }));
    yield put(setDetails({
      details: data.billingAddress,
      selector: payload.selector
    }));
  }
}

export function * payByGooglePay ({ payload }) {
  try {
    const googlePayData = yield select(paymentMethodSelectors.googlePay.data(payload.selector));
    const [firstName, lastName = ''] = googlePayData.billingAddress.name.split(' ');

    const billingAddress = {
      firstName,
      lastName: lastName,
      addressLine1: googlePayData.billingAddress.address1,
      addressLine2: googlePayData.billingAddress.address2,
      postalCode: googlePayData.billingAddress.postalCode,
      city: googlePayData.billingAddress.locality,
      addressPhoneNumber: googlePayData.billingAddress.phoneNumber
    };

    yield put(saveAddress({ addressType: 'billing', address: billingAddress, redirectToUrl: SINGLE_PAGE_CHECKOUT_ROUTE }));
    const threeDResponse = yield call(verifyCardWith3ds, googlePayData.paymentDetails.nonce, googlePayData.paymentDetails.details, billingAddress, payload.quotationId);

    const paymentInformation = {
      paymentType: GOOGLE_PAY,
      isPriceWithVat: yield select(orderSelectors.isPriceWithVat),
      deviceData: yield call(getDeviceData),
      braintreePayload: {
        nonce: threeDResponse.nonceFromThreeDS,
        details: threeDResponse.detailsFromThreeDS
      },
      shouldUpdateBillingAddress: true,
      csAgentName: yield select(orderSelectors.csAgent.name),
      storePaymentMethodStatus: true
    };
    const order = yield call(createOrder, GOOGLE_PAY, billingAddress, paymentInformation);
    if (payload.quotationId) {
      order.quotationId = payload.quotationId;
    }
    const postOrderResponse = yield call(postOrderWithPaymentInformation, order);
    yield call(handleResponse, postOrderResponse);
  } catch (err) {
    yield call(orderFailed, getLocalizedString('singlePageCheckout.place.order.internal.server.error'));
  }
}

function * watchInitializeGooglePay () {
  yield takeLatest(INITIALIZE_GOOGLE_PAY, initializeGooglePay);
}
function * watchPayByGooglePay () {
  yield takeLatest(PAY_BY_GOOGLE_PAY, payByGooglePay);
}
export function * watchAllPayByGooglePaySagas () {
  yield all([
    fork(watchInitializeGooglePay),
    fork(watchPayByGooglePay)
  ]);
}
