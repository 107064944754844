import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { getLocalizedString } from 'localization/localizer';
import { setUnsupportedBrowserDialogVilisbility } from 'client/actions/ui/dialogActions';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';

if (process.browser) {
  require('./UnsupportedBrowserDialog.scss');
}

function mapStateToProps (state) {
  return {
    isVisible: state.getIn(['ui', 'dialogs', 'unsupportedBrowser', 'isVisible']),
    browserName: state.getIn(['ui', 'dialogs', 'unsupportedBrowser', 'browserName']),
    browserSupport: state.getIn(['config', 'browserSupport']),
    browserVersion: state.getIn(['ui', 'dialogs', 'unsupportedBrowser', 'browserVersion'])
  };
}

function mapDispatchToProps (dispatch) {
  return {
    hideUnsupportedBrowserDialog: () => {
      dispatch(setUnsupportedBrowserDialogVilisbility(false));
    }
  };
}

// --------------------------------------------------
//

export class UnsupportedBrowserDialog extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    browserName: PropTypes.string,
    browserSupport: PropTypes.object,
    hideUnsupportedBrowserDialog: PropTypes.func
  };

  render () {
    const { isVisible, browserName, hideUnsupportedBrowserDialog, browserSupport } = this.props;
    const isKnownBrowser = !!browserSupport.get(browserName);
    const title = isKnownBrowser ? getLocalizedString('unsupportedBrowser.title') : getLocalizedString('unsupportedBrowser.title.alt');
    const mainText = isKnownBrowser
      ? getLocalizedString('unsupportedBrowser.mainText')
      : getLocalizedString('unsupportedBrowser.mainText.alt');
    const browserFamilyName = isKnownBrowser ? browserName : 'Chrome';
    const suggestedBrowserName = browserFamilyName === 'IE' ? 'Internet Explorer' : browserFamilyName;
    const imgUrl = `/content/images/static/${browserFamilyName.toLowerCase()}_logo.png`;
    const downloadLink = browserSupport.getIn([browserFamilyName, 'downloadLink']);

    // TODO: we should use a modal that older browsers can display as well.
    // Until that this is just a workaround.
    if (process.browser) {
      const browserVersion = parseInt(this.props.browserVersion, 10);
      if (browserName === 'Chrome' && browserVersion < 19) {
        alert(`${title} \n ${getLocalizedString('unsupportedBrowser.suggestionText')} ${suggestedBrowserName}`);
      }
    }

    return (
      <Modal className="UnsupportedBrowserDialog" show={ isVisible } onHide={ hideUnsupportedBrowserDialog }>
        <Modal.Header className="UnsupportedBrowserDialog_header" closeButton>
          <h1 className="UnsupportedBrowserDialog_title">{ title }</h1>
        </Modal.Header>
        <Modal.Body className="UnsupportedBrowserDialog_body">
          { mainText }
          <div className="UnsupportedBrowserDialog_suggestionBox">
            <div className="UnsupportedBrowserDialog_suggestedBrowser">
              <div><a href={ downloadLink } rel="noopener noreferrer" target="_blank"><ZoroImage src={ imgUrl } alt={ suggestedBrowserName }/></a></div>
              <a href={ downloadLink } rel="noopener noreferrer" target="_blank">{ suggestedBrowserName }</a>
            </div>
            <div className="UnsupportedBrowserDialog_suggestion">
              <span>{ getLocalizedString('unsupportedBrowser.suggestionText') } <a href={ downloadLink } rel="noopener noreferrer" target="_blank">{ suggestedBrowserName }</a></span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsupportedBrowserDialog);
