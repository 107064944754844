import Popover from 'react-bootstrap/lib/Popover';
import QuickOrderForm from './components/QuickOrderDialog/QuickOrderForm';
import { getLocalizedString } from 'localization/localizer';
import { ToggleButton } from '../toggleButton/toggleButton';
import React, { useState } from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';
import { connect } from 'react-redux';
import flowRight from 'lodash/flowRight';
import { onToggleGlobalVat } from 'client/actions/userActions';

const MobileMenuHeader = ({
  changeGlobalVATState,
  isPriceWithVat
}) => {
  const [showQuickOrder, setQuickOrder] = useState(false);
  return <Popover id="popover-positioned-bottom" className="HeaderB_menuPopOver">
    <div className="HeaderB_menuPopOver_quote">
      <SvgLoader xlinkHref="#quote-icon"/>
      <a href="/global-quotation">{ getLocalizedString('label.quotation') }</a>
    </div>
    <div className="HeaderB_menuPopOver_quickOrder">
      <div onClick={ () => setQuickOrder(!showQuickOrder) } className="HeaderB_menuPopOver_quickLabel">
        <SvgLoader xlinkHref="#quick-order"/>
        <span>{ getLocalizedString('label.quickOrder') }</span>
      </div>
      {
        showQuickOrder
          ? <div className="HeaderB_menuPopOver_quickBody">
            <QuickOrderForm />
          </div>
          : null
      }
    </div>
    <div className="HeaderB_menuPopOver_vat">
      <span className="infoRibbonQuoteBold">{ getLocalizedString('header.label.showPrices') }:</span>
      <span className="infoRibbonIncVATSmall">{ getLocalizedString('excVat') }</span>
      <ToggleButton
        id='globalIncExcVAT'
        name='globalIncExcVAT'
        checked={ isPriceWithVat }
        showLabel={ false }
        onChange={ () => changeGlobalVATState(isPriceWithVat) }
      />
      <span className="infoRibbonExcVATSmall">{ getLocalizedString('incVat') }</span>
    </div>
  </Popover>;
};

const mapStateToProps = (state) => ({
  isPriceWithVat: state.getIn(['user', 'isPriceWithVat'])
});

const mapDispatchToProps = (dispatch) => ({
  changeGlobalVATState: flowRight(dispatch, onToggleGlobalVat)
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuHeader);
