import React from 'react';
import ProductAddToCart from 'client/components/elements/productAddToCart/ProductAddToCart';
import {
  RecommendationBrandName,
  RecommendationReview,
  RecommendationDeliveryDetails,
  RecommendationPriceWithMultiplier,
  RecommendationPrice
} from 'client/components/elements/recommendations/baseComponents/index';
import ZoroImage from 'client/components/elements/zoroImage/ZoroImage';
import { Heading } from '@graingerglobalonline/curly-eureka';
import { BoughtOn } from './BoughtOn';
import { getLocalizedString, getLocalizedStringWithParam } from 'localization/localizer';

export const RecommendationTile = ({
  link,
  clickHandler,
  thumbnail,
  actionButtonProps,
  name,
  truncatedName,
  brandName,
  promoLabelProps,
  reviewProps,
  deliveryDetailsProps,
  quantityOfProps,
  priceWithMultiplierProps,
  priceProps,
  boughtOn,
  basketDropDown
}) => {
  const { displayDiscount, isInPromo, breakDiscount } = promoLabelProps;
  const isDiscount = isInPromo || !!breakDiscount;
  return (
    <div className="RecommendationTile_Container">
      { isDiscount
        ? <span className="RecommendationTile_Discount">
          { getLocalizedStringWithParam('recommendations.product.tile.discount.percentage', { discount: displayDiscount }) }
        </span>
        : null
      }
      <div className="RecommendationTile_Clickable">
        <a href={ link } onClick={ clickHandler }>
          <div className="RecommendationTile_Image">
            <ZoroImage className="gtm_recommended_image" src={ thumbnail } alt={ name } />
            { !actionButtonProps.isPOA
              ? <RecommendationPriceWithMultiplier { ...priceWithMultiplierProps } location />
              : null }
          </div>
          <div className="RecommendationTile_Title">
            <a
              href={ link }
              onClick={ clickHandler }
              className="gtm_recommended_title"
              title={ name }
            >
              <div className='gtm_recommended_brandName'>
                <RecommendationBrandName brandName={ brandName } />
              </div>
              <div className='gtm_recommended_title_name'>
                <Heading variant="small" tag="p">
                  {truncatedName}
                </Heading>
              </div>
            </a>
          </div>
          <div className='gtm_recommended_review'>
            <RecommendationReview { ...reviewProps } />
          </div>
          { !actionButtonProps.isPOA ? <>
            <div className='gtm_recommended_deliveryDetails'>
              <RecommendationDeliveryDetails { ...deliveryDetailsProps } />
            </div>
            <RecommendationPrice { ...priceProps } />
          </> : <div className="RecommendationTile_PoaText">
            { getLocalizedString('POA.product.text.poa') }
          </div>
          }
        </a>
        <div className='ProductLine_boughtOn mt-auto flex items-center text-darkgreys'>
          { boughtOn
            ? <BoughtOn className="ProductLine_boughtOn"/>
            : null}
        </div>
      </div>
      <ProductAddToCart { ...actionButtonProps } basketDropDown={ basketDropDown } />
    </div>
  );
};
