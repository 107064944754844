import React from 'react';
import SvgLoader from 'client/components/svg/SvgLoader';

const Avatar = ({ text }) => {
  return (
    text ? <div className="AccountDropdown_avatar">
      {text }
    </div>
      : <SvgLoader xlinkHref="#logged-out-icon" />
  );
};
export default Avatar;
