import jwt from 'jsonwebtoken';
import { ApiRequest, FrontendApiRequest } from './apiUtils';

import {
  updateJWTToken
} from 'client/actions/tokenActions';

export const JWT_COOKIE_NAME = 'jwtToken';
export const LONG_SESSION_JWT_COOKIE_NAME = 'jwtTokenLongSession';
const TOKEN_REFRESH_THRESHOLD_SECONDS = 10 * 60;

export const TOKEN_COOKIE_EXP_DAYS = 180;
export const LONG_SESSION_TOKEN_COOKIE_EXP_DAYS = 210;

export const TokenStatus = {
  MISSING: 'MISSING',
  VALID: 'VALID',
  ALMOST_EXPIRED: 'ALMOST_EXPIRED',
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID'
};

export function getToken (getState) {
  return getState().getIn(['auth', 'jwtToken']);
}

export function getTokenStatus (token) {
  if (!token) {
    return TokenStatus.MISSING;
  }

  let decodedToken;
  try {
    decodedToken = jwt.decode(token);
    const expiresOn = decodedToken.exp;

    if (expiresOn === undefined) {
      return TokenStatus.VALID;
    }

    const curSeconds = Date.now() / 1000;
    if (curSeconds >= expiresOn) {
      return TokenStatus.EXPIRED;
    }

    if (curSeconds >= (expiresOn - TOKEN_REFRESH_THRESHOLD_SECONDS)) {
      return TokenStatus.ALMOST_EXPIRED;
    }

    return TokenStatus.VALID;
  } catch (err) {
    console.log('Invalid token:', err); // eslint-disable-line no-console
    return TokenStatus.INVALID;
  }
}

export function clearJwtTokenCookie (res) {
  res.clearCookie(JWT_COOKIE_NAME);
}

export function clearLongSessionJwtTokenCookie (res) {
  res.clearCookie(LONG_SESSION_JWT_COOKIE_NAME);
}

export function setJwtTokenCookie (token, res, cookieName, cookieExp) {
  // We are server-side and setting cookie via Response object
  const sslOnly = (process.env.NODE_ENV !== 'development');
  res.cookie(
    cookieName,
    token,
    {
      maxAge: 3600 * 24 * cookieExp * 1000,
      httpOnly: true,
      secure: sslOnly
    }
  );
}

// TODO: should return a promise?
export function setToken (token, longSessionToken, hasActiveTradeAccount) {
  // Updating cookie directly is not possible as it's HttpOnly
  const req = new FrontendApiRequest({
    method: 'PUT',
    apiPath: 'api/jwtToken',
    body: { token, longSessionToken, hasActiveTradeAccount }
  });

  return req.execute();
}

function onMissingToken (dispatch, getState) {
  return fetchNewAnonymousToken(dispatch, getState)
    .then((newToken) => {
      setToken(newToken);
      dispatch(updateJWTToken({ token: newToken }));
    });
}

function onAlmostExpiredToken (dispatch, getState, token) {
  return renewToken(dispatch, getState, token, 'token,longSessionToken')
    .then((newTokens) => {
      setToken(newTokens.token, newTokens.longSesionToken, newTokens.hasActiveTradeAccount);
      dispatch(updateJWTToken(newTokens));
    });
}

export function forceUserLogout () {
  if (!process.browser) {
    throw new Error('Logout only works on browser');
  }

  const req = new FrontendApiRequest({
    method: 'DELETE',
    apiPath: 'api/jwtToken'
  });

  return req.execute().then(() => {
    window.location.href = '/';
  });
}

function onExpiredOrInvalidToken () {
  // TODO: this is a temporary hack to refetch new anonymous token
  // by removing cookie and refreshing browser window
  forceUserLogout();
  return Promise.reject();
}

export function validateToken (dispatch, getState) {
  const token = getToken(getState);
  const tokenStatus = getTokenStatus(token);

  if (tokenStatus !== TokenStatus.VALID) {
    console.log('Validating JWT token. Status:', tokenStatus); // eslint-disable-line no-console
  }
  const statusHandlers = {
    [TokenStatus.MISSING]: onMissingToken,
    [TokenStatus.ALMOST_EXPIRED]: onAlmostExpiredToken,
    [TokenStatus.INVALID]: onExpiredOrInvalidToken,
    [TokenStatus.EXPIRED]: onExpiredOrInvalidToken
  };

  const handler = statusHandlers[tokenStatus] || (() => { return Promise.resolve(); });

  return handler(dispatch, getState, token);
}

export function fetchNewAnonymousToken (dispatch, getState, subject = 'subject') {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/token',
    body: {
      sub: subject
    },
    sendJwtToken: false,
    validateToken: false, // to avoid infinite loop
    dispatch,
    getState
  });

  return req.execute()
    .then((r) => {
      return r.body.token;
    });
}

export function renewToken (dispatch, getState, token, tokensToRenew = 'token') {
  const req = new ApiRequest({
    method: 'POST',
    apiPath: '/security/renew_token',
    body: {
      token: token,
      tokensToRenew
    },
    validateToken: false, // to avoid infinite loop
    dispatch,
    getState
  });

  return req.execute()
    .then((r) => {
      return JSON.parse(r.text);
    });
}

export function getJwtEmail (token) {
  try {
    const { email } = jwt.decode(token);
    return email;
  } catch (err) {
    return '';
  }
}

export function getJwtCustomerId (token) {
  try {
    const { customerId } = jwt.decode(token);
    return customerId;
  } catch (err) {
    return '';
  }
}

export const getJwtUnleashUserId = (token, longSessionToken, isLoggedInOrhasValidLongSessionToken) => {
  try {
    const { customerId, prevCustomerId } = jwt.decode(longSessionToken) || jwt.decode(token);
    return {
      unleashUserId: prevCustomerId || customerId,
      zoroUserId: isLoggedInOrhasValidLongSessionToken ? customerId : null
    };
  } catch (err) {
    return '';
  }
};

export function getJwtName (token) {
  try {
    const { name } = jwt.decode(token);
    return name;
  } catch (err) {
    return '';
  }
}

export const getJwtCsAgentDetails = (token) => {
  try {
    const {
      csAgentId,
      csAgentName
    } = jwt.decode(token);

    return {
      csAgentId,
      csAgentName
    };
  } catch (err) {
    return '';
  }
};

export const getJwtUserCode = (token) => {
  try {
    const { userCode } = jwt.decode(token);
    return userCode;
  } catch (err) {
    return '';
  }
};

export function getTradeAccountDataFromToken (token, longSessionToken) {
  const { tradeAccountIsValidForStatement, tradeAccountIsValid, accountNo } = jwt.decode(token) || {};
  const {
    tradeAccountIsValidForStatement: isValidforStatementLong,
    tradeAccountIsValid: isValidAccountLong,
    accountNo: accountNoLongSession
  } = jwt.decode(longSessionToken) || {};
  return {
    tradeAccountIsValidForStatement: tradeAccountIsValidForStatement || isValidforStatementLong,
    tradeAccountIsValid: tradeAccountIsValid || isValidAccountLong,
    accountNo: accountNo || accountNoLongSession
  };
}

export function getTokenDetails (token) {
  try {
    return jwt.decode(token);
  } catch (err) {
    return '';
  }
}
