import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import { getLocalizedString } from 'localization/localizer';
import FieldWithValidations from 'client/components/elements/formField/FieldWithValidationsNewStyles';
import { PrimaryButton, SecondaryButton } from 'client/components/controls/StyledForms';
import {
  isRequiredIf,
  matches,
  minLength,
  passwordValidator
} from 'client/utils/validators';
import { fromJS } from 'immutable';
import {
  registerUserAndGoToCheckout
} from 'client/actions/userActions';
import {
  CREATE_INDIVIDUAL_ACCOUNT_VIEW, USER_TYPE_INDIVIDUALS
} from '../constants';
import { makeValidator } from 'client/utils/validators';
import { validateForm } from 'client/utils/formValidation';
import CheckBoxWithValidations from 'client/components/elements/formField/CheckboxWithValidations';
import PasswordStengthIndicator from 'client/components/elements/formField/PasswordStrengthIndicator';
import { validatePasswordChecklist } from 'client/actions/passwordChecklistAction';
import { PasswordChecklistAndSvg } from './PasswordChecklistAndSvg';
import { setView } from 'client/actions/loginAndRegistration';

if (process.browser) {
  require('./EmailAndPassword.scss');
}

const privacyPolicyLink = (
  <a id="PrivacyPolicyAgreement.checkbox.PrivacyPolicyLink" href="/info/privacy-policy" target="_blank">
    { getLocalizedString('registration.label.privacyPolicy') }
  </a>
);

const termsofAccessLink = (
  <a id="TAndCAcceptance.checkbox.TAndCLink" href="/info/zoro-business-account-terms" target="_blank">
    Terms of Access
  </a>
);

const validateFunc = (csAgentName) => makeValidator({
  password: [
    // Password is not required when customer-agent session is active
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.password')
    ),
    passwordValidator({
      fieldName: getLocalizedString('registration.label.password')
    }),
    minLength({
      minLength: 8,
      fieldName: getLocalizedString('registration.label.password')
    })
  ],
  confirmPassword: [
    // Password is not required when customer-agent session is active
    isRequiredIf(
      () => !csAgentName,
      getLocalizedString('registration.label.confirmPassword')
    ),
    matches({
      otherField: 'password',
      fieldName: getLocalizedString('registration.label.confirmPassword'),
      otherFieldName: getLocalizedString('registration.label.password'),
      message: getLocalizedString('error.match.password')
    })
  ],
  marketingConsent: []
});

const FORM_NAME = 'emailAndPassword';

const EmailAndPassword = ({
  csAgentName,
  handleSubmit,
  dispatchSetView,
  onRegister,
  aboutYouFormValues
}) => {
  const [, setConfirmPasswordVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordChecklist, setPasswordChecklist] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false
  });

  const createAccount = (handleSubmit, submit) => {
    return handleSubmit(submit)();
  };

  const submitCreateAccount = (values) => {
    return validateForm(values, validateFunc(csAgentName)).then(() => {
      return onRegister({
        title: aboutYouFormValues.title,
        password: values.get('password'),
        email: aboutYouFormValues.email,
        firstName: aboutYouFormValues.firstName,
        lastName: aboutYouFormValues.lastName,
        phoneNumber: aboutYouFormValues.phoneNumber,
        companyName: '',
        marketingConsent: values.get('marketingConsent'),
        captchaResponse: true,
        csAgentName,
        userType: USER_TYPE_INDIVIDUALS
      });
    });
  };

  const updatePasswordChecklist = (password) => {
    setPasswordVal(password);
    const checklist = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*()+.,£-]/.test(password)
    };

    setPasswordChecklist(checklist);
    if (
      checklist.length &&
      checklist.uppercase &&
      checklist.lowercase &&
      checklist.digit &&
      checklist.specialChar
    ) {
      setPasswordStrength('strong');
    } else if (checklist.lowercase && checklist.uppercase) {
      setPasswordStrength('average');
    } else {
      setPasswordStrength('weak');
    }
  };

  return (<div className="EmailAndRegistrationForm">
    <h1 className="EmailAndRegistrationForm_finalStep">Final Step</h1>
    { csAgentName
      ? <div className="EmailAndRegistrationForm_getStartedTitle">{ getLocalizedString('login.csAgentNameLogin.text') }</div>
      : <React.Fragment>
        <div className="EmailAndRegistrationForm_getStartedInput">
          <Field
            id="password"
            name="password"
            className={ `form-control password focus-shadow-none:focus ${passwordStrength === 'weak' ? 'border-weak weak-pass' : passwordStrength === 'average' ? 'border-average average-pass' : passwordStrength === 'strong' ? 'border-success success-pass' : ''}` }
            labelClassName="control-label required"
            type="password"
            placeholderResourceName="login.password.placeholder"
            labelResourceName="login.createAPassword.placeholder"
            component={ PasswordStengthIndicator }
            formClassName='password'
            datae2e="password"
            updatePasswordChecklist={ updatePasswordChecklist }
            passwordStrength={ passwordStrength }
            validatePasswordChecklist={ validatePasswordChecklist }
          />
        </div>
        <div className="EmailAndRegistrationForm_getStartedInput">
          <Field
            id="confirmPassword"
            placeholderResourceName="login.confirmPassword.placeholder"
            name="confirmPassword"
            className="form-control confirmPassword"
            labelClassName="control-label required"
            type="password"
            labelResourceName="login.confirmPassword.placeholder"
            component={ FieldWithValidations }
            datae2e="confirm-password"
            onChange= { (e) => setConfirmPasswordVal(e.target.value) }
          />
        </div>
        <h3 className="passContain">{ getLocalizedString('your.password.must.contain') }</h3>
        <div className="passwordChecklist passwordChecklistMobile">
          <div className="passwordChecklistContent_1 fontWeightNormal">
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.length } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.length') } />
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.lowercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.lowercase') } />
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.uppercase } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.uppercase') }/>
            }
          </div>

          <div className="passwordChecklistContent_2">
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.digit } passwordVal={ passwordVal } name={ getLocalizedString('password.checklist.digit') }/>
            }
            {
              <PasswordChecklistAndSvg condition={ passwordChecklist.specialChar } passwordVal={ passwordVal } name= { getLocalizedString('password.checklist.specialChar') } />
            }
          </div>

        </div>
      </React.Fragment>
    }
    <Field type="checkbox"
      key="marketingConsent"
      name="marketingConsent"
      className="ForgotPasswordNewForm_marketingConsent"
      component={ CheckBoxWithValidations }
      datae2e="marketing-consent"
      textAndLink={
        <div>
          <span> { getLocalizedString('registration.label.marketingConsent') } </span>
        </div>
      } />
    <div className="row">
      <div className="col-xs-12 BusinessAccountRegistrationForm_finalStep-btns">
        <div className="BusinessAccountRegistrationForm_finalStep-back">
          <SecondaryButton
            className="ForgotPasswordNewForm_backToLogin"
            datae2e="back"
            onClick={ () => dispatchSetView(CREATE_INDIVIDUAL_ACCOUNT_VIEW) }
          >
            Back
          </SecondaryButton>
        </div>
        <div className="ForgotPasswordNewForm_submitBtn BusinessAccountRegistrationForm_finalStep-createAccount">
          <PrimaryButton
            datae2e="createAccount"
            onClick={ () => createAccount(handleSubmit, submitCreateAccount) }
            data-e2e="submit">
                Create account
          </PrimaryButton>
        </div>
      </div>
    </div>
    <div className="EmailAndRegistrationForm_termsAndConditionsLink">
      { getLocalizedString('contactus.thank.you.message') } { termsofAccessLink }
      { '.' } { getLocalizedString('register.tAndCpart2.text') } { privacyPolicyLink } { getLocalizedString('register.tAndCpart3.text') }<br />
    </div>
  </div>);
};

const mapStateToProps = (state) => {
  const csAgentName = state.getIn(['customerService', 'csAgentName']);
  return {
    csAgentName,
    aboutYouFormValues: (getFormValues('aboutYouForm')(state) || fromJS({})).toJS(),
    passwordValidationChecklist: state.getIn(['passwordChecklist'])
  };
};

function mapDispatchToProps (dispatch) {
  return {
    validatePasswordChecklist: () => dispatch(validatePasswordChecklist()),
    dispatchSetView: (view) => dispatch(setView({ view })),
    onRegister: (params) => {
      dispatch(registerUserAndGoToCheckout(params));
    }
  }; }

const reduxLoginForm = reduxForm({
  form: FORM_NAME,
  validate: validateFunc()
})(EmailAndPassword);

export { EmailAndPassword };
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxLoginForm
);
