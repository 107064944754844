import React from 'react';
import UnsupportedBrowserDialog from 'client/components/dialogs/unsupportedBrowserDialog/UnsupportedBrowserDialog';
import RemoveProductDialog from 'client/components/dialogs/removeProductDialog/RemoveProductDialog';
import RemoveProductBundleDialog from 'client/components/dialogs/removeProductBundleDialog/RemoveProductBundleDialog';
import AddToQuotationDialog from 'client/components/dialogs/addToQuotationDialog/AddToQuotationDialog';
import AddToBasketDialog from '../../dialogs/addToBasketDialog/AddToBasketDialog';
import CustomAlertDialog from 'client/components/dialogs/customAlertDialog/CustomAlertDialog';
import RemoveQuoteLineDialog from 'client/components/dialogs/removeQuoteLineDialog/RemoveQuoteLineDialog';
import RemoveQuotationDialog from 'client/components/dialogs/removeQuotationDialog/RemoveQuotationDialog';
import RemoveAddressDialog from 'client/components/dialogs/removeAddressDialog/RemoveAddressDialog';
import ChangeInQuoteLineQtyDialog from 'client/components/dialogs/changeInQuoteLineQtyDialog/ChangeInQuoteLineQtyDialog';
import RemoveRepeatOrderDialog from 'client/components/dialogs/repeatOrderDialogs/RemoveRepeatOrderDialog';
import PausePlayRepeatOrderDialog from 'client/components/dialogs/repeatOrderDialogs/PausePlayRepeatOrderDialog';
import RemoveProductRepeatOrder from 'client/components/dialogs/repeatOrderDialogs/RemoveProductRepeatOrder';
import EditRepeatOrderDialog from 'client/components/dialogs/repeatOrderDialogs/EditRepeatOrderDialog';
import GlobalQuotationReminderDialog from 'client/components/dialogs/globalQuotationReminderDialog/globalQuotationReminderDialog';
import LockAndSaveGlobalQuote from 'client/components/dialogs/lockAndSaveQuoteDialog/lockAndSaveQuoteDialog';
import RemovePaymentMethodDialog from 'client/components/dialogs/removePaymentMethodDialog/RemovePaymentMethodDialog';
import BraintreeCvvCheckDialog from 'client/components/dialogs/braintreeCvvCheckDialog/BraintreeCvvCheckDialog';
import CancelReturnConfirmationDialog from 'client/components/dialogs/cancelReturnConfirmationDialog/cancelReturnConfirmationDialog';
import ValidationMessageDialog from 'client/components/dialogs/ValidationMessageDialog/ValidationMessageDialog';
import BusinessAccountOnHoldDialog from 'client/components/dialogs/businessAccountOnHoldDialog/BusinessAccountOnHoldDialog';
import ReturnConfirmationDialog from 'client/components/dialogs/returnConfirmationDialog/ReturnConfirmationDialog';
import SaveQuoteToMyList from 'client/components/dialogs/saveQuoteDialog/saveQuoteDialog';
import LoginAndRegistrationDialog from 'client/components/dialogs/loginAndRegistrationDialog/LoginAndRegistration';

const DialogContainer = ({ dialogs, location, updateDataLayer }) => {
  const isLoginDialogVisible = dialogs.getIn(['login', 'isVisible']);
  return (
    <div>
      <UnsupportedBrowserDialog />
      <RemoveProductDialog location={ location } updateDataLayer = { updateDataLayer } />
      <RemoveProductBundleDialog location={ location } updateDataLayer = { updateDataLayer } />
      <AddToQuotationDialog />
      <AddToBasketDialog />
      <CustomAlertDialog />
      <RemoveQuoteLineDialog />
      <RemoveQuotationDialog />
      <RemoveAddressDialog />
      <ChangeInQuoteLineQtyDialog />
      <RemoveRepeatOrderDialog />
      <PausePlayRepeatOrderDialog />
      <RemoveProductRepeatOrder />
      <EditRepeatOrderDialog />
      <GlobalQuotationReminderDialog />
      <LockAndSaveGlobalQuote />
      <SaveQuoteToMyList />
      <RemovePaymentMethodDialog />
      <BraintreeCvvCheckDialog location={ location } updateDataLayer = { updateDataLayer } />
      <CancelReturnConfirmationDialog />
      <ValidationMessageDialog />
      <BusinessAccountOnHoldDialog location={ location } updateDataLayer = { updateDataLayer } />
      <ReturnConfirmationDialog />
      { isLoginDialogVisible ? <LoginAndRegistrationDialog /> : null }
    </div>
  );
};

export default DialogContainer;
