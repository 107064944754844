import Immutable from 'immutable';
import {
  CATEGORY_BROWSING_STARTED
} from 'client/actions/ui/categoriesDropdownActions';
import {
  SEARCH_TERM_CHANGED,
  SEARCH_TERM_CLEARED
} from 'client/actions/searchBarActions';

export const defaultState = Immutable.fromJS({});

const categoriesFilterPanelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_TERM_CLEARED:
    case SEARCH_TERM_CHANGED:
    case CATEGORY_BROWSING_STARTED:
      return state.delete('expandedCategoryId');
    default:
      return state;
  }
};

export default categoriesFilterPanelReducer;
